import { AppBar as MuiAppBar, styled, Toolbar as MuiToolbar } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';
import { forNonDesktop } from '../../../utils/media-queries';

export const discussionPanelHeaderClasses = {
  ...generateClasses('PageHeader', ['toolbar', 'notification']),
};

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(5, 5),
  maxHeight: 80,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [forNonDesktop(theme)]: {
    padding: theme.spacing(5, 4),
  },
  position: 'relative',
  [`& .${discussionPanelHeaderClasses.notification}`]: {
    position: 'absolute',
    bottom: theme.spacing(-4),
    left: '50%',
    transform: 'translate(-50%, 100%)',
    whiteSpace: 'pre-wrap',
  },
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  minHeight: 44,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(3),
  [`&.${discussionPanelHeaderClasses.toolbar}`]: {
    minHeight: 'unset',
  },
  [forNonDesktop(theme)]: {
    gap: theme.spacing(4),
  },
  position: 'static',
}));
