import { SvgIcon, SvgIconProps } from '@mui/material';

export interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const Edit2Icon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19 20H4.99999C4.73477 20 4.48042 20.1054 4.29288 20.2929C4.10535 20.4804 3.99999 20.7348 3.99999 21C3.99999 21.2652 4.10535 21.5196 4.29288 21.7071C4.48042 21.8946 4.73477 22 4.99999 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20Z"
          fill="currentColor"
        />
        <path
          d="M4.99999 18H5.08999L9.25999 17.62C9.71679 17.5745 10.144 17.3732 10.47 17.05L19.47 8.05C19.8193 7.68096 20.0081 7.1885 19.995 6.68053C19.9818 6.17256 19.7679 5.6905 19.4 5.34L16.66 2.6C16.3024 2.26409 15.8338 2.07135 15.3433 2.05845C14.8529 2.04554 14.3748 2.21336 14 2.53L4.99999 11.53C4.67676 11.856 4.4755 12.2832 4.42999 12.74L3.99999 16.91C3.98652 17.0565 4.00553 17.2041 4.05565 17.3424C4.10578 17.4807 4.18579 17.6062 4.28999 17.71C4.38343 17.8027 4.49425 17.876 4.61609 17.9258C4.73792 17.9755 4.86838 18.0008 4.99999 18ZM15.27 4L18 6.73L16 8.68L13.32 6L15.27 4Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 20H4.99999C4.73477 20 4.48042 20.1054 4.29288 20.2929C4.10535 20.4804 3.99999 20.7348 3.99999 21C3.99999 21.2652 4.10535 21.5196 4.29288 21.7071C4.48042 21.8946 4.73477 22 4.99999 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21C20 20.7348 19.8946 20.4804 19.7071 20.2929C19.5196 20.1054 19.2652 20 19 20Z"
        fill="currentColor"
      />
      <path
        d="M4.99999 18H5.08999L9.25999 17.62C9.71679 17.5745 10.144 17.3732 10.47 17.05L19.47 8.05C19.8193 7.68096 20.0081 7.1885 19.995 6.68053C19.9818 6.17256 19.7679 5.6905 19.4 5.34L16.66 2.6C16.3024 2.26409 15.8338 2.07135 15.3433 2.05845C14.8529 2.04554 14.3748 2.21336 14 2.53L4.99999 11.53C4.67676 11.856 4.4755 12.2832 4.42999 12.74L3.99999 16.91C3.98652 17.0565 4.00553 17.2041 4.05565 17.3424C4.10578 17.4807 4.18579 17.6062 4.28999 17.71C4.38343 17.8027 4.49425 17.876 4.61609 17.9258C4.73792 17.9755 4.86838 18.0008 4.99999 18ZM15.27 4L18 6.73L16 8.68L13.32 6L15.27 4ZM6.36999 12.91L12 7.32L14.7 10.02L9.09999 15.62L6.09999 15.9L6.36999 12.91Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Edit2Icon;
