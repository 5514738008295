import { RootState } from '../../store/types';
import { getUnseenComments } from '../../utils/activities';
import { notEmpty } from '../../utils/array';
import { unix } from '../../utils/datetime';
import { getActivityById, getFullId } from '../shared/helpers';
import { CourseRole, TimelineItemType } from '../shared/types';
import { getAssignmentStats } from './helpers';
import { Assignment, SuggestedAssignment } from './types';

export const selectFullAssignmentId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { assignments } = state.db;
  return getFullId(assignments, shortId);
};

export const selectAssignment = (id: ShortId | MongoId) => (state: RootState) => {
  const { assignments } = state.db;
  return getActivityById(assignments, id);
};

export const selectAssignmentStats = (id: ShortId | MongoId) => (state: RootState) => {
  const assignment = selectAssignment(id)(state);
  return getAssignmentStats(assignment);
};

export const selectUnseenComments = (id: ShortId | MongoId) => (state: RootState) => {
  const assignment = selectAssignment(id)(state);
  if (!assignment) return 0;
  return getUnseenComments(assignment);
};

export const selectSuggestedAssignments = (state: RootState) => {
  const { assignments } = state.db;

  if (!assignments.suggested) return null;

  const { order, byId } = assignments.suggested;

  const result: SuggestedAssignment[] = [];

  for (const id of order) {
    const assignment = byId[id];
    if (assignment) result.push(assignment);
  }

  return result;
};

export const selectSuggestedAssignment = (id: ShortId | MongoId) => (state: RootState) => {
  const { suggested } = state.db.assignments;

  if (!suggested) return undefined;

  return getActivityById(suggested, id);
};

export const selectIsAssignmentDue = (id: ShortId | MongoId, role: CourseRole) => (state: RootState) => {
  const assignment = selectAssignment(id)(state);

  if (!assignment) return false;

  if (role !== CourseRole.STUDENT) {
    // for course team assignment due means it is yet to be published
    return assignment.publishedOn <= 0;
  }

  const userId = state.auth.session?.userId || '';
  const studentData = assignment.studentDataById[userId];

  if (!studentData) return true;

  const canSubmit = assignment.preferences.allowLate || unix() < assignment.preferences.dueDateTime;

  return canSubmit;
};

export const selectAllAssignments = (courseId: ShortId | MongoId) => (state: RootState) => {
  const { courses } = state.db;

  const course = getActivityById(courses, courseId);
  if (!course?.timeline)
    return {
      unpublishedAssignments: [],
      publishedAssignments: [],
    };

  const publishedAssignments: Assignment[] = [];
  const unpublishedAssignments: Assignment[] = [];

  for (const item of course.timeline) {
    if (item.type !== TimelineItemType.ASSIGNMENT) continue;

    const assignment = selectAssignment(item.id)(state);
    if (!assignment) continue;

    if (assignment.publishedOn === 0) {
      unpublishedAssignments.push(assignment);
    } else {
      publishedAssignments.push(assignment);
    }
  }

  return {
    unpublishedAssignments,
    publishedAssignments,
  };
};

export const selectAssignmentQuestions = (assignmentId: ShortId | MongoId) => (state: RootState) => {
  const assignment = selectAssignment(assignmentId)(state);
  if (!assignment?.questionsById) return [];
  return Object.values(assignment.questionsById).filter(notEmpty);
};
