import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

interface TabData {
  left: number;
  width: number;
  contentWidth: number;
}

interface TabsContextProps {
  activeTabData: TabData;
  setActiveTabData: Dispatch<SetStateAction<TabData>>;
}

export const TabsContext = createContext<TabsContextProps>({
  activeTabData: { left: 0, width: 0, contentWidth: 0 },
  setActiveTabData: () => {},
});

const useTabsContextProviderVM = () => {
  const [activeTabData, setActiveTabData] = useState<TabData>({ left: 0, width: 0, contentWidth: 0 });

  return useMemo(
    () => ({
      activeTabData,
      setActiveTabData,
    }),
    [activeTabData]
  );
};

interface Props {
  children: ReactNode;
}

const TabsContextProvider = ({ children }: Props) => {
  const providerValue = useTabsContextProviderVM();
  return <TabsContext.Provider value={providerValue}>{children}</TabsContext.Provider>;
};

export default TabsContextProvider;
