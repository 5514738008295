import { useEffect } from 'react';

import i18n, { i18nNS } from '../../../i18n';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

export default function usePricingInquiryThanksVM() {
  const { activeStep, closeDrawer, isCreatingProCourse, setTitle, setMobileTitle } = useCreateCourseContext();
  const isVisible = activeStep === CreateCourseStep.PRICING_INQUIRY_THANKS;

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return { closeDrawer, isVisible };
}
