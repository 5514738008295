import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { BreadcrumbContext } from '../../app/BreadcrumbProvider';
import BreadcrumbItem from '../../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../../components/Breadcrumb/index';
import { selectCurrentCourse } from '../../db/courses/selectors';

const useDiscussionWordCloudBreadcrumbVM = () => {
  const currentCourse = useSelector(selectCurrentCourse);
  const isCurrentCourseArchived = Boolean(currentCourse?.status.isArchived);

  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems, isCurrentCourseArchived };
};

const DiscussionWordCloudBreadcrumb = () => {
  const { breadcrumbItems, isCurrentCourseArchived } = useDiscussionWordCloudBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.discussionWordCloud}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      {isCurrentCourseArchived && <BreadcrumbItem item={breadcrumbItems.archived} />}
      <BreadcrumbItem item={breadcrumbItems.course} />
      <BreadcrumbItem item={breadcrumbItems.class} />
      <BreadcrumbItem item={breadcrumbItems.discussion} />
    </Breadcrumb>
  );
};

export default DiscussionWordCloudBreadcrumb;
