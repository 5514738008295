const refer = {
  /** document title */
  acadly_referral: 'Acadly - Referral',

  /** Refer page */
  help_us_spread_the_word: 'Help us spread the word',
  hi_there_thank_you_for_using_acadly_this_semester_if_it_has_been_a_useful_resource_for_you_so_far_you_can_play_a_big_role_in_helping_us_grow_and_improve_by_sparing_a_minute_of_your_time:
    'Hi there! Thank you for using Acadly this semester. If it has been a useful resource for you so far, you can play a big role in helping us grow and improve by sparing a minute of your time.',
  we_re_still_in_our_early_days_and_a_positive_word_or_referral_from_you_could_contribute_significantly_to_our_growth: `We're still in our early days, and a positive word or referral from you could contribute significantly to our growth.`,
  if_you_could_share_the_full_names_and_or_email_addresses_of_any_colleagues_from_universities_who_may_find_acadly_useful_we_will_reach_out_to_them:
    'If you could share the full names, and/or email addresses of any colleagues from universities who may find Acadly useful, we will reach out to them.',
  send_an_invite_to: 'Send an invite to',
  email_or_full_name: 'Email or Full Name',
  click_to_submit_your_referrals: 'click to submit your referrals',
  success_do_you_want_to_refer_more_people: 'Success! Do you want to refer more people?',
  click_to_go_back_to_settings: 'click to go back to settings',
  click_to_refer_more_people: 'click to refer more people',
};

export default refer;
