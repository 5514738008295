import { useTranslation } from 'react-i18next';

import DatePicker from '../../../../components/HookFormControls/Datepicker';
import { i18nNS } from '../../../../i18n';
import { dateToUnix, unix, unixToDate } from '../../../../utils/datetime';
import useSelectEndDateVM, { VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
  disabled?: boolean;
  helperText?: string;
}

const SelectEndDate = ({ className, disabled, helperText, ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  const { control, controlName, setEndDate, startDate } = useSelectEndDateVM(vmProps);

  return (
    <DatePicker
      required
      fullWidth
      className={className}
      minDate={startDate || unix()}
      control={control}
      disabled={disabled}
      name={controlName}
      parser={unixToDate}
      formatter={dateToUnix}
      onChange={setEndDate}
      inputFormat="MMM dd, yyyy"
      label={t('end_date', { ns: i18nNS.COURSES })}
      helperText={helperText}
    />
  );
};
export default SelectEndDate;
