import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const SpeakerphoneIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21.2 3.2C21.2 2.78411 20.9847 2.39787 20.6309 2.17922C20.2771 1.96057 19.8353 1.9407 19.4633 2.12669L10.1167 6.8H5.6C3.61177 6.8 2 8.41178 2 10.4C2 12.3882 3.61177 14 5.6 14H5.9351L8.06159 20.3795C8.22493 20.8695 8.68349 21.2 9.20001 21.2H10.4C11.0627 21.2 11.6 20.6627 11.6 20V14.7416L19.4633 18.6733C19.8353 18.8593 20.2771 18.8394 20.6309 18.6208C20.9847 18.4021 21.2 18.0159 21.2 17.6V3.2Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3514 2.06383C18.7416 2.21027 19 2.58332 19 3.00005V6.12606C20.7252 6.57011 22 8.13621 22 10C22 11.8639 20.7252 13.43 19 13.874V17C19 17.4168 18.7416 17.7898 18.3514 17.9363C17.9613 18.0827 17.5212 17.9719 17.247 17.6581C16.634 16.9566 15.5484 16.2834 14.0587 15.7864C13.4275 15.5759 12.7368 15.4014 12 15.2708V19.2402C12 20.7644 10.7644 22 9.24018 22C8.07384 22 7.03342 21.2668 6.64114 20.1685L6.63872 20.1617L4.62855 14.4027C3.06483 13.5588 2 11.9047 2 10C2 7.23862 4.23857 5.00005 6.99999 5.00005H8.83208C9.53626 5.00005 10.2202 4.96179 10.876 4.88985C10.8846 4.88878 10.8933 4.88782 10.902 4.88697C12.0562 4.75863 13.1228 4.52587 14.0587 4.21365C15.5484 3.71666 16.634 3.04352 17.247 2.34204C17.5212 2.02824 17.9613 1.91738 18.3514 2.06383ZM10 6.96791C9.61591 6.98917 9.22622 7.00005 8.83208 7.00005H6.99999C5.34314 7.00005 4 8.34319 4 10C4 11.2392 4.75153 12.3052 5.82767 12.7626C6.1865 12.9151 6.58204 13 6.99998 13L8.83209 13C9.22623 13 9.61591 13.0109 10 13.0322V6.96791ZM12 13.2431V6.75703C12.9581 6.6059 13.8624 6.38748 14.6916 6.11087C15.5313 5.83074 16.3125 5.48458 17 5.07584L17 14.9242C16.3125 14.5155 15.5313 14.1694 14.6916 13.8892C13.8624 13.6126 12.9581 13.3942 12 13.2431ZM19 11.7325C19.5978 11.3867 20 10.7403 20 10C20 9.25976 19.5978 8.61342 19 8.26761V11.7325ZM10 15.0356C9.61804 15.0122 9.22827 15 8.83208 15L6.99997 15C6.98507 15 6.97019 15 6.95532 14.9998L8.52462 19.4958C8.52495 19.4967 8.52527 19.4976 8.52559 19.4985C8.63432 19.7994 8.92002 20 9.24018 20C9.65982 20 10 19.6599 10 19.2402V15.0356Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SpeakerphoneIcon;
