import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, styled, SvgIconProps } from '@mui/material';

import clsx from 'clsx';

import Button from '../../components/Button';
import { i18nNS } from '../../i18n';
import CheckmarkCircle2Icon from '../../icons/CheckmarkCircle2Icon';
import { generateClasses } from '../../utils/helpers';

const SkinTones = ['', 'p', 't', 'd'] as const;
export type SkinTonetype = typeof SkinTones[number];

export function isSkinTone(value: string): value is SkinTonetype {
  return SkinTones.includes(value as SkinTonetype);
}

export const skinToneClasses = {
  ...generateClasses('SkinTone', ['badge', 'badgeIcon', 'button', 'image']),
};

const BadgeIcon = styled((props: SvgIconProps) => (
  <CheckmarkCircle2Icon variant="filled" color="primary" {...props} />
))(({ theme }) => ({
  width: 20,
  height: 20,
}));

const Image = styled('img')(({ theme }) => ({
  width: 32,
  height: 32,
}));

interface Props {
  tone: SkinTonetype;
  isSelected: boolean;
  onSelect?: (tone: SkinTonetype) => any;
  className?: string;
  classes?: Partial<typeof skinToneClasses>;
}

const SkinTone = ({ tone: suffix, isSelected, onSelect, className, classes }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const handleItemClick: MouseEventHandler = (event) => {
    event.nativeEvent.preventDefault();
    return onSelect?.(suffix);
  };

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        isSelected ? <BadgeIcon className={clsx(skinToneClasses.badgeIcon, classes?.badgeIcon)} /> : null
      }
      className={clsx(skinToneClasses.badge, className, classes?.badge)}
    >
      <Button
        variant="text"
        onClick={handleItemClick}
        size="large"
        className={clsx(skinToneClasses.button, classes?.button)}
      >
        <Image
          className={clsx(skinToneClasses.image, classes?.image)}
          src={`https://s3.amazonaws.com/acadly-test/i/public/tone${suffix ? `-${suffix}` : ''}.png`}
          alt={t('skin_tone', { ns: i18nNS.COMMON })}
        />
      </Button>
    </Badge>
  );
};

export default SkinTone;
