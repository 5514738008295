import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { Props as ButtonProps } from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import CreditCardIcon from '../../../icons/CreditCardIcon';
import ChoosePaymentMethodDialog from '../ChoosePaymentMethodDialog';
import PaymentWallContextProvider from '../Context';
import CoursePaymentDrawer from '../CoursePaymentDrawer';
import usePayButtonVM from './vm';

interface Props extends ButtonProps {
  children?: ReactNode;
}

const Pay = ({ children, ...props }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);
  const {
    handleClickMakePayment,
    isChoosePaymentMethodDialogOpen,
    handleCloseChoosePaymentMethodDialog,
    isCoursePaymentDrawerOpen,
    handleClosePaymentDrawer,
  } = usePayButtonVM();

  return (
    <>
      <Button
        startIcon={<CreditCardIcon />}
        aria-label={t('click_to_make_payment', { ns: i18nNS.SHARED })}
        {...props}
        onClick={handleClickMakePayment}
      >
        {children ?? t('make_payment', { ns: i18nNS.COMMON })}
      </Button>
      <ChoosePaymentMethodDialog
        open={isChoosePaymentMethodDialogOpen}
        onClose={handleCloseChoosePaymentMethodDialog}
      />
      <CoursePaymentDrawer open={isCoursePaymentDrawerOpen} onClose={handleClosePaymentDrawer} />
    </>
  );
};

const PayButton = (props: Props) => {
  return (
    <PaymentWallContextProvider>
      <Pay {...props} />
    </PaymentWallContextProvider>
  );
};

export default PayButton;
