import { forwardRef, KeyboardEventHandler, MouseEventHandler, Ref } from 'react';

import { ClickAwayListener, Paper } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

import clsx from 'clsx';

import CalendarIcon from '../../../icons/CalendarIcon';
import { omit } from '../../../utils/helpers';
import TextFieldBase, { Props as TextFieldBaseProps } from '../TextFieldBase';
import { datepickerClasses, Root, StyledPickersDay } from './styles';
import useDatePickerVM, { DateValue, Props as VMProps } from './vm';

export interface Props<T extends DateValue>
  extends VMProps<T>,
    Pick<
      TextFieldBaseProps,
      | 'label'
      | 'placeholder'
      | 'required'
      | 'size'
      | 'error'
      | 'helperIcon'
      | 'helperText'
      | 'fullWidth'
      | 'disabled'
      | 'className'
    > {
  /** @default 'MM-dd-yyyy' */
  inputFormat?: string;
  disablePast?: boolean;
  disableFuture?: boolean;
  shouldDisableDate?: (date: Date) => boolean;
  shouldDisableMonth?: (month: Date) => boolean;
  shouldDisableYear?: (year: Date) => boolean;
  classes?: Partial<
    Record<'root' | 'datepickerRoot' | 'textFieldRoot', string> & TextFieldBaseProps['classes']
  >;
}

export type { DateValue };

const DatePicker = <T extends DateValue = Date>(
  {
    label,
    required = false,
    size = 'medium',
    inputFormat = 'MM/dd/yyyy',
    value: initialValue,
    minDate: _minDate,
    maxDate: _maxDate,
    placeholder,
    disabled,
    disablePast,
    disableFuture,
    shouldDisableDate,
    shouldDisableMonth,
    shouldDisableYear,
    helperIcon,
    helperText,
    error = false,
    fullWidth,
    className,
    classes,
    ...restProps
  }: Props<T>,
  ref: Ref<HTMLDivElement>
) => {
  const {
    value,
    setValue,
    minDate,
    maxDate,
    isCalendarOpen,
    handleCloseCalendar,
    handleToggleCalendar,
    handleClose,
    handleAccept,
    handleErrorChange,
  } = useDatePickerVM({
    ...restProps,
    value: initialValue,
    minDate: _minDate,
    maxDate: _maxDate,
  });

  const handleClickCalendarIcon: MouseEventHandler = (event) => {
    event.preventDefault();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key !== 'Escape') return;
    handleCloseCalendar();
  };

  return (
    <Root ref={ref} className={clsx(className, classes?.root)}>
      <MuiDatePicker
        /**
         * FIXME: masked input is not fully supported yet
         * @see https://github.com/mui/mui-x/issues/4487
         */
        disableMaskedInput
        inputFormat={inputFormat}
        value={value}
        onChange={setValue}
        onError={handleErrorChange}
        minDate={minDate}
        maxDate={maxDate}
        ignoreInvalidInputs
        open={isCalendarOpen}
        onClose={handleClose}
        onAccept={handleAccept}
        showDaysOutsideCurrentMonth
        disabled={disabled}
        disablePast={disablePast}
        disableFuture={disableFuture}
        shouldDisableDate={shouldDisableDate}
        shouldDisableMonth={shouldDisableMonth}
        shouldDisableYear={shouldDisableYear}
        components={{
          OpenPickerIcon: (props) => <CalendarIcon variant="filled" {...props} />,
          PaperContent: (props) => (
            <ClickAwayListener onClickAway={handleCloseCalendar}>
              <Paper {...props} onKeyDown={handleKeyDown} />
            </ClickAwayListener>
          ),
        }}
        OpenPickerButtonProps={{
          className: datepickerClasses.openPickerButton,
          onClick: handleClickCalendarIcon,
        }}
        className={clsx(classes?.datepickerRoot, {
          [datepickerClasses.calendarOpen]: isCalendarOpen,
          [datepickerClasses.error]: error,
        })}
        renderDay={(day, selectedDays, pickersDayProps) => <StyledPickersDay {...pickersDayProps} />}
        renderInput={({ ...params }) => {
          const {
            InputLabelProps,
            InputProps,
            /** ignored props */
            defaultValue,
            value,
            onInvalid,
            onKeyDown,
            onKeyUp,
            margin,
            ...restParams
          } = params;
          const { ref: inputBaseRef, startAdornment, endAdornment, className } = InputProps!;
          return (
            <TextFieldBase
              {...restParams}
              label={label}
              required={required}
              fullWidth={fullWidth}
              /** Props applied to Input tag */
              InputBaseRef={inputBaseRef as Ref<any>}
              inputProps={{ ...restParams.inputProps, placeholder }}
              classes={{
                ...(classes ? omit(classes, 'root', 'datepickerRoot', 'textFieldRoot') : {}),
                root: clsx(classes?.textFieldRoot),
                inputBase: clsx(className, classes?.inputBase),
              }}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              size={size}
              error={error}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              disabled={disabled}
              onClick={disabled ? undefined : handleToggleCalendar}
              onTouchEnd={handleToggleCalendar}
              /** Props applied to HelperText */
              helperIcon={helperIcon}
              helperText={helperText}
            />
          );
        }}
      />
    </Root>
  );
};

export default forwardRef(DatePicker) as <T extends DateValue = Date>(
  props: Props<T> & { ref?: Ref<HTMLDivElement> }
) => JSX.Element;
