import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';
import Button, { Props as ButtonProps } from '../Button';
import Spinner from '../Spinner';
import Typography from '../Typography';

const imagePreviewDialogClasses = generateClasses('ImagePreviewDialog', ['paper', 'closeButton']);

export const Dialog = styled((props: MuiDialogProps) => (
  <MuiDialog
    {...props}
    classes={{ ...props.classes, paper: clsx(imagePreviewDialogClasses.paper, props.classes?.paper) }}
  />
))(({ theme }) => ({
  [`& .${imagePreviewDialogClasses.paper}`]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    lineHeight: 0,
    maxWidth: `calc(100vw - ${theme.spacing(8)})`,
    maxHeight: `calc(100vh - ${theme.spacing(8)})`,
    height: '100%',
    width: '100%',
    pointerEvents: 'none',
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: 0,
}));

export const CloseButton = styled((props: ButtonProps) => (
  <Button {...props} className={clsx(imagePreviewDialogClasses.closeButton, props.className)} />
))(({ theme }) => ({
  [`&.${imagePreviewDialogClasses.closeButton}`]: {
    pointerEvents: 'all',
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.drawer,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export const Content = styled((props: StackProps) => (
  <Stack justifyContent="center" alignItems="center" gap={2} {...props} />
))(({ theme }) => ({
  position: 'relative',
  height: '100%',
}));

export const Image = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '100%',
  pointerEvents: 'all',
}));

export const Loader = styled(Spinner)(({ theme }) => ({
  pointerEvents: 'all',
}));

export const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));
