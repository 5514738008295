import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { coursePurchaseSuccessful } from '../../../../db/courses/actions';
import { selectCourse } from '../../../../db/courses/selectors';
import { CourseParams } from '../../../../pages/routes';
import { usePaymentWallContext } from '../../Context';
import { PaymentStep } from '../../types';
import { useCoursePaymentContext } from '../Context';

export default function usePaymentSuccessVM() {
  const dispatch = useDispatch();

  const { courseShortId } = useParams<CourseParams>();
  const course = useSelector(selectCourse(courseShortId));

  const { currentPaymentStep } = usePaymentWallContext();
  const { closeDrawer } = useCoursePaymentContext();

  const isVisible = currentPaymentStep.name === PaymentStep.PAYMENT_SUCCESS;

  const handleCloseDrawer = () => {
    if (!course) return;
    dispatch(coursePurchaseSuccessful({ courseId: course.id }));
    closeDrawer();
  };

  return { handleCloseDrawer, isVisible };
}
