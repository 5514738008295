import { AppBar as MuiAppBar, AppBarProps, styled } from '@mui/material';

export const AppBar = styled((props: AppBarProps) => (
  <MuiAppBar {...props} component="div" elevation={0} position="relative" />
))(({ theme }) => ({
  minHeight: 42,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
}));
