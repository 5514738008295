import { useContext } from 'react';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import Breadcrumb from '../components/Breadcrumb/index';

const useHomeBreadcrumbVM = () => {
  const { breadcrumbItems } = useContext(BreadcrumbContext);
  return { breadcrumbItems };
};

const HomeBreadcrumb = () => {
  const { breadcrumbItems } = useHomeBreadcrumbVM();

  return <Breadcrumb pageTitle={breadcrumbItems.home} />;
};

export default HomeBreadcrumb;
