import { useMemo } from 'react';

import { formatDistanceToNow } from '../../../../../../../../utils/datetime';

export interface Props {
  createdOn: UnixTime;
}

function useCommentCreatorVM({ createdOn }: Props) {
  const time = useMemo(() => formatDistanceToNow(createdOn), [createdOn]);
  return { time };
}

export default useCommentCreatorVM;
