import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material';

import Button from '../../../../components/Button';
import Typography, { Props as TypographyProps } from '../../../../components/Typography';
import { RecordInPersonAttendanceColor } from '../../../../styles/colors';
import { generateClasses } from '../../../../utils/helpers';

export const attendanceSectionClasses = generateClasses('RecordInPersonAttendance', [
  'highlighted',
  'stopAttendanceWrapper',
]);

export const Root = styled((props: StackProps) => (
  <Stack direction="column" alignItems="stretch" {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: theme.spacing(4, 0),
  padding: theme.spacing(5, 5, 0),
}));

export const Cell = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))(({ theme }) => ({
  [`&.${attendanceSectionClasses.highlighted}`]: {
    backgroundColor: RecordInPersonAttendanceColor.SAFFRON,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  [`&.${attendanceSectionClasses.stopAttendanceWrapper}`]: {
    backgroundColor: theme.palette.common.white,
    borderTop: `1px solid ${theme.palette.grey[700]}`,
    height: '48px',
    position: 'sticky',
    bottom: 0,
  },
}));

export const CellLabel = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({}));

export const CellValue = styled((props: TypographyProps) => (
  <Typography variant="paragraphBold" color="grey.800" {...props} />
))(({ theme }) => ({}));

export const FailureWrapper = styled((props: StackProps) => <Stack direction="column" gap={1} {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(2, 0),
  })
);

export const StyledButton = styled(Button)(({ theme }) => ({
  alignSelf: 'center',
  margin: theme.spacing(2, 0),
}));

export const ProcessFailureText = styled((props: TypographyProps) => (
  <Typography variant="paragraphBold" color="grey.800" {...props} />
))(({ theme }) => ({}));
