import { useTranslation } from 'react-i18next';

import { Search as SearchIcon } from '@mui/icons-material';

import TextField from '../../components/FormControls/TextField';
import { i18nNS } from '../../i18n';
import FaqAnswerDialog from './FaqAnswerDialog';
import FaqSection from './FaqSection';
import { faqClasses, Root } from './styles';
import useFaqVM from './vm';

const Faq = () => {
  const { t } = useTranslation([i18nNS.COURSES]);

  const {
    answerDialogData,
    faqSections,
    handleCloseAnswer,
    handleSearch,
    handleShowAnswer,
    handleShowNextQuestion,
    handleShowPreviousQuestion,
    search,
  } = useFaqVM();

  return (
    <Root>
      <div className={faqClasses.header}>
        <TextField
          fullWidth
          value={search}
          onChange={handleSearch}
          placeholder={t('search_in_faq', { ns: i18nNS.COURSES })}
          endAdornment={<SearchIcon />}
        />
      </div>
      <div className={faqClasses.content}>
        {faqSections.map((section, index) => (
          <FaqSection key={index} {...section} onSelectQuestion={handleShowAnswer(section)} />
        ))}
      </div>
      <FaqAnswerDialog
        data={answerDialogData}
        onClose={handleCloseAnswer}
        onNext={handleShowNextQuestion}
        onPrevious={handleShowPreviousQuestion}
      />
    </Root>
  );
};

export default Faq;
