import { forwardRef, Ref } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { i18nNS } from '../../../../i18n';
import { NotificationTypeButton, notificationTypeClasses } from './styles';
import useNotificationTypeVM, { Props as VMProps } from './vm';

interface Props<T extends FieldValues> extends VMProps<T> {
  icon: JSX.Element;
  notificationType: string;
  label: string;
  className?: string;
}

const NotificationType = <T extends FieldValues>(
  { control, name, defaultValue, icon, notificationType, label, className, ...props }: Props<T>,
  ref: Ref<HTMLButtonElement>
) => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  const { handleToggleNotify, shouldNotify } = useNotificationTypeVM({ control, name, defaultValue });

  return (
    <NotificationTypeButton
      {...props}
      ref={ref}
      variant="text"
      size="small"
      onClick={handleToggleNotify}
      className={clsx(
        {
          [notificationTypeClasses.on]: shouldNotify,
          [notificationTypeClasses.off]: !shouldNotify,
        },
        className
      )}
      aria-label={t(
        shouldNotify
          ? 'type_notifications_when_label_are_on_click_to_turn_it_off'
          : 'type_notifications_when_label_are_off_click_to_turn_it_on',
        { ns: i18nNS.SETTINGS, type: notificationType, label }
      )}
    >
      {icon}
    </NotificationTypeButton>
  );
};

export default forwardRef(NotificationType) as <T extends FieldValues>(
  props: Props<T> & { ref?: Ref<HTMLButtonElement> }
) => JSX.Element;
