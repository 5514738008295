import { useRef, useState } from 'react';

import { ChooseAvatarFormInstance } from '../ChooseAvatar/vm';

export interface Props {
  onClose: () => void;
}

const useChangeAvatarDrawerVM = ({ onClose }: Props) => {
  const chooseAvatarRef = useRef<ChooseAvatarFormInstance>(null);

  const [canSubmit, setCanSubmit] = useState(false);

  const getFormValidationStatus = (isValid: boolean) => {
    setCanSubmit(isValid);
  };

  const handleChooseAvatarDone = () => {
    onClose();
  };

  return {
    chooseAvatarRef,
    handleChooseAvatarDone,
    canSubmit,
    getFormValidationStatus,
  };
};

export default useChangeAvatarDrawerVM;
