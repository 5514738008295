import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Props as TextFieldProps } from '../TextField';

export interface Props extends DistributiveOmit<TextFieldProps, 'type' | 'classes'> {
  classes?: Partial<
    TextFieldProps['classes'] &
      Record<
        'inputAdornment' | 'togglePasswordVisibilityButton' | 'visibilityOnIcon' | 'visibilityOffIcon',
        string
      >
  >;
}

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
}));
