import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import Button from '../../components/Button';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import useVerificationAwaitedVM from './vm';

const VerificationAwaited = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH]);

  const { handleBack } = useVerificationAwaitedVM();

  return (
    <Layout
      title={t('verification_awaited', { ns: i18nNS.AUTH })}
      subtitle={t('we_have_not_been_able_to_verify_you_yet', { ns: i18nNS.AUTH })}
    >
      <Stack gap={6}>
        <Stack gap={4}>
          <Typography variant="paragraphRegular">
            {t('we_would_love_to_have_you_on_acadly_as_soon_as_possible', { ns: i18nNS.AUTH })}
          </Typography>
          <Typography variant="paragraphRegular">
            {t(
              'having_said_that_we_just_sent_a_reminder_to_the_verification_team_and_you_can_expect_a_mail_on_your_registered_email_id_welcoming_you_to_acadly_shortly',
              { ns: i18nNS.AUTH }
            )}
          </Typography>
          <Typography variant="paragraphRegular">
            {t('thank_you_for_your_patience', {
              ns: i18nNS.AUTH,
            })}
          </Typography>
        </Stack>
        <footer>
          <Button
            variant="outlined"
            size={isMobile ? 'large' : 'xLarge'}
            fullWidth={isMobile}
            onClick={handleBack}
            aria-label={t('click_to_go_back_to_login_page', { ns: i18nNS.AUTH })}
          >
            {t('back_to_login', { ns: i18nNS.AUTH })}
          </Button>
        </footer>
      </Stack>
    </Layout>
  );
};

export default VerificationAwaited;
