import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getAcadlyRouteByURL } from '../../pages/helpers';
import { ResourceParams } from '../../pages/routes';
import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { activityUpdatedEvent } from '../courses/pusher-events';
import { selectCourseUser, selectCurrentCourse } from '../courses/selectors';
import { ActivityType, AppContext, ClassIncharge, ResourceType } from '../shared/types';
import {
  createResource,
  deleteResource,
  editPublishedResource,
  editResource,
  fetchAllResources,
  fetchResourceAnalytics,
  incrementResourceViews,
  publishResource,
  resourceEdited,
  resourcePublished,
} from './actions';
import {
  createResource as createResourceAPI,
  deleteResource as deleteResourceAPI,
  editPublishedResource as editPublishedResourceAPI,
  editResource as editResourceAPI,
  getAllResources,
  getResourceAnalytics,
  incrementResourceViews as incrementResourceViewsAPI,
  publishResource as publishResourceAPI,
} from './api';
import { resourcePublishedEvent } from './pusher-events';
import { selectResource } from './selectors';

const log = Logger.create('db/resources');

function* fetchAllResourcesWorker(action: ReturnType<typeof fetchAllResources.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const currentCourse: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!currentCourse) return;

    /** skip unnecessary API call */
    if (currentCourse.activities.resources.total === 0) {
      yield put(
        fetchAllResources.success(requestId, { activityData: [], userData: { resources: {} }, currentUser })
      );
      return;
    }

    const response: YieldCallType<typeof getAllResources> = yield call(getAllResources);
    yield put(
      fetchAllResources.success(requestId, {
        ...response,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAllResources.failure(requestId));
  }
}

function* createResourceWorker(action: ReturnType<typeof createResource.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const response: YieldCallType<typeof createResourceAPI> = yield call(createResourceAPI, action.payload);
    yield put(
      createResource.success(requestId, {
        ...response,
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createResource.failure(requestId));
  }
}

function* editResourceWorker(action: ReturnType<typeof editResource.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    yield call(editResourceAPI, action.payload);
    yield put(
      editResource.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editResource.failure(requestId));
  }
}

function* activityUpdatedEventWorker(event: ReturnType<typeof activityUpdatedEvent>) {
  const { activityId } = event.payload;

  if (event.payload.activityType !== ActivityType.RESOURCE) return;

  yield put(resourceEdited(event.payload));

  const { route, match }: YieldCallType<typeof getAcadlyRouteByURL> = yield call(
    getAcadlyRouteByURL,
    window.location.pathname
  );

  if (!match?.params) return;

  const { resourceShortId } = match?.params as ResourceParams;
  if (!resourceShortId) return;

  if (route?.context !== AppContext.RESOURCE) return;
  if (!activityId.endsWith(resourceShortId)) return;

  // If user is already on a resource page mark updates as seen to hide edited tag on resource widget
  yield put(incrementResourceViews.request({ resourceId: activityId }));
}

function* deleteResourceWorker(action: ReturnType<typeof deleteResource.request>) {
  const { requestId } = action.meta;
  const { resourceId } = action.payload;
  try {
    const resource: YieldSelectorType<typeof selectResource> = yield select(selectResource(resourceId));
    if (!resource) return;
    yield call(deleteResourceAPI, action.payload);
    yield put(deleteResource.success(requestId, { resource }));
  } catch (error) {
    log.error(error);
    yield put(deleteResource.failure(requestId));
  }
}

function* publishResourceWorker(action: ReturnType<typeof publishResource.request>) {
  const { requestId } = action.meta;
  try {
    const { classId, resourceId, toBeDone, subscribeToComments } = action.payload;

    const resource: YieldSelectorType<typeof selectResource> = yield select(
      selectResource(action.payload.resourceId)
    );
    if (!resource) return;

    const getResourceTypeFields = () => {
      switch (resource.type) {
        case ResourceType.TEXT:
          return {
            resourceType: ResourceType.TEXT as const,
          };
        case ResourceType.FILE:
          return {
            resourceType: ResourceType.FILE as const,
            originalFileName: resource.originalName,
            fileName: resource.name,
          };
        case ResourceType.VIDEO:
          return {
            resourceType: ResourceType.VIDEO as const,
            videoId: resource.videoId,
          };
        case ResourceType.LINK:
          return {
            resourceType: ResourceType.LINK as const,
            url: resource.url,
          };
      }
    };

    const payload = {
      classId,
      resourceId,
      toBeDone,
      subscribeToComments,
      title: resource.title ?? '',
      description: resource.description,
      ...getResourceTypeFields(),
    };

    const response: YieldCallType<typeof publishResourceAPI> = yield call(publishResourceAPI, payload);
    yield put(publishResource.success(requestId, { ...response, ...payload }));
  } catch (error) {
    log.error(error);
    yield put(publishResource.failure(requestId));
  }
}

function* resourcePublishedEventWorker(event: ReturnType<typeof resourcePublishedEvent>) {
  const { resourceId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const resource: YieldSelectorType<typeof selectResource> = yield select(selectResource(resourceId));

  yield put(resourcePublished({ ...event.payload, currentUser, doesExist: Boolean(resource) }));
}

function* editPublishedResourceWorker(action: ReturnType<typeof editPublishedResource.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    yield call(editPublishedResourceAPI, action.payload);
    yield put(
      editPublishedResource.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editPublishedResource.failure(requestId));
  }
}

function* incrementResourceViewsWorker(action: ReturnType<typeof incrementResourceViews.request>) {
  const { requestId } = action.meta;
  const { resourceId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const resource: YieldSelectorType<typeof selectResource> = yield select(selectResource(resourceId));
    if (!resource) return;

    const isFirstAccess = resource.firstAccessedOn <= 0;

    const response: YieldCallType<typeof incrementResourceViewsAPI> = yield call(incrementResourceViewsAPI, {
      resourceId,
      isFirstAccess,
    });

    yield put(
      incrementResourceViews.success(requestId, {
        ...action.payload,
        ...response,
        courseId: resource.courseId,
        classId: resource.classId,
        toBeDone: resource.toBeDone,
        isFirstAccess,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(incrementResourceViews.failure(requestId));
  }
}

function* fetchResourceAnalyticsWorker(action: ReturnType<typeof fetchResourceAnalytics.request>) {
  const { requestId } = action.meta;
  try {
    const { accessedBy }: YieldCallType<typeof getResourceAnalytics> = yield call(
      getResourceAnalytics,
      action.payload
    );
    yield put(fetchResourceAnalytics.success(requestId, { accessedBy, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(fetchResourceAnalytics.failure(requestId));
  }
}

export default function* rootResourcesSaga() {
  try {
    yield takeLatest(fetchAllResources.request, fetchAllResourcesWorker);

    yield takeLatest(createResource.request, createResourceWorker);

    yield takeLatest(editResource.request, editResourceWorker);
    yield takeEveryPusher(activityUpdatedEvent, activityUpdatedEventWorker);

    yield takeLatest(deleteResource.request, deleteResourceWorker);

    yield takeLatest(publishResource.request, publishResourceWorker);
    yield takeEveryPusher(resourcePublishedEvent, resourcePublishedEventWorker);

    yield takeLatest(editPublishedResource.request, editPublishedResourceWorker);
    yield takeLatest(incrementResourceViews.request, incrementResourceViewsWorker);
    yield takeLatest(fetchResourceAnalytics.request, fetchResourceAnalyticsWorker);
  } catch (error) {
    log.error(error);
  }
}
