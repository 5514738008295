import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import { appActions } from '../../app/store';
import { getAcadlyRouteByURL } from '../../pages/helpers';
import routes from '../../pages/routes';
import { takeEveryPusher } from '../../pusher/subscribe';
import { omit } from '../../utils/helpers';
import Logger from '../../utils/logger';
import { fetchClassDetails } from '../classes/actions';
import { selectClass, selectFullClassId } from '../classes/selectors';
import { selectCourseUser } from '../courses/selectors';
import { ClassRole, CourseRole, CourseStudentUser } from '../shared/types';
import {
  addressHand,
  addressingHand,
  attendingRemotely,
  authorizeZoomApp,
  broadcastStarted,
  broadcastStopped,
  createZoomMeeting,
  deleteClassRecordings,
  endZoomMeetingBroadcast,
  fetchClassRecordings,
  fetchZoomMeetingCredentials,
  fetchZoomMeetingDetails,
  getZoomMeetingStatus,
  handLowered,
  handRaised,
  handResolved,
  lowerHand,
  markRemoteAttendance,
  meetingDestroyed,
  meetingEnded,
  meetingLaunched,
  meetingStarted,
  participantJoined,
  participantLeft,
  publishClassRecordings,
  raiseHand,
  readyToBroadcast,
  registerZoomMeetingParticipant,
  resolveHand,
  startRemoteAttendance,
  startZoomMeetingBroadcast,
  stopRemoteAttendance,
  unregisterZoomMeetingParticipant,
  zoomAuthenticated,
} from './actions';
import {
  addressHand as addressHandAPI,
  attendingRemotely as attendingRemotelyAPI,
  authorizeZoomApp as authorizeZoomAppAPI,
  createZoomMeeting as createZoomMeetingAPI,
  deleteClassRecordings as deleteClassRecordingsAPI,
  endZoomMeetingBroadcast as endZoomMeetingBroadcastAPI,
  getClassRecordings,
  getZoomMeetingCredentials,
  getZoomMeetingDetails,
  getZoomMeetingStatus as getZoomMeetingStatusAPI,
  lowerHand as lowerHandAPI,
  markRemoteAttendance as markRemoteAttendanceAPI,
  publishClassRecordings as publishClassRecordingsAPI,
  raiseHand as raiseHandAPI,
  registerZoomMeetingParticipant as registerZoomMeetingParticipantAPI,
  resolveHand as resolveHandAPI,
  startRemoteAttendance as startRemoteAttendanceAPI,
  startZoomMeetingBroadcast as startZoomMeetingBroadcastAPI,
  stopRemoteAttendance as stopRemoteAttendanceAPI,
  unregisterZoomMeetingParticipant as unregisterZoomMeetingParticipantAPI,
} from './api';
import {
  addressingHandEvent,
  anotherInchargeReadyToBroadcastEvent,
  broadcastStartedEvent,
  broadcastStoppedEvent,
  handLoweredEvent,
  handRaisedEvent,
  handResolvedEvent,
  meetingDestroyedEvent,
  meetingEndedEvent,
  meetingStartedEvent,
  participantJoinedEvent,
  participantLeftEvent,
  readyToBroadcastEvent,
  zoomAuthenticatedEvent,
} from './pusher-events';
import { selectOnlineMeeting } from './selectors';

const log = Logger.create('db/classes');

function* authorizeZoomAppWorker(action: ReturnType<typeof authorizeZoomApp.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof authorizeZoomAppAPI> = yield call(authorizeZoomAppAPI);
    yield put(authorizeZoomApp.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(authorizeZoomApp.failure(requestId));
  }
}

function* zoomAuthenticatedEventWorker(event: ReturnType<typeof zoomAuthenticatedEvent>) {
  try {
    const { match }: YieldCallType<typeof getAcadlyRouteByURL> = yield call(
      getAcadlyRouteByURL,
      window.location.pathname
    );

    switch (match?.path) {
      case routes.settings.path:
        yield put(authorizeZoomApp.request());
        break;
      case routes.activities.path:
        const match: YieldCallType<typeof routes.activities.match> = yield call(routes.activities.match);
        const { classShortId } = match?.params || {};
        if (!classShortId) return;

        const classId: YieldSelectorType<typeof selectFullClassId> = yield select(
          selectFullClassId(classShortId)
        );
        if (!classId) return;

        yield put(zoomAuthenticated({ ...event.payload, classId }));
        break;
      default:
        break;
    }
  } catch (error) {
    log.error(error);
  }
}

function* createZoomMeetingWorker(action: ReturnType<typeof createZoomMeeting.request>) {
  const { requestId } = action.meta;
  try {
    const onlineDetails: YieldCallType<typeof createZoomMeetingAPI> = yield call(
      createZoomMeetingAPI,
      action.payload
    );
    yield put(
      createZoomMeeting.success(requestId, {
        ...action.payload,
        onlineDetails,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createZoomMeeting.failure(requestId));
  }
}

function* getZoomMeetingStatusWorker(action: ReturnType<typeof getZoomMeetingStatus.request>) {
  const { requestId } = action.meta;
  try {
    yield call(getZoomMeetingStatusAPI, action.payload);
    yield put(getZoomMeetingStatus.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(getZoomMeetingStatus.failure(requestId));
  }
}

function* anotherInchargeReadyToBroadcastEventWorker(
  event: ReturnType<typeof anotherInchargeReadyToBroadcastEvent>
) {
  yield put(meetingLaunched(event.payload));
}

function* readyToBroadcastEventWorker(event: ReturnType<typeof readyToBroadcastEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  yield put(readyToBroadcast({ ...event.payload, currentUser }));
}

function* startZoomMeetingBroadcastWorker(action: ReturnType<typeof startZoomMeetingBroadcast.request>) {
  const { requestId } = action.meta;
  try {
    yield call(startZoomMeetingBroadcastAPI, action.payload);
    yield put(startZoomMeetingBroadcast.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(startZoomMeetingBroadcast.failure(requestId));
  }
}

function* broadcastStartedEventWorker(event: ReturnType<typeof broadcastStartedEvent>) {
  yield put(broadcastStarted(event.payload));
}

function* meetingStartedEventWorker(event: ReturnType<typeof meetingStartedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  if (event.payload.hostUserId === currentUser.userId) return;

  yield put(meetingStarted(event.payload));
}

function* fetchZoomMeetingDetailsWorker(action: ReturnType<typeof fetchZoomMeetingDetails.request>) {
  const { requestId } = action.meta;
  try {
    const cls: YieldSelectorType<typeof selectClass> = yield select(selectClass(action.payload.classId));
    if (!cls) return;

    const response: YieldCallType<typeof getZoomMeetingDetails> = yield call(
      getZoomMeetingDetails,
      action.payload
    );

    yield put(
      fetchZoomMeetingDetails.success(requestId, {
        ...action.payload,
        // isUserAuthenticated is sent from server, but its value is inconsistent, so it may be incorrect
        // server argues that it is part of response due to inheritance, so please ignore it.
        ...omit(response, 'isUserAuthenticated'),
        classRole: cls.myRole,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchZoomMeetingDetails.failure(requestId));
  }
}

function* fetchZoomMeetingCredentialsWorker(action: ReturnType<typeof fetchZoomMeetingCredentials.request>) {
  const { requestId } = action.meta;
  try {
    const onlineDetails: YieldSelectorType<typeof selectOnlineMeeting> = yield select(
      selectOnlineMeeting(action.payload.classId)
    );

    if (!onlineDetails) return;

    const response: YieldCallType<typeof getZoomMeetingCredentials> = yield call(getZoomMeetingCredentials, {
      ...action.payload,
      meetingId: onlineDetails.meetingId,
    });

    yield put(fetchZoomMeetingCredentials.success(requestId, { ...action.payload, ...response }));
  } catch (error) {
    log.error(error);
    yield put(fetchZoomMeetingCredentials.failure(requestId));
  }
}

function* registerZoomMeetingParticipantWorker(
  action: ReturnType<typeof registerZoomMeetingParticipant.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof registerZoomMeetingParticipantAPI> = yield call(
      registerZoomMeetingParticipantAPI,
      action.payload
    );
    yield put(registerZoomMeetingParticipant.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(registerZoomMeetingParticipant.failure(requestId));
  }
}

function* participantJoinedEventWorker(event: ReturnType<typeof participantJoinedEvent>) {
  if (event.payload.mustExpel) return;
  yield put(participantJoined(event.payload));
}

function* attendingRemotelyWorker(action: ReturnType<typeof attendingRemotely.request>) {
  const { requestId } = action.meta;
  try {
    yield call(attendingRemotelyAPI, action.payload);
    yield put(attendingRemotely.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(attendingRemotely.failure(requestId));
  }
}

function* raiseHandWorker(action: ReturnType<typeof raiseHand.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (currentUser?.role !== CourseRole.STUDENT) return;

    const response: YieldCallType<typeof raiseHandAPI> = yield call(raiseHandAPI, action.payload);
    yield put(
      raiseHand.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(raiseHand.failure(requestId));
  }
}

function* handRaisedEventWorker(event: ReturnType<typeof handRaisedEvent>) {
  yield put(handRaised(event.payload));
}

function* lowerHandWorker(action: ReturnType<typeof lowerHand.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (currentUser?.role !== CourseRole.STUDENT) return;

    const response: YieldCallType<typeof lowerHandAPI> = yield call(lowerHandAPI, action.payload);
    yield put(
      lowerHand.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(lowerHand.failure(requestId));
  }
}

function* handLoweredEventWorker(event: ReturnType<typeof handLoweredEvent>) {
  yield put(handLowered(event.payload));
}

function* addressHandWorker(action: ReturnType<typeof addressHand.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof addressHandAPI> = yield call(addressHandAPI, action.payload);
    if (response.isErrored) {
      yield put(appActions.setError(response.errorMessage));
      yield put(addressHand.failure(requestId));
    } else {
      yield put(addressHand.success(requestId, { ...action.payload, ...response }));
    }
  } catch (error) {
    log.error(error);
    yield put(addressHand.failure(requestId));
  }
}

function* addressingHandEventWorker(event: ReturnType<typeof addressingHandEvent>) {
  yield put(addressingHand(event.payload));
}

function* resolveHandWorker(action: ReturnType<typeof resolveHand.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof resolveHandAPI> = yield call(resolveHandAPI, action.payload);
    if (response.isErrored) {
      yield put(appActions.setError(response.errorMessage));
      yield put(resolveHand.failure(requestId));
    } else {
      yield put(resolveHand.success(requestId, { ...action.payload, ...response }));
    }
  } catch (error) {
    log.error(error);
    yield put(resolveHand.failure(requestId));
  }
}

function* handResolvedEventWorker(event: ReturnType<typeof handResolvedEvent>) {
  yield put(handResolved(event.payload));
}

function* unregisterZoomMeetingParticipantWorker(
  action: ReturnType<typeof unregisterZoomMeetingParticipant.request>
) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof unregisterZoomMeetingParticipantAPI> = yield call(
      unregisterZoomMeetingParticipantAPI,
      action.payload
    );
    yield put(unregisterZoomMeetingParticipant.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(unregisterZoomMeetingParticipant.failure(requestId));
  }
}

function* participantLeftEventWorker(event: ReturnType<typeof participantLeftEvent>) {
  yield put(participantLeft(event.payload));
}

function* endZoomMeetingBroadcastWorker(action: ReturnType<typeof endZoomMeetingBroadcast.request>) {
  const { requestId } = action.meta;
  try {
    yield call(endZoomMeetingBroadcastAPI, action.payload);
    yield put(endZoomMeetingBroadcast.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(endZoomMeetingBroadcast.failure(requestId));
  }
}

function* broadcastStoppedEventWorker(event: ReturnType<typeof broadcastStoppedEvent>) {
  yield put(broadcastStopped(event.payload));
}

function* meetingEndedEventWorker(event: ReturnType<typeof meetingEndedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const { classId } = event.payload;

  const cls: YieldSelectorType<typeof selectClass> = yield select(selectClass(classId));
  if (!cls) return;

  const onlineMeeting: YieldSelectorType<typeof selectOnlineMeeting> = yield select(
    selectOnlineMeeting(classId)
  );
  if (!onlineMeeting) return;

  if (onlineMeeting.hostUserId === currentUser.userId) return;

  if (cls.myRole === ClassRole.INCHARGE) {
    // fetching class details again to get correct meeting status
    // cannot use fetch online meeting details istead, because it throws error when there is no meeting
    yield putResolve(fetchClassDetails.request({ classId }));
  }

  yield put(meetingEnded({ ...event.payload, myRole: cls.myRole }));
}

function* meetingDestroyedEventWorker(event: ReturnType<typeof meetingDestroyedEvent>) {
  yield put(meetingDestroyed(event.payload));
}

function* fetchClassRecordingsWorker(action: ReturnType<typeof fetchClassRecordings.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getClassRecordings> = yield call(getClassRecordings, action.payload);
    yield put(
      fetchClassRecordings.success(requestId, {
        classId: action.payload.classId,
        ...response,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchClassRecordings.failure(requestId));
  }
}

function* publishClassRecordingsWorker(action: ReturnType<typeof publishClassRecordings.request>) {
  const { requestId } = action.meta;
  try {
    yield call(publishClassRecordingsAPI, action.payload);
    yield put(publishClassRecordings.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(publishClassRecordings.failure(requestId));
  }
}

function* deleteClassRecordingsWorker(action: ReturnType<typeof deleteClassRecordings.request>) {
  const { requestId } = action.meta;
  try {
    yield call(deleteClassRecordingsAPI, action.payload);
    yield put(deleteClassRecordings.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(deleteClassRecordings.failure(requestId));
  }
}

function* startRemoteAttendanceWorker(action: ReturnType<typeof startRemoteAttendance.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof startRemoteAttendanceAPI> = yield call(
      startRemoteAttendanceAPI,
      action.payload
    );
    yield put(startRemoteAttendance.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(startRemoteAttendance.failure(requestId));
  }
}

function* stopRemoteAttendanceWorker(action: ReturnType<typeof stopRemoteAttendance.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof stopRemoteAttendanceAPI> = yield call(
      stopRemoteAttendanceAPI,
      action.payload
    );
    yield put(stopRemoteAttendance.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(stopRemoteAttendance.failure(requestId));
  }
}

function* markRemoteAttendanceWorker(action: ReturnType<typeof markRemoteAttendance.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof markRemoteAttendanceAPI> = yield call(
      markRemoteAttendanceAPI,
      action.payload
    );
    yield put(markRemoteAttendance.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(markRemoteAttendance.failure(requestId));
  }
}

export default function* rootOnlineMeetingSagas() {
  try {
    yield takeLatest(authorizeZoomApp.request, authorizeZoomAppWorker);

    yield takeEveryPusher(zoomAuthenticatedEvent, zoomAuthenticatedEventWorker);

    yield takeLatest(createZoomMeeting.request, createZoomMeetingWorker);
    yield takeLatest(getZoomMeetingStatus.request, getZoomMeetingStatusWorker);

    yield takeEveryPusher(anotherInchargeReadyToBroadcastEvent, anotherInchargeReadyToBroadcastEventWorker);
    yield takeEveryPusher(readyToBroadcastEvent, readyToBroadcastEventWorker);

    yield takeLatest(startZoomMeetingBroadcast.request, startZoomMeetingBroadcastWorker);
    yield takeEveryPusher(broadcastStartedEvent, broadcastStartedEventWorker);
    yield takeEveryPusher(meetingStartedEvent, meetingStartedEventWorker);

    yield takeLatest(fetchZoomMeetingDetails.request, fetchZoomMeetingDetailsWorker);
    yield takeLatest(fetchZoomMeetingCredentials.request, fetchZoomMeetingCredentialsWorker);

    yield takeLatest(registerZoomMeetingParticipant.request, registerZoomMeetingParticipantWorker);
    yield takeEveryPusher(participantJoinedEvent, participantJoinedEventWorker);

    yield takeLatest(attendingRemotely.request, attendingRemotelyWorker);

    yield takeLatest(raiseHand.request, raiseHandWorker);
    yield takeEveryPusher(handRaisedEvent, handRaisedEventWorker);

    yield takeLatest(lowerHand.request, lowerHandWorker);
    yield takeEveryPusher(handLoweredEvent, handLoweredEventWorker);

    yield takeLatest(addressHand.request, addressHandWorker);
    yield takeEveryPusher(addressingHandEvent, addressingHandEventWorker);

    yield takeLatest(resolveHand.request, resolveHandWorker);
    yield takeEveryPusher(handResolvedEvent, handResolvedEventWorker);

    yield takeLatest(unregisterZoomMeetingParticipant.request, unregisterZoomMeetingParticipantWorker);
    yield takeEveryPusher(participantLeftEvent, participantLeftEventWorker);

    yield takeLatest(endZoomMeetingBroadcast.request, endZoomMeetingBroadcastWorker);
    yield takeEveryPusher(broadcastStoppedEvent, broadcastStoppedEventWorker);
    yield takeEveryPusher(meetingEndedEvent, meetingEndedEventWorker);
    yield takeEveryPusher(meetingDestroyedEvent, meetingDestroyedEventWorker);

    yield takeLatest(fetchClassRecordings.request, fetchClassRecordingsWorker);
    yield takeLatest(publishClassRecordings.request, publishClassRecordingsWorker);
    yield takeLatest(deleteClassRecordings.request, deleteClassRecordingsWorker);

    yield takeLatest(startRemoteAttendance.request, startRemoteAttendanceWorker);
    yield takeLatest(stopRemoteAttendance.request, stopRemoteAttendanceWorker);
    yield takeLatest(markRemoteAttendance.request, markRemoteAttendanceWorker);
  } catch (error) {
    log.error(error);
  }
}
