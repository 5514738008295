import { useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { joiResolver } from '@hookform/resolvers/joi';

import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { forgotPassword, resendTempPass, verifyTempPass } from '../store/actions';
import { selectAuthSession } from '../store/selectors';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  isResettingPassword?: boolean;
}

const useEmailVerificationVM = ({ isResettingPassword }: Props) => {
  const requestDispatch = useRequestDispatch();

  const { setScreen } = useContext(AuthContext);

  const session = useSelector(selectAuthSession)!;

  const formMethods = useForm<FormValues>({
    defaultValues: {
      verificationCode: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setError } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const response = await requestDispatch(verifyTempPass, { tempPass: data.verificationCode });
    if (!response.isSuccess) {
      setError('verificationCode', { message: response.message });
      return;
    }

    const { next } = response;

    switch (next) {
      case NextScreen.SET_NEW_PASSWORD:
        setScreen(AuthScreen.SET_PASSWORD);
        break;
      case NextScreen.RESET_PASSWORD:
        setScreen(AuthScreen.RESET_PASSWORD);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setScreen(AuthScreen.PASSWORD);
  };

  const handleResendVerificationCode = async () => {
    if (isResettingPassword) {
      await requestDispatch(forgotPassword);
    } else {
      await requestDispatch(resendTempPass);
    }
  };

  return {
    formMethods,
    onSubmit,
    handleBack,
    session,
    handleResendVerificationCode,
  };
};

export default useEmailVerificationVM;
