import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { selectAuthSession } from '../../auth/store/selectors';
import { useCurrentUserWithRole } from '../../courses/hooks';
import { selectCurrentParticipant, selectOnlineMeeting } from '../../db/online-meeting/selectors';
import routes, { ClassParams } from '../../pages/routes';
import { PipContainerMode } from '../../pip-container';
import { useEventListener, useWarnBeforeWindowClose } from '../../utils/hooks';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useZoomMeetingPipVM() {
  const {
    pipId: id,
    pipRef: ref,
    setPipMode,
    state: { isOpen, classId },
  } = useZoomMeetingContext();

  const currentUser = useCurrentUserWithRole();

  const session = useSelector(selectAuthSession);
  const participant = useSelector(selectCurrentParticipant(classId));
  const onlineDetails = useSelector(selectOnlineMeeting(classId));

  const match = useRouteMatch<ClassParams>({
    path: routes.activities.path,
    exact: true,
  });

  const { classShortId } = match?.params || {};

  const handlePipModeChange = (mode: PipContainerMode) => setPipMode(mode);

  useWarnBeforeWindowClose(() => {
    const isHost = onlineDetails?.hostUserId === currentUser.userId;
    if (!isHost) return false;

    const isBroadcasting = onlineDetails?.isBroadcasting;
    if (!isBroadcasting) return false;

    return true;
  }, [currentUser.userId, onlineDetails?.hostUserId, onlineDetails?.isBroadcasting]);

  useEventListener(
    'unload',
    useCallback(() => {
      const baseUrl = session?.cluster;
      if (!baseUrl) return;

      const closeUrl = onlineDetails?.closeUrl;
      if (!closeUrl) return;

      const payload = {
        zoomUserId: participant?.zoomUserId ?? 0,
      };

      const blob = new Blob([JSON.stringify(payload)], {
        type: 'application/json',
      });

      /**
       * auto close meeting if user leave the page by
       * refreshing or closing the tab or browser
       */
      navigator.sendBeacon(baseUrl + closeUrl, blob);
    }, [onlineDetails?.closeUrl, participant?.zoomUserId, session?.cluster])
  );

  useEffect(
    function autoChangePipMode() {
      if (!ref.current) return;
      const pip = ref.current;
      if (classShortId && classId.endsWith(classShortId)) {
        pip.requestModeChange(PipContainerMode.EMBEDDED);
      } else {
        pip.requestModeChange(PipContainerMode.PIP);
      }
    },
    [classId, classShortId, ref]
  );

  return { handlePipModeChange, id, isOpen, ref };
}
