import { useState } from 'react';

import { Box } from '@mui/material';

import VideoJSPlayer from '../components/VideoJSPlayer';

enum PlayerType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

const VIDEO_SOURCE =
  'https://acdly-dv-actn-rec.b-cdn.net/c/5eb9332a76885812b7af6d3e/cl/612ddfcf2e38b469499cf375/rec/6c650e47-e3be-4a34-994c-3a358a0cf672_video.MP4?token=KFH12fiNfJBUSjnwoEyB0jy61UwSAJ9IT4n7sXZFQoQ&expires=1664379099';
const AUDIO_SOURCE =
  'https://acdly-dv-actn-rec.b-cdn.net/c/5eb9332a76885812b7af6d3e/cl/612ddfcf2e38b469499cf375/rec/45c068db-a31e-43c1-9562-b5aebd654e00_audio.M4A?token=EK_zfEqK-eWk4Q_3ddZdXX2gHvVLYzHD44pcihtzh6M&expires=1664379069';

const VideoJSPlayground = () => {
  const [playerType, setPlayerType] = useState<PlayerType | null>(null);

  const handlePlayVideo = () => {
    setPlayerType(PlayerType.VIDEO);
  };

  const handlePlayAudio = () => {
    setPlayerType(PlayerType.AUDIO);
  };

  if (playerType) {
    return (
      <VideoJSPlayer
        sources={[
          {
            src: playerType === PlayerType.VIDEO ? VIDEO_SOURCE : AUDIO_SOURCE,
          },
        ]}
        autoplay
        playerType={playerType}
      />
    );
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      <button type="button" onClick={handlePlayVideo}>
        Play video using VideoJS
      </button>
      <button type="button" onClick={handlePlayAudio}>
        Play audio using VideoJS
      </button>
    </Box>
  );
};

export default VideoJSPlayground;
