import { RefObject } from 'react';

import { Editor } from 'slate';

import { TextFormat } from '../../types';
import RichTextEditorUtils from '../../utils';

export interface Props {
  editorRef: RefObject<Editor | null>;
}

const useTextFormattingButtonsVM = ({ editorRef }: Props) => {
  const onToggleMark = (mark: keyof TextFormat) => {
    if (!editorRef.current) return;
    return RichTextEditorUtils.toggleMark(editorRef.current, mark);
  };

  const getIsBoldActive = () => {
    if (!editorRef.current) return;
    return RichTextEditorUtils.isMarkActive(editorRef.current, 'bold');
  };

  const getIsItalicActive = () => {
    if (!editorRef.current) return;
    return RichTextEditorUtils.isMarkActive(editorRef.current, 'italic');
  };

  const getIsUnderlineActive = () => {
    if (!editorRef.current) return;
    return RichTextEditorUtils.isMarkActive(editorRef.current, 'underline');
  };

  return {
    isBoldActive: getIsBoldActive(),
    isItalicActive: getIsItalicActive(),
    isUnderlineActive: getIsUnderlineActive(),
    onToggleMark,
  };
};

export default useTextFormattingButtonsVM;
