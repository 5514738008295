import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentUserWithRole } from '../../../../../../../../../courses/hooks';
import {
  selectCommentMyReactions,
  selectContextData,
} from '../../../../../../../../../db/comments/selectors';
import { Comment } from '../../../../../../../../../db/comments/types';
import { CommentContext } from '../../../../../../../../../db/shared/types';
import { getCanAwardComment } from '../../../../../../../../helpers';

export interface Props {
  comment: Comment;
}

const useCommentAwardVM = ({ comment }: Props) => {
  const contextData = useSelector(selectContextData());

  const currentUser = useCurrentUserWithRole();

  const canAward = useMemo(() => {
    if (contextData?.type === CommentContext.DISCUSSION) {
      return getCanAwardComment({
        comment,
        context: contextData?.type,
        discussion: contextData.activity,
        currentUser,
      });
    }
    return getCanAwardComment({ comment, context: contextData?.type, discussion: undefined, currentUser });
  }, [comment, contextData?.type, contextData?.activity, currentUser]);

  const myReactions = useSelector(selectCommentMyReactions(comment.id));
  const awardPoints = myReactions?.awardPoints ?? 0;
  const hasAwarded = Boolean(awardPoints);

  return { canAward, hasAwarded, awardPoints };
};

export default useCommentAwardVM;
