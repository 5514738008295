import { Draft } from '@reduxjs/toolkit';

import { splitFileNameAndExtension } from '../../../utils/file';
import { API } from '../../shared/api-responses';
import { ResourceType } from '../../shared/types';
import { Resource } from '../types';

export function updateResourceContent({
  resource,
  content,
}: {
  resource: Draft<Resource>;
  content: API.ResourceContent;
}): Resource {
  switch (content.resourceType) {
    case ResourceType.FILE: {
      const { name, extension } = splitFileNameAndExtension(content.originalFileName, content.fileName);
      return {
        ...resource,
        type: ResourceType.FILE,
        name: content.fileName,
        extension,
        originalName: name,
      };
    }
    case ResourceType.LINK: {
      return {
        ...resource,
        type: ResourceType.LINK,
        url: content.url,
      };
    }
    case ResourceType.VIDEO: {
      return {
        ...resource,
        type: ResourceType.VIDEO,
        videoId: content.videoId,
      };
    }
    case ResourceType.TEXT:
    default: {
      return {
        ...resource,
        type: ResourceType.TEXT,
      };
    }
  }
}
