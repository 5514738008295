import { styled } from '@mui/material';

import Button from '../../../../components/Button';
import { buttonClasses } from '../../../../components/Button/styles';
import { generateClasses, pick } from '../../../../utils/helpers';
import { forDesktop } from '../../../../utils/media-queries';

export const hamburgerClasses = {
  ...pick(buttonClasses, 'text'),
  ...generateClasses('Hamburger', ['menuOpen']),
};

export const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${hamburgerClasses.text}`]: {
    color: theme.palette.primary[800],
    [forDesktop(theme)]: {
      backgroundColor: theme.palette.primary[50],
      [`&:hover, &.${buttonClasses.focusVisible}, &.${hamburgerClasses.menuOpen}`]: {
        backgroundColor: theme.palette.primary[100],
      },
    },
  },
}));
