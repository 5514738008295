import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../components/Breadcrumb/index';
import { selectCurrentCourse } from '../db/courses/selectors';

const useClassBreadcrumbVM = () => {
  const currentCourse = useSelector(selectCurrentCourse);
  const isCurrentCourseArchived = Boolean(currentCourse?.status.isArchived);

  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems, isCurrentCourseArchived };
};

const ClassBreadcrumb = () => {
  const { breadcrumbItems, isCurrentCourseArchived } = useClassBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.class}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      {isCurrentCourseArchived && <BreadcrumbItem item={breadcrumbItems.archived} />}
      <BreadcrumbItem item={breadcrumbItems.course} />
    </Breadcrumb>
  );
};

export default ClassBreadcrumb;
