import { useSelector } from 'react-redux';

import { cancelFacultyAccountVerification } from '../../auth/store/actions';
import { selectAuthSession, selectIsProvisionalAccount } from '../../auth/store/selectors';
import { useRequestDispatch } from '../../utils/request-actions';

export default function useHomeHeaderVM() {
  const requestDispatch = useRequestDispatch();
  const session = useSelector(selectAuthSession);
  const isProvisionalAccount = useSelector(selectIsProvisionalAccount);
  const university = session?.university.name || 'university';

  const handleCancelAccountVerification = async () => {
    await requestDispatch(cancelFacultyAccountVerification);
  };

  return { handleCancelAccountVerification, isProvisionalAccount, university };
}
