import { useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import FileTextIcon from '../../icons/FileTextIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';

const PrivacyPolicy = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  return (
    <ListItemButton
      component="a"
      href="https://www.acadly.com/privacy"
      target="_blank"
      rel="noopener noreferrer"
      aria-label={t('click_to_read_our_privacy_policy', { ns: i18nNS.SETTINGS })}
    >
      <ListItemIcon>
        <FileTextIcon />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6Regular" component="div">
          {t('privacy_policy', { ns: i18nNS.COMMON })}
        </Typography>
      </ListItemText>
      <ChevronRightIcon color="disabled" />
    </ListItemButton>
  );
};

export default PrivacyPolicy;
