import 'cropperjs/dist/cropper.css';

import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { CloudUpload } from '@mui/icons-material';
import { Stack } from '@mui/material';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { cropDialogClasses, Cropper, Preview } from './styles';
import useCropDialogVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  title: string;
  onCancel?: () => any;
}

const CropDialog = ({ open, title, onCancel, onResult }: Props) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { fileName, fileURI, fileInput, cropperRef, handleFileSelected, handleUpload } = useCropDialogVM({
    open,
    onResult,
  });

  const handleChooseFileMouseDown: MouseEventHandler = (event) => {
    event.preventDefault();
  };

  return (
    <Alert open={open} maxWidth="sm" fullWidth>
      <Alert.Header>{title}</Alert.Header>
      <Alert.Body>
        <Stack alignItems="center" gap={2}>
          {fileURI === '#' ? (
            <Typography variant="paragraphRegular">{t('no_file_selected', { ns: i18nNS.AUTH })}</Typography>
          ) : (
            <Cropper
              src={fileURI}
              dragMode="move"
              aspectRatio={1}
              guides={true}
              ref={cropperRef}
              minCropBoxHeight={64}
              minCropBoxWidth={64}
              preview={`.${cropDialogClasses.preview}`}
            />
          )}
          <Button
            component="label"
            aria-label={t('click_to_choose_a_file', { ns: i18nNS.AUTH })}
            onMouseDown={handleChooseFileMouseDown}
            startIcon={<CloudUpload />}
          >
            <input
              hidden
              multiple={false}
              ref={fileInput}
              type="file"
              accept="image/png,image/jpg,image/jpeg"
              onChange={handleFileSelected}
            />
            {t('choose_a_file', { ns: i18nNS.AUTH })}
          </Button>
          {fileURI !== '#' && (
            <>
              <Typography variant="h6Bold" component="div">
                {t('preview', { ns: i18nNS.GLOSSARY })}
              </Typography>
              {/** Preview component will be modified by react-cropper automatically */}
              <Preview className={cropDialogClasses.preview} />
            </>
          )}
        </Stack>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action
          variant="text"
          onClick={onCancel}
          aria-label={t('click_to_cancel_cropping', { ns: i18nNS.AUTH })}
        >
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action
          disabled={!fileName}
          onClick={handleUpload}
          aria-label={t('click_to_upload_cropped_image', { ns: i18nNS.AUTH })}
        >
          {t('upload', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default CropDialog;
