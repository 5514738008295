import { useState } from 'react';

const useChangeNameVM = () => {
  const [isChangeNameDrawerOpen, setIsChangeNameDrawerOpen] = useState(false);

  const handleCloseChangeNameDrawer = () => {
    setIsChangeNameDrawerOpen(false);
  };

  const handleChangeName = () => {
    setIsChangeNameDrawerOpen(true);
  };

  return { isChangeNameDrawerOpen, handleCloseChangeNameDrawer, handleChangeName };
};

export default useChangeNameVM;
