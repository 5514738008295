import { forwardRef, Ref } from 'react';

import { dialogContentClasses, Stack, StackProps, styled, Typography } from '@mui/material';

import Dialog from '../../../components/Dialog';

export const Root = styled(Dialog)(() => ({
  [`.${dialogContentClasses.root}`]: {
    padding: 0,
    minWidth: 200,
  },
}));

export const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  padding: theme.spacing(5, 5, 4),
}));

export const Wrapper = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => <Stack ref={ref} flex={1} gap={1} {...props} />)
)(({ theme }) => ({
  ':focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  overflow: 'hidden',
  padding: theme.spacing(4, 5, 5),
}));

export const ActionButton = styled('button')(({ theme }) => ({
  border: 'none',
  cursor: 'pointer',
  background: 'transparent',
  padding: 0,
  display: 'block',

  ':focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const ActionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  textAlign: 'left',
  maxWidth: `calc(50% - ${theme.spacing(4)}/2)`,

  '&.right': {
    textAlign: 'right',
  },

  ':focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(5),
  ':focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(-0.5),
  },
})) as typeof Typography;
