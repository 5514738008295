import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import Button from '../../components/Button';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import ChooseAvatar from '../Components/ChooseAvatar';
import { Layout } from './styles';
import useSetProfileAvatarVM from './vm';

const SetProfileAvatar = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { chooseAvatarRef, handleChooseAvatarDone, authCredentials, canSubmit, getFormValidationStatus } =
    useSetProfileAvatarVM();

  return (
    <Layout title={t('choose_your_avatar', { ns: i18nNS.AUTH })}>
      <ChooseAvatar
        ref={chooseAvatarRef}
        onDone={handleChooseAvatarDone}
        getFormValidationStatus={getFormValidationStatus}
        token={authCredentials?.token}
      />
      <Stack component="footer">
        <Button
          variant="contained"
          size={isMobile ? 'large' : 'xLarge'}
          disabled={!canSubmit}
          onClick={chooseAvatarRef.current?.submit}
          aria-label={t('click_to_save_avatar', { ns: i18nNS.AUTH })}
        >
          {t('done', { ns: i18nNS.GLOSSARY })}
        </Button>
      </Stack>
    </Layout>
  );
};

export default SetProfileAvatar;
