import ReactCropper from 'react-cropper';

import { styled } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const cropDialogClasses = {
  ...generateClasses('CropDialog', ['preview']),
};

export const Cropper = styled(ReactCropper)(({ theme }) => ({
  height: 300,
  width: '100%',
}));

export const Preview = styled('div')(({ theme }) => ({
  width: 120,
  height: 120,
  overflow: 'hidden',
}));
