export enum TipContext {
  HOME = 'courseHome',
  COURSE = 'courseMain',
  COURSE_ANALYTICS = 'courseAnalytics',
  ASSIGNMENT = 'assignmentMain',
  ASSIGNMENT_ATTEMPT = 'assignmentAttempt',
  CLASS = 'classMain',
  QUIZ = 'quizMain',
  QUIZ_ATTEMPT = 'quizAttempt',
  POLL = 'pollMain',
  DISCUSSION = 'discussionMain',
  QUERY = 'queryMain',
  ACTIVITY_ANALYTICS = 'activityAnalytics',
}
