import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyTimePicker, { Props as AcadlyTimePickerProps } from '../FormControls/Timepicker';

type VMProps<T extends FieldValues> = UseControllerProps<T> & {
  onChange?: AcadlyTimePickerProps['onChange'];
};

const useTimepickerVM = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  onChange: _onChange,
}: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });

  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (newValue: string | null) => {
    onChange(newValue);
    _onChange?.(newValue);
  };

  return { error, handleChange, inputRef, onBlur, value };
};

export type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyTimePickerProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const TimePicker = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  helperText,
  onChange,
  ...props
}: Props<T>) => {
  const { error, handleChange, inputRef, onBlur, value } = useTimepickerVM({
    control,
    name,
    defaultValue,
    onChange,
  });

  return (
    <AcadlyTimePicker
      {...props}
      ref={inputRef}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default TimePicker;
