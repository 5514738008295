import { Editor, Element as SlateElement, Transforms } from 'slate';

import { ElementNodeType, TextFormat } from '../types';

function isBlockActive(editor: Editor, nodeType: ElementNodeType) {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && SlateElement.isElement(node) && node.type === nodeType,
    })
  );

  return !!match;
}

function toggleBlock(editor: Editor, nodeType: ElementNodeType) {
  const isActive = isBlockActive(editor, nodeType);
  Transforms.setNodes<SlateElement>(editor, {
    type: isActive ? 'paragraph' : nodeType,
  });
}

function isMarkActive(editor: Editor, format: keyof TextFormat) {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

function toggleMark(editor: Editor, format: keyof TextFormat) {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}

const RichTextEditorUtils = {
  ...Editor,

  isBlockActive,
  toggleBlock,

  isMarkActive,
  toggleMark,
};

export default RichTextEditorUtils;
