import { FocusEvent, KeyboardEvent, Ref, useEffect, useState } from 'react';

import { useForwardedRef } from '../../../utils/hooks';

export interface UseListVmProps {
  numItems: number;
  onEnter?: (activeDescendentEl: Element) => void;
  getActiveItemId: (focusIndex: number) => string | undefined;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  initialFocusIndex?: number;
}

const useAccessibleList = (
  { numItems, onEnter, getActiveItemId, direction = 'column', initialFocusIndex = -1 }: UseListVmProps,
  ref: Ref<HTMLUListElement>
) => {
  const listRef = useForwardedRef(ref);

  const [focusIndex, setFocusIndex] = useState(-1);
  const [lastfocusIndex, setLastFocusIndex] = useState(-1);

  const handleFocus = (event: FocusEvent<HTMLUListElement>) => {
    if (event.target !== listRef.current) return;
    if (lastfocusIndex >= 0) {
      setFocusIndex(lastfocusIndex);
      return;
    }
    if (initialFocusIndex >= 0) {
      setFocusIndex(initialFocusIndex);
      return;
    }
    setFocusIndex(0);
  };

  const handleBlur = () => {
    setFocusIndex(-1);
  };

  const handleFocusIndexIncrement = () => {
    setFocusIndex((prevIndex) => (prevIndex === numItems - 1 ? 0 : prevIndex + 1));
  };
  const handleFocusIndexDecrement = () => {
    setFocusIndex((prevIndex) => (prevIndex === 0 ? numItems - 1 : prevIndex - 1));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLUListElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        setLastFocusIndex(-1);
        if (direction === 'row' || direction === 'row-reverse') break;
        if (direction === 'column') {
          handleFocusIndexDecrement();
          break;
        }
        if (direction === 'column-reverse') {
          handleFocusIndexIncrement();
          break;
        }
        break;
      case 'ArrowDown':
        setLastFocusIndex(-1);
        if (direction === 'row' || direction === 'row-reverse') break;
        if (direction === 'column') {
          handleFocusIndexIncrement();
          break;
        }
        if (direction === 'column-reverse') {
          handleFocusIndexDecrement();
          break;
        }
        break;
      case 'ArrowLeft':
        setLastFocusIndex(-1);
        if (direction === 'column' || direction === 'column-reverse') break;
        if (direction === 'row') {
          handleFocusIndexDecrement();
          break;
        }

        if (direction === 'row-reverse') {
          handleFocusIndexIncrement();
          break;
        }
        break;
      case 'ArrowRight':
        setLastFocusIndex(-1);
        if (direction === 'column' || direction === 'column-reverse') break;

        if (direction === 'row') {
          handleFocusIndexIncrement();
          break;
        }

        if (direction === 'row-reverse') {
          handleFocusIndexDecrement();
          break;
        }
        break;
      case 'Tab':
        setLastFocusIndex(-1);
        break;
      case 'Enter':
        setLastFocusIndex(focusIndex);
        const activeItemId = getActiveItemId(focusIndex);
        if (!activeItemId) break;

        const activeDescendentEl = document.querySelector(`#${activeItemId}`);
        if (!activeDescendentEl) break;

        if (onEnter) {
          onEnter(activeDescendentEl);
          break;
        }

        if (!('click' in activeDescendentEl)) break;
        if (typeof activeDescendentEl.click !== 'function') break;
        activeDescendentEl.click();
        break;
      default:
        break;
    }
  };

  /**
   * move focus to previous item when last item is removed
   */
  useEffect(
    function onNumItemsChange() {
      if (lastfocusIndex > numItems - 1) {
        setFocusIndex(numItems - 1);
      }
    },
    [lastfocusIndex, numItems]
  );

  return {
    listRef,
    focusIndex,
    handleFocus,
    handleBlur,
    handleKeyDown,
  };
};
export default useAccessibleList;
