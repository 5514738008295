import { useEffect, useRef, useState } from 'react';
import Youtube, { YouTubeEvent, YouTubePlayer, YouTubeProps } from 'react-youtube';

import { debounceTime, fromEvent, map, Subject } from 'rxjs';

const useYoutubePlayerVM = () => {
  const isPlaying = useRef(false);
  const playerRef = useRef<YouTubePlayer>(null);
  const ytStateChange$ = useRef(new Subject<number>());

  const [showOverlay, setShowOverlay] = useState(true);

  const handlePlayerReady: YouTubeProps['onReady'] = (event) => {
    const player = event.target;

    playerRef.current = player;

    fromEvent<YouTubeEvent<number>>(player, 'onStateChange')
      .pipe(map(({ data }) => data))
      .subscribe(ytStateChange$.current);
  };

  const handlePlay = () => {
    if (!playerRef.current) return;
    playerRef.current.playVideo();
    isPlaying.current = true;
    setShowOverlay(false);
  };

  useEffect(function subscribeToYtStateChange() {
    const sub = ytStateChange$.current.pipe(debounceTime(1000)).subscribe((state) => {
      const isStopped = [Youtube.PlayerState.PAUSED, Youtube.PlayerState.ENDED].includes(state);

      if (isStopped) {
        setShowOverlay(true);
        isPlaying.current = false;
        return;
      }

      if (!isPlaying.current && state === Youtube.PlayerState.PLAYING) {
        playerRef.current.stopVideo();
      }
    });

    return () => sub.unsubscribe();
  }, []);

  return {
    showOverlay,
    handlePlayerReady,
    handlePlay,
  };
};

export default useYoutubePlayerVM;
