import { SyntheticEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyDropdown, { Props as AcadlyCountryPickerProps } from '../FormControls/CountryPicker';
import { CountryType } from '../FormControls/CountryPicker/types';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useCountryPickerVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });

  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (e: SyntheticEvent<Element, Event>, value: CountryType) => onChange(value);

  return { error, handleChange, inputRef, onBlur, value };
};

type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyCountryPickerProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const CountryPicker = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  helperText,
  ...props
}: Props<T>) => {
  const { error, handleChange, inputRef, onBlur, value } = useCountryPickerVM({
    control,
    name,
    defaultValue,
  });

  return (
    <AcadlyDropdown
      {...props}
      ref={inputRef}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default CountryPicker;
