import { Divider, Stack, StackProps } from '@mui/material';

interface Props extends StackProps {
  /**
   * If `true`, then show divider abover footer
   * @default false
   */
  withDivider?: boolean;
}

const Footer = ({ withDivider, ...props }: Props) => {
  return (
    <>
      {withDivider && <Divider />}
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        gap={3}
        pt={4}
        paddingX={5}
        pb={5}
        component="footer"
        {...props}
      />
    </>
  );
};

export default Footer;
