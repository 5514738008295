import { useMemo, useState } from 'react';

import { formatTimeFromUnix } from '../../../utils/datetime';
import { randomStr } from '../../../utils/helpers';

export interface Props {
  value: string | null;
  onChange: (newValue: string | null) => void;
  onCommit?: (newValue: string | null) => void;
  onCancel?: () => void;
  onBlur?: () => void;
}

const useTimepickerVM = ({ value, onChange, onCommit, onCancel, onBlur }: Props) => {
  const { okayId, cancelId } = useMemo(
    () => ({ okayId: `okay-id-${randomStr(6)}`, cancelId: `cancel-id-${randomStr(6)}` }),
    []
  );

  const [isTimeDialOpen, setIsTimeDialOpen] = useState(false);

  const handleOpenTimeDial = () => {
    setIsTimeDialOpen(true);
  };

  const handleCloseTimeDial = () => {
    setIsTimeDialOpen(false);
  };

  const handleChange = (newValue: Date | null) => {
    try {
      const selectedTime = newValue ? formatTimeFromUnix(newValue, '24h') : null;
      onChange(selectedTime);
    } catch (error) {
      // swallow parsing errors
    }
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLElement;

    if (target.id === okayId || target.querySelector(`#${okayId}`)) {
      onCommit?.(value);
      handleCloseTimeDial();
      onBlur?.();
    }
    if (target.id === cancelId || target.querySelector(`#${cancelId}`)) {
      onCancel?.();
      handleCloseTimeDial();
      onBlur?.();
    }
  };

  return {
    okayId,
    cancelId,
    isTimeDialOpen,
    handleOpenTimeDial,
    handleCloseTimeDial,
    handleChange,
    handleClick,
  };
};

export default useTimepickerVM;
