import { BaseEditor, BaseSelection, Descendant } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { FormulaEditor, FormulaElement } from './plugins/formula/types';
import { ImageEditor, ImageElement } from './plugins/image/types';

export const Hotkeys = Object.freeze({
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
} as const);

export interface TextFormat {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export interface TextNode extends TextFormat {
  type: 'text';
  text: string;
}

export interface ParagraphElement {
  type: 'paragraph';
  children: Descendant[];
}

export type ElementNode = ParagraphElement | ImageElement | FormulaElement;
export type ElementNodeType = ElementNode['type'];

export type RichTextNode = TextNode | ElementNode;

interface EditorAddOns {
  /** Clears the content of the editor */
  clear(): void;
  /** Gets the content of the rich text editor */
  getValue(): RichText;
  /** Saves current cursor position or selection in editor */
  saveSelection(): BaseSelection;
  /** Restores last saved cursor position or selection in editor */
  restoreSelection(selection?: BaseSelection): void;
}

type ExtendedEditor = BaseEditor & EditorAddOns & ReactEditor & HistoryEditor & ImageEditor & FormulaEditor;

declare module 'slate' {
  interface CustomTypes {
    Editor: ExtendedEditor;
    Element: ElementNode;
    Text: TextNode;
  }
}
