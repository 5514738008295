import React from 'react';

import { Box, colors, useTheme } from '@mui/material';
import { Skeleton } from '@mui/material';

interface Props {
  className?: string;
}

const CourseScheduleItemSkeleton = ({ className }: Props) => {
  const theme = useTheme();
  return (
    <Box
      width="100%"
      paddingX={2}
      paddingY={1}
      bgcolor={theme.palette.mode === 'dark' ? colors.grey[800] : theme.palette.common.white}
      display="grid"
      gridTemplateColumns="1fr auto"
      gridTemplateRows="auto auto"
      alignItems="center"
      className={className}
    >
      <Skeleton variant="text" height={24} width="50%" sx={{ gridColumn: '1', gridRow: '1' }} />
      <Skeleton variant="text" height={20} width="35%" sx={{ gridColumn: '1', gridRow: '2' }} />
      <Skeleton variant="circular" height={40} width={40} sx={{ gridColumn: '2', gridRow: '1 / 3' }} />
    </Box>
  );
};

export default CourseScheduleItemSkeleton;
