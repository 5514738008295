import urls from '../../app/urls';
import { format } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { CreateQueryPayload, QueryId } from './types';

export async function getAllQueries() {
  const { data } = await request.get<API.GetAllQueriesResponse>(urls.getAllQueries);
  return data;
}

export async function createQuery({ isAnonymous, ...query }: CreateQueryPayload) {
  const payload: API.CreateQueryRequest = { ...query, isAnon: isAnonymous ? 1 : 0 };
  const { data } = await request.post<API.CreateQueryResponse<'anonymous' | 'nonAnonymous'>>(
    urls.createQuery,
    payload
  );
  return { isAnonymous, ...data };
}

export async function approveQuery(payload: API.ApproveQueryRequest) {
  await request.post(urls.approveQuery, payload);
}

export async function deleteQuery(payload: API.DeleteQueryRequest) {
  await request.post(urls.deleteQuery, payload);
}

export async function markQueryAsViewed({ queryId, currentTime }: QueryId & { currentTime: UnixTime }) {
  const payload: API.MarkQueryAsReadRequest = {
    queryId,
    firstAccess: 1,
    localTime: format(currentTime, "yyyyMMdd'T'HH:mm"),
  };
  await request.post(urls.markQueryAsViewed, payload);
}

export async function upvoteQuery({ queryId }: QueryId) {
  await request.get(urls.upvoteQuery(queryId));
}

export async function hideQuery(payload: API.HideQueryRequest) {
  await request.post(urls.hideQuery, payload);
}

export async function closeQuery(payload: API.CloseQueryRequest) {
  await request.post(urls.closeQuery, payload);
}
