import { HTMLAttributes, ReactNode, useEffect } from 'react';

import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import DiscussionPanel from './DiscussionPanel';
import PageBody from './PageBody';

const pageClasses = generateClasses('Page', ['body']);

const Root = styled('div')(() => ({
  display: 'flex',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',

  [`.${pageClasses.body}`]: {
    flex: 1,
  },
}));

interface VMProps {
  title?: string;
}

const usePageVM = ({ title }: VMProps) => {
  useEffect(() => {
    if (!title) return;
    document.title = title;
  }, [title]);
};

interface Props extends VMProps, HTMLAttributes<HTMLDivElement> {
  title?: string;
  children?: ReactNode;
}

const Page = ({ children, title, ...props }: Props) => {
  usePageVM({ title });
  return (
    <Root {...props}>
      <PageBody className={pageClasses.body}>{children}</PageBody>
      <DiscussionPanel />
    </Root>
  );
};

export default Page;
