import { useSelector } from 'react-redux';

import { selectComments, selectIsFetchingComments } from '../../../../db/comments/selectors';

const useCommentListVM = () => {
  const isFetchingComments = useSelector(selectIsFetchingComments);
  const comments = useSelector(selectComments);

  return { isFetchingComments, comments };
};

export default useCommentListVM;
