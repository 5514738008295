import { toast, ToastOptions } from 'react-toastify';

import clsx from 'clsx';

import { PageVisibility } from '../../utils/page-visibility';
import { NOTIFICATION_SOUND_FILE_URL } from '../constants';
import NotificationContent from './NotificationContent';
import { toastClasses } from './styles';

const notificationSound = new Audio(NOTIFICATION_SOUND_FILE_URL);

interface NotifyParams {
  id: string;
  avatar?: string;
  content: string;
  options: ToastOptions;
  playSound?: boolean;
}

const notify = ({ id, avatar, content, options, playSound }: NotifyParams) => {
  toast(<NotificationContent avatar={avatar} content={content} />, {
    ...options,
    toastId: id,
    className: clsx(toastClasses.toast, { [toastClasses.hasAvatar]: Boolean(avatar) }),
  });

  if (!PageVisibility.isHidden) return;

  if (Notification.permission !== 'granted') {
    console.warn('Notification permision not granted!');
    return;
  }

  const notification = new Notification('Acadly', { tag: id, icon: avatar, body: content });

  if (playSound) {
    notificationSound.play();
  }

  notification.onclick = function (this: Notification, event: Event) {
    this.close();
    window.focus();
    options.onClick?.(event as unknown as React.MouseEvent);
  };
};

notify.dismiss = toast.dismiss;

export { notify };
