import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import AlertButton, { Props as AlertButtonProps } from '../../components/AlertButton';
import Button, { Props as ButtonProps } from '../../components/Button';
import Checkbox, { checkboxClasses } from '../../components/FormControls/Checkbox';
import ChevronRightIcon from '../../icons/ChevronRightIcon';

export const NextButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="contained" endIcon={<ChevronRightIcon />} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 120,
}));

export const NextAlertButton = styled(
  forwardRef((props: AlertButtonProps, ref: Ref<HTMLButtonElement>) => (
    <AlertButton variant="contained" endIcon={<ChevronRightIcon />} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 120,
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  alignItems: 'flex-start',
  [`& .${checkboxClasses.checkbox}`]: {
    paddingTop: 0,
  },
}));
