import { PaletteMode } from '@mui/material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import cookie from 'js-cookie';

import { logout } from '../auth/store/actions';
import { APP_STATE_KEY } from './constants';

export const THEME_MODE_COOKIE = 'themeMode';

// TODO: use react's context API instead of redux
export interface AppState {
  error: string;
  themeMode: PaletteMode;
}

const prefersDarkMode = window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;
const preferredThemeMode: PaletteMode =
  (cookie.get(THEME_MODE_COOKIE) as PaletteMode) || (prefersDarkMode ? 'dark' : 'light');

const initialState: AppState = {
  error: '',
  themeMode: preferredThemeMode,
};

const { actions, reducer } = createSlice({
  name: APP_STATE_KEY,
  initialState,
  reducers: {
    setThemeMode(state, action: PayloadAction<PaletteMode>) {
      state.themeMode = action.payload;
      cookie.set(THEME_MODE_COOKIE, action.payload, { expires: 365 });
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(logout, (state, action) => {
      if (action.payload?.message) {
        state.error = action.payload.message;
      }
    });
  },
});

export const appReducer = reducer;

export const appActions = actions;
