const en = {
  showing_all_queries: 'Showing all queries',
  no_queries_have_been_created_in_this_course: 'No queries have been created in this course',

  asked_by_anonymous: 'Asked by Anonymous',
  query_asked_by_name: 'Query asked by {{name}}',
  anonymous_queries_will_have_to_be_approved_by_the_course_instructor_first_before_they_show_up_in_the_class:
    'Anonymous queries will have to be approved by the course instructor first before they show up in the class.',
  posting_a_new_query: 'Posting a new query',
  please_make_sure_your_query_has_an_appropriate_title_and_description:
    'Please make sure your query has an appropriate title and description',
  yes_i_m_sure: "Yes, i'm sure",
  you_wont_be_able_to_edit_delete_it_once_it_is_posted:
    "You won't be able to edit/delete it once it is posted.",
  are_you_sure_you_want_to_ask_the_query_in_its_current_form:
    'Are you sure you want to ask the query in its current form?',
  after_your_anonymous_query_is_created_it_will_show_up_on_the_class_page_only_after_a_course_team_member_approves_it:
    'After your anonymous query is created, it will show up on the class page only after a course team member approves it',
  click_to_create_query: 'click_to_create_query',
  ask_query: 'Ask Query',
  this_query_has_been_hidden_by_professor: 'This query has been hidden by {{professor}}',

  delete_query: 'Delete query',
  hide_query: 'Hide query',
  approve_query: 'Approve query',
  close_this_query: 'Close this query',
  i_have_the_same_query: 'I have the same query',
  activity_query_for_the_class_on_date_at_time:
    '{{activity}} query for the class on {{scheduledDate}} at {{scheduledTime}}',
  are_you_sure_you_want_to_delete_this_query: 'Are you sure you want to delete this query?',
  are_you_sure_you_want_to_hide_this_query: 'Are you sure you want to hide this query?',
  are_you_sure_you_want_to_approve_this_query: 'Are you sure you want to approve this query?',

  students_can_interact_with_this_query_only_after_it_has_been_approved_by_a_member_of_the_course_team:
    'Students can interact with this query only after it has been approved by a member of the course team',
  to_post_a_comment_on_this_query_please_tap_on_the_icon_on_the_top_right_corner_of_the_screen:
    'To post a comment on this query please tap on the icon on the top right corner of the screen.',

  N_student_has_this_query_one: '{{count}} student has this query',
  N_student_has_this_query_other: '{{count}} students have this query',
  no_other_student_has_the_same_query: 'No other student has the same query',
  N_other_student_has_the_same_query_one: '{{count}} other student has the same query',
  N_other_student_has_the_same_query_other: '{{count}} other students have the same query',
  you_and_N_other_student_has_the_same_query_one: 'You and {{count}} other student has the same query',
  you_and_N_other_student_has_the_same_query_other: 'You and {{count}} other students have the same query',
  only_you_have_this_query: 'Only you have this query',

  click_to_delete_query: 'Click to delete query',
  click_to_hide_query: 'Click to hide query',
  click_to_approve_query: 'Click to approve query',
  click_to_close_query: 'Click to close query',
  click_to_raise_same_query_you_have: 'Click to raise same query you have',

  /** comments panel */
  query_discussion: 'Query Discussion',
  export_query_discussion_data_as: 'Export query discussion data as',
  you_can_no_longer_comment_here_since_the_author_of_this_query_has_marked_it_as_closed:
    'You can no longer comment here, since the author of this query has marked it as "Closed"',

  /** onboarding tips */
  if_this_is_an_appropriate_query_use_this_button_to_approve_it_once_approved_the_course_members_will_be_able_to_access_the_query:
    'If this is an appropriate query, use this button to approve it. Once approved the course members will be able to access the query',
  if_this_query_is_inappropriate_use_the_delete_button_to_discard_it_removed_queries_cannot_be_retrieved_later:
    'If this query is inappropriate, use the "Delete" button to discard it. Removed queries cannot be retrieved later.',
  if_this_query_is_inappropriate_you_can_hide_it_from_the_course_team_by_using_the_hide_button:
    'If this query is inappropriate, you can hide it from the course team by using the "Hide" button',
};

export default en;
