import { t } from 'i18next';

import { i18nNS } from '../../../i18n';
import ClockIcon from '../../../icons/ClockIcon';
import { formatDistanceToNow } from '../../../utils/datetime';
import { StyledNote } from './styles';
import useDiscussionWordCloudHeaderVM from './vm';

const DiscussionWordCloudHeader = () => {
  const { discussion } = useDiscussionWordCloudHeaderVM();

  if (!discussion) return null;
  if (!discussion.wordCloud.data) return null;

  return (
    <StyledNote icon={<ClockIcon variant="filled" aria-hidden />}>
      {t('last_generated_at_ago', {
        ns: i18nNS.DISCUSSION,
        at: formatDistanceToNow(discussion.wordCloud.data.generatedAt),
      })}
    </StyledNote>
  );
};

export default DiscussionWordCloudHeader;
