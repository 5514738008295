import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import {
  AttendanceMarkedEvent,
  AttendanceScheduledToStartEvent,
  AttendanceWarningEvent,
  AttendeeAvailableEvent,
  AttendeeFailureEvent,
  ClassCheckInEvent,
} from './pusher-events';
import {
  AwardClassParticipationPointsSuccessPayload,
  CancelClassPayload,
  ClassId,
  ClassInchargeSetPayload,
  ClassTeamEditedPayload,
  CourseId,
  CreateClassPayload,
  CreateClassSuccessPayload,
  DeleteClassSuccessPayload,
  EditAttendanceSchedulePayload,
  EditClassAttendancePayload,
  EditClassSummaryPayload,
  EditClassSummarySuccessPayload,
  EditClassTeamPayload,
  EditClassTeamSuccessPayload,
  EditClassTimingsFailurePayload,
  EditClassTimingsPayload,
  EditClassVenuePayload,
  EndClassSuccessPayload,
  FetchAttendanceRespondersPayload,
  FetchAttendanceRespondersSuccessPayload,
  FetchClassAttendancePayload,
  FetchClassAttendanceSuccessPayload,
  FetchClassDetailsSuccessPayload,
  FetchClassesToCopyActivitySuccessPayload,
  FetchClassParticipationsPayload,
  FetchClassSummarySuccessPayload,
  FetchMyAttendanceStatusPayload,
  FetchMyAttendanceStatusSuccessPayload,
  MoveActivityPayload,
  RescheduleClassPayload,
  ScheduleAttendancePayload,
  StartProxyAttendanceSuccessPayload,
  StopProxyAttendancePayload,
} from './types';

export const openClass = createAction<ClassId>('classes/open');
export const closeClass = createAction<ClassId>('classes/close');

export const createClass = createRequestAction<CreateClassPayload, CreateClassSuccessPayload>('classes/add');

export const editClassTitle = createRequestAction<API.EditClassTitleRequest, API.EditClassTitleRequest>(
  'classes/title/edit'
);

export const editClassTimings = createRequestAction<
  EditClassTimingsPayload,
  EditClassTimingsPayload,
  EditClassTimingsFailurePayload
>('classes/timings/edit');

export const rescheduleClass = createRequestAction<RescheduleClassPayload, RescheduleClassPayload>(
  'classes/reschedule'
);

export const cancelClass = createRequestAction<CancelClassPayload, CancelClassPayload>('classes/cancel');

export const editClassVenue = createRequestAction<EditClassVenuePayload, EditClassVenuePayload>(
  'classes/venue/edit'
);

export const classInchargeSet = createAction<ClassInchargeSetPayload>('classes/incharge/set');

export const editClassTeam = createRequestAction<EditClassTeamPayload, EditClassTeamSuccessPayload>(
  'classes/team/edit'
);

export const classTeamEdited = createAction<ClassTeamEditedPayload>('classes/team/edited');

export const deleteClass = createRequestAction<API.DeleteClassRequest, DeleteClassSuccessPayload>(
  'classes/remove'
);

export const fetchClassDetails = createRequestAction<ClassId, FetchClassDetailsSuccessPayload>(
  'classes/fetch/details'
);

export const startClass = createRequestAction<ClassId, ClassId>('classes/start');

export const endClass = createRequestAction<ClassId, EndClassSuccessPayload>('classes/end');

export const checkInToClass = createRequestAction<ClassId, API.CheckInToClassResponse>('classes/check-in');

export const checkedInToClass = createAction<ClassCheckInEvent>('classes/checked-in');

export const editClassAgenda = createRequestAction<API.EditClassAgendaRequest, API.EditClassAgendaRequest>(
  'classes/agenda/edit'
);

export const editClassTopics = createRequestAction<API.EditClassTopicsRequest, API.EditClassTopicsRequest>(
  'classes/topics/edit'
);

export const fetchClassSummary = createRequestAction<ClassId, FetchClassSummarySuccessPayload>(
  'classes/summary/fetch'
);

export const editClassSummary = createRequestAction<EditClassSummaryPayload, EditClassSummarySuccessPayload>(
  'classes/summary/edit'
);

export const fetchClassParticipations = createRequestAction<ClassId, FetchClassParticipationsPayload>(
  'classes/participations/fetch'
);

export const awardClassParticipationPoints = createRequestAction<
  API.AwardParticipationPointsRequest,
  AwardClassParticipationPointsSuccessPayload
>('classes/participations/award');

export const scheduleAttendance = createRequestAction<ScheduleAttendancePayload, ScheduleAttendancePayload>(
  'classes/attendance/schedule'
);

export const attendanceScheduledToStart = createAction<AttendanceScheduledToStartEvent>(
  'classes/attendance/scheduled-to-start'
);

export const editAttendanceSchedule = createRequestAction<
  EditAttendanceSchedulePayload,
  EditAttendanceSchedulePayload
>('classes/attendance/edit-schedule');

export const fetchClassAttendance = createRequestAction<
  FetchClassAttendancePayload,
  FetchClassAttendanceSuccessPayload
>('classes/attendance/fetch');

export const fetchMyAttendanceStatus = createRequestAction<
  FetchMyAttendanceStatusPayload,
  FetchMyAttendanceStatusSuccessPayload
>('classes/my-attendance/fetch');

export const startProxyAttendance = createRequestAction<
  API.StartProxyAttendanceRequest,
  StartProxyAttendanceSuccessPayload
>('classes/proxy-attendance/start');

export const proxyAttendanceStarted = createAction<AttendanceWarningEvent>(
  'classes/proxy-attendance/started'
);

export const stopProxyAttendance = createRequestAction<
  StopProxyAttendancePayload,
  StopProxyAttendancePayload
>('classes/proxy-attendance/stop');

export const proxyAttendanceStopped = createAction<AttendanceWarningEvent>(
  'classes/proxy-attendance/stopped'
);

export const fetchAttendanceResponders = createRequestAction<
  FetchAttendanceRespondersPayload,
  FetchAttendanceRespondersSuccessPayload
>('classes/attendance/responders/fetch');

export const attendeeAvailable = createAction<AttendeeAvailableEvent>('classes/attendee/available');

export const attendeeFailure = createAction<AttendeeFailureEvent>('classes/attendee/failure');

export const attendanceMarked = createAction<AttendanceMarkedEvent>('classes/attendance/marked');

export const editClassAttendance = createRequestAction<
  EditClassAttendancePayload,
  EditClassAttendancePayload
>('classes/attendance/edit');

export const clearLocalAttendanceData = createAction('classes/attendance/clear');

export const fetchClassesToCopyActivity = createRequestAction<
  CourseId,
  FetchClassesToCopyActivitySuccessPayload
>('classes/fetch/copy-activities');

export const moveActivity = createRequestAction<MoveActivityPayload>('classes/activities/move');
