import { Stack, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';

import Progressbar from '../components/Progressbar';
import darkTheme from '../styles/theme/dark';
import lightTheme from '../styles/theme/light';

const Progressbars = () => {
  return (
    <Stack gap={8}>
      <Stack flex={1} direction="row" gap={4}>
        <Progressbar shape="circular" variant="determinate" value={75} color="primary" />
        <Progressbar shape="circular" variant="determinate" value={75} color="success" />
        <Progressbar shape="circular" variant="determinate" value={75} color="warning" />
        <Progressbar shape="circular" variant="determinate" value={75} color="error" />
      </Stack>
      <Stack flex={1} direction="row" gap={4}>
        <Progressbar shape="circular" color="primary" />
        <Progressbar shape="circular" color="success" />
        <Progressbar shape="circular" color="warning" />
        <Progressbar shape="circular" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" variant="determinate" value={75} size="thin" color="primary" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thin" color="success" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thin" color="warning" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thin" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" size="thin" color="primary" />
        <Progressbar shape="linear" size="thin" color="success" />
        <Progressbar shape="linear" size="thin" color="warning" />
        <Progressbar shape="linear" size="thin" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" variant="determinate" value={75} size="medium" color="primary" />
        <Progressbar shape="linear" variant="determinate" value={75} size="medium" color="success" />
        <Progressbar shape="linear" variant="determinate" value={75} size="medium" color="warning" />
        <Progressbar shape="linear" variant="determinate" value={75} size="medium" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" size="medium" color="primary" />
        <Progressbar shape="linear" size="medium" color="success" />
        <Progressbar shape="linear" size="medium" color="warning" />
        <Progressbar shape="linear" size="medium" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" variant="determinate" value={75} size="thick" color="primary" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thick" color="success" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thick" color="warning" />
        <Progressbar shape="linear" variant="determinate" value={75} size="thick" color="error" />
      </Stack>
      <Stack gap={4}>
        <Progressbar shape="linear" size="thick" color="primary" />
        <Progressbar shape="linear" size="thick" color="success" />
        <Progressbar shape="linear" size="thick" color="warning" />
        <Progressbar shape="linear" size="thick" color="error" />
      </Stack>
    </Stack>
  );
};

const ContentRoot = styled('div')(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(8),
}));

const ProgressbarPlayground = () => {
  return (
    <Stack direction="row">
      <ThemeProvider theme={lightTheme}>
        <ContentRoot>
          <Progressbars />
        </ContentRoot>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ContentRoot>
          <Progressbars />
        </ContentRoot>
      </ThemeProvider>
    </Stack>
  );
};

export default ProgressbarPlayground;
