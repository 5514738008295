import { CreateCourseStep } from '../../types';

export const getCreateCourseProgress = ({ activeStep }: { activeStep: CreateCourseStep | undefined }) => {
  const studentPaysSteps = [
    CreateCourseStep.INTRODUCTION,
    CreateCourseStep.PAYMENT_METHOD,
    CreateCourseStep.BASIC_DETAILS,
    CreateCourseStep.INITIALIZE,
    CreateCourseStep.COPY_CONTENT,
    CreateCourseStep.SCHEDULE,
    CreateCourseStep.TEAM_MEMBERS,
    CreateCourseStep.ENROLLMENT_METHOD,
  ];

  const instructorPaysSteps = [
    CreateCourseStep.INTRODUCTION,
    CreateCourseStep.PAYMENT_METHOD,
    CreateCourseStep.COST_CALCULATOR,
    CreateCourseStep.PAYMENT_DETAILS,
    CreateCourseStep.PAYMENT_SUCCESS,
  ];

  if (!activeStep) return 0;

  let currentIndex = studentPaysSteps.indexOf(activeStep);

  if (currentIndex >= 0) {
    return ((currentIndex + 1) * 100) / studentPaysSteps.length;
  }

  currentIndex = instructorPaysSteps.indexOf(activeStep);

  if (currentIndex >= 0) {
    return ((currentIndex + 1) * 100) / instructorPaysSteps.length;
  }

  return 0;
};
