import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Public as WorldIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';

import Spinner from '../../../components/Spinner';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import SelectTimezoneAlert from './SelectTimezoneAlert';
import { SelectTimezoneButton } from './styles';
import useTimezoneVM, { Props as VMProps } from './vm';

interface Props<T extends FieldValues> extends VMProps<T> {
  className?: string;
  onChangeTimezone: (timezone: string) => void;
}

const Timezone = <T extends FieldValues>({ className, onChangeTimezone, ...vmOptions }: Props<T>) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE]);

  const {
    controller,
    isSelectTimezoneAlertOpen,
    onClickSelectTimezone,
    onCloseSelectTimezoneAlert,
    isFetchingTimezones,
    universityTimezones,
  } = useTimezoneVM(vmOptions);

  const {
    field: { value },
    fieldState: { invalid, error },
  } = controller;

  const handleClickSelectTimezone = () => {
    onClickSelectTimezone();
  };

  return (
    <>
      <div className={className}>
        <Stack direction="row" alignItems="baseline" gap={2}>
          <Typography variant="h6Medium" textTransform="uppercase">
            {t('course_timezone', { ns: i18nNS.COURSES })}
          </Typography>
          {invalid && (
            <Typography variant="paragraphRegular" color="error">
              {error?.message}
            </Typography>
          )}
        </Stack>
        <SelectTimezoneButton
          variant="text"
          onClick={handleClickSelectTimezone}
          aria-label={
            value
              ? t('selected_timezone_click_to_change_selection', {
                  ns: i18nNS.CREATE_COURSE,
                  timezone: value,
                })
              : t('click_to_select_a_course_timezone', { ns: i18nNS.CREATE_COURSE })
          }
          fullWidth
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="h6Regular">
              {value || t('select_a_course_timezone', { ns: i18nNS.CREATE_COURSE })}
            </Typography>
            {isFetchingTimezones ? <Spinner size="small" /> : <WorldIcon />}
          </Stack>
        </SelectTimezoneButton>
      </div>
      <SelectTimezoneAlert
        open={isSelectTimezoneAlertOpen}
        onClose={onCloseSelectTimezoneAlert}
        timezones={universityTimezones}
        defaultValues={{ timezone: value || '' }}
        onChangeTimezone={onChangeTimezone}
      />
    </>
  );
};

export default Timezone;
