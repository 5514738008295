import { Resource } from '../db/resources/types';
import { CourseRole, ResourceType, UserWithRole } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { getIsActivityPublished } from '../utils/activities';

export function getCanFetchResourceComments() {
  return true;
}

export function getCanSendResourceComments(
  resource: Resource | undefined,
  isCourseArchived: boolean | undefined
) {
  if (isCourseArchived) return false;

  if (!resource) return false;

  const isResourcePublished = getIsActivityPublished(resource);
  return isResourcePublished;
}

export function getEmptyCommentsMessageForResource(
  resource: Resource | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  if (!resource) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  const isResourcePublished = getIsActivityPublished(resource);
  if (!isResourcePublished) {
    return i18n.t('comments_will_appear_here_once_this_activity_is_published', { ns: i18nNS.CLASS });
  }

  if (currentUser.role === CourseRole.STUDENT) {
    return i18n.t(
      'this_is_the_resource_discussion_thread_if_you_have_any_thoughts_on_this_shared_resource_material_you_can_share_it_here',
      { ns: i18nNS.RESOURCE }
    );
  }

  return i18n.t('you_can_encourage_students_to_participate_in_discussing_this_shared_resource', {
    ns: i18nNS.RESOURCE,
  });
}

export function getResourceHeader(resource: Pick<Resource, 'sequenceNum'> | undefined) {
  if (!resource?.sequenceNum || resource.sequenceNum <= 0) return i18n.t('resource', { ns: i18nNS.GLOSSARY });
  return i18n.t('resource_num', { ns: i18nNS.RESOURCE, num: `${resource.sequenceNum}`.padStart(2, '0') });
}

export function isResourceTypeOrNull(value: string | null): value is ResourceType {
  if (value === null) return true;
  return Object.values(ResourceType).includes(value as any);
}
