import { useEffect, useRef } from 'react';

export interface UseListItemProps {
  isFocused?: boolean;
}

const useListItem = ({ isFocused }: UseListItemProps) => {
  const rootRef = useRef<HTMLLIElement | null>(null);

  useEffect(
    function init() {
      if (!isFocused) return;
      if (!rootRef.current) return;
      rootRef.current.scrollIntoView({ block: 'nearest' });
    },
    [isFocused]
  );

  return {
    rootRef,
  };
};

export default useListItem;
