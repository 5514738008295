import { ListItem as MuiListItem, styled } from '@mui/material';

import { FabMenuItem, fabMenuItemClasses } from '../components/FabMenu';
import List from '../components/List';

export const CourseList = styled(List)(({ theme }) => ({
  gap: theme.spacing(3),
}));

export const CourseItem = styled(MuiListItem)(() => ({
  padding: 0,
}));

export const CloseFabMenuItem = styled(FabMenuItem)(({ theme }) => ({
  [`.${fabMenuItemClasses.icon}`]: {
    color: theme.palette.error[800],
  },
  [`.${fabMenuItemClasses.text}`]: {
    color: theme.palette.error[800],
  },
}));
