import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { i18nNS } from '../../i18n';
import Attachment from './Attachment';
import File from './File';
import { attachmentViewClasses, Content, Extension, FileName, Root } from './styles';
import useAttachmentViewVM, { Props as VMProps } from './vm';

type Props<T> = VMProps<T> & {
  className?: string;
  /**
   * Left icon can be overrided with this icon if provided
   * else extension text will be used as placeholder
   */
  icon?: ReactNode;
  /**
   * Used as background color for extension
   * @default 'primary'
   */
  color?: 'primary' | 'success' | 'warning' | 'error';
  disabled?: boolean;
  /**
   * It can be used as an extra button for ex. delete button
   */
  rightAction?: ReactNode;
  fullWidth?: boolean;
  classes?: Partial<Record<'content', string>>;
};

const AttachmentView = <T,>({
  className,
  icon,
  color,
  disabled,
  rightAction,
  fullWidth,
  classes,
  ...vmOptions
}: Props<T>) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { fileName, extension } = useAttachmentViewVM(vmOptions);

  return (
    <Root
      className={clsx(className, { [attachmentViewClasses.fullWidth]: fullWidth })}
      aria-label={t('acadly_attachment_file_name', { ns: i18nNS.COMMON, fileName })}
    >
      {icon || (
        <Extension
          color="textRegular"
          className={clsx({
            [attachmentViewClasses.colorPrimary]: color === 'primary',
            [attachmentViewClasses.colorSuccess]: color === 'success',
            [attachmentViewClasses.colorWarning]: color === 'warning',
            [attachmentViewClasses.colorError]: color === 'error',
          })}
        >
          {extension}
        </Extension>
      )}

      <Content className={classes?.content}>
        <FileName variant="paragraphRegular">{fileName}</FileName>
        {vmOptions.variant === 'file' ? (
          <File {...vmOptions} disabled={disabled} />
        ) : (
          <Attachment {...vmOptions} disabled={disabled} fileName={fileName} />
        )}
        {rightAction}
      </Content>
    </Root>
  );
};

export default AttachmentView;
