import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttendanceProcessUnresponsiveAttendeesFailure } from '../../../../../db/classes/types';
import { i18nNS } from '../../../../../i18n';
import AttendanceProcessErrorLayout from '../AttendanceProcessErrorLayout';
import { Cell, CellLabel, CellValue, StyledTypography } from './styles';

interface Props {
  failure: AttendanceProcessUnresponsiveAttendeesFailure;
}

const UnresponsiveAttendeeError = ({ failure }: Props) => {
  const { t } = useTranslation([i18nNS.CLASS]);

  return (
    <AttendanceProcessErrorLayout failure={failure}>
      <>
        <Cell>
          <CellLabel>{t('students_with_app_closed', { ns: i18nNS.CLASS })}</CellLabel>
          <CellValue>{failure.unresponsiveAttendees}</CellValue>
        </Cell>
        <StyledTypography>{t('please_ask_them_to_open_the_app', { ns: i18nNS.CLASS })}</StyledTypography>
      </>
    </AttendanceProcessErrorLayout>
  );
};

export default UnresponsiveAttendeeError;
