import { styled } from '@mui/material';

import { forMobile } from '../../utils/media-queries';
import Header from '../Header';

export const DrawerHeader = styled(Header)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  height: 80,
  [forMobile(theme)]: {
    padding: theme.spacing(0, 4),
  },
}));
