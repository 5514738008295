import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../components/Button';
import { generateClasses } from '../../../utils/helpers';

export const timezoneClasses = generateClasses('Timezone', ['selectTimezoneButton']);

export const SelectTimezoneButton = styled((props: ButtonProps) => (
  <Button {...props} className={clsx(timezoneClasses.selectTimezoneButton, props.className)} />
))(({ theme }) => ({
  [`&.${timezoneClasses.selectTimezoneButton}`]: {
    padding: theme.spacing(0, 2),
    color: theme.palette.grey[600],
    letterSpacing: 0.2,
  },
}));
