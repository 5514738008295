import { useSelector } from 'react-redux';

import { removeWeeklySchedule } from '../../../../../db/courses/actions';
import { selectCurrentCourse, selectGroupedWeeklySchedule } from '../../../../../db/courses/selectors';
import { WeeklySchedule } from '../../../../../db/courses/types';
import { useRequestDispatch } from '../../../../../utils/request-actions';

const useScheduledClassesVM = () => {
  const requestDispatch = useRequestDispatch();

  const currentCourse = useSelector(selectCurrentCourse);
  const schedules = useSelector(selectGroupedWeeklySchedule);

  const onDeleteCourseScheduleItem = async (courseScheduleItem: WeeklySchedule) => {
    const { type, day, startTime } = courseScheduleItem;
    await requestDispatch(removeWeeklySchedule, { type, day, startTime });
  };

  return {
    isFetchingCurrentCourse: !currentCourse,
    onDeleteCourseScheduleItem,
    schedules,
  };
};

export default useScheduledClassesVM;
