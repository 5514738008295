import { useState } from 'react';

const usePasswordTextFieldVM = () => {
  const [canSeePassword, setCanSeePassword] = useState(false);

  const handleTogglePasswordVisibilityClick = () => {
    setCanSeePassword((prevValue) => !prevValue);
  };

  return { canSeePassword, handleTogglePasswordVisibilityClick };
};

export default usePasswordTextFieldVM;
