import { ThemeOptions } from '@mui/material';

import { baseTypographyStyles, fontWeight } from './variables';

const baseTheme: ThemeOptions = {
  typography: {
    fontWeightBold: fontWeight.bold,
    fontWeightMedium: fontWeight.medium,
    fontWeightRegular: fontWeight.regular,

    displayBold: {
      ...baseTypographyStyles.display,
      fontWeight: fontWeight.bold,
    },
    displayMedium: {
      ...baseTypographyStyles.display,
      fontWeight: fontWeight.medium,
    },
    displayRegular: {
      ...baseTypographyStyles.display,
      fontWeight: fontWeight.regular,
    },

    h1Bold: {
      ...baseTypographyStyles.h1,
      fontWeight: fontWeight.bold,
    },
    h1Medium: {
      ...baseTypographyStyles.h1,
      fontWeight: fontWeight.medium,
    },
    h1Regular: {
      ...baseTypographyStyles.h1,
      fontWeight: fontWeight.regular,
    },

    h2Bold: {
      ...baseTypographyStyles.h2,
      fontWeight: fontWeight.bold,
    },
    h2Medium: {
      ...baseTypographyStyles.h2,
      fontWeight: fontWeight.medium,
    },
    h2Regular: {
      ...baseTypographyStyles.h2,
      fontWeight: fontWeight.regular,
    },

    h3Bold: {
      ...baseTypographyStyles.h3,
      fontWeight: fontWeight.bold,
    },
    h3Medium: {
      ...baseTypographyStyles.h3,
      fontWeight: fontWeight.medium,
    },
    h3Regular: {
      ...baseTypographyStyles.h3,
      fontWeight: fontWeight.regular,
    },

    h4Bold: {
      ...baseTypographyStyles.h4,
      fontWeight: fontWeight.bold,
    },
    h4Medium: {
      ...baseTypographyStyles.h4,
      fontWeight: fontWeight.medium,
    },
    h4Regular: {
      ...baseTypographyStyles.h4,
      fontWeight: fontWeight.regular,
    },

    h5Bold: {
      ...baseTypographyStyles.h5,
      fontWeight: fontWeight.bold,
    },
    h5Medium: {
      ...baseTypographyStyles.h5,
      fontWeight: fontWeight.medium,
    },
    h5Regular: {
      ...baseTypographyStyles.h5,
      fontWeight: fontWeight.regular,
    },

    h6Bold: {
      ...baseTypographyStyles.h6,
      fontWeight: fontWeight.bold,
    },
    h6Medium: {
      ...baseTypographyStyles.h6,
      fontWeight: fontWeight.medium,
    },
    h6Regular: {
      ...baseTypographyStyles.h6,
      fontWeight: fontWeight.regular,
    },

    paragraphBold: {
      ...baseTypographyStyles.paragraph,
      fontWeight: fontWeight.bold,
    },
    paragraphMedium: {
      ...baseTypographyStyles.paragraph,
      fontWeight: fontWeight.medium,
    },
    paragraphRegular: {
      ...baseTypographyStyles.paragraph,
      fontWeight: fontWeight.regular,
    },

    link: {
      ...baseTypographyStyles.link,
    },

    titleBold: {
      ...baseTypographyStyles.title,
      fontWeight: fontWeight.bold,
    },
    titleMedium: {
      ...baseTypographyStyles.title,
      fontWeight: fontWeight.medium,
    },
    titleRegular: {
      ...baseTypographyStyles.title,
      fontWeight: fontWeight.regular,
    },

    textSmBold: {
      ...baseTypographyStyles.textSm,
      fontWeight: fontWeight.bold,
    },
    textSmMedium: {
      ...baseTypographyStyles.textSm,
      fontWeight: fontWeight.medium,
    },
    textSmRegular: {
      ...baseTypographyStyles.textSm,
      fontWeight: fontWeight.regular,
    },

    textXsBold: {
      ...baseTypographyStyles.textXs,
      fontWeight: fontWeight.bold,
    },
    textXsMedium: {
      ...baseTypographyStyles.textXs,
      fontWeight: fontWeight.medium,
    },
    textXsRegular: {
      ...baseTypographyStyles.textXs,
      fontWeight: fontWeight.regular,
    },
  },
  spacing: (factor: number | string) => (typeof factor === 'number' ? `${0.25 * factor}rem` : factor),
  acadlyShadows: {
    default: '0px 3px 5px rgba(15, 23, 42, 0.2), 0px 0px 1px rgba(15, 23, 42, 0.31)',
    sm: '0px 1px 1px rgba(15, 23, 42, 0.25), 0px 0px 1px rgba(15, 23, 42, 0.31)',
    md: '0px 8px 12px rgba(15, 23, 42, 0.15), 0px 0px 1px rgba(15, 23, 42, 0.31)',
    lg: '0px 10px 18px rgba(15, 23, 42, 0.15), 0px 0px 1px rgba(76, 29, 149, 0.31)',
    xl: '0px 10px 18px rgba(15, 23, 42, 0.15), 0px 0px 1px rgba(15, 23, 42, 0.31)',
  },
};

export default baseTheme;
