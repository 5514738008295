import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Resource } from './types';

export const selectFullResourceId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { resources } = state.db;
  return getFullId(resources, shortId);
};

export const selectResource = (id: ShortId | MongoId) => (state: RootState) => {
  const { resources } = state.db;
  return getActivityById(resources, id);
};

export const selectAllResource = (state: RootState) => {
  const { resources } = state.db;
  const publishedResource: Resource[] = [];
  const unpublishedResource: Resource[] = [];

  if (!resources.byId) return { publishedResource, unpublishedResource };

  for (const resource of Object.values(resources.byId)) {
    if (!resource) continue;
    if (resource.publishedOn <= 0) {
      unpublishedResource.push(resource);
    } else {
      publishedResource.push(resource);
    }
  }

  return {
    publishedResource,
    unpublishedResource,
  };
};

export const selectSuggestedResource = (id: MongoId) => (state: RootState) => {
  const { suggestedById } = state.db.resources;
  return suggestedById[id];
};
