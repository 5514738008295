import { ChangeEventHandler, forwardRef, Ref } from 'react';

import { Error as ErrorIcon } from '@mui/icons-material';
import { FormControl, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import clsx from 'clsx';

import {
  CharacterLimit,
  HelperText,
  inputBaseClasses,
  Props,
  StyledInputBase,
  StyledInputLabel,
} from './styles';
import useTextFieldBaseVM from './vm';

export type { Props };

const TextFieldBase = (
  {
    size = 'medium',
    InputBaseRef,
    error,
    helperIcon,
    helperText,
    characterLimit,
    fullWidth,
    className,
    classes,
    ...props
  }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const { ids, value, handleChange: _handleChange } = useTextFieldBaseVM(props);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    _handleChange(event.target.value);
    props.onChange?.(event);
  };

  return (
    <FormControl
      variant="standard"
      fullWidth={fullWidth}
      ref={ref}
      className={clsx(className, classes?.root)}
    >
      {props.label && (
        <StyledInputLabel shrink htmlFor={ids.input} required={props.required} className={classes?.label}>
          {props.label}
        </StyledInputLabel>
      )}
      <StyledInputBase
        {...props}
        ref={InputBaseRef}
        id={ids.input}
        size={size}
        value={value}
        onChange={handleChange}
        error={error}
        aria-describedby={ids.helperText}
        className={clsx(inputBaseClasses.root, classes?.inputBase)}
        inputProps={{
          ...props.inputProps,
          'aria-label': props['aria-label'] ?? props.placeholder,
          maxLength: characterLimit,
          className: clsx(props.inputProps?.className, classes?.input, {
            [inputBaseClasses.inputSizeXLarge]: size === 'xLarge',
            [inputBaseClasses.inputSizeLarge]: size === 'large',
            [inputBaseClasses.inputSizeMedium]: size === 'medium',
          }),
        }}
      />
      {(helperIcon || helperText || characterLimit) && (
        <HelperText id={ids.helperText} error={error} className={classes?.helperTextRoot}>
          {helperIcon || (error && <TextFieldBaseHelperIcon Icon={ErrorIcon} />)}
          {helperText && (
            <Typography
              variant="textXsRegular"
              color="inherit"
              component="span"
              className={classes?.helperText}
            >
              {helperText}
            </Typography>
          )}
          {characterLimit && (
            <CharacterLimit
              aria-hidden
              className={classes?.characterLimit}
            >{`${value.length}/${characterLimit}`}</CharacterLimit>
          )}
        </HelperText>
      )}
    </FormControl>
  );
};

interface TextFieldBaseHelperIconProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const TextFieldBaseHelperIcon = ({ Icon }: TextFieldBaseHelperIconProps) => {
  return <Icon sx={{ fontSize: '1rem' }} aria-hidden />;
};

export default forwardRef(TextFieldBase);
