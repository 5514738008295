import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import FormulaIcon from '../../icons/FormulaIcon';
import CreateFormulaOverlay, {
  Props as CreateFormulaOverlayProps,
} from '../../plugins/formula/CreateFormulaOverlay';
import ToolBarButton, { Props as ToolBarButtonProps } from '../../ToolBarButton';
import useInsertFormulaButtonVM, { Props as VMProps } from './vm';

const insertFormulaButtonClasses = generateClasses('InsertFormulaButton', [
  'toolbarButton',
  'formula',
  'toolbarIcon',
  'formulaIcon',
]);

export type Props = VMProps &
  ToolBarButtonProps & {
    renderCreateFormulaOverlay: CreateFormulaOverlayProps['renderOverlay'];
    i18n?: CreateFormulaOverlayProps['i18n'];
    classes?: Partial<typeof insertFormulaButtonClasses>;
    insertFormulaButtonId?: string;
    activeItemId?: string;
  };

const InsertFormulaButton = ({
  renderCreateFormulaOverlay,
  i18n,
  classes,
  editorRef,
  insertFormulaButtonId,
  activeItemId,
  ...props
}: Props) => {
  const {
    showCreateFormulaOverlay,
    isCreateFormulaOverlayOpen,
    onInsertFormula: handleInsertFormula,
  } = useInsertFormulaButtonVM({ editorRef });
  return (
    <>
      <ToolBarButton
        id={insertFormulaButtonId}
        {...props}
        onClick={showCreateFormulaOverlay}
        className={clsx(
          insertFormulaButtonClasses.toolbarButton,
          classes?.toolbarButton,
          insertFormulaButtonClasses.formula,
          classes?.formula
        )}
        tabIndex={-1}
        aria-selected={insertFormulaButtonId === activeItemId}
      >
        <FormulaIcon
          fontSize="small"
          className={clsx(
            insertFormulaButtonClasses.toolbarIcon,
            classes?.toolbarIcon,
            insertFormulaButtonClasses.formulaIcon,
            classes?.formulaIcon
          )}
        />
      </ToolBarButton>
      <CreateFormulaOverlay
        isOpen={isCreateFormulaOverlayOpen}
        onClose={handleInsertFormula}
        editorRef={editorRef}
        renderOverlay={renderCreateFormulaOverlay}
        i18n={i18n}
      />
    </>
  );
};

export default InsertFormulaButton;
