import { ReactNode } from 'react';

import { FormControl, InputBaseProps } from '@mui/material';
import { CardElementProps } from '@stripe/react-stripe-js';
import { StripeCardElementOptions } from '@stripe/stripe-js';

import clsx from 'clsx';

import Typography from '../../../components/Typography';
import AlertCircleIcon from '../../../icons/AlertCircleIcon';
import {
  HeightAdjuster,
  HelperText,
  InputLabel,
  InputWrapper,
  StripeCardElement,
  stripeCardFieldClasses,
} from './styles';
import useStripeInput, { VMProps } from './vm';

const CARD_OPTIONS: StripeCardElementOptions = { hidePostalCode: true };

export interface Props extends VMProps, DistributiveOmit<CardElementProps, 'onBlur'> {
  className?: string;
  /** @default false */
  disabled?: boolean;
  /** @default false */
  fullWidth?: boolean;
  error?: boolean;
  helperIcon?: ReactNode;
  helperText?: string;
  label: ReactNode;
  /** @default 'medium' */
  size?: InputBaseProps['size'];
}

const StripeInput = ({
  className,
  disabled = false,
  fullWidth = false,
  error,
  helperIcon,
  helperText,
  label,
  onReady,
  onChange,
  size = 'medium',
  ...vmProps
}: Props) => {
  const { handleBlur, handleFocus, ids, isFocused } = useStripeInput(vmProps);
  return (
    <FormControl variant="standard" fullWidth={fullWidth} className={clsx(className)}>
      <InputLabel shrink htmlFor={ids.inputWrapper} required>
        {label}
      </InputLabel>
      <InputWrapper
        aria-describedby={ids.helperText}
        className={clsx(stripeCardFieldClasses.input, {
          [stripeCardFieldClasses.inputError]: error,
          [stripeCardFieldClasses.inputDisabled]: disabled,
          [stripeCardFieldClasses.inputFocused]: isFocused,
          [stripeCardFieldClasses.inputSizeXLarge]: size === 'xLarge',
          [stripeCardFieldClasses.inputSizeLarge]: size === 'large',
          [stripeCardFieldClasses.inputSizeMedium]: size === 'medium',
          [stripeCardFieldClasses.inputSizeSmall]: size === 'small',
        })}
      >
        <HeightAdjuster>
          <StripeCardElement
            options={CARD_OPTIONS}
            onReady={onReady}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={onChange}
          />
        </HeightAdjuster>
      </InputWrapper>
      {(helperIcon || helperText) && (
        <HelperText id={ids.helperText} error={error} className={stripeCardFieldClasses.helperTextRoot}>
          {helperIcon || (error && <AlertCircleIcon variant="filled" fontSize="xSmall" />)}
          {helperText && (
            <Typography
              variant="textXsRegular"
              color="inherit"
              component="span"
              className={stripeCardFieldClasses.helperText}
            >
              {helperText}
            </Typography>
          )}
        </HelperText>
      )}
    </FormControl>
  );
};

export default StripeInput;
