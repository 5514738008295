import { forwardRef, MouseEventHandler, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { InputAdornment } from '@mui/material';

import { i18nNS } from '../../../i18n';
import EyeIcon from '../../../icons/EyeIcon';
import EyeOffIcon from '../../../icons/EyeOffIcon';
import TextField from '../TextField';
import { Props, StyledIconButton } from './styles';
import usePasswordTextFieldVM from './vm';

export type { Props };

const PasswordTextField = ({ classes, ...props }: Props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation([i18nNS.COMMON]);
  const { canSeePassword, handleTogglePasswordVisibilityClick } = usePasswordTextFieldVM();

  const handleTogglePasswordVisibilityMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      ref={ref}
      type={canSeePassword ? 'text' : 'password'}
      classes={classes}
      endAdornment={
        <InputAdornment position="end" className={classes?.inputAdornment}>
          <StyledIconButton
            onClick={handleTogglePasswordVisibilityClick}
            onMouseDown={handleTogglePasswordVisibilityMouseDown}
            aria-label={t('click_to_toggle_password_visibility', { ns: i18nNS.COMMON })}
            className={classes?.togglePasswordVisibilityButton}
          >
            {canSeePassword ? (
              <EyeOffIcon variant="filled" className={classes?.visibilityOnIcon} />
            ) : (
              <EyeIcon variant="filled" className={classes?.visibilityOffIcon} />
            )}
          </StyledIconButton>
        </InputAdornment>
      }
    />
  );
};

export default forwardRef(PasswordTextField);
