import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../components/Button';
import Dialog from '../../components/Dialog/index';
import TextField from '../../components/HookFormControls/TextField';
import { i18nNS } from '../../i18n';
import useFeedbackDialogVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const FeedbackDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation([i18nNS.FEEDBACK, i18nNS.GLOSSARY]);

  const { formMethods, onSubmit } = useFeedbackDialogVM({ open, onClose });
  const { handleSubmit, control } = formMethods;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      header={t('send_a_message_to_the_acadly_team', { ns: i18nNS.FEEDBACK })}
      footer={
        <Button
          onClick={handleSubmit(onSubmit)}
          aria-label={t('click_to_submit_feedback', { ns: i18nNS.FEEDBACK })}
        >
          {t('submit', { ns: i18nNS.GLOSSARY })}
        </Button>
      }
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <TextField
            name="subject"
            control={control}
            label={t('subject', { ns: i18nNS.GLOSSARY })}
            characterLimit={140}
            autoFocus
          />
          <TextField
            name="body"
            control={control}
            label={t('body', { ns: i18nNS.GLOSSARY })}
            multiline
            rows={8}
          />
        </Stack>
      </form>
    </Dialog>
  );
};

export default FeedbackDialog;
