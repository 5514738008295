import { useTranslation } from 'react-i18next';

import { Divider, Stack } from '@mui/material';

import Typography from '../../../../components/Typography';
import { i18nNS } from '../../../../i18n';
import { toShortID } from '../../../../utils/helpers';
import AttendanceError from '../AttendanceError';
import { AttendanceGif } from '../styles';
import {
  attendanceSectionClasses,
  Cell,
  CellLabel,
  CellValue,
  FailureWrapper,
  ProcessFailureText,
  Root,
  StyledButton,
} from './styles';
import useAttendanceSection, { UseAttendanceSection } from './vm';

interface Props extends UseAttendanceSection {
  timeRemaining: number;
  canStopAttendance: boolean;
  onStopAttendance: () => Promise<void>;
}

const AttendanceSection = ({
  timeRemaining,
  canStopAttendance,
  onStopAttendance: handleStopAttendance,
  ...vmOptions
}: Props) => {
  const { t } = useTranslation([i18nNS.CLASS, i18nNS.GLOSSARY]);

  const { attendanceInProgress } = vmOptions;

  const {
    isInProgress,
    courseId: attendanceCourseId,
    classId: attendanceClassId,
    present,
    enrolled,
    available,
    nowMarkedPresent,
    failures,
  } = attendanceInProgress;

  const {
    courseShortId,
    classShortId,
    isInClassTeam,
    isProxyAttendanceInProgress,
    handleClearLocalAttendanceData,
  } = useAttendanceSection(vmOptions);

  if (!isInClassTeam) return null;

  const attendanceCourseShortId = toShortID(attendanceCourseId);
  if (courseShortId !== attendanceCourseShortId) return null;

  const attendanceClassShortId = toShortID(attendanceClassId);
  if (classShortId !== attendanceClassShortId) return null;

  return (
    <Root>
      <Typography variant="h6Bold" color="grey.800">
        {t(isInProgress ? 'recording_in_person_attendance' : 'recorded_in_person_attendance', {
          ns: i18nNS.CLASS,
        })}
      </Typography>
      <Cell>
        <CellLabel>{t('unmarked_students', { ns: i18nNS.CLASS })}</CellLabel>
        <CellValue>{enrolled - present}</CellValue>
      </Cell>
      <Cell>
        <CellLabel>{t('students_with_app_open', { ns: i18nNS.CLASS })}</CellLabel>
        <CellValue>{available > -1 ? available : '--'}</CellValue>
      </Cell>
      <Cell>
        <AttendanceGif isHidden={!isInProgress} />
        <Stack direction="column">
          <CellValue>{t('marked_now', { ns: i18nNS.CLASS })}</CellValue>
          <CellValue variant="h4Bold" align="right">
            {nowMarkedPresent}
          </CellValue>
        </Stack>
      </Cell>

      <Cell className={attendanceSectionClasses.highlighted}>
        <CellLabel>{t('total_present_in_this_class', { ns: i18nNS.CLASS })}</CellLabel>
        <CellLabel>{present > -1 ? present : '--'}</CellLabel>
      </Cell>

      {failures.length >= 1 && <Divider variant="middle" />}

      {failures.length ? (
        <FailureWrapper>
          <ProcessFailureText>{t('process_failures', { ns: i18nNS.CLASS })}</ProcessFailureText>
          <AttendanceError />
        </FailureWrapper>
      ) : null}
      {isProxyAttendanceInProgress ? (
        <Cell className={attendanceSectionClasses.stopAttendanceWrapper}>
          <CellLabel>{t('time_seconds_remaining', { ns: i18nNS.CLASS, time: timeRemaining })}</CellLabel>
          <StyledButton
            color="error"
            size="small"
            onClick={handleStopAttendance}
            aria-label={t('click_to_stop_in_person_attendance', { ns: i18nNS.CLASS })}
            disabled={!canStopAttendance}
          >
            {t('stop_attendance', { ns: i18nNS.CLASS })}
          </StyledButton>
        </Cell>
      ) : (
        <Cell justifyContent="flex-end" className={attendanceSectionClasses.stopAttendanceWrapper}>
          <StyledButton color="error" size="small" onClick={handleClearLocalAttendanceData}>
            {t('close', { ns: i18nNS.GLOSSARY })}
          </StyledButton>
        </Cell>
      )}
    </Root>
  );
};

export default AttendanceSection;
