import { Stack, StackProps, styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../components/Typography';

export const Content = styled((props: StackProps) => <Stack direction="column" gap={2} {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(2),
  })
);

export const Cell = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))(({ theme }) => ({}));

export const CellText = styled((props: TypographyProps) => (
  <Typography variant="h6Bold" color="grey.800" {...props} />
))(({ theme }) => ({}));
