import { useCallback, useMemo, useState } from 'react';

import { FabMenuContextType } from './Context';

export interface VMProps {
  rootMenu: string;
}

export default function useFabMenuVM({ rootMenu }: VMProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const [currentMenu, setCurrentMenu] = useState(rootMenu);

  const openFabMenu = useCallback(() => setIsOpen(true), []);

  const closeFabMenu = useCallback(() => {
    setCurrentMenu(rootMenu);
    setIsOpen(false);
  }, [rootMenu]);

  const goToMenu = useCallback((menuId: string) => {
    setCurrentMenu(menuId);
  }, []);

  const contextValue = useMemo<FabMenuContextType>(() => {
    return {
      isOpen: isOpen,
      open: openFabMenu,
      close: closeFabMenu,
      currentMenu,
      menuHeight,
      goToMenu,
      setMenuHeight,
    };
  }, [closeFabMenu, currentMenu, goToMenu, isOpen, menuHeight, openFabMenu]);

  return { contextValue };
}
