import { AttendanceFailureCode } from '../../../../../db/shared/types';
import i18n, { i18nNS } from '../../../../../i18n';

export default function useAttendeeErrorVM() {
  const getAttendanceErrorMessage = (failureCode: AttendanceFailureCode) => {
    switch (failureCode) {
      case AttendanceFailureCode.BLE_NOT_SUPPORTED:
        return i18n.t('is_using_a_device_that_doesn_t_support_bluetooth', { ns: i18nNS.CLASS });
      case AttendanceFailureCode.NO_GPS:
        return i18n.t('has_not_switched_on_the_gps', { ns: i18nNS.CLASS });
      case AttendanceFailureCode.NO_PERMISSIONS:
      default:
        return i18n.t('has_not_provided_adequate_permissions', { ns: i18nNS.CLASS });
    }
  };

  return {
    getAttendanceErrorMessage,
  };
}
