import { useEffect, useMemo, useState } from 'react';

import { randomStr } from '../../../utils/helpers';

export interface Props {
  id?: string;
  defaultValue?: string;
  value?: string;
}

const useTextFieldBaseVM = ({ id, defaultValue, value: _value }: Props) => {
  const ids = useMemo(
    () => ({
      input: id || `input-${randomStr(4)}`,
      helperText: `helper-text-${id || randomStr(4)}`,
    }),
    [id]
  );

  const [value, setValue] = useState('');

  const handleChange = (updatedValue: string) => {
    setValue(updatedValue);
  };

  useEffect(
    function setDefaultValue() {
      setValue(defaultValue || '');
    },
    [defaultValue]
  );

  useEffect(
    function setInitialValue() {
      setValue(_value || '');
    },
    [_value]
  );

  return { ids, value, handleChange };
};

export default useTextFieldBaseVM;
