import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ListItem, ListItemIcon } from '@mui/material';

import ProBookImage from '../../../assets/pro-book.png';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import DoneAllIcon from '../../../icons/DoneAllIcon';
import KeypadIcon from '../../../icons/KeypadIcon';
import PersonAddIcon from '../../../icons/PersonAddIcon';
import PersonDoneIcon from '../../../icons/PersonDoneIcon';
import {
  BookIcon,
  Content,
  CreateButton,
  Description,
  FeatureList,
  FeatureSection,
  FeatureTitle,
  Header,
  Root,
  Title,
} from './styles';

interface Props {
  onCreateClick: MouseEventHandler;
}

const ProCourseWidget = ({ onCreateClick }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);
  return (
    <Root>
      <Content tabIndex={0}>
        <BookIcon src={ProBookImage} aria-hidden />
        <Header>
          <Title id="title" variant="h4Bold">
            {t('acadly_pro_demo_course_with_zero_tap_attendance', { ns: i18nNS.COURSES })}
          </Title>
          <Description id="description">
            {t('create_a_pro_course_take_acadly_for_a_spin', { ns: i18nNS.COURSES })}
          </Description>
        </Header>
        <Divider />
        <FeatureSection>
          <FeatureTitle variant="titleBold" aria-label={t('features', { ns: i18nNS.COURSES })}>
            {t('features', { ns: i18nNS.COURSES })}
          </FeatureTitle>
          <FeatureList>
            <ListItem>
              <ListItemIcon>
                <PersonDoneIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('automate_classroom_attendance', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <KeypadIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('breakout_rooms', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('lms_importable_csv', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DoneAllIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('every_basic_course_feature', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
          </FeatureList>
        </FeatureSection>
      </Content>
      <CreateButton size="xLarge" variant="contained" onClick={onCreateClick}>
        {t('create_acadly_pro_demo_course', { ns: i18nNS.COURSES })}
      </CreateButton>
    </Root>
  );
};

export default ProCourseWidget;
