const en = {
  click_to_switch_to_title: 'click to switch to {{title}}',

  comment_posted_by_creator_name_time: 'Comment posted by {{name}}, {{time}}',
  award_points: 'Award points',
  click_to_give_award_points_to_this_post: 'click to give award points to this post',
  retract_award_points: 'Retract Award points',
  click_to_undo_points_awarded_to_this_post: 'click to undo points awarded from this post',
  click_to_like_this_post: 'click to like this post',
  undo_like: 'Undo like',
  click_to_undo_like: 'click to undo like',
  click_to_thank_creator_name: 'click to thank {{name}}',
  undo_thank: 'Undo thank',
  click_to_undo_thank_creator_name: 'click to undo thank {{name}}',
  click_to_delete_this_post: 'click to delete this post',
  click_to_view_comment_details: 'click to view comment details',
  this_comment_has_num_likes_num_thanks_and_num_awards_click_to_see_details:
    'This comment has {{numLikes}} likes, {{numThanks}} thanks and {{numAwards}} awards, click to see details',
  N_new_message_one: '{{count}} new message',
  N_new_message_other: '{{count}} new messages',
  click_to_see_new_messages: 'Click to see new messages',
  click_to_close_new_message_info: 'Click to close new message info',

  send_a_response: 'Send a response',
  click_to_send_this_comment: 'click to send this comment',
  click_to_open_rich_text_editor: 'click to open rich text editor',
  click_to_attach_file: 'click to attach file',
  click_to_attach_image: 'click to attach image',

  load_older_comments: 'Load older comments',
  no_more_comments_to_show: 'No more comments to show',
  image_preview: 'Image Preview',

  thank_the_comment_author: 'Thank the comment author',
  like_this_comment: 'Like this comment',
  you_awarded_num_points_points_to_this_post: 'You awarded {{numPoints}} points to this post',
  delete_this_comment: 'Delete this comment',
  click_to_see_previous_post: 'click to see previous post',
  click_to_see_next_post: 'click to see next post',
  slide_to_give_award_points_to_this_post: 'slide to give award points to this post',
  click_to_award_count_points_to_this_students_post_one:
    "click to award {{count}} point to this student's post",
  click_to_award_count_points_to_this_students_post_other:
    "click to award {{count}} points to this student's post",
  click_to_cancel_awarding_points_to_this_students_post:
    "click to cancel awarding points to this student's post",
  click_to_retract_awarded_points_from_this_students_post:
    "click to retract awarded points from this student's post",
  click_to_cancel_retracting_awarded_points_from_this_students_post:
    "click to cancel retracting awarded points from this student's post",

  comment_stats: 'Comment stats',
  presenter_awarded_num_points_points: '{{presenter}} awarded {{numPoints}} points',

  are_you_sure_you_want_to_award_num_points_points_to_this_students_post:
    "Are you sure you want to award {{numPoints}} points to this student's post?",
  are_you_sure_you_want_to_retract_num_points_points_you_awarded_to_this_students_post:
    "Are you sure you want to retract {{numPoints}} points you awarded to this student's post?",

  do_you_want_to_remove_this_comment: 'Do you want to remove this comment?',
  this_comment_was_removed_by_the_author: 'This comment was removed by the author',
  this_comment_was_removed_by_article_role: 'This comment was removed by {{article}} {{role}}',

  discussion_types: 'discussion types',
  pre_submission: 'Pre-submission',
  post_submission: 'Post-submission',

  empty_comments: 'There are no comments yet.',
  empty_activity_published_comments: 'Comments will appear here once this activity is published',

  enter_comment_here: 'Enter comment here',
  enter_an_optional_comment_here: 'Enter an optional comment here',
  attached_image: 'Attached image',
};

export default en;
