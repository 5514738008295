import { useCallback, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { selectFirstUnseenCommentId } from '../../../../../../db/comments/selectors';
import { CommentsContext, NewIndicatorVisibility } from '../../../../../Context';

const useCommentGroupVM = () => {
  const { commentListRef, newIndicatorRef, setNewIndicatorVisibility } = useContext(CommentsContext);

  const { ref: newIndicatorWrapperRef, inView, entry } = useInView({ root: commentListRef.current });

  const firstUnseenCommentId = useSelector(selectFirstUnseenCommentId);

  const computeNewIndicatorVisibility = useCallback((): NewIndicatorVisibility => {
    if (!newIndicatorRef.current) return null;
    if (inView) return { isInView: true };
    if (!entry) return null;

    const { rootBounds, boundingClientRect } = entry;
    if (!rootBounds) return null;
    if (rootBounds.top > boundingClientRect.bottom) return { isInView: false, direction: 'top' };
    if (rootBounds.bottom < boundingClientRect.top) return { isInView: false, direction: 'bottom' };
    return null;
  }, [entry, inView, newIndicatorRef]);

  useEffect(() => {
    const newIndicatorVisibility = computeNewIndicatorVisibility();
    setNewIndicatorVisibility(newIndicatorVisibility);
  }, [computeNewIndicatorVisibility, setNewIndicatorVisibility]);

  return { newIndicatorWrapperRef, firstUnseenCommentId, newIndicatorRef };
};

export default useCommentGroupVM;
