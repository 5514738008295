import { useTranslation } from 'react-i18next';

import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
  Typography,
} from '@mui/material';

import { i18nNS } from '../../i18n';
import DownloadIcon from '../../icons/DownloadIcon';
import Button from '../Button';
import Header from '../Header';

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(4, 5, 5),
  '& > *:not(:first-of-type)': {
    margin: 0,
  },
}));

export interface Props {
  title: string;
  onClose: () => void;
  onDownloadCopy: () => void;
}

const EmailSuccessStep = ({ title, onClose, onDownloadCopy }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  return (
    <>
      <Header>{title}</Header>
      <DialogContent>
        <Typography variant="paragraphRegular">
          {t(
            'the_exported_file_has_been_mailed_to_your_registered_email_address_in_case_you_can_t_spot_it_please_look_into_your_spam_folder',
            { ns: i18nNS.COMMON }
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          onClick={onDownloadCopy}
          aria-label={t('click_to_download_a_copy', { ns: i18nNS.COMMON })}
        >
          {t('download_a_copy', { ns: i18nNS.COMMON })}
        </Button>
        <Button onClick={onClose} aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}>
          {t('done', { ns: i18nNS.GLOSSARY })}
        </Button>
      </DialogActions>
    </>
  );
};

export default EmailSuccessStep;
