import { useSelector } from 'react-redux';

import { selectAuthSession } from '../../auth/store/selectors';

export default function useNoCoursesCardVM() {
  const session = useSelector(selectAuthSession);

  const name = session?.name || '';

  return {
    name,
  };
}
