import { RootState } from '../../store/types';

export const selectCourseLink = (linkId: MongoId) => (state: RootState) => {
  const { links } = state.db;
  return links.byId[linkId];
};

export const selectCourseLinks = (linkIds: MongoId[]) => (state: RootState) => {
  return linkIds.map((linkId) => selectCourseLink(linkId)(state));
};
