import { Dictionary } from '@reduxjs/toolkit';

import { Action as RouteAction, Location } from 'history';
import { buffers, eventChannel } from 'redux-saga';

import { AcadlyPage, PageStatus } from '../../pages/types';
import BrowserHistory from '../../utils/history';
import { waitForStateToHaveValue } from '../../utils/saga-helpers';
import { API } from './api-responses';
import { SuggestedActivity, SuggestedActivityType } from './types';

interface EntityDictionary<T> {
  byId: Dictionary<T>;
}

export const getFullId = <T>(state: EntityDictionary<T>, shortId: ShortId | MongoId) => {
  if (!shortId) return undefined;
  return Object.keys(state.byId).find((id) => id.endsWith(shortId));
};

export const getActivityById = <T>(state: EntityDictionary<T>, shortId: ShortId | MongoId): T | undefined => {
  const id = getFullId(state, shortId);
  return id ? state.byId[id] : undefined;
};

export function* waitUntilCoursePageIsReady() {
  yield waitForStateToHaveValue(
    (state) => state.pages[AcadlyPage.COURSE] || PageStatus.NOT_READY,
    PageStatus.READY
  );
}

export interface RouteUpdateEvent {
  location: Location<unknown>;
  action: RouteAction;
}

/**
 * Saga channel factory for route change updates
 */
export function createRouteUpdatesChannel() {
  return eventChannel<RouteUpdateEvent>(
    (emitter) =>
      BrowserHistory.listen((location, action) => {
        emitter({ location, action });
      }),
    buffers.none()
  );
}

export function baseSuggestedActivityFactory(suggestedActivity: API.SuggestedActivity): SuggestedActivity {
  return {
    id: suggestedActivity._id,
    courseId: suggestedActivity.identifiers.courseId,
    courseCode: suggestedActivity.identifiers.courseCode,
    courseTitle: suggestedActivity.identifiers.courseTitle,
    title: suggestedActivity.details.title || null,
    sequenceNum:
      (suggestedActivity.nodeType === SuggestedActivityType.ASSIGNMENT
        ? suggestedActivity.details.num
        : suggestedActivity.details.trueNum) ?? 0,
    publishedOn: suggestedActivity.details.publishedOn > 0 ? suggestedActivity.details.publishedOn : -1,
    isHidden: Boolean(suggestedActivity.hidden),
    isUsed: Boolean(suggestedActivity.used),
    createdOn: suggestedActivity.details.createdOn,
    createdBy: suggestedActivity.details.createdBy,
  };
}
