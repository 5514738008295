import { ReactNode, useState } from 'react';

import ExpandableListItem, { BaseItemProps, ItemProps } from './ExpandableListItem';

export const NEW_ITEM_ID = 'new_item';

interface VMProps<T extends BaseItemProps> {
  /**
   * called every time when accordion toogles expanded or collpased state
   * @param isExpanded current expand state after toggle
   * @param item item which is toggled, in case of new item, it will be `{ id: 'new_item' }`
   */
  onItemToggle?(isExpanded: boolean, item: T): void;
}

interface Props<T extends BaseItemProps> extends ItemProps<T>, VMProps<T> {
  items: T[];
  placeholder?: ReactNode;
  /**
   * Allow adding more items
   * @default false
   */
  canAddItems?: boolean;
  /**
   * Prop overrides for new item, if not specified fallback to
   * props provided for other items
   */
  newItemProps?: Partial<ItemProps<T>>;
}

const useExpandableListVM = <T extends BaseItemProps>({ onItemToggle }: VMProps<T>) => {
  const [expandedItemId, setExpandedItemId] = useState<string | null>(null);

  const onExpand = (item: T) => {
    setExpandedItemId(item.id);
    onItemToggle?.(true, item);
  };

  const onCollapse = (item: T) => {
    setExpandedItemId(null);
    onItemToggle?.(false, item);
  };

  return {
    expandedItemId,
    onExpand,
    onCollapse,
  };
};

const ExpandableList = <T extends BaseItemProps>({
  items,
  placeholder,
  canAddItems,
  renderSummary,
  renderDetails,
  newItemProps,
  className,
  classes,
  ...vmOptions
}: Props<T>) => {
  const { expandedItemId, onExpand, onCollapse } = useExpandableListVM(vmOptions);
  const isNewItemExpanded = expandedItemId === NEW_ITEM_ID;
  const newItem = { id: NEW_ITEM_ID } as T;

  return (
    <>
      {canAddItems && (
        <ExpandableListItem
          key={NEW_ITEM_ID}
          item={newItem}
          renderSummary={newItemProps?.renderSummary ?? renderSummary}
          renderDetails={newItemProps?.renderDetails ?? renderDetails}
          isExpanded={isNewItemExpanded}
          onToggle={() => (isNewItemExpanded ? onCollapse(newItem) : onExpand(newItem))}
          className={newItemProps?.className}
          classes={newItemProps?.classes}
          highlighted
        />
      )}
      {items.length === 0
        ? placeholder
        : items.map((item) => {
            const isExpanded = expandedItemId === item.id;
            return (
              <ExpandableListItem
                key={item.id}
                item={item}
                renderSummary={renderSummary}
                renderDetails={renderDetails}
                isExpanded={isExpanded}
                onToggle={() => (isExpanded ? onCollapse(item) : onExpand(item))}
                className={className}
                classes={classes}
              />
            );
          })}
    </>
  );
};

export default ExpandableList;
