import { Stack, StackProps, styled } from '@mui/material';

import BasicCourseWidget from './BasicCourseWidget';
import DemoCourseDrawer from './DemoCourseDrawer';
import ProCourseWidget from './ProCourseWidget';
import useDemoCourseVM from './vm';

const Root = styled((props: StackProps) => <Stack gap={5} {...props} />)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const DemoCourse = () => {
  const {
    handleClickCreateDemoCourse: _handleClickCreateDemoCourse,
    handleCloseDemoCourseDrawer,
    isDemoCourseDrawerOpen,
    isCreatingProDemoCourse,
    canCreateBasicCourse,
    canCreateProCourse,
  } = useDemoCourseVM();

  const handleClickCreateDemoCourse =
    (isPro = false) =>
    () => {
      _handleClickCreateDemoCourse(isPro);
    };

  return (
    <Root>
      {canCreateProCourse && <ProCourseWidget onCreateClick={handleClickCreateDemoCourse(true)} />}
      {canCreateBasicCourse && <BasicCourseWidget onCreateClick={handleClickCreateDemoCourse(false)} />}
      <DemoCourseDrawer
        open={isDemoCourseDrawerOpen}
        onClose={handleCloseDemoCourseDrawer}
        isDemoPro={isCreatingProDemoCourse}
      />
    </Root>
  );
};

export default DemoCourse;
