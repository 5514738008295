import urls from '../../app/urls';
import { format, formatTimeToDate } from '../../utils/datetime';
import request from '../../utils/request';
import { removeExtraKeysFromCourseUser } from '../courses/helpers';
import { API } from '../shared/api-responses';
import { AttendanceStatus, CopyToFutureClasses, ScheduleAttendanceRule } from '../shared/types';
import {
  CancelClassPayload,
  ClassId,
  CreateClassPayload,
  EditAttendanceSchedulePayload,
  EditClassAttendancePayload,
  EditClassTeamPayload,
  EditClassTimingsPayload,
  EditClassVenuePayload,
  RescheduleClassPayload,
  ScheduleAttendancePayload,
  StopProxyAttendancePayload,
} from './types';

export async function createClass({
  dates,
  startTime,
  endTime,
  isOnlineMeeting,
  type,
  venue,
}: CreateClassPayload) {
  const payload: API.CreateClassRequest = {
    dates: dates.map((date) => {
      const startDate = formatTimeToDate(startTime, date);
      const endDate = formatTimeToDate(endTime, date);
      return {
        scheStartTime: format(startDate, "yyyy-MM-dd'T'HH:mm"),
        scheEndTime: format(endDate, "yyyy-MM-dd'T'HH:mm"),
      };
    }),
    isOnlineMeeting: isOnlineMeeting ? 1 : 0,
    type,
    venue,
  };

  const { data } = await request.post<API.CreateClassResponse>(urls.createClass, payload);
  return data;
}

export async function editClassTitle(payload: API.EditClassTitleRequest) {
  await request.post(urls.editClassTitle, payload);
}

export async function editClassTimings(params: EditClassTimingsPayload) {
  const payload: API.EditClassTimingsRequest = {
    classId: params.classId,
    newStartTime: params.newStartTime,
    newEndTime: params.newEndTime,
    multiple: params.editSimilarClasses ? 1 : 0,
    notify: params.shouldNotifyStudent ? 1 : 0,
  };
  const { data } = await request.post<API.EditClassTimingsResponse>(urls.editClassTimings, payload);
  return data;
}

export async function rescheduleClass(params: RescheduleClassPayload) {
  const payload: API.CancelClassRequest = {
    classId: params.classId,
    hasMakeUp: 1,
    mark: 'canceled',
    newClassStartTime: format(params.newStartTime, "yyyyMMdd'T'HH:mm"),
    newClassEndTime: format(params.newEndTime, "yyyyMMdd'T'HH:mm"),
    announce: params.createAnnouncement ? 1 : 0,
    maintain: params.maintainActivitySequence ? 1 : 0,
    remove: 0,
    isOnlineMeeting: params.isOnline ? 1 : 0,
    venue: params.isOnline ? '' : params.venue,
  };
  await request.post(urls.cancelClass, payload);
}

export async function cancelClass(params: CancelClassPayload) {
  const payload: API.CancelClassRequest = {
    classId: params.classId,
    hasMakeUp: 0,
    mark: 'canceled',
    announce: params.createAnnouncement ? 1 : 0,
    maintain: 1,
    remove: params.removeFromTimeline ? 1 : 0,
    venue: '',
  };
  await request.post(urls.cancelClass, payload);
}

export async function editClassVenue(params: EditClassVenuePayload) {
  const payload: API.EditClassVenueRequest = {
    classId: params.classId,
    isOnlineMeeting: params.isOnline ? 1 : 0,
    newVenue: params.isOnline ? '' : params.newVenue,
    multiple: params.editSimilarClasses ? 1 : 0,
    notify: params.shouldNotifyStudent ? 1 : 0,
  };
  await request.post(urls.editClassVenue, payload);
}

export async function editClassTeam(params: EditClassTeamPayload) {
  const payload: API.EditClassTeamRequest = {
    classId: params.classId,
    inCharges: params.incharges.map((incharge) => removeExtraKeysFromCourseUser(incharge)),
    assistants: params.assistants.map(removeExtraKeysFromCourseUser),
    multiple: [],
  };

  if (params.editSimilarClasses.incharges) {
    payload.multiple.push('inCharge');
  }

  if (params.editSimilarClasses.assistants) {
    payload.multiple.push('assistants');
  }

  await request.put(urls.editClassTeam, payload);
}

export async function deleteClass({ classId }: API.DeleteClassRequest) {
  await request.delete<unknown>(urls.deleteClass(classId));
}

export async function getClassDetails({ classId }: ClassId) {
  const { data } = await request.get<API.GetClassDetailsResponse>(urls.getClassDetails(classId));
  return data;
}

export async function startClass({ classId, isOnline }: ClassId & { isOnline: boolean }) {
  const url = isOnline ? urls.startOnlineClass : urls.startClass;
  const payload: API.StartClassRequest = { classId, agent: 'web', locationAvailable: 0 };
  const { data } = await request.put<API.StartClassResponse>(url, payload);
  return data;
}

export async function endClass({ classId }: ClassId) {
  const { data } = await request.get<API.EndClassResponse>(urls.endClass(classId));
  return data;
}

export async function checkInToClass({ classId }: ClassId) {
  const payload: API.CheckInToClassRequest = { classId, agent: 'web', locationAvailable: 0 };
  const { data } = await request.put<API.CheckInToClassResponse>(urls.checkInToClass, payload);
  return data;
}

export async function editClassAgenda(payload: API.EditClassAgendaRequest) {
  await request.post(urls.editClassAgenda, payload);
}

export async function editClassTopics(payload: API.EditClassTopicsRequest) {
  await request.post(urls.editClassTopics, payload);
}

export async function getClassSummary(params: ClassId) {
  /**
   * FIXME: api server should send summary updatedOn key too
   */
  const { data } = await request.get<API.GetClassSummaryResponse>(urls.getClassSummary, { params });
  return data;
}

export async function editClassSummary(payload: API.EditClassSummaryRequest) {
  await request.post(urls.editClassSummary, payload);
}

export async function getClassParticipations({ classId }: ClassId) {
  const { data } = await request.get<API.GetClassParticipationsResponse>(urls.getClassParticipation(classId));
  return data;
}

export async function awardClassParticipationPoints(payload: API.AwardParticipationPointsRequest) {
  const { data } = await request.post<API.AwardParticipationPointsResponse>(
    urls.awardClassParticipationPoints,
    payload
  );
  return data;
}

export async function scheduleAttendance({ classId, ...params }: ScheduleAttendancePayload) {
  let paylod: API.ScheduleAttendanceRequest = {
    classId,
    followForFutureClasses: 0,
    futureClassType: CopyToFutureClasses.NO,
    scheduleRule: params.type,
    time: '',
  };

  if (params.copyToFutureClasses !== CopyToFutureClasses.NO) {
    paylod = {
      ...paylod,
      followForFutureClasses: 1,
      futureClassType: params.copyToFutureClasses,
    };
  }

  if (params.type === ScheduleAttendanceRule.TIME_SET) {
    paylod.time = format(params.time, 'HH:mm');
  }

  const { data } = await request.post<API.ScheduleAttendanceResponse>(urls.scheduleAttendance, paylod);
  return data;
}

export async function editAttendanceSchedule({ classId, ...params }: EditAttendanceSchedulePayload) {
  let payload: API.EditAttendanceScheduleRequest = {
    classId,
    remove: 1,
    followForFutureClasses: 0,
    futureClassType: CopyToFutureClasses.NO,
    scheduleRule: '',
    time: '',
  };

  if (params.copyToFutureClasses !== CopyToFutureClasses.NO) {
    payload = {
      ...payload,
      followForFutureClasses: 1,
      futureClassType: params.copyToFutureClasses,
    };
  }

  if (params.type === ScheduleAttendanceRule.UNSET) {
    payload = {
      ...payload,
      remove: 1,
    };
  } else if (params.type === ScheduleAttendanceRule.TIME_SET) {
    payload = {
      ...payload,
      remove: 0,
      scheduleRule: params.type,
      time: format(params.time, 'HH:mm'),
    };
  } else {
    payload = {
      ...payload,
      remove: 0,
      scheduleRule: params.type,
      time: '',
    };
  }

  const { data } = await request.put<API.ScheduleAttendanceResponse>(urls.editAttendanceSchedule, payload);
  return data;
}

export async function getClassAttendance({ classId }: ClassId) {
  const { data } = await request.get<API.GetClassAttendanceResponse>(urls.getClassAttendance(classId));
  return data;
}

export async function getMyAttendanceStatus({ classId }: ClassId) {
  const { data } = await request.get<API.GetMyAttendanceStatusResponse>(urls.getMyAttendanceStatus(classId));
  return data;
}

export async function startProxyAttendance(payload: API.StartProxyAttendanceRequest) {
  const { data } = await request.post<API.StartProxyAttendanceResponse>(urls.startProxyAttendance, payload);
  return data;
}

export async function stopProxyAttendance({ courseId, ...payload }: StopProxyAttendancePayload) {
  await request.post(urls.stopProxyAttendance, payload, {
    headers: { courseId },
  });
}

export async function getAttendanceResponders({ classId, courseId }: API.GetAttendanceRespondersRequest) {
  const { data } = await request.get<API.GetAttendanceRespondersResponse>(urls.getAttendanceResponders, {
    headers: { courseId },
    params: { classId },
  });
  return data;
}

export async function acknowledgeAttendanceStarted(payload: API.AcknowledgeAttendanceStartedRequest) {
  await request.post<API.AcknowledgeAttendanceStartedResponse>(urls.acknowledgeAttendanceStarted, payload);
}

export async function editClassAttendance({
  classId,
  attendanceStatusByStudentId,
}: EditClassAttendancePayload) {
  const payload: API.EditClassAttendanceRequest = {
    classId,
    present: [],
    absent: [],
    excused: [],
    late: [],
  };

  for (const [studentId, status] of Object.entries(attendanceStatusByStudentId)) {
    switch (status) {
      case AttendanceStatus.PRESENT:
        payload.present.push(studentId);
        break;
      case AttendanceStatus.ABSENT:
        payload.absent.push(studentId);
        break;
      case AttendanceStatus.EXCUSED:
        payload.excused.push(studentId);
        break;
      case AttendanceStatus.LATE:
        payload.late.push(studentId);
        break;
    }
  }

  await request.post(urls.editClassAttendance, payload);
}

export async function getClassesToCopyActivity({ courseId }: API.GetClassesToCopyActivityRequest) {
  const { data } = await request.get<API.GetClassesToCopyActivityResponse>(urls.getClassesToCopyActivity, {
    headers: { courseId },
  });
  return data;
}

export async function moveActivity(payload: API.MoveActivityRequest) {
  await request.post<API.MoveActivityResponse>(urls.moveActivity, payload);
}
