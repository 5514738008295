import React from 'react';

import { AttendanceProcessAttendeeFailure } from '../../../../../db/classes/types';
import AttendanceProcessErrorLayout from '../AttendanceProcessErrorLayout';
import { StyledUserCell, userCellClasses } from './styles';
import useAttendeeErrorVM from './vm';

interface Props {
  failure: AttendanceProcessAttendeeFailure;
}

const AttendeeError = ({ failure }: Props) => {
  const { getAttendanceErrorMessage } = useAttendeeErrorVM();

  return (
    <AttendanceProcessErrorLayout failure={failure}>
      <StyledUserCell
        title={failure.attendee.name}
        avatar={failure.attendee.avatar}
        subtitle={getAttendanceErrorMessage(failure.failureCode)}
        classes={{ subtitle: userCellClasses.subtitle }}
      />
    </AttendanceProcessErrorLayout>
  );
};

export default AttendeeError;
