import { ReactNode } from 'react';

import { styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  padding: theme.spacing(3, 5),

  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

interface Props {
  className?: string;
  children?: ReactNode;
  tabIndex?: number;
}

const Placeholder = ({ className, children, ...props }: Props) => {
  return (
    <Root className={className} {...props}>
      {children}
    </Root>
  );
};

export default Placeholder;
