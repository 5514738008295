import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import Alert from '../../../../../../../../../components/Alert';
import AlertButton from '../../../../../../../../../components/AlertButton';
import Button, { Props as ButtonProps } from '../../../../../../../../../components/Button';
import Tooltip from '../../../../../../../../../components/Tooltip';
import { i18nNS } from '../../../../../../../../../i18n';
import Trash2Icon from '../../../../../../../../../icons/Trash2Icon';
import useDeleteVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  edge?: ButtonProps['edge'];
  className?: string;
  classes?: Partial<Record<'root' | 'action', string>>;
}

const Delete = ({ commentId, edge, className, classes }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { canRemove, handleDeleteComment } = useDeleteVM({ commentId });

  if (!canRemove) return null;

  return (
    <AlertButton
      component={forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
        <Tooltip title={t('delete', { ns: i18nNS.GLOSSARY })} className={clsx(className, classes?.root)}>
          <span>
            <Button
              {...props}
              ref={ref}
              variant="text"
              color="error"
              size="small"
              edge={edge}
              className={classes?.action}
            />
          </span>
        </Tooltip>
      ))}
      aria-label={t('click_to_delete_this_post', { ns: i18nNS.COMMENTS })}
    >
      <Trash2Icon fontSize="small" color="inherit" aria-hidden />
      <Alert>
        <Alert.Header>{t('warning', { ns: i18nNS.GLOSSARY })}</Alert.Header>
        <Alert.Body>{t('do_you_want_to_remove_this_comment', { ns: i18nNS.COMMENTS })}</Alert.Body>
        <Alert.Footer>
          <Alert.Action variant="text">{t('no', { ns: i18nNS.GLOSSARY })}</Alert.Action>
          <Alert.Action color="error" onClick={handleDeleteComment}>
            {t('yes', { ns: i18nNS.GLOSSARY })}
          </Alert.Action>
        </Alert.Footer>
      </Alert>
    </AlertButton>
  );
};

export default Delete;
