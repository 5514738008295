import { Query } from '../db/queries/types';
import { CourseRole, QueryRole, QueryStatus, TipStatusKey } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { getIsActivityPublished } from '../utils/activities';

export function getCanDeleteQuery(query: Query | undefined, role = CourseRole.STUDENT) {
  if (!query) return false;

  return query.status === QueryStatus.PENDING && role !== CourseRole.STUDENT && role !== CourseRole.TA;
}

export function getCanHideQuery(
  query: Query | undefined,
  role = CourseRole.STUDENT,
  isCourseArchived: boolean | undefined
) {
  if (!query) return false;
  if (role === CourseRole.STUDENT) return false;
  if (query.isHidden || isCourseArchived) return false;

  return query.status === QueryStatus.OPEN;
}

export function getCanApproveQuery(query: Query | undefined, role = CourseRole.STUDENT) {
  if (!query) return false;
  if (query.status !== QueryStatus.PENDING) return false;

  return role !== CourseRole.STUDENT && role !== CourseRole.TA;
}

export function getIsActionBarVisible(
  query: Query | undefined,
  role = CourseRole.STUDENT,
  isCourseArchived: boolean | undefined
) {
  if (!query) return false;

  const canHideQuery = getCanHideQuery(query, role, isCourseArchived);
  if (canHideQuery) return true;

  const canApproveQuery = getCanApproveQuery(query, role);
  return canApproveQuery;
}

export function getIsCreator(query: Query | undefined, currentUserId: MongoId) {
  if (!query) return false;
  if (query.isAnonymous) return true;

  return query.createdBy.userId === currentUserId;
}

export function getCanCloseQuery(
  query: Query | undefined,
  currentUserId: MongoId,
  isCourseArchived: boolean | undefined
) {
  if (!query) return false;
  if (query.isHidden || isCourseArchived) return false;
  if (query.status !== QueryStatus.OPEN) return false;
  if (!query.isAnonymous && query.createdBy.userId !== currentUserId) return false;
  return query.myRole === QueryRole.ASKER;
}

export function getCanUpvoteQuery(
  query: Query | undefined,
  currentUserId: MongoId,
  role = CourseRole.STUDENT,
  isCourseArchived: boolean | undefined
) {
  if (!query) return false;
  if (role !== CourseRole.STUDENT) return false;
  if (query.isHidden || isCourseArchived) return false;
  if (!query.isAnonymous && query.createdBy.userId === currentUserId) return false;
  if (query.status !== QueryStatus.OPEN) return false;
  return query.myRole === QueryRole.NONE;
}

export function getCanShowUpvoteCounts(
  query: Query | undefined,
  currentUserId: MongoId,
  role = CourseRole.STUDENT,
  isCourseArchived: boolean | undefined
) {
  if (!query) return false;
  if (role !== CourseRole.STUDENT) return true;

  const canUpvoteQuery = getCanUpvoteQuery(query, currentUserId, role, isCourseArchived);
  return !canUpvoteQuery;
}

export function getActivityMessage(
  isAnonymous: boolean,
  isMobile: boolean,
  role = CourseRole.STUDENT,
  status = QueryStatus.PENDING
) {
  if (isAnonymous && status === QueryStatus.PENDING) {
    return i18n.t(
      'anonymous_queries_will_have_to_be_approved_by_the_course_instructor_first_before_they_show_up_in_the_class',
      { ns: i18nNS.QUERY }
    );
  }

  if (isMobile) {
    if (role !== CourseRole.STUDENT && status === QueryStatus.PENDING) {
      return i18n.t(
        'students_can_interact_with_this_query_only_after_it_has_been_approved_by_a_member_of_the_course_team',
        { ns: i18nNS.QUERY }
      );
    }
    return i18n.t(
      'to_post_a_comment_on_this_query_please_tap_on_the_icon_on_the_top_right_corner_of_the_screen',
      { ns: i18nNS.QUERY }
    );
  }

  return null;
}

export function getUpvotesText(query: Query | undefined, role = CourseRole.STUDENT) {
  if (!query) return null;
  const numAskers = query.askers;
  const isStudent = role === CourseRole.STUDENT;

  if (!isStudent) {
    return i18n.t('N_student_has_this_query', {
      ns: i18nNS.QUERY,
      count: numAskers + 1,
    });
  }

  const isAsker = query.myRole === QueryRole.ASKER;

  if (isAsker && numAskers === 0)
    return i18n.t('only_you_have_this_query', {
      ns: i18nNS.QUERY,
    });

  if (isAsker) {
    return i18n.t('you_and_N_other_student_has_the_same_query', {
      ns: i18nNS.QUERY,
      count: numAskers,
    });
  }

  if (numAskers === 0) return i18n.t('no_other_student_has_the_same_query', { ns: i18nNS.QUERY });

  return i18n.t('N_other_student_has_the_same_query', {
    ns: i18nNS.QUERY,
    count: numAskers,
  });
}

export function getCanFetchQueryComments({
  query,
  role,
}: {
  query: Query | undefined;
  role: CourseRole | undefined;
}) {
  if (role === CourseRole.STUDENT) return true;

  if (!query) return false;
  if (!query.isAnonymous) return true;

  return query.status !== QueryStatus.PENDING;
}

export function getCanSendQueryComments(query: Query | undefined, isCourseArchived: boolean | undefined) {
  if (isCourseArchived) return false;

  if (!query) return false;

  const isQueryClosed = query.status === QueryStatus.CLOSED;
  return !isQueryClosed;
}

export function getEmptyCommentsMessageForQuery(query: Query | undefined) {
  if (!query) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  const isQueryClosed = query.status === QueryStatus.CLOSED;
  if (isQueryClosed) {
    return i18n.t('you_can_no_longer_comment_here_since_the_author_of_this_query_has_marked_it_as_closed', {
      ns: i18nNS.QUERY,
    });
  }

  const isQueryPublished = getIsActivityPublished(query);
  if (!isQueryPublished) {
    return i18n.t('comments_will_appear_here_once_this_activity_is_published', { ns: i18nNS.CLASS });
  }

  return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });
}

export function getQueryPageTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'queryMainApprove':
      return i18n.t(
        'if_this_is_an_appropriate_query_use_this_button_to_approve_it_once_approved_the_course_members_will_be_able_to_access_the_query',
        { ns: i18nNS.QUERY }
      );
    case 'queryMainDelete':
      return i18n.t(
        'if_this_query_is_inappropriate_use_the_delete_button_to_discard_it_removed_queries_cannot_be_retrieved_later',
        { ns: i18nNS.QUERY }
      );
    case 'queryMainHide':
      return i18n.t(
        'if_this_query_is_inappropriate_you_can_hide_it_from_the_course_team_by_using_the_hide_button',
        { ns: i18nNS.QUERY }
      );
    default:
      return '';
  }
}
