import { Stack, StackProps, styled, Typography } from '@mui/material';

import Image from '../../../../../../../components/Image';
import { buttonAsDiv } from '../../../../../../../styles/mixins';
import { generateClasses } from '../../../../../../../utils/helpers';
import CommentActions from './CommentActions';
import CommentStatsButton from './CommentStatsButton';

export const commentClasses = {
  ...generateClasses('Comment', ['clickable', 'actions', 'alignActionsWithCreator']),
};

export const Root = styled((props: StackProps) => (
  <Stack direction="column" alignItems="flex-start" gap={4} {...props} />
))(({ theme }) => ({
  position: 'relative',
  [`&.${commentClasses.clickable}`]: {
    cursor: 'pointer',
  },
  '&:hover': {
    [`& .${commentClasses.actions}`]: {
      display: 'flex',
    },
  },
}));

export const Actions = styled(CommentActions)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(3),
  zIndex: 100,
  display: 'none',
  [`&.${commentClasses.alignActionsWithCreator}`]: {
    top: theme.spacing(3),
  },
}));

export const RemovedCommentContent = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.grey[600],
}));

export const ImageButton = styled('button')(({ theme }) => ({
  ...buttonAsDiv,
  maxWidth: '100%',
  width: '100%',
  maxHeight: 300,
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  objectFit: 'cover',
  maxWidth: '100%',
  minHeight: 300,
}));

export const StyledCommentStatsButton = styled(CommentStatsButton)(({ theme }) => ({
  /** adjust top margin to neglect impact of gap between items */
  marginTop: `calc(-1 * (${theme.spacing(3)} - 1px))`,
  /** adjust bottom margin to neglect impact of padding on parent */
  marginBottom: `calc(-1 * (${theme.spacing(3)} - 1px))`,
}));
