import CommentGroup from './CommentGroup';
import CommentDialog from './CommentGroup/Comment/CommentDialog';
import useCommentGroupsVM from './vm';

const CommentGroups = () => {
  const { commentGroups } = useCommentGroupsVM();

  return (
    <>
      {commentGroups.map((commentGroup, index) => (
        <CommentGroup key={index} commentGroup={commentGroup} />
      ))}
      <CommentDialog />
    </>
  );
};

export default CommentGroups;
