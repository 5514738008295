import { useMemo, useState } from 'react';

import { randomStr } from '../../../utils/helpers';

export interface VMProps {
  onBlur?: () => void;
}

const useStripeInput = ({ onBlur }: VMProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    onBlur?.();
    setIsFocused(false);
  };
  const handleFocus = () => setIsFocused(true);

  const ids = useMemo(
    () => ({
      inputWrapper: `input_wrapper_${randomStr(6)}`,
      helperText: `helper_text_${randomStr(6)}`,
    }),
    []
  );

  return { handleBlur, handleFocus, ids, isFocused };
};

export default useStripeInput;
