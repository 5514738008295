import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentCourse } from '../../../db/courses/selectors';
import { getCanEditCourseDeadline } from '../../helpers';
import { getIsDeadlineOver } from '../helpers';

const useDeadlineOverVM = () => {
  const course = useSelector(selectCurrentCourse);

  const [isOpenCourseEndedDrawer, setOpenCourseEndedDrawer] = useState(false);

  const closeCourseEndedDrawer = () => {
    setOpenCourseEndedDrawer(false);
  };

  const openCourseEndedDrawer = () => {
    setOpenCourseEndedDrawer(true);
  };

  const isVisible = getIsDeadlineOver({ course });

  const canEditCourseDeadline = getCanEditCourseDeadline(course);

  return {
    canEditCourseDeadline,
    closeCourseEndedDrawer,
    course,
    isVisible,
    isOpenCourseEndedDrawer,
    openCourseEndedDrawer,
  };
};

export default useDeadlineOverVM;
