import { forwardRef, ReactNode, Ref } from 'react';

import clsx from 'clsx';
import { Editable, Slate } from 'slate-react';

import ElementNode from '../ElementNode';
import LeafNode from '../LeafNode';
import { Root } from './styles';
import useRichTextView, { RichTextViewRef, VMProps } from './vm';

export interface Props extends VMProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<Record<'root' | 'editable', string>>;
  tabIndex?: number;
}

const RichTextView = (
  { children, className, classes, tabIndex, ...vmProps }: Props,
  ref: Ref<RichTextViewRef>
) => {
  const { editor, value } = useRichTextView(vmProps, ref);

  return (
    <Root className={clsx(className, classes?.root)} tabIndex={tabIndex}>
      <Slate editor={editor} value={value}>
        <Editable readOnly renderElement={ElementNode} renderLeaf={LeafNode} className={classes?.editable} />
        {children}
      </Slate>
    </Root>
  );
};

export default forwardRef(RichTextView);
