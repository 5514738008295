import { styled, Typography, TypographyProps } from '@mui/material';

import Progressbar from '../../../components/Progressbar';
import useCreateCourseHeaderVM from './vm';

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 10),
  minHeight: 80,
}));

const Title = styled((props: TypographyProps) => <Typography {...props} variant="h4Bold" />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

interface Props {
  id?: string;
}

const CreateCourseHeader = ({ id }: Props) => {
  const { progress, title } = useCreateCourseHeaderVM();
  return (
    <>
      <Header id={id}>
        <Title>{title}</Title>
      </Header>
      <Progressbar shape="linear" variant="determinate" value={progress} />
    </>
  );
};

export default CreateCourseHeader;
