import { AttendanceProcessFailure } from '../../../../../db/classes/types';
import { ClassTeamUser } from '../../../../../db/shared/types';

export function getSecondsPassed({
  failure,
  attendanceInProgress,
}: {
  failure: AttendanceProcessFailure;
  attendanceInProgress: {
    isInProgress: boolean;
    courseId: string;
    classId: string;
    isProxy: boolean;
    available: number;
    present: number;
    enrolled: number;
    nowMarkedPresent: number;
    startedAt: number;
    taker: ClassTeamUser;
    failures: AttendanceProcessFailure[];
    failuresLastSyncedAt: number;
  } | null;
}) {
  if (!attendanceInProgress) return 0;
  return failure.failedOn - attendanceInProgress.startedAt;
}
