import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { markComment } from '../../../../../../../../../db/comments/actions';
import { selectComment, selectCommentMyReactions } from '../../../../../../../../../db/comments/selectors';
import { MarkCommentAction } from '../../../../../../../../../db/shared/types';
import { useRequestDispatch } from '../../../../../../../../../utils/request-actions';
import { CommentsContext } from '../../../../../../../../Context';

export interface Props {
  commentId: MongoId;
}

const useThankVM = ({ commentId }: Props) => {
  const requestDispatch = useRequestDispatch();

  const comment = useSelector(selectComment(commentId));
  const myReactions = useSelector(selectCommentMyReactions(commentId));

  const { likingCommentId, thankingCommentId, setThankingCommentId } = useContext(CommentsContext);

  const hasLiked = Boolean(myReactions?.hasLiked);
  const hasThanked = Boolean(myReactions?.hasThanked);
  const canThank = !(comment?.isDeleted || hasLiked);

  const isThankLoading = thankingCommentId === commentId;
  const isThankDisabled = likingCommentId === commentId || thankingCommentId === commentId;

  const handleMarkComment = async (mark: MarkCommentAction.THANK | MarkCommentAction.UNTHANK) => {
    try {
      setThankingCommentId(commentId);
      await requestDispatch(markComment, { commentId: commentId, mark });
    } finally {
      setThankingCommentId(null);
    }
  };

  return {
    canThank,
    isThankLoading,
    isThankDisabled,
    hasThanked,
    handleMarkComment,
    commentCreatorName: comment?.createdBy.name,
  };
};

export default useThankVM;
