import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectAuthSession } from '../auth/store/selectors';
import { selectCourse, selectCourseRole } from '../db/courses/selectors';
import { CourseParams } from '../pages/routes';
import { UserWithRole } from '../types';

export const useCurrentUserWithRole = (): UserWithRole => {
  const session = useSelector(selectAuthSession);
  const role = useSelector(selectCourseRole());

  return {
    userId: session?.userId || '',
    avatar: session?.avatar || '',
    name: session?.name || '',
    role,
  };
};

export const useCourseCode = (courseId?: ShortId | MongoId) => {
  const { courseShortId } = useParams<CourseParams>();
  const course = useSelector(selectCourse(courseId || courseShortId));
  return course?.code;
};
