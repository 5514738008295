import en from './en';

type Translation = {
  [key in keyof typeof en]: string;
};

type CourseTranslations = {
  [lang: string]: Translation;
};

export const courseTranslations: CourseTranslations = {
  en,
};
