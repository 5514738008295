import { forwardRef, memo, ReactNode, Ref } from 'react';

import { Stack } from '@mui/material';

import clsx from 'clsx';
import { Editor as SlateEditor } from 'slate';
import { Editable, Slate } from 'slate-react';

import ElementNode from '../ElementNode';
import LeafNode from '../LeafNode';
import { Props as CreateFormulaOverlayProps } from '../plugins/formula/CreateFormulaOverlay';
import Toolbar, { Props as ToolbarProps, ToolbarCustomizationProps } from '../Toolbar';
import { Body, Error } from './styles';
import useEditorVM, { VMProps } from './vm';

export interface Props extends VMProps {
  children?: ReactNode;
  className?: string;
  classes?: Partial<Record<'root' | 'body' | 'editable' | 'toolbar', string>> & ToolbarProps['classes'];
  i18n?: CreateFormulaOverlayProps['i18n'];
  placeholder?: string;
  renderCreateFormulaOverlay: CreateFormulaOverlayProps['renderOverlay'];
  spellCheck?: boolean;
  /**
   * Can be used to customize toolbar, set to false to hide the toolbar.
   * @default
   * {
   *   canFormatText: true,
   *   canInsertFormula: true,
   *   canUploadImage: true
   * }
   */
  toolbar?: false | ToolbarCustomizationProps;
  /** @default true */
  showErrors?: boolean;
  errorMessage?: string;
  /** @default false */
  readOnly?: boolean;
  /** @default false */
  autoFocus?: boolean;
}

const Editor = (
  {
    className,
    classes,
    children,
    spellCheck,
    placeholder,
    i18n,
    renderCreateFormulaOverlay,
    toolbar = {
      canFormatText: true,
      canInsertFormula: true,
      canUploadImage: true,
    },
    showErrors = true,
    errorMessage,
    readOnly = false,
    autoFocus = false,
    ...vmProps
  }: Props,
  ref: Ref<SlateEditor>
) => {
  const {
    editor,
    onBlur: handleBlur,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    value,
  } = useEditorVM(vmProps, ref);

  return (
    <Stack gap={4} className={clsx(classes?.root, className)}>
      <Slate editor={editor} value={value} onChange={handleChange}>
        <Body className={clsx(classes?.body)}>
          <Editable
            spellCheck={spellCheck}
            renderElement={ElementNode}
            renderLeaf={LeafNode}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            className={clsx(classes?.editable)}
            readOnly={readOnly}
            autoFocus={autoFocus}
          />
          {children}
        </Body>
        {showErrors && errorMessage && <Error>{errorMessage}</Error>}
        {toolbar !== false && (
          <Toolbar
            {...toolbar}
            i18n={i18n}
            className={clsx(classes?.toolbar)}
            classes={{
              ...classes,
              root: classes?.toolbar,
            }}
            renderCreateFormulaOverlay={renderCreateFormulaOverlay}
          />
        )}
      </Slate>
    </Stack>
  );
};

export default memo(forwardRef(Editor));
