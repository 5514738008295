import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { joiResolver } from '@hookform/resolvers/joi';

import { setCourseEndDate as setCourseEndDateAction } from '../../../../db/courses/actions';
import { selectCurrentCourse } from '../../../../db/courses/selectors';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  onClose: () => void;
}

const useEditCourseDatesVM = ({ onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const currentCourse = useSelector(selectCurrentCourse);

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      startDate: currentCourse?.schedule.startDate || -1,
      endDate: currentCourse?.schedule.endDate || -1,
    },
    mode: 'all',
  });

  const { setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (data.endDate <= 0) return;
    try {
      await requestDispatch(setCourseEndDateAction, { endDate: data.endDate });
      onClose();
    } catch {}
  };

  useEffect(
    function init() {
      setValue('startDate', currentCourse?.schedule.startDate ?? 0);
      setValue('endDate', currentCourse?.schedule.endDate ?? 0);
    },
    [setValue, currentCourse?.schedule.startDate, currentCourse?.schedule.endDate]
  );

  return {
    formMethods,
    onSubmit,
    initialEndDate: currentCourse?.schedule.endDate,
  };
};

export default useEditCourseDatesVM;
