import { styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../components/Typography';

export const Text = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="background.paper" component="div" {...props} />
))(({ theme }) => ({
  '& p:first-of-type': {
    marginTop: 0,
  },
  '& p:last-of-type': {
    marginBottom: 0,
  },
}));
