import React from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowBack as BackIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { Slide, Stack, useMediaQuery } from '@mui/material';

import Button from '../../components/Button';
import Drawer from '../../components/Drawer';
import Header from '../../components/Header';
import TextField from '../../components/HookFormControls/TextField';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import { useJoinAnExistingCourseDrawerVm, VMProps } from './vm';

interface Props extends VMProps {}

const JoinAnExistingCourseDrawer = (vmOptions: Props) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);
  const isMobile = useMediaQuery(forMobile);

  const {
    bodyRef,
    formMethods,
    onSubmit,
    courseDetails,
    onCloseDrawer: handleCloseDrawer,
    onBack: handleBack,
  } = useJoinAnExistingCourseDrawerVm(vmOptions);

  const { control, handleSubmit } = formMethods;

  return (
    <Drawer
      anchor="right"
      open={vmOptions.open}
      onClose={handleCloseDrawer}
      header={
        isMobile
          ? (id) => (
              <Header
                id={id}
                left={{ children: <BackIcon />, onClick: courseDetails ? handleBack : handleCloseDrawer }}
                right={{
                  component: Button,
                  buttonType: 'icon',
                  children: <CheckIcon />,
                  onClick: handleSubmit(onSubmit),
                }}
              >
                {t(courseDetails ? 'course_details' : 'enter_join_code', { ns: i18nNS.COURSES })}
              </Header>
            )
          : (id) => (
              <Header
                id={id}
                left={{ children: courseDetails ? <BackIcon /> : null, onClick: handleBack }}
                right={{ children: <CloseIcon />, onClick: handleCloseDrawer }}
              >
                {t(courseDetails ? 'course_details' : 'enter_join_code', { ns: i18nNS.COURSES })}
              </Header>
            )
      }
      footer={
        isMobile ? null : (
          <Button
            onClick={handleSubmit(onSubmit)}
            aria-label={
              courseDetails
                ? t('click_join_course_button_to_join_the_course', { ns: i18nNS.COURSES })
                : t('click_continue_button_to_submit_the_joincode', { ns: i18nNS.COURSES })
            }
          >
            {courseDetails
              ? t('join_course', { ns: i18nNS.COURSES })
              : t('continue', { ns: i18nNS.GLOSSARY })}
          </Button>
        )
      }
      bodyRef={bodyRef}
    >
      {!courseDetails && (
        <Stack gap={4}>
          <Typography variant="paragraphRegular" color="grey.800">
            {t('enter_the_course_join_code_to_enroll_in_the_course_as_a_student', { ns: i18nNS.COURSES })}
          </Typography>
          <TextField
            placeholder={t('six_character_join_code', { ns: i18nNS.COURSES })}
            label={t('join_code', { ns: i18nNS.COURSES })}
            name="joinCode"
            control={control}
            fullWidth
          />
        </Stack>
      )}
      {courseDetails && (
        <Slide in direction="left" timeout={300}>
          <Stack gap={4}>
            <Typography variant="h6Medium" color="warning.500" component="div">
              {t('you_will_be_enrolled_as_a_student_in_the_following_course', { ns: i18nNS.COURSES })}
            </Typography>
            <Typography variant="h6Bold" color="grey.800" component="div">
              {`${courseDetails.courseCode}: ${courseDetails.title}`}
            </Typography>
            <Stack>
              <Typography variant="paragraphMedium" color="grey.600" component="div">
                {courseDetails.universityName}
              </Typography>
              <Typography variant="paragraphMedium" color="grey.600" component="div">
                {courseDetails.admin.name}
              </Typography>
            </Stack>
          </Stack>
        </Slide>
      )}
    </Drawer>
  );
};

export default JoinAnExistingCourseDrawer;
