import { createPusherEvent } from '../../pusher/helpers';
import {
  ClassActivityPusherEvent,
  ClassPusherEvent,
  PusherChannel,
  PusherEventName,
} from '../../pusher/types';
import { API } from '../shared/api-responses';
import { ClassIncharge, WithCurrentUser } from '../shared/types';

export type DiscussionPublishedEvent = ClassPusherEvent<{
  classNum: number;
  discussionId: MongoId;
  details: API.BaseDiscussion['details'];
  stats: API.BaseDiscussion['stats'];
  activities: API.BaseDiscussion['activities'];
}>;

export type DiscussionPublishedPayload = WithCurrentUser<
  DiscussionPublishedEvent & {
    /** Indicates whether discussion data exists in our redux state */
    doesExist: boolean;
  }
>;

export const discussionPublishedEvent = createPusherEvent<DiscussionPublishedEvent>(
  PusherEventName.DISCUSSION_PUBLISHED,
  { channels: [PusherChannel.COURSE] }
);

export type WordCloudAvailableEvent = ClassActivityPusherEvent<{
  // FIXME: convert plural discussions to singular discussion
  activityType: 'discussions';
  commentsUsed: API.WordCloud['commentsUsed'];
  totalComments: API.WordCloud['totalComments'];
}>;

export const wordCloudAvailableEvent = createPusherEvent<WordCloudAvailableEvent>(
  PusherEventName.WORD_CLOUD_AVAILABLE,
  { channels: [PusherChannel.COURSE] }
);

export type WordCloudGeneratedEvent = ClassActivityPusherEvent<{
  // FIXME: convert plural discussions to singular discussion
  activityType: 'discussions';
  generatedAt: API.WordCloud['generatedAt'];
  image: API.WordCloud['image'];
  wordList: API.WordCloud['wordList'];
  commentsUsed: API.WordCloud['commentsUsed'];
  totalComments: API.WordCloud['totalComments'];
  removedWords: API.WordCloud['removedWords'];
  sender: ClassIncharge;
  totalWords: number;
}>;

export const wordCloudGeneratedEvent = createPusherEvent<WordCloudGeneratedEvent>(
  PusherEventName.WORD_CLOUD_GENERATED,
  { channels: [PusherChannel.COURSE] }
);
