import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import i18n from '../../i18n';
import { debounce } from '../../utils/helpers';
import { FaqAnswerDialogData } from './FaqAnswerDialog';
import { FaqQuestion, FaqQuestionWithSectionTitle, FaqSection } from './types';

export default function useFaqVM() {
  const lang = i18n.language;

  const prevQuestionByTitle = useRef(new Map<string, FaqQuestionWithSectionTitle>());
  const nextQuestionByTitle = useRef(new Map<string, FaqQuestionWithSectionTitle>());

  const [search, setSearch] = useState('');

  const [answerDialogData, setAnswerDialogData] = useState<FaqAnswerDialogData | null>(null);

  const [faqSections, setFaqSections] = useState<FaqSection[]>([]);
  const [filteredFaqs, setFilteredFaqs] = useState<FaqSection[]>([]);

  const handleSearch = useMemo(() => {
    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      const escappedExpression = e.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const regex = new RegExp(escappedExpression, 'i');
      const result = faqSections.filter(({ questions }) =>
        questions.some((question) => regex.test(question.title))
      );
      setFilteredFaqs(result);
    }, 500);
  }, [faqSections]);

  const handleShowAnswer = (section: FaqSection) => (question: FaqQuestion) => {
    const nextQuestion = nextQuestionByTitle.current.get(question.title);
    const prevQuestion = prevQuestionByTitle.current.get(question.title);
    setAnswerDialogData({
      nextQuestionTitle: nextQuestion?.title || '',
      previousQuestionTitle: prevQuestion?.title || '',
      question,
      sectionTitle: section.title,
    });
  };

  const handleCloseAnswer = () => setAnswerDialogData(null);

  const handleShowNextQuestion = () => {
    const { question } = answerDialogData || {};
    if (!question) return;
    const nextQuestion = nextQuestionByTitle.current.get(question.title);
    if (!nextQuestion) return;
    setAnswerDialogData({
      nextQuestionTitle: nextQuestionByTitle.current.get(nextQuestion.title)?.title || '',
      previousQuestionTitle: question.title,
      question: nextQuestion,
      sectionTitle: nextQuestion.sectionTitle,
    });
  };

  const handleShowPreviousQuestion = () => {
    const { question } = answerDialogData || {};
    if (!question) return;
    const prevQuestion = prevQuestionByTitle.current.get(question.title);
    if (!prevQuestion) return;
    setAnswerDialogData({
      nextQuestionTitle: question.title,
      previousQuestionTitle: prevQuestionByTitle.current.get(prevQuestion.title)?.title || '',
      question: prevQuestion,
      sectionTitle: prevQuestion.sectionTitle,
    });
  };

  const initQuestionMaps = (sections: FaqSection[]) => {
    prevQuestionByTitle.current.clear();
    nextQuestionByTitle.current.clear();

    const questions: FaqQuestionWithSectionTitle[] = [];

    for (const section of sections) {
      for (const question of section.questions) {
        questions.push({ ...question, sectionTitle: section.title });
      }
    }

    for (let i = 0; i < questions.length - 1; i++) {
      const question = questions[i];
      const nextQuestion = questions[i + 1];
      nextQuestionByTitle.current.set(question.title, nextQuestion);
      if (i === 0) continue;
      const prevQuestion = questions[i - 1];
      prevQuestionByTitle.current.set(question.title, prevQuestion);
    }
  };

  useEffect(
    function fetchFaqs() {
      fetch(`/faq/${lang}.json`)
        .then<FaqSection[]>((response) => response.json())
        .then((sections) => {
          setFaqSections(sections);
          setFilteredFaqs(sections);
          initQuestionMaps(sections);
        });
    },
    [lang]
  );

  return {
    answerDialogData,
    faqSections: filteredFaqs,
    handleCloseAnswer,
    handleSearch,
    handleShowAnswer,
    handleShowNextQuestion,
    handleShowPreviousQuestion,
    search,
  };
}
