import '../i18n';

import { Route, Switch } from 'react-router-dom';

import AuthModule from '../auth/AuthModule';
import routes from '../pages/routes';
import Playground from '../playground';
import AppError from './AppError';
import GlobalProvider from './GlobalProvider';
import RootModule from './RootModule';

const App = () => {
  return (
    <GlobalProvider>
      <Switch>
        <Route path={[routes.login.path, routes.signup.path]}>
          <AuthModule />
        </Route>
        {process.env.REACT_APP_ENV === 'development' && (
          <Route path={routes.playground.path}>
            <Playground />
          </Route>
        )}
        <Route path={routes.root.path}>
          <RootModule />
        </Route>
      </Switch>
      <AppError />
    </GlobalProvider>
  );
};

export default App;
