import { MouseEvent, useCallback } from 'react';

import { SelectionMode } from './types';

export interface VMProps {
  onChange: (value: string[]) => void;
  /** @default SelectionMode.SINGLE */
  selectionMode?: SelectionMode;
  value: string[];
}

export default function useToggleButtonGroupVM({
  onChange,
  selectionMode = SelectionMode.SINGLE,
  value,
}: VMProps) {
  const getIsSelected = useCallback((itemValue: string) => value.includes(itemValue), [value]);

  const toggleItem = useCallback(
    (itemValue: string) => (e: MouseEvent<HTMLElement>) => {
      const isSelected = getIsSelected(itemValue);
      if (isSelected) {
        onChange(value.filter((v) => v !== itemValue));
      } else {
        onChange(selectionMode === SelectionMode.SINGLE ? [itemValue] : [...value, itemValue]);
      }
    },
    [getIsSelected, onChange, selectionMode, value]
  );

  return { getIsSelected, toggleItem };
}
