import { createReducer } from '@reduxjs/toolkit';

import { commentCreated, fetchComments, markCommentsAsSeen } from '../comments/actions';
import { closeCourse, fetchCourseDetails, hideSuggestedActivity, openCourse } from '../courses/actions';
import {
  createDiscussion,
  deleteDiscussion,
  discussionPublished,
  markDiscussionAsViewed,
  publishDiscussion,
} from '../discussions/actions';
import {
  createPoll,
  deletePoll,
  fetchPollDetails,
  pollPublished,
  publishPoll,
  submitPoll,
} from '../polls/actions';
import {
  approveQuery,
  closeQuery,
  createQuery,
  deleteQuery,
  markQueryAsViewed,
  queryApproved,
  queryCreated,
} from '../queries/actions';
import {
  createQuiz,
  deleteQuiz,
  fetchQuizDetails,
  publishQuiz,
  quizPublished,
  submitQuiz,
} from '../quizzes/actions';
import {
  createResource,
  deleteResource,
  incrementResourceViews,
  publishResource,
  resourcePublished,
} from '../resources/actions';
import createCommentReducer from '../shared/reducers/comments';
import {
  Activities,
  ActivityType,
  AttendanceProcessFailureType,
  AttendanceStatus,
  ClassStatus,
  CommentContext,
  CourseRole,
  QueryToBeDone,
  ScheduleAttendanceRule,
  TimelineItemType,
  ToBeDone,
} from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  attendanceMarked,
  attendanceScheduledToStart,
  attendeeAvailable,
  attendeeFailure,
  awardClassParticipationPoints,
  checkedInToClass,
  checkInToClass,
  classInchargeSet,
  classTeamEdited,
  clearLocalAttendanceData,
  deleteClass,
  editAttendanceSchedule,
  editClassAgenda,
  editClassAttendance,
  editClassSummary,
  editClassTeam,
  editClassTitle,
  editClassTopics,
  endClass,
  fetchAttendanceResponders,
  fetchClassAttendance,
  fetchClassDetails,
  fetchClassParticipations,
  fetchClassSummary,
  fetchMyAttendanceStatus,
  proxyAttendanceStarted,
  proxyAttendanceStopped,
  scheduleAttendance,
  startClass,
  startProxyAttendance,
  stopProxyAttendance,
} from './actions';
import { activityPublished } from './case-reducers';
import {
  createClassFactory,
  getClassAttendance,
  getClassRole,
  getClassStatus,
  getClassTeam,
  getClassTiming,
  suggestedActivityItemFactory,
} from './helpers';
import { AttendanceInProgressData, Class } from './types';

/**
 * This state slice will contain the classes of current course only
 * when a course page is closed this state data should be cleared
 */
interface Classes extends Activities<Class> {
  /** All labels for class participation in this course */
  allParticipationLabels: string[];

  /**
   * when attendanceScheduleToStart pusher is received attendanceScheduleToStart is filled with object
   * ```ts
   * interface AttendanceScheduleToStart {
   *  courseId: MongoId;
   *  classId: MongoId;
   * }
   * ```
   * It contain courseId and classId required to navigate to the class page
   *
   * `null` means attendanceScheduleToStart Dialog is closed
   */
  attendanceScheduleToStartFor: null | {
    courseId: MongoId;
    classId: MongoId;
  };

  /**
   * Global attendance data for an in-progress attendance, this
   * data remain persistent during course or class page transitions.
   *
   * `null` means attendance is not in progress
   */
  attendanceInProgress: AttendanceInProgressData | null;
}

const initialState: Classes = {
  courseId: '',
  byId: {},
  attendanceScheduleToStartFor: null,
  attendanceInProgress: null,
  allParticipationLabels: [],
};

const baseClassesReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
    state.allParticipationLabels = [];
  });

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const { courseId, courseData = [], userData, currentUser } = action.payload;

    state.byId = {};
    state.courseId = courseId;

    const allParticipationLabels = new Set<string>();

    for (const item of courseData) {
      if (item.nodeType !== TimelineItemType.CLASS) continue;

      item.participationLabels.map((label) => allParticipationLabels.add(label));

      state.byId[item._id] = createClassFactory({
        cls: item,
        currentUser,
        userData: userData?.classes?.[item._id],
      });
    }

    state.allParticipationLabels = Array.from(allParticipationLabels);
  });

  builder.addCase(editClassTitle.success, (state, action) => {
    const { classId, title } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.title = title;
  });

  builder.addCase(classInchargeSet, (state, action) => {
    const { courseId, classId, inCharge, assistants, teamMembers, currentUser, scheStartTime, venue } =
      action.payload;

    if (state.courseId !== courseId) return;

    const cls = state.byId[classId];
    if (!cls) return;

    const classTeam = getClassTeam({ classInchargeSet: 1 }, { team: { inCharge, assistants }, teamMembers });
    cls.team = classTeam;

    cls.myRole = getClassRole(classTeam, currentUser);

    cls.timing.startTime.scheduled = scheStartTime;
    cls.venue = venue;
  });

  builder.addCase(editClassTeam.success, (state, action) => {
    const { classId, incharges, assistants, currentUser } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    const classTeam = getClassTeam(
      { classInchargeSet: 1 },
      { team: { inCharge: incharges[0], assistants }, teamMembers: { inCharges: incharges, assistants } }
    );
    cls.team = classTeam;

    cls.myRole = getClassRole(classTeam, currentUser);
  });

  builder.addCase(classTeamEdited, (state, action) => {
    const { courseId, classId, inCharge, assistants, teamMembers, currentUser } = action.payload;

    if (state.courseId !== courseId) return;

    const cls = state.byId[classId];
    if (!cls) return;

    const classTeam = getClassTeam({ classInchargeSet: 1 }, { team: { inCharge, assistants }, teamMembers });
    cls.team = classTeam;

    cls.myRole = getClassRole(classTeam, currentUser);
  });

  builder.addCase(deleteClass.success, (state, action) => {
    const { classId } = action.payload;
    if (!state.byId[classId]) return;

    state.byId[classId] = undefined;
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const {
      classId,
      autoSchedule,
      details,
      info,
      attendance,
      quizzes,
      polls,
      queries,
      discussions,
      resources,
      suggestedActivities,
      showSuggestedActivities,
      currentUser,
    } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.title = details.title || null;
    cls.isOnline = Boolean(details.isOnlineMeeting);
    cls.sequenceNum = details.trueNum;
    cls.venue = details.venue;
    cls.status = getClassStatus({
      status: details.status,
      scheduledStartTime: details.scheStartTime,
      scheduledEndTime: details.scheEndTime,
    });
    cls.timing = getClassTiming(details);

    cls.agenda = info.agenda;
    cls.topics = info.topicIds;

    const classTeam = getClassTeam(details, info);
    cls.team = classTeam;
    cls.myRole = getClassRole(classTeam, currentUser);

    cls.attendance = getClassAttendance({
      classStartTime: details.scheStartTime,
      attendanceSchedule: autoSchedule,
      attendance,
      isCheckedIn: cls.attendance.isCheckedIn,
      checkInTime: cls.attendance.checkInTime,
    });

    if (attendance?.inProgress) {
      state.attendanceInProgress = {
        isInProgress: true,
        classId,
        courseId: state.courseId,
        isProxy: Boolean(attendance.isProxy),
        available: attendance.numAvailable,
        present: attendance.numPresent,
        enrolled: attendance.numEnrolled,
        startedAt: attendance.attendanceTime,
        taker: attendance.taker,
        nowMarkedPresent: state.attendanceInProgress?.nowMarkedPresent || 0,
        failures: state.attendanceInProgress?.failures || [],
        failuresLastSyncedAt: state.attendanceInProgress?.failuresLastSyncedAt || -1,
      };
    } else {
      /**
       * clear attendance data when fetching class details as
       * 1. we will be able to fetch it again
       * 2. it helps us to avoid showing stale data in other classes
       */
      state.attendanceInProgress = null;
    }

    cls.activities.preClass.quizzes.items = [];
    cls.activities.inClass.quizzes.items = [];

    for (const quiz of quizzes) {
      if (quiz.details.toBeDone === ToBeDone.PRE_CLASS) {
        cls.activities.preClass.quizzes.items.push({
          id: quiz._id,
          publishedOn: quiz.details.publishedOn,
          createdOn: quiz.details.createdOn,
          type: ActivityType.QUIZ,
        });
      } else {
        cls.activities.inClass.quizzes.items.push({
          id: quiz._id,
          publishedOn: quiz.details.publishedOn,
          createdOn: quiz.details.createdOn,
          type: ActivityType.QUIZ,
        });
      }
    }

    cls.activities.preClass.polls.items = [];
    cls.activities.inClass.polls.items = [];

    for (const poll of polls) {
      if (poll.details.toBeDone === ToBeDone.PRE_CLASS) {
        cls.activities.preClass.polls.items.push({
          id: poll._id,
          publishedOn: poll.details.publishedOn,
          createdOn: poll.details.createdOn,
          type: ActivityType.POLL,
        });
      } else {
        cls.activities.inClass.polls.items.push({
          id: poll._id,
          publishedOn: poll.details.publishedOn,
          createdOn: poll.details.createdOn,
          type: ActivityType.POLL,
        });
      }
    }

    cls.activities.preClass.queries.items = [];
    cls.activities.inClass.queries.items = [];
    cls.activities.review.queries.items = [];

    for (const query of queries) {
      switch (query.details.toBeDone) {
        case QueryToBeDone.PRE_CLASS:
          cls.activities.preClass.queries.items.push({
            id: query._id,
            publishedOn: query.details.publishedOn,
            createdOn: query.details.createdOn,
            type: ActivityType.QUERY,
          });
          break;
        case QueryToBeDone.IN_CLASS:
          cls.activities.inClass.queries.items.push({
            id: query._id,
            publishedOn: query.details.publishedOn,
            createdOn: query.details.createdOn,
            type: ActivityType.QUERY,
          });
          break;
        case QueryToBeDone.REVIEW:
          cls.activities.review.queries.items.push({
            id: query._id,
            publishedOn: query.details.publishedOn,
            createdOn: query.details.createdOn,
            type: ActivityType.QUERY,
          });
          break;
      }
    }

    cls.activities.preClass.discussions.items = [];
    cls.activities.inClass.discussions.items = [];

    for (const discussion of discussions) {
      if (discussion.details.toBeDone === ToBeDone.PRE_CLASS) {
        cls.activities.preClass.discussions.items.push({
          id: discussion._id,
          publishedOn: discussion.details.publishedOn,
          createdOn: discussion.details.createdOn,
          type: ActivityType.DISCUSSION,
        });
      } else {
        cls.activities.inClass.discussions.items.push({
          id: discussion._id,
          publishedOn: discussion.details.publishedOn,
          createdOn: discussion.details.createdOn,
          type: ActivityType.DISCUSSION,
        });
      }
    }

    cls.activities.preClass.resources.items = [];
    cls.activities.inClass.resources.items = [];

    for (const resource of resources) {
      if (resource.details.toBeDone === ToBeDone.PRE_CLASS) {
        cls.activities.preClass.resources.items.push({
          id: resource._id,
          publishedOn: resource.details.publishedOn,
          createdOn: resource.details.createdOn,
          type: ActivityType.RESOURCE,
        });
      } else {
        cls.activities.inClass.resources.items.push({
          id: resource._id,
          publishedOn: resource.details.publishedOn,
          createdOn: resource.details.createdOn,
          type: ActivityType.RESOURCE,
        });
      }
    }
    cls.suggestedActivities = []; // clear older suggested activities

    cls.suggestedActivities = suggestedActivityItemFactory({ suggestedActivities });

    cls.hasSuggestedActivities = Boolean(showSuggestedActivities);
  });

  builder.addCase(hideSuggestedActivity.success, (state, action) => {
    const { activityId, classId } = action.payload;

    const cls = state.byId[classId];
    if (!cls?.suggestedActivities) return;

    cls.suggestedActivities = cls.suggestedActivities.filter((activty) => activty.id !== activityId);
  });

  builder.addCase(startClass.success, (state, action) => {
    const { classId } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.status = ClassStatus.IN_SESSION;
  });

  builder.addCase(endClass.success, (state, action) => {
    const { classId, actualEndTime } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.timing.endTime.actual = actualEndTime;
    cls.status = ClassStatus.CLOSED;
  });

  builder.addCase(checkInToClass.success, (state, action) => {
    const { classId, checkInTime } = action.payload;
    const cls = state.byId[classId];
    if (!cls) return;
    cls.attendance.isCheckedIn = true;
    cls.attendance.checkInTime = checkInTime;
  });

  builder.addCase(checkedInToClass, (state, action) => {
    const { classId, sender, checkInTime } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    if (!cls.attendance.data) return;

    const studentId = sender.userId;

    const student = cls.attendance.data.byStudentId[studentId];
    if (!student || student.role === CourseRole.STUDENT) return;

    student.systemStatus.value = AttendanceStatus.CHECKED_IN;
    student.checkInTime = checkInTime;
  });

  builder.addCase(editClassAgenda.success, (state, action) => {
    const { classId, agenda } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.agenda = agenda;
  });

  builder.addCase(editClassTopics.success, (state, action) => {
    const { classId, topicIds } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.topics = topicIds;
  });

  builder.addCase(fetchClassSummary.success, (state, action) => {
    const { classId, fetchedOn, description, attachments = [], links } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.summary.accessedOn = fetchedOn;
    cls.summary.description = description;
    cls.summary.attachments = attachments;
    cls.summary.links = links.map(({ linkId }) => linkId);
  });

  builder.addCase(editClassSummary.success, (state, action) => {
    const { classId, updatedOn, description, attachments = [], links } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.summary.accessedOn = updatedOn;
    cls.summary.updatedOn = updatedOn;
    cls.summary.description = description;
    cls.summary.attachments = attachments;
    cls.summary.links = links;
  });

  builder.addCase(fetchClassParticipations.success, (state, action) => {
    const { classId, studentData } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    const participationLabels = new Set<string>(cls.participationLabels);

    cls.studentDataById = {}; // clear previous data

    for (const student of studentData) {
      cls.studentDataById[student.userId] = {
        userId: student.userId,
        name: student.name,
        avatar: student.avatar,
        attendance: student.attendance,
        participation: student.participation,
      };
      for (const { label } of student.participation) {
        if (!label) continue;
        participationLabels.add(label);
      }
    }

    cls.participationLabels = Array.from(participationLabels);

    const allParticipationLabels = new Set(state.allParticipationLabels);

    // union both participation label sets
    for (const label of cls.participationLabels) {
      allParticipationLabels.add(label);
    }

    state.allParticipationLabels = Array.from(allParticipationLabels);
  });

  builder.addCase(awardClassParticipationPoints.success, (state, action) => {
    const { classId, currentUser, isNewLabel, label, points, studentIds } = action.payload;

    const cls = state.byId[classId];
    if (!cls?.studentDataById) return;

    for (const studentId of studentIds) {
      const studentData = cls.studentDataById[studentId];
      if (!studentData) continue;
      studentData.participation.push({
        points,
        label,
        awardedBy: currentUser,
      });
    }

    if (isNewLabel) {
      const participationLabels = new Set(cls.participationLabels);
      participationLabels.add(label);
      cls.participationLabels = Array.from(participationLabels);

      const allParticipationLabels = new Set(state.allParticipationLabels);
      allParticipationLabels.add(label);
      state.allParticipationLabels = Array.from(allParticipationLabels);
    }
  });

  builder.addCase(scheduleAttendance.success, (state, action) => {
    const { classId, ...attendanceScheduleConfig } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.attendance.schedule = {
      isScheduled: true,
      ...attendanceScheduleConfig,
    };
  });

  builder.addCase(attendanceScheduledToStart, (state, action) => {
    const { classId, courseId } = action.payload;

    state.attendanceScheduleToStartFor = {
      courseId,
      classId,
    };
  });

  builder.addCase(editAttendanceSchedule.success, (state, action) => {
    const { classId, ...attendanceScheduleConfig } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    if (attendanceScheduleConfig.type === ScheduleAttendanceRule.UNSET) {
      cls.attendance.schedule = { isScheduled: false };
    } else {
      cls.attendance.schedule = { isScheduled: true, ...attendanceScheduleConfig };
    }
  });

  builder.addCase(fetchClassAttendance.success, (state, action) => {
    const { classId, currentUser, attendance, remoteAutoInstances } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.attendance.data = {
      remoteInstances: remoteAutoInstances,
      isRecorded: {
        inPerson: attendance.autoAttendance > 0,
        proxy: attendance.proxyAttendance > 0,
        remote: attendance.remoteAttendance > 0,
      },
      byStudentId: {},
    };

    for (const student of attendance.checkedIn) {
      cls.attendance.data.byStudentId[student.userId] = {
        userId: student.userId,
        name: student.name,
        avatar: student.avatar,
        role: currentUser.role,
        isEdited: Boolean(student.edited),
        recordedAt: attendance.lastStartedAt || -1,
        remoteResponses: student.remoteResponses,
        checkInTime: student?.checkInTime ?? 0,
        status: {
          value: student.finalStatus,
          color: student.finalStatusColor,
        },
        systemStatus: {
          value: student.systemStatus,
          color: student.systemStatusColor,
        },
      };
    }
  });

  builder.addCase(fetchMyAttendanceStatus.success, (state, action) => {
    const { classId, currentUser, ...data } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.attendance.data = {
      /** We know that attendance has been recorded or not from data.attendance,
       * But we do not know the method of recorded attendance,
       * so, we assume that the type of attendance is inPerson
       */
      isRecorded: {
        inPerson: Boolean(data.attendance),
        proxy: false,
        remote: false,
      },
      remoteInstances: 0,
      byStudentId: {},
    };

    if (!data.attendance) return;

    const { attendanceTime, status, visibleStatus } = data;

    cls.attendance.data.byStudentId[currentUser.userId] = {
      userId: currentUser.userId,
      name: currentUser.name,
      avatar: currentUser.avatar,
      role: currentUser.role,
      recordedAt: attendanceTime || -1,
      status,
      visibleStatus: visibleStatus || AttendanceStatus.ABSENT,
    };
  });

  builder.addCase(startProxyAttendance.success, (state, action) => {
    const { classId, numAvailable, numPresent, attendanceTime, numEnrolled, currentUser } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    state.attendanceInProgress = {
      isInProgress: true,
      courseId: state.courseId,
      classId,
      isProxy: true,
      available: numAvailable,
      present: numPresent,
      enrolled: numEnrolled,
      startedAt: attendanceTime,
      taker: currentUser,
      nowMarkedPresent: 0, // reset nowMarkedPresent to 0 when startProxyAttendance is started
      failures: [], // clear older failures
      failuresLastSyncedAt: -1, // reset to -1, meaning not synced yet
    };

    cls.attendance = {
      ...cls.attendance,
      inProgress: true,
      startedAt: attendanceTime,
      taker: currentUser,
    };

    if (!cls.attendance.data) {
      cls.attendance.data = {
        byStudentId: {},
        isRecorded: false,
        remoteInstances: 0,
      };
    }

    cls.attendance.data.isRecorded = {
      inPerson: true,
      proxy: true,
      remote: cls.attendance.data.isRecorded ? cls.attendance.data.isRecorded.remote : false,
    };
  });

  builder.addCase(stopProxyAttendance.success, (state, action) => {
    const { classId } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    if (state.attendanceInProgress) {
      state.attendanceInProgress = {
        ...state.attendanceInProgress,
        isInProgress: false,
      };
    }

    cls.attendance = {
      inProgress: false,
      data: cls.attendance.data,
      schedule: cls.attendance.schedule,
      isCheckedIn: cls.attendance.isCheckedIn,
      checkInTime: cls.attendance.checkInTime,
    };
  });

  builder.addCase(proxyAttendanceStarted, (state, action) => {
    const { classId, numAvailable, numPresent, attendanceTime, numEnrolled, sender } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    state.attendanceInProgress = {
      isInProgress: true,
      courseId: state.courseId,
      classId,
      isProxy: true,
      available: numAvailable,
      present: numPresent,
      enrolled: numEnrolled,
      startedAt: attendanceTime,
      taker: sender,
      nowMarkedPresent: 0, // reset nowMarkedPresent to 0 when proxyAttendance is started
      failures: [], // clear older failures reports
      failuresLastSyncedAt: -1, // reset to -1, meaning not synced yet
    };

    cls.attendance = {
      ...cls.attendance,
      inProgress: true,
      startedAt: attendanceTime,
      taker: sender,
    };

    if (!cls.attendance.data) {
      cls.attendance.data = {
        byStudentId: {},
        isRecorded: false,
        remoteInstances: 0,
      };
    }

    cls.attendance.data.isRecorded = {
      inPerson: true,
      proxy: true,
      remote: cls.attendance.data.isRecorded ? cls.attendance.data.isRecorded.remote : false,
    };
  });

  builder.addCase(proxyAttendanceStopped, (state, action) => {
    const { classId } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    if (state.attendanceInProgress) {
      state.attendanceInProgress = {
        ...state.attendanceInProgress,
        isInProgress: false,
      };
    }

    cls.attendance = {
      inProgress: false,
      data: cls.attendance.data,
      schedule: cls.attendance.schedule,
      isCheckedIn: cls.attendance.isCheckedIn,
      checkInTime: cls.attendance.checkInTime,
    };
  });

  builder.addCase(fetchAttendanceResponders.request, (state, action) => {
    const { timestamp } = action.payload;

    if (!state.attendanceInProgress) return;

    state.attendanceInProgress.failuresLastSyncedAt = timestamp;
  });

  builder.addCase(fetchAttendanceResponders.success, (state, action) => {
    const { timestamp, numUnresponsive } = action.payload;

    if (!state.attendanceInProgress) return;

    state.attendanceInProgress.failuresLastSyncedAt = timestamp;

    if (numUnresponsive > 0) {
      state.attendanceInProgress.failures = [
        {
          type: AttendanceProcessFailureType.UNRESPONSIVE_ATTENDEES_FAILURE,
          failedOn: timestamp,
          unresponsiveAttendees: numUnresponsive,
        },
        ...state.attendanceInProgress.failures,
      ];
    }
  });

  builder.addCase(attendeeAvailable, (state, action) => {
    const { classId, count, attendanceTime } = action.payload;

    if (!state.attendanceInProgress) return;
    if (state.attendanceInProgress.classId !== classId) return;
    if (state.attendanceInProgress.startedAt !== attendanceTime) return;

    state.attendanceInProgress.available = Math.max(state.attendanceInProgress.available + 1, count);
  });

  builder.addCase(attendeeFailure, (state, action) => {
    const {
      classId,
      attendanceTime,
      failedAttendee: { avatar, failureCode, userId, name, role },
      timestamp,
    } = action.payload;

    if (!state.attendanceInProgress) return;
    if (state.attendanceInProgress.classId !== classId) return;
    if (state.attendanceInProgress.startedAt !== attendanceTime) return;

    state.attendanceInProgress.failures = [
      {
        type: AttendanceProcessFailureType.ATTENDEE_FAILURE,
        failedOn: timestamp,
        failureCode,
        attendee: { userId, avatar, name, role },
      },
      ...state.attendanceInProgress.failures,
    ];
  });

  builder.addCase(attendanceMarked, (state, action) => {
    const { attendanceTime, classId, count, attendee } = action.payload;

    if (!state.attendanceInProgress) return;
    if (state.attendanceInProgress.classId !== classId) return;
    if (state.attendanceInProgress.startedAt !== attendanceTime) return;

    const attendanceInProgress = state.attendanceInProgress;

    attendanceInProgress.present = Math.max(attendanceInProgress.present + 1, count);
    attendanceInProgress.nowMarkedPresent = attendanceInProgress.nowMarkedPresent + 1;
    attendanceInProgress.failures = attendanceInProgress.failures.filter((failure) => {
      if (failure.type === AttendanceProcessFailureType.UNRESPONSIVE_ATTENDEES_FAILURE) return true;
      return failure.attendee.userId !== attendee;
    });
  });

  builder.addCase(editClassAttendance.success, (state, action) => {
    const { classId, attendanceStatusByStudentId } = action.payload;

    const cls = state.byId[classId];
    if (!cls?.attendance.data) return;

    for (const [studentId, status] of Object.entries(attendanceStatusByStudentId)) {
      const student = cls.attendance.data.byStudentId[studentId];
      if (!student || student.role === CourseRole.STUDENT) continue;

      student.isEdited = student.isEdited || student.status.value !== status;
      student.status.value = status;

      if (status === AttendanceStatus.PRESENT) student.systemStatus.value = status;
    }
  });

  builder.addCase(clearLocalAttendanceData, (state) => {
    state.attendanceInProgress = null;
  });

  builder.addCase(createQuiz.success, (state, action) => {
    const { classId, toBeDone, ...quiz } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].quizzes.items.push({
      id: quiz._id,
      publishedOn: quiz.details.publishedOn,
      createdOn: quiz.details.createdOn,
      type: ActivityType.QUIZ,
    });

    cls.activities[toBeDone].quizzes.total++;
    cls.activities[toBeDone].quizzes.seen++;
  });

  builder.addCase(deleteQuiz.success, (state, action) => {
    const { quiz } = action.payload;
    const { classId, toBeDone } = quiz;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].quizzes.items = cls.activities[toBeDone].quizzes.items.filter(
      ({ id }) => id !== quiz.id
    );

    if (quiz.firstAccessedOn > 0) cls.activities[toBeDone].quizzes.seen--;
    cls.activities[toBeDone].quizzes.total--;
  });

  builder.addCase(fetchQuizDetails.success, (state, action) => {
    const { classId, isFirstAccess, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls || !isFirstAccess) return;

    cls.activities[toBeDone].quizzes.seen++;
  });

  builder.addCase(publishQuiz.success, (state, action) => {
    const {
      classId,
      preferences: { toBeDone },
    } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].quizzes.published++;
  });

  builder.addCase(quizPublished, (state, action) => {
    const { classId, quizId, details } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    activityPublished({
      activityId: quizId,
      activityType: ActivityType.QUIZ,
      activities: cls.activities[details.toBeDone].quizzes,
      createdOn: details.createdOn,
      publishedOn: details.publishedOn,
    });
  });

  builder.addCase(submitQuiz.success, (state, action) => {
    const { classId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].quizzes.completed++;
  });

  builder.addCase(createPoll.success, (state, action) => {
    const { classId, toBeDone, ...poll } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].polls.items.push({
      id: poll._id,
      publishedOn: poll.details.publishedOn,
      createdOn: poll.details.createdOn,
      type: ActivityType.POLL,
    });

    cls.activities[toBeDone].polls.total++;
    cls.activities[toBeDone].polls.seen++;
  });

  builder.addCase(deletePoll.success, (state, action) => {
    const { poll } = action.payload;
    const { classId, toBeDone } = poll;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].polls.items = cls.activities[toBeDone].polls.items.filter(
      ({ id }) => id !== poll.id
    );

    if (poll.firstAccessedOn > 0) cls.activities[toBeDone].polls.seen--;
    cls.activities[toBeDone].polls.total--;
  });

  builder.addCase(fetchPollDetails.success, (state, action) => {
    const { classId, isFirstAccess, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls || !isFirstAccess) return;

    cls.activities[toBeDone].polls.seen++;
  });

  builder.addCase(publishPoll.success, (state, action) => {
    const {
      classId,
      preferences: { toBeDone },
    } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].polls.published++;
  });

  builder.addCase(pollPublished, (state, action) => {
    const { classId, pollId, details } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    activityPublished({
      activityId: pollId,
      activityType: ActivityType.POLL,
      activities: cls.activities[details.toBeDone].polls,
      createdOn: details.createdOn,
      publishedOn: details.publishedOn,
    });
  });

  builder.addCase(submitPoll.success, (state, action) => {
    const { classId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].polls.completed++;
  });

  builder.addCase(createResource.success, (state, action) => {
    const { classId, toBeDone, ...resource } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].resources.items.push({
      id: resource._id,
      publishedOn: resource.details.publishedOn,
      createdOn: resource.details.createdOn,
      type: ActivityType.RESOURCE,
    });

    cls.activities[toBeDone].resources.total++;
    cls.activities[toBeDone].resources.seen++;
  });

  builder.addCase(deleteResource.success, (state, action) => {
    const { resource } = action.payload;
    const { classId, toBeDone } = resource;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].resources.items = cls.activities[toBeDone].resources.items.filter(
      ({ id }) => id !== resource.id
    );

    if (resource.firstAccessedOn > 0) cls.activities[toBeDone].resources.seen--;
    cls.activities[toBeDone].resources.total--;
  });

  builder.addCase(publishResource.success, (state, action) => {
    const { classId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].resources.published++;
  });

  builder.addCase(resourcePublished, (state, action) => {
    const { classId, resourceId, details } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    activityPublished({
      activityId: resourceId,
      activityType: ActivityType.RESOURCE,
      activities: cls.activities[details.toBeDone].resources,
      createdOn: details.createdOn,
      publishedOn: details.publishedOn,
    });
  });

  builder.addCase(incrementResourceViews.success, (state, action) => {
    const { classId, isFirstAccess, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls || !isFirstAccess) return;

    cls.activities[toBeDone].resources.seen++;
  });

  builder.addCase(createDiscussion.success, (state, action) => {
    const { classId, toBeDone, ...discussion } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].discussions.items.push({
      id: discussion._id,
      publishedOn: discussion.details.publishedOn,
      createdOn: discussion.details.createdOn,
      type: ActivityType.DISCUSSION,
    });

    cls.activities[toBeDone].discussions.total++;
    cls.activities[toBeDone].discussions.seen++;
  });

  builder.addCase(deleteDiscussion.success, (state, action) => {
    const { discussion } = action.payload;
    const { classId, toBeDone } = discussion;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].discussions.items = cls.activities[toBeDone].discussions.items.filter(
      ({ id }) => id !== discussion.id
    );

    if (discussion.firstAccessedOn > 0) cls.activities[toBeDone].discussions.seen--;
    cls.activities[toBeDone].discussions.total--;
  });

  builder.addCase(publishDiscussion.success, (state, action) => {
    const {
      classId,
      preferences: { toBeDone },
    } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].discussions.published++;
  });

  builder.addCase(discussionPublished, (state, action) => {
    const { classId, discussionId, details } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    activityPublished({
      activityId: discussionId,
      activityType: ActivityType.DISCUSSION,
      activities: cls.activities[details.toBeDone].discussions,
      createdOn: details.createdOn,
      publishedOn: details.publishedOn,
    });
  });

  builder.addCase(markDiscussionAsViewed.success, (state, action) => {
    const { classId, toBeDone, isFirstAccess } = action.payload;

    const cls = state.byId[classId];
    if (!cls || !isFirstAccess) return;

    cls.activities[toBeDone].discussions.seen++;
  });

  builder.addCase(createQuery.success, (state, action) => {
    const { classId, ...payload } = action.payload;

    if (payload.isAnonymous) return;

    const cls = state.byId[classId];
    if (!cls) return;

    const query = payload.query;
    const { toBeDone } = query.details;

    cls.activities[toBeDone].queries.items.push({
      id: query._id,
      publishedOn: query.details.publishedOn,
      createdOn: query.details.createdOn,
      type: ActivityType.QUERY,
    });

    cls.activities[toBeDone].queries.total++;
    cls.activities[toBeDone].queries.seen++;
  });

  builder.addCase(queryCreated, (state, action) => {
    const { classId, currentUser, queryId, details, sender } = action.payload;

    const isSameUser = currentUser.userId === sender.userId;

    if (details.isAnon && currentUser.role === CourseRole.STUDENT) return;

    const cls = state.byId[classId];
    if (!cls) return;

    const { toBeDone } = details;

    cls.activities[toBeDone].queries.items.push({
      id: queryId,
      publishedOn: details.publishedOn,
      createdOn: details.createdOn,
      type: ActivityType.QUERY,
    });

    if (details.isAnon) {
      cls.activities[toBeDone].queries.pending++;
    } else {
      cls.activities[toBeDone].queries.total++;
    }

    if (isSameUser) {
      cls.activities[toBeDone].queries.seen++;
    }
  });

  builder.addCase(approveQuery.success, (state, action) => {
    const { classId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].queries.total++;
    cls.activities[toBeDone].queries.pending--;
  });

  builder.addCase(queryApproved, (state, action) => {
    const { classId, queryId, currentUser, details } = action.payload;
    const toBeDone = details.toBeDone;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].queries.total++;
    cls.activities[toBeDone].queries.seen++;

    if (currentUser.role !== CourseRole.STUDENT) {
      cls.activities[toBeDone].queries.pending--;
    } else {
      cls.activities[toBeDone].queries.items.push({
        id: queryId,
        publishedOn: details.publishedOn,
        createdOn: details.createdOn,
        type: ActivityType.QUERY,
      });
    }
  });

  builder.addCase(deleteQuery.success, (state, action) => {
    const { classId, queryId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    const deletedQueryIndex = cls.activities[toBeDone].queries.items.findIndex(
      (query) => query.id === queryId
    );
    cls.activities[toBeDone].queries.items.splice(deletedQueryIndex, 1);

    cls.activities[toBeDone].queries.pending--;
  });

  builder.addCase(markQueryAsViewed.success, (state, action) => {
    const { classId, toBeDone, isFirstAccess } = action.payload;

    const cls = state.byId[classId];
    if (!cls || !isFirstAccess) return;

    cls.activities[toBeDone].queries.seen++;
  });

  builder.addCase(closeQuery.success, (state, action) => {
    const { classId, toBeDone } = action.payload;

    const cls = state.byId[classId];
    if (!cls) return;

    cls.activities[toBeDone].queries.closed++;
  });

  builder.addCase(fetchComments.success, (state, action) => {
    const { context, unseenCommentsCount } = action.payload;
    const { classId, type: activityType, subType: activityToBeDone } = context;
    if (!classId) return;

    const cls = state.byId[classId];
    if (!cls) return;

    switch (activityType) {
      case CommentContext.QUIZ:
      case CommentContext.POLL:
      case CommentContext.DISCUSSION:
      case CommentContext.RESOURCE:
        if (activityToBeDone === ToBeDone.IN_CLASS || activityToBeDone === ToBeDone.PRE_CLASS) {
          cls.activities[activityToBeDone][activityType].comments.seen += unseenCommentsCount;
        }
        return;
      case CommentContext.QUERY:
        if (
          activityToBeDone === QueryToBeDone.IN_CLASS ||
          activityToBeDone === QueryToBeDone.PRE_CLASS ||
          activityToBeDone === QueryToBeDone.REVIEW
        ) {
          cls.activities[activityToBeDone][activityType].comments.seen += unseenCommentsCount;
        }
        return;
      default:
        return;
    }
  });

  builder.addCase(commentCreated, (state, action) => {
    const { classId, context: activityType, subContext: activityToBeDone } = action.payload;
    if (!classId) return;

    const cls = state.byId[classId];
    if (!cls) return;

    switch (activityType) {
      case CommentContext.QUIZ:
      case CommentContext.POLL:
      case CommentContext.DISCUSSION:
      case CommentContext.RESOURCE:
        if (activityToBeDone === ToBeDone.IN_CLASS || activityToBeDone === ToBeDone.PRE_CLASS) {
          cls.activities[activityToBeDone][activityType].comments.total++;
        }
        return;
      case CommentContext.QUERY:
        if (
          activityToBeDone === QueryToBeDone.IN_CLASS ||
          activityToBeDone === QueryToBeDone.PRE_CLASS ||
          activityToBeDone === QueryToBeDone.REVIEW
        ) {
          cls.activities[activityToBeDone][activityType].comments.total++;
        }
        return;
      default:
        return;
    }
  });

  builder.addCase(markCommentsAsSeen.success, (state, action) => {
    const { context, seen } = action.payload;
    const { classId, type: activityType, subType: activityToBeDone } = context;
    if (!classId) return;

    const cls = state.byId[classId];
    if (!cls) return;

    switch (activityType) {
      case CommentContext.QUIZ:
      case CommentContext.POLL:
      case CommentContext.DISCUSSION:
      case CommentContext.RESOURCE:
        if (activityToBeDone === ToBeDone.IN_CLASS || activityToBeDone === ToBeDone.PRE_CLASS) {
          const clsActivity = cls.activities[activityToBeDone][activityType];
          clsActivity.comments.seen = Math.max(clsActivity.comments.total, clsActivity.comments.seen + seen);
        }
        return;
      case CommentContext.QUERY:
        if (
          activityToBeDone === QueryToBeDone.IN_CLASS ||
          activityToBeDone === QueryToBeDone.PRE_CLASS ||
          activityToBeDone === QueryToBeDone.REVIEW
        ) {
          const clsActivity = cls.activities[activityToBeDone][activityType];
          clsActivity.comments.seen = Math.max(clsActivity.comments.total, clsActivity.comments.seen + seen);
        }
        return;
      default:
        return;
    }
  });
});

const classesReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.CLASS }),
  baseClassesReducer
);

export default classesReducer;
