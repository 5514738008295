import { styled, Typography, TypographyProps } from '@mui/material';

import { ellipsize } from '../../../../styles/mixins';

export const Title = styled((props: TypographyProps) => <Typography variant="h6Bold" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey[700],
    ...ellipsize,
  })
);
