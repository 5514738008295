import { colorPalette } from './theme/variables';

export const ZoomMeetingAreaColor = Object.freeze({
  BLUE: '#0e71eb',
});

export const WidgetColor = Object.freeze({
  GREY: colorPalette.grey[500],
  GREEN: colorPalette.green[500],
  PINK: colorPalette.red[400],
  RED: colorPalette.red[500],
  BLUE: colorPalette.blue[500],
  ORANGE: colorPalette.orange[500],
});

export const QuizOptionColor = Object.freeze({
  GREY: colorPalette.grey[500],
  BLUE: colorPalette.blue[500],
  RED: colorPalette.red[500],
});

export const PollOptionColor = Object.freeze({
  GREY: colorPalette.grey[500],
  BLUE: colorPalette.blue[500],
});

export const AttendanceStatusColor = Object.freeze({
  RED: colorPalette.red[500],
  GREEN: colorPalette.green[500],
  BLUE: colorPalette.blue[500],
});

export const ScheduleAttendanceRuleOptionColor = Object.freeze({
  GREY: colorPalette.grey[500],
  BLUE: colorPalette.blue[500],
  RED: colorPalette.red[500],
});

export const StudentAttendanceResultColor = Object.freeze({
  GREY: colorPalette.grey[500],
  GREEN: colorPalette.green[500],
  RED: colorPalette.red[500],
  ORANGE: colorPalette.orange[500],
});

export const AcadlyLogoColors = Object.freeze({
  RED: colorPalette.red[500],
  GREEN: colorPalette.green[500],
  BLUE: colorPalette.blue[500],
  ORANGE: colorPalette.orange[500],
});

export const SuggestedActivitiesColor = Object.freeze({
  GREY: colorPalette.grey[500],
  BLUE: colorPalette.blue[500],
  GREEN: colorPalette.green[500],
});

export const TipsColor = Object.freeze({
  BLUE: colorPalette.blue[500],
  GREY: colorPalette.grey[500],
});

export const RecordInPersonAttendanceColor = Object.freeze({
  SAFFRON: '#D1EDAD',
});
