import { Stack, StackProps, styled } from '@mui/material';

export const zoomAppFooterHeight = 60;

export const Root = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} component="footer" />
))(({ theme }) => ({
  height: zoomAppFooterHeight,
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(0, 2),
}));
