import { Draft } from '@reduxjs/toolkit';

import { CommentContextData } from '../../comments/types';
import { CommentContext } from '../../shared/types';
import { AssignmentsState } from '../types';

export function incrementCommentsCount({
  assignmentsById,
  context,
  isSeen,
}: {
  assignmentsById: Draft<AssignmentsState['byId']>;
  context: CommentContextData;
  isSeen: boolean;
}) {
  if (context.type !== CommentContext.ASSIGNMENT) return;

  const assignment = assignmentsById[context.id];
  if (!assignment) return;

  assignment.comments[context.subType].total++;

  if (isSeen) {
    assignment.comments[context.subType].seen++;
  }
}
