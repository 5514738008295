import { ReactNode } from 'react';

import {
  Accordion,
  AccordionClasses,
  AccordionDetails,
  AccordionDetailsClasses,
  AccordionSummary,
  AccordionSummaryClasses,
  accordionSummaryClasses,
  styled,
} from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../utils/helpers';

export type BaseItemProps = { id: string };

type SummaryItemProps<T extends BaseItemProps> = T & {
  isExpanded: boolean;
  onToggle: () => void;
};

type DetailsItemProps<T extends BaseItemProps> = T & {
  onToggle: () => void;
  isExpanded: boolean;
};

type RenderSummaryFn<T extends BaseItemProps> = (props: SummaryItemProps<T>) => ReactNode;
type RenderDetailsFn<T extends BaseItemProps> = (props: DetailsItemProps<T>) => ReactNode;

export interface ItemProps<T extends BaseItemProps> {
  /**
   * Used to render the header of the list item
   */
  renderSummary: RenderSummaryFn<T>;
  /**
   * Used to render the details of the list when expanded
   */
  renderDetails: RenderDetailsFn<T>;
  className?: string;
  classes?: {
    root?: Partial<AccordionClasses>;
    summary?: Partial<AccordionSummaryClasses>;
    details?: Partial<AccordionDetailsClasses>;
  };
  highlighted?: true;
}

export interface Props<T extends BaseItemProps> extends ItemProps<T> {
  item: T;
  isExpanded: boolean;
  /**
   * Called on toggle of expand state
   * @param isExpanded toggled value of expand state
   */
  onToggle(isExpanded: boolean): void;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  '&::before': {
    backgroundColor: 'transparent',
  },
}));

const customAccordionSummaryClasses = {
  ...accordionSummaryClasses,
  ...generateClasses('ExpandableListItem', ['highlighted']),
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  margin: 0,
  cursor: 'unset !important',
  [`& .${customAccordionSummaryClasses.content}`]: {
    margin: 0,
  },
  [`&.${customAccordionSummaryClasses.highlighted}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const ExpandableListItem = <T extends BaseItemProps>({
  item,
  renderSummary,
  renderDetails,
  isExpanded,
  onToggle,
  className,
  classes,
  highlighted,
}: Props<T>) => {
  return (
    <StyledAccordion
      key={item.id}
      expanded={isExpanded}
      className={clsx(className)}
      classes={classes?.root}
      TransitionProps={{ unmountOnExit: true }}
    >
      <StyledAccordionSummary
        classes={classes?.summary}
        className={clsx({ [customAccordionSummaryClasses.highlighted]: highlighted })}
      >
        {renderSummary({ ...item, isExpanded, onToggle: () => onToggle(!isExpanded) })}
      </StyledAccordionSummary>
      <StyledAccordionDetails classes={classes?.details}>
        {renderDetails({ ...item, isExpanded, onToggle: () => onToggle(!isExpanded) })}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default ExpandableListItem;
