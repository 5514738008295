export function removeDuplicates<T extends Record<K, PropertyKey>, K extends keyof T>(
  arr: T[],
  propName: K
): T[] {
  const lookup = arr.reduce((acc, item) => {
    acc[item[propName]] = item;
    return acc;
  }, {} as Record<T[K], T>);

  return Object.values<T>(lookup);
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

/**
 * moves the item at source index to destination index in specified array
 *
 * @param arr array in which item needs to be moved
 * @param sourceIndex 0 based index of the item that needs to be moved
 * @param destinationIndex 0 based index where the item needs to be moved
 * @returns new instance of array with item moved to destination index
 *
 * @example
 * moveItemAtIndex([1, 2, 3, 4, 5], 2, 4) -> [1, 2, 4, 5, 3]
 */
export function moveItemAtIndex<T>(arr: T[], sourceIndex: number, destinationIndex: number) {
  const result = Array.from(arr);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);
  return result;
}
