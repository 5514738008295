import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { AddCourseTopicPayload, AddCourseTopicSuccessPayload, DeleteCourseTopicPayload } from './types';

export const addCourseTopic = createRequestAction<AddCourseTopicPayload, AddCourseTopicSuccessPayload>(
  'topics/add'
);

export const deleteCourseTopic = createRequestAction<DeleteCourseTopicPayload, DeleteCourseTopicPayload>(
  'topics/delete'
);

export const editCourseTopic = createRequestAction<API.EditCourseTopicRequest, API.EditCourseTopicRequest>(
  'topics/edit'
);
