import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export interface FormValues {
  timezone: string | '';
}

export interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues: FormValues;
  onChangeTimezone: (timezone: string) => void;
}

const useSelectTimezoneAlert = ({ open, onClose, defaultValues, onChangeTimezone }: Props) => {
  const formMethods = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });

  const { setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (data.timezone) {
      onChangeTimezone(data.timezone);
    }

    onClose();
  };

  useEffect(
    function setInitialValues() {
      if (!open) return;
      setValue('timezone', defaultValues.timezone);
    },
    [open, defaultValues.timezone, setValue]
  );

  return {
    formMethods,
    onSubmit,
  };
};

export default useSelectTimezoneAlert;
