import { styled } from '@mui/material';

import clsx from 'clsx';

import AcadlyDialog, { Props as AcadlyDialogClasses } from '../../components/Dialog';
import { generateClasses } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';

export const zoomIntegrationDialogClasses = generateClasses('ZoomIntegrationDialog', ['body']);

export const Dialog = styled((props: AcadlyDialogClasses) => (
  <AcadlyDialog
    {...props}
    classes={{ ...props.classes, body: clsx(zoomIntegrationDialogClasses.body, props.classes?.body) }}
  />
))(({ theme }) => ({
  [`& .${zoomIntegrationDialogClasses.body}`]: {
    padding: theme.spacing(5, 8),
    [forMobile(theme)]: {
      padding: theme.spacing(4),
    },
  },
}));
