import { styled, Typography } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const attachmentViewClasses = {
  ...generateClasses('AttachmentView', [
    'colorPrimary',
    'colorSuccess',
    'colorWarning',
    'colorError',
    'fullWidth',
    'borderNone',
  ]),
};

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
  backgroundImage: `linear-gradient(180deg, #FFFFFF 0%, ${theme.palette.grey[50]} 100%)`,
  borderRadius: 4,
  overflow: 'hidden',
  width: '100%',
  maxWidth: 300,
  [`&.${attachmentViewClasses.fullWidth}`]: {
    maxWidth: 'unset',
  },
}));

export const Extension = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  padding: theme.spacing(3),
  [`&.${attachmentViewClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary[600],
  },
  [`&.${attachmentViewClasses.colorSuccess}`]: {
    backgroundColor: theme.palette.success[600],
  },
  [`&.${attachmentViewClasses.colorWarning}`]: {
    backgroundColor: theme.palette.warning[600],
  },
  [`&.${attachmentViewClasses.colorError}`]: {
    backgroundColor: theme.palette.error[600],
  },
}));

export const Content = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(0, 3),
  border: `1px solid ${theme.palette.grey[400]}`,
  borderLeft: 'none',
  borderRadius: '0 4px 4px 0',
  /** If icon is passed as props, then border is not applied to that icon.
   * So externally we add border to Root element and remove border from Content,
   * so there will be no double border overlapping issues
   */
  [`&.${attachmentViewClasses.borderNone}`]: {
    border: 'none',
  },
}));

export const FileName = styled(Typography)(({ theme }) => ({
  flex: 1,
  color: theme.palette.grey[700],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));
