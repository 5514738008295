import { Divider, styled, Typography, TypographyProps } from '@mui/material';

import useCoursePaymentHeaderVM from './vm';

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 10),
  minHeight: 80,
}));

const Title = styled((props: TypographyProps) => <Typography {...props} variant="h4Bold" />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

interface Props {
  id?: string;
}

const CoursePaymentHeader = ({ id }: Props) => {
  const { title } = useCoursePaymentHeaderVM();
  return (
    <>
      <Header id={id}>
        <Title>{title}</Title>
      </Header>
      <Divider />
    </>
  );
};

export default CoursePaymentHeader;
