import { useTranslation } from 'react-i18next';

import Button from '../../../../../components/Button';
import { i18nNS } from '../../../../../i18n';
import { NoMoreCommentsMessage } from './styles';
import useLoadOlderCommentsVM from './vm';

const LoadOlderComments = () => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const { hasMoreComments, pageNumber, handleLoadOlderComments } = useLoadOlderCommentsVM();

  if (hasMoreComments) {
    return (
      <Button fullWidth variant="text" color="primary" size="medium" onClick={handleLoadOlderComments}>
        {t('load_older_comments', { ns: i18nNS.COMMENTS })}
      </Button>
    );
  }

  if (pageNumber > 0) {
    return (
      <NoMoreCommentsMessage variant="paragraphRegular">
        {t('no_more_comments_to_show', { ns: i18nNS.COMMENTS })}
      </NoMoreCommentsMessage>
    );
  }

  return null;
};

export default LoadOlderComments;
