import { useSelector } from 'react-redux';

import { useCurrentUserWithRole } from '../../../../../../../../../courses/hooks';
import { deleteComment } from '../../../../../../../../../db/comments/actions';
import { selectComment } from '../../../../../../../../../db/comments/selectors';
import { useRequestDispatch } from '../../../../../../../../../utils/request-actions';
import { getCanRemoveComment } from '../../../../../../../../helpers';

export interface Props {
  commentId: MongoId;
}

const useDeleteVM = ({ commentId }: Props) => {
  const requestDispatch = useRequestDispatch();

  const comment = useSelector(selectComment(commentId));
  const currentUser = useCurrentUserWithRole();

  const canRemove = getCanRemoveComment(comment, currentUser);

  const handleDeleteComment = async () => {
    await requestDispatch(deleteComment, { commentId });
  };

  return { canRemove, handleDeleteComment };
};

export default useDeleteVM;
