import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import { Divider, Typography } from '@mui/material';

import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import CloseIcon from '../../../icons/CloseIcon';
import { FaqQuestion } from '../types';
import { ActionButton, ActionWrapper, Description, Footer, Header, Root, Wrapper } from './styles';

export interface FaqAnswerDialogData {
  /** empty string, if there are no next questions */
  nextQuestionTitle: string;
  question: FaqQuestion;
  /** empty string, if there are no previous questions */
  previousQuestionTitle: string;
  sectionTitle: string;
}

interface Props {
  /** if not null then dialog will be open */
  data: FaqAnswerDialogData | null;
  onClose: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
}

const FaqAnswerDialog = ({ data, onClose, onNext, onPrevious }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const firstChildRef = useRef<HTMLDivElement | null>(null);

  const { sectionTitle, nextQuestionTitle, question, previousQuestionTitle } = data || {};
  const isOpen = Boolean(data);

  useEffect(() => {
    if (!isOpen) return;
    // focus on first child after drawer open
    new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
      console.log('useEffect called');
      firstChildRef.current?.focus();
    });
  }, [isOpen]);

  return (
    <Root
      open={isOpen}
      onClose={onClose}
      header={() => (
        <>
          <Header>
            <Wrapper ref={firstChildRef} tabIndex={0}>
              <Typography variant="titleBold" color="">
                {sectionTitle}
              </Typography>
              <Typography variant="h4Bold" color="">
                {question?.title}
              </Typography>
            </Wrapper>
            <Button size="small" variant="outlined" onClick={onClose} tabIndex={1}>
              <CloseIcon sx={(theme) => ({ fontSize: theme.typography.h6 })} />
            </Button>
          </Header>
          <Divider />
        </>
      )}
      footer={() => (
        <>
          <Divider />
          <Footer>
            <ActionWrapper className="right" tabIndex={0}>
              {nextQuestionTitle && (
                <>
                  <Typography>{t('next', { ns: i18nNS.GLOSSARY })}</Typography>
                  <Typography component={ActionButton} color="primary" variant="link" onClick={onNext} noWrap>
                    {nextQuestionTitle}
                  </Typography>
                </>
              )}
            </ActionWrapper>
            <ActionWrapper tabIndex={0}>
              {previousQuestionTitle && (
                <>
                  <Typography>{t('previous', { ns: i18nNS.GLOSSARY })}</Typography>
                  <Typography
                    component={ActionButton}
                    color="primary"
                    variant="link"
                    onClick={onPrevious}
                    noWrap
                  >
                    {previousQuestionTitle}
                  </Typography>
                </>
              )}
            </ActionWrapper>
          </Footer>
        </>
      )}
    >
      <Description component="div" tabIndex={0}>
        <Markdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{question?.answer || ''}</Markdown>
      </Description>
    </Root>
  );
};

export default FaqAnswerDialog;
