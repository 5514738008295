import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import Button from '../Button';

export const moreActionsMenuClasses = {
  ...generateClasses('MoreActions', ['button']),
};

export const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${moreActionsMenuClasses.button}`]: {
    color: theme.palette.grey[500],
  },
}));
