import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const LayersIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M3.23999 7.29L11.76 11.92C11.8339 11.9594 11.9163 11.98 12 11.98C12.0837 11.98 12.1661 11.9594 12.24 11.92L20.76 7.29C20.8377 7.25161 20.9024 7.19111 20.9459 7.1161C20.9893 7.04109 21.0097 6.95491 21.0043 6.86837C20.999 6.78184 20.9682 6.69882 20.9158 6.62972C20.8635 6.56062 20.7919 6.50854 20.71 6.48L12.19 3C12.0683 2.94999 11.9317 2.94999 11.81 3L3.28999 6.48C3.20812 6.50854 3.13651 6.56062 3.08414 6.62972C3.03178 6.69882 3.00099 6.78184 2.99565 6.86837C2.99031 6.95491 3.01065 7.04109 3.05412 7.1161C3.09759 7.19111 3.16225 7.25161 3.23999 7.29Z"
          fill="currentColor"
        />
        <path
          d="M20.71 10.66L18.88 9.88L12.24 13.49C12.1661 13.5294 12.0837 13.55 12 13.55C11.9163 13.55 11.8339 13.5294 11.76 13.49L5.11999 9.88L3.28999 10.66C3.21249 10.7007 3.14759 10.7618 3.10231 10.8367C3.05703 10.9116 3.03309 10.9975 3.03309 11.085C3.03309 11.1725 3.05703 11.2584 3.10231 11.3333C3.14759 11.4082 3.21249 11.4693 3.28999 11.51L11.81 16.41C11.8822 16.4542 11.9653 16.4776 12.05 16.4776C12.1347 16.4776 12.2177 16.4542 12.29 16.41L20.81 11.51C20.8838 11.4609 20.9425 11.3923 20.9797 11.3119C21.0169 11.2315 21.0311 11.1423 21.0208 11.0543C21.0104 10.9663 20.9759 10.8829 20.921 10.8133C20.8662 10.7437 20.7931 10.6906 20.71 10.66Z"
          fill="currentColor"
        />
        <path
          d="M20.71 15.1L19.15 14.42L12.24 18.18C12.1661 18.2194 12.0837 18.24 12 18.24C11.9163 18.24 11.8339 18.2194 11.76 18.18L4.84999 14.42L3.28999 15.1C3.21029 15.1413 3.14348 15.2038 3.09684 15.2805C3.05021 15.3572 3.02554 15.4452 3.02554 15.535C3.02554 15.6248 3.05021 15.7128 3.09684 15.7895C3.14348 15.8662 3.21029 15.9287 3.28999 15.97L11.81 20.97C11.8839 21.0094 11.9663 21.03 12.05 21.03C12.1337 21.03 12.2161 21.0094 12.29 20.97L20.81 15.97C20.886 15.9202 20.9466 15.8503 20.9852 15.768C21.0237 15.6858 21.0387 15.5944 21.0283 15.5042C21.0179 15.4139 20.9826 15.3283 20.9264 15.257C20.8702 15.1856 20.7953 15.1312 20.71 15.1Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 11.35C20.9885 11.1641 20.9253 10.985 20.8176 10.8331C20.7098 10.6811 20.5617 10.5623 20.39 10.49L18.24 9.57L20.5 8.27C20.6602 8.17784 20.7918 8.04308 20.88 7.88067C20.9683 7.71827 21.0098 7.53458 21 7.35C20.9885 7.16406 20.9253 6.98502 20.8176 6.83307C20.7098 6.68112 20.5617 6.5623 20.39 6.49L12.39 3.08C12.2666 3.02774 12.134 3.00081 12 3.00081C11.866 3.00081 11.7334 3.02774 11.61 3.08L3.61001 6.49C3.43832 6.5623 3.29024 6.68112 3.18246 6.83307C3.07468 6.98502 3.01149 7.16406 3.00001 7.35C2.99018 7.53458 3.03171 7.71827 3.11997 7.88067C3.20824 8.04308 3.33978 8.17784 3.50001 8.27L5.76001 9.57L3.61001 10.49C3.43832 10.5623 3.29024 10.6811 3.18246 10.8331C3.07468 10.985 3.01149 11.1641 3.00001 11.35C2.99018 11.5346 3.03171 11.7183 3.11997 11.8807C3.20824 12.0431 3.33978 12.1778 3.50001 12.27L5.76001 13.57L3.61001 14.49C3.43832 14.5623 3.29024 14.6811 3.18246 14.8331C3.07468 14.985 3.01149 15.1641 3.00001 15.35C2.99018 15.5346 3.03171 15.7183 3.11997 15.8807C3.20824 16.0431 3.33978 16.1778 3.50001 16.27L11.5 20.87C11.652 20.9578 11.8245 21.004 12 21.004C12.1755 21.004 12.348 20.9578 12.5 20.87L20.5 16.27C20.6602 16.1778 20.7918 16.0431 20.88 15.8807C20.9683 15.7183 21.0098 15.5346 21 15.35C20.9885 15.1641 20.9253 14.985 20.8176 14.8331C20.7098 14.6811 20.5617 14.5623 20.39 14.49L18.24 13.57L20.5 12.27C20.6602 12.1778 20.7918 12.0431 20.88 11.8807C20.9683 11.7183 21.0098 11.5346 21 11.35V11.35ZM12 5.09L17.76 7.54L12 10.85L6.24001 7.54L12 5.09ZM11.5 12.87C11.652 12.9578 11.8245 13.004 12 13.004C12.1755 13.004 12.348 12.9578 12.5 12.87L16.07 10.87L17.76 11.59L12 14.85L6.24001 11.54L7.93001 10.82L11.5 12.87ZM17.76 15.54L12 18.85L6.24001 15.54L7.93001 14.82L11.5 16.87C11.652 16.9578 11.8245 17.004 12 17.004C12.1755 17.004 12.348 16.9578 12.5 16.87L16.07 14.82L17.76 15.54Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default LayersIcon;
