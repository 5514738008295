import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BasePipContainerData, PipContainerState } from './types';

export const initialPipContainerState: PipContainerState = {
  byId: {},
};

const pipContainerStateSlice = createSlice({
  name: 'pipContainerState',
  initialState: initialPipContainerState,
  reducers: {
    open<T extends BasePipContainerData>(state: PipContainerState, action: PayloadAction<T>) {
      state.byId[action.payload.id] = {
        ...action.payload,
        isOpen: true,
      };
    },
    close(state: PipContainerState, action: PayloadAction<{ containerId: string }>) {
      const { containerId } = action.payload;
      state.byId[containerId] = undefined;
    },
    updatePipContainerData<T extends {}>(
      state: PipContainerState,
      action: PayloadAction<{
        containerId: string;
        updates: Partial<T>;
      }>
    ) {
      const { containerId, updates } = action.payload;
      const containerData = state.byId[containerId];

      if (!containerData) return;

      state.byId[containerId] = {
        ...containerData,
        ...updates,
      };
    },
  },
});

export const pipContainerAction = pipContainerStateSlice.actions;

export default pipContainerStateSlice;
