import { createAction } from '@reduxjs/toolkit';

import { AuthAPI } from '../../auth/store/api.types';
import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import {
  AddCourseStudentsPayload,
  AddCourseTeamMemberPayload,
  AddCourseTeamMemberSuccessPayload,
  AddWeeklySchedulePayload,
  AddWeeklyScheduleSuccessPayload,
  CopyActivityPayload,
  CourseFile,
  CreateCoursePayload,
  EditCourseDescriptionPayload,
  ExportActivityGradesPayload,
  ExportActivityGradesSuccessPayload,
  ExportAllGradesPayload,
  FetchBlueprintCoursePreviewSuccessPayload,
  FetchCourseDetailsPayload,
  FetchCourseDetailsSuccessPayload,
  FetchCoursesToCopyActivitySuccessPayload,
  FetchMyCourseAnalyticsSuccessPayload,
  FetchMyCoursesSuccessPayload,
  FetchStudentAveragesForCourseTeamPayload,
  FetchStudentAveragesForCourseTeamSuccessPayload,
  FetchSuggestedActivityDataPayload,
  HideSuggestedClassActivityPayload,
  InitializeCoursePayload,
  JoinCourseByJoinCodeRequest,
  PublishCourseSchedulePayload,
  PublishCourseSuccessPayload,
  ReinstateRemovedStudentPayload,
  RemoveCourseMemberPayload,
  RemoveCourseStudentPayload,
  RemoveCourseStudentSuccessPayload,
  RemoveWeeklyScheduleSuccessPayload,
  SaveOfficeHoursPayload,
  SendJoinCode,
  SetCourseEndDatePayload,
  SetCourseStartDatePayload,
  SetTimezoneSuccessPayload,
  UseSuggestedActivityPayload,
} from './types';

export const openCourse = createAction<{ courseId: MongoId }>('courses/open');

export const closeCourse = createAction('courses/close');

export const fetchAvailableSlots = createRequestAction<void, API.GetAvailableSlotsResponse>(
  'courses/available-slots/fetch'
);

export const fetchCoursePurchaseCost = createRequestAction<
  API.GetCoursePurchaseCostRequest,
  API.GetCoursePurchaseCostResponse
>('courses/course-purchase-cost/fetch');

export const fetchInstructorPurchaseIntent = createRequestAction<
  API.GetInstructorPurchaseIntentRequest,
  API.GetInstructorPurchaseIntentResponse
>('courses/instructor-purchase-intent/fetch');

export const confirmInstructorPaymentStatus = createRequestAction<
  API.ConfirmInstructorPaymentStatusRequest,
  API.ConfirmInstructorPaymentStatusResponse
>('courses/instructor-payment-status/confirm');

export const fetchPurchaseCourseIntent = createRequestAction<
  API.GetPurchaseCourseIntentRequest,
  API.GetPurchaseCourseIntentResponse
>('courses/purchase-intent/fetch');

export const updateCoursePurchaseStatus = createRequestAction<
  API.UpdateCoursePurchaseStatusRequest,
  API.UpdateCoursePurchaseStatusRequest & API.UpdateCoursePurchaseStatusResponse
>('courses/purchase-status/udpate');

export const coursePurchaseSuccessful = createAction<{ courseId: MongoId }>('courses/purchase/successful');

export const createCourse = createRequestAction<CreateCoursePayload, API.CreateCourseResponse>(
  'courses/create'
);

export const fetchCoursePurchaseCouponStatus = createRequestAction<
  void,
  API.GetCoursePurchaseCouponStatusResponse
>('courses/purchased-coupon/status');

export const redeemCoupon = createRequestAction<API.RedeemCouponRequest, API.RedeemCouponResponse>(
  'courses/coupon/redeem'
);

export const fetchAdminCourses = createRequestAction<void, API.GetAdminCoursesResponse>('fetch/admin-course');

export const deleteCourses = createRequestAction<API.DeleteCourseRequest, API.DeleteCourseRequest>(
  'courses/delete'
);

export const fetchBlueprintCourses = createRequestAction<void, API.FetchBlueprintCoursesResponse>(
  'courses/blueprint/fetch'
);

export const fetchBlueprintCoursePreview = createRequestAction<
  API.FetchBlueprintCoursePreviewRequest,
  FetchBlueprintCoursePreviewSuccessPayload
>('courses/blueprint/preview');

export const initializeCourse = createRequestAction<InitializeCoursePayload, API.InitializeCourseResponse>(
  'courses/initialize'
);

export const fetchTimezones = createRequestAction<void, API.FetchTimezonesResponse>('timezones/fetch');

export const setTimezone = createRequestAction<API.SetTimezoneRequest, SetTimezoneSuccessPayload>(
  'courses/timezone/set'
);

export const addWeeklySchedule = createRequestAction<
  AddWeeklySchedulePayload,
  AddWeeklyScheduleSuccessPayload
>('courses/schedule/weekly/add');

export const removeWeeklySchedule = createRequestAction<
  API.RemoveWeeklyScheduleRequest,
  RemoveWeeklyScheduleSuccessPayload
>('courses/schedule/weekly/remove');

export const checkNumScheduledClassesLimit = createRequestAction<
  void,
  API.CheckNumScheduledClassesLimitResponse
>('courses/schedule/weekly/check-limit');

export const publishCourseSchedule = createRequestAction<
  PublishCourseSchedulePayload,
  API.PublishCourseScheduleResponse
>('courses/publish-schedule');

export const publishCourse = createRequestAction<void, PublishCourseSuccessPayload>('courses/publish');

export const fetchMyCourses = createRequestAction<void, FetchMyCoursesSuccessPayload>('courses/fetch/all');

export const archiveCourse = createRequestAction('courses/archive');

export const fetchCourseDetails = createRequestAction<
  FetchCourseDetailsPayload,
  FetchCourseDetailsSuccessPayload
>('courses/fetch/details');

export const fetchCourseDetailsByJoinCode = createRequestAction<
  SendJoinCode,
  AuthAPI.GetCourseByCodeResponse
>('courses/fetch/by-join-code');

export const joinCourseByJoinCode = createRequestAction<
  JoinCourseByJoinCodeRequest,
  API.JoinCourseByJoinCodeResponse
>('courses/join/by-join-code');

export const createProDemoCourse = createRequestAction<
  API.CreateProDemoCourseRequest,
  API.CreateProDemoCourseResponse
>('courses/demo/create-pro');

export const createBasicDemoCourse = createRequestAction<
  API.CreateBasicDemoCourseRequest,
  API.CreateBasicDemoCourseResponse
>('courses/demo/create-basic');

export const fetchCoursesToCopyActivity = createRequestAction<void, FetchCoursesToCopyActivitySuccessPayload>(
  'courses/fetch/copy-activities'
);

export const copyActivity = createRequestAction<CopyActivityPayload>('courses/activities/copy');

export const fetchCourseInfo = createRequestAction<void, API.FetchCourseInfoResponse>('courses/fetch/info');

export const publishCourseInfo = createRequestAction('courses/info/publish');

export const setCourseStartDate = createRequestAction<
  SetCourseStartDatePayload,
  API.SetCourseStartDateResponse
>('courses/start-date/set');

export const setCourseEndDate = createRequestAction<SetCourseEndDatePayload, API.SetCourseEndDateResponse>(
  'courses/end-date/set'
);

export const editCourseDescription = createRequestAction<
  EditCourseDescriptionPayload,
  EditCourseDescriptionPayload
>('courses/description/edit');

export const saveOfficeHours = createRequestAction<SaveOfficeHoursPayload, SaveOfficeHoursPayload>(
  'courses/office-hours/save'
);

export const saveCourseInfoFile = createRequestAction<API.SaveCourseInfoFileRequest, CourseFile>(
  'courses/info-file/save'
);

export const removeCourseInfoFile = createRequestAction<
  API.RemoveCourseInfoFileRequest,
  API.RemoveCourseInfoFileRequest
>('courses/info-file/remove');

export const saveCourseSyllabusFile = createRequestAction<API.SaveCourseSyllabusFileRequest, CourseFile>(
  'courses/syllabus-file/save'
);

export const addCourseTeamMember = createRequestAction<
  AddCourseTeamMemberPayload,
  AddCourseTeamMemberSuccessPayload
>('courses/team-members/add');

export const removeCourseMember = createRequestAction<RemoveCourseMemberPayload, RemoveCourseMemberPayload>(
  'courses/members/remove'
);

export const fetchCourseEnrollments = createRequestAction<void, API.FetchCourseEnrollmentsResponse>(
  'courses/enrollments/fetch'
);

export const addCourseStudents = createRequestAction<AddCourseStudentsPayload, API.AddCourseStudentsResponse>(
  'courses/students/add'
);

export const removeCourseStudent = createRequestAction<
  RemoveCourseStudentPayload,
  RemoveCourseStudentSuccessPayload
>('courses/students/remove');

export const reinstateRemovedStudent = createRequestAction<
  ReinstateRemovedStudentPayload,
  ReinstateRemovedStudentPayload
>('courses/students/reinstate');

export const resendCourseInvites = createRequestAction('courses/invites/resend');

export const fetchCourseAveragesForCourseTeam = createRequestAction<
  void,
  API.GetCourseAveragesForCourseTeamResponse
>('courses/averages/for-team');

export const fetchStudentAveragesForCourseTeam = createRequestAction<
  FetchStudentAveragesForCourseTeamPayload,
  FetchStudentAveragesForCourseTeamSuccessPayload
>('courses/student-averages/for-team');

export const fetchMyCourseAnalytics = createRequestAction<void, FetchMyCourseAnalyticsSuccessPayload>(
  'courses/fetch/my-analytics'
);

export const fetchAllSuggestedActivities = createRequestAction<void, API.GetAllSuggestedActivityResponse>(
  'courses/fetch/all-suggested-activities'
);

export const fetchSuggestedActivityData = createRequestAction<
  FetchSuggestedActivityDataPayload,
  API.FetchSuggestedActivityDataResponse
>('courses/fetch/suggested-activity');

export const useSuggestedActivity = createRequestAction<
  UseSuggestedActivityPayload,
  UseSuggestedActivityPayload
>('courses/suggested-activity/use');

export const hideSuggestedActivity = createRequestAction<
  HideSuggestedClassActivityPayload,
  HideSuggestedClassActivityPayload
>('courses/suggested-activity/hide');

/**
 * Exports grades of an individual activity
 */
export const exportActivityGrades = createRequestAction<
  ExportActivityGradesPayload,
  ExportActivityGradesSuccessPayload
>('courses/activity-data/export');

/**
 * Exports all grades of a particular activity-type in current course,
 * for example, export all grades of all quizzes in current course
 */
export const exportAllGrades = createRequestAction<ExportAllGradesPayload, API.ExportAllGradesResponse>(
  'courses/all-grades/export'
);
