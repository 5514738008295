const en = {
  quiz_num: 'Quiz {{num}}',

  showing_all_quizzes: 'Showing all quizzes',
  no_quizzes_have_been_published_in_this_course: 'No quizzes have been published in the course',
  no_unpublished_quizzes_have_been_created_in_this_course:
    'No unpublished quizzes have been created in this course',
  no_quizzes_have_been_created_in_this_course: 'No quizzes have been created in this course',

  quiz_analytics: 'Quiz Analytics',
  click_to_open_quiz_analytics: 'click to open quiz analytics',
  click_to_close_quiz_analytics: 'click to close quiz analytics',

  click_to_export_quiz_data_as_csv: 'Click to export quiz data as csv',
  export_quiz_data_as_csv: 'Export quiz data as CSV',
  export_quiz_data: 'Export quiz data',
  status_colon_date: '{{status}}: {{date}}',

  click_to_delete_quiz: 'click to delete quiz',
  click_to_copy_quiz_to_another_class: 'click to copy quiz to another class',
  click_to_move_quiz_to_another_class: 'click to move quiz to another class',
  quizzes_need_to_have_at_least_one_question_please_add_a_question_s_and_try_again:
    'Quizzes need to have at least one question. Please add a question(s) and try again',
  click_to_publish_quiz: 'click to publish quiz',
  click_to_edit_quiz: 'click to edit quiz',

  do_you_want_to_discard_changes_made_to_quiz: 'Do you want to discard changes made to quiz?',
  click_to_update_quiz: 'click to update quiz',

  show_answers: 'Show answers',
  click_to_show_answers: 'click to show answerss',
  hide_answers: 'Hide answers',
  click_to_hide_answers: 'click to hide answers',
  reorder_questions: 'Reorder questions',
  click_to_reorder_questions: 'click to reorder questions',
  click_and_hold_to_drag_and_reorder_questions: 'click and hold to drag and reorder questions',
  no_questions_added_yet_click_on_plus_icon_to_add_a_question:
    'No questions added yet. Click on <1></1> icon to add a question.',

  click_to_select_true: 'click to select true',
  click_to_deselect_true: 'click to deselect true',
  click_to_select_false: 'click to select false',
  click_to_deselect_false: 'click to deselect false',
  select_all_correct_options: 'Select all correct options',

  click_to_view_question_N_analytics: 'click to view question {{num}} analytics',
  click_to_edit_question_N: 'click to edit question {{num}}',

  add_quiz_question: 'Add quiz question',
  true_false_type: 'True/False type',
  click_to_add_true_false_type_question: 'click to add true false type question',
  multiple_choice_type: 'Multiple choice type',
  click_to_add_multiple_choice_type_question: 'click to add multiple choice type question',
  sorting_type: 'Sorting type',
  click_to_add_sorting_type_question: 'click to add sorting type question',
  available_ony_in_acadly_s_pro_courses: "Avilable only in Acadly's Pro courses",
  click_to_add_quiz_question: 'click to add quiz question',
  click_to_add_true_false_type_quiz: 'click to add true false type quiz',
  click_to_add_multiple_choice_type_type_quiz: 'click to add multiple choice type type quiz',
  click_to_add_sorting_type_type_type_quiz: 'click to add sorting type type type quiz',

  add_true_false_question: 'Add True/False question',
  edit_true_false_question: 'Edit True/False question',
  the_above_statement_is: 'The above statement is',
  please_select_a_correct_option: 'Please select a correct option.',

  add_multiple_choice_question: 'Add multiple choice question',
  edit_multiple_choice_question: 'Edit multiple choice question',
  please_select_atleast_one_correct_option: 'Please select atleast one correct option',
  specify_correct_options: 'Specify correct options',
  upload_an_image: 'Upload an image',
  choose_image: 'Choose image',

  add_a_sorting_type_question: 'Add a sorting type question',
  edit_sorting_type_question: 'Edit sorting type question',
  options_in_correct_order: 'Options (in correct order)',

  scoring_scheme: 'Scoring scheme',
  click_to_edit_scoring_scheme: 'click to edit scoring scheme',
  click_to_select_incentivizing: 'click to select incentivizing',
  click_to_select_neutral: 'click to select neutral',
  click_to_select_penalizing: 'click to select penalizing',
  student_submission_score: 'Score: {{score}}',
  student_submission_status: '{{status}}',
  click_to_see_student_submission: 'click to see student submission',
  once_the_students_start_submitting_the_quiz_you_will_be_able_to_see_the_averages_and_each_students_responses_here:
    "Once the students start submitting the quiz, you will be able to see the averages and each student's responses here.",
  picked_colon_N: 'Picked: {{alphabet}}',
  use_drag_handle_or_arrow_buttons_to_reorder_options: 'Use drag handle or arrow buttons to reorder options',
  click_to_move_this_item_1_step_up: 'click to move this item 1 step up',
  click_to_move_this_item_1_step_down: 'click to move this item 1 step down',
  click_and_hold_to_drag_and_reorder_options: 'click and hold to drag and reorder options',
  this_quiz_has_been_updated_click_okay_to_reload_the_changes:
    'This quiz has been updated, click okay to reload the changes.',

  acadly_allows_you_to_choose_one_of_the_three_scoring_schemes:
    'Acadly allows you to choose one of the three scoring schemes',
  for_each_attempted_question_student_gets_colon: 'For each attempted question, student gets:',
  for_each_unattempted_question_student_gets_N_points:
    'For each unattempted question, student gets {{points}} points',
  N_for_picking_each_correct_option: '{{points}} for picking each correct option',
  N_for_not_picking_an_incorrect_option: '{{points}} for not picking an incorrect option',
  N_for_picking_all_correct_options: '{{points}} for picking all correct options',
  N_otherwise: '{{points}} otherwise',
  incentivizes_attempts_high_possibility_of_guesswork: 'Incentivizes attempts, high possibility of guesswork',
  high_possibility_of_guesswork: 'High possibility of guesswork',
  possibility_of_relatively_low_average_scores_curbs_guesswork:
    'Possibility of relatively low average scores, curbs guesswork',
  click_to_save_scoring_scheme: 'click to save scoring scheme',

  publishing_quiz: 'Publishing Quiz',
  quiz_preferences: 'Quiz preferences',
  quiz_is_currently_open: 'Quiz is currently open',
  quiz_closes: 'Quiz closes',
  click_to_select_duration_for_closing_quiz: 'click to select {{duration}} for closing quiz',
  quiz_closed: 'Quiz closed',
  available_after_deadline: 'Available after deadline',
  quiz_not_submitted: 'Quiz not submitted',
  closes_on: 'Closes on',
  students_wont_be_able_to_submit_the_quiz_after_the_deadline:
    "Students won't be able to submit the quiz after the deadline",
  students_will_see_their_scores_and_answers_as_soon_as_they_submit_the_quiz:
    'Students will see their scores and answers as soon as they submit the quiz.',
  students_will_see_their_scores_and_the_quiz_answers_not_upon_submitting_the_quiz_but_after_the_quiz_deadline_is_over:
    'Students will see their scores and the quiz answers not upon submitting the quiz but after the quiz deadline is over.',
  randomize_quiz_questions_order: "Randomize Quiz Questions' Order",
  turn_off_to_not_randomize_quiz_questions_order: "turn off to not randomize quiz questions' order",
  turn_on_to_randomize_quiz_questions_order: "turn on to randomize quiz questions' order",
  you_had_manually_reordered_the_questions_randomizing_the_questions_will_disregard_the_order:
    'You had manually reordered the questions. Randomizing the questions will disregard the order',
  randomize_options_order: "Randomize Options' Order",
  turn_off_to_not_randomize_options_order: "turn off to not randomize options' order",
  turn_on_to_randomize_options_order: "turn on to randomize options' order",
  use_same_publish_preferences_as_default_for_all_in_class_quizzes:
    'Use same publish preferences as default for all in-class quizzes',
  click_to_save_quiz_publish_preferences: 'click to save quiz publish preferences',

  for_each_attempted_question: 'For each attempted question',
  N_for_marking_correct_option: '{{points}} for marking correct option',
  N_for_not_marking_an_incorrect_option: '{{points}} for not marking an incorrect option',
  N_for_marking_all_correct_options: '{{points}} for marking all correct options',
  N_in_any_other_case: '{{points}} in any other case',
  N_for_marking_an_incorrect_option: '{{points}} for marking an incorrect option',
  for_each_unattempted_question: 'For each unattempted question',
  you_score_N_points: 'You score {{points}} points',

  quiz_closes_in_time: 'Quiz closes in {{time}}',
  attempt_quiz: 'Attempt quiz',
  click_to_attempt_quiz: 'click to attempt quiz',
  resume_quiz: 'Resume quiz',
  click_to_resume_quiz: 'click to resume quiz',

  submitting_quiz: 'Submitting quiz',
  you_won_t_be_able_to_undo_this_submission: "You won't be able to undo this submission.",
  click_to_save_quiz: 'click to save quiz',
  click_to_submit_quiz: 'click to submit quiz',
  click_to_ignore_submitting_quiz: 'click to ignore submitting quiz',

  this_quiz_does_not_allow_late_submissions: 'This quiz DOES NOT allow late submissions.',

  /** comments panel */
  quiz_discussion: 'Quiz Discussion',
  export_quiz_discussion_data_as: 'Export quiz discussion data as',
  this_is_the_quiz_discussion_area_any_doubts_or_points_regarding_the_quiz_can_be_posted_here_after_you_ve_submitted_the_quiz_happy_discussing:
    "This is the quiz discussion area. Any doubts or points regarding the quiz can be posted here after you've submitted the quiz. Happy discussing!",
  you_will_be_able_to_post_doubts_questions_and_random_musings_about_this_quiz_once_you_have_submitted_the_quiz_do_well:
    'You will be able to post doubts, questions and random musings about this quiz once you have submitted the quiz. Do well!',
  students_will_be_able_to_post_their_thoughts_on_this_quiz_using_this_discussion_area_to_prevent_quiz_spoilers_the_students_will_be_able_to_access_it_only_once_they_have_submitted_the_quiz:
    'Students will be able to post their thoughts on this quiz using this discussion area. To prevent quiz spoilers, the students will be able to access it only once they have submitted the quiz.',

  /** quiz page onboarding tips */
  use_this_button_to_add_a_question_to_the_quiz_you_can_choose_to_add_either_a_multiple_choice_question_or_a_true_false_type_question_or_a_sorting_type_question_there_is_no_limit_to_the_number_of_questions_you_can_add:
    'Use this button to add a question to the quiz. You can choose to add either a Multiple Choice Question or a True/False type question, or a Sorting type question. There is no limit to the number of questions you can add',
  to_attempt_the_quiz_questions_use_this_button_you_can_quit_anytime_and_your_quiz_progress_will_be_saved:
    'To attempt the quiz questions, use this button. You can quit anytime and your quiz progress will be saved',
  once_you_ve_submitted_the_quiz_you_ll_be_able_to_access_discussions_and_clarify_doubts_about_this_quiz_using_this_section:
    "Once you've submitted the quiz, you'll be able to access discussions and clarify doubts about this quiz using this section.",
  you_and_the_students_can_conduct_any_discussions_around_a_quiz_using_the_discussion_section_only_those_students_who_ve_submitted_the_quiz_can_participate:
    "You and the students can conduct any discussions around a quiz using the discussion section. Only those students who've submitted the quiz can participate",

  /** attempt quiz page onboarding tips */
  you_can_save_and_your_progress_in_the_quiz_by_using_this_button_this_will_not_submit_the_quiz:
    'You can save and your progress in the quiz by using this button. This will NOT submit the quiz',
  whenever_you_re_satisfied_with_your_answers_to_the_questions_you_can_use_this_button_to_submit_the_quiz_please_be_careful_of_not_missing_the_deadline:
    "Whenever you're satisfied with your answers to the questions, you can use this button to Submit the quiz. Please be careful of not missing the deadline",

  /** Quiz widget + Suggested Quiz */
  click_to_view_quiz_title_quiz: 'click to view {{quizTitle}} quiz',
};

export default en;
