import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../../../../../../components/Button';
import Tooltip from '../../../../../../../../../components/Tooltip';
import { MarkCommentAction } from '../../../../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../../../../i18n';
import GiftIcon from '../../../../../../../../../icons/GiftIcon';
import SpinnerIcon from '../../../../../../../../../icons/LoaderIcon';
import useThankVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  edge?: ButtonProps['edge'];
  className?: string;
  classes?: Partial<Record<'root' | 'action', string>>;
}

const Thank = ({ commentId, edge, className, classes }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);
  const {
    canThank,
    isThankLoading,
    isThankDisabled,
    hasThanked,
    handleMarkComment: _handleMarkComment,
    commentCreatorName,
  } = useThankVM({ commentId });

  const handleMarkComment = (mark: MarkCommentAction.THANK | MarkCommentAction.UNTHANK) => () => {
    _handleMarkComment(mark);
  };

  if (!canThank) return null;

  return (
    <Tooltip
      title={hasThanked ? t('undo_thank', { ns: i18nNS.COMMENTS }) : t('thank', { ns: i18nNS.GLOSSARY })}
      className={clsx(className, classes?.root)}
    >
      <span>
        <Button
          variant="text"
          size="small"
          edge={edge}
          className={classes?.action}
          aria-label={t(hasThanked ? 'click_to_undo_thank_creator_name' : 'click_to_thank_creator_name', {
            ns: i18nNS.COMMENTS,
            name: commentCreatorName,
          })}
          disabled={isThankDisabled}
          onClick={handleMarkComment(hasThanked ? MarkCommentAction.UNTHANK : MarkCommentAction.THANK)}
        >
          {isThankLoading ? (
            <SpinnerIcon fontSize="small" />
          ) : (
            <GiftIcon variant={hasThanked ? 'filled' : 'outlined'} fontSize="small" aria-hidden />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default Thank;
