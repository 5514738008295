import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import { LinearProgress, linearProgressClasses, Props } from './styles';

export type { Props };

const LinearProgressbar = (
  { size = 'thin', color = 'primary', className, ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <LinearProgress
      ref={ref}
      variant={props.variant || 'indeterminate'}
      color={color}
      value={props.variant === 'determinate' ? props.value : undefined}
      className={clsx(className, {
        [linearProgressClasses.sizeThin]: size === 'thin',
        [linearProgressClasses.sizeThin]: size === 'medium',
        [linearProgressClasses.sizeThick]: size === 'thick',
        [linearProgressClasses.colorSuccess]: color === 'success',
        [linearProgressClasses.colorError]: color === 'error',
        [linearProgressClasses.colorWarning]: color === 'warning',
      })}
    />
  );
};

export default forwardRef(LinearProgressbar);
