import { AvatarProps } from '@mui/material';

import clsx from 'clsx';

import { ReactComponent as DefaultAvatar } from '../../assets/default-avatar.svg';
import { DEFAULT_AVATAR_ID } from '../../constants';
import { Avatar, avatarClasses } from './styles';
import useUserAvatarVM, { VMProps } from './vm';

export interface Props extends VMProps, DistributiveOmit<AvatarProps, 'src'> {}

const UserAvatar = ({ className, variant = 'rounded', ...props }: Props) => {
  const { avatarId } = props;
  const { loaded, avatarURL, nameInitials, handleLoad } = useUserAvatarVM(props);

  return (
    <Avatar
      {...props}
      variant={variant}
      src={!avatarId || avatarURL === DEFAULT_AVATAR_ID ? undefined : avatarURL}
      imgProps={{ ...props.imgProps, onLoad: handleLoad }}
      className={clsx(className, { [avatarClasses.defaultBackground]: !loaded && avatarId })}
    >
      {nameInitials || <DefaultAvatar />}
    </Avatar>
  );
};

export default UserAvatar;
