import { API } from '../shared/api-responses';

export enum TopicType {
  PARENT = 'parent_topic',
  SUB_TOPIC = 'sub_topic',
}

export interface BaseTopic {
  id: MongoId;
  type: TopicType;
  title: string;
  /** list of book-ids */
  books: MongoId[];
  /** list of link-ids */
  links: MongoId[];
}

export interface CourseParentTopic extends BaseTopic {
  type: TopicType.PARENT;
  /** list of sub-topic-ids */
  subTopics: MongoId[];
}

export interface CourseSubTopic extends BaseTopic {
  type: TopicType.SUB_TOPIC;
  parentId: MongoId;
}

export type CourseTopic = CourseParentTopic | CourseSubTopic;

interface BaseAddTopicPayload {
  title: string;
  /** List of book-ids, refers to {@link CourseBook.id book id} */
  books: MongoId[];
  /** List of link-ids, refers to {@link CourseLink.id link id} */
  links: MongoId[];
}
interface AddParentTopicPayload extends BaseAddTopicPayload {
  type: TopicType.PARENT;
}

interface AddSubTopicPayload extends BaseAddTopicPayload {
  type: TopicType.SUB_TOPIC;
  parentId: MongoId;
}

export type AddCourseTopicPayload = AddParentTopicPayload | AddSubTopicPayload;
export type AddCourseTopicSuccessPayload = API.AddCourseTopicResponse & AddCourseTopicPayload;

export interface DeleteCourseTopicPayload {
  type: TopicType;
  topicId: MongoId;
}
