import { ButtonGroup, styled } from '@mui/material';

import { generateClasses } from '../../../../../../../../utils/helpers';

export const commentActionsClasses = {
  ...generateClasses('CommentActions', ['action']),
};

export const Root = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.acadlyShadows.md,
  display: 'flex',
  alignItems: 'stretch',
  gap: 1,
  [`& > *`]: {
    border: `1px solid ${theme.palette.primary[200]}`,
    borderRadius: 0,
    display: 'flex',
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  [`& .${commentActionsClasses.action}`]: {
    /** targeting using ":not" pseudo selector for increasing css specificity inside ButtonGroup  */
    '&:not(:first-of-type)': {
      borderColor: theme.palette.primary[200],
    },
    '&:not(:last-of-type)': {
      borderColor: theme.palette.primary[200],
    },
  },
}));
