import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

/**
 * FIXME: infer Generic from RadioOption in RadioGroup
 */
interface RadioGroupContextProps {
  canUncheck: boolean;
  setCanUncheck: Dispatch<SetStateAction<boolean>>;
  value: string | null;
  setValue: Dispatch<SetStateAction<string | null>>;
}

export const RadioGroupContext = createContext<RadioGroupContextProps>({
  canUncheck: false,
  setCanUncheck: () => {},
  value: null,
  setValue: () => {},
});

const useRadioGroupContextProviderVM = () => {
  const [canUncheck, setCanUncheck] = useState(false);
  const [value, setValue] = useState<string | null>(null);

  return useMemo(
    () => ({
      canUncheck,
      setCanUncheck,
      value,
      setValue,
    }),
    [canUncheck, value]
  );
};

interface Props {
  children: ReactNode;
}

const RadioGroupContextProvider = ({ children }: Props) => {
  const providerValue = useRadioGroupContextProviderVM();
  return <RadioGroupContext.Provider value={providerValue}>{children}</RadioGroupContext.Provider>;
};

export default RadioGroupContextProvider;
