import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { MarkFeatureUpdateAsReadPayload } from './types';

export async function getFeatureUpdates() {
  const { data } = await request.get<API.GetAllFeatureUpdatesResponse>(urls.newFeatures);
  return data;
}

export async function newFeaturesLastAccesed() {
  const { data } = await request.put<API.NewFeaturesLastAccesedResponse>(urls.newFeaturesLastAccesed);
  return data;
}

export async function markFeatureUpdateAsRead({ featureName }: MarkFeatureUpdateAsReadPayload) {
  const params: API.MarkFeatureUpdateAsReadRequest = { featureSeen: featureName };
  const { data } = await request.get<API.MarkFeatureUpdateAsReadResponse>(urls.newFeatures, { params });
  return data;
}
