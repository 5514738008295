import { Descendant } from 'slate';

import Logger from '../../utils/logger';
import { FormulaElement } from '../plugins/formula/types';
import { ImageElement } from '../plugins/image/types';
import { RichTextNode, TextNode } from '../types';

const logger = Logger.create('richtext-editor');

function getFormulaTag(node: FormulaElement) {
  const element = document.createElement('formula');

  element.setAttribute('format', node.format);
  element.setAttribute('display', node.display);
  element.setAttribute('id', node.id);

  element.setAttribute('ach', node.ach);
  element.setAttribute('acw', node.acw);
  element.setAttribute('url', node.url);

  element.innerHTML = node.code;

  return element.outerHTML;
}

function getImageTag(node: ImageElement) {
  const element = document.createElement('img');

  element.setAttribute('src', node.url);
  element.setAttribute('acw', node.acw);
  element.setAttribute('ach', node.ach);

  return element.outerHTML;
}

function getFormattedText(node: TextNode) {
  const element = document.createElement('div');
  let leafChild: HTMLElement = element;

  if (node.bold) {
    const bold = document.createElement('b');
    leafChild.appendChild(bold);
    leafChild = bold;
  }

  if (node.italic) {
    const italic = document.createElement('i');
    leafChild.appendChild(italic);
    leafChild = italic;
  }

  if (node.underline) {
    const underline = document.createElement('u');
    leafChild.appendChild(underline);
    leafChild = underline;
  }

  leafChild.innerHTML = node.text.replaceAll('\n', '<br>');

  return element.innerHTML;
}

export default function serialize(nodes: Descendant[]): RichText {
  let richtext: RichText = '';
  let lastNodeType: RichTextNode['type'] = 'text';

  for (let node of nodes) {
    /**
     * after adding inline formula, i don't know why a new node is automatically added?
     * Also, automatically added node looks like { text: '' }
     * It does not have a type, so text after any inline formula was getting ignored.
     * Below mentioned code, fixes it, but still I do not have a clue on why this happens!
     */
    if (!('type' in node) && 'text' in node) {
      node = { ...(node as any), type: 'text' };
    }

    switch (node.type) {
      case 'text':
        richtext += getFormattedText(node);
        lastNodeType = 'text';
        break;
      case 'formula':
        richtext += getFormulaTag(node);
        lastNodeType = 'formula';
        break;
      case 'image':
        richtext += getImageTag(node);
        lastNodeType = 'image';
        break;
      case 'paragraph':
        if (lastNodeType === 'paragraph') richtext += '<br>';
        richtext += serialize(node.children);
        lastNodeType = 'paragraph';
        break;
      default:
        logger.error(`Unhandled slate node found: ${(node as any).type}`);
        break;
    }
  }

  return richtext;
}
