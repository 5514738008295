import { useTranslation } from 'react-i18next';

import ForNonDesktop from '../components/ForNonDesktop';
import ChatButton from '../components/HeaderActions/ChatButton';
import { i18nNS } from '../i18n';
import QuestionMarkCircleIcon from '../icons/QuestionMarkCircleIcon';

const HomeHeaderActions = () => {
  const { t } = useTranslation([i18nNS.COMMON]);

  return (
    <ForNonDesktop>
      <ChatButton edge="end" aria-label={t('click_to_open_help_panel', { ns: i18nNS.COMMON })}>
        <QuestionMarkCircleIcon aria-hidden />
      </ChatButton>
    </ForNonDesktop>
  );
};

export default HomeHeaderActions;
