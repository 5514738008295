import { styled } from '@mui/material';

import Button from '../../../../components/Button';
import { generateClasses } from '../../../../utils/helpers';

export const leftActionsClasses = {
  ...generateClasses('LeftActions', ['button']),
};

export const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${leftActionsClasses.button}`]: {
    color: theme.palette.grey[500],
  },
}));
