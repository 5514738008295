import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyDatePicker, { DateValue, Props as AcadlyDatePickerProps } from '../FormControls/Datepicker';

type VMProps<T extends FieldValues, U extends DateValue> = UseControllerProps<T> & {
  onChange?: AcadlyDatePickerProps<U>['onChange'];
};

const useDatepickerVM = <T extends FieldValues, U extends DateValue>({
  control,
  name,
  defaultValue,
  onChange: _onChange,
}: VMProps<T, U>) => {
  const controller = useController({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: inputRef, value, onBlur, onChange } = field;
  const { error } = fieldState;

  const handleChange: AcadlyDatePickerProps<U>['onChange'] = (newValue) => {
    onChange(newValue);
    _onChange?.(newValue);
  };

  return { error, handleBlur: onBlur, handleChange, inputRef, value };
};

export type Props<T extends FieldValues, U extends DateValue> = VMProps<T, U> &
  DistributiveOmit<AcadlyDatePickerProps<U>, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const DatePicker = <T extends FieldValues, U extends DateValue>({
  control,
  name,
  defaultValue,
  helperText,
  onChange,
  ...props
}: Props<T, U>) => {
  const { error, handleBlur, handleChange, inputRef, value } = useDatepickerVM<T, U>({
    control,
    name,
    defaultValue,
    onChange,
  });

  return (
    <AcadlyDatePicker
      {...props}
      ref={inputRef}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default DatePicker;
