import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Discussion } from './types';

export const selectFullDiscussionId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { discussions } = state.db;
  return getFullId(discussions, shortId);
};

export const selectDiscussion = (id: ShortId | MongoId) => (state: RootState) => {
  const { discussions } = state.db;
  return getActivityById(discussions, id);
};

export const selectAllDiscussions = (state: RootState) => {
  const { discussions } = state.db;

  const publishedDiscussions: Discussion[] = [];
  const unpublishedDiscussions: Discussion[] = [];

  if (!discussions.byId)
    return {
      publishedDiscussions,
      unpublishedDiscussions,
    };

  for (const discussion of Object.values(discussions.byId)) {
    if (!discussion) continue;
    if (discussion.publishedOn <= 0) {
      unpublishedDiscussions.push(discussion);
    } else {
      publishedDiscussions.push(discussion);
    }
  }

  return {
    publishedDiscussions,
    unpublishedDiscussions,
  };
};

export const selectSuggestedDiscussion = (id: MongoId) => (state: RootState) => {
  const { suggestedById } = state.db.discussions;
  return suggestedById[id];
};
