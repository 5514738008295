import CreateTrialAccount from '../CreateTrialAccount';
import EmailVerification from '../EmailVerification';
import InstructorVerification from '../InstructorVerification';
import JoinCode from '../JoinCode';
import LoginEmail from '../LoginEmail';
import LoginPassword from '../LoginPassword';
import SetName from '../SetName';
import SetPassword from '../SetPassword';
import SetProfileAvatar from '../SetProfileAvatar';
import SignupEmail from '../SignupEmail';
import Sso from '../Sso';
import ThankYou from '../ThankYou';
import { AuthScreen } from '../types';
import VerificationAwaited from '../VerificationAwaited';
import useAuthScreenContainerVM from './vm';

interface Props {
  path: 'login' | 'signup';
}

const AuthScreenContainer = ({ path }: Props) => {
  const { screen } = useAuthScreenContainerVM();

  switch (screen) {
    case AuthScreen.SSO:
      return <Sso />;
    case AuthScreen.PASSWORD:
      return <LoginPassword />;
    case AuthScreen.FORGOT_PASSWORD:
      return <EmailVerification isResettingPassword />;
    case AuthScreen.RESET_PASSWORD:
      return <SetPassword isResettingPassword />;
    case AuthScreen.VERIFY_EMAIL:
      return <EmailVerification />;
    case AuthScreen.SET_PASSWORD:
      return <SetPassword />;
    case AuthScreen.SET_NAME:
      return <SetName />;
    case AuthScreen.SET_PROFILE_AVATAR:
      return <SetProfileAvatar />;
    case AuthScreen.VERIFICATION_AWAITED:
      return <VerificationAwaited />;
    case AuthScreen.JOIN_CODE:
      return <JoinCode />;
    case AuthScreen.CREATE_TRIAL_ACCOUNT:
      return <CreateTrialAccount />;
    case AuthScreen.INSTRUCTOR_VERIFICATION:
      return <InstructorVerification />;
    case AuthScreen.THANK_YOU:
      return <ThankYou />;
    default:
      if (path === 'signup') return <SignupEmail />;
      return <LoginEmail />;
  }
};

export default AuthScreenContainer;
