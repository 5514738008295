import { useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import BellIcon from '../../icons/BellIcon';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import ManageNotificationsDrawer from './ManageNotificationsDrawer';
import useManageNotificationsVM from './vm';

const ManageNotifications = () => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  const { isManageNotificationsDrawerOpen, handleCloseManageNotificationsDrawer, handleManageNotifications } =
    useManageNotificationsVM();

  return (
    <>
      <ListItemButton
        onClick={handleManageNotifications}
        aria-label={t('click_to_manage_notifications', { ns: i18nNS.SETTINGS })}
        aria-haspopup
      >
        <ListItemIcon>
          <BellIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('manage_notifications', { ns: i18nNS.SETTINGS })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <ManageNotificationsDrawer
        open={isManageNotificationsDrawerOpen}
        onClose={handleCloseManageNotificationsDrawer}
      />
    </>
  );
};

export default ManageNotifications;
