import { useCallback, useEffect, useState } from 'react';

import { toDate } from '../../../utils/datetime';

export type DateValue = Date | UnixTime | string;

export interface Props<T extends DateValue> {
  open?: boolean;
  onClose?: (value: T | null) => any;
  value: T | null;
  onChange: (newValue: T | null) => any;
  onBlur?: () => void;
  /** Max date (inclusive) */
  maxDate?: T;
  /** Min date (inclusive) */
  minDate?: T;
  /**
   * Parses the value passes to datepicker into Date object
   */
  parser?: (value: T | null) => Date | null;
  /**
   * Formats date into the type of the value prop
   */
  formatter?: (date: Date | null) => T | null;
}

export const defaultParser = <T extends DateValue>(value: T | null) => {
  if (value == null) return null;
  if (typeof value === 'string') return new Date(value);
  return toDate(value);
};

export const defaultFormatter = <R extends DateValue>(value: Date | null) => value as R | null;

const useDatePickerVM = <T extends DateValue>({
  open = false,
  onClose,
  value: initialValue,
  onChange,
  onBlur,
  minDate: _minDate,
  maxDate: _maxDate,
  parser = defaultParser,
  formatter = defaultFormatter,
}: Props<T>) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(open);

  const handleCloseCalendar = () => {
    setIsCalendarOpen(false);
  };

  const handleToggleCalendar = () => {
    setIsCalendarOpen((prevVal) => !prevVal);
  };

  useEffect(
    function setInitialOpen() {
      setIsCalendarOpen(open);
    },
    [open]
  );

  const [hasError, setHasError] = useState(false);

  const parseBoundaryDates = useCallback(
    (date: T | undefined) => {
      if (!date) return undefined;
      const result = parser(date);
      return result ?? undefined;
    },
    [parser]
  );

  const [minDate, setMinDate] = useState(parseBoundaryDates(_minDate));
  useEffect(() => setMinDate(parseBoundaryDates(_minDate)), [_minDate, parseBoundaryDates]);

  const [maxDate, setMaxDate] = useState(parseBoundaryDates(_maxDate));
  useEffect(() => setMaxDate(parseBoundaryDates(_maxDate)), [_maxDate, parseBoundaryDates]);

  const [value, setValue] = useState(parser(initialValue));
  useEffect(() => setValue(parser(initialValue)), [initialValue, parser]);

  const handleErrorChange = (reason: string | null, _value: Date | null) => {
    setHasError(Boolean(reason));
  };

  const handleClose = useCallback(() => {
    if (!hasError) {
      const newValue = formatter(value);
      onClose?.(newValue);
    } else {
      // rollback to initial value in case of error
      onClose?.(initialValue);
    }
    handleCloseCalendar();
  }, [hasError, formatter, value, onClose, initialValue]);

  const handleAccept = useCallback(
    (value: Date | null) => {
      if (onBlur) onBlur();
      const newValue = formatter(value);
      onChange(newValue);
      handleCloseCalendar();
    },
    [formatter, onBlur, onChange]
  );

  return {
    value,
    setValue,
    minDate,
    maxDate,
    isCalendarOpen,
    handleCloseCalendar,
    handleToggleCalendar,
    handleClose,
    handleAccept,
    handleErrorChange,
  };
};

export default useDatePickerVM;
