import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import Alert from '../../../components/Alert';
import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import CreateCourseForm from '../CreateCourseForm';
import CreateCoursePreview from '../Preview';
import CourseDates from './CourseDates';
import useScheduleVM from './vm';
import WeeklySchedule from './WeeklySchedule';

const Title = styled((props: TypographyProps) => <Typography {...props} variant="h5Bold" />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const Schedule = () => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.GLOSSARY]);

  const {
    closeDrawer,
    closeTooManyClassesWarning,
    formMethods,
    isSubmitting,
    isVisible,
    moveToNextStep,
    tooManyClassesWarning,
    verifyMaxClassLimit,
  } = useScheduleVM();

  return (
    <FormProvider {...formMethods}>
      <CreateCourseForm
        isVisible={isVisible}
        onSubmit={verifyMaxClassLimit}
        footer={
          <>
            <Button
              type="button"
              variant="text"
              onClick={closeDrawer}
              aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              isLoading={isSubmitting}
              endIcon={<ChevronRightIcon />}
              aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          </>
        }
      >
        <Stack gap={6}>
          <Title>{t('step_2_colon_define_the_course_schedule', { ns: i18nNS.CREATE_COURSE })}</Title>
          <CourseDates
            timezoneControlName="timezone"
            startDateControlName="startDate"
            endDateControlName="endDate"
          />
          <WeeklySchedule />
          <CreateCoursePreview />
        </Stack>
        <Alert open={tooManyClassesWarning.isVisible}>
          <Alert.Header>{t('warning', { ns: i18nNS.GLOSSARY })}</Alert.Header>
          <Alert.Body>
            <Typography variant="paragraphRegular" color="textPrimary">
              {tooManyClassesWarning.message}
            </Typography>
          </Alert.Body>
          <Alert.Footer>
            <Alert.Action type="button" variant="text" color="warning" onClick={moveToNextStep}>
              {t('ignore', { ns: i18nNS.GLOSSARY })}
            </Alert.Action>
            <Alert.Action type="button" onClick={closeTooManyClassesWarning}>
              {t('replan', { ns: i18nNS.GLOSSARY })}
            </Alert.Action>
          </Alert.Footer>
        </Alert>
      </CreateCourseForm>
    </FormProvider>
  );
};

export default Schedule;
