import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { FormHelperText, Link, Stack, useMediaQuery } from '@mui/material';

import TextField from '../../components/HookFormControls/TextField';
import { i18nNS } from '../../i18n';
import routes from '../../pages/routes';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import { NextButton } from './styles';
import useLoginEmailVM, { VALIDATION_TYPES } from './vm';

const LoginEmail = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.VALIDATION]);

  const { formMethods, onSubmit, handleEmailBlur } = useLoginEmailVM();
  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting, errors } = formState;

  return (
    <Layout
      title={t('log_in', { ns: i18nNS.AUTH })}
      subtitle={t('please_enter_your_registered_email_address', { ns: i18nNS.AUTH })}
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div>
          <TextField
            control={control}
            name="emailId"
            type="email"
            label={t('email', { ns: i18nNS.GLOSSARY })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('enter_your_email_here', { ns: i18nNS.AUTH })}
            autoComplete="email"
            autoCapitalize="none"
            autoCorrect="off"
            fullWidth
            onBlur={handleEmailBlur}
            autoFocus
          />
          <input hidden type="password" autoComplete="current-password" />
          {errors['emailId']?.type === VALIDATION_TYPES.EMAIL_NOT_FOUND && (
            <FormHelperText
              error
              aria-label={t('this_email_address_cannot_be_found_signup_instead', { ns: i18nNS.AUTH })}
            >
              <Trans ns={i18nNS.VALIDATION} i18nKey="this_email_address_cannot_be_found_sign_up_instead">
                This email address cannot be found.{' '}
                <Link
                  component={RouterLink}
                  to={routes.signup.url()}
                  aria-label={t('click_to_signup_instead', { ns: i18nNS.AUTH })}
                >
                  Sign up instead?
                </Link>
              </Trans>
            </FormHelperText>
          )}
        </div>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={5}
          component="footer"
        >
          <Link
            variant="paragraphRegular"
            component={RouterLink}
            to={routes.signup.path}
            aria-label={t('click_to_create_a_new_account', { ns: i18nNS.AUTH })}
          >
            {t('create_a_new_account', { ns: i18nNS.AUTH })}
          </Link>
          <NextButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            fullWidth={isMobile}
            isLoading={isSubmitting}
            aria-label={t('click_to_login', { ns: i18nNS.AUTH })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </NextButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default LoginEmail;
