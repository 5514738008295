import { Course } from '../../db/courses/types';
import { API } from '../../db/shared/api-responses';
import { CourseType, EnrollmentBy, TipStatusKey } from '../../db/shared/types';
import i18n, { i18nNS } from '../../i18n';

interface AttendanceStatsContainer {
  attendance: API.CourseAverages['attendance'];
}

export function getAverageAttendance<T extends AttendanceStatsContainer>({ attendance }: T) {
  const totalClasses = attendance.recordedClasses;
  return totalClasses <= 0 ? 0 : attendance.presents / totalClasses;
}

interface ScoresStatsContainer {
  scores: API.CourseAverages['scores'];
}

export function getAverageTotalScore<T extends ScoresStatsContainer>({ scores }: T) {
  const maxScore = scores.overall.max;
  return maxScore <= 0 ? 0 : (scores.overall.attained * 100) / maxScore;
}

export function getAverageAssignmentScore<T extends ScoresStatsContainer>({ scores }: T) {
  const maxScore = scores.assignments.max;
  return maxScore <= 0 ? 0 : (scores.assignments.attained * 100) / maxScore;
}

export function getAverageQuizScore<T extends ScoresStatsContainer>({ scores }: T) {
  const maxScore = scores.quizzes.max;
  return maxScore <= 0 ? 0 : (scores.quizzes.attained * 100) / maxScore;
}

export function getCourseAnalyticsPageTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'courseAnalyticsExport':
      return i18n.t(
        'to_export_the_consolidated_scores_of_various_activities_that_were_conducted_throughout_the_course_in_a_csv_format_use_this_button',
        { ns: i18nNS.COURSES }
      );
    case 'courseAnalyticsAddStudents':
      return i18n.t(
        'by_using_this_button_you_can_add_students_to_your_course_with_their_email_addresses_students_will_be_notified_via_email_to_join_this_course_on_acadly',
        { ns: i18nNS.COURSES }
      );
    default:
      return '';
  }
}

export function getActivityAnalyticsPagesTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'activityAnalyticsExport':
      return i18n.t(
        'you_can_export_the_students_scores_and_participation_in_this_activity_in_a_csv_format_by_using_this_button',
        { ns: i18nNS.COURSES }
      );
    default:
      return '';
  }
}

export function getCourseEnrollmentMethod(
  { type, enrollment }: Pick<Course, 'type' | 'enrollment'> | undefined = {
    type: CourseType.SYSTEM,
    enrollment: { by: EnrollmentBy.INVITATION },
  }
) {
  if (type === CourseType.SYNCED) {
    return i18n.t('synced', { ns: i18nNS.GLOSSARY });
  }

  if (enrollment.by === EnrollmentBy.CODE) {
    return i18n.t('by_join_code', { ns: i18nNS.COURSES });
  }

  return i18n.t('by_email_invitation', { ns: i18nNS.COURSES });
}
