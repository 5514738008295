import { LogglyTracker } from 'loggly-jslogger';

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

const tracker = new LogglyTracker();

tracker.push({
  logglyKey: process.env.REACT_APP_LOGGLY_KEY,
  sendConsoleErrors: true,
  tag: 'acadly-web',
});

function logFn(level: LogLevel, namespace: string) {
  if (process.env.REACT_APP_ENV === 'production') {
    return (...args: any[]) => {
      tracker.push({
        args,
        level,
        tag: 'acadly-web',
        version: process.env.REACT_APP_VERSION,
      });
    };
  } else if (process.env.NODE_ENV !== 'test') {
    return console[level].bind(window, `[${namespace}]`);
  }
  return () => {};
}

const Logger = {
  debug: logFn('debug', 'acadly'),
  info: logFn('info', 'acadly'),
  warn: logFn('warn', 'acadly'),
  error: logFn('error', 'acadly'),
  create(scope: string) {
    return {
      debug: logFn('debug', scope),
      info: logFn('info', scope),
      warn: logFn('warn', scope),
      error: logFn('error', scope),
    };
  },
};

export default Logger;
