import { styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import AcadlyButton from '../../Button';
import { buttonClasses, ButtonProps } from '../../Button/styles';

export const toggleButtonClasses = generateClasses('ToggleButton', ['root', 'selected']);

const Button = styled(AcadlyButton)(({ theme }) => ({
  [`&.${buttonClasses.root}`]: {
    color: theme.palette.primary[600],
    border: '1px solid currentColor',
    padding: theme.spacing(2, 3),
    minHeight: 'unset',
    [`&.${toggleButtonClasses.selected}`]: {
      backgroundColor: theme.palette.primary[200],
    },
  },
}));

export interface Props extends ButtonProps<'button'> {
  value: string;
  selected?: boolean;
}

const ToggleButton = ({ className, value, selected, ...props }: Props) => {
  return (
    <Button
      className={clsx(toggleButtonClasses.root, className, { [toggleButtonClasses.selected]: selected })}
      variant="outlined"
      {...props}
    />
  );
};

export default ToggleButton;
