import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';

import { Collapse, Stack, styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import { CourseRole } from '../../../types';
import { generateClasses } from '../../../utils/helpers';
import CreateCourseForm from '../CreateCourseForm';
import CreateCoursePreview from '../Preview';
import AddInstructor from './AddInstructor';
import AddTeachingAssistant from './AddTeachingAssistant';
import CourseTeamUser from './CourseTeamUser';
import useTeamMembersVM from './vm';

const teamMembersClasses = generateClasses('TeamMembers', ['title', 'description']);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),

  [`.${teamMembersClasses.title}`]: {
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(2),
  },

  [`.${teamMembersClasses.description}`]: {
    color: theme.palette.grey[500],
  },
}));

const TeamMembers = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE]);
  const { assistants, closeDrawer, instructors, isVisible, moveToNextStep, moveToPreviousStep } =
    useTeamMembersVM();

  return (
    <CreateCourseForm
      as="div"
      isVisible={isVisible}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button variant="text" startIcon={<ChevronLeftIcon />} onClick={moveToPreviousStep}>
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button variant="text" onClick={closeDrawer}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button variant="contained" endIcon={<ChevronRightIcon />} onClick={moveToNextStep}>
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Root>
        <div>
          <Typography className={teamMembersClasses.title} variant="h5Bold">
            {t('step_3_colon_add_course_team_members', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          <Typography className={teamMembersClasses.description}>
            {t('this_is_an_optional_step_you_can_add_the_team_members_later_as_well', {
              ns: i18nNS.CREATE_COURSE,
            })}
          </Typography>
        </div>
        <AddInstructor />
        <TransitionGroup>
          {instructors.map((instructor) => (
            <Collapse key={instructor.userId}>
              <CourseTeamUser {...instructor} role={instructor.role as CourseRole.INSTRUCTOR} />
            </Collapse>
          ))}
        </TransitionGroup>
        <AddTeachingAssistant />
        <TransitionGroup>
          {assistants.map((assistant) => (
            <Collapse key={assistant.userId}>
              <CourseTeamUser {...assistant} role={assistant.role as CourseRole.TA} />
            </Collapse>
          ))}
        </TransitionGroup>
        <CreateCoursePreview showTimezone showWeeklySchedule />
      </Root>
    </CreateCourseForm>
  );
};

export default TeamMembers;
