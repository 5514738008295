import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import useNotificationPermissionDialogVM from './vm';

const NotificationPermissionDialog = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.GLOSSARY]);

  const {
    container,
    handleCloseNotificationPermissionDialog,
    isNotificationPermissionDialogOpen,
    handleSubmitPermission,
  } = useNotificationPermissionDialogVM();

  return (
    <Alert open={isNotificationPermissionDialogOpen} container={container}>
      <Alert.Header>{t('allow_desktop_notifications', { ns: i18nNS.ONLINE_MEETING })}</Alert.Header>
      <Alert.Body>
        <Typography variant="paragraphRegular">
          {t('acadly_will_send_desktop_notifications_only_while_an_online_meeting_is_in_progress', {
            ns: i18nNS.ONLINE_MEETING,
          })}
        </Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action variant="text" color="warning" onClick={handleCloseNotificationPermissionDialog}>
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action onClick={handleSubmitPermission}>{t('okay', { ns: i18nNS.GLOSSARY })}</Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default NotificationPermissionDialog;
