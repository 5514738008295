import { forwardRef, Ref } from 'react';

import {
  Divider,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
  Stack,
  StackProps,
  styled,
} from '@mui/material';

import AcadlyUserCell from '../components/UserCell';
import { forMobile } from '../utils/media-queries';

export const Root = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => <Stack ref={ref} gap={5} {...props} />)
)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [forMobile(theme)]: {
    padding: theme.spacing(4),
  },
}));

export const UserCell = styled(AcadlyUserCell)(({ theme }) => ({
  padding: theme.spacing(5),
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
}));

export const List = styled(<D extends React.ElementType>(props: StackProps<D>) => (
  <Stack divider={<Divider flexItem />} {...props} component="ul" />
))(({ theme }) => ({
  margin: 0,
  padding: 0,
  backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  overflow: 'hidden',
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

export const ListItemButton = styled(MuiListItemButton)<
  MuiListItemButtonProps & { color?: 'primary' | 'success' | 'error' | 'warning' }
>(({ theme, color = 'primary' }) => {
  return {
    padding: theme.spacing(3, 5),
    color: color ? theme.palette[color][600] : undefined,
  };
}) as typeof MuiListItemButton;

export const ListItemIcon = styled(MuiListItemIcon)<
  MuiListItemIconProps & { color?: 'primary' | 'success' | 'error' | 'warning' }
>(({ theme, color = 'primary' }) => ({
  minWidth: 'unset',
  color: color ? theme.palette[color][color === 'primary' ? 700 : 500] : undefined,
}));

export const ListItemText = styled((props: MuiListItemTextProps) => (
  <MuiListItemText disableTypography {...props} />
))(({ theme }) => ({}));
