import { useTranslation } from 'react-i18next';

import { CommentContextData } from '../../../db/comments/types';
import { i18nNS } from '../../../i18n';
import { Tab, Tabs } from './styles';
import useCommentsTabsVM from './vm';

const CommentsTabs = () => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const { tabs, activeTab, handleTabChange: _handleTabChange } = useCommentsTabsVM();

  const handleTabChange = (newContext: CommentContextData) => () => {
    _handleTabChange(newContext);
  };

  if (!tabs) return null;

  return (
    <Tabs
      value={activeTab?.context.subType || ''}
      aria-label={t('discussion_types', { ns: i18nNS.COMMENTS })}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.context.subType}
          label={tab.title}
          value={tab.context.subType}
          aria-label={t('click_to_switch_to_title', { ns: i18nNS.COMMENTS, context: tab.title })}
          onClick={handleTabChange(tab.context)}
          disabled={tab.isDisabled}
        />
      ))}
    </Tabs>
  );
};

export default CommentsTabs;
