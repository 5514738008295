import {
  NotificationData,
  NumericBoolean,
  ZoomAppContext,
  ZoomAppMode,
  ZoomAppRole,
  ZoomId,
  ZoomParticipant,
} from './common';

export enum UpstreamZoomAppEvent {
  READY = '@zoom-app/ready',
  JOINED_MEETING = '@zoom-app/joined_meeting',
  LEFT_MEETING = '@zoom-app/left_meeting',
  NOTIFICATION_CLICK = '@zoom-app/notification_click',
  UNHANDLED_ERROR = '@zoom-app/unhandled_error',
}

export enum DownstreamZoomAppEvent {
  ENABLE_CAMERA_MIC = '@zoom-app/enable_camera_mic',
  DISABLE_CAMERA_MIC = '@zoom-app/disable_camera_mic',
  PARTICIPANT_JOINED = '@zoom-app/participant_joined',
  PARTICIPANT_LEFT = '@zoom-app/participant_left',
  SHOW_NOTIFICATION = '@zoom-app/show_notification',
  JOIN_MEETING = '@zoom-app/join_meeting',
  LEAVE_MEETING = '@zoom-app/leave_meeting',
  MODE_CHANGE_EVENT = '@zoom-app/mode_change_event',
}

interface ReadyEvent {
  type: UpstreamZoomAppEvent.READY;
}

interface JoinedMeetingEvent extends ZoomParticipant {
  type: UpstreamZoomAppEvent.JOINED_MEETING;
}

interface LeftMeetingEvent extends ZoomParticipant {
  type: UpstreamZoomAppEvent.LEFT_MEETING;
}

interface NotificationClickEvent {
  type: UpstreamZoomAppEvent.NOTIFICATION_CLICK;
  notificationId: string;
}

interface UnhandledErrorEvent {
  type: UpstreamZoomAppEvent.UNHANDLED_ERROR;
  agent: string;
  exception: {
    message: string | Event;
    url?: string;
    lineno?: number;
    colno?: number;
    stack: string;
  };
}

interface ParticipantJoinedEvent {
  type: DownstreamZoomAppEvent.PARTICIPANT_JOINED;
  mustExpel: NumericBoolean;
  zoomUserId: ZoomId;
  name: string;
  joinId: string;
  toLeave: ZoomId[];
}

interface ParticipantLeftEvent {
  type: DownstreamZoomAppEvent.PARTICIPANT_LEFT;
  zoomUserId: ZoomId;
  name: string;
}

interface ModeChangedEvent<Mode extends ZoomAppMode> {
  type: DownstreamZoomAppEvent.MODE_CHANGE_EVENT;
  mode: Mode;
}

interface JoinMeetingEvent<Role extends ZoomAppRole> extends ZoomAppContext<Role> {
  type: DownstreamZoomAppEvent.JOIN_MEETING;
}

interface LeaveMeetingEvent {
  type: DownstreamZoomAppEvent.LEAVE_MEETING;
}

interface EnableCameraMicEvent {
  type: DownstreamZoomAppEvent.ENABLE_CAMERA_MIC;
}

interface DisableCameraMicEvent {
  type: DownstreamZoomAppEvent.DISABLE_CAMERA_MIC;
}

interface ShowNotificationEvent {
  type: DownstreamZoomAppEvent.SHOW_NOTIFICATION;
  payload: NotificationData;
}

type AllEvents<Role extends ZoomAppRole = ZoomAppRole, Mode extends ZoomAppMode = ZoomAppMode> =
  | ReadyEvent
  | JoinedMeetingEvent
  | LeftMeetingEvent
  | NotificationClickEvent
  | UnhandledErrorEvent
  | ModeChangedEvent<Mode>
  | JoinMeetingEvent<Role>
  | LeaveMeetingEvent
  | ParticipantJoinedEvent
  | ParticipantLeftEvent
  | EnableCameraMicEvent
  | DisableCameraMicEvent
  | ShowNotificationEvent;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ZoomAppEvent {
  /**
   * Extracts event payload by zoom event type
   */
  export type Payload<T extends AllEvents['type']> = Omit<Extract<AllEvents, { type: T }>, 'type'>;

  /**
   * Events that are sent from iframe to parent window
   */
  export type Upstream<Event extends UpstreamZoomAppEvent = UpstreamZoomAppEvent> = Extract<AllEvents, { type: Event }>;

  /**
   * Events that are sent from parent window to iframe
   */
  export type Downstream<
    Event extends DownstreamZoomAppEvent = DownstreamZoomAppEvent,
    Role extends ZoomAppRole = ZoomAppRole,
    Mode extends ZoomAppMode = ZoomAppMode
  > = Extract<AllEvents<Role, Mode>, { type: Event }>;
}
