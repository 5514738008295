import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectAuthSession } from '../store/selectors';

const useLayoutVM = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(
    function scrollToTop() {
      ref.current?.scrollTo(0, 0);
    },
    [pathname]
  );

  const session = useSelector(selectAuthSession);

  return { ref, session };
};

export default useLayoutVM;
