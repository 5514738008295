import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../components/Button';
import { generateClasses } from '../../utils/helpers';

export const signupRoleClasses = {
  ...generateClasses('SignupRole', ['button', 'startIcon', 'endIcon']),
};

export const StyledButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
      variant="outlined"
      size="xLarge"
      {...props}
      className={clsx(signupRoleClasses.button, props.className)}
      ref={ref}
    />
  ))
)(({ theme }) => ({
  [`&.${signupRoleClasses.button}`]: {
    color: theme.palette.grey[900],
    backgroundColor: theme.palette.primary[100],
    borderColor: theme.palette.primary[200],
    padding: theme.spacing(2),
    [`& .${signupRoleClasses.startIcon}`]: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary[900],
      padding: theme.spacing(2),
      borderRadius: 6,
    },
    [`& .${signupRoleClasses.endIcon}`]: {
      marginLeft: 'auto',
    },
  },
})) as typeof Button;
