import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';

import { useMediaQuery } from '@mui/material';

import { forMobile } from '../../utils/media-queries';
import CloseButton from './CloseButton';
import { Props, StyledContainer, toastClasses } from './styles';

export { notify } from './notify';

const NotificationProvider = ({ ...props }: Props) => {
  const isMobile = useMediaQuery(forMobile);
  return (
    <StyledContainer>
      <ToastContainer
        {...props}
        closeButton={CloseButton}
        closeOnClick={false}
        autoClose={15 * 1000} // 15 seconds
        draggable={false}
        hideProgressBar
        position={isMobile ? toast.POSITION.BOTTOM_CENTER : toast.POSITION.BOTTOM_LEFT}
        className={toastClasses.root}
        toastClassName={toastClasses.toast}
        bodyClassName={toastClasses.body}
        progressClassName={toastClasses.progress}
      />
    </StyledContainer>
  );
};

export default NotificationProvider;
