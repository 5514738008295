import Joi from 'joi';

import { optionalString, requiredInteger, requiredString } from '../../../utils/validators';
import { FormValues } from './types';

const validationSchema = Joi.object<FormValues, true>({
  courseCount: requiredString,
  studentCount: requiredString,
  courseStartDate: requiredInteger,
  phone: optionalString,
});

export default validationSchema;
