import { ComponentProps, ComponentType, FC, lazy, ReactNode, Suspense } from 'react';

const LazyModule = <T extends ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
  fallback: ReactNode = null
) => {
  const LazyComponent = lazy(importFunc);

  const Component: FC<ComponentProps<typeof LazyComponent>> = (
    props: ComponentProps<typeof LazyComponent>
  ) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );

  Component.displayName = 'LazyModule';
  return Component;
};

export default LazyModule;
