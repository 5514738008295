import { FieldValues, Path, useFormContext } from 'react-hook-form';

export interface Props<T extends FieldValues> {
  controlName: Path<T>;
}

const useWeekdaysVM = <T extends FieldValues>({ controlName }: Props<T>) => {
  const { control, formState } = useFormContext();

  const { errors } = formState;
  const error = errors[controlName];

  return { control, controlName, error };
};

export default useWeekdaysVM;
