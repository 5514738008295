import { Skeleton } from '@mui/material';

import { Content, Root, Subtitle, Title } from './styles';

interface UserCellSkeletonProps {
  className?: string;
}

const UserCellSkeleton = ({ className }: UserCellSkeletonProps) => {
  return (
    <Root className={className}>
      <Skeleton variant="rounded" height={40} width={40} />
      <Content>
        <Title>
          <Skeleton variant="text" width="25%" />
        </Title>
        <Subtitle>
          <Skeleton variant="text" width="60%" />
        </Subtitle>
      </Content>
    </Root>
  );
};

export default UserCellSkeleton;
