import { Trans, useTranslation } from 'react-i18next';

import { Link, List as MuiList, ListItem, ListItemText, Stack, styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import CreateCourseForm from '../CreateCourseForm';
import useIntroductionVM from './vm';

const List = styled(MuiList)(() => ({
  gap: 0,
}));

const Introduction = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMON, i18nNS.CREATE_COURSE]);

  const { isVisible, closeDrawer, moveToNextStep } = useIntroductionVM();

  return (
    <CreateCourseForm
      as="div"
      isVisible={isVisible}
      footer={() => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3} p={5}>
          <Link href="https://www.acadly.com/acadly-pro" target="_blank">
            {t('learn_more', { ns: i18nNS.COMMON })}
          </Link>
          <Stack direction="row" gap={3}>
            <Button
              type="button"
              variant="text"
              onClick={closeDrawer}
              aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ChevronRightIcon />}
              onClick={moveToNextStep}
              aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={3}>
        <Typography variant="h5Regular">
          {t('you_can_create_two_kinds_of_courses_in_acadly', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Stack gap={1}>
          <Typography variant="h5Bold">{t('acadly_pro_course', { ns: i18nNS.CREATE_COURSE })}</Typography>
          <List dense>
            <ListItem>
              <ListItemText>
                <Trans
                  t={t}
                  ns={i18nNS.CREATE_COURSE}
                  i18nKey="get_access_to_all_acadly_features_except_lms_integration_only_available_for_acadly_enterprise_customers"
                >
                  Get access to all Acadly features except LMS integration (only available for{' '}
                  <Link href="https://www.acadly.com/use-case-lms-integration" target="_blank">
                    Acadly Enterprise
                  </Link>{' '}
                  customers)
                </Trans>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t(
                  'download_excel_reports_with_students_email_addresses_for_easy_data_imports_to_the_lms_gradebook',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t('schedule_auto_attendance_for_click_free_attendance_tracking', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t('anonymise_students_discussion_responses_to_encourage_participation_in_discussions', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t('create_quizzes_with_true_false_multiple_choice_and_sorting_ordering_questions', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Trans
                  t={t}
                  ns={i18nNS.CREATE_COURSE}
                  i18nKey="get_zoom_integration_to_teach_online_and_hybrid_classes_learn_more_about_our_zoom_enabled_features_here"
                >
                  Get Zoom integration to teach online and hybrid classes. Learn more about our Zoom-enabled
                  features{' '}
                  <Link href="https://www.acadly.com/use-case-online-learning" target="_blank">
                    here.
                  </Link>
                </Trans>
              </ListItemText>
            </ListItem>
          </List>
        </Stack>
        <Stack gap={1}>
          <Typography variant="h5Bold">{t('acadly_basic_course', { ns: i18nNS.CREATE_COURSE })}</Typography>
          <List dense>
            <ListItem>
              <ListItemText>
                {t('all_acadly_basic_features_are_available_in_pro_courses_as_well', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t('record_in_person_attendance_automatically_at_the_tap_of_a_button', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t(
                  'share_in_class_activities_like_polls_quizzes_discussions_and_word_clouds_to_engage_students',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t('share_pre_class_activities_to_keep_students_engaged_round_the_clock', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t(
                  'download_excel_reports_of_participation_and_attendance_reports_do_not_include_students_email_addresses',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t(
                  'share_quizzes_with_true_false_and_multiple_choice_questions_sorting_ordering_questions_are_not_included',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </ListItemText>
            </ListItem>
          </List>
        </Stack>
      </Stack>
    </CreateCourseForm>
  );
};

export default Introduction;
