import { AriaAttributes, ReactNode } from 'react';

import FabMenuContext from './Context';
import FabMenuWrapper from './FabMenuWrapper';
import useFabMenuVM, { VMProps } from './vm';

interface Props extends VMProps, AriaAttributes {
  className?: string;
  children?: ReactNode;
  icon?: ReactNode;
}

/**
 * See following example for its usage:
 * ```tsx
 * <FabMenu rootMenu="add_activity_menu">
 *   <ChildFabMenu menuId="add_activity_menu">
 *     <FabMenuItem targetMenu="pre_class_menu">Pre class</FabMenuItem>
 *     <FabMenuItem targetMenu="in_class_menu">In class</FabMenuItem>
 *   </ChildFabMenu>
 *   <ChildFabMenu menuId="pre_class_menu">
 *     <FabMenuItem disabled>Pre class</FabMenuItem>
 *     <FabMenuItem onClick={...}>Quiz</FabMenuItem>
 *     <FabMenuItem onClick={...}>Poll</FabMenuItem>
 *     <FabMenuItem onClick={...}>Discussion</FabMenuItem>
 *     <FabMenuItem onClick={...}>Query</FabMenuItem>
 *     <FabMenuItem targetMenu="add_activity_menu">Back</FabMenuItem>
 *   </ChildFabMenu>
 *   <ChildFabMenu menuId="in_class_menu">
 *     <FabMenuItem disabled>In class</FabMenuItem>
 *     <FabMenuItem onClick={...}>Quiz</FabMenuItem>
 *     <FabMenuItem onClick={...}>Poll</FabMenuItem>
 *     <FabMenuItem onClick={...}>Discussion</FabMenuItem>
 *     <FabMenuItem onClick={...}>Query</FabMenuItem>
 *     <FabMenuItem targetMenu="add_activity_menu">Back</FabMenuItem>
 *   </ChildFabMenu>
 * </FabMenu>
 * ```
 */
export const FabMenu = ({ className, children, icon, rootMenu, ...props }: Props) => {
  const { contextValue } = useFabMenuVM({ rootMenu });
  return (
    <FabMenuContext.Provider value={contextValue}>
      <FabMenuWrapper className={className} icon={icon} {...props}>
        {children}
      </FabMenuWrapper>
    </FabMenuContext.Provider>
  );
};

export { default as ChildFabMenu } from './ChildFabMenu';
export { default as FabMenuItem, fabMenuItemClasses } from './FabMenuItem';
