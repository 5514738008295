import { Trans, useTranslation } from 'react-i18next';

import { FormControl, Link, Stack, useMediaQuery } from '@mui/material';

import Alert from '../../components/Alert';
import PasswordTextField from '../../components/HookFormControls/PasswordTextField';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import { MIN_PASSWORD_LENGTH } from '../constants';
import Layout from '../Layout';
import { NextAlertButton, NextButton, StyledCheckbox } from './styles';
import useSetPasswordVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const SetPassword = ({ ...vmOptions }: Props) => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { isResettingPassword } = vmOptions;
  const { formMethods, onSubmit, session, areTermsAccepted, handleChangeTermsAccepted } =
    useSetPasswordVM(vmOptions);

  const { handleSubmit, control, formState, trigger } = formMethods;
  const { isSubmitting, isValid } = formState;

  return (
    <Layout
      title={t(isResettingPassword ? 'resetting_password' : 'set_password', { ns: i18nNS.AUTH })}
      subtitle={t('your_password_should_be_at_least_N_characters_long', {
        ns: i18nNS.AUTH,
        min: MIN_PASSWORD_LENGTH,
      })}
      canSeeUserCell
    >
      <Stack gap={6} component="form" onSubmit={(event) => event.preventDefault()} noValidate>
        <input readOnly type="email" autoComplete="username" value={session.email} hidden />
        <PasswordTextField
          control={control}
          name="password"
          label={t('password', { ns: i18nNS.GLOSSARY })}
          required
          size={isMobile ? 'medium' : 'xLarge'}
          autoComplete="new-password"
          fullWidth
          autoFocus
        />
        <Stack direction="row" justifyContent="flex-end" component="footer">
          {isResettingPassword ? (
            <NextButton
              size={isMobile ? 'large' : 'xLarge'}
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              aria-label={t('click_to_set_password', { ns: i18nNS.AUTH })}
              fullWidth={isMobile}
            >
              {t('next', { ns: i18nNS.GLOSSARY })}
            </NextButton>
          ) : (
            <NextAlertButton
              type="submit"
              size={isMobile ? 'large' : 'xLarge'}
              onClick={() => trigger()}
              canOpenAlert={isValid}
              aria-label={t('click_to_set_password', { ns: i18nNS.AUTH })}
              fullWidth={isMobile}
            >
              {t('next', { ns: i18nNS.GLOSSARY })}
              <Alert maxWidth="xs">
                <Alert.Body>
                  <FormControl>
                    <StyledCheckbox
                      name="termsAccepted"
                      checked={areTermsAccepted}
                      onChange={handleChangeTermsAccepted}
                      label={
                        <Trans t={t} i18nKey="i_have_read_the_privacy_policy_and_agree_to_the_terms_of_use">
                          I have read the{' '}
                          <Link
                            component="a"
                            href="https://www.acadly.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={t('click_to_view_acadly_privacy_policy', { ns: i18nNS.AUTH })}
                          >
                            Privacy Policy
                          </Link>{' '}
                          and agree to the{' '}
                          <Link
                            component="a"
                            href="https://www.acadly.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={t('click_to_view_acadly_terms_of_use', { ns: i18nNS.AUTH })}
                          >
                            Terms of Use
                          </Link>
                        </Trans>
                      }
                      aria-label={t(
                        areTermsAccepted
                          ? 'click_to_reject_you_have_read_privacy_policy_and_agree_to_the_terms_of_use'
                          : 'click_to_confirm_you_have_read_privacy_policy_and_agree_to_the_terms_of_use',
                        { ns: i18nNS.AUTH }
                      )}
                      autoFocus
                    />
                  </FormControl>
                </Alert.Body>
                <Alert.Footer>
                  <Alert.Action variant="text" type="button">
                    {t('cancel', { ns: i18nNS.GLOSSARY })}
                  </Alert.Action>
                  <Alert.Action
                    variant="text"
                    type="button"
                    disabled={!areTermsAccepted}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('next', { ns: i18nNS.GLOSSARY })}
                  </Alert.Action>
                </Alert.Footer>
              </Alert>
            </NextAlertButton>
          )}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SetPassword;
