import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../i18n';
import { Dialog } from './styles';
import useZoomIntegrationDialogVM, { Props as VMProps } from './vm';
import ZoomIntegrationDialogContent from './ZoomIntegrationDialogContent';

interface Props extends VMProps {}

const ZoomIntegrationDialog = ({ open, onClose: handleClose }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { isAuthorizing, zoomAuthorizationData } = useZoomIntegrationDialogVM({ open, onClose: handleClose });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      header={t('zoom_integration', { ns: i18nNS.COMMON })}
      maxWidth="sm"
      fullWidth
    >
      <ZoomIntegrationDialogContent
        isAuthorizing={isAuthorizing}
        zoomAuthorizationData={zoomAuthorizationData}
      />
    </Dialog>
  );
};

export default ZoomIntegrationDialog;
