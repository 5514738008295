import { Stack, StackProps, styled } from '@mui/material';

import SearchIcon from '../../icons/SearchIcon';
import { generateClasses } from '../../utils/helpers';
import List from '../List';

export const userListClasses = generateClasses('UserList', ['actions', 'searchField', 'sortButton', 'users']);

export const Root = styled((props: StackProps) => <Stack gap={2} {...props} />)(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const Actions = styled((props: StackProps) => (
  <Stack direction="row" alignItems="center" gap={2} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(0, 5),
}));

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

export const UserCells = styled(List)(({ theme }) => ({
  outlineOffset: theme.spacing(-0.25),

  '& li > *': {
    width: '100%',
  },
}));
