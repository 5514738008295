const en = {
  /** login - sso */
  authentication_process_active_in_another_window: 'Authentication process active in another window...',
  sign_in_process_could_not_be_completed_please_try_again:
    'Sign in process could not be completed, please try again',

  /** login - email page */
  log_in: 'Log in',
  please_enter_your_registered_email_address: 'Please enter your registered email address',
  email_address: 'Email address',
  enter_your_email_here: 'Enter your email here',
  create_a_new_account: 'Create a new account?',
  click_to_create_a_new_account: 'click to create a new account',
  this_email_address_cannot_be_found_signup_instead: 'This email address cannot be found. Signup instead?',
  click_to_signup_instead: 'click to signup instead',
  back_to_login: 'Back to login',

  /** login - password page */
  welcome_back: 'Welcome back!',
  enter_your_password_here: 'Enter your password here',
  forgot_password: 'Forgot password?',
  click_to_go_to_forgot_password_page: 'click to go to forgot password page',
  click_to_login: 'click to login',

  /** email verification page */
  email_verification: 'Email verification',
  resetting_password: 'Resetting password',
  please_enter_the_verification_code_emailed_to_you_at_email:
    'Please enter the verification code emailed to you at <2>{{email}}</2>',
  verification_code: 'Verification code',
  enter_verification_code: 'Enter verification code',
  resend_verification_code: 'Resend verification code',
  resending_dot_dot_dot: 'Resending...',
  sent_retry_after_N_seconds_one: 'Sent. Retry after {{count}} second',
  sent_retry_after_N_seconds_other: 'Sent. Retry after {{count}} seconds',
  click_to_verify_code: 'click to verify code',
  click_to_resend_verification_code: 'click to resend verification code',
  reset_password: 'Reset password',

  /** set password page */
  set_password: 'Set password',
  your_password_should_be_at_least_N_characters_long:
    'Your password should be at least {{min}} characters long',
  i_have_read_the_privacy_policy_and_agree_to_the_terms_of_use:
    'I have read the  <2>Privacy Policy</2> and agree to the  <6>Terms of Use</6>',
  click_to_set_password: 'click to set password',
  click_to_reject_you_have_read_privacy_policy_and_agree_to_the_terms_of_use:
    'click to reject you have read privacy policy and agree to the terms of use',
  click_to_confirm_you_have_read_privacy_policy_and_agree_to_the_terms_of_use:
    'click to confirm you have read privacy policy and agree to the terms of use',
  click_to_view_acadly_privacy_policy: 'click to view Acadly privacy policy',
  click_to_view_acadly_terms_of_use: 'click to view Acadly terms of use',

  /** set name page */
  your_official_full_name_please: 'Your official full name please',
  please_enter_your_official_full_name_as_it_appears_in_the_course_roster_watch_out_for_typos_and_auto_correct:
    'Please enter your official full name as it appears in the course roster. Watch out for typos and auto-correct.',
  first_name: 'First name',
  type_your_first_name_here: 'Type your first name here',
  middle_name: 'Middle name',
  type_your_middle_name_here: 'Type your middle name here',
  type_your_last_name_here: 'Type your last name here',
  last_name: 'Last name',
  click_to_save_name: 'click to save name',

  /** set profile avatar */
  choose_your_avatar: 'Choose your avatar',
  click_to_save_avatar: 'click to save avatar',

  /** choose avatar */
  your_avatar: 'Your avatar',
  your_current_avatar: 'Your current avatar',
  choose_from_system: 'Choose from system',
  choose_from_default_avatars: 'Choose from default avatars',
  select_skin_tone: 'Select skin tone',
  select_avatar: 'Select avatar',
  crop_avatar: 'Crop avatar',
  custom_image: 'Custom image',
  click_to_choose_file_from_system: 'click to choose file from system',

  /** Crop avatar */
  no_file_selected: 'No file selected',
  choose_a_file: 'Choose a file',
  click_to_choose_a_file: 'click to choose a file',
  click_to_cancel_cropping: 'click to cancel cropping',
  click_to_upload_cropped_image: 'click to upload cropped image',

  /** signup - choose role */
  sign_up_for_acadly: 'Sign up for Acadly',
  what_type_of_an_account_do_you_need: 'What type of an account do you need?',
  already_have_an_account_login: 'Already have an account? Login',
  a_student_account: 'A student account',
  an_instructor_account: 'An instructor account',
  click_to_signup_as_a_student: 'click to signup as a student',
  click_to_signup_as_an_instructor: 'click to signup as an instructor',

  /** signup - email */
  creating_a_student_account: 'Creating a student account',
  signing_up: 'Signing up',
  please_enter_your_email_address: 'Please enter your email address',
  please_enter_your_official_email_address_to_signup_as_an_instructor:
    'Please enter your official email address to signup as an instructor',
  i_agree_to_receive_product_news_and_updates_via_email:
    'I agree to receive product news and updates via email',
  which_email_address_should_i_use: 'Which email address should I use?',
  if_you_ve_been_invited_to_join_a_course_please_use_the_email_address_on_which_you_received_the_invitation:
    "If you've been invited to join a course, please use the email address on which you received the invitation.",
  if_you_were_not_invited_via_email_you_should_preferably_use_the_email_address_provided_by_the_institute:
    'If you were not invited via email, you should preferably use the email address provided by the institute.',
  if_you_ve_been_invited_by_another_instructor_to_join_a_course_please_use_the_email_address_on_which_you_received_the_invitation:
    "If you've been invited by another instructor to join a course, please use the email address on which you received the invitation.",
  to_request_a_new_account_please_use_the_email_address_provided_by_your_institute_for_quick_verification:
    'To request a new account, please use the email address provided by your institute for quick verification.',
  click_to_know_which_email_address_should_you_use: 'click to know which email address should you use',
  click_to_sign_up: 'click to sign up',
  this_email_address_already_exists_login_instead: 'This email address already exists. Login instead?',
  click_to_login_instead: 'click to login instead',
  click_to_agree_to_receive_product_news_and_updates_via_email:
    'click to agree to receive product news and updates via email',
  click_to_disagree_to_receive_product_news_and_updates_via_email:
    'click to disagree to receive product news and updates via email',

  /** signup - teacher - creating trial account */
  creating_trial_account: 'Creating trial account',
  please_provide_the_following_details_to_help_us_verify_your_identity_as_an_instructor_at_university_name:
    'Please provide the following details to help us verify your identity as an instructor at {{universityName}}.',
  we_ll_verify_and_change_your_account_status_in_the_next_24_hours:
    "We'll verify and change your account status in the next 24 hours",
  full_name: 'Full name',
  enter_your_full_name: 'Enter your full name',
  website_optional: 'Website (optional)',
  your_official_webpage: 'Your official webpage',
  select_your_role: 'Select your role',
  graduate_teaching_assistant: 'Graduate/Teaching Assistant',
  administrative_staff: 'Administrative Staff',
  click_to_create_your_trial_account: 'click to create your trial account',

  /** signup - teacher - instructor verification */
  instructor_verification_required: 'Instructor verification required',
  instructors_can_use_acadly_after_being_verified_please_provide_us_with_the_following_details_to_activate_your_account_within_the_next_24_hours:
    'Instructors can use Acadly after being verified. Please provide us with the following details to activate your account within the next 24 hours',
  select_your_organization: 'Select your organization',
  you_want_to_use_acadly_for: 'You want to use Acadly for',
  select_your_use_case: 'Select your use case',
  university_college: 'University/College',
  k_12_school: 'K-12 School',
  vocational_training_school: 'Vocational Training School',
  company_or_govt_organization: 'Company or Govt Organization',
  non_profit: 'Non profit',
  independent_instructor_presenter: 'Independent Instructor/Presenter',
  an_in_person_course: 'An In-Person Course',
  a_synchronous_live_online_course: 'A Synchronous/ Live Online Course',
  an_asynchronous_self_paced_course: 'An Asynchronous/ Self-Paced Course',
  standalone_seminar_event_talk: 'Standalone Seminar/ Event/ Talk',
  internal_online_training: 'Internal, online training',
  internal_in_person_training: 'Internal, in-person training',
  online_training_for_customers: 'Online training for customers',
  in_person_training_for_customers: 'In-person training for customers',
  none_of_the_above: 'None of the above',
  click_to_send_your_basic_details_for_verification_as_instructor:
    'click to send your basic details for verification as instructor',

  /** join code */
  please_enter_the_N_character_course_join_code_shared_by_your_instructor:
    'Please enter the {{count}} character course "Join Code" shared by your instructor',
  how_to_sign_up_as_a_student: 'How to sign up as a student',
  you_can_sign_up_as_a_student_only_if_you_are_enrolled_in_a_course_or_have_a_course_join_code_if_you_do_not_have_a_join_code_but_have_received_an_email_from_acadly_that_you_re_now_enrolled_in_a_course_please_log_in_or_sign_up_using_the_email_address_at_which_you_have_received_the_email_the_email_mentions_the_email_address_you_should_use_to_create_the_account:
    'You can sign up as a student only if you are enrolled in a course or have a course Join Code. If you do not have a Join Code but have received an email from Acadly that you’re now enrolled in a course, please log in or sign up using the email address at which you have received the email. The email mentions the email address you should use to create the account',
  go_to_login: 'Go to login',
  i_do_not_have_a_join_code: 'I do not have a Join Code',
  course_join_code: 'Course join code',
  N_character_join_code_here: '{{count}} character join code here',
  join_code_colon_code: 'Join code: {{code}}',
  click_to_cancel_joining_this_course: 'click to cancel joining this course',
  click_to_join_this_course: 'click to join this course',
  please_press_continue_to_join_this_course_with_following_email_colon_email_address:
    'Please press continue to join this course with following email: <2>{{email}}</2>',
  click_to_learn_how_to_signup_as_a_student: 'click to learn how to signup as a student',
  click_to_verify_join_code: 'click to verify join code',

  /** thank you */
  thank_you_for_choosing_acadly: 'Thank you for choosing Acadly!',
  we_will_manually_verify_if_you_teach_at_a_higher_education_institution_upon_successful_verification_your_free_account_will_be_activated_and_you_will_be_notified_via_email_within_the_next_24_hours:
    'We will manually verify if you teach at a higher education institution. Upon successful verification, your free account will be activated and you will be notified via email within the next 24 hours.',
  if_you_work_at_a_company_or_non_profit_we_will_get_in_touch_with_you_with_details_on_how_you_can_get_started_with_acadly:
    'If you work at a company or non-profit, we will get in touch with you with details on how you can get started with Acadly.',
  click_to_learn_about_acadly_here_and_automatically_record_attendance_anywhere:
    'Click to learn about Acadly Here and automatically record attendance anywhere',
  if_you_have_any_questions_please_feel_free_to_write_to_us_at_email_thanks:
    'If you have any questions, please feel free to write to us at <2>support@acadly.com.</2> Thanks!',
  click_to_go_back_to_login_page: 'click to go back to login page',

  /** Verification Awaited */
  verification_awaited: 'Verification awaited',
  we_have_not_been_able_to_verify_you_yet: 'We have not been able to verify you yet :(',
  we_would_love_to_have_you_on_acadly_as_soon_as_possible:
    'We would love to have you on Acadly as soon as possible.',
  having_said_that_we_just_sent_a_reminder_to_the_verification_team_and_you_can_expect_a_mail_on_your_registered_email_id_welcoming_you_to_acadly_shortly:
    'Having said that, we just sent a reminder to the verification team, and you can expect a mail on your registered Email ID welcoming you to Acadly shortly.',
  thank_you_for_your_patience: 'Thank you for your patience!',

  /** Change Password Drawer */
  changing_password: 'Changing Password',
  your_current_password: 'Your current password',
  new_password: 'New password',
  confirm_new_password: 'Confirm new password',
  your_password_has_been_changed_kindly_log_in_again_to_continue:
    'Your password has been changed. Kindly log in again to continue.',

  /** Change Name Drawer */
  changing_name: 'Changing name',
  current_name: 'Current name',
  please_enter_your_full_name: 'Please enter your full name',
  are_you_sure_you_want_to_change_your_name_from_old_name_to_new_name:
    'Are you sure you want to change your name from {{oldName}} to {{newName}}?',
  you_will_be_logged_out_of_acadly_from_all_your_devices_the_change_would_reflect_upon_logging_back_in:
    'You will be logged out of Acadly from all your devices. The change would reflect upon logging back in.',
  you_can_change_your_name_N_times_one: 'you can change your name {{count}} time',
  you_can_change_your_name_N_times_other: 'you can change your name {{count}} times',

  /** Change Avatar Drawer */
  set_avatar: 'Set avatar',
};

export default en;
