import { ReactNode } from 'react';

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { Divider, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Stack } from '@mui/material';

import Button from '../../../../components/Button';
import { FormulaTranslations } from '../i18n';
import { FormulaDisplay, FormulaFormat } from '../types';
import { FomulaDisplayLabelMap, FomulaFormatLabelMap } from './constants';
import useFormatMenuVM, { VMProps } from './vm';

interface MenuOptionProps {
  children: ReactNode;
  selected?: boolean;
  onSelect: () => any;
}

const MenuOption = ({ children, selected, onSelect }: MenuOptionProps) => {
  return (
    <MenuItem
      sx={{
        color: selected ? 'primary.main' : undefined,
        '& .MuiSvgIcon-root': { color: selected ? 'primary.main' : undefined },
      }}
      onClick={onSelect}
    >
      <ListItemIcon>{selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}</ListItemIcon>
      <ListItemText sx={{ color: selected ? 'primary.main' : undefined }}>{children}</ListItemText>
    </MenuItem>
  );
};

interface Props extends VMProps {
  i18n: FormulaTranslations;
}

const FormatMenu = ({ i18n, ...vmProps }: Props) => {
  const {
    anchorEl,
    display,
    format,
    isOpen,
    onCancel: handleCancel,
    onDisplayChange: handleDisplayChange,
    onDone: handleDone,
    onFormatChange: handleFormatChange,
    onOpen: handleOpen,
    toggleMenuLabel,
  } = useFormatMenuVM(vmProps);

  return (
    <div>
      <Button
        id="format-menu-button"
        aria-controls={isOpen ? 'format-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        sx={{ textTransform: 'none' }}
        variant="outlined"
        disableElevation
        onClick={handleOpen}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {toggleMenuLabel}
      </Button>
      <Menu
        open={isOpen}
        onClose={handleCancel}
        id="format-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          dense: true,
          'aria-labelledby': 'format-menu-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <ListSubheader>{i18n.input_format}</ListSubheader>
        <MenuOption selected={format === FormulaFormat.TEX} onSelect={handleFormatChange(FormulaFormat.TEX)}>
          {FomulaFormatLabelMap[FormulaFormat.TEX]}
        </MenuOption>
        <MenuOption
          selected={format === FormulaFormat.ASCII_MATH}
          onSelect={handleFormatChange(FormulaFormat.ASCII_MATH)}
        >
          {FomulaFormatLabelMap[FormulaFormat.ASCII_MATH]}
        </MenuOption>
        <MenuOption
          selected={format === FormulaFormat.MATH_ML}
          onSelect={handleFormatChange(FormulaFormat.MATH_ML)}
        >
          {FomulaFormatLabelMap[FormulaFormat.MATH_ML]}
        </MenuOption>
        <Divider sx={{ my: 0.5 }} />
        <ListSubheader>{i18n.output_display_style}</ListSubheader>
        <MenuOption
          selected={display === FormulaDisplay.INLINE}
          onSelect={handleDisplayChange(FormulaDisplay.INLINE)}
        >
          {FomulaDisplayLabelMap[FormulaDisplay.INLINE]}
        </MenuOption>
        <MenuOption
          selected={display === FormulaDisplay.BLOCK}
          onSelect={handleDisplayChange(FormulaDisplay.BLOCK)}
        >
          {FomulaDisplayLabelMap[FormulaDisplay.BLOCK]}
        </MenuOption>
        <Stack direction="row" justifyContent="center">
          <Button variant="text" color="warning" onClick={handleCancel}>
            {i18n.cancel}
          </Button>
          <Button variant="text" onClick={handleDone}>
            {i18n.done}
          </Button>
        </Stack>
      </Menu>
    </div>
  );
};

export default FormatMenu;
