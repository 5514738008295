import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Button from '../../../../components/Button';
import { buttonClasses } from '../../../../components/Button/styles';
import { HelperText } from '../../../../components/FormControls/TextFieldBase/styles';
import TextField from '../../../../components/HookFormControls/TextField';
import { i18nNS } from '../../../../i18n';
import InfoIcon from '../../../../icons/InfoIcon';
import { generateClasses } from '../../../../utils/helpers';
import RoleDetails from './RoleDetails';
import useAddTeachingAssistantVM from './vm';

const addTeachingAssistantClasses = generateClasses('AddTeachingAssistant', [
  'heading',
  'helperButton',
  'helperIcon',
  'helperText',
  'submit',
]);

const Root = styled('form')(({ theme }) => ({
  [`.${addTeachingAssistantClasses.heading}`]: {
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[700],
  },

  [`.${addTeachingAssistantClasses.helperButton}`]: {
    [`&.${buttonClasses.root}`]: {
      padding: 0,
      border: 'none',
      display: 'block',
      minHeight: 'unset',
      backgroundColor: 'transparent',
    },
  },

  [`.${addTeachingAssistantClasses.helperIcon}`]: {
    fontSize: theme.typography.pxToRem(16),
  },

  [`.${addTeachingAssistantClasses.helperText}`]: {
    color: theme.palette.primary[600],
  },

  [`.${addTeachingAssistantClasses.submit}`]: {
    marginTop: 22,
  },
}));

const AddTeachingAssistant = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.CREATE_COURSE, i18nNS.GLOSSARY]);

  const {
    addTeachingAssistant,
    closeRoleDetails,
    control,
    controlName,
    error,
    isRoleDetailsOpen,
    isSubmitting,
    openRoleDetails,
  } = useAddTeachingAssistantVM();

  return (
    <Root onSubmit={addTeachingAssistant}>
      <Typography className={addTeachingAssistantClasses.heading} variant="h6Bold">
        {t('add_course_teaching_assistants', { ns: i18nNS.CREATE_COURSE })}
      </Typography>
      <Stack direction="row" alignItems="flex-start" gap={3}>
        <Stack flex="1">
          <TextField
            control={control}
            name={controlName}
            size="large"
            placeholder={t('enter_ta_s_valid_institute_email_address', {
              ns: i18nNS.CREATE_COURSE,
            })}
          />
          {!error && (
            <Button className={addTeachingAssistantClasses.helperButton} onClick={openRoleDetails}>
              <HelperText className={addTeachingAssistantClasses.helperText}>
                <InfoIcon
                  className={addTeachingAssistantClasses.helperIcon}
                  variant="filled"
                  color="inherit"
                />
                {t('learn_what_course_teaching_assistant_can_do', { ns: i18nNS.CREATE_COURSE })}
              </HelperText>
            </Button>
          )}
        </Stack>
        <Button
          type="submit"
          variant="outlined"
          size="large"
          isLoading={isSubmitting}
          aria-label={t('click_to_add_teaching_assistant', { ns: i18nNS.CREATE_COURSE })}
        >
          {t('add', { ns: i18nNS.GLOSSARY })}
        </Button>
      </Stack>
      <RoleDetails open={isRoleDetailsOpen} onClose={closeRoleDetails} />
    </Root>
  );
};

export default AddTeachingAssistant;
