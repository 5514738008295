import Pusher from 'pusher-js';
import { EventChannel } from 'redux-saga';
import { call, fork, put, race, take, takeLatest } from 'redux-saga/effects';

import { logout } from '../auth/store/actions';
import Logger from '../utils/logger';
import {
  startCourseChannel,
  startCourseTeamChannel,
  startPusher,
  stopCourseChannel,
  stopCourseTeamChannel,
} from './actions';
import pusherEventChannel from './channel';
import { createPusher, createPusherSagaChannel } from './helpers';
import rootPusherEventWatcher from './subscribe';
import { PusherChannel, PusherEvent, PusherEventName } from './types';

const log = Logger.create('pusher');

function* readUserChannel(pusher: Pusher, userId: MongoId) {
  const channel: EventChannel<PusherEvent<{}, PusherEventName>> = yield call(createPusherSagaChannel, {
    pusher,
    channel: {
      id: `private-user-${userId}`,
      name: PusherChannel.USER,
    },
  });

  while (true) {
    type RaceActions = {
      event: PusherEvent<{}>;
      stop: ReturnType<typeof logout>;
    };

    const { event, stop }: RaceActions = yield race({
      event: take(channel),
      stop: take(logout),
    });

    if (stop) break;

    yield put(pusherEventChannel, event);
  }

  yield call(channel.close);
}

function* readCourseChannel(pusher: Pusher, action: ReturnType<typeof startCourseChannel>) {
  const channel: EventChannel<PusherEvent<{}, PusherEventName>> = yield call(createPusherSagaChannel, {
    pusher,
    channel: {
      id: `private-course-${action.payload.courseId}`,
      name: PusherChannel.COURSE,
    },
  });

  while (true) {
    type RaceActions = {
      event: PusherEvent<{}>;
      stop: ReturnType<typeof logout | typeof stopCourseChannel>;
    };

    const { event, stop }: RaceActions = yield race({
      event: take(channel),
      stop: take([logout, stopCourseChannel]),
    });

    if (stop) break;

    yield put(pusherEventChannel, event);
  }

  yield call(channel.close);
}

function* readCourseTeamChannel(pusher: Pusher, action: ReturnType<typeof startCourseTeamChannel>) {
  const channel: EventChannel<PusherEvent<{}, PusherEventName>> = yield call(createPusherSagaChannel, {
    pusher,
    channel: {
      id: `private-courseTeam-${action.payload.courseId}`,
      name: PusherChannel.COURSE_TEAM,
    },
  });

  while (true) {
    type RaceActions = {
      event: PusherEvent<{}>;
      stop: ReturnType<typeof logout | typeof stopCourseTeamChannel>;
    };

    const { event, stop }: RaceActions = yield race({
      event: take(channel),
      stop: take([logout, stopCourseTeamChannel]),
    });

    if (stop) break;

    yield put(pusherEventChannel, event);
  }

  yield call(channel.close);
}

function* readPusherEvents(action: ReturnType<typeof startPusher>) {
  try {
    const session = action.payload;

    const pusher: YieldCallType<typeof createPusher> = yield call(createPusher, action.payload);

    yield fork(readUserChannel, pusher, session.userId);
    yield takeLatest(startCourseChannel, readCourseChannel, pusher);
    yield takeLatest(startCourseTeamChannel, readCourseTeamChannel, pusher);
  } catch (err) {
    log.error(err);
  }
}

export default function* rootPusherSaga() {
  yield fork(rootPusherEventWatcher);
  yield takeLatest(startPusher, readPusherEvents);
}
