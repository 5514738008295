import { forwardRef, Ref } from 'react';

import TooltipArrowIcon from '../../icons.deprecated/TooltipArrow';
import { Props, StyledTooltip, tooltipClasses } from './styles';

const TooltipArrow = forwardRef((props: any, ref: Ref<HTMLElement>) => {
  return (
    <span ref={ref} className={tooltipClasses.arrowWrapper}>
      <TooltipArrowIcon {...props} />
    </span>
  );
});

const Tooltip = (
  { arrow = true, disableInteractive = true, placement = 'top', ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <StyledTooltip
      ref={ref}
      arrow={arrow}
      slots={{
        arrow: TooltipArrow,
      }}
      {...props}
      disableInteractive={disableInteractive}
      placement={placement}
    >
      {props.children}
    </StyledTooltip>
  );
};

export default forwardRef(Tooltip);
