import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const SortDescending = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 1632 1792" {...props}>
      <path
        d="M1159 1432h177l-72-218-12-47q-2-16-2-20h-4l-3 20q0 1-3.5 18t-7.5 29zm-455 8q0 12-10 24l-319 319q-10 9-23 9-12 0-23-9L9 1463q-15-16-7-35 8-20 30-20h192V32q0-14 9-23t23-9h192q14 0 23 9t9 23v1376h192q14 0 23 9t9 23zm925 246v106h-288v-106h75l-47-144h-243l-47 144h75v106H867v-106h70l230-662h162l230 662h70zm-89-1151v233H956v-90l369-529q12-18 21-27l11-9v-3q-2 0-6.5.5t-7.5.5q-12 3-30 3h-232v115H961V0h567v89l-369 530q-6 8-21 26l-11 10v3l14-3q9-1 30-1h248V535h121z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SortDescending;
