import { Dialog as MuiDialog, dialogClasses, styled } from '@mui/material';

import SlideUpTransition from '../Dialog/SlideUpTransition';
import ExportSteps from './ExportSteps';
import { ExportType } from './types';

export interface Props {
  open: boolean;
  onClose: () => void;
  exportTypeTitle: string;
  downloadStepTitle?: string;
  emailStepTitle?: string;
  onGetFileDetails: (
    selectedExportType: ExportType
  ) => Promise<{ fileName: string; downloadURL: string } | undefined>;
}

const Dialog = styled(MuiDialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
  },
}));

const ExportDialog = ({
  open,
  onClose,
  exportTypeTitle,
  downloadStepTitle,
  emailStepTitle,
  onGetFileDetails,
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={SlideUpTransition} fullWidth maxWidth="xs">
      <ExportSteps
        onClose={onClose}
        exportTypeTitle={exportTypeTitle}
        onGetFileDetails={onGetFileDetails}
        downloadStepTitle={downloadStepTitle}
        emailStepTitle={emailStepTitle}
      />
    </Dialog>
  );
};

export default ExportDialog;
