import { forwardRef, Ref } from 'react';

import { keyframes, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../components/Button';
import AcadlyPipHeader from '../../../pip-container/PipHeader';
import { generateClasses } from '../../../utils/helpers';
import { ATTENDANCE_GIF_0, ATTENDANCE_GIF_20, ATTENDANCE_GIF_40, ATTENDANCE_GIF_80 } from '../../constants';

export const recordInPersonAttendanceClasses = generateClasses('RecordInPersonAttendance', [
  'pipHeaderButton',
  'pipHeaderButtonDragHandle',
  'alignCenter',
]);

export const Pip = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  bottom: 0,
  boxShadow: theme.shadows[2],
  maxWidth: '100%',
}));

export const PipHeader = styled(AcadlyPipHeader)(({ theme }) => ({
  /** Acadly pip header already has position: absolute */
  zIndex: 1,
  /** Acadly pip header is already a flex container */
  justifyContent: 'space-between',
}));

export const PipHeaderButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
      variant="text"
      {...props}
      ref={ref}
      className={clsx(recordInPersonAttendanceClasses.pipHeaderButton, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${recordInPersonAttendanceClasses.pipHeaderButton}`]: {
    color: theme.palette.grey[600],
    '&:hover, &:focus': {
      color: theme.palette.common.black,
    },
    [`&.${recordInPersonAttendanceClasses.pipHeaderButtonDragHandle}`]: {
      cursor: 'move',
    },
  },
})) as typeof Button;

export const PipContent = styled((props: StackProps) => <Stack alignItems="center" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    width: '25rem',
    maxWidth: '100%',
    padding: theme.spacing(4, 4, 6),
    position: 'relative',
    [`& .${recordInPersonAttendanceClasses.alignCenter}`]: {
      alignSelf: 'center',
    },
  })
);

export const PipStopButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(0.5),
  right: theme.spacing(1),
  color: theme.palette.warning.main,
}));

export const attendancegif = keyframes`
0% { background-image: url('${ATTENDANCE_GIF_0}')}
20% { background-image: url('${ATTENDANCE_GIF_20}') }
40% { background-image: url('${ATTENDANCE_GIF_40}') }
80% { background-image: url('${ATTENDANCE_GIF_80}') }
`;

export const AttendanceGif = styled('div')<{ isHidden?: boolean }>(({ theme, isHidden }) => ({
  backgroundImage: `url('${ATTENDANCE_GIF_0}')`,
  animation: `${attendancegif} 1s linear infinite`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  padding: theme.spacing(6),
  height: theme.spacing(24),
  width: theme.spacing(24),
  visibility: isHidden ? 'hidden' : 'visible',
}));
