import { useSelector } from 'react-redux';

import { selectCurrentCourse } from '../../db/courses/selectors';
import { getIsDeadlineOver } from './helpers';

export default function useCourseFooterVM() {
  const course = useSelector(selectCurrentCourse);
  const isDeadlineOver = getIsDeadlineOver({ course });
  return { isDeadlineOver };
}
