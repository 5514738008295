import { useEffect, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

import { fetchTimezones } from '../../../db/courses/actions';
import { UniversityTimezone } from '../../../db/shared/types';
import { useRequestDispatch } from '../../../utils/request-actions';

export interface Props<T extends FieldValues> {
  control: Control<T, object>;
  name: Path<T>;
}

const useTimezoneVM = <T extends FieldValues>({ control, name }: Props<T>) => {
  const requestDispatch = useRequestDispatch();

  const [isFetchingTimezones, setIsFetchingTimezones] = useState(false);
  const [universityTimezones, setUniversityTimezones] = useState<UniversityTimezone[]>([]);
  const [isSelectTimezoneAlertOpen, setIsSelectTimezoneAlertOpen] = useState(false);

  const controller = useController({
    control,
    name,
  });

  const onClickSelectTimezone = () => {
    setIsSelectTimezoneAlertOpen(true);
  };

  const onCloseSelectTimezoneAlert = () => {
    controller.field.onBlur();
    setIsSelectTimezoneAlertOpen(false);
  };

  useEffect(
    function getTimezones() {
      setIsFetchingTimezones(true);
      requestDispatch(fetchTimezones)
        .then((response) => setUniversityTimezones(response.timezones.universityTimezones))
        .finally(() => setIsFetchingTimezones(false));
    },
    [requestDispatch]
  );

  return {
    controller,
    isSelectTimezoneAlertOpen,
    onClickSelectTimezone,
    onCloseSelectTimezoneAlert,
    isFetchingTimezones,
    universityTimezones,
  };
};

export default useTimezoneVM;
