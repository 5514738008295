import { forwardRef, ReactElement, Ref } from 'react';

import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import RadioGroupContextProvider from './Context';
import useRadioGroupVM, { Props as VMProps } from './vm';

export const radioGroupClasses = {
  ...generateClasses('RadioGroup', ['root', 'label', 'radioGroup']),
};

export interface Props
  extends VMProps,
    DistributiveOmit<
      MuiRadioGroupProps,
      'ref' | 'children' | 'defaultValue' | 'value' | 'onChange' | 'classes'
    > {
  label?: string;
  fullWidth?: boolean;
  groupRef?: MuiRadioGroupProps['ref'];
  children: MuiRadioGroupProps['children'];
  classes?: Partial<typeof radioGroupClasses>;
}

const RadioGroup = (
  { label, fullWidth, groupRef, canUncheck, value = null, onChange, className, classes, ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const { labelId, contextValue, handleClick } = useRadioGroupVM({ canUncheck, value, onChange });

  return (
    <FormControl
      ref={ref}
      variant="standard"
      fullWidth={fullWidth}
      className={clsx(radioGroupClasses.root, className, classes?.root)}
    >
      {label && (
        <FormLabel id={labelId} className={clsx(radioGroupClasses.label, classes?.label)}>
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        aria-labelledby={labelId}
        {...props}
        ref={groupRef}
        value={contextValue}
        onClick={handleClick}
        className={clsx(radioGroupClasses.radioGroup, classes?.radioGroup)}
      />
    </FormControl>
  );
};

const RadioGroupWithRef = forwardRef(RadioGroup) as (
  p: Props & { ref?: Ref<HTMLDivElement> }
) => ReactElement;

const RadioGroupWrapper = (props: Props, ref: Ref<HTMLDivElement>) => {
  return (
    <RadioGroupContextProvider>
      <RadioGroupWithRef {...props} ref={ref} />
    </RadioGroupContextProvider>
  );
};

export default forwardRef(RadioGroupWrapper);
