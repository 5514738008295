import { styled } from '@mui/material';

import clsx from 'clsx';

import AcadlyDialog, { Props as AcadlyDialogProps } from '../../../../components/Dialog';
import Typography from '../../../../components/Typography';
import { generateClasses } from '../../../../utils/helpers';
import { forMobile } from '../../../../utils/media-queries';

export const selectTimezoneAlertClasses = generateClasses('SelectTimezoneAlert', ['body']);

export const Dialog = styled((props: AcadlyDialogProps) => (
  <AcadlyDialog
    {...props}
    classes={{ ...props.classes, body: clsx(selectTimezoneAlertClasses.body, props.classes?.body) }}
  />
))(({ theme }) => ({
  [`& .${selectTimezoneAlertClasses.body}`]: {
    [forMobile(theme)]: {
      padding: theme.spacing(3, 7),
    },
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: 1.2,
}));
