import { NotificationType } from '../../../db/shared/types';
import i18n, { i18nNS } from '../../../i18n';

export function getNotificationLabel(notificationType: NotificationType) {
  switch (notificationType) {
    case NotificationType.SCHEDULE_UPDATE:
      return i18n.t('course_schedule_updated', { ns: i18nNS.SETTINGS });
    case NotificationType.NEW_QUERY:
      return i18n.t('new_query_raised', { ns: i18nNS.SETTINGS });
    case NotificationType.QUERY_RESPONSE:
      return i18n.t('new_response_to_your_query', { ns: i18nNS.SETTINGS });
    case NotificationType.NEW_ACTIVITY:
      return i18n.t('new_class_activity_published', { ns: i18nNS.SETTINGS });
    case NotificationType.NEW_ANNOUNCEMENT:
      return i18n.t('new_announcement_made', { ns: i18nNS.SETTINGS });
    case NotificationType.NEW_ASSIGNMENT:
      return i18n.t('new_assignment_published', { ns: i18nNS.SETTINGS });
    default:
      return i18n.t('course_schedule_updated', { ns: i18nNS.SETTINGS });
  }
}
