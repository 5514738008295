import { Drawer as MuiDrawer, drawerClasses, styled, Typography } from '@mui/material';

import { ReactComponent as AcadlyLogo } from '../../../../../assets/acadly-logo.svg';
import Button from '../../../../../components/Button';
import UserCell from '../../../../../components/UserCell/index';
import { generateClasses, pick } from '../../../../../utils/helpers';

export const hamburgerDrawerClasses = {
  ...pick(drawerClasses, 'paper'),
  ...generateClasses('HamburgerDrawer', ['closeDrawer']),
};

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
  [`& .${hamburgerDrawerClasses.paper}`]: {
    width: 312,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const AccountInfo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary[50],
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  padding: 0,
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-start',
  [`&.${hamburgerDrawerClasses.closeDrawer}`]: {
    color: theme.palette.grey[900],
    padding: 0,
    minHeight: 'unset',
  },
}));

export const Nav = styled('nav')(({ theme }) => ({
  padding: theme.spacing(1),
}));

export const Footer = styled('div')(({ theme }) => ({
  margin: theme.spacing(10, 5),
  display: 'grid',
  gridTemplateAreas: '"label label" "logo version"',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: 'auto auto',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  gap: theme.spacing(1),
}));

export const FooterLabel = styled(Typography)(({ theme }) => ({
  gridArea: 'label',
  color: theme.palette.grey[500],
}));

export const FooterLogo = styled(AcadlyLogo)(({ theme }) => ({
  gridArea: 'logo',
  height: 32,
  width: 'auto',
}));

export const FooterVersion = styled(Typography)(({ theme }) => ({
  gridArea: 'version',
  color: theme.palette.grey[500],
}));
