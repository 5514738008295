import { useState } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { ClassModality } from '../../../../../../courses/types';
import { selectCurrentCourse } from '../../../../../../db/courses/selectors';

export interface VMProps<T extends FieldValues> {
  controlName: Path<T>;
  venueControlName: Path<T>;
}

export default function useClassModalityVM<T extends FieldValues>({
  controlName,
  venueControlName,
}: VMProps<T>) {
  const currentCourse = useSelector(selectCurrentCourse);

  const { control, getFieldState, getValues, trigger } = useFormContext();

  const isProCourse = currentCourse?.permissions.isPro ?? false;

  const shouldShowVenue = (classModality: ClassModality) => classModality === ClassModality.IN_PERSON;

  const [isVenueVisible, setIsVenueVisible] = useState(shouldShowVenue(getValues(controlName)));

  const toggleVenueFieldVisibility = async () => {
    const classModality = getValues(controlName);
    setIsVenueVisible(shouldShowVenue(classModality));
    const { error, isTouched } = getFieldState(venueControlName);
    if (error || isTouched) await trigger(venueControlName);
  };

  return {
    control,
    controlName,
    isVenueVisible,
    isProCourse,
    toggleVenueFieldVisibility,
    venueControlName,
  };
}
