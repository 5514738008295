import { useTranslation } from 'react-i18next';

import { Link } from '@mui/material';

import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import useResendVerificationCodeVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
}

const ResendVerificationCode = ({ className, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.AUTH]);
  const {
    isResendingVerificationCode,
    timeLeftToAllowResendingVerificationCode,
    handleResendVerificationCode,
  } = useResendVerificationCodeVM(vmOptions);

  if (isResendingVerificationCode) {
    return (
      <Typography variant="paragraphRegular" className={className}>
        {t('resending_dot_dot_dot', { ns: i18nNS.AUTH })}
      </Typography>
    );
  }

  if (timeLeftToAllowResendingVerificationCode > 0) {
    return (
      <Typography variant="paragraphRegular" className={className}>
        {t('sent_retry_after_N_seconds', {
          ns: i18nNS.AUTH,
          count: timeLeftToAllowResendingVerificationCode,
        })}
      </Typography>
    );
  }

  return (
    <Link
      type="button"
      variant="paragraphRegular"
      component="button"
      onClick={handleResendVerificationCode}
      aria-label={t('click_to_resend_verification_code', { ns: i18nNS.AUTH })}
      className={className}
    >
      {t('resend_verification_code', { ns: i18nNS.AUTH })}
    </Link>
  );
};

export default ResendVerificationCode;
