import en from './en';

type Translation = {
  [key in keyof typeof en]: string;
};

type AnnouncementTranslations = {
  [lang: string]: Translation;
};

export const announcementTranslations: AnnouncementTranslations = {
  en,
};
