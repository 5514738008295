import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Alert from '../../../../components/Alert';
import AlertButton from '../../../../components/AlertButton';
import Tag from '../../../../components/Tag/index';
import UserCell from '../../../../components/UserCell/index';
import { i18nNS } from '../../../../i18n';
import Trash2Icon from '../../../../icons/Trash2Icon';
import useCourseTeamUserVM, { VMProps } from './vm';

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const Role = styled(Tag)(() => ({
  textTransform: 'capitalize',
}));

interface Props extends VMProps {
  className?: string;
}

const CourseTeamUser = ({ className, ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);

  const { avatar, deleteMember, name, role, status } = useCourseTeamUserVM(vmProps);

  return (
    <UserCell
      avatar={avatar}
      title={
        <Stack direction="row" alignItems="center" gap={3}>
          <Title variant="paragraphMedium">{name}</Title>
          <Role color="success" variant="outlined" label={role} />
        </Stack>
      }
      subtitle={<Typography variant="paragraphRegular">{status}</Typography>}
      action={
        <AlertButton color="error" variant="text">
          <Trash2Icon color="warning" />
          <Alert maxWidth="xs">
            <Alert.Body>
              {t('are_you_sure_you_want_to_delete_this_team_member', { ns: i18nNS.COURSES })}
            </Alert.Body>
            <Alert.Footer>
              <Alert.Action variant="text">{t('no', { ns: i18nNS.GLOSSARY })}</Alert.Action>
              <Alert.Action variant="text" color="error" onClick={deleteMember}>
                {t('yes', { ns: i18nNS.GLOSSARY })}
              </Alert.Action>
            </Alert.Footer>
          </Alert>
        </AlertButton>
      }
    />
  );
};

export default CourseTeamUser;
