import { ReactNode } from 'react';

import { ButtonBase } from '@mui/material';

interface WrapperProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler;
  tabIndex?: number;
}

const Wrapper = ({ id, className, children, onClick, tabIndex }: WrapperProps) => {
  if (onClick) {
    return (
      <ButtonBase id={id} focusRipple className={className} onClick={onClick} tabIndex={tabIndex}>
        {children}
      </ButtonBase>
    );
  }

  return (
    <div id={id} className={className} tabIndex={tabIndex}>
      {children}
    </div>
  );
};

export default Wrapper;
