import { Discussion } from '../db/discussions/types';
import { ClassRole, CourseRole, TipStatusKey, UserWithRole } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { getIsActivityPublished } from '../utils/activities';

export function getCanFetchDiscussionDefaultPrefs(
  discussion: Pick<Discussion, 'publishedOn'> | undefined,
  role = ClassRole.STUDENT,
  isCourseArchived?: boolean
) {
  if (!discussion) return false;

  if (role !== ClassRole.INCHARGE) return false;

  if (isCourseArchived) return false;

  const isDiscussionPublished = getIsActivityPublished(discussion);
  return !isDiscussionPublished;
}

export function getCanFetchDiscussionComments({
  discussion,
  role,
}: {
  discussion: Discussion | undefined;
  role: CourseRole | undefined;
}) {
  if (role !== CourseRole.STUDENT) return true;

  if (!discussion) return false;
  if (!discussion.preferences.submitFirst) return true;

  return discussion.hasParticipated;
}

export function getCanSendDiscussionComments(
  discussion: Discussion | undefined,
  isCourseArchived: boolean | undefined
) {
  if (isCourseArchived) return false;

  if (!discussion) return false;

  const isDiscussionPublished = getIsActivityPublished(discussion);
  return isDiscussionPublished;
}

export function getEmptyCommentsMessageForDiscussion(
  discussion: Discussion | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  if (!discussion) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  const isDiscussionPublished = getIsActivityPublished(discussion);
  if (!isDiscussionPublished) {
    return i18n.t('comments_will_appear_here_once_this_activity_is_published', { ns: i18nNS.CLASS });
  }

  const isStudent = currentUser.role === CourseRole.STUDENT;
  const isSubmitFirst = discussion?.preferences.submitFirst;
  const hasParticipated = discussion?.hasParticipated;

  if (isStudent && isSubmitFirst && !hasParticipated) {
    return i18n.t('you_need_to_contribute_to_a_post_before_reading_other_posts', { ns: i18nNS.DISCUSSION });
  }

  return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });
}

export function getDiscussionHeader(discussion: Pick<Discussion, 'sequenceNum'> | undefined) {
  if (!discussion?.sequenceNum || discussion.sequenceNum <= 0) {
    return i18n.t('discussion', { ns: i18nNS.GLOSSARY });
  }
  return i18n.t('discussion_num', {
    ns: i18nNS.DISCUSSION,
    num: `${discussion.sequenceNum}`.padStart(2, '0'),
  });
}

export function getDiscussionPageTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'discussionMainComment':
      return i18n.t(
        'the_posts_about_a_particular_discussion_topic_can_be_made_and_accessed_by_using_this_icon',
        { ns: i18nNS.DISCUSSION }
      );
    default:
      return '';
  }
}

export function getCanSeeDiscussionHeaderActionStar(
  discussion: Discussion | undefined,
  role = ClassRole.STUDENT
) {
  if (!discussion) return false;
  if (role === ClassRole.STUDENT) return false;
  if (discussion.wordCloud.isGenerated) return false;
  return discussion.wordCloud.isAvailable;
}
