import { AriaAttributes, MouseEventHandler, ReactNode, useContext } from 'react';

import { ListItemIcon, ListItemText, MenuItem, styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';
import Tooltip from '../Tooltip';
import FabMenuContext from './Context';

export const fabMenuItemClasses = generateClasses('FabMenuItem', ['root', 'disabled', 'icon', 'text']);

const MenuItemText = styled(ListItemText)(({ theme }) => ({
  [`.${fabMenuItemClasses.disabled} &`]: {
    color: theme.palette.grey[500],
  },
}));

interface Props extends AriaAttributes {
  /**
   * If set to `true`, `FabMenu` is closed after clicking on `FabMenuItem`.
   * If `targetMenu` is a truthy value then it has no effect.
   * @default true
   */
  autoCloseMenu?: boolean;
  children: ReactNode;
  className?: string;
  color?: 'primary' | 'warn' | 'error' | 'inherit';
  /** @default false */
  disabled?: boolean;
  icon?: ReactNode;
  onClick?: MouseEventHandler;
  /** if falsy value then it will not open child-menu */
  targetMenu?: string;
  tooltip?: string;
  autoFocus?: boolean;
}

function FabMenuItem({
  autoCloseMenu = true,
  children,
  className,
  disabled,
  icon,
  onClick,
  targetMenu,
  tooltip,
  autoFocus = false,
  ...props
}: Props) {
  const { goToMenu, close } = useContext(FabMenuContext);

  const openMenu: MouseEventHandler = (e) => {
    onClick?.(e);

    if (targetMenu) {
      goToMenu(targetMenu);
    } else if (autoCloseMenu) {
      close();
    }
  };

  const content = (
    <MenuItem
      className={clsx(className, fabMenuItemClasses.root, { [fabMenuItemClasses.disabled]: disabled })}
      disabled={disabled && !tooltip}
      disableRipple={disabled}
      onClick={disabled ? undefined : openMenu}
      tabIndex={0}
      autoFocus={autoFocus}
      {...props}
    >
      {icon && <ListItemIcon className={fabMenuItemClasses.icon}>{icon}</ListItemIcon>}
      <MenuItemText className={fabMenuItemClasses.text}>{children}</MenuItemText>
    </MenuItem>
  );

  if (tooltip) {
    return <Tooltip title={tooltip}>{content}</Tooltip>;
  }

  return content;
}

export default FabMenuItem;
