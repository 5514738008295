import { splitFileNameAndExtension } from '../../utils/file';
import { API } from '../shared/api-responses';
import { CourseRole, CourseUser, ResourceType } from '../shared/types';
import { BaseResource, BaseSuggestedResource, Resource, SuggestedResource } from './types';

interface ResourseIdentifiers {
  courseId: MongoId;
  classId: MongoId;
}

interface CreateResourseOptions<T extends API.BaseResource> {
  resource: T;
  identifiers: ResourseIdentifiers;
  userData: API.ResourceUserData | undefined;
  currentUser: CourseUser | undefined;
}

export function createResourseFactory<T extends API.BaseResource>({
  resource,
  userData,
  currentUser,
  identifiers: { courseId, classId },
}: CreateResourseOptions<T>): Resource {
  const editedOn = resource.details.lastEditedOn ?? -1;

  let studentDataById: Resource['studentDataById'] = {};

  if (currentUser?.role === CourseRole.STUDENT) {
    studentDataById = {
      [currentUser.userId]: {
        userId: currentUser.userId,
        name: currentUser.name,
        avatar: currentUser.avatar,
        clicks: userData?.timesFetched ?? 1,
        firstAccessedOn: userData?.firstAccessedOn ?? -1,
        lastAccessedOn: userData?.lastAccessedOn ?? -1,
      },
    };
  }

  const baseResource: BaseResource = {
    id: resource._id,
    classId,
    courseId,
    title: resource.details.title,
    description: resource.details.description,
    toBeDone: resource.details.toBeDone,
    dueDateTime: -1,
    sequenceNum: resource.details.trueNum ?? 0,
    studentDataById,
    totalClicks: resource.stats?.numClicks ?? 0,
    comments: {
      total: resource.activities.numCommentsTotal,
      seen: userData?.numCommentsSeen ?? 0,
      isSubscribed: Boolean(userData?.subscribed),
    },
    editedOn,
    editedBy: editedOn > 0 && resource.details.lastEditedBy ? resource.details.lastEditedBy : null,
    createdOn: resource.details.createdOn,
    createdBy: resource.details.createdBy,
    firstAccessedOn:
      currentUser?.userId === resource.details.createdBy.userId
        ? resource.details.createdOn
        : userData?.firstAccessedOn ?? -1,
    lastAccessedOn: userData?.lastAccessedOn ?? -1,
    publishedOn: resource.details.publishedOn > 0 ? resource.details.publishedOn : -1,
  };

  switch (resource.details.resourceType) {
    case ResourceType.FILE: {
      const { name, extension } = splitFileNameAndExtension(
        resource.details.originalFileName,
        resource.details.fileName
      );
      return {
        ...baseResource,
        type: ResourceType.FILE,
        name: resource.details.fileName,
        extension,
        originalName: name,
      };
    }
    case ResourceType.LINK: {
      return {
        ...baseResource,
        type: ResourceType.LINK,
        url: resource.details.url,
      };
    }
    case ResourceType.VIDEO: {
      return {
        ...baseResource,
        type: ResourceType.VIDEO,
        videoId: resource.details.videoId,
      };
    }
    case ResourceType.TEXT:
    default: {
      return {
        ...baseResource,
        type: ResourceType.TEXT,
      };
    }
  }
}

interface SuggestedResourceOptions<T extends API.SuggestedResource> {
  suggestedResource: T;
}

export function suggestedResourceFactory<T extends API.SuggestedResource>({
  suggestedResource,
}: SuggestedResourceOptions<T>): SuggestedResource {
  const suggestedBaseResource: BaseSuggestedResource = {
    id: suggestedResource._id,
    type: suggestedResource.details.resourceType,
    courseId: suggestedResource.identifiers.courseId,
    courseCode: suggestedResource.identifiers.courseCode,
    courseTitle: suggestedResource.identifiers.courseTitle,
    title: suggestedResource.details.title || null,
    description: suggestedResource.details.description,
    toBeDone: suggestedResource.details.toBeDone,
    sequenceNum: suggestedResource.details.trueNum ?? 0,
    publishedOn: suggestedResource.details.publishedOn > 0 ? suggestedResource.details.publishedOn : -1,
    isHidden: Boolean(suggestedResource.hidden),
    isUsed: Boolean(suggestedResource.used),
    createdOn: suggestedResource.details.createdOn,
    createdBy: suggestedResource.details.createdBy,
  };
  switch (suggestedResource.details.resourceType) {
    case ResourceType.FILE: {
      const { name, extension } = splitFileNameAndExtension(
        suggestedResource.details.originalFileName,
        suggestedResource.details.fileName
      );
      return {
        ...suggestedBaseResource,
        type: ResourceType.FILE,
        name: suggestedResource.details.fileName,
        originalName: name,
        extension,
      };
    }

    case ResourceType.LINK: {
      return {
        ...suggestedBaseResource,
        type: ResourceType.LINK,
        url: suggestedResource.details.url,
      };
    }

    case ResourceType.VIDEO: {
      return {
        ...suggestedBaseResource,
        type: ResourceType.VIDEO,
        videoId: suggestedResource.details.videoId,
      };
    }

    case ResourceType.TEXT: {
      return {
        ...suggestedBaseResource,
        type: ResourceType.TEXT,
      };
    }
  }
}
