import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { sendFeedback } from '../../db/app/actions';
import { useRequestDispatch } from '../../utils/request-actions';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useFeedbackDialogVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      subject: '',
      body: '',
    },
    mode: 'all',
  });

  const { reset, setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await requestDispatch(sendFeedback, { subject: data.subject, body: data.body });
    reset();
    onClose();
  };

  useEffect(() => {
    if (!open) return;
    setValue('subject', '');
    setValue('body', '');
  }, [open, setValue]);

  return {
    formMethods,
    onSubmit,
  };
};

export default useFeedbackDialogVM;
