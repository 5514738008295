import { Stack, StackProps, styled } from '@mui/material';

import AcadlyPlusIcon from '../../icons/PlusIcon';

export const Root = styled((props: StackProps) => <Stack alignItems="flex-start" gap={4} {...props} />)(
  ({ theme }) => ({
    padding: theme.spacing(8),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
  })
);

export const PlusIcon = styled(AcadlyPlusIcon)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.primary[500],
  color: theme.palette.common.white,
  margin: theme.spacing(0, 1, -1),
}));
