import { Draft } from '@reduxjs/toolkit';

import { ActivityType } from '../../shared/types';
import { BaseClassActivities } from '../types';

export function activityPublished({
  activityId,
  activityType,
  activities,
  createdOn,
  publishedOn,
}: {
  activityId: MongoId;
  activityType: ActivityType;
  activities: Draft<BaseClassActivities & { published: number }>;
  createdOn: UnixTime;
  publishedOn: UnixTime;
}) {
  activities.published++;

  const activity = activities.items.find((item) => item.id === activityId);

  if (activity) {
    activity.createdOn = createdOn;
    activity.publishedOn = publishedOn;
  } else {
    activities.items.push({
      id: activityId,
      type: activityType,
      publishedOn: publishedOn,
      createdOn: createdOn,
    });
    activities.total++;
  }
}
