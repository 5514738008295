import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import Alert from '../../../../components/Alert';
import AlertButton from '../../../../components/AlertButton';
import Button from '../../../../components/Button';
import { i18nNS } from '../../../../i18n';
import useCourseEndedInfoVM from './vm';

interface Props {
  onEditCourseDates: () => void;
}

const CourseEndedInfo = ({ onEditCourseDates: handleEditCourseDatesClick }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { isArchivingCourse, archiveCourse } = useCourseEndedInfoVM();

  return (
    <Stack gap={5}>
      <Typography>
        {t(
          'the_course_is_over_you_cannot_add_any_more_activities_to_it_because_it_is_past_the_course_end_date',
          { ns: i18nNS.COURSES }
        )}
      </Typography>
      <Typography>
        {t('you_can_either_archive_this_course_or_extend_the_end_date', { ns: i18nNS.COURSES })}
      </Typography>

      <Stack gap={2}>
        <Typography variant="titleBold">{t('if_you_archive_the_course', { ns: i18nNS.COURSES })}</Typography>
        <ul>
          <Typography component="li">
            {t('students_and_ta_wont_be_able_to_access_this_course', { ns: i18nNS.COURSES })}
          </Typography>
          <Typography component="li">
            {t('you_and_co_instructors_will_be_able_to_access_the_course_through_archived_courses', {
              ns: i18nNS.COURSES,
            })}
          </Typography>
          <Typography component="li">
            {t('all_course_data_including_responses_comments_and_grades_will_be_preserved', {
              ns: i18nNS.COURSES,
            })}
          </Typography>
          <Typography component="li">
            {t('you_will_be_able_to_copy_existing_activities_and_content_to_your_new_courses', {
              ns: i18nNS.COURSES,
            })}
          </Typography>
          <Typography component="li">
            {t('you_cannot_undo_the_course_archive_process', { ns: i18nNS.COURSES })}
          </Typography>
        </ul>

        <AlertButton
          fullWidth
          color="error"
          variant="outlined"
          size="large"
          aria-label={t('click_to_archive_course', { ns: i18nNS.COURSES })}
        >
          {t('archive_this_course_irreversible', { ns: i18nNS.COURSES })}
          <Alert maxWidth="xs">
            <Alert.Body>
              {t('are_you_sure_you_want_to_archive_this_course', { ns: i18nNS.COURSES })}
            </Alert.Body>
            <Alert.Footer paddingY={2}>
              <Alert.Action
                variant="text"
                disabled={isArchivingCourse}
                aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}
              >
                {t('no', { ns: i18nNS.GLOSSARY })}
              </Alert.Action>
              <Alert.Action
                color="error"
                variant="text"
                onClick={archiveCourse}
                aria-label={t('click_to_archive_course', { ns: i18nNS.COURSES })}
              >
                {t('yes', { ns: i18nNS.GLOSSARY })}
              </Alert.Action>
            </Alert.Footer>
          </Alert>
        </AlertButton>
      </Stack>

      <Stack gap={2}>
        <Typography variant="titleBold">
          {t('if_you_extend_the_course_end_date', { ns: i18nNS.COURSES })}
        </Typography>
        <ul>
          <Typography component="li">
            {t('the_course_will_continue_as_it_is_till_the_new_course_end_date', { ns: i18nNS.COURSES })}
          </Typography>
        </ul>
        <Button
          size="large"
          onClick={handleEditCourseDatesClick}
          aria-label={t('click_to_extend_course_deadline', { ns: i18nNS.COURSES })}
        >
          {t('set_new_course_end_date', { ns: i18nNS.COURSES })}
        </Button>
      </Stack>
    </Stack>
  );
};

export default CourseEndedInfo;
