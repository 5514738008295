import { Ref, useCallback, useContext, useEffect } from 'react';

import { tabClasses as MuiTabClasses } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';
import { useEventListener, useForwardedRef } from '../../../utils/hooks';
import { TabsContext } from '../context';

export const tabClasses = {
  ...MuiTabClasses,
  ...generateClasses('Tab', ['label']),
};

export interface Props {}

/**
 * There are some props set by Mui which are not publicly available
 */
export interface PrivateProps {
  selected?: boolean;
}

const useTabVM = (props: Props & PrivateProps, ref: Ref<HTMLDivElement>) => {
  const tabRef = useForwardedRef(ref);

  const { setActiveTabData } = useContext(TabsContext);

  const calculateActiveTabIndicatorSizeAndPosition = useCallback(() => {
    if (!props.selected) return;
    if (!tabRef.current) return;

    /** prefer offsetLeft over clientLeft to avoid calculation errors when view is horizontally scrolled */
    const left = tabRef.current.offsetLeft;
    const width = tabRef.current.clientWidth;
    const contentWidth = tabRef.current.querySelector(`.${tabClasses.label}`)?.clientWidth || 0;

    setActiveTabData({
      left,
      width,
      contentWidth,
    });
  }, [props.selected, setActiveTabData, tabRef]);

  useEventListener('resize', calculateActiveTabIndicatorSizeAndPosition);

  useEffect(
    function init() {
      calculateActiveTabIndicatorSizeAndPosition();
    },
    [calculateActiveTabIndicatorSizeAndPosition]
  );

  useEffect(
    function onResizeTab() {
      const ref = tabRef.current;
      if (!ref) return;

      /** This resize observer should trigger when tab's width changes */
      const resizeObserver = new ResizeObserver(calculateActiveTabIndicatorSizeAndPosition);
      resizeObserver.observe(ref);

      return () => {
        if (!ref) {
          resizeObserver.disconnect();
          return;
        }

        resizeObserver.unobserve(ref);
      };
    },
    [calculateActiveTabIndicatorSizeAndPosition, tabRef]
  );

  return { tabRef };
};

export default useTabVM;
