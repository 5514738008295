import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxIndeterminateIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <rect width="20" height="20" rx="3" fill="currentColor" />
      <rect x="6" y="9" width="8" height="2" rx="1" fill="white" />
    </SvgIcon>
  );
};

export default CheckboxIndeterminateIcon;
