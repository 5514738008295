import { forwardRef, ReactNode, Ref } from 'react';

import clsx from 'clsx';

import { Divider, Label, listSeparatorClasses, Root } from './styles';

export interface Props {
  /** @default 'primary' */
  color?: 'primary' | 'success' | 'warning' | 'error';
  leftLabel?: ReactNode;
  rightLabel?: ReactNode;
  className?: string;
}

const ListSeparator = (
  { color = 'primary', leftLabel, rightLabel, className }: Props,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <Root
      ref={ref}
      className={clsx(
        {
          [listSeparatorClasses.colorPrimary]: color === 'primary',
          [listSeparatorClasses.colorSuccess]: color === 'success',
          [listSeparatorClasses.colorWarning]: color === 'warning',
          [listSeparatorClasses.colorError]: color === 'error',
        },
        className
      )}
    >
      <Divider className={listSeparatorClasses.edgeDivider} />
      {leftLabel && <Label>{leftLabel}</Label>}
      <Divider />
      {rightLabel && <Label>{rightLabel}</Label>}
      <Divider className={listSeparatorClasses.edgeDivider} />
    </Root>
  );
};

export default forwardRef(ListSeparator);
