import { RootState } from '../../store/types';

export const selectFeatureUpdates = (state: RootState) => {
  const { byName } = state.db.features;
  return Object.values(byName).sort((a, b) => b.releasedOn - a.releasedOn);
};

export const selectFeatureSeenAtAndUpdatedAt = (state: RootState) => {
  const { seenAt, updatedAt } = state.db.features;
  return { seenAt, updatedAt };
};
