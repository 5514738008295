import { fork } from 'redux-saga/effects';

import rootAnnouncementsSaga from './announcements/sagas';
import rootAppSagas from './app/sagas';
import rootAssignmentsSaga from './assignments/sagas';
import rootAvatarsSaga from './avatars/sagas';
import rootBooksSaga from './books/sagas';
import rootClassesSagas from './classes/sagas';
import rootCommentsSaga from './comments/sagas';
import rootCoursesSaga from './courses/sagas';
import rootDiscussionsSaga from './discussions/sagas';
import rootFeaturesSaga from './features/sagas';
import rootLinksSaga from './links/sagas';
import rootOnlineMeetingSagas from './online-meeting/sagas';
import rootPollsSaga from './polls/sagas';
import rootQueriesSaga from './queries/sagas';
import rootQuizzesSaga from './quizzes/sagas';
import rootResourcesSaga from './resources/sagas';
import rootTopicsSaga from './topics/sagas';

export default function* rootDbSaga() {
  yield fork(rootAnnouncementsSaga);
  yield fork(rootAppSagas);
  yield fork(rootAssignmentsSaga);
  yield fork(rootAvatarsSaga);
  yield fork(rootBooksSaga);
  yield fork(rootClassesSagas);
  yield fork(rootCommentsSaga);
  yield fork(rootCoursesSaga);
  yield fork(rootDiscussionsSaga);
  yield fork(rootFeaturesSaga);
  yield fork(rootLinksSaga);
  yield fork(rootOnlineMeetingSagas);
  yield fork(rootPollsSaga);
  yield fork(rootQueriesSaga);
  yield fork(rootQuizzesSaga);
  yield fork(rootResourcesSaga);
  yield fork(rootTopicsSaga);
}
