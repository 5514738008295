import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import useRemoteAttendanceMarkedDialog from './vm';

interface Props {
  isOpen: boolean;
  onClose: MouseEventHandler;
}

const RemoteAttendanceMarkedDialog = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.GLOSSARY]);
  const { container } = useRemoteAttendanceMarkedDialog();

  return (
    <Alert open={isOpen} container={container}>
      <Alert.Header>{t('attentiveness_check', { ns: i18nNS.ONLINE_MEETING })}</Alert.Header>
      <Alert.Body>
        <Typography variant="paragraphRegular">
          {t(
            'you_turned_up_when_the_the_instructor_was_checking_the_attentiveness_of_the_class_we_have_let_them_know_you_re_here',
            { ns: i18nNS.ONLINE_MEETING }
          )}
        </Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action onClick={onClose}>{t('okay', { ns: i18nNS.GLOSSARY })}</Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default RemoteAttendanceMarkedDialog;
