import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../../../../../../components/Button';
import Tooltip from '../../../../../../../../../components/Tooltip';
import { MarkCommentAction } from '../../../../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../../../../i18n';
import SpinnerIcon from '../../../../../../../../../icons/LoaderIcon';
import ThumbsUpIcon from '../../../../../../../../../icons/ThumbsUpIcon';
import useLikeVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  edge?: ButtonProps['edge'];
  className?: string;
  classes?: Partial<Record<'root' | 'action', string>>;
}

const Like = ({ commentId, edge, className, classes }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);
  const {
    canLike,
    isLikeLoading,
    isLikeDisabled,
    hasLiked,
    handleMarkComment: _handleMarkComment,
  } = useLikeVM({ commentId });

  const handleMarkComment = (mark: MarkCommentAction.LIKE | MarkCommentAction.UNLIKE) => () => {
    _handleMarkComment(mark);
  };

  if (!canLike) return null;

  return (
    <Tooltip
      title={hasLiked ? t('undo_like', { ns: i18nNS.COMMENTS }) : t('like', { ns: i18nNS.GLOSSARY })}
      className={clsx(className, classes?.root)}
    >
      <span>
        <Button
          variant="text"
          size="small"
          edge={edge}
          className={classes?.action}
          aria-label={t(hasLiked ? 'click_to_undo_like' : 'click_to_like_this_post', {
            ns: i18nNS.COMMENTS,
          })}
          disabled={isLikeDisabled}
          onClick={handleMarkComment(hasLiked ? MarkCommentAction.UNLIKE : MarkCommentAction.LIKE)}
        >
          {isLikeLoading ? (
            <SpinnerIcon fontSize="small" />
          ) : (
            <ThumbsUpIcon variant={hasLiked ? 'filled' : 'outlined'} fontSize="small" aria-hidden />
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default Like;
