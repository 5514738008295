import { useTranslation } from 'react-i18next';

import Section from '../components/Section';
import { i18nNS } from '../i18n';
import { useDocumentTitle } from '../utils/hooks';
import AccessibilityFeatures from './AccessibilityFeatures';
import ChangeAvatar from './ChangeAvatar';
import ChangeName from './ChangeName';
import ChangePassword from './ChangePassword';
import DeleteCourses from './DeleteCourses';
import FeatureUpdates from './FeatureUpdates';
import Feedback from './Feedback';
import HelpTips from './HelpTips';
import ManageNotifications from './ManageNotifications';
import PrivacyPolicy from './PrivacyPolicy';
import ShareTheGoodness from './ShareTheGoodnes';
import { List, Root, UserCell } from './styles';
import TermsOfUse from './TermsOfUse';
import useSettingsVM from './vm';
import ZoomIntegration from './ZoomIntegration';

const Settings = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.GLOSSARY]);
  useDocumentTitle(t('acadly_settings', { ns: i18nNS.SETTINGS }));

  const { rootRef, session } = useSettingsVM();

  return (
    <Root ref={rootRef}>
      <UserCell avatar={session.avatar} title={session.name} subtitle={session.email} />

      <Section variant="transparent" title={t('account_settings', { ns: i18nNS.SETTINGS })}>
        <List>
          <ChangePassword />
          <ChangeName />
          <ChangeAvatar />
          <ManageNotifications />
          <FeatureUpdates />
          <HelpTips />
          <AccessibilityFeatures />
        </List>
      </Section>

      <Section variant="transparent" title={t('integrations', { ns: i18nNS.GLOSSARY })}>
        <List>
          <ZoomIntegration />
        </List>
      </Section>

      <Section variant="transparent" title={t('course_settings', { ns: i18nNS.SETTINGS })}>
        <List>
          <DeleteCourses />
        </List>
      </Section>

      <Section variant="transparent" title={t('legal', { ns: i18nNS.GLOSSARY })}>
        <List>
          <TermsOfUse />
          <PrivacyPolicy />
        </List>
      </Section>

      <Section variant="transparent" title={t('contact', { ns: i18nNS.GLOSSARY })}>
        <List>
          <Feedback />
          <ShareTheGoodness />
        </List>
      </Section>
    </Root>
  );
};

export default Settings;
