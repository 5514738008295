import { useTranslation } from 'react-i18next';

import { MenuItem, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/material';

import Select from '../../components/HookFormControls/Select';
import TextField from '../../components/HookFormControls/TextField';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import { roles } from './constants';
import { BackButton, NextButton } from './styles';
import useCreateTrialAccountVM from './vm';

const CreateTrialAccount = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { formMethods, onSubmit, universityName, handleBack } = useCreateTrialAccountVM();
  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Layout
      title={t('creating_trial_account', { ns: i18nNS.AUTH })}
      subtitle={
        <Stack gap={2}>
          <Typography variant="paragraphRegular">
            {t(
              'please_provide_the_following_details_to_help_us_verify_your_identity_as_an_instructor_at_university_name',
              { ns: i18nNS.AUTH, universityName }
            )}
          </Typography>
          <Typography variant="paragraphRegular">
            {t('we_ll_verify_and_change_your_account_status_in_the_next_24_hours', {
              ns: i18nNS.AUTH,
            })}
          </Typography>
        </Stack>
      }
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={4}>
          <TextField
            control={control}
            name="fullName"
            label={t('full_name', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('enter_your_full_name', { ns: i18nNS.AUTH })}
            fullWidth
            autoFocus
          />
          <TextField
            control={control}
            name="website"
            label={t('website_optional', { ns: i18nNS.AUTH })}
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('your_official_webpage', { ns: i18nNS.AUTH })}
            fullWidth
          />
          <Select
            control={control}
            name="role"
            label={t('role', { ns: i18nNS.GLOSSARY })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('select_your_role', { ns: i18nNS.AUTH })}
            fullWidth
          >
            {roles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.title}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            onClick={handleBack}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <NextButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_create_your_trial_account', { ns: i18nNS.AUTH })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </NextButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default CreateTrialAccount;
