import Joi from 'joi';

import i18n, { i18nNS } from '../../../../i18n';
import { requiredInteger } from '../../../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  startDate: requiredInteger
    .custom((startDate, helpers) => {
      const { endDate } = helpers.state.ancestors[0];
      if (endDate > 0 && startDate > endDate) {
        return helpers.error('any.custom');
      }
      return startDate;
    })
    .messages({
      ...requiredInteger.messages,
      'any.custom': i18n.t('start_date_should_be_ealier_than_end_date', {
        ns: i18nNS.VALIDATION,
      }),
    }),
  endDate: requiredInteger
    .custom((endDate, helpers) => {
      const { startDate } = helpers.state.ancestors[0];
      if (startDate > 0 && startDate > endDate) {
        return helpers.error('any.custom');
      }
      return endDate;
    })
    .messages({
      ...requiredInteger.messages,
      'any.custom': i18n.t('end_date_should_be_later_than_start_date', {
        ns: i18nNS.VALIDATION,
      }),
    }),
});
