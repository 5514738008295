import { Draft } from '@reduxjs/toolkit';

import { API } from '../../shared/api-responses';
import { createCommentFactory, getIsSameCommentContext, joinCommentList } from '../helpers';
import { CommentContextData, CommentsState } from '../types';

export function addCommentCaseReducer({
  comment,
  commentsById,
  context,
  currentContext,
  order,
}: {
  comment: API.Comment;
  commentsById: Draft<CommentsState['byId']>;
  context: CommentContextData;
  currentContext: Draft<CommentContextData | null>;
  order: Draft<CommentsState['order']>;
}) {
  /** If pusher has already added the comment then ignore */
  if (commentsById[comment._id]) return order;

  /** Ignore since current comment context has been changed */
  if (!getIsSameCommentContext(currentContext, context)) return order;

  commentsById[comment._id] = createCommentFactory({
    comment,
    isFirstCommentInGroup: false, // assuming it is not first in group, will be updated by joinCommentList()
  });

  // Add comment to same comment-group if it satisfies all contraints
  return joinCommentList({
    commentsById: commentsById,
    list1: order,
    list2: [comment._id],
  });
}
