import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { joiResolver } from '@hookform/resolvers/joi';

import { SignupParams } from '../../pages/routes';
import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { checkSignupEmail } from '../store/actions';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export enum VALIDATION_TYPES {
  EMAIL_ALREADY_EXISTS = 'emailAlreadyExists',
}

const useSignupEmailVM = () => {
  const requestDispatch = useRequestDispatch();

  const { role } = useParams<SignupParams>();

  const { email, setEmail, setMarketing, setUniversityName, setScreen } = useContext(AuthContext);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      emailId: '',
      marketing: false,
    },
    resolver: joiResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { setError, getValues, setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setEmail(data.emailId);
    setMarketing(data.marketing);

    const response = await requestDispatch(checkSignupEmail, { emailId: data.emailId });

    switch (response.next) {
      case NextScreen.GET_ROLE_UE:
        if (role === 'student') setScreen(AuthScreen.JOIN_CODE);
        else {
          setUniversityName(response.univName);
          setScreen(AuthScreen.CREATE_TRIAL_ACCOUNT);
        }
        break;
      case NextScreen.GET_ROLE_UNE:
        if (role === 'student') setScreen(AuthScreen.JOIN_CODE);
        else setScreen(AuthScreen.INSTRUCTOR_VERIFICATION);
        break;
      case NextScreen.VERIFICATION_AWAITED:
        setScreen(AuthScreen.VERIFICATION_AWAITED);
        break;
      default:
        /** not setting error message intentionally to render custom jsx on this error */
        setError('emailId', { type: VALIDATION_TYPES.EMAIL_ALREADY_EXISTS }, { shouldFocus: true });
        break;
    }
  };

  const handleEmailBlur = () => {
    const { emailId } = getValues();
    setEmail(emailId);
  };

  useEffect(
    function setInitialEmail() {
      setValue('emailId', email);
    },
    [email, setValue]
  );

  return {
    isStudent: role === 'student',
    formMethods,
    onSubmit,
    handleEmailBlur,
  };
};

export default useSignupEmailVM;
