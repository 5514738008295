import { useEffect, useState } from 'react';

import { deleteCourses, fetchAdminCourses } from '../../db/courses/actions';
import { API } from '../../db/shared/api-responses';
import { useRequestDispatch } from '../../utils/request-actions';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useDeleteCoursesDrawerVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const [isFetchingCourses, setIsFetchingCourses] = useState(false);
  const [courses, setCourses] = useState<API.GetAdminCoursesResponse['courseData']>([]);

  const handleDeleteCourse = (courseId: MongoId) => async () => {
    await requestDispatch(deleteCourses, { courseId });
    setCourses((prevCourses) => prevCourses.filter((course) => course._id !== courseId));
  };

  useEffect(
    function getCourses() {
      if (!open) return;
      setIsFetchingCourses(true);
      requestDispatch(fetchAdminCourses)
        .then(({ courseData }) => {
          setCourses(courseData);
        })
        .finally(() => {
          setIsFetchingCourses(false);
        });
    },
    [open, requestDispatch]
  );

  return {
    isFetchingCourses,
    courses,
    handleDeleteCourse,
  };
};

export default useDeleteCoursesDrawerVM;
