import { ButtonGroup as MuiButtonGroup, styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../components/Button';
import CloseIcon from '../../../icons/CloseIcon';
import { generateClasses } from '../../../utils/helpers';

export const newCommentsChipClasses = {
  ...generateClasses('NewCommentsChip', ['newMessagesButton', 'closeButton']),
};

export const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  borderRadius: 9999,
  overflow: 'hidden',
}));

export const NewMessagesButton = styled((props: ButtonProps) => (
  <Button
    {...props}
    variant="contained"
    color="primary"
    className={clsx(newCommentsChipClasses.newMessagesButton, props.className)}
  />
))(({ theme }) => ({
  [`&.${newCommentsChipClasses.newMessagesButton}`]: {
    padding: theme.spacing(1, 4),
    textTransform: 'none',
    minWidth: 'unset',
    minHeight: 'unset',
    whiteSpace: 'nowrap',
  },
}));

export const CloseButton = styled((props: ButtonProps) => (
  <Button
    {...props}
    variant="contained"
    color="primary"
    className={clsx(newCommentsChipClasses.closeButton, props.className)}
  />
))(({ theme }) => ({
  [`&.${newCommentsChipClasses.closeButton}`]: {
    padding: theme.spacing(1, 2),
    minWidth: 'unset',
    minHeight: 'unset',
  },
}));

export const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
}));
