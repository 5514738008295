import { RenderElementProps } from 'slate-react';

import FormulaElement from './plugins/formula/FormulaElement';
import ImageElement from './plugins/image/ImageElement';
import ParagraphElement from './plugins/paragraph/ParagraphElement';

interface Props extends RenderElementProps {}

const ElementNode = ({ element, ...props }: Props) => {
  switch (element.type) {
    case 'paragraph':
      return <ParagraphElement {...props} element={element} />;
    case 'image':
      return <ImageElement {...props} element={element} />;
    case 'formula':
      return <FormulaElement {...props} element={element} />;
    default:
      return <div {...props.attributes}>{props.children}</div>;
  }
};

export default ElementNode;
