import { useTranslation } from 'react-i18next';

import { Divider, Stack, styled, Typography } from '@mui/material';

import clsx from 'clsx';

import { ReactComponent as ZoomUsIcon } from '../../../../assets/zoom-us-round.svg';
import Tag from '../../../../components/Tag/index';
import UserAvatar from '../../../../components/UserAvatar/index';
import { i18nNS } from '../../../../i18n';
import CalendarIcon from '../../../../icons/CalendarIcon';
import { generateClasses } from '../../../../utils/helpers';
import useCoursePreviewVM from './vm';

const coursePreviewClasses = generateClasses('CoursePreviewClasses', [
  'root',
  'header',
  'code',
  'title',
  'creator',
  'avatar',
  'footer',
]);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: `linear-gradient(180deg, ${theme.palette.common.white} 0%, ${theme.palette.grey[50]} 100%);`,
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,

  [`.${coursePreviewClasses.header}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    padding: theme.spacing(3.5, 5),
  },

  [`.${coursePreviewClasses.code}`]: {
    color: theme.palette.primary[900],
  },

  [`.${coursePreviewClasses.title}`]: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 5),
    color: theme.palette.primary[900],
  },

  [`.${coursePreviewClasses.creator}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2.5),
    padding: theme.spacing(3, 5),
    color: theme.palette.grey[700],
  },

  [`.${coursePreviewClasses.avatar}`]: {
    width: 24,
    height: 24,
  },

  [`.${coursePreviewClasses.footer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3.5, 5),
  },
}));

interface Props {
  className?: string;
}

const CoursePreview = ({ className }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);
  const { avatar, code, isPro, schedule, title } = useCoursePreviewVM();
  return (
    <Root className={clsx(coursePreviewClasses.root, className)}>
      <div className={coursePreviewClasses.header}>
        {isPro && <ZoomUsIcon />}
        <Typography className={coursePreviewClasses.code} component="span" variant="h6Medium">
          {code}
        </Typography>
      </div>
      <Divider />
      <Typography className={coursePreviewClasses.title} component="div" variant="h5Bold">
        {title}
      </Typography>
      <div className={coursePreviewClasses.creator}>
        <UserAvatar className={coursePreviewClasses.avatar} avatarId={avatar} />
        <Typography color="inherit" variant="h6Medium" component="span">
          {t('you', { ns: i18nNS.GLOSSARY })}
        </Typography>
      </div>
      <Divider />
      <div className={coursePreviewClasses.footer}>
        <Stack direction="row" alignItems="center" gap={1}>
          <CalendarIcon color="primary" />
          <Typography component="span">{schedule}</Typography>
        </Stack>
        <Tag color="success" label={t('admin', { ns: i18nNS.GLOSSARY })} />
      </div>
    </Root>
  );
};
export default CoursePreview;
