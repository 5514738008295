import { Stack, StackProps, styled } from '@mui/material';

import { generateClasses } from '../../../../../../../../../utils/helpers';

export const commentActionsClasses = {
  ...generateClasses('CommentActions', ['hasDivider']),
};

export const Root = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="flex-start" alignItems="stretch" gap={4} {...props} />
))(({ theme }) => ({
  [`& .${commentActionsClasses.hasDivider}`]: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: theme.spacing(-1.5),
      width: 1,
      backgroundColor: theme.palette.grey[200],
    },
  },
}));
