import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { checkLoginEmail } from '../store/actions';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export enum VALIDATION_TYPES {
  EMAIL_NOT_FOUND = 'emailNotFound',
}

const useLoginEmailVM = () => {
  const requestDispatch = useRequestDispatch();

  const { email, setEmail, setScreen, setSsoCredentials } = useContext(AuthContext);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      emailId: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'onSubmit',
  });

  const { setError, getValues, setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setEmail(data.emailId);
    const response = await requestDispatch(checkLoginEmail, { emailId: data.emailId });

    switch (response.next) {
      case NextScreen.SSO: {
        const { authUrl, sessionId } = response;
        setSsoCredentials({ authUrl, sessionId });
        setScreen(AuthScreen.SSO);
        break;
      }
      case NextScreen.GET_PASSWORD:
        setScreen(AuthScreen.PASSWORD);
        break;
      case NextScreen.PASSWORD_NOT_SET:
        setScreen(AuthScreen.VERIFY_EMAIL);
        break;
      case NextScreen.VERIFICATION_AWAITED:
        setScreen(AuthScreen.VERIFICATION_AWAITED);
        break;
      default:
        /** not setting error message intentionally to render custom jsx on this error */
        setError('emailId', { type: VALIDATION_TYPES.EMAIL_NOT_FOUND }, { shouldFocus: true });
        break;
    }
  };

  const handleEmailBlur = () => {
    const { emailId } = getValues();
    setEmail(emailId);
  };

  useEffect(
    function setInitialValues() {
      setValue('emailId', email);
    },
    [email, setValue]
  );

  return {
    formMethods,
    onSubmit,
    handleEmailBlur,
  };
};

export default useLoginEmailVM;
