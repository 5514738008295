import { Dictionary } from '@reduxjs/toolkit';

import { ClassRole, CourseUser, MeetingStatus, RecordingFile, RecordingStatus } from '../shared/types';

export enum MeetingType {
  ZOOM = 'zoom',
}

interface MeetingParticipant extends CourseUser {
  hasRaisedHand: boolean;
  /** `-1` for {@link MeetingParticipant.hasRaisedHand hasRaisedHand} === false */
  handRaisedAt: UnixTime;
  beingAddressed: boolean;
}

interface BaseOnlineMeeting {
  type: MeetingType;
  classId: MongoId;
  courseId: MongoId;
  /**
   * url to send close meeting request when user leaves
   * the page by reloading or closing the tab
   */
  closeUrl: string;
  myClassRole: ClassRole;
  participantsById: Dictionary<MeetingParticipant>;
  status: MeetingStatus;
}

export interface ZoomParticipant extends MeetingParticipant {
  zoomUserId: ZoomId;
}

interface ZoomMeeting extends BaseOnlineMeeting {
  type: MeetingType.ZOOM;
  /**
   * `0` for non class in-charge if meeting not created, `0` for others
   * if not being broadcast
   */
  meetingId: ZoomId;
  /**
   * Whether the current Acadly user has authorized Acadly to create zoom
   * meetings on behalf of them.
   *
   * Useful for class in-charge only.
   */
  hasAuthorizedAcadly: boolean;
  /**
   * Used when `meetingStarted` pusher event is received. If set to
   * `true`, then the user will be automatically joined in the meeting
   */
  canAutoJoinMeeting: boolean;
  /**
   * Auth redirect url for authorization if user has not authorized Acadly.
   *
   * Useful for class in-charge only.
   */
  authorizationURL: string;
  /**
   * Useful for class in-charge only.
   */
  isReadyToBroadcast: boolean;
  /**
   * Will be true if meeting is being broadcasted on Acadly by class in-charge.
   */
  isBroadcasting: boolean;
  /**
   * Will be used by instructor only, not received from server
   */
  isBroadcastedFromCurrentDevice: boolean;
  /**
   * Use this while joining the meeting being broadcast.
   *
   * Useful for non-charge only.
   */
  joinId: string;
  /**
   * Use this while joining the meeting being broadcast.
   *
   * Useful for non-charge only.
   */
  password: string;
  participantsById: Dictionary<ZoomParticipant>;
  /**
   * Acadly userId of the meeting host
   * Since we have multiple incharges, hostUserId is needed to identify which incharge is meeting host
   */
  hostUserId: MongoId;
  /**
   * Details of the meeting host
   * Since we have multiple incharges, these details are needed to identify which incharge is meeting host
   */
  hostDetails: {
    userId: MongoId;
    name: string;
    avatar: string;
    role: ClassRole.INCHARGE;
  };
}

export type OnlineMeeting = ZoomMeeting; // add pipe-separated types here for other meeting types like jitsi etc

export interface RecordConfig {
  /**
   * Whether cloud recording is allowed or not. Zoom users who have purchased
   * zoom's pro plan have this facility.
   */
  isAllowed: boolean;
  /**
   * Whether acadly user has allowed to auto record meeting when created through
   * acadly's server.
   */
  autoRecord: boolean;
}

export interface MeetingRecording {
  recordingId: MongoId;
  meetingName: string;
  filesByName: Dictionary<RecordingFile>;
  createdAt: UnixTime;
}

export interface ClassRecordingData {
  hasViewed: boolean;
  isPublished: boolean;
  /** recordingsAvailable shows number of recording available */
  recordingsAvailable: number;
  status: RecordingStatus;
}

export interface ClassRecordings {
  /** null means the recordings data is yet to be fetched */
  byId: Dictionary<MeetingRecording> | null;
  config: RecordConfig;
  data: ClassRecordingData | null;
}

export interface ZoomAuthorizationData {
  isAllowed: boolean;
  /** not used anywhere */
  notAllowedMessage: string;
  hasProCourses: boolean;
  isAuthorized: boolean;
  authorizationUrl: string;
  revokeUrl: string;
}

export interface OnlineMeetings {
  zoomAuthorizationData: ZoomAuthorizationData | undefined;
  byClassId: Dictionary<OnlineMeeting>;
  recordingsByClassId: Dictionary<ClassRecordings>;
}
