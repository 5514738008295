import { FocusEventHandler } from 'react';
import { FieldValues, Path, PathValue, useController, UseControllerProps } from 'react-hook-form';

import { SelectProps } from '@mui/material';

import AcadlySelect, { Props as AcadlySelectProps } from '../FormControls/Select';

type VMProps<T extends FieldValues> = UseControllerProps<T> & {
  onBlur?: SelectProps['onBlur'];
  onChange?: (value: PathValue<T, Path<T>>) => void;
};

const useSelectVM = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  onBlur: _onBlur,
  onChange: _onChange,
}: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });

  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onBlur();
    _onBlur?.(event);
  };

  const handleChange: SelectProps['onChange'] = (event) => {
    onChange(event);
    _onChange?.(event.target.value as PathValue<T, Path<T>>);
  };

  return { error, handleBlur, handleChange, inputRef, value };
};

type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlySelectProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onBlur' | 'onChange'>;

const Select = <T extends FieldValues>({
  control,
  defaultValue,
  helperText,
  name,
  onBlur,
  onChange,
  ...props
}: Props<T>) => {
  const { error, handleBlur, handleChange, inputRef, value } = useSelectVM({
    control,
    defaultValue,
    name,
    onBlur,
    onChange,
  });

  return (
    <AcadlySelect
      {...props}
      inputRef={inputRef}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default Select;
