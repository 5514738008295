import { FC } from 'react';

import { useMediaQuery } from '@mui/material';

import { forDesktop } from '../utils/media-queries';

const ForNonDesktop: FC = ({ children }) => {
  const isDesktop = useMediaQuery(forDesktop);
  if (isDesktop) return null;
  return <>{children}</>;
};

export default ForNonDesktop;
