import { useMemo } from 'react';

import { v4 as uuid } from 'uuid';

import { randomStr } from '../../../utils/helpers';
import { FaqQuestion } from '../types';

interface UseFaqSectionVMProps {
  questions: FaqQuestion[];
}

export function useFaqSectionVM({ questions }: UseFaqSectionVMProps) {
  return useMemo(() => {
    const questionsWithId = questions.map((question) => {
      const id = uuid();
      return {
        ...question,
        id,
      };
    });
    const id = randomStr(6);
    const headerId = `${id}-header`;
    const contentId = `${id}-content`;
    return { headerId, contentId, questions: questionsWithId };
  }, []);
}
