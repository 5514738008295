import { useTranslation } from 'react-i18next';

import { MenuItem, Stack, useMediaQuery } from '@mui/material';

import Select from '../../components/HookFormControls/Select';
import TextField from '../../components/HookFormControls/TextField';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import { organizations } from './constants';
import { BackButton, NextButton } from './styles';
import useInstructorVerificationVM from './vm';

const InstructorVerification = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { formMethods, onSubmit, useCases, handleBack } = useInstructorVerificationVM();
  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Layout
      title={t('instructor_verification_required', { ns: i18nNS.AUTH })}
      subtitle={t(
        'instructors_can_use_acadly_after_being_verified_please_provide_us_with_the_following_details_to_activate_your_account_within_the_next_24_hours',
        { ns: i18nNS.AUTH }
      )}
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={4}>
          <TextField
            control={control}
            name="fullName"
            label={t('full_name', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('enter_your_full_name', { ns: i18nNS.AUTH })}
            fullWidth
            autoFocus
          />
          <TextField
            control={control}
            name="website"
            label={t('website_optional', { ns: i18nNS.AUTH })}
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('your_official_webpage', { ns: i18nNS.AUTH })}
            fullWidth
          />
          <Select
            control={control}
            name="organization"
            label={t('organization', { ns: i18nNS.GLOSSARY })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('select_your_organization', { ns: i18nNS.AUTH })}
            fullWidth
          >
            {organizations.map((organization) => (
              <MenuItem key={organization.value} value={organization.value}>
                {organization.title}
              </MenuItem>
            ))}
          </Select>
          <Select
            control={control}
            name="useCase"
            label={t('you_want_to_use_acadly_for', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('select_your_use_case', { ns: i18nNS.AUTH })}
            fullWidth
          >
            {useCases.map((useCase) => (
              <MenuItem key={useCase.value} value={useCase.value}>
                {useCase.title}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            onClick={handleBack}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <NextButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_send_your_basic_details_for_verification_as_instructor', {
              ns: i18nNS.AUTH,
            })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </NextButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default InstructorVerification;
