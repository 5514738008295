import { ElementType, forwardRef, Ref } from 'react';

import { Stack, StackProps, styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../components/Button';
import { ZoomMeetingAreaColor } from '../../../styles/colors';
import { generateClasses } from '../../../utils/helpers';

export const zoomIntegrationDialogContentClasses = generateClasses('ZoomIntegrationDialogContentClasses', [
  'addToZoomButton',
]);

export const ZoomLogo = styled('img')(({ theme }) => ({
  alignSelf: 'center',
  width: 110,
}));

export const List = styled(<D extends ElementType>(props: StackProps<D>) => (
  <Stack gap={1} {...props} component="ul" />
))(({ theme }) => ({
  margin: 0,
  padding: 0,
  paddingLeft: theme.spacing(8),
}));

export const AddToZoomButton = styled(
  forwardRef(<C extends ElementType>(props: ButtonProps<C>, ref: Ref<HTMLButtonElement>) => (
    <Button
      size="large"
      variant="contained"
      {...props}
      ref={ref}
      className={clsx(zoomIntegrationDialogContentClasses.addToZoomButton, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${zoomIntegrationDialogContentClasses.addToZoomButton}`]: {
    borderRadius: 100,
    margin: theme.spacing(2.5, 'auto', 2),
    padding: 0,
    backgroundColor: ZoomMeetingAreaColor.BLUE,
  },
}));

export const AddToZoomImage = styled('img')(({ theme }) => ({
  height: theme.spacing(12),
}));
