import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { i18nNS } from '../../../../../i18n';
import ClipboardIcon from '../../../../../icons/ClipboardIcon';
import LogOutIcon from '../../../../../icons/LogOutIcon';
import SettingsIcon from '../../../../../icons/SettingsIcon';
import routes from '../../../../../pages/routes';
import { hamburgerMenuClasses, MenuItems, Popover, StyledTag, StyledUserCell } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  avatar: string;
  name: string;
  universityName: string;
  isTrialAccount: boolean;
  onSettingsClick: () => void;
  onFeedbackClick: () => void;
  onLogoutClick: () => void;
}

const HamburgerMenu = ({
  open,
  onClose: handleClose,
  anchorEl,
  avatar,
  name,
  universityName,
  isTrialAccount,
  onSettingsClick: handleSettingsClick,
  onFeedbackClick: handleFeedbackClick,
  onLogoutClick: handleLogoutClick,
}: Props) => {
  const { t } = useTranslation([i18nNS.SHARED, i18nNS.GLOSSARY]);

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      tabIndex={-1}
    >
      <StyledUserCell
        avatar={avatar}
        title={name}
        subtitle={universityName}
        action={
          isTrialAccount ? (
            <StyledTag variant="outlined" color="warning" label={t('trial_account', { ns: i18nNS.SHARED })} />
          ) : null
        }
        classes={{
          avatar: hamburgerMenuClasses.avatar,
        }}
      />
      <MenuItems role="menu" tabIndex={-1}>
        <MenuItem
          /**
           * FIXME: mui styled swallows component prop, so, using ts-ignore till this is fixed
           * @see https://github.com/mui/material-ui/issues/29875
           */
          // @ts-ignore
          component={Link}
          to={routes.settings.url()}
          onClick={handleSettingsClick}
          tabIndex={0}
          aria-label={t('click_to_navigate_to_settings_page', { ns: i18nNS.SHARED })}
        >
          <ListItemIcon>
            <SettingsIcon color="inherit" fontSize="small" aria-hidden />
          </ListItemIcon>
          <ListItemText>{t('settings', { ns: i18nNS.GLOSSARY })}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleFeedbackClick}
          tabIndex={0}
          aria-label={t('click_to_send_a_message_to_the_acadly_team', { ns: i18nNS.SHARED })}
        >
          <ListItemIcon>
            <ClipboardIcon color="inherit" fontSize="small" aria-hidden />
          </ListItemIcon>
          <ListItemText>{t('feedback', { ns: i18nNS.GLOSSARY })}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleLogoutClick}
          tabIndex={0}
          aria-label={t('click_to_logout', { ns: i18nNS.SHARED })}
        >
          <ListItemIcon>
            <LogOutIcon color="inherit" fontSize="small" aria-hidden />
          </ListItemIcon>
          <ListItemText>{t('logout', { ns: i18nNS.GLOSSARY })}</ListItemText>
        </MenuItem>
      </MenuItems>
    </Popover>
  );
};

export default HamburgerMenu;
