import clsx from 'clsx';

import Actions from './Actions';
import Breadcrumb from './Breadcrumb';
import Hamburger from './Hamburger';
import { Actions as RightActions, AppBar, pageHeaderClasses, Toolbar } from './styles';

interface Props {
  className?: string;
  classes?: Partial<Record<'root' | 'toolbar' | 'navigation' | 'actions', string>>;
}

const PageHeader = ({ className, classes }: Props) => {
  return (
    <AppBar elevation={0} position="relative" className={clsx(className, classes?.root)}>
      <Toolbar className={clsx(pageHeaderClasses.toolbar, classes?.toolbar)} disableGutters>
        <Hamburger />
        <Breadcrumb />
        <RightActions>
          <Actions />
        </RightActions>
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
