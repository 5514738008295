import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { rateComment } from '../../../../../../../../../../db/comments/actions';
import { useRequestDispatch } from '../../../../../../../../../../utils/request-actions';
import { CommentsContext } from '../../../../../../../../../Context';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  commentId: MongoId;
  points: number;
}

const useAwardPointsVM = ({ commentId, points }: Props) => {
  const requestDispatch = useRequestDispatch();

  const { setAwardingCommentId } = useContext(CommentsContext);

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      awardPoints: 0,
    },
    mode: 'all',
  });

  const { setValue } = formMethods;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await requestDispatch(rateComment, {
      commentId: commentId,
      points: data.awardPoints,
    });
    setAwardingCommentId(null);
  };

  useEffect(
    function initFormData() {
      setValue('awardPoints', points);
    },
    [setValue, points]
  );

  const handleCancel = () => {
    setAwardingCommentId(null);
  };

  return { formMethods, onSubmit, handleCancel };
};

export default useAwardPointsVM;
