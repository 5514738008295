import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { AddCourseTopicPayload, DeleteCourseTopicPayload, TopicType } from './types';

export async function addCourseTopic(data: AddCourseTopicPayload) {
  const payload: API.AddCourseTopicRequest = {
    parentId: data.type === TopicType.PARENT ? 'self' : data.parentId,
    books: data.books,
    links: data.links,
    title: data.title,
  };
  return (await request.post<API.AddCourseTopicResponse>(urls.addCourseTopic, payload)).data;
}

export async function deleteCourseTopic(data: DeleteCourseTopicPayload) {
  const payload: API.DeleteCourseTopicRequest = {
    topicId: data.topicId,
    type: data.type === TopicType.PARENT ? 'topic' : 'subTopic',
  };
  await request.delete(urls.deleteCourseTopic, {
    data: payload,
  });
}

export async function editCourseTopic(data: API.EditCourseTopicRequest) {
  await request.post(urls.editCourseTopic, data);
}
