import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import MeetingStatusText from '../MeetingStatusText';
import ZoomAppFooterLayout from '../ZoomAppFooterLayout';
import useStartBroadcastFooterViewVM from './vm';

const StartBroadcastFooterView = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING]);

  const { handleStartBroadcast } = useStartBroadcastFooterViewVM();

  return (
    <ZoomAppFooterLayout
      left={
        <MeetingStatusText variant="paragraphRegular" highlighted>
          {t('not_broadcasting', { ns: i18nNS.ONLINE_MEETING })}
        </MeetingStatusText>
      }
      right={
        <Button variant="contained" color="success" size="small" onClick={handleStartBroadcast}>
          {t('start_broadcasting', { ns: i18nNS.ONLINE_MEETING })}
        </Button>
      }
    />
  );
};

export default StartBroadcastFooterView;
