import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { joiResolver } from '@hookform/resolvers/joi';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { countriesByCode } from '../../../../components/FormControls/CountryPicker/countries';
import { fetchPurchaseCourseIntent, updateCoursePurchaseStatus } from '../../../../db/courses/actions';
import { selectCourse } from '../../../../db/courses/selectors';
import { i18nNS } from '../../../../i18n';
import { CourseParams } from '../../../../pages/routes';
import Logger from '../../../../utils/logger';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { usePaymentWallContext } from '../../Context';
import { PaymentStep } from '../../types';
import { useCoursePaymentContext } from '../Context';
import { formatCurrency } from './helpers';
import { FormValues } from './types';
import { validationSchema } from './validators';

const logger = Logger.create('PurchaseCourse');

export default function usePaymentFormVM() {
  const stripe = useStripe();
  const stipeElements = useElements();

  const { t } = useTranslation([i18nNS.COMMON]);
  const requestDispatch = useRequestDispatch();
  const { courseShortId } = useParams<CourseParams>();

  const course = useSelector(selectCourse(courseShortId));

  const { currentPaymentStep, setCurrentPaymentStep } = usePaymentWallContext();
  const { closeDrawer } = useCoursePaymentContext();

  const isVisible = currentPaymentStep.name === PaymentStep.PAY_VIA_CARD;

  const cost = course ? formatCurrency(course.payment.cost) : '-';

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: '',
      address: '',
      city: '',
      country: countriesByCode['US'],
      state: '',
      zip: '',
      tnc: false,
    },
    mode: 'all',
  });

  const { formState } = formMethods;
  const { errors } = formState;
  const tncError = errors['tnc']?.message;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!stripe || !stipeElements || !course) return;

    const card = stipeElements.getElement('card');

    if (!card) return;

    const defaultErrorMessage = t('something_went_wrong_please_try_again_later', { ns: i18nNS.COMMON });

    try {
      const { clientKey, purchaseId } = await requestDispatch(fetchPurchaseCourseIntent, {
        courseId: course.id,
        purchaseType: 'course',
        customer: {
          name: data.name,
          address: {
            line1: data.address,
            city: data.city,
            country: data.country.code,
            state: data.state,
            zip: data.zip,
          },
        },
      });

      const result = await stripe.confirmCardPayment(clientKey, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card,
        },
      });

      const isFailed = Boolean(result.error);

      await requestDispatch(updateCoursePurchaseStatus, {
        purchaseId,
        courseId: course.id,
        status: isFailed ? 'failed' : 'success',
        errorMessage: result.error?.message,
      });

      if (isFailed) {
        setCurrentPaymentStep({
          name: PaymentStep.PAYMENT_FAILED,
          error: result.error?.message || defaultErrorMessage,
        });
      } else {
        setCurrentPaymentStep({ name: PaymentStep.PAYMENT_SUCCESS });
      }
    } catch (error) {
      logger.error(error);
      setCurrentPaymentStep({
        name: PaymentStep.PAYMENT_FAILED,
        error: defaultErrorMessage,
      });
    }
  };

  return {
    isVisible,
    closeDrawer,
    formMethods,
    cost,
    onSubmit,
    tncError,
  };
}
