import { ZoomParticipant } from '../../../db/online-meeting/types';
import { ClassRole } from '../../../db/shared/types';
import i18n, { i18nNS } from '../../../i18n';

export function getMeetingStatusText(participant: ZoomParticipant | undefined) {
  if (participant?.beingAddressed) return i18n.t('being_addressed', { ns: i18nNS.ONLINE_MEETING });
  return i18n.t('in_meeting', { ns: i18nNS.ONLINE_MEETING });
}

export function getCanRaiseHand(participant: ZoomParticipant | undefined, role = ClassRole.STUDENT) {
  if (!participant) return false;
  if (role !== ClassRole.STUDENT) return false;
  if (participant.beingAddressed) return false;
  return !participant.hasRaisedHand;
}

export function getCanLowerHand(participant: ZoomParticipant | undefined, role = ClassRole.STUDENT) {
  if (!participant) return false;
  if (role !== ClassRole.STUDENT) return false;
  if (participant.beingAddressed) return false;
  return participant.hasRaisedHand;
}

export function getCanLeaveMeeting(participant: ZoomParticipant | undefined) {
  return !participant?.beingAddressed;
}
