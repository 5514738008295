import { CSSProperties } from 'react';

export const ellipsize: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const pointer: CSSProperties = {
  cursor: 'pointer',
};

export const buttonAsDiv: CSSProperties = {
  ...pointer,
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
  fontSize: 'unset',
  textAlign: 'left',
};
