import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../../../../../../../i18n';
import CommentStats from '../CommentStats';
import { commentStatsDialogClasses, StyledDialog } from './styles';
import { VMProps } from './vm';

interface Props extends VMProps {
  open: boolean;
  onClose: () => void;
  commentId: MongoId;
}

const CommentStatsDialog = ({ open, onClose, commentId }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMENTS]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      classes={{ body: commentStatsDialogClasses.dialogContent }}
      fullWidth
      header={t('comment_stats', { ns: i18nNS.COMMENTS })}
    >
      <CommentStats commentId={commentId} />
    </StyledDialog>
  );
};

export default CommentStatsDialog;
