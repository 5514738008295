import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { useCurrentUserWithRole } from '../courses/hooks';
import { selectAssignment } from '../db/assignments/selectors';
import { CourseRole } from '../db/shared/types';
import { i18nNS } from '../i18n';
import routes, { AssignmentParams } from '../pages/routes';
import { getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';
import { getIsAssignmentPublished } from './helpers';

const useAssignmentHeaderActionsVM = () => {
  const params = useParams<AssignmentParams>();

  const assignment = useSelector(selectAssignment(params.assignmentShortId));
  const isAssignmentPublished = getIsAssignmentPublished(assignment);

  const currentUser = useCurrentUserWithRole();

  const numUnseenComments = assignment ? getUnseenComments(assignment) : 0;

  return { params, isAssignmentPublished, currentUser, numUnseenComments };
};

const AssignmentHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.ASSIGNMENT]);

  const { params, isAssignmentPublished, currentUser, numUnseenComments } = useAssignmentHeaderActionsVM();

  return (
    <>
      {currentUser.role !== CourseRole.STUDENT && isAssignmentPublished && (
        <AnalyticsButton
          to={routes.assignmentAnalytics.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_assignment_analytics', { ns: i18nNS.ASSIGNMENT })}
        />
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default AssignmentHeaderActions;
