import { useContext } from 'react';

import { RadioGroupContext } from '../Context';

export interface UseRadioOption {
  disabled?: boolean;
}

const useRadioOption = ({ disabled }: UseRadioOption) => {
  const { canUncheck, value: contextValue, setValue: setContextValue } = useContext(RadioGroupContext);

  const handleClick = (currentValue: string | null) => {
    if (disabled) return;

    setContextValue((prevVal) => {
      if (prevVal === currentValue && canUncheck) return null;
      return currentValue;
    });
  };

  return {
    contextValue,
    handleClick,
  };
};

export default useRadioOption;
