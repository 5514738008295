import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';
import { requiredString } from '../../utils/validators';
import { MIN_PASSWORD_LENGTH } from '../constants';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  password: requiredString.min(MIN_PASSWORD_LENGTH).messages({
    ...requiredString.messages,
    'string.min': i18n.t('this_is_an_invalid_password', { ns: i18nNS.VALIDATION }),
  }),
});
