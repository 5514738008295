import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { useElements, useStripe } from '@stripe/react-stripe-js';

import { countriesByCode } from '../../../components/FormControls/CountryPicker/countries';
import {
  confirmInstructorPaymentStatus,
  fetchCoursePurchaseCost,
  fetchInstructorPurchaseIntent,
} from '../../../db/courses/actions';
import i18n, { i18nNS } from '../../../i18n';
import { formatCurrency } from '../../../utils/currency';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

const useEnterPaymentDetails = () => {
  const requestDispatch = useRequestDispatch();
  const stripe = useStripe();
  const stipeElements = useElements();

  const {
    activeStep,
    setTitle,
    setMobileTitle,
    closeDrawer,
    moveToPreviousStep: _moveToPreviousStep,
    moveToNextStep,
    isCreatingProCourse,
    setIsCreatingProCourse,
    numCourses,
    numStudents,
    setPaymentErrorMessage,
    setPaymentMethodSlotId,
  } = useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.PAYMENT_DETAILS;
  const moveToPreviousStep = () => _moveToPreviousStep();

  const [courseCost, setCourseCost] = useState('');

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      name: '',
      address: '',
      city: '',
      country: countriesByCode['US'],
      state: '',
      zip: '',
      tnc: false,
    },
    mode: 'all',
  });

  const { formState } = formMethods;
  const { errors } = formState;
  const tncError = errors['tnc']?.message;

  const calculateCost = useCallback(async () => {
    try {
      const numOfCourses = parseInt(numCourses, 10);

      const [lowerLimit] = numStudents.split('-');
      const numOfstudents = parseInt(lowerLimit, 10);

      const { cost } = await requestDispatch(fetchCoursePurchaseCost, {
        type: isCreatingProCourse ? 'pro' : 'basic',
        numCourses: numOfCourses,
        numStudents: numOfstudents,
      });

      const formattedCost = formatCurrency(cost);
      setCourseCost(formattedCost);
    } catch (error) {
      console.log(error);
    }
  }, [isCreatingProCourse, numCourses, numStudents, requestDispatch]);

  const makePayment: SubmitHandler<FormValues> = async (data) => {
    if (!stripe || !stipeElements) {
      throw new Error(i18n.t('card_is_required', { ns: i18nNS.COMMON }));
    }

    const card = stipeElements.getElement('card');

    if (!card) return;

    const defaultErrorMessage = i18n.t('something_went_wrong_please_try_again_later', { ns: i18nNS.COMMON });

    try {
      const { name, address, city, country, state, zip } = data;

      const numOfCourses = parseInt(numCourses, 10);

      const [lowerLimit] = numStudents.split('-');
      const numOfstudents = parseInt(lowerLimit, 10);

      const { purchaseId, clientKey } = await requestDispatch(fetchInstructorPurchaseIntent, {
        purchaseType: 'instructorSlots',
        slots: {
          type: isCreatingProCourse ? 'pro' : 'basic',
          numCourses: numOfCourses,
          numStudents: numOfstudents,
        },
        customer: {
          name: name,
          address: {
            line1: address,
            city: city,
            country: country.code,
            state: state,
            zip: zip,
          },
        },
      });

      const result = await stripe.confirmCardPayment(clientKey, {
        // eslint-disable-next-line camelcase
        payment_method: {
          card,
        },
      });

      const isFailed = Boolean(result.error);

      const statusResponse = await requestDispatch(confirmInstructorPaymentStatus, {
        purchaseId,
        status: isFailed ? 'failed' : 'success',
        errorMessage: result.error?.message,
      });

      if (isFailed) {
        setPaymentErrorMessage(result.error?.message || defaultErrorMessage);

        moveToNextStep({ skip: 1 });
      } else {
        const { courseType, slotId } = statusResponse;
        setIsCreatingProCourse(courseType === 'pro');
        setPaymentMethodSlotId(slotId);
        moveToNextStep();
      }
    } catch (error) {
      console.log(error);
      setPaymentErrorMessage(defaultErrorMessage);
      moveToNextStep({ skip: 1 });
    }
  };

  useEffect(() => {
    if (!isVisible) return;
    calculateCost();
  }, [calculateCost, isVisible]);

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('enter_payment_details', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('enter_payment_details', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return {
    formMethods,
    closeDrawer,
    isVisible,
    moveToPreviousStep,
    courseCost,
    makePayment,
    tncError,
  };
};
export default useEnterPaymentDetails;
