import { createEditor, Editor, Selection, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';

import Logger from '../../utils/logger';
import withFormulae from '../plugins/formula/helpers';
import { FormulaEditorOptions } from '../plugins/formula/types';
import withImages from '../plugins/image/helpers';
import { ImageEditorOptions } from '../plugins/image/types';
import withParagraphs from '../plugins/paragraph/helpers';
import serialize from './serialize';

const logger = Logger.create('richtext-editor');

/**
 * Pipes all function calls
 * @param seed initial value
 * @param fn0 first required function which takes initial values and returns a transformed value
 * @param fns optional N number of transform functions wich takes result of previous function call
 */
function pipe<T, R extends T>(seed: T, fn0: (arg: T) => R, ...fns: ((arg: R) => R)[]) {
  let result: R = fn0(seed);

  for (const fn of fns) {
    result = fn.call(fn, result);
  }

  return result;
}

type CreateEditorOptions = ImageEditorOptions & FormulaEditorOptions;

export default function editorFactory({ uploadFormula, uploadImage }: CreateEditorOptions) {
  const editor = createEditor();

  let savedSelection: Selection | null = null;

  editor.getValue = () => serialize(editor.children);

  editor.clear = () => {
    // set to empty paragraph
    editor.children = [
      {
        type: 'paragraph',
        children: [{ type: 'text', text: '' }],
      },
    ];
    // move selection to start position
    Transforms.select(editor, Editor.start(editor, []));
    // trigger change
    editor.onChange();
  };

  editor.saveSelection = () => (savedSelection = editor.selection);

  editor.restoreSelection = (selection = savedSelection) => {
    try {
      ReactEditor.focus(editor);

      if (selection) {
        // move selection to last saved selection
        Transforms.setSelection(editor, selection);
        return;
      }

      // move selection to last position
      Transforms.select(editor, Editor.end(editor, []));
    } catch (error) {
      logger.info('Restore editor selection failed', error);
    }
  };

  return pipe(
    editor,
    withHistory,
    withReact,
    withParagraphs,
    withImages({
      uploadImage,
    }),
    withFormulae({
      uploadFormula,
    })
  );
}
