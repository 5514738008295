import i18n, { i18nNS } from '../../i18n';
import { CourseRole } from '../../types';
import { CourseWidgetData } from '../types';

const getNumPendingQueries = (course: CourseWidgetData | undefined) => {
  if (!course) return 0;
  if (!course.status.isLive) return 0;

  const { activities } = course;
  if (!activities) return 0;

  return activities.queries.pending;
};

const getNumUnseenQueries = (course: CourseWidgetData | undefined) => {
  if (!course) return 0;
  if (!course.status.isLive) return 0;

  const { activities } = course;
  if (!activities) return 0;

  const unseenQueries = activities.queries.total - activities.queries.seen;
  if (unseenQueries <= 0) return 0;

  return unseenQueries;
};

export function getCanSeeQueries(course: CourseWidgetData | undefined) {
  if (!course) return false;

  const isCourseTeam = [CourseRole.ADMIN, CourseRole.INSTRUCTOR, CourseRole.TA].includes(course.myRole);
  if (!isCourseTeam) return false;

  const numPendingQueries = getNumPendingQueries(course);
  const numUnseenQueries = getNumUnseenQueries(course);

  return numPendingQueries > 0 || numUnseenQueries > 0;
}

export function getQueriesCount(course: CourseWidgetData | undefined): number {
  if (!course) return 0;

  const isCourseTeam = [CourseRole.ADMIN, CourseRole.INSTRUCTOR, CourseRole.TA].includes(course.myRole);
  if (!isCourseTeam) return 0;

  const numPendingQueries = getNumPendingQueries(course);
  if (numPendingQueries > 0) return numPendingQueries;

  const numUnseenQueries = getNumUnseenQueries(course);
  if (numUnseenQueries > 0) return numUnseenQueries;

  return 0;
}

export function getQueriesLabel(course: CourseWidgetData | undefined): string | undefined {
  if (!course) return undefined;

  const isCourseTeam = [CourseRole.ADMIN, CourseRole.INSTRUCTOR, CourseRole.TA].includes(course.myRole);
  if (!isCourseTeam) return undefined;

  const numPendingQueries = getNumPendingQueries(course);
  if (numPendingQueries > 0)
    return i18n.t('pending_queries', { ns: i18nNS.COURSES, count: numPendingQueries });

  const numUnseenQueries = getNumUnseenQueries(course);
  if (numUnseenQueries > 0) return i18n.t('new_queries', { ns: i18nNS.COURSES, count: numUnseenQueries });

  return undefined;
}

export function getQueriesTagColor(course: CourseWidgetData | undefined): 'primary' | 'warning' | undefined {
  if (!course) return undefined;

  const isCourseTeam = [CourseRole.ADMIN, CourseRole.INSTRUCTOR, CourseRole.TA].includes(course.myRole);
  if (!isCourseTeam) return undefined;

  const numPendingQueries = getNumPendingQueries(course);
  if (numPendingQueries > 0) return 'warning';

  const numUnseenQueries = getNumUnseenQueries(course);
  if (numUnseenQueries > 0) return 'primary';

  return undefined;
}

export function getNumUnseenActivities(course: CourseWidgetData | undefined) {
  if (!course) return 0;
  if (!course.status.isLive) return 0;

  const { activities } = course;
  if (!activities) return 0;

  const numUnseenActivities = activities.total - activities.seen;
  const numUnseenQueries = getNumUnseenQueries(course);
  return numUnseenActivities + numUnseenQueries;
}

export function getCanSeeActivities(course: CourseWidgetData | undefined) {
  if (!course) return undefined;

  const isStudent = course.myRole === CourseRole.STUDENT;
  if (!isStudent) return undefined;

  const numUnseenActivities = getNumUnseenActivities(course);
  return numUnseenActivities > 0;
}

export function getNumUnseenComments(course: CourseWidgetData | undefined) {
  if (!course) return 0;
  if (!course.status.isLive) return 0;

  const { comments } = course;
  if (!comments) return 0;

  return comments.total - comments.seen;
}

export function getCanSeeComments(course: CourseWidgetData | undefined) {
  const numUnseenComments = getNumUnseenComments(course);
  return numUnseenComments > 0;
}
