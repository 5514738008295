import { CommentContext } from '../../../../../db/shared/types';
import i18n, { i18nNS } from '../../../../../i18n';

export const getExportDialogTitle = (commentContext: CommentContext | undefined) => {
  switch (commentContext) {
    case CommentContext.COURSE:
      return i18n.t('export_course_discussion_data_as', { ns: i18nNS.COURSES });
    case CommentContext.ASSIGNMENT:
      return i18n.t('export_assignment_discussion_data_as', {
        ns: i18nNS.ASSIGNMENT,
      });
    case CommentContext.CLASS:
      return i18n.t('export_class_discussion_data_as', { ns: i18nNS.CLASS });
    case CommentContext.QUIZ:
      return i18n.t('export_quiz_discussion_data_as', { ns: i18nNS.QUIZ });
    case CommentContext.POLL:
      return i18n.t('export_poll_discussion_data_as', { ns: i18nNS.POLL });
    case CommentContext.DISCUSSION:
      return i18n.t('export_discussion_data_as', { ns: i18nNS.DISCUSSION });
    case CommentContext.RESOURCE:
      return i18n.t('export_resource_discussion_data_as', {
        ns: i18nNS.RESOURCE,
      });
    case CommentContext.QUERY:
      return i18n.t('export_query_discussion_data_as', { ns: i18nNS.QUERY });
    default:
      return i18n.t('export', { ns: i18nNS.GLOSSARY });
  }
};
