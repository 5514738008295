import { Draft } from '@reduxjs/toolkit';

import { insertByBinarySearch } from '../../../utils/helpers';
import { API } from '../../shared/api-responses';
import { TimelineItemType } from '../../shared/types';
import { timelineComparer } from '../helpers';
import { Course, TimelineItem } from '../types';

export function addAnnouncementCaseReducer({
  announcementDetails,
  announcementId,
  course,
}: {
  announcementDetails: API.AnnouncementDetails;
  announcementId: MongoId;
  course: Draft<Course | undefined>;
}) {
  if (!course) return;

  if (!course.timeline) {
    course.activities.announcements.total++;
    course.activities.announcements.published++;
    return;
  }

  const existingAnnouncement = course.timeline.find((item) => item.id === announcementId);
  if (existingAnnouncement) return; // item already added

  course.activities.announcements.total++;
  course.activities.announcements.published++;

  const timelineItem: TimelineItem = {
    id: announcementId,
    type: TimelineItemType.ANNOUNCEMENT,
    dueDateTime: announcementDetails.dueDateTime,
  };

  insertByBinarySearch(timelineItem, course.timeline, timelineComparer);
}
