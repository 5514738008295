import { lib } from 'crypto-js';

/**
 * Executes a promise function and retries if failed
 * @param fn async/promise function
 * @param retriesLeft number of retries including the first attempt
 * @param delay retry delay
 */
export async function retryPromise<T>(fn: (...args: any[]) => Promise<T>, retriesLeft = 3, delay = 1000) {
  return new Promise<T>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft <= 1) {
            // maximum retries exceeded
            reject(error);
            return;
          }

          // try again
          retryPromise(fn, retriesLeft - 1, delay).then(resolve, reject);
        }, delay);
      });
  });
}

export const SHORT_ID_LENGTH = 8;

/**
 * Last 8 charcters of a mongodb id
 * @param id mongodb id
 */
export function toShortID(id: MongoId) {
  return id ? id.slice(-SHORT_ID_LENGTH) : '';
}

/**
 * Helper function to mimic for-in loop in functional way. Iterates over
 * all owner keys of `obj` and map with returned value of `mapper()`
 * @param obj object to be transformed
 * @param mapper callback to map values
 */
export function forIn<T extends object, U extends { [key in keyof T]: unknown }>(
  obj: T,
  mapper: <K extends keyof T>(value: T[K], key: K) => U[K]
) {
  const result = {} as U;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = mapper(obj[key], key);
    }
  }

  return result;
}

/**
 * Helper function to mimic `Array.prototype.map()` for objects.
 * @param obj object to be mapped
 * @param mapper callback to map each value of keys
 *
 * @example
 * ```ts
 * const obj = { a: 1, b: 2, c: 3 };
 * const result = map(obj, (v) => v * v);
 * console.log(result); // { a: 1, b: 4, c: 9 }
 * ```
 */
export function map<T extends object, U>(
  obj: T,
  mapper: <K extends keyof T>(value: T[K], key: K) => U
): Record<keyof T, U> {
  return forIn<T, Record<keyof T, U>>(obj, mapper);
}

/**
 * Helper function to mimic `Array.prototype.reduce()` for objects.
 * @param obj object to be reduced
 * @param reducer callback to reduce object
 * @param initialValue initial value
 *
 * @example
 * ```ts
 * const obj = { a: 1, b: 2, c: 3 };
 * const sum = reduce(obj, (result, v) => result += v, 0);
 * console.log(sum); // 6
 * ```
 */
export function reduce<T extends Record<string, Primitive>, U>(
  obj: T,
  reducer: (result: U, value: T[keyof T], key: keyof T) => U,
  initialValue: U
) {
  let result = initialValue;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result = reducer(result, obj[key], key);
    }
  }

  return result;
}

export function getWindowDimensions() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  return { height, width };
}

/**
 * Throttles callbacks by specified delay
 * @param callback function to be called
 * @param delay time in milli-seconds to delay before calling next time
 * @see [source](https://programmingwithmosh.com/javascript/javascript-throttle-and-debounce-patterns/)
 */
export function throttle<A extends any[]>(callback: (...args: A) => void, delay = 100) {
  let enableCall = true;

  return function <T>(this: T, ...args: A) {
    if (!enableCall) return;

    enableCall = false;
    callback.apply(this, args);
    setTimeout(() => (enableCall = true), delay);
  };
}

/**
 * Waits to call a callback for specified delay, if callback is called
 * multiple times then only lastest call is used rest are ignored
 * @param callback function to be called
 * @param delay time in milli-seconds to wait before calling
 * @see [source](https://programmingwithmosh.com/javascript/javascript-throttle-and-debounce-patterns/)
 */
export function debounce<A extends any[]>(callback: (...args: A) => void, delay = 100) {
  let debounceTimeoutId: NodeJS.Timeout;

  return function <T>(this: T, ...args: A) {
    clearTimeout(debounceTimeoutId);
    debounceTimeoutId = setTimeout(() => callback.apply(this, args), delay);
  };
}

/**
 * Generates a random string of provided length
 * @param length length of string to be generated
 * @returns random string
 */
export function randomStr(length = 8) {
  return lib.WordArray.random(length).toString();
}

/**
 * Generates a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 * @param min minimum (inclusive) possible value
 * @param max maximum (inclusive) possible value
 * @see https://stackoverflow.com/a/1527820/9709887
 */
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Groups items of an array based on iterator callback result.
 *
 * **Note:** `shouldGroupIterator()` is not called for the first item of the array.
 *
 * @param arr array to be grouped
 * @param shouldGroupIterator iterator callback, should return truthy value to push the item to current group
 * @returns array of groups
 *
 * @example
 * const a = [1, 2, 3, 4, 5, 6];
 * console.log(groupBy(a, item => item % 2)); // [[1], [2, 3], [4, 5]]
 */
export function groupBy<T>(
  arr: T[],
  shouldGroupIterator: (item: T, index: number, currentGroup: T[]) => boolean
) {
  const groups: T[][] = [];

  arr.forEach((item, index) => {
    const lastIndex = groups.length - 1;
    if (groups.length && shouldGroupIterator(item, index, groups[lastIndex])) {
      const list = groups[groups.length - 1];
      list.push(item);
    } else {
      groups.push([item]);
    }
  });

  return groups;
}

/**
 * Checks if an element is scrolled to bottom or not
 * @param target DOM element reference
 * @param threshold threshold helps to consider computing scrolltop earlier than reaching 0
 * @param reversed Are elements of target rendered in reversed order?
 * @returns boolean, true when the target element is scrolled to bottom
 */
export function checkIfScrolledToBottom(target: HTMLElement | null, threshold = 0, reversed?: boolean) {
  if (!target) return false;
  const { clientHeight, scrollHeight, scrollTop } = target;

  /**
   * when a div is rendered with flexDirection: column-reverse,
   * it's scrollbar behaves differently than normal use case.
   *
   * When scrollTop is 0, it indicates that scrollbar is at the bottom
   * Otherwise scrollTop is negative.
   */

  if (reversed) return scrollTop >= 0 - threshold;

  return scrollTop + clientHeight >= scrollHeight + threshold;
}

/**
 * scrolls to bottom
 * @param target DOM element reference
 * @param reversed Are elements of target rendered in reverse order?
 * @returns void
 */
export function scrollToBottom(target: HTMLElement | null, reversed?: boolean) {
  if (!target) return;

  /**
   * when a div is rendered with flexDirection: column-reverse,
   * it's scrollbar behaves differently than normal use case.
   *
   * When scrollTop is 0, it indicates that scrollbar is at the bottom
   * Otherwise scrollTop is negative.
   */
  if (reversed) {
    target.scrollTop = 0;
    return;
  }

  target.scrollTop = target.scrollHeight;
}

/**
 * returns if scrollbar is present
 * @param target DOM element reference
 * @returns boolean, true when scrollbar is present
 */
export function getHasScrollbar(target: HTMLElement | null) {
  if (!target) return false;
  return target.scrollHeight > target.clientHeight;
}

/**
 * finds a parent recursively till it finds a parent that has scrollbar
 * @param target DOM element reference
 * @returns DOM element reference that has scrollbar
 */
export function getScrollParent(target: HTMLElement | null): HTMLElement | null {
  if (!target) return null;

  const hasScrollbar = getHasScrollbar(target);
  if (hasScrollbar) return target;

  return getScrollParent(target.parentElement);
}

/**
 * Finds the insert position in a sorted array using the binary search technique
 * @param element element to search
 * @param array sorted array
 * @param comparer element comparer callback
 * @param start start index
 * @param end end index
 * @returns index where the element should be inserted
 */
export function findPositionByBinarySearch<T>(
  element: T,
  array: T[],
  comparer: (a: T, b: T) => number,
  start = 0,
  end = array.length
): number {
  if (array.length === 0) return 0;

  const pivot = (start + end) >> 1; // divide by 2
  const c = comparer(element, array[pivot]);

  if (end - start <= 1) return c < 0 ? pivot - 1 : pivot;

  if (c < 0) return findPositionByBinarySearch(element, array, comparer, start, pivot);

  if (c > 0) return findPositionByBinarySearch(element, array, comparer, pivot, end);

  return pivot;
}

/**
 * Inserts an element into a sorted array by mutating them
 * @param element element to be inserted
 * @param array sorted array
 * @param comparer element comparer callback
 * @returns reference of modified array
 */
export function insertByBinarySearch<T>(element: T, array: T[], comparer: (a: T, b: T) => number) {
  const index = findPositionByBinarySearch(element, array, comparer);
  array.splice(index + 1, 0, element);
  return array;
}

/**
 * Formats fractional numbers to fixed decimal places.
 * It does NOT formats integer numbers.
 * @param num number to be formatted
 * @param decimals fixed of decimals to show
 * @param suffix suffix to append in formatted number string
 * @returns formatted number string
 *
 * @example
 * formatFractions(4) // returns '4'
 * formatFractions(4.4445) // returns '4.44'
 * formatFractions(4.4555) // returns '4.46'
 * formatFractions(4.1) // returns '4.10'
 * formatFractions(4.1, '%') // returns '4.10%'
 */
export function formatFractions(num: number, suffix = '', decimals = 2) {
  const formattedNumber = Number.isInteger(num) ? num.toString() : num.toFixed(decimals);
  return formattedNumber + suffix;
}

/**
 * Converts Index to character code using (64 + index + 1)
 * and then converts character code to character
 * @param index 0 based array index
 * @returns Alphabet or null
 *
 * @example
 * getAlphabetFromIndex(2) // returns 'C'
 * getAlphabetFromIndex(0) // returns 'A'
 * getAlphabetFromIndex(-5) // returns null
 * getAlphabetFromIndex(100) // returns null
 */
export function getAlphabetFromIndex(index: number) {
  if (index < 0 || index > 25) return null;
  return String.fromCharCode(64 + index + 1);
}

/**
 * removes specified keys from object
 *
 * @see https://stackoverflow.com/a/67434028/2284240
 */
export function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]) {
  for (const key of keys) {
    delete obj[key];
  }
  return obj as unknown as DistributiveOmit<T, K>;
}

export function pick<T extends {}>(obj: T, ...keys: (keyof T)[]) {
  const result = {} as Pick<T, keyof T>;
  for (const key of keys) result[key] = obj[key];
  return result;
}

export function generateClasses<T extends string>(componentName: string, classes: T[]) {
  return classes.reduce((acc, cls) => {
    acc[cls] = `Acadly${componentName}-${cls}`;
    return acc;
  }, {} as Record<T, string>);
}
