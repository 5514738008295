import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const Trash2Icon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21 5.99997H16V4.32997C15.9765 3.68979 15.7002 3.08503 15.2316 2.64827C14.7629 2.2115 14.1402 1.97837 13.5 1.99997H10.5C9.85975 1.97837 9.23706 2.2115 8.76843 2.64827C8.2998 3.08503 8.02346 3.68979 8 4.32997V5.99997H3C2.73478 5.99997 2.48043 6.10533 2.29289 6.29287C2.10536 6.4804 2 6.73476 2 6.99997C2 7.26519 2.10536 7.51954 2.29289 7.70708C2.48043 7.89462 2.73478 7.99997 3 7.99997H4V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V7.99997H21C21.2652 7.99997 21.5196 7.89462 21.7071 7.70708C21.8946 7.51954 22 7.26519 22 6.99997C22 6.73476 21.8946 6.4804 21.7071 6.29287C21.5196 6.10533 21.2652 5.99997 21 5.99997ZM10 16C10 16.2652 9.89464 16.5195 9.70711 16.7071C9.51957 16.8946 9.26522 17 9 17C8.73478 17 8.48043 16.8946 8.29289 16.7071C8.10536 16.5195 8 16.2652 8 16V12C8 11.7348 8.10536 11.4804 8.29289 11.2929C8.48043 11.1053 8.73478 11 9 11C9.26522 11 9.51957 11.1053 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12V16ZM10 4.32997C10 4.16997 10.21 3.99997 10.5 3.99997H13.5C13.79 3.99997 14 4.16997 14 4.32997V5.99997H10V4.32997ZM16 16C16 16.2652 15.8946 16.5195 15.7071 16.7071C15.5196 16.8946 15.2652 17 15 17C14.7348 17 14.4804 16.8946 14.2929 16.7071C14.1054 16.5195 14 16.2652 14 16V12C14 11.7348 14.1054 11.4804 14.2929 11.2929C14.4804 11.1053 14.7348 11 15 11C15.2652 11 15.5196 11.1053 15.7071 11.2929C15.8946 11.4804 16 11.7348 16 12V16Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 6.00001H16V4.33001C15.9765 3.68982 15.7002 3.08506 15.2316 2.6483C14.7629 2.21153 14.1402 1.9784 13.5 2.00001H10.5C9.85975 1.9784 9.23706 2.21153 8.76843 2.6483C8.2998 3.08506 8.02346 3.68982 8 4.33001V6.00001H3C2.73478 6.00001 2.48043 6.10536 2.29289 6.2929C2.10536 6.48043 2 6.73479 2 7.00001C2 7.26522 2.10536 7.51958 2.29289 7.70711C2.48043 7.89465 2.73478 8.00001 3 8.00001H4V19C4 19.7957 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7957 20 19V8.00001H21C21.2652 8.00001 21.5196 7.89465 21.7071 7.70711C21.8946 7.51958 22 7.26522 22 7.00001C22 6.73479 21.8946 6.48043 21.7071 6.2929C21.5196 6.10536 21.2652 6.00001 21 6.00001ZM10 4.33001C10 4.17001 10.21 4.00001 10.5 4.00001H13.5C13.79 4.00001 14 4.17001 14 4.33001V6.00001H10V4.33001ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V8.00001H18V19Z"
        fill="currentColor"
      />
      <path
        d="M9 17C9.26522 17 9.51957 16.8946 9.70711 16.7071C9.89464 16.5196 10 16.2652 10 16V12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11C8.73478 11 8.48043 11.1054 8.29289 11.2929C8.10536 11.4804 8 11.7348 8 12V16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17Z"
        fill="currentColor"
      />
      <path
        d="M15 17C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16V12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11C14.7348 11 14.4804 11.1054 14.2929 11.2929C14.1054 11.4804 14 11.7348 14 12V16C14 16.2652 14.1054 16.5196 14.2929 16.7071C14.4804 16.8946 14.7348 17 15 17Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Trash2Icon;
