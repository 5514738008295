import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { addDateTime, format, unix } from '../../../utils/datetime';
import Timezone from '../TimeZone';
import { Cell, CellText, Content } from './styles';
import useDemoCouseDrawerVM, { VMProps } from './vm';

interface Props extends VMProps {}

const DemoCourseDrawer = (vmOptions: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);

  const { handleCloseDrawer, isDemoPro, onSubmit, formMethods, handleChangeTimezone } =
    useDemoCouseDrawerVM(vmOptions);

  const { handleSubmit, control } = formMethods;

  return (
    <Drawer
      anchor="right"
      open={vmOptions.open}
      header={
        isDemoPro
          ? t('acadly_pro_create_free_trial_course', { ns: i18nNS.COURSES })
          : 'Acadly Basic - Create demo course'
      }
      onClose={handleCloseDrawer}
      footer={<Button onClick={handleSubmit(onSubmit)}>{t('create', { ns: i18nNS.GLOSSARY })}</Button>}
    >
      <Content>
        <Typography variant="paragraphRegular" color="grey.800">
          {isDemoPro
            ? t(
                'with_an_acadly_pro_course_you_can_conduct_remote_lectures_using_your_existing_zoom_account_with_acadly_once_you_re_verified_you_ll_be_able_to_create_pro_courses_after_purchasing_slots'
              )
            : t(
                'using_a_7_day_long_demo_course_you_ll_be_able_to_see_how_activities_are_added_to_a_course_and_how_students_respond_you_will_also_be_able_to_record_attendance_using_acadly_s_mobile_apps',
                { ns: i18nNS.COURSES }
              )}
        </Typography>
        <Cell>
          <CellText textTransform="uppercase">{t('course_begins', { ns: i18nNS.COURSES })}</CellText>
          <CellText>{format(unix(), 'MMM dd, yyyy')}</CellText>
        </Cell>
        <Cell>
          <CellText textTransform="uppercase">{t('course_ends', { ns: i18nNS.COURSES })}</CellText>
          <CellText>{format(addDateTime(unix(), 8, 'days'), 'MMM dd, yyyy')}</CellText>
        </Cell>
        <Timezone name="timezone" control={control} onChangeTimezone={handleChangeTimezone} />
      </Content>
    </Drawer>
  );
};

export default DemoCourseDrawer;
