import { keyframes, styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../../../components/Typography';

export const errorCellIn = keyframes`
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 1000px;
      opacity: 1;
    },
`;

export const ErrorCellWrapper = styled('div')(({ theme }) => ({
  animationName: errorCellIn,
  animationDuration: '500ms',
  animationTimingFunction: 'ease-in',
  animationFillMode: 'forwards',
}));

export const Time = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
