import { ReactNode, useState } from 'react';

import { useTheme } from '@mui/material';
import { StepType, TourProvider as ReactourProvider } from '@reactour/tour';

import { TipStatusKey } from '../../db/shared/types';
import { TipsColor } from '../../styles/colors';
import { Content, StyledNavigation } from './styles';
import TipsContextProvider from './TipsContext';

interface Props {
  children: ReactNode;
}

const DEFAULT_STEPS: StepType[] = [];

const TourProvider = ({ children }: Props) => {
  const theme = useTheme();

  const [seenTips, setSeenTips] = useState<Set<TipStatusKey>>(new Set());

  return (
    <ReactourProvider
      steps={DEFAULT_STEPS}
      /**
       * onClickMask is necessary to prevent closing tour on click of overlay
       * Note: In latest version (3.1.4), we can use a prop `closeWithMask: false` to achive same behavior
       **/
      onClickMask={() => {}}
      disableInteraction
      disableKeyboardNavigation={['esc', 'left', 'right']}
      components={{
        Content: ({ content, ...props }) => <Content {...props}>{content}</Content>,
        Badge: () => null,
        Close: () => null,
        Navigation: (props) => <StyledNavigation {...props} seenTips={seenTips} setSeenTips={setSeenTips} />,
      }}
      /**
       * focus lock is disabled to avoid focus fight between confirm alert and reactour
       * if enabled, we will get an infinite loop due to focus fight between confirm alert and reactour
       */
      disableFocusLock
      styles={{
        popover: (styles) => ({
          ...styles,
          padding: 0,
          width: 350,
          backgroundColor: TipsColor.BLUE,
          zIndex: theme.zIndex.reactourTip,
          borderRadius: 8,
        }),
        maskWrapper: (styles) => ({
          ...styles,
          zIndex: theme.zIndex.reactourMask,
          borderRadius: 8,
        }),
      }}
    >
      <TipsContextProvider>{children}</TipsContextProvider>
    </ReactourProvider>
  );
};

export default TourProvider;
