import { call, put, select, takeLatest } from 'redux-saga/effects';

import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { selectCourseUser, selectCurrentCourse } from '../courses/selectors';
import { API } from '../shared/api-responses';
import { ClassIncharge, CourseRole } from '../shared/types';
import {
  approveQuery,
  closeQuery,
  createQuery,
  deleteQuery,
  fetchAllQueries,
  hideQuery,
  markQueryAsViewed,
  queryApproved,
  queryCreated,
  queryUpvoted,
  upvoteQuery,
  yourQueryApproved,
} from './actions';
import {
  approveQuery as approveQueryAPI,
  closeQuery as closeQueryAPI,
  createQuery as createQueryAPI,
  deleteQuery as deleteQueryAPI,
  getAllQueries,
  hideQuery as hideQueryAPI,
  markQueryAsViewed as markQueryAsViewedAPI,
  upvoteQuery as upvoteQueryAPI,
} from './api';
import {
  anonymousQueryCreatedEvent,
  queryApprovedEvent,
  queryCreatedEvent,
  queryUpvotedEvent,
  yourQueryApprovedEvent,
} from './pusher-events';
import { selectQuery } from './selectors';

const log = Logger.create('db/queries');

function* fetchAllQueriesWorker(action: ReturnType<typeof fetchAllQueries.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const currentCourse: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!currentCourse) return;

    /** skip unnecessary API call */
    if (currentCourse.activities.queries.total === 0) {
      yield put(
        fetchAllQueries.success(requestId, { activityData: [], userData: { queries: {} }, currentUser })
      );
      return;
    }

    const response: YieldCallType<typeof getAllQueries> = yield call(getAllQueries);
    yield put(
      fetchAllQueries.success(requestId, {
        ...response,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAllQueries.failure(requestId));
  }
}

function* createQueryWorker(action: ReturnType<typeof createQuery.request>) {
  const { requestId } = action.meta;
  const { classId, isAnonymous } = action.payload;
  try {
    const response: YieldCallType<typeof createQueryAPI> = yield call(createQueryAPI, action.payload);
    yield put(
      createQuery.success(
        requestId,
        isAnonymous
          ? { classId, isAnonymous: true }
          : { classId, isAnonymous: false, query: response as API.CreateQueryResponse<'nonAnonymous'> }
      )
    );
  } catch (error) {
    log.error(error);
    yield put(createQuery.failure(requestId));
  }
}

function* queryCreatedEventWorker(event: ReturnType<typeof queryCreatedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;
  yield put(queryCreated({ ...event.payload, currentUser }));
}

function* anonymousQueryCreatedEventWorker(event: ReturnType<typeof anonymousQueryCreatedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;
  yield put(queryCreated({ ...event.payload, currentUser }));
}

function* approveQueryWorker(action: ReturnType<typeof approveQuery.request>) {
  const { requestId } = action.meta;
  const { queryId } = action.payload;
  try {
    const query: YieldSelectorType<typeof selectQuery> = yield select(selectQuery(queryId));
    if (!query) return;

    yield call(approveQueryAPI, action.payload);
    yield put(
      approveQuery.success(requestId, {
        ...action.payload,
        courseId: query.courseId,
        classId: query.classId,
        toBeDone: query.toBeDone,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(approveQuery.failure(requestId));
  }
}

function* queryApprovedEventWorker(event: ReturnType<typeof queryApprovedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  yield put(queryApproved({ ...event.payload, currentUser }));
}

function* yourQueryApprovedEventWorker(event: ReturnType<typeof yourQueryApprovedEvent>) {
  yield put(yourQueryApproved({ ...event.payload }));
}

function* deleteQueryWorker(action: ReturnType<typeof deleteQuery.request>) {
  const { requestId } = action.meta;
  const { queryId } = action.payload;
  try {
    const query: YieldSelectorType<typeof selectQuery> = yield select(selectQuery(queryId));
    if (!query) return;

    yield call(deleteQueryAPI, action.payload);
    yield put(
      deleteQuery.success(requestId, {
        ...action.payload,
        courseId: query.courseId,
        classId: query.classId,
        toBeDone: query.toBeDone,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(deleteQuery.failure(requestId));
  }
}

function* markQueryAsViewedWorker(action: ReturnType<typeof markQueryAsViewed.request>) {
  const { requestId } = action.meta;
  const { queryId } = action.payload;
  try {
    const query: YieldSelectorType<typeof selectQuery> = yield select(selectQuery(queryId));
    if (!query) return;

    const currentTime = unix();
    const isFirstAccess = query.firstAccessedOn <= 0;

    if (isFirstAccess) {
      yield call(markQueryAsViewedAPI, { queryId, currentTime });
    }

    yield put(
      markQueryAsViewed.success(requestId, {
        queryId,
        currentTime,
        isFirstAccess,
        classId: query.classId,
        courseId: query.courseId,
        toBeDone: query.toBeDone,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(markQueryAsViewed.failure(requestId));
  }
}

function* upvoteQueryWorker(action: ReturnType<typeof upvoteQuery.request>) {
  const { requestId } = action.meta;
  try {
    yield call(upvoteQueryAPI, action.payload);
    yield put(upvoteQuery.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(upvoteQuery.failure(requestId));
  }
}

function* queryUpvotedEventWorker(event: ReturnType<typeof queryUpvotedEvent>) {
  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;
  yield put(queryUpvoted({ ...event.payload, currentUser }));
}

function* hideQueryWorker(action: ReturnType<typeof hideQuery.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser || currentUser.role === CourseRole.STUDENT) return;
    yield call(hideQueryAPI, action.payload);
    yield put(
      hideQuery.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(hideQuery.failure(requestId));
  }
}

function* closeQueryWorker(action: ReturnType<typeof closeQuery.request>) {
  const { requestId } = action.meta;
  const { queryId } = action.payload;
  try {
    const query: YieldSelectorType<typeof selectQuery> = yield select(selectQuery(queryId));
    if (!query) return;

    yield call(closeQueryAPI, action.payload);
    yield put(
      closeQuery.success(requestId, {
        ...action.payload,
        courseId: query.courseId,
        classId: query.classId,
        toBeDone: query.toBeDone,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(closeQuery.failure(requestId));
  }
}

export default function* rootQueriesSaga() {
  try {
    yield takeLatest(fetchAllQueries.request, fetchAllQueriesWorker);

    yield takeLatest(createQuery.request, createQueryWorker);
    yield takeEveryPusher(queryCreatedEvent, queryCreatedEventWorker);
    yield takeEveryPusher(anonymousQueryCreatedEvent, anonymousQueryCreatedEventWorker);

    yield takeLatest(approveQuery.request, approveQueryWorker);
    yield takeEveryPusher(queryApprovedEvent, queryApprovedEventWorker);
    yield takeEveryPusher(yourQueryApprovedEvent, yourQueryApprovedEventWorker);

    yield takeLatest(deleteQuery.request, deleteQueryWorker);
    yield takeLatest(markQueryAsViewed.request, markQueryAsViewedWorker);

    yield takeLatest(upvoteQuery.request, upvoteQueryWorker);
    yield takeEveryPusher(queryUpvotedEvent, queryUpvotedEventWorker);

    yield takeLatest(hideQuery.request, hideQueryWorker);
    yield takeLatest(closeQuery.request, closeQueryWorker);
  } catch (error) {
    log.error(error);
  }
}
