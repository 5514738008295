import { forwardRef, Ref } from 'react';

import { Stack, StackProps, styled, Typography, TypographyProps } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const sectionClasses = {
  ...generateClasses('Section', [
    'root',
    'variantDefault',
    'variantTransparent',
    'variantEmphasized',
    'title',
    'children',
  ]),
};

export const Root = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => <Stack ref={ref} {...props} />)
)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,

  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },

  [`&.${sectionClasses.variantDefault}`]: {
    [`& .${sectionClasses.title}`]: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(3, 5),
      minHeight: 52,
    },
    [`& .${sectionClasses.children}`]: {
      padding: theme.spacing(3, 5),
    },
  },

  [`&.${sectionClasses.variantTransparent}`]: {
    border: 'none',
    background: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },

  [`&.${sectionClasses.variantEmphasized}`]: {
    overflow: 'hidden',
    [`& .${sectionClasses.title}`]: {
      backgroundColor: theme.palette.primary[100],
      color: theme.palette.grey[900],
      padding: theme.spacing(5),
    },
    [`& .${sectionClasses.children}`]: {
      padding: theme.spacing(3, 5),
    },
  },
}));

export const Title = styled((props: TypographyProps) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

export const Children = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[500],
}));
