import { styled } from '@mui/material';

import { forMobile } from '../../../../utils/media-queries';

export const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: '100%',
  position: 'absolute',
  inset: 0,
}));

export const FormContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6, 8),
  flex: 1,
  overflow: 'auto',

  [forMobile(theme)]: {
    padding: theme.spacing(4, 6),
  },
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
}));
