import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import { i18nNS } from '../../../i18n';
import ClockIcon from '../../../icons/ClockIcon';
import Globe2Icon from '../../../icons/Globe2Icon';
import CoursePreview from './CoursePreview';
import useCreateCoursePreviewVM from './vm';
import WeeklySchedule from './WeeklySchedule';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.primary[50],
  borderRadius: 8,
}));

const Title = styled((props: TypographyProps<'div'>) => (
  <Typography {...props} variant="h6Bold" component="div" />
))(({ theme }) => ({
  color: theme.palette.grey[700],
}));

interface Props {
  className?: string;

  /** @default false */
  showTimezone?: boolean;

  /** @default false */
  showWeeklySchedule?: boolean;
}

const CreateCoursePreview = ({ className, showTimezone = false, showWeeklySchedule = false }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  const { timezone, weeklySchedule } = useCreateCoursePreviewVM();
  return (
    <Root>
      <Title>{t('course_preview', { ns: i18nNS.CREATE_COURSE })}</Title>
      <CoursePreview />
      <Stack gap={3.5}>
        {showTimezone && (
          <Stack direction="row" gap={1}>
            <Globe2Icon variant="filled" />
            <Typography variant="h6Regular" component="span">
              {timezone}
            </Typography>
          </Stack>
        )}
        {showWeeklySchedule && (
          <>
            <Stack direction="row" gap={1}>
              <ClockIcon />
              <Typography variant="h6Regular" component="span">
                {t('class_will_happen_every', { ns: i18nNS.CREATE_COURSE })}
              </Typography>
            </Stack>
            <Stack gap={2} pl={8}>
              {weeklySchedule.map((schedule, index) => (
                <WeeklySchedule key={index} {...schedule} />
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </Root>
  );
};

export default CreateCoursePreview;
