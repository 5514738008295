import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectDiscussion } from '../../../db/discussions/selectors';
import { DiscussionParams } from '../../../pages/routes';

const useDiscussionWordCloudHeaderVM = () => {
  const { discussionShortId } = useParams<DiscussionParams>();

  const discussion = useSelector(selectDiscussion(discussionShortId));

  return { discussion };
};

export default useDiscussionWordCloudHeaderVM;
