import { useTranslation } from 'react-i18next';

import { ChangeAvatarDrawer } from '../../auth/Components';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import { UserAvatar } from './styles';
import useChangeAvatarVM from './vm';

const ChangeAvatar = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  const { avatar, isChangeAvatarDrawerOpen, handleCloseChangeAvatarDrawer, handleChangeAvatar } =
    useChangeAvatarVM();

  return (
    <>
      <ListItemButton
        onClick={handleChangeAvatar}
        aria-label={t('click_to_change_avatar', { ns: i18nNS.COMMON })}
        aria-haspopup
      >
        <ListItemIcon>
          <UserAvatar avatarId={avatar} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('change_avatar', { ns: i18nNS.SETTINGS })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <ChangeAvatarDrawer open={isChangeAvatarDrawerOpen} onClose={handleCloseChangeAvatarDrawer} />
    </>
  );
};

export default ChangeAvatar;
