import { styled } from '@mui/material';

import Button from '../../../../../components/Button';
import { generateClasses } from '../../../../../utils/helpers';

export const subscribeButtonClasses = {
  ...generateClasses('SubscribeButton', ['subscribed', 'unsubscribed', 'notification']),
};

export const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${subscribeButtonClasses.subscribed}`]: {
    color: theme.palette.primary[500],
  },
  [`&.${subscribeButtonClasses.unsubscribed}`]: {
    color: theme.palette.grey[400],
  },
}));
