import { combineReducers } from '@reduxjs/toolkit';

import announcementsReducer from './announcements/reducer';
import appReducer from './app/reducer';
import assignmentsReducer from './assignments/reducer';
import avatarsReducer from './avatars/reducer';
import booksReducer from './books/reducer';
import classesReducer from './classes/reducer';
import commentsReducer from './comments/reducer';
import coursesReducer from './courses/reducer';
import discussionsReducer from './discussions/reducer';
import featuresReducer from './features/reducer';
import linksReducer from './links/reducer';
import onlineMeetingReducer from './online-meeting/reducer';
import pollsReducer from './polls/reducer';
import queriesReducer from './queries/reducer';
import quizzesReducer from './quizzes/reducer';
import resourcesReducer from './resources/reducer';
import topicsReducer from './topics/reducer';
import universityReducer from './university/reducer';

export interface DbState {
  announcements: ReturnType<typeof announcementsReducer>;
  app: ReturnType<typeof appReducer>;
  assignments: ReturnType<typeof assignmentsReducer>;
  avatars: ReturnType<typeof avatarsReducer>;
  books: ReturnType<typeof booksReducer>;
  classes: ReturnType<typeof classesReducer>;
  comments: ReturnType<typeof commentsReducer>;
  courses: ReturnType<typeof coursesReducer>;
  discussions: ReturnType<typeof discussionsReducer>;
  features: ReturnType<typeof featuresReducer>;
  links: ReturnType<typeof linksReducer>;
  onlineMeetings: ReturnType<typeof onlineMeetingReducer>;
  polls: ReturnType<typeof pollsReducer>;
  queries: ReturnType<typeof queriesReducer>;
  quizzes: ReturnType<typeof quizzesReducer>;
  resources: ReturnType<typeof resourcesReducer>;
  topics: ReturnType<typeof topicsReducer>;
  university: ReturnType<typeof universityReducer>;
}

const dbReducer = combineReducers<DbState>({
  announcements: announcementsReducer,
  app: appReducer,
  assignments: assignmentsReducer,
  books: booksReducer,
  avatars: avatarsReducer,
  classes: classesReducer,
  comments: commentsReducer,
  courses: coursesReducer,
  discussions: discussionsReducer,
  features: featuresReducer,
  links: linksReducer,
  onlineMeetings: onlineMeetingReducer,
  polls: pollsReducer,
  queries: queriesReducer,
  quizzes: quizzesReducer,
  resources: resourcesReducer,
  topics: topicsReducer,
  university: universityReducer,
});

export default dbReducer;
