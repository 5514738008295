import { getAcadlyRouteByURL } from '../../pages/helpers';
import {
  AssignmentParams,
  ClassParams,
  CourseParams,
  DiscussionParams,
  PollParams,
  QueryParams,
  QuizParams,
  ResourceParams,
} from '../../pages/routes';
import { RootState } from '../../store/types';
import { getTotalComments, getUnseenComments } from '../../utils/activities';
import { groupBy } from '../../utils/helpers';
import { getActivityById } from '../shared/helpers';
import { AppContext, AssignmentSubContext, CommentContext, ToBeDone, User } from '../shared/types';
import { Comment, CommentContextData } from './types';

export const selectIsFetchingComments = (state: RootState) => {
  const { comments } = state.db;
  return comments.isFetching;
};

export const selectCommentContext = (state: RootState) => {
  const { comments } = state.db;
  return comments.context;
};

/**
 * Select activity by comment context
 * @param context comment context, default is current context
 */
export const selectContextData = (context?: CommentContextData) => (state: RootState) => {
  const { assignments, classes, comments, courses, discussions, polls, queries, quizzes, resources } =
    state.db;

  context = context || comments.context || undefined;

  if (!context) return undefined;

  switch (context.type) {
    case CommentContext.COURSE:
      return {
        ...context,
        activity: getActivityById(courses, context.id),
      };
    case CommentContext.ASSIGNMENT:
      return {
        ...context,
        activity: getActivityById(assignments, context.id),
      };
    case CommentContext.CLASS:
      return {
        ...context,
        activity: getActivityById(classes, context.id),
      };
    case CommentContext.QUIZ:
      return {
        ...context,
        activity: getActivityById(quizzes, context.id),
      };
    case CommentContext.POLL:
      return {
        ...context,
        activity: getActivityById(polls, context.id),
      };
    case CommentContext.RESOURCE:
      return {
        ...context,
        activity: getActivityById(resources, context.id),
      };
    case CommentContext.DISCUSSION:
      return {
        ...context,
        activity: getActivityById(discussions, context.id),
      };
    case CommentContext.QUERY:
      return {
        ...context,
        activity: getActivityById(queries, context.id),
      };
  }
};

/**
 * Selects total number of comments in a comment context. If context
 * is not specified then current context is used.
 * @param context comment context
 */
export const selectTotalComments = (context?: CommentContextData) => (state: RootState) => {
  const data = selectContextData(context)(state);
  if (!data?.activity) return 0;

  if (data.type === CommentContext.ASSIGNMENT) {
    return getTotalComments({
      comments: data.activity.comments[data.subType],
    });
  }

  return data?.activity ? getTotalComments(data.activity) : 0;
};

export const selectUnseenCommentsCount = (context?: CommentContextData) => (state: RootState) => {
  const data = selectContextData(context)(state);
  if (!data?.activity) return 0;

  if (data.type === CommentContext.ASSIGNMENT) {
    return getUnseenComments({
      comments: data.activity.comments[data.subType],
    });
  }

  return data?.activity ? getUnseenComments(data.activity) : 0;
};

export const selectCommentContextByURL =
  (url: string) =>
  (state: RootState): CommentContextData | null => {
    const { route, match } = getAcadlyRouteByURL(url);

    if (!route || !match) return null;

    switch (route.context) {
      case AppContext.COURSE: {
        const params = match.params as CourseParams;
        const course = getActivityById(state.db.courses, params.courseShortId);
        if (!course?.id) return null;
        return {
          id: course.id,
          type: CommentContext.COURSE,
          subType: ToBeDone.PRE_CLASS,
          classId: null,
        };
      }
      case AppContext.ASSIGNMENT: {
        const params = match.params as AssignmentParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.ASSIGNMENT ? context.subType : undefined;
        const assignment = getActivityById(state.db.assignments, params.assignmentShortId);
        if (!assignment?.id) return null;
        return {
          id: assignment.id,
          type: CommentContext.ASSIGNMENT,
          subType: subType || AssignmentSubContext.PRE_SUBMISSION,
          classId: null,
        };
      }
      case AppContext.CLASS: {
        const params = match.params as ClassParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.CLASS ? context.subType : undefined;
        const cls = getActivityById(state.db.classes, params.classShortId);
        if (!cls?.id) return null;
        return {
          id: cls.id,
          type: CommentContext.CLASS,
          subType: subType || ToBeDone.PRE_CLASS,
          classId: cls.id,
        };
      }
      case AppContext.QUIZ: {
        const params = match.params as QuizParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.QUIZ ? context.subType : undefined;
        const quiz = getActivityById(state.db.quizzes, params.quizShortId);
        if (!quiz?.id) return null;
        return {
          id: quiz.id,
          type: CommentContext.QUIZ,
          subType: subType || quiz.toBeDone,
          classId: quiz.classId,
        };
      }
      case AppContext.POLL: {
        const params = match.params as PollParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.POLL ? context.subType : undefined;
        const poll = getActivityById(state.db.polls, params.pollShortId);
        if (!poll?.id) return null;
        return {
          id: poll.id,
          type: CommentContext.POLL,
          subType: subType || poll.toBeDone,
          classId: poll.classId,
        };
      }
      case AppContext.RESOURCE: {
        const params = match.params as ResourceParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.RESOURCE ? context.subType : undefined;
        const resource = getActivityById(state.db.resources, params.resourceShortId);
        if (!resource?.id) return null;
        return {
          id: resource.id,
          type: CommentContext.RESOURCE,
          subType: subType || resource.toBeDone,
          classId: resource.classId,
        };
      }
      case AppContext.DISCUSSION: {
        const params = match.params as DiscussionParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.DISCUSSION ? context.subType : undefined;
        const discussion = getActivityById(state.db.discussions, params.discussionShortId);
        if (!discussion?.id) return null;
        return {
          id: discussion.id,
          type: CommentContext.DISCUSSION,
          subType: subType || discussion.toBeDone,
          classId: discussion.classId,
        };
      }
      case AppContext.QUERY: {
        const params = match.params as QueryParams;
        const { context } = state.db.comments;
        const subType = context?.type === CommentContext.QUERY ? context.subType : undefined;
        const query = getActivityById(state.db.queries, params.queryShortId);
        if (!query?.id) return null;
        return {
          id: query.id,
          type: CommentContext.QUERY,
          subType: subType || query.toBeDone,
          classId: query.classId,
        };
      }
    }

    return null;
  };

export const selectFirstUnseenCommentId = (state: RootState) => {
  const { firstUnseenCommentId } = state.db.comments;
  return firstUnseenCommentId;
};

export const selectComment = (commentId: MongoId) => (state: RootState) => {
  const { comments } = state.db;
  return comments.byId[commentId];
};

export const selectComments = (state: RootState) => {
  const { order, byId: commentsById } = state.db.comments;
  const comments: Comment[] = [];

  for (const commentId of order) {
    const comment = commentsById[commentId];
    if (!comment) continue;
    comments.push(comment);
  }

  return comments;
};

/**
 * groups comments that are sent by same user within 1 hour window
 * and reverses the order of groups, but not comments inside each group.
 * @returns grouped comments in reverse order. only groups are reversed, items inside each group are not reversed.
 */
export const selectCommentGroupsInReverseOrder = (state: RootState) => {
  const comments = selectComments(state);
  const commentGroups = groupBy(comments, (comment) => !comment.isFirstCommentInGroup);

  // reverse outer array, but not inner array
  commentGroups.reverse();

  return commentGroups;
};

export const selectCommentMyReactions = (commentId: MongoId) => (state: RootState) => {
  const myReactions = state.db.comments.myReactionsByCommentId[commentId];
  return myReactions;
};

interface Reactions {
  likes: User[];
  thanks: User[];
}

export const selectCommentReactions = (commentId: MongoId) => (state: RootState) => {
  const comment = selectComment(commentId)(state);

  const reactions: Reactions = {
    likes: [],
    thanks: [],
  };

  if (!comment) return reactions;

  const { likedBy, thankedBy } = comment.reactions;

  for (const user of Object.values(likedBy || {})) {
    if (!user) continue;
    reactions.likes.push(user);
  }

  for (const user of Object.values(thankedBy || {})) {
    if (!user) continue;
    reactions.thanks.push(user);
  }

  return reactions;
};

export const selectNewCommentsCount = (state: RootState) => {
  return state.db.comments.newCommentsCount;
};
