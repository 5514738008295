import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { FormHelperText, Link, Stack, useMediaQuery } from '@mui/material';

import Alert from '../../components/Alert';
import TextField from '../../components/HookFormControls/TextField';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import routes from '../../pages/routes';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import { AlertLink, BackButton, MarketingCheckbox, NextButton } from './styles';
import useSignupEmailVM, { VALIDATION_TYPES } from './vm';

const SignupEmail = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.VALIDATION]);

  const { isStudent, formMethods, onSubmit, handleEmailBlur } = useSignupEmailVM();
  const { handleSubmit, control, formState, watch } = formMethods;
  const { isSubmitting, errors } = formState;
  const marketing = watch('marketing');

  return (
    <Layout
      title={t('signing_up', { ns: i18nNS.AUTH })}
      subtitle={t(
        isStudent
          ? 'please_enter_your_email_address'
          : 'please_enter_your_official_email_address_to_signup_as_an_instructor',
        { ns: i18nNS.AUTH }
      )}
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3}>
          <div>
            <TextField
              control={control}
              name="emailId"
              type="email"
              label={t('email_address', { ns: i18nNS.AUTH })}
              required
              size={isMobile ? 'medium' : 'xLarge'}
              placeholder={t('enter_your_email_here', { ns: i18nNS.AUTH })}
              autoComplete="username"
              autoCapitalize="none"
              autoCorrect="off"
              fullWidth
              onBlur={handleEmailBlur}
              autoFocus
            />
            {errors['emailId']?.type === VALIDATION_TYPES.EMAIL_ALREADY_EXISTS && (
              <FormHelperText
                error
                aria-label={t('this_email_address_already_exists_login_instead', { ns: i18nNS.AUTH })}
              >
                <Trans ns={i18nNS.VALIDATION} i18nKey="this_email_address_already_exists_log_in_instead">
                  This email address already exists.{' '}
                  <Link
                    component={RouterLink}
                    to={routes.login.url()}
                    aria-label={t('click_to_login_instead', { ns: i18nNS.AUTH })}
                  >
                    Log in instead?
                  </Link>
                </Trans>
              </FormHelperText>
            )}
          </div>
          <MarketingCheckbox
            control={control}
            name="marketing"
            label={t('i_agree_to_receive_product_news_and_updates_via_email', { ns: i18nNS.AUTH })}
            aria-label={t(
              marketing
                ? 'click_to_disagree_toreceive_product_news_and_updates_via_email'
                : 'click_to_agree_to_receive_product_news_and_updates_via_email',
              { ns: i18nNS.AUTH }
            )}
          />
          <Link
            component={AlertLink}
            disableRipple
            disableFocusRipple
            aria-label={t('click_to_know_which_email_address_should_you_use', { ns: i18nNS.AUTH })}
          >
            {t('which_email_address_should_i_use', { ns: i18nNS.AUTH })}
            <Alert>
              <Alert.Header>{t('which_email_address_should_i_use', { ns: i18nNS.AUTH })}</Alert.Header>
              <Alert.Body>
                <Stack gap={4}>
                  {isStudent ? (
                    <>
                      <Typography variant="paragraphRegular">
                        {t(
                          'if_you_ve_been_invited_to_join_a_course_please_use_the_email_address_on_which_you_received_the_invitation',
                          { ns: i18nNS.AUTH }
                        )}
                      </Typography>
                      <Typography variant="paragraphRegular">
                        {t(
                          'if_you_were_not_invited_via_email_you_should_preferably_use_the_email_address_provided_by_the_institute',
                          { ns: i18nNS.AUTH }
                        )}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="paragraphRegular">
                        {t(
                          'if_you_ve_been_invited_by_another_instructor_to_join_a_course_please_use_the_email_address_on_which_you_received_the_invitation',
                          { ns: i18nNS.AUTH }
                        )}
                      </Typography>
                      <Typography variant="paragraphRegular">
                        {t(
                          'to_request_a_new_account_please_use_the_email_address_provided_by_your_institute_for_quick_verification',
                          { ns: i18nNS.AUTH }
                        )}
                      </Typography>
                    </>
                  )}
                </Stack>
              </Alert.Body>
              <Alert.Footer>
                <Alert.Action variant="text">{t('okay', { ns: i18nNS.GLOSSARY })}</Alert.Action>
              </Alert.Footer>
            </Alert>
          </Link>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            component={RouterLink}
            to={routes.signup.url()}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <NextButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_sign_up', { ns: i18nNS.AUTH })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </NextButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SignupEmail;
