import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';

interface LayoutContextType {
  isCommentsPanelOpen: boolean;
  setIsCommentsPanelOpen: Dispatch<SetStateAction<boolean>>;
  isCommentsPanelExpanded: boolean;
  setIsCommentsPanelExpanded: Dispatch<SetStateAction<boolean>>;
}

export const LayoutContext = createContext<LayoutContextType>({
  isCommentsPanelOpen: false,
  setIsCommentsPanelOpen: () => {},
  isCommentsPanelExpanded: false,
  setIsCommentsPanelExpanded: () => {},
});

interface Props {
  children: ReactNode;
}

const useLayoutContextProviderVM = () => {
  const [isCommentsPanelOpen, setIsCommentsPanelOpen] = useState(false);
  const [isCommentsPanelExpanded, setIsCommentsPanelExpanded] = useState(false);

  return useMemo<LayoutContextType>(
    () => ({
      isCommentsPanelOpen,
      setIsCommentsPanelOpen,
      isCommentsPanelExpanded,
      setIsCommentsPanelExpanded,
    }),
    [isCommentsPanelOpen, isCommentsPanelExpanded]
  );
};

const LayoutContextProvider = ({ children }: Props) => {
  const providerValue = useLayoutContextProviderVM();

  return <LayoutContext.Provider value={providerValue}>{children}</LayoutContext.Provider>;
};

export default LayoutContextProvider;
