import { Class } from '../db/classes/types';
import { ClassType } from '../db/shared/types';
import { ActivityType } from '../types';

export type ClassWidgetData = Pick<
  Class,
  'id' | 'timing' | 'title' | 'sequenceNum' | 'isOnline' | 'status' | 'team' | 'activities' | 'myRole'
> & {
  comments?: Class['comments'];
};

export enum ActivityIconBadge {
  NONE,
  HAS_NEW_COMMENTS,
  UNSEEN,
}

export interface ActivityIcon {
  color: string;
  type: ActivityType;
  badge: ActivityIconBadge;
}

export interface ClashingClass {
  /* 24h format: hh:mm */
  startTime: string;
  /* 24h format: hh:mm */
  endTime: string;
  date: UnixTime;
  type: ClassType;
}
