import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { ActivityUpdatedEvent } from '../courses/pusher-events';
import { API } from '../shared/api-responses';
import { DiscussionPublishedPayload, WordCloudAvailableEvent } from './pusher-events';
import {
  CreateDiscussionSuccessPayload,
  CreateWordCloudSuccessPayload,
  DeleteDiscussionSuccessPayload,
  DiscussionId,
  EditDiscussionSuccessPayload,
  EditPublishedDiscussionPayload,
  EditPublishedDiscussionSuccessPayload,
  EditWordCloudSuccessPayload,
  FetchAllDiscussionsSuccessPayload,
  FetchDiscussionPublishPrefsSuccessPayload,
  FetchWordCloudDetailsSuccessPayload,
  MarkDiscussionAsReadSuccessPayload,
  PublishDiscussionPayload,
  PublishDiscussionSuccessPayload,
  SaveDiscussionPublishPrefsPayload,
} from './types';

export const openDiscussion = createAction<DiscussionId>('discussions/open');
export const closeDiscussion = createAction<DiscussionId>('discussions/close');

export const fetchAllDiscussions = createRequestAction<void, FetchAllDiscussionsSuccessPayload>(
  'discussions/fetch/all'
);

export const createDiscussion = createRequestAction<
  API.CreateDiscussionRequest,
  CreateDiscussionSuccessPayload
>('discussions/create');

export const editDiscussion = createRequestAction<API.EditDiscussionRequest, EditDiscussionSuccessPayload>(
  'discussions/edit'
);

export const discussionEdited = createAction<ActivityUpdatedEvent>('discussions/edited');

export const deleteDiscussion = createRequestAction<
  API.DeleteDiscussionRequest,
  DeleteDiscussionSuccessPayload
>('discussions/delete');

export const fetchDiscussionPublishPrefs = createRequestAction<
  API.GetDiscussionPublishPrefsRequest,
  FetchDiscussionPublishPrefsSuccessPayload
>('discussions/publish-prefs/fetch');

export const saveDiscussionPublishPrefs = createRequestAction<
  SaveDiscussionPublishPrefsPayload,
  SaveDiscussionPublishPrefsPayload
>('discussions/publish-prefs/save');

export const publishDiscussion = createRequestAction<
  PublishDiscussionPayload,
  PublishDiscussionSuccessPayload
>('discussions/publish');

export const discussionPublished = createAction<DiscussionPublishedPayload>('discussions/published');

export const markDiscussionAsViewed = createRequestAction<DiscussionId, MarkDiscussionAsReadSuccessPayload>(
  'discussions/mark/read'
);

export const editPublishedDiscussion = createRequestAction<
  EditPublishedDiscussionPayload,
  EditPublishedDiscussionSuccessPayload
>('discussions/published/edit');

export const createWordCloud = createRequestAction<DiscussionId, CreateWordCloudSuccessPayload>(
  'discussions/word-cloud/create'
);

export const editWordCloud = createRequestAction<API.EditWordCloudRequest, EditWordCloudSuccessPayload>(
  'discussions/word-cloud/edit'
);

export const fetchWordCloudDetails = createRequestAction<DiscussionId, FetchWordCloudDetailsSuccessPayload>(
  'discussions/word-cloud/fetch'
);

export const wordCloudAvailable = createAction<WordCloudAvailableEvent>('discussions/word-cloud/available');
