import { useState } from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Box, Stack, styled, TextField } from '@mui/material';

import Button from '../components/Button';
import { NEW_ITEM_ID } from '../components/ExpandableList';
import AcadlyExpandableListItem from '../components/ExpandableListItem';
import Typography from '../components/Typography';
import { generateClasses } from '../utils/helpers';

const expandableListItemPlaygroundClasses = generateClasses('ExpandableListItemPlayground', [
  'summaryRoot',
  'summaryContent',
]);

const ExpandableListItem = styled(AcadlyExpandableListItem)(({ theme }) => ({
  [`& .${expandableListItemPlaygroundClasses.summaryRoot}`]: {
    padding: 0,
  },
  [`& .${expandableListItemPlaygroundClasses.summaryContent}`]: {
    margin: 0,
  },
})) as typeof AcadlyExpandableListItem;

const Details = ({ onToggle }: { onToggle: () => void }) => {
  const handleSave = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    onToggle();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end" gap={2}>
      <TextField fullWidth />
      <Box display="flex" gap={2}>
        <Button onClick={onToggle}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </Box>
    </Box>
  );
};

const ExpandableListItemPlayground = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ExpandableListItem
      isExpanded={expanded}
      item={{ id: NEW_ITEM_ID }}
      onToggle={setExpanded}
      renderSummary={({ isExpanded, onToggle }) => {
        return (
          <Button variant="text" disabled={isExpanded} onClick={onToggle} fullWidth>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6Regular" color="grey.800">
                Add New Item
              </Typography>
              {isExpanded ? undefined : <AddIcon color="primary" />}
            </Stack>
          </Button>
        );
      }}
      renderDetails={({ onToggle }) => <Details onToggle={onToggle} />}
      classes={{
        summary: {
          root: expandableListItemPlaygroundClasses.summaryRoot,
          content: expandableListItemPlaygroundClasses.summaryContent,
        },
      }}
    />
  );
};

export default ExpandableListItemPlayground;
