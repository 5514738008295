import { useTranslation } from 'react-i18next';

import { Button as MuiButton, styled } from '@mui/material';

import { i18nNS } from '../../i18n';
import FeatureUpdatesDrawer from '../../settings/FeatureUpdates/FeatureUpdatesDrawer';
import { forMobile } from '../../utils/media-queries';
import useFeatureUpdatesButtonVM from './vm';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 74,
  right: 400,
  zIndex: 10,
  [forMobile(theme)]: {
    right: 10,
  },
}));

const NewUpdatesButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  boxShadow: theme.shadows[2],
  borderRadius: 'unset',
  borderTopRightRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    boxShadow: theme.shadows[2],
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: -6,
    width: 6,
    height: 6,
    background: theme.palette.warning.main,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: -8,
    width: 8,
    height: 6,
    background: theme.palette.error.dark,
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
}));

const FeatureUpdatesButton = () => {
  const { t } = useTranslation([i18nNS.COURSES]);

  const {
    isFeatureUpdatesDrawerOpen,
    onOpenFeatureUpdatesDrawer: handleOpenFeatureUpdatesDrawer,
    onCloseFeatureUpdatesDrawer: handleCloseFeatureUpdatesDrawer,
    canViewFeatureUpdatesButton,
  } = useFeatureUpdatesButtonVM();

  if (!canViewFeatureUpdatesButton) return null;

  return (
    <>
      <Root>
        <NewUpdatesButton
          variant="contained"
          onClick={handleOpenFeatureUpdatesDrawer}
          aria-label={t('click_to_learn_about_new_updates', { ns: i18nNS.HOME })}
        >
          {t('new_updates', { ns: i18nNS.COURSES })}
        </NewUpdatesButton>
      </Root>
      <FeatureUpdatesDrawer open={isFeatureUpdatesDrawerOpen} onClose={handleCloseFeatureUpdatesDrawer} />
    </>
  );
};

export default FeatureUpdatesButton;
