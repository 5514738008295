import { useContext } from 'react';

import { Backdrop, useMediaQuery } from '@mui/material';

import { forDesktop } from '../../../utils/media-queries';
import { LayoutContext } from '../Context';

const useDrawerBackdropVM = () => {
  const { isCommentsPanelExpanded, setIsCommentsPanelExpanded, setIsCommentsPanelOpen } =
    useContext(LayoutContext);

  const handleBackdropClick = () => {
    setIsCommentsPanelOpen(false);
    setIsCommentsPanelExpanded(false);
  };

  return {
    isCommentsPanelExpanded,
    handleBackdropClick,
  };
};

const DrawerBackdrop = () => {
  const isDesktop = useMediaQuery(forDesktop);

  const { isCommentsPanelExpanded, handleBackdropClick } = useDrawerBackdropVM();

  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      open={isDesktop && isCommentsPanelExpanded}
      onClick={handleBackdropClick}
    />
  );
};
export default DrawerBackdrop;
