import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const ArrowheadLeftIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11.64 5.23002C11.5389 5.14582 11.4223 5.08238 11.2967 5.04334C11.1711 5.00429 11.039 4.99041 10.908 5.00248C10.777 5.01456 10.6497 5.05236 10.5334 5.11371C10.417 5.17506 10.3139 5.25876 10.23 5.36002L5.22999 11.36C5.08291 11.539 5.0025 11.7634 5.0025 11.995C5.0025 12.2266 5.08291 12.4511 5.22999 12.63L10.06 18.63C10.1541 18.746 10.273 18.8395 10.408 18.9035C10.543 18.9676 10.6906 19.0005 10.84 19C11.0292 19.0007 11.2148 18.9476 11.375 18.847C11.5353 18.7464 11.6638 18.6024 11.7454 18.4317C11.8271 18.261 11.8587 18.0706 11.8365 17.8827C11.8143 17.6948 11.7392 17.517 11.62 17.37L7.28999 12L11.77 6.63002C11.9369 6.42652 12.0169 6.16541 11.9926 5.90332C11.9682 5.64123 11.8415 5.3993 11.64 5.23002Z"
          fill="currentColor"
        />
        <path
          d="M14.29 12L18.77 6.63002C18.9397 6.4258 19.0214 6.16252 18.997 5.8981C18.9726 5.63367 18.8442 5.38976 18.64 5.22002C18.4358 5.05028 18.1725 4.96862 17.9081 4.993C17.6436 5.01738 17.3997 5.1458 17.23 5.35002L12.23 11.35C12.0829 11.529 12.0025 11.7534 12.0025 11.985C12.0025 12.2166 12.0829 12.4411 12.23 12.62L17.06 18.62C17.1541 18.736 17.273 18.8295 17.408 18.8935C17.543 18.9576 17.6906 18.9905 17.84 18.99C18.0292 18.9907 18.2148 18.9376 18.375 18.837C18.5353 18.7364 18.6638 18.5924 18.7454 18.4217C18.8271 18.251 18.8587 18.0606 18.8365 17.8727C18.8143 17.6848 18.7392 17.507 18.62 17.36L14.29 12Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.64 5.23002C11.539 5.14582 11.4223 5.08238 11.2967 5.04334C11.1711 5.00429 11.039 4.99041 10.908 5.00248C10.7771 5.01456 10.6497 5.05236 10.5334 5.11371C10.4171 5.17506 10.314 5.25876 10.23 5.36002L5.23001 11.36C5.08292 11.539 5.00252 11.7634 5.00252 11.995C5.00252 12.2266 5.08292 12.4511 5.23001 12.63L10.06 18.63C10.1541 18.746 10.273 18.8395 10.408 18.9035C10.543 18.9676 10.6906 19.0005 10.84 19C11.0292 19.0007 11.2148 18.9476 11.3751 18.847C11.5353 18.7464 11.6638 18.6024 11.7455 18.4317C11.8271 18.261 11.8587 18.0706 11.8365 17.8827C11.8143 17.6948 11.7392 17.517 11.62 17.37L7.29001 12L11.77 6.63002C11.9369 6.42652 12.0169 6.16541 11.9926 5.90332C11.9683 5.64123 11.8416 5.3993 11.64 5.23002Z"
        fill="currentColor"
      />
      <path
        d="M14.29 12L18.77 6.63002C18.9397 6.4258 19.0214 6.16252 18.997 5.8981C18.9726 5.63367 18.8442 5.38976 18.64 5.22002C18.4358 5.05028 18.1725 4.96862 17.9081 4.993C17.6437 5.01738 17.3997 5.1458 17.23 5.35002L12.23 11.35C12.0829 11.529 12.0025 11.7534 12.0025 11.985C12.0025 12.2166 12.0829 12.4411 12.23 12.62L17.06 18.62C17.1541 18.736 17.273 18.8295 17.408 18.8935C17.543 18.9576 17.6906 18.9905 17.84 18.99C18.0292 18.9907 18.2148 18.9376 18.3751 18.837C18.5353 18.7364 18.6638 18.5924 18.7455 18.4217C18.8271 18.251 18.8587 18.0606 18.8365 17.8727C18.8143 17.6848 18.7392 17.507 18.62 17.36L14.29 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ArrowheadLeftIcon;
