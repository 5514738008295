import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import UserCellSkeleton from '../../../../../../../../../components/UserCell/Skeleton';
import { i18nNS } from '../../../../../../../../../i18n';
import { StyledUserCell, UserReactions } from './styles';
import useReactionsVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const Reactions = ({ commentId }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { isFetchingReactions, totalReactions, likes, thanks } = useReactionsVM({ commentId });

  if (totalReactions === 0) return null;

  return (
    <Stack direction="column" gap={4} component="section" aria-labelledby="awards">
      <Typography variant="titleBold">{t('reactions', { ns: i18nNS.GLOSSARY })}</Typography>
      <UserReactions component="ul">
        {isFetchingReactions &&
          new Array(Math.min(totalReactions, 3)).fill('').map((_, index) => (
            <li key={index}>
              <UserCellSkeleton />
            </li>
          ))}
        {!isFetchingReactions &&
          likes.map((user) => (
            <li key={user.userId}>
              <StyledUserCell
                title={user.name}
                avatar={user.avatar}
                subtitle={t('liked', { ns: i18nNS.GLOSSARY })}
                size="medium"
              />
            </li>
          ))}
        {!isFetchingReactions &&
          thanks.map((user) => (
            <li key={user.userId}>
              <StyledUserCell
                title={user.name}
                avatar={user.avatar}
                subtitle={t('thanked', { ns: i18nNS.GLOSSARY })}
                size="medium"
              />
            </li>
          ))}
      </UserReactions>
    </Stack>
  );
};

export default Reactions;
