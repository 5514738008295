import { call, put, takeLatest } from 'redux-saga/effects';

import Logger from '../../utils/logger';
import { addCourseTopic, deleteCourseTopic, editCourseTopic } from './actions';
import {
  addCourseTopic as addCourseTopicAPI,
  deleteCourseTopic as deleteCourseTopicAPI,
  editCourseTopic as editCourseTopicAPI,
} from './api';

const log = Logger.create('db/topics');

function* addCourseTopicWorker(action: ReturnType<typeof addCourseTopic.request>) {
  const { requestId } = action.meta;
  try {
    const { topicId }: YieldCallType<typeof addCourseTopicAPI> = yield call(
      addCourseTopicAPI,
      action.payload
    );
    yield put(
      addCourseTopic.success(requestId, {
        ...action.payload,
        topicId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(addCourseTopic.failure(requestId));
  }
}

function* deleteCourseTopicWorker(action: ReturnType<typeof deleteCourseTopic.request>) {
  const { requestId } = action.meta;
  try {
    yield call(deleteCourseTopicAPI, action.payload);
    yield put(deleteCourseTopic.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(deleteCourseTopic.failure(requestId));
  }
}

function* editCourseTopicWorker(action: ReturnType<typeof editCourseTopic.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editCourseTopicAPI, action.payload);
    yield put(editCourseTopic.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editCourseTopic.failure(requestId));
  }
}

export default function* rootTopicsSaga() {
  try {
    yield takeLatest(addCourseTopic.request, addCourseTopicWorker);
    yield takeLatest(deleteCourseTopic.request, deleteCourseTopicWorker);
    yield takeLatest(editCourseTopic.request, editCourseTopicWorker);
  } catch (error) {
    log.error(error);
  }
}
