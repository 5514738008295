import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getAcadlyRouteByURL } from '../../pages/helpers';
import { DiscussionParams } from '../../pages/routes';
import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { activityUpdatedEvent } from '../courses/pusher-events';
import { selectCourseUser, selectCurrentCourse } from '../courses/selectors';
import { ActivityType, AppContext, ClassIncharge } from '../shared/types';
import {
  createDiscussion,
  createWordCloud,
  deleteDiscussion,
  discussionEdited,
  discussionPublished,
  editDiscussion,
  editPublishedDiscussion,
  editWordCloud,
  fetchAllDiscussions,
  fetchDiscussionPublishPrefs,
  fetchWordCloudDetails,
  markDiscussionAsViewed,
  publishDiscussion,
  saveDiscussionPublishPrefs,
  wordCloudAvailable,
} from './actions';
import {
  createDiscussion as createDiscussionAPI,
  createWordCloud as createWordCloudAPI,
  deleteDiscussion as deleteDiscussionAPI,
  editDiscussion as editDiscussionAPI,
  editPublishedDiscussion as editPublishedDiscussionAPI,
  editWordCloud as editWordCloudAPI,
  getAllDiscussions,
  getDiscussionPublishPrefs,
  getWordCloudDetails,
  markDiscussionAsViewed as markDiscussionAsViewedAPI,
  publishDiscussion as publishDiscussionAPI,
  saveDiscussionPublishPrefs as saveDiscussionPublishPrefsAPI,
} from './api';
import { discussionPublishedEvent, wordCloudAvailableEvent, wordCloudGeneratedEvent } from './pusher-events';
import { selectDiscussion } from './selectors';

const log = Logger.create('db/discussions');

function* fetchAllDiscussionsWorker(action: ReturnType<typeof fetchAllDiscussions.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const currentCourse: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!currentCourse) return;

    /** skip unnecessary API call */
    if (currentCourse.activities.discussions.total === 0) {
      yield put(
        fetchAllDiscussions.success(requestId, {
          activityData: [],
          userData: { discussions: {} },
          currentUser,
        })
      );
      return;
    }

    const response: YieldCallType<typeof getAllDiscussions> = yield call(getAllDiscussions);

    yield put(
      fetchAllDiscussions.success(requestId, {
        ...response,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAllDiscussions.failure(requestId));
  }
}

function* createDiscussionWorker(action: ReturnType<typeof createDiscussion.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const response: YieldCallType<typeof createDiscussionAPI> = yield call(
      createDiscussionAPI,
      action.payload
    );

    yield put(
      createDiscussion.success(requestId, {
        ...response,
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createDiscussion.failure(requestId));
  }
}

function* editDiscussionWorker(action: ReturnType<typeof editDiscussion.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    yield call(editDiscussionAPI, action.payload);
    yield put(
      editDiscussion.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editDiscussion.failure(requestId));
  }
}

function* activityUpdatedEventWorker(event: ReturnType<typeof activityUpdatedEvent>) {
  const { activityId, activityType } = event.payload;

  if (activityType !== ActivityType.DISCUSSION) return;

  yield put(discussionEdited(event.payload));

  const { route, match }: YieldCallType<typeof getAcadlyRouteByURL> = yield call(
    getAcadlyRouteByURL,
    window.location.pathname
  );

  if (!match?.params) return;

  const { discussionShortId } = match?.params as DiscussionParams;
  if (!discussionShortId) return;

  if (route?.context !== AppContext.DISCUSSION) return;
  if (!activityId.endsWith(discussionShortId)) return;

  // If user is already on a discussion page mark updates as seen to hide edited tag on discussion widget

  // TODO: add incrementDiscussionViews action
}

function* deleteDiscussionWorker(action: ReturnType<typeof deleteDiscussion.request>) {
  const { requestId } = action.meta;
  const { discussionId } = action.payload;
  try {
    const discussion: YieldSelectorType<typeof selectDiscussion> = yield select(
      selectDiscussion(discussionId)
    );
    if (!discussion) return;
    yield call(deleteDiscussionAPI, action.payload);
    yield put(deleteDiscussion.success(requestId, { discussion }));
  } catch (error) {
    log.error(error);
    yield put(deleteDiscussion.failure(requestId));
  }
}

function* fetchDiscussionPublishPrefsWorker(action: ReturnType<typeof fetchDiscussionPublishPrefs.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getDiscussionPublishPrefs> = yield call(
      getDiscussionPublishPrefs,
      action.payload
    );
    yield put(fetchDiscussionPublishPrefs.success(requestId, { ...action.payload, ...response }));
  } catch (error) {
    log.error(error);
    yield put(fetchDiscussionPublishPrefs.failure(requestId));
  }
}

function* saveDiscussionPublishPrefsWorker(action: ReturnType<typeof saveDiscussionPublishPrefs.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveDiscussionPublishPrefsAPI, action.payload);
    yield put(saveDiscussionPublishPrefs.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(saveDiscussionPublishPrefs.failure(requestId));
  }
}

function* publishDiscussionWorker(action: ReturnType<typeof publishDiscussion.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof publishDiscussionAPI> = yield call(
      publishDiscussionAPI,
      action.payload
    );
    yield put(publishDiscussion.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(publishDiscussion.failure(requestId));
  }
}

function* discussionPublishedEventWorker(event: ReturnType<typeof discussionPublishedEvent>) {
  const { discussionId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const discussion: YieldSelectorType<typeof selectDiscussion> = yield select(selectDiscussion(discussionId));

  yield put(discussionPublished({ ...event.payload, currentUser, doesExist: Boolean(discussion) }));
}

function* markDiscussionAsViewedWorker(action: ReturnType<typeof markDiscussionAsViewed.request>) {
  const { requestId } = action.meta;
  const { discussionId } = action.payload;
  try {
    const discussion: YieldSelectorType<typeof selectDiscussion> = yield select(
      selectDiscussion(discussionId)
    );
    if (!discussion) return;

    const currentTime = unix();
    const isFirstAccess = discussion.firstAccessedOn <= 0;

    if (isFirstAccess) {
      yield call(markDiscussionAsViewedAPI, { discussionId, currentTime });
    }

    yield put(
      markDiscussionAsViewed.success(requestId, {
        courseId: discussion.courseId,
        classId: discussion.classId,
        discussionId,
        currentTime,
        isFirstAccess,
        toBeDone: discussion.toBeDone,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(markDiscussionAsViewed.failure(requestId));
  }
}

function* editPublishedDiscussionWorker(action: ReturnType<typeof editPublishedDiscussion.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    yield call(editPublishedDiscussionAPI, action.payload);
    yield put(
      editPublishedDiscussion.success(requestId, {
        ...action.payload,
        currentUser: currentUser as ClassIncharge,
        editedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editPublishedDiscussion.failure(requestId));
  }
}

function* createWordCloudWorker(action: ReturnType<typeof createWordCloud.request>) {
  const { requestId } = action.meta;
  try {
    const result: YieldCallType<typeof createWordCloudAPI> = yield call(createWordCloudAPI, action.payload);
    yield put(
      createWordCloud.success(requestId, {
        ...action.payload,
        ...result,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(createWordCloud.failure(requestId));
  }
}

function* wordCloudGeneratedEventWorker(event: ReturnType<typeof wordCloudGeneratedEvent>) {
  const { activityId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const { route, match }: YieldCallType<typeof getAcadlyRouteByURL> = yield call(
    getAcadlyRouteByURL,
    window.location.pathname
  );

  if (!match?.params) return;

  const { discussionShortId } = match.params as DiscussionParams;
  if (!discussionShortId) return;

  if (route?.context !== AppContext.DISCUSSION) return;
  if (!activityId.endsWith(discussionShortId)) return;

  yield put(fetchWordCloudDetails.request({ discussionId: activityId }));
}

function* editWordCloudWorker(action: ReturnType<typeof editWordCloud.request>) {
  const { requestId } = action.meta;
  try {
    const result: YieldCallType<typeof editWordCloudAPI> = yield call(editWordCloudAPI, action.payload);
    yield put(
      editWordCloud.success(requestId, {
        ...action.payload,
        ...result,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editWordCloud.failure(requestId));
  }
}

function* fetchWordCloudDetailsWorker(action: ReturnType<typeof fetchWordCloudDetails.request>) {
  const { requestId } = action.meta;
  try {
    const result: YieldCallType<typeof getWordCloudDetails> = yield call(getWordCloudDetails, action.payload);
    yield put(
      fetchWordCloudDetails.success(requestId, {
        ...action.payload,
        ...result,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchWordCloudDetails.failure(requestId));
  }
}

function* wordCloudAvailableEventWorker(event: ReturnType<typeof wordCloudAvailableEvent>) {
  yield put(wordCloudAvailable(event.payload));
}

export default function* rootDiscussionsSaga() {
  try {
    yield takeLatest(fetchAllDiscussions.request, fetchAllDiscussionsWorker);

    yield takeLatest(createDiscussion.request, createDiscussionWorker);

    yield takeLatest(editDiscussion.request, editDiscussionWorker);
    yield takeEveryPusher(activityUpdatedEvent, activityUpdatedEventWorker);

    yield takeLatest(deleteDiscussion.request, deleteDiscussionWorker);
    yield takeLatest(fetchDiscussionPublishPrefs.request, fetchDiscussionPublishPrefsWorker);
    yield takeLatest(saveDiscussionPublishPrefs.request, saveDiscussionPublishPrefsWorker);

    yield takeLatest(publishDiscussion.request, publishDiscussionWorker);
    yield takeEveryPusher(discussionPublishedEvent, discussionPublishedEventWorker);

    yield takeLatest(markDiscussionAsViewed.request, markDiscussionAsViewedWorker);

    yield takeLatest(editPublishedDiscussion.request, editPublishedDiscussionWorker);

    yield takeLatest(createWordCloud.request, createWordCloudWorker);
    yield takeEveryPusher(wordCloudGeneratedEvent, wordCloudGeneratedEventWorker);

    yield takeLatest(editWordCloud.request, editWordCloudWorker);
    yield takeLatest(fetchWordCloudDetails.request, fetchWordCloudDetailsWorker);
    yield takeEveryPusher(wordCloudAvailableEvent, wordCloudAvailableEventWorker);
  } catch (error) {
    log.error(error);
  }
}
