import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import CheckboxCheckedIcon from '../../../icons.deprecated/CheckboxCheckedIcon';
import CheckboxIndeterminateIcon from '../../../icons.deprecated/CheckboxIndeterminateIcon';
import CheckboxUncheckedIcon from '../../../icons.deprecated/CheckboxUncheckedIcon';
import { checkboxClasses, Props, StyledCheckbox, StyledFormControlLabel } from './styles';

export type { Props };
export { checkboxClasses };

const Checkbox = (
  { label, labelPlacement, disabled, className, classes, ...props }: Props,
  ref: Ref<HTMLLabelElement>
) => {
  return (
    <StyledFormControlLabel
      ref={ref}
      label={label}
      labelPlacement={labelPlacement}
      disabled={disabled}
      className={clsx(checkboxClasses.root, className, classes?.root)}
      classes={{
        ...checkboxClasses,
        ...classes,
      }}
      control={
        <StyledCheckbox
          {...props}
          label={label}
          className={clsx(checkboxClasses.checkbox, classes?.checkbox)}
          classes={{
            ...checkboxClasses,
            ...classes,
            root: clsx(checkboxClasses.checkbox, classes?.checkbox),
          }}
          icon={
            <CheckboxUncheckedIcon fontSize="inherit" className={clsx(checkboxClasses.icon, classes?.icon)} />
          }
          checkedIcon={
            <CheckboxCheckedIcon
              fontSize="inherit"
              className={clsx(checkboxClasses.checkedIcon, classes?.checkedIcon)}
            />
          }
          indeterminateIcon={
            <CheckboxIndeterminateIcon
              fontSize="inherit"
              className={clsx(checkboxClasses.indeterminateIcon, classes?.indeterminateIcon)}
            />
          }
        />
      }
    />
  );
};

export default forwardRef(Checkbox);
