import { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlySwitch, { Props as AcadlySwitchProps } from '../FormControls/Switch';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useSwitchVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

export type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlySwitchProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const Switch = <T extends FieldValues>({ control, name, defaultValue, ...props }: Props<T>) => {
  const { controller } = useSwitchVM({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <AcadlySwitch
      {...props}
      inputRef={inputRef}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      checked={Boolean(value)}
      aria-invalid={Boolean(error)}
    />
  );
};

export default Switch;
