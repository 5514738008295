import { call, fork, select, take } from 'redux-saga/effects';

import { selectAuthSession } from '../auth/store/selectors';
import { CourseRole } from '../types';
import Logger from '../utils/logger';
import pusherBus from './bus';
import pusherEventChannel from './channel';
import notificationWorker from './notification-worker';
import { PusherEvent, PusherEventCreator, PusherEventName, PusherEventPayload } from './types';

const logger = Logger.create('pusher');

type PusherEventHandler<P extends {}, EventName extends PusherEventName> = (
  event: PusherEvent<P, EventName>
) => any;

const subscribersByEventName: {
  [key in PusherEventName]?: PusherEventHandler<PusherEventPayload, PusherEventName>[];
} = {};

/**
 * Calls worker on receipt of every pusher event corresponding
 * to pusher event creator
 * @param eventCreator pusher event creator
 * @param worker event handler for this event
 */
export function* takeEveryPusher<P extends {}, EventName extends PusherEventName>(
  eventCreator: PusherEventCreator<P, EventName>,
  worker: PusherEventHandler<P, EventName>
) {
  const subscribers: PusherEventHandler<any, PusherEventName>[] =
    subscribersByEventName[eventCreator.eventName] || [];

  yield call([subscribers, subscribers.push], worker as PusherEventHandler<any, PusherEventName>);

  subscribersByEventName[eventCreator.eventName] = subscribers;
}

export default function* rootPusherEventWatcher() {
  while (true) {
    const event: PusherEvent<PusherEventPayload, PusherEventName> = yield take(pusherEventChannel);
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    /**
     * Ignore pusher for same user.
     *
     * FIXME: ideally we should ignore the event only for the user
     * on the same device or browser tab. We can fix this if the server
     * sends a token that is unique for every pusher connection.
     */
    const isSameUser = event.payload.sender && event.payload.sender.userId === session?.userId;

    if (isSameUser && event.meta.ignoreForSameUser) continue;

    // FIXME: added to handle undefined errors,
    if (session && !event.payload.sender) {
      logger.warn(`sender key is missing in ${event.meta.eventName} event`);
      event.payload.sender = {
        userId: session.userId,
        name: session.name,
        avatar: session.avatar,
        role: CourseRole.STUDENT,
      };
    }

    // get list of all subscribers to this event
    const subscribers = subscribersByEventName[event.type];

    if (subscribers?.length) {
      for (const subscriber of subscribers) {
        // run all subscribers in parallel
        yield fork(subscriber, event);
      }
    }

    yield call(pusherBus.broadcast, event);
    yield call(notificationWorker, event);
  }
}
