import { call, put, putResolve, select, takeLatest, takeLeading } from 'redux-saga/effects';

import i18n, { i18nNS } from '../../i18n';
import Logger from '../../utils/logger';
import { NextScreen } from '../types';
import { UserSession } from '../types';
import {
  cancelFacultyAccountVerification,
  changeName,
  changePassword,
  checkLoginEmail,
  checkSignupEmail,
  createProfile,
  fetchChangeNameStatus,
  fetchCourseByCode,
  fetchUserRegistration,
  forgotPassword,
  getSsoStatus,
  logout,
  resendTempPass,
  setPassword,
  setUserSession,
  studentSignup,
  teacherSignup,
  tryLogin,
  trySsoLogin,
  verifyTempPass,
} from './actions';
import {
  cancelFacultyAccountVerification as cancelFacultyAccountVerificationAPI,
  changeName as changeNameAPI,
  changePassword as changePasswordAPI,
  createProfile as createProfileAPI,
  forgotPassword as forgotPasswordAPI,
  getChangeNameStatus,
  getCourseByCode,
  getServer,
  getSsoStatus as getSsoStatusAPI,
  getUserRegistration,
  login,
  logout as logoutAPI,
  resendTempPass as resendTempPassAPI,
  setPassword as setPasswordAPI,
  ssoLogin,
  studentSignup as studentSignupAPI,
  teacherSignup as teacherSignupAPI,
  verifyTempPass as verifyTempPassAPI,
} from './api';
import { selectAuthSession } from './selectors';
import { clearSession, setSession } from './session';

const log = Logger.create('auth');

function* setUserSessionWorker(action: ReturnType<typeof setUserSession>) {
  try {
    const { session } = action.payload;
    if (session) yield call(setSession, { ...session, isAuthenticated: true });
    else yield call(clearSession);
  } catch (error) {
    log.error(error);
  }
}

function* checkLoginEmailWorker(action: ReturnType<typeof checkLoginEmail.request>) {
  const { requestId } = action.meta;
  try {
    yield putResolve(setUserSession({ session: null }));
    const getServerResponse: YieldCallType<typeof getServer> = yield call(getServer, action.payload);
    const { cluster, next: getServerNext } = getServerResponse;

    if (getServerNext === NextScreen.SSO) {
      const { next, authUrl, sessionId } = getServerResponse;
      yield put(checkLoginEmail.success(requestId, { next, authUrl, sessionId }));
      return;
    }

    if (getServerNext !== NextScreen.CHANGE_SERVER) {
      yield put(checkLoginEmail.success(requestId, { next: getServerNext }));
      return;
    }

    const fetchUserRegistrationResponse: ReturnType<typeof fetchUserRegistration.success>['payload'] =
      yield putResolve(fetchUserRegistration.request({ baseURL: cluster, emailId: action.payload.emailId }));

    const { next } = fetchUserRegistrationResponse;

    yield put(checkLoginEmail.success(requestId, { next }));
  } catch (error) {
    log.error(error);
    yield put(checkLoginEmail.failure(requestId));
  }
}

function* getSsoStatusWorker(action: ReturnType<typeof getSsoStatus.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getSsoStatusAPI> = yield call(getSsoStatusAPI, action.payload);
    yield put(getSsoStatus.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(getSsoStatus.failure(requestId));
  }
}

function* fetchUserRegistrationWorker(action: ReturnType<typeof fetchUserRegistration.request>) {
  const { requestId } = action.meta;
  try {
    const { baseURL, emailId } = action.payload;
    const response: YieldCallType<typeof getUserRegistration> = yield call(getUserRegistration, baseURL, {
      emailId,
    });

    const { avatarURL, userExists, profileCompleted, verified, university, ...session } = response;

    const updatedSession: UserSession = {
      ...session,
      cluster: baseURL,
      email: emailId,
      s3BucketURL: avatarURL,
      userExists: Boolean(userExists),
      profileCompleted: Boolean(profileCompleted),
      verified: Boolean(verified),
      university: {
        name: university.univName,
        slug: university.univSlug,
      },
    };

    yield put(setUserSession({ session: updatedSession }));
    yield put(fetchUserRegistration.success(requestId, { next: response.next }));
  } catch (error) {
    log.error(error);
    yield put(fetchUserRegistration.failure(requestId));
  }
}

function* tryLoginWorker(action: ReturnType<typeof tryLogin.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const { userId } = session;
    const { password } = action.payload;

    const result: YieldCallType<typeof login> = yield call(login, { userId, password });

    if (!result.isSuccess) {
      yield put(tryLogin.success(requestId, { isSuccess: result.isSuccess, message: result.message }));
      return;
    }

    const { socket, token, next } = result.data;

    const updatedSession: UserSession = {
      ...session,
      socket,
      token,
    };

    if (next === NextScreen.LANDING_PAGE) {
      yield put(setUserSession({ session: updatedSession }));
      yield put(tryLogin.success(requestId, { isSuccess: result.isSuccess, next }));
    } else {
      yield put(tryLogin.success(requestId, { isSuccess: result.isSuccess, socket, token, next }));
    }
  } catch (error) {
    log.error(error);
    yield put(tryLogin.failure(requestId));
  }
}

function* trySsoLoginWorker(action: ReturnType<typeof trySsoLogin.request>) {
  const { requestId } = action.meta;
  try {
    const { baseURL, sessionId } = action.payload;
    const result: YieldCallType<typeof ssoLogin> = yield call(ssoLogin, baseURL, { sessionId });

    const {
      userId,
      emailId,
      name,
      avatar,
      avatarURL,
      university,
      profileCompleted,
      canCreateCourses,
      socket,
      token,
      next,
    } = result;

    const updatedSession: UserSession = {
      cluster: baseURL,
      userId,
      email: emailId,
      name,
      avatar,
      s3BucketURL: avatarURL,
      userExists: true,
      profileCompleted: Boolean(profileCompleted),
      verified: true,
      university: {
        name: university.univName,
        slug: university.univSlug,
      },
      canCreateCourses,
      socket,
      token,
    };

    if (next === NextScreen.LANDING_PAGE) {
      yield put(setUserSession({ session: updatedSession }));
      yield put(trySsoLogin.success(requestId, { next }));
    } else {
      yield put(trySsoLogin.success(requestId, { socket, token, next }));
    }
  } catch (error) {
    log.error(error);
    yield put(trySsoLogin.failure(requestId));
  }
}

function* forgotPasswordWorker(action: ReturnType<typeof forgotPassword.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const response: YieldCallType<typeof forgotPasswordAPI> = yield call(forgotPasswordAPI, {
      userId: session.userId,
    });

    const { userExists, profileCompleted, university, next, ...responseSession } = response;

    const updatedSession = {
      ...session,
      ...responseSession,
      userExists: Boolean(userExists),
      profileCompleted: Boolean(profileCompleted),
      university: {
        name: university.univName,
        slug: university.univSlug,
      },
    };

    yield put(setUserSession({ session: updatedSession }));
    yield put(forgotPassword.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(forgotPassword.failure(requestId));
  }
}

function* resendTempPassWorker(action: ReturnType<typeof resendTempPass.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    yield call(resendTempPassAPI, { userId: session.userId });
    yield put(resendTempPass.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(resendTempPass.failure(requestId));
  }
}

function* verifyTempPassWorker(action: ReturnType<typeof verifyTempPass.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const { tempPass } = action.payload;
    const result: YieldCallType<typeof verifyTempPassAPI> = yield call(verifyTempPassAPI, {
      userId: session.userId,
      tempPass,
    });
    yield put(verifyTempPass.success(requestId, result));
  } catch (error) {
    log.error(error);
    yield put(verifyTempPass.failure(requestId));
  }
}

function* setPasswordWorker(action: ReturnType<typeof setPassword.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const { password } = action.payload;
    const result: YieldCallType<typeof setPasswordAPI> = yield call(setPasswordAPI, {
      userId: session.userId,
      password,
    });

    switch (result.next) {
      case NextScreen.PROFILE_NOT_COMPLETED: {
        const { next, socket, token } = result;

        yield put(setPassword.success(requestId, { next, socket, token }));
        break;
      }
      case NextScreen.LANDING_PAGE: {
        const updatedSession: UserSession = {
          ...session,
          socket: result.socket,
          token: result.token,
        };

        yield put(setUserSession({ session: updatedSession }));

        yield put(setPassword.success(requestId, { next: result.next }));
        break;
      }
      case NextScreen.WINDOW_EXPIRED:
        yield put(setPassword.success(requestId, { next: result.next }));
        break;
      default:
        log.error(new Error(`Unexpected value of "next": ${(result as any).next}`));
        break;
    }
  } catch (error) {
    log.error(error);
    yield put(setPassword.failure(requestId));
  }
}

function* createProfileWorker(action: ReturnType<typeof createProfile.request>) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const { token, name } = action.payload;

    yield call(createProfileAPI, token, { name });

    const updatedSession = {
      ...session,
      name,
    };
    yield put(setUserSession({ session: updatedSession }));
    yield put(createProfile.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(createProfile.failure(requestId));
  }
}

function* logoutWorker(action: ReturnType<typeof logout>) {
  try {
    if (!action.payload?.skipServerRequest) {
      yield call(logoutAPI);
    }
  } catch (error) {
    log.error(error);
  } finally {
    localStorage.clear();
    yield put(setUserSession({ session: null }));
  }
}

function* checkSignupEmailWorker(action: ReturnType<typeof checkSignupEmail.request>) {
  const { requestId } = action.meta;
  try {
    yield putResolve(setUserSession({ session: null }));
    const getServerResponse: YieldCallType<typeof getServer> = yield call(getServer, action.payload);
    const { next: getServerNext } = getServerResponse;

    if (getServerNext === NextScreen.GET_ROLE_UE) {
      yield put(
        checkSignupEmail.success(requestId, { next: getServerNext, univName: getServerResponse.univName })
      );
      return;
    }

    if (getServerNext === NextScreen.GET_PASSWORD) return;

    // sso does not exist for signup flow
    if (getServerNext === 'sso') return;

    yield put(checkSignupEmail.success(requestId, { next: getServerNext }));
  } catch (error) {
    log.error(error);
    yield put(checkSignupEmail.failure(requestId));
  }
}

function* teacherSignupWorker(action: ReturnType<typeof teacherSignup.request>) {
  const { requestId } = action.meta;
  try {
    const teacherSignupResponse: YieldCallType<typeof teacherSignupAPI> = yield call(
      teacherSignupAPI,
      action.payload
    );

    const { next: teacherSignupNext } = teacherSignupResponse;

    if (teacherSignupNext !== NextScreen.CHANGE_SERVER) {
      yield put(teacherSignup.success(requestId, { next: teacherSignupNext }));
      return;
    }

    const { cluster } = teacherSignupResponse;

    const fetchUserRegistrationResponse: ReturnType<typeof fetchUserRegistration.success>['payload'] =
      yield putResolve(fetchUserRegistration.request({ baseURL: cluster, emailId: action.payload.emailId }));

    const { next } = fetchUserRegistrationResponse;

    // this scenario is never possible, because you cannot ask for password without first creating it
    if (next === NextScreen.GET_PASSWORD) return;

    yield put(teacherSignup.success(requestId, { next }));
  } catch (error) {
    log.error(error);
    yield put(teacherSignup.failure(requestId));
  }
}

function* fetchCourseByCodeWorker(action: ReturnType<typeof fetchCourseByCode.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCourseByCode> = yield call(getCourseByCode, action.payload);
    yield put(fetchCourseByCode.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseByCode.failure(requestId));
  }
}

function* studentSignupWorker(action: ReturnType<typeof studentSignup.request>) {
  const { requestId } = action.meta;
  try {
    const studentSignupResponse: YieldCallType<typeof studentSignupAPI> = yield call(
      studentSignupAPI,
      action.payload
    );
    const { cluster } = studentSignupResponse;

    const fetchUserRegistrationResponse: ReturnType<typeof fetchUserRegistration.success>['payload'] =
      yield putResolve(fetchUserRegistration.request({ baseURL: cluster, emailId: action.payload.emailId }));

    const { next } = fetchUserRegistrationResponse;

    // this scenario is never possible, because you cannot ask for password without first creating it
    if (next === NextScreen.GET_PASSWORD) return;

    yield put(studentSignup.success(requestId, { next }));
  } catch (error) {
    log.error(error);
    yield put(studentSignup.failure(requestId));
  }
}

function* changePasswordWorker(action: ReturnType<typeof changePassword.request>) {
  const { requestId } = action.meta;
  try {
    yield call(changePasswordAPI, action.payload);
    yield put(changePassword.success(requestId));
    yield put(
      logout({
        message: i18n.t('your_password_has_been_changed_kindly_log_in_again_to_continue', {
          ns: i18nNS.AUTH,
        }),
        skipServerRequest: true,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(changePassword.failure(requestId));
  }
}

function* fetchChangeNameStatusWorker(action: ReturnType<typeof fetchChangeNameStatus.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getChangeNameStatus> = yield call(getChangeNameStatus);
    yield put(fetchChangeNameStatus.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchChangeNameStatus.failure(requestId));
  }
}

function* changeNameWorker(action: ReturnType<typeof changeName.request>) {
  const { requestId } = action.meta;
  try {
    yield call(changeNameAPI, action.payload);
    yield put(changeName.success(requestId));
    yield put(logout({ skipServerRequest: true }));
  } catch (error) {
    log.error(error);
    yield put(changeName.failure(requestId));
  }
}

function* cancelFacultyAccountVerificationWorker(
  action: ReturnType<typeof cancelFacultyAccountVerification.request>
) {
  const { requestId } = action.meta;
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);
    if (!session) return;

    const response: YieldCallType<typeof cancelFacultyAccountVerificationAPI> = yield call(
      cancelFacultyAccountVerificationAPI
    );

    const updatedSession: UserSession = {
      ...session,
      canCreateCourses: response.canCreateCourses,
    };

    yield put(setUserSession({ session: updatedSession }));

    yield put(cancelFacultyAccountVerification.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(cancelFacultyAccountVerification.failure(requestId));
  }
}

export default function* rootAuthSaga() {
  try {
    yield takeLatest(setUserSession, setUserSessionWorker);
    yield takeLatest(checkLoginEmail.request, checkLoginEmailWorker);
    yield takeLatest(getSsoStatus.request, getSsoStatusWorker);
    yield takeLatest(fetchUserRegistration.request, fetchUserRegistrationWorker);
    yield takeLatest(tryLogin.request, tryLoginWorker);
    yield takeLatest(trySsoLogin.request, trySsoLoginWorker);
    yield takeLatest(forgotPassword.request, forgotPasswordWorker);
    yield takeLatest(resendTempPass.request, resendTempPassWorker);
    yield takeLatest(verifyTempPass.request, verifyTempPassWorker);
    yield takeLatest(setPassword.request, setPasswordWorker);
    yield takeLatest(createProfile.request, createProfileWorker);
    yield takeLeading(logout, logoutWorker);
    yield takeLatest(checkSignupEmail.request, checkSignupEmailWorker);
    yield takeLatest(teacherSignup.request, teacherSignupWorker);
    yield takeLatest(fetchCourseByCode.request, fetchCourseByCodeWorker);
    yield takeLatest(studentSignup.request, studentSignupWorker);
    yield takeLatest(changePassword.request, changePasswordWorker);
    yield takeLatest(fetchChangeNameStatus.request, fetchChangeNameStatusWorker);
    yield takeLatest(changeName.request, changeNameWorker);
    yield takeLatest(cancelFacultyAccountVerification.request, cancelFacultyAccountVerificationWorker);
  } catch (error) {
    log.error(error);
  }
}
