import { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthSession, selectIsProvisionalAccount } from '../auth/store/selectors';
import { selectArchivedCourses, selectCourses, selectUniversityData } from '../db/courses/selectors';
import { usePageManager } from '../pages/hooks';
import { AcadlyPage } from '../pages/types';

const useHomeVM = () => {
  const { isPageReady } = usePageManager(AcadlyPage.HOME, { setPageReadyWhenParentIsReady: true });

  const activeCourses = useSelector(selectCourses);
  const archivedCourses = useSelector(selectArchivedCourses);

  const session = useSelector(selectAuthSession)!;
  const universityData = useSelector(selectUniversityData);
  const isProvisionalAccount = useSelector(selectIsProvisionalAccount);

  const hasCourses = activeCourses.length > 0;
  const isNoCoursesCardVisible = isPageReady && !hasCourses && !isProvisionalAccount;

  const [isCreatingProCourse, setIsCreatingProCourse] = useState(false);
  const [isInformationAlertOpen, setIsInformationAlertOpen] = useState(false);
  const [isCreateCourseDrawerOpen, setIsCreateCourseDrawerOpen] = useState(false);
  const [isJoinAnExistingCourseDrawerOpen, setIsJoinAnExistingCourseDrawerOpen] = useState(false);

  const [selectedCourseId, setSelectedCourseId] = useState<MongoId>();

  const onClickCreateCourse = () => {
    if (universityData?.hasEnterpriseAccount && universityData.integratedWith) {
      setIsInformationAlertOpen(true);
    } else {
      setIsCreateCourseDrawerOpen(true);
    }
  };

  const onCloseInformationAlert = () => {
    setIsInformationAlertOpen(false);
  };

  const onContinueInformationAlert = () => {
    setIsInformationAlertOpen(false);
    setIsCreateCourseDrawerOpen(true);
  };

  const onCloseCreateCourseDrawer = () => {
    setSelectedCourseId(undefined);
    setIsCreateCourseDrawerOpen(false);
  };

  const onClickCourseWidget = (courseId: MongoId, isCreatingProCourse: boolean) => {
    setSelectedCourseId(courseId);
    setIsCreatingProCourse(isCreatingProCourse);
    setIsCreateCourseDrawerOpen(true);
  };

  const onClickJoinAnExistingCourse = () => {
    setIsJoinAnExistingCourseDrawerOpen(true);
  };

  const onCloseJoinAnExistingCourseDrawer = () => {
    setIsJoinAnExistingCourseDrawerOpen(false);
  };

  return {
    isNoCoursesCardVisible,
    activeCourses,
    archivedCourses,
    isCreateCourseDrawerOpen,
    isFetchingCourses: !isPageReady,
    isInformationAlertOpen,
    isJoinAnExistingCourseDrawerOpen,
    isProvisionalAccount,
    onClickCourseWidget,
    onClickCreateCourse,
    onClickJoinAnExistingCourse,
    onCloseCreateCourseDrawer,
    onCloseInformationAlert,
    onCloseJoinAnExistingCourseDrawer,
    onContinueInformationAlert,
    selectedCourseId,
    isCreatingProCourse,
    session,
  };
};

export default useHomeVM;
