import { SyntheticEvent } from 'react';

import { styled } from '@mui/material/styles';

import { Props as ButtonProps } from '../../components/Button/index';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import TabPanel from '../../components/Tabs/TabPanel';
import Buttons from './Buttons';

export enum TabItems {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

interface Props {
  activeTab: TabItems;
  onChangeTab: (newValue: TabItems) => void;
}

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.contrastText[600],
  '&.Mui-selected': {
    color: theme.palette.primary[600],
  },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
}));

const colors: NonNullable<ButtonProps['color']>[] = ['primary', 'success', 'warning', 'error'];

const ButtonTabs = ({ activeTab, onChangeTab: handleChangeTab }: Props) => {
  const handleChangeActiveTab = (event: SyntheticEvent, newValue: TabItems) => {
    handleChangeTab(newValue);
  };

  return (
    <>
      <StyledTabs variant="fullWidth" value={activeTab} onChange={handleChangeActiveTab}>
        <StyledTab value={TabItems.CONTAINED} label="Contained" />
        <StyledTab value={TabItems.OUTLINED} label="Outlined" />
        <StyledTab value={TabItems.TEXT} label="Text" />
      </StyledTabs>
      <StyledTabPanel isActive>
        {colors.map((color) => (
          <Buttons key={color} color={color} variant={activeTab} />
        ))}
        <Buttons disabled variant={activeTab} />
      </StyledTabPanel>
    </>
  );
};

export default ButtonTabs;
