import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../../../../../components/Button';
import { generateClasses } from '../../../../../../../../utils/helpers';
import { forMobile } from '../../../../../../../../utils/media-queries';
import CommentCreator from '../CommentCreator';

const commentDialogClasses = {
  ...generateClasses('CommentDialog', ['headerAction', 'footerAction', 'footerCloseButton']),
};

export const StyledCommentCreator = styled(CommentCreator)(({ theme }) => ({
  flex: 1,
}));

export const HeaderAction = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
      ref={ref}
      variant="text"
      color="primary"
      size="small"
      edge="end"
      {...props}
      className={clsx(commentDialogClasses.headerAction, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${commentDialogClasses.headerAction}`]: {
    color: theme.palette.grey[500],
  },
}));

export const Footer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(2, 5),
  [forMobile(theme)]: {
    padding: theme.spacing(2, 3),
  },
}));
