import { Children, cloneElement, ElementType, forwardRef, isValidElement, Ref, useMemo } from 'react';

import Alert from '../Alert';
import Button, { Props as ButtonProps } from '../Button';
import useAlertButtonVM, { Props as VMProps } from './vm';

export type Props<C extends ElementType<any> = 'button'> = ButtonProps<C> & DistributiveOmit<VMProps, 'ref'>;

const AlertButton = <C extends ElementType<any>>(
  { onClick, onBeforeOpenAlert, canOpenAlert, children: _children, isButtonfocusable, ...props }: Props<C>,
  ref: Ref<HTMLButtonElement>
) => {
  const { alertButtonRef, isAlertOpen, handleCloseAlert, handleClick } = useAlertButtonVM({
    ref,
    onClick,
    onBeforeOpenAlert,
    canOpenAlert,
    isButtonfocusable,
  });

  const { alert, children } = useMemo(() => {
    const childrenArray = Children.toArray(_children);
    const alert = childrenArray.find((child) => {
      // FIXME: check for wrapped Alert as well to allow using StyledAlert
      return isValidElement(child) && child.type === Alert;
    });
    const children = childrenArray.filter((child) => child !== alert);
    return { alert, children };
  }, [_children]);

  return (
    <>
      <Button {...props} ref={alertButtonRef} onClick={handleClick}>
        {children}
      </Button>
      {isValidElement(alert) &&
        cloneElement(
          alert,
          { ...alert.props, open: isAlertOpen, onClose: handleCloseAlert },
          alert.props.children
        )}
    </>
  );
};

export default forwardRef(AlertButton) as <C extends ElementType<any> = 'button'>(
  props: Props<C> & { ref?: Ref<HTMLButtonElement> }
) => JSX.Element;
