import { useEffect, useState } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlateStatic } from 'slate-react';

import { uploadAndUpdateImageNode } from '../helpers';
import { ImageElement, ImageUploadStatus } from '../types';

export interface VMProps {
  element: ImageElement;
}

export default function useImageElementVM({ element }: VMProps) {
  const editor = useSlateStatic();

  const isFocused = useFocused();
  const isSelected = useSelected();

  const [isImagePreviewDialogOpen, setIsImagePreviewDialogOpen] = useState(false);

  const handleImageClick = () => {
    if (isFocused || isSelected) return;
    setIsImagePreviewDialogOpen(true);
  };

  const handleCloseImagePreviewDialog = () => {
    setIsImagePreviewDialogOpen(false);
  };

  const removeImage = () => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.removeNodes(editor, { at: path });
  };

  useEffect(
    function init() {
      if (element.uploadStatus !== ImageUploadStatus.NOT_UPLOADED) return;

      uploadAndUpdateImageNode(editor, element).catch(() => {
        // swallow error
      });
    },
    [editor, element]
  );

  return {
    isFocused,
    isSelected,
    removeImage,
    isImagePreviewDialogOpen,
    handleImageClick,
    handleCloseImagePreviewDialog,
  };
}
