import { styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';
import AuthLayout, { Props as AuthLayoutProps } from '../Layout';

const setProfileAvatarClasses = generateClasses('SetProfileAvatar', ['title']);

export const Layout = styled((props: AuthLayoutProps) => (
  <AuthLayout
    {...props}
    classes={{
      ...props.classes,
      contentHeader: clsx(setProfileAvatarClasses.title, props.classes?.contentHeader),
    }}
  />
))(({ theme }) => ({
  [`& .${setProfileAvatarClasses.title}`]: {
    alignItems: 'center',
  },
}));
