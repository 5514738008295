import { useTranslation } from 'react-i18next';

import { Link, Typography } from '@mui/material';

import Button from '../../../../components/Button';
import { i18nNS } from '../../../../i18n';
import CoursePaymentLayout from '../Layout';
import usePaymentFailedVM from './vm';

const PaymentFailed = () => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);
  const { children, closeDrawer, isVisible, retryPayment } = usePaymentFailedVM();

  return (
    <CoursePaymentLayout
      isVisible={isVisible}
      as="div"
      footer={
        <>
          <Button variant="text" onClick={closeDrawer}>
            {t('close', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Button onClick={retryPayment}>{t('retry_payment', { ns: i18nNS.COMMON })}</Button>
        </>
      }
    >
      <Typography>
        {children.map((item) => {
          if (item.type === 'string') return item.value;
          return (
            <Link href={item.value} target="_blank">
              {item.value}
            </Link>
          );
        })}
      </Typography>
    </CoursePaymentLayout>
  );
};

export default PaymentFailed;
