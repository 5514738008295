import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayArrow as PlayIcon } from '@mui/icons-material';

import { i18nNS } from '../../i18n';
import { Overlay, PlayButton, Root, StyledYoutube, youtubePlayerClasses } from './styles';
import useYoutubePlayerVM from './vm';

interface Props {
  videoId: string;
  overlayActions?: ReactNode;
}

const playerOptions = {
  width: '100%',
  playerVars: {
    enablejsapi: 1,
    controls: 1,
    modestbranding: 1,
  },
};

const YoutubePlayer = ({ videoId, overlayActions }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { showOverlay, handlePlayerReady, handlePlay } = useYoutubePlayerVM();

  return (
    <Root>
      <StyledYoutube videoId={videoId} opts={playerOptions} onReady={handlePlayerReady} />
      {showOverlay && (
        <Overlay>
          <PlayButton
            onClick={handlePlay}
            startIcon={<PlayIcon color="primary" aria-hidden className={youtubePlayerClasses.playIcon} />}
            aria-label={t('click_to_play_video', { ns: i18nNS.COMMON })}
          />
          {overlayActions}
        </Overlay>
      )}
    </Root>
  );
};

export default YoutubePlayer;
