import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import { ReactComponent as AcadlyLogo } from '../assets/acadly-logo-square.svg';
import { i18nNS } from '../i18n';

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  paddingLeft: theme.spacing(6),
}));

interface Props {
  className?: string;
}

const Help = ({ className }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);

  // TODO: add "To Enroll Students" screen when course has no enrollments

  return (
    <Stack className={className} gap={8} p={5}>
      <Stack direction="row" gap={2}>
        <AcadlyLogo width={46} height={46} />
        <Stack gap={1}>
          <Typography variant="h6Bold" component="div">
            {t('acadly_help', { ns: i18nNS.COURSES })}
          </Typography>
          <Typography variant="textSmRegular" component="div">
            {t('system_pipe_a_few_moments_ago', { ns: i18nNS.COURSES })}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={2}>
        <Typography variant="h6Bold" component="div">
          {t('publishing_the_course', { ns: i18nNS.COURSES })}
        </Typography>
        <Stack gap={4}>
          <Typography>
            {t(
              'please_check_that_the_class_schedule_on_the_course_timeline_accurately_reflects_your_course_schedule',
              { ns: i18nNS.COURSES }
            )}
          </Typography>
          <Typography>
            {t(
              'you_can_delete_unnecessary_classes_and_add_a_extra_classes_to_prune_the_timeline_to_your_liking',
              { ns: i18nNS.COURSES }
            )}
          </Typography>
          <Typography>
            {t('once_you_re_done_you_need_to_publish_the_course_to', { ns: i18nNS.COURSES })}
          </Typography>
          <List>
            <Typography component="li">
              {t('enroll_students_by_adding_their_email_addresses_to_the_course', { ns: i18nNS.COURSES })}
            </Typography>
            <Typography component="li">
              {t('allow_course_co_instructors_and_teaching_assistants_to_access_the_course', {
                ns: i18nNS.COURSES,
              })}
            </Typography>
            <Typography component="li">
              {t('create_and_publish_activities', { ns: i18nNS.COURSES })}
            </Typography>
            <Typography component="li">{t('record_attendance', { ns: i18nNS.COURSES })}</Typography>
          </List>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Help;
