import { useTranslation } from 'react-i18next';

import Button from '../../../../components/Button';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { i18nNS } from '../../../../i18n';
import { commentComposerDrawerClasses, Drawer, Image, ImageWrapper } from './styles';
import useCommentComposerDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const CommentComposerDrawer = ({ ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { open, initialMessage } = vmOptions;
  const { formMethods, onSubmit, handleCloseDrawer } = useCommentComposerDrawerVM(vmOptions);
  const { handleSubmit, control, watch, formState } = formMethods;
  const [image, attachments] = watch(['image', 'attachments']);
  const { isValid, isSubmitting } = formState;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      header={t('comment', { ns: i18nNS.GLOSSARY })}
      footer={
        <Button variant="text" onClick={handleSubmit(onSubmit)} disabled={!isValid} isLoading={isSubmitting}>
          {t('send', { ns: i18nNS.GLOSSARY })}
        </Button>
      }
      classes={{ body: commentComposerDrawerClasses.drawerContent }}
    >
      {image && (
        <ImageWrapper>
          <Image src={image} alt={t('attached_image', { ns: i18nNS.COMMENTS })} />
        </ImageWrapper>
      )}
      <RichTextEditor
        control={control}
        inputField={{ name: 'message', initialValue: initialMessage }}
        attachments={{ name: 'attachments', initialValue: attachments, uploadRequestConfig: undefined }}
        placeholder={t(
          image || attachments.length > 0 ? 'enter_an_optional_comment_here' : 'enter_comment_here',
          { ns: i18nNS.COMMENTS }
        )}
        toolbar={{
          canFormatText: true,
          canInsertFormula: true,
          canUploadImage: false,
        }}
        classes={{
          root: commentComposerDrawerClasses.richTextEditor,
          body: commentComposerDrawerClasses.richTextEditorBody,
          editable: commentComposerDrawerClasses.richTextEditorEditable,
          removeAttachmentButton: commentComposerDrawerClasses.removeAttachmentButton,
        }}
        showErrors={false}
      />
    </Drawer>
  );
};

export default CommentComposerDrawer;
