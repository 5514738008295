import DeadlineOver from './DeadlineOver';
import PaymentWarning from './PaymentWarning';
import useCourseFooterVM from './vm';

const CourseFooter = () => {
  const { isDeadlineOver } = useCourseFooterVM();

  if (isDeadlineOver) return <DeadlineOver />;

  return <PaymentWarning />;
};

export default CourseFooter;
