import { Draft } from '@reduxjs/toolkit';

import { MarkCommentAction, User } from '../../shared/types';
import { Comment, CommentMyReactions } from '../types';

function toggleLikeComment({
  comment,
  myReactions,
  hasLiked,
  isCurrentUser,
  user,
}: {
  comment: Draft<Comment>;
  myReactions: Draft<CommentMyReactions>;
  hasLiked: boolean;
  isCurrentUser: boolean;
  user: User;
}) {
  if (isCurrentUser) {
    myReactions.hasLiked = hasLiked;
  }

  if (hasLiked) {
    comment.stats.likes++;
  } else {
    comment.stats.likes--;
  }

  if (comment.reactions.likedBy) {
    comment.reactions.likedBy[user.userId] = hasLiked ? user : undefined;
  }
}

function toggleThankComment({
  comment,
  myReactions,
  hasThanked,
  isCurrentUser,
  user,
}: {
  comment: Draft<Comment>;
  myReactions: Draft<CommentMyReactions>;
  hasThanked: boolean;
  isCurrentUser: boolean;
  user: User;
}) {
  if (isCurrentUser) {
    myReactions.hasThanked = hasThanked;
  }

  if (hasThanked) {
    comment.stats.thanks++;
  } else {
    comment.stats.thanks--;
  }

  if (comment.reactions.thankedBy) {
    comment.reactions.thankedBy[user.userId] = hasThanked ? user : undefined;
  }
}

export function markCommentCaseReducer({
  comment,
  myReactions,
  mark,
  isCurrentUser,
  user,
}: {
  comment: Draft<Comment | undefined>;
  myReactions: Draft<CommentMyReactions>;
  mark: MarkCommentAction;
  isCurrentUser: boolean;
  user: User;
}) {
  if (!comment) return;

  switch (mark) {
    case MarkCommentAction.LIKE:
    case MarkCommentAction.UNLIKE: {
      toggleLikeComment({
        comment,
        myReactions,
        isCurrentUser,
        hasLiked: mark === MarkCommentAction.LIKE,
        user,
      });
      break;
    }
    case MarkCommentAction.THANK:
    case MarkCommentAction.UNTHANK: {
      toggleThankComment({
        comment,
        myReactions,
        isCurrentUser,
        hasThanked: mark === MarkCommentAction.THANK,
        user,
      });
      break;
    }
  }
}
