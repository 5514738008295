import { useSelector } from 'react-redux';

import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useCreatorFooterViewVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useSelector(selectOnlineMeeting(classId));

  const isBroadcasting = onlineDetails?.isBroadcastedFromCurrentDevice ?? false;

  return {
    isBroadcasting,
  };
}
