interface UploadedImageAttributes {
  /** Actual width of the image generated by the server */
  acw: string;
  /** Actual height of the image generated by the server */
  ach: string;
  /** URL of the image generated by the server */
  url: string;
}

export enum ImageUploadStatus {
  NOT_UPLOADED = 'not_uploaded',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
}

export interface ImageProps extends UploadedImageAttributes {
  /**
   * file name including file extension, used when file need to be
   * uploaded, if not available then `image` will be used as default value
   */
  fileName?: string;
  fileMimeType?: string;
  /** Whether the image has been uploaded to server or not */
  uploadStatus: ImageUploadStatus;
}

export interface ImageElement extends ImageProps {
  type: 'image';
  children: [{ text: '' }];
}

export type UploadImageFn = (
  file: File,
  onUploadProgress?: (event: ProgressEvent) => any
) => Promise<UploadedImageAttributes>;

export interface ImageEditorOptions {
  uploadImage: UploadImageFn;
}

export interface ImageEditor {
  /**
   * @returns `true` when editor has at least one image element
   */
  hasImage(): boolean;
  image: {
    upload: UploadImageFn;
  };
}
