import { Trans, useTranslation } from 'react-i18next';

import { Link, Stack, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import CreateCourseForm from '../CreateCourseForm';
import usePricingInquiryThanksVM from './vm';

const PricingInquiryThanks = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMON, i18nNS.CREATE_COURSE]);
  const { closeDrawer, isVisible } = usePricingInquiryThanksVM();
  return (
    <CreateCourseForm
      isVisible={isVisible}
      as="div"
      footer={<Button onClick={closeDrawer}>{t('okay', { ns: i18nNS.GLOSSARY })}</Button>}
    >
      <Stack gap={4}>
        <Typography variant="h5Bold">{t('thank_you', { ns: i18nNS.COMMON })}</Typography>
        <Typography>
          {t(
            'we_will_reach_out_to_you_with_our_instructor_pays_pricing_and_payment_information_via_email_in_the_next_24_hours',
            { ns: i18nNS.CREATE_COURSE }
          )}
        </Typography>
        <Typography>
          <Trans
            t={t}
            ns={i18nNS.CREATE_COURSE}
            i18nKey="please_send_us_an_email_at_support_email_if_you_need_to_expedite_this_process"
          >
            Please send us an email at <Link href="mailto://support@acadly.com">support@acadly.com</Link> if
            you need to expedite this process.
          </Trans>
        </Typography>
      </Stack>
    </CreateCourseForm>
  );
};

export default PricingInquiryThanks;
