import { styled, Typography, TypographyProps } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const selectClasses = {
  ...generateClasses('Select', ['root', 'placeholder', 'input']),
};

export const Placeholder = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[400],
}));
