import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import Button from '../../../../components/Button';
import { i18nNS } from '../../../../i18n';
import CoursePaymentLayout from '../Layout';
import usePaymentSuccessVM from './vm';

const PaymentSuccess = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);
  const { handleCloseDrawer, isVisible } = usePaymentSuccessVM();

  return (
    <CoursePaymentLayout
      isVisible={isVisible}
      footer={
        <Button variant="text" onClick={handleCloseDrawer}>
          {t('okay', { ns: i18nNS.GLOSSARY })}
        </Button>
      }
    >
      <Typography>
        {t('thank_you_for_the_payment_you_can_access_the_course', { ns: i18nNS.COURSES })}
      </Typography>
    </CoursePaymentLayout>
  );
};

export default PaymentSuccess;
