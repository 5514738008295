import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import { Props, sliderClasses, StyledSlider } from './styles';

export type { Props };

const Slider = ({ className, color, ...props }: Props, ref: Ref<HTMLSpanElement>) => {
  return (
    <StyledSlider
      {...props}
      ref={ref}
      className={clsx(className, {
        [sliderClasses.colorPrimary]: color === 'primary',
        [sliderClasses.colorSuccess]: color === 'success',
        [sliderClasses.colorError]: color === 'error',
        [sliderClasses.colorWarning]: color === 'warning',
      })}
    />
  );
};

export default forwardRef(Slider);
