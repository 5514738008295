import { useState } from 'react';

import { archiveCourse as archiveCourseAction } from '../../../../db/courses/actions';
import { useRequestDispatch } from '../../../../utils/request-actions';

const useCourseEndedInfoVM = () => {
  const requestDispatch = useRequestDispatch();

  const [isArchivingCourse, setIsArchivingCourse] = useState(false);

  const archiveCourse = async () => {
    try {
      setIsArchivingCourse(true);
      await requestDispatch(archiveCourseAction);
    } finally {
      setIsArchivingCourse(false);
    }
  };

  return { archiveCourse, isArchivingCourse };
};

export default useCourseEndedInfoVM;
