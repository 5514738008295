import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { fetchCoursePurchaseCost } from '../../../db/courses/actions';
import i18n, { i18nNS } from '../../../i18n';
import { formatCurrency } from '../../../utils/currency';
import { useRequestDispatch } from '../../../utils/request-actions';
import { COURSE_COUNT_OPTIONS, STUDENT_COUNT_OPTIONS } from '../../constants';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { getCanSendPricingRequest } from './helpers';
import { FormValues } from './types';
import validationSchema from './validators';

const useCostCalculator = () => {
  const requestDispatch = useRequestDispatch();
  const {
    activeStep,
    closeDrawer,
    moveToPreviousStep: _moveToPreviousStep,
    moveToNextStep,
    isCreatingProCourse,
    setIsCreatingProCourse,
    setNumCourses,
    setNumStudents,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const [isFetchingCourseCost, setIsFetchingCourseCost] = useState(false);
  const [courseCost, setCourseCost] = useState('');

  const [canSendPricingRequest, setCanSendPricingRequest] = useState(false);

  const isVisible = activeStep === CreateCourseStep.COST_CALCULATOR;
  const moveToPreviousStep = () => _moveToPreviousStep();

  const defaultValues: FormValues = useMemo(
    () => ({
      isCreatingProCourse: isCreatingProCourse ? 'true' : 'false',
      numCourses: COURSE_COUNT_OPTIONS[0].id,
      numStudents: STUDENT_COUNT_OPTIONS[0].id,
    }),
    [isCreatingProCourse]
  );

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues,
    mode: 'all',
  });

  const { setValue, handleSubmit, watch } = formMethods;

  const calculateCost: SubmitHandler<FormValues> = useCallback(
    async (data) => {
      try {
        const { isCreatingProCourse: isCreatingProCourseString, numCourses, numStudents } = data;
        const isCreatingProCourse = isCreatingProCourseString === 'true';

        const canSendPricingRequest = getCanSendPricingRequest(numCourses, numStudents);
        setCanSendPricingRequest(canSendPricingRequest);

        if (canSendPricingRequest) {
          setCourseCost(i18n.t('custom_pricing', { ns: i18nNS.CREATE_COURSE }));
          return;
        }

        setIsFetchingCourseCost(true);

        const numOfCourses = parseInt(numCourses, 10);

        const [lowerLimit] = numStudents.split('-');
        const numOfstudents = parseInt(lowerLimit, 10);

        const { cost } = await requestDispatch(fetchCoursePurchaseCost, {
          type: isCreatingProCourse ? 'pro' : 'basic',
          numCourses: numOfCourses,
          numStudents: numOfstudents,
        });

        const formattedCost = formatCurrency(cost);
        setCourseCost(formattedCost);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetchingCourseCost(false);
      }
    },
    [requestDispatch]
  );

  const handleSendPricingRequest: SubmitHandler<FormValues> = (data) => {
    const { numCourses, numStudents } = data;
    setNumCourses(numCourses);
    setNumStudents(numStudents);
    moveToNextStep({ skip: 3 });
  };

  const handlePay: SubmitHandler<FormValues> = async (data) => {
    const { isCreatingProCourse: isCreatingProCourseString, numCourses, numStudents } = data;
    const isCreatingProCourse = isCreatingProCourseString === 'true';

    setIsCreatingProCourse(isCreatingProCourse);
    setNumCourses(numCourses);
    setNumStudents(numStudents);
    moveToNextStep();
  };

  useEffect(() => {
    if (!isVisible) return;
    const subscription = watch(() => handleSubmit(calculateCost)(), defaultValues);
    return () => subscription.unsubscribe();
  }, [isVisible, handleSubmit, calculateCost, watch, defaultValues]);

  useEffect(() => {
    if (!isVisible) return;
    setValue('isCreatingProCourse', isCreatingProCourse ? 'true' : 'false');
  }, [isVisible, isCreatingProCourse, setValue]);

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('purchase_new_courses', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('purchase_new_courses', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return {
    formMethods,
    closeDrawer,
    isVisible,
    moveToPreviousStep,
    isFetchingCourseCost,
    canSendPricingRequest,
    courseCost,
    handleSendPricingRequest,
    handlePay,
  };
};

export default useCostCalculator;
