import { Stack, StackProps, styled } from '@mui/material';

import UserCell from '../../../../../../../../../components/UserCell/index';

export const UserReactions = styled((props: StackProps) => <Stack direction="column" gap={2} {...props} />)(
  ({ theme }) => ({
    margin: 0,
    paddingLeft: theme.spacing(2),
    listStyleType: 'none',
  })
) as typeof Stack;

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  padding: 0,
}));
