import { ReactNode } from 'react';

import { Stack, useTheme } from '@mui/material';

import clsx from 'clsx';

import Typography from '../../components/Typography';
import { layoutClasses, Logo, LogoLight, Root, StyledPaper, StyledUserCell } from './styles';
import useLayoutVM from './vm';

export interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  canSeeUserCell?: boolean;
  children: ReactNode;
  className?: string;
  classes?: Partial<Record<keyof typeof layoutClasses, string>>;
}

export { layoutClasses };

const Layout = ({ title, subtitle, canSeeUserCell, children, className, classes }: Props) => {
  const theme = useTheme();

  const { ref, session } = useLayoutVM();

  return (
    <Root ref={ref} className={clsx(layoutClasses.root, className, classes?.root)}>
      <StyledPaper
        elevation={theme.palette.mode === 'dark' ? 6 : 0}
        className={clsx(layoutClasses.paper, classes?.paper)}
      >
        {theme.palette.mode === 'dark' ? (
          <LogoLight className={clsx(layoutClasses.acadlyLogo, classes?.acadlyLogo)} />
        ) : (
          <Logo className={clsx(layoutClasses.acadlyLogo, classes?.acadlyLogo)} />
        )}
        <Stack gap={6} className={clsx(layoutClasses.content, classes?.content)}>
          <Stack gap={2} className={clsx(layoutClasses.contentHeader, classes?.contentHeader)}>
            {typeof title === 'string' ? (
              <Typography
                variant="h3Bold"
                color="grey.800"
                className={clsx(layoutClasses.title, classes?.title)}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
            {subtitle &&
              (typeof subtitle === 'string' ? (
                <Typography
                  variant="paragraphRegular"
                  color="grey.500"
                  className={clsx(layoutClasses.subtitle, classes?.subtitle)}
                >
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              ))}
            {canSeeUserCell && session && (
              <StyledUserCell
                classes={{
                  root: clsx(layoutClasses.userCell, classes?.userCell),
                  avatar: clsx(layoutClasses.avatar, classes?.avatar),
                }}
                avatar={session.avatar}
                title={session.name}
                subtitle={session.university.name}
                size="large"
              />
            )}
          </Stack>
          {children}
        </Stack>
      </StyledPaper>
    </Root>
  );
};

export default Layout;
