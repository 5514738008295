import urls from '../../app/urls';
import { UploadFormulaFn, UploadImageFn } from '../../rich-text';
import { getFileNameComponents } from '../../utils/file';
import request from '../../utils/request';

interface UploadFormulaRequest {
  display: 'inline' | 'block';
  format: 'am' | 'tex' | 'mml';
  math: string;
}

interface UploadFormulaResponse {
  _id: string;
  ach: number;
  acw: number;
  url: string;
}

export const uploadFormula: UploadFormulaFn = async (payload: UploadFormulaRequest) => {
  const { data } = await request.post<UploadFormulaResponse>(urls.uploadFormula, payload);
  return {
    id: data._id,
    ach: data.ach.toString(),
    acw: data.acw.toString(),
    url: data.url,
  };
};

interface UploadImageResponse {
  _id: string;
  ach: number;
  acw: number;
  url: string;
}

export const uploadImage: UploadImageFn = async (file: File, onUploadProgress) => {
  const { name, extension } = getFileNameComponents(file.name);

  const form = new FormData();
  form.append('file', file);
  form.append('originalFileName', name);
  form.append('fileType', extension);

  const { data } = await request.post<UploadImageResponse>(urls.uploadImage, form, {
    onUploadProgress,
  });

  return {
    ach: data.ach.toString(),
    acw: data.acw.toString(),
    url: data.url,
  };
};
