export enum FormulaFormat {
  TEX = 'tex',
  MATH_ML = 'mml',
  ASCII_MATH = 'am',
}

export enum FormulaDisplay {
  BLOCK = 'block',
  INLINE = 'inline',
}

interface UploadedFormulaAttributes {
  /** Unique id generated by server after creating a formula */
  id: string;
  /** Actual width of the image generated by the server for this formula */
  acw: string;
  /** Actual height of the image generated by the server for this formula */
  ach: string;
  /** URL of the image generated by the server for this formula */
  url: string;
}

export enum FormulaUploadStatus {
  NOT_UPLOADED = 'not_uploaded',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
}

export interface FormulaProps extends UploadedFormulaAttributes {
  /** Contents of the formula */
  code: string;
  /** Language used to create this formula like: tex, mathml or ascii-math */
  format: FormulaFormat;
  display: FormulaDisplay;
  uploadStatus: FormulaUploadStatus;
}

export interface FormulaElement extends FormulaProps {
  type: 'formula';
  children: [{ text: '' }];
}

export type UploadFormulaFn = (options: {
  display: 'block' | 'inline';
  format: FormulaFormat;
  math: string;
}) => Promise<UploadedFormulaAttributes>;

export interface FormulaEditorOptions {
  uploadFormula: UploadFormulaFn;
}

export interface FormulaEditor {
  /**
   * @returns `true` when editor has at least one formula element
   */
  hasFormula(): boolean;
  formula: {
    upload: UploadFormulaFn;
  };
}
