import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import MeetingStatusText from '../MeetingStatusText';
import ZoomAppFooterLayout from '../ZoomAppFooterLayout';
import useParticipantFooterViewVM from './vm';

const ParticipantFooterView = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING]);
  const {
    meetingStatusText,
    canRaiseHand,
    handleClickRaiseHand,
    canLowerHand,
    handleClickLowerHand,
    canLeaveMeeting,
    handleClickLeaveMeeting,
  } = useParticipantFooterViewVM();

  return (
    <ZoomAppFooterLayout
      left={
        <MeetingStatusText variant="paragraphRegular" highlighted>
          {meetingStatusText}
        </MeetingStatusText>
      }
      right={
        <>
          {canRaiseHand && (
            <Button variant="outlined" size="small" onClick={handleClickRaiseHand}>
              {t('raise_hand', { ns: i18nNS.ONLINE_MEETING })}
            </Button>
          )}
          {canLowerHand && (
            <Button variant="outlined" size="small" onClick={handleClickLowerHand}>
              {t('lower_hand', { ns: i18nNS.ONLINE_MEETING })}
            </Button>
          )}
          {canLeaveMeeting && (
            <Button variant="contained" size="small" color="error" onClick={handleClickLeaveMeeting}>
              {t('leave_meeting', { ns: i18nNS.ONLINE_MEETING })}
            </Button>
          )}
        </>
      }
    />
  );
};

export default ParticipantFooterView;
