import CommentComposer from './CommentComposer';
import CommentsScrollManager from './CommentsScrollManager';
import CommentsTabs from './CommentsTabs';
import { CommentsScrollManagerWrapper, NewCommentsChip, Root } from './styles';

interface Props {
  className?: string;
}

const CommentsPanel = ({ className }: Props) => {
  return (
    <Root className={className}>
      <CommentsTabs />
      <CommentsScrollManagerWrapper>
        <CommentsScrollManager />
        <NewCommentsChip />
      </CommentsScrollManagerWrapper>
      <CommentComposer />
    </Root>
  );
};

export default CommentsPanel;
