import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material';

import TimePicker from '../../../../../../components/HookFormControls/Timepicker';
import { i18nNS } from '../../../../../../i18n';
import ArrowForwardIcon from '../../../../../../icons/ArrowForwardIcon';
import { generateClasses } from '../../../../../../utils/helpers';
import useClassTimingVM, { VMProps } from './vm';

const classTimingClasses = generateClasses('ClassTiming', ['timePicker', 'arrowIcon']);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginTop: theme.spacing(4),
  [`.${classTimingClasses.timePicker}`]: {
    flex: 1,
  },
  [`.${classTimingClasses.arrowIcon}`]: {
    flexShrink: 0,
    marginTop: theme.spacing(7),
    color: theme.palette.grey[400],
  },
}));

interface Props<T extends FieldValues> extends VMProps<T> {
  className?: string;
}

const ClassTiming = <T extends FieldValues>({ className, ...vmProps }: Props<T>) => {
  const { t } = useTranslation([i18nNS.COURSES]);
  const { control, endTimeControlName, startTimeControlName, validateTimeRange } = useClassTimingVM(vmProps);
  return (
    <Root className={className}>
      <TimePicker
        required
        fullWidth
        control={control}
        name={startTimeControlName}
        onChange={validateTimeRange}
        className={classTimingClasses.timePicker}
        label={t('start_time', { ns: i18nNS.COURSES })}
      />
      <ArrowForwardIcon className={classTimingClasses.arrowIcon} />
      <TimePicker
        required
        fullWidth
        control={control}
        name={endTimeControlName}
        onChange={validateTimeRange}
        className={classTimingClasses.timePicker}
        label={t('end_time', { ns: i18nNS.COURSES })}
      />
    </Root>
  );
};

export default ClassTiming;
