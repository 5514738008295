import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { closeCourse, fetchCourseDetails, openCourse } from '../db/courses/actions';
import { EnrollmentBy } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { useRequestDispatch } from '../utils/request-actions';
import { CreateCourseStep } from './types';

interface CreateCourseContextType {
  isOpen: boolean;
  /**
   * resets create course context to initial state and
   * closes the drawer
   */
  closeDrawer: () => void;
  /** `true`, when fetching course details */
  isFetchingCourseDetails: boolean;
  /**
   * Will empty string only when currentStep is
   * `CreateCourseStep.BASIC_DETAILS`
   */
  courseId: MongoId;
  setCourseId: (courseId: MongoId) => void;
  isCreatingProCourse: boolean;
  setIsCreatingProCourse: (isCreatingProCourse: boolean) => void;
  paymentMethodSlotId: string;
  setPaymentMethodSlotId: (slotId: string) => void;
  activeStep?: CreateCourseStep;
  moveToStep: (step: CreateCourseStep, canMovePrevious?: boolean) => void;
  canMoveToPreviousStep: boolean;
  moveToPreviousStep: (options?: { skip: number }) => void;
  moveToNextStep: (options?: { skip: number }) => void;
  /** empty when creating from scratch */
  sourceCourseId: MongoId | '';
  setSourceCourseId: (courseId: MongoId) => void;
  /** `null`, when not selected */
  enrollmentBy: EnrollmentBy | null;
  setEnrollmentBy: (enrollmentBy: EnrollmentBy | null) => void;
  numCourses: string;
  setNumCourses: (numCourses: string) => void;
  numStudents: string;
  setNumStudents: (numStudents: string) => void;
  paymentErrorMessage: string;
  setPaymentErrorMessage: (message: string) => void;
  title: string;
  setTitle: (title: string) => void;
  mobileTitle: string;
  setMobileTitle: (mobileTitle: string) => void;
}

const CreateCourseContext = createContext<CreateCourseContextType | undefined>(undefined);

export function useCreateCourseContext() {
  const value = useContext(CreateCourseContext);
  if (!value) {
    throw new Error('Accesing useCreateCourseContext() outside CreateCourseProvider is not allowed');
  }
  return value;
}

const createCourseStepsList = [
  { step: CreateCourseStep.INTRODUCTION, canMovePrevious: false },
  { step: CreateCourseStep.PAYMENT_METHOD, canMovePrevious: true },
  { step: CreateCourseStep.COST_CALCULATOR, canMovePrevious: true },
  { step: CreateCourseStep.PAYMENT_DETAILS, canMovePrevious: true },
  { step: CreateCourseStep.PAYMENT_SUCCESS, canMovePrevious: false }, // end
  { step: CreateCourseStep.PAYMENT_FAILED, canMovePrevious: true },
  { step: CreateCourseStep.PRICING_INQUIRY, canMovePrevious: true },
  { step: CreateCourseStep.PRICING_INQUIRY_THANKS, canMovePrevious: false }, // end
  { step: CreateCourseStep.BASIC_DETAILS, canMovePrevious: true },
  { step: CreateCourseStep.INITIALIZE, canMovePrevious: false },
  { step: CreateCourseStep.COPY_CONTENT, canMovePrevious: true },
  { step: CreateCourseStep.SCHEDULE, canMovePrevious: false },
  { step: CreateCourseStep.TEAM_MEMBERS, canMovePrevious: true },
  { step: CreateCourseStep.ENROLLMENT_METHOD, canMovePrevious: true }, // end
];

interface VMProps {
  open: boolean;
  onClose: () => void;
  courseId: MongoId;
  isCreatingProCourse: boolean;
}

const useCreateCourseContextProviderVM = ({
  open,
  onClose,
  courseId: _courseId,
  isCreatingProCourse: _isCreatingProCourse,
}: VMProps) => {
  const dispatch = useDispatch();
  const requestDispatch = useRequestDispatch();

  const [createCourseSteps, setCreateCourseSteps] = useState(createCourseStepsList);
  const [courseId, setCourseId] = useState(_courseId);
  const [isCreatingProCourse, setIsCreatingProCourse] = useState(_isCreatingProCourse);
  const [paymentMethodSlotId, setPaymentMethodSlotId] = useState('');
  const [isFetchingCourseDetails, setIsFetchingCourseDetails] = useState(false);
  const [activeStep, setActiveStep] = useState<CreateCourseStep>(CreateCourseStep.INTRODUCTION);
  const [sourceCourseId, setSourceCourseId] = useState<MongoId | ''>('');
  const [enrollmentBy, setEnrollmentBy] = useState<EnrollmentBy | null>(null);
  const [numCourses, setNumCourses] = useState('');
  const [numStudents, setNumStudents] = useState('');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [title, setTitle] = useState('');
  const [mobileTitle, setMobileTitle] = useState('');

  const moveToStep = useCallback((step: CreateCourseStep, canMovePrevious?: boolean) => {
    setCreateCourseSteps((prevVal) =>
      prevVal.map((createCourseStep) => {
        if (createCourseStep.step !== step) return createCourseStep;
        return {
          step,
          canMovePrevious: canMovePrevious ?? createCourseStep.canMovePrevious,
        };
      })
    );
    setActiveStep(step);
  }, []);

  const moveToPreviousStep = useCallback(
    (options?: { skip: number }) => {
      const activeStepIndex = createCourseSteps.findIndex(
        (createCourseStep) => createCourseStep.step === activeStep
      );
      if (activeStepIndex === -1) return;

      if (!createCourseSteps[activeStepIndex].canMovePrevious) return;

      const previousStep = createCourseSteps[activeStepIndex - ((options?.skip ?? 0) + 1)]?.step;
      if (!previousStep) return;

      setActiveStep(previousStep);
    },
    [activeStep, createCourseSteps]
  );

  const moveToNextStep = useCallback(
    (options?: { skip: number }) => {
      const activeStepIndex = createCourseSteps.findIndex(
        (createCourseStep) => createCourseStep.step === activeStep
      );
      if (activeStepIndex === -1) return;

      const nextStep = createCourseSteps[activeStepIndex + (options?.skip ?? 0) + 1]?.step;
      if (!nextStep) return;

      setActiveStep(nextStep);
    },
    [activeStep, createCourseSteps]
  );

  const handleCloseDrawer = useCallback(() => {
    setCreateCourseSteps(createCourseStepsList);
    setCourseId('');
    setIsCreatingProCourse(true);
    setPaymentMethodSlotId('');
    setIsFetchingCourseDetails(false);
    setActiveStep(CreateCourseStep.INTRODUCTION);
    setSourceCourseId('');
    setEnrollmentBy(null);
    onClose();
  }, [onClose]);

  useEffect(
    function syncCourseId() {
      setCourseId(_courseId);
    },
    [_courseId]
  );

  useEffect(
    function syncIsCreatingProCourse() {
      setIsCreatingProCourse(_isCreatingProCourse);
    },
    [_isCreatingProCourse]
  );

  useEffect(
    function init() {
      if (!open) return;

      setSourceCourseId('');
      setEnrollmentBy(null);

      if (!courseId) {
        setActiveStep(CreateCourseStep.INTRODUCTION);
        return;
      }

      dispatch(openCourse({ courseId }));
      setIsFetchingCourseDetails(true);

      requestDispatch(fetchCourseDetails, { courseId })
        .then(({ courseStatus }) => {
          if (!courseStatus.initialized) {
            setActiveStep(CreateCourseStep.INITIALIZE);
          } else {
            setActiveStep(CreateCourseStep.SCHEDULE);
          }
        })
        .finally(() => {
          setIsFetchingCourseDetails(false);
        });

      return () => {
        if (!courseId) return;
        dispatch(closeCourse());
      };
    },
    [courseId, dispatch, open, requestDispatch]
  );
  useEffect(() => {
    setTitle(i18n.t('new_course', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('new_course', { ns: i18nNS.CREATE_COURSE }));
  }, []);

  return useMemo<CreateCourseContextType>(
    () => ({
      isOpen: open,
      closeDrawer: handleCloseDrawer,
      isFetchingCourseDetails,
      courseId,
      isCreatingProCourse,
      setIsCreatingProCourse,
      paymentMethodSlotId,
      setPaymentMethodSlotId,
      activeStep,
      setActiveStep,
      moveToStep,
      canMoveToPreviousStep:
        createCourseSteps.find((createCourseStep) => createCourseStep.step === activeStep)?.canMovePrevious ??
        false,
      moveToPreviousStep,
      moveToNextStep,
      sourceCourseId,
      setSourceCourseId,
      setCourseId,
      enrollmentBy,
      setEnrollmentBy,
      numCourses,
      setNumCourses,
      numStudents,
      setNumStudents,
      paymentErrorMessage,
      setPaymentErrorMessage,
      title,
      setTitle,
      mobileTitle,
      setMobileTitle,
    }),
    [
      open,
      handleCloseDrawer,
      isFetchingCourseDetails,
      courseId,
      isCreatingProCourse,
      paymentMethodSlotId,
      activeStep,
      moveToStep,
      createCourseSteps,
      moveToPreviousStep,
      moveToNextStep,
      sourceCourseId,
      enrollmentBy,
      numCourses,
      numStudents,
      paymentErrorMessage,
      title,
      mobileTitle,
    ]
  );
};

interface Props extends VMProps {
  children: ReactNode;
}

const CreateCourseProvider = ({ children, ...vmProps }: Props) => {
  const providerValue = useCreateCourseContextProviderVM(vmProps);
  return <CreateCourseContext.Provider value={providerValue}>{children}</CreateCourseContext.Provider>;
};

export default CreateCourseProvider;
