import { AriaAttributes, ElementType, MouseEvent } from 'react';

import Button, { Props as ButtonProps } from '../Button';
import { useAlertContext } from './Context';

type VMProps<C extends ElementType<any>> = ButtonProps<C>;

const useActionVM = <C extends ElementType<any>>({ onClick }: VMProps<C>) => {
  const { close } = useAlertContext();
  const handleClick = async (e: MouseEvent<HTMLElement>) => {
    await onClick?.(e);
    close?.();
  };
  return { handleClick };
};

type Props<C extends ElementType<any>> = VMProps<C> & AriaAttributes;

const Action = <C extends ElementType<any> = 'button'>(props: Props<C>) => {
  const { handleClick } = useActionVM<C>(props);
  return <Button {...props} onClick={handleClick} />;
};

export default Action;
