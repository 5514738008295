import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Button from '../../../../components/Button';
import { buttonClasses } from '../../../../components/Button/styles';
import { HelperText } from '../../../../components/FormControls/TextFieldBase/styles';
import TextField from '../../../../components/HookFormControls/TextField';
import { i18nNS } from '../../../../i18n';
import InfoIcon from '../../../../icons/InfoIcon';
import { generateClasses } from '../../../../utils/helpers';
import RoleDetails from './RoleDetails';
import useAddInstructorVM from './vm';

const addInstructorClasses = generateClasses('AddInstructor', [
  'heading',
  'helperButton',
  'helperIcon',
  'helperText',
  'submit',
]);

const Root = styled('form')(({ theme }) => ({
  [`.${addInstructorClasses.heading}`]: {
    marginBottom: theme.spacing(3),
    color: theme.palette.grey[700],
  },

  [`.${addInstructorClasses.helperButton}`]: {
    [`&.${buttonClasses.root}`]: {
      padding: 0,
      border: 'none',
      display: 'block',
      minHeight: 'unset',
      backgroundColor: 'transparent',
    },
  },

  [`.${addInstructorClasses.helperIcon}`]: {
    fontSize: theme.typography.pxToRem(16),
  },

  [`.${addInstructorClasses.helperText}`]: {
    color: theme.palette.primary[600],
  },

  [`.${addInstructorClasses.submit}`]: {
    marginTop: 22,
  },
}));

const AddInstructor = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.CREATE_COURSE, i18nNS.GLOSSARY]);

  const {
    addInstructor,
    closeRoleDetails,
    control,
    controlName,
    error,
    isRoleDetailsOpen,
    isSubmitting,
    openRoleDetails,
  } = useAddInstructorVM();

  return (
    <Root onSubmit={addInstructor}>
      <Typography className={addInstructorClasses.heading} variant="h6Bold">
        {t('add_course_instructors', { ns: i18nNS.COURSES })}
      </Typography>
      <Stack direction="row" alignItems="flex-start" gap={3}>
        <Stack flex="1">
          <TextField
            control={control}
            name={controlName}
            size="large"
            placeholder={t('enter_instructor_s_valid_institute_email_address', { ns: i18nNS.CREATE_COURSE })}
            autoFocus
          />
          {!error && (
            <Button className={addInstructorClasses.helperButton} onClick={openRoleDetails}>
              <HelperText className={addInstructorClasses.helperText}>
                <InfoIcon className={addInstructorClasses.helperIcon} variant="filled" color="inherit" />
                {t('learn_what_course_instructor_can_do', { ns: i18nNS.CREATE_COURSE })}
              </HelperText>
            </Button>
          )}
        </Stack>
        <Button
          type="submit"
          variant="outlined"
          size="large"
          isLoading={isSubmitting}
          aria-label={t('click_to_add_course_instructor', { ns: i18nNS.CREATE_COURSE })}
        >
          {t('add', { ns: i18nNS.GLOSSARY })}
        </Button>
      </Stack>
      <RoleDetails open={isRoleDetailsOpen} onClose={closeRoleDetails} />
    </Root>
  );
};

export default AddInstructor;
