import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';

import Dialog from '../../../../../../../../components/Dialog';
import Header from '../../../../../../../../components/Header';
import { i18nNS } from '../../../../../../../../i18n';
import ArrowBackIcon from '../../../../../../../../icons/ArrowBackIcon';
import ChevronLeftIcon from '../../../../../../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../../../../../../icons/ChevronRightIcon';
import CloseIcon from '../../../../../../../../icons/CloseIcon';
import { forMobile } from '../../../../../../../../utils/media-queries';
import CommentActions from './CommentActions';
import CommentAward from './CommentAward';
import CommentDetails from './CommentDetails';
import { Footer, HeaderAction, StyledCommentCreator } from './styles';
import useCommentDialogVM from './vm';

const CommentDialog = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const {
    isOpen,
    comment,
    awardingCommentId,
    handleCloseDialog,
    hasPrevious,
    handlePreviousClick,
    hasNext,
    handleNextClick,
  } = useCommentDialogVM();

  if (!comment) return null;

  return (
    <Dialog
      fullWidth
      dismissal={awardingCommentId === comment.id ? 'none' : ['esc', 'backdrop']}
      open={isOpen}
      onClose={handleCloseDialog}
      header={(id) => (
        <Header
          id={id}
          left={
            isMobile
              ? {
                  'aria-label': t('click_to_close_dialog', { ns: i18nNS.COMMON }),
                  onClick: handleCloseDialog,
                  size: 'small',
                  edge: 'start',
                  children: <ArrowBackIcon color="inherit" aria-hidden />,
                }
              : undefined
          }
          right={() =>
            isMobile ? (
              <>
                <HeaderAction
                  disabled={!hasPrevious}
                  aria-label={t('click_to_see_previous_post', { ns: i18nNS.COMMENTS })}
                  onClick={handlePreviousClick}
                >
                  <ChevronLeftIcon color="inherit" aria-hidden />
                </HeaderAction>
                <HeaderAction
                  disabled={!hasNext}
                  aria-label={t('click_to_see_next_post', { ns: i18nNS.COMMENTS })}
                  onClick={handleNextClick}
                >
                  <ChevronRightIcon />
                </HeaderAction>
              </>
            ) : (
              <HeaderAction
                aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}
                onClick={handleCloseDialog}
              >
                <CloseIcon color="inherit" aria-hidden />
              </HeaderAction>
            )
          }
        >
          <StyledCommentCreator
            avatar={comment.createdBy.avatar}
            name={comment.createdBy.name}
            createdOn={comment.createdOn}
          />
        </Header>
      )}
      footer={
        comment.isDeleted
          ? null
          : () => (
              <Footer>
                {awardingCommentId === comment.id ? (
                  <CommentAward comment={comment} />
                ) : (
                  <CommentActions commentId={comment.id} />
                )}
              </Footer>
            )
      }
    >
      <CommentDetails comment={comment} />
    </Dialog>
  );
};

export default CommentDialog;
