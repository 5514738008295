import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { i18nNS } from '../../../../../../../../../i18n';
import AwardIcon from '../../../../../../../../../icons/AwardIcon';
import useAwardsVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const Awards = ({ commentId }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { canShowAwards, awards } = useAwardsVM({ commentId });

  if (!canShowAwards) return null;
  if (awards.length === 0) return null;

  return (
    <Stack direction="column" gap={4} component="section" aria-labelledby="awards">
      <Typography variant="titleBold">{t('awards', { ns: i18nNS.GLOSSARY })}</Typography>
      <Stack direction="column" gap={2} m={0} pl={2} component="ul">
        {awards.map((award) => (
          <Stack key={award.awardedBy.userId} direction="row" alignItems="center" gap={2} component="li">
            <AwardIcon variant="filled" color="primary" aria-hidden />
            <Typography variant="paragraphRegular">
              {t('presenter_awarded_num_points_points', {
                ns: i18nNS.COMMENTS,
                presenter: award.awardedBy.name,
                numPoints: award.points,
              })}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default Awards;
