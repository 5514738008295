import { drawerClasses as muiDrawerClasses, styled } from '@mui/material';

import Help from '../../../courses/Help';
import { generateClasses, pick } from '../../../utils/helpers';
import { forMobile } from '../../../utils/media-queries';

export const DISCUSSION_PANEL_WIDTH = 350;

export const discussionPanelClasses = {
  ...pick(muiDrawerClasses, 'paper'),
  ...generateClasses('DiscussionPanel', ['expanded', 'page']),
};

export const ContentRoot = styled('div')(({ theme }) => ({
  width: DISCUSSION_PANEL_WIDTH,
  maxWidth: '100vw',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',

  [forMobile(theme)]: {
    width: '100vw',
  },

  [`&.${discussionPanelClasses.expanded}`]: {
    width: '70vw',

    [forMobile(theme)]: {
      width: '100vw',
    },
  },

  [`& .${discussionPanelClasses.page}`]: {
    flex: 1,
  },
}));

export const Page = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.level1,
}));

export const HelpPanel = styled(Help)(({ theme }) => ({
  backgroundColor: theme.palette.background.level1,
}));
