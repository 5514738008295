import { Draft } from '@reduxjs/toolkit';

import { API } from '../../shared/api-responses';
import { CourseUser, SubmissionStatus } from '../../shared/types';
import { Poll, StudentPollData } from '../types';

export function updateStudentSubmission({
  student,
  poll,
  stats,
  submission,
  submittedOn,
}: {
  student: CourseUser;
  poll: Draft<Poll>;
  stats?: API.SubmitPollResponse['stats'];
  submission: API.SubmitPollResponse['submission'];
  submittedOn: UnixTime;
}) {
  const { userId, name, avatar } = student;
  const prevStudentData = poll.studentDataById?.[userId];

  // do nothing as poll submission data is already updated either by pusher or api-response
  if (prevStudentData && prevStudentData.submittedOn > 0) return;

  poll.totalSubmissions++;

  const { questionsById } = poll;
  if (!questionsById) return;

  // first question, loop here instead when multiple questions are supported
  const questionId = Object.keys(submission)[0];
  const question = questionsById[questionId];

  if (question && stats) {
    question.totalAttempts = stats.numAttempted;
    question.options.forEach((option) => {
      const stat = stats.optionSelection.find((s) => s.num === option.num);
      option.totalSelections += stat?.numSelected ?? 0;
    });
  } else if (question) {
    question.totalAttempts++;
    question.options.forEach((option) => {
      if (submission[questionId].optionSelected === option.num) {
        option.totalSelections++;
      }
    });
  }

  const studentData: StudentPollData = {
    userId,
    name,
    avatar,
    submissionsByQuestionId: null,
    submittedOn,
    firstAccessedOn: prevStudentData?.firstAccessedOn ?? -1,
    status:
      submittedOn > poll.dueDateTime && poll.dueDateTime !== -1
        ? SubmissionStatus.LATE
        : SubmissionStatus.SUBMITTED,
  };

  studentData.submissionsByQuestionId = {};

  for (const [questionId, response] of Object.entries(submission)) {
    studentData.submissionsByQuestionId[questionId] = response;
  }

  if (!poll.studentDataById) {
    poll.studentDataById = { [userId]: studentData };
  } else {
    poll.studentDataById[userId] = studentData;
  }
}
