import { Draft } from '@reduxjs/toolkit';

import { CourseTeamUser } from '../../shared/types';
import { Comment, CommentMyReactions } from '../types';

export function rateCommentCaseReducer({
  comment,
  myReactions,
  isCurrentUser,
  points,
  awardedBy,
}: {
  comment: Draft<Comment | undefined>;
  myReactions: Draft<CommentMyReactions>;
  isCurrentUser: boolean;
  points: number;
  awardedBy: CourseTeamUser;
}) {
  if (!comment) return;

  if (isCurrentUser) {
    myReactions.awardPoints = points;
  }

  /**
   * first remove previous award item to make it idempotent action
   * to handle race between pusher as well as server API response
   */
  comment.stats.awards = comment.stats.awards.filter((a) => a.awardedBy.userId !== awardedBy.userId);

  if (points > 0) {
    comment.stats.awards.push({ awardedBy, points });
  }
}
