import { createPusherEvent } from '../../pusher/helpers';
import { CoursePusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { CourseStudentUser } from '../shared/types';

type AssignmentPublishedEvent = CoursePusherEvent<{
  assignmentId: MongoId;
  num: number;
  dueDateTime: UnixTime;
  title: string;
}>;

export const assignmentPublishedEvent = createPusherEvent<AssignmentPublishedEvent>(
  PusherEventName.ASSIGNMENT_PUBLISHED,
  { channels: [PusherChannel.COURSE] }
);

export type AssignmentSubmittedEvent = CoursePusherEvent<{
  assignmentId: MongoId;
  sender: CourseStudentUser;
}>;

export const assignmentSubmittedEvent = createPusherEvent<AssignmentSubmittedEvent>(
  PusherEventName.ASSIGNMENT_SUBMITTED,
  { channels: [PusherChannel.COURSE_TEAM] }
);

export type AssignmentRetractedEvent = CoursePusherEvent<{
  assignmentId: MongoId;
  sender: CourseStudentUser;
}>;

export const assignmentRetractedEvent = createPusherEvent<AssignmentRetractedEvent>(
  PusherEventName.SUBMISSION_RETRACTED,
  { channels: [PusherChannel.COURSE_TEAM] }
);
