import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import { CircularProgress } from '../../components/Progressbar/CircularProgressbar/styles';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { Root } from './styles';
import useSsoVM from './vm';

const Sso = () => {
  const { t } = useTranslation([i18nNS.AUTH]);

  const { errorMessage, handleLoginAgain } = useSsoVM();

  if (errorMessage) {
    return (
      <Root>
        <Typography variant="paragraphRegular" color="error" textAlign="center">
          {errorMessage}
        </Typography>
        <Button onClick={handleLoginAgain} sx={{ cursor: 'pointer' }}>
          Log in again
        </Button>
      </Root>
    );
  }

  return (
    <Root>
      <CircularProgress size={60} />
      <Typography variant="h6Regular" color="grey.700" component="div">
        {t('authentication_process_active_in_another_window', { ns: i18nNS.AUTH })}
      </Typography>
    </Root>
  );
};

export default Sso;
