import React, { forwardRef } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  isActive: boolean;
}

const TabPanel = forwardRef<HTMLDivElement, Props>(({ children, isActive, ...restProps }, ref) => {
  return (
    <div ref={ref} role="tabpanel" hidden={!isActive} {...restProps}>
      {isActive && children}
    </div>
  );
});

export default TabPanel;
