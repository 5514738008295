import i18n, { i18nNS } from '../../i18n';
import { SignupTeacherRole } from '../types';

interface Option<T extends string> {
  value: T;
  title: string;
}

export const roles: Option<SignupTeacherRole>[] = [
  {
    value: SignupTeacherRole.FACULTY,
    title: i18n.t('faculty', { ns: i18nNS.GLOSSARY }),
  },
  {
    value: SignupTeacherRole.TA,
    title: i18n.t('graduate_teaching_assistant', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherRole.STAFF,
    title: i18n.t('administrative_staff', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherRole.STUDENT,
    title: i18n.t('student', { ns: i18nNS.GLOSSARY }),
  },
];
