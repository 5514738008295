import { createContext, ReactNode, useContext, useMemo } from 'react';

interface AlertContextType {
  close?: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('using useAlertContext outside AlertProvider not allowed');
  }
  return context;
};

interface Props {
  children?: ReactNode;
  onClose?: () => void;
}

export const AlertProvider = ({ children, onClose }: Props) => {
  const contextValue = useMemo<AlertContextType>(() => {
    return { close: onClose };
  }, [onClose]);

  return <AlertContext.Provider value={contextValue}>{children}</AlertContext.Provider>;
};
