import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BreadcrumbContext, BreadcrumbItems } from '../app/BreadcrumbProvider';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../components/Breadcrumb/index';
import { selectCourse } from '../db/courses/selectors';
import { i18nNS } from '../i18n';
import { useIsPageReady } from '../pages/hooks';
import routes, { CourseParams } from '../pages/routes';
import { AcadlyPage } from '../pages/types';

const useCourseBreadcrumbVM = () => {
  const { t } = useTranslation([i18nNS.COURSES]);
  const { courseShortId, universitySlug } = useParams<CourseParams>();

  const isPageReady = useIsPageReady(AcadlyPage.ROOT);
  const course = useSelector(selectCourse(courseShortId));
  const isCurrentCourseArchived = Boolean(course?.status.isArchived);

  const { breadcrumbItems, updateBreadcrumbItem } = useContext(BreadcrumbContext);

  const setBreadcrumbValue = useCallback(() => {
    if (!course?.code) return;
    updateBreadcrumbItem(BreadcrumbItems.COURSE, {
      path: routes.timeline.url({
        universitySlug: universitySlug,
        courseShortId: courseShortId,
      }),
      content: course.code,
      extraContent: t('course_code_colon_course_title', {
        ns: i18nNS.COURSES,
        courseCode: course.code,
        courseTitle: course.title,
      }),
    });
  }, [course?.code, course?.title, courseShortId, t, universitySlug, updateBreadcrumbItem]);

  useEffect(
    function initBreadcrumb() {
      if (!isPageReady) return;
      setBreadcrumbValue();
    },
    [isPageReady, setBreadcrumbValue]
  );

  return { breadcrumbItems, isCurrentCourseArchived };
};

const CourseBreadcrumb = () => {
  const { breadcrumbItems, isCurrentCourseArchived } = useCourseBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.course}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      {isCurrentCourseArchived && <BreadcrumbItem item={breadcrumbItems.archived} />}
    </Breadcrumb>
  );
};

export default CourseBreadcrumb;
