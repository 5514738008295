import { useTranslation } from 'react-i18next';

import { styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import CheckmarkIcon from '../../../icons/CheckmarkIcon';
import InfoIcon from '../../../icons/InfoIcon';
import { forNonDesktop } from '../../../utils/media-queries';
import usePublishInfoVM from './vm';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary[100],
  minHeight: 60,
  padding: theme.spacing(3, 8),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  [forNonDesktop(theme)]: {
    padding: theme.spacing(3, 4),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  color: theme.palette.primary[900],
}));

const MessageIcon = styled(InfoIcon)(() => ({
  width: 18,
  height: 18,
}));

const OkayButton = styled(Button)(({ theme }) => ({
  [forNonDesktop(theme)]: {
    marginLeft: theme.spacing(7),
  },
}));

const PublishInfo = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);

  const { onOkayClick: handleOkayClick } = usePublishInfoVM();

  return (
    <Root>
      <MessageWrapper>
        <MessageIcon color="inherit" variant="filled" />
        <Typography color="inherit">
          {t('use_this_section_to_manage_your_course_team_and_share_essential_info', {
            ns: i18nNS.COURSES,
          })}
        </Typography>
      </MessageWrapper>
      <OkayButton
        variant="contained"
        startIcon={<CheckmarkIcon />}
        aria-label={t('click_to_publish_info', { ns: i18nNS.COURSES })}
        onClick={handleOkayClick}
      >
        {t('okay', { ns: i18nNS.GLOSSARY })}
      </OkayButton>
    </Root>
  );
};

export default PublishInfo;
