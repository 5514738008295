import { FormEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { addCourseTeamMember } from '../../../../db/courses/actions';
import { CourseRole } from '../../../../types';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { FormValues } from './types';
import { validationSchema } from './validator';

export default function useAddInstructorVM() {
  const requestDispatch = useRequestDispatch();

  const [isRoleDetailsOpen, setIsRoleDetailsOpen] = useState(false);

  const { control, formState, getValues, handleSubmit, reset } = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      emailId: '',
    },
  });

  const controlName = 'emailId' as const;
  const { errors, isSubmitting } = formState;
  const error = errors[controlName];

  const openRoleDetails = () => setIsRoleDetailsOpen(true);
  const closeRoleDetails = () => setIsRoleDetailsOpen(false);

  const addInstructor: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit(async () => {
      const emailId = getValues(controlName);
      if (!emailId) return;
      await requestDispatch(addCourseTeamMember, { role: CourseRole.INSTRUCTOR, emailId });
      reset({ emailId: '' });
    })(e);
  };

  return {
    addInstructor,
    closeRoleDetails,
    control,
    controlName,
    error,
    isRoleDetailsOpen,
    isSubmitting,
    openRoleDetails,
  };
}
