import { createReducer, Dictionary } from '@reduxjs/toolkit';

import { closeCourse, fetchCourseInfo, openCourse } from '../courses/actions';
import { addCourseBook, editCourseBook, fetchCourseBooks, removeCourseBook } from './actions';
import { CourseBook } from './types';

interface BooksState {
  courseId: MongoId;
  byId: Dictionary<CourseBook>;
}

const initialState: BooksState = {
  courseId: '',
  byId: {},
};

const booksReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(fetchCourseInfo.success, (state, action) => {
    const { readingList } = action.payload;
    for (const book of readingList.books) {
      state.byId[book._id] = {
        id: book._id,
        title: book.title,
        author: book.author,
        isbn: book.isbn,
        isRecommended: Boolean(book.recommended),
      };
    }
  });

  builder.addCase(fetchCourseBooks.success, (state, action) => {
    const { books } = action.payload;
    for (const book of books) {
      state.byId[book.id] = book;
    }
  });

  builder.addCase(addCourseBook.success, (state, action) => {
    const { book } = action.payload;
    state.byId[book.id] = book;
  });

  builder.addCase(editCourseBook.success, (state, action) => {
    const book = action.payload;
    state.byId[book.id] = book;
  });

  builder.addCase(removeCourseBook.success, (state, action) => {
    const { bookId } = action.payload;
    state.byId[bookId] = undefined;
  });
});

export default booksReducer;
