import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const ArrowheadRightIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M18.78 11.37L14 5.37C13.9175 5.26728 13.8155 5.18187 13.6998 5.1187C13.5842 5.05552 13.4572 5.01583 13.3262 5.00189C13.1951 4.98795 13.0627 5.00004 12.9363 5.03747C12.81 5.07491 12.6923 5.13694 12.59 5.22C12.4873 5.30253 12.4019 5.40454 12.3387 5.52017C12.2755 5.63581 12.2358 5.76279 12.2219 5.89382C12.2079 6.02485 12.22 6.15734 12.2575 6.28368C12.2949 6.41002 12.3569 6.52771 12.44 6.63L16.71 12L12.23 17.37C12.1458 17.4711 12.0823 17.5877 12.0433 17.7133C12.0043 17.8389 11.9904 17.971 12.0025 18.102C12.0145 18.233 12.0523 18.3603 12.1137 18.4766C12.175 18.593 12.2587 18.6961 12.36 18.78C12.5413 18.9255 12.7676 19.0033 13 19C13.1469 19.0002 13.2921 18.9681 13.4251 18.9059C13.5582 18.8437 13.676 18.7529 13.77 18.64L18.77 12.64C18.9185 12.4622 19.0007 12.2384 19.0025 12.0068C19.0043 11.7752 18.9257 11.5501 18.78 11.37Z"
          fill="currentColor"
        />
        <path
          d="M6.99999 5.37C6.8329 5.1565 6.58785 5.01812 6.31873 4.9853C6.04962 4.95249 5.77849 5.02792 5.56499 5.195C5.35149 5.36209 5.21311 5.60714 5.18029 5.87626C5.14747 6.14537 5.2229 6.4165 5.38999 6.63L9.70999 12L5.22999 17.36C5.14579 17.4611 5.08235 17.5777 5.0433 17.7033C5.00426 17.8289 4.99038 17.961 5.00245 18.092C5.01453 18.223 5.05233 18.3503 5.11368 18.4666C5.17503 18.583 5.25873 18.6861 5.35999 18.77C5.5399 18.9191 5.76634 19.0005 5.99999 19C6.1469 19.0002 6.29206 18.9681 6.42514 18.9059C6.55823 18.8437 6.67597 18.7529 6.76999 18.64L11.77 12.64C11.9171 12.4611 11.9975 12.2366 11.9975 12.005C11.9975 11.7734 11.9171 11.5489 11.77 11.37L6.99999 5.37Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.78 11.37L14 5.37C13.9175 5.26728 13.8155 5.18187 13.6998 5.1187C13.5842 5.05552 13.4572 5.01583 13.3262 5.00189C13.1952 4.98795 13.0627 5.00004 12.9363 5.03747C12.81 5.07491 12.6923 5.13694 12.59 5.22C12.4873 5.30253 12.4019 5.40454 12.3387 5.52017C12.2755 5.63581 12.2358 5.76279 12.2219 5.89382C12.208 6.02485 12.22 6.15734 12.2575 6.28368C12.2949 6.41002 12.3569 6.52771 12.44 6.63L16.71 12L12.23 17.37C12.1458 17.4711 12.0824 17.5877 12.0433 17.7133C12.0043 17.8389 11.9904 17.971 12.0025 18.102C12.0145 18.233 12.0523 18.3603 12.1137 18.4766C12.175 18.593 12.2587 18.6961 12.36 18.78C12.5413 18.9255 12.7676 19.0033 13 19C13.1469 19.0002 13.2921 18.9681 13.4252 18.9059C13.5582 18.8437 13.676 18.7529 13.77 18.64L18.77 12.64C18.9185 12.4622 19.0007 12.2384 19.0025 12.0068C19.0043 11.7752 18.9257 11.5501 18.78 11.37Z"
        fill="currentColor"
      />
      <path
        d="M7.00001 5.37C6.83292 5.1565 6.58786 5.01812 6.31875 4.9853C6.04963 4.95249 5.77851 5.02792 5.56501 5.195C5.35151 5.36209 5.21313 5.60714 5.18031 5.87626C5.14749 6.14537 5.22292 6.4165 5.39001 6.63L9.71001 12L5.23001 17.36C5.1458 17.4611 5.08236 17.5777 5.04332 17.7033C5.00427 17.8289 4.99039 17.961 5.00247 18.092C5.01454 18.223 5.05234 18.3503 5.11369 18.4666C5.17504 18.583 5.25875 18.6861 5.36001 18.77C5.53992 18.9191 5.76635 19.0005 6.00001 19C6.14692 19.0002 6.29208 18.9681 6.42516 18.9059C6.55824 18.8437 6.67598 18.7529 6.77001 18.64L11.77 12.64C11.9171 12.4611 11.9975 12.2366 11.9975 12.005C11.9975 11.7734 11.9171 11.5489 11.77 11.37L7.00001 5.37Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ArrowheadRightIcon;
