import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import MeetingStatusText from '../MeetingStatusText';
import ZoomAppFooterLayout from '../ZoomAppFooterLayout';
import useRecordingAttendanceFooterViewVM, { VMProps } from './vm';

interface Props extends VMProps {
  attendanceId: MongoId;
}

const RecordingAttendanceFooterView = ({ ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING]);

  const { handleStopRecordingAttendance, respondersCount } = useRecordingAttendanceFooterViewVM(vmProps);

  return (
    <ZoomAppFooterLayout
      left={
        <MeetingStatusText variant="paragraphRegular" highlighted>
          {t('N_students_are_here', {
            ns: i18nNS.ONLINE_MEETING,
            count: respondersCount,
          })}
        </MeetingStatusText>
      }
      right={
        <Button variant="contained" size="small" color="error" onClick={handleStopRecordingAttendance}>
          {t('stop_recording_attendance', { ns: i18nNS.ONLINE_MEETING })}
        </Button>
      }
    />
  );
};

export default RecordingAttendanceFooterView;
