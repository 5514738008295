import { useTranslation } from 'react-i18next';

import { Stack, styled } from '@mui/material';

import Button from '../../../components/Button';
import { radioGroupClasses } from '../../../components/FormControls/RadioGroup';
import AcadlyRadioOption, {
  radioOptionClasses,
} from '../../../components/FormControls/RadioGroup/RadioOption';
import AcadlyRadioGroup from '../../../components/HookFormControls/RadioGroup';
import Typography from '../../../components/Typography';
import CourseWidget from '../../../courses/CourseWidget';
import { i18nNS } from '../../../i18n';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import CreateCourseForm from '../CreateCourseForm';
import RadioCourseSkeleton from './RadioCourseSkeleton';
import useInitializeCourseVM from './vm';

const RadioGroup = styled(AcadlyRadioGroup)(({ theme }) => ({
  [`.${radioGroupClasses.radioGroup}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
})) as typeof AcadlyRadioGroup;

const RadioOption = styled(AcadlyRadioOption)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  [`& .${radioOptionClasses.radioRoot}`]: {
    /** To align radio icon and course widget's title */
    marginTop: theme.spacing(1.8),
  },
}));

const FromScratch = styled('div')(({ theme }) => ({
  /** To align radio icon and label */
  marginTop: theme.spacing(2.75),
  color: theme.palette.grey[900],
}));

interface Props {
  id?: string;
}

const InitializeCourse = ({ id }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.COMMON, i18nNS.GLOSSARY, i18nNS.COURSES]);

  const {
    blueprintCourses,
    closeDrawer,
    control,
    handleInitializeCourse,
    isFetchingBlueprintCourses,
    isSubmitting,
    isVisible,
  } = useInitializeCourseVM();

  return (
    <CreateCourseForm
      id={id}
      isVisible={isVisible}
      onSubmit={handleInitializeCourse}
      footer={
        <>
          <Button
            type="button"
            variant="text"
            onClick={closeDrawer}
            aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isFetchingBlueprintCourses || isSubmitting}
            isLoading={isSubmitting}
            endIcon={<ChevronRightIcon />}
            aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </Button>
        </>
      }
    >
      <Stack gap={4}>
        <Typography variant="h5Bold" color="grey.900">
          {t('step_1_dot_1_colon_initialize_course', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Typography>
          {t('create_a_course_from_scratch_or_select_a_course_to_copy_from', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <RadioGroup name="sourceCourseId" control={control}>
          <RadioOption
            value=""
            aria-label={t('click_to_create_a_course_from_scratch', { ns: i18nNS.CREATE_COURSE })}
          >
            <FromScratch>
              <Typography variant="h5Bold" color="inherit">
                {t('create_a_course_from_scratch', { ns: i18nNS.CREATE_COURSE })}
              </Typography>
            </FromScratch>
          </RadioOption>
          <Typography
            variant="h6Medium"
            color="grey.400"
            component="div"
            textAlign="center"
            textTransform="uppercase"
          >
            {t('or', { ns: i18nNS.GLOSSARY })}
          </Typography>
          <Typography variant="h5Bold" component="div" color="grey.800">
            {t('select_a_past_course_as_blueprint', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          {isFetchingBlueprintCourses && (
            <>
              <RadioCourseSkeleton />
              <RadioCourseSkeleton />
              <RadioCourseSkeleton />
            </>
          )}
          {blueprintCourses.map((blueprintCourse) => (
            <RadioOption
              key={blueprintCourse.id}
              value={blueprintCourse.id}
              aria-label={t('course_colon_title', { ns: i18nNS.COMMON, title: blueprintCourse.title })}
            >
              <CourseWidget disabled component="div" course={blueprintCourse} forceRenderDates />
            </RadioOption>
          ))}
        </RadioGroup>
      </Stack>
    </CreateCourseForm>
  );
};

export default InitializeCourse;
