import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const RichTextIcon = ({ variant = 'outlined', ...props }: Props) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00026 2C7.40028 2 7.76182 2.23774 7.9194 2.6044L10.1607 7.8196C10.1669 7.83323 10.1729 7.84703 10.1785 7.86098L10.9194 9.58501C11.137 10.0912 10.9018 10.6775 10.3942 10.8944C9.88655 11.1114 9.29867 10.8769 9.08111 10.3707L8.59086 9.22992H5.40965L4.9194 10.3707C4.70185 10.8769 4.11397 11.1114 3.60634 10.8944C3.09871 10.6775 2.86356 10.0912 3.08111 9.58501L3.82203 7.86098C3.82766 7.84703 3.83359 7.83323 3.83982 7.8196L6.08111 2.6044C6.23869 2.23774 6.60023 2 7.00026 2ZM7.73372 7.23546L7.00026 5.52879L6.2668 7.23546H7.73372Z"
        fill="currentColor"
      />
      <path
        d="M20.05 4.99445H11.95C11.4253 4.99445 11 5.4186 11 5.94181V6.04154C11 6.56475 11.4253 6.98891 11.95 6.98891H20.05C20.5747 6.98891 21 6.56475 21 6.04154V5.94181C21 5.4186 20.5747 4.99445 20.05 4.99445Z"
        fill="currentColor"
      />
      <path
        d="M3.95 12.9833H20.05C20.5747 12.9833 21 13.4075 21 13.9307V14.0304C21 14.5536 20.5747 14.9778 20.05 14.9778H3.95C3.42533 14.9778 3 14.5536 3 14.0304V13.9307C3 13.4075 3.42533 12.9833 3.95 12.9833Z"
        fill="currentColor"
      />
      <path
        d="M20.05 16.9778H3.95C3.42533 16.9778 3 17.4019 3 17.9252V18.0249C3 18.5481 3.42533 18.9722 3.95 18.9722H20.05C20.5747 18.9722 21 18.5481 21 18.0249V17.9252C21 17.4019 20.5747 16.9778 20.05 16.9778Z"
        fill="currentColor"
      />
      <path
        d="M13.95 8.98889H20.05C20.5747 8.98889 21 9.41304 21 9.93626V10.036C21 10.5592 20.5747 10.9834 20.05 10.9834H13.95C13.4253 10.9834 13 10.5592 13 10.036V9.93626C13 9.41304 13.4253 8.98889 13.95 8.98889Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RichTextIcon;
