import { useTranslation } from 'react-i18next';

import Switch from '../../components/FormControls/Switch';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import EyeIcon from '../../icons/EyeIcon';
import SpinnerIcon from '../../icons/LoaderIcon';
import { ListItem, ListItemIcon, ListItemText } from '../styles';
import useAccessibilityFeaturesVM from './vm';

const AccessibilityFeatures = () => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  const { isTogglingAccessibility, isAccessibilityOn, handleToggleAccessibility } =
    useAccessibilityFeaturesVM();

  return (
    <ListItem>
      <ListItemIcon>{isTogglingAccessibility ? <SpinnerIcon /> : <EyeIcon />}</ListItemIcon>
      <ListItemText>
        <Typography variant="h6Regular" component="div">
          {t('accessibility_features', { ns: i18nNS.SETTINGS })}
        </Typography>
      </ListItemText>
      <Switch
        edge="end"
        checked={isAccessibilityOn}
        disabled={isTogglingAccessibility}
        onChange={handleToggleAccessibility}
        aria-label={t(
          isAccessibilityOn ? 'click_to_turn_off_accessibility' : 'click_to_turn_on_accessibility',
          { ns: i18nNS.SETTINGS }
        )}
      />
    </ListItem>
  );
};

export default AccessibilityFeatures;
