import { Ref, useEffect, useImperativeHandle, useMemo } from 'react';

import { Editor as SlateEditor } from 'slate';

import deserialize from '../utils/deserialize';
import editorFactory from '../utils/editor-factory';

export interface VMProps {
  richtext: RichText;
}

export interface RichTextViewRef {
  /** @returns true when editor has at least one formula element */
  hasFormula: SlateEditor['hasFormula'];
  /** @returns `true` when editor has at least one image element */
  hasImage: SlateEditor['hasImage'];
}

export default function useRichTextView({ richtext }: VMProps, ref: Ref<RichTextViewRef>) {
  const value = useMemo(() => deserialize(richtext), [richtext]);

  const editor = useMemo(
    () =>
      editorFactory({
        uploadFormula: async () => ({ id: '', ach: '', acw: '', url: '' }),
        uploadImage: async () => ({ ach: '', acw: '', url: '' }),
      }),
    []
  );

  useImperativeHandle(ref, () => {
    return {
      hasFormula: () => editor.hasFormula(),
      hasImage: () => editor.hasImage(),
    } as RichTextViewRef;
  });

  useEffect(
    function reset() {
      /**
       * NOTE: it can be missused to control the editor from parent component,
       * may be we can use a counter to throw warning if this effect is triggered
       * more than 50 times (or so) in 500ms
       */
      editor.children = value;
      editor.onChange();
    },
    [editor, value]
  );

  return { editor, value };
}
