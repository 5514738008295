import { SvgIcon, SvgIconProps } from '@mui/material';

const ItalicIcon = (props: SvgIconProps) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97 19L12.72 5H10C9.73478 5 9.48043 4.89464 9.29289 4.70711C9.10536 4.51957 9 4.26522 9 4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73478 19 4C19 4.26522 18.8946 4.51957 18.7071 4.70711C18.5196 4.89464 18.2652 5 18 5H14.792L11.04 19H14C14.2652 19 14.5196 19.1054 14.7071 19.2929C14.8946 19.4804 15 19.7348 15 20C15 20.2652 14.8946 20.5196 14.7071 20.7071C14.5196 20.8946 14.2652 21 14 21H6C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20C5 19.7348 5.10536 19.4804 5.29289 19.2929C5.48043 19.1054 5.73478 19 6 19H8.97Z"
    />
  </SvgIcon>
);

export default ItalicIcon;
