import { useContext } from 'react';

import { TabsContext } from './context';

const useTabsVM = () => {
  const { activeTabData } = useContext(TabsContext);
  const { left, width, contentWidth } = activeTabData;

  /**
   * width - contentWidth => white space around content
   * (width - contentWidth) / 2 => white space around content on left side
   */
  const activeTabIndicatorLeft = left + (width - contentWidth) / 2;
  const activeTabIndicatorWidth = contentWidth;

  return { activeTabIndicatorLeft, activeTabIndicatorWidth };
};

export default useTabsVM;
