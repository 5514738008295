import i18n, { i18nNS } from '../i18n';

export const CREATE_COURSE_STATE_KEY = 'createCourse';

export const COURSE_COUNT_OPTIONS = [
  { id: '1', title: '1' },
  { id: '2', title: '2' },
  { id: '3', title: '3' },
  { id: '4', title: '4' },
  { id: '5', title: '5' },
  { id: '6', title: '6' },
  { id: '7', title: '7' },
  { id: '8', title: '8' },
  { id: '9', title: '9' },
  { id: '10+', title: i18n.t('N_or_more', { ns: i18nNS.COMMON, count: 10 }) },
];

export const STUDENT_COUNT_OPTIONS = [
  { id: '0-50', title: i18n.t('less_than_N', { ns: i18nNS.COMMON, count: 50 }) },
  { id: '50-100', title: '50-100' },
  { id: '100-150', title: '100-150' },
  { id: '150-200', title: '150-200' },
  { id: '200-250', title: '200-250' },
  { id: '250-300', title: '250-300' },
  { id: '300-350', title: '300-350' },
  { id: '350-400', title: '350-400' },
  { id: '400-450', title: '400-450' },
  { id: '450-500', title: '450-500' },
  { id: '500+', title: i18n.t('more_than_N', { ns: i18nNS.COMMON, count: 500 }) },
];
