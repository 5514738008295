import { useTranslation } from 'react-i18next';

import { Add as AddIcon } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

import Button from '../../../../components/Button';
import { i18nNS } from '../../../../i18n';
import AddWeeklyClassDrawer from './AddWeeklyClassDrawer';
import CourseSchedule from './CourseSchedule';
import useWeeklyScheduleVM from './vm';

interface Props {
  className?: string;
}

const WeeklySchedule = ({ className }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);

  const {
    isAddWeeklyClassDrawerOpen,
    onAddWeeklyClassButtonClick: handleAddWeeklyClassButtonClick,
    onCloseAddWeeklyClassDrawer: handleCloseAddWeeklyClassDrawer,
  } = useWeeklyScheduleVM();

  return (
    <Stack className={className} gap={3}>
      <Typography variant="h6Bold">{t('weekly_schedule', { ns: i18nNS.CREATE_COURSE })}</Typography>
      <Typography>
        {t('a_weekly_schedule_automatically_creates_your_course_timeline_with_the_scheduled_classes', {
          ns: i18nNS.CREATE_COURSE,
        })}{' '}
        {t('if_you_do_not_specify_a_weekly_session_schedule_you_will_have_to_create_each_class_manually', {
          ns: i18nNS.CREATE_COURSE,
        })}
      </Typography>
      <CourseSchedule />
      <Button
        fullWidth
        variant="outlined"
        size="large"
        startIcon={<AddIcon color="primary" />}
        onClick={handleAddWeeklyClassButtonClick}
      >
        {t('add_weekly_meeting_schedule', { ns: i18nNS.CREATE_COURSE })}
      </Button>
      <AddWeeklyClassDrawer open={isAddWeeklyClassDrawerOpen} onClose={handleCloseAddWeeklyClassDrawer} />
    </Stack>
  );
};

export default WeeklySchedule;
