import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../../../../../components/Button';
import { generateClasses } from '../../../../../../../../utils/helpers';

export const commentStatsClasses = {
  ...generateClasses('CommentStats', ['button']),
};

export const Root = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
      variant="text"
      {...props}
      ref={ref}
      className={clsx(commentStatsClasses.button, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${commentStatsClasses.button}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(5),
    color: theme.palette.grey[500],
  },
}));
