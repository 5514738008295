import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { ActivityUpdatedEvent } from '../courses/pusher-events';
import { API } from '../shared/api-responses';
import { WithCurrentUser } from '../shared/types';
import { PollPublishedPayload, PollSubmittedEvent } from './pusher-events';
import {
  AddPollQuestionPayload,
  AddPollQuestionSuccessPayload,
  CreatePollSuccessPayload,
  DeletePollSuccessPayload,
  EditPollQuestionPayload,
  EditPollQuestionSuccessPayload,
  EditPollSuccessPayload,
  EditPublishedPollPayload,
  EditPublishedPollSuccessPayload,
  FetchAllPollsSuccessPayload,
  FetchPollDetailsSuccessPayload,
  FetchPollSubmissionsSuccessPayload,
  PollId,
  PollStoppedPayload,
  PublishPollPayload,
  PublishPollSuccessPayload,
  SavePollPublishPrefsPayload,
  StopPollPayload,
  StopPollSuccessPayload,
  SubmitPollPayload,
  SubmitPollSuccessPayload,
} from './types';

export const openPoll = createAction<PollId>('polls/open');
export const closePoll = createAction<PollId>('polls/close');

export const fetchAllPolls = createRequestAction<void, FetchAllPollsSuccessPayload>('polls/fetch/all');

export const createPoll = createRequestAction<API.CreatePollRequest, CreatePollSuccessPayload>(
  'polls/create'
);

export const editPoll = createRequestAction<API.EditPollRequest, EditPollSuccessPayload>('polls/edit');

export const deletePoll = createRequestAction<API.DeletePollRequest, DeletePollSuccessPayload>(
  'polls/delete'
);

export const addPollQuestion = createRequestAction<AddPollQuestionPayload, AddPollQuestionSuccessPayload>(
  'polls/questions/add'
);

export const editPollQuestion = createRequestAction<EditPollQuestionPayload, EditPollQuestionSuccessPayload>(
  'polls/questions/edit'
);

export const savePollPublishPrefs = createRequestAction<
  SavePollPublishPrefsPayload,
  SavePollPublishPrefsPayload
>('polls/publish-prefs/save');

export const publishPoll = createRequestAction<PublishPollPayload, PublishPollSuccessPayload>(
  'polls/publish'
);

export const pollPublished = createAction<PollPublishedPayload>('polls/published');

export const editPublishedPoll = createRequestAction<
  EditPublishedPollPayload,
  EditPublishedPollSuccessPayload
>('polls/published/edit');

export const fetchPollDetails = createRequestAction<PollId, FetchPollDetailsSuccessPayload>('polls/fetch');

export const pollEdited = createAction<ActivityUpdatedEvent>('polls/edited');

export const submitPoll = createRequestAction<SubmitPollPayload, SubmitPollSuccessPayload>('polls/submit');

export const pollSubmitted = createAction<WithCurrentUser<PollSubmittedEvent>>('poll/submitted');

export const stopPoll = createRequestAction<StopPollPayload, StopPollSuccessPayload>('polls/stop');

export const pollStopped = createAction<PollStoppedPayload>('polls/stopped');

export const fetchPollSubmissions = createRequestAction<PollId, FetchPollSubmissionsSuccessPayload>(
  'polls/submissions/fetch'
);
