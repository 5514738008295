import { createReducer, Dictionary } from '@reduxjs/toolkit';

import { fetchClassSummary } from '../classes/actions';
import { closeCourse, fetchCourseDetails, fetchCourseInfo, openCourse } from '../courses/actions';
import { TimelineItemType } from '../shared/types';
import { addCourseLink, fetchCourseLinks, removeCourseLink } from './actions';
import { CourseLink } from './types';

interface LinksState {
  courseId: MongoId;
  byId: Dictionary<CourseLink>;
}

const initialState: LinksState = {
  courseId: '',
  byId: {},
};

const linksReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const { courseData = [] } = action.payload;
    for (const item of courseData) {
      if (item.nodeType !== TimelineItemType.CLASS) continue;
      for (const link of item.summary.links) {
        state.byId[link.linkId] = {
          id: link.linkId,
          title: link.title,
          url: link.url,
        };
      }
    }
  });

  builder.addCase(fetchCourseInfo.success, (state, action) => {
    const { readingList } = action.payload;
    for (const link of readingList.links) {
      state.byId[link._id] = {
        id: link._id,
        title: link.title,
        url: link.url,
      };
    }
  });

  builder.addCase(fetchCourseLinks.success, (state, action) => {
    const { links } = action.payload;
    for (const link of links) {
      state.byId[link.id] = link;
    }
  });

  builder.addCase(addCourseLink.success, (state, action) => {
    const { link } = action.payload;
    state.byId[link.id] = link;
  });

  builder.addCase(removeCourseLink.success, (state, action) => {
    const { linkId } = action.payload;
    state.byId[linkId] = undefined;
  });

  builder.addCase(fetchClassSummary.success, (state, action) => {
    const { links } = action.payload;
    for (const { linkId: id, title, url } of links) {
      state.byId[id] = { id, title, url };
    }
  });
});

export default linksReducer;
