import { Divider as MuiDivider, styled, Typography, TypographyProps } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const listSeparatorClasses = {
  ...generateClasses('ListSeparator', [
    'colorPrimary',
    'colorSuccess',
    'colorWarning',
    'colorError',
    'edgeDivider',
  ]),
};

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [`&.${listSeparatorClasses.colorPrimary}`]: {
    color: theme.palette.primary[600],
  },
  [`&.${listSeparatorClasses.colorSuccess}`]: {
    color: theme.palette.success[600],
  },
  [`&.${listSeparatorClasses.colorWarning}`]: {
    color: theme.palette.warning[600],
  },
  [`&.${listSeparatorClasses.colorError}`]: {
    color: theme.palette.error[600],
  },
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: 'currentColor',
  flex: 1,
  [`&.${listSeparatorClasses.edgeDivider}`]: {
    flex: 'unset',
    width: '8%',
  },
}));

export const Label = styled((props: TypographyProps) => (
  <Typography variant="textSmBold" color="inherit" {...props} />
))(({ theme }) => ({
  textTransform: 'uppercase',
  padding: theme.spacing(0, 1),
}));
