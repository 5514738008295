import React, { Fragment } from 'react';

import { Divider } from '@mui/material';

import { AttendanceProcessFailureType } from '../../../../db/shared/types';
import AttendeeError from './AttendeeError';
import UnresponsiveAttendeeError from './UnresponsiveAttendeesError';
import useAttendanceErrorVM from './vm';

const AttendanceError = () => {
  const { attendanceInProgress, getKey } = useAttendanceErrorVM();

  if (!attendanceInProgress) return null;

  const { failures } = attendanceInProgress;

  return (
    <>
      {failures.map((failure, index) => {
        return failure.type === AttendanceProcessFailureType.UNRESPONSIVE_ATTENDEES_FAILURE ? (
          <Fragment key={getKey(failure)}>
            <UnresponsiveAttendeeError failure={failure} />
            {failures.length - 1 !== index && <Divider variant="middle" />}
          </Fragment>
        ) : (
          <Fragment key={getKey(failure)}>
            <AttendeeError failure={failure} />
            {failures.length - 1 !== index && <Divider variant="middle" />}
          </Fragment>
        );
      })}
    </>
  );
};

export default AttendanceError;
