import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Editor } from 'slate';

import { debounce } from '../../common/helpers';
import { wrapWithMathJaxDelimiters } from '../helpers';
import { FormulaDisplay, FormulaFormat, FormulaProps, FormulaUploadStatus } from '../types';

export interface VMProps {
  editorRef: RefObject<Editor | null>;
  onClose: (formulaAttributes: FormulaProps | null) => any;
}

export default function useCreateFormulaOverlay({ editorRef, onClose }: VMProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{ display, format }, setFormatMenuValue] = useState({
    display: FormulaDisplay.INLINE,
    format: FormulaFormat.TEX,
  });

  const [isPreviewPlaceholderVisible, setIsPreviewPlaceholderVisible] = useState(true);

  const onCancel = () => onClose(null);

  const onSubmit = async () => {
    const input = inputRef.current;
    if (!input?.value) return;

    if (!editorRef.current) return;

    const attrs = await editorRef.current.formula.upload({
      display,
      format,
      math: input.value,
    });

    onClose({
      ...attrs,
      display,
      format,
      code: input.value,
      uploadStatus: FormulaUploadStatus.UPLOADED,
    });
  };

  const renderPreview = useCallback(() => {
    const parent = previewRef.current;
    if (!parent) return;

    const formula = parent.querySelector('formula');

    if (formula) {
      window.MathJax.typesetClear?.([formula]);
      parent.removeChild(formula);
    }

    const code = inputRef.current?.value;

    if (!code) {
      setIsPreviewPlaceholderVisible(true);
      return;
    }

    setIsPreviewPlaceholderVisible(false);

    const element = document.createElement('formula');

    element.innerHTML = wrapWithMathJaxDelimiters({ code, display, format });

    parent.append(element);

    try {
      window.MathJax.typeset?.([element]);
    } catch (error) {
      // swallow error
    }
  }, [display, format]);

  const refreshPreview = useMemo(() => debounce(renderPreview, 500), [renderPreview]);

  useEffect(
    function updatePreviewOnMenuValueChange() {
      renderPreview();
    },
    [renderPreview]
  );

  return {
    display,
    format,
    inputRef,
    isPreviewPlaceholderVisible,
    onCancel,
    setFormatMenuValue,
    onSubmit,
    previewRef,
    refreshPreview,
  };
}
