import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { selectAuthSession } from '../auth/store/selectors';
import AttendanceInProgressDialog from '../class/Attendance/AttendanceInProgressDialog';
import AttendanceScheduleToStartDialog from '../class/Attendance/AttendanceScheduleToStartDialog';
import RecordingInPersonAttendance from '../class/Attendance/RecordingInPersonAttendance';
import MediaPlayerPip from '../components/MediaPlayerPip';
import LazyCourseModule from '../courses';
import Archived from '../courses/Archived';
import { fetchUserPreferences } from '../db/app/actions';
import { fetchMyCourses } from '../db/courses/actions';
import Home from '../home';
import ZoomMeetingContextProvider from '../online-meeting/ZoomMeetingContext';
import ZoomMeetingPip from '../online-meeting/ZoomMeetingPip';
import { usePageManager } from '../pages/hooks';
import routes from '../pages/routes';
import { AcadlyPage } from '../pages/types';
import { startPusher } from '../pusher/actions';
import Refer from '../refer';
import Settings from '../settings';
import Page from '../shared/Layout/Page';
import PaymentWall from '../shared/PaymentWall';
import Logger from '../utils/logger';
import { useRequestDispatch } from '../utils/request-actions';

const logger = Logger.create('RootModule');

const RootModule = () => {
  const dispatch = useDispatch();
  const requestDispatch = useRequestDispatch();

  const session = useSelector(selectAuthSession);

  const { setPageReady } = usePageManager(AcadlyPage.ROOT);

  useEffect(
    function initPage() {
      if (!session) return;

      dispatch(startPusher(session));

      try {
        requestDispatch(fetchUserPreferences)
          .then(() => requestDispatch(fetchMyCourses))
          .then(setPageReady);
      } catch (error) {
        logger.error(error);
      }
    },
    [dispatch, requestDispatch, session, setPageReady]
  );

  if (!session?.token) {
    return (
      <Redirect
        to={{
          pathname: routes.login.path,
        }}
      />
    );
  }

  return (
    <ZoomMeetingContextProvider>
      <Page>
        <Switch>
          <Route path={routes.archives.path}>
            <Archived />
          </Route>
          <Route path={routes.home.path}>
            <Home />
          </Route>
          <Route path={routes.settings.path}>
            <Settings />
          </Route>
          <Route path={routes.refer.path}>
            <Refer />
          </Route>
          <Route path={routes.course.path}>
            <PaymentWall>
              <LazyCourseModule />
            </PaymentWall>
          </Route>
          <Redirect to={routes.home.path} />
        </Switch>
      </Page>
      <AttendanceInProgressDialog />
      <RecordingInPersonAttendance isInPip />
      <AttendanceScheduleToStartDialog />
      <MediaPlayerPip />
      <ZoomMeetingPip />
    </ZoomMeetingContextProvider>
  );
};

export default RootModule;
