import { configureStore } from './configureStore';
import rootSagas from './sagas';

const { store: appStore, runSaga } = configureStore();

// run statically imported sagas
let tasks = rootSagas.map(runSaga);

if (module.hot) {
  module.hot.accept('./sagas', async () => {
    const nextRootSagas = require('./sagas').default;
    await Promise.all(
      tasks.map((task) => {
        task.cancel();
        return task.toPromise();
      })
    );
    tasks = nextRootSagas.map(runSaga);
  });
}

export default appStore;
