import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Typography from '../../components/Typography';
import UserList from '../../components/UserList';
import { i18nNS } from '../../i18n';
import { studentWithRaisedHandDialogClasses, StyledAlert, UserCell } from './styles';
import useStudentWithHandRaisedDialogVM, { VMProps } from './vm';

interface Props extends VMProps {}

const StudentWithHandRaisedDialog = ({ ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.COURSES, i18nNS.GLOSSARY]);

  const { addressStudent, container, handleCloseDialog, isOpen, participants, timeDistanceFromNow } =
    useStudentWithHandRaisedDialogVM(vmOptions);

  return (
    <StyledAlert open={isOpen} onClose={handleCloseDialog} container={container}>
      <Alert.Header>
        <div className={studentWithRaisedHandDialogClasses.alertHeader}>
          <Typography variant="h6Bold">{t('raised_hands', { ns: i18nNS.ONLINE_MEETING })}</Typography>
          <Typography variant="paragraphRegular">
            {t('click_on_a_student_to_allow_them_to_speak', { ns: i18nNS.ONLINE_MEETING })}
          </Typography>
        </div>
      </Alert.Header>
      <Alert.Body>
        <UserList users={participants}>
          {(participant) => (
            <UserCell
              key={participant.userId}
              title={participant.name}
              avatar={participant.avatar}
              subtitle={timeDistanceFromNow(participant.handRaisedAt)}
              onClick={addressStudent(participant)}
            />
          )}
        </UserList>
      </Alert.Body>
      <Alert.Footer className={studentWithRaisedHandDialogClasses.alertFooter}>
        <Alert.Action>{t('close', { ns: i18nNS.GLOSSARY })}</Alert.Action>
      </Alert.Footer>
    </StyledAlert>
  );
};

export default StudentWithHandRaisedDialog;
