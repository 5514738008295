import { styled } from '@mui/material';

import Navigation from './Navigation';

export const Content = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

export const StyledNavigation = styled(Navigation)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(3, 5),
}));
