import { MouseEventHandler } from 'react';

import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import BoldIcon from '../../icons/BoldIcon';
import ItalicIcon from '../../icons/ItalicIcon';
import UnderlineIcon from '../../icons/UnderlineIcon';
import ToolBarButton, { Props as ToolBarButtonProps } from '../../ToolBarButton';
import { TextFormat } from '../../types';
import useTextFormattingButtonsVM, { Props as VMProps } from './vm';

const textFormattingButtonsClasses = generateClasses('TextFormattingButtons', [
  'toolbarButton',
  'bold',
  'italic',
  'underline',
  'toolbarIcon',
  'boldIcon',
  'italicIcon',
  'underlineIcon',
]);

export type Props = VMProps &
  ToolBarButtonProps & {
    classes?: Partial<typeof textFormattingButtonsClasses>;
    boldbuttonId?: string;
    italicbuttonId?: string;
    underlinebuttonId?: string;
    activeItemId?: string;
  };

const TextFormattingButtons = ({
  classes,
  editorRef,
  boldbuttonId,
  italicbuttonId,
  underlinebuttonId,
  activeItemId,
  ...props
}: Props) => {
  const { isBoldActive, isItalicActive, isUnderlineActive, onToggleMark } = useTextFormattingButtonsVM({
    editorRef,
  });

  const handleMouseDown =
    (mark: keyof TextFormat): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      console.log('test bold', '>>>>>>>>');
      event.preventDefault();
      onToggleMark(mark);
    };

  return (
    <>
      <ToolBarButton
        id={boldbuttonId}
        {...props}
        isActive={isBoldActive}
        // onClick={handleMouseDown('bold')}
        onMouseDown={handleMouseDown('bold')}
        className={clsx(
          textFormattingButtonsClasses.toolbarButton,
          classes?.toolbarButton,
          textFormattingButtonsClasses.bold,
          classes?.bold
        )}
        tabIndex={-1}
        aria-selected={boldbuttonId === activeItemId}
      >
        <BoldIcon
          fontSize="small"
          className={clsx(
            textFormattingButtonsClasses.toolbarIcon,
            classes?.toolbarIcon,
            textFormattingButtonsClasses.boldIcon,
            classes?.boldIcon
          )}
        />
      </ToolBarButton>
      <ToolBarButton
        id={italicbuttonId}
        {...props}
        isActive={isItalicActive}
        onMouseDown={handleMouseDown('italic')}
        className={clsx(
          textFormattingButtonsClasses.toolbarButton,
          classes?.toolbarButton,
          textFormattingButtonsClasses.italic,
          classes?.italic
        )}
        tabIndex={-1}
        aria-selected={italicbuttonId === activeItemId}
      >
        <ItalicIcon
          fontSize="small"
          className={clsx(
            textFormattingButtonsClasses.toolbarIcon,
            classes?.toolbarIcon,
            textFormattingButtonsClasses.italicIcon,
            classes?.italicIcon
          )}
        />
      </ToolBarButton>
      <ToolBarButton
        id={underlinebuttonId}
        {...props}
        isActive={isUnderlineActive}
        onMouseDown={handleMouseDown('underline')}
        className={clsx(
          textFormattingButtonsClasses.toolbarButton,
          classes?.toolbarButton,
          textFormattingButtonsClasses.underline,
          classes?.underline
        )}
        tabIndex={-1}
        aria-selected={underlinebuttonId === activeItemId}
      >
        <UnderlineIcon
          fontSize="small"
          className={clsx(
            textFormattingButtonsClasses.toolbarIcon,
            classes?.toolbarIcon,
            textFormattingButtonsClasses.underlineIcon,
            classes?.underlineIcon
          )}
        />
      </ToolBarButton>
    </>
  );
};

export default TextFormattingButtons;
