import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { WithCurrentUser } from '../shared/types';
import { CommentDeletedPayload, CommentMarkedEvent, CommentRatedEvent } from './pusher-events';
import {
  CommentContextData,
  CommentCreatedPayload,
  CommentId,
  CommentIdWithCurrentUser,
  CreateCommentPayload,
  CreateCommentSuccessPayload,
  ExportAllCommentStatsPayload,
  FetchCommentReactionsSuccessPayload,
  FetchCommentsPayload,
  FetchCommentsSuccessPayload,
  MarkCommentPayload,
  MarkCommentsAsSeenSuccessPayload,
  MarkCommentSuccessPayload,
  RateCommentPayload,
  RateCommentSuccessPayload,
  SubscribeToCommentsPayload,
} from './types';

export const setCommentContext = createAction<CommentContextData | null>('comments/context/set');

export const fetchComments = createRequestAction<FetchCommentsPayload, FetchCommentsSuccessPayload>(
  'comments/fetch'
);

export const createComment = createRequestAction<CreateCommentPayload, CreateCommentSuccessPayload>(
  'comments/create'
);

export const commentCreated = createAction<CommentCreatedPayload>('comments/created');

export const markCommentsAsSeen = createRequestAction<void, MarkCommentsAsSeenSuccessPayload>(
  'comments/mark/seen'
);

export const fetchCommentReactions = createRequestAction<CommentId, FetchCommentReactionsSuccessPayload>(
  'comments/reactions/fetch'
);

export const deleteComment = createRequestAction<CommentId, CommentIdWithCurrentUser>('comments/delete');

export const commentDeleted = createAction<CommentDeletedPayload>('comments/deleted');

export const markComment = createRequestAction<MarkCommentPayload, MarkCommentSuccessPayload>(
  'comments/mark'
);

export const commentMarked = createAction<WithCurrentUser<CommentMarkedEvent>>('comments/marked');

export const rateComment = createRequestAction<RateCommentPayload, RateCommentSuccessPayload>(
  'comments/rate'
);

export const commentRated = createAction<WithCurrentUser<CommentRatedEvent>>('comments/rated');

export const exportAllCommentsStats = createRequestAction<
  ExportAllCommentStatsPayload,
  API.ExportAllCommentStatsResponse
>('comments/export-stats/all');

export const subscribeToComments = createRequestAction<
  SubscribeToCommentsPayload,
  SubscribeToCommentsPayload,
  SubscribeToCommentsPayload
>('comments/subscribe');

export const setCanUpdateNewIndicatorPosition = createAction<boolean>(
  'comments/set/can-update-new-indicator-position'
);
