import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import AlertButton, { Props as AlertButtonProps } from '../../components/AlertButton';
import Button, { Props as ButtonProps } from '../../components/Button';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import { generateClasses } from '../../utils/helpers';

const joinCodeClasses = {
  ...generateClasses('JoinCode', ['noJoinCodeLink']),
};

export const AlertLink = styled(
  forwardRef((props: AlertButtonProps, ref: Ref<HTMLButtonElement>) => (
    <AlertButton
      variant="text"
      {...props}
      ref={ref}
      className={clsx(joinCodeClasses.noJoinCodeLink, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${joinCodeClasses.noJoinCodeLink}`]: {
    alignSelf: 'flex-start',
    fontWeight: theme.typography.paragraphRegular.fontWeight,
    padding: 0,
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof AlertButton;

export const BackButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="outlined" {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 92,
}));

export const NextButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="contained" endIcon={<ChevronRightIcon />} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  flex: 1,
}));
