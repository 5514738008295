import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import { i18nNS } from '../../../../i18n';
import ArrowForwardIcon from '../../../../icons/ArrowForwardIcon';
import { generateClasses } from '../../../../utils/helpers';
import SelectEndDate from '../SelectEndDate';
import SelectStartDate from '../SelectStartDate';
import SelectTimezone from '../SelectTimezone';
import useCourseDatesVM, { Props as VMProps } from './vm';

const courseDatesClasses = generateClasses('CourseDates', ['datePicker', 'arrowIcon']);

const DateWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [`.${courseDatesClasses.datePicker}`]: {
    flex: 1,
  },
  [`.${courseDatesClasses.arrowIcon}`]: {
    flexShrink: 0,
    marginTop: theme.spacing(7),
    color: theme.palette.grey[400],
  },
}));

interface Props<T extends FieldValues> extends VMProps<T> {
  className?: string;
}

const CourseDates = <T extends FieldValues>({ className, ...vmOptions }: Props<T>) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.COMMON]);

  const { endDateControlName, startDateControlName, timezoneControlName } = vmOptions;
  const { timezone } = useCourseDatesVM(vmOptions);

  return (
    <Stack className={className} gap={3}>
      <Typography variant="h6Bold">{t('course_dates', { ns: i18nNS.COMMON })}</Typography>
      <SelectTimezone controlName={timezoneControlName} />
      <DateWrapper>
        <SelectStartDate
          disabled={!timezone}
          controlName={startDateControlName}
          endDateControlName={endDateControlName}
          className={courseDatesClasses.datePicker}
          helperText={
            !timezone ? t('select_a_your_course_timezone_first', { ns: i18nNS.CREATE_COURSE }) : undefined
          }
        />
        <ArrowForwardIcon className={courseDatesClasses.arrowIcon} />
        <SelectEndDate
          disabled={!timezone}
          controlName={endDateControlName}
          startDateControlName={startDateControlName}
          className={courseDatesClasses.datePicker}
          helperText={
            !timezone ? t('select_a_your_course_timezone_first', { ns: i18nNS.CREATE_COURSE }) : undefined
          }
        />
      </DateWrapper>
    </Stack>
  );
};

export default CourseDates;
