import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../i18n';
import DownloadStep from './DownloadStep';
import EmailSuccessStep from './EmailSuccessStep';
import ExportStep from './ExportStep';
import { ExportType } from './types';

export interface Props {
  onBack?: () => void;
  onClose: () => void;
  exportTypeTitle: string;
  downloadStepTitle?: string;
  emailStepTitle?: string;
  onGetFileDetails: (
    selectedExportOption: ExportType
  ) => Promise<{ fileName: string; downloadURL: string } | undefined>;
}

const ExportSteps = ({
  onBack,
  onClose,
  exportTypeTitle,
  downloadStepTitle,
  emailStepTitle,
  onGetFileDetails,
}: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const [selectedExportType, setSelectedExportType] = useState<ExportType | null>(null);
  const [fileName, setFileName] = useState('');
  const [downloadURL, setDownloadURL] = useState('');

  const handleSelectExportType = async (selectedExportType: ExportType) => {
    const fileDetails = await onGetFileDetails(selectedExportType);
    if (!fileDetails) return;

    const { fileName, downloadURL } = fileDetails;

    setFileName(fileName);
    setDownloadURL(downloadURL);
    setSelectedExportType(selectedExportType);
  };

  const handleDownloadCopy = () => {
    setSelectedExportType(ExportType.CSV_DOWNLOAD);
  };

  return (
    <>
      {selectedExportType === null && (
        <ExportStep
          title={exportTypeTitle}
          onBack={onBack}
          onClose={onClose}
          onSelect={handleSelectExportType}
        />
      )}
      {selectedExportType === ExportType.CSV_DOWNLOAD && (
        <DownloadStep
          title={downloadStepTitle || t('file_generated', { ns: i18nNS.COMMON })}
          onClose={onClose}
          fileName={fileName}
          downloadURL={downloadURL}
        />
      )}
      {selectedExportType === ExportType.EMAIL_ATTACHMENT && (
        <EmailSuccessStep
          title={emailStepTitle || t('file_mailed', { ns: i18nNS.COMMON })}
          onClose={onClose}
          onDownloadCopy={handleDownloadCopy}
        />
      )}
    </>
  );
};

export default ExportSteps;
