export enum CreateCourseStep {
  INTRODUCTION = 'introduction',
  PAYMENT_METHOD = 'payment_method',
  COST_CALCULATOR = 'cost_calculator',
  PAYMENT_DETAILS = 'enter_payment_details',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_SUCCESS = 'payment_succes',
  PRICING_INQUIRY = 'pricing_inquiry',
  PRICING_INQUIRY_THANKS = 'pricing_inquiry_thanks',
  BASIC_DETAILS = 'basic_details',
  /** Select course blueprint course */
  INITIALIZE = 'initialize',
  /** Copy contents of blueprint course */
  COPY_CONTENT = 'copy_content',
  SCHEDULE = 'schedule',
  TEAM_MEMBERS = 'team_members',
  ENROLLMENT_METHOD = 'enrollment_method',
}
