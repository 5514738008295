import { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

import i18n, { i18nNS } from '../i18n';
import routes from '../pages/routes';

export interface BreadcrumbItem {
  path: string | undefined;
  content: string;
  extraContent?: string;
  status?: string;
}

export enum BreadcrumbItems {
  HOME = 'home',
  ARCHIVED = 'archived',
  COURSE = 'course',
  COURSE_ANALYTICS = 'courseAnalytics',
  STUDENT_ANALYTICS = 'studentAnalytics',
  ANNOUNCEMENT = 'announcement',
  ASSIGNMENT = 'assignment',
  ASSIGNMENT_ANALYTICS = 'assignmentAnalytics',
  CLASS = 'class',
  CLASS_ANALYTICS = 'classAnalytics',
  QUIZ = 'quiz',
  QUIZ_ANALYTICS = 'quizAnalytics',
  POLL = 'poll',
  POLL_ANALYTICS = 'pollAnalytics',
  DISCUSSION = 'discussion',
  DISCUSSION_WORD_CLOUD = 'discussionWordCloud',
  RESOURCE = 'resource',
  RESOURCE_ANALYTICS = 'resourceAnalytics',
  QUERY = 'query',
  SETTINGS = 'settings',
  REFER = 'refer',
}

const defaultBreadcrumbItems: Record<BreadcrumbItems, BreadcrumbItem | null> = {
  [BreadcrumbItems.HOME]: { path: routes.home.url(), content: i18n.t('home', { ns: i18nNS.GLOSSARY }) },
  [BreadcrumbItems.ARCHIVED]: null,
  [BreadcrumbItems.COURSE]: null,
  [BreadcrumbItems.COURSE_ANALYTICS]: null,
  [BreadcrumbItems.STUDENT_ANALYTICS]: null,
  [BreadcrumbItems.ANNOUNCEMENT]: null,
  [BreadcrumbItems.ASSIGNMENT]: null,
  [BreadcrumbItems.ASSIGNMENT_ANALYTICS]: null,
  [BreadcrumbItems.CLASS]: null,
  [BreadcrumbItems.CLASS_ANALYTICS]: null,
  [BreadcrumbItems.QUIZ]: null,
  [BreadcrumbItems.QUIZ_ANALYTICS]: null,
  [BreadcrumbItems.POLL]: null,
  [BreadcrumbItems.POLL_ANALYTICS]: null,
  [BreadcrumbItems.DISCUSSION]: null,
  [BreadcrumbItems.DISCUSSION_WORD_CLOUD]: null,
  [BreadcrumbItems.RESOURCE]: null,
  [BreadcrumbItems.RESOURCE_ANALYTICS]: null,
  [BreadcrumbItems.QUERY]: null,
  [BreadcrumbItems.SETTINGS]: {
    path: routes.settings.url(),
    content: i18n.t('settings', { ns: i18nNS.GLOSSARY }),
  },
  [BreadcrumbItems.REFER]: {
    path: routes.refer.url(),
    content: i18n.t('share_the_goodness', { ns: i18nNS.COMMON }),
  },
};

export const BreadcrumbContext = createContext<{
  breadcrumbItems: Record<BreadcrumbItems, BreadcrumbItem | null>;
  updateBreadcrumbItem: (key: BreadcrumbItems, item: BreadcrumbItem | null) => void;
}>({
  breadcrumbItems: defaultBreadcrumbItems,
  updateBreadcrumbItem: () => {},
});

interface Props {
  children: ReactNode;
}

const useBreadcrumbContextProviderVM = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState(defaultBreadcrumbItems);

  const updateBreadcrumbItem = useCallback((key: BreadcrumbItems, item: BreadcrumbItem | null) => {
    setBreadcrumbItems((prevVal) => ({
      ...prevVal,
      [key]: item,
    }));
  }, []);

  return useMemo(
    () => ({
      breadcrumbItems,
      updateBreadcrumbItem,
    }),
    [breadcrumbItems, updateBreadcrumbItem]
  );
};

const BreadcrumbContextProvider = ({ children }: Props) => {
  const providerValue = useBreadcrumbContextProviderVM();

  return <BreadcrumbContext.Provider value={providerValue}>{children}</BreadcrumbContext.Provider>;
};

export default BreadcrumbContextProvider;
