import { useTranslation } from 'react-i18next';

import { ChangeNameDrawer } from '../../auth/Components';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import PersonIcon from '../../icons/PersonIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import useChangeNameVM from './vm';

const ChangeName = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  const { isChangeNameDrawerOpen, handleCloseChangeNameDrawer, handleChangeName } = useChangeNameVM();

  return (
    <>
      <ListItemButton
        onClick={handleChangeName}
        aria-label={t('click_to_change_name', { ns: i18nNS.COMMON })}
        aria-haspopup
      >
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('change_name', { ns: i18nNS.SETTINGS })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <ChangeNameDrawer open={isChangeNameDrawerOpen} onClose={handleCloseChangeNameDrawer} />
    </>
  );
};

export default ChangeName;
