import urls from '../../app/urls';
import { format, unix } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { EditPublishedResourcePayload, ResourceId } from './types';

export async function getAllResources() {
  const { data } = await request.get<API.GetAllResourcesResponse>(urls.getAllResources);
  return data;
}

export async function createResource(payload: API.CreateResourceRequest) {
  const { data } = await request.post<API.CreateResourceResponse>(urls.createResource, payload);
  return data;
}

export async function editResource(payload: API.EditResourceRequest) {
  await request.post(urls.editResource, payload);
}

export async function deleteResource(data: API.DeleteResourceRequest) {
  await request.delete(urls.deleteResource, { data });
}

export async function publishResource({
  subscribeToComments,
  ...params
}: DistributiveOmit<API.PublishResourceRequest, 'subscribeToComments'> & { subscribeToComments: boolean }) {
  const payload: API.PublishResourceRequest = {
    ...params,
    subscribeToComments: subscribeToComments ? 1 : 0,
  };
  const { data } = await request.post<API.PublishResourceResponse>(urls.publishResource, payload);
  return data;
}

export async function editPublishedResource({ notifyStudents, ...params }: EditPublishedResourcePayload) {
  const payload: API.EditPublishedResourceRequest = {
    ...params,
    toNotify: notifyStudents ? 1 : 0,
  };
  await request.put(urls.editPublishedResource, payload);
}

export async function incrementResourceViews({
  resourceId,
  isFirstAccess,
}: ResourceId & { isFirstAccess: boolean }) {
  const accessedOn = unix();
  const payload: API.IncrementResourceViewsRequest = {
    resourceId,
    firstAccess: isFirstAccess ? 1 : 0,
    localTime: format(accessedOn, `yyyyMMdd'T'HH:mm`),
  };
  await request.post(urls.incrementResourceViews, payload);
  return { accessedOn };
}

export async function getResourceAnalytics({ resourceId }: ResourceId) {
  const { data } = await request.get<API.GetResourceAnalyticsResponse>(urls.getResourceAnalytics(resourceId));
  return data;
}
