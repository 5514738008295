import { FormEventHandler, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { joiResolver } from '@hookform/resolvers/joi';

import { ClassModality } from '../../../../../courses/types';
import { addWeeklySchedule } from '../../../../../db/courses/actions';
import { selectCurrentCourse } from '../../../../../db/courses/selectors';
import { ClassType } from '../../../../../db/shared/types';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { FormValues } from './types';
import { proValidationSchema, validationSchema } from './validators';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useAddWeeklyClassDrawerVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const currentCourse = useSelector(selectCurrentCourse);

  const formMethods = useForm<FormValues>({
    // TODO: improve validation schema
    resolver: (values, ...args) => {
      if (currentCourse?.permissions.isPro) return joiResolver(proValidationSchema)(values, ...args);
      return joiResolver(validationSchema)(values, ...args);
    },
    defaultValues: {
      weekdays: [],
      startTime: '10:00',
      endTime: '11:00',
      venue: '',
      classModality: '',
    },
    mode: 'all',
  });

  const { setValue, handleSubmit } = formMethods;

  const saveWeeklySchedule: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit(async (data) => {
      await requestDispatch(addWeeklySchedule, {
        type: ClassType.LECTURE,
        day: data.weekdays,
        startTime: data.startTime,
        endTime: data.endTime,
        venue: data.venue || '',
        isOnlineMeeting: data.classModality ? data.classModality === ClassModality.ONLINE : false,
      });
      onClose();
    })(e);
  };

  useEffect(
    function setInitialValues() {
      if (!open) return;
      setValue('weekdays', []);
      setValue('startTime', '10:00');
      setValue('endTime', '11:00');
      setValue('venue', '');
      setValue('classModality', ClassModality.IN_PERSON);
    },
    [open, setValue]
  );

  return {
    formMethods,
    saveWeeklySchedule,
  };
};

export default useAddWeeklyClassDrawerVM;
