import { styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../components/Typography';

interface Props extends TypographyProps {
  highlighted?: true;
}

const MeetingStatusText = styled(({ ...props }: Props) => <Typography {...props} />, {
  shouldForwardProp: (props) => props !== 'highlighted',
})(({ theme, highlighted }) => ({
  color: highlighted ? theme.palette.primary.main : theme.palette.text.primary,
}));

export default MeetingStatusText;
