import { ReactNode, useEffect, useRef } from 'react';

import { styled, Typography as MuiTypography } from '@mui/material';

interface Props {
  className?: string;
  children: ReactNode;
}

const Typography = styled(MuiTypography)(({ theme }) => ({
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
})) as typeof MuiTypography;

const Body = ({ className, children }: Props) => {
  const firstChildRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    firstChildRef.current?.focus();
  }, []);

  if (typeof children === 'string') {
    return (
      <Typography
        ref={firstChildRef}
        className={className}
        component="div"
        variant="paragraphRegular"
        tabIndex={0}
      >
        {children}
      </Typography>
    );
  }
  return <>{children}</>;
};

export default Body;
