import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getOS } from '../../utils/get-os';
import Logger from '../../utils/logger';
import { API } from '../shared/api-responses';
import { TipStatusKey } from '../shared/types';
import {
  fetchNotificationPrefs,
  fetchUserPreferences,
  markTipsAsSeen,
  saveNotificationPrefs,
  sendFeedback,
  sendReferral,
  setSortPreference,
  toggleAccessibility,
  toggleTips,
} from './actions';
import {
  getNotificationPrefs,
  getUserPreferences,
  saveNotificationPrefs as saveNotificationPrefsAPI,
  sendFeedback as sendFeedbackAPI,
  sendReferrals,
  setSortPreference as setSortPreferenceAPI,
  updateAccessibilityPreference,
  updateTipsPreference,
} from './api';
import { selectTipsPrefs } from './selectors';
import { Accessibility, Tips } from './types';

const log = Logger.create('db/app');

function* fetchUserPreferencesWorker(action: ReturnType<typeof fetchUserPreferences.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getUserPreferences> = yield call(getUserPreferences);
    yield put(fetchUserPreferences.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchUserPreferences.failure(requestId));
  }
}

function* setSortPreferenceWorker(action: ReturnType<typeof setSortPreference.request>) {
  const { sortBy } = action.payload;
  const { requestId } = action.meta;
  try {
    yield call(setSortPreferenceAPI, { sortBy });
    yield put(setSortPreference.success(requestId, { sortBy }));
  } catch (error) {
    log.error(error);
    yield put(setSortPreference.failure(requestId));
  }
}

function* markTipsAsSeenWorker(action: ReturnType<typeof markTipsAsSeen.request>) {
  const { requestId } = action.meta;
  try {
    const tips: YieldSelectorType<typeof selectTipsPrefs> = yield select(selectTipsPrefs);
    if (tips.status === null) return;

    const { seenTipKeys, turnOff } = action.payload;

    let status = {} as NonNullable<Tips['status']>;

    for (let _statusKey in tips.status) {
      const statusKey = _statusKey as TipStatusKey;
      status[statusKey] = {
        priority: tips.status[statusKey].priority,
        seen: seenTipKeys.includes(statusKey) ? true : tips.status[statusKey].seen,
      };
    }

    const updatedTips: Tips = {
      status,
      turnOff,
    };

    yield call(updateTipsPreference, updatedTips);
    yield put(markTipsAsSeen.success(requestId, updatedTips));
  } catch (error) {
    log.error(error);
    yield put(markTipsAsSeen.failure(requestId));
  }
}

function* toggleTipsWorker(action: ReturnType<typeof toggleTips.request>) {
  const { requestId } = action.meta;
  try {
    const tips: YieldSelectorType<typeof selectTipsPrefs> = yield select(selectTipsPrefs);
    if (tips.status === null) return;

    const updatedTips: Tips = {
      status: tips.status,
      turnOff: action.payload,
    };

    yield call(updateTipsPreference, updatedTips);
    yield put(toggleTips.success(requestId, updatedTips));
  } catch (error) {
    log.error(error);
    yield put(toggleTips.failure(requestId));
  }
}

function* toggleAccessibilityWorker(action: ReturnType<typeof toggleAccessibility.request>) {
  const { requestId } = action.meta;
  try {
    const updatedAccessibility: Accessibility = {
      turnOff: action.payload,
    };

    yield call(updateAccessibilityPreference, updatedAccessibility);
    yield put(toggleAccessibility.success(requestId, updatedAccessibility));
  } catch (error) {
    log.error(error);
    yield put(toggleAccessibility.failure(requestId));
  }
}

function* fetchNotificationPrefsWorker(action: ReturnType<typeof fetchNotificationPrefs.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getNotificationPrefs> = yield call(getNotificationPrefs);
    yield put(fetchNotificationPrefs.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchNotificationPrefs.failure(requestId));
  }
}

function* saveNotificationPrefsWorker(action: ReturnType<typeof saveNotificationPrefs.request>) {
  const { requestId } = action.meta;
  try {
    yield call(saveNotificationPrefsAPI, action.payload);
    yield put(saveNotificationPrefs.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(saveNotificationPrefs.failure(requestId));
  }
}

function* sendReferralWorker(action: ReturnType<typeof sendReferral.request>) {
  const { requestId } = action.meta;
  try {
    yield call(sendReferrals, action.payload);
    yield put(sendReferral.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(sendReferral.failure(requestId));
  }
}

function* sendFeedbackWorker(action: ReturnType<typeof sendFeedback.request>) {
  const { requestId } = action.meta;

  const payload: API.SendFeedbackRequestBody = {
    ...action.payload,
    agent: 'web',
    browser: navigator.userAgent,
    os: getOS(),
    version: process.env.REACT_APP_VERSION,
  };

  try {
    yield call(sendFeedbackAPI, payload);
    yield put(sendFeedback.success(requestId));
  } catch (error) {
    log.error(error);
    yield put(sendFeedback.failure(requestId));
  }
}

export default function* rootAppSagas() {
  try {
    yield takeLatest(fetchUserPreferences.request, fetchUserPreferencesWorker);
    yield takeLatest(setSortPreference.request, setSortPreferenceWorker);
    yield takeLatest(markTipsAsSeen.request, markTipsAsSeenWorker);
    yield takeLatest(toggleTips.request, toggleTipsWorker);
    yield takeLatest(toggleAccessibility.request, toggleAccessibilityWorker);
    yield takeLatest(fetchNotificationPrefs.request, fetchNotificationPrefsWorker);
    yield takeLatest(saveNotificationPrefs.request, saveNotificationPrefsWorker);
    yield takeLatest(sendReferral.request, sendReferralWorker);
    yield takeLatest(sendFeedback.request, sendFeedbackWorker);
  } catch (error) {
    log.error(error);
  }
}
