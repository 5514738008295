import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { FormulaDisplay, FormulaFormat } from '../types';
import { FomulaDisplayLabelMap, FomulaFormatLabelMap } from './constants';

export interface FormatMenuValue {
  format: FormulaFormat;
  display: FormulaDisplay;
}

export interface VMProps {
  value: FormatMenuValue;
  onChange: (value: FormatMenuValue) => any;
}

export default function useFormatMenuVM({ value, onChange }: VMProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [format, setFormat] = useState(value.format);
  const [display, setDisplay] = useState(value.display);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const toggleMenuLabel = `${FomulaFormatLabelMap[value.format]}, ${FomulaDisplayLabelMap[value.display]}`;

  const onOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const onCancel = () => setAnchorEl(null);

  const onDone = () => {
    onChange({ format, display });
    setAnchorEl(null);
  };

  const onDisplayChange = (display: FormulaDisplay) => () => setDisplay(display);
  const onFormatChange = (format: FormulaFormat) => () => setFormat(format);

  useEffect(
    function initValues() {
      if (!isOpen) return;
      setFormat(value.format);
      setDisplay(value.display);
    },
    [isOpen, value.display, value.format]
  );

  return {
    anchorEl,
    display,
    format,
    isOpen,
    onCancel,
    onDisplayChange,
    onDone,
    onFormatChange,
    onOpen,
    setFormat,
    toggleMenuLabel,
  };
}
