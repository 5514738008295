import { useState } from 'react';

const useMoreActionsMenuVM = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (target: HTMLElement) => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleClose,
    handleClick,
  };
};

export default useMoreActionsMenuVM;
