import { useEffect, useRef, useState } from 'react';

import { AxiosResponse } from 'axios';
import { parse as parseQueryParams } from 'query-string';

import { unix } from '../../../utils/datetime';
import request from '../../../utils/request';
import { AttachmentProps } from '../types';

export interface Props<T> extends AttachmentProps<T> {}

const useAttachmentVM = <T>({ requestConfig }: Props<T>) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const [downloadURL, setDownloadURL] = useState<string | null>(null);
  const [isFetchingURL, setIsFetchingURL] = useState(false);

  const fetchDownloadURL = async () => {
    const { data, headers, method = 'POST', url } = requestConfig || {};
    if (!url) return;

    try {
      setIsFetchingURL(true);

      /**
       * API server is expected to send a `link` key in response body
       */
      const response: AxiosResponse<{ link: string }> = await request({
        data: method === 'POST' ? data : undefined,
        headers,
        method,
        params: method === 'GET' ? data : undefined,
        url,
      });

      setDownloadURL(response.data.link);
      setIsFetchingURL(false);

      const queryParams = parseQueryParams(response.data.link);
      const expiry: UnixTime = parseInt(queryParams?.Expires as string);
      const timeout = (expiry ? expiry - unix() : 10 * 60) * 1000;

      /**
       * Delete the file download url after its expiry
       */
      timerRef.current = setTimeout(() => {
        setDownloadURL(null);
        timerRef.current = undefined;
      }, timeout);
    } catch {
      setIsFetchingURL(false);
    }
  };

  useEffect(function onUnmount() {
    return function stopTimer() {
      if (timerRef.current) {
        // clear pending timeouts if component is unmounting
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { fetchDownloadURL, isFetchingURL, downloadURL };
};

export default useAttachmentVM;
