import { AttendanceInProgressData } from '../../../db/classes/types';

export const getIsAttendanceInProgressDialogOpen = ({
  attendanceInProgress,
  isZoomMeetingPipOpen,
  count,
}: {
  attendanceInProgress: AttendanceInProgressData | null;
  isZoomMeetingPipOpen: boolean;
  count: number;
}) => {
  if (!attendanceInProgress) return false;
  if (isZoomMeetingPipOpen) return false;
  return count > 0;
};
