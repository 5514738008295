import { useCallback, useMemo } from 'react';

import { randomStr } from '../../../utils/helpers';
import { CountryType } from './types';

export interface Props {
  id?: string;
}

const useCountryPickerVM = ({ id }: Props) => {
  const ids = useMemo(
    () => ({
      countryPicker: id || `country_picker-${randomStr(4)}`,
    }),
    [id]
  );

  const getFlagImageAttributes = useCallback(
    ({ code }: CountryType) => ({
      src: `https://flagcdn.com/w20/${code.toLowerCase()}.png`,
      srcSet: `https://flagcdn.com/w40/${code.toLowerCase()}.png 2x`,
      width: 20,
    }),
    []
  );

  return { getFlagImageAttributes, ids };
};

export default useCountryPickerVM;
