import { CSSProperties } from 'react';

import { styled } from '@mui/material';

import { RenderElementProps } from 'slate-react';

import useFormulaElement, { VMProps } from './vm';

const Wrapper = styled('div')<{ isActive: boolean; display: 'block' | 'inline' }>(
  ({ theme, isActive, display }) => ({
    display: display === 'inline' ? 'inline-block' : 'block',
    borderRadius: 4,
    boxShadow: isActive ? '0 0 0 2px #B4D5FF' : 'none',
  })
);

type Props = RenderElementProps &
  VMProps & {
    style?: CSSProperties;
  };

const FormulaElement = ({ attributes, children, style, ...vmProps }: Props) => {
  const { isFocused, isSelected, ref } = useFormulaElement(vmProps);

  const { display } = vmProps.element;

  return (
    <Wrapper
      ref={ref}
      style={style}
      isActive={isSelected && isFocused}
      display={display}
      contentEditable={false}
    >
      <span {...attributes}>{children}</span>
    </Wrapper>
  );
};

export default FormulaElement;
