import { useSelector } from 'react-redux';

import { resolveHand } from '../../../db/online-meeting/actions';
import {
  selectParticipantBeingAddressed,
  selectParticipantsWithRaisedHand,
} from '../../../db/online-meeting/selectors';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useStudentBeingAddressedFooterViewVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const requestDispatch = useRequestDispatch();

  const participantsWithRaisedHand = useSelector(selectParticipantsWithRaisedHand(classId));
  const participantBeingAddressed = useSelector(selectParticipantBeingAddressed(classId));

  const hasNext = participantsWithRaisedHand.length > 1;

  const handleClickResolve = async () => {
    if (!participantBeingAddressed) return;
    await requestDispatch(resolveHand, {
      classId,
      userId: participantBeingAddressed.userId,
      addressNext: false,
    });
  };

  const handleClickNext = async () => {
    if (!participantBeingAddressed) return;
    await requestDispatch(resolveHand, {
      classId,
      userId: participantBeingAddressed.userId,
      addressNext: true,
    });
  };

  return {
    handleClickNext,
    handleClickResolve,
    hasNext,
    participantBeingAddressed,
  };
}
