import { createPusherEvent } from '../../pusher/helpers';
import { CoursePusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';

export type AnnouncementCreatedEvent = CoursePusherEvent<{
  announcementId: MongoId;
  /** @deprecated */
  stats: {
    numSeenBy: number;
  };
  details: API.AnnouncementDetails;
}>;

export const announcementCreatedEvent = createPusherEvent<AnnouncementCreatedEvent>(
  PusherEventName.ANNOUNCEMENT_ADDED,
  { channels: [PusherChannel.COURSE] }
);
