import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { announcementTranslations } from '../announcement/i18n';
import { assignmentTranslations } from '../assignment/i18n';
import authTranslations from '../auth/i18n/en';
import { classTranslations } from '../class/i18n';
import { commentsTranslations } from '../comments/i18n';
import { courseTranslations } from '../courses/i18n';
import { createCourseTranslations } from '../create-course/i18n';
import { discussionTranslations } from '../discussion/i18n';
import { feedbackTranslations } from '../feedback/i18n';
import { homeTranslations } from '../home/i18n';
import { onlineMeetingTranslations } from '../online-meeting/i18n';
import { pollTranslations } from '../poll/i18n';
import { queryTranslations } from '../query/i18n';
import { quizTranslations } from '../quiz/i18n';
import { referTranslations } from '../refer/i18n';
import { resourceTranslations } from '../resource/i18n';
import { settingsTranslations } from '../settings/i18n';
import common from './common/en';
import glossary from './glossary/en';
import shared from './shared/en';
import validation from './validation/en';

export enum i18nNS {
  COMMON = 'common',
  SHARED = 'shared',
  GLOSSARY = 'glossary',
  VALIDATION = 'validation',
  AUTH = 'auth',
  HOME = 'home',
  COURSES = 'courses',
  CREATE_COURSE = 'create_course',
  COMMENTS = 'comments',
  SETTINGS = 'settings',
  REFER = 'refer',
  CLASS = 'class',
  FEEDBACK = 'feedback',
  ANNOUNCEMENT = 'announcement',
  ASSIGNMENT = 'assignment',
  QUIZ = 'quiz',
  POLL = 'poll',
  DISCUSSION = 'discussion',
  QUERY = 'query',
  RESOURCE = 'resource',
  ONLINE_MEETING = 'online_meeting',
}

const enLang = {
  [i18nNS.COMMON]: common,
  [i18nNS.SHARED]: shared,
  [i18nNS.GLOSSARY]: glossary,
  [i18nNS.VALIDATION]: validation,
  [i18nNS.AUTH]: authTranslations,
  [i18nNS.HOME]: homeTranslations['en'],
  [i18nNS.COURSES]: courseTranslations['en'],
  [i18nNS.CREATE_COURSE]: createCourseTranslations['en'],
  [i18nNS.COMMENTS]: commentsTranslations['en'],
  [i18nNS.SETTINGS]: settingsTranslations['en'],
  [i18nNS.REFER]: referTranslations['en'],
  [i18nNS.ANNOUNCEMENT]: announcementTranslations['en'],
  [i18nNS.ASSIGNMENT]: assignmentTranslations['en'],
  [i18nNS.CLASS]: classTranslations['en'],
  [i18nNS.QUIZ]: quizTranslations['en'],
  [i18nNS.POLL]: pollTranslations['en'],
  [i18nNS.DISCUSSION]: discussionTranslations['en'],
  [i18nNS.QUERY]: queryTranslations['en'],
  [i18nNS.RESOURCE]: resourceTranslations['en'],
  [i18nNS.ONLINE_MEETING]: onlineMeetingTranslations['en'],
  [i18nNS.FEEDBACK]: feedbackTranslations['en'],
};

if (process.env.NODE_ENV === 'development') {
  const enResources = Object.entries(enLang);
  const duplicates: { namespaces: Set<string>; translationKey: string }[] = [];

  for (let [namespace, translations] of enResources) {
    for (let [comparisonNamespace, comparisonTranslations] of enResources) {
      if (namespace === comparisonNamespace) continue;
      for (let translationKey in translations) {
        if (!comparisonTranslations.hasOwnProperty(translationKey)) continue;

        const duplicateKeyIndex = duplicates.findIndex(
          (duplicate) => duplicate.translationKey === translationKey
        );

        if (duplicateKeyIndex > -1) {
          duplicates[duplicateKeyIndex].namespaces.add(namespace);
          duplicates[duplicateKeyIndex].namespaces.add(comparisonNamespace);
          continue;
        }

        duplicates.push({
          namespaces: new Set([namespace, comparisonNamespace]),
          translationKey,
        });
      }
    }
  }

  if (duplicates.length > 0) {
    console.error('Duplicate translations found:', ...duplicates);
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',

    ns: [i18nNS.COMMON, i18nNS.SHARED, i18nNS.GLOSSARY, i18nNS.VALIDATION],
    defaultNS: i18nNS.COMMON,

    resources: {
      en: enLang,
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ',',
    },

    react: {
      useSuspense: true,
    },
  });

export default i18n;
