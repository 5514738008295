import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthSession } from '../../../auth/store/selectors';
import { selectAttendanceScheduleToStart, selectClass } from '../../../db/classes/selectors';
import { ClassRole } from '../../../db/shared/types';
import routes from '../../../pages/routes';
import { toShortID } from '../../../utils/helpers';

export default function useAttendanceScheduleToStartVM() {
  const session = useSelector(selectAuthSession);
  const attendanceScheduleToStart = useSelector(selectAttendanceScheduleToStart);
  const cls = useSelector(selectClass(attendanceScheduleToStart?.classId ?? ''));

  const [isAttendanceScheduleToStartDialogOpen, setIsAttendanceScheduleToStartDialogOpen] = useState(false);

  const handleCloseAttendanceScheduleToStartDialog = () => {
    setIsAttendanceScheduleToStartDialogOpen(false);
  };

  useEffect(
    function onAttencanceScheduleToStartChange() {
      if (!attendanceScheduleToStart) return;
      if (!attendanceScheduleToStart.courseId) return;
      if (!attendanceScheduleToStart.classId) return;
      if (!cls?.myRole) return;
      if (cls?.myRole === ClassRole.STUDENT) return;
      setIsAttendanceScheduleToStartDialogOpen(true);
    },
    [attendanceScheduleToStart, cls?.myRole]
  );

  const handleGoToClass = () => {
    if (!session) return;
    if (!attendanceScheduleToStart) return;

    handleCloseAttendanceScheduleToStartDialog();

    routes.class.navigate({
      universitySlug: session.university.slug,
      courseShortId: toShortID(attendanceScheduleToStart.courseId),
      classShortId: toShortID(attendanceScheduleToStart.classId),
    });
  };

  return {
    isAttendanceScheduleToStartDialogOpen,
    handleCloseAttendanceScheduleToStartDialog,
    handleGoToClass,
  };
}
