import { useTranslation } from 'react-i18next';

import { ChangePasswordDrawer } from '../../auth/Components';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import LockIcon from '../../icons/LockIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import useChangePasswordVM from './vm';

const ChangePassword = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  const { isChangePasswordDrawerOpen, handleCloseChangePasswordDrawer, handleChangePassword } =
    useChangePasswordVM();

  return (
    <>
      <ListItemButton
        onClick={handleChangePassword}
        aria-label={t('click_to_change_password', { ns: i18nNS.COMMON })}
        aria-haspopup
      >
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('change_password', { ns: i18nNS.SETTINGS })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <ChangePasswordDrawer open={isChangePasswordDrawerOpen} onClose={handleCloseChangePasswordDrawer} />
    </>
  );
};

export default ChangePassword;
