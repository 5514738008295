import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Query } from './types';

export const selectFullQueryId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { queries } = state.db;
  return getFullId(queries, shortId);
};

export const selectQuery = (id: ShortId | MongoId) => (state: RootState) => {
  const { queries } = state.db;
  return getActivityById(queries, id);
};

export const selectAllQueries = (state: RootState) => {
  const { queries } = state.db;

  const publishedQueries: Query[] = [];

  if (!queries.byId) return { publishedQueries };

  for (const query of Object.values(queries.byId)) {
    if (!query) continue;

    publishedQueries.push(query);
  }

  return {
    publishedQueries,
  };
};
