import { Route, Switch } from 'react-router-dom';

import AnnouncementBreadcrumb from '../../../announcement/AnnouncementBreadcrumb';
import AssignmentAnalyticsBreadcrumb from '../../../assignment/AssignmentAnalytics/AssignmentAnalyticsBreadcrumb';
import AssignmentBreadcrumb from '../../../assignment/AssignmentBreadcrumb';
import ClassAnalyticsBreadcrumb from '../../../class/ClassAnalytics/ClassAnalyticsBreadcrumb';
import ClassBreadcrumb from '../../../class/ClassBreadcrumb';
import ArchivedBreadcrumb from '../../../courses/ArchivedBreadcrumb';
import CourseAnalyticsBreadcrumb from '../../../courses/CourseAnalytics/CourseAnalyticsBreadcrumb';
import StudentAnalyticsBreadcrumb from '../../../courses/CourseAnalytics/StudentAnalyticsBreadcrumb';
import CourseBreadcrumb from '../../../courses/CourseBreadcrumb';
import HomeBreadcrumb from '../../../courses/HomeBreadcrumb';
import DiscussionBreadcrumb from '../../../discussion/DiscussionBreadcrumb';
import DiscussionWordCloudBreadcrumb from '../../../discussion/DiscussionWordCloud/DiscussionWordCloudBreadcrumb';
import routes from '../../../pages/routes';
import PollAnalyticsBreadcrumb from '../../../poll/PollAnalytics/PollAnalyticsBreadcrumb';
import PollBreadcrumb from '../../../poll/PollBreadcrumb';
import QueryBreadcrumb from '../../../query/QueryBreadcrumb';
import QuizAnalyticsBreadcrumb from '../../../quiz/QuizAnalytics/QuizAnalyticsBreadcrumb';
import QuizBreadcrumb from '../../../quiz/QuizBreadcrumb';
import ReferBreadcrumb from '../../../refer/ReferBreadcrumb';
import ResourceAnalyticsBreadcrumb from '../../../resource/ResourceAnalytics/ResourceAnalyticsBreadcrumb';
import ResourceBreadcrumb from '../../../resource/ResourceBreadcrumb';
import SettingsBreadcrumb from '../../../settings/SettingsBreadcrumb';
import PaymentWall from '../../PaymentWall';

const Breadcrumb = () => {
  return (
    <Switch>
      <Route exact path={routes.home.path}>
        <HomeBreadcrumb />
      </Route>
      <Route exact path={routes.archives.path}>
        <ArchivedBreadcrumb />
      </Route>
      <Route exact path={routes.settings.path}>
        <SettingsBreadcrumb />
      </Route>
      <Route exact path={routes.refer.path}>
        <ReferBreadcrumb />
      </Route>
      <Route path={routes.course.path}>
        {/* routes protected by a payment wall */}
        <PaymentWall disableLoader fallback={<CourseBreadcrumb />}>
          <Switch>
            <Route
              exact
              path={[
                routes.timeline.path,
                routes.info.path,
                routes.syllabus.path,
                routes.courseAnnouncements.path,
                routes.courseAssignments.path,
              ]}
            >
              <CourseBreadcrumb />
            </Route>
            <Route exact path={[routes.courseAnalytics.path, routes.courseAverages.path]}>
              <CourseAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.studentAnalytics.path}>
              <StudentAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.announcementPage.path}>
              <AnnouncementBreadcrumb />
            </Route>
            <Route exact path={routes.assignmentPage.path}>
              <AssignmentBreadcrumb />
            </Route>
            <Route exact path={routes.assignmentAnalytics.path}>
              <AssignmentAnalyticsBreadcrumb />
            </Route>
            <Route
              exact
              path={[routes.class.path, routes.activities.path, routes.agenda.path, routes.attendance.path]}
            >
              <ClassBreadcrumb />
            </Route>
            <Route exact path={routes.classAnalytics.path}>
              <ClassAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.quiz.path}>
              <QuizBreadcrumb />
            </Route>
            <Route exact path={routes.quizAnalytics.path}>
              <QuizAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.poll.path}>
              <PollBreadcrumb />
            </Route>
            <Route exact path={routes.pollAnalytics.path}>
              <PollAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.discussion.path}>
              <DiscussionBreadcrumb />
            </Route>
            <Route exact path={routes.discussionWordCloud.path}>
              <DiscussionWordCloudBreadcrumb />
            </Route>
            <Route exact path={routes.resource.path}>
              <ResourceBreadcrumb />
            </Route>
            <Route exact path={routes.resourceAnalytics.path}>
              <ResourceAnalyticsBreadcrumb />
            </Route>
            <Route exact path={routes.newQuery.path}>
              <QueryBreadcrumb />
            </Route>
            <Route exact path={routes.query.path}>
              <QueryBreadcrumb />
            </Route>
          </Switch>
        </PaymentWall>
      </Route>
    </Switch>
  );
};

export default Breadcrumb;
