import { useMemo } from 'react';

import { removeWeeklySchedule } from '../../../../../db/courses/actions';
import { GroupedWeeklySchedule } from '../../../../../db/courses/types';
import { format24hTo12hTime } from '../../../../../utils/datetime';
import { useRequestDispatch } from '../../../../../utils/request-actions';

export type VMProps = GroupedWeeklySchedule;

export default function useWeeklyClassVM({
  type,
  days,
  startTime,
  endTime,
  isOnlineMeeting,
  venue,
}: VMProps) {
  const requestDispatch = useRequestDispatch();

  const formattedStartTime = useMemo(() => format24hTo12hTime(startTime), [startTime]);
  const formattedEndTime = useMemo(() => format24hTo12hTime(endTime), [endTime]);

  const deleteClass = async () => {
    await Promise.allSettled(
      days.map((day) => requestDispatch(removeWeeklySchedule, { type, day, startTime }))
    );
  };

  return { days, deleteClass, formattedEndTime, formattedStartTime, isOnlineMeeting, venue };
}
