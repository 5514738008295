import Joi from 'joi';

import { requiredOneOption, requiredString } from '../../../utils/validators';
import { FormValues } from './types';

const validationSchema = Joi.object<FormValues, true>({
  isCreatingProCourse: requiredOneOption({ options: ['true', 'false'] }),
  numCourses: requiredString,
  numStudents: requiredString,
});

export default validationSchema;
