import urls from '../../app/urls';
import request from '../../utils/request';
import { getAvatarURL } from './helpers';

interface FetchAvatarsResponse {
  links: Record<MongoId, AvatarFileName>;
}

export async function fetchAvatars(bucket: string, avatarIds: MongoId[]) {
  const response = await request.post<FetchAvatarsResponse>(urls.getAvatar, { avatarIds });

  const { links } = response.data;
  const avatars: Record<MongoId, AvatarURL> = {};

  for (const avatarId in links) {
    if (Object.prototype.hasOwnProperty.call(links, avatarId)) {
      const fileName = links[avatarId];
      avatars[avatarId] = getAvatarURL(bucket, fileName);
    }
  }

  return avatars;
}
