import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const AwardIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19 20.75L16.69 11.75C17.5441 10.6874 18.0066 9.36329 18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75737 3.75736C6.63215 4.88258 6.00001 6.4087 6.00001 8C5.99727 9.37381 6.47081 10.7061 7.34001 11.77L5.00001 20.75C4.95023 20.9419 4.95864 21.1443 5.02416 21.3315C5.08968 21.5186 5.20936 21.6821 5.36799 21.801C5.52661 21.92 5.71703 21.9891 5.91503 21.9996C6.11303 22.0101 6.30969 21.9615 6.48001 21.86L11.81 18.73L17.49 21.87C17.6435 21.9626 17.8209 22.0078 18 22C18.1553 22.005 18.3096 21.9737 18.4507 21.9086C18.5917 21.8435 18.7157 21.7465 18.8128 21.6252C18.9098 21.5039 18.9773 21.3616 19.0098 21.2097C19.0423 21.0578 19.0389 20.9004 19 20.75ZM12 4C12.7911 4 13.5645 4.2346 14.2223 4.67412C14.8801 5.11365 15.3928 5.73836 15.6955 6.46927C15.9983 7.20017 16.0775 8.00444 15.9231 8.78036C15.7688 9.55629 15.3878 10.269 14.8284 10.8284C14.269 11.3878 13.5563 11.7688 12.7804 11.9231C12.0044 12.0775 11.2002 11.9983 10.4693 11.6955C9.73837 11.3928 9.11365 10.8801 8.67413 10.2223C8.2346 9.56448 8.00001 8.79113 8.00001 8C8.00001 6.93913 8.42143 5.92172 9.17158 5.17157C9.92173 4.42143 10.9391 4 12 4V4Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 20.75L16.69 11.75C17.544 10.6874 18.0066 9.36329 18 8C18 6.4087 17.3678 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88255 2.63214 7.75734 3.75736C6.63212 4.88258 5.99998 6.4087 5.99998 8C5.99724 9.37381 6.47078 10.7061 7.33998 11.77L4.99998 20.75C4.9502 20.9419 4.95861 21.1443 5.02413 21.3315C5.08965 21.5186 5.20933 21.6821 5.36796 21.801C5.52658 21.92 5.71699 21.9891 5.915 21.9996C6.113 22.0101 6.30966 21.9615 6.47998 21.86L11.81 18.73L17.49 21.87C17.6435 21.9626 17.8209 22.0078 18 22C18.1553 22.005 18.3095 21.9737 18.4506 21.9086C18.5917 21.8435 18.7157 21.7465 18.8127 21.6252C18.9098 21.5039 18.9772 21.3616 19.0097 21.2097C19.0422 21.0578 19.0389 20.9004 19 20.75ZM12 4C12.7911 4 13.5645 4.2346 14.2223 4.67412C14.8801 5.11365 15.3927 5.73836 15.6955 6.46927C15.9982 7.20017 16.0775 8.00444 15.9231 8.78036C15.7688 9.55629 15.3878 10.269 14.8284 10.8284C14.269 11.3878 13.5563 11.7688 12.7803 11.9231C12.0044 12.0775 11.2001 11.9983 10.4692 11.6955C9.73834 11.3928 9.11362 10.8801 8.6741 10.2223C8.23457 9.56448 7.99998 8.79113 7.99998 8C7.99998 6.93913 8.4214 5.92172 9.17155 5.17157C9.92169 4.42143 10.9391 4 12 4V4ZM12.31 16.71C12.158 16.6222 11.9855 16.576 11.81 16.576C11.6344 16.576 11.462 16.6222 11.31 16.71L7.55998 18.91L8.99998 13.21C9.90059 13.7276 10.9212 14 11.96 14C12.9988 14 14.0194 13.7276 14.92 13.21L16.45 19L12.31 16.71Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AwardIcon;
