import React from 'react';

import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import Tooltip from '../../components/Tooltip';

const Root = styled('div')(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(8),
}));

const Tooltips = (): JSX.Element => {
  return (
    <Root>
      <Tooltip
        title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quo doloremque corrupti vel reprehenderit repellendus aperiam porro ea tenetur! Vero ex ab enim dignissimos placeat quod fugit. Dolorem, totam quam illum molestias quis fuga assumenda exercitationem porro, neque tenetur eum!"
        placement="top"
      >
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip
        title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quo doloremque corrupti vel reprehenderit repellendus aperiam porro ea tenetur! Vero ex ab enim dignissimos placeat quod fugit. Dolorem, totam quam illum molestias quis fuga assumenda exercitationem porro, neque tenetur eum!"
        placement="bottom"
      >
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip
        title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quo doloremque corrupti vel reprehenderit repellendus aperiam porro ea tenetur! Vero ex ab enim dignissimos placeat quod fugit. Dolorem, totam quam illum molestias quis fuga assumenda exercitationem porro, neque tenetur eum!"
        placement="left"
      >
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip
        title="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Modi quo doloremque corrupti vel reprehenderit repellendus aperiam porro ea tenetur! Vero ex ab enim dignissimos placeat quod fugit. Dolorem, totam quam illum molestias quis fuga assumenda exercitationem porro, neque tenetur eum!"
        placement="right"
      >
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip title="This is a tooltip" placement="top">
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip title="This is a tooltip" placement="bottom">
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip title="This is a tooltip" placement="left">
        <Button>Tooltip</Button>
      </Tooltip>
      <Tooltip title="This is a tooltip" placement="right">
        <Button>Tooltip</Button>
      </Tooltip>
    </Root>
  );
};

export default Tooltips;
