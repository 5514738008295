import { useTranslation } from 'react-i18next';

import { styled, Typography } from '@mui/material';

import Alert from '../../components/Alert';
import AlertButton from '../../components/AlertButton';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import PersonIcon from '../../icons/PersonIcon';
import { forDesktop, forNonDesktop } from '../../utils/media-queries';
import useHomeHeaderVM from './vm';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.warning[100],
  minHeight: 60,
  padding: theme.spacing(3, 8),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  [forNonDesktop(theme)]: {
    padding: theme.spacing(3, 4),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

const MessageIcon = styled(PersonIcon)(() => ({
  width: 18,
  height: 18,
}));

const LearnMoreButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  minWidth: 100,
  [forDesktop(theme)]: {
    // fix to add more specificity
    marginLeft: theme.spacing(7),
  },
  [forNonDesktop(theme)]: {
    marginLeft: theme.spacing(7),
  },
}));

const HomeHeader = () => {
  const { t } = useTranslation([i18nNS.HOME, i18nNS.COMMON, i18nNS.GLOSSARY]);
  const { handleCancelAccountVerification, isProvisionalAccount, university } = useHomeHeaderVM();

  if (!isProvisionalAccount) return null;

  return (
    <Root>
      <MessageWrapper>
        <MessageIcon variant="filled" />
        <Typography>
          {t('we_are_in_the_process_of_verifying_you_as_a_faculty_member_at_university', {
            ns: i18nNS.HOME,
            university,
          })}
        </Typography>
      </MessageWrapper>
      <AlertButton
        endIcon={<ChevronRightIcon />}
        component={LearnMoreButton}
        size="small"
        aria-label={t('click_to_learn_more_about_verification', { ns: i18nNS.HOME })}
      >
        <Typography component="span">{t('learn_more', { ns: i18nNS.COMMON })}</Typography>
        <Alert>
          <Alert.Header>{t('verification_pending', { ns: i18nNS.HOME })}</Alert.Header>
          <Alert.Body>
            <Typography variant="body2" gutterBottom>
              {t(
                'since_acadly_deals_with_student_data_to_meet_with_the_data_security_compliance_requirements_we_ensure_that_every_instructor_account_actually_belongs_to_a_university_faculty_member_or_a_university_staff_or_a_ta',
                { ns: i18nNS.HOME }
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t(
                'this_verification_process_takes_at_most_two_four_hours_and_till_then_users_are_provided_with_a_trial_account_to_try_acadly_out_with_demo_courses',
                { ns: i18nNS.HOME }
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {t(
                'in_case_you_re_a_student_who_has_accidentally_signed_up_for_an_instructor_account_please_click_on_i_m_a_student_button_we_ll_remove_you_from_the_verification_list_and_you_ll_be_able_to_continue_using_acadly_as_a_student',
                { ns: i18nNS.HOME }
              )}
            </Typography>
          </Alert.Body>
          <Alert.Footer>
            <Alert.Action variant="text">{t('back', { ns: i18nNS.GLOSSARY })}</Alert.Action>
            <Alert.Action onClick={handleCancelAccountVerification}>
              {t('i_m_a_student', { ns: i18nNS.HOME })}
            </Alert.Action>
          </Alert.Footer>
        </Alert>
      </AlertButton>
    </Root>
  );
};

export default HomeHeader;
