import Joi from 'joi';

import { requiredString } from '../../../utils/validators';
import { FormValues } from './types';

const validationSchema = Joi.object<FormValues, true>({
  courseCode: requiredString,
  courseTitle: requiredString,
});

export default validationSchema;
