import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@mui/material';

import clsx from 'clsx';

import { i18nNS } from '../../i18n';
import SortAscending from '../../icons.deprecated/SortAscending';
import SortDescending from '../../icons.deprecated/SortDescending';
import Button from '../Button';
import TextField from '../FormControls/TextField';
import SortDialog from './SortDialog';
import { Actions, Root, StyledSearchIcon, UserCells, userListClasses } from './styles';
import useUserListVM, { IdAndName, Props as VMProps, SortOrder } from './vm';

const USER_ID_PREFIX = 'user';

interface Props<T extends IdAndName> extends VMProps<T> {
  showFilterAndSort?: boolean;
  placeholder?: string;
  children: (user: T, index: number, users: T[], itemId: string, focusIndex: number) => ReactNode;
  className?: string;
  classes?: Partial<typeof userListClasses>;
}

const UserList = <T extends IdAndName>({
  showFilterAndSort,
  placeholder,
  children,
  className,
  classes,
  ...vmOptions
}: Props<T>) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const {
    sortButtonRef,
    searchTerm,
    handleChangeSearchTerm,
    sort,
    handleChangeSort,
    sortOrder,
    isSortDialogOpen,
    filteredAndSortedUsers,
    handleSortButtonClick,
    handleCloseSortDialog,
  } = useUserListVM(vmOptions);

  return (
    <Root className={className}>
      {showFilterAndSort && (
        <Actions className={clsx(userListClasses.actions, classes?.actions)}>
          <TextField
            value={searchTerm}
            onChange={handleChangeSearchTerm}
            placeholder={placeholder}
            autoCorrect="off"
            aria-label={t('type_to_filter_results', { ns: i18nNS.COMMON })}
            fullWidth
            endAdornment={<StyledSearchIcon aria-hidden />}
            className={clsx(userListClasses.searchField, classes?.searchField)}
          />
          <Button
            ref={sortButtonRef}
            variant="outlined"
            onClick={handleSortButtonClick}
            aria-label={t('click_to_see_sort_options', { ns: i18nNS.COMMON })}
            startIcon={
              sortOrder === SortOrder.ASC ? (
                <SortAscending aria-hidden fontSize="inherit" />
              ) : (
                <SortDescending aria-hidden fontSize="inherit" />
              )
            }
            className={clsx(userListClasses.sortButton, classes?.sortButton)}
          />
          <SortDialog
            open={isSortDialogOpen}
            onClose={handleCloseSortDialog}
            sortBy={sort}
            onChangeSortBy={handleChangeSort}
          />
        </Actions>
      )}
      <UserCells
        className={clsx(userListClasses.users, classes?.users)}
        type="accessible"
        numItems={filteredAndSortedUsers.length}
        getActiveItemId={(focusIndex) =>
          filteredAndSortedUsers[focusIndex]
            ? `${USER_ID_PREFIX}-${filteredAndSortedUsers[focusIndex].userId}`
            : undefined
        }
      >
        {(focusIndex) =>
          filteredAndSortedUsers.map((...props) => {
            const [user, index, self] = props;
            return (
              <>
                {index !== 0 && <Divider />}
                {children(user, index, self, `${USER_ID_PREFIX}-${user.userId}`, focusIndex)}
              </>
            );
          })
        }
      </UserCells>
    </Root>
  );
};

export default UserList;
