import { createTheme } from '@mui/material';

import baseTheme from './base';
import { createThemeColor } from './helpers';
import { ColorName, colorPalette, ColorVariant } from './variables';

const {
  blue,
  green,
  orange,
  red,
  grey,
}: Record<ColorName, Record<ColorVariant, string>> = Object.freeze({
  blue: {
    50: '#f2f8fe',
    100: '#e6f1fd',
    200: '#bfdcfa',
    300: '#99c7f6',
    400: '#4d9cf0',
    500: '#0072e9',
    600: '#0067d2',
    700: '#0056af',
    800: '#00448c',
    900: '#003872',
  },
  green: {
    50: '#f2f9f7',
    100: '#e6f3ef',
    200: '#bfe1d8',
    300: '#99cec1',
    400: '#4daa92',
    500: '#008563',
    600: '#007859',
    700: '#00644a',
    800: '#00503b',
    900: '#004131',
  },
  orange: {
    50: '#fcf6f4',
    100: '#f9eee9',
    200: '#f0d4c9',
    300: '#e7baa9',
    400: '#d68668',
    500: '#c45227',
    600: '#b04a23',
    700: '#933e1d',
    800: '#763117',
    900: '#602813',
  },
  red: {
    50: '#fef2f6',
    100: '#fde6ed',
    200: '#fabfd2',
    300: '#f699b7',
    400: '#f04d82',
    500: '#e9004c',
    600: '#d20044',
    700: '#af0039',
    800: '#8c002e',
    900: '#720025',
  },
  grey: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#cbd5e1',
    400: '#94a3b8',
    500: '#64748b',
    600: '#475569',
    700: '#334155',
    800: '#1e293b',
    900: '#0f172a',
  },
});

const theme = createTheme(baseTheme, {
  palette: {
    mode: 'light',
    primary: createThemeColor({ colorSet: blue }),
    error: createThemeColor({ colorSet: red, contrastThreshold: 300 }),
    warning: createThemeColor({ colorSet: orange, contrastThreshold: 700 }),
    success: createThemeColor({ colorSet: green, contrastThreshold: 500 }),
    grey: createThemeColor({ colorSet: grey, contrastThreshold: 500 }),
    background: {
      default: '#e5e5e5',
      paper: colorPalette.white,
      level1: grey[50],
      level2: grey[100],
      contrastColors: {
        default: grey[700],
        paper: colorPalette.black,
        level1: grey[300],
        level2: grey[400],
      },
    },
    text: {
      dark: grey[800],
      primary: grey[600],
      secondary: grey[400],
      placeholder: grey[400],
      disabled: grey[400],
    },
    divider: grey[200],
    action: {
      hover: grey[100],
      disabledOpacity: 1,
      disabledBackground: grey[200],
    },
  },
});

export default theme;
