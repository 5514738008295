import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';
import { optionalUrl, requiredOneOption, requiredString } from '../../utils/validators';
import { SignupTeacherRole } from '../types';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  fullName: requiredString,
  website: optionalUrl,
  role: requiredOneOption({ options: Object.values(SignupTeacherRole) }).messages({
    'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.only': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  }),
});
