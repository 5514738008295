import { useTranslation } from 'react-i18next';

import { ArrowBack as BackIcon, Close as CloseIcon } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

import Drawer from '../../../../components/Drawer';
import Header from '../../../../components/Header';
import { i18nNS } from '../../../../i18n';
import { forMobile } from '../../../../utils/media-queries';
import CourseEndedInfo from '../CourseEndedInfo';
import EditCourseDates from '../EditCourseDates';
import useCourseEndedDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  open: boolean;
}

const CourseEndedDrawer = ({ open, ...vmOptions }: Props) => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.COURSES]);

  const { isEditingCourseDates, editCourseDates, goBack, closeDrawer } = useCourseEndedDrawerVM(vmOptions);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={closeDrawer}
      header={(id: string) =>
        isMobile ? (
          <Header
            id={id}
            left={{
              onClick: isEditingCourseDates ? goBack : closeDrawer,
              children: <BackIcon />,
            }}
          >
            {t('course_ended', { ns: i18nNS.COURSES })}
          </Header>
        ) : (
          <Header
            id={id}
            left={
              isEditingCourseDates
                ? {
                    edge: 'start',
                    children: <BackIcon />,
                    onClick: isEditingCourseDates ? goBack : closeDrawer,
                  }
                : undefined
            }
            right={{
              edge: 'end',
              children: <CloseIcon />,
              onClick: closeDrawer,
            }}
          >
            {t('course_ended', { ns: i18nNS.COURSES })}
          </Header>
        )
      }
    >
      {isEditingCourseDates ? (
        <EditCourseDates onClose={closeDrawer} />
      ) : (
        <CourseEndedInfo onEditCourseDates={editCourseDates} />
      )}
    </Drawer>
  );
};

export default CourseEndedDrawer;
