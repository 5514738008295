import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import {
  AddCourseBookPayload,
  AddCourseBookSuccessPayload,
  CourseBook,
  GetCourseBooksSuccessPayload,
} from './types';

export const fetchCourseBooks = createRequestAction<API.GetCourseBooksRequest, GetCourseBooksSuccessPayload>(
  'books/fetch'
);

export const addCourseBook = createRequestAction<AddCourseBookPayload, AddCourseBookSuccessPayload>(
  'books/add'
);

export const editCourseBook = createRequestAction<CourseBook, CourseBook>('books/edit');

export const removeCourseBook = createRequestAction<API.RemoveCourseBookRequest, API.RemoveCourseBookRequest>(
  '/books/remove'
);
