import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const RadioUncheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        d="M19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default RadioUncheckedIcon;
