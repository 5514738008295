import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const KeypadIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M5 2C4.40666 2 3.82664 2.17595 3.33329 2.50559C2.83994 2.83523 2.45543 3.30377 2.22836 3.85195C2.0013 4.40013 1.94189 5.00333 2.05765 5.58527C2.1734 6.16721 2.45912 6.70176 2.87868 7.12132C3.29824 7.54088 3.83279 7.8266 4.41473 7.94236C4.99667 8.05811 5.59987 7.9987 6.14805 7.77164C6.69623 7.54458 7.16477 7.16006 7.49441 6.66671C7.82405 6.17336 8 5.59334 8 5C8 4.20435 7.68393 3.44129 7.12132 2.87868C6.55871 2.31607 5.79565 2 5 2Z"
          fill="currentColor"
        />
        <path
          d="M12 2C11.4067 2 10.8266 2.17595 10.3333 2.50559C9.83994 2.83523 9.45542 3.30377 9.22836 3.85195C9.0013 4.40013 8.94189 5.00333 9.05764 5.58527C9.1734 6.16721 9.45912 6.70176 9.87868 7.12132C10.2982 7.54088 10.8328 7.8266 11.4147 7.94236C11.9967 8.05811 12.5999 7.9987 13.1481 7.77164C13.6962 7.54458 14.1648 7.16006 14.4944 6.66671C14.8241 6.17336 15 5.59334 15 5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7957 2 12 2Z"
          fill="currentColor"
        />
        <path
          d="M19 8C19.5933 8 20.1734 7.82405 20.6667 7.49441C21.1601 7.16476 21.5446 6.69623 21.7716 6.14805C21.9987 5.59987 22.0581 4.99667 21.9424 4.41473C21.8266 3.83279 21.5409 3.29824 21.1213 2.87868C20.7018 2.45912 20.1672 2.1734 19.5853 2.05764C19.0033 1.94189 18.4001 2.0013 17.852 2.22836C17.3038 2.45542 16.8352 2.83994 16.5056 3.33329C16.1759 3.82664 16 4.40666 16 5C16 5.79565 16.3161 6.55871 16.8787 7.12132C17.4413 7.68393 18.2044 8 19 8Z"
          fill="currentColor"
        />
        <path
          d="M5 9C4.40666 9 3.82664 9.17595 3.33329 9.50559C2.83994 9.83524 2.45543 10.3038 2.22836 10.8519C2.0013 11.4001 1.94189 12.0033 2.05765 12.5853C2.1734 13.1672 2.45912 13.7018 2.87868 14.1213C3.29824 14.5409 3.83279 14.8266 4.41473 14.9424C4.99667 15.0581 5.59987 14.9987 6.14805 14.7716C6.69623 14.5446 7.16477 14.1601 7.49441 13.6667C7.82405 13.1734 8 12.5933 8 12C8 11.2043 7.68393 10.4413 7.12132 9.87868C6.55871 9.31607 5.79565 9 5 9Z"
          fill="currentColor"
        />
        <path
          d="M12 9C11.4067 9 10.8266 9.17595 10.3333 9.50559C9.83994 9.83524 9.45542 10.3038 9.22836 10.8519C9.0013 11.4001 8.94189 12.0033 9.05764 12.5853C9.1734 13.1672 9.45912 13.7018 9.87868 14.1213C10.2982 14.5409 10.8328 14.8266 11.4147 14.9424C11.9967 15.0581 12.5999 14.9987 13.1481 14.7716C13.6962 14.5446 14.1648 14.1601 14.4944 13.6667C14.8241 13.1734 15 12.5933 15 12C15 11.2043 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7957 9 12 9Z"
          fill="currentColor"
        />
        <path
          d="M19 9C18.4067 9 17.8266 9.17595 17.3333 9.50559C16.8399 9.83524 16.4554 10.3038 16.2284 10.8519C16.0013 11.4001 15.9419 12.0033 16.0576 12.5853C16.1734 13.1672 16.4591 13.7018 16.8787 14.1213C17.2982 14.5409 17.8328 14.8266 18.4147 14.9424C18.9967 15.0581 19.5999 14.9987 20.1481 14.7716C20.6962 14.5446 21.1648 14.1601 21.4944 13.6667C21.8241 13.1734 22 12.5933 22 12C22 11.2043 21.6839 10.4413 21.1213 9.87868C20.5587 9.31607 19.7957 9 19 9Z"
          fill="currentColor"
        />
        <path
          d="M5 16C4.40666 16 3.82664 16.1759 3.33329 16.5056C2.83994 16.8352 2.45543 17.3038 2.22836 17.8519C2.0013 18.4001 1.94189 19.0033 2.05765 19.5853C2.1734 20.1672 2.45912 20.7018 2.87868 21.1213C3.29824 21.5409 3.83279 21.8266 4.41473 21.9424C4.99667 22.0581 5.59987 21.9987 6.14805 21.7716C6.69623 21.5446 7.16477 21.1601 7.49441 20.6667C7.82405 20.1734 8 19.5933 8 19C8 18.2044 7.68393 17.4413 7.12132 16.8787C6.55871 16.3161 5.79565 16 5 16Z"
          fill="currentColor"
        />
        <path
          d="M12 16C11.4067 16 10.8266 16.1759 10.3333 16.5056C9.83994 16.8352 9.45542 17.3038 9.22836 17.8519C9.0013 18.4001 8.94189 19.0033 9.05764 19.5853C9.1734 20.1672 9.45912 20.7018 9.87868 21.1213C10.2982 21.5409 10.8328 21.8266 11.4147 21.9424C11.9967 22.0581 12.5999 21.9987 13.1481 21.7716C13.6962 21.5446 14.1648 21.1601 14.4944 20.6667C14.8241 20.1734 15 19.5933 15 19C15 18.2044 14.6839 17.4413 14.1213 16.8787C13.5587 16.3161 12.7957 16 12 16Z"
          fill="currentColor"
        />
        <path
          d="M19 16C18.4067 16 17.8266 16.1759 17.3333 16.5056C16.8399 16.8352 16.4554 17.3038 16.2284 17.8519C16.0013 18.4001 15.9419 19.0033 16.0576 19.5853C16.1734 20.1672 16.4591 20.7018 16.8787 21.1213C17.2982 21.5409 17.8328 21.8266 18.4147 21.9424C18.9967 22.0581 19.5999 21.9987 20.1481 21.7716C20.6962 21.5446 21.1648 21.1601 21.4944 20.6667C21.8241 20.1734 22 19.5933 22 19C22 18.2044 21.6839 17.4413 21.1213 16.8787C20.5587 16.3161 19.7957 16 19 16Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 2C4.40666 2 3.82664 2.17595 3.33329 2.50559C2.83994 2.83523 2.45543 3.30377 2.22836 3.85195C2.0013 4.40013 1.94189 5.00333 2.05765 5.58527C2.1734 6.16721 2.45912 6.70176 2.87868 7.12132C3.29824 7.54088 3.83279 7.8266 4.41473 7.94236C4.99667 8.05811 5.59987 7.9987 6.14805 7.77164C6.69623 7.54458 7.16477 7.16006 7.49441 6.66671C7.82405 6.17336 8 5.59334 8 5C8 4.20435 7.68393 3.44129 7.12132 2.87868C6.55871 2.31607 5.79565 2 5 2ZM5 6C4.80222 6 4.60888 5.94135 4.44443 5.83147C4.27998 5.72159 4.15181 5.56541 4.07612 5.38268C4.00043 5.19996 3.98063 4.99889 4.01922 4.80491C4.0578 4.61093 4.15304 4.43275 4.29289 4.29289C4.43275 4.15304 4.61093 4.0578 4.80491 4.01921C4.99889 3.98063 5.19996 4.00043 5.38268 4.07612C5.56541 4.15181 5.72159 4.27998 5.83147 4.44443C5.94135 4.60888 6 4.80222 6 5C6 5.26522 5.89464 5.51957 5.70711 5.70711C5.51957 5.89464 5.26522 6 5 6Z"
        fill="currentColor"
      />
      <path
        d="M12 2C11.4067 2 10.8266 2.17595 10.3333 2.50559C9.83994 2.83523 9.45542 3.30377 9.22836 3.85195C9.0013 4.40013 8.94189 5.00333 9.05764 5.58527C9.1734 6.16721 9.45912 6.70176 9.87868 7.12132C10.2982 7.54088 10.8328 7.8266 11.4147 7.94236C11.9967 8.05811 12.5999 7.9987 13.1481 7.77164C13.6962 7.54458 14.1648 7.16006 14.4944 6.66671C14.8241 6.17336 15 5.59334 15 5C15 4.20435 14.6839 3.44129 14.1213 2.87868C13.5587 2.31607 12.7957 2 12 2ZM12 6C11.8022 6 11.6089 5.94135 11.4444 5.83147C11.28 5.72159 11.1518 5.56541 11.0761 5.38268C11.0004 5.19996 10.9806 4.99889 11.0192 4.80491C11.0578 4.61093 11.153 4.43275 11.2929 4.29289C11.4327 4.15304 11.6109 4.0578 11.8049 4.01921C11.9989 3.98063 12.2 4.00043 12.3827 4.07612C12.5654 4.15181 12.7216 4.27998 12.8315 4.44443C12.9414 4.60888 13 4.80222 13 5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89464 12.2652 6 12 6Z"
        fill="currentColor"
      />
      <path
        d="M19 8C19.5933 8 20.1734 7.82405 20.6667 7.49441C21.1601 7.16476 21.5446 6.69623 21.7716 6.14805C21.9987 5.59987 22.0581 4.99667 21.9424 4.41473C21.8266 3.83279 21.5409 3.29824 21.1213 2.87868C20.7018 2.45912 20.1672 2.1734 19.5853 2.05764C19.0033 1.94189 18.4001 2.0013 17.852 2.22836C17.3038 2.45542 16.8352 2.83994 16.5056 3.33329C16.1759 3.82664 16 4.40666 16 5C16 5.79565 16.3161 6.55871 16.8787 7.12132C17.4413 7.68393 18.2044 8 19 8ZM19 4C19.1978 4 19.3911 4.05865 19.5556 4.16853C19.72 4.27841 19.8482 4.43459 19.9239 4.61732C19.9996 4.80004 20.0194 5.00111 19.9808 5.19509C19.9422 5.38907 19.847 5.56725 19.7071 5.70711C19.5673 5.84696 19.3891 5.9422 19.1951 5.98078C19.0011 6.01937 18.8 5.99957 18.6173 5.92388C18.4346 5.84819 18.2784 5.72002 18.1685 5.55557C18.0587 5.39112 18 5.19778 18 5C18 4.73478 18.1054 4.48043 18.2929 4.29289C18.4804 4.10536 18.7348 4 19 4Z"
        fill="currentColor"
      />
      <path
        d="M5 9C4.40666 9 3.82664 9.17595 3.33329 9.50559C2.83994 9.83524 2.45543 10.3038 2.22836 10.8519C2.0013 11.4001 1.94189 12.0033 2.05765 12.5853C2.1734 13.1672 2.45912 13.7018 2.87868 14.1213C3.29824 14.5409 3.83279 14.8266 4.41473 14.9424C4.99667 15.0581 5.59987 14.9987 6.14805 14.7716C6.69623 14.5446 7.16477 14.1601 7.49441 13.6667C7.82405 13.1734 8 12.5933 8 12C8 11.2043 7.68393 10.4413 7.12132 9.87868C6.55871 9.31607 5.79565 9 5 9ZM5 13C4.80222 13 4.60888 12.9413 4.44443 12.8315C4.27998 12.7216 4.15181 12.5654 4.07612 12.3827C4.00043 12.2 3.98063 11.9989 4.01922 11.8049C4.0578 11.6109 4.15304 11.4327 4.29289 11.2929C4.43275 11.153 4.61093 11.0578 4.80491 11.0192C4.99889 10.9806 5.19996 11.0004 5.38268 11.0761C5.56541 11.1518 5.72159 11.28 5.83147 11.4444C5.94135 11.6089 6 11.8022 6 12C6 12.2652 5.89464 12.5196 5.70711 12.7071C5.51957 12.8946 5.26522 13 5 13Z"
        fill="currentColor"
      />
      <path
        d="M12 9C11.4067 9 10.8266 9.17595 10.3333 9.50559C9.83994 9.83524 9.45542 10.3038 9.22836 10.8519C9.0013 11.4001 8.94189 12.0033 9.05764 12.5853C9.1734 13.1672 9.45912 13.7018 9.87868 14.1213C10.2982 14.5409 10.8328 14.8266 11.4147 14.9424C11.9967 15.0581 12.5999 14.9987 13.1481 14.7716C13.6962 14.5446 14.1648 14.1601 14.4944 13.6667C14.8241 13.1734 15 12.5933 15 12C15 11.2043 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7957 9 12 9ZM12 13C11.8022 13 11.6089 12.9413 11.4444 12.8315C11.28 12.7216 11.1518 12.5654 11.0761 12.3827C11.0004 12.2 10.9806 11.9989 11.0192 11.8049C11.0578 11.6109 11.153 11.4327 11.2929 11.2929C11.4327 11.153 11.6109 11.0578 11.8049 11.0192C11.9989 10.9806 12.2 11.0004 12.3827 11.0761C12.5654 11.1518 12.7216 11.28 12.8315 11.4444C12.9414 11.6089 13 11.8022 13 12C13 12.2652 12.8946 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13Z"
        fill="currentColor"
      />
      <path
        d="M19 9C18.4067 9 17.8266 9.17595 17.3333 9.50559C16.8399 9.83524 16.4554 10.3038 16.2284 10.8519C16.0013 11.4001 15.9419 12.0033 16.0576 12.5853C16.1734 13.1672 16.4591 13.7018 16.8787 14.1213C17.2982 14.5409 17.8328 14.8266 18.4147 14.9424C18.9967 15.0581 19.5999 14.9987 20.1481 14.7716C20.6962 14.5446 21.1648 14.1601 21.4944 13.6667C21.8241 13.1734 22 12.5933 22 12C22 11.2043 21.6839 10.4413 21.1213 9.87868C20.5587 9.31607 19.7957 9 19 9ZM19 13C18.8022 13 18.6089 12.9413 18.4444 12.8315C18.28 12.7216 18.1518 12.5654 18.0761 12.3827C18.0004 12.2 17.9806 11.9989 18.0192 11.8049C18.0578 11.6109 18.153 11.4327 18.2929 11.2929C18.4327 11.153 18.6109 11.0578 18.8049 11.0192C18.9989 10.9806 19.2 11.0004 19.3827 11.0761C19.5654 11.1518 19.7216 11.28 19.8315 11.4444C19.9414 11.6089 20 11.8022 20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071C19.5196 12.8946 19.2652 13 19 13Z"
        fill="currentColor"
      />
      <path
        d="M5 16C4.40666 16 3.82664 16.1759 3.33329 16.5056C2.83994 16.8352 2.45543 17.3038 2.22836 17.8519C2.0013 18.4001 1.94189 19.0033 2.05765 19.5853C2.1734 20.1672 2.45912 20.7018 2.87868 21.1213C3.29824 21.5409 3.83279 21.8266 4.41473 21.9424C4.99667 22.0581 5.59987 21.9987 6.14805 21.7716C6.69623 21.5446 7.16477 21.1601 7.49441 20.6667C7.82405 20.1734 8 19.5933 8 19C8 18.2044 7.68393 17.4413 7.12132 16.8787C6.55871 16.3161 5.79565 16 5 16ZM5 20C4.80222 20 4.60888 19.9414 4.44443 19.8315C4.27998 19.7216 4.15181 19.5654 4.07612 19.3827C4.00043 19.2 3.98063 18.9989 4.01922 18.8049C4.0578 18.6109 4.15304 18.4327 4.29289 18.2929C4.43275 18.153 4.61093 18.0578 4.80491 18.0192C4.99889 17.9806 5.19996 18.0004 5.38268 18.0761C5.56541 18.1518 5.72159 18.28 5.83147 18.4444C5.94135 18.6089 6 18.8022 6 19C6 19.2652 5.89464 19.5196 5.70711 19.7071C5.51957 19.8946 5.26522 20 5 20Z"
        fill="currentColor"
      />
      <path
        d="M12 16C11.4067 16 10.8266 16.1759 10.3333 16.5056C9.83994 16.8352 9.45542 17.3038 9.22836 17.8519C9.0013 18.4001 8.94189 19.0033 9.05764 19.5853C9.1734 20.1672 9.45912 20.7018 9.87868 21.1213C10.2982 21.5409 10.8328 21.8266 11.4147 21.9424C11.9967 22.0581 12.5999 21.9987 13.1481 21.7716C13.6962 21.5446 14.1648 21.1601 14.4944 20.6667C14.8241 20.1734 15 19.5933 15 19C15 18.2044 14.6839 17.4413 14.1213 16.8787C13.5587 16.3161 12.7957 16 12 16ZM12 20C11.8022 20 11.6089 19.9414 11.4444 19.8315C11.28 19.7216 11.1518 19.5654 11.0761 19.3827C11.0004 19.2 10.9806 18.9989 11.0192 18.8049C11.0578 18.6109 11.153 18.4327 11.2929 18.2929C11.4327 18.153 11.6109 18.0578 11.8049 18.0192C11.9989 17.9806 12.2 18.0004 12.3827 18.0761C12.5654 18.1518 12.7216 18.28 12.8315 18.4444C12.9414 18.6089 13 18.8022 13 19C13 19.2652 12.8946 19.5196 12.7071 19.7071C12.5196 19.8946 12.2652 20 12 20Z"
        fill="currentColor"
      />
      <path
        d="M19 16C18.4067 16 17.8266 16.1759 17.3333 16.5056C16.8399 16.8352 16.4554 17.3038 16.2284 17.8519C16.0013 18.4001 15.9419 19.0033 16.0576 19.5853C16.1734 20.1672 16.4591 20.7018 16.8787 21.1213C17.2982 21.5409 17.8328 21.8266 18.4147 21.9424C18.9967 22.0581 19.5999 21.9987 20.1481 21.7716C20.6962 21.5446 21.1648 21.1601 21.4944 20.6667C21.8241 20.1734 22 19.5933 22 19C22 18.2044 21.6839 17.4413 21.1213 16.8787C20.5587 16.3161 19.7957 16 19 16ZM19 20C18.8022 20 18.6089 19.9414 18.4444 19.8315C18.28 19.7216 18.1518 19.5654 18.0761 19.3827C18.0004 19.2 17.9806 18.9989 18.0192 18.8049C18.0578 18.6109 18.153 18.4327 18.2929 18.2929C18.4327 18.153 18.6109 18.0578 18.8049 18.0192C18.9989 17.9806 19.2 18.0004 19.3827 18.0761C19.5654 18.1518 19.7216 18.28 19.8315 18.4444C19.9414 18.6089 20 18.8022 20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default KeypadIcon;
