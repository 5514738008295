import { Descendant, Editor, Element as SlateElement, Node as SlateNode, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import Logger from '../../../utils/logger';
import {
  FormulaDisplay,
  FormulaEditor,
  FormulaEditorOptions,
  FormulaElement,
  FormulaFormat,
  FormulaProps,
  FormulaUploadStatus,
} from './types';

const logger = Logger.create('richtext-editor');

export function wrapWithMathJaxDelimiters({
  code,
  display,
  format,
}: {
  code: string;
  display: FormulaDisplay;
  format: FormulaFormat;
}) {
  switch (format) {
    case FormulaFormat.TEX: {
      if (display === FormulaDisplay.INLINE) return `\\(${code}\\)`;
      return `\\[${code}\\]`;
    }
    case FormulaFormat.ASCII_MATH: {
      if (display === 'block') {
        return `<span style="width: 100%; display: flex; justify-content: center">\`${code}\`</span>`;
      }
      return `\`${code}\``;
    }
    case FormulaFormat.MATH_ML: {
      const element = document.createElement('div');
      element.innerHTML = code;
      element.children[0]?.setAttribute('display', display);
      return element.innerHTML;
    }
    default: {
      logger.error(`Unhandled formula format attribute: ${format}`);
      return code;
    }
  }
}

export function insertFormula(editor: Editor, props: FormulaProps) {
  const element: FormulaElement = {
    ...props,
    type: 'formula',
    children: [{ text: '' }],
  };

  Transforms.insertNodes(editor, element);

  // code for adjusting cursor

  if (!editor.selection || !Range.isCollapsed(editor.selection)) return;

  let [, path] = Editor.node(editor, editor.selection);

  // when block level formula is added at the end, there is no element after that, so need to add 1 more element to move focus to new line
  if (props.display === 'block' && path[0] === editor.children.length - 1) {
    Transforms.insertNodes(
      editor,
      { type: 'paragraph', children: [{ type: 'text', text: '' }] },
      { at: [editor.children.length], select: true }
    );
    return;
  }

  if (props.display === 'inline' && path[0] === editor.children.length - 1) {
    Transforms.insertNodes(
      editor,
      { type: 'text', text: '' },
      { at: [editor.children.length], select: true }
    );
    return;
  }

  // move cursor to next character of formula
  Transforms.move(editor, { distance: 1 });
}

export async function uploadAndUpdateFormulaNode(editor: Editor, element: FormulaElement) {
  try {
    Transforms.setNodes(
      editor,
      { uploadStatus: FormulaUploadStatus.UPLOADING },
      { at: ReactEditor.findPath(editor, element) }
    );

    const { id, ach, acw, url } = await editor.formula.upload({
      display: element.display,
      format: element.format,
      math: element.code,
    });

    Transforms.setNodes(
      editor,
      { id, ach, acw, url, uploadStatus: FormulaUploadStatus.UPLOADED },
      { at: ReactEditor.findPath(editor, element) }
    );
  } catch (error) {
    logger.error(error);
  }
}

export function isFormulaElement(node: SlateNode): node is FormulaElement {
  return SlateElement.isElement(node) && node.type === 'formula';
}

function hasFormula(nodes: Descendant[] | [{ text: '' }]): boolean {
  for (const node of nodes) {
    if (!SlateElement.isElement(node)) continue;
    if (isFormulaElement(node)) return true;
    if (hasFormula(node.children)) return true;
  }
  return false;
}

export default function withFormulae({ uploadFormula }: FormulaEditorOptions) {
  return <T extends Editor>(editor: T): T & FormulaEditor => {
    (editor as T & FormulaEditor).formula = { upload: uploadFormula };
    const { isInline, isVoid } = editor;

    editor.isVoid = (element) => {
      return element.type === 'formula' ? true : isVoid(element);
    };

    editor.isInline = (element) => {
      return element.type === 'formula' ? element.display === 'inline' : isInline(element);
    };

    editor.hasFormula = () => hasFormula(editor.children);

    return editor as T & FormulaEditor;
  };
}
