import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Link as MuiLink, Skeleton } from '@mui/material';

import { BreadcrumbItem as BreadcrumbItemType } from '../../app/BreadcrumbProvider';
import { i18nNS } from '../../i18n';

interface Props {
  id?: string;
  item: BreadcrumbItemType | null;
}

const BreadcrumbItem = ({ id, item, ...props }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  if (!item?.path) {
    return <Skeleton variant="text" width={60} />;
  }

  const title = item?.status
    ? t('activity_bracket_status', {
        ns: i18nNS.COMMON,
        activity: item.content,
        status: item.status,
      })
    : item?.content;

  return (
    <MuiLink
      id={id}
      component={Link}
      to={item.path}
      aria-label={t('click_to_visit_page', { ns: i18nNS.COMMON, page: title })}
      {...props}
    >
      {title}
    </MuiLink>
  );
};

export default BreadcrumbItem;
