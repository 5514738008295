import { createContext } from 'react';

export interface FabMenuContextType {
  /** closes whole fab menu and reset currentMenu to root level */
  close: () => any;
  currentMenu: string;
  goToMenu: (menuId: string) => any;
  isOpen: boolean;
  open: () => any;
}

const FabMenuContext = createContext<FabMenuContextType>({
  close: () => {},
  currentMenu: '',
  goToMenu: () => {},
  isOpen: false,
  open: () => {},
});

export default FabMenuContext;
