import { ElementType, forwardRef, Ref } from 'react';

import clsx from 'clsx';

import SpinnerIcon from '../../icons/LoaderIcon';
import { buttonClasses, ButtonRoot, Props } from './styles';
import useButtonVM from './vm';

export type { Props };

const Button = <C extends ElementType<any> = 'button'>(
  {
    variant = 'contained',
    size = 'medium',
    color = 'primary',
    edge,
    startIcon,
    endIcon,
    disabled,
    children,
    className,
    classes,
    hideContentWhenLoading,
    ...props
  }: Props<C>,
  ref: Ref<HTMLButtonElement>
) => {
  const { isLoading, handleClick } = useButtonVM(props);

  return (
    <ButtonRoot
      {...props}
      ref={ref}
      color={color}
      startIcon={hideContentWhenLoading && isLoading ? null : startIcon}
      endIcon={
        isLoading ? (
          <SpinnerIcon className={classes?.spinner} classes={{ root: classes?.spinner }} />
        ) : (
          endIcon
        )
      }
      disabled={disabled || isLoading}
      onClick={handleClick}
      className={clsx(className, classes?.root, buttonClasses.root, {
        [buttonClasses.contained]: variant === 'contained',
        [buttonClasses.outlined]: variant === 'outlined',
        [buttonClasses.text]: variant === 'text',
        [buttonClasses.colorPrimary]: color === 'primary',
        [buttonClasses.colorSuccess]: color === 'success',
        [buttonClasses.colorWarning]: color === 'warning',
        [buttonClasses.colorError]: color === 'error',
        [buttonClasses.sizeXLarge]: size === 'xLarge',
        [buttonClasses.sizeLarge]: size === 'large',
        [buttonClasses.sizeMedium]: size === 'medium',
        [buttonClasses.sizeSmall]: size === 'small',
        [buttonClasses.sizeXSmall]: size === 'xSmall',
        [buttonClasses.edgeStart]: edge === 'start',
        [buttonClasses.edgeEnd]: edge === 'end',
        [buttonClasses.disabled]: disabled || isLoading,
        [buttonClasses.loading]: isLoading,
      })}
      classes={{
        ...classes,
        startIcon: clsx(buttonClasses.startIcon, classes?.startIcon),
        endIcon: clsx(buttonClasses.endIcon, classes?.endIcon),
      }}
    >
      {hideContentWhenLoading && isLoading ? null : children}
    </ButtonRoot>
  );
};

export default forwardRef(Button) as <C extends ElementType<any> = 'button'>(
  props: Props<C> & { ref?: Ref<HTMLButtonElement> }
) => JSX.Element;
