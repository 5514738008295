import {
  LinearProgress as MuiLinearProgress,
  linearProgressClasses as MuiLinearProgressClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateClasses } from '../../../utils/helpers';
import { CommonProps } from '../types';

export type Props = CommonProps & {
  /** @default 'linear' */
  shape?: 'linear';
  /** @default 'thin' */
  size?: 'thin' | 'medium' | 'thick';
};

export const linearProgressClasses = {
  ...MuiLinearProgressClasses,
  ...generateClasses('Progressbar', [
    'sizeThin',
    'sizeMedium',
    'sizeThick',
    'colorSuccess',
    'colorError',
    'colorWarning',
  ]),
};

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],

  [`&.${linearProgressClasses.sizeThin}`]: {
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(0.75),
  },
  [`&.${linearProgressClasses.sizeMedium}`]: {
    borderRadius: theme.spacing(0.75),
    height: theme.spacing(1.5),
  },
  [`&.${linearProgressClasses.sizeThick}`]: {
    borderRadius: theme.spacing(1),
    height: theme.spacing(2),
  },

  [`&.${linearProgressClasses.colorPrimary}`]: {
    color: theme.palette.primary[500],
  },
  [`&.${linearProgressClasses.colorSuccess}`]: {
    color: theme.palette.success[500],
  },
  [`&.${linearProgressClasses.colorError}`]: {
    color: theme.palette.error[500],
  },
  [`&.${linearProgressClasses.colorWarning}`]: {
    color: theme.palette.warning[500],
  },
}));
