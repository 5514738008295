import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const BookOpenIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21 4.34C20.85 4.22084 20.6744 4.13806 20.487 4.09815C20.2996 4.05825 20.1055 4.0623 19.92 4.11L13 5.89V20.16L20.56 18.22C20.8304 18.1508 21.0699 17.9931 21.2402 17.772C21.4106 17.5509 21.502 17.2791 21.5 17V5.32C21.4969 5.12933 21.4503 4.9419 21.3636 4.77204C21.2769 4.60217 21.1526 4.45439 21 4.34Z"
          fill="currentColor"
        />
        <path
          d="M11 5.89L4.06 4.11C3.87729 4.0677 3.68748 4.06638 3.50421 4.10615C3.32093 4.14591 3.14874 4.22578 3 4.34C2.84843 4.4585 2.72626 4.61039 2.643 4.78384C2.55975 4.95729 2.51765 5.14762 2.52 5.34V17C2.52021 17.2774 2.61269 17.5469 2.78287 17.7659C2.95305 17.985 3.19126 18.1412 3.46 18.21L11 20.16V5.89Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.62 4.22C20.5038 4.12602 20.3681 4.05928 20.2227 4.02466C20.0773 3.99005 19.9261 3.98846 19.78 4.02L12 5.77L4.22 4C4.07162 3.96649 3.91756 3.96727 3.76953 4.00228C3.6215 4.03729 3.48341 4.10561 3.36577 4.20204C3.24812 4.29847 3.15403 4.42047 3.09065 4.55874C3.02726 4.69702 2.99626 4.84794 3 5V17.2C2.99435 17.4312 3.06898 17.6571 3.2112 17.8395C3.35341 18.0218 3.55442 18.1492 3.78 18.2L11.78 20H12.22L20.22 18.2C20.4456 18.1492 20.6466 18.0218 20.7888 17.8395C20.931 17.6571 21.0056 17.4312 21 17.2V5C20.9993 4.8498 20.9648 4.70168 20.899 4.56664C20.8332 4.43161 20.7379 4.31313 20.62 4.22ZM5 6.25L11 7.6V17.75L5 16.4V6.25ZM19 16.4L13 17.75V7.6L19 6.25V16.4Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default BookOpenIcon;
