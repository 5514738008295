import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { PaymentStep } from './types';

type CurrentPaymentStepData =
  | { name: PaymentStep.COUPON_CODE | PaymentStep.PAY_VIA_CARD | PaymentStep.PAYMENT_SUCCESS }
  | { name: PaymentStep.PAYMENT_FAILED; error: string };

interface PaymentWallContextType {
  alertMessage: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  isChoosePaymentMethodDialogOpen: boolean;
  setIsChoosePaymentMethodDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentPaymentStep: CurrentPaymentStepData;
  setCurrentPaymentStep: (data: CurrentPaymentStepData) => void;
  isCoursePaymentDrawerOpen: boolean;
  setIsCoursePaymentDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PaymentWallContext = createContext<PaymentWallContextType | undefined>(undefined);

export const usePaymentWallContext = () => {
  const context = useContext(PaymentWallContext);
  if (!context) {
    throw new Error('using usePaymentWallContext outside PaymentWallContext not allowed');
  }
  return context;
};

const usePaymentWallContextProviderVM = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [isChoosePaymentMethodDialogOpen, setIsChoosePaymentMethodDialogOpen] = useState(false);
  const [currentPaymentStep, setCurrentPaymentStep] = useState<CurrentPaymentStepData>({
    name: PaymentStep.COUPON_CODE,
  });
  const [isCoursePaymentDrawerOpen, setIsCoursePaymentDrawerOpen] = useState(false);

  return useMemo(
    () => ({
      alertMessage,
      setAlertMessage,
      isChoosePaymentMethodDialogOpen,
      setIsChoosePaymentMethodDialogOpen,
      currentPaymentStep,
      setCurrentPaymentStep,
      isCoursePaymentDrawerOpen,
      setIsCoursePaymentDrawerOpen,
    }),
    [alertMessage, isChoosePaymentMethodDialogOpen, currentPaymentStep, isCoursePaymentDrawerOpen]
  );
};

interface Props {
  children: ReactNode;
}

const PaymentWallContextProvider = ({ children }: Props) => {
  const providerValue = usePaymentWallContextProviderVM();

  return <PaymentWallContext.Provider value={providerValue}>{children}</PaymentWallContext.Provider>;
};

export default PaymentWallContextProvider;
