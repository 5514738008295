import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  styled,
  Typography,
} from '@mui/material';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: 4,
  backgroundColor: theme.palette.background.paper,
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ChevronRightIcon fontSize="medium" />} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(2.5, 3),
  outlineOffset: theme.spacing(0.5),
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
  },
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    transform: 'rotate(90deg)',
  },
  [`& .${accordionSummaryClasses.content}`]: {
    margin: 0,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  borderTop: `1px solid ${theme.palette.background.level2}`,
}));

export const Question = styled('button')(({ theme }) => ({
  ...theme.typography.paragraphRegular,
  cursor: 'pointer',
  border: 'none',
  backgroundColor: 'transparent',
  textAlign: 'left',
  color: theme.palette.primary[800],
}));
