import { Trans, useTranslation } from 'react-i18next';

import { Link, Stack, useMediaQuery } from '@mui/material';

import Button from '../../components/Button';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import { ACADLY_HERE_LOGO_URL, ACADLY_HERE_URL } from '../constants';
import Layout from '../Layout';
import { AcadlyHere, AcadlyHereLogo } from './styles';
import useThankYouVM from './vm';

const ThankYou = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.COMMON]);

  const { handleBack } = useThankYouVM();

  return (
    <Layout
      title={t('thank_you', { ns: i18nNS.COMMON })}
      subtitle={t('thank_you_for_choosing_acadly', { ns: i18nNS.AUTH })}
    >
      <Stack gap={6}>
        <Stack gap={4}>
          <Typography variant="paragraphRegular">
            {t(
              'we_will_manually_verify_if_you_teach_at_a_higher_education_institution_upon_successful_verification_your_free_account_will_be_activated_and_you_will_be_notified_via_email_within_the_next_24_hours',
              { ns: i18nNS.AUTH }
            )}
          </Typography>
          <Typography variant="paragraphRegular">
            {t(
              'if_you_work_at_a_company_or_non_profit_we_will_get_in_touch_with_you_with_details_on_how_you_can_get_started_with_acadly',
              { ns: i18nNS.AUTH }
            )}
          </Typography>
          <AcadlyHere href={ACADLY_HERE_URL} target="_blank" rel="noreferrer">
            <AcadlyHereLogo src={ACADLY_HERE_LOGO_URL} alt="" />
            <Typography variant="paragraphRegular">
              {t('click_to_learn_about_acadly_here_and_automatically_record_attendance_anywhere', {
                ns: i18nNS.AUTH,
              })}
            </Typography>
          </AcadlyHere>
          <Typography variant="paragraphRegular">
            <Trans t={t} i18nKey="if_you_have_any_questions_please_feel_free_to_write_to_us_at_email_thanks">
              If you have any questions, please feel free to write to us at{' '}
              <Link href="mailto:support@acadly.com" target="_blank" rel="noreferrer">
                support@acadly.com.
              </Link>{' '}
              Thanks!
            </Trans>
          </Typography>
        </Stack>
        <footer>
          <Button
            variant="outlined"
            size={isMobile ? 'large' : 'xLarge'}
            fullWidth={isMobile}
            onClick={handleBack}
            aria-label={t('click_to_go_back_to_login_page', { ns: i18nNS.AUTH })}
          >
            {t('back_to_login', { ns: i18nNS.AUTH })}
          </Button>
        </footer>
      </Stack>
    </Layout>
  );
};

export default ThankYou;
