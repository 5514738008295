import Joi from 'joi';

import { optionalString, requiredString } from '../utils/validators';

export const validationSchema = Joi.object({
  referrals: Joi.array()
    .ordered(
      // For validating first input field only
      Joi.object({
        text: requiredString,
      })
    )
    .items(
      Joi.object({
        text: optionalString,
      })
    ),
});
