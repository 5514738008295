import { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { teacherSignup } from '../store/actions';
import { AuthScreen, NextScreen } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export enum VALIDATION_TYPES {
  EMAIL_ALREADY_EXISTS = 'emailAlreadyExists',
}

const useCreateTrialAccountVM = () => {
  const requestDispatch = useRequestDispatch();

  const { email, marketing, universityName, setUniversityName, setScreen } = useContext(AuthContext);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      fullName: '',
      website: '',
      role: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { fullName, website, role } = data;

    if (!role) return;

    const { next } = await requestDispatch(teacherSignup, {
      emailId: email,
      marketing,
      universityExists: true,
      name: fullName,
      website,
      role,
    });

    switch (next) {
      case NextScreen.THANK_YOU:
        setScreen(AuthScreen.THANK_YOU);
        break;
      case NextScreen.VERIFICATION_AWAITED:
        setScreen(AuthScreen.VERIFICATION_AWAITED);
        break;
      case NextScreen.PASSWORD_NOT_SET:
        setScreen(AuthScreen.VERIFY_EMAIL);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setScreen(AuthScreen.DEFAULT);
  };

  useEffect(
    function onUnmount() {
      return () => {
        setUniversityName('');
      };
    },
    [setUniversityName]
  );

  return {
    formMethods,
    onSubmit,
    universityName,
    handleBack,
  };
};

export default useCreateTrialAccountVM;
