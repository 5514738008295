import { usePaymentWallContext } from '../Context';
import { PaymentStep } from '../types';

const useChoosePaymentMethodDialog = () => {
  const { alertMessage, setCurrentPaymentStep, setIsCoursePaymentDrawerOpen } = usePaymentWallContext();

  const handleClickRedeemCoupon = () => {
    setCurrentPaymentStep({ name: PaymentStep.COUPON_CODE });
    setIsCoursePaymentDrawerOpen(true);
  };

  const handleClickPayUsingCard = () => {
    setCurrentPaymentStep({ name: PaymentStep.PAY_VIA_CARD });
    setIsCoursePaymentDrawerOpen(true);
  };

  return { alertMessage, handleClickRedeemCoupon, handleClickPayUsingCard };
};

export default useChoosePaymentMethodDialog;
