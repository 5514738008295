import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import { Root, zoomAppFooterHeight } from './styles';
import useZoomAppFooterLayoutVM from './vm';

export { zoomAppFooterHeight };

interface Props {
  left?: ReactNode;
  right?: ReactNode;
}

const ZoomAppFooterLayout = ({ left, right }: Props) => {
  const { t } = useTranslation([i18nNS.CLASS]);

  const { isOnClassPage, handleGoToClassPage } = useZoomAppFooterLayoutVM();

  return (
    <Root>
      <Stack direction="row" gap={1}>
        {left}
      </Stack>
      {isOnClassPage ? (
        <Stack direction="row" gap={1}>
          {right}
        </Stack>
      ) : (
        <Button variant="outlined" size="small" onClick={handleGoToClassPage}>
          {t('go_to_class', { ns: i18nNS.CLASS })}
        </Button>
      )}
    </Root>
  );
};

export default ZoomAppFooterLayout;
