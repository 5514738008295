import urls from '../../app/urls';
import { format, now, unix } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { toAssignmentQuestion } from './helpers';
import {
  AddAssignmentQuestionPayload,
  Assignment,
  CreateAssignmentPayload,
  DeleteAssignmentPayload,
  DeleteAssignmentQuestionPayload,
  EditAssignmentQuestionPayload,
  EditPublishedAssignmentPayload,
  EditUnpublishedAssignmentPayload,
  FetchAssignmentDataPayload,
  FetchIndividualAssignmentSubmissionPayload,
  PublishAssignmentPayload,
  QuestionSubmissionType,
  RetractAssignmentPayload,
  SaveFileSubmissionPayload,
  SaveURLSubmissionPayload,
  SubmitAssignmentPayload,
} from './types';

export async function createAssignment({ createFromScratch }: CreateAssignmentPayload) {
  const payload: API.CreateAssignmentRequest = { isNewFromScratch: createFromScratch ? 1 : 0 };
  const response = await request.post<API.CreateAssignmentResponse>(urls.createAssignment, payload);

  if (response.data.hasSuggestedAssignments === 1) {
    return {
      assignment: null,
      suggested: response.data.suggestedAssignments,
    };
  }

  const { newAssignment, suggestedAssignments } = response.data;

  const assignment: Assignment = {
    id: newAssignment._id,
    courseId: response.config.headers.courseId,
    sequenceNum: 0,
    title: newAssignment.details.title || null,
    instructions: newAssignment.details.instructions,
    attachments: newAssignment.details.attachments,
    questions: [],
    questionsById: {},
    studentDataById: {},
    preferences: {
      dueDateTime: newAssignment.details.dueDateTime,
      maxMarks: newAssignment.details.maxMarks,
      allowLate: Boolean(newAssignment.details.allowLate),
    },
    comments: {
      total: newAssignment.activities.numCommentsTotal,
      seen: 0,
      isSubscribed: false,
      preSub: {
        total: newAssignment.activities.numCommentsPreSub,
        seen: 0,
      },
      postSub: {
        total: newAssignment.activities.numCommentsPostSub,
        seen: 0,
      },
    },
    gradedSubmissions: newAssignment.stats.numGraded,
    totalSubmissions: newAssignment.stats.numSubmitted,
    firstAccessedOn: newAssignment.details.createdOn || unix(),
    lastAccessedOn: unix(),
    editedBy: null,
    editedOn: -1,
    createdBy: newAssignment.details.createdBy,
    createdOn: newAssignment.details.createdOn || unix(),
    publishedOn: -1,
  };

  return {
    assignment,
    suggested: suggestedAssignments,
  };
}

export async function editUnpublishedAssignment(data: EditUnpublishedAssignmentPayload) {
  return request.post<unknown>(urls.editUnpublishedAssignment, data);
}

export async function editPublishedAssignment({
  notifyStudents,
  questions,
  ...data
}: EditPublishedAssignmentPayload) {
  const payload: API.EditPublishedAssignmentRequest = {
    ...data,
    toNotify: notifyStudents ? 1 : 0,
  };

  if (questions) {
    payload.questions = questions.map((q) => ({
      questionId: q.id,
      description: { text: q.description },
      marks: q.maxMarks,
      attachments: q.attachments,
      submission: q.submissionType,
      submitExt: q.submissionType === QuestionSubmissionType.URL ? ['dummy'] : q.extensions,
    }));
  }

  return request.put<unknown>(urls.editPublishedAssignment, payload);
}

export async function publishAssignment(data: PublishAssignmentPayload) {
  const payload: API.PublishAssignmentRequest = {
    assignmentId: data.assignmentId,
    dueDateTime: data.dueDateTime,
    allowLate: data.allowLate ? 1 : 0,
    subscribeToComments: data.subscribeToComments ? 1 : 0,
  };
  return (await request.post<API.PublishAssignmentResponse>(urls.publishAssignment, payload)).data;
}

export async function deleteAssignment(data: DeleteAssignmentPayload) {
  return (await request.delete<unknown>(urls.deleteAssignment, { data })).data;
}

export async function getAssignmentData({ assignmentId, isFirstAccess }: FetchAssignmentDataPayload) {
  const params: API.FetchAssignmentDataRequest = {
    assignmentId,
    firstAccess: isFirstAccess ? 1 : 0,
  };
  return (await request.get<API.FetchAssignmentDataResponse>(urls.getAssignmentData, { params })).data;
}

export async function addAssignmentQuestion(data: AddAssignmentQuestionPayload) {
  const payload: API.AddAssignmentQuestionRequest = {
    activityType: 'assignments',
    activityId: data.assignmentId,
    description: { text: data.description },
    submission: data.submissionType,
    marks: data.maxMarks,
    attachments: data.attachments,
    submitExt: data.submissionType === QuestionSubmissionType.URL ? ['dummy'] : data.extensions,
  };
  const response = await request.post<API.AssignmentQuestion>(urls.addAssignmentQuestion, payload);
  return toAssignmentQuestion(response.data);
}

export async function editAssignmentQuestion(data: EditAssignmentQuestionPayload) {
  const payload: API.EditAssignmentQuestionRequest = {
    activityType: 'assignments',
    activityId: data.assignmentId,
    questionId: data.id,
    description: { text: data.description },
    submission: data.submissionType,
    marks: data.maxMarks,
    attachments: data.attachments,
    submitExt: data.submissionType === QuestionSubmissionType.URL ? ['dummy'] : data.extensions,
  };
  return request.post<unknown>(urls.editAssignmentQuestion, payload);
}

export async function deleteAssignmentQuestion(data: DeleteAssignmentQuestionPayload) {
  const payload: API.DeleteAssignmentQuestionRequest = {
    activityType: 'assignments',
    activityId: data.assignmentId,
    questionId: data.questionId,
  };
  return request.delete<unknown>(urls.deleteAssignmentQuestion, { data: payload });
}

export async function saveFileSubmission({ assignmentId, ...data }: SaveFileSubmissionPayload) {
  const payload: API.SaveAssignmentFileSubmissionRequest = {
    activityId: assignmentId,
    activityType: 'assignments',
    ...data,
  };
  return (
    await request.post<API.SaveAssignmentFileSubmissionResponse>(urls.saveAssignmentFileSubmission, payload)
  ).data;
}

export async function saveURLSubmission(data: SaveURLSubmissionPayload) {
  const payload: API.SaveAssignmentURLSubmissionRequest = {
    activityType: 'assignments',
    activityId: data.assignmentId,
    questionId: data.questionId,
    url: data.url,
  };
  return (
    await request.post<API.SaveAssignmentURLSubmissionResponse>(urls.saveAssignmentURLSubmission, payload)
  ).data;
}

export async function submitAssignment({ assignmentId }: SubmitAssignmentPayload) {
  const data: API.SubmitAssignmentRequest = {
    assignmentId,
    localTime: format(now(), `yyyyMMdd'T'HH:mm`),
  };
  return (await request.post<API.SubmitAssignmentResponse>(urls.submitAssignment, data)).data;
}

export async function retractAssignment(data: RetractAssignmentPayload) {
  return (await request.put<unknown>(urls.retractAssignment, data)).data;
}

export async function getAssignmentAnalyticsForCourseTeam(assignmentId: MongoId) {
  return (
    await request.get<API.GetAssignmentAnalyticsForCourseTeamResponse>(
      urls.getAssignmentAnalyticsForCourseTeam(assignmentId)
    )
  ).data;
}

export async function getIndividualAssignmentSubmission({
  assignmentId,
  studentId,
}: FetchIndividualAssignmentSubmissionPayload) {
  return (
    await request.get<API.GetIndividualAssignmentSubmissionResponse>(
      urls.getIndividualAssignmentSubmission(assignmentId, studentId)
    )
  ).data;
}

export async function saveAssignmentGrades(data: API.SaveAssignmentGradesRequest) {
  return (await request.post<unknown>(urls.saveAssignmentGrades, data)).data;
}

export async function submitAssignmentGrades(data: API.SaveAssignmentGradesRequest) {
  return (await request.post<unknown>(urls.submitAssignmentGrades, data)).data;
}
