import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const PersonDoneIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M21.66 4.25C21.461 4.0752 21.2007 3.9865 20.9364 4.00337C20.672 4.02025 20.4252 4.14131 20.25 4.34L18.38 6.49L17.75 5.78C17.5736 5.58109 17.3255 5.46039 17.0601 5.44445C16.7947 5.4285 16.5339 5.51863 16.335 5.695C16.1361 5.87137 16.0154 6.11953 15.9994 6.3849C15.9835 6.65026 16.0736 6.91109 16.25 7.11L17.64 8.67C17.7346 8.77485 17.8504 8.85847 17.9796 8.91535C18.1089 8.97223 18.2488 9.00108 18.39 9C18.5303 8.99841 18.6687 8.96732 18.7962 8.90874C18.9237 8.85017 19.0374 8.76542 19.13 8.66L21.74 5.66C21.916 5.46226 22.0064 5.20278 21.9915 4.93847C21.9765 4.67415 21.8573 4.42657 21.66 4.25Z"
          fill="currentColor"
        />
        <path
          d="M10 11C10.7911 11 11.5645 10.7654 12.2223 10.3259C12.8801 9.88635 13.3928 9.26164 13.6955 8.53074C13.9983 7.79983 14.0775 6.99556 13.9231 6.21964C13.7688 5.44372 13.3878 4.73098 12.8284 4.17157C12.269 3.61216 11.5563 3.2312 10.7804 3.07686C10.0044 2.92252 9.20017 3.00173 8.46927 3.30448C7.73836 3.60723 7.11365 4.11992 6.67412 4.77772C6.2346 5.43552 6 6.20888 6 7C6 8.06087 6.42143 9.07828 7.17157 9.82843C7.92172 10.5786 8.93913 11 10 11Z"
          fill="currentColor"
        />
        <path
          d="M16 21C16.2652 21 16.5196 20.8946 16.7071 20.7071C16.8946 20.5196 17 20.2652 17 20C17 18.1435 16.2625 16.363 14.9497 15.0503C13.637 13.7375 11.8565 13 10 13C8.14348 13 6.36301 13.7375 5.05025 15.0503C3.7375 16.363 3 18.1435 3 20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.66 4.25C21.461 4.0752 21.2007 3.9865 20.9364 4.00337C20.672 4.02025 20.4252 4.14131 20.25 4.34L18.38 6.49L17.75 5.78C17.5736 5.58109 17.3255 5.46039 17.0601 5.44445C16.7947 5.4285 16.5339 5.51863 16.335 5.695C16.1361 5.87137 16.0154 6.11953 15.9994 6.3849C15.9835 6.65026 16.0736 6.91109 16.25 7.11L17.64 8.67C17.7346 8.77485 17.8504 8.85847 17.9796 8.91535C18.1089 8.97223 18.2488 9.00108 18.39 9C18.5303 8.99841 18.6687 8.96732 18.7962 8.90874C18.9237 8.85017 19.0374 8.76542 19.13 8.66L21.74 5.66C21.916 5.46226 22.0064 5.20278 21.9915 4.93847C21.9765 4.67415 21.8573 4.42657 21.66 4.25Z"
        fill="currentColor"
      />
      <path
        d="M10 11C10.7911 11 11.5645 10.7654 12.2223 10.3259C12.8801 9.88635 13.3928 9.26164 13.6955 8.53074C13.9983 7.79983 14.0775 6.99556 13.9231 6.21964C13.7688 5.44372 13.3878 4.73098 12.8284 4.17157C12.269 3.61216 11.5563 3.2312 10.7804 3.07686C10.0044 2.92252 9.20017 3.00173 8.46927 3.30448C7.73836 3.60723 7.11365 4.11992 6.67412 4.77772C6.2346 5.43552 6 6.20888 6 7C6 8.06087 6.42143 9.07828 7.17157 9.82843C7.92172 10.5786 8.93913 11 10 11ZM10 5C10.3956 5 10.7822 5.1173 11.1111 5.33706C11.44 5.55683 11.6964 5.86918 11.8478 6.23463C11.9991 6.60009 12.0387 7.00222 11.9616 7.39018C11.8844 7.77814 11.6939 8.13451 11.4142 8.41422C11.1345 8.69392 10.7781 8.8844 10.3902 8.96157C10.0022 9.03874 9.60009 8.99914 9.23463 8.84776C8.86918 8.69639 8.55682 8.44004 8.33706 8.11114C8.1173 7.78224 8 7.39556 8 7C8 6.46957 8.21071 5.96086 8.58579 5.58579C8.96086 5.21072 9.46957 5 10 5Z"
        fill="currentColor"
      />
      <path
        d="M10 13C8.14348 13 6.36301 13.7375 5.05025 15.0503C3.7375 16.363 3 18.1435 3 20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21C4.26522 21 4.51957 20.8946 4.70711 20.7071C4.89464 20.5196 5 20.2652 5 20C5 18.6739 5.52678 17.4022 6.46447 16.4645C7.40215 15.5268 8.67392 15 10 15C11.3261 15 12.5979 15.5268 13.5355 16.4645C14.4732 17.4022 15 18.6739 15 20C15 20.2652 15.1054 20.5196 15.2929 20.7071C15.4804 20.8946 15.7348 21 16 21C16.2652 21 16.5196 20.8946 16.7071 20.7071C16.8946 20.5196 17 20.2652 17 20C17 18.1435 16.2625 16.363 14.9497 15.0503C13.637 13.7375 11.8565 13 10 13Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default PersonDoneIcon;
