import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { AuthAPI } from './api.types';
import {
  CheckLoginEmailPayload,
  CheckLoginEmailSuccessPayload,
  CheckSignupEmailPayload,
  CheckSignupEmailSuccessPayload,
  CreateProfilePayload,
  FetchUserRegistrationPayload,
  FetchUserRegistrationSuccessPayload,
  LogoutPayload,
  SetPasswordPayload,
  SetPasswordSuccessPayload,
  SetSessionPayload,
  StudentSignupPayload,
  StudentSignupSuccessPayload,
  TeacherSignupPayload,
  TeacherSignupSuccessPayload,
  TryLoginDonePayload,
  TryLoginPayload,
  TrySsoLoginDonePayload,
  TrySsoLoginPayload,
  VerifyTempPassDonePayload,
  VerifyTempPassPayload,
} from './types';

export const setUserSession = createAction<SetSessionPayload>('auth/session/set');

export const checkLoginEmail = createRequestAction<CheckLoginEmailPayload, CheckLoginEmailSuccessPayload>(
  'auth/login/check-email'
);

export const getSsoStatus = createRequestAction<AuthAPI.GetSsoStatusRequest, AuthAPI.GetSsoStatusResponse>(
  'auth/sso/get-status'
);

export const fetchUserRegistration = createRequestAction<
  FetchUserRegistrationPayload,
  FetchUserRegistrationSuccessPayload
>('auth/user-registration/fetch');

export const tryLogin = createRequestAction<TryLoginPayload, TryLoginDonePayload>('auth/login');

export const trySsoLogin = createRequestAction<TrySsoLoginPayload, TrySsoLoginDonePayload>('auth/sso-login');

export const forgotPassword = createRequestAction<void, AuthAPI.ForgotPasswordResponse>(
  'auth/forgot-password'
);

export const resendTempPass = createRequestAction('auth/temp-pass/resend');

export const verifyTempPass = createRequestAction<VerifyTempPassPayload, VerifyTempPassDonePayload>(
  'auth/temp-pass/verify'
);

export const setPassword = createRequestAction<SetPasswordPayload, SetPasswordSuccessPayload>(
  'auth/password/set'
);

export const createProfile = createRequestAction<CreateProfilePayload>('auth/profile/create');

export const logout = createAction<LogoutPayload>('auth/logout');

export const checkSignupEmail = createRequestAction<CheckSignupEmailPayload, CheckSignupEmailSuccessPayload>(
  'auth/signup/check-email'
);

export const teacherSignup = createRequestAction<TeacherSignupPayload, TeacherSignupSuccessPayload>(
  'auth/signup/teacher'
);

export const fetchCourseByCode = createRequestAction<
  AuthAPI.GetCourseByCodeRequest,
  AuthAPI.GetCourseByCodeResponse
>('auth/course/get-by-code');

export const studentSignup = createRequestAction<StudentSignupPayload, StudentSignupSuccessPayload>(
  'auth/signup/student'
);

export const changePassword = createRequestAction<AuthAPI.ChangePasswordRequest>('auth/change-password');

export const fetchChangeNameStatus = createRequestAction<void, AuthAPI.GetChangeNameStatusResponse>(
  'auth/change-name-status/fetch'
);

export const changeName = createRequestAction<AuthAPI.ChangeNameRequest>('auth/change-name');

export const cancelFacultyAccountVerification = createRequestAction('auth/faculty-verification/cancel');
