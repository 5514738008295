import { styled, Typography, TypographyProps } from '@mui/material';

import { ellipsize } from '../../styles/mixins';
import { generateClasses } from '../../utils/helpers';
import Button from '../Button';
import Wrapper from './Wrapper';

export const userCellClasses = generateClasses('UserCell', [
  'root',
  'title',
  'avatar',
  'action',
  'subtitle',
  'content',
  'sizeMedium',
  'sizeLarge',
]);

export const Root = styled(Wrapper)(({ theme }) => ({
  textAlign: 'left',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(3),

  [`&.${userCellClasses.sizeLarge}`]: {
    [`.${userCellClasses.avatar}`]: {
      width: 48,
      height: 48,
    },
    [`.${userCellClasses.title}`]: {
      ...theme.typography.h5Bold,
      color: theme.palette.grey[700],
    },
  },
  [`&.${userCellClasses.sizeMedium}`]: {
    [`.${userCellClasses.avatar}`]: {
      width: 40,
      height: 40,
    },
    [`.${userCellClasses.title}`]: {
      ...theme.typography.paragraphBold,
      color: theme.palette.grey[700],
    },
  },
}));

export const Content = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
}));

export const Title = styled((props: TypographyProps) => <Typography variant="h5Bold" {...props} />)(
  ({ theme }) => ({
    ...ellipsize,
    color: theme.palette.grey[700],
  })
);

export const Subtitle = styled((props: TypographyProps) => (
  <Typography variant="paragraphMedium" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const Action = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
}));
