import { useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import routes, { ClassParams } from '../../pages/routes';
import { ClassTabItems } from './types';

export default function useClassHeaderVM() {
  const params = useParams<ClassParams>();
  const { pathname } = useLocation();

  const activeTab = useMemo(() => {
    const routeMap = {
      [routes.activities.path]: ClassTabItems.ACTIVITIES,
      [routes.agenda.path]: ClassTabItems.AGENDA,
      [routes.attendance.path]: ClassTabItems.ATTENDANCE,
    };

    for (const [path, tab] of Object.entries(routeMap)) {
      if (matchPath(pathname, { path, exact: true })) return tab;
    }

    return ClassTabItems.ACTIVITIES;
  }, [pathname]);

  return { activeTab, params };
}
