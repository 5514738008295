import { RefObject, useEffect } from 'react';
import { Control, FieldValues, Path, PathValue, UnpackNestedValue, useController } from 'react-hook-form';

import { Editor } from 'slate';

import { ToolbarCustomizationProps } from '../../rich-text/Toolbar';
import RichTextEditorComp, {
  Props as RichTextEditorProps,
  RichTextUploadAttachmentConfig,
} from '../RichText/Editor';

interface VMProps<T extends FieldValues> {
  editorRef?: RefObject<Editor>;
  control: Control<T, object>;
  inputField: {
    name: Path<T>;
    initialValue: UnpackNestedValue<PathValue<T, Path<T>>> | undefined;
  };
  attachments?: {
    name: Path<T>;
    initialValue: UnpackNestedValue<PathValue<T, Path<T>>>;
    uploadRequestConfig: RichTextUploadAttachmentConfig | undefined;
  };
  onContentStateChange?: ({ hasImage, hasFormula }: { hasImage: boolean; hasFormula: boolean }) => any;
}

const useRichTextEditorVM = <T extends FieldValues>({
  control,
  inputField,
  attachments,
  editorRef,
  onContentStateChange,
}: VMProps<T>) => {
  const {
    field: { onChange: onInputChange, onBlur },
    fieldState: { error: inputError },
  } = useController({
    name: inputField.name,
    control,
    defaultValue: inputField.initialValue || ('' as UnpackNestedValue<PathValue<T, Path<T>>>),
  });

  const {
    field: { onChange: onAttachmentsChange },
    fieldState: { error: attachmentError },
  } = useController({
    name: attachments?.name || ('' as Path<T>),
    control,
    defaultValue: attachments?.initialValue || ([] as UnpackNestedValue<PathValue<T, Path<T>>>),
  });

  const onChange = (value: string) => {
    onInputChange(value);
    if (editorRef?.current && onContentStateChange) {
      const hasImage = editorRef.current.hasImage();
      const hasFormula = editorRef.current.hasFormula();
      onContentStateChange({ hasImage, hasFormula });
    }
  };

  useEffect(
    function init() {
      if (!editorRef?.current) return;
      if (!onContentStateChange) return;
      const hasImage = editorRef.current.hasImage();
      const hasFormula = editorRef.current.hasFormula();
      onContentStateChange({ hasImage, hasFormula });
    },
    [editorRef, onContentStateChange]
  );

  return { onChange, onBlur, onAttachmentsChange, error: inputError || attachmentError };
};

export type Props<T extends FieldValues> = VMProps<T> &
  Omit<RichTextEditorProps, 'onChange' | 'onAttachmentsChange' | 'initialAttachments' | 'initialValue'> & {
    toolbar?: false | ToolbarCustomizationProps;
  };

const RichTextEditor = <T extends FieldValues>({ toolbar, ...props }: Props<T>) => {
  const { editorRef, inputField, attachments } = props;
  const { onChange, onBlur, onAttachmentsChange, error } = useRichTextEditorVM(props);

  return (
    <RichTextEditorComp
      {...props}
      ref={editorRef}
      initialValue={inputField.initialValue || ''}
      initialAttachments={attachments?.initialValue || []}
      onChange={onChange}
      onAttachmentsChange={onAttachmentsChange}
      onBlur={onBlur}
      toolbar={
        typeof toolbar === 'boolean'
          ? toolbar
          : {
              ...toolbar,
              uploadAttachmentConfig: attachments?.uploadRequestConfig,
            }
      }
      errorMessage={error?.message}
    />
  );
};

export default RichTextEditor;
