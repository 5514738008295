import { RootState } from '../../store/types';
import { notEmpty } from '../../utils/array';
import { getActivityById, getFullId } from '../shared/helpers';
import { Poll } from './types';

export const selectFullPollId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { polls } = state.db;
  return getFullId(polls, shortId);
};

export const selectPoll = (id: ShortId | MongoId) => (state: RootState) => {
  const { polls } = state.db;
  return getActivityById(polls, id);
};

export const selectAllPolls = (state: RootState) => {
  const { polls } = state.db;

  const publishedPolls: Poll[] = [];
  const unpublishedPolls: Poll[] = [];

  if (!polls.byId) return { publishedPolls, unpublishedPolls };

  for (const poll of Object.values(polls.byId)) {
    if (!poll) continue;

    if (poll.publishedOn <= 0) {
      unpublishedPolls.push(poll);
    } else {
      publishedPolls.push(poll);
    }
  }

  return {
    publishedPolls,
    unpublishedPolls,
  };
};

export const selectSuggestedPoll = (id: MongoId) => (state: RootState) => {
  const { suggestedById } = state.db.polls;
  return suggestedById[id];
};

export const selectSuggestedPollQuestions = (suggestedPollId: MongoId) => (state: RootState) => {
  const poll = selectSuggestedPoll(suggestedPollId)(state);
  if (!poll?.questionsById) return [];
  return Object.values(poll.questionsById).filter(notEmpty);
};

export const selectPollQuestions = (pollId: ShortId | MongoId) => (state: RootState) => {
  const poll = selectPoll(pollId)(state);
  if (!poll?.questionsById) return [];
  return Object.values(poll.questionsById).filter(notEmpty);
};
