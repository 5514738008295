import LeftActions from './LeftActions';
import RightActions from './RightActions';
import { AppBar, discussionPanelHeaderClasses, Toolbar } from './styles';
import Title from './Title';

const DiscussionPanelHeader = () => {
  return (
    <AppBar elevation={0} position="relative">
      <Toolbar className={discussionPanelHeaderClasses.toolbar} disableGutters>
        <LeftActions />
        <Title />
        <RightActions classes={{ notification: discussionPanelHeaderClasses.notification }} />
      </Toolbar>
    </AppBar>
  );
};

export default DiscussionPanelHeader;
