import { styled } from '@mui/material';

import UserCell from '../../../../../components/UserCell/index';
import { userCellClasses as acadlyUserCellClasses } from '../../../../../components/UserCell/styles';
import { pick } from '../../../../../utils/helpers';

export const userCellClasses = pick(acadlyUserCellClasses, 'subtitle');

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  [`& .${userCellClasses.subtitle}`]: {
    color: theme.palette.warning[500],
  },
}));
