import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { toggleAccessibility } from '../../db/app/actions';
import { selectAccessibilityPrefs } from '../../db/app/selectors';
import { useRequestDispatch } from '../../utils/request-actions';

const useAccessibilityFeaturesVM = () => {
  const requestDispatch = useRequestDispatch();

  const { turnOff } = useSelector(selectAccessibilityPrefs);

  const [isTogglingAccessibility, setIsTogglingAccessibility] = useState(false);
  const [isAccessibilityOn, setIsAccessibilityOn] = useState(!turnOff);

  const handleToggleAccessibility = async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      setIsTogglingAccessibility(true);
      await requestDispatch(toggleAccessibility, !checked);
      setIsAccessibilityOn(checked);
    } finally {
      setIsTogglingAccessibility(false);
    }
  };

  useEffect(
    function setInitialValues() {
      setIsAccessibilityOn(!turnOff);
    },
    [turnOff]
  );

  return { isTogglingAccessibility, isAccessibilityOn, handleToggleAccessibility };
};

export default useAccessibilityFeaturesVM;
