import { ReactNode } from 'react';

import { FormControlLabel, FormControlLabelProps, styled, Switch, SwitchProps } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const switchClasses = {
  ...generateClasses('Switch', [
    'root',
    'label',
    'labelPlacementStart',
    'labelPlacementTop',
    'labelPlacementBottom',
    'labelDisabled',
    'labelError',
    'switchRoot',
    'switchBase',
    'checked',
    'disabled',
    'thumb',
    'checkedThumb',
    'track',
    'focusVisible',
  ]),
};

export interface Props extends DistributiveOmit<SwitchProps, 'size' | 'color' | 'classes'> {
  label?: ReactNode;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  classes?: Partial<typeof switchClasses>;
}

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${switchClasses.label}`]: {
    color: theme.palette.grey[800],
    marginLeft: theme.spacing(4),
    [`&.${switchClasses.disabled}`]: {
      color: theme.palette.grey[800],
    },
  },
  [`&.${switchClasses.labelPlacementStart}`]: {
    [`& .${switchClasses.label}`]: {
      marginLeft: 'unset',
      marginRight: theme.spacing(4),
    },
  },
}));

export const StyledSwitch = styled(Switch)(({ theme }) => {
  const switchWidth = '3.25rem';
  const switchHeight = '2rem';
  return {
    width: switchWidth,
    height: switchHeight,
    padding: 0,
    [`& .${switchClasses.switchBase}`]: {
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      padding: 0,
      margin: '0 2px',
      [`& .${switchClasses.thumb}`]: {
        width: `calc(${switchHeight} - 4px)`,
        height: `calc(${switchHeight} - 4px)`,
        color: theme.palette.background.paper,
        boxShadow: 'none',
      },
      [`& + .${switchClasses.track}`]: {
        backgroundColor: theme.palette.grey[100],
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 9999,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border-color'], {
          duration: 300,
        }),
      },
      '&:hover': {
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.primary[300],
          borderColor: theme.palette.primary[500],
        },
      },
      [`&.${switchClasses.focusVisible}`]: {
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[600],
        },
      },
      [`&.${switchClasses.disabled}`]: {
        [`& .${switchClasses.thumb}`]: {
          color: theme.palette.grey[300],
        },
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
          opacity: 1,
        },
      },
      [`&.${switchClasses.checked}`]: {
        transform: 'translate(70%, -50%)',
        [`& + .${switchClasses.track}`]: {
          backgroundColor: theme.palette.primary[600],
          borderColor: theme.palette.primary[600],
          opacity: 1,
        },
        '&:hover': {
          [`& + .${switchClasses.track}`]: {
            backgroundColor: theme.palette.primary[500],
            borderColor: theme.palette.primary[500],
          },
        },
        [`&.${switchClasses.focusVisible}`]: {
          [`& + .${switchClasses.track}`]: {
            backgroundColor: theme.palette.primary[700],
            borderColor: theme.palette.primary[700],
          },
        },
        [`&.${switchClasses.disabled}`]: {
          [`& .${switchClasses.thumb}`]: {
            color: theme.palette.grey[300],
          },
          [`& + .${switchClasses.track}`]: {
            backgroundColor: theme.palette.grey[100],
            borderColor: theme.palette.grey[300],
            opacity: 1,
          },
        },
      },
    },
  };
});
