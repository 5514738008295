import { RootState } from '../../store/types';
import { unix } from '../../utils/datetime';
import {
  selectIsAssignmentDue,
  selectUnseenComments as selectAssignmentUnseenComments,
} from '../assignments/selectors';
import {
  selectDueActivities as selectClassDueActivities,
  selectUnseenComments as selectClassUnseenComments,
  selectUnseenQueries,
} from '../classes/selectors';
import { CourseRole, CourseUser, EnrollmentBy, TimelineItemType } from '../shared/types';
import { groupWeeklySchedule } from './helpers';
import { BlueprintCourse, Course, EnrolledStudent, InvitedStudent, RemovedStudent } from './types';

export const selectFullCourseId = (shortId: ShortId) => (state: RootState) => {
  const { courses } = state.db;
  return Object.keys(courses.byId).find((id) => id.endsWith(shortId));
};

export const selectCourses = (state: RootState) => {
  const result: Course[] = [];
  const { courses } = state.db;

  for (const courseId of courses.order) {
    const course = courses.byId[courseId];
    if (course) result.push(course);
  }

  return result;
};

export const selectLiveCourses = (state: RootState) => {
  const result: Course[] = [];
  const { courses } = state.db;

  for (const courseId of courses.order) {
    const course = courses.byId[courseId];
    if (course?.status.isLive) result.push(course);
  }

  return result;
};

export const selectArchivedCourses = (state: RootState) => {
  const result: Course[] = [];
  const { courses } = state.db;

  for (const courseId of courses.archivedOrder) {
    const course = courses.byId[courseId];
    if (course?.status.isLive) result.push(course);
  }

  return result;
};

export const selectBlueprintCourseIds = (state: RootState) => state.db.courses.blueprintOrder;

export const selectBlueprintCourses = (state: RootState) => {
  const result: BlueprintCourse[] = [];
  const { courses } = state.db;

  for (const blueprintCourseId of courses.blueprintOrder) {
    const blueprintCourse = courses.blueprintById[blueprintCourseId];
    if (!blueprintCourse) continue;
    result.push(blueprintCourse);
  }

  return result;
};

export const selectCourse = (id: ShortId | MongoId) => (state: RootState) => {
  const { courses } = state.db;
  const courseId = selectFullCourseId(id)(state);
  if (!courseId) return undefined;
  return courses.byId[courseId];
};

export const selectCurrentCourseId = (state: RootState) => state.db.courses.currentCourse;

export const selectCurrentCourse = (state: RootState) => {
  const { currentCourse, byId } = state.db.courses;
  if (!currentCourse) return undefined;
  return byId[currentCourse];
};

export const selectGroupedWeeklySchedule = (state: RootState) => {
  const course = selectCurrentCourse(state);
  if (!course) return [];
  return groupWeeklySchedule(course.schedule.weekly);
};

/**
 * Selects current user's role in specified course, If course-id is not
 * provided then it will return current user's role in current course,
 * if current course is not selected then returns student role as defualt
 * role.
 * @param courseId optional short or full course-id
 */
export const selectCourseRole = (courseId?: ShortId | MongoId) => (state: RootState) => {
  const course = selectCourse(courseId || state.db.courses.currentCourse || '')(state);
  if (!course) return CourseRole.STUDENT;
  return course.myRole;
};

/**
 * Select current course user, if courseId is not specified
 * then current course will be used
 * @param courseId optional short or full course-id
 */
export const selectCourseUser = (courseId?: ShortId | MongoId) => (state: RootState) => {
  const { session } = state.auth;
  const role = selectCourseRole(courseId)(state);
  if (!session) return null;
  const courseUser: CourseUser = {
    userId: session.userId,
    name: session.name,
    avatar: session.avatar,
    role,
  };
  return courseUser;
};

export const selectCourseEnrollment = (state: RootState): Course['enrollment'] => {
  const course = selectCurrentCourse(state);
  if (!course) return { by: EnrollmentBy.INVITATION };
  return course.enrollment;
};

export const selectCourseStudents = (state: RootState) => {
  const course = selectCurrentCourse(state);

  const students = {
    enrolled: [] as EnrolledStudent[],
    invited: [] as InvitedStudent[],
    removed: [] as RemovedStudent[],
  };

  if (!course) return students;

  for (const student of Object.values(course.enrolledStudentsById || [])) {
    if (!student) continue;
    students.enrolled.push(student);
  }

  for (const student of Object.values(course.invitedStudentsById || [])) {
    if (!student) continue;
    students.invited.push(student);
  }

  for (const student of Object.values(course.removedStudentsById || [])) {
    if (!student) continue;
    students.removed.push(student);
  }

  return students;
};

export const selectCourseAverages = (state: RootState) => {
  const course = selectCurrentCourse(state);
  if (!course) return null;
  return course.averages;
};

export const selectEnrolledStudent = (studentId: MongoId | undefined) => (state: RootState) => {
  const course = selectCurrentCourse(state);
  if (!course?.enrolledStudentsById || !studentId) return undefined;
  return course.enrolledStudentsById[studentId];
};

export const selectStudentAverages = (studentId: MongoId | undefined) => (state: RootState) => {
  const course = selectCurrentCourse(state);
  if (!course?.studentAveragesByStudentId || !studentId) return undefined;
  return course.studentAveragesByStudentId[studentId];
};

export const selectTimeline = (id: ShortId | MongoId) => (state: RootState) => {
  return selectCourse(id)(state)?.timeline ?? null;
};

export const selectOldUnseenComments = (state: RootState) => {
  const course = selectCurrentCourse(state);

  if (!course?.timeline) return 0;

  let unseen = 0;
  const now = unix();

  for (const item of course.timeline) {
    if (item.dueDateTime >= now) continue;

    switch (item.type) {
      case TimelineItemType.ANNOUNCEMENT: {
        // announcements does not own comments
        break;
      }
      case TimelineItemType.ASSIGNMENT: {
        unseen += selectAssignmentUnseenComments(item.id)(state);
        break;
      }
      case TimelineItemType.CLASS: {
        unseen += selectClassUnseenComments(item.id)(state);
        break;
      }
    }
  }

  return unseen;
};

export const selectOldUnseenQueries = (state: RootState) => {
  const course = selectCurrentCourse(state);

  if (!course?.timeline || course.myRole === CourseRole.STUDENT) return 0;

  let unseen = 0;
  const now = unix();

  for (const item of course.timeline) {
    if (item.type !== TimelineItemType.CLASS || item.dueDateTime >= now) continue;
    unseen += selectUnseenQueries(item.id)(state);
  }

  return unseen;
};

export const selectOldDueActivities = (state: RootState) => {
  const course = selectCurrentCourse(state);

  if (!course?.timeline) return 0;

  let due = 0;
  const now = unix();

  for (const item of course.timeline) {
    if (item.dueDateTime >= now) continue;

    switch (item.type) {
      case TimelineItemType.ASSIGNMENT: {
        const isDue = selectIsAssignmentDue(item.id, course.myRole)(state);
        due += isDue ? 1 : 0;
        break;
      }
      case TimelineItemType.CLASS: {
        due += selectClassDueActivities(item.id)(state);
        break;
      }
    }
  }

  return due;
};

export const selectFile = (fileId: MongoId) => (state: RootState) => {
  const course = selectCurrentCourse(state);
  if (!course?.info) return undefined;
  return course.info.files.find((file) => file.id === fileId);
};

export const selectUniversityData = (state: RootState) => state.db.courses?.universityData || null;
