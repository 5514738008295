import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectAuthSession } from './store/selectors';
import { getSession, setSession } from './store/session';
import { AuthCredentials, AuthScreen, SsoCredentials } from './types';

export const AuthContext = createContext<{
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  marketing: boolean;
  setMarketing: React.Dispatch<React.SetStateAction<boolean>>;
  universityName: string;
  setUniversityName: React.Dispatch<React.SetStateAction<string>>;
  screen: AuthScreen;
  setScreen: React.Dispatch<React.SetStateAction<AuthScreen>>;
  authCredentials: Required<AuthCredentials> | null;
  setAuthCredentials: (authCredentials: Required<AuthCredentials> | null) => void;
  ssoCredentials: SsoCredentials | null;
  setSsoCredentials: (ssoCredentials: SsoCredentials | null) => void;
}>({
  email: '',
  setEmail: () => {},
  marketing: false,
  setMarketing: () => {},
  universityName: '',
  setUniversityName: () => {},
  screen: AuthScreen.DEFAULT,
  setScreen: () => {},
  authCredentials: null,
  setAuthCredentials: () => {},
  ssoCredentials: null,
  setSsoCredentials: () => {},
});

interface Props {
  children: ReactNode;
}

const useAuthContextProviderVM = () => {
  const session = useSelector(selectAuthSession);

  const [email, setEmail] = useState('');
  const [marketing, setMarketing] = useState(false);
  const [universityName, setUniversityName] = useState('');
  const [screen, setScreen] = useState(AuthScreen.DEFAULT);
  const [authCredentials, _setAuthCredentials] = useState<Required<AuthCredentials> | null>(null);
  const [ssoCredentials, setSsoCredentials] = useState<SsoCredentials | null>(null);

  const setAuthCredentials = useCallback(
    (authCredentials: Required<AuthCredentials> | null) => {
      if (session && authCredentials) setSession({ ...session, ...authCredentials, isAuthenticated: false });
      _setAuthCredentials(authCredentials);
    },
    [session]
  );

  useEffect(function initializeAuthCredentials() {
    const sessionWithIsAuthenticated = getSession();
    if (!sessionWithIsAuthenticated) {
      _setAuthCredentials(null);
      return;
    }

    const { isAuthenticated, ...session } = sessionWithIsAuthenticated;
    if (isAuthenticated) {
      _setAuthCredentials(null);
      return;
    }

    const { socket, token } = session;
    if (!(socket && token)) {
      _setAuthCredentials(null);
      return;
    }

    _setAuthCredentials({ socket, token });
  }, []);

  return useMemo(
    () => ({
      email,
      setEmail,
      marketing,
      setMarketing,
      universityName,
      setUniversityName,
      screen,
      setScreen,
      authCredentials,
      setAuthCredentials,
      ssoCredentials,
      setSsoCredentials,
    }),
    [email, marketing, universityName, screen, setScreen, authCredentials, setAuthCredentials, ssoCredentials]
  );
};

const AuthContextProvider = ({ children }: Props) => {
  const providerValue = useAuthContextProviderVM();

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
