import { combineReducers, ReducersMapObject } from '@reduxjs/toolkit';

import { APP_STATE_KEY } from '../app/constants';
import { appReducer } from '../app/store';
import authReducer from '../auth/store/reducer';
import dbReducer from '../db/reducer';
import pagesReducer from '../pages/reducer';
import { RootState } from './types';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers: ReducersMapObject = {}) {
  const rootReducer = combineReducers<RootState>({
    db: dbReducer,
    pages: pagesReducer,
    ...injectedReducers,
    [APP_STATE_KEY]: appReducer,
    auth: authReducer,
    // static reducers will come here
  });

  return rootReducer;
}
