import { createReducer, Dictionary } from '@reduxjs/toolkit';

import defaultAvatarImageURL from '../../assets/default-avatar.svg';
import { fetchAvatars, setAvatars } from './actions';

type AvatarsState = {
  byId: Dictionary<MongoId>;
};

const initialState: AvatarsState = {
  byId: {},
};

const avatarsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchAvatars, (state, action) => {
    const avatarIds = typeof action.payload === 'string' ? [action.payload] : action.payload;

    for (const avatarId of avatarIds) {
      if (state.byId[avatarId]) continue;
      /**
       * set to default avatar until the server response is received,
       * setting to default helps in avoiding multiple request to server
       * in case request failure or when previous request is already in progress
       */
      state.byId[avatarId] = defaultAvatarImageURL;
    }
  });

  builder.addCase(setAvatars, (state, action) => {
    state.byId = { ...state.byId, ...action.payload };
  });
});

export default avatarsReducer;
