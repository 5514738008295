import { ReactNode } from 'react';

import { styled } from '@mui/material';

import DialogHeader from '../Header';

interface Props {
  className?: string;
  children: ReactNode;
}

const StyledDialogHeader = styled(DialogHeader)(({ theme }) => ({
  maxHeight: 'unset',
}));

const Header = ({ className, children }: Props) => {
  if (typeof children === 'string') {
    return <StyledDialogHeader className={className}>{children}</StyledDialogHeader>;
  }

  return <>{children}</>;
};

export default Header;
