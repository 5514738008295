import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import useWeeklyScheduleVM, { VMProps } from './vm';

const WeekDayCell = styled((props: TypographyProps<'div'>) => (
  <Typography {...props} component="div" variant="paragraphMedium" />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary[100],
  borderRadius: 6,
  textTransform: 'capitalize',
  border: `1px solid ${theme.palette.primary[600]}`,
  color: theme.palette.primary[600],
}));

interface Props extends VMProps {
  className?: string;
}

const WeeklySchedule = ({ className, ...vmProps }: Props) => {
  const { days, timing } = useWeeklyScheduleVM(vmProps);
  return (
    <Stack className={className} gap={2}>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {days.map((day) => (
          <WeekDayCell key={day}>{day}</WeekDayCell>
        ))}
      </Stack>
      <Typography variant="paragraphBold" component="div">
        {timing}
      </Typography>
    </Stack>
  );
};
export default WeeklySchedule;
