import React from 'react';

import { Slide, SlideProps } from '@mui/material';

const SlideUpTransition = React.forwardRef(function SlideUpTransition(
  props: SlideProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUpTransition;
