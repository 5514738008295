import Joi from 'joi';

import { requiredOneOption } from '../../../utils/validators';
import { FormValues, PaymentMethod } from './types';

const validationSchema = Joi.object<FormValues, true>({
  isCreatingProCourse: requiredOneOption({ options: ['true', 'false'] }),
  paymentMethod: requiredOneOption({ options: Object.values(PaymentMethod) }),
});

export default validationSchema;
