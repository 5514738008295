import React from 'react';

import { Avatar as MuiAvatar, AvatarProps, Badge, styled } from '@mui/material';

import clsx from 'clsx';

import Button from '../../components/Button';
import CheckmarkCircle2Icon from '../../icons/CheckmarkCircle2Icon';
import { generateClasses } from '../../utils/helpers';

const selectableAvatarClasses = {
  ...generateClasses('SelectableAvatar', ['badge', 'badgeIcon', 'button']),
};

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  height: 56,
  width: 56,
}));

interface Props {
  avatarProps: AvatarProps;
  isSelected: boolean;
  onSelect?: React.MouseEventHandler;
  className?: string;
  classes?: Partial<typeof selectableAvatarClasses>;
}

const SelectableAvatar = ({ avatarProps, isSelected, onSelect, className, classes }: Props) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={
        isSelected ? (
          <CheckmarkCircle2Icon
            variant="filled"
            color="primary"
            className={clsx(selectableAvatarClasses.badgeIcon, classes?.badgeIcon)}
          />
        ) : null
      }
      className={clsx(selectableAvatarClasses.badge, className, classes?.badge)}
    >
      <Button
        variant="text"
        onClick={onSelect}
        size="large"
        className={clsx(selectableAvatarClasses.button, classes?.button)}
      >
        <Avatar variant="rounded" {...avatarProps} />
      </Button>
    </Badge>
  );
};

export default SelectableAvatar;
