const en = {
  resource_num: 'Resource {{num}}',

  showing_all_resources: 'Showing all resources',
  no_resources_have_been_published_in_this_course: 'No resources have been published in the course',
  no_unpublished_resources_have_been_created_in_this_course:
    'No unpublished resources have been created in this course',
  no_resources_have_been_created_in_this_course: 'No resources have been created in this course',

  total_clicks: 'Total Clicks',
  accessed_by: 'Accessed By',
  accessed_date: 'Accessed: {{date}}',
  N_of_clicks: '{{totalClicks}} clicks',

  resource_analytics: 'Resource Analytics',
  click_to_open_resource_analytics: 'click to open resource analytics',
  click_to_close_resource_analytics: 'click to close resource analytics',

  publishing_resource: 'Publishing resource',
  click_to_publish_resource: 'click to publish resource',
  you_won_t_be_able_to_change_the_text_description_once_the_resource_has_been_published:
    "You won't be able to change the text description once the resource has been published",

  learning_resources_need_to_have_a_title_please_add_a_title_and_try_again:
    'Learning resources need to have a title. Please add a title and try again.',
  do_you_want_to_discard_changes_made_to_resource: 'Do you want to discard changes made to resource?',
  resource_type: 'Resource type',
  select_resource_type: 'Select Resource Type',
  you_cannot_edit_the_resource_type: 'You cannot edit the resource type',
  youtube_video: 'YouTube video',
  provide_youtube_video_link: 'Provide YouTube video link',
  edit_youtube_video_url: 'Edit YouTube video URL',
  type_youtube_video_url_here: 'Type YouTube video URL here',
  click_to_cancel_editing_youtube_video_url: 'click to cancel editing YouTube url',
  click_to_update_youtube_video_url: 'click to update YouTube video url',
  provide_a_url: 'Provide a URL',
  click_to_edit_resource_url: 'click to edit resource url',
  edit_resource_url: 'Edit Resource URL',
  type_resource_url_here: 'Type Resource URL here',
  click_to_cancel_editing_resource_url: 'click to cancel editing resource url',
  click_to_update_resource_url: 'click to update resource url',
  click_to_select_text_as_resource_type: 'click to select text as resource type',
  click_to_select_file_as_resource_type: 'click to select file as resource type',
  click_to_select_video_as_resource_type: 'click to select video as resource type',
  click_to_select_link_as_resource_type: 'click to select link as resource type',

  /** comments panel */
  resource_discussion: 'Resource Discussion',
  export_resource_discussion_data_as: 'Export resource discussion data as',
  this_is_the_resource_discussion_thread_if_you_have_any_thoughts_on_this_shared_resource_material_you_can_share_it_here:
    'This is the resource discussion thread. If you have any thoughts on this shared resource material, you can share it here.',
  you_can_encourage_students_to_participate_in_discussing_this_shared_resource:
    'You can encourage students to participate in discussing this shared resource.',
};

export default en;
