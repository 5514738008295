import { forwardRef, Ref } from 'react';

import AccessibleList, { AccessibleListProps } from './AccessibleList';
import NonAccessibleList, { NonAccessibleListProps } from './NonAccessibleList';

interface AccessibleProps extends AccessibleListProps {
  type: 'accessible';
}

interface NonAccessibleProps extends NonAccessibleListProps {
  type?: undefined;
}

export type ListProps = (AccessibleProps | NonAccessibleProps) & {
  className?: string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
};

const List = forwardRef(({ ...props }: ListProps, ref: Ref<HTMLUListElement>) => {
  if (props.type === 'accessible') {
    return <AccessibleList ref={ref} {...props} />;
  }

  return <NonAccessibleList ref={ref} {...props} />;
});

export default List;
