import { API } from '../shared/api-responses';
import { NotificationType } from '../shared/types';
import { NotificationPrefs } from './types';

export function createNotificationPrefsFactory(notificationPrefs: API.NotificationPrefs): NotificationPrefs {
  const result = {} as NotificationPrefs;

  for (const [name, prefs] of Object.entries(notificationPrefs)) {
    result[name as NotificationType] = {
      cloud: Boolean(prefs.cloud),
      mail: Boolean(prefs.mail),
    };
  }

  return result;
}
