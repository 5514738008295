import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { toggleTips } from '../../db/app/actions';
import { selectTipsPrefs } from '../../db/app/selectors';
import { useRequestDispatch } from '../../utils/request-actions';

const useHelpTipsVM = () => {
  const requestDispatch = useRequestDispatch();

  const { turnOff } = useSelector(selectTipsPrefs);

  const [isTogglingHelpTips, setIsTogglingHelpTips] = useState(false);
  const [areHelpTipsOn, setAreHelpTipsOn] = useState(!turnOff);

  const handleToggleHelpTips = async (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    try {
      setIsTogglingHelpTips(true);
      await requestDispatch(toggleTips, !checked);
      setAreHelpTipsOn(checked);
    } finally {
      setIsTogglingHelpTips(false);
    }
  };

  useEffect(
    function setInitialValues() {
      setAreHelpTipsOn(!turnOff);
    },
    [turnOff]
  );

  return { isTogglingHelpTips, areHelpTipsOn, handleToggleHelpTips };
};

export default useHelpTipsVM;
