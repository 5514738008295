import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const GraduationCapIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12.4727 2.49705C12.1709 2.36768 11.8292 2.36768 11.5273 2.49705L3.12732 6.09705C2.6861 6.28615 2.40002 6.71999 2.40002 7.20002C2.40002 7.68006 2.6861 8.1139 3.12732 8.303L6.30047 9.66292C6.41586 9.53218 6.5605 9.4257 6.72732 9.35421L11.5273 7.29707C12.1365 7.036 12.8419 7.31818 13.103 7.92734C13.3641 8.53649 13.0819 9.24195 12.4727 9.50301L9.20004 10.9056L11.5273 11.903C11.8292 12.0324 12.1709 12.0324 12.4727 11.903L20.8727 8.303C21.3139 8.1139 21.6 7.68006 21.6 7.20002C21.6 6.71999 21.3139 6.28615 20.8727 6.09705L12.4727 2.49705Z"
          fill="currentColor"
        />
        <path
          d="M3.97208 11.2761L6.00002 12.1452V17.0672C5.5887 16.9739 5.16849 16.9041 4.74087 16.8593C4.17715 16.8002 3.73166 16.3547 3.67255 15.7909C3.62458 15.3334 3.60002 14.8693 3.60002 14.3998C3.60002 13.324 3.72892 12.2778 3.97208 11.2761Z"
          fill="currentColor"
        />
        <path
          d="M11.16 19.887C10.3525 19.0955 9.42203 18.4301 8.40002 17.9221V13.1738L10.5819 14.1089C11.4875 14.497 12.5126 14.497 13.4181 14.1089L20.028 11.2761C20.2711 12.2778 20.4 13.324 20.4 14.3998C20.4 14.8693 20.3755 15.3334 20.3275 15.7909C20.2684 16.3547 19.8229 16.8002 19.2592 16.8593C16.7718 17.1201 14.5349 18.2256 12.8401 19.887C12.3735 20.3445 11.6266 20.3445 11.16 19.887Z"
          fill="currentColor"
        />
        <path
          d="M7.20002 21.6C7.86277 21.6 8.40002 21.0627 8.40002 20.4V17.9221C7.64566 17.5472 6.84143 17.258 6.00002 17.0672V20.4C6.00002 21.0627 6.53728 21.6 7.20002 21.6Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5144 3.12546C11.8164 2.95818 12.1836 2.95818 12.4856 3.12546L21.4856 8.11007C21.8031 8.2859 22 8.61949 22 8.98154C22 9.34359 21.8031 9.67718 21.4856 9.85301L19.3858 11.016C19.7849 12.2615 20 13.588 20 14.963C20 15.7193 19.9349 16.4612 19.8098 17.1833C19.7332 17.6257 19.3694 17.9624 18.9212 18.0059C16.53 18.2376 14.3628 19.2328 12.6669 20.7459C12.2872 21.0847 11.7128 21.0847 11.3331 20.7459C10.6304 20.119 9.84688 19.581 9 19.1493V19.9477C9 20.4982 8.55228 20.9446 8 20.9446C7.44772 20.9446 7 20.4982 7 19.9477V18.3664C6.38008 18.1916 5.73797 18.0698 5.07884 18.0059C4.63065 17.9624 4.26686 17.6257 4.19021 17.1833C4.06509 16.4612 4 15.7193 4 14.963C4 13.588 4.21513 12.2614 4.61416 11.016L2.51436 9.85301C2.19689 9.67718 2 9.34359 2 8.98154C2 8.61949 2.19689 8.2859 2.51436 8.11007L11.5144 3.12546ZM9 16.9535C10.082 17.4034 11.0898 17.9946 12 18.7039C13.6925 17.385 15.7224 16.4742 17.9394 16.1221C17.9794 15.7414 18 15.3548 18 14.963C18 13.9379 17.8592 12.9468 17.5961 12.0072L12.4856 14.8376C12.1836 15.0049 11.8164 15.0049 11.5144 14.8376L9 13.4451V16.9535ZM7.00836 12.342C7.00283 12.3844 7 12.4274 7 12.4708V16.307C6.691 16.2342 6.37773 16.1724 6.06063 16.1221C6.02057 15.7414 6 15.3547 6 14.963C6 13.9378 6.14083 12.9467 6.40392 12.0072L7.00836 12.342ZM10.7091 12.1108L12.4856 11.1269C12.9684 10.8595 13.1424 10.2525 12.8742 9.77124C12.6059 9.28994 11.9971 9.11653 11.5144 9.38392L8.65 10.9703L6.34153 9.6918C6.33236 9.68652 6.32308 9.68138 6.31369 9.67638L5.05913 8.98154L12 5.13736L18.9409 8.98154L17.6862 9.67642C17.6769 9.68141 17.6676 9.68653 17.6585 9.69178L12 12.8257L10.7091 12.1108Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default GraduationCapIcon;
