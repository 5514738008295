import { Course } from '../../db/courses/types';
import { unix } from '../../utils/datetime';

export function getIsDeadlineOver({ course }: { course: Course | undefined }) {
  if (!course) return false;
  const now = unix();

  // Course deadline is in future
  if (course.schedule.endDate > now) return false;
  // No need to show as course is already archived
  if (course.status.isArchived) return false;
  // Don't show when course is not published yet
  if (!course.status.isLive) return false;

  return true;
}
