import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, styled } from '@mui/material';

import { i18nNS } from '../../i18n';
import MessageCircleIcon from '../../icons/MessageCircleIcon';
import { LayoutContext } from '../../shared/Layout/Context';
import { generateClasses } from '../../utils/helpers';
import Button, { Props as ButtonProps } from '../Button';

const useChatButtonVM = () => {
  const { setIsCommentsPanelOpen } = useContext(LayoutContext);

  const showDiscussionPanel = () => setIsCommentsPanelOpen(true);

  return { showDiscussionPanel };
};

const chatButtonClasses = {
  ...generateClasses('ChatButton', ['root']),
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${chatButtonClasses.root}`]: {
    color: theme.palette.primary[500],
  },
}));

type Props = ButtonProps & {
  /** @default 0 */
  numUnseenComments?: number;
};

const ChatButton = ({ numUnseenComments = 0, children, ...props }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { showDiscussionPanel } = useChatButtonVM();

  return (
    <StyledButton
      variant="text"
      size="small"
      aria-label={t('click_to_open_discussion_panel', { ns: i18nNS.COMMON })}
      onClick={showDiscussionPanel}
      {...props}
    >
      {children || (
        <Badge color="warning" badgeContent={numUnseenComments}>
          <MessageCircleIcon variant="filled" aria-hidden />
        </Badge>
      )}
    </StyledButton>
  );
};

export default ChatButton;
