import { linkClasses, listItemIconClasses, Theme, ThemeOptions } from '@mui/material';

import { forDesktop, forTablet } from '../../utils/media-queries';

const overrides = (theme: Theme): ThemeOptions => ({
  zIndex: {
    /**
     * Refer to https://v5-0-6.mui.com/customization/z-index/
     * for MUI components' z-indices
     */
    pip: theme.zIndex.modal,
    pageHeader: 1250,
    drawer: theme.zIndex.modal,
    reactourMask: theme.zIndex.modal + 1,
    reactourTip: theme.zIndex.modal + 2,
    reactourAlert: theme.zIndex.modal + 3,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar': {
          [forTablet(theme) && forDesktop(theme)]: {
            backgroundColor: 'transparent',
            width: '0.5rem',
          },
        },
        '*::-webkit-scrollbar-thumb': {
          [forTablet(theme) && forDesktop(theme)]: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: 8,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.primary[400]}`,
            outlineOffset: theme.spacing(0.5),
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.primary[400]}`,
            outlineOffset: theme.spacing(0.5),
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'paragraphRegular',
        variantMapping: {
          displayBold: 'div',
          displayMedium: 'div',
          displayRegular: 'div',

          h1Bold: 'h1',
          h1Medium: 'h1',
          h1Regular: 'h1',

          h2Bold: 'h2',
          h2Medium: 'h2',
          h2Regular: 'h2',

          h3Bold: 'h3',
          h3Medium: 'h3',
          h3Regular: 'h3',

          h4Bold: 'h4',
          h4Medium: 'h4',
          h4Regular: 'h4',

          h5Bold: 'h5',
          h5Medium: 'h5',
          h5Regular: 'h5',

          h6Bold: 'h6',
          h6Medium: 'h6',
          h6Regular: 'h6',

          paragraphBold: 'p',
          paragraphMedium: 'p',
          paragraphRegular: 'p',
          link: 'span',

          titleBold: 'div',
          titleMedium: 'div',
          titleRegular: 'div',

          textSmBold: 'div',
          textSmMedium: 'div',
          textSmRegular: 'div',

          textXsBold: 'div',
          textXsMedium: 'div',
          textXsRegular: 'div',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiRadio: {
      defaultProps: {
        focusRipple: false,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        focusRipple: false,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          color: theme.palette.primary[600],
          '&:hover': {
            color: theme.palette.primary[500],
          },
          [`&.${linkClasses.focusVisible}`]: {
            color: theme.palette.primary[700],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          [`& .${listItemIconClasses.root}`]: {
            minWidth: 'unset',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          gap: theme.spacing(2),
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: '100%',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: theme.palette.background.level2,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: theme.spacing(1),
          boxShadow: theme.acadlyShadows.lg,
          borderRadius: 8,
        },
        list: {
          minWidth: 200,
          padding: theme.spacing(1),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 5),
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(2),
          width: '100%',
          textDecoration: 'none',
          outlineOffset: theme.spacing(0.5),
          '&:hover': {
            backgroundColor: theme.palette.background.level2,
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.primary[600]}`,
          },
          [`& .${listItemIconClasses.root}`]: {
            minWidth: 'unset',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[500],
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[800],
          fontSize: theme.typography.paragraphRegular.fontSize,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: theme.spacing(8),
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.primary[400]}`,
            outlineOffset: theme.spacing(-0.5),
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.fontSize === 'xSmall' && {
            fontSize: '1rem',
          }),
        }),
      },
    },
  },
});

export default overrides;
