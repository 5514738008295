import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Skeleton } from '@mui/material';
import { Stack } from '@mui/material';

import { CreateCoursePermission } from '../auth/types';
import { ChildFabMenu, FabMenu, FabMenuItem } from '../components/FabMenu';
import ListItem from '../components/ListItem';
import CourseWidget from '../courses/CourseWidget';
import CourseWidgetSkeleton from '../courses/CourseWidget/Skeleton';
import DemoCourse from '../courses/DemoCourse';
import JoinAnExistingCourseDrawer from '../courses/JoinAnExistingCourseDrawer';
import CreateCourseDrawer from '../create-course/CreateCourseDrawer';
import InformationAlert from '../create-course/InformationAlert';
import { i18nNS } from '../i18n';
import BookIcon from '../icons/BookIcon';
import BookOpenIcon from '../icons/BookOpenIcon';
import CloseIcon from '../icons/CloseIcon';
import routes from '../pages/routes';
import { toShortID } from '../utils/helpers';
import { useDocumentTitle } from '../utils/hooks';
import FeatureUpdatesButton from './FeatureUpdatesButton';
import NoCoursesCard from './NoCoursesCard';
import { CloseFabMenuItem, CourseItem, CourseList } from './styles';
import useHomeVM from './vm';

const Home = () => {
  const { t } = useTranslation([i18nNS.HOME, i18nNS.CREATE_COURSE, i18nNS.COMMON]);
  useDocumentTitle(t('acadly_home', { ns: i18nNS.HOME }));

  const {
    isNoCoursesCardVisible,
    activeCourses,
    archivedCourses,
    isCreateCourseDrawerOpen,
    isFetchingCourses,
    isInformationAlertOpen,
    isJoinAnExistingCourseDrawerOpen,
    isProvisionalAccount,
    onClickCourseWidget,
    onClickCreateCourse: handleClickCreateCourse,
    onClickJoinAnExistingCourse: handleClickJoinAnExistingCourse,
    onCloseCreateCourseDrawer: handleCloseCreateCourseDrawer,
    onCloseInformationAlert: handleCloseInformationAlert,
    onCloseJoinAnExistingCourseDrawer: handleCloseJoinAnExistingCourseDrawer,
    onContinueInformationAlert: handleContinueInformationAlert,
    selectedCourseId,
    isCreatingProCourse,
    session,
  } = useHomeVM();

  const handleClickCourseWidget = (courseId: MongoId, isCreatingProCourse: boolean) => () => {
    onClickCourseWidget(courseId, isCreatingProCourse);
  };

  return (
    <>
      {!isProvisionalAccount && <FeatureUpdatesButton />}
      {isFetchingCourses ? (
        <CourseList>
          <CourseItem>
            <Skeleton variant="text" width="50%" sx={{ margin: 'auto' }} />
          </CourseItem>
          <CourseItem>
            <CourseWidgetSkeleton />
          </CourseItem>
          <CourseItem>
            <CourseWidgetSkeleton />
          </CourseItem>
          <CourseItem>
            <CourseWidgetSkeleton />
          </CourseItem>
        </CourseList>
      ) : (
        <Stack direction="column-reverse" gap={3} p={4}>
          {isNoCoursesCardVisible ? (
            <NoCoursesCard />
          ) : (
            <CourseList
              type="accessible"
              numItems={activeCourses.length}
              getActiveItemId={(focusIndex) =>
                activeCourses[focusIndex] ? `course-${activeCourses[focusIndex].id}` : undefined
              }
            >
              {(focusIndex) =>
                activeCourses.map((course, index) => (
                  <ListItem key={course.id} isFocused={focusIndex === index}>
                    {course.status.isSchedulePublished ? (
                      <CourseWidget
                        course={course}
                        component={RouterLink}
                        to={routes.timeline.url({
                          universitySlug: session.university.slug,
                          courseShortId: toShortID(course.id),
                        })}
                        tabIndex={-1}
                        id={`course-${course.id}`}
                        role="option"
                        aria-label={t('course_colon_title', { ns: i18nNS.COMMON, title: course.title })}
                      />
                    ) : (
                      <CourseWidget
                        course={course}
                        onClick={handleClickCourseWidget(course.id, course.permissions.isPro)}
                        tabIndex={-1}
                        id={`course-${course.id}`}
                        role="option"
                        aria-label={t('course_colon_title', { ns: i18nNS.COMMON, title: course.title })}
                      />
                    )}
                  </ListItem>
                ))
              }
            </CourseList>
          )}
          {isProvisionalAccount && <DemoCourse />}
          {archivedCourses.length > 0 && (
            <Button
              fullWidth
              variant="contained"
              component={RouterLink}
              to={routes.archives.url({ universitySlug: session.university.slug })}
              className="courseHomeArchive"
              aria-label={t('click_to_view_archived_course', { ns: i18nNS.HOME })}
            >
              {t('view_archived_courses', { ns: i18nNS.HOME })}
            </Button>
          )}
        </Stack>
      )}
      {!isFetchingCourses && !isProvisionalAccount && (
        <>
          <FabMenu
            className="courseHomeFloatingButton"
            rootMenu="add_course_menu"
            aria-label={t('click_to_add_courses', { ns: i18nNS.HOME })}
          >
            <ChildFabMenu menuId="add_course_menu">
              {session.canCreateCourses !== CreateCoursePermission.NOT_ALLOWED && (
                <FabMenuItem
                  onClick={handleClickCreateCourse}
                  icon={<BookIcon />}
                  aria-label={t('click_to_create_a_new_course', { ns: i18nNS.CREATE_COURSE })}
                  autoFocus
                >
                  {t('create_a_new_course', { ns: i18nNS.CREATE_COURSE })}
                </FabMenuItem>
              )}
              <FabMenuItem
                onClick={handleClickJoinAnExistingCourse}
                icon={<BookOpenIcon />}
                aria-label={t('click_to_join_an_existing_course', { ns: i18nNS.CREATE_COURSE })}
              >
                {t('join_an_existing_course', { ns: i18nNS.HOME })}
              </FabMenuItem>
              <CloseFabMenuItem
                icon={<CloseIcon color="inherit" />}
                aria-label={t('click_to_cancel', { ns: i18nNS.COMMON })}
              >
                {t('cancel', { ns: i18nNS.GLOSSARY })}
              </CloseFabMenuItem>
            </ChildFabMenu>
          </FabMenu>
          <InformationAlert
            open={isInformationAlertOpen}
            onCancel={handleCloseInformationAlert}
            onContinue={handleContinueInformationAlert}
          />
          <CreateCourseDrawer
            open={isCreateCourseDrawerOpen}
            onClose={handleCloseCreateCourseDrawer}
            courseId={selectedCourseId}
            isCreatingProCourse={isCreatingProCourse}
          />
          <JoinAnExistingCourseDrawer
            open={isJoinAnExistingCourseDrawerOpen}
            onClose={handleCloseJoinAnExistingCourseDrawer}
          />
        </>
      )}
    </>
  );
};

export default Home;
