import { Skeleton } from '@mui/material';

import { Props as ButtonProps } from './index';

interface Props extends Pick<ButtonProps, 'variant' | 'size' | 'fullWidth'> {}

const calculateHeight = (size: Props['size']) => {
  switch (size) {
    case 'xLarge':
      return 56;
    case 'large':
      return 48;
    case 'small':
      return 32;
    case 'xSmall':
      return 28;
    default:
      return 40;
  }
};

const ButtonSkeleton = ({ variant = 'contained', size = 'medium', fullWidth }: Props) => {
  return (
    <Skeleton
      sx={{ margin: variant === 'text' ? 2 : 0 }}
      variant={variant === 'text' ? 'text' : 'rounded'}
      width={fullWidth ? '100%' : 80}
      height={variant === 'text' ? undefined : calculateHeight(size)}
    />
  );
};

export default ButtonSkeleton;
