import { RefObject } from 'react';

import { Editor } from 'slate';

import { useImagesSelector } from '../../plugins/image/hooks';

export interface Props {
  editorRef: RefObject<Editor | null>;
  onBeforeImageUpload?: () => any;
}

const useImageUploadButtonVM = ({ editorRef, onBeforeImageUpload }: Props) => {
  const { selectImageRef, allowedImages, onImageSelect } = useImagesSelector(editorRef);

  const handleImageSelect = () => {
    return onImageSelect(onBeforeImageUpload);
  };

  return { selectImageRef, allowedImages, handleImageSelect };
};

export default useImageUploadButtonVM;
