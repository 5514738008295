import { RefObject } from 'react';

import { Editor } from 'slate';

import { useCreateFormulaOverlay } from '../../plugins/formula/hooks';

export interface Props {
  editorRef: RefObject<Editor | null>;
}

const useInsertFormulaButtonVM = ({ editorRef }: Props) => {
  const {
    isOpen: isCreateFormulaOverlayOpen,
    onInsertFormula,
    show: showCreateFormulaOverlay,
  } = useCreateFormulaOverlay(editorRef);

  return {
    showCreateFormulaOverlay,
    isCreateFormulaOverlayOpen,
    onInsertFormula,
  };
};

export default useInsertFormulaButtonVM;
