import { ReactElement } from 'react';

import { Chip as MuiChip, chipClasses, ChipProps, styled, svgIconClasses, Theme } from '@mui/material';

export interface Props
  extends Pick<ChipProps, 'variant' | 'icon' | 'label' | 'disabled' | 'className' | 'classes'> {
  /** @default 'default' */
  color: 'default' | 'primary' | 'success' | 'error' | 'warning';
  /**
   * Override the default close icon element.
   * Shown only if `onClose` is set.
   */
  closeIcon?: ReactElement;
}

const getPadding = ({
  theme,
  onDelete,
  icon,
}: {
  theme: Theme;
  onDelete?: React.EventHandler<any>;
  icon?: React.ReactElement;
}) => {
  if (onDelete && icon) return theme.spacing(0.5, 2.5);
  if (icon) return theme.spacing(0.5, 3, 0.5, 2.5);
  if (onDelete) return theme.spacing(0.5, 2.5, 0.5, 3);
  return theme.spacing(0.5, 2.5);
};

export const Chip = styled(MuiChip)<DistributiveOmit<Props, 'color'> & Pick<Props, 'color'>>(
  ({ theme, color, onDelete, icon }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(3),
    padding: getPadding({ theme, onDelete, icon }),
    width: 'fit-content',
    [`&.${chipClasses.filled}`]: {
      backgroundColor: color === 'default' ? theme.palette.grey[200] : theme.palette[color][500],
      color: color === 'default' ? theme.palette.grey[800] : null,
    },
    [`&.${chipClasses.outlined}`]: {
      backgroundColor: color === 'default' ? theme.palette.grey[100] : theme.palette[color][50],
      color: color === 'default' ? theme.palette.grey[500] : theme.palette[color][500],
      border: `1px solid ${color === 'default' ? theme.palette.grey[500] : theme.palette[color][500]}`,
    },
    [`& .${chipClasses.icon}`]: {
      fontSize: theme.typography.pxToRem(20),
      margin: 0,
    },
    [`& .${svgIconClasses.root}`]: {
      fontSize: theme.typography.pxToRem(20),
      margin: 0,
      color: 'inherit',
    },
    [`& .${chipClasses.label}`]: {
      padding: 0,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      fontWeight: 500,
    },
  })
);
