import { ElementType, forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../components/Button';
import { buttonClasses } from '../../../components/Button/styles';
import { generateClasses, pick } from '../../../utils/helpers';

export const commentComposerClasses = {
  ...pick(buttonClasses, 'focusVisible'),
  // FIXME: pick richTextEditor and richTextEditorBody from classes of richTextField
  ...generateClasses('CommentComposer', [
    'richTextEditor',
    'richTextEditorBody',
    'send',
    'actions',
    'action',
  ]),
};

export const Root = styled('form')(({ theme }) => ({
  position: 'relative', // used to create a new stacking-context
  backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  boxShadow: theme.acadlyShadows.lg,
  padding: theme.spacing(5),
  display: 'grid',
  gridTemplateAreas: '"rich-text send" "actions actions"',
  gridTemplateColumns: '1fr auto',
  justifyItems: 'flex-start',
  alignItems: 'flex-start',
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(4),
  [`& .${commentComposerClasses.richTextEditor}`]: {
    width: '100%',
    gridArea: 'rich-text',
    '&:hover': {
      [`& .${commentComposerClasses.richTextEditorBody}`]: {
        boxShadow: 'none',
      },
    },
    '&:focus-within': {
      [`& .${commentComposerClasses.richTextEditorBody}`]: {
        boxShadow: 'none',
      },
      '&:hover': {
        [`& .${commentComposerClasses.richTextEditorBody}`]: {
          boxShadow: 'none',
        },
      },
    },
    [`& .${commentComposerClasses.richTextEditorBody}`]: {
      outline: 'none',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 8,
      backgroundColor: theme.palette.grey[50],
      boxShadow: 'none',
      overflow: 'auto',
      maxHeight: 98,
      wordBreak: 'break-word',
      padding: theme.spacing(3, 4),
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
      },
    },
  },
  [`&.${commentComposerClasses.send}`]: {
    gridArea: 'send',
  },
  [`&.${commentComposerClasses.actions}`]: {
    gridArea: 'actions',
  },
}));

export const Action = styled(
  forwardRef(<C extends ElementType<any> = 'button'>(props: ButtonProps<C>, ref: Ref<HTMLButtonElement>) => (
    <Button
      variant="text"
      size="small"
      edge="start"
      {...props}
      ref={ref}
      className={clsx(commentComposerClasses.action, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${commentComposerClasses.action}`]: {
    color: theme.palette.grey[400],
    '&:hover': {
      color: theme.palette.primary[500],
    },
    [`&.${commentComposerClasses.focusVisible}`]: {
      color: theme.palette.primary[700],
    },
  },
}));
