import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { ActivityUpdatedEvent } from '../courses/pusher-events';
import { API } from '../shared/api-responses';
import { ResourcePublishedPayload } from './pusher-events';
import {
  CreateResourceSuccessPayload,
  DeleteResourceSuccessPayload,
  EditPublishedResourcePayload,
  EditPublishedResourceSuccessPayload,
  EditResourceSuccessPayload,
  FetchAllResourcesSuccessPayload,
  FetchResourceAnalyticsSuccessPayload,
  IncrementResourceViewsSuccessPayload,
  PublishResourcePayload,
  PublishResourceSuccessPayload,
  ResourceId,
} from './types';

export const openResource = createAction<ResourceId>('resources/open');
export const closeResource = createAction<ResourceId>('resources/close');

export const fetchAllResources = createRequestAction<void, FetchAllResourcesSuccessPayload>(
  'resources/fetch/all'
);

export const createResource = createRequestAction<API.CreateResourceRequest, CreateResourceSuccessPayload>(
  'resources/create'
);

export const editResource = createRequestAction<API.EditResourceRequest, EditResourceSuccessPayload>(
  'resources/edit'
);

export const resourceEdited = createAction<ActivityUpdatedEvent>('resources/edited');

export const deleteResource = createRequestAction<API.DeleteResourceRequest, DeleteResourceSuccessPayload>(
  'resources/delete'
);

export const publishResource = createRequestAction<PublishResourcePayload, PublishResourceSuccessPayload>(
  'resources/publish'
);

export const resourcePublished = createAction<ResourcePublishedPayload>('resources/published');

export const editPublishedResource = createRequestAction<
  EditPublishedResourcePayload,
  EditPublishedResourceSuccessPayload
>('resources/published/edit');

export const incrementResourceViews = createRequestAction<ResourceId, IncrementResourceViewsSuccessPayload>(
  'resources/views/increment'
);

export const fetchResourceAnalytics = createRequestAction<ResourceId, FetchResourceAnalyticsSuccessPayload>(
  'resources/analytics/fetch'
);
