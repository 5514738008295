import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const ChevronLeftIcon = ({ variant = 'outlined', ...props }: Props) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.36 17C13.2253 16.9995 13.0921 16.9718 12.9684 16.9185C12.8446 16.8652 12.7329 16.7875 12.64 16.69L8.78 12.69C8.59677 12.5031 8.49414 12.2518 8.49414 11.99C8.49414 11.7282 8.59677 11.4769 8.78 11.29L12.78 7.29C12.8732 7.19676 12.9839 7.1228 13.1057 7.07234C13.2276 7.02188 13.3581 6.99591 13.49 6.99591C13.6219 6.99591 13.7524 7.02188 13.8742 7.07234C13.9961 7.1228 14.1068 7.19676 14.2 7.29C14.2932 7.38324 14.3672 7.49393 14.4177 7.61575C14.4681 7.73758 14.4941 7.86814 14.4941 8C14.4941 8.13186 14.4681 8.26243 14.4177 8.38425C14.3672 8.50607 14.2932 8.61676 14.2 8.71L10.9 12L14.08 15.3C14.2662 15.4874 14.3708 15.7408 14.3708 16.005C14.3708 16.2692 14.2662 16.5226 14.08 16.71C13.9854 16.8039 13.8729 16.8779 13.7493 16.9277C13.6256 16.9775 13.4933 17.0021 13.36 17Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ChevronLeftIcon;
