import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorOutline as QueriesIcon } from '@mui/icons-material';

import Tag from '../../components/Tag/index';
import Tooltip from '../../components/Tooltip';
import { i18nNS } from '../../i18n';
import ActivityIcon from '../../icons/ActivityIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import MessageCircleIcon from '../../icons/MessageCircleIcon';
import { CourseRole } from '../../types';
import Status from './Status';
import {
  AdminAvatar,
  AdminName,
  Content,
  CourseCode,
  CourseTitle,
  Dates,
  Footer,
  Header,
  Props,
  Role,
  Root,
  Stack,
  StyledNowPlayingIcon,
  StyledZoomUsIcon,
} from './styles';
import useCourseWidgetVM from './vm';

const CourseWidget = <C extends ElementType<any>>({
  className,
  course,
  renderDates,
  forceRenderDates,
  ...restProps
}: Props<C>) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);
  const {
    admin,
    dates,
    isPlayingMedia,
    canSeeQueries,
    queriesCount,
    queriesLabel,
    queriesTagColor,
    canSeeActivities,
    numUnseenActivities,
    canSeeComments,
    numUnseenComments,
  } = useCourseWidgetVM({ course });

  return (
    <Root {...restProps}>
      <Header>
        {isPlayingMedia ? (
          <StyledNowPlayingIcon />
        ) : (
          course.permissions?.canStream && (
            <Tooltip placement="top" title={t('zoom_enabled_course', { ns: i18nNS.COMMON }) || ''}>
              <StyledZoomUsIcon />
            </Tooltip>
          )
        )}
        <CourseCode>{course.code}</CourseCode>
      </Header>
      <Content>
        <CourseTitle>{course.title}</CourseTitle>
        <Stack gap={2.5}>
          <AdminAvatar variant="rounded" avatarId={admin.avatar} />
          <AdminName>{admin.name}</AdminName>
        </Stack>
        {(canSeeQueries || canSeeActivities || canSeeComments) && (
          <Stack gap={2} mt={6} flexWrap="wrap">
            {canSeeQueries && (
              <Status
                Icon={QueriesIcon}
                label={queriesLabel ?? ''}
                count={queriesCount}
                color={queriesTagColor}
              />
            )}
            {canSeeActivities && (
              <Status
                Icon={ActivityIcon}
                label={t('activity', { ns: i18nNS.GLOSSARY })}
                count={numUnseenActivities}
                color="success"
              />
            )}
            {canSeeComments && (
              <Status
                Icon={MessageCircleIcon}
                label={t('messages', { ns: i18nNS.GLOSSARY })}
                count={numUnseenComments}
                color="primary"
              />
            )}
          </Stack>
        )}
      </Content>
      <Footer>
        {course.status.isLive || forceRenderDates ? (
          <>
            <CalendarIcon color="primary" />
            <Dates>{(renderDates?.(dates, course) ?? dates) || '-'}</Dates>
          </>
        ) : (
          <Tag color="warning" label={t('unpublished', { ns: i18nNS.GLOSSARY })} variant="outlined" />
        )}
        <Role
          color={course.myRole === CourseRole.STUDENT ? 'primary' : 'success'}
          label={t(course.myRole, { ns: i18nNS.GLOSSARY })}
          variant="outlined"
        />
      </Footer>
    </Root>
  );
};

export default CourseWidget;
