import { createReducer } from '@reduxjs/toolkit';

import { fetchMyCourses } from '../courses/actions';
import { setUniversity, updateUniversity } from './actions';
import { University } from './types';

const initialState: University = {
  id: '',
  slug: '',
  name: '',
  integratedWith: '',
  hasEnterpriseAccount: false,
};

const universitiesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUniversity, (_state, action) => {
    const university = action.payload;
    return {
      ...university,
      id: university.id ?? university.slug,
      name: university.name ?? university.slug,
      hasEnterpriseAccount: university.hasEnterpriseAccount ?? false,
      integratedWith: university.integratedWith ?? null,
    };
  });

  builder.addCase(updateUniversity, (state, action) => {
    const universities = action.payload;
    return { ...state, ...universities };
  });

  builder.addCase(fetchMyCourses.success, (state, action) => {
    const { universityData } = action.payload;
    state.integratedWith = universityData.integratedWith;
    state.hasEnterpriseAccount = Boolean(universityData.hasEnterpriseAccount);
  });
});

export default universitiesReducer;
