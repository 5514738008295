import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchCommentReactions } from '../../../../../../../../../db/comments/actions';
import { selectComment, selectCommentReactions } from '../../../../../../../../../db/comments/selectors';
import { useRequestDispatch } from '../../../../../../../../../utils/request-actions';

export interface Props {
  commentId: MongoId;
}

const useReactionsVM = ({ commentId }: Props) => {
  const comment = useSelector(selectComment(commentId));

  const totalReactions = useMemo(() => {
    if (!comment?.stats) return 0;
    return comment.stats.likes + comment.stats.thanks;
  }, [comment?.stats]);

  const { likes, thanks } = useSelector(selectCommentReactions(commentId));

  const hasReactions = totalReactions > 0;

  /** fetching reactions */

  const requestDispatch = useRequestDispatch();

  const isUnmounted = useRef(false);
  const [isFetchingReactions, setIsFetchingReactions] = useState(false);

  useEffect(
    function fetchReactions() {
      if (!hasReactions) return;

      setIsFetchingReactions(true);
      requestDispatch(fetchCommentReactions, { commentId }).finally(() => {
        if (isUnmounted.current) return;
        setIsFetchingReactions(false);
      });

      return () => {
        isUnmounted.current = true;
      };
    },
    [commentId, hasReactions, requestDispatch]
  );

  return { isFetchingReactions, totalReactions, likes, thanks };
};

export default useReactionsVM;
