import { Skeleton, Stack } from '@mui/material';

import Typography from '../../../components/Typography';
import { List } from './styles';

const ZoomIntegrationDialogContentSkeleton = () => {
  return (
    <Stack gap={4}>
      <Stack gap={4} alignItems="center">
        <Skeleton variant="rounded" height={110} width={110} />
        <Typography variant="h5Bold" textAlign="center">
          <Skeleton variant="text" width={300} />
        </Typography>
      </Stack>
      <Stack gap={2}>
        <Typography variant="h6Bold">
          <Skeleton variant="text" width={300} />
        </Typography>
        <List>
          <li>
            <Typography variant="paragraphRegular">
              <Skeleton variant="text" width="70%" />
            </Typography>
          </li>
          <li>
            <Typography variant="paragraphRegular">
              <Skeleton variant="text" width="70%" />
            </Typography>
          </li>
        </List>
      </Stack>
      <Stack gap={2}>
        <Typography variant="h6Bold">
          <Skeleton variant="text" width={300} />
        </Typography>
        <List>
          <li>
            <Typography variant="paragraphRegular">
              <Skeleton variant="text" width="85%" />
            </Typography>
          </li>
          <li>
            <Typography variant="paragraphRegular">
              <Skeleton variant="text" width="85%" />
            </Typography>
          </li>
          <li>
            <Typography variant="paragraphRegular">
              <Skeleton variant="text" width="85%" />
            </Typography>
          </li>
        </List>
        <Stack direction="row" justifyContent="center" pt={4}>
          <Skeleton variant="rounded" width={200} height={40} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ZoomIntegrationDialogContentSkeleton;
