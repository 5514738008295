import { Stack } from '@mui/material';
import { Skeleton } from '@mui/material';

import TagSkeleton from '../../components/Tag/Skeleton';
import UserAvatarSkeleton from '../../components/UserAvatar/Skeleton';
import { AdminName, Content, CourseCode, CourseTitle, Dates, Footer, Header, Root } from './styles';

interface Props {
  className?: string;
}

const CourseWidgetSkeleton = ({ className }: Props) => {
  return (
    <Root className={className}>
      <Header>
        <CourseCode>
          <Skeleton variant="text" width={100} />
        </CourseCode>
      </Header>
      <Content>
        <CourseTitle>
          <Skeleton variant="text" width={200} />
        </CourseTitle>
        <Stack direction="row" alignItems="center" gap={2.5}>
          <UserAvatarSkeleton height={24} width={24} />
          <AdminName>
            <Skeleton variant="text" width={100} />
          </AdminName>
        </Stack>
      </Content>
      <Footer>
        <Skeleton variant="rounded" height={20} width={20} />
        <Dates sx={{ flex: 1 }}>
          <Skeleton variant="text" width={150} />
        </Dates>
        <TagSkeleton />
      </Footer>
    </Root>
  );
};

export default CourseWidgetSkeleton;
