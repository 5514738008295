import { call, put, select } from 'redux-saga/effects';

import { selectAuthSession } from '../../auth/store/selectors';
import Logger from '../../utils/logger';
import { debounce } from '../../utils/saga-helpers';
import { fetchAvatars, setAvatars } from './actions';
import { fetchAvatars as fetchAvatarsAPI } from './apis';

const log = Logger.create('db/avatars');

function* fetchAvatarsWorker(...actions: ReturnType<typeof fetchAvatars>[]) {
  try {
    const session: YieldSelectorType<typeof selectAuthSession> = yield select(selectAuthSession);

    if (!session) {
      log.warn(`S3 bucket URL is not set`);
      return;
    }

    const ids = new Set<MongoId>(actions.flatMap((a) => a.payload));
    const avatarIds: MongoId[] = Array.from(ids);
    const avatars: YieldCallType<typeof fetchAvatarsAPI> = yield call(
      fetchAvatarsAPI,
      session.s3BucketURL,
      avatarIds
    );

    yield put(setAvatars(avatars));
  } catch (error) {
    log.error(error);
  }
}

export default function* rootAvatarsSaga() {
  yield debounce(500, fetchAvatars, fetchAvatarsWorker);
}
