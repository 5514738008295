import { MouseEvent, MouseEventHandler } from 'react';

import List from '../../../components/List';
import ListItem from '../../../components/ListItem';
import { FaqQuestion, FaqSection as FaqSectionType } from '../types';
import { Accordion, AccordionDetails, AccordionSummary, Question, Title } from './styles';
import { useFaqSectionVM } from './vm';

interface Props extends FaqSectionType {
  isExpanded?: boolean;
  onToggleExpand?: MouseEventHandler;
  onSelectQuestion?: (question: FaqQuestion) => void;
}

const FaqSection = ({
  isExpanded,
  questions: _questions,
  title,
  onSelectQuestion,
  onToggleExpand,
}: Props) => {
  const { headerId, contentId, questions } = useFaqSectionVM({ questions: _questions });

  const handleQuestionClick = (question: FaqQuestion) => (e: MouseEvent) => onSelectQuestion?.(question);
  return (
    <Accordion expanded={isExpanded} onClick={onToggleExpand}>
      <AccordionSummary aria-controls={contentId} id={headerId} tabIndex={0}>
        <Title variant="paragraphBold">{title}</Title>
      </AccordionSummary>
      <AccordionDetails id={contentId}>
        <List
          type="accessible"
          getActiveItemId={(focusIndex) =>
            questions[focusIndex] ? `question-${questions[focusIndex].id}` : undefined
          }
          numItems={questions.length}
        >
          {(focusIndex) =>
            questions.map((question, index) => (
              <ListItem key={question.id} isFocused={focusIndex === index}>
                <Question
                  id={`question-${question.id}`}
                  role="option"
                  tabIndex={-1}
                  onClick={handleQuestionClick(question)}
                >
                  {question.title}
                </Question>
              </ListItem>
            ))
          }
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqSection;
