import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const SmartphoneIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM12 18C11.7033 18 11.4133 17.912 11.1666 17.7472C10.92 17.5824 10.7277 17.3481 10.6142 17.074C10.5006 16.7999 10.4709 16.4983 10.5288 16.2074C10.5867 15.9164 10.7296 15.6491 10.9393 15.4393C11.1491 15.2296 11.4164 15.0867 11.7074 15.0288C11.9983 14.9709 12.2999 15.0006 12.574 15.1142C12.8481 15.2277 13.0824 15.42 13.2472 15.6666C13.412 15.9133 13.5 16.2033 13.5 16.5C13.5 16.8978 13.342 17.2794 13.0607 17.5607C12.7794 17.842 12.3978 18 12 18ZM14.5 8H9.5C9.23478 8 8.98043 7.89464 8.79289 7.70711C8.60536 7.51957 8.5 7.26522 8.5 7C8.5 6.73478 8.60536 6.48043 8.79289 6.29289C8.98043 6.10536 9.23478 6 9.5 6H14.5C14.7652 6 15.0196 6.10536 15.2071 6.29289C15.3946 6.48043 15.5 6.73478 15.5 7C15.5 7.26522 15.3946 7.51957 15.2071 7.70711C15.0196 7.89464 14.7652 8 14.5 8Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V5C20 4.20435 19.6839 3.44129 19.1213 2.87868C18.5587 2.31607 17.7956 2 17 2ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H17C17.2652 4 17.5196 4.10536 17.7071 4.29289C17.8946 4.48043 18 4.73478 18 5V19Z"
        fill="currentColor"
      />
      <path
        d="M12 18C12.8284 18 13.5 17.3284 13.5 16.5C13.5 15.6716 12.8284 15 12 15C11.1716 15 10.5 15.6716 10.5 16.5C10.5 17.3284 11.1716 18 12 18Z"
        fill="currentColor"
      />
      <path
        d="M14.5 6H9.5C9.23478 6 8.98043 6.10536 8.79289 6.29289C8.60536 6.48043 8.5 6.73478 8.5 7C8.5 7.26522 8.60536 7.51957 8.79289 7.70711C8.98043 7.89464 9.23478 8 9.5 8H14.5C14.7652 8 15.0196 7.89464 15.2071 7.70711C15.3946 7.51957 15.5 7.26522 15.5 7C15.5 6.73478 15.3946 6.48043 15.2071 6.29289C15.0196 6.10536 14.7652 6 14.5 6Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SmartphoneIcon;
