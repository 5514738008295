import { Paper, styled, Theme } from '@mui/material';

import { ReactComponent as AcadlyLogo } from '../../assets/acadly-logo.svg';
import { ReactComponent as AcadlyLogoLight } from '../../assets/acadly-logo-light.svg';
import authBg from '../../assets/auth-bg.png';
import UserCell from '../../components/UserCell/index';
import { generateClasses } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';

export const layoutClasses = {
  ...generateClasses('Layout', [
    'root',
    'paper',
    'acadlyLogo',
    'content',
    'contentHeader',
    'title',
    'subtitle',
    'userCell',
    'avatar',
  ]),
};

const PAPER_WIDTH = 460;
const PAPER_HEIGHT = 420;

export const Root = styled('main')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  height: '100vh',
  padding: theme.spacing(4, 0),
  minheight: PAPER_HEIGHT,
  overflowY: 'auto',
  backgroundColor: theme.palette.grey[800],
  backgroundImage: `url(${authBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom -100px left 0',
  backgroundSize: '100%',

  [forMobile(theme)]: {
    padding: 0,
  },
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  width: PAPER_WIDTH,
  minHeight: PAPER_HEIGHT,
  padding: theme.spacing(10),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),

  [forMobile(theme)]: {
    width: '100%',
    height: '100vh',
    padding: theme.spacing(8),
    overflowY: 'auto',
    border: 'none',
    borderRadius: 0,
    gap: theme.spacing(8),
  },
}));

const logoStyles = (theme: Theme) => ({
  flexShrink: 0,
  margin: theme.spacing(0, 'auto'),
});

export const Logo = styled(AcadlyLogo)(({ theme }) => ({
  ...logoStyles(theme),
}));

export const LogoLight = styled(AcadlyLogoLight)(({ theme }) => ({
  ...logoStyles(theme),
}));

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary[50],
  borderRadius: 8,
  [`& .${layoutClasses.avatar}`]: {
    borderColor: theme.palette.primary[300],
  },

  [forMobile(theme)]: {
    backgroundColor: 'transparent',
    padding: 0,
    borderRadius: 0,
    [`& .${layoutClasses.avatar}`]: {
      borderColor: theme.palette.grey[200],
    },
  },
}));
