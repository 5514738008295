import { useTranslation } from 'react-i18next';

import { SortBy } from '../../../db/shared/types';
import { i18nNS } from '../../../i18n';
import CloseIcon from '../../../icons/CloseIcon';
import Alert from '../../Alert';
import Header from '../../Header';
import RadioGroup from '../../HookFormControls/RadioGroup';
import { Checkbox, RadioOption } from './styles';
import useSortDialogVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  open: boolean;
  onClose: () => void;
}

const SortDialog = ({ open, onClose, ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMON]);

  const { formMethods, onSubmit } = useSortDialogVM({ ...vmProps, onClose });
  const { handleSubmit, control, watch } = formMethods;
  const { sortBy } = vmProps;

  const setAsDefault = watch('setAsDefault');

  return (
    <Alert open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <Alert.Header>
        <Header
          right={{
            size: 'large',
            startIcon: <CloseIcon aria-hidden />,
            onClick: onClose,
            'aria-label': t('click_to_cancel_sort', { ns: i18nNS.COMMON }),
          }}
        >
          {t('sort_order', { ns: i18nNS.COMMON })}
        </Header>
      </Alert.Header>
      <Alert.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RadioGroup control={control} name="sortBy">
            <RadioOption
              value={SortBy.ASC_FIRST_NAME}
              aria-label={t('click_to_sort_firstname_in_ascending_order', { ns: i18nNS.COMMON })}
              autoFocus={SortBy.ASC_FIRST_NAME === sortBy}
            >
              {t('first_name_ascending', { ns: i18nNS.COMMON })}
            </RadioOption>
            <RadioOption
              value={SortBy.DESC_FIRST_NAME}
              aria-label={t('click_to_sort_firstname_in_descending_order', { ns: i18nNS.COMMON })}
              autoFocus={SortBy.DESC_FIRST_NAME === sortBy}
            >
              {t('first_name_descending', { ns: i18nNS.COMMON })}
            </RadioOption>
            <RadioOption
              value={SortBy.ASC_LAST_NAME}
              aria-label={t('click_to_sort_lastname_in_ascending_order', { ns: i18nNS.COMMON })}
              autoFocus={SortBy.ASC_LAST_NAME === sortBy}
            >
              {t('last_name_ascending', { ns: i18nNS.COMMON })}
            </RadioOption>
            <RadioOption
              value={SortBy.DESC_LAST_NAME}
              aria-label={t('click_to_sort_lastname_in_descending_order', { ns: i18nNS.COMMON })}
              autoFocus={SortBy.DESC_LAST_NAME === sortBy}
            >
              {t('last_name_descending', { ns: i18nNS.COMMON })}
            </RadioOption>
          </RadioGroup>
        </form>
      </Alert.Body>
      <Alert.Footer>
        <Checkbox
          control={control}
          name="setAsDefault"
          label={t('set_as_default', { ns: i18nNS.COMMON })}
          aria-label={t(
            setAsDefault
              ? 'click_to_unselect_selected_sorting_as_default'
              : 'click_to_select_selected_sorting_as_default',
            { ns: i18nNS.COMMON }
          )}
        />
        <Alert.Action
          onClick={handleSubmit(onSubmit)}
          aria-label={t('click_to_apply_sort', { ns: i18nNS.COMMON })}
        >
          {t('okay', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default SortDialog;
