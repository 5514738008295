import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { setSortPreference } from '../../../db/app/actions';
import { SortBy } from '../../../db/shared/types';
import { useRequestDispatch } from '../../../utils/request-actions';
import { FormValues } from './types';

export interface Props {
  onClose: () => void;
  sortBy: SortBy;
  onChangeSortBy: (sortBy: SortBy) => void;
}

const useSortDialogVM = ({ onClose, sortBy, onChangeSortBy }: Props) => {
  const requestDispatch = useRequestDispatch();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      sortBy: SortBy.ASC_FIRST_NAME,
      setAsDefault: false,
    },
    mode: 'onSubmit',
  });

  useEffect(
    function setInitialValues() {
      const { setValue } = formMethods;
      setValue('sortBy', sortBy);
    },
    [formMethods, sortBy]
  );

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { sortBy, setAsDefault } = data;

    onChangeSortBy(sortBy);

    if (setAsDefault) {
      await requestDispatch(setSortPreference, { sortBy });
    }

    onClose();
  };

  return {
    formMethods,
    onSubmit,
  };
};

export default useSortDialogVM;
