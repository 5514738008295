import { Trans, useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import { i18nNS } from '../../../i18n';
import { ANDROID_APP_DOWNLOAD_LINK, IOS_APP_DOWNLOAD_LINK } from '../../constants';
import { StyledTypography, UnorderedList } from './styles';
import useAttendanceScheduleToStartVM from './vm';

const AttendanceScheduleToStartDialog = () => {
  const { t } = useTranslation();

  const {
    isAttendanceScheduleToStartDialogOpen,
    handleCloseAttendanceScheduleToStartDialog,
    handleGoToClass,
  } = useAttendanceScheduleToStartVM();

  return (
    <Alert open={isAttendanceScheduleToStartDialogOpen}>
      <Alert.Header>{t('attendance_scheduled_to_start', { ns: i18nNS.CLASS })}</Alert.Header>
      <Alert.Body>
        <StyledTypography>
          {t(
            'attendance_process_to_discover_in_person_attendees_will_start_in_the_next_couple_of_minutes_please_ensure',
            { ns: i18nNS.CLASS }
          )}
        </StyledTypography>
        <UnorderedList>
          <li>
            <Trans
              t={t}
              i18nKey="students_have_acadly_s_ios_or_android_apps_not_the_acadly_websie_open_on_their_mobile_devices"
            >
              Students have{' '}
              <a
                href={IOS_APP_DOWNLOAD_LINK}
                target="_blank"
                rel="noreferrer"
                aria-label={t('click_to_download_android_app', { ns: i18nNS.CLASS })}
              >
                Acadly\'s iOS{' '}
              </a>
              or{' '}
              <a
                href={ANDROID_APP_DOWNLOAD_LINK}
                target="_blank"
                rel="noreferrer"
                aria-label={t('click_to_download_android_app', { ns: i18nNS.CLASS })}
              >
                Android app{' '}
              </a>
              (NOT the Acadly website) open on their mobile devices
            </Trans>
          </li>
          <li>{t('students_mobile_devices_are_connected_to_the_internet', { ns: i18nNS.CLASS })}</li>
          <li>{t('students_have_granted_acadly_app_the_necessary_permissions', { ns: i18nNS.CLASS })}</li>
        </UnorderedList>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action variant="text" color="warning" onClick={handleCloseAttendanceScheduleToStartDialog}>
          {t('dismiss', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action onClick={handleGoToClass}>{t('go_to_class', { ns: i18nNS.CLASS })}</Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default AttendanceScheduleToStartDialog;
