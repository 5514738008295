import { CSSProperties, MouseEvent } from 'react';

import { RenderElementProps } from 'slate-react';

import ImagePreviewDialog from '../../../../components/ImagePreviewDialog';
import { ReactComponent as DeleteIcon } from '../delete.svg';
import { ImageUploadStatus } from '../types';
import { Button, Image, Loader, Overlay, Wrapper } from './styles';
import useImageElementVM, { VMProps } from './vm';

type Props = RenderElementProps &
  VMProps & {
    style?: CSSProperties;
  };

const ImageElement = ({ attributes, children, element, style }: Props) => {
  const {
    isFocused,
    isSelected,
    removeImage,
    isImagePreviewDialogOpen,
    handleImageClick,
    handleCloseImagePreviewDialog,
  } = useImageElementVM({ element });

  const handleDelete = (e: MouseEvent) => {
    e.preventDefault();
    removeImage();
  };

  return (
    <div {...attributes} style={style} contentEditable={false}>
      {children}
      <Wrapper>
        {element.uploadStatus !== ImageUploadStatus.UPLOADED && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
        <Image isActive={isSelected && isFocused} src={element.url} alt="" onClick={handleImageClick} />
        {!(isSelected || isFocused) && (
          <ImagePreviewDialog
            open={isImagePreviewDialogOpen}
            onClose={handleCloseImagePreviewDialog}
            src={element.url}
          />
        )}
        <Button isVisible={isSelected && isFocused} onMouseDown={handleDelete}>
          <DeleteIcon width={24} height={24} fill="currentColor" />
        </Button>
      </Wrapper>
    </div>
  );
};

export default ImageElement;
