import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useRequestDispatch } from '../../../utils/request-actions';
import { changePassword } from '../../store/actions';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useChangePasswordDrawerVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setValue, reset } = formMethods;

  const onCloseDrawer = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    await requestDispatch(changePassword, {
      oldPass: data.currentPassword,
      newPass: data.newPassword,
    });
    onCloseDrawer();
  };

  useEffect(
    function setInitialValues() {
      if (!open) return;
      setValue('currentPassword', '');
      setValue('newPassword', '');
      setValue('confirmNewPassword', '');
    },
    [open, setValue]
  );

  return {
    onCloseDrawer,
    formMethods,
    onSubmit,
  };
};

export default useChangePasswordDrawerVM;
