import { call, put, select, takeLatest } from 'redux-saga/effects';

import { takeEveryPusher } from '../../pusher/subscribe';
import Logger from '../../utils/logger';
import { selectCourseUser } from '../courses/selectors';
import { selectDiscussion } from '../discussions/selectors';
import { CommentContext, CourseRole, CourseTeamUser } from '../shared/types';
import {
  commentCreated,
  commentDeleted,
  commentMarked,
  commentRated,
  createComment,
  deleteComment,
  exportAllCommentsStats,
  fetchCommentReactions,
  fetchComments,
  markComment,
  markCommentsAsSeen,
  rateComment,
  subscribeToComments,
} from './actions';
import {
  createComment as createCommentAPI,
  deleteComment as deleteCommentAPI,
  exportAllCommentsStats as exportAllCommentsStatsAPI,
  getCommentReactions,
  getComments,
  markComment as markCommentAPI,
  markCommentsAsSeen as markCommentsAsSeenAPI,
  rateComment as rateCommentAPI,
  subscribeToComments as subscribeToCommentsAPI,
} from './api';
import { commentCreatedEvent, commentDeletedEvent, commentMarkedEvent } from './pusher-events';
import {
  selectComment,
  selectCommentContext,
  selectTotalComments,
  selectUnseenCommentsCount,
} from './selectors';
import { MarkCommentsAsSeenSuccessPayload } from './types';

const log = Logger.create('db/comments');

function* fetchCommentsWorker(action: ReturnType<typeof fetchComments.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const totalComments: YieldSelectorType<typeof selectTotalComments> = yield select(
      selectTotalComments(action.payload.context)
    );

    const unseenCommentsCount: YieldSelectorType<typeof selectUnseenCommentsCount> = yield select(
      selectUnseenCommentsCount(action.payload.context)
    );

    if (totalComments <= 0) {
      // there are no comments to fetch, so bypassing api request
      yield put(
        fetchComments.success(requestId, {
          ...action.payload,
          commentsData: [],
          currentUser,
          userData: {},
          userSubscribed: 0,
          unseenCommentsCount: 0,
        })
      );
      return;
    }

    const response: YieldCallType<typeof getComments> = yield call(getComments, action.payload);

    yield put(
      fetchComments.success(requestId, {
        ...response,
        ...action.payload,
        currentUser,
        unseenCommentsCount,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchComments.failure(requestId));
  }
}

function* createCommentWorker(action: ReturnType<typeof createComment.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const context: YieldSelectorType<typeof selectCommentContext> = yield select(selectCommentContext);
    if (!context) return;

    const response: YieldCallType<typeof createCommentAPI> = yield call(createCommentAPI, {
      ...action.payload,
      context,
    });

    const comment: YieldSelectorType<typeof selectComment> = yield select(selectComment);

    yield put(
      createComment.success(requestId, {
        context,
        comment: response,
        currentUser,
        isAlreadyAdded: Boolean(comment),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchCommentReactions.failure(requestId));
  }
}

function* commentCreatedEventWorker(event: ReturnType<typeof commentCreatedEvent>) {
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    // ignore this pusher event
    if (event.payload.shouldTeamIgnore === 1) return;

    let canSeeComments = true;

    if (currentUser.role === CourseRole.STUDENT && event.payload.context === CommentContext.DISCUSSION) {
      const discussion: YieldSelectorType<typeof selectDiscussion> = yield select(
        selectDiscussion(event.payload.contextId)
      );
      if (discussion?.preferences.submitFirst) {
        canSeeComments = discussion.hasParticipated;
      }
    }

    // forward to redux
    yield put(commentCreated({ ...event.payload, currentUser, canSeeComments }));
  } catch (error) {
    log.error(error);
  }
}

function* markCommentsAsSeenWorker(action: ReturnType<typeof markCommentsAsSeen.request>) {
  const { requestId } = action.meta;
  try {
    const context: YieldSelectorType<typeof selectCommentContext> = yield select(selectCommentContext);
    if (!context) return;

    const unseenCommentsCount: YieldSelectorType<typeof selectUnseenCommentsCount> = yield select(
      selectUnseenCommentsCount(context)
    );

    const successPayload: MarkCommentsAsSeenSuccessPayload = {
      context,
      seen: unseenCommentsCount,
    };

    yield call(markCommentsAsSeenAPI, successPayload);
    yield put(markCommentsAsSeen.success(requestId, successPayload));
  } catch (error) {
    log.error(error);
    yield put(markCommentsAsSeen.failure(requestId));
  }
}

function* fetchCommentReactionsWorker(action: ReturnType<typeof fetchCommentReactions.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getCommentReactions> = yield call(
      getCommentReactions,
      action.payload
    );

    yield put(
      fetchCommentReactions.success(requestId, {
        ...response,
        ...action.payload,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchCommentReactions.failure(requestId));
  }
}

function* deleteCommentWorker(action: ReturnType<typeof deleteComment.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    yield call(deleteCommentAPI, action.payload);

    yield put(
      deleteComment.success(requestId, {
        ...action.payload,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(deleteComment.failure(requestId));
  }
}

function* commentDeletedEventWorker(event: ReturnType<typeof commentDeletedEvent>) {
  yield put(commentDeleted(event.payload));
}

function* markCommentWorker(action: ReturnType<typeof markComment.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const context: YieldSelectorType<typeof selectCommentContext> = yield select(selectCommentContext);
    if (!context) return;

    yield call(markCommentAPI, action.payload, context);
    yield put(markComment.success(requestId, { ...action.payload, currentUser }));
  } catch (error) {
    log.error(error);
    yield put(markComment.failure(requestId));
  }
}

function* commentMarkedEventWorker(event: ReturnType<typeof commentMarkedEvent>) {
  const { courseId } = event.payload;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser(courseId));
  if (!currentUser) return;

  if (event.payload.marked === 'awarded') {
    yield put(commentRated({ ...event.payload, currentUser }));
  } else {
    yield put(commentMarked({ ...event.payload, currentUser }));
  }
}

function* rateCommentWorker(action: ReturnType<typeof rateComment.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser || currentUser.role === CourseRole.STUDENT) return;
    yield call(rateCommentAPI, action.payload);
    yield put(
      rateComment.success(requestId, {
        ...action.payload,
        currentUser: currentUser as CourseTeamUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(rateComment.failure(requestId));
  }
}

function* exportAllCommentsStatsWorker(action: ReturnType<typeof exportAllCommentsStats.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof exportAllCommentsStatsAPI> = yield call(
      exportAllCommentsStatsAPI,
      action.payload
    );
    yield put(exportAllCommentsStats.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(exportAllCommentsStats.failure(requestId));
  }
}

function* subscribeToCommentsWorker(action: ReturnType<typeof subscribeToComments.request>) {
  const { requestId } = action.meta;
  try {
    yield call(subscribeToCommentsAPI, action.payload);
    yield put(subscribeToComments.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(subscribeToComments.failure(requestId, action.payload));
  }
}

export default function* rootCommentsSaga() {
  try {
    yield takeLatest(fetchComments.request, fetchCommentsWorker);

    yield takeLatest(createComment.request, createCommentWorker);
    yield takeEveryPusher(commentCreatedEvent, commentCreatedEventWorker);

    yield takeLatest(markCommentsAsSeen.request, markCommentsAsSeenWorker);
    yield takeLatest(fetchCommentReactions.request, fetchCommentReactionsWorker);

    yield takeLatest(deleteComment.request, deleteCommentWorker);
    yield takeEveryPusher(commentDeletedEvent, commentDeletedEventWorker);

    yield takeLatest(markComment.request, markCommentWorker);
    yield takeEveryPusher(commentMarkedEvent, commentMarkedEventWorker);

    yield takeLatest(rateComment.request, rateCommentWorker);

    yield takeLatest(exportAllCommentsStats.request, exportAllCommentsStatsWorker);
    yield takeLatest(subscribeToComments.request, subscribeToCommentsWorker);
  } catch (error) {
    log.error(error);
  }
}
