import { styled } from '@mui/material';

import { forMobile } from '../../../utils/media-queries';
import AcadlyRadioOption from '../../FormControls/RadioGroup/RadioOption';
import AcadlyCheckbox from '../../HookFormControls/Checkbox';

export const RadioOption = styled(AcadlyRadioOption)(({ theme }) => ({
  margin: 0,
}));

export const Checkbox = styled(AcadlyCheckbox)(({ theme }) => ({
  margin: 0,
  marginRight: 'auto',
  [forMobile(theme)]: {
    marginLeft: theme.spacing(-2),
  },
})) as typeof AcadlyCheckbox;
