import { Slider, sliderClasses as MuiSliderClasses, SliderProps, styled } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const sliderClasses = {
  ...MuiSliderClasses,
  ...generateClasses('Slider', ['colorPrimary', 'colorSuccess', 'colorError', 'colorWarning']),
};

export interface Props extends DistributiveOmit<SliderProps, 'color'> {
  /** @default default */
  color?: 'default' | 'primary' | 'success' | 'error' | 'warning';
}

export const StyledSlider = styled(Slider)(({ theme }) => ({
  [`& .${sliderClasses.rail}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${sliderClasses.track}`]: {
    border: 'none',
    backgroundColor: theme.palette.grey[600],
  },
  [`& .${sliderClasses.thumb}`]: {
    border: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.background.paper,
    '&::before': {
      boxShadow: 'none',
    },
  },
  [`&.${sliderClasses.colorPrimary}`]: {
    [`& .${sliderClasses.track}`]: {
      backgroundColor: theme.palette.primary[500],
    },
  },
  [`&.${sliderClasses.colorSuccess}`]: {
    [`& .${sliderClasses.track}`]: {
      backgroundColor: theme.palette.success[500],
    },
  },
  [`&.${sliderClasses.colorError}`]: {
    [`& .${sliderClasses.track}`]: {
      backgroundColor: theme.palette.error[500],
    },
  },
  [`&.${sliderClasses.colorWarning}`]: {
    [`& .${sliderClasses.track}`]: {
      backgroundColor: theme.palette.warning[500],
    },
  },
}));
