import { call, put, takeLatest } from 'redux-saga/effects';

import { takeEveryPusher } from '../../pusher/subscribe';
import Logger from '../../utils/logger';
import { announcementCreated, createAnnouncement, markAnnouncementAsRead } from './actions';
import {
  createAnnouncement as createAnnouncementAPI,
  markAnnouncementAsRead as markAnnouncementAsReadAPI,
} from './api';
import { announcementCreatedEvent } from './pusher-events';

const log = Logger.create('db/announcements');

function* createAnnouncementWorker(action: ReturnType<typeof createAnnouncement.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof createAnnouncementAPI> = yield call(
      createAnnouncementAPI,
      action.payload
    );
    yield put(createAnnouncement.success(requestId, { ...response, ...action.payload }));
  } catch (error) {
    log.error(error);
    yield put(createAnnouncement.failure(requestId));
  }
}

function* announcementCreatedWorker(event: ReturnType<typeof announcementCreatedEvent>) {
  yield put(announcementCreated(event.payload));
}

function* markAnnouncementAsReadWorker(action: ReturnType<typeof markAnnouncementAsRead.request>) {
  const { requestId } = action.meta;
  try {
    yield call(markAnnouncementAsReadAPI, action.payload.announcementId);
    yield put(markAnnouncementAsRead.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(markAnnouncementAsRead.failure(requestId));
  }
}

export default function* rootAnnouncementsSaga() {
  try {
    yield takeLatest(createAnnouncement.request, createAnnouncementWorker);
    yield takeEveryPusher(announcementCreatedEvent, announcementCreatedWorker);

    yield takeLatest(markAnnouncementAsRead.request, markAnnouncementAsReadWorker);
  } catch (error) {
    log.error(error);
  }
}
