import { useTranslation } from 'react-i18next';

import { MenuItem, Skeleton, Stack } from '@mui/material';

import Button from '../../../components/Button';
import RadioOption from '../../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../../components/HookFormControls/RadioGroup';
import Select from '../../../components/HookFormControls/Select';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import { COURSE_COUNT_OPTIONS, STUDENT_COUNT_OPTIONS } from '../../constants';
import CreateCourseForm from '../CreateCourseForm';
import useCostCalculator from './vm';

const CostCalculator = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);

  const {
    formMethods,
    closeDrawer,
    isVisible,
    moveToPreviousStep,
    isFetchingCourseCost,
    canSendPricingRequest,
    courseCost,
    handleSendPricingRequest,
    handlePay,
  } = useCostCalculator();

  const { handleSubmit, control } = formMethods;

  return (
    <CreateCourseForm
      isVisible={isVisible}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button type="button" variant="text" startIcon={<ChevronLeftIcon />} onClick={moveToPreviousStep}>
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button type="button" variant="text" onClick={closeDrawer}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            {canSendPricingRequest ? (
              <Button
                type="button"
                variant="contained"
                endIcon={<ChevronRightIcon />}
                onClick={handleSubmit(handleSendPricingRequest)}
              >
                {t('send_pricing_request', { ns: i18nNS.CREATE_COURSE })}
              </Button>
            ) : (
              <Button
                type="button"
                variant="contained"
                endIcon={<ChevronRightIcon />}
                onClick={handleSubmit(handlePay)}
              >
                {t('pay', { ns: i18nNS.GLOSSARY })}
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={6}>
        <Typography variant="h5Bold">{t('cost_calculator', { ns: i18nNS.CREATE_COURSE })}</Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Typography variant="h5Bold">{t('course_type', { ns: i18nNS.CREATE_COURSE })}</Typography>
          <RadioGroup control={control} name="isCreatingProCourse">
            <Stack direction="row" alignItems="center" gap={6}>
              <RadioOption value="false">
                <Typography variant="paragraphBold" component="span">
                  {t('basic', { ns: i18nNS.GLOSSARY })}
                </Typography>
              </RadioOption>
              <RadioOption value="true">
                <Typography variant="paragraphBold" component="span">
                  {t('pro', { ns: i18nNS.GLOSSARY })}
                </Typography>
              </RadioOption>
            </Stack>
          </RadioGroup>
        </Stack>
        <Select
          control={control}
          name="numCourses"
          label={t('number_of_courses_required', { ns: i18nNS.CREATE_COURSE })}
          autoFocus
        >
          {COURSE_COUNT_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        <Select
          control={control}
          name="numStudents"
          label={t('expected_number_of_enrolled_students_across_all_courses', {
            ns: i18nNS.CREATE_COURSE,
          })}
          helperText={t(
            'example_if_you_are_purchasing_N_courses_and_expect_around_M_students_per_course_then_the_total_number_of_students_will_be_R',
            {
              ns: i18nNS.CREATE_COURSE,
              numCourses: 4,
              numStudents: 50,
              range: '150-200',
            }
          )}
        >
          {STUDENT_COUNT_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Typography variant="h5Bold">{t('cost', { ns: i18nNS.GLOSSARY })}</Typography>
          <Typography variant="h5Bold">
            {isFetchingCourseCost ? <Skeleton variant="text" width={80} /> : courseCost}
          </Typography>
        </Stack>
      </Stack>
    </CreateCourseForm>
  );
};

export default CostCalculator;
