import { AriaAttributes, MouseEventHandler, ReactNode } from 'react';

import clsx from 'clsx';

import { Props as UserAvatarProps } from '../UserAvatar/index';
import UserAvatar from '../UserAvatar/index';
import { Action, Content, Root, Subtitle, Title, userCellClasses } from './styles';

const DEFAULT_AVATAR_ID = 'default' as const;

export interface Props extends AriaAttributes {
  id?: string;
  className?: string;
  classes?: {
    root?: string;
    title?: string;
    avatar?: string;
    action?: string;
    subtitle?: string;
    content?: string;
  };
  title: ReactNode;
  /** name is used for displaying initials, name is not directly displayed anywhere */
  name?: string;
  subtitle?: ReactNode;
  action?: ReactNode;
  edge?: 'start' | 'end';
  avatar?: string | UserAvatarProps | typeof DEFAULT_AVATAR_ID;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** @default 'medium' */
  size?: 'large' | 'medium';
  tabIndex?: number;
}

const UserCell = ({
  id,
  classes,
  className,
  onClick,
  avatar,
  name,
  title,
  subtitle,
  action,
  size = 'medium',
  ...buttonProps
}: Props) => {
  return (
    <Root
      id={id}
      {...buttonProps}
      className={clsx(className, userCellClasses.root, classes?.root, {
        [userCellClasses.sizeMedium]: size === 'medium',
        [userCellClasses.sizeLarge]: size === 'large',
      })}
      onClick={onClick}
    >
      {avatar !== undefined && (
        <UserAvatar
          variant="rounded"
          className={clsx(userCellClasses.avatar, classes?.avatar)}
          avatarId={typeof avatar === 'string' ? avatar : avatar.avatarId}
          bucketUrl={typeof avatar === 'string' ? undefined : avatar.bucketUrl}
          name={name || (typeof title === 'string' ? title : undefined)}
        />
      )}
      <Content className={clsx(userCellClasses.content, classes?.content)}>
        {typeof title === 'string' ? (
          <Title className={clsx(userCellClasses.title, classes?.title)} noWrap>
            {title}
          </Title>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Subtitle className={clsx(userCellClasses.subtitle, classes?.subtitle)} noWrap>
            {subtitle}
          </Subtitle>
        ) : (
          subtitle
        )}
      </Content>
      {typeof action === 'string' ? (
        <Action className={clsx(userCellClasses.action, classes?.action)}>{action}</Action>
      ) : (
        action
      )}
    </Root>
  );
};

export default UserCell;
