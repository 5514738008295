import { MouseEventHandler } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import ImageIcon from '../../icons/ImageIcon';
import AcadlyToolBarButton, { Props as AcadlyToolBarButtonProps } from '../../ToolBarButton';
import useImageUploadButtonVM, { Props as VMProps } from './vm';

const imageUploadButtonClasses = generateClasses('ImageUploadButton', [
  'toolbarButton',
  'image',
  'toolbarIcon',
  'imageIcon',
  'label',
]);

const ToolBarButton = styled(AcadlyToolBarButton)(({ theme }) => ({
  position: 'relative',
  [`& .${imageUploadButtonClasses.label}`]: {
    position: 'absolute',
    inset: 0,
  },
}));

export type Props = VMProps &
  AcadlyToolBarButtonProps & {
    /** allow to upload a single image or multiple image at a time, default true */
    multiple?: boolean;
    classes?: Partial<typeof imageUploadButtonClasses>;
    imageUploadButtonId?: string;
    activeItemId?: string;
  };

const ImageUploadButton = ({
  editorRef,
  onBeforeImageUpload,
  multiple = true,
  classes,
  imageUploadButtonId,
  activeItemId,
  ...props
}: Props) => {
  const { selectImageRef, allowedImages, handleImageSelect } = useImageUploadButtonVM({
    editorRef,
    onBeforeImageUpload,
  });

  const handleImageButtonMouseDown: MouseEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  };

  return (
    <ToolBarButton
      {...props}
      onMouseDown={handleImageButtonMouseDown}
      className={clsx(
        imageUploadButtonClasses.toolbarButton,
        classes?.toolbarButton,
        imageUploadButtonClasses.image,
        classes?.image
      )}
      tabIndex={-1}
      aria-selected={imageUploadButtonId === activeItemId}
    >
      <ImageIcon
        fontSize="small"
        className={clsx(
          imageUploadButtonClasses.toolbarIcon,
          classes?.toolbarIcon,
          imageUploadButtonClasses.imageIcon,
          classes?.imageIcon
        )}
      />
      <label className={clsx(imageUploadButtonClasses.label, classes?.label)}>
        <input
          hidden
          id={imageUploadButtonId}
          multiple={multiple}
          ref={selectImageRef}
          accept={allowedImages}
          type="file"
          onChange={handleImageSelect}
        />
      </label>
    </ToolBarButton>
  );
};

export default ImageUploadButton;
