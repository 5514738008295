import { useSelector } from 'react-redux';

import { selectCurrentCourse, selectGroupedWeeklySchedule } from '../../../db/courses/selectors';

export default function useCreateCoursePreviewVM() {
  const currentCourse = useSelector(selectCurrentCourse);
  const weeklySchedule = useSelector(selectGroupedWeeklySchedule);
  const timezone = currentCourse?.schedule.timezone || '';
  return { timezone, weeklySchedule };
}
