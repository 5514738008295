import { FC } from 'react';

import { components } from '@reactour/tour';

import { markTipsAsSeen } from '../../../db/app/actions';
import { TipStatusKey } from '../../../db/shared/types';
import { useRequestDispatch } from '../../../utils/request-actions';

type InferProps<T> = T extends FC<infer P> ? P : unknown;
type NavigationProps = InferProps<typeof components.Navigation>;

export type VMProps = NavigationProps & {
  seenTips: Set<TipStatusKey>;
  setSeenTips: React.Dispatch<React.SetStateAction<Set<TipStatusKey>>>;
};

const useNavigationVM = ({
  steps,
  currentStep,
  setCurrentStep,
  setIsOpen,
  seenTips,
  setSeenTips,
}: VMProps) => {
  const requestDispatch = useRequestDispatch();

  const handleCloseTips = () => {
    setSeenTips(new Set());
    setCurrentStep(0);
    setIsOpen(false);
  };

  const handleTurnOffHelp = async () => {
    await requestMarkTipAsSeen(true);
  };

  const requestMarkTipAsSeen = async (turnOffTips = false) => {
    // FIXME: type inference for StepType is incorrect, so converting to any for now
    const currentStepKey: TipStatusKey = (steps[currentStep] as any).key;
    const seenTipKeys = Array.from(seenTips.values());
    if (!turnOffTips) seenTipKeys.push(currentStepKey);
    await requestDispatch(markTipsAsSeen, {
      seenTipKeys,
      turnOff: turnOffTips,
    });
    handleCloseTips();
  };

  const handlePrevious = async () => {
    setCurrentStep((currentStep) => currentStep - 1);
  };

  const handleNext = async () => {
    // FIXME: type inference for StepType is incorrect, so converting to any for now
    const currentStepKey: TipStatusKey = (steps[currentStep] as any).key;
    setSeenTips((prevVal) => prevVal.add(currentStepKey));
    setCurrentStep((currentStep) => currentStep + 1);
  };

  const handleMarkTipsAndClose = () => {
    requestMarkTipAsSeen(false);
  };

  return {
    handleTurnOffHelp,
    canSeePrevious: currentStep !== 0,
    handlePrevious,
    canSeeNext: currentStep < steps.length - 1,
    handleNext,
    canSeeClose: currentStep === steps.length - 1,
    handleMarkTipsAndClose,
  };
};

export default useNavigationVM;
