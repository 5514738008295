import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay as _endOfDay,
  endOfMonth as _endOfMonth,
  format as _format,
  formatDistanceToNow as _formatDistanceToNow,
  fromUnixTime,
  getDaysInMonth as _getDaysInMonth,
  isWithinInterval as _isWithinInterval,
  parse,
  startOfDay as _startOfDay,
  startOfMonth as _startOfMonth,
} from 'date-fns';

const ACADLY_TIME_OFFSET = 'acadlyTimeOffset';

export enum WeekDay {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

/**
 * @example
 * '12:25 pm'
 */
export const TIME_12_HOUR_FORMAT = 'hh:mm a';

/**
 * @example
 * '14:25'
 */
export const TIME_24_HOUR_FORMAT = 'HH:mm';

/**
 * @example
 * 'Fri, Apr 01, 2022'
 */
export const WEEKDAY_MONTH_DATE_YEAR_FORMAT = 'EEE, MMM dd, yyyy';

const regexp12h = /^(0?[1-9]|1[012])(:[0-5]\d)(:[0-5]\d)? [APap][mM]$/;
const regexp24h = /^([01]?\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/;

/**
 * Converts date argument from unix-timestamp to milli-seconds,
 * does nothing if argument is date
 * @param date date in unix-timestamp or date object
 */
const toDateArg = (date: UnixTime | Date) => (typeof date === 'number' ? date * 1000 : date);

/**
 * Returns the formatted date string in the given format. The result may vary by locale.
 * @param date date to format
 * @param formatStr format string
 * @see https://date-fns.org/v2.24.0/docs/format
 */
export const format = (
  date: Date | UnixTime,
  formatStr = 'MMM dd, yyyy',
  options?: Parameters<typeof _format>['2']
) => _format(toDateArg(date), formatStr, options);

export const setAcadlyTimeOffset = (acadlyTime: UnixTime) => {
  const offset = Math.round(acadlyTime - new Date().getTime() / 1000);
  localStorage.setItem(ACADLY_TIME_OFFSET, JSON.stringify(offset));
};

export const getAcadlyTimeOffset = () => {
  const acadlyTimeOffset = localStorage.getItem(ACADLY_TIME_OFFSET) ?? '0';
  const offset: UnixTime = JSON.parse(acadlyTimeOffset);
  return offset;
};

const getLocalTimeWithOffset = () => {
  const offset = getAcadlyTimeOffset();
  const localTime = Math.round(new Date().getTime() / 1000);
  return localTime + offset;
};

/**
 * @returns current date object
 */
export const now = () => {
  const localTime = getLocalTimeWithOffset();
  return toDate(localTime);
};

/**
 * @returns current unix timestamp in seconds
 */
export const unix = () => {
  return getLocalTimeWithOffset();
};

/**
 * Converts a date object into unix timestamp
 * @param date date object
 * @returns unix timestamp in seconds
 */
export const toUnix = (date: Date) => Math.round(date.getTime() / 1000);

/**
 * Converts a unix-timestamp to date object, does nothing if "date" is a date object
 * @param date unix-timestamp or date object
 * @returns date object
 */
export const toDate = (date: UnixTime | Date) => (typeof date === 'number' ? fromUnixTime(date) : date);

/**
 * Transforms first argument to date or milli-seconds format
 * @param date date in unix-timestamp or date object format
 * @param restArgs other arguments
 * @returns transformed arguments
 */
const firstArgToDate = <T extends any[]>(date: UnixTime | Date, ...restArgs: T): [number | Date, ...T] => [
  toDateArg(date),
  ...restArgs,
];

/**
 * Wrapper function to transform arguments and then pass to "fn"
 * @param fn a function
 * @param transformer argument transformer
 * @returns result of fn()
 */
const transformArgs =
  <Fn extends (...args: any[]) => any, Transformer extends (...args: Parameters<Fn>) => Parameters<Fn>>(
    fn: Fn,
    transformer: Transformer
  ) =>
  (...args: Parameters<Fn>): ReturnType<Fn> =>
    fn.apply(fn, transformer(...args));

/**
 * Gets a date with mid-night time i.e. 00:00 AM
 * @param date date object or unix timestamp
 * @returns date with 00:00AM time
 */
export const startOfDay = transformArgs(_startOfDay, firstArgToDate);

export const endOfDay = transformArgs(_endOfDay, firstArgToDate);

export const startOfMonth = transformArgs(_startOfMonth, firstArgToDate);
export const endOfMonth = transformArgs(_endOfMonth, firstArgToDate);

export const getDaysInMonth = transformArgs(_getDaysInMonth, firstArgToDate);

export const isWithinInterval = transformArgs(_isWithinInterval, (date, { start, end }) => [
  toDateArg(date),
  { start: toDateArg(start), end: toDateArg(end) },
]);

/**
 * Return the distance between the given date and now in words.
 * @param date source date
 * @param options formatting options
 * @see https://date-fns.org/v2.24.0/docs/formatDistanceToNow
 */
export const formatDistanceToNow = transformArgs(_formatDistanceToNow, firstArgToDate);

/**
 * Converts a date or unix timestamp into formatted time string
 * @param date date object or unix timestamp in seconds
 * @param _format time format 12h or 24h
 * @returns formatted time string
 */
export const formatTimeFromUnix = (date: Date | UnixTime, _format: '12h' | '24h' = '12h') => {
  return format(date, _format === '12h' ? TIME_12_HOUR_FORMAT : TIME_24_HOUR_FORMAT);
};

/**
 * Converts 12h | 24h time to unix timestamp
 * @param time 12h (e.g. 05:00 PM) | 24h (e.g. 17:00)
 * @returns unix timestamp with current date and specified time
 */
export const formatTimeToUnix = (time: string): UnixTime => {
  const date = formatTimeToDate(time);
  return toUnix(date);
};

/**
 * Converts 24h time to 12h with AM/PM time format
 * @param timeIn24h time string in 24h e.g. 14:25
 * @returns formatted time in 12h + am/pm e.g. 02:25 pm
 * @throws InvalidTimeFormatError
 */
export const format24hTo12hTime = (timeIn24h: string) => {
  return format(parse(timeIn24h, TIME_24_HOUR_FORMAT, now()), TIME_12_HOUR_FORMAT);
};

/**
 * Converts 12h with am/pm time to 24h time format
 * @param timeIn12h time string in 12h e.g. 02:25 pm
 * @returns formatted time in 24h e.g. 14:25
 * @throws InvalidTimeFormatError
 */
export const format12hTo24hTime = (timeIn12h: string) => {
  return format(parse(timeIn12h, TIME_12_HOUR_FORMAT, now()), TIME_24_HOUR_FORMAT);
};

export const isInvalidDate = (date: Date) => {
  return Number.isNaN(date.getTime());
};

/**
 * Replaces passedin time in now() or specified date
 * @param time time string in 12h or 24h e.g. 02:25 pm or 14:25
 * @param date date object to which you need to append time
 * @returns date object with specified time e.g. Thu Feb 17 2022 14:25:56 GMT+0530 (India Standard Time)
 * @throws InvalidTimeFormatError
 */
export const formatTimeToDate = (time: string, date?: Date | number) => {
  date = toDate(date || now());

  let result = parse(time, TIME_12_HOUR_FORMAT, date); // try 12 hour format

  if (isInvalidDate(result)) {
    result = parse(time, TIME_24_HOUR_FORMAT, date); // try 24 hour format
  }

  if (isInvalidDate(result)) {
    throw new Error('Invalid time format');
  }

  return result;
};

/**
 * @returns specified day's start of day (midnight)
 */
export const getStartOfDayUnix = (date: UnixTime | Date) => {
  let d: Date;
  if (typeof date === 'number') d = toDate(date);
  else d = date;
  d.setHours(0, 0, 0, 0);
  return toUnix(d);
};

/**
 * @returns today's start of day (midnight)
 */
export const getStartOfTodayUnix = () => {
  const date = now();
  date.setHours(0, 0, 0, 0);
  return toUnix(date);
};

/**
 * Calculates date and time difference
 * @param dateLeft left operand, if number then expects a unix-timestamp
 * @param dateRight right operand, if number then expects a unix-timestamp
 * @param unit unit of date and time in which difference to be returned
 * @throws InvalidUnitError
 */
export const dateTimeDiff = transformArgs(
  (
    dateLeft: Date | UnixTime,
    dateRight: Date | UnixTime,
    unit: 'days' | 'hours' | 'minutes' | 'seconds'
  ): number => {
    switch (unit) {
      case 'days':
        return differenceInDays(dateLeft, dateRight);
      case 'hours':
        return differenceInHours(dateLeft, dateRight);
      case 'minutes':
        return differenceInMinutes(dateLeft, dateRight);
      case 'seconds':
        return differenceInSeconds(dateLeft, dateRight);
      default:
        throw new Error('invalid unit provided');
    }
  },
  (left, right, ...args) => [toDateArg(left), toDateArg(right), ...args]
);

/**
 * Adds "units" amount to given date
 * @param date source date
 * @param amount number to add
 * @param unit unit of amount
 * @throws InvalidUnitError
 */
export const addDateTime = transformArgs(
  (
    date: Date | UnixTime,
    amount: number,
    unit: 'months' | 'days' | 'hours' | 'minutes' | 'seconds'
  ): Date => {
    switch (unit) {
      case 'months':
        return addMonths(date, amount);
      case 'days':
        return addDays(date, amount);
      case 'hours':
        return addHours(date, amount);
      case 'minutes':
        return addMinutes(date, amount);
      case 'seconds':
        return addSeconds(date, amount);
      default:
        throw new Error('invalid unit provided');
    }
  },
  firstArgToDate
);

/**
 * Checks if time is withing the given interval including start and end
 * @param time - time to be checked in 24h or 12h format
 * @param interval - start: 24h or 12h format, end: 24h or 12h format
 */
export const isTimeWithinInterval = (time: string, { start, end }: { start: string; end: string }) => {
  let [timeInSeconds, startInSeconds, endInSeconds] = [time, start, end].map((t) => {
    let tIn24h: string;
    if (regexp12h.test(t)) {
      // time is in 12h format
      tIn24h = format12hTo24hTime(t);
    } else if (regexp24h.test(t)) {
      // time is in 24h format
      tIn24h = t;
    } else {
      throw new Error('Invalid time format');
    }

    const [hours, minutes, seconds] = tIn24h.split(':');
    const h = isNaN(+hours) ? 0 : +hours * 60 * 60;
    const m = isNaN(+minutes) ? 0 : +minutes * 60;
    const s = isNaN(+seconds) ? 0 : +seconds;

    return h + m + s;
  });

  // end time is smaller than start time, then add 24 hours to end time
  if (endInSeconds < startInSeconds) endInSeconds += 24 * 60 * 60;
  const nextDayTimeInSeconds = timeInSeconds + 24 * 60 * 60;

  return (
    (startInSeconds <= timeInSeconds && timeInSeconds <= endInSeconds) ||
    (startInSeconds <= nextDayTimeInSeconds && nextDayTimeInSeconds <= endInSeconds)
  );
};

/**
 * converts seconds to hh:mm:ss or mm:ss or ss independent of timezones
 * @returns hh:mm:ss or mm:ss or ss
 */
export function formatSecondsTo24hClockTime(
  time: number,
  options?: { showHours?: boolean; showMinutes: boolean }
) {
  const hours = Math.floor(time / (60 * 60));
  const remainingTime = time - hours * (60 * 60);
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const hh = `${hours}`.padStart(2, '0');
  const mm = `${minutes}`.padStart(2, '0');
  const ss = `${seconds}`.padStart(2, '0');

  if (options?.showHours) return `${hh}:${mm}:${ss}`;
  if (options?.showMinutes) return `${mm}:${ss}`;
  return `${ss}`;
}

/**
 * Returns name of the month from month number
 * @param month 0 based month number, valid values: 0 -11
 * @param locale convert month name to specified locale
 * @returns Month name. e.g. January
 */
export const getMonthName = (month: number, locale?: Locale) => {
  const date = now();
  date.setMonth(month);
  return format(date, 'MMMM', { locale });
};

/* Parsers & Formatters */

export const unixToDate = (date: UnixTime | null) => {
  if (!date) return null;
  if (date <= 0) return null;
  return toDate(date);
};

export const dateToUnix = (date: Date | null) => {
  if (!date) return null;
  const dateInUnix = toUnix(date);
  if (dateInUnix <= 0) return null;
  return dateInUnix;
};

/**
 * Parses a date string into unix timestamp in seconds
 * @param dateStr date string
 * @param format format of date string provided
 * @param fallback default date or unix timestamp in seconds if parsing failed
 * @returns parsed date's unix timestamp in seconds
 */
export const parseToUnix = (dateStr: string, format: string, fallback: UnixTime | Date) => {
  const defaultDate = toDate(fallback);
  try {
    const date = parse(dateStr, format, defaultDate);
    const time = date.getTime();
    if (isNaN(time)) throw new Error('Invalid date');
    return toUnix(date);
  } catch (error) {
    console.warn(error);
    // Can't parse or invalid date so fallback to default date
    return toUnix(defaultDate);
  }
};
