import urls from '../../app/urls';
import { format, unix } from '../../utils/datetime';
import { pick } from '../../utils/helpers';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import {
  AddPollQuestionPayload,
  EditPollQuestionPayload,
  EditPublishedPollPayload,
  PollId,
  PublishPollPayload,
  SavePollPublishPrefsPayload,
  StopPollPayload,
  SubmitPollPayload,
} from './types';

export async function getAllPolls() {
  const { data } = await request.get<API.GetAllPollsResponse>(urls.getAllPolls);
  return data;
}

export async function createPoll(payload: API.CreatePollRequest) {
  const { data } = await request.post<API.CreatePollResponse>(urls.createPoll, payload);
  return data;
}

export async function editPoll(payload: API.EditPollRequest) {
  await request.post(urls.editPoll, payload);
}

export async function deletePoll(data: API.DeletePollRequest) {
  await request.delete(urls.deletePoll, { data });
}

export async function addPollQuestion(params: AddPollQuestionPayload) {
  const payload: API.AddPollQuestionRequest = {
    activityId: params.pollId,
    activityType: 'polls',
    classId: params.classId,
    description: {
      text: params.description,
      attachments: params.attachments,
    },
    options: params.options,
  };
  const { data } = await request.post<API.AddPollQuestionResponse>(urls.addPollQuestion, payload);
  return data;
}

export async function editPollQuestion(params: EditPollQuestionPayload) {
  const payload: API.EditPollQuestionRequest = {
    activityId: params.pollId,
    activityType: 'polls',
    classId: params.classId,
    questionId: params.questionId,
    description: {
      text: params.description,
      attachments: params.attachments,
    },
    options: params.options.map((option) => pick(option, 'type', 'text')),
  };

  const { data } = await request.post<API.EditPollQuestionResponse>(urls.editPollQuestion, payload);
  const question: API.PollQuestion = { _id: params.questionId, ...data };
  return question;
}

export async function savePollPublishPrefs(params: SavePollPublishPrefsPayload) {
  const payload: API.SavePollPrefsRequest = {
    classId: params.classId,
    activityType: 'poll',
    activityId: params.pollId,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
    pollPref: {
      allowLate: params.allowLate ? 1 : 0,
      deadlineFirst: params.deadlineFirst ? 1 : 0,
      dueDateType: params.dueDateType,
      duration: params.duration,
      isAnon: params.isAnonymous ? 1 : 0,
      subscribeToComments: params.subscribeToComments ? 1 : 0,
    },
  };
  await request.post(urls.savePublishPrefs, payload);
}

export async function publishPoll(params: PublishPollPayload) {
  const payload: API.PublishPollRequest = {
    classId: params.classId,
    pollId: params.pollId,
    toBeDone: params.preferences.toBeDone,
    dueDateTime: format(unix(), "yyyyMMdd'T'HH:mm"),
    isAnon: params.preferences.isAnonymous ? 1 : 0,
    allowLate: params.preferences.allowLate ? 1 : 0,
    deadlineFirst: params.preferences.deadlineFirst ? 1 : 0,
    dueDateType: params.preferences.dueDateType,
    duration: params.preferences.duration,
    subscribeToComments: params.preferences.subscribeToComments ? 1 : 0,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
  };
  const { data } = await request.post<API.PublishPollResponse>(urls.publishPoll, payload);
  return data;
}

export async function editPublishedPoll(params: EditPublishedPollPayload) {
  const question = params.questions[0];

  const payload: API.EditPublishedPollRequest = {
    classId: params.classId,
    pollId: params.pollId,
    questionId: question.id,
    title: params.title,
    description: {
      text: question.description,
      attachments: question.attachments,
    },
    options: question.options.map((option) => pick(option, 'type', 'text')),
    toNotify: params.notifyStudents ? 1 : 0,
  };

  await request.put(urls.editPublishedPoll, payload);
}

export async function getPollDetails(params: API.GetPollDetailsRequest) {
  const { data } = await request.get<API.GetPollDetailsResponse>(urls.getPollDetails, { params });
  return data;
}

export async function submitPoll(params: SubmitPollPayload) {
  const payload: API.SubmitPollRequest = {
    classId: params.classId,
    pollId: params.pollId,
    submission: params.responses,
    submissionType: params.submissionType,
    localTime: format(unix(), "yyyyMMdd'T'HH:mm"),
  };
  const { data } = await request.post<API.SubmitPollResponse>(urls.submitPoll, payload);
  return data;
}

export async function stopPoll({ classId, pollId }: StopPollPayload) {
  const payload: API.StopPollRequest = {
    classId,
    activityType: 'polls',
    activityId: pollId,
    localTime: format(unix(), "yyyyMMdd'T'HH:mm"),
  };
  const { data } = await request.post<API.StopPollResponse>(urls.stopPoll, payload);
  return data;
}

export async function getPollSubmissions({ pollId }: PollId) {
  const { data } = await request.get<API.GetPollSubmissionsResponse>(urls.getPollSubmissions(pollId));
  return data;
}
