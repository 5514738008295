import { FocusEventHandler } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyTextField, { Props as AcadlyTextFieldProps } from '../FormControls/TextField';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useTextFieldVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyTextFieldProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange'>;

const TextField = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  helperText,
  onBlur,
  ...props
}: Props<T>) => {
  const { controller } = useTextFieldVM({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur: _onBlur } = field;
  const { error } = fieldState;

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    onBlur?.(event);
    _onBlur();
  };

  return (
    <AcadlyTextField
      {...props}
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      error={Boolean(error)}
      aria-invalid={Boolean(error)}
      helperText={error?.message ?? helperText}
    />
  );
};

export default TextField;
