import { SortBy } from '../db/shared/types';
import { AcadlyLogoColors } from '../styles/colors';

export interface UserName {
  firstName: string;
  lastName: string;
}

export const compareNames = (a: UserName, b: UserName, sortBy: SortBy) => {
  const aFirstName = a.firstName.toLowerCase();
  const aLastName = a.lastName.toLowerCase();

  const bFirstName = b.firstName.toLowerCase();
  const bLastName = b.lastName.toLowerCase();

  switch (sortBy) {
    case SortBy.ASC_FIRST_NAME:
      if (aFirstName < bFirstName) return -1;
      if (aFirstName > bFirstName) return 1;
      return 0;
    case SortBy.DESC_FIRST_NAME:
      if (aFirstName < bFirstName) return 1;
      if (aFirstName > bFirstName) return -1;
      return 0;
    case SortBy.ASC_LAST_NAME:
      if (aLastName < bLastName) return -1;
      if (aLastName > bLastName) return 1;
      return 0;
    case SortBy.DESC_LAST_NAME:
      if (aLastName < bLastName) return 1;
      if (aLastName > bLastName) return -1;
      return 0;
    default:
      return 0;
  }
};

export const isSalutation = (str: string) => {
  const salutations = ['Mr.', 'Ms.', 'Mr', 'Miss', 'Dr.', 'Dr', 'Prof.', 'Prof'];
  return salutations.includes(str);
};

export const splitName = (name: string) => {
  let firstName = '',
    middleName = '',
    lastName = '';

  if (name.includes(' ')) {
    const tokens = name.split(' ').filter(Boolean);
    firstName = tokens.shift()!;

    if (isSalutation(firstName)) {
      firstName += ' ' + tokens.shift()!;
    }

    lastName = tokens.pop() || '';

    if (tokens.length) {
      middleName = tokens.join(' ');
    }
  } else {
    firstName = name;
  }

  return { firstName, middleName, lastName };
};

export const joinName = (firstName?: string, middleName?: string, lastName?: string) => {
  const nameParts = `${firstName} ${middleName} ${lastName}`;
  return nameParts
    .trim()
    .split(' ')
    .filter((s) => s)
    .join(' ');
};

/**
 *
 * @param name user's name
 * @returns combined initials of firstName and lastName
 *
 * @example 1. getNameInitials('John A Doe') => 'JD'
 * @example 2. getNameInitials('mary jane') => 'MJ'
 */
export const getNameInitials = (name: string) => {
  const { firstName, lastName } = splitName(name);
  const firstLetterOfFirstName = firstName[0].toUpperCase();
  const firstLetterOfLastName = lastName[0].toUpperCase();
  return firstLetterOfFirstName + firstLetterOfLastName;
};

/**
 * A fast and simple hash function with decent collision resistance.
 * Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
 * Public domain.
 *
 * @param str string to be hashed
 * @param seed initial seed value
 *
 * @see https://stackoverflow.com/a/52171480/9709887
 */
function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch: number; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

/**
 * @param name user's name
 * @returns background color by hashing name
 */
export function getBgColorFromName(name: string) {
  const allowedBgColors = [
    AcadlyLogoColors.ORANGE,
    AcadlyLogoColors.RED,
    AcadlyLogoColors.BLUE,
    AcadlyLogoColors.GREEN,
  ] as const;
  return allowedBgColors[cyrb53(name) % allowedBgColors.length];
}
