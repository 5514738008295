import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const QuizIcon = ({ variant = 'outlined', ...props }: Props) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 14C5.55228 14 6 13.5523 6 13C6 12.4477 5.55228 12 5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14Z"
        fill="currentColor"
      />
      <path
        d="M5 19C5.55228 19 6 18.5523 6 18C6 17.4477 5.55228 17 5 17C4.44772 17 4 17.4477 4 18C4 18.5523 4.44772 19 5 19Z"
        fill="currentColor"
      />
      <path
        d="M20.06 12H8.94C8.42085 12 8 12.4209 8 12.94V13.06C8 13.5791 8.42085 14 8.94 14H20.06C20.5791 14 21 13.5791 21 13.06V12.94C21 12.4209 20.5791 12 20.06 12Z"
        fill="currentColor"
      />
      <path
        d="M20.06 17H8.94C8.42085 17 8 17.4209 8 17.94V18.06C8 18.5791 8.42085 19 8.94 19H20.06C20.5791 19 21 18.5791 21 18.06V17.94C21 17.4209 20.5791 17 20.06 17Z"
        fill="currentColor"
      />
      <path
        d="M20.06 7H10.94C10.4209 7 10 7.42085 10 7.94V8.06C10 8.57915 10.4209 9 10.94 9H20.06C20.5791 9 21 8.57915 21 8.06V7.94C21 7.42085 20.5791 7 20.06 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78033 5.21967C9.07322 5.51256 9.07322 5.98744 8.78033 6.28033L5.78033 9.28033C5.48744 9.57322 5.01256 9.57322 4.71967 9.28033L3.21967 7.78033C2.92678 7.48744 2.92678 7.01256 3.21967 6.71967C3.51256 6.42678 3.98744 6.42678 4.28033 6.71967L5.25 7.68934L7.71967 5.21967C8.01256 4.92678 8.48744 4.92678 8.78033 5.21967Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default QuizIcon;
