import { useSelector } from 'react-redux';

import { selectCurrentCourse } from '../../../../db/courses/selectors';

const useRightActionsVM = () => {
  const currentCourse = useSelector(selectCurrentCourse);

  return { currentCourse };
};

export default useRightActionsVM;
