import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import { CircularProgress, circularProgressClasses, Props } from './styles';

export type { Props };

const CircularProgressbar = (
  { color = 'primary', size = 45, className, ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <CircularProgress
      ref={ref}
      variant={props.variant || 'indeterminate'}
      color={color}
      value={props.variant === 'determinate' ? props.value : 0}
      size={size}
      className={clsx(className, {
        [circularProgressClasses.colorSuccess]: color === 'success',
        [circularProgressClasses.colorError]: color === 'error',
        [circularProgressClasses.colorWarning]: color === 'warning',
      })}
    />
  );
};

export default forwardRef(CircularProgressbar);
