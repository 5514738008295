import { createPusherEvent } from '../../pusher/helpers';
import { ClassPusherEvent, PusherChannel, PusherEventName, PusherEventPayload } from '../../pusher/types';
import { API } from '../shared/api-responses';
import { ClassRole, CourseRole, CourseStudentUser, ResolveHandAction } from '../shared/types';

export type ZoomAuthenticatedEvent = PusherEventPayload<{
  userId: MongoId;
  autoRecordDefaults: API.GetClassDetailsResponse['autoRecordDefaults'];
}>;

export interface ZoomAuthenticatedPayload extends ZoomAuthenticatedEvent {
  classId: MongoId;
}

export const zoomAuthenticatedEvent = createPusherEvent<ZoomAuthenticatedEvent>(
  PusherEventName.ZOOM_AUTHENTICATED,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type AnotherInchargeReadyToBroadcastEvent = ClassPusherEvent<{
  hostDetails: {
    userId: MongoId;
    name: string;
    avatar: string;
    role: ClassRole.INCHARGE;
  };
}>;

export const anotherInchargeReadyToBroadcastEvent = createPusherEvent<AnotherInchargeReadyToBroadcastEvent>(
  PusherEventName.ANOTHER_INCHARGE_READY_TO_BROADCAST,
  { channels: [PusherChannel.USER] }
);

export type ReadyToBroadcastEvent = ClassPusherEvent<{}>;

/**
 * Received by meeting Host only
 * when he launches meeting
 */
export const readyToBroadcastEvent = createPusherEvent<ReadyToBroadcastEvent>(
  PusherEventName.READY_TO_BROADCAST,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type BroadcastStartedEvent = ClassPusherEvent<{
  meetingId: ZoomId;
}>;

export const broadcastStartedEvent = createPusherEvent<BroadcastStartedEvent>(PusherEventName.BROADCASTING, {
  channels: [PusherChannel.USER],
  ignoreForSameUser: false,
});

export type MeetingStartedEvent = ClassPusherEvent<{
  meetingId: ZoomId;
  /**
   * Acadly userId of the meeting host
   * Since we have multiple incharges, hostUserId is needed to identify which incharge is meeting host
   */
  hostUserId: MongoId;
  /**
   * Details of the meeting host
   * Since we have multiple incharges, these details are needed to identify which incharge is meeting host
   */
  hostDetails: {
    userId: MongoId;
    name: string;
    avatar: string;
    role: ClassRole.INCHARGE;
  };
}>;

export const meetingStartedEvent = createPusherEvent<MeetingStartedEvent>(PusherEventName.MEETING_STARTED, {
  channels: [PusherChannel.COURSE],
  ignoreForSameUser: false,
});

export type ParticipantJoinedEvent = ClassPusherEvent<{
  userId: MongoId;
  zoomUserId: ZoomId;
  name: string;
  avatar: MongoId;
  joinId: string;
  role: CourseRole;
  /**
   * if set to `1`, participant should be expelled by
   * class-incharge from zoom meeting
   */
  mustExpel: NumericBoolean;
  /** list of participants to be removed from zoom meeting */
  toLeave: ZoomId[];
}>;

export const participantJoinedEvent = createPusherEvent<ParticipantJoinedEvent>(
  PusherEventName.PARTICIPANT_JOINED,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type RemoteAttendanceStartedEvent = PusherEventPayload<{
  classId: MongoId;
  meetingId: ZoomId;
  attendanceId: MongoId;
  displayMessages: {
    subtitle: string;
    buttonText: string;
  }[];
}>;

export const remoteAttendanceStartedEvent = createPusherEvent<RemoteAttendanceStartedEvent>(
  PusherEventName.REMOTE_ATTENDANCE_STARTED,
  { channels: [PusherChannel.COURSE], ignoreForSameUser: false }
);

export type RemoteAttendanceMarkedEvent = PusherEventPayload<{
  classId: MongoId;
  meetingId: ZoomId;
  attendanceId: MongoId;
  sender: CourseStudentUser;
}>;

export const remoteAttendanceMarkedEvent = createPusherEvent<RemoteAttendanceMarkedEvent>(
  PusherEventName.REMOTE_ATTENDANCE_RESPONSE,
  { channels: [PusherChannel.COURSE_TEAM], ignoreForSameUser: false }
);

export type RemoteAttendanceStopppedEvent = PusherEventPayload<{
  classId: MongoId;
  meetingId: ZoomId;
  attendanceId: MongoId;
}>;

export const remoteAttendanceStopppedEvent = createPusherEvent<RemoteAttendanceStopppedEvent>(
  PusherEventName.REMOTE_ATTENDANCE_STOPPED,
  { channels: [PusherChannel.COURSE], ignoreForSameUser: false }
);

export type HandRaisedEvent = ClassPusherEvent<{
  userId: MongoId;
  avatar: MongoId;
  name: string;
  role: CourseRole.STUDENT;
  message: string;
  raisedAt: UnixTime;
}>;

export const handRaisedEvent = createPusherEvent<HandRaisedEvent>(PusherEventName.HAND_RAISED, {
  channels: [PusherChannel.USER],
  ignoreForSameUser: false,
});

export type HandLoweredEvent = ClassPusherEvent<{
  userId: MongoId;
  avatar: MongoId;
  name: string;
  role: CourseRole.STUDENT;
  message: string;
}>;

export const handLoweredEvent = createPusherEvent<HandLoweredEvent>(PusherEventName.HAND_LOWERED, {
  channels: [PusherChannel.USER],
  ignoreForSameUser: false,
});

export type AddressingHandEvent = ClassPusherEvent<{
  userId: MongoId;
  zoomUserId: ZoomId;
  avatar: MongoId;
  name: string;
  role: CourseRole.STUDENT;
  message: string;
}>;

export const addressingHandEvent = createPusherEvent<AddressingHandEvent>(PusherEventName.ADDRESSING_HAND, {
  channels: [PusherChannel.COURSE],
  ignoreForSameUser: false,
});

export type HandResolvedEvent = ClassPusherEvent<
  {
    userId: MongoId;
    zoomUserId: ZoomId;
    avatar: MongoId;
    name: string;
    role: CourseRole.STUDENT;
    message: string;
  } & (
    | { action: ResolveHandAction.RESOLVE }
    | {
        action: ResolveHandAction.NEXT;
        nextUser: {
          userId: MongoId;
          zoomUserId: ZoomId;
          name: string;
          avatar: MongoId;
          role: CourseRole.STUDENT;
        };
      }
  )
>;

export const handResolvedEvent = createPusherEvent<HandResolvedEvent>(PusherEventName.HAND_RESOLVED, {
  channels: [PusherChannel.COURSE],
  ignoreForSameUser: false,
});

export type ParticipantLeftEvent = ClassPusherEvent<{
  userId: MongoId;
  zoomUserId: ZoomId;
  name: string;
  role: CourseRole;
}>;

export const participantLeftEvent = createPusherEvent<ParticipantLeftEvent>(
  PusherEventName.PARTICIPANT_LEFT,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type BroadcastStoppedEvent = ClassPusherEvent<{
  meetingId: ZoomId;
}>;

/**
 * Recieved by class-incharge only when meeting is ended
 * from acadly web-app
 */
export const broadcastStoppedEvent = createPusherEvent<BroadcastStoppedEvent>(
  PusherEventName.BROADCAST_STOPPED,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type MeetingEndedEvent = ClassPusherEvent<{
  meetingId: ZoomId;
}>;

/**
 * Recieved by all course members when meeting is ended
 * either from zoom-app or acadly web-app
 */
export const meetingEndedEvent = createPusherEvent<MeetingEndedEvent>(PusherEventName.MEETING_ENDED, {
  channels: [PusherChannel.COURSE],
  ignoreForSameUser: false,
});

export type MeetingDestroyedEvent = ClassPusherEvent<{
  meetingId: ZoomId;
}>;

/**
 * Recieved by class-incharge only when meeting is ended
 * from zoom-app
 */
export const meetingDestroyedEvent = createPusherEvent<MeetingDestroyedEvent>(
  PusherEventName.MEETING_DESTROYED,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);
