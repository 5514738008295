import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { Props as ButtonProps } from '../../../../../components/Button';
import ExportDialog from '../../../../../components/ExportDialog';
import MoreActionsMenu from '../../../../../components/MoreActionsMenu';
import { CourseRole } from '../../../../../db/shared/types';
import { i18nNS } from '../../../../../i18n';
import ExportIcon from '../../../../../icons.deprecated/ExportIcon';
import useMoreActionsVM from './vm';

type Props = DistributiveOmit<ButtonProps, 'onClick'>;

const MoreActions = (props: Props) => {
  const { t } = useTranslation([i18nNS.SHARED, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const {
    currentCourse,
    exportDialogTitle,
    isExportDialogOpen,
    handleOpenExportDialog: _handleOpenExportDialog,
    handleCloseExportDialog,
    handleGetFileDetails,
  } = useMoreActionsVM();

  const handleOpenExportDialog = (onClose: () => void) => () => {
    _handleOpenExportDialog(onClose);
  };

  if (currentCourse?.myRole === CourseRole.STUDENT) return null;

  return (
    <>
      <MoreActionsMenu {...props}>
        {(onClose) => (
          <MenuItem
            onClick={handleOpenExportDialog(onClose)}
            aria-label={t('click_to_export_students_posts', { ns: i18nNS.SHARED })}
          >
            <ListItemIcon>
              <ExportIcon color="inherit" fontSize="small" aria-hidden />
            </ListItemIcon>
            <ListItemText>{t('export', { ns: i18nNS.GLOSSARY })}</ListItemText>
          </MenuItem>
        )}
      </MoreActionsMenu>
      <ExportDialog
        open={isExportDialogOpen}
        onClose={handleCloseExportDialog}
        onGetFileDetails={handleGetFileDetails}
        exportTypeTitle={exportDialogTitle}
      />
    </>
  );
};

export default MoreActions;
