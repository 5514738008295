import { Trans, useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import CheckmarkCircleIcon from '../../../icons/CheckmarkCircleIcon';
import CreateCourseForm from '../CreateCourseForm';
import usePaymentSuccess from './vm';

const PaymentSuccess = () => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.GLOSSARY]);
  const { closeDrawer, isVisible, isCreatingProCourse, handleCreateCourse } = usePaymentSuccess();

  return (
    <CreateCourseForm
      isVisible={isVisible}
      as="div"
      footer={
        <>
          <Button variant="text" onClick={closeDrawer}>
            {t('create_later', { ns: i18nNS.CREATE_COURSE })}
          </Button>
          <Button onClick={handleCreateCourse}>
            {t(isCreatingProCourse ? 'create_acadly_pro_course' : 'create_acadly_basic_course', {
              ns: i18nNS.CREATE_COURSE,
            })}
          </Button>
        </>
      }
    >
      <Stack justifyContent="center" alignItems="center" gap={5} height="100%">
        <CheckmarkCircleIcon
          fontSize="large"
          sx={{
            color: (theme) => theme.palette.success[400],
            fontSize: '5rem',
          }}
          isAnimated
        />
        <Typography textAlign="center" sx={{ textWrap: 'pretty' }}>
          <Trans
            t={t}
            i18nKey={
              isCreatingProCourse
                ? 'your_purchase_of_an_acadly_pro_course_is_successful_you_can_either_create_the_course_now_or_any_time_later_using_the_create_a_new_course_button_the_purchased_course_will_be_available_to_use_and_you_will_not_have_to_purchase_again'
                : 'your_purchase_of_an_acadly_basic_course_is_successful_you_can_either_create_the_course_now_or_any_time_later_using_the_create_a_new_course_button_the_purchased_course_will_be_available_to_use_and_you_will_not_have_to_purchase_again'
            }
            ns={i18nNS.CREATE_COURSE}
          >
            <strong>Your purchase of an Acadly Pro course</strong> is successful. You can either create the
            course now or any time later using the "Create a new Course" button. The purchased course will be
            available to use and you will not have to purchase again.
          </Trans>
        </Typography>
      </Stack>
    </CreateCourseForm>
  );
};

export default PaymentSuccess;
