import { useContext } from 'react';

import { useRequestDispatch } from '../../../utils/request-actions';
import { AuthContext } from '../../Context';
import { studentSignup } from '../../store/actions';
import { AuthScreen, NextScreen } from '../../types';

export interface Props {
  onClose: () => void;
  joinCode: string;
}

const useCourseInfoAlertVM = ({ onClose, joinCode }: Props) => {
  const requestDispatch = useRequestDispatch();

  const { email, marketing, setScreen } = useContext(AuthContext);

  const handleJoinThisCourse = async () => {
    const { next } = await requestDispatch(studentSignup, { emailId: email, marketing, joinCode });

    switch (next) {
      case NextScreen.PASSWORD_NOT_SET:
        setScreen(AuthScreen.VERIFY_EMAIL);
        break;
      default:
        break;
    }

    onClose();
  };

  return { email, handleJoinThisCourse };
};

export default useCourseInfoAlertVM;
