import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import clsx from 'clsx';

import Button from '../../../components/Button';
import RadioOption from '../../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../../components/HookFormControls/RadioGroup';
import Tag from '../../../components/Tag/index';
import { i18nNS } from '../../../i18n';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import InfoIcon from '../../../icons/InfoIcon';
import CreateCourseForm from '../CreateCourseForm';
import Skeleton from './Skeleton';
import { Note, paymentMethodClasses, Root, StrikeThrough } from './styles';
import { PaymentMethod as PaymentMethodType } from './types';
import usePaymentMethodVM from './vm';

const PaymentMethod = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);
  const {
    isVisible,
    isFetchingAvailableSlots,
    closeDrawer,
    control,
    isBasicCourseFree,
    isProCourseFree,
    isFreeCourseSelected,
    canUsePurchasedCourse,
    formattedStudentPurchasePrices,
    freeMessage,
    freeMessageColor,
    moveToNextStep,
    moveToPreviousStep,
  } = usePaymentMethodVM();
  return (
    <CreateCourseForm
      isVisible={isVisible}
      onSubmit={moveToNextStep}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button
            type="button"
            variant="text"
            startIcon={<ChevronLeftIcon />}
            onClick={moveToPreviousStep}
            aria-label={t('click_to_go_to_previous_step', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button
              type="button"
              variant="text"
              onClick={closeDrawer}
              aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              endIcon={<ChevronRightIcon />}
              aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
              disabled={isFetchingAvailableSlots}
            >
              {t('continue', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      {isFetchingAvailableSlots ? (
        <Skeleton />
      ) : (
        <Root>
          <Stack gap={2}>
            <Typography variant="h5Bold">{t('choose_course_type', { ns: i18nNS.CREATE_COURSE })}</Typography>
            <RadioGroup control={control} name="isCreatingProCourse">
              <RadioOption
                value="true"
                aria-label={t('click_to_create_an_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })}
              >
                <div className={paymentMethodClasses.radioLabel}>
                  <Typography variant="paragraphBold" component="span">
                    {t('create_an_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })}
                  </Typography>
                  {isProCourseFree && <Tag color="success" label={t('free', { ns: i18nNS.GLOSSARY })} />}
                </div>
              </RadioOption>
              <RadioOption
                value="false"
                aria-label={t('click_to_create_an_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })}
              >
                <div className={clsx(paymentMethodClasses.radioLabel, paymentMethodClasses.radioLabelRow)}>
                  <Typography variant="paragraphBold" component="span">
                    {t('create_an_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })}
                  </Typography>
                  {isBasicCourseFree && <Tag color="success" label={t('free', { ns: i18nNS.GLOSSARY })} />}
                </div>
              </RadioOption>
            </RadioGroup>
          </Stack>
          <Stack gap={2}>
            <Typography variant="h5Bold">
              {t('choose_payment_method', { ns: i18nNS.CREATE_COURSE })}
            </Typography>
            <RadioGroup control={control} name="paymentMethod">
              {isFreeCourseSelected && (
                <RadioOption
                  value={PaymentMethodType.FREE}
                  aria-label={t('click_to_select_option_use_the_free_course', {
                    ns: i18nNS.CREATE_COURSE,
                  })}
                >
                  <div className={paymentMethodClasses.radioLabel}>
                    <Typography variant="paragraphBold" component="span">
                      {t('use_the_free_course', { ns: i18nNS.CREATE_COURSE })}
                    </Typography>
                    <Typography component="div">
                      {t('free_no_credit_card_required', { ns: i18nNS.CREATE_COURSE })}
                    </Typography>
                  </div>
                </RadioOption>
              )}
              {canUsePurchasedCourse && (
                <RadioOption
                  value={PaymentMethodType.PURCHASED}
                  aria-label={t('click_to_select_option_use_a_purchased_course', {
                    ns: i18nNS.CREATE_COURSE,
                  })}
                >
                  <div className={paymentMethodClasses.radioLabel}>
                    <Typography variant="paragraphBold" component="span">
                      {t('use_a_purchased_course', { ns: i18nNS.CREATE_COURSE })}
                    </Typography>
                    <Typography component="div">
                      {t('from_an_earlier_purchase', { ns: i18nNS.CREATE_COURSE })}
                    </Typography>
                  </div>
                </RadioOption>
              )}
              <RadioOption
                value={PaymentMethodType.STUDENT_PAYS}
                aria-label={t('click_to_select_option_purchase_a_new_course_students_pay', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              >
                <div className={paymentMethodClasses.radioLabel}>
                  <Typography variant="paragraphBold" component="span">
                    {t('purchase_a_new_course_students_pay', { ns: i18nNS.CREATE_COURSE })}
                  </Typography>
                  <Typography component="div">
                    {formattedStudentPurchasePrices.actual !== formattedStudentPurchasePrices.offered && (
                      <StrikeThrough>{formattedStudentPurchasePrices.actual}</StrikeThrough>
                    )}
                    <span>
                      {t('price_per_student_per_course_free_for_instructors', {
                        ns: i18nNS.CREATE_COURSE,
                        price: formattedStudentPurchasePrices.offered,
                      })}
                    </span>
                  </Typography>
                </div>
              </RadioOption>
              <RadioOption
                value={PaymentMethodType.INSTRUCTOR_PAYS}
                aria-label={t('click_to_select_option_purchase_a_new_course_instructor_pays', {
                  ns: i18nNS.CREATE_COURSE,
                })}
              >
                <div className={paymentMethodClasses.radioLabel}>
                  <Typography variant="paragraphBold" component="span">
                    {t('purchase_a_new_course_instructor_pays', { ns: i18nNS.CREATE_COURSE })}
                  </Typography>
                  <Typography component="div">
                    {t('free_for_students', { ns: i18nNS.CREATE_COURSE })}
                  </Typography>
                </div>
              </RadioOption>
            </RadioGroup>
          </Stack>
          {freeMessage && (
            <Note icon={<InfoIcon variant="filled" color="inherit" />} color={freeMessageColor}>
              <Typography color="inherit" mt={0.5}>
                {freeMessage}
              </Typography>
            </Note>
          )}
        </Root>
      )}
    </CreateCourseForm>
  );
};
export default PaymentMethod;
