import { styled } from '@mui/material';

import { radioGroupClasses } from '../../../components/FormControls/RadioGroup';
import { radioOptionClasses } from '../../../components/FormControls/RadioGroup/RadioOption';
import AcadlyNote from '../../../components/Note';
import { generateClasses } from '../../../utils/helpers';

export const paymentMethodClasses = generateClasses('PaymentMethod', ['radioLabel', 'radioLabelRow']);

export const Root = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  [`.${radioGroupClasses.radioGroup}`]: {
    gap: theme.spacing(4),
  },

  [`.${radioOptionClasses.root}`]: {
    alignItems: 'flex-start',
  },

  [`.${paymentMethodClasses.radioLabel}`]: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    [`&.${paymentMethodClasses.radioLabelRow}`]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

export const StrikeThrough = styled('span')(({ theme }) => ({
  textDecoration: 'line-through',
  marginRight: theme.spacing(1),
}));

export const Note = styled(AcadlyNote)(({ theme }) => ({
  marginTop: 'auto',
}));
