import { useState } from 'react';

import { Stack, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';

import darkTheme from '../../styles/theme/dark';
import lightTheme from '../../styles/theme/light';
import FormControlsTabs, { TabItems } from './FormControlsTabs';

const useFormControlsPlaygroundVM = () => {
  const [activeTab, setActiveTab] = useState(TabItems.TEXT_FIELD);

  const handleChangeActiveTab = (newValue: TabItems) => {
    setActiveTab(newValue);
  };

  return { activeTab, handleChangeActiveTab };
};

const ContentRoot = styled('div')(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
}));

const FormControlsPlayground = () => {
  const { activeTab, handleChangeActiveTab } = useFormControlsPlaygroundVM();

  return (
    <Stack direction="row">
      <ThemeProvider theme={lightTheme}>
        <ContentRoot>
          <FormControlsTabs activeTab={activeTab} onChangeTab={handleChangeActiveTab} />
        </ContentRoot>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ContentRoot>
          <FormControlsTabs activeTab={activeTab} onChangeTab={handleChangeActiveTab} />
        </ContentRoot>
      </ThemeProvider>
    </Stack>
  );
};

export default FormControlsPlayground;
