import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';

import clsx from 'clsx';

import FeedbackDialog from '../../../../feedback/FeedbackDialog';
import { i18nNS } from '../../../../i18n';
import ArrowBackIcon from '../../../../icons/ArrowBackIcon';
import MenuIcon from '../../../../icons/MenuIcon';
import { forDesktop } from '../../../../utils/media-queries';
import HamburgerDrawer from './HamburgerDrawer';
import HamburgerMenu from './HamburgerMenu';
import { hamburgerClasses, StyledButton } from './styles';
import useHamburgerMenu from './vm';

const Hamburger = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.SHARED, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const {
    hamburgerButtonRef,
    session,
    isTrialAccount,
    isHomePage,
    anchorEl,
    open,
    handleHamburgerClick: _handleHamburgerClick,
    handleClose,
    handleBackClick,
    handleSettingsClick,
    isFeedbackDialogOpen,
    handleFeedbackClick,
    handleCloseFeedbackDialog,
    handleLogoutClick,
  } = useHamburgerMenu();

  const handleHamburgerClick = (event: MouseEvent<HTMLElement>) => {
    _handleHamburgerClick(event.currentTarget);
  };

  return (
    <>
      {isDesktop || isHomePage ? (
        <StyledButton
          ref={hamburgerButtonRef}
          variant="text"
          size="small"
          edge={isDesktop ? undefined : 'start'}
          aria-label={t('click_to_open_menu', { ns: i18nNS.SHARED })}
          onClick={handleHamburgerClick}
          aria-haspopup
          className={clsx({ [hamburgerClasses.menuOpen]: open }, 'courseHomeSetting')}
        >
          <MenuIcon aria-hidden />
        </StyledButton>
      ) : (
        <StyledButton
          variant="text"
          size="small"
          edge="start"
          aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          onClick={handleBackClick}
        >
          <ArrowBackIcon aria-hidden />
        </StyledButton>
      )}
      {isDesktop ? (
        <HamburgerMenu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          avatar={session.avatar}
          name={session.name}
          universityName={session.university.name}
          isTrialAccount={isTrialAccount}
          onSettingsClick={handleSettingsClick}
          onFeedbackClick={handleFeedbackClick}
          onLogoutClick={handleLogoutClick}
        />
      ) : (
        <HamburgerDrawer
          open={open}
          onClose={handleClose}
          avatar={session.avatar}
          name={session.name}
          universityName={session.university.name}
          isTrialAccount={isTrialAccount}
          onSettingsClick={handleSettingsClick}
          onFeedbackClick={handleFeedbackClick}
          onLogoutClick={handleLogoutClick}
        />
      )}
      <FeedbackDialog open={isFeedbackDialogOpen} onClose={handleCloseFeedbackDialog} />
    </>
  );
};

export default Hamburger;
