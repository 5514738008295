import { SvgIcon, SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const BackgroundItem = styled('div')(({ theme }) => ({
  backgroundImage: 'conic-gradient(from 22.5deg, currentColor, transparent)',
  height: '100%',
  animation: 'rotation 1.4s infinite linear',
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(359deg)',
    },
  },
}));

const SpinnerIcon = (props: SvgIconProps) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <clipPath id="clip">
      <path
        d="M12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2Z"
        fill="currentColor"
      />
      <path
        d="M21 11H19C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12C18 12.2652 18.1054 12.5196 18.2929 12.7071C18.4804 12.8946 18.7348 13 19 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
        fill="currentColor"
      />
      <path
        d="M6 12C6 11.7348 5.89464 11.4804 5.70711 11.2929C5.51957 11.1054 5.26522 11 5 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H5C5.26522 13 5.51957 12.8946 5.70711 12.7071C5.89464 12.5196 6 12.2652 6 12Z"
        fill="currentColor"
      />
      <path
        d="M6.22 5C6.02507 4.81567 5.76489 4.71634 5.49672 4.72384C5.22854 4.73134 4.97433 4.84507 4.79 5.04C4.60567 5.23493 4.50634 5.49511 4.51384 5.76328C4.52134 6.03146 4.63507 6.28568 4.83 6.47L6.27 7.86C6.36664 7.95331 6.48108 8.02619 6.6065 8.0743C6.73192 8.12241 6.86575 8.14475 7 8.14C7.1347 8.13949 7.26792 8.11176 7.39164 8.05849C7.51537 8.00522 7.62705 7.9275 7.72 7.83C7.90625 7.64264 8.01079 7.38919 8.01079 7.125C8.01079 6.86081 7.90625 6.60736 7.72 6.42L6.22 5Z"
        fill="currentColor"
      />
      <path
        d="M17 8.14C17.2575 8.13898 17.5046 8.03868 17.69 7.86L19.13 6.47C19.3057 6.28645 19.4049 6.04292 19.4075 5.78887C19.4101 5.53482 19.3159 5.2893 19.1441 5.10217C18.9722 4.91504 18.7356 4.80034 18.4822 4.78137C18.2289 4.76239 17.9778 4.84056 17.78 5L16.34 6.42C16.1537 6.60736 16.0492 6.86081 16.0492 7.125C16.0492 7.38919 16.1537 7.64264 16.34 7.83C16.5131 8.01272 16.7488 8.12342 17 8.14Z"
        fill="currentColor"
      />
      <path
        d="M12 18C11.7348 18 11.4804 18.1054 11.2929 18.2929C11.1054 18.4804 11 18.7348 11 19V21C11 21.2652 11.1054 21.5196 11.2929 21.7071C11.4804 21.8946 11.7348 22 12 22C12.2652 22 12.5196 21.8946 12.7071 21.7071C12.8946 21.5196 13 21.2652 13 21V19C13 18.7348 12.8946 18.4804 12.7071 18.2929C12.5196 18.1054 12.2652 18 12 18Z"
        fill="currentColor"
      />
      <path
        d="M17.73 16.14C17.539 15.9557 17.2827 15.8548 17.0173 15.8594C16.752 15.8641 16.4993 15.974 16.315 16.165C16.1307 16.356 16.0298 16.6123 16.0344 16.8777C16.0391 17.143 16.149 17.3957 16.34 17.58L17.78 19C17.9654 19.1787 18.2125 19.279 18.47 19.28C18.604 19.2808 18.7368 19.2546 18.8605 19.2031C18.9841 19.1516 19.0962 19.0757 19.19 18.98C19.2837 18.887 19.3581 18.7764 19.4089 18.6546C19.4597 18.5327 19.4858 18.402 19.4858 18.27C19.4858 18.138 19.4597 18.0073 19.4089 17.8854C19.3581 17.7636 19.2837 17.653 19.19 17.56L17.73 16.14Z"
        fill="currentColor"
      />
      <path
        d="M6.27 16.14L4.83 17.53C4.73627 17.623 4.66188 17.7336 4.61111 17.8554C4.56034 17.9773 4.5342 18.108 4.5342 18.24C4.5342 18.372 4.56034 18.5027 4.61111 18.6246C4.66188 18.7464 4.73627 18.857 4.83 18.95C4.92379 19.0457 5.03586 19.1216 5.15954 19.1731C5.28323 19.2246 5.41601 19.2508 5.55 19.25C5.79651 19.2521 6.03512 19.1631 6.22 19L7.66 17.61C7.85096 17.4257 7.96087 17.173 7.96556 16.9077C7.97024 16.6423 7.86933 16.386 7.685 16.195C7.50067 16.004 7.24804 15.8941 6.98268 15.8894C6.71731 15.8848 6.46096 15.9857 6.27 16.17V16.14Z"
        fill="currentColor"
      />
    </clipPath>
    <foreignObject x={0} y={0} width="100%" height="100%" clipPath="url(#clip)">
      <BackgroundItem />
    </foreignObject>
  </SvgIcon>
);

export default SpinnerIcon;
