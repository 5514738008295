import { useSelector } from 'react-redux';

import { startZoomMeetingBroadcast } from '../../../db/online-meeting/actions';
import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useStartBroadcastFooterViewVM() {
  const requestDispatch = useRequestDispatch();

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useSelector(selectOnlineMeeting(classId));

  const handleStartBroadcast = async () => {
    if (!classId || !onlineDetails?.meetingId) return;
    await requestDispatch(startZoomMeetingBroadcast, {
      classId,
      meetingId: onlineDetails?.meetingId,
    });
  };

  return { handleStartBroadcast };
}
