import { ElementType } from 'react';

import { Theme, Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material';

type NamedColor = keyof Pick<
  Theme['palette'],
  'primary' | 'secondary' | 'success' | 'error' | 'warning' | 'grey'
>;
type ColorVariant = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type BackgrounColors = keyof Theme['palette']['background'];
type CommonColors = keyof Theme['palette']['common'];

export interface TypographyProps<C extends ElementType<any>> {
  component?: C;
  color?:
    | NamedColor
    | `${NamedColor}.${ColorVariant}`
    | `background.${BackgrounColors}`
    | `common.${CommonColors}`
    | 'inherit';
}

export type Props<C extends ElementType<any> = 'span'> = MuiTypographyProps<C, TypographyProps<C>>;

const Typography = <C extends ElementType<any> = 'span'>(props: Props<C>) => {
  return <MuiTypography {...props} />;
};

export default Typography;
