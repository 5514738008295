import { colorPalette } from './variables';

export const colorIntensities = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900] as const;
export type ColorIntensity = typeof colorIntensities[number];

export const createThemeColor = ({
  colorSet,
  highContrastColor = colorPalette.white,
  lowContrastColor = colorPalette.black,
  contrastThreshold = 400,
}: {
  colorSet: Record<ColorIntensity, string>;
  highContrastColor?: string;
  lowContrastColor?: string;
  contrastThreshold?: ColorIntensity;
}) => {
  const contrastColors = colorIntensities.reduce((acc, colorIntensity) => {
    if (colorIntensity < contrastThreshold) {
      acc[colorIntensity] = lowContrastColor;
    } else {
      acc[colorIntensity] = highContrastColor;
    }
    return acc;
  }, {} as Record<ColorIntensity, string>);

  return {
    ...colorSet,
    main: colorSet[600],
    dark: colorSet[700],
    light: colorSet[500],
    contrastText: highContrastColor,
    highContrastColor,
    lowContrastColor,
    contrastColors,
  };
};
