import { Descendant } from 'slate';

import Logger from '../../utils/logger';
import { FormulaDisplay, FormulaElement, FormulaFormat, FormulaUploadStatus } from '../plugins/formula/types';
import { ImageElement, ImageUploadStatus } from '../plugins/image/types';
import { TextFormat } from '../types';

const logger = Logger.create('richtext-editor');

function decodeHtml(html: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

function parseFormula(formula: Element): FormulaElement {
  const display = formula.getAttribute('display');
  const format = formula.getAttribute('format');

  const id = formula.getAttribute('id')!;
  const ach = formula.getAttribute('ach')!;
  const acw = formula.getAttribute('acw')!;
  const url = formula.getAttribute('url')!;

  return {
    id,
    ach,
    acw,
    url,
    code: formula.innerHTML,
    type: 'formula',
    children: [{ text: '' }],
    format: format as FormulaFormat,
    display: display === 'block' ? FormulaDisplay.BLOCK : FormulaDisplay.INLINE,
    uploadStatus: FormulaUploadStatus.UPLOADED,
  };
}

function parseImage(image: Element): ImageElement {
  const ach = image.getAttribute('ach')!;
  const acw = image.getAttribute('acw')!;
  const url = image.getAttribute('src')!;

  return {
    ach,
    acw,
    url,
    type: 'image',
    uploadStatus: ImageUploadStatus.UPLOADED,
    children: [{ text: '' }],
  };
}

function insertNode(nodes: Descendant[], node: Descendant) {
  const lastNode = nodes[nodes.length - 1] as Descendant | undefined;

  if (node.type === 'image' || (node.type === 'formula' && node.display === 'block')) {
    nodes.push(node);
    return;
  }

  if (lastNode?.type === 'paragraph') {
    lastNode.children.push(node);
    return;
  }

  nodes.push({ type: 'paragraph', children: [node] });
  return;
}

interface TraversalContext {
  nodes: Descendant[];
  format: TextFormat;
}

function traverseNodes(root: Element, { nodes, format }: TraversalContext): Descendant[] {
  for (const node of Array.from(root.childNodes)) {
    if (node.nodeType === Node.TEXT_NODE) {
      insertNode(nodes, {
        ...format,
        type: 'text',
        text: decodeHtml(node.textContent || ''),
      });
      continue;
    }

    if (node.nodeType !== Node.ELEMENT_NODE) {
      logger.error(`Unhandled node found: ${node.nodeName}`);
      continue;
    }

    switch (node.nodeName.toUpperCase()) {
      case 'B':
        traverseNodes(node as Element, {
          nodes,
          format: { ...format, bold: true },
        });
        break;
      case 'I':
        traverseNodes(node as Element, {
          nodes,
          format: { ...format, italic: true },
        });
        break;
      case 'U':
        traverseNodes(node as Element, {
          nodes,
          format: { ...format, underline: true },
        });
        break;
      case 'BR':
        insertNode(nodes, { type: 'text', text: '\n', ...format });
        break;
      case 'FORMULA':
        insertNode(nodes, parseFormula(node as Element));
        break;
      case 'IMG':
        insertNode(nodes, parseImage(node as Element));
        break;
      case 'P':
        traverseNodes(node as Element, { nodes, format });
        break;
      default:
        insertNode(nodes, { ...format, type: 'text', text: (node as Element).textContent || '' });
        logger.error(`Unhandled element node found: ${node.nodeName}`);
        break;
    }
  }

  return nodes;
}

export default function deserialize(richtext: string): Descendant[] {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = richtext;

  const nodes = traverseNodes(wrapper, {
    nodes: [],
    format: {},
  });

  if (!nodes.length) {
    nodes.push({
      type: 'paragraph',
      children: [{ type: 'text', text: '' }],
    });
  }

  return nodes;
}
