import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const FileIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.74 7.33L15.3 2.33C15.2065 2.2264 15.0924 2.14353 14.9649 2.08669C14.8375 2.02986 14.6995 2.00033 14.56 2H6.56C6.22775 1.99603 5.89797 2.05756 5.5895 2.18105C5.28103 2.30455 4.9999 2.4876 4.76218 2.71975C4.52446 2.9519 4.33479 3.22861 4.20402 3.53406C4.07324 3.83952 4.00392 4.16775 4 4.5V19.5C4.00392 19.8322 4.07324 20.1605 4.20402 20.4659C4.33479 20.7714 4.52446 21.0481 4.76218 21.2802C4.9999 21.5124 5.28103 21.6954 5.5895 21.8189C5.89797 21.9424 6.22775 22.004 6.56 22H17.44C17.7723 22.004 18.102 21.9424 18.4105 21.8189C18.719 21.6954 19.0001 21.5124 19.2378 21.2802C19.4755 21.0481 19.6652 20.7714 19.796 20.4659C19.9268 20.1605 19.9961 19.8322 20 19.5V8C19.9994 7.75216 19.9067 7.51339 19.74 7.33V7.33ZM14 4L17.74 8H14.74C14.6353 7.99367 14.5329 7.96656 14.4387 7.92023C14.3446 7.8739 14.2607 7.80929 14.1918 7.73016C14.1229 7.65103 14.0704 7.55898 14.0375 7.45937C14.0046 7.35976 13.9918 7.25459 14 7.15V4Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.74 8.33L14.3 2.33C14.2065 2.2264 14.0924 2.14353 13.9649 2.08669C13.8375 2.02986 13.6995 2.00033 13.56 2H6.56C6.22775 1.99603 5.89797 2.05756 5.5895 2.18105C5.28103 2.30455 4.9999 2.4876 4.76218 2.71975C4.52446 2.9519 4.33479 3.22861 4.20402 3.53406C4.07324 3.83952 4.00392 4.16775 4 4.5V19.5C4.00392 19.8322 4.07324 20.1605 4.20402 20.4659C4.33479 20.7714 4.52446 21.0481 4.76218 21.2802C4.9999 21.5124 5.28103 21.6954 5.5895 21.8189C5.89797 21.9424 6.22775 22.004 6.56 22H17.44C17.7723 22.004 18.102 21.9424 18.4105 21.8189C18.719 21.6954 19.0001 21.5124 19.2378 21.2802C19.4755 21.0481 19.6652 20.7714 19.796 20.4659C19.9268 20.1605 19.9961 19.8322 20 19.5V9C19.9994 8.75216 19.9067 8.51339 19.74 8.33V8.33ZM17.65 9H13.71C13.5038 8.9797 13.3139 8.87923 13.1811 8.72025C13.0483 8.56126 12.9833 8.35647 13 8.15V4H13.11L17.65 9ZM17.44 20H6.56C6.49037 20.004 6.42063 19.9943 6.35477 19.9714C6.28892 19.9484 6.22824 19.9127 6.17621 19.8662C6.12419 19.8198 6.08184 19.7635 6.0516 19.7007C6.02137 19.6378 6.00383 19.5696 6 19.5V4.5C6.00383 4.43036 6.02137 4.36216 6.0516 4.29931C6.08184 4.23647 6.12419 4.1802 6.17621 4.13375C6.22824 4.0873 6.28892 4.05158 6.35477 4.02863C6.42063 4.00567 6.49037 3.99594 6.56 4H11V8.15C10.9839 8.88662 11.2598 9.59972 11.7675 10.1337C12.2752 10.6676 12.9735 10.979 13.71 11H18V19.5C17.9962 19.5696 17.9786 19.6378 17.9484 19.7007C17.9182 19.7635 17.8758 19.8198 17.8238 19.8662C17.7718 19.9127 17.7111 19.9484 17.6452 19.9714C17.5794 19.9943 17.5096 20.004 17.44 20V20Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default FileIcon;
