import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../components/Button';
import { generateClasses } from '../../utils/helpers';

export const zoomAppHeaderClasses = generateClasses('ZoomAppHeader', ['iconButton', 'dragHandle']);

export const IconButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button
      variant="text"
      {...props}
      ref={ref}
      className={clsx(zoomAppHeaderClasses.iconButton, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${zoomAppHeaderClasses.iconButton}`]: {
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      color: theme.palette.common.black,
    },
    [`&.${zoomAppHeaderClasses.dragHandle}`]: {
      cursor: 'move',
    },
  },
}));
