import { call, put, takeLatest } from 'redux-saga/effects';

import Logger from '../../utils/logger';
import { addCourseBook, editCourseBook, fetchCourseBooks, removeCourseBook } from './actions';
import {
  addCourseBook as addCourseBookAPI,
  editCourseBook as editCourseBookAPI,
  getCourseBooks,
  removeCourseBook as removeCourseBookAPI,
} from './api';

const log = Logger.create('db/books');

function* fetchCourseBooksWorker(action: ReturnType<typeof fetchCourseBooks.request>) {
  const { requestId } = action.meta;
  try {
    const books: YieldCallType<typeof getCourseBooks> = yield call(getCourseBooks, action.payload);
    yield put(fetchCourseBooks.success(requestId, { books }));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseBooks.failure(requestId));
  }
}

function* addCourseBookWorker(action: ReturnType<typeof addCourseBook.request>) {
  const { requestId } = action.meta;
  try {
    const book: YieldCallType<typeof addCourseBookAPI> = yield call(addCourseBookAPI, action.payload);
    yield put(addCourseBook.success(requestId, { book, parent: action.payload.parent }));
  } catch (error) {
    log.error(error);
    yield put(addCourseBook.failure(requestId));
  }
}

function* editCourseBookWorker(action: ReturnType<typeof editCourseBook.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editCourseBookAPI, action.payload);
    yield put(editCourseBook.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editCourseBook.failure(requestId));
  }
}

function* removeCourseBookWorker(action: ReturnType<typeof removeCourseBook.request>) {
  const { requestId } = action.meta;
  try {
    yield call(removeCourseBookAPI, action.payload);
    yield put(removeCourseBook.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(removeCourseBook.failure(requestId));
  }
}

export default function* rootBooksSaga() {
  yield takeLatest(fetchCourseBooks.request, fetchCourseBooksWorker);
  yield takeLatest(addCourseBook.request, addCourseBookWorker);
  yield takeLatest(editCourseBook.request, editCourseBookWorker);
  yield takeLatest(removeCourseBook.request, removeCourseBookWorker);
}
