import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlySlider, { Props as AcadlySliderProps } from '../FormControls/Slider';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useSliderVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlySliderProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const Slider = <T extends FieldValues>({ control, name, defaultValue, ...props }: Props<T>) => {
  const { controller } = useSliderVM({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref, value, onChange, onBlur } = field;
  const { error } = fieldState;

  return (
    <AcadlySlider
      {...props}
      ref={ref}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      aria-invalid={Boolean(error)}
    />
  );
};

export default Slider;
