import { Draft } from '@reduxjs/toolkit';

import i18n, { i18nNS } from '../../../i18n';
import { CourseRole, CourseUser } from '../../shared/types';
import { Comment } from '../types';

export function removeCommentCaseReducer({
  comment,
  user,
}: {
  comment: Draft<Comment | undefined>;
  user: CourseUser;
}) {
  if (!comment) return;

  comment.isDeleted = true;

  const { userId, role } = user;

  const isStudent = role === CourseRole.STUDENT;
  const isAuthor = comment.createdBy.userId === userId;

  if (isStudent || isAuthor) {
    comment.message = i18n.t('this_comment_was_removed_by_the_author', { ns: i18nNS.COMMENTS });
  } else {
    const isTA = role === CourseRole.TA;
    comment.message = i18n.t('this_comment_was_removed_by_article_role', {
      ns: i18nNS.COMMENTS,
      article: isTA ? 'a' : 'an',
      role: isTA ? role.toUpperCase() : `${role[0].toUpperCase()}${role.slice(1)}`,
    });
  }
}
