import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, styled } from '@mui/material';

import clsx from 'clsx';

import { FileAttachment } from '../../db/shared/types';
import { i18nNS } from '../../i18n';
import { View as RichTextViewer, ViewProps as RichTextViewerProps } from '../../rich-text';
import { AttachmentRequestConfig } from '../../types';
import AttachmentView from '../AttachmentView';
import Typography, { Props as TypographyProps } from '../Typography';

const AttachmentsLabel = styled((props: TypographyProps) => (
  <Typography variant="h6Bold" color="grey.800" {...props} />
))(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

export interface RichTextViewAttachments {
  files: FileAttachment[];
  requestConfig?: (attachment: FileAttachment) => AttachmentRequestConfig<unknown> | undefined;
}

export interface Props {
  attachments?: RichTextViewAttachments;
  children?: ReactNode;
  className?: string;
  classes?: RichTextViewerProps['classes'] &
    Partial<Record<'attachmentsHeading' | 'attachmentsList' | 'attachment', string>>;
  richtext: RichTextViewerProps['richtext'];
  tabIndex?: number;
}

const RichTextView = ({ attachments, children, className, classes, richtext, tabIndex }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  return (
    <RichTextViewer className={className} richtext={richtext} classes={classes} tabIndex={tabIndex}>
      {attachments?.files && attachments.files.length > 0 && (
        <>
          <AttachmentsLabel className={clsx(classes?.attachmentsHeading)}>
            {t('attachments', { ns: i18nNS.GLOSSARY })}
          </AttachmentsLabel>
          <Stack gap={1}>
            {attachments.files.map((attachment) => (
              <AttachmentView
                key={attachment.name}
                attachment={attachment}
                requestConfig={attachments.requestConfig?.(attachment)}
                color="success"
                className={classes?.attachment}
              />
            ))}
          </Stack>
        </>
      )}
      {children}
    </RichTextViewer>
  );
};

export default RichTextView;
