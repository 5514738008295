import { FieldValues, Path, useFormContext } from 'react-hook-form';

export interface VMProps<T extends FieldValues> {
  endTimeControlName: Path<T>;
  startTimeControlName: Path<T>;
}

export default function useClassTimingVM<T extends FieldValues>({
  endTimeControlName,
  startTimeControlName,
}: VMProps<T>) {
  const { control, trigger } = useFormContext();

  const validateTimeRange = async () => {
    await trigger([startTimeControlName, endTimeControlName]);
  };

  return { control, endTimeControlName, startTimeControlName, validateTimeRange };
}
