import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import RadioCheckedIcon from '../../../../icons.deprecated/RadioCheckedIcon';
import RadioUncheckedIcon from '../../../../icons.deprecated/RadioUncheckedIcon';
import { FormControlLabel, Props, Radio, radioOptionClasses } from './styles';
import useRadioOptionVM from './vm';

export type { Props };
export { radioOptionClasses };

const RadioOption = (
  { labelPlacement, value, children, disabled, className, classes, ...props }: Props,
  ref: Ref<HTMLInputElement>
) => {
  const { contextValue, handleClick: _handleClick } = useRadioOptionVM({ disabled });

  const handleClick = () => {
    _handleClick(value);
  };

  return (
    <FormControlLabel
      ref={ref}
      label={children}
      labelPlacement={labelPlacement}
      value={value}
      disabled={disabled}
      disableTypography={typeof children !== 'string'}
      color={props.color}
      className={clsx(radioOptionClasses.root, className, classes?.root)}
      classes={{
        root: clsx(radioOptionClasses.root, classes?.root),
        label: clsx(radioOptionClasses.label, classes?.label),
        disabled: clsx(radioOptionClasses.labelDisabled, classes?.labelDisabled),
      }}
      onClick={handleClick}
      control={
        <Radio
          {...props}
          className={clsx(radioOptionClasses.radioRoot, classes?.radioRoot, {
            [radioOptionClasses.disabled]: disabled,
            [radioOptionClasses.checked]: contextValue === value,
          })}
          classes={{
            root: clsx(radioOptionClasses.radioRoot, classes?.radioRoot),
            radio: clsx(radioOptionClasses.radio, classes?.radio),
          }}
          icon={
            <RadioUncheckedIcon
              sx={{ fontSize: 'inherit' }}
              className={clsx(radioOptionClasses.icon, classes?.icon)}
            />
          }
          checkedIcon={
            <RadioCheckedIcon
              sx={{ fontSize: 'inherit' }}
              className={clsx(radioOptionClasses.checkedIcon, classes?.checkedIcon)}
            />
          }
        />
      }
    />
  );
};

export default forwardRef(RadioOption);
