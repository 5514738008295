const en = {
  showing_all_assignments: 'Showing all assignments',
  no_assignments_have_been_published_in_this_course: 'No assignments have been published in the course',
  no_unpublished_assignments_have_been_created_in_this_course:
    'No unpublished assignments have been created in this course',
  no_assignments_have_been_created_in_this_course: 'No assignments have been created in this course',

  acadly_create_new_assignment: 'Acadly - Create new assignment',
  acadly_assignment_analytics: 'Acadly - Assignment analytics',
  click_to_close_assignment_analytics: 'click to close assignment analytics',
  select_from_suggested_assignments: 'Select from suggested assignments',
  assignment_preview: 'Assignment preview',
  editing_url: 'Editing url',
  enter_url: 'Enter url',
  click_to_save_url: 'click to save url',

  assignment_N: 'Assignment {{count}}',
  untitled_assignment: 'untitled assignment',
  published_on_date: 'Published on: {{date}}',
  create_from_scratch: 'Create from scratch',
  click_to_create_assignment_from_scratch: 'click to create assignment from scratch',
  copy_from_selected_assignment: 'Copy from selected assignment',
  click_to_copy_from_selected_assignment: 'click to copy from selected assignment',

  assignment_published_on_date_in_course_code_colon_course_title:
    'Assignment published on {{publishedOn}} in {{courseCode}}: {{courseTitle}}',
  max_marks_colon_max_marks_count: '(Max marks: {{maxMarks}})',
  acceptable_submission_formats: 'Acceptable submission formats',
  points_awarded: 'Points awarded',
  use_assignment: 'Use assignment',
  click_to_use_this_assignment: 'click to use this assignment',

  click_to_delete_assignment: 'click to delete assignment',
  are_you_sure_you_want_to_delete_this_assignment: 'Are you sure you want to delete this assignment?',

  click_to_copy_assignment_to_another_course: 'click to copy assignment to another course',
  click_to_copy_assignment: 'click to copy assignment',
  click_to_cancel_copying_assignment: 'click to cancel copying assignment',
  copy_assignment: 'Copy assignment',
  are_you_sure_you_want_to_copy_this_assignment: 'Are you sure you want to copy this assignment?',

  click_to_move_assignment_to_another_course: 'click to move assignment to another course',

  click_to_publish_assignment: 'click to publish assignment',
  assignments_need_to_have_at_least_one_question_please_add_questions_and_try_again:
    'Assignments need to have at least one question. Please add question(s) and try again.',

  publishing_assignment: 'Publishing assignment',
  due_date: 'Due date',
  click_to_reject_late_submissions: 'click to reject late submissions',
  click_to_allow_late_submissions: 'click to allow late submissions',
  students_will_not_be_able_to_make_submissions_once_the_deadline_is_over_you_won_t_be_able_to_give_students_partial_credit:
    "Students will not be able to make submissions once the deadline is over. You won't be able to give students partial credit.",
  click_to_subscribe_to_comment_notifications: 'click to subscribe to comment notifications',
  click_to_unsubscribe_from_comment_notifications: 'click to unsubscribe from comment notifications',
  once_published_the_activity_cannot_be_modified_deleted_or_moved_to_another_class:
    'Once published, the activity cannot be modified, deleted, or moved to another class.',

  click_to_edit_assignment: 'click to edit assignment',

  do_you_want_to_discard_changes_made_to_assignment: 'Do you want to discard changes made to assignment?',

  click_to_update_assignment: 'click to update assignment',
  you_cannot_edit_assignment_questions_after_some_students_have_made_submissions:
    'You cannot edit assignment questions after some students have made submissions',
  the_assignment_doesn_t_have_a_title_yet_click_on_the_edit_icon_to_add_a_title:
    "The assignment doesn't have a title yet. Click on the <2></2> icon o add.",
  click_to_save_title: 'click to save title',
  the_assignment_doesn_t_have_instructions_yet_tap_on_the_edit_icon_to_edit_general_instructions:
    "The assignment doesn't have instructions yet. Tap on the <2></2> to edit general instructions.",
  click_to_save_instructions: 'click to save instructions',
  there_are_no_special_instructions_just_attempt_the_assignment_best_of_luck:
    'There are no special instructions. Just attempt the assignment. Best of luck!',
  no_questions_added_yet_tap_on_the_add_icon_to_add_a_question:
    'No questions added yet. Tap on the <1></1> icon to add a question.',
  click_to_view_remarks: 'click to view remarks',
  remarks_dialog: 'remarks dialog',
  marks_awarded_colon_N: 'Marks awarded: {{marks}}',
  click_to_edit_question: 'click to edit question',
  click_to_delete_question: 'click to delete question',
  retract_assignment: 'Retract Assignment',
  retracting_submission: 'Retracting Submission',
  click_to_retract_assignment_submission: 'click to retract assignment submission',
  once_you_retract_your_submission_the_professor_and_course_team_members_will_no_longer_see_it:
    'Once you retract your submission, the professor and course team members will no longer see it.',
  if_the_deadline_has_passed_please_check_if_late_submissions_are_allowed:
    'If the deadline has passed, please check if late submissions are allowed.',
  are_you_sure_you_want_to_retract_this_submission: 'Are you sure you want to retract this submission?',
  attempt_assignment: 'Attempt Assignment',
  resume_assignment: 'Resume Assignment',
  the_assignment_must_be_published_by_the_creator_for_the_students_to_be_able_to_access_it:
    'The assignment must be published by the creator for the students to be able to access it.',

  add_question: 'Add question',
  click_to_add_assignment_question: 'click to add assignment question',
  assignment_question: 'Assignment question',
  click_to_go_to_previous_assignment_question: 'click to go to previous assignment question',
  click_to_go_to_next_assignment_question: 'click to go to next assignment question',
  click_to_edit_url: 'click to edit url',
  no_url_provided: 'No url provided',
  click_to_select_submission_type_as_url: 'click to select submission type as url',
  click_to_select_submission_type_as_file: 'click to select submission type as file',
  submission_format: 'Submission format',
  acadly_allows_you_to_choose_one_of_the_following_formats:
    'Acadly allows you to choose one of the following formats',
  students_provide_a_valid_web_link_as_thier_submission:
    'Students provide a valid web link as their submission',
  students_upload_a_file_with_specified_formats: 'Students upload a file with specified formats',
  acceptable_file_formats: 'Acceptable file formats',
  separate_by_commas_e_g_txt_png: 'separate by commas (e.g. txt, png)',
  click_to_remove_extension_from_acceptable_file_formats:
    'click to remove {{extension}} from acceptable file formats',
  no_formats_specified_yet: 'No formats specified yet',
  max_score: 'Max score',
  max_marks_colon_N: 'Max marks: {{maxMarks}}',
  you_can_upload_one_file_in_any_of_the_following_formats:
    'You can uplopad <1>one</1> file in any of the following formats',
  click_to_submit: 'Click to submit',
  you_uploaded: 'You uploaded',
  submitting_assignment: 'Submitting assignment',
  you_can_retract_your_submission_till_it_is_graded: 'You can retract your submission till it is graded.',
  assignment_analytics: 'Assignment Analytics',
  click_to_open_assignment_analytics: 'click to open assignment analytics',
  submissions_graded: 'Submissions graded',
  click_to_export_assignment_data_as_csv: 'click to export assignment data as csv',
  export_assignment_data_as_csv: 'Export assignment data as CSV',
  export_assignment_data: 'Export assignment data',
  graded_score_colon_score_slash_max_marks: 'Graded score: {{marks}}/{{maxMarks}}',
  once_the_students_start_submitting_the_assignment_you_will_be_able_to_access_and_grade_their_submissions_here_tas_can_grade_assignments_too:
    'Once the students start submitting the assignment, you will be able to access and grade their submissions here. TAs can grade assignments too.',
  once_you_submit_the_grades_you_cannot_edit_them: 'Once you submit the grades, you cannot edit them.',
  are_you_sure_you_want_to_submit: 'Are you sure you want to submit?',

  /** comments panel */
  assignment_discussion: 'Assignment Discussion',
  export_assignment_discussion_data_as: 'Export assignment discussion data as',
  every_student_has_access_to_this_discussion_area_irrespective_of_whether_they_have_submitted_the_assignment_or_not_it_was_designed_to_encourage_discussions_about_the_assignment_before_submitting_it:
    'Every student has access to this discussion area, irrespective of whether they have submitted the assignment or not. It was designed to encourage discussions about the assignment before submitting it.',
  in_case_you_face_any_difficulties_while_attempting_the_assignment_please_feel_free_to_use_this_discussion_thread_you_will_be_able_to_access_the_post_submission_discussion_thread_after_you_have_submitted_the_assignment:
    'In case you face any difficulties while attempting the assignment, please feel free to use this discussion thread. You will be able to access the "Post Submission" discussion thread after you have submitted the assignment',
  only_the_students_who_have_submitted_the_assignment_will_have_access_to_this_discussion_thread:
    'Only the students who have submitted the assignment will have access to this discussion thread.',

  /** assignment page onboarding tips */
  using_this_button_you_can_attach_multiple_questions_to_each_assignment_each_question_can_have_a_different_acceptable_submission_format:
    'Using this button, you can attach multiple questions to each assignment. Each question can have a different acceptable submission format',

  /** attempt assignment page onboarding tips */
  whenever_you_re_satisfied_with_your_submissions_you_can_use_this_button_to_submit_the_assignment_for_grading_please_be_careful_of_not_missing_the_deadline:
    "Whenever you're satisfied with your submissions, you can use this button to Submit the assignment for grading. Please be careful of not missing the deadline",

  click_to_open_add_assignment_question_menu: 'click to open add assignment question menu',
  click_to_close_add_assignment_question_menu: 'click to close add assignment question menu',
  click_to_retract_assignment: 'click to retract assignment',
  click_to_resume_assignment: 'click to resume assignment',
  click_to_attempt_assignment: 'click to attempt assignment',

  click_to_view_assignment_assignment_title_having_total_submission_submissioncount:
    'click to view assignment {{assignmentTitle}} having total submission {{submissioncount}}',
  click_to_view_assignment_assignment_title_with_due_date_on_date:
    'click to view assignment {{assignmentTitle}} with due date on {{date}}',
};

export default en;
