import { API } from '../shared/api-responses';
import {
  ActivityDuration,
  CourseRole,
  CourseUser,
  DueDateType,
  SubmissionStatus,
  ToBeDone,
} from '../shared/types';
import { Poll, PollQuestion, SuggestedPoll } from './types';

export function createPreferencesFactory(publishDefaults: API.PublishPollDefaults): Poll['preferences'] {
  return {
    toBeDone: publishDefaults.activityType,
    allowLate: Boolean(publishDefaults.allowLate),
    deadlineFirst: Boolean(publishDefaults.deadlineFirst),
    dueDateType: publishDefaults.dueDateType,
    duration: publishDefaults.duration,
    isAnonymous: Boolean(publishDefaults.isAnon),
    subscribeToComments: Boolean(publishDefaults.subscribeToComments),
  };
}

interface CreatePollOptions<T extends API.BasePoll> {
  poll: T;
  identifiers: { courseId: MongoId; classId: MongoId };
  publishDefaults: API.PublishPollDefaults | undefined;
  userData: API.PollUserData | undefined;
  currentUser: CourseUser | undefined;
}

export function createPollFactory<T extends API.BasePoll>({
  poll,
  userData,
  currentUser,
  publishDefaults,
  identifiers: { courseId, classId },
}: CreatePollOptions<T>): Poll {
  const editedOn = poll.details.lastEditedOn || -1;

  let studentDataById: Poll['studentDataById'] = {};

  if (currentUser?.role === CourseRole.STUDENT) {
    studentDataById = {
      [currentUser.userId]: {
        userId: currentUser.userId,
        name: currentUser.name,
        avatar: currentUser.avatar,
        submissionsByQuestionId: null,
        submittedOn: userData?.submittedOn || -1,
        firstAccessedOn: userData?.firstAccessedOn || -1,
        status: userData?.status ?? SubmissionStatus.NOT_ATTEMPTED,
      },
    };
  }

  return {
    id: poll._id,
    classId,
    courseId,
    title: poll.details.title || null,
    toBeDone: poll.details.toBeDone,
    dueDateTime: poll.details.dueDateTime,
    sequenceNum: poll.details.trueNum ?? 0,
    questions: null,
    questionsById: null,
    studentDataById,
    totalSubmissions: poll.stats?.numSubmitted ?? 0,
    areQuestionsAdded: Boolean(poll.details.questionSet),
    areQuestionsEdited: false,
    preferences: publishDefaults
      ? createPreferencesFactory(publishDefaults)
      : {
          toBeDone: poll.details.toBeDone,
          allowLate: Boolean(poll.details.allowLate),
          deadlineFirst: Boolean(poll.details.deadlineFirst),
          dueDateType: poll.details.dueDateType,
          duration:
            poll.details.toBeDone === ToBeDone.PRE_CLASS
              ? ActivityDuration.NOT_APPLICABLE
              : poll.details.dueDateType === DueDateType.MANUAL
              ? ActivityDuration.MANUALLY_STOPPED
              : ActivityDuration.AFTER_5_MINUTES, // default is After 5 Minutes
          isAnonymous: Boolean(poll.details.isAnon),
          subscribeToComments: Boolean(userData?.subscribed),
        },
    comments: {
      seen: userData?.numCommentsSeen ?? 0,
      total: poll.activities.numCommentsTotal,
      isSubscribed: Boolean(userData?.subscribed),
    },
    editedOn,
    editedBy: editedOn > 0 ? poll.details.createdBy : null,
    createdOn: poll.details.createdOn,
    createdBy: poll.details.createdBy,
    firstAccessedOn:
      currentUser?.userId === poll.details.createdBy.userId
        ? poll.details.createdOn
        : userData?.firstAccessedOn ?? -1,
    lastAccessedOn: userData?.lastAccessedOn ?? -1,
    publishedOn: poll.details.publishedOn > 0 ? poll.details.publishedOn : -1,
  };
}

interface SuggestedPollOptions<T extends API.SuggestedPoll> {
  suggestedPoll: T;
}

export function suggestedPollFactory<T extends API.SuggestedPoll>({
  suggestedPoll,
}: SuggestedPollOptions<T>): SuggestedPoll {
  return {
    id: suggestedPoll._id,
    classId: suggestedPoll.identifiers.classId,
    courseId: suggestedPoll.identifiers.courseId,
    title: suggestedPoll.details.title || null,
    toBeDone: suggestedPoll.details.toBeDone,
    questionsById: null,
    areQuestionsAdded: false,
    areQuestionsEdited: false,
    createdOn: suggestedPoll.details.createdOn,
    createdBy: suggestedPoll.details.createdBy,
    publishedOn: suggestedPoll.details.publishedOn,
    isHidden: Boolean(suggestedPoll.hidden),
    isUsed: Boolean(suggestedPoll.used),
    sequenceNum: suggestedPoll.details.trueNum,
    courseCode: suggestedPoll.identifiers.courseCode,
    courseTitle: suggestedPoll.identifiers.courseTitle,
    questions: null,
    publishedOnDate: suggestedPoll.details.publishedOnDate,
  };
}

export function createPollQuestionFactory(question: API.PollQuestion): PollQuestion {
  return {
    id: question._id,
    description: question.details.description.text,
    attachments: question.details.description.attachments || [],
    totalAttempts: question.stats?.numAttempted ?? 0,
    options: question.details.options.map((option) => {
      const stats = question.stats?.optionSelection?.find((s) => option.num === s.num);
      return {
        ...option,
        totalSelections: stats?.numSelected ?? 0,
      };
    }),
  };
}
