import AES from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';

import Logger from '../../utils/logger';
import { UserSession } from '../types';

const SESSION_KEY = 'session';
const SESSION_SECRET = process.env.REACT_APP_SESSION_SECRET || 'secret';

/**
 * Stores encrypted session-data to browser local-storage to make state persistent
 * @param user session data of user
 */
export function setSession(session: UserSession & { isAuthenticated?: boolean }) {
  const sessionStr = JSON.stringify(session);
  const sessionCipher = AES.encrypt(sessionStr, SESSION_SECRET).toString();
  window.localStorage.setItem(SESSION_KEY, sessionCipher);
}

/**
 * Delete user's session-data from broswer local-storage
 */
export function clearSession() {
  window.localStorage.clear();
}

/**
 * Gets and decrypts the user's session-data from browser local-storage
 */
export function getSession(): (UserSession & { isAuthenticated?: boolean }) | null {
  const sessionCipher = window.localStorage.getItem(SESSION_KEY);

  if (!sessionCipher) return null;

  try {
    const bytes = AES.decrypt(sessionCipher, SESSION_SECRET);
    return JSON.parse(bytes.toString(utf8));
  } catch (error) {
    Logger.error(error);
    return null;
  }
}
