import urls from '../app/urls';
import { getCanSendAssignmentComments, getEmptyCommentsMessageForAssignment } from '../assignment/helpers';
import { getCanSendClassComments, getEmptyCommentsMessageForClass } from '../class/helpers';
import { getCanSendCourseComments, getEmptyCommentsMessageForCourse } from '../courses/helpers';
import { Comment } from '../db/comments/types';
import { Discussion } from '../db/discussions/types';
import {
  AttachmentType,
  CommentContext,
  CourseUser,
  DiscussionAnonymity,
  UserWithRole,
} from '../db/shared/types';
import { getCanSendDiscussionComments, getEmptyCommentsMessageForDiscussion } from '../discussion/helpers';
import i18n, { i18nNS } from '../i18n';
import { getCanSendPollComments, getEmptyCommentsMessageForPoll } from '../poll/helpers';
import { getCanSendQueryComments, getEmptyCommentsMessageForQuery } from '../query/helpers';
import { getCanSendQuizComments, getEmptyCommentsMessageForQuiz } from '../quiz/helpers';
import { getCanSendResourceComments, getEmptyCommentsMessageForResource } from '../resource/helpers';
import { CourseRole } from '../types';
import { CommentAttachment, ContextData } from './types';

export const CommentAuditors = [CourseRole.ADMIN, CourseRole.INSTRUCTOR];

export function getImageThumbUrl(url: string) {
  const decodedUrl = window.decodeURIComponent(url);
  const urlStringInParts = decodedUrl.split('/i/');
  if (urlStringInParts.length !== 2) return url;
  return `${urlStringInParts[0]}/i/comm/th/${urlStringInParts[1]}`;
}

export function getCommentAttachment<
  T extends {
    id: Comment['id'];
    image: Comment['image'];
    files: Comment['files'];
  }
>(comment: T | undefined): CommentAttachment | null {
  if (!comment) return null;

  if (comment.image) {
    return {
      type: AttachmentType.IMAGE,
      imageURL: comment.image,
      thumbURL: getImageThumbUrl(comment.image),
    };
  }

  if (comment.files.length) {
    const [attachment] = comment.files;
    return {
      ...attachment,
      type: AttachmentType.FILE,
      requestConfig: {
        data: { commentId: comment.id, fileName: attachment.name },
        method: 'GET',
        url: urls.downloadCommentFiles,
      },
    };
  }

  return null;
}

export function getTotalAwardPoints<
  T extends {
    stats: { awards: Comment['stats']['awards'] };
  }
>({ stats: { awards } }: T): number {
  return awards.reduce((points, award) => (points += award.points), 0);
}

export function getIsCommentAuditor(currentUser: CourseUser) {
  return CommentAuditors.includes(currentUser.role);
}

export function getCanShowAwardsInComment({
  comment,
  context,
  discussion,
  currentUser,
}: {
  comment: { createdBy: Comment['createdBy'] } | undefined;
  context: CommentContext | undefined;
  discussion: Discussion | undefined;
  currentUser: CourseUser;
}) {
  if (context !== CommentContext.DISCUSSION) return true;
  if (!discussion) return false;

  const isStudent = currentUser.role === CourseRole.STUDENT;

  if (isStudent) {
    const isCommentCreator = comment?.createdBy.userId === currentUser.userId;
    return !discussion.preferences.hideAwards || isCommentCreator;
  }

  const isDiscussionAnonymityToStudents = discussion.preferences.anonymity === DiscussionAnonymity.STUDENTS;
  return !discussion.preferences.anonymize || isDiscussionAnonymityToStudents;
}

export function getCanAwardComment(args: {
  comment: { createdBy: Comment['createdBy']; isDeleted: Comment['isDeleted'] } | undefined;
  context: CommentContext | undefined;
  discussion: Discussion | undefined;
  currentUser: CourseUser;
}) {
  const { comment, currentUser } = args;

  if (comment?.isDeleted) return false;
  if (comment?.createdBy.role !== CourseRole.STUDENT) return false;

  const isCommentAuditor = getIsCommentAuditor(currentUser);
  if (!isCommentAuditor) return false;

  const canShowAwardsInComment = getCanShowAwardsInComment(args);
  return canShowAwardsInComment;
}

export function getCanRemoveComment(
  comment: Pick<Comment, 'createdBy' | 'createdOn' | 'isDeleted'> | undefined,
  currentUser: CourseUser
) {
  if (!comment) return false;
  if (comment.isDeleted) return false;
  if (comment.createdBy.userId === currentUser.userId) return true;
  return getIsCommentAuditor(currentUser);
}

export function getCanSendComment(
  contextData: ContextData,
  currentUser: UserWithRole<CourseRole>,
  isCourseArchived: boolean | undefined
) {
  if (!contextData) return false;

  switch (contextData.type) {
    case CommentContext.COURSE:
      return getCanSendCourseComments(isCourseArchived);
    case CommentContext.ASSIGNMENT:
      return getCanSendAssignmentComments(contextData.activity, isCourseArchived);
    case CommentContext.CLASS:
      return getCanSendClassComments(isCourseArchived);
    case CommentContext.QUIZ:
      return getCanSendQuizComments(contextData.activity, currentUser, isCourseArchived);
    case CommentContext.POLL:
      return getCanSendPollComments(contextData.activity, isCourseArchived);
    case CommentContext.DISCUSSION:
      return getCanSendDiscussionComments(contextData.activity, isCourseArchived);
    case CommentContext.RESOURCE:
      return getCanSendResourceComments(contextData.activity, isCourseArchived);
    case CommentContext.QUERY:
      return getCanSendQueryComments(contextData.activity, isCourseArchived);
    default:
      return false;
  }
}

export function getEmptyCommentsMessage(contextData: ContextData, currentUser: UserWithRole<CourseRole>) {
  if (!contextData) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  switch (contextData.type) {
    case CommentContext.COURSE:
      return getEmptyCommentsMessageForCourse();
    case CommentContext.ASSIGNMENT:
      return getEmptyCommentsMessageForAssignment(contextData.subType, currentUser);
    case CommentContext.CLASS:
      return getEmptyCommentsMessageForClass();
    case CommentContext.QUIZ:
      return getEmptyCommentsMessageForQuiz(contextData.activity, currentUser);
    case CommentContext.POLL:
      return getEmptyCommentsMessageForPoll(contextData.activity);
    case CommentContext.DISCUSSION:
      return getEmptyCommentsMessageForDiscussion(contextData.activity, currentUser);
    case CommentContext.RESOURCE:
      return getEmptyCommentsMessageForResource(contextData.activity, currentUser);
    case CommentContext.QUERY:
      return getEmptyCommentsMessageForQuery(contextData.activity);
    default:
      return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });
  }
}
