import { keyframes, styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../components/Typography';
import { generateClasses } from '../../../utils/helpers';
import { ATTENDANCE_GIF_0, ATTENDANCE_GIF_20, ATTENDANCE_GIF_40, ATTENDANCE_GIF_80 } from '../../constants';

export const attendanceInProgressDialogClasses = generateClasses('AttendanceInProgressDialog', [
  'highlighted',
]);

export const StyledTypography = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({
  [`&.${attendanceInProgressDialogClasses.highlighted}`]: {
    color: theme.palette.warning[500],
    ...theme.typography.h6Bold,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export const Instruction = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const attendancegif = keyframes`
0% { background-image: url('${ATTENDANCE_GIF_0}')}
20% { background-image: url('${ATTENDANCE_GIF_20}') }
40% { background-image: url('${ATTENDANCE_GIF_40}') }
80% { background-image: url('${ATTENDANCE_GIF_80}') }
`;

export const AttendanceGif = styled('div')(({ theme }) => ({
  animation: `${attendancegif} 1s linear infinite`,
  backgroundImage: `url('${ATTENDANCE_GIF_0}')`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  padding: theme.spacing(4),
  height: theme.spacing(12),
}));
