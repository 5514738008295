import { useSelector } from 'react-redux';

import { DownstreamZoomAppEvent } from '@acadly/zoom-app';

import { selectClass } from '../../../db/classes/selectors';
import { lowerHand, raiseHand } from '../../../db/online-meeting/actions';
import { selectCurrentParticipant } from '../../../db/online-meeting/selectors';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';
import { getCanLeaveMeeting, getCanLowerHand, getCanRaiseHand, getMeetingStatusText } from './helpers';

export default function useParticipantFooterViewVM() {
  const requestDispatch = useRequestDispatch();

  const {
    appRef: zoomAppRef,
    state: { classId },
    disableAutoJoin,
  } = useZoomMeetingContext();

  const cls = useSelector(selectClass(classId));

  const participant = useSelector(selectCurrentParticipant(classId));

  const meetingStatusText = getMeetingStatusText(participant);

  const canRaiseHand = getCanRaiseHand(participant, cls?.myRole);

  const handleClickRaiseHand = async () => {
    await requestDispatch(raiseHand, {
      classId,
    });
  };

  const canLowerHand = getCanLowerHand(participant, cls?.myRole);

  const handleClickLowerHand = async () => {
    await requestDispatch(lowerHand, {
      classId,
    });
  };

  const canLeaveMeeting = getCanLeaveMeeting(participant);

  const handleClickLeaveMeeting = () => {
    zoomAppRef.current?.sendEvent({
      type: DownstreamZoomAppEvent.LEAVE_MEETING,
    });

    // student leaving manually so disbale auto joining meeting
    disableAutoJoin();
  };

  return {
    meetingStatusText,
    canRaiseHand,
    handleClickRaiseHand,
    canLowerHand,
    handleClickLowerHand,
    canLeaveMeeting,
    handleClickLeaveMeeting,
  };
}
