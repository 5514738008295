import { AttachmentProps, FileProps } from './types';

export type Props<T> = (AttachmentProps<T> | FileProps) & {
  /**
   * Flag to show file extension name in attachment file name
   * @default true
   */
  showExtensionInName?: boolean;
};

const useAttachmentViewVM = <T>({ showExtensionInName, ...props }: Props<T>) => {
  const getFileNameAndExtension = () => {
    let originalName = '';
    let extension = '';

    if (!props.variant || props.variant === 'attachment') {
      originalName = props.attachment.originalName;
      extension = props.attachment.extension;
    } else if (props.variant === 'file') {
      const fileNameParts = props.fileName.split('.');
      extension = fileNameParts.pop() || '';
      originalName = fileNameParts.join();
    }

    return {
      fileName: originalName + (showExtensionInName && extension ? `.${extension}` : ''),
      extension,
    };
  };
  const { fileName, extension } = getFileNameAndExtension();

  return { fileName, extension };
};

export default useAttachmentViewVM;
