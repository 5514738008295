import { forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';

import { ButtonBase, styled, Zoom } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';

const fabClasses = generateClasses('Fab', ['outline', 'outlineDisabled', 'button']);

const Outline = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 16,
  width: 80,
  height: 80,
  [`&:not(.${fabClasses.outlineDisabled}):focus-within`]: {
    background: theme.palette.primary[200],
    border: `4px solid ${theme.palette.primary[100]}`,
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  gap: theme.spacing(3),
  width: 54,
  height: 54,
  borderRadius: 10,
  border: 'none',
  backgroundColor: theme.palette.primary[600],
  color: theme.palette.common.white,

  '&:focused': {
    boxShadow: theme.acadlyShadows.xl,
  },
}));

interface FabProps extends HTMLAttributes<HTMLButtonElement> {
  className?: string;
  classes?: Partial<typeof fabClasses>;
  children: ReactNode;
  /** @default false */
  disableOutline?: boolean;
}

const Fab = (
  { className, classes, children, disableOutline, ...props }: FabProps,
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <Zoom in>
      <Outline
        className={clsx(fabClasses.outline, className, classes?.outline, {
          [fabClasses.outlineDisabled]: disableOutline,
        })}
      >
        <Button ref={ref} className={clsx(fabClasses.button, classes?.button)} {...props}>
          {children}
        </Button>
      </Outline>
    </Zoom>
  );
};

export default forwardRef(Fab);
