import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import Switch from '../../../components/HookFormControls/Switch';
import { BlueprintCoursePreview } from '../../../db/courses/types';
import { i18nNS } from '../../../i18n';
import { FormValues } from './vm';

interface Props {
  control: Control<FormValues, object>;
  description?: BlueprintCoursePreview['description'];
  isSubmitting: boolean;
}

const CopyCourseDescription = ({ control, description, isSubmitting }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6Bold" component="label">
          {t('copy_course_description', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Switch
          name="description"
          control={control}
          edge="end"
          inputProps={{
            'aria-label': t('click_to_select_copy_course_description', { ns: i18nNS.CREATE_COURSE }),
            role: 'switch',
          }}
          disabled={!description || isSubmitting}
        />
      </Stack>
      {description ? (
        <Typography>{description}</Typography>
      ) : (
        <Typography>{t('this_course_has_no_description_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
      )}
    </Stack>
  );
};

export default CopyCourseDescription;
