import { Skeleton, Stack, StackProps, styled } from '@mui/material';

import AcadlyCourseWidgetSkeleton from '../../../courses/CourseWidget/Skeleton';

const Root = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" gap={3} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  /** margins provided by material ui to form control label */
  marginLeft: theme.spacing(-2.75),
  marginRight: theme.spacing(4),
}));

const RadioWrapper = styled('div')(({ theme }) => ({
  padding: `calc(${theme.spacing(2)} + 1px)`,
  marginTop: theme.spacing(1.8),
}));

const CourseWidgetSkeleton = styled(AcadlyCourseWidgetSkeleton)(({ theme }) => ({
  flex: 1,
}));

const RadioCourseSkeleton = () => {
  return (
    <Root>
      <RadioWrapper>
        <Skeleton variant="circular" height={20} width={20} />
      </RadioWrapper>
      <CourseWidgetSkeleton />
    </Root>
  );
};

export default RadioCourseSkeleton;
