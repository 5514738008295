interface FileName {
  name: string;
  extension: string;
}

/**
 * Splits file name into name and its extension
 * @param fullName complete file name
 * @deprecated - use splitFileNameAndExtension
 */
export function getFileNameComponents(fullName: string, alternateName?: string): FileName {
  const parts = fullName.split('.');
  if (parts.length < 2) {
    if (alternateName) return { name: parts[0], extension: getFileNameComponents(alternateName).extension };
    return { name: parts[0], extension: '' };
  } else {
    return {
      name: parts.slice(0, parts.length - 1).join('.'),
      extension: parts[parts.length - 1],
    };
  }
}

/**
 * Splits file name into name and its extension
 * @param originalFileName file name (with or without extension)
 * @param fileName file name (random characters) with extension
 */
export function splitFileNameAndExtension(originalFileName: string, fileName?: string) {
  let extension = '';

  if (fileName) extension = fileName.split('.').pop() ?? '';
  if (!extension) extension = originalFileName.split('.').pop() ?? '';

  const doesOriginalFileNameContainExtension = originalFileName.split('.').at(-1) === extension;
  if (!doesOriginalFileNameContainExtension) return { name: originalFileName, extension };

  const parts = originalFileName.split('.');
  const name = parts.slice(0, parts.length - 1).join('.');

  return { name, extension };
}

/**
 * Converts a file blob to base64 encoding
 * @param file input file to be converted to base64 encoding
 */
export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Convets a base64 encoded URI to File object
 * @param name filename
 * @param dataURI base64 encoded URI
 */
export function base64ToFile(name: string, dataURI: string): File {
  const byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], name, { type: mimeString });

  return file;
}

export function getImageSizeFromUrl(url: string, signal?: AbortSignal): Promise<number> {
  return new Promise<number>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url);

    const onCancel = () => {
      xhr.abort();
      reject('Request cancelled.');
    };

    if (signal) {
      signal.addEventListener('abort', onCancel, { once: true });
    }

    xhr.addEventListener('error', reject);
    xhr.addEventListener('load', function (data) {
      resolve((data as any).total);
    });

    xhr.send();
  });
}
