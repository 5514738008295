import { styled, Typography, TypographyProps } from '@mui/material';

import { generateClasses } from '../../../../../../../../../utils/helpers';
import CommentStats from '../../CommentStats';
import CommentStatsButton from '../../CommentStatsButton';

export const commentDetailsClasses = {
  ...generateClasses('CommentDetails', ['accordion', 'accordionIcon']),
};

export const RemovedCommentContent = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" {...props} />
))(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.grey[500],
}));

export const Image = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  maxWidth: '100%',
}));

export const StyledCommentStatsButton = styled(CommentStatsButton)(({ theme }) => ({
  [`&.${commentDetailsClasses.accordion}`]: {
    [`& .${commentDetailsClasses.accordionIcon}`]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
}));

export const StyledCommentStats = styled(CommentStats)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}));
