import urls from '../../app/urls';
import request from '../../utils/request';
import { AuthAPI } from './api.types';
import { StudentSignupPayload, TeacherSignupPayload } from './types';

export async function getServer(params: AuthAPI.GetServerRequest) {
  const { data } = await request.get<AuthAPI.GetServerResponse>(urls.server, {
    baseURL: process.env.REACT_APP_API_GATEWAY,
    params,
  });
  return data;
}

export async function getSsoStatus(params: AuthAPI.GetSsoStatusRequest) {
  const { data } = await request.get<AuthAPI.GetSsoStatusResponse>(urls.ssoStatus(params.sessionId), {
    baseURL: process.env.REACT_APP_API_GATEWAY,
  });
  return data;
}

export async function getUserRegistration(baseURL: string, payload: AuthAPI.GetUserRegistrationRequest) {
  const { data } = await request.post<AuthAPI.GetUserRegistrationResponse>(urls.email, payload, { baseURL });
  return data;
}

export async function login(payload: AuthAPI.LoginRequest) {
  const response = await request.post<AuthAPI.LoginResponse>(urls.login, payload, {
    validateStatus: (status) => status >= 200 && status < 500,
  });

  if (response.status === 409) {
    const data = response.data as AuthAPI.InvalidPasswordResponse;
    return { isSuccess: false as const, message: data.message };
  }

  return {
    isSuccess: true as const,
    data: response.data as AuthAPI.ProfileNotCompletedResponse | AuthAPI.LoginSuccessResponse,
  };
}

export async function ssoLogin(baseURL: string, payload: AuthAPI.SsoLoginRequest) {
  const _payload = {
    ...payload,
    agent: 'web',
  };

  const { data } = await request.post<AuthAPI.SsoLoginResponse>(urls.ssoLogin, _payload, {
    baseURL,
  });

  return data;
}

export async function forgotPassword(payload: AuthAPI.ForgotPasswordRequest) {
  const { data } = await request.post<AuthAPI.ForgotPasswordResponse>(urls.forgotPassword, payload);
  return data;
}

export async function resendTempPass(payload: AuthAPI.ResendTempPassRequest) {
  await request.post<unknown>(urls.resendTempPass, payload);
}

export async function verifyTempPass(payload: AuthAPI.VerifyTempPassRequest) {
  const response = await request.post<AuthAPI.VerifyTempPassResponse>(urls.verifyTempPass, payload, {
    validateStatus: (status) => status >= 200 && status < 500,
  });

  if (response.status === 409) {
    const data = response.data as AuthAPI.VerifyTempPassFailedResponse;
    return { isSuccess: false as const, message: data.message };
  }

  return {
    isSuccess: true as const,
    next: (response.data as AuthAPI.VerifyTempPassSuccessResponse).next,
  };
}

export async function setPassword(payload: AuthAPI.SetPasswordRequest) {
  const { data } = await request.post<AuthAPI.SetPasswordResponse>(urls.setPassword, payload);
  return data;
}

export async function createProfile(token: string, payload: AuthAPI.CreateProfileRequest) {
  return await request.post<unknown>(
    urls.createProfile,
    { ...payload, sex: 'undisclosed' },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

export async function setAvatar(payload: AuthAPI.SetAvatarRequest, token: string | undefined) {
  const formData = new FormData();

  for (const key in payload) {
    formData.append(key, payload[key as keyof AuthAPI.SetAvatarRequest] as any);
  }

  if (token) {
    const { data } = await request.post<AuthAPI.SetAvatarResponse>(urls.changeAvatar, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return data;
  }

  const { data } = await request.post<AuthAPI.SetAvatarResponse>(urls.changeAvatar, formData);
  return data;
}

export async function logout() {
  return await request.get<unknown>(urls.logout);
}

export async function teacherSignup(payload: TeacherSignupPayload) {
  const _payload: AuthAPI.TeacherSignupRequest = payload.universityExists
    ? {
        ...payload,
        marketing: payload.marketing ? 1 : 0,
        universityExists: 1,
      }
    : {
        ...payload,
        marketing: payload.marketing ? 1 : 0,
        universityExists: 0,
        organization: payload.organization,
        useCase: payload.useCase,
      };

  const { data } = await request.post<AuthAPI.TeacherSignupResponse>(urls.signupTeacher, _payload, {
    baseURL: process.env.REACT_APP_API_GATEWAY,
  });
  return data;
}

export async function getCourseByCode(payload: AuthAPI.GetCourseByCodeRequest) {
  const { data } = await request.get<AuthAPI.GetCourseByCodeResponse>(urls.courseByCode(payload.joinCode), {
    baseURL: process.env.REACT_APP_API_GATEWAY,
  });
  return data;
}

export async function studentSignup(payload: StudentSignupPayload) {
  const _payload: AuthAPI.StudentSignupRequest = {
    ...payload,
    marketing: payload.marketing ? 1 : 0,
  };

  const { data } = await request.post<AuthAPI.StudentSignupResponse>(urls.signupStudent, _payload, {
    baseURL: process.env.REACT_APP_API_GATEWAY,
  });
  return data;
}

export async function changePassword(payload: AuthAPI.ChangePasswordRequest) {
  await request.post(urls.changePassword, payload);
}

export async function getChangeNameStatus() {
  const { data } = await request.get<AuthAPI.GetChangeNameStatusResponse>(urls.changeNameStatusResponse);
  return data;
}

export async function changeName(payload: AuthAPI.ChangeNameRequest) {
  await request.post(urls.changeName, payload);
}

export async function cancelFacultyAccountVerification() {
  const { data } = await request.put<AuthAPI.CancelFacultyAccountVerificationResponse>(
    urls.cancelFacultyAccountVerification
  );
  return data;
}
