import { forwardRef, Ref } from 'react';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import clsx from 'clsx';

import TextFieldBase from '../../../components/FormControls/TextFieldBase';
import { omit } from '../../../utils/helpers';
import { countries } from './countries';
import { Listbox, PopupIcon, Props, StyledAutocomplete } from './styles';
import useCountryPickerVM from './vm';

export type { Props };

const CountryPicker = (
  {
    label,
    size = 'medium',
    required = false,
    error = false,
    helperIcon,
    helperText,
    disabled,
    className,
    classes,
    ...props
  }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const { getFlagImageAttributes, ids } = useCountryPickerVM(props);

  return (
    <StyledAutocomplete
      {...props}
      ref={ref}
      disableClearable
      options={countries}
      id={ids.countryPicker}
      className={clsx(className, classes?.root)}
      isOptionEqualToValue={(a, b) => a.code === b.code}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemIcon>
            <img loading="lazy" alt="" {...getFlagImageAttributes(option)} />
          </ListItemIcon>
          <ListItemText>
            {option.label} ({option.code}) +{option.phone}
          </ListItemText>
        </ListItem>
      )}
      renderInput={({ ...params }) => {
        const { InputLabelProps, InputProps, ...restParams } = params;
        const { ref, startAdornment, endAdornment, className } = InputProps;
        return (
          <TextFieldBase
            {...restParams}
            label={label}
            required={required}
            /** Props applied to Input tag */
            InputBaseRef={ref}
            classes={{
              ...(classes ? omit(classes, 'root', 'textFieldRoot', 'popupIcon') : {}),
              root: classes?.textFieldRoot,
              inputBase: clsx(className, classes?.inputBase),
            }}
            startAdornment={
              startAdornment ||
              (props.value ? (
                <img loading="lazy" alt="" {...getFlagImageAttributes(props.value)} />
              ) : undefined)
            }
            endAdornment={endAdornment}
            size={size}
            error={error}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            disabled={disabled}
            /** Props applied to HelperText */
            helperIcon={helperIcon}
            helperText={helperText}
          />
        );
      }}
      popupIcon={<PopupIcon className={classes?.popupIcon} />}
      ListboxComponent={Listbox}
      disabled={disabled}
    />
  );
};

export default forwardRef(CountryPicker);
