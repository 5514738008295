import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@mui/material';

import { i18nNS } from '../../../i18n';
import DownloadIcon from '../../../icons/DownloadIcon';
import Button from '../../Button';
import Progressbar from '../../Progressbar';
import useAttachmentVM, { Props as VMProps } from './vm';

export interface Props<T> extends VMProps<T> {
  fileName: string;
  disabled?: boolean;
}

const Attachment = <T,>({ fileName, disabled, ...vmOptions }: Props<T>) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { requestConfig } = vmOptions;
  const { fetchDownloadURL, isFetchingURL, downloadURL } = useAttachmentVM(vmOptions);

  const handleDownloadClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    if (disabled) event.preventDefault();
  };

  const handleDownloadIconClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    fetchDownloadURL();
  };

  if (isFetchingURL) {
    return <Progressbar shape="circular" variant="indeterminate" size={24} />;
  }

  if (downloadURL) {
    return (
      <Link
        href={downloadURL}
        target="_blank"
        rel="noreferrer"
        download={fileName}
        onClick={handleDownloadClick}
        aria-label={t('click_to_download_attachment', { ns: i18nNS.COMMON })}
      >
        {t('download', { ns: i18nNS.GLOSSARY })}
      </Link>
    );
  }

  if (requestConfig) {
    return (
      <Button
        variant="text"
        color="success"
        size="small"
        edge="end"
        disabled={disabled}
        onClick={handleDownloadIconClick}
        aria-label={t('click_to_get_download_url_of_attachment', { ns: i18nNS.COMMON })}
      >
        <DownloadIcon color="inherit" aria-hidden />
      </Button>
    );
  }

  return null;
};

export default Attachment;
