export const getPresetAvatars = (baseUrl: string, avatarPrefix: string) => {
  const prefix = avatarPrefix ? `${avatarPrefix}-` : '';
  return [
    {
      alt: 'Female avatar 1',
      src: `https://${baseUrl}${prefix}default-01.png`,
    },
    {
      alt: 'Female avatar 2',
      src: `https://${baseUrl}${prefix}default-02.png`,
    },
    {
      alt: 'Female avatar 3',
      src: `https://${baseUrl}${prefix}default-03.png`,
    },
    {
      alt: 'Female avatar 4',
      src: `https://${baseUrl}${prefix}default-04.png`,
    },
    {
      alt: 'Male avatar 1',
      src: `https://${baseUrl}${prefix}default-05.png`,
    },
    {
      alt: 'Male avatar 2',
      src: `https://${baseUrl}${prefix}default-06.png`,
    },
    {
      alt: 'Male avatar 3',
      src: `https://${baseUrl}${prefix}default-07.png`,
    },
    {
      alt: 'Male avatar 4',
      src: `https://${baseUrl}${prefix}default-08.png`,
    },
    {
      alt: 'Faceless avatar 1',
      src: `https://${baseUrl}default.png`,
    },
    {
      alt: 'Faceless avatar 2',
      src: `https://${baseUrl}default-09.png`,
    },
    {
      alt: 'Faceless avatar 3',
      src: `https://${baseUrl}default-10.png`,
    },
    {
      alt: 'Faceless avatar 4',
      src: `https://${baseUrl}default-11.png`,
    },
  ];
};
