import { Drawer, styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';

export const drawerClasses = {
  ...generateClasses('Drawer', ['root', 'paper', 'header', 'body', 'footer']),
};

export const DrawerRoot = styled(Drawer)(({ theme }) => ({
  [`&.${drawerClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${drawerClasses.paper}`]: {
    width: 600,
    maxWidth: '100%',
    overflowY: 'unset',
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${drawerClasses.body}`]: {
    flex: 1,
  },
}));

export const Body = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(5, 8),
  [forMobile(theme)]: {
    padding: theme.spacing(4),
  },
}));

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(5),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.grey[800],
  padding: theme.spacing(5, 8),
  [forMobile(theme)]: {
    padding: theme.spacing(4),
  },
}));
