import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useRequestDispatch } from '../../utils/request-actions';
import { AuthContext } from '../Context';
import { teacherSignup } from '../store/actions';
import { AuthScreen, NextScreen, SignupTeacherOrganization } from '../types';
import { useCasesType1, useCasesType2 } from './constants';
import { FormValues } from './types';
import { validationSchema } from './validators';

export enum VALIDATION_TYPES {
  EMAIL_ALREADY_EXISTS = 'emailAlreadyExists',
}

const useCreateTrialAccountVM = () => {
  const requestDispatch = useRequestDispatch();

  const { email, marketing, setScreen } = useContext(AuthContext);

  const [useCases, setUseCases] = useState(useCasesType1);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      fullName: '',
      website: '',
      organization: '',
      useCase: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { watch, setValue } = formMethods;
  const organization = watch('organization');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { fullName, website, organization, useCase } = data;

    if (!organization || !useCase) return;

    const { next } = await requestDispatch(teacherSignup, {
      emailId: email,
      marketing,
      universityExists: false,
      name: fullName,
      website,
      organization,
      useCase,
    });

    switch (next) {
      case NextScreen.THANK_YOU:
        setScreen(AuthScreen.THANK_YOU);
        break;
      case NextScreen.VERIFICATION_AWAITED:
        setScreen(AuthScreen.VERIFICATION_AWAITED);
        break;
      case NextScreen.PASSWORD_NOT_SET:
        setScreen(AuthScreen.VERIFY_EMAIL);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setScreen(AuthScreen.DEFAULT);
  };

  useEffect(
    function onChangeOrganization() {
      setValue('useCase', '');
      switch (organization) {
        case SignupTeacherOrganization.UNIVERSITY:
        case SignupTeacherOrganization.K12:
        case SignupTeacherOrganization.VOCATIONAL:
          setUseCases(useCasesType1);
          break;
        case SignupTeacherOrganization.COMPANY:
        case SignupTeacherOrganization.NON_PROFIT:
        case SignupTeacherOrganization.INDEPENDENT:
          setUseCases(useCasesType2);
          break;
      }
    },
    [organization, setValue]
  );

  return {
    formMethods,
    onSubmit,
    useCases,
    handleBack,
  };
};

export default useCreateTrialAccountVM;
