import { parseLinks } from '../../../../utils/links';
import { usePaymentWallContext } from '../../Context';
import { PaymentStep } from '../../types';
import { useCoursePaymentContext } from '../Context';

export default function usePaymentFailedVM() {
  const { currentPaymentStep, setCurrentPaymentStep } = usePaymentWallContext();
  const { closeDrawer } = useCoursePaymentContext();

  const isVisible = currentPaymentStep.name === PaymentStep.PAYMENT_FAILED;

  const children = parseLinks(
    currentPaymentStep.name === PaymentStep.PAYMENT_FAILED ? currentPaymentStep.error : ''
  );

  const retryPayment = () => setCurrentPaymentStep({ name: PaymentStep.PAY_VIA_CARD });

  return { children, closeDrawer, isVisible, retryPayment };
}
