import { useEffect, useState } from 'react';

import { Stack, styled } from '@mui/material';

import Button from '../components/Button';
import AcadlyExpandableList from '../components/ExpandableList';
import TextField from '../components/FormControls/TextField';
import Typography from '../components/Typography';
import Edit2Icon from '../icons/Edit2Icon';
import PlusIcon from '../icons/PlusIcon';
import Trash2Icon from '../icons/Trash2Icon';
import { generateClasses } from '../utils/helpers';

const EditSummary = ({
  item,
  isAnyExpanded,
  isExpanded,
  onToggle,
  setItems,
  setItemsById,
}: {
  item: { id: string; name: string };
  isAnyExpanded: boolean;
  isExpanded: boolean;
  onToggle: () => void;
  setItems: React.Dispatch<React.SetStateAction<string[]>>;
  setItemsById: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        id: string;
        name: string;
      };
    }>
  >;
}) => {
  const handleDelete = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setItems((items) => items.filter((itemId) => itemId !== item.id));
    setItemsById((currentItemsById) => {
      const _currentItemsById = currentItemsById;
      delete _currentItemsById[item.id];
      return _currentItemsById;
    });
    if (isExpanded) onToggle();
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
      <span>{item.name}</span>
      <div>
        {!isExpanded && (
          <Button variant="text" disabled={isAnyExpanded} onClick={onToggle} startIcon={<Edit2Icon />} />
        )}
        <Button variant="text" edge="end" onClick={handleDelete} startIcon={<Trash2Icon />} />
      </div>
    </Stack>
  );
};

const UpsertDetails = ({
  item,
  onToggle,
  items,
  setItems,
  setItemsById,
}: {
  item?: { id: string; name: string };
  onToggle: () => void;
  items: string[];
  setItems: React.Dispatch<React.SetStateAction<string[]>>;
  setItemsById: React.Dispatch<
    React.SetStateAction<{
      [key: string]: {
        id: string;
        name: string;
      };
    }>
  >;
}) => {
  const [value, setValue] = useState(item?.name ?? '');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleUpsert = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    if (item) {
      setItemsById((currentItemsById) => ({
        ...currentItemsById,
        [item.id]: {
          ...currentItemsById[item.id],
          name: value,
        },
      }));
    } else {
      const newId = (items[items.length - 1] as unknown as number) + 1;
      setItemsById((currentItemsById) => ({
        ...currentItemsById,
        [newId]: {
          id: `${newId}`,
          name: value,
        },
      }));
      setItems((items) => [...items, `${newId}`]);
    }
    onToggle();
  };

  useEffect(() => {
    if (item) {
      setValue(item.name);
    }
  }, [item]);

  return (
    <Stack alignItems="flex-end" width="100%">
      <TextField fullWidth name="name" value={value} onChange={handleNameChange} />
      <Stack direction="row" alignItems="center" mt={2}>
        <Button onClick={onToggle}>Cancel</Button>
        <Button onClick={handleUpsert}>{item ? 'Update' : 'Add'}</Button>
      </Stack>
    </Stack>
  );
};

const expandableListPlaygroundClasses = generateClasses('ExpandableListPlayground', [
  'summaryRoot',
  'summaryContent',
]);

const ExpandableList = styled(AcadlyExpandableList)(({ theme }) => ({
  [`& .${expandableListPlaygroundClasses.summaryRoot}`]: {
    padding: 0,
  },
  [`& .${expandableListPlaygroundClasses.summaryContent}`]: {
    margin: 0,
  },
})) as typeof AcadlyExpandableList;

const ExpandableListPlayground = () => {
  const [isAnyItemExpanded, setIsAnyItemExpanded] = useState(false);

  const [items, setItems] = useState(['1', '2', '3']);
  const [itemsById, setItemsById] = useState<{ [key: string]: { id: string; name: string } }>({
    '1': { id: '1', name: 'Virat' },
    '2': { id: '2', name: 'Sachin' },
    '3': { id: '3', name: 'Rohit' },
  });

  const handleItemToggle = (isExpanded: boolean) => {
    setIsAnyItemExpanded(isExpanded);
  };

  return (
    <ExpandableList
      items={items.map((item) => itemsById[item])}
      onItemToggle={handleItemToggle}
      renderSummary={({ isExpanded, onToggle, ...item }) => (
        <EditSummary
          key={item.id}
          item={item}
          isAnyExpanded={isAnyItemExpanded}
          isExpanded={isExpanded}
          onToggle={onToggle}
          setItems={setItems}
          setItemsById={setItemsById}
        />
      )}
      renderDetails={({ onToggle, ...item }) => (
        <UpsertDetails
          item={item}
          onToggle={onToggle}
          items={items}
          setItems={setItems}
          setItemsById={setItemsById}
        />
      )}
      canAddItems
      newItemProps={{
        renderSummary: ({ isExpanded, onToggle }) => (
          <Button variant="text" disabled={isAnyItemExpanded} onClick={onToggle} fullWidth>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6Regular" color="grey.800">
                Add New Item
              </Typography>
              {isExpanded ? undefined : <PlusIcon color={isAnyItemExpanded ? 'disabled' : 'primary'} />}
            </Stack>
          </Button>
        ),
        renderDetails: ({ onToggle }) => (
          <UpsertDetails onToggle={onToggle} items={items} setItems={setItems} setItemsById={setItemsById} />
        ),
        classes: {
          summary: {
            root: expandableListPlaygroundClasses.summaryRoot,
            content: expandableListPlaygroundClasses.summaryContent,
          },
        },
      }}
    />
  );
};

export default ExpandableListPlayground;
