import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxUncheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <rect x="0.5" y="0.5" width="19" height="19" rx="2.5" fill="currentColor" />
    </SvgIcon>
  );
};

export default CheckboxUncheckedIcon;
