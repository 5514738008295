import { keyframes, Stack, StackProps, styled } from '@mui/material';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  margin: theme.spacing(1, 0),
}));

export const Overlay = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="center" alignItems="center" {...props} />
))(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
}));

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled('span')(({ theme }) => ({
  width: 32,
  height: 32,
  border: `5px solid ${theme.palette.background.paper}`,
  borderBottomColor: '#ff3d00',
  boxShadow: '0 0 10px rgb(0 0 0 / 25%)',
  backgroundColor: 'rgb(0 0 0 / 10%)',
  borderRadius: '50%',
  display: 'inline-block',
  boxSizing: 'border-box',
  animation: `${rotate} 1s linear infinite`,
}));

export const Image = styled('img')<{ isActive: boolean }>(({ theme, isActive }) => ({
  maxWidth: '100%',
  boxShadow: isActive ? '0 0 0 2px #B4D5FF' : 'none',
}));

export const Button = styled('button')<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  padding: 4,
  top: '0.5em',
  left: '0.5em',
  backgroundColor: '#fff',
  color: '#f00',
  border: '1px solid #bbb',
  borderRadius: 4,
}));
