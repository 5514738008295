import { call, put, takeLatest } from 'redux-saga/effects';

import Logger from '../../utils/logger';
import { fetchFeatureUpdates, markFeatureUpdateAsRead, setFeatureUpdatesSeenAt } from './actions';
import {
  getFeatureUpdates,
  markFeatureUpdateAsRead as markFeatureUpdateAsReadAPI,
  newFeaturesLastAccesed,
} from './api';

const log = Logger.create('db/features');

function* fetchFeatureUpdatesWorker(action: ReturnType<typeof fetchFeatureUpdates.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getFeatureUpdates> = yield call(getFeatureUpdates);
    yield put(fetchFeatureUpdates.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(fetchFeatureUpdates.failure(requestId));
  }
}

function* setFeatureUpdatesSeenAtWorker(action: ReturnType<typeof setFeatureUpdatesSeenAt.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof newFeaturesLastAccesed> = yield call(newFeaturesLastAccesed);
    yield put(setFeatureUpdatesSeenAt.success(requestId, response));
  } catch (error) {
    log.error(error);
    yield put(setFeatureUpdatesSeenAt.failure(requestId));
  }
}

function* markFeatureUpdateAsReadWorker(action: ReturnType<typeof markFeatureUpdateAsRead.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof markFeatureUpdateAsReadAPI> = yield call(
      markFeatureUpdateAsReadAPI,
      action.payload
    );
    yield put(
      markFeatureUpdateAsRead.success(requestId, {
        ...action.payload,
        ...response,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(markFeatureUpdateAsRead.failure(requestId));
  }
}

export default function* rootFeaturesSaga() {
  try {
    yield takeLatest(fetchFeatureUpdates.request, fetchFeatureUpdatesWorker);
    yield takeLatest(setFeatureUpdatesSeenAt.request, setFeatureUpdatesSeenAtWorker);
    yield takeLatest(markFeatureUpdateAsRead.request, markFeatureUpdateAsReadWorker);
  } catch (error) {
    log.error(error);
  }
}
