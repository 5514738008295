import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  styled,
} from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';

export const dialogClasses = {
  ...generateClasses('Dialog', ['root', 'hasContainer', 'paper', 'header', 'body', 'footer']),
};

export const StyledMuiDialog = styled(MuiDialog)(({ theme }) => ({
  [`&.${dialogClasses.hasContainer}`]: {
    position: 'absolute',
  },
  [`& .${dialogClasses.paper}`]: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    [forMobile(theme)]: {
      borderRadius: 0,
    },
  },
}));

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(5),
  [forMobile(theme)]: {
    padding: theme.spacing(3),
  },
}));

export const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}));
