import { styled } from '@mui/material';

import Dialog from '../../../../../../../../components/Dialog';
import { generateClasses } from '../../../../../../../../utils/helpers';

export const commentStatsDialogClasses = {
  ...generateClasses('CommentStatsDialog', ['dialogContent']),
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${commentStatsDialogClasses.dialogContent}`]: {
    padding: theme.spacing(4),
  },
}));
