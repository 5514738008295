import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { MarkFeatureUpdateAsReadPayload, MarkFeatureUpdateAsReadSuccessPayload } from './types';

export const fetchFeatureUpdates = createRequestAction<void, API.GetAllFeatureUpdatesResponse>(
  'features/updates/fetch'
);

export const setFeatureUpdatesSeenAt = createRequestAction<void, API.NewFeaturesLastAccesedResponse>(
  'features/updates/seen'
);

export const markFeatureUpdateAsRead = createRequestAction<
  MarkFeatureUpdateAsReadPayload,
  MarkFeatureUpdateAsReadSuccessPayload
>('features/mark/read');
