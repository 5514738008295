export const MAIN_CONTENT_BODY_ID = 'main-content-body';
export const DEFAULT_AVATAR_ID = 'default' as const;
export const CLASS_RECORDING_PLAY_AREA = 'class_recording_play_area';
export const ZOOM_MEETING_AREA = 'zoom_meeting_area';
export const ZOOM_MEETING_PIP = 'zoom_meeting_pip';

/** In seconds */
export const CLASS_END_TIME_OFFSET = 5 * 60;

/** In seconds */
export const PRE_CLASS_ACTIVITIES_OFFSET = 30 * 60;
