import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import Button from '../../../../../../../../../../components/Button';
import Tooltip from '../../../../../../../../../../components/Tooltip';
import { i18nNS } from '../../../../../../../../../../i18n';
import AwardIcon from '../../../../../../../../../../icons/AwardIcon';
import CloseIcon from '../../../../../../../../../../icons/CloseIcon';
import Trash2Icon from '../../../../../../../../../../icons/Trash2Icon';
import useRetractPointsVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  points: number;
}

const RetractPoints = ({ commentId, points }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { handleRetractAwardPoints, handleCancel } = useRetractPointsVM({ commentId });

  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <AwardIcon variant="filled" color="primary" fontSize="small" aria-hidden />
      <Typography variant="paragraphRegular" flex={1}>
        {t('you_awarded_num_points_points_to_this_post', {
          ns: i18nNS.COMMENTS,
          numPoints: points,
        })}
      </Typography>
      <Stack direction="row" alignItems="center" gap={2}>
        <Tooltip title={t('retract', { ns: i18nNS.GLOSSARY })}>
          <Button
            variant="text"
            color="error"
            size="small"
            edge="end"
            hideContentWhenLoading
            aria-label={t('click_to_retract_awarded_points_from_this_students_post', { ns: i18nNS.COMMENTS })}
            onClick={handleRetractAwardPoints}
          >
            <Trash2Icon color="inherit" fontSize="small" aria-hidden />
          </Button>
        </Tooltip>
        <Tooltip title={t('cancel', { ns: i18nNS.GLOSSARY })}>
          <Button
            variant="text"
            size="small"
            edge="end"
            aria-label={t('click_to_cancel_retracting_awarded_points_from_this_students_post', {
              ns: i18nNS.COMMENTS,
            })}
            onClick={handleCancel}
          >
            <CloseIcon color="inherit" fontSize="small" aria-hidden />
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default RetractPoints;
