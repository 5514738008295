import en from './en';

type Translation = {
  [key in keyof typeof en]: string;
};

type ReferTranslations = {
  [lang: string]: Translation;
};

export const referTranslations: ReferTranslations = {
  en,
};
