import { Draft } from '@reduxjs/toolkit';

import { API } from '../../shared/api-responses';
import { createAnnouncementFactory } from '../helpers';
import { AnnouncementsState } from '../types';

export function addAnnouncement({
  announcementId,
  announcementsById,
  courseId,
  details,
  userData,
}: {
  announcementId: MongoId;
  announcementsById: Draft<AnnouncementsState['byId']>;
  courseId: MongoId;
  details: API.AnnouncementDetails;
  userData: API.AnnouncementUserData | undefined;
}) {
  const announcement = announcementsById[announcementId];

  // do nothing if announcement is already added by pusher
  if (announcement) return;

  announcementsById[announcementId] = createAnnouncementFactory({
    announcementId,
    courseId,
    details,
    userData,
  });
}
