import { DEFAULT_AVATAR_ID } from '../../constants';
import { pick } from '../../utils/helpers';
import { API } from '../shared/api-responses';
import {
  ClassType,
  CourseMember,
  CourseMemberStatus,
  CourseRole,
  CourseUser,
  EnrollmentBy,
  WeekDay,
} from '../shared/types';
import {
  Course,
  CourseActivities,
  CourseEnrollment,
  CourseFile,
  CourseSchedule,
  CourseStatus,
  GroupedWeeklySchedule,
  TimelineItem,
  WeeklySchedule,
} from './types';

export function timelineComparer(a: TimelineItem, b: TimelineItem) {
  return a.dueDateTime - b.dueDateTime;
}

export function toPermissions({
  canProxy,
  canStream,
  isPro,
  isDemo,
}: {
  canProxy: NumericBoolean;
  canStream: NumericBoolean;
  isPro?: NumericBoolean;
  isDemo?: NumericBoolean;
}): Course['permissions'] {
  return {
    canProxy: Boolean(canProxy),
    canStream: Boolean(canStream),
    isPro: Boolean(isPro),
    isDemo: Boolean(isDemo),
  };
}

export function toCourseTeam(team: CourseMember[]): Course['team'] {
  const admin = team.find((u) => u.role === CourseRole.ADMIN);
  const instructors = team.filter((u) => u.role === CourseRole.INSTRUCTOR);
  const assistants = team.filter((u) => u.role === CourseRole.TA);
  return {
    admin: admin || {
      userId: '',
      avatar: DEFAULT_AVATAR_ID,
      name: '--',
      emailId: 'n/a',
      role: CourseRole.ADMIN,
      status: CourseMemberStatus.ACTIVE,
    },
    instructors,
    assistants,
  };
}

export function toCourseEnrollment(
  enrollmentType = EnrollmentBy.INVITATION,
  joinCode = ''
): CourseEnrollment {
  return enrollmentType === EnrollmentBy.CODE
    ? { by: EnrollmentBy.CODE, code: joinCode }
    : { by: EnrollmentBy.INVITATION };
}

export function toCourseSchedule(timezone = '', courseDates?: Partial<API.CourseDates>): CourseSchedule {
  const weekly: CourseSchedule['weekly'] = (courseDates?.schedule || []).map<WeeklySchedule>((s) => ({
    type: ClassType.LECTURE, // other types are no longer used
    day: s.day,
    startTime: s.startTime,
    endTime: s.endTime,
    venue: s.venue,
    isOnlineMeeting: Boolean(s.isOnlineMeeting),
  }));

  return {
    weekly,
    timezone,
    startDate: courseDates?.startDate || -1,
    startDateStr: courseDates?.startDateL || '',
    endDate: courseDates?.endDate || -1,
    endDateStr: courseDates?.endDateL || '',
  };
}

export function toCourseStatus(status: Partial<API.CourseStatus>, isArchived = false): CourseStatus {
  return {
    isInitialized: Boolean(isArchived || status.initialized),
    isTimezoneSet: Boolean(isArchived || status.timezoneSet),
    isSchedulePublished: Boolean(isArchived || status.schedulePublished),
    hasEnrollments: Boolean(isArchived || status.studentsEnrolled),
    isInfoPublished: Boolean(isArchived || status.infoPublished),
    isLive: Boolean(isArchived || status.courseLive),
    isArchived,
  };
}

interface ActivitiesStats {
  totalActivities: number;
  activities: API.CourseActivities;
}

export function toCourseActivities<T extends ActivitiesStats>(
  { activities, totalActivities }: T,
  userData?: API.CourseUserData
): CourseActivities {
  return {
    total: Math.max(0, totalActivities),
    seen: Math.max(0, userData?.activitiesSeen ?? 0),
    announcements: {
      total: Math.max(0, activities.announcements.numTotal),
      published: Math.max(0, activities.announcements.numPublished),
    },
    assignments: {
      total: Math.max(0, activities.assignments.numTotal),
      published: Math.max(0, activities.assignments.numPublished),
    },
    classes: {
      total: Math.max(0, activities.classes.numTotal),
      published: Math.max(0, activities.classes.numPublished),
    },
    discussions: {
      total: Math.max(0, activities.discussions.numTotal),
      published: Math.max(0, activities.discussions.numPublished),
    },
    polls: {
      total: Math.max(0, activities.polls.numTotal),
      published: Math.max(0, activities.polls.numPublished),
    },
    queries: {
      total: Math.max(0, activities.numQueries),
      published: Math.max(0, activities.numQueries),
      seen: Math.max(0, userData?.queriesSeen ?? 0),
      pending: Math.max(0, activities.numQueriesPending ?? 0),
    },
    quizzes: {
      total: Math.max(0, activities.quizzes.numTotal),
      published: Math.max(0, activities.quizzes.numPublished),
    },
    resources: {
      total: Math.max(0, activities.resources.numTotal),
      published: Math.max(0, activities.resources.numPublished),
    },
  };
}

export function toCourseFile({ _id: id, ...rest }: API.CourseFile): CourseFile {
  return { id, ...rest };
}

export function removeExtraKeysFromCourseUser<T extends CourseUser>(user: T): T {
  return pick(user, 'userId', 'name', 'avatar', 'role');
}

const daysOrderMap = {
  [WeekDay.MON]: 0,
  [WeekDay.TUE]: 1,
  [WeekDay.WED]: 2,
  [WeekDay.THU]: 3,
  [WeekDay.FRI]: 4,
  [WeekDay.SAT]: 5,
  [WeekDay.SUN]: 6,
};

export function groupWeeklySchedule(schedules: WeeklySchedule[]) {
  const groupMap = new Map<string, GroupedWeeklySchedule>();

  const sortedSchedules = [...schedules].sort((a, b) => {
    const keyA = `${daysOrderMap[a.day]}${a.startTime}${a.endTime}`;
    const keyB = `${daysOrderMap[b.day]}${b.startTime}${b.endTime}`;
    return keyA > keyB ? 1 : -1;
  });

  for (const s of sortedSchedules) {
    const key = `${s.startTime}${s.endTime}${s.isOnlineMeeting ? 'online' : s.venue}`;
    const group = groupMap.get(key);
    if (group) {
      group.days.push(s.day);
    } else {
      const { day, ...data } = s;
      groupMap.set(key, { ...data, days: [s.day] });
    }
  }

  const groups = Array.from(groupMap.values());
  return groups;
}
