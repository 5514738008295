import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';

import { i18nNS } from '../../../../i18n';
import ArrowheadLeftIcon from '../../../../icons/ArrowheadLeftIcon';
import ArrowheadRightIcon from '../../../../icons/ArrowheadRightIcon';
import CloseIcon from '../../../../icons/CloseIcon';
import { forDesktop } from '../../../../utils/media-queries';
import { leftActionsClasses, StyledButton } from './styles';
import useLeftActionsVM from './vm';

const LeftActions = () => {
  const isDesktop = useMediaQuery(forDesktop);

  const { t } = useTranslation([i18nNS.SHARED, i18nNS.GLOSSARY]);

  const { expandButtonRef, isCommentsPanelExpanded, toggleExpand, handleClose } = useLeftActionsVM();

  if (isDesktop) {
    return (
      <StyledButton
        ref={expandButtonRef}
        edge="start"
        aria-label={t(
          isCommentsPanelExpanded ? 'click_to_collapse_comments_panel' : 'click_to_expand_comments_panel',
          { ns: i18nNS.SHARED }
        )}
        onClick={toggleExpand}
        size="small"
        variant="text"
        className={leftActionsClasses.button}
      >
        {isCommentsPanelExpanded ? (
          <ArrowheadRightIcon color="inherit" aria-hidden />
        ) : (
          <ArrowheadLeftIcon color="inherit" aria-hidden />
        )}
      </StyledButton>
    );
  }

  return (
    <StyledButton
      edge="start"
      aria-label={t('click_to_hide_discussion_panel', { ns: i18nNS.SHARED })}
      onClick={handleClose}
      size="small"
      variant="text"
      className={leftActionsClasses.button}
    >
      <CloseIcon color="inherit" aria-hidden />
    </StyledButton>
  );
};

export default LeftActions;
