import { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Stack, StackProps, styled } from '@mui/material';

import { ReactComponent as AcadlyLogoIcon } from '../../assets/acadly-logo-square.svg';
import ClassHeader from '../../class/ClassHeader';
import { MAIN_CONTENT_BODY_ID } from '../../constants';
import CourseAnalyticsHeader from '../../courses/CourseAnalytics/CourseAnalyticsHeader';
import CourseFooter from '../../courses/CourseFooter';
import CourseHeader from '../../courses/CourseHeader';
import DiscussionWordCloudHeader from '../../discussion/DiscussionWordCloud/DiscussionWordCloudHeader';
import HomeHeader from '../../home/HomeHeader';
import routes from '../../pages/routes';
import { MAIN_CONTENT_WIDTH } from '../../styles/theme';

const Root = styled('main')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

const Header = styled('div')(({ theme }) => ({
  flexShrink: 0,
  zIndex: theme.zIndex.pageHeader,
}));

const Body = styled((props: StackProps) => <Stack {...props} />)(() => ({
  flex: 1,
  overflow: 'auto',
}));

const BodyContent = styled('div')(() => ({
  flex: 1,
  width: '100%',
  maxWidth: MAIN_CONTENT_WIDTH,
  margin: 'auto',
}));

const AcadlyLogo = styled(AcadlyLogoIcon)(({ theme }) => ({
  position: 'fixed',
  left: theme.spacing(5),
  bottom: theme.spacing(5),
  width: 24,
  height: 24,
  zIndex: -1,
}));

const Footer = styled('div')(() => ({
  flexShrink: 0,
}));

interface Props {
  className?: string;
  children?: ReactNode;
}

const MainContent = ({ className, children }: Props) => {
  return (
    <Root className={className}>
      <Header>
        <Switch>
          <Route exact path={routes.home.path}>
            <HomeHeader />
          </Route>
          <Route exact path={[routes.timeline.path, routes.info.path, routes.syllabus.path]}>
            <CourseHeader />
          </Route>
          <Route exact path={[routes.courseAnalytics.path, routes.courseAverages.path]}>
            <CourseAnalyticsHeader />
          </Route>
          <Route exact path={[routes.activities.path, routes.agenda.path, routes.attendance.path]}>
            <ClassHeader />
          </Route>
          <Route exact path={routes.discussionWordCloud.path}>
            <DiscussionWordCloudHeader />
          </Route>
        </Switch>
      </Header>
      <Body id={MAIN_CONTENT_BODY_ID}>
        <BodyContent>{children}</BodyContent>
        <AcadlyLogo />
      </Body>
      <Footer>
        <Switch>
          <Route path={routes.course.path}>
            <CourseFooter />
          </Route>
        </Switch>
      </Footer>
    </Root>
  );
};

export default MainContent;
