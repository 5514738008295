import { useTranslation } from 'react-i18next';

import UpArrow from '@mui/icons-material/North';
import DownArrow from '@mui/icons-material/South';

import { i18nNS } from '../../../i18n';
import { ButtonGroup, CloseButton, NewMessagesButton, StyledCloseIcon } from './styles';
import useNewCommentsChipVM from './vm';

interface Props {
  className?: string;
}

const NewCommentsChip = ({ className }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const { isNewCommentsChipVisible, newCommentsCount, newChipArrowDirection, handleClose, handleClick } =
    useNewCommentsChipVM();

  if (!isNewCommentsChipVisible) return null;

  if (newCommentsCount === 0) return null;

  return (
    <ButtonGroup size="small" className={className} sx={{ gap: '2px' }}>
      <NewMessagesButton
        aria-label={t('click_to_see_new_messages', { ns: i18nNS.COMMENTS })}
        onClick={handleClick}
      >
        {t('N_new_message', { ns: i18nNS.COMMENTS, count: newCommentsCount })}
        {newChipArrowDirection === 'top' && <UpArrow fontSize="xSmall" />}
        {newChipArrowDirection === 'bottom' && <DownArrow fontSize="xSmall" />}
      </NewMessagesButton>
      <CloseButton
        aria-label={t('click_to_close_new_message_info', { ns: i18nNS.COMMENTS })}
        onClick={handleClose}
      >
        <StyledCloseIcon />
      </CloseButton>
    </ButtonGroup>
  );
};

export default NewCommentsChip;
