import { MenuList } from '@mui/material';
import { Popover as MuiPopover, popoverClasses, styled } from '@mui/material';

import Tag from '../../../../../components/Tag/index';
import UserCell from '../../../../../components/UserCell';
import { userCellClasses } from '../../../../../components/UserCell/styles';
import { pick } from '../../../../../utils/helpers';

export const hamburgerMenuClasses = {
  ...pick(popoverClasses, 'paper', 'root'),
  ...pick(userCellClasses, 'avatar'),
};

export const Popover = styled(MuiPopover)(({ theme }) => ({
  [`& .${hamburgerMenuClasses.paper}`]: {
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:focus-visible': {
      outline: `2px solid ${theme.palette.primary[600]}`,
      outlineOffset: theme.spacing(0.5),
    },
  },
}));

export const StyledUserCell = styled(UserCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary[50],
  padding: theme.spacing(3, 5),
  [`& .${hamburgerMenuClasses.avatar}`]: {
    borderColor: theme.palette.primary[300],
  },
}));

export const StyledTag = styled(Tag)(({ theme }) => ({
  alignSelf: 'flex-start',
}));

export const MenuItems = styled(MenuList)(({ theme }) => ({
  padding: theme.spacing(1),
  gap: theme.spacing(1),
  margin: 0,
}));
