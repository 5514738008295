/**
 * @description This file automatically loads MathJax 3.x
 * and typeset math equations currently present in DOM
 */

window.MathJax = {
  loader: { load: ['ui/safe', 'input/mml', 'input/asciimath'] },
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
  },
};

(function () {
  var script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js';
  script.async = true;
  document.head.appendChild(script);
})();

export {};
