import { ReactNode, useContext, useRef } from 'react';

import { Fade, MenuList, styled } from '@mui/material';

import FabMenuContext from './Context';

const Root = styled(MenuList)(() => ({
  flexShrink: 0,
  width: 232,
  padding: 0,
}));

export interface Props {
  menuId?: string;
  children: ReactNode;
  className?: string;
}

const ChildFabMenu = ({ children, className, menuId }: Props) => {
  const ref = useRef<HTMLUListElement>(null);
  const { currentMenu } = useContext(FabMenuContext);

  const isVisible = currentMenu === menuId;

  if (!isVisible) return null;

  return (
    <Fade in={isVisible}>
      <Root ref={ref} id={menuId} className={className}>
        {children}
      </Root>
    </Fade>
  );
};

export default ChildFabMenu;
