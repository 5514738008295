import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { logout } from '../../../../auth/store/actions';
import { selectAuthSession } from '../../../../auth/store/selectors';
import { CreateCoursePermission } from '../../../../auth/types';
import { getAcadlyRouteByURL } from '../../../../pages/helpers';
import routes from '../../../../pages/routes';

const useHamburgerMenu = () => {
  const dispatch = useDispatch();

  const session = useSelector(selectAuthSession)!;
  const isTrialAccount = session.canCreateCourses === CreateCoursePermission.PROVISIONAL;

  const location = useLocation();
  const isHomePage = location.pathname === routes.home.path;

  /** hamburger menu dropdown */

  const hamburgerButtonRef = useRef<HTMLButtonElement | null>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleHamburgerClick = (target: HTMLElement) => {
    setAnchorEl(target);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    // waiting for popover closing animation
    await new Promise((resolve) => setTimeout(resolve, 0));
    if (!hamburgerButtonRef.current) return;
    hamburgerButtonRef.current.focus();
  };

  /** Back button */

  const handleBackClick = () => {
    const currentRoute = getAcadlyRouteByURL(location.pathname);
    if (!currentRoute.route) return;
    if (!currentRoute.match) return;

    currentRoute.route.goBack(currentRoute.match.params);
  };

  /** Settings */

  const handleSettingsClick = () => {
    handleClose();
  };

  /** Feedback dialog */

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const handleFeedbackClick = () => {
    handleClose();
    setIsFeedbackDialogOpen(true);
  };

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false);
  };

  /** Logout */

  const handleLogoutClick = () => {
    handleClose();
    dispatch(logout());
  };

  return {
    hamburgerButtonRef,
    session,
    isTrialAccount,
    isHomePage,
    anchorEl,
    open,
    handleHamburgerClick,
    handleClose,
    handleBackClick,
    handleSettingsClick,
    isFeedbackDialogOpen,
    handleFeedbackClick,
    handleCloseFeedbackDialog,
    handleLogoutClick,
  };
};

export default useHamburgerMenu;
