import { fetchCoursePurchaseCouponStatus } from '../../../db/courses/actions';
import { useRequestDispatch } from '../../../utils/request-actions';
import { usePaymentWallContext } from '../Context';
import { PaymentStep } from '../types';

export default function usePayButtonVM() {
  const requestDispatch = useRequestDispatch();

  const {
    setAlertMessage,
    isChoosePaymentMethodDialogOpen,
    setIsChoosePaymentMethodDialogOpen,
    setCurrentPaymentStep,
    isCoursePaymentDrawerOpen,
    setIsCoursePaymentDrawerOpen,
  } = usePaymentWallContext();

  const handleClickMakePayment = async () => {
    const { hasCoursePurchaseCoupons, alertMessage } = await requestDispatch(fetchCoursePurchaseCouponStatus);
    if (hasCoursePurchaseCoupons) {
      setAlertMessage(alertMessage);
      setIsChoosePaymentMethodDialogOpen(true);
      return;
    }
    setCurrentPaymentStep({ name: PaymentStep.PAY_VIA_CARD });
    setIsCoursePaymentDrawerOpen(true);
  };

  const handleCloseChoosePaymentMethodDialog = () => {
    setIsChoosePaymentMethodDialogOpen(false);
  };

  const handleClosePaymentDrawer = () => {
    setIsCoursePaymentDrawerOpen(false);
  };

  return {
    handleClickMakePayment,
    isChoosePaymentMethodDialogOpen,
    handleCloseChoosePaymentMethodDialog,
    isCoursePaymentDrawerOpen,
    handleClosePaymentDrawer,
  };
}
