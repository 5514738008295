import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getCanFetchAssignmentPostSubmissionComments,
  getCanFetchAssignmentPreSubmissionComments,
} from '../assignment/helpers';
import { selectAuthSession } from '../auth/store/selectors';
import { fetchComments, setCommentContext } from '../db/comments/actions';
import { getIsSameCommentContext } from '../db/comments/helpers';
import { selectCommentContextByURL, selectContextData } from '../db/comments/selectors';
import { selectCurrentCourse } from '../db/courses/selectors';
import { AssignmentSubContext, CommentContext } from '../db/shared/types';
import { getCanFetchDiscussionComments } from '../discussion/helpers';
import { getCanFetchPollComments } from '../poll/helpers';
import { getCanFetchQueryComments } from '../query/helpers';
import { getCanFetchQuizComments } from '../quiz/helpers';
import { getCanFetchResourceComments } from '../resource/helpers';
import { useRequestDispatch } from '../utils/request-actions';
import CommentsPanel from './CommentsPanel';
import { CommentsContext } from './Context';

interface Props {
  className?: string;
}

function useCommentsPanelModuleVM() {
  const location = useLocation();

  const dispatch = useDispatch();
  const requestDispatch = useRequestDispatch();

  const course = useSelector(selectCurrentCourse);
  const session = useSelector(selectAuthSession);

  const currentContext = useSelector(selectCommentContextByURL(location.pathname), getIsSameCommentContext);
  const contextData = useSelector(selectContextData(currentContext || undefined));

  const { resetPageNumber } = useContext(CommentsContext);

  const shouldFetchComments = useMemo(() => {
    if (!session) return false;

    // do not fetch comments if course is not live
    if (!course?.status.isLive) return false;

    // no context activity found, may be activity not loaded
    if (!contextData?.activity) return false;

    if ('publishedOn' in contextData.activity && contextData.activity.publishedOn <= 0) {
      // do not fetch comments if activity is not published
      return false;
    }

    switch (contextData.type) {
      case CommentContext.ASSIGNMENT:
        if (contextData.subType === AssignmentSubContext.PRE_SUBMISSION) {
          return getCanFetchAssignmentPreSubmissionComments();
        }
        return getCanFetchAssignmentPostSubmissionComments({
          assignment: contextData.activity,
          role: course.myRole,
          userId: session.userId,
        });
      case CommentContext.QUIZ:
        return getCanFetchQuizComments({
          quiz: contextData.activity,
          role: course.myRole,
          userId: session.userId,
        });
      case CommentContext.POLL:
        return getCanFetchPollComments();
      case CommentContext.DISCUSSION:
        return getCanFetchDiscussionComments({
          discussion: contextData.activity,
          role: course.myRole,
        });
      case CommentContext.RESOURCE:
        return getCanFetchResourceComments();
      case CommentContext.QUERY:
        return getCanFetchQueryComments({
          query: contextData.activity,
          role: course.myRole,
        });
    }

    return true;
  }, [
    contextData?.activity,
    contextData?.subType,
    contextData?.type,
    course?.myRole,
    course?.status.isLive,
    session,
  ]);

  useEffect(
    function updateContext() {
      dispatch(setCommentContext(currentContext));
      resetPageNumber();
    },
    [currentContext, dispatch, resetPageNumber]
  );

  useEffect(
    function loadComments() {
      if (!currentContext) return;
      if (!shouldFetchComments) return;
      // clear current comment data and fetch new comment data
      requestDispatch(fetchComments, { context: currentContext });
    },
    [requestDispatch, shouldFetchComments, currentContext]
  );
}

const CommentsPanelModule = ({ className }: Props) => {
  useCommentsPanelModuleVM();
  return <CommentsPanel className={className} />;
};

export default CommentsPanelModule;
