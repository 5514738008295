import urls from '../../app/urls';
import { format, now } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';

export async function createAnnouncement(payload: API.CreateAnnouncementRequest) {
  const { data } = await request.post<API.CreateAnnouncementResponse>(urls.createAnnouncement, payload);
  return data;
}

export async function markAnnouncementAsRead(announcementId: MongoId) {
  return request.post<unknown>(urls.markAnnouncementAsRead, {
    announcementId,
    firstAccess: 1,
    localTime: format(now(), `yyyyMMdd'T'HH:mm`),
  });
}
