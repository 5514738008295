import { MouseEvent, Ref, useState } from 'react';

import { useForwardedRef } from '../../utils/hooks';
import { Props as ButtonProps } from '../Button';

export interface Props {
  ref: Ref<HTMLButtonElement>;
  /**
   * Will only trigger when canOpenAlert is false
   */
  onClick?: ButtonProps['onClick'];
  /**
   * Will trigger before opening alert
   * Will only trigger if canOpenAlert is true
   */
  onBeforeOpenAlert?: () => any;
  /**
   * Should alert be opened on click of this button or not.
   * @default true
   */
  canOpenAlert?: boolean;

  /**
   * @default true
   */
  isButtonfocusable?: boolean;
}

const useConfirmButtonVM = ({
  ref,
  onClick,
  canOpenAlert = true,
  onBeforeOpenAlert,
  isButtonfocusable = true,
}: Props) => {
  const alertButtonRef = useForwardedRef(ref);

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleCloseAlert = async () => {
    setIsAlertOpen(false);
    if (!isButtonfocusable) return;
    await new Promise((resolve) => setTimeout(resolve, 0));
    alertButtonRef.current?.focus();
  };

  const handleClick = async (event: MouseEvent<HTMLElement>) => {
    if (canOpenAlert) {
      await onBeforeOpenAlert?.();
      setIsAlertOpen(true);
      return;
    }

    await onClick?.(event);
  };

  return { alertButtonRef, isAlertOpen, handleCloseAlert, handleClick };
};

export default useConfirmButtonVM;
