import { Stack } from '@mui/material';

import WeeklyClass from '../WeeklyClass';
import CourseScheduleItemSkeleton from './CourseScheduleItemSkeleton';
import useCourseScheduleVM from './vm';

const CourseSchedule = () => {
  const { isFetchingCurrentCourse, schedules } = useCourseScheduleVM();

  if (isFetchingCurrentCourse) {
    return (
      <Stack gap={0.5}>
        <CourseScheduleItemSkeleton />
        <CourseScheduleItemSkeleton />
      </Stack>
    );
  }

  return (
    <>
      {schedules.map((schedule, index) => {
        return <WeeklyClass key={index} {...schedule} />;
      })}
    </>
  );
};

export default CourseSchedule;
