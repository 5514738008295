import { useTranslation } from 'react-i18next';

import { Stack, useTheme } from '@mui/material';

import Alert from '../../../components/Alert';
import AlertButton from '../../../components/AlertButton';
import { i18nNS } from '../../../i18n';
import { Button } from './styles';
import useNavigationVM, { VMProps } from './vm';

type Props = VMProps & {
  className?: string;
};

const Navigation = ({ className, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);
  const theme = useTheme();

  const {
    handleTurnOffHelp,
    canSeePrevious,
    handlePrevious,
    canSeeNext,
    handleNext,
    canSeeClose,
    handleMarkTipsAndClose,
  } = useNavigationVM(vmOptions);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={5} className={className}>
      <AlertButton aria-label={t('click_to_turn_off_help', { ns: i18nNS.COMMON })}>
        {t('turn_off_help', { ns: i18nNS.COMMON })}
        <Alert zIndex={theme.zIndex.reactourAlert}>
          <Alert.Header>{t('stop_help_tips', { ns: i18nNS.COMMON })}</Alert.Header>
          <Alert.Body>
            {t(
              'clicking_yes_will_stop_these_help_tips_to_view_them_again_please_go_to_settings_and_turn_on_helpful_tips',
              {
                ns: i18nNS.COMMON,
              }
            )}
          </Alert.Body>
          <Alert.Footer>
            <Alert.Action variant="text">{t('no', { ns: i18nNS.GLOSSARY })}</Alert.Action>
            <Alert.Action onClick={handleTurnOffHelp}>{t('yes', { ns: i18nNS.GLOSSARY })}</Alert.Action>
          </Alert.Footer>
        </Alert>
      </AlertButton>
      <Stack direction="row" alignItems="center">
        {canSeePrevious && (
          <Button
            variant="text"
            onClick={handlePrevious}
            aria-label={t('click_to_view_previous_tip', { ns: i18nNS.COMMON })}
          >
            {t('prev', { ns: i18nNS.GLOSSARY })}
          </Button>
        )}
        {canSeeNext && (
          <Button
            variant="text"
            onClick={handleNext}
            aria-label={t('click_to_view_next_tip', { ns: i18nNS.COMMON })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </Button>
        )}
      </Stack>
      {canSeeClose && (
        <Button
          variant="text"
          onClick={handleMarkTipsAndClose}
          aria-label={t('click_to_close_tour', { ns: i18nNS.COMMON })}
        >
          {t('close', { ns: i18nNS.GLOSSARY })}
        </Button>
      )}
    </Stack>
  );
};

export default Navigation;
