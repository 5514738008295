import { FormHelperText, InputLabel as MuiInputLabel, inputLabelClasses, styled } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';

import { generateClasses } from '../../../utils/helpers';

export const stripeCardFieldClasses = generateClasses('StripCardField', [
  'input',
  'inputError',
  'inputDisabled',
  'inputFocused',
  'inputSizeXLarge',
  'inputSizeLarge',
  'inputSizeMedium',
  'inputSizeSmall',
  'helperTextRoot',
  'helperText',
]);

export const InputLabel = styled(MuiInputLabel)(({ theme }) => ({
  ...theme.typography.textSmBold,
  transform: 'translate(0, -1.5px) scale(1)',
  color: theme.palette.grey[600],
  [`&.${inputLabelClasses.focused}`]: {
    color: theme.palette.grey[600],
  },
  [`& .${inputLabelClasses.asterisk}`]: {
    color: theme.palette.error[600],
  },
}));

export const InputWrapper = styled('div')(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.grey[50],
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  transition: theme.transitions.create(['border-color', 'background-color']),
  overflow: 'hidden',
  color: theme.palette.text.dark,
  minHeight: '1.4375em',

  'label + &': {
    marginTop: theme.spacing(5),
  },

  [`&.${stripeCardFieldClasses.inputError}`]: {
    backgroundColor: theme.palette.error[50],
    borderColor: theme.palette.error[600],
  },
  [`&.${stripeCardFieldClasses.inputDisabled}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`&.${stripeCardFieldClasses.inputFocused}`]: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary[600],
    [`&.${stripeCardFieldClasses.inputError}`]: {
      borderColor: theme.palette.error[600],
    },
  },

  [`&.${stripeCardFieldClasses.inputSizeXLarge}`]: {
    padding: theme.spacing(4),
  },
  [`&.${stripeCardFieldClasses.inputSizeLarge}`]: {
    padding: theme.spacing(3, 4),
  },
  [`&.${stripeCardFieldClasses.inputSizeMedium}`]: {
    padding: theme.spacing(2, 4),
  },
  [`&.${stripeCardFieldClasses.inputSizeSmall}`]: {
    padding: theme.spacing(1, 4),
  },

  [`&.${stripeCardFieldClasses.inputDisabled}`]: {
    textFillColor: theme.palette.grey[600],
    cursor: 'not-allowed',
  },

  '&::placeholder': {
    opacity: 1,
    color: theme.palette.grey[400],
    textFillColor: theme.palette.grey[400],
  },
}));

export const HeightAdjuster = styled('div')(() => ({
  height: '1.4375em',
  display: 'flex',
  alignItems: 'center',
}));

export const StripeCardElement = styled(CardElement)(() => ({
  width: '100%',
}));

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.grey[600],
}));
