import { Dictionary } from '@reduxjs/toolkit';

import { ClassRole, CourseRole, DueDateType, SubmissionStatus, ToBeDone } from '../db/shared/types';
import { dateTimeDiff, unix } from './datetime';

export function getIsDeadlinePast(deadline: UnixTime) {
  return deadline < unix();
}

export function getIsDeadlineNear(endTimestamp: UnixTime) {
  const hoursToEndTime = dateTimeDiff(endTimestamp, unix(), 'hours');
  return hoursToEndTime >= 0 && hoursToEndTime <= 24;
}

export interface CommentsContainer {
  comments: {
    total: number;
    seen: number;
  };
}

export const getTotalComments = <T extends CommentsContainer>(state: T) => state.comments.total;
export const getSeenComments = <T extends CommentsContainer>(state: T) => state.comments.seen;

export const getUnseenComments = <T extends CommentsContainer>(state: T) => {
  return Math.max(0, getTotalComments(state) - getSeenComments(state));
};

export function getIsManuallyClosedActivity<
  T extends {
    preferences: {
      dueDateType: DueDateType;
    };
  }
>(activity: T | undefined) {
  return activity?.preferences.dueDateType === DueDateType.MANUAL;
}

interface StudentDataByIdContainer {
  studentDataById: Dictionary<{ status: SubmissionStatus }>;
}

export function getIsActivitySubmitted<T extends StudentDataByIdContainer>(activity: T, studentId: MongoId) {
  const submission = activity.studentDataById[studentId];
  if (!submission) return false;
  return [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(submission.status);
}

export function getIsActivityAttempted<T extends StudentDataByIdContainer>(activity: T, studentId: MongoId) {
  const studentData = activity.studentDataById[studentId];
  if (!studentData) return false;
  return SubmissionStatus.NOT_ATTEMPTED !== studentData.status;
}

export function getIsActivityUpdated<
  T extends {
    editedOn: UnixTime;
    firstAccessedOn: UnixTime;
    lastAccessedOn: UnixTime;
  }
>(activity: T) {
  if (activity.editedOn <= 0) return false;
  if (activity.lastAccessedOn > 0) {
    return activity.editedOn > activity.lastAccessedOn;
  }
  return activity.editedOn > activity.firstAccessedOn;
}

export function getIsActivityUrgent<
  T extends {
    toBeDone: ToBeDone;
    dueDateTime: UnixTime;
  }
>(activity: T) {
  if (activity.toBeDone !== ToBeDone.PRE_CLASS) return false;

  const isDeadlinePast = getIsDeadlinePast(activity.dueDateTime);
  if (isDeadlinePast) return false;

  const isDeadlineNear = getIsDeadlineNear(activity.dueDateTime);
  return isDeadlineNear;
}

export function getIsActivityClosed<
  T extends {
    preferences: { allowLate: boolean; dueDateType: DueDateType };
    dueDateTime: UnixTime;
  }
>(activity: T) {
  if (activity.preferences.allowLate) return false;

  const isManuallyClosed = getIsManuallyClosedActivity(activity);
  const hasDueDateTime = activity.dueDateTime > 0;

  if (isManuallyClosed && hasDueDateTime) return true;

  const isDeadlinePast = getIsDeadlinePast(activity.dueDateTime);
  return !isManuallyClosed && isDeadlinePast;
}

export function getIsActivityPastDue<
  T extends {
    preferences: { allowLate: boolean; dueDateType: DueDateType };
    dueDateTime: UnixTime;
  } & StudentDataByIdContainer
>(activity: T, studentId: MongoId) {
  if (!activity.preferences.allowLate) return false;

  const isSubmitted = getIsActivitySubmitted(activity, studentId);
  if (isSubmitted) return false;

  const isManuallyClosed = getIsManuallyClosedActivity(activity);
  const hasDueDateTime = activity.dueDateTime > 0;

  if (isManuallyClosed && hasDueDateTime) return true;

  const isDeadlinePast = getIsDeadlinePast(activity.dueDateTime);
  return !isManuallyClosed && isDeadlinePast;
}

export function getIsActivityDue<
  T extends {
    preferences: { dueDateType: DueDateType };
    dueDateTime: UnixTime;
    toBeDone: ToBeDone;
  } & StudentDataByIdContainer
>(activity: T, studentId: MongoId) {
  const isUrgent = getIsActivityUrgent(activity);
  if (isUrgent) return false;

  const isSubmitted = getIsActivitySubmitted(activity, studentId);
  if (isSubmitted) return false;

  const isManuallyClosed = getIsManuallyClosedActivity(activity);
  const hasDueDateTime = activity.dueDateTime > 0;

  if (isManuallyClosed && !hasDueDateTime) return true;

  const isDeadlinePast = getIsDeadlinePast(activity.dueDateTime);
  return !isDeadlinePast;
}

export function getIsActivityPublished<T extends { publishedOn: UnixTime | undefined }>(activity: T) {
  if (!activity.publishedOn) return false;
  return activity.publishedOn > 0;
}

export function getIsActivityNew<T extends { firstAccessedOn: UnixTime }>(activity: T) {
  return activity.firstAccessedOn <= 0;
}

export interface ActivityStatsContainer extends CommentsContainer {
  total: number;
  seen: number;
  closed?: number;
  pending?: number;
  published?: number;
  completed?: number;
}

export const addActivityStats = <T extends ActivityStatsContainer>(...activities: T[]): T => {
  const result = { total: 0, seen: 0, comments: { total: 0, seen: 0 } } as T;

  for (const stats of activities) {
    result.total += stats.total;
    result.seen += stats.seen;

    result.comments.total += stats.comments.total;
    // sometimes backend returns more seen comments than total comments
    result.comments.seen += Math.min(stats.comments.total, stats.comments.seen);

    if ('pending' in stats) {
      result.pending = (result.pending ?? 0) + (stats?.pending ?? 0);
    }

    if ('published' in stats) {
      result.published = (result.published ?? 0) + (stats?.published ?? 0);
    }

    if ('completed' in stats) {
      result.completed = (result.completed ?? 0) + (stats?.completed ?? 0);
    }

    if ('closed' in stats) {
      result.closed = (result.closed ?? 0) + (stats?.closed ?? 0);
    }
  }

  return result;
};

export const getTotalActivities = <T extends ActivityStatsContainer>(stats: T) => {
  return stats.total + (stats.pending ?? 0);
};

export const getUnseenActivities = <T extends ActivityStatsContainer>(stats: T) => {
  const total = getTotalActivities(stats);
  return Math.max(0, total - stats.seen);
};

export const getUnpublishedActivities = <T extends ActivityStatsContainer>(stats: T) => {
  const total = getTotalActivities(stats);
  const published = stats.published ?? 0;
  return Math.max(0, total - published);
};

export const getUncompletedActivities = <T extends ActivityStatsContainer>(stats: T) => {
  const total = getTotalActivities(stats);
  const completed = stats.completed ?? 0;
  return Math.max(0, total - completed);
};

export const getUnclosedQueries = <T extends ActivityStatsContainer>(stats: T) => {
  return Math.max(0, stats.total - (stats.closed || 0));
};

export function getCanCopyActivity(courseRole = CourseRole.STUDENT, classRole = ClassRole.STUDENT) {
  if (classRole === ClassRole.STUDENT) return false;
  if (classRole === ClassRole.INCHARGE) return true;
  return courseRole === CourseRole.ADMIN || courseRole === CourseRole.INSTRUCTOR;
}

export function getCanMoveActivity<T extends { publishedOn: UnixTime }>(
  activity: T | undefined,
  isCourseArchived?: boolean,
  role = ClassRole.STUDENT
) {
  if (!activity) return false;

  if (isCourseArchived) return false;

  const isActivityPublished = getIsActivityPublished(activity);
  if (isActivityPublished) return false;

  return role === ClassRole.INCHARGE;
}
