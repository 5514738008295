import { CLASS_END_TIME_OFFSET } from '../../constants';
import { UserWithRole } from '../../types';
import { getIsActivityPublished } from '../../utils/activities';
import { toDate, toUnix, unix } from '../../utils/datetime';
import { API } from '../shared/api-responses';
import {
  ClassIncharge,
  ClassRole,
  ClassStatus,
  CopyToFutureClasses,
  CourseRole,
  CourseTeamUser,
  CourseUser,
  QueryToBeDone,
  ScheduleAttendanceRule,
  SuggestedActivityType,
  ToBeDone,
} from '../shared/types';
import {
  Class,
  ClassDiscussions,
  ClassPolls,
  ClassQueries,
  ClassQuizzes,
  ClassResources,
  SuggestedActivityItem,
} from './types';

export function getClassTiming(details: API.ClassDetails): Class['timing'] {
  return {
    dueDateTime: details.dueDateTime,
    startTime: {
      actual: details.actStartTime || -1,
      scheduled: details.scheStartTime,
    },
    endTime: {
      actual: details.actEndTime || -1,
      scheduled: details.scheEndTime,
    },
  };
}

export function getClassTeam(
  details: Pick<API.ClassDetails, 'classInchargeSet'>,
  info: Pick<API.ClassInfo, 'team' | 'teamMembers'>
): Class['team'] {
  return {
    isInchargeSet: Boolean(details.classInchargeSet),
    incharges: info.teamMembers?.inCharges ?? [info.team.inCharge],
    assistants: info.teamMembers?.assistants ?? info.team.assistants,
  };
}

export function getClassRole(
  team: { incharges: ClassIncharge[]; assistants: CourseTeamUser[] },
  currentUser: UserWithRole | undefined
): Class['myRole'] {
  if (!currentUser || currentUser.role === CourseRole.STUDENT) return ClassRole.STUDENT;

  if (team.incharges.some((user) => user.userId === currentUser.userId)) return ClassRole.INCHARGE;

  if (team.assistants.some((t) => t.userId === currentUser.userId)) return ClassRole.ASSISTANT;

  return ClassRole.NONE;
}

export function getAttendanceSchedule(
  classStartTime: UnixTime,
  schedule: API.Class['autoSchedule']
): Class['attendance']['schedule'] {
  if (!schedule?.isScheduled) return { isScheduled: false };

  const copyToFutureClasses: CopyToFutureClasses = !schedule.followForFutureClasses
    ? CopyToFutureClasses.NO
    : schedule.futureClassType;

  if (schedule.scheduleRule !== ScheduleAttendanceRule.TIME_SET) {
    return {
      isScheduled: true,
      type: schedule.scheduleRule,
      copyToFutureClasses,
    };
  }

  const time = toDate(classStartTime);
  const [hours, minutes] = schedule.time.split(':').map(Number);
  time.setHours(hours, minutes);

  return {
    isScheduled: true,
    type: ScheduleAttendanceRule.TIME_SET,
    time: toUnix(time),
    copyToFutureClasses,
  };
}

export function getClassAttendance({
  attendance,
  attendanceSchedule,
  checkInTime,
  classStartTime,
  isCheckedIn,
}: {
  classStartTime: UnixTime;
  attendanceSchedule: API.Class['autoSchedule'];
  attendance: API.ClassAttendance | undefined;
  isCheckedIn: boolean;
  checkInTime: UnixTime;
}): Class['attendance'] {
  const schedule = getAttendanceSchedule(classStartTime, attendanceSchedule);

  if (!attendance || !attendance.inProgress) {
    return {
      inProgress: false,
      data: null,
      schedule,
      isCheckedIn,
      checkInTime,
    };
  }

  return {
    inProgress: true,
    data: null,
    schedule,
    isCheckedIn,
    checkInTime,
    taker: attendance.taker,
    startedAt: attendance.attendanceTime,
  };
}

export function getClassStatus({
  scheduledEndTime,
  scheduledStartTime,
  status,
}: {
  scheduledEndTime: UnixTime;
  scheduledStartTime: UnixTime;
  status: ClassStatus;
}) {
  const now = unix();

  const endTime = scheduledEndTime + CLASS_END_TIME_OFFSET;

  if (scheduledStartTime <= now && now < endTime) {
    return ClassStatus.IN_SESSION;
  }

  if (endTime <= now) {
    return ClassStatus.CLOSED;
  }

  return status;
}

export function getClassQueries(
  toBeDone: QueryToBeDone,
  stats?: API.ClassActivitiesStats,
  userData?: API.ClassUserData
): ClassQueries {
  if (toBeDone === 'review') {
    return {
      items: [],
      seen: userData?.reviewQueries?.numSeen ?? 0,
      total: stats?.reviewQueries?.numTotal ?? 0,
      closed: stats?.reviewQueries.numClosed ?? 0,
      pending: stats?.reviewQueries.numPending ?? 0,
      comments: {
        seen: userData?.reviewQueries?.numCommentsSeen ?? 0,
        total: stats?.reviewQueries?.numCommentsTotal ?? 0,
      },
    };
  }

  return {
    items: [],
    seen: userData?.[toBeDone]?.queries?.numSeen ?? 0,
    total: stats?.[toBeDone]?.queries.numTotal ?? 0,
    closed: stats?.[toBeDone]?.queries.numClosed ?? 0,
    pending: stats?.[toBeDone]?.queries.numPending ?? 0,
    comments: {
      seen: userData?.[toBeDone]?.queries?.numCommentsSeen ?? 0,
      total: stats?.[toBeDone]?.queries.numCommentsTotal ?? 0,
    },
  };
}

export function getClassQuizzes(
  toBeDone: ToBeDone,
  role: ClassRole,
  stats?: API.ClassActivitiesStats,
  userData?: API.ClassUserData
): ClassQuizzes {
  const isStudent = role === ClassRole.STUDENT;
  const published = stats?.[toBeDone]?.quizzes.numPublished ?? 0;
  const total = isStudent ? published : stats?.[toBeDone]?.quizzes.numTotal ?? 0;
  return {
    total,
    published,
    items: [],
    seen: userData?.[toBeDone]?.quizzes?.numSeen ?? 0,
    completed: userData?.[toBeDone]?.quizzes?.numCompleted ?? 0,
    comments: {
      seen: userData?.[toBeDone]?.quizzes?.numCommentsSeen ?? 0,
      total: stats?.[toBeDone]?.quizzes.numCommentsTotal ?? 0,
    },
  };
}

export function getClassPolls(
  toBeDone: ToBeDone,
  role: ClassRole,
  stats?: API.ClassActivitiesStats,
  userData?: API.ClassUserData
): ClassPolls {
  const isStudent = role === ClassRole.STUDENT;
  const published = stats?.[toBeDone]?.polls.numPublished ?? 0;
  const total = isStudent ? published : stats?.[toBeDone]?.polls.numTotal ?? 0;
  return {
    total,
    published,
    items: [],
    seen: userData?.[toBeDone]?.polls?.numSeen ?? 0,
    completed: userData?.[toBeDone]?.polls?.numCompleted ?? 0,
    comments: {
      seen: userData?.[toBeDone]?.polls?.numCommentsSeen ?? 0,
      total: stats?.[toBeDone]?.polls.numCommentsTotal ?? 0,
    },
  };
}

export function getClassDiscussions(
  toBeDone: ToBeDone,
  role: ClassRole,
  stats?: API.ClassActivitiesStats,
  userData?: API.ClassUserData
): ClassDiscussions {
  const isStudent = role === ClassRole.STUDENT;
  const published = stats?.[toBeDone]?.discussions.numPublished ?? 0;
  const total = isStudent ? published : stats?.[toBeDone]?.discussions.numTotal ?? 0;
  return {
    total,
    published,
    items: [],
    seen: userData?.[toBeDone]?.discussions?.numSeen ?? 0,
    comments: {
      seen: userData?.[toBeDone]?.discussions?.numCommentsSeen ?? 0,
      total: stats?.[toBeDone]?.discussions.numCommentsTotal ?? 0,
    },
  };
}

export function getClassResources(
  toBeDone: ToBeDone,
  role: ClassRole,
  stats?: API.ClassActivitiesStats,
  userData?: API.ClassUserData
): ClassResources {
  const isStudent = role === ClassRole.STUDENT;
  const published = stats?.[toBeDone]?.resources.numPublished ?? 0;
  const total = isStudent ? published : stats?.[toBeDone]?.resources.numTotal ?? 0;
  return {
    total,
    published,
    items: [],
    seen: userData?.[toBeDone]?.resources?.numSeen ?? 0,
    comments: {
      seen: userData?.[toBeDone]?.resources?.numCommentsSeen ?? 0,
      total: stats?.[toBeDone]?.resources.numCommentsTotal ?? 0,
    },
  };
}

export function getClassActivities(
  role: ClassRole,
  stats: API.ClassActivitiesStats | undefined,
  userData?: API.ClassUserData | undefined
): Class['activities'] {
  return {
    preClass: {
      quizzes: getClassQuizzes(ToBeDone.PRE_CLASS, role, stats, userData),
      polls: getClassPolls(ToBeDone.PRE_CLASS, role, stats, userData),
      discussions: getClassDiscussions(ToBeDone.PRE_CLASS, role, stats, userData),
      resources: getClassResources(ToBeDone.PRE_CLASS, role, stats, userData),
      queries: getClassQueries(QueryToBeDone.PRE_CLASS, stats, userData),
    },
    inClass: {
      quizzes: getClassQuizzes(ToBeDone.IN_CLASS, role, stats, userData),
      polls: getClassPolls(ToBeDone.IN_CLASS, role, stats, userData),
      discussions: getClassDiscussions(ToBeDone.IN_CLASS, role, stats, userData),
      resources: getClassResources(ToBeDone.IN_CLASS, role, stats, userData),
      queries: getClassQueries(QueryToBeDone.IN_CLASS, stats, userData),
    },
    review: {
      queries: getClassQueries(QueryToBeDone.REVIEW, stats, userData),
    },
  };
}

export function classActivityComparator<T extends { publishedOn: UnixTime; createdOn: UnixTime }>(
  activity1: T,
  activity2: T
) {
  const isActivity1Published = getIsActivityPublished(activity1);
  const isActivity2Published = getIsActivityPublished(activity2);

  if (isActivity1Published && isActivity2Published) return activity1.publishedOn - activity2.publishedOn;
  if (isActivity2Published) return 1;
  if (isActivity1Published) return -1;

  return activity1.createdOn - activity2.createdOn;
}

interface CreateClassOptions {
  cls: API.Class;
  currentUser: CourseUser | undefined;
  userData: API.ClassUserData | undefined;
}

export function createClassFactory({ cls, currentUser, userData }: CreateClassOptions): Class {
  const team = getClassTeam(cls.details, cls.info);
  const myRole = getClassRole(team, currentUser);
  const activities = getClassActivities(myRole, cls.activities, userData);

  const summary: Class['summary'] = {
    description: cls.summary.description,
    attachments: cls.summary.attachments || [],
    links: cls.summary.links.map(({ linkId }) => linkId),
    accessedOn: userData?.summaryAccessedOn || -1,
    updatedOn: cls.summaryUpdatedOn || -1,
  };

  const comments: Class['comments'] = {
    isSubscribed: Boolean(userData?.subscribed),
    seen: userData?.numCommentsSeen ?? 0,
    total: cls.activities?.numCommentsTotal ?? 0,
  };

  return {
    id: cls._id,
    title: cls.details.title || null,
    isOnline: Boolean(cls.details.isOnlineMeeting),
    venue: cls.details.venue,
    sequenceNum: cls.details.trueNum,
    activities,
    team,
    myRole,
    status: getClassStatus({
      status: cls.details.status,
      scheduledStartTime: cls.details.scheStartTime,
      scheduledEndTime: cls.details.scheEndTime,
    }),
    comments,
    summary,
    agenda: cls.info.agenda,
    topics: cls.info.topicIds,
    timing: getClassTiming(cls.details),
    attendance: getClassAttendance({
      classStartTime: cls.details.scheStartTime,
      attendanceSchedule: cls.autoSchedule,
      attendance: undefined,
      isCheckedIn: Boolean(userData?.attendance?.isCheckedIn),
      checkInTime: userData?.attendance?.checkInTime || -1,
    }),
    participationLabels: cls.participationLabels,
    studentDataById: null,
    suggestedActivities: null,
    hasSuggestedActivities: false,
  };
}

interface suggestedActivityItemOptions {
  suggestedActivities: API.SuggestedActivity[] | API.SuggestedClassActivity[];
}

export const suggestedActivityItemFactory = ({
  suggestedActivities,
}: suggestedActivityItemOptions): SuggestedActivityItem[] => {
  const suggestedActivity: SuggestedActivityItem[] = [];

  for (const activity of suggestedActivities) {
    if (activity.nodeType === SuggestedActivityType.ASSIGNMENT) continue;
    suggestedActivity.push({
      id: activity._id,
      type: activity.nodeType,
    });
  }

  return suggestedActivity;
};
