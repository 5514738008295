import { ExtractRouteOptionalParam } from 'react-router';

import { AppContext } from '../types';

type ExtractEnumParams<T extends string> = T extends `${infer First}|${infer Rest}`
  ? First | ExtractEnumParams<Rest>
  : T;

export type ExtractRouteParams<T extends string, U = string> = string extends T
  ? { [k in string]?: U }
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer _Start}:${infer ParamWithOptionalRegExp}/${infer Rest}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer RegExp})`
    ? ExtractRouteOptionalParam<Param, ExtractEnumParams<RegExp>> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends `${infer _Start}:${infer ParamWithOptionalRegExp}`
  ? ParamWithOptionalRegExp extends `${infer Param}(${infer RegExp})`
    ? ExtractRouteOptionalParam<Param, ExtractEnumParams<RegExp>>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
  : {};

type ParamArg<T extends {}> = keyof T extends '' ? void : T;

export enum AcadlyPage {
  LOGIN = 'login',
  SIGNUP = 'signup',
  SIGNUP_BY_ROLE = 'signup_by_role',

  ROOT = 'root',
  HOME = 'home',
  SETTINGS = 'settings',
  ARCHIVES = 'archives',
  REFER = 'refer',

  COURSE = 'course',
  TIMELINE = 'timeline',
  INFO = 'info',
  SYLLABUS = 'syllabus',
  COURSE_ANALYTICS = 'course_analytics',
  COURSE_AVERAGES = 'course_averages',
  STUDENT_ANALYTICS = 'student_analytics',

  COURSE_ANNOUNCEMENTS = 'course_announcements',
  NEW_ANNOUNCEMENT = 'new_announcement',
  ANNOUNCEMENT_PAGE = 'announcement_page',

  COURSE_ASSIGNMENTS = 'course_assignments',
  NEW_ASSIGNMENT = 'new_assignment',
  ASSIGNMENT_PAGE = 'assignment_page',
  ASSIGNMENT_ANALYTICS = 'assignment_analytics',

  CLASS = 'class',
  ACTIVITIES = 'activities',
  AGENDA = 'agenda',
  ATTENDANCE = 'attendance',
  CLASS_ANALYTICS = 'class_analytics',

  /** All quizzes in a class */
  CLASS_QUIZZES = 'class_quizzes',
  NEW_QUIZ = 'new_quiz',
  QUIZ = 'quiz',
  QUIZ_ANALYTICS = 'quiz_analytics',

  /** All polls in a class */
  CLASS_POLLS = 'class_polls',
  NEW_POLL = 'new_poll',
  POLL = 'poll',
  POLL_ANALYTICS = 'poll_analytics',

  /** All discussions in a class */
  CLASS_DISCUSSIONS = 'class_discussions',
  NEW_DISCUSSION = 'new_discussion',
  DISCUSSION = 'discussion',
  DISCUSSION_WORD_CLOUD = 'discussion_word_cloud',

  /** All queries in a class */
  CLASS_QUERIES = 'class_queries',
  NEW_QUERY = 'new_query',
  QUERY = 'query',

  /** All resources in a class */
  CLASS_RESOURCES = 'class_resources',
  NEW_RESOURCE = 'new_resource',
  RESOURCE = 'resource',
  RESOURCE_ANALYTICS = 'resource_analytics',

  PLAYGROUND = 'playground',
}

export enum PageStatus {
  NOT_READY = 'not_ready',
  READY = 'ready',
}

export interface RouteOptions<P extends string, BP extends string> {
  /**
   * Route name, used to query acadly routes by name
   */
  name: AcadlyPage;
  /**
   * Specify immediate parent on which this route depends to
   * fetch its own data
   */
  dependsOn?: AcadlyPage;
  path: P;
  backPath: BP;
  context: AppContext;
}

export interface NavigateOptions {
  search?: Record<string, string>;
  state?: any;
  replace?: boolean;
}

interface Match<Params extends {}> {
  params: Params;
  isExact: boolean;
  path: string;
  url: string;
}

export interface AcadlyRoute<P extends string, BP extends string> extends RouteOptions<P, BP> {
  goBack(params: ParamArg<ExtractRouteParams<BP>>, options?: NavigateOptions): void;
  /**
   * Matches this route is the current route in browser
   * @param exact optional, default is `false`, to match exact path, set to `true`
   */
  match(exact?: boolean): Match<ExtractRouteParams<P>> | null;
  navigate(params: ParamArg<ExtractRouteParams<P>>, options?: NavigateOptions): void;
  /**
   * Returns url by replacing route parameters with their values
   * @param params route parameter value map
   */
  url(params: ParamArg<ExtractRouteParams<P>>): string;
}

export type GetParams<T extends AcadlyRoute<any, any>> = T extends AcadlyRoute<infer P, any>
  ? ExtractRouteParams<P>
  : {};
