import { createReducer } from '@reduxjs/toolkit';

import { setPageStatus } from './actions';
import { AcadlyPage, PageStatus } from './types';

type Pages = {
  [key in AcadlyPage]?: PageStatus | undefined;
};

const initialState: Pages = {};

const pagesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setPageStatus, (state, action) => {
    const { page, status } = action.payload;
    state[page] = status;
  });
});

export default pagesReducer;
