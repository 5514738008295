import { API } from '../shared/api-responses';
import { QueryRole } from '../shared/types';
import { Query } from './types';

interface CreateQueryOptions<T extends API.BaseQuery> {
  query: T;
  identifiers: { courseId: MongoId; classId: MongoId };
  userData: API.QueryUserData | undefined;
}

export function createQueryFactory<T extends API.BaseQuery>({
  query,
  userData,
  identifiers: { courseId, classId },
}: CreateQueryOptions<T>): Query {
  let myRole = QueryRole.NONE;

  if (userData?.role?.includes(QueryRole.ASKER)) {
    myRole = QueryRole.ASKER;
  } else if (userData?.role?.includes(QueryRole.UPVOTER)) {
    myRole = QueryRole.UPVOTER;
  }

  return {
    id: query._id,
    classId,
    courseId: courseId,
    title: query.details.title,
    description: query.details.description,
    attachments: query.details.attachments || [],
    toBeDone: query.details.toBeDone,
    dueDateTime: -1,
    sequenceNum: 0,
    isAnonymous: Boolean(query.details.isAnon),
    isHidden: Boolean(query.details.isHidden),
    myRole,
    askers: query.stats?.numAskers ?? 0,
    comments: {
      total: query.activities.numCommentsTotal,
      byCourseTeam: query.activities.numCommentsCourseTeam,
      seen: userData?.numCommentsSeen ?? 0,
      isSubscribed: Boolean(userData?.subscribed),
    },
    status: query.details.status,
    editedOn: -1,
    editedBy: null,
    createdOn: query.details.createdOn,
    createdBy: query.details.createdBy,
    firstAccessedOn: userData?.firstAccessedOn ?? -1,
    publishedOn: query.details.publishedOn > 0 ? query.details.publishedOn : -1,
  };
}
