import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const EyeIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
          fill="currentColor"
        />
        <path
          d="M21.87 11.5C21.23 10.39 17.71 4.82 11.73 5C6.20001 5.14 3.00001 10 2.13001 11.5C2.04224 11.652 1.99603 11.8245 1.99603 12C1.99603 12.1755 2.04224 12.348 2.13001 12.5C2.76001 13.59 6.13001 19 12.02 19H12.27C17.8 18.86 21.01 14 21.87 12.5C21.9578 12.348 22.004 12.1755 22.004 12C22.004 11.8245 21.9578 11.652 21.87 11.5ZM12 15.5C11.3078 15.5 10.6311 15.2947 10.0555 14.9101C9.47994 14.5256 9.03134 13.9789 8.76643 13.3394C8.50152 12.6999 8.43221 11.9961 8.56726 11.3172C8.70231 10.6383 9.03565 10.0146 9.52513 9.52513C10.0146 9.03564 10.6383 8.7023 11.3172 8.56725C11.9961 8.4322 12.6999 8.50152 13.3394 8.76642C13.9789 9.03133 14.5256 9.47993 14.9102 10.0555C15.2947 10.6311 15.5 11.3078 15.5 12C15.5 12.9283 15.1313 13.8185 14.4749 14.4749C13.8185 15.1313 12.9283 15.5 12 15.5Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.87 11.5C21.23 10.39 17.71 4.82 11.73 5C6.20001 5.14 3.00001 10 2.13001 11.5C2.04224 11.652 1.99603 11.8245 1.99603 12C1.99603 12.1755 2.04224 12.348 2.13001 12.5C2.76001 13.59 6.13001 19 12.02 19H12.27C17.8 18.86 21.01 14 21.87 12.5C21.9578 12.348 22.004 12.1755 22.004 12C22.004 11.8245 21.9578 11.652 21.87 11.5ZM12.22 17C7.91001 17.1 5.10001 13.41 4.22001 12C5.22001 10.39 7.83001 7.1 11.83 7C16.12 6.89 18.94 10.59 19.83 12C18.8 13.61 16.22 16.9 12.22 17Z"
        fill="currentColor"
      />
      <path
        d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47994 9.47444 9.03134 10.0211 8.76643 10.6606C8.50152 11.3002 8.43221 12.0039 8.56726 12.6828C8.70231 13.3618 9.03565 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9102 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1667 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.471 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5007 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default EyeIcon;
