import { useTranslation } from 'react-i18next';

import { Link, Stack, useMediaQuery } from '@mui/material';

import Alert from '../../components/Alert';
import TextField from '../../components/HookFormControls/TextField';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import CourseInfoAlert from './CourseInfoAlert';
import { AlertLink, BackButton, NextButton } from './styles';
import useJoinCodeVM from './vm';

const JoinCode = () => {
  const isMobile = useMediaQuery(forMobile);

  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.VALIDATION]);

  const {
    formMethods,
    onSubmit,
    handleBack,
    courseInfo,
    handleBackToLogin,
    isCourseInfoAlertOpen,
    handleCloseCourseInfoAlert,
  } = useJoinCodeVM();
  const { handleSubmit, control, formState, watch } = formMethods;
  const { isSubmitting } = formState;

  const joinCode = watch('joinCode');

  return (
    <Layout
      title={t('creating_a_student_account', { ns: i18nNS.AUTH })}
      subtitle={t('please_enter_the_N_character_course_join_code_shared_by_your_instructor', {
        ns: i18nNS.AUTH,
        count: 6,
      })}
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3}>
          <TextField
            control={control}
            name="joinCode"
            label={t('course_join_code', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('N_character_join_code_here', { ns: i18nNS.AUTH, count: 6 })}
            autoCapitalize="none"
            autoCorrect="off"
            fullWidth
            autoFocus
          />
          <Link
            component={AlertLink}
            disableRipple
            disableFocusRipple
            aria-label={t('click_to_learn_how_to_signup_as_a_student', { ns: i18nNS.AUTH })}
          >
            {t('i_do_not_have_a_join_code', { ns: i18nNS.AUTH })}
            <Alert>
              <Alert.Header>{t('how_to_sign_up_as_a_student', { ns: i18nNS.AUTH })}</Alert.Header>
              <Alert.Body>
                {t(
                  'you_can_sign_up_as_a_student_only_if_you_are_enrolled_in_a_course_or_have_a_course_join_code_if_you_do_not_have_a_join_code_but_have_received_an_email_from_acadly_that_you_re_now_enrolled_in_a_course_please_log_in_or_sign_up_using_the_email_address_at_which_you_have_received_the_email_the_email_mentions_the_email_address_you_should_use_to_create_the_account',
                  { ns: i18nNS.AUTH }
                )}
              </Alert.Body>
              <Alert.Footer>
                <Alert.Action variant="text" onClick={handleBackToLogin}>
                  {t('go_to_login', { ns: i18nNS.AUTH })}
                </Alert.Action>
                <Alert.Action variant="text">{t('okay', { ns: i18nNS.GLOSSARY })}</Alert.Action>
              </Alert.Footer>
            </Alert>
          </Link>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            onClick={handleBack}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <NextButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_verify_join_code', { ns: i18nNS.AUTH })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </NextButton>
        </Stack>
      </Stack>
      {courseInfo && (
        <CourseInfoAlert
          open={isCourseInfoAlertOpen}
          onClose={handleCloseCourseInfoAlert}
          courseInfo={courseInfo}
          joinCode={joinCode}
        />
      )}
    </Layout>
  );
};

export default JoinCode;
