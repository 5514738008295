import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import Spinner from '../../components/Spinner';
import { i18nNS } from '../../i18n';
import PayButton from './PayButton';
import usePaymentWallVM from './vm';

interface Props {
  children: ReactNode;
  /**
   * if set to `true`, fallback will not be rendered
   * when payment is required
   */
  disableFallback?: boolean;
  disableLoader?: boolean;
  /**
   * view to be rendered when payment is required,
   * if not provided then default fallback will be rendered
   */
  fallback?: ReactNode;
  /** loader view when all course data is being fetched */
  loader?: ReactNode;
}

const PaymentWall = ({ children, disableFallback, disableLoader, fallback, loader }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);
  const { isInitializing, isPaymentRequiredToProceed } = usePaymentWallVM();

  if (isInitializing) {
    return disableLoader ? null : loader ? (
      <>{loader}</>
    ) : (
      <Stack display="flex" justifyContent="center" alignItems="center" height="100%">
        <Spinner />
      </Stack>
    );
  }

  if (!isPaymentRequiredToProceed) {
    return <>{children}</>;
  }

  if (disableFallback) return null;

  if (fallback) {
    return <>{fallback}</>;
  }

  // default fallback
  return (
    <Stack gap={5} p={4} pt={8}>
      <Typography variant="h5Bold" component="div">
        {t('you_need_to_pay_to_access_this_course', { ns: i18nNS.COURSES })}
      </Typography>
      <PayButton size="xLarge">{t('make_payment', { ns: i18nNS.COMMON })}</PayButton>
    </Stack>
  );
};

export default PaymentWall;
