import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { selectAuthSession } from '../../../auth/store/selectors';
import { useCurrentUserWithRole } from '../../../courses/hooks';
import {
  fetchAttendanceResponders as fetchAttendanceRespondersAction,
  stopProxyAttendance,
} from '../../../db/classes/actions';
import { selectAttendanceInProgress, selectClass } from '../../../db/classes/selectors';
import routes, { ClassParams } from '../../../pages/routes';
import { dateTimeDiff, unix } from '../../../utils/datetime';
import { toShortID } from '../../../utils/helpers';
import { useWarnBeforeWindowClose } from '../../../utils/hooks';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useInterval } from '../../../utils/timer';
import { ATTENDANCE_TIMEOUT } from '../../constants';
import { getCanSeeProxyAttendancePip, getCanStopProxyAttendance, getIsInClassTeam } from '../helpers';

export interface Props {
  isInPip?: boolean;
}

const useRecordingInPersonAttendanceVM = ({ isInPip }: Props) => {
  const requestDispatch = useRequestDispatch();
  const location = useLocation();

  const { classShortId } = useParams<ClassParams>();
  const cls = useSelector(selectClass(classShortId));
  const isInClassTeam = getIsInClassTeam(cls);

  const currentUser = useCurrentUserWithRole();
  const session = useSelector(selectAuthSession);
  const universitySlug = session?.university.slug;

  const attendanceInProgress = useSelector(selectAttendanceInProgress);
  const canStopProxyAttendance = getCanStopProxyAttendance(attendanceInProgress, currentUser.userId);

  /** alert user when reload or closing window as he has to stop the attendance manually */
  useWarnBeforeWindowClose(Boolean(attendanceInProgress?.isProxy));

  const canSeePip = useMemo(
    () =>
      getCanSeeProxyAttendancePip(
        attendanceInProgress?.isInProgress ?? false,
        attendanceInProgress?.courseId,
        attendanceInProgress?.classId,
        attendanceInProgress?.isProxy,
        location.pathname
      ),
    [
      attendanceInProgress?.isInProgress,
      attendanceInProgress?.courseId,
      attendanceInProgress?.classId,
      attendanceInProgress?.isProxy,
      location.pathname,
    ]
  );

  const attendancePageUrl = useMemo(() => {
    if (!universitySlug) return '';
    if (!attendanceInProgress?.courseId) return '';
    if (!attendanceInProgress?.classId) return '';

    const courseShortId = toShortID(attendanceInProgress.courseId);
    const classShortId = toShortID(attendanceInProgress.classId);

    return routes.attendance.url({ universitySlug, courseShortId, classShortId });
  }, [universitySlug, attendanceInProgress?.courseId, attendanceInProgress?.classId]);

  const stopAttendance = useCallback(async () => {
    if (!attendanceInProgress?.courseId) return;
    if (!attendanceInProgress.classId) return;
    await requestDispatch(stopProxyAttendance, {
      courseId: attendanceInProgress.courseId,
      classId: attendanceInProgress.classId,
    });
  }, [attendanceInProgress?.courseId, attendanceInProgress?.classId, requestDispatch]);

  const [count, setCount] = useState(-1);

  const setAttendanceResponders = async () => {
    if (!attendanceInProgress?.isInProgress) return;
    if (!isInClassTeam) return;

    const difference = unix() - attendanceInProgress.startedAt;
    const currentOffset = Math.floor(difference / 30) * 30;
    const nextSyncTime = attendanceInProgress.startedAt + currentOffset;

    if (
      attendanceInProgress.startedAt < nextSyncTime &&
      attendanceInProgress.failuresLastSyncedAt < nextSyncTime
    ) {
      await requestDispatch(fetchAttendanceRespondersAction, {
        classId: attendanceInProgress.classId,
        courseId: attendanceInProgress.courseId,
        timestamp: unix(),
      });
    }
  };

  const { start, stop } = useInterval(() => {
    if (!attendanceInProgress?.isInProgress) {
      onStop();
      return;
    }

    const diff = ATTENDANCE_TIMEOUT - dateTimeDiff(unix(), attendanceInProgress.startedAt, 'seconds');
    if (diff <= 0) {
      onStop();
      return;
    }
    setAttendanceResponders();
    setCount(diff);
  }, null);

  const onStop = async () => {
    stop();
    await stopAttendance();
    setCount(-1);
  };

  useEffect(
    function startCountdown() {
      if (!attendanceInProgress?.isInProgress) return;

      const diff = ATTENDANCE_TIMEOUT - dateTimeDiff(unix(), attendanceInProgress.startedAt, 'seconds');
      if (diff <= 0) return;
      setCount(diff);

      if (isInPip && canSeePip) start(1000);
      if (!isInPip) start(1000);

      return stop;
    },
    [start, stop, attendanceInProgress, isInPip, canSeePip]
  );

  return {
    currentUser,
    attendanceInProgress,
    attendancePageUrl,
    canSeePip,
    canStopProxyAttendance,
    onStopAttendance: onStop,
    timeRemaining: count,
  };
};

export default useRecordingInPersonAttendanceVM;
