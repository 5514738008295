import { Props as NoteProps } from '../../../components/Note';
import { API } from '../../../db/shared/api-responses';
import i18n, { i18nNS } from '../../../i18n';
import { formatCurrency } from '../../../utils/currency';
import { PaymentMethod } from './types';

export function getIsFreeCourse({
  isCreatingProCourse,
  availableSlots,
}: {
  isCreatingProCourse: boolean;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  if (!availableSlots) return false;

  const { basic, pro } = availableSlots;

  if (isCreatingProCourse) {
    return pro.free != null;
  }

  return basic.free != null;
}

export function getCanUsePurchasedCourse({
  isCreatingProCourse,
  availableSlots,
}: {
  isCreatingProCourse: boolean;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  if (!availableSlots) return false;

  const { basic, pro } = availableSlots;

  if (isCreatingProCourse) {
    return Boolean(pro.instructorPurchase);
  }

  return Boolean(basic.instructorPurchase);
}

export function getFreeMessage({
  isCreatingProCourse,
  paymentMethod,
  availableSlots,
}: {
  isCreatingProCourse: boolean;
  paymentMethod: PaymentMethod;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  if (!availableSlots) return '';

  const { basic, pro } = availableSlots;

  switch (paymentMethod) {
    case PaymentMethod.FREE: {
      const isFreeCourse = getIsFreeCourse({
        isCreatingProCourse,
        availableSlots,
      });

      if (!isFreeCourse) return '';

      if (isCreatingProCourse) {
        return pro.free!.freeMessage;
      }

      return basic.free!.freeMessage;
    }
    case PaymentMethod.PURCHASED:
      return '';
    case PaymentMethod.STUDENT_PAYS:
      if (isCreatingProCourse) {
        return i18n.t(
          'after_a_N_day_free_trial_every_student_enrolled_in_the_course_will_have_to_pay_M_to_continue_accessing_the_course_material_and_activities_on_acadly',
          {
            ns: i18nNS.CREATE_COURSE,
            numFreeTrialDays: 14,
            amountWithCurrency: formatCurrency(pro.studentPurchase.cost),
          }
        );
      }

      return i18n.t(
        'after_a_N_day_free_trial_every_student_enrolled_in_the_course_will_have_to_pay_M_to_continue_accessing_the_course_material_and_activities_on_acadly',
        {
          ns: i18nNS.CREATE_COURSE,
          numFreeTrialDays: 14,
          amountWithCurrency: formatCurrency(basic.studentPurchase.cost),
        }
      );
    case PaymentMethod.INSTRUCTOR_PAYS:
      return i18n.t(
        'costs_will_be_calculated_in_the_next_step_based_on_the_number_of_courses_and_students_enrolled_starts_at_N_per_student',
        { ns: i18nNS.CREATE_COURSE, amountWithCurrency: formatCurrency(150) }
      );
    default:
      return '';
  }
}

export function getFreeMessageColor(paymentMethod: PaymentMethod): NoteProps['color'] {
  switch (paymentMethod) {
    case PaymentMethod.FREE:
      return 'success';
    case PaymentMethod.PURCHASED:
      return undefined;
    case PaymentMethod.STUDENT_PAYS:
      return 'warning';
    case PaymentMethod.INSTRUCTOR_PAYS:
      return 'warning';
    default:
      return undefined;
  }
}

export function getFormattedStudentPurchasePrices({
  isCreatingProCourse,
  availableSlots,
}: {
  isCreatingProCourse: boolean;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  const result = {
    basic: {
      actual: formatCurrency(500), // ideally should be received from server
      offered: '-',
    },
    pro: {
      actual: formatCurrency(1000), // ideally should be received from server
      offered: '-',
    },
  };

  if (!availableSlots) {
    if (isCreatingProCourse) return result.pro;
    return result.basic;
  }

  const { basic, pro } = availableSlots;

  result.basic.offered = formatCurrency(basic.studentPurchase.cost);
  result.pro.offered = formatCurrency(pro.studentPurchase.cost);

  if (isCreatingProCourse) return result.pro;
  return result.basic;
}

export function convertPaymentMethodToSlotId({
  isCreatingProCourse,
  paymentMethod,
  availableSlots,
}: {
  isCreatingProCourse: boolean;
  paymentMethod: PaymentMethod;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  if (!availableSlots) return null;

  const { basic, pro } = availableSlots;

  if (isCreatingProCourse) {
    switch (paymentMethod) {
      case PaymentMethod.FREE:
        return pro.free?.slotId ?? null;
      case PaymentMethod.PURCHASED:
        return pro.instructorPurchase?.slotId ?? null;
      case PaymentMethod.STUDENT_PAYS:
        return pro.studentPurchase.slotId;
      case PaymentMethod.INSTRUCTOR_PAYS:
        return PaymentMethod.INSTRUCTOR_PAYS;
      default:
        return null;
    }
  }

  switch (paymentMethod) {
    case PaymentMethod.FREE:
      return basic.free?.slotId ?? null;
    case PaymentMethod.PURCHASED:
      return basic.instructorPurchase?.slotId ?? null;
    case PaymentMethod.STUDENT_PAYS:
      return basic.studentPurchase.slotId;
    case PaymentMethod.INSTRUCTOR_PAYS:
      return PaymentMethod.INSTRUCTOR_PAYS;
    default:
      return null;
  }
}

export function convertSlotIdToPaymentMethod({
  slotId,
  availableSlots,
}: {
  slotId: string;
  availableSlots: API.GetAvailableSlotsResponse['slots'] | null;
}) {
  if (!availableSlots) return null;

  const { basic, pro } = availableSlots;

  switch (slotId) {
    case basic.free?.slotId:
    case pro.free?.slotId:
      return PaymentMethod.FREE;
    case basic.instructorPurchase?.slotId:
    case pro.instructorPurchase?.slotId:
      return PaymentMethod.PURCHASED;
    case basic.studentPurchase.slotId:
    case pro.studentPurchase.slotId:
      return PaymentMethod.STUDENT_PAYS;
    case PaymentMethod.INSTRUCTOR_PAYS:
      return PaymentMethod.INSTRUCTOR_PAYS;
    default:
      return null;
  }
}
