import { forwardRef, Ref } from 'react';

import { Stack, StackProps, styled } from '@mui/material';

import TextField from '../../../../components/FormControls/TextField';

export const Root = styled(Stack)(({ theme }) => ({
  height: '100%',
}));

export const Preview = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => (
    <Stack direction="row" justifyContent="center" alignItems="center" {...props} ref={ref} />
  ))
)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  minHeight: 80,
}));

export const Formatter = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

export const FormulaEditor = styled(TextField)(({ theme }) => ({
  padding: theme.spacing(3, 5),
  overflow: 'hidden',
  '& textarea': {
    /** need to override with important because mui is having `overflow: hidden` at local style level */
    overflow: 'auto !important',
    maxHeight: '100%',
  },
}));
