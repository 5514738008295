const en = {
  showing_all_announcements: 'Showing all announcements',
  no_announcements_have_been_created_in_this_course: 'No announcements have been created in this course',

  click_to_view_announcement_title_announcement: 'click to view {{announcementTitle}} announcement',

  announcement_was_not_found_it_has_either_been_moved_deleted_or_not_published_yet:
    'Announcement was not found. It has either been moved, deleted or not published yet.',
  this_announcement_doesn_t_have_a_title_yet_click_on_the_edit_icon_to_add_a_title:
    "This announcement doesn't have a title yet. Click on the <2></2> icon to add a title",
  to_add_a_rich_text_description_or_attach_files_images_etc_to_the_announcement_click_on_the_edit_icon_to_add_a_description:
    'To add a rich text description, or attach files, images etc to the announcement, click on the <2></2> icon to add a description',
  all_the_course_members_will_be_immediately_notified_via_mail_and_push_notifications:
    'All the course members will be immediately notified via mail and push notifications.',
  the_announcement_can_not_be_edited_or_deleted_once_posted:
    'The announcement can not be edited or deleted once posted.',

  do_you_want_to_discard_your_announcement: 'Do you want to discard your announcement?',

  posting_a_new_announcement: 'Posting a New Announcement',
  please_make_sure_your_announcement_has_an_appropriate_title_and_description:
    'Please make sure your announcement has an appropriate title and description',
  you_won_t_be_able_to_edit_delete_it_once_it_is_posted:
    "You won't be able to edit/delete it once it is posted.",
  are_you_sure_you_want_to_post_the_announcement_in_its_current_form:
    'Are you sure you want to post the Announcement in its current form?',
  click_to_show_announcement_details: 'click to show announcement details',
  click_to_delete_announcement: 'click to delete announcement',
  click_to_publish_announcement: 'click to publish announcement',
};

export default en;
