import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../i18n';
import CloseIcon from '../../icons/CloseIcon';
import { CloseButton, Content, Dialog, DialogContent, Image, Loader, Message } from './styles';
import useImagePreviewDialog, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const ImagePreviewDialog = (props: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { loaded, failed, fileSizeInMB, handleCloseDialog, handleCloseButtonClick } =
    useImagePreviewDialog(props);

  return (
    <Dialog open={props.open} onClose={handleCloseDialog}>
      <DialogContent>
        <CloseButton
          size="small"
          onClick={handleCloseButtonClick}
          aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}
        >
          <CloseIcon />
        </CloseButton>
        <Content>
          {loaded ? (
            <Image src={props.src} alt={props.alt} />
          ) : (
            <>
              {!failed && <Loader />}
              <Message>
                {failed
                  ? t('there_was_an_error_loading_this_image', { ns: i18nNS.COMMON })
                  : fileSizeInMB > 1 &&
                    t('this_is_a_big_image_of_size_mb_and_may_take_some_time_to_load', {
                      ns: i18nNS.COMMON,
                      size: Math.round(fileSizeInMB),
                    })}
              </Message>
            </>
          )}
        </Content>
      </DialogContent>
    </Dialog>
  );
};

export default ImagePreviewDialog;
