import { getAssignmentPageTipContent } from '../../assignment/helpers';
import { getClassPageTipContent } from '../../class/helpers';
import {
  getActivityAnalyticsPagesTipContent,
  getCourseAnalyticsPageTipContent,
} from '../../courses/CourseAnalytics/helpers';
import { getCoursePageTipContent } from '../../courses/helpers';
import { CourseRole, TipStatusKey } from '../../db/shared/types';
import { getDiscussionPageTipContent } from '../../discussion/helpers';
import { getHomePageTipContent } from '../../home/helpers';
import { getAcadlyRouteByName } from '../../pages/helpers';
import { AcadlyPage } from '../../pages/types';
import { getPollPageTipContent } from '../../poll/helpers';
import { getQueryPageTipContent } from '../../query/helpers';
import { getQuizPageTipContent } from '../../quiz/helpers';
import { AppContext } from '../../types';
import { TipContext } from './types';

export function getTipContextByPage(page: AcadlyPage): TipContext | null {
  const route = getAcadlyRouteByName(page);

  if (!route) return null;

  switch (route.context) {
    case AppContext.HOME:
      return TipContext.HOME;
    case AppContext.COURSE:
      if (route.name === AcadlyPage.COURSE_ANALYTICS || route.name === AcadlyPage.STUDENT_ANALYTICS) {
        return TipContext.COURSE_ANALYTICS;
      }
      return TipContext.COURSE;
    case AppContext.ASSIGNMENT:
      if (route.name === AcadlyPage.ASSIGNMENT_ANALYTICS) {
        return TipContext.ACTIVITY_ANALYTICS;
      }
      return TipContext.ASSIGNMENT;
    // case AppContext.ASSIGNMENT_ATTEMPT:
    //   return TipContext.ASSIGNMENT_ATTEMPT;
    case AppContext.CLASS:
      if (route.name === AcadlyPage.CLASS_ANALYTICS) {
        return TipContext.ACTIVITY_ANALYTICS;
      }
      return TipContext.CLASS;
    case AppContext.QUIZ:
      if (route.name === AcadlyPage.QUIZ_ANALYTICS) {
        return TipContext.ACTIVITY_ANALYTICS;
      }
      return TipContext.QUIZ;
    // case AppContext.QUIZ_ATTEMPT:
    //   return TipContext.QUIZ_ATTEMPT;
    case AppContext.POLL:
      if (route.name === AcadlyPage.POLL_ANALYTICS) {
        return TipContext.ACTIVITY_ANALYTICS;
      }
      return TipContext.POLL;
    case AppContext.DISCUSSION:
      return TipContext.DISCUSSION;
    case AppContext.QUERY:
      return TipContext.QUERY;
  }

  return null;
}

export function getTipContent(
  tipKey: TipStatusKey,
  role: CourseRole,
  isMobile: boolean,
  isDemoCourse: boolean
) {
  switch (tipKey) {
    case 'courseHomeFloatingButton':
    case 'courseHomeSetting':
    case 'courseHomeArchive':
      return getHomePageTipContent(tipKey);
    case 'courseMainFloatingButton':
    case 'courseMainAnalytics':
    case 'courseMainChat':
      return getCoursePageTipContent(tipKey, role, isMobile, isDemoCourse);
    case 'courseAnalyticsExport':
    case 'courseAnalyticsAddStudents':
      return getCourseAnalyticsPageTipContent(tipKey);
    case 'assignmentMainFloatingButton':
    case 'assignmentMainAttempt':
    case 'assignmentAttemptNavigation':
    case 'assignmentAttemptSave':
    case 'assignmentAttemptSubmit':
      return getAssignmentPageTipContent(tipKey);
    case 'classMainFloatingButton':
    case 'classMainQueryAdd':
    case 'classMainChat':
      return getClassPageTipContent(tipKey, isMobile);
    case 'activityAnalyticsExport':
      return getActivityAnalyticsPagesTipContent(tipKey);
    case 'quizMainFloatingButton':
    case 'quizMainAttempt':
    case 'quizMainChat':
    case 'quizAttemptNavigation':
    case 'quizAttemptSave':
    case 'quizAttemptSubmit':
      return getQuizPageTipContent(tipKey, role);
    case 'pollMainFloatingButton':
    case 'pollMainChat':
    case 'pollMainSubmit':
      return getPollPageTipContent(tipKey, role);
    case 'discussionMainComment':
      return getDiscussionPageTipContent(tipKey);
    case 'queryMainApprove':
    case 'queryMainClose':
    case 'queryMainDelete':
    case 'queryMainHide':
    case 'queryMainUpvote':
      return getQueryPageTipContent(tipKey);
    default:
      return '';
  }
}
