import { forwardRef, Ref } from 'react';
import { FieldValues } from 'react-hook-form';

import { styled } from '@mui/material';

import clsx from 'clsx';

import AlertButton, { Props as AlertButtonProps } from '../../components/AlertButton';
import Button, { Props as ButtonProps } from '../../components/Button';
import Checkbox, { Props as CheckboxProps } from '../../components/HookFormControls/Checkbox';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import { generateClasses } from '../../utils/helpers';

export const signupEmailClasses = generateClasses('SignupEmail', [
  'checkboxRoot',
  'checkbox',
  'whichEmailLink',
]);

export const MarketingCheckbox = styled(<T extends FieldValues>(props: CheckboxProps<T>) => (
  <Checkbox
    {...props}
    classes={{ ...props.classes, checkbox: clsx(signupEmailClasses.checkbox, props.classes?.checkbox) }}
  />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  [`& .${signupEmailClasses.checkbox}`]: {
    /** same as checkbox paddingTop */
    marginTop: `calc(${theme.spacing(-2.25)})`,
  },
})) as typeof Checkbox;

export const AlertLink = styled(
  forwardRef((props: AlertButtonProps, ref: Ref<HTMLButtonElement>) => (
    <AlertButton
      variant="text"
      {...props}
      ref={ref}
      className={clsx(signupEmailClasses.whichEmailLink, props.className)}
    />
  ))
)(({ theme }) => ({
  [`&.${signupEmailClasses.whichEmailLink}`]: {
    alignSelf: 'flex-start',
    fontWeight: theme.typography.fontWeightRegular,
    padding: 0,
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})) as typeof AlertButton;

export const BackButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="outlined" size="xLarge" {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 92,
})) as typeof Button;

export const NextButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="contained" size="xLarge" endIcon={<ChevronRightIcon />} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  flex: 1,
}));
