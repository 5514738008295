import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const TooltipArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="8"
      viewBox="0 0 20 8"
      {...props}
    >
      <path d="M8.76788 1.37998L4.76004 5.86875C3.54935 7.22473 1.81782 8 0 8L20 8C18.1822 8 16.4506 7.22473 15.24 5.86875L11.2321 1.37998C10.5754 0.644422 9.42462 0.644421 8.76788 1.37998Z" />
    </SvgIcon>
  );
};

export default TooltipArrowIcon;
