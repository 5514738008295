import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const SwitchUncheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SwitchUncheckedIcon;
