import { Children, cloneElement, forwardRef, isValidElement, ReactNode, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../../utils/helpers';
import ToggleButton, { Props as ToggleButtonProps } from './ToggleButton';
import useToggleButtonGroupVM, { VMProps } from './vm';

export const toggelButtonGroupClasses = generateClasses('ToggleButtonGroup', ['root']);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

export interface Props extends VMProps {
  children: ReactNode;
  className?: string;
}

const ToggleButtonGroup = (
  { children: _children, className, ...vmProps }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const { getIsSelected, toggleItem } = useToggleButtonGroupVM(vmProps);

  return (
    <Root ref={ref} className={clsx(toggelButtonGroupClasses.root, className)}>
      {Children.map(_children, (child) => {
        if (isValidElement(child) && child.type === ToggleButton) {
          const overriddenProps: ToggleButtonProps = {
            ...child.props,
            selected: getIsSelected(child.props.value),
            onClick: toggleItem(child.props.value),
          };
          return cloneElement(child, overriddenProps);
        }
        return child;
      })}
    </Root>
  );
};

export default forwardRef(ToggleButtonGroup);
