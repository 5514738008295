import { ChangeEvent } from 'react';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyCheckbox, { Props as AcadlyCheckboxProps } from '../FormControls/Checkbox';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useCheckboxVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

export type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyCheckboxProps, 'ref' | 'inputRef' | 'name' | 'value' | 'onBlur'>;

const Checkbox = <T extends FieldValues>({ control, name, defaultValue, ...props }: Props<T>) => {
  const { controller } = useCheckboxVM({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: inputRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
    props.onChange?.(event, event.target.checked);
  };

  return (
    <AcadlyCheckbox
      {...props}
      inputRef={inputRef}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      checked={Boolean(value)}
      aria-invalid={Boolean(error)}
    />
  );
};

export default Checkbox;
