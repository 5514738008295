import { buffers, channel } from 'redux-saga';

import { PusherEvent, PusherEventName, PusherEventPayload } from './types';

/**
 * This channel should not be used directly, it works as a
 * message queue between individual pusher channels and
 * rootPusherHandler which further brodcasts the event to whole
 * application, so if there are more than one consumer, then
 * they (sagas) will be competing with each other.
 *
 * @see https://github.com/redux-saga/redux-saga/issues/820 for more details
 */
const pusherEventChannel = channel<PusherEvent<PusherEventPayload, PusherEventName>>(buffers.none());

export default pusherEventChannel;
