import { useState } from 'react';

const useFeatureUpdatesVM = () => {
  const [isFeatureUpdatesDrawerOpen, setIsFeatureUpdatesDrawerOpen] = useState(false);

  const handleCloseFeatureUpdatesDrawer = () => {
    setIsFeatureUpdatesDrawerOpen(false);
  };

  const handleFeatureUpdates = () => {
    setIsFeatureUpdatesDrawerOpen(true);
  };

  return { isFeatureUpdatesDrawerOpen, handleCloseFeatureUpdatesDrawer, handleFeatureUpdates };
};

export default useFeatureUpdatesVM;
