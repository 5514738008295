import { cloneElement, isValidElement, ReactNode } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import { Props as CreateFormulaOverlayProps } from '../plugins/formula/CreateFormulaOverlay';
import ImageUploadButton, { Props as ImageUploadButtonProps } from './ImageUploadButton';
import InsertFormulaButton, { Props as InsertFormulaButtonProps } from './InsertFormulaButton';
import TextFormattingButtons, { Props as TextFormattingButtonsProps } from './TextFormattingButtons';
import useToolbarVM from './vm';

export interface ToolbarCustomizationProps {
  /**
   * Allows text formatting, setting this to `false` will hide
   * bold, italics and underline buttons
   * @default true
   */
  canFormatText?: boolean;
  /**
   * Allows image uploading, setting this to `false` will hide
   * upload image button
   * @default true
   */
  canUploadImage?: boolean;
  /**
   * Allows math equation insertion, setting this to `false` will hide
   * insert math formula button
   * @default true
   */
  canInsertFormula?: boolean;
  /**
   * You can pass more actions as children. Use {@link ToolBarButton}
   * to add a button with preset styling
   */
  children?: ReactNode;
}

export interface Props extends ToolbarCustomizationProps {
  className?: string;
  classes?: Partial<
    Record<'root', string> &
      TextFormattingButtonsProps['classes'] &
      InsertFormulaButtonProps['classes'] &
      ImageUploadButtonProps['classes']
  >;
  i18n?: CreateFormulaOverlayProps['i18n'];
  renderCreateFormulaOverlay: CreateFormulaOverlayProps['renderOverlay'];
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  alignItems: 'center',
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(2),
    '& [aria-selected="true"]': {
      outline: `2px solid ${theme.palette.primary[400]}`,
      outlineOffset: theme.spacing(0.5),
    },
  },
}));

const Toolbar = ({
  className,
  classes,
  canFormatText = true,
  canInsertFormula = true,
  canUploadImage = true,
  children: _children,
  i18n,
  renderCreateFormulaOverlay,
}: Props) => {
  const {
    children,
    editorRef,
    buttonIds,
    activeItemId,
    extraToolsButtonId,
    handleFocus,
    handleBlur,
    handleKeyDown,
  } = useToolbarVM({
    canFormatText,
    canInsertFormula,
    canUploadImage,
    children: _children,
  });

  return (
    <Root
      className={clsx(className, classes?.root)}
      aria-activedescendant={activeItemId}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {canFormatText && (
        <TextFormattingButtons
          editorRef={editorRef}
          classes={classes}
          boldbuttonId={buttonIds.bold}
          italicbuttonId={buttonIds.italic}
          underlinebuttonId={buttonIds.underline}
          activeItemId={activeItemId}
        />
      )}
      {canInsertFormula && (
        <InsertFormulaButton
          editorRef={editorRef}
          renderCreateFormulaOverlay={renderCreateFormulaOverlay}
          i18n={i18n}
          classes={classes}
          insertFormulaButtonId={buttonIds.inertFormula}
          activeItemId={activeItemId}
        />
      )}
      {canUploadImage && (
        <ImageUploadButton
          editorRef={editorRef}
          classes={classes}
          imageUploadButtonId={buttonIds.uploadImage}
          activeItemId={activeItemId}
        />
      )}
      {children.map((child, index) => {
        const isFocused = extraToolsButtonId[index] === activeItemId;
        return (
          isValidElement(child) &&
          cloneElement(
            child,
            { ...child.props, id: extraToolsButtonId[index], isFocused },
            child.props.children
          )
        );
      })}
    </Root>
  );
};

export default Toolbar;

export { default as TextFormattingButtons } from './TextFormattingButtons';
export { default as InsertFormulaButton } from './InsertFormulaButton';
export { default as ImageUploadButton } from './ImageUploadButton';
