import { FormulaDisplay, FormulaFormat } from '../types';

export const FomulaFormatLabelMap: {
  [key in FormulaFormat]: string;
} = {
  [FormulaFormat.TEX]: 'TeX',
  [FormulaFormat.ASCII_MATH]: 'AsciiMath',
  [FormulaFormat.MATH_ML]: 'MathML',
};

export const FomulaDisplayLabelMap: {
  [key in FormulaDisplay]: string;
} = {
  [FormulaDisplay.BLOCK]: 'Block',
  [FormulaDisplay.INLINE]: 'Inline',
};
