import { useSelector } from 'react-redux';

import { useCurrentUserWithRole } from '../../../../../courses/hooks';
import { selectContextData } from '../../../../../db/comments/selectors';
import { getEmptyCommentsMessage } from '../../../../helpers';

const useEmptyCommentsMessage = () => {
  const contextData = useSelector(selectContextData());
  const currentUser = useCurrentUserWithRole();

  const message = getEmptyCommentsMessage(contextData, currentUser);

  return { message };
};

export default useEmptyCommentsMessage;
