import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectAuthSession } from '../auth/store/selectors';
import { getScrollParent } from '../utils/helpers';

const useSettingsVM = () => {
  const rootRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const session = useSelector(selectAuthSession)!;

  useEffect(
    function init() {
      const scrollParent = getScrollParent(rootRef.current);
      if (scrollParent) scrollParent.scrollTop = 0;
    },
    [location.pathname]
  );

  return { rootRef, session };
};

export default useSettingsVM;
