import { styled } from '@mui/material';

import { ReactComponent as AcadlyLogo } from '../assets/acadly-logo-square.svg';
import { useImage } from '../utils/hooks';

interface Props {
  src: string;
  alt?: string;
  className?: string;
}

const Placeholder = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 'min(100%, 400px)',
}));

const StyledAcadlyLogo = styled(AcadlyLogo)(({ theme }) => ({
  width: 52,
  height: 52,
}));

const Image = ({ src, alt, className }: Props) => {
  const { loaded } = useImage(src);

  return loaded ? (
    <img className={className} src={src} alt={alt} />
  ) : (
    <Placeholder className={className}>
      <StyledAcadlyLogo />
    </Placeholder>
  );
};

export default Image;
