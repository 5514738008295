import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import routes from '../pages/routes';
import AuthScreenContainer from './AuthScreenContainer';
import AuthContextProvider from './Context';
import SignupRole from './SignupRole';
import { selectAuthSession } from './store/selectors';

const useAuthModuleVM = () => {
  const location = useLocation();
  const session = useSelector(selectAuthSession);

  return { location, session };
};

const AuthModule = () => {
  const { session } = useAuthModuleVM();

  if (session?.token) {
    return <Redirect to={routes.home.path} />;
  }

  return (
    <AuthContextProvider>
      <Switch>
        <Route path={routes.login.path}>
          <AuthScreenContainer path="login" />
        </Route>
        <Route path={routes.signupWithRole.path}>
          <AuthScreenContainer path="signup" />
        </Route>
        <Route path={routes.signup.path}>
          <SignupRole />
        </Route>
      </Switch>
    </AuthContextProvider>
  );
};

export default AuthModule;
