const en = {
  /** DiscussionWidget translations */
  click_to_view_discussion_title_discussion_description_discussion:
    'click to view {{discussionTitle}} {{discussionDescription}} discussion',

  /** DiscussionHeader translations */
  discussion_num: 'Discussion {{num}}',

  /** All discussions */
  showing_all_discussions: 'Showing all discussions',
  no_discussions_have_been_published_in_this_course: 'No discussions have been published in the course',
  no_unpublished_discussions_have_been_created_in_this_course:
    'No unpublished discussions have been created in this course',
  no_discussions_have_been_created_in_this_course: 'No discussions have been created in this course',

  /** DiscussionPage translations */
  do_you_want_to_discard_changes_made_to_discussion: 'Do you want to discard changes made to discussion?',

  /** DiscussionPage -> DiscussionActionBar translations */
  please_add_a_discussion_title_and_try_again: 'Please add a discussion title and try again.',

  /** DiscussionPage -> PublishPreferences translations */
  comment_awards_will_be_visible_only_to_the_course_team_and_the_comment_author:
    'Comment awards will be visible only to the course team and the comment author.',
  comment_awards_will_be_visible_only_to_the_course_team:
    'Comment awards will be visible only to the course team',
  comments_cannot_be_awarded: 'Comments cannot be awarded',
  your_fellow_students_won_t_be_able_to_identify_your_posts_but_the_course_team_members_will_still_be_able_to_see_who_contributed_a_particular_post:
    "Your fellow students won't be able to identify your posts but the course team members will still be able to see who contributed a particular post",
  students_won_t_be_able_to_identify_other_student_authors_of_posts:
    "Students won't be able to identify other student authors of posts",
  all_the_posts_you_make_will_be_anonymous: 'All the posts you make will be anonymous',
  none_of_the_course_members_will_be_able_to_identify_the_student_authors_of_posts:
    'None of the course members will be able to identify the student authors of posts',

  /** DiscussionPage -> PublishPreferences -> EditPublishPreferencesDrawer translations */
  discussion_preferences: 'Discussion preferences',
  publishing_discussion: 'Publishing discussion',
  click_to_publish_discussion: 'click to publish discussion',
  click_to_save_discussion_publish_preferences: 'click to save discussion publish preferences',
  contribute_to_consume: 'Contribute to consume',
  turn_off_to_allow_students_to_view_discussion_comments_even_before_posting_a_comment:
    'turn off to allow students to view discussion comments even before posting a comment',
  turn_on_to_hide_discussion_comments_till_student_posts_a_comment:
    'turn on to hide discussion comments till student posts a comment',
  if_toggled_students_will_have_to_post_a_comment_to_the_discussion_before_they_can_read_other_posts_in_the_discussion:
    'If toggled students will have to post a comment to the discussion before they can read other posts in the discussion.',
  hide_awards: 'Hide awards',
  turn_off_to_make_award_points_publically_visible: 'turn off to make award points publically visible',
  turn_on_to_make_award_points_only_visible_to_course_team_and_the_recepient:
    'turn on to make award points only visible to course team and the recepient',
  only_course_team_members_and_the_recipient_will_see_the_points_rewarded:
    'Only course team members and the recipient will see the points rewarded.',
  any_reward_points_awarded_to_a_discussion_post_will_be_visible_to_all:
    'Any reward points awarded to a discussion post will be visible to all.',
  anonymize_responses: 'Anonymize responses',
  turn_on_to_allow_anonymous_discussion_comments: 'turn on to allow anonymous discussion comments',
  turn_off_to_restrict_anonymous_discussion_comments: 'turn off to restrict anonymous discussion comments',
  for_students_only: 'For students only',
  clicking_on_for_student_only_option_students_won_t_be_able_to_identify_the_comment_author:
    "clicking on for student only option,students won't be able to identify the comment author",
  clicking_on_for_everyone_option_neither_students_not_instructors_will_be_able_to_identify_the_author:
    'clicking on for everyone option, neither students not instructors will be able to identify the author',
  for_everyone: 'For everyone',
  students_won_t_be_able_to_identify_the_comment_author:
    "Students won't be able to identify the comment author.",
  neither_students_nor_instructors_will_be_able_to_identify_the_author:
    'Neither students not instructors will be able to identify the author',
  comment_author_s_identity_will_be_visible_to_all: "Comment author's identity will be visible to all",
  anonymization_is_available_only_for_acadly_pro_courses:
    'Anonymization is available only for Acadly Pro courses',
  use_same_publish_preferences_as_default_for_all_pre_class_discussions:
    'Use same publish preferences as default for all the pre-class discussions',
  use_same_publish_preferences_as_default_for_all_in_class_discussions:
    'Use same publish preferences as default for all the in-class discussions',

  /** WordCloud translations */
  word_cloud: 'Word Cloud',
  click_to_open_word_cloud: 'click to open word cloud',
  click_to_close_word_cloud: 'click to close word cloud',
  the_word_cloud_for_this_discussion_has_not_been_generated_yet:
    'The word cloud for this discussion has not been generated yet',
  the_word_cloud_for_this_discussion_is_available_please_click_on_generate_word_cloud_to_generate_a_new_word_cloud:
    'The word cloud for this discussion is available. Please click on "Generate Word Cloud" to generate a new word cloud',
  there_is_not_enough_meaningful_data_in_the_posts_yet_to_generate_a_word_cloud:
    'There is not enough meaningful data in the posts yet to generate a word cloud',
  word_cloud_not_available: 'word cloud not available',
  word_cloud_not_generated: 'word cloud not generated',
  generating_word_cloud: 'generating word cloud',
  generate_word_cloud: 'Generate word cloud',
  click_to_generate_word_cloud: 'click to generate word cloud',
  generate_new_word_cloud: 'Generate new word cloud',
  click_to_generate_new_word_cloud: 'click to generate new word cloud',
  edit_words_list: 'Edit words list',
  click_to_edit_words_list: 'click to edit words list',
  last_generated_at_ago: 'Last generated {{at}} ago',
  comments_used: 'Comments used',
  total_comments: 'Total comments',
  unique_words: 'Unique words',
  words_used: 'Words used',
  removed_from_word_cloud: 'Removed from word cloud',
  occurs_frequency_times_one: 'Occurs {{count}} time',
  occurs_frequency_times_other: 'Occurs {{count}} times',
  click_to_add_word_to_word_cloud: 'click to add word to word cloud',
  click_to_remove_word_from_word_cloud: 'click to remove word from word cloud',

  /** comments panel */
  discussion_comments: 'Discussion Comments',
  export_discussion_data_as: 'Export discussion data as',
  you_need_to_contribute_to_a_post_before_reading_other_posts:
    'You need to contribute to a post before reading other posts.',

  /** onboarding tips */
  the_posts_about_a_particular_discussion_topic_can_be_made_and_accessed_by_using_this_icon:
    'The posts about a particular discussion topic can be made and accessed by using this icon',
};

export default en;
