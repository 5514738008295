import { forwardRef, Ref } from 'react';

import { Props, StyledTab } from './styles';
import useTabVM, { PrivateProps, tabClasses } from './vm';

const Tab = <D extends React.ElementType<any> = 'div'>(
  props: Props<D> & PrivateProps,
  ref: Ref<HTMLDivElement>
) => {
  const { tabRef } = useTabVM(props, ref);

  return <StyledTab {...props} ref={tabRef} label={<div className={tabClasses.label}>{props.label}</div>} />;
};

export default forwardRef(Tab) as <D extends React.ElementType<any> = 'div'>(
  props: Props<D> & PrivateProps & { ref?: Ref<HTMLDivElement> }
) => JSX.Element;
