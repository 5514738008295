import { forwardRef, Ref } from 'react';

import TextFieldBase, { Props as TextFieldBaseProps } from '../TextFieldBase';
import useTextFieldVM, { Props as VMProps } from './vm';

export interface Props extends Omit<TextFieldBaseProps, 'inputProps' | 'onKeyPress'>, VMProps {}

const TextField = ({ onEnterKeyPress, onKeyPress, ...props }: Props, ref: Ref<HTMLDivElement>) => {
  const { handleKeyPress } = useTextFieldVM({ onEnterKeyPress, onKeyPress });

  return <TextFieldBase {...props} ref={ref} onKeyPress={handleKeyPress} />;
};

export default forwardRef(TextField);
