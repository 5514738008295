import { ReactNode, Ref } from 'react';

import {
  FormHelperText,
  InputBase,
  inputBaseClasses as MuiInputBaseClasses,
  InputBaseProps,
  InputLabel,
  inputLabelClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateClasses } from '../../../utils/helpers';
import { Props as VMProps } from './vm';

export const inputBaseClasses = {
  ...MuiInputBaseClasses,
  ...generateClasses('InputBase', ['inputSizeXLarge', 'inputSizeLarge', 'inputSizeMedium']),
};

export type Props = VMProps &
  DistributiveOmit<InputBaseProps, 'classes' | 'required'> & {
    label?: string;
    /**
     * displays Asterisk(*) at the end of the label
     * @default false
     */
    required?: boolean;
    /**
     * ref of InputBase component (points to the `root` element of `InputBase`).
     * This `InputRef` should not be confused with `inputRef`, which points to actual `input` element
     */
    InputBaseRef?: Ref<HTMLDivElement>;
    /**
     * If helperIcon prop is passed, then it will show up in HelperText section below InputBase regardless of state.
     * Otherwise, in case of error, ErrorIcon will show up. There is no default HelperIcon in any state except error.
     */
    helperIcon?: ReactNode;
    helperText?: string;
    characterLimit?: number;
    classes?: Partial<
      Record<
        'root' | 'label' | 'inputBase' | 'input' | 'helperTextRoot' | 'helperText' | 'characterLimit',
        string
      >
    >;
  };

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  ...theme.typography.textSmBold,
  transform: 'translate(0, -1.5px) scale(1)',
  color: theme.palette.grey[600],
  [`&.${inputLabelClasses.focused}`]: {
    color: theme.palette.grey[600],
  },
  [`& .${inputLabelClasses.asterisk}`]: {
    color: theme.palette.error[600],
  },
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  borderRadius: 10,
  backgroundColor: theme.palette.grey[50],
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.grey[200],
  transition: theme.transitions.create(['border-color', 'background-color']),
  overflow: 'hidden',
  [`&.${inputBaseClasses.error}`]: {
    backgroundColor: theme.palette.error[50],
    borderColor: theme.palette.error[600],
  },
  [`&.${inputBaseClasses.disabled}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`&.${inputBaseClasses.focused}`]: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary[600],
    [`&.${inputBaseClasses.error}`]: {
      borderColor: theme.palette.error[600],
    },
  },
  'label + &': {
    marginTop: theme.spacing(5),
  },
  [`& .${inputBaseClasses.input}`]: {
    [`&.${inputBaseClasses.inputSizeXLarge}`]: {
      padding: theme.spacing(4),
    },
    [`&.${inputBaseClasses.inputSizeLarge}`]: {
      padding: theme.spacing(3, 4),
    },
    [`&.${inputBaseClasses.inputSizeMedium}`]: {
      padding: theme.spacing(2, 4),
    },
    [`&.${inputBaseClasses.inputSizeSmall}`]: {
      padding: theme.spacing(1, 4),
    },
    color: theme.palette.text.dark,
    [`&.${inputBaseClasses.disabled}`]: {
      textFillColor: theme.palette.grey[600],
      cursor: 'not-allowed',
    },
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.grey[400],
      textFillColor: theme.palette.grey[400],
    },
  },
  [`&.${inputBaseClasses.adornedStart}`]: {
    paddingLeft: theme.spacing(2),
  },
  [`&.${inputBaseClasses.adornedEnd}`]: {
    paddingRight: theme.spacing(2),
  },
}));

export const HelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.grey[600],
}));

export const CharacterLimit = styled('span')(({ theme }) => ({
  marginLeft: 'auto',
}));
