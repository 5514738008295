import { FC } from 'react';

import { ActivityType, AppContext, CourseRole, TimelineItemType, User } from '../db/shared/types';

export type { User };
export { AppContext, ActivityType, CourseRole, TimelineItemType };

export enum AssignmentStatus {
  LATE = 'late',
  IN_PROGRESS = 'inProgress',
  SUBMITTED = 'submitted',
}

export enum AttendanceStatus {
  CHECKED_IN = 'checkedIn',
  PRESENT = 'present',
  ABSENT = 'absent',
  LATE = 'late',
  EXCUSED = 'excused',
}

export interface UserWithRole extends User {
  role: CourseRole;
}

export interface UserInfo extends UserWithRole {
  emailId: string;
}

export interface Attachment {
  originalName: string;
  name: string;
  extension: string;
}

export interface AttachmentRequestConfig<T> {
  data?: T;
  headers?: Record<string, string>;
  /**
   * @default 'POST'
   */
  method?: 'GET' | 'POST';
  url: string;
}

export interface TimelineWidgetData {
  readonly id: MongoId;
  readonly type: TimelineItemType;
}

export enum AlertOptionTypes {
  YES = 'yes',
  NO = 'no',
  CANCEL = 'cancel',
  OKAY = 'okay',
  NEXT = 'next',
  CONTINUE = 'continue',
  ADD = 'add',
  IGNORE = 'ignore',
  REPLAN = 'replan',
}

// FIXME: typings of getOptions is not perfect. When we have a use case, we can fix it accordingly.
export interface AlertBodyProps<T> {
  getContent: FC<T>;
  getOptions: Record<string, FC>;
}

export interface StripeCard {
  elementType: 'card';
  empty: boolean;
  complete: boolean;
  brand: string;
  value: {
    postalcode: '';
  };
  error:
    | {
        type: 'validation_error';
        code: string;
        message: string;
      }
    | undefined;
}
