import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../../components/ForNonDesktop';
import ChatButton from '../../components/HeaderActions/ChatButton';
import CloseButton from '../../components/HeaderActions/CloseButton';
import { selectCourse } from '../../db/courses/selectors';
import { i18nNS } from '../../i18n';
import routes, { CourseParams } from '../../pages/routes';
import { getUnseenComments } from '../../utils/activities';
import { forDesktop } from '../../utils/media-queries';

const useCourseAnalyticsHeaderActionsVM = () => {
  const params = useParams<CourseParams>();
  const course = useSelector(selectCourse(params.courseShortId));

  const numUnseenComments = course ? getUnseenComments(course) : 0;

  return { params, numUnseenComments };
};

const CourseAnalyticsHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.COURSES]);

  const { params, numUnseenComments } = useCourseAnalyticsHeaderActionsVM();

  return (
    <>
      <CloseButton
        to={routes.timeline.url(params)}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_close_course_analytics', { ns: i18nNS.COURSES })}
      />
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default CourseAnalyticsHeaderActions;
