import { SvgIcon, SvgIconProps } from '@mui/material';

const BoldIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 19.2V4.8C6 3.81 6.81 3 7.8 3H12.3C15 3 17.6694 4.26 17.7 7.4271C17.7 9.0813 16.8621 10.7841 15.4212 11.3187V11.4402C17.2509 11.9028 18.6 13.2645 18.6 15.6972C18.6 16.617 18.4173 17.4117 18.0843 18.0849C17.1033 20.064 15.081 21 12.3 21H7.8C6.81 21 6 20.19 6 19.2ZM8.7 18.3H12.3C14.6094 18.3 15.9 17.22 15.9 15.6C15.9 13.98 14.6328 12.9 12.3 12.9H8.7V18.3ZM8.7 10.2H11.4C13.5492 10.2 15 9.435 15 7.905C15 6.375 13.65 5.7 11.3964 5.7H8.7V10.2Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default BoldIcon;
