import { Root } from './styles';
import useEmptyCommentsMessage from './vm';

const EmptyCommentMessage = () => {
  const { message } = useEmptyCommentsMessage();

  return <Root>{message}</Root>;
};

export default EmptyCommentMessage;
