import AwardPoints from './AwardPoints';
import RetractPoints from './RetractPoints';
import useCommentAwardVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const CommentAward = ({ comment }: Props) => {
  const { canAward, hasAwarded, awardPoints } = useCommentAwardVM({ comment });

  if (!canAward) return null;

  if (hasAwarded) {
    return <RetractPoints commentId={comment.id} points={awardPoints} />;
  }

  return <AwardPoints commentId={comment.id} points={awardPoints} />;
};

export default CommentAward;
