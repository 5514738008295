import { useEffect } from 'react';

import i18n, { i18nNS } from '../../../i18n';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

const usePaymentFailed = () => {
  const { activeStep, closeDrawer, moveToPreviousStep, paymentErrorMessage, setTitle, setMobileTitle } =
    useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.PAYMENT_FAILED;

  const retryPayment = () => moveToPreviousStep({ skip: 1 });

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('payment_failed', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('payment_failed', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return { closeDrawer, paymentErrorMessage, isVisible, retryPayment };
};

export default usePaymentFailed;
