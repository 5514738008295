import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { fetchAvailableSlots } from '../../../db/courses/actions';
import { instructorPurchaseSuccessfulEvent } from '../../../db/courses/pusher-events';
import { API } from '../../../db/shared/api-responses';
import i18n, { i18nNS } from '../../../i18n';
import { usePusherEvent } from '../../../pusher/bus';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import {
  convertPaymentMethodToSlotId,
  convertSlotIdToPaymentMethod,
  getCanUsePurchasedCourse,
  getFormattedStudentPurchasePrices,
  getFreeMessage,
  getFreeMessageColor,
  getIsFreeCourse,
} from './helpers';
import { FormValues, PaymentMethod } from './types';
import validationSchema from './validators';

export default function usePaymentMethodVM() {
  const requestDispatch = useRequestDispatch();

  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    setIsCreatingProCourse,
    paymentMethodSlotId,
    setPaymentMethodSlotId,
    moveToNextStep: _moveToNextStep,
    moveToPreviousStep: _moveToPreviousStep,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const [isFetchingAvailableSlots, setIsFetchingAvailableSlots] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<API.GetAvailableSlotsResponse['slots'] | null>(null);

  const isVisible = activeStep === CreateCourseStep.PAYMENT_METHOD;

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      isCreatingProCourse: isCreatingProCourse ? 'true' : 'false',
      paymentMethod: PaymentMethod.STUDENT_PAYS,
    },
  });

  const { control, handleSubmit, watch, setValue, trigger } = formMethods;

  const [isProOptionSelected, paymentMethod] = watch(['isCreatingProCourse', 'paymentMethod']);
  const isPro = isProOptionSelected === 'true';

  const isBasicCourseFree = getIsFreeCourse({
    isCreatingProCourse: false,
    availableSlots,
  });

  const isProCourseFree = getIsFreeCourse({
    isCreatingProCourse: true,
    availableSlots,
  });

  const isFreeCourseSelected = getIsFreeCourse({
    isCreatingProCourse: isPro,
    availableSlots,
  });

  const canUsePurchasedCourse = getCanUsePurchasedCourse({
    isCreatingProCourse: isPro,
    availableSlots,
  });

  const freeMessage = getFreeMessage({
    isCreatingProCourse: isPro,
    paymentMethod,
    availableSlots,
  });

  const freeMessageColor = getFreeMessageColor(paymentMethod);

  const formattedStudentPurchasePrices = getFormattedStudentPurchasePrices({
    isCreatingProCourse: isPro,
    availableSlots,
  });

  const moveToPreviousStep = () => _moveToPreviousStep();

  const moveToNextStep: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit(async (data) => {
      const isValid = await trigger();
      if (!isValid) return;

      const { isCreatingProCourse: isCreatingProCourseString, paymentMethod } = data;

      const isCreatingProCourse = isCreatingProCourseString === 'true';
      setIsCreatingProCourse(isCreatingProCourse);

      const slotId = convertPaymentMethodToSlotId({ isCreatingProCourse, paymentMethod, availableSlots });
      setPaymentMethodSlotId(slotId ?? '');

      if (paymentMethod === PaymentMethod.INSTRUCTOR_PAYS) {
        _moveToNextStep();
      } else {
        _moveToNextStep({ skip: 6 });
      }
    })(e);
  };

  const getAvailableSlots = useCallback(() => {
    setIsFetchingAvailableSlots(true);
    requestDispatch(fetchAvailableSlots)
      .then((response) => setAvailableSlots(response.slots))
      .finally(() => setIsFetchingAvailableSlots(false));
  }, [requestDispatch]);

  usePusherEvent(instructorPurchaseSuccessfulEvent, getAvailableSlots);

  useEffect(
    function initAvailableSlots() {
      if (!isVisible) return;
      if (availableSlots) return;
      getAvailableSlots();
    },
    [isVisible, availableSlots, getAvailableSlots]
  );

  useEffect(
    function setInitialCourseType() {
      if (!isVisible) return;
      if (!availableSlots) return;
      if (isCreatingProCourse == null) return;

      setValue('isCreatingProCourse', isCreatingProCourse ? 'true' : 'false');
    },
    [isVisible, availableSlots, setValue, isCreatingProCourse]
  );

  useEffect(
    function setInitialPaymentMethod() {
      if (!isVisible) return;
      if (!availableSlots) return;

      let paymentMethod = convertSlotIdToPaymentMethod({ slotId: paymentMethodSlotId, availableSlots });
      if (paymentMethod === null) {
        if (isFreeCourseSelected) paymentMethod = PaymentMethod.FREE;
        else if (canUsePurchasedCourse) paymentMethod = PaymentMethod.PURCHASED;
        else paymentMethod = PaymentMethod.STUDENT_PAYS;
      }
      setValue('paymentMethod', paymentMethod);
    },
    [isVisible, availableSlots, setValue, paymentMethodSlotId, isFreeCourseSelected, canUsePurchasedCourse]
  );

  useEffect(
    function onChangeCourseType() {
      let paymentMethod: PaymentMethod;
      if (isFreeCourseSelected) paymentMethod = PaymentMethod.FREE;
      else if (canUsePurchasedCourse) paymentMethod = PaymentMethod.PURCHASED;
      else paymentMethod = PaymentMethod.STUDENT_PAYS;
      setValue('paymentMethod', paymentMethod);
    },
    [isFreeCourseSelected, canUsePurchasedCourse, setValue]
  );
  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('adding_a_new_acadly_course', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('new_course', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return {
    isVisible,
    closeDrawer,
    isFetchingAvailableSlots,
    control,
    isBasicCourseFree,
    isProCourseFree,
    isFreeCourseSelected,
    canUsePurchasedCourse,
    formattedStudentPurchasePrices,
    freeMessage,
    freeMessageColor,
    moveToNextStep,
    moveToPreviousStep,
  };
}
