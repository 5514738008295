import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const DownloadIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4 19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V17C20 16.4477 19.5523 16 19 16C18.4477 16 18 16.4477 18 17V18H6V17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17V19Z"
          fill="currentColor"
        />
        <path
          d="M11.002 12.0625C11.0007 12.0418 11 12.0209 11 12V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4V12C13 12.0024 13 12.0047 13 12.0071L15.4 10.2C15.6122 10.0409 15.8789 9.97255 16.1414 10.0101C16.404 10.0476 16.6409 10.1878 16.8 10.4C16.9591 10.6122 17.0275 10.8789 16.9899 11.1414C16.9524 11.404 16.8122 11.6409 16.6 11.8L12.6 14.8C12.4269 14.9298 12.2164 15 12 15C11.7927 15.0016 11.59 14.9387 11.42 14.82L7.42 12C7.20441 11.8471 7.05814 11.615 7.01317 11.3545C6.96819 11.0941 7.02817 10.8264 7.18 10.61C7.25578 10.5019 7.35224 10.4098 7.4638 10.3391C7.57536 10.2684 7.69982 10.2206 7.82998 10.1982C7.96014 10.1759 8.09344 10.1796 8.22217 10.2091C8.3509 10.2386 8.47252 10.2933 8.58 10.37L11.002 12.0625Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V17C20 16.4477 19.5523 16 19 16C18.4477 16 18 16.4477 18 17V18H6V17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17V19Z"
        fill="currentColor"
      />
      <path
        d="M11.002 12.0625C11.0007 12.0418 11 12.0209 11 12V4C11 3.73478 11.1054 3.48043 11.2929 3.29289C11.4804 3.10536 11.7348 3 12 3C12.2652 3 12.5196 3.10536 12.7071 3.29289C12.8946 3.48043 13 3.73478 13 4V12C13 12.0024 13 12.0047 13 12.0071L15.4 10.2C15.6122 10.0409 15.8789 9.97255 16.1414 10.0101C16.404 10.0476 16.6409 10.1878 16.8 10.4C16.9591 10.6122 17.0275 10.8789 16.99 11.1414C16.9525 11.404 16.8122 11.6409 16.6 11.8L12.6 14.8C12.4269 14.9298 12.2164 15 12 15C11.7927 15.0016 11.59 14.9387 11.42 14.82L7.42001 12C7.20442 11.8471 7.05815 11.615 7.01318 11.3545C6.96821 11.0941 7.02819 10.8264 7.18001 10.61C7.2558 10.5019 7.35226 10.4098 7.46382 10.3391C7.57538 10.2684 7.69983 10.2206 7.83 10.1982C7.96016 10.1759 8.09345 10.1796 8.22218 10.2091C8.35091 10.2386 8.47253 10.2933 8.58001 10.37L11.002 12.0625Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
