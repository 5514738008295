import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { AnnouncementCreatedEvent } from './pusher-events';
import {
  CreateAnnouncementPayload,
  CreateAnnouncementSuccessPayload,
  MarkAnnouncementAsReadPayload,
} from './types';

export const createAnnouncement = createRequestAction<
  CreateAnnouncementPayload,
  CreateAnnouncementSuccessPayload
>('announcements/create');

export const announcementCreated = createAction<AnnouncementCreatedEvent>('announcement/created');

export const markAnnouncementAsRead = createRequestAction<
  MarkAnnouncementAsReadPayload,
  MarkAnnouncementAsReadPayload
>('announcements/mark/read');
