import urls from '../../app/urls';
import { format, unix } from '../../utils/datetime';
import { pick } from '../../utils/helpers';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { QuizQuestionType } from '../shared/types';
import {
  AddQuizQuestionPayload,
  DeleteQuizQuestionPayload,
  EditPublishedQuizPayload,
  EditQuizQuestionPayload,
  PublishQuizPayload,
  QuizId,
  SaveQuizPublishPrefsPayload,
  SaveQuizSubmissionPayload,
  StopQuizPaylod,
  SubmitQuizPayload,
} from './types';

export async function getAllQuizzes() {
  const { data } = await request.get<API.GetAllQuizzesResponse>(urls.getAllQuizzes);
  return data;
}

export async function createQuiz(payload: API.CreateQuizRequest) {
  const { data } = await request.post<API.CreateQuizResponse>(urls.createQuiz, payload);
  return data;
}

export async function editQuiz(payload: API.EditQuizRequest) {
  await request.post(urls.editQuiz, payload);
}

export async function editPublishedQuiz(params: EditPublishedQuizPayload & { hasSubmissions: boolean }) {
  const payload: API.EditPublishedQuizRequest = {
    classId: params.classId,
    quizId: params.quizId,
    title: params.title,
    instructions: params.instructions,
    attachments: params.attachments,
    toNotify: params.notifyStudents ? 1 : 0,
    questionsOrder: params.questions.map(({ id }, index) => ({
      questionId: id,
      order: index + 1,
    })),
  };

  if (!params.hasSubmissions) {
    payload.questions = [];
    for (const question of params.questions) {
      if (question.type === QuizQuestionType.TF) {
        payload.questions.push({
          questionId: question.id,
          type: QuizQuestionType.TF,
          answerKey: question.answerKey,
          description: { text: question.description },
        });
      } else if (question.type === QuizQuestionType.MCQ) {
        payload.questions.push({
          questionId: question.id,
          type: QuizQuestionType.MCQ,
          answerKey: question.answerKey,
          description: { text: question.description },
          options: question.options,
        });
      } else {
        payload.questions.push({
          questionId: question.id,
          type: QuizQuestionType.SORTING,
          answerKey: question.answerKey,
          description: { text: question.description },
          options: question.options,
        });
      }
    }
  }

  const { data } = await request.put<API.EditPublishedQuizResponse>(urls.editPublishedQuiz, payload);
  return data;
}

export async function deleteQuiz(data: API.DeleteQuizRequest) {
  await request.delete(urls.deleteQuiz, { data });
}

export async function getQuizDetails(params: API.GetQuizDetailsRequest) {
  const { data } = await request.get<API.GetQuizDetailsResponse>(urls.getQuizDetails, { params });
  return data;
}

export async function saveQuizPublishPrefs(params: SaveQuizPublishPrefsPayload) {
  const payload: API.SaveQuizPrefsRequest = {
    classId: params.classId,
    activityType: 'quiz',
    activityId: params.quizId,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
    quizPref: {
      allowLate: params.allowLate ? 1 : 0,
      deadlineFirst: params.deadlineFirst ? 1 : 0,
      dueDateType: params.dueDateType,
      duration: params.duration,
      randomOpt: params.randomOptions ? 1 : 0,
      randomQues: params.randomQuestions ? 1 : 0,
      scoring: params.scoring,
      subscribeToComments: params.subscribeToComments ? 1 : 0,
    },
  };
  await request.post(urls.savePublishPrefs, payload);
}

export async function publishQuiz(params: PublishQuizPayload) {
  const payload: API.PublishQuizRequest = {
    classId: params.classId,
    quizId: params.quizId,
    toBeDone: params.preferences.toBeDone,
    dueDateTime: format(unix(), "yyyyMMdd'T'HH:mm"),
    scoring: params.preferences.scoring,
    allowLate: params.preferences.allowLate ? 1 : 0,
    deadlineFirst: params.preferences.deadlineFirst ? 1 : 0,
    dueDateType: params.preferences.dueDateType,
    duration: params.preferences.duration,
    randomOpt: params.preferences.randomOptions ? 1 : 0,
    randomQues: params.preferences.randomQuestions ? 1 : 0,
    subscribeToComments: params.preferences.subscribeToComments ? 1 : 0,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
  };
  const { data } = await request.post<API.PublishQuizResponse>(urls.publishQuiz, payload);
  return data;
}

export async function saveQuizQuestionResponse(payload: API.SaveQuizQuestionResponseRequest) {
  await request.post(urls.saveQuizQuestionResponse, payload);
}

export async function saveQuizSubmission({ courseId, ...params }: SaveQuizSubmissionPayload) {
  const payload: API.SaveQuizSubmissionRequest = params;
  await request.post(urls.saveQuizSubmission, payload, {
    headers: { courseId },
  });
}

export async function submitQuiz({ classId, quizId, responses, submissionType }: SubmitQuizPayload) {
  const payload: API.SubmitQuizRequest = {
    classId,
    quizId,
    submissionType,
    submission: responses,
    localTime: format(unix(), "yyyyMMdd'T'HH:mm"),
  };
  const { data } = await request.post<API.SubmitQuizResponse>(urls.submitQuiz, payload);
  return data;
}

export async function stopQuiz({ classId, quizId }: StopQuizPaylod) {
  const payload: API.StopQuizRequest = {
    classId,
    activityType: 'quizzes',
    activityId: quizId,
    localTime: format(unix(), "yyyyMMdd'T'HH:mm"),
  };
  const { data } = await request.post<API.StopQuizResponse>(urls.stopQuiz, payload);
  return data;
}

function toAddQuizQuestionRequest(params: AddQuizQuestionPayload): API.AddQuizQuestionRequest {
  if (params.type === QuizQuestionType.TF) {
    return {
      type: QuizQuestionType.TF,
      classId: params.classId,
      activityType: 'quizzes',
      activityId: params.quizId,
      answerKey: params.answerKey,
      description: { text: params.description },
    };
  }

  if (params.type === QuizQuestionType.MCQ) {
    return {
      type: QuizQuestionType.MCQ,
      classId: params.classId,
      activityType: 'quizzes',
      activityId: params.quizId,
      answerKey: params.answerKey,
      description: { text: params.description },
      options: params.options,
    };
  }

  return {
    type: QuizQuestionType.SORTING,
    classId: params.classId,
    activityType: 'quizzes',
    activityId: params.quizId,
    answerKey: params.answerKey,
    description: { text: params.description },
    options: params.options.map((option) => pick(option, 'type', 'text')),
  };
}

export async function addQuizQuestion(params: AddQuizQuestionPayload) {
  const payload = toAddQuizQuestionRequest(params);
  const { data } = await request.post<API.AddQuizQuestionResponse>(urls.addQuizQuestion, payload);
  return data;
}

export async function editQuizQuestion(params: EditQuizQuestionPayload) {
  const payload: API.EditQuizQuestionRequest = {
    ...toAddQuizQuestionRequest(params),
    questionId: params.questionId,
  };
  const { data } = await request.post<API.EditQuizQuestionResponse>(urls.editQuizQuestion, payload);
  return data;
}

export async function reorderQuizQuestions(payload: API.ReorderQuizQuestionsRequest) {
  await request.put(urls.reorderQuizQuestions, payload);
}

export async function deleteQuizQuestion({ classId, quizId, questionId }: DeleteQuizQuestionPayload) {
  const data: API.DeleteQuizQuestionRequest = {
    classId,
    activityType: 'quizzes',
    activityId: quizId,
    questionId,
  };
  await request.delete(urls.deleteQuizQuestion, { data });
}

export async function getQuizQuestionStats({ quizId, questionId }: API.GetQuizQuestionStatsRequest) {
  const { data } = await request.get<API.GetQuizQuestionStatsResponse>(
    urls.getQuizQuestionStats(quizId, questionId)
  );
  return data;
}

export async function getQuizSubmissions({ quizId }: QuizId) {
  const { data } = await request.get<API.GetQuizSubmissionsResponse>(urls.getQuizSubmissions(quizId));
  return data;
}

export async function getIndividualQuizSubmission({
  quizId,
  studentId,
}: API.GetIndividualQuizSubmissionRequest) {
  const { data } = await request.get<API.GetIndividualQuizSubmissionResponse>(
    urls.getIndividualQuizSubmission(quizId, studentId)
  );
  return data;
}
