import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { AddCourseLinkSuccessPayload, FetchCourseLinksSuccessPayload } from './types';

export const fetchCourseLinks = createRequestAction<
  API.GetCourseLinksRequest,
  FetchCourseLinksSuccessPayload
>('links/fetch');

export const addCourseLink = createRequestAction<API.AddCourseLinkRequest, AddCourseLinkSuccessPayload>(
  'links/add'
);

export const removeCourseLink = createRequestAction<API.RemoveCourseLinkRequest, API.RemoveCourseLinkRequest>(
  'links/remove'
);
