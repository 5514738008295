import { SyntheticEvent } from 'react';

import { styled } from '@mui/material/styles';

import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import TabPanel from '../../components/Tabs/TabPanel';
import { MAIN_CONTENT_WIDTH } from '../../styles/theme';
import FormCheckbox from './FormCheckbox';
import FormDatepicker from './FormDatepicker';
import FormDropdown from './FormDropdown';
import FormRadioGroup from './FormRadioGroup';
import FormSelect from './FormSelect';
import FormSlider from './FormSlider';
import FormSwitch from './FormSwitch';
import FormTextField from './FormTextField';
import FormTimepicker from './FormTimepicker';

export enum TabItems {
  TEXT_FIELD = 'text-field',
  DROPDOWN = 'drop-down',
  SELECT = 'select',
  DATE_PICKER = 'date picker',
  TIME_PICKER = 'time picker',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  SLIDER = 'slider',
}

interface Props {
  activeTab: TabItems;
  onChangeTab: (newValue: TabItems) => void;
}

const Root = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: MAIN_CONTENT_WIDTH,
  margin: '0 auto',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
}));

const FormControlsTabs = ({ activeTab, onChangeTab: handleChangeTab }: Props) => {
  const handleChangeActiveTab = (event: SyntheticEvent, newValue: TabItems) => {
    handleChangeTab(newValue);
  };

  return (
    <Root>
      <StyledTabs variant="scrollable" value={activeTab} onChange={handleChangeActiveTab}>
        <StyledTab value={TabItems.TEXT_FIELD} label="Text Field" />
        <StyledTab value={TabItems.DROPDOWN} label="Dropdown" />
        <StyledTab value={TabItems.SELECT} label="Select" />
        <StyledTab value={TabItems.DATE_PICKER} label="Datepicker" />
        <StyledTab value={TabItems.TIME_PICKER} label="Timepicker" />
        <StyledTab value={TabItems.RADIO} label="Radio" />
        <StyledTab value={TabItems.CHECKBOX} label="Checkbox" />
        <StyledTab value={TabItems.SWITCH} label="Switch" />
        <StyledTab value={TabItems.SLIDER} label="Slider" />
      </StyledTabs>
      <StyledTabPanel isActive={activeTab === TabItems.TEXT_FIELD}>
        <FormTextField />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.DROPDOWN}>
        <FormDropdown />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.SELECT}>
        <FormSelect />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.DATE_PICKER}>
        <FormDatepicker />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.TIME_PICKER}>
        <FormTimepicker />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.RADIO}>
        <FormRadioGroup />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.CHECKBOX}>
        <FormCheckbox />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.SWITCH}>
        <FormSwitch />
      </StyledTabPanel>
      <StyledTabPanel isActive={activeTab === TabItems.SLIDER}>
        <FormSlider />
      </StyledTabPanel>
    </Root>
  );
};

export default FormControlsTabs;
