const glossary = {
  prev: 'Prev',
  previous: 'Previous',
  next: 'Next',
  okay: 'Okay',
  cancel: 'Cancel',
  continue: 'Continue',
  upload: 'Upload',
  uploading: 'Uploading',
  preview: 'Preview',
  done: 'Done',
  organization: 'Organization',
  role: 'Role',
  home: 'Home',
  settings: 'Settings',
  feedback: 'Feedback',
  logout: 'Logout',
  expand: 'Expand',
  minimise: 'Minimise',
  help: 'Help',
  menu: 'Menu',
  you: 'You',
  published: 'Published',
  unpublished: 'Unpublished',
  legal: 'Legal',
  contact: 'Contact',
  timeline: 'Timeline',
  info: 'Info',
  syllabus: 'Syllabus',
  class: 'Class',
  canceled: 'Canceled',
  holiday: 'Holiday',
  completed: 'Completed',
  activities: 'Activities',
  announcement: 'Announcement',
  announcements: 'Announcements',
  assignment: 'Assignment',
  assignments: 'Assignments',
  untitled: 'Untitled',
  status: 'Status',
  ungraded: 'Ungraded',
  graded: 'Graded',
  unsubmitted: 'Unsubmitted',
  unattempted: 'Unattempted',
  edited: 'Edited',
  download: 'Download',
  post: 'Post',
  reactions: 'Reactions',
  thanked: 'Thanked',
  liked: 'Liked',
  rating: 'Rating',
  delete: 'Delete',
  awards: 'Awards',
  confirm: 'Confirm',
  confirmation: 'Confirmation',
  no: 'No',
  yes: 'Yes',
  warning: 'Warning',
  students: 'Students',
  averages: 'Averages',
  export: 'Export',
  success: 'Success',
  active: 'Active',
  or: 'Or',
  remind: 'Remind',
  reinstate: 'Reinstate',
  scores: 'Scores',
  participation: 'Participation',
  initiated: 'Initiated',
  instances: 'Instances',
  enrolled: 'Enrolled',
  archives: 'Archives',
  information: 'Information',
  recommended: 'Recommended',
  required: 'Required',
  links: 'Links',
  files: 'Files',
  timezone: 'Timezone',
  from: 'From',
  till: 'Till',
  save: 'Save',
  admin: 'Admin',
  instructor: 'Instructor',
  ta: 'TA',
  ignore: 'Ignore',
  replan: 'Re-plan',
  archived: 'Archived',
  back: 'Back',
  subject: 'Subject',
  body: 'Body',
  publish: 'Publish',
  title: 'Title',
  description: 'Description',
  submit: 'Submit',
  attachments: 'Attachments',
  questions: 'Questions',
  url: 'Url',
  copy: 'Copy',
  move: 'Move',
  details: 'Details',
  options: 'Options',
  warnings: 'Warnings',
  instructions: 'Instructions',
  file: 'File',
  question: 'Question',
  submission: 'Submission',
  one: 'One',
  attempted: 'Attempted',
  allowed: 'Allowed',
  edit: 'Edit',
  update: 'Update',
  use: 'Use',
  used: 'Used',
  hidden: 'Hidden',
  retract: 'Retract',
  submissions: 'Submissions',
  comments: 'Comments',
  add: 'Add',
  course: 'Course',
  venue: 'Venue',
  author: 'Author',
  isbn: 'ISBN',
  suggested: 'Suggested',
  topics: 'Topics',
  topic: 'Topic',
  schedule: 'Schedule',
  agenda: 'Agenda',
  attendance: 'Attendance',
  on: 'On',
  off: 'Off',
  maintain: 'Maintain',
  start: 'Start',
  end: 'End',
  dismiss: 'Dismiss',
  na: 'N/A',
  quiz: 'Quiz',
  quizzes: 'Quizzes',
  poll: 'Poll',
  polls: 'Polls',
  urgent: 'Urgent',
  close: 'Close',
  closed: 'Closed',
  due: 'Due',
  past_due: 'Past due',
  new: 'New',
  assistant: 'Assistant',
  incharge: 'In-Charge',
  outline: 'Outline',
  discussion: 'Discussion',
  discussions: 'Discussions',
  query: 'Query',
  queries: 'Queries',
  resource: 'Resource',
  resources: 'Resources',
  open: 'Open',
  pending: 'Pending',
  summary: 'Summary',
  true: 'True',
  false: 'False',
  answers: 'Answers',
  score: 'Score',
  scoring: 'Scoring',
  incentivizing: 'Incentivizing',
  neutral: 'Neutral',
  penalizing: 'Penalizing',
  anonymous: 'Anonymous',
  non_anonymous: 'Non Anonymous',
  not_anonymous: 'Not Anonymous',
  late: 'Late',
  later: 'Later',
  deadline: 'Deadline',
  manually: 'Manually',
  stop: 'Stop',
  review: 'Review',
  text: 'Text',
  video: 'Video',
  link: 'Link',
  type: 'Type',
  scheduled: 'Scheduled',
  recorded: 'Recorded',
  present: 'Present',
  absent: 'Absent',
  excused: 'Excused',
  hide: 'Hide',
  approve: 'Approve',
  remove: 'Remove',
  input: 'Input',
  comment: 'Comment',
  send: 'Send',
  clear: 'Clear',
  more: 'More',
  create: 'Create',
  password: 'Password',
  faculty: 'Faculty',
  student: 'Student',
  resolve: 'Resolve',
  activity: 'Activity',
  messages: 'Messages',
  email: 'Email',
  login: 'Login',
  profile: 'Profile',
  faq: 'FAQ',
  like: 'Like',
  thank: 'Thank',
  offline: 'Offline',
  online: 'Online',
  free: 'Free',
  now: 'Now',
  analytics: 'Analytics',
  in_person: 'In-person',
  payment: 'Payment',
  name: 'Name',
  country: 'Country',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  card: 'Card',
  submitted: 'Submitted',
  words: 'Words',
  cloud: 'Cloud',
  integrations: 'Integrations',
  synced: 'Synced',
  cost: 'Cost',
  basic: 'Basic',
  pro: 'Pro',
  pay: 'Pay',
};

export default glossary;
