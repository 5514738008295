import { AriaAttributes, forwardRef, ReactNode, Ref } from 'react';

import { Divider } from '@mui/material';

import clsx from 'clsx';

import Placeholder from './Placeholder';
import { Children, Root, sectionClasses, Title } from './styles';

export interface Props extends AriaAttributes {
  title: ReactNode;
  children: ReactNode;
  /** @default 'default' */
  variant?: 'default' | 'transparent' | 'emphasized';
  className?: string;
  classes?: Partial<Record<keyof typeof sectionClasses, string>>;
  tabIndex?: number;
}

export { sectionClasses };

const Section = forwardRef(
  (
    { title, children, variant = 'default', className, classes, ...props }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <Root
        ref={ref}
        className={clsx(
          className,
          {
            [sectionClasses.variantDefault]: variant === 'default',
            [sectionClasses.variantTransparent]: variant === 'transparent',
            [sectionClasses.variantEmphasized]: variant === 'emphasized',
          },
          classes?.root,
          classes?.variantDefault ? { [classes.variantDefault]: variant === 'default' } : undefined,
          classes?.variantTransparent
            ? { [classes.variantTransparent]: variant === 'transparent' }
            : undefined,
          classes?.variantEmphasized ? { [classes.variantEmphasized]: variant === 'emphasized' } : undefined
        )}
        divider={variant === 'default' ? <Divider /> : null}
        {...props}
      >
        {typeof title === 'string' ? (
          <Title
            variant={variant === 'default' ? 'h6Bold' : 'h5Bold'}
            className={clsx(sectionClasses.title, classes?.title)}
          >
            {title}
          </Title>
        ) : (
          title
        )}
        {typeof children === 'string' ? (
          variant === 'transparent' ? (
            <Placeholder className={clsx(sectionClasses.children, classes?.children)}>{children}</Placeholder>
          ) : (
            <Children className={clsx(sectionClasses.children, classes?.children)}>{children}</Children>
          )
        ) : (
          children
        )}
      </Root>
    );
  }
);

export default Section;
