import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { useCurrentUserWithRole } from '../courses/hooks';
import { selectQuiz } from '../db/quizzes/selectors';
import { CourseRole } from '../db/shared/types';
import { i18nNS } from '../i18n';
import routes, { QuizParams } from '../pages/routes';
import { getIsActivityPublished, getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';

const QuizHeaderActionsVM = () => {
  const params = useParams<QuizParams>();

  const quiz = useSelector(selectQuiz(params.quizShortId));
  const isQuizPublished = getIsActivityPublished({ publishedOn: quiz?.publishedOn });

  const currentUser = useCurrentUserWithRole();

  const numUnseenComments = quiz ? getUnseenComments(quiz) : 0;

  return { params, isQuizPublished, currentUser, numUnseenComments };
};

const QuizHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.QUIZ]);

  const { params, isQuizPublished, currentUser, numUnseenComments } = QuizHeaderActionsVM();

  return (
    <>
      {currentUser.role !== CourseRole.STUDENT && isQuizPublished && (
        <AnalyticsButton
          to={routes.quizAnalytics.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_quiz_analytics', { ns: i18nNS.QUIZ })}
        />
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default QuizHeaderActions;
