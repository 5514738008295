import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import Switch from '../../../components/HookFormControls/Switch';
import { BlueprintCoursePreview } from '../../../db/courses/types';
import { TopicType } from '../../../db/topics/types';
import { i18nNS } from '../../../i18n';
import { FormValues } from './vm';

const Topic = styled((props: TypographyProps<'div'>) => <Typography {...props} component="div" />)(
  ({ theme }) => ({
    marginLeft: theme.spacing(4),
  })
);

const SubTopic = styled((props: TypographyProps<'div'>) => <Typography {...props} component="div" />)(
  ({ theme }) => ({
    marginLeft: theme.spacing(8),
  })
);

interface Props {
  control: Control<FormValues, object>;
  topics?: BlueprintCoursePreview['topics'];
  isSubmitting: boolean;
}

const CopyCourseTopics = ({ control, topics, isSubmitting }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6Bold" component="label">
          {t('copy_course_topics', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Switch
          name="topics"
          control={control}
          edge="end"
          inputProps={{
            'aria-label': t('click_to_select_copy_course_topics', { ns: i18nNS.CREATE_COURSE }),
            role: 'switch',
          }}
          disabled={!(topics && topics.length > 0) || isSubmitting}
        />
      </Stack>
      {topics && topics.length > 0 ? (
        topics.map((topic) =>
          topic.type === TopicType.SUB_TOPIC ? (
            <SubTopic key={topic.id} variant="h6Medium">
              {topic.title}
            </SubTopic>
          ) : (
            <Topic key={topic.id} variant="h6Bold">
              {topic.title}
            </Topic>
          )
        )
      ) : (
        <Typography>{t('this_course_has_no_topics_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
      )}
    </Stack>
  );
};

export default CopyCourseTopics;
