import CreateCourseProvider from '../Context';
import Root from './Root';

interface Props {
  open: boolean;
  onClose: () => void;
  /**
   * If specified, course creation will resume from
   * last saved state of course creation
   */
  courseId?: MongoId;
  /** @default true */
  isCreatingProCourse?: boolean;
}

const CreateCourseDrawer = ({
  courseId = '',
  isCreatingProCourse = true,
  open,
  onClose: handleClose,
}: Props) => {
  return (
    <CreateCourseProvider
      open={open}
      onClose={handleClose}
      courseId={courseId}
      isCreatingProCourse={isCreatingProCourse}
    >
      <Root />
    </CreateCourseProvider>
  );
};

export default CreateCourseDrawer;
