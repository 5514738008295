import { useTranslation } from 'react-i18next';

import Drawer from '../../../components/Drawer';
import { i18nNS } from '../../../i18n';
import ChooseAvatar from '../ChooseAvatar';
import { CancelButton, SaveButton } from './styles';
import useChangeAvatarDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  open: boolean;
}

const ChangeAvatarDrawer = ({ open, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.COMMON]);

  const { onClose: handleClose } = vmOptions;
  const { chooseAvatarRef, handleChooseAvatarDone, canSubmit, getFormValidationStatus } =
    useChangeAvatarDrawerVM({ ...vmOptions });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      header={t('set_avatar', { ns: i18nNS.AUTH })}
      footer={
        <>
          <CancelButton
            variant="text"
            onClick={handleClose}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </CancelButton>
          <SaveButton
            disabled={!canSubmit}
            onClick={chooseAvatarRef.current?.submit}
            aria-label={t('click_to_change_avatar', { ns: i18nNS.COMMON })}
          >
            {t('save', { ns: i18nNS.GLOSSARY })}
          </SaveButton>
        </>
      }
    >
      <ChooseAvatar
        canSetInitialValues={open}
        ref={chooseAvatarRef}
        getFormValidationStatus={getFormValidationStatus}
        onDone={handleChooseAvatarDone}
      />
    </Drawer>
  );
};

export default ChangeAvatarDrawer;
