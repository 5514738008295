import { Children, cloneElement, isValidElement, KeyboardEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Breadcrumbs as MuiBreadcrumbs,
  breadcrumbsClasses,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';

import { v4 as uuid } from 'uuid';

import { BreadcrumbItem } from '../../app/BreadcrumbProvider';
import { i18nNS } from '../../i18n';
import ForDesktop from '../ForDesktop';
import ForNonDesktop from '../ForNonDesktop';

interface Props {
  children?: ReactNode;
  pageTitle?: BreadcrumbItem | null;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  color: theme.palette.primary[500],
  fontWeight: theme.typography.fontWeightMedium,
  outlineOffset: theme.spacing(1.5),

  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    '& [aria-selected="true"]': {
      outline: `2px solid ${theme.palette.primary[400]}`,
      outlineOffset: theme.spacing(0.5),
    },
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    flexWrap: 'nowrap',
  },
  [`& .${breadcrumbsClasses.li}`]: {
    whiteSpace: 'nowrap',
  },
  [`& .${breadcrumbsClasses.separator}`]: {
    marginLeft: 1,
    marginRight: 1,
  },
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[900],
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Breadcrumb = ({ children: _children, pageTitle }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  // State childrenIds and focusIndex are use for keyboard navgation
  const [childrenIds, setChildrenIds] = useState<string[]>([]);
  const [focusIndex, setFocusIndex] = useState(-1);

  const title = pageTitle?.status
    ? t('activity_bracket_status', {
        ns: i18nNS.COMMON,
        activity: pageTitle.extraContent ?? pageTitle.content,
        status: pageTitle.status,
      })
    : pageTitle?.extraContent ?? pageTitle?.content;

  const children = Children.toArray(_children);

  useEffect(
    function getChildrenIds() {
      const ids = Array(children.length)
        .fill(null)
        .map(() => {
          const id = uuid();
          return `breadcrumb-item-${id}`;
        });
      setChildrenIds(ids);
    },
    [children.length]
  );

  const handleFocus = () => {
    setFocusIndex(0);
  };

  const handleBlur = () => {
    setFocusIndex(-1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLOListElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
        setFocusIndex((prevIndex) => (prevIndex === 0 ? children.length - 1 : prevIndex - 1));
        break;
      case 'ArrowRight':
        setFocusIndex((prevIndex) => (prevIndex === children.length - 1 ? 0 : prevIndex + 1));
        break;
      case 'Enter':
        const activeItemId = childrenIds[focusIndex];
        if (!activeItemId) break;
        const firstChildOfActiveDescendant = document.querySelector(`#${activeItemId}`);
        if (!firstChildOfActiveDescendant) break;
        if (!('click' in firstChildOfActiveDescendant)) break;
        if (typeof firstChildOfActiveDescendant.click !== 'function') break;
        firstChildOfActiveDescendant.click();
        break;
      default:
        break;
    }
  };

  return (
    <Root>
      <ForDesktop>
        <Breadcrumbs
          separator={<ChevronRightIcon fontSize="xSmall" />}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          aria-activedescendant={childrenIds[focusIndex] ?? undefined}
          aria-label="breadcrumb"
          tabIndex={children.length === 0 ? -1 : 0}
        >
          {children.map((child, index) => {
            const isFocused = focusIndex === index;
            return (
              isValidElement(child) &&
              cloneElement(
                child,
                { ...child.props, id: childrenIds[index], tabIndex: -1, 'aria-selected': isFocused },
                child.props.children
              )
            );
          })}
          {/*
            empty span is used to fool breadcrumb to think there is an item at the end
            so that breadcrumb adds a trailing slash
          */}
          <span aria-hidden />
        </Breadcrumbs>
        <PageTitle variant="h6Bold">{title || <Skeleton variant="text" width={200} />}</PageTitle>
      </ForDesktop>
      <ForNonDesktop>
        <PageTitle variant="h5Regular">{title || <Skeleton variant="text" width={200} />}</PageTitle>
      </ForNonDesktop>
    </Root>
  );
};

export default Breadcrumb;
