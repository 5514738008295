import { useTranslation } from 'react-i18next';

import { CourseRole } from '../../../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../../../i18n';
import { commentCreatorClasses, Role, StyledUserCell } from './styles';
import useCommentCreatorVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
  avatar: string;
  name: string;
  role?: CourseRole;
}

const CommentCreator = ({ className, avatar, name, role, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS, i18nNS.GLOSSARY]);

  const { time } = useCommentCreatorVM(vmOptions);

  return (
    <StyledUserCell
      size="medium"
      avatar={avatar}
      title={name}
      subtitle={`${time} ago`}
      action={role && role !== CourseRole.STUDENT && <Role label={t(role, { ns: i18nNS.GLOSSARY })} />}
      className={className}
      classes={{
        avatar: commentCreatorClasses.avatar,
        content: commentCreatorClasses.content,
        subtitle: commentCreatorClasses.subtitle,
      }}
    />
  );
};

export default CommentCreator;
