import { Course } from '../../../db/courses/types';
import { addDateTime, now, startOfDay, toUnix, unix } from '../../../utils/datetime';

const LS_KEY = 'next_show_payment_message_timestamp';

export function getIsPaymentPending({ course }: { course: Course | undefined }) {
  if (!course || !course.status.isLive || course.status.isArchived) return false;
  return !course.payment.hasPaid && course.payment.payTill > 0;
}

export function getCanDismissPaymentWarning({ course }: { course: Course | undefined }) {
  if (!course) return true;
  const currentTimestamp = unix();
  return !course.payment.hasPaid && currentTimestamp < course.payment.payTill;
}

export function updatePaymentWarningTime() {
  const nextDayStart = startOfDay(addDateTime(now(), 1, 'days'));
  const nextDayStartUnix = toUnix(nextDayStart);
  window.localStorage.setItem(LS_KEY, nextDayStartUnix.toString());
}

export function getShouldShowPaymentWarning({ course }: { course: Course | undefined }) {
  const earliestTimeToShow = parseFloat(window.localStorage.getItem(LS_KEY) || '-1');
  const isPaymentPending = getIsPaymentPending({ course });
  if (!course || !isPaymentPending) return false;
  const currentTimestamp = unix();
  return earliestTimeToShow < currentTimestamp;
}
