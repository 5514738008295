import { createReducer } from '@reduxjs/toolkit';

import { closeCourse, openCourse } from '../courses/actions';
import { API } from '../shared/api-responses';
import {
  commentCreated,
  commentDeleted,
  commentMarked,
  commentRated,
  createComment,
  deleteComment,
  fetchCommentReactions,
  fetchComments,
  markComment,
  markCommentsAsSeen,
  rateComment,
  setCanUpdateNewIndicatorPosition,
  setCommentContext,
} from './actions';
import {
  addCommentCaseReducer,
  markCommentCaseReducer,
  rateCommentCaseReducer,
  removeCommentCaseReducer,
} from './case-reducers';
import {
  createCommentFactory,
  getIsFirstCommentInGroup,
  getIsSameCommentContext,
  joinCommentList,
} from './helpers';
import { CommentContextData, CommentMyReactions, CommentsState } from './types';

const initialState: CommentsState = {
  courseId: '',
  isFetching: false,
  context: null,
  firstUnseenCommentId: null,
  initialUnseenCommentsCount: 0,
  byId: {},
  order: [],
  myReactionsByCommentId: {},
  newCommentsCount: 0,
  canUpdateNewIndicatorPosition: false,
};

const defaultMyReactions: CommentMyReactions = {
  hasLiked: false,
  hasThanked: false,
  awardPoints: 0,
};

const commentsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    const { courseId } = action.payload;
    state.courseId = courseId;
  });

  builder.addCase(closeCourse, () => initialState);

  builder.addCase(fetchComments.request, (state, action) => {
    const { lastCommentId } = action.payload;
    if (lastCommentId) return;
    state.isFetching = true;
  });

  builder.addCase(fetchComments.failure, (state) => {
    state.isFetching = false;
  });

  builder.addCase(fetchComments.success, (state, action) => {
    const { context, commentsData, unseenCommentsCount, userData, lastCommentId, currentUser } =
      action.payload;

    state.isFetching = false;

    if (!lastCommentId) {
      // No last comment-id means it's a fresh fetch so clear previously fetch data
      state.byId = {};
      state.order = [];
      state.newCommentsCount = 0;
      state.firstUnseenCommentId = null;
      state.initialUnseenCommentsCount = unseenCommentsCount;

      // set current context
      state.context = context;
    } else if (!getIsSameCommentContext(state.context, context)) {
      // most probably a race condition so ignore to update state
      return;
    }

    if (!commentsData.length) return;

    for (const commentId in userData) {
      state.myReactionsByCommentId[commentId] = {
        hasLiked: Boolean(userData?.[commentId]?.starred),
        hasThanked: Boolean(userData?.[commentId]?.helpful),
        awardPoints: 0, // award points will be filled in later when we loop through comments
      };
    }

    const order: MongoId[] = [];
    let prevComment: API.Comment | undefined = undefined;

    for (const commentData of commentsData) {
      state.byId[commentData._id] = createCommentFactory({
        comment: commentData,
        isFirstCommentInGroup:
          !prevComment || getIsFirstCommentInGroup(prevComment.details, commentData.details),
      });

      order.push(commentData._id);

      state.myReactionsByCommentId[commentData._id] = {
        hasLiked:
          state.myReactionsByCommentId[commentData._id]?.hasLiked ??
          Boolean(userData?.[commentData._id]?.starred) ??
          false,
        hasThanked:
          state.myReactionsByCommentId[commentData._id]?.hasThanked ??
          Boolean(userData?.[commentData._id]?.helpful) ??
          false,
        awardPoints:
          commentData.stats.awards.find((a) => a.awardedBy.userId === currentUser.userId)?.points ?? 0,
      };

      prevComment = commentData;
    }

    state.order = joinCommentList({
      commentsById: state.byId,
      list1: order,
      list2: state.order,
    });

    if (state.initialUnseenCommentsCount > 0 && !state.firstUnseenCommentId) {
      state.firstUnseenCommentId = state.order.at(-state.initialUnseenCommentsCount) ?? null;
    }

    const comment = state.byId[state.firstUnseenCommentId || ''];

    if (comment) {
      comment.isFirstCommentInGroup = true;
    }
  });

  builder.addCase(createComment.success, (state, action) => {
    const { comment, context } = action.payload;
    state.order = addCommentCaseReducer({
      comment,
      commentsById: state.byId,
      context,
      currentContext: state.context,
      order: state.order,
    });

    state.newCommentsCount = 0;
    state.firstUnseenCommentId = null;
  });

  builder.addCase(commentCreated, (state, action) => {
    const {
      classId = null,
      commentId,
      context: contextType,
      contextId,
      currentUser,
      details,
      sender,
      stats,
      subContext,
      canSeeComments,
    } = action.payload;

    if (!canSeeComments) return;

    const context = { id: contextId, type: contextType, subType: subContext, classId } as CommentContextData;

    state.order = addCommentCaseReducer({
      comment: { _id: commentId, details, stats },
      commentsById: state.byId,
      context,
      currentContext: state.context,
      order: state.order,
    });

    const isSameCommentContext = getIsSameCommentContext(state.context, context);

    if (sender.userId === currentUser.userId) {
      state.newCommentsCount = 0; // mark all comments as seen
    } else if (isSameCommentContext && state.canUpdateNewIndicatorPosition) {
      state.newCommentsCount++;
      state.firstUnseenCommentId = state.order[state.order.length - state.newCommentsCount] ?? null;
    }
  });

  builder.addCase(markCommentsAsSeen.success, (state, action) => {
    const { context } = action.payload;

    // do nothing if context is changed
    if (!getIsSameCommentContext(context, state.context)) return;

    state.newCommentsCount = 0;
  });

  builder.addCase(setCommentContext, (state, action) => {
    const context = action.payload;

    if (!getIsSameCommentContext(state.context, context)) {
      // context is changed so clear comment data
      state.byId = {};
      state.order = [];
      state.newCommentsCount = 0;
      state.firstUnseenCommentId = null;
      state.initialUnseenCommentsCount = 0;
    }

    state.context = context;
  });

  builder.addCase(fetchCommentReactions.success, (state, action) => {
    const { commentId, approvers, users } = action.payload;

    const comment = state.byId[commentId];
    if (!comment) return;

    if (!comment.reactions.likedBy) {
      comment.reactions.likedBy = {};
    }

    if (!comment.reactions.thankedBy) {
      comment.reactions.thankedBy = {};
    }

    for (const approver of [...approvers, ...users]) {
      const user = {
        userId: approver._id,
        name: approver.name,
        avatar: approver.avatar,
      };

      if (approver.liked) {
        comment.reactions.likedBy[approver._id] = user;
      }

      if (approver.thanked) {
        comment.reactions.thankedBy[approver._id] = user;
      }
    }
  });

  builder.addCase(deleteComment.success, (state, action) => {
    const { commentId, currentUser } = action.payload;
    removeCommentCaseReducer({ comment: state.byId[commentId], user: currentUser });
  });

  builder.addCase(commentDeleted, (state, action) => {
    const { commentId, sender } = action.payload;
    removeCommentCaseReducer({ comment: state.byId[commentId], user: sender });
  });

  builder.addCase(markComment.success, (state, action) => {
    const { commentId, currentUser, mark } = action.payload;

    if (!state.myReactionsByCommentId[commentId]) {
      state.myReactionsByCommentId[commentId] = defaultMyReactions;
    }

    markCommentCaseReducer({
      comment: state.byId[commentId],
      myReactions: state.myReactionsByCommentId[commentId]!,
      isCurrentUser: true,
      mark,
      user: currentUser,
    });
  });

  builder.addCase(commentMarked, (state, action) => {
    const { commentId, marked: mark, currentUser, sender } = action.payload;

    if (!state.myReactionsByCommentId[commentId]) {
      state.myReactionsByCommentId[commentId] = defaultMyReactions;
    }

    markCommentCaseReducer({
      comment: state.byId[commentId],
      myReactions: state.myReactionsByCommentId[commentId]!,
      isCurrentUser: currentUser.userId === sender.userId,
      mark,
      user: currentUser,
    });
  });

  builder.addCase(rateComment.success, (state, action) => {
    const { commentId, currentUser, points } = action.payload;

    if (!state.myReactionsByCommentId[commentId]) {
      state.myReactionsByCommentId[commentId] = defaultMyReactions;
    }

    rateCommentCaseReducer({
      comment: state.byId[commentId],
      myReactions: state.myReactionsByCommentId[commentId]!,
      isCurrentUser: true,
      points,
      awardedBy: currentUser,
    });
  });

  builder.addCase(commentRated, (state, action) => {
    const { commentId, currentUser, points, sender } = action.payload;

    if (!state.myReactionsByCommentId[commentId]) {
      state.myReactionsByCommentId[commentId] = defaultMyReactions;
    }

    rateCommentCaseReducer({
      comment: state.byId[commentId],
      myReactions: state.myReactionsByCommentId[commentId]!,
      isCurrentUser: currentUser.userId === sender.userId,
      points,
      awardedBy: sender,
    });
  });

  builder.addCase(setCanUpdateNewIndicatorPosition, (state, action) => {
    state.canUpdateNewIndicatorPosition = action.payload;
  });
});

export default commentsReducer;
