import { RefObject, useState } from 'react';

import { Editor } from 'slate';

import Logger from '../../../utils/logger';
import { insertFormula } from './helpers';
import { FormulaProps } from './types';

const logger = Logger.create('richtext-editor');

export function useCreateFormulaOverlay(editorRef: RefObject<Editor | null>) {
  const [isOpen, setIsOpen] = useState(false);

  const show = () => setIsOpen(true);

  const onInsertFormula = (formulaProps: FormulaProps | null) => {
    setIsOpen(false);

    if (!editorRef.current) return;

    if (!formulaProps) return;

    try {
      editorRef.current.restoreSelection();
      insertFormula(editorRef.current, formulaProps);
    } catch (error) {
      logger.error('formula insertion failed', error);
    }
  };

  return { isOpen, onInsertFormula, show };
}
