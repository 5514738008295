import { Trans, useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Alert from '../../../components/Alert';
import AlertButton from '../../../components/AlertButton';
import { i18nNS } from '../../../i18n';
import CheckmarkCircle2Icon from '../../../icons/CheckmarkCircle2Icon';
import CloseCircleIcon from '../../../icons/CloseCircleIcon';
import Edit2Icon from '../../../icons/Edit2Icon';
import RocketIcon from '../../../icons/RocketIcon';
import { forNonDesktop } from '../../../utils/media-queries';
import usePublishCourseVM from './vm';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary[100],
  minHeight: 60,
  padding: theme.spacing(3, 8),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  [forNonDesktop(theme)]: {
    padding: theme.spacing(3, 4),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  color: theme.palette.primary[900],
}));

const MessageIcon = styled(Edit2Icon)(() => ({
  width: 18,
  height: 18,
}));

const AlertBody = styled(Alert.Body)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  color: theme.palette.grey[900],
}));

const PublishCourse = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);

  const { canPublishCourse, publishCourse } = usePublishCourseVM();

  if (!canPublishCourse) return null;

  return (
    <Root>
      <MessageWrapper>
        <MessageIcon color="inherit" variant="filled" />
        <Typography color="inherit">
          {t('please_publish_the_course_to_add_activities_and_enroll_students_in_the_course', {
            ns: i18nNS.COURSES,
          })}
        </Typography>
      </MessageWrapper>
      <AlertButton
        startIcon={<RocketIcon />}
        aria-label={t('click_to_publish_course', { ns: i18nNS.COURSES })}
      >
        {t('publish', { ns: i18nNS.GLOSSARY })}
        <Alert>
          <Alert.Header>{t('are_you_sure_you_want_to_publish', { ns: i18nNS.COURSES })}</Alert.Header>
          <AlertBody>
            <Typography variant="h6Bold" component="div">
              {t('after_this_course_is_published', { ns: i18nNS.COURSES })}
            </Typography>
            <Stack gap={1}>
              <div>
                <Trans t={t} ns={i18nNS.COURSES} i18nKey="you_wont_be_able_to">
                  You{' '}
                  <Typography variant="paragraphBold" component="span">
                    WON'T
                  </Typography>{' '}
                  be able to
                </Trans>
              </div>
              <Stack component="ul" gap={1} m={0} p={0}>
                <Stack component="li" direction="row" alignItems="center" gap={1}>
                  <CloseCircleIcon variant="filled" color="error" fontSize="small" />
                  <span>{t('delete_classes_without_notifying_the_students', { ns: i18nNS.COURSES })}</span>
                </Stack>
              </Stack>
            </Stack>
            <Stack gap={1}>
              <div>
                <Trans t={t} ns={i18nNS.COURSES} i18nKey="you_will_be_able_to">
                  You{' '}
                  <Typography variant="paragraphBold" component="span">
                    WILL
                  </Typography>{' '}
                  be able to
                </Trans>
              </div>
              <Stack component="ul" gap={1} m={0} p={0}>
                <Stack component="li" direction="row" alignItems="center" gap={1}>
                  <CheckmarkCircle2Icon variant="filled" color="success" fontSize="small" />
                  <span>{t('enroll_students', { ns: i18nNS.COURSES })}</span>
                </Stack>
                <Stack component="li" direction="row" alignItems="center" gap={1}>
                  <CheckmarkCircle2Icon variant="filled" color="success" fontSize="small" />
                  <span>{t('cancel_and_reschedule_your_classes', { ns: i18nNS.COURSES })}</span>
                </Stack>
                <Stack component="li" direction="row" alignItems="center" gap={1}>
                  <CheckmarkCircle2Icon variant="filled" color="success" fontSize="small" />
                  <span>{t('add_more_classes', { ns: i18nNS.COURSES })}</span>
                </Stack>
              </Stack>
            </Stack>
            <Typography component="div" variant="paragraphRegular">
              {t(
                'please_ensure_that_the_timeline_accurately_reflects_your_schedule_even_though_you_can_change_things_later_if_necessary',
                { ns: i18nNS.COURSES }
              )}
            </Typography>
          </AlertBody>
          <Alert.Footer withDivider>
            <Alert.Action variant="outlined" color="error">
              {t('no', { ns: i18nNS.GLOSSARY })}
            </Alert.Action>
            <Alert.Action onClick={publishCourse}>{t('yes_i_am_sure', { ns: i18nNS.COMMON })}</Alert.Action>
          </Alert.Footer>
        </Alert>
      </AlertButton>
    </Root>
  );
};

export default PublishCourse;
