import { useTranslation } from 'react-i18next';

import { Divider, Stack } from '@mui/material';

import Drawer from '../../../components/Drawer';
import Tooltip from '../../../components/Tooltip';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import EmailIcon from '../../../icons/EmailIcon';
import SmartphoneIcon from '../../../icons/SmartphoneIcon';
import { getNotificationLabel } from './helpers';
import NotificationType from './NotificationType';
import Skeleton from './Skeleton';
import { CancelButton, NotificationCell, SaveButton } from './styles';
import useManageNotificationsDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const ManageNotificationsDrawer = ({ open, onClose }: Props) => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.GLOSSARY, i18nNS.COMMON]);

  const { formMethods, fieldArrayMethods, onSubmit, isFetchingNotificationPrefs, handleCloseDrawer } =
    useManageNotificationsDrawerVM({ open, onClose });

  const { handleSubmit, control } = formMethods;
  const { fields } = fieldArrayMethods;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      header="Notifications"
      footer={
        <>
          <CancelButton
            variant="text"
            onClick={handleCloseDrawer}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </CancelButton>
          <SaveButton
            disabled={isFetchingNotificationPrefs}
            onClick={handleSubmit(onSubmit)}
            aria-label={t('click_to_save_notification_preferences', { ns: i18nNS.SETTINGS })}
          >
            {t('save', { ns: i18nNS.GLOSSARY })}
          </SaveButton>
        </>
      }
    >
      {isFetchingNotificationPrefs ? (
        <Skeleton />
      ) : (
        <Stack gap={5}>
          <Typography variant="h6Medium" color="grey.800">
            {t('set_notification_preferences', { ns: i18nNS.SETTINGS })}
          </Typography>
          <Stack divider={<Divider />}>
            {fields.map((notification, index) => {
              const label = getNotificationLabel(notification.notificationType);
              return (
                <NotificationCell key={notification.id}>
                  <Typography variant="paragraphRegular" color="grey.800">
                    {label}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Tooltip title={t('cloud_notifications', { ns: i18nNS.SETTINGS })}>
                      <NotificationType
                        control={control}
                        name={`notificationPrefs.${index}.cloud`}
                        icon={<SmartphoneIcon />}
                        notificationType={t('cloud', { ns: i18nNS.GLOSSARY })}
                        label={label}
                      />
                    </Tooltip>
                    <Tooltip title={t('email_notifications', { ns: i18nNS.SETTINGS })}>
                      <NotificationType
                        control={control}
                        name={`notificationPrefs.${index}.mail`}
                        icon={<EmailIcon />}
                        notificationType={t('email', { ns: i18nNS.GLOSSARY })}
                        label={label}
                      />
                    </Tooltip>
                  </Stack>
                </NotificationCell>
              );
            })}
          </Stack>
        </Stack>
      )}
    </Drawer>
  );
};

export default ManageNotificationsDrawer;
