import { useContext } from 'react';

import routes from '../../pages/routes';
import { AuthContext } from '../Context';
import { AuthScreen } from '../types';

const useThankYouVM = () => {
  const { setScreen } = useContext(AuthContext);

  const handleBack = () => {
    routes.login.navigate();
    setScreen(AuthScreen.DEFAULT);
  };

  return { handleBack };
};

export default useThankYouVM;
