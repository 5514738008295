import { Route, Switch } from 'react-router-dom';

import { Stack, styled } from '@mui/material';

import Button from '../../../../components/Button';
import ArrowheadLeftIcon from '../../../../icons/ArrowheadLeftIcon';
import routes from '../../../../pages/routes';
import MoreActions from './MoreActions';
import SubscribeButton from './SubscribeButton';
import useRightActionsVM from './vm';

/**
 * This button is used to balance content on both sides,
 * so title of discussion header appears to be centered
 */
const InvisibleButton = styled(Button)(({ theme }) => ({
  visibility: 'hidden',
}));

interface Props {
  classes?: Record<'notification', string>;
}

const RightActions = ({ classes }: Props) => {
  const { currentCourse } = useRightActionsVM();

  return (
    <Switch>
      <Route exact path={[routes.home.path, routes.archives.path, routes.settings.path]}>
        <InvisibleButton size="small" aria-hidden>
          <ArrowheadLeftIcon aria-hidden />
        </InvisibleButton>
      </Route>
      {currentCourse && !currentCourse.status.hasEnrollments && (
        <Route exact path={routes.course.path}>
          <InvisibleButton size="small" aria-hidden>
            <ArrowheadLeftIcon aria-hidden />
          </InvisibleButton>
        </Route>
      )}
      <Route>
        <Stack direction="row" alignItems="center">
          <SubscribeButton
            variant="text"
            size="small"
            edge="end"
            classes={{ notification: classes?.notification }}
          />
          <MoreActions variant="text" size="small" edge="end" />
        </Stack>
      </Route>
    </Switch>
  );
};

export default RightActions;
