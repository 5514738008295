import { ElementType } from 'react';

import { ButtonProps, IconButton, styled } from '@mui/material';

import clsx from 'clsx';

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'startIcon',
})(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

interface ToolBarButtonProps<C extends ElementType<any>> {
  component?: C;
  isActive?: boolean;
}

export type Props<C extends ElementType<any> = 'button'> = ButtonProps<C, ToolBarButtonProps<C>>;

const ToolBarButton = <C extends ElementType<any> = 'button'>({
  className,
  children,
  isActive,
  ...restProps
}: Props<C>) => {
  return (
    <StyledIconButton {...restProps} className={clsx({ active: isActive }, className)}>
      {children}
    </StyledIconButton>
  );
};

export default ToolBarButton;
