import { forwardRef, ReactNode, Ref } from 'react';

import { ListProps as MuiListProps } from '@mui/material';

import { Root } from '../styles';

export interface NonAccessibleListProps extends DistributiveOmit<MuiListProps, 'children'> {
  children: ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
}

const NonAccessibleList = forwardRef(
  ({ children, direction = 'column', ...props }: NonAccessibleListProps, ref: Ref<HTMLUListElement>) => {
    return (
      <Root ref={ref} role="listbox" tabIndex={-1} direction={direction} {...props}>
        {children}
      </Root>
    );
  }
);

export default NonAccessibleList;
