import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { Star } from '@mui/icons-material';
import { Button } from '@mui/material';

import Joi from 'joi';

import { TextFieldBaseHelperIcon } from '../../components/FormControls/TextFieldBase';
import Timepicker from '../../components/HookFormControls/Timepicker';
import { requiredString } from '../../utils/validators';

interface FormValues {
  meetingTime: string | null;
}

const schema = Joi.object<FormValues, true>({
  meetingTime: requiredString,
});

const FormTimepicker = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: {
      meetingTime: null,
    },
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Timepicker
        control={control}
        name="meetingTime"
        required
        label="Meeting Time"
        size="large"
        placeholder="Enter meeting time"
        helperText="Use this field to enter meeting time"
        fullWidth
        // disabled
        // error
        // helperIcon={<TextFieldBaseHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <Timepicker
        control={control}
        name="meetingTime"
        required
        label="Meeting Time"
        size="large"
        placeholder="Enter meeting time"
        helperText="Use this field to enter meeting time"
        fullWidth
        disabled
        // error
        helperIcon={<TextFieldBaseHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormTimepicker;
