import React from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

const SwitchCheckedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14ZM19.3333 9.80366C18.6077 9.29074 17.5889 9.44306 17.0578 10.1439L13.2431 15.1779L10.7809 12.7942C10.1458 12.1794 9.11486 12.1782 8.47826 12.7916C7.84165 13.4049 7.84043 14.4006 8.47552 15.0154L12.8134 19.215C13.2445 19.6323 13.9435 19.5787 14.3059 19.1005L19.6856 12.0014C20.2167 11.3005 20.059 10.3166 19.3333 9.80366Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SwitchCheckedIcon;
