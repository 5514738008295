import { KeyboardEventHandler, Ref, useEffect, useImperativeHandle, useMemo } from 'react';

import isHotkey from 'is-hotkey';
import {
  Descendant,
  Editor,
  Element as SlateElement,
  Node as SlateNode,
  Path,
  Range,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';

import { UploadFormulaFn } from '../plugins/formula/types';
import { UploadImageFn } from '../plugins/image/types';
import RichTextEditorUtils from '../utils';
import deserialize from '../utils/deserialize';
import editorFactory from '../utils/editor-factory';
import serialize from '../utils/serialize';

export const FormatKeys = Object.freeze({
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
} as const);

const getIsDeleteOrBackspace = isHotkey(['delete', 'backspace']);
const getIsLeftOrUpArrow = isHotkey(['arrowleft', 'arrowup']);
const getIsRightOrDownArrow = isHotkey(['arrowright', 'arrowdown']);
const getIsEnter = isHotkey('enter');

export interface VMProps {
  /**
   * This prop is used to set the initial value of the editor. DO NOT use
   * it to control the component externally like a regular form field, it
   * will/may affect the performance of whole application.
   */
  initialValue?: string;
  /**
   * Use this prop with caution, if you are performing some heavy operations
   * consider using `ref.current?.getValue()` and calling it on demand basis
   * instead of every change in editor's value
   */
  onChange?: (value: string) => any;
  onBlur?: () => any;
  onEnter?: KeyboardEventHandler;
  uploadFormula: UploadFormulaFn;
  uploadImage: UploadImageFn;
}

export default function useEditorVM(
  { initialValue = '', onChange, onBlur: _onBlur, onEnter, uploadFormula, uploadImage }: VMProps,
  ref: Ref<Editor>
) {
  const value = useMemo(() => deserialize(initialValue), [initialValue]);

  const editor = useMemo(() => editorFactory({ uploadFormula, uploadImage }), [uploadFormula, uploadImage]);

  useImperativeHandle(ref, () => editor);

  const handleChange = (children: Descendant[]) => {
    if (!onChange) return;
    const isAstChange = editor.operations.some((op) => 'set_selection' !== op.type);
    if (isAstChange) onChange(serialize(children));
  };

  const onBlur = () => {
    // saved cursor position or selection, useful for image or formula insertion
    editor.saveSelection();
    _onBlur?.();
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    // handle text formatting commands like toggle bold, italic and underline
    for (const [hotkey, mark] of Object.entries(FormatKeys)) {
      if (isHotkey(hotkey)(event)) {
        event.preventDefault();
        RichTextEditorUtils.toggleMark(editor, mark);
        return;
      }
    }

    // hook for controlling editor when enter key is pressed
    if (onEnter && getIsEnter(event)) {
      onEnter(event);
      return;
    }

    if (!editor.selection || !Range.isCollapsed(editor.selection)) return;

    let [node, path] = Editor.node(editor, editor.selection);

    if (!SlateElement.isElement(node)) {
      node = SlateNode.parent(editor, path);
      path = ReactEditor.findPath(editor, node);
    }

    if (!SlateElement.isElement(node) || !editor.isVoid(node)) return;

    // void only elements logic further

    /**
     * if current selected node is a void element then delete it on press
     * of delete or backspace key
     */
    if (getIsDeleteOrBackspace(event)) {
      event.preventDefault();
      Transforms.removeNodes(editor, { at: path });
      return;
    }

    /**
     * if a void element is the first element and user presses left or up arrow key
     * then insert an empty paragraph
     */
    if (getIsLeftOrUpArrow(event) && !Path.hasPrevious(path)) {
      event.preventDefault();
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{ type: 'text', text: '' }],
        },
        { at: [0], select: true }
      );
      return;
    }

    /**
     * if a void element is the last element and user presses right or down arrow key
     * then insert an empty paragraph
     */
    if (getIsRightOrDownArrow(event) && path[0] === editor.children.length - 1) {
      event.preventDefault();
      Transforms.insertNodes(
        editor,
        { type: 'paragraph', children: [{ type: 'text', text: '' }] },
        { at: [editor.children.length], select: true }
      );
      return;
    }
  };

  useEffect(
    function reset() {
      /**
       * NOTE: it can be missused to control the editor from parent component,
       * may be we can use a counter to throw warning if this effect is triggered
       * more than 50 times (or so) in 500ms
       */
      editor.children = value;

      // move selection to last position
      Transforms.select(editor, Editor.end(editor, []));

      editor.onChange();
    },
    [editor, value]
  );

  return { editor, onBlur, onChange: handleChange, onKeyDown, value };
}
