import { Action } from '@reduxjs/toolkit';

import { ActionPattern, delay, fork, race, select, Tail, take } from 'redux-saga/effects';

/**
 * Debounces the execution of worker by a delay time and accumulates
 * all the actions during the delay time window. When action dispatch
 * is stopped for specified delay time, worker is executed and all the
 * accumulated actions are passed to it in same order of dispatch in time
 * @param ms delay in milli-seconds
 * @param pattern redux action type pattern
 * @param worker worker to execute when debounced
 * @returns reference of forked worker
 */
export function debounce<A extends string, T extends Action<A>>(
  ms: number,
  pattern: ActionPattern<T>,
  worker: (...actions: T[]) => any
) {
  return fork(function* () {
    while (true) {
      let pendingActions: T[] = [];
      let action: T = yield take(pattern);

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        });

        if (debounced) {
          /**
           * delay threshold hit, trigger worker with
           * all accumulated actions and current action
           */
          yield fork(worker, ...pendingActions, action);
          pendingActions = []; // clear pending actions queue
          break;
        }

        // accumulate previous actions
        pendingActions.push(action);
        action = latestAction;
      }
    }
  });
}

/**
 * Blocks the execution of saga until the expected value is not matched
 * to the returned value of state selector
 * @param selector state selector function
 * @param expectedValue expected value of the state
 * @param args optional extra arguments to selector
 */
export function* waitForStateToHaveValue<V, Fn extends (state: any, ...args: any[]) => any>(
  selector: Fn,
  expectedValue: V,
  ...args: Tail<Parameters<Fn>>
) {
  let stateSlice: V = yield select(selector, ...args);
  while (stateSlice !== expectedValue) {
    yield take();
    stateSlice = yield select(selector, ...args);
  }
}
