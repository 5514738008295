import { Trans, useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import TextField from '../../components/HookFormControls/TextField';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import ResendVerificationCode from './ResendVerificationCode';
import { BackButton, ResetPasswordButton } from './styles';
import useEmailVerificationVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const EmailVerification = ({ ...vmOptions }: Props) => {
  const isMobile = useMediaQuery(forMobile);

  const { t } = useTranslation([i18nNS.AUTH, i18nNS.COMMON]);

  const { isResettingPassword } = vmOptions;

  const { formMethods, onSubmit, handleBack, session, handleResendVerificationCode } =
    useEmailVerificationVM(vmOptions);

  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Layout
      title={t(isResettingPassword ? 'resetting_password' : 'email_verification', {
        ns: i18nNS.AUTH,
      })}
      subtitle={
        <Typography variant="paragraphRegular" color="grey.500">
          <Trans
            t={t}
            i18nKey="please_enter_the_verification_code_emailed_to_you_at_email"
            tOptions={{ ns: i18nNS.ASSIGNMENT, email: session.email }}
          >
            Please enter the verification code emailed to you at{' '}
            <Typography variant="paragraphBold" color="grey.700" component="span">
              {session.email}
            </Typography>
          </Trans>
        </Typography>
      }
      canSeeUserCell
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3} alignItems="flex-start">
          <TextField
            control={control}
            name="verificationCode"
            label={t('verification_code', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('enter_verification_code', { ns: i18nNS.AUTH })}
            autoCapitalize="none"
            autoCorrect="off"
            fullWidth
            autoFocus
          />
          <ResendVerificationCode onResendVerificationCode={handleResendVerificationCode} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            onClick={handleBack}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <ResetPasswordButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_verify_code', { ns: i18nNS.AUTH })}
          >
            {t(isResettingPassword ? 'reset_password' : 'set_password', { ns: i18nNS.AUTH })}
          </ResetPasswordButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default EmailVerification;
