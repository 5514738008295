import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { ActivityUpdatedEvent } from '../courses/pusher-events';
import { API } from '../shared/api-responses';
import { QuizPublishedPayload, QuizSubmittedEvent } from './pusher-events';
import {
  AddQuizQuestionPayload,
  AddQuizQuestionSuccessPayload,
  CreateQuizSuccessPayload,
  DeleteQuizQuestionPayload,
  DeleteQuizSuccessPayload,
  EditPublishedQuizPayload,
  EditPublishedQuizSuccessPayload,
  EditQuizQuestionPayload,
  EditQuizSuccessPayload,
  FetchAllQuizzesSuccessPayload,
  FetchQuizDetailsSuccessPayload,
  FetchQuizQuestionStatsSuccess,
  FetchQuizSubmissionsSuccessPayload,
  GetIndividualQuizSubmissionSuccessPayload,
  PublishQuizPayload,
  PublishQuizSuccessPayload,
  QuizId,
  QuizStoppedPayload,
  SaveQuizPublishPrefsPayload,
  SaveQuizSubmissionPayload,
  SaveQuizSubmissionSuccessPayload,
  StopQuizPaylod,
  StopQuizSuccessPayload,
  SubmitQuizPayload,
  SubmitQuizSuccessPayload,
} from './types';

export const openQuiz = createAction<QuizId>('quizzes/open');
export const closeQuiz = createAction<QuizId>('quizzes/close');

export const fetchAllQuizzes = createRequestAction<void, FetchAllQuizzesSuccessPayload>('quizzes/fetch/all');

export const createQuiz = createRequestAction<API.CreateQuizRequest, CreateQuizSuccessPayload>(
  'quizzes/create'
);

export const editQuiz = createRequestAction<API.EditQuizRequest, EditQuizSuccessPayload>('quizzes/edit');

export const editPublishedQuiz = createRequestAction<
  EditPublishedQuizPayload,
  EditPublishedQuizSuccessPayload
>('quizzes/published/edit');

export const deleteQuiz = createRequestAction<API.DeleteQuizRequest, DeleteQuizSuccessPayload>(
  'quizzes/delete'
);

export const fetchQuizDetails = createRequestAction<QuizId, FetchQuizDetailsSuccessPayload>(
  'quizzes/details/fetch'
);

export const quizEdited = createAction<ActivityUpdatedEvent>('quizzes/edited');

export const saveQuizPublishPrefs = createRequestAction<
  SaveQuizPublishPrefsPayload,
  SaveQuizPublishPrefsPayload
>('quizzes/publish-prefs/save');

export const publishQuiz = createRequestAction<PublishQuizPayload, PublishQuizSuccessPayload>(
  'quizzes/publish'
);

export const quizPublished = createAction<QuizPublishedPayload>('quizzes/published');

export const saveQuizQuestionResponse = createRequestAction<
  API.SaveQuizQuestionResponseRequest,
  API.SaveQuizQuestionResponseRequest
>('quizzes/question-response/save');

export const saveQuizSubmission = createRequestAction<
  SaveQuizSubmissionPayload,
  SaveQuizSubmissionSuccessPayload
>('quizzes/submission/save');

export const submitQuiz = createRequestAction<SubmitQuizPayload, SubmitQuizSuccessPayload>('quizzes/submit');

export const quizSubmitted = createAction<QuizSubmittedEvent>('quizzes/submitted');

export const stopQuiz = createRequestAction<StopQuizPaylod, StopQuizSuccessPayload>('quizzes/stop');

export const quizStopped = createAction<QuizStoppedPayload>('quizzes/stopped');

export const addQuizQuestion = createRequestAction<AddQuizQuestionPayload, AddQuizQuestionSuccessPayload>(
  'quizzes/questions/add'
);

export const editQuizQuestion = createRequestAction<EditQuizQuestionPayload, EditQuizQuestionPayload>(
  'quizzes/questions/edit'
);

export const reorderQuizQuestions = createRequestAction<
  API.ReorderQuizQuestionsRequest,
  API.ReorderQuizQuestionsRequest
>('quizzes/questions/reorder');

export const deleteQuizQuestion = createRequestAction<DeleteQuizQuestionPayload, DeleteQuizQuestionPayload>(
  'quizzes/questions/delete'
);

export const fetchQuizQuestionStats = createRequestAction<
  API.GetQuizQuestionStatsRequest,
  FetchQuizQuestionStatsSuccess
>('quizzes/question-stats/fetch');

export const fetchQuizSubmissions = createRequestAction<QuizId, FetchQuizSubmissionsSuccessPayload>(
  'quizzes/submissions/fetch'
);

export const fetchIndividualQuizSubmission = createRequestAction<
  API.GetIndividualQuizSubmissionRequest,
  GetIndividualQuizSubmissionSuccessPayload
>('quizzes/individual-submission/fetch');
