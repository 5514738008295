import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useCurrentUserWithRole } from '../../../courses/hooks';
import { useMounted } from '../../../utils/hooks';
import { joinName } from '../../../utils/name';
import { useRequestDispatch } from '../../../utils/request-actions';
import { changeName, fetchChangeNameStatus } from '../../store/actions';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface VMProps {
  open: boolean;
  onClose: () => void;
}

const useChangeNameDrawerVM = ({ open, onClose }: VMProps) => {
  const requestDispatch = useRequestDispatch();
  const getIsMounted = useMounted();

  const currentUser = useCurrentUserWithRole();

  const [isFetchingRemainingNameChangeCount, setIsFetchingRemainingNameChangeCount] = useState(false);
  const [remainingNameChangeCount, setRemainingNameChangeCount] = useState(-1);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setValue, reset } = formMethods;
  const handleCloseDrawer = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    const newName = joinName(data.firstName, data.middleName, data.lastName);
    await requestDispatch(changeName, {
      newName,
    });
    handleCloseDrawer();
  };

  useEffect(
    function initDrawer() {
      if (!open) return;
      setIsFetchingRemainingNameChangeCount(true);
      requestDispatch(fetchChangeNameStatus)
        .then(({ timesRemaining }) => {
          setRemainingNameChangeCount(timesRemaining);
        })
        .catch(() => {
          handleCloseDrawer();
        })
        .finally(() => {
          const isMounted = getIsMounted();
          if (isMounted) {
            setIsFetchingRemainingNameChangeCount(false);
          }
        });
    },
    [getIsMounted, handleCloseDrawer, open, requestDispatch]
  );

  useEffect(
    function setInitialValues() {
      if (!open) return;
      setValue('firstName', '');
      setValue('middleName', '');
      setValue('lastName', '');
    },
    [open, setValue]
  );

  return {
    handleCloseDrawer,
    currentUser,
    formMethods,
    onSubmit,
    isFetchingRemainingNameChangeCount,
    remainingNameChangeCount,
  };
};

export default useChangeNameDrawerVM;
