import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Link, Stack, Typography } from '@mui/material';

import urls from '../../../app/urls';
import AttachmentView from '../../../components/AttachmentView';
import Switch from '../../../components/HookFormControls/Switch';
import { BlueprintCoursePreview } from '../../../db/courses/types';
import { i18nNS } from '../../../i18n';
import ExternalLinkIcon from '../../../icons/ExternalLinkIcon';
import { FormValues } from './vm';

interface Props {
  control: Control<FormValues, object>;
  readingList?: BlueprintCoursePreview['readingList'];
  isSubmitting: boolean;
}

const CopyReadingList = ({ control, readingList, isSubmitting }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.GLOSSARY, i18nNS.COURSES]);

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6Bold" component="label">
          {t('copy_reading_list', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Switch
          name="readingList"
          control={control}
          edge="end"
          inputProps={{
            'aria-label': t('click_to_select_copy_reading_list', { ns: i18nNS.CREATE_COURSE }),
            role: 'switch',
          }}
          disabled={
            !(
              readingList &&
              (readingList.books.length > 0 || readingList.links.length > 0 || readingList.files.length > 0)
            ) || isSubmitting
          }
        />
      </Stack>

      <Stack gap={3}>
        <Typography variant="paragraphBold" component="div">
          {t('course_textbooks', { ns: i18nNS.COURSES })}
        </Typography>
        <Stack gap={2}>
          {readingList && readingList.books.length > 0 ? (
            readingList.books.map((book) => (
              <Stack key={book.id} pl={5}>
                <Typography variant="paragraphMedium" component="div">
                  {book.title}
                </Typography>
                <Stack direction="row" gap={1}>
                  <Typography component="span">{book.author}</Typography>
                  <Typography component="span">|</Typography>
                  <Typography component="span" color={book.isRecommended ? 'success' : undefined}>
                    {book.isRecommended
                      ? t('required', { ns: i18nNS.GLOSSARY })
                      : t('suggested', { ns: i18nNS.GLOSSARY })}
                  </Typography>
                </Stack>
              </Stack>
            ))
          ) : (
            <Typography>{t('this_course_has_no_books_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
          )}
        </Stack>
      </Stack>

      <Stack gap={3}>
        <Typography variant="paragraphBold" component="div">
          {t('links', { ns: i18nNS.GLOSSARY })}
        </Typography>
        <Stack gap={2}>
          {readingList && readingList.links.length > 0 ? (
            readingList.links.map((link) => (
              <Stack key={link.id} pl={5}>
                <Typography variant="paragraphMedium" component="div">
                  {link.title}
                </Typography>
                <Stack direction="row" gap={1}>
                  <Link key={link.id} href={link.url} target="_blank" rel="noreferrer">
                    {link.url}
                  </Link>
                  <ExternalLinkIcon color="primary" />
                </Stack>
              </Stack>
            ))
          ) : (
            <Typography>{t('this_course_has_no_links_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
          )}
        </Stack>
      </Stack>

      <Stack gap={3}>
        <Typography variant="paragraphBold" component="div">
          {t('files', { ns: i18nNS.GLOSSARY })}
        </Typography>
        <Stack gap={2}>
          {readingList && readingList.files.length > 0 ? (
            readingList.files.map((file) => (
              <AttachmentView
                key={file.id}
                attachment={file}
                requestConfig={{
                  data: { fileName: file.name },
                  method: 'GET',
                  url: urls.downloadBlueprintCoursePreviewInfoFile,
                }}
                color="success"
              />
            ))
          ) : (
            <Typography>{t('this_course_has_no_files_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CopyReadingList;
