import { useTranslation } from 'react-i18next';

import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText,
  Stack,
  styled,
  Typography,
} from '@mui/material';

import Dialog from '../../../../components/Dialog';
import { i18nNS } from '../../../../i18n';
import CheckmarkCircleIcon from '../../../../icons/CheckmarkCircleIcon';
import CloseIcon from '../../../../icons/CloseIcon';

const List = styled(MuiList)(({ theme }) => ({
  gap: 0,
  paddingLeft: theme.spacing(2),
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0, 2, 0, 6),

  '&::before': {
    content: '" "',
    position: 'absolute',
    top: 13,
    left: theme.spacing(2),
    display: 'inline-block',
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

interface Props {
  onClose: () => void;
  open: boolean;
}

const RoleDetails = ({ onClose, open }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.COURSES, i18nNS.GLOSSARY]);

  const can = [
    t('access_unpublished_activities', { ns: i18nNS.CREATE_COURSE }),
    t('grade_assignments', { ns: i18nNS.CREATE_COURSE }),
    t('be_made_class_assistant_for_class_meetings', { ns: i18nNS.CREATE_COURSE }),
    t('take_attendance_if_they_are_part_of_the_team_for_a_class_meeting', {
      ns: i18nNS.CREATE_COURSE,
    }),
    t('reward_offline_class_participation_points_if_they_are_part_of_the_team_for_a_class_meeting', {
      ns: i18nNS.CREATE_COURSE,
    }),
  ];

  const canNot = [
    t('be_made_a_class_in_charge_or_class_assistant_for_class_meetings', {
      ns: i18nNS.CREATE_COURSE,
    }),
    t('create_activities_announcement_assignments', { ns: i18nNS.CREATE_COURSE }),
    t('add_or_remove_students', { ns: i18nNS.CREATE_COURSE }),
    t('invite_course_collaborators', { ns: i18nNS.CREATE_COURSE }),
    t('edit_the_course_schedule_or_syllabus_topics', { ns: i18nNS.CREATE_COURSE }),
    t('select_a_class_in_charge', { ns: i18nNS.CREATE_COURSE }),
  ];

  return (
    <Dialog open={open} onClose={onClose} header={t('course_ta_role_details', { ns: i18nNS.COURSES })}>
      <Stack gap={4}>
        <div>
          <Stack direction="row" alignItems="center" gap={2} component={Typography} variant="h6Bold">
            <CheckmarkCircleIcon color="primary" />
            {t('a_course_ta_can', { ns: i18nNS.COURSES })}
          </Stack>
          <List>
            {can.map((item) => (
              <ListItem key={item}>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
        <div>
          <Stack direction="row" alignItems="center" gap={2} component={Typography} variant="h6Bold">
            <CloseIcon color="error" />
            {t('a_course_ta_cannot', { ns: i18nNS.COURSES })}
          </Stack>
          <List>
            {canNot.map((item) => (
              <ListItem key={item}>
                <ListItemText>{item}</ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </Stack>
    </Dialog>
  );
};

export default RoleDetails;
