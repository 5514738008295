import { useTranslation } from 'react-i18next';

import { MenuItem, Stack, Typography } from '@mui/material';

import Button from '../../../components/Button';
import DatePicker from '../../../components/HookFormControls/Datepicker';
import Select from '../../../components/HookFormControls/Select';
import TextField from '../../../components/HookFormControls/TextField';
import { i18nNS } from '../../../i18n';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import { dateToUnix, unix, unixToDate } from '../../../utils/datetime';
import { COURSE_COUNT_OPTIONS, STUDENT_COUNT_OPTIONS } from '../../constants';
import CreateCourseForm from '../CreateCourseForm';
import usePricingInquiryVM from './vm';

const PricingInquiry = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE]);
  const { closeDrawer, control, isSubmitting, isVisible, moveToPreviousStep, sendPricingInquiry } =
    usePricingInquiryVM();
  return (
    <CreateCourseForm
      isVisible={isVisible}
      onSubmit={sendPricingInquiry}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button type="button" variant="text" startIcon={<ChevronLeftIcon />} onClick={moveToPreviousStep}>
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button type="button" variant="text" onClick={closeDrawer}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button type="submit" variant="contained" isLoading={isSubmitting} endIcon={<ChevronRightIcon />}>
              {t('send', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={4}>
        <Typography variant="h5Bold">{t('send_pricing_inquiry', { ns: i18nNS.CREATE_COURSE })}</Typography>
        <Select
          control={control}
          name="courseCount"
          label={t('how_many_courses_are_you_teaching_in_spring_2023', { ns: i18nNS.CREATE_COURSE })}
          autoFocus
          disabled
        >
          {COURSE_COUNT_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        <Select
          control={control}
          name="studentCount"
          label={t('how_many_students_will_be_enrolled_in_your_courses_in_spring_2023_total', {
            ns: i18nNS.CREATE_COURSE,
          })}
          disabled
        >
          {STUDENT_COUNT_OPTIONS.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
        <DatePicker
          control={control}
          minDate={unix()}
          parser={unixToDate}
          formatter={dateToUnix}
          inputFormat="MMM dd, yyyy"
          name="courseStartDate"
          label={t('when_do_you_teach_your_first_class', { ns: i18nNS.CREATE_COURSE })}
        />
        {/* FIXME: use phone input with country flags */}
        <TextField
          control={control}
          name="phone"
          label={t('what_is_your_phone_number', { ns: i18nNS.CREATE_COURSE })}
        />
      </Stack>
    </CreateCourseForm>
  );
};

export default PricingInquiry;
