import { SvgIcon, SvgIconProps } from '@mui/material';

const UnderlineIcon = (props: SvgIconProps) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4L8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11L16 4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4L18 11C18 14.3137 15.3137 17 12 17C8.68629 17 6 14.3137 6 11V4Z" />
    <path d="M6 20C6 19.4477 6.44772 19 7 19H17C17.5523 19 18 19.4477 18 20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20Z" />
  </SvgIcon>
);

export default UnderlineIcon;
