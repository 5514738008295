import { FieldValues, Path, useFormContext } from 'react-hook-form';

export interface Props<T extends FieldValues> {
  timezoneControlName: Path<T>;
  startDateControlName: Path<T>;
  endDateControlName: Path<T>;
}

const useCourseDatesVM = <T extends FieldValues>({ timezoneControlName, startDateControlName }: Props<T>) => {
  const { watch } = useFormContext();

  const timezone = watch(timezoneControlName);
  const startDate = watch(startDateControlName);

  return { startDate, timezone };
};

export default useCourseDatesVM;
