import { useEffect } from 'react';

import i18n, { i18nNS } from '../../../i18n';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

export default function useIntroductionVM() {
  const {
    activeStep,
    closeDrawer,
    moveToNextStep: _moveToNextStep,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.INTRODUCTION;

  const moveToNextStep = () => _moveToNextStep();

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('adding_a_new_acadly_course', { ns: i18nNS.CREATE_COURSE }));
    setMobileTitle(i18n.t('new_course', { ns: i18nNS.CREATE_COURSE }));
  }, [isVisible, setMobileTitle, setTitle]);

  return { isVisible, closeDrawer, moveToNextStep };
}
