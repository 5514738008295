import { SvgIcon, SvgIconProps } from '@mui/material';

const FormulaIcon = (props: SvgIconProps) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M7.52659 6.18631C7.70669 3.48737 10.8083 2.06329 12.9734 3.68677L13.2268 3.87716C13.3281 3.95318 13.4135 4.04843 13.4781 4.15745C13.5427 4.26647 13.5851 4.38715 13.603 4.51258C13.6209 4.638 13.614 4.76574 13.5825 4.88847C13.5511 5.01121 13.4958 5.12656 13.4197 5.22792C13.3437 5.32928 13.2485 5.41468 13.1395 5.47923C13.0304 5.54378 12.9098 5.58623 12.7843 5.60415C12.6589 5.62207 12.5312 5.61511 12.4084 5.58366C12.2857 5.55222 12.1704 5.4969 12.069 5.42088L11.8156 5.23049C11.6025 5.07049 11.3502 4.97101 11.0853 4.94254C10.8203 4.91408 10.5527 4.95769 10.3105 5.06877C10.0683 5.17985 9.86058 5.35427 9.70932 5.57362C9.55806 5.79297 9.46884 6.0491 9.4511 6.31496L9.37005 7.5435H11.0398C11.2957 7.5435 11.5411 7.64515 11.7221 7.82609C11.903 8.00703 12.0047 8.25244 12.0047 8.50833C12.0047 8.76421 11.903 9.00962 11.7221 9.19056C11.5411 9.3715 11.2957 9.47315 11.0398 9.47315H9.24141L8.6921 17.6973C8.65565 18.2448 8.49236 18.7763 8.21513 19.2498C7.93791 19.7233 7.55435 20.1258 7.09478 20.4255C6.6352 20.7252 6.11221 20.9139 5.56714 20.9767C5.02207 21.0395 4.46986 20.9747 3.95416 20.7873L3.63513 20.6716C3.51603 20.6282 3.40663 20.5619 3.31318 20.4763C3.21972 20.3906 3.14405 20.2875 3.09047 20.1726C3.0369 20.0577 3.00647 19.9335 3.00092 19.8068C2.99538 19.6802 3.01483 19.5538 3.05816 19.4347C3.10149 19.3156 3.16786 19.2062 3.25347 19.1127C3.33908 19.0193 3.44226 18.9436 3.55712 18.89C3.67198 18.8364 3.79626 18.806 3.92288 18.8005C4.0495 18.7949 4.17597 18.8144 4.29507 18.8577L4.61282 18.9735C4.84724 19.0586 5.09825 19.0881 5.34601 19.0595C5.59377 19.0309 5.83149 18.9451 6.04038 18.8089C6.24926 18.6726 6.42359 18.4896 6.54958 18.2744C6.67557 18.0591 6.74977 17.8175 6.76631 17.5687L7.30661 9.47315H6.53732C6.28144 9.47315 6.03603 9.3715 5.85509 9.19056C5.67415 9.00962 5.5725 8.76421 5.5725 8.50833C5.5725 8.25244 5.67415 8.00703 5.85509 7.82609C6.03603 7.64515 6.28144 7.5435 6.53732 7.5435H7.43654L7.52659 6.18631Z" />
    <path d="M13.5038 11C13.7495 11.1567 13.9565 11.367 14.1093 11.6151L15.4009 13.7145L18.0767 11.0426C18.2596 10.8722 18.5015 10.7794 18.7514 10.7838C19.0014 10.7882 19.2399 10.8895 19.4166 11.0662C19.5934 11.243 19.6947 11.4815 19.6991 11.7315C19.7035 11.9814 19.6107 12.2233 19.4403 12.4062L16.4416 15.4062L17.975 17.898L18.3983 17.4761C18.5812 17.3056 18.8231 17.2129 19.073 17.2173C19.323 17.2217 19.5615 17.3229 19.7382 17.4997C19.915 17.6765 20.0163 17.915 20.0207 18.1649C20.0251 18.4149 19.9323 18.6568 19.7619 18.8397L19.3399 19.2616C19.1339 19.4677 18.8839 19.6244 18.6087 19.72C18.3334 19.8157 18.0401 19.8477 17.7507 19.8138C17.4613 19.7799 17.1834 19.6809 16.9377 19.5242C16.692 19.3675 16.485 19.1573 16.3323 18.9091L15.0394 16.8084L12.3649 19.4816C12.182 19.652 11.9401 19.7448 11.6901 19.7404C11.4402 19.736 11.2017 19.6347 11.0249 19.458C10.8481 19.2812 10.7469 19.0427 10.7425 18.7928C10.7381 18.5428 10.8308 18.3009 11.0013 18.118L14 15.118L12.4665 12.6262L12.0433 13.0482C11.8604 13.2186 11.6185 13.3114 11.3685 13.307C11.1186 13.3025 10.8801 13.2013 10.7033 13.0245C10.5265 12.8477 10.4253 12.6093 10.4209 12.3593C10.4165 12.1093 10.5092 11.8674 10.6797 11.6845L11.1016 11.2626C11.3076 11.0565 11.5576 10.8998 11.8329 10.8042C12.1081 10.7085 12.4014 10.6765 12.6908 10.7104C12.9802 10.7443 13.2582 10.8433 13.5038 11Z" />
  </SvgIcon>
);

export default FormulaIcon;
