import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const BellOffIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15.88 18.71L15.29 18.12L14 16.78L13.93 16.71L6.58 9.39999L5.31 8.13999C5.29978 8.33653 5.29978 8.53346 5.31 8.72999V13.4L3.51 15.21C3.28555 15.438 3.13271 15.7267 3.07029 16.0405C3.00787 16.3543 3.03861 16.6795 3.15872 16.9761C3.27883 17.2727 3.48307 17.5276 3.74627 17.7095C4.00947 17.8915 4.32014 17.9924 4.64 18H8V18.34C8.04671 19.3552 8.49395 20.3105 9.24373 20.9965C9.9935 21.6826 10.9847 22.0434 12 22C12.9446 22.0428 13.8723 21.7393 14.6089 21.1464C15.3454 20.5534 15.8401 19.7119 16 18.78L15.88 18.71ZM14 18.34C13.9445 18.821 13.7056 19.262 13.3331 19.5713C12.9605 19.8805 12.483 20.0341 12 20C11.517 20.0341 11.0395 19.8805 10.6669 19.5713C10.2944 19.262 10.0555 18.821 10 18.34V18H14V18.34Z"
          fill="currentColor"
        />
        <path
          d="M7.13 4.29999L20.12 17.29L20.43 17.59C20.626 17.429 20.7803 17.2232 20.88 16.99C21.0059 16.6922 21.0399 16.3636 20.9775 16.0464C20.9151 15.7292 20.7593 15.4379 20.53 15.21L18.73 13.4V8.93999C18.7536 7.28234 18.1761 5.67221 17.1044 4.40742C16.0326 3.14263 14.5391 2.30874 12.9 2.05999C11.9497 1.93269 10.9831 2.01051 10.0655 2.28824C9.14777 2.56596 8.30025 3.03712 7.58 3.66999C7.37645 3.8387 7.1828 4.019 7 4.20999L7.13 4.29999Z"
          fill="currentColor"
        />
        <path
          d="M20.71 19.29L19.41 18L4.71 3.28999C4.61676 3.19675 4.50607 3.12279 4.38425 3.07233C4.26243 3.02187 4.13186 2.9959 4 2.9959C3.86814 2.9959 3.73758 3.02187 3.61575 3.07233C3.49393 3.12279 3.38324 3.19675 3.29 3.28999C3.1017 3.4783 2.99591 3.73369 2.99591 3.99999C2.99591 4.26629 3.1017 4.52169 3.29 4.70999L5.53 6.99999L7.28 8.69999L16.59 18L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.90001 5.16999C9.40142 4.71567 9.99558 4.37574 10.6413 4.17372C11.2871 3.97171 11.9691 3.91243 12.64 3.99999C13.7965 4.18777 14.8462 4.787 15.5959 5.68737C16.3456 6.58774 16.7448 7.72863 16.72 8.89999V13.4C16.7223 13.6007 16.756 13.7998 16.82 13.99L20.42 17.59C20.616 17.429 20.7703 17.2232 20.87 16.99C20.9959 16.6922 21.0299 16.3636 20.9675 16.0464C20.9052 15.7292 20.7493 15.4379 20.52 15.21L18.72 13.4V8.93999C18.744 7.28382 18.1679 5.6749 17.0981 4.41033C16.0284 3.14576 14.5372 2.31093 12.9 2.05999C11.9497 1.93269 10.9832 2.01051 10.0655 2.28824C9.14777 2.56596 8.30026 3.03712 7.58001 3.66999C7.37646 3.8387 7.1828 4.019 7.00001 4.20999L8.47001 5.63999C8.60275 5.47396 8.7464 5.31694 8.90001 5.16999Z"
        fill="currentColor"
      />
      <path
        d="M14 16.86L13.17 16H5.51001L6.69001 14.82C6.87717 14.6339 7.02567 14.4127 7.12693 14.169C7.22819 13.9252 7.28022 13.6639 7.28001 13.4V10.11L5.28001 8.10999C5.26979 8.30653 5.26979 8.50346 5.28001 8.69999V13.4L3.48001 15.21C3.24902 15.438 3.09135 15.7298 3.02721 16.0479C2.96306 16.3661 2.99536 16.6961 3.11996 16.9958C3.24457 17.2955 3.4558 17.5512 3.7266 17.7301C3.99741 17.909 4.31546 18.003 4.64001 18H8.00001V18.34C8.04672 19.3552 8.49396 20.3105 9.24373 20.9965C9.99351 21.6826 10.9847 22.0434 12 22C12.9446 22.0428 13.8723 21.7393 14.6089 21.1464C15.3454 20.5534 15.8401 19.7119 16 18.78L15.17 18L14 16.86ZM12 20C11.517 20.0341 11.0395 19.8805 10.6669 19.5713C10.2944 19.262 10.0555 18.821 10 18.34V18H14V18.34C13.9445 18.821 13.7057 19.262 13.3331 19.5713C12.9605 19.8805 12.483 20.0341 12 20Z"
        fill="currentColor"
      />
      <path
        d="M20.71 19.29L19.41 18L4.71001 3.28999C4.61677 3.19675 4.50608 3.12279 4.38426 3.07233C4.26243 3.02187 4.13187 2.9959 4.00001 2.9959C3.86815 2.9959 3.73758 3.02187 3.61576 3.07233C3.49393 3.12279 3.38324 3.19675 3.29001 3.28999C3.1017 3.4783 2.99591 3.73369 2.99591 3.99999C2.99591 4.26629 3.1017 4.52169 3.29001 4.70999L5.53001 6.99999L7.28001 8.69999L16.59 18L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default BellOffIcon;
