import { styled } from '@mui/material';

export const AcadlyHere = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(0.5, 3),
  border: `1px solid ${theme.palette.success[600]}`,
  borderRadius: 8,
  color: theme.palette.success[600],
  textDecoration: 'none',
  boxShadow: '0 1px 1px 1px rgb(6 143 104 / 50%)',
  '&:hover': {
    boxShadow: '0 1px 3px 1px rgb(6 143 104 / 80%)',
  },
}));

export const AcadlyHereLogo = styled('img')(({ theme }) => ({
  height: 48,
  width: 48,
}));
