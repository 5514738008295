import { styled } from '@mui/material';

import Layout, { layoutClasses, Props as LayoutProps } from '../Layout';

export const Root = styled((props: DistributiveOmit<LayoutProps, 'title'>) => <Layout {...props} title="" />)(
  ({ theme }) => ({
    [`& .${layoutClasses.paper}`]: {
      display: 'flex',
      gap: 0,
    },
    [`& .${layoutClasses.contentHeader}`]: {
      display: 'none',
    },
    [`& .${layoutClasses.content}`]: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(5),
    },
  })
);
