import { createAction } from '@reduxjs/toolkit';

import { AcadlyPage, PageStatus } from './types';

interface SetPageStatusPayload {
  page: AcadlyPage;
  status: PageStatus;
}

export const setPageStatus = createAction<SetPageStatusPayload>('pages/status');
