import { putResolve, select } from 'redux-saga/effects';

import { fetchCourseDetails } from '../courses/actions';
import { selectCurrentCourse } from '../courses/selectors';

export function* refreshTimelineWorker(courseId: MongoId) {
  const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
  if (course?.id !== courseId) return; // do nothing if current course is different from provided course's id
  yield putResolve(fetchCourseDetails.request({ courseId }));
}
