import { TipStatusKey } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';

export function getHomePageTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'courseHomeFloatingButton':
      return i18n.t(
        'to_begin_you_can_create_a_course_by_tapping_the_add_button_if_you_re_trying_acadly_for_the_first_time_it_is_advisable_that_you_create_a_demo_course_courses_can_be_deleted_any_time_through_settings',
        { ns: i18nNS.COURSES }
      );
    case 'courseHomeArchive':
      return i18n.t(
        'click_here_to_access_the_archived_courses_in_which_you_were_an_admin_or_an_instructor_you_can_copy_activities_from_archived_courses_to_your_current_courses_easily',
        { ns: i18nNS.COURSES }
      );
    case 'courseHomeSetting':
      return i18n.t('click_here_to_access_your_account_settings', { ns: i18nNS.COURSES });
    default:
      return '';
  }
}
