import { RootState } from '../../store/types';
import { getActivityById, getFullId } from '../shared/helpers';
import { Quiz, QuizQuestion } from './types';

export const selectFullQuizId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { quizzes } = state.db;
  return getFullId(quizzes, shortId);
};

export const selectQuiz = (id: ShortId | MongoId) => (state: RootState) => {
  const { quizzes } = state.db;
  return getActivityById(quizzes, id);
};

export const selectAllQuizzes = (state: RootState) => {
  const { quizzes } = state.db;
  const publishedQuizzes: Quiz[] = [];
  const unpublishedQuizzes: Quiz[] = [];

  if (!quizzes.byId)
    return {
      publishedQuizzes,
      unpublishedQuizzes,
    };

  for (const quiz of Object.values(quizzes.byId)) {
    if (!quiz) continue;
    if (quiz.publishedOn <= 0) {
      unpublishedQuizzes.push(quiz);
    } else {
      publishedQuizzes.push(quiz);
    }
  }

  return {
    publishedQuizzes,
    unpublishedQuizzes,
  };
};

export const selectSuggestedQuiz = (id: MongoId) => (state: RootState) => {
  const { suggestedById } = state.db.quizzes;
  return suggestedById[id];
};

export const selectSuggestedQuizQuestions = (suggestedQuizId: MongoId) => (state: RootState) => {
  const quiz = selectSuggestedQuiz(suggestedQuizId)(state);
  if (!quiz?.questionsById) return [];

  const questions: QuizQuestion[] = [];
  for (let questionId of quiz.questions) {
    const question = quiz.questionsById[questionId];
    if (!question) continue;
    questions.push(question);
  }

  return questions;
};

export const selectQuizQuestions = (quizId: ShortId | MongoId) => (state: RootState) => {
  const quiz = selectQuiz(quizId)(state);
  if (!quiz?.questionsById) return [];

  const questions: QuizQuestion[] = [];
  for (let questionId of quiz.questions) {
    const question = quiz.questionsById[questionId];
    if (!question) continue;
    questions.push(question);
  }

  return questions;
};
