import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentUserWithRole } from '../../../../../../../courses/hooks';
import { selectComment } from '../../../../../../../db/comments/selectors';
import { CommentsContext } from '../../../../../../Context';
import { getCommentAttachment } from '../../../../../../helpers';

export interface Props {
  commentId: MongoId;
}

const useCommentVM = ({ commentId }: Props) => {
  const comment = useSelector(selectComment(commentId));
  const currentUser = useCurrentUserWithRole();
  const attachment = getCommentAttachment(comment);

  const { setSelectedCommentId } = useContext(CommentsContext);

  const handleCommentClick = () => {
    setSelectedCommentId(commentId);
  };

  /** comment stats dialog */

  const [isCommentStatsDialogOpen, setIsCommentStatsDialogOpen] = useState(false);

  const handleCloseStatsDialog = () => {
    setIsCommentStatsDialogOpen(false);
  };

  const handleStatsClick = () => {
    setIsCommentStatsDialogOpen(true);
  };

  /** image preview dialog */

  const [selectedCommentImagePreviewUrl, setSelectedCommentImagePreviewUrl] = useState<string | null>(null);

  const isImagePreviewDialogOpen = Boolean(selectedCommentImagePreviewUrl);

  const handleCloseImagePreviewDialog = () => {
    setSelectedCommentImagePreviewUrl(null);
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedCommentImagePreviewUrl(imageUrl);
  };

  return {
    comment,
    currentUser,
    attachment,
    handleCommentClick,
    isCommentStatsDialogOpen,
    handleCloseStatsDialog,
    handleStatsClick,
    selectedCommentImagePreviewUrl,
    isImagePreviewDialogOpen,
    handleCloseImagePreviewDialog,
    handleImageClick,
  };
};

export default useCommentVM;
