const en = {
  format_and_display: 'Format and display',
  cancel: 'Cancel',
  done: 'Done',
  input: 'Input',
  input_format: 'Input format',
  output_display_style: 'Output display style',
  enter_formula_here: 'Enter formula here',
  as_you_type_the_expression_in_the_input_box_below_the_formatted_output_will_appear_here:
    'As you type the expression in the input box below, the formatted output will appear here.',
};

export default en;
