import { ReactNode } from 'react';

import {
  Checkbox,
  checkboxClasses as MuiCheckboxClasses,
  CheckboxProps,
  FormControlLabel,
  formControlLabelClasses as MuiFormControlLabelClasses,
  FormControlLabelProps,
  styled,
} from '@mui/material';

import { generateClasses } from '../../../utils/helpers';

export const checkboxClasses = {
  ...MuiFormControlLabelClasses,
  ...MuiCheckboxClasses,
  ...generateClasses('Checkbox', ['checkbox', 'icon', 'checkedIcon', 'indeterminateIcon']),
};

interface CustomColors {
  color: string;
  hasColorWhenDisabled?: boolean;
}

interface DefaultColors {
  color?: undefined;
}

export type Props = DistributiveOmit<CheckboxProps, 'color' | 'classes'> &
  (CustomColors | DefaultColors) & {
    label: ReactNode;
    color?: string;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
    classes?: Partial<typeof checkboxClasses>;
  };

const MuiCheckbox = ({ color, ...props }: Props) => {
  return <Checkbox {...props} />;
};

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`& .${checkboxClasses.label}`]: {
    color: theme.palette.grey[800],
    [`&.${checkboxClasses.disabled}`]: {
      color: theme.palette.grey[800],
    },
  },
  '&:focus-within:has(:focus-visible)': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const StyledCheckbox = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== 'hasColorWhenDisabled',
})(({ theme, ...props }) => ({
  fontSize: theme.typography.pxToRem(20),
  color: theme.palette.grey[100],
  stroke: theme.palette.grey[400],
  '&:hover': {
    color: theme.palette.primary[100],
    stroke: props.color || theme.palette.primary[600],
  },
  '&:focus': {
    color: theme.palette.grey[200],
    stroke: theme.palette.grey[500],
  },
  [`&.${checkboxClasses.disabled}`]: {
    color: theme.palette.grey[100],
    stroke: props.color && props.hasColorWhenDisabled ? props.color : theme.palette.grey[300],
  },
  [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
    color: props.color || theme.palette.primary[600],
    stroke: 'transparent',
    '&:hover': {
      color: theme.palette.primary[500],
    },
    '&:focus': {
      color: theme.palette.primary[700],
    },
    [`&.${checkboxClasses.disabled}`]: {
      color: props.color && props.hasColorWhenDisabled ? props.color : theme.palette.grey[300],
    },
  },
}));
