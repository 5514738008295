import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import StudentWithHandRaisedDialog from '../../StudentWithHandRaisedDialog';
import MeetingStatusText from '../MeetingStatusText';
import RecordingAttendanceFooterView from '../RecordingAttendanceFooterView';
import StudentBeingAddressedFooterView from '../StudentBeingAddressedFooterView';
import ZoomAppFooterLayout from '../ZoomAppFooterLayout';
import useBroadcastStartedFooterViewVM from './vm';

const BroadcastStartedFooterView = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.COURSES, i18nNS.GLOSSARY]);

  const {
    attendanceId,
    handleCloseStudentWithHandRaisedDialog,
    handleOpenStudentWithHandRaisedDialog,
    handleStartRecordingAttendance,
    handleStopBroadcasting,
    handleStopRecordingAttendance,
    numParticipantsWithHandRaised,
    isParticipantBeingAddressed,
    isRecordingAttendance,
    isStudentWithHandRaisedDialogOpen,
  } = useBroadcastStartedFooterViewVM();

  if (isParticipantBeingAddressed) return <StudentBeingAddressedFooterView />;

  if (isRecordingAttendance) {
    return (
      <RecordingAttendanceFooterView attendanceId={attendanceId} onStop={handleStopRecordingAttendance} />
    );
  }

  return (
    <>
      <ZoomAppFooterLayout
        left={
          <MeetingStatusText variant="paragraphRegular" highlighted>
            {t('broadcasting', { ns: i18nNS.ONLINE_MEETING })}
          </MeetingStatusText>
        }
        right={
          <>
            {numParticipantsWithHandRaised > 0 ? (
              <Button variant="outlined" size="small" onClick={handleOpenStudentWithHandRaisedDialog}>
                {t('N_raised_hands', {
                  ns: i18nNS.ONLINE_MEETING,
                  count: numParticipantsWithHandRaised,
                })}
              </Button>
            ) : null}
            <Button variant="outlined" size="small" onClick={handleStartRecordingAttendance}>
              {t('record_attendance', { ns: i18nNS.COURSES })}
            </Button>
            <Button variant="contained" size="small" color="error" onClick={handleStopBroadcasting}>
              {t('end', { ns: i18nNS.GLOSSARY })}
            </Button>
          </>
        }
      />
      <StudentWithHandRaisedDialog
        open={isStudentWithHandRaisedDialogOpen}
        onClose={handleCloseStudentWithHandRaisedDialog}
      />
    </>
  );
};

export default BroadcastStartedFooterView;
