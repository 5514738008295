import { createPusherEvent } from '../../pusher/helpers';
import {
  AcadlyScreen,
  ClassPusherEvent,
  CoursePusherEvent,
  PusherChannel,
  PusherEventName,
} from '../../pusher/types';
import {
  AttendanceFailureCode,
  AttendanceWarningAction,
  ClassIncharge,
  ClassTeamUser,
  CourseStudentUser,
  CourseTeamUser,
} from '../shared/types';

type ClassCreatedEvent = CoursePusherEvent<{
  courseCode: string;
  numClasses: number;
  classDates: {
    /**
     * @format `yyyy-MM-dd'T'HH:mm`
     * @example '2022-10-03T10:00'
     */
    scheStartTime: string;
    /**
     * @format `yyyy-MM-dd'T'HH:mm`
     * @example '2022-10-03T11:00'
     */
    scheEndTime: string;
  }[];
}>;

export const classCreatedEvent = createPusherEvent<ClassCreatedEvent>(PusherEventName.CLASS_ADDED, {
  channels: [PusherChannel.COURSE],
});

type ClassTimingsChangedEvent = ClassPusherEvent<{
  /**
   * @format `hh:mm`
   * @example '10:00'
   */
  newStartTime: string;
  /**
   * @format `hh:mm`
   * @example '12:00'
   */
  newEndTime: string;
  scheStartTime: UnixTime;
  /** Will be empty string for online class */
  venue: string;
}>;

export const classTimingsChangedEvent = createPusherEvent<ClassTimingsChangedEvent>(
  PusherEventName.CLASS_TIMINGS_CHANGED,
  { channels: [PusherChannel.COURSE] }
);

type ClassVenueChangedEvent = ClassPusherEvent<{
  isOnlineMeeting: NumericBoolean;
  /** updated value of venue, can be empty string for online meeting */
  newVenue: string;
  /** previous venue value, can be empty string for online meeting */
  venue: string;
  scheStartTime: UnixTime;
}>;

export const classVenueChangedEvent = createPusherEvent<ClassVenueChangedEvent>(
  PusherEventName.CLASS_VENUE_CHANGED,
  { channels: [PusherChannel.COURSE] }
);

export type AttendanceEditedEvent = ClassPusherEvent<{}>;

export const attendanceEditedEvent = createPusherEvent<AttendanceEditedEvent>(
  PusherEventName.ATTENDANCE_EDITED,
  { channels: [PusherChannel.USER] }
);

export type AttendanceScheduledToStartEvent = ClassPusherEvent<{
  taker: MongoId;
  sender: ClassIncharge;
}>;

export const attendanceScheduledToStartEvent = createPusherEvent<AttendanceScheduledToStartEvent>(
  PusherEventName.ATTENDANCE_SCHEDULED_TO_START,
  { channels: [PusherChannel.USER], ignoreForSameUser: false }
);

export type ClassTeamEditedEvent = ClassPusherEvent<{
  message: string;
  /**
   * old classes are still dependent on this key because server has not done any migration
   * @deprecated - prefer teamMembers.inCharges
   */
  inCharge: ClassIncharge;
  /**
   * old classes are still dependent on this key because server has not done any migration
   * @deprecated - prefer teamMembers.assistants
   */
  assistants: CourseTeamUser[];
  /** teamMembers is optional because older classes will not have this key because server has not done any migration */
  teamMembers?: {
    /** admin, instructor */
    inCharges: ClassIncharge[];
    /** admin, instructor, ta */
    assistants: CourseTeamUser[];
  };
  multiple: NumericBoolean;
}>;

export const classTeamEditedEvent = createPusherEvent<ClassTeamEditedEvent>(
  PusherEventName.CLASS_TEAM_EDITED,
  { channels: [PusherChannel.COURSE_TEAM] }
);

export type AttendanceWarningEvent = ClassPusherEvent<{
  /**
   * `showWarning` means attendance is started.
   * `hideWarning` means attendance is stopped
   */
  action: AttendanceWarningAction;
  isProxy: NumericBoolean;
  attendanceTime: UnixTime;
  scheStartTime: UnixTime;
  /** whether class is automatically started, because of a scheduled attendance */
  classAutoStarted: NumericBoolean;
  /** ignore, if classAutoStarted === 0 */
  classStartedAt: UnixTime;
  /** ignore, if classAutoStarted === 0 */
  classTrueNum: number;
  wasScheduled: NumericBoolean;
  numEnrolled: number;
  numAvailable: number;
  numPresent: number;
  sender: ClassTeamUser;
}>;

export const attendanceWarningEvent = createPusherEvent<AttendanceWarningEvent>(
  PusherEventName.WEB_ATTENDANCE_WARNING,
  { channels: [PusherChannel.COURSE], ignoreForSameUser: false }
);

export type AttendeeAvailableEvent = ClassPusherEvent<{
  attendanceTime: UnixTime;
  count: number;
  goToView: AcadlyScreen.CLASS_PAGE;
  sender: CourseStudentUser;
}>;

export const attendeeAvailableEvent = createPusherEvent<AttendeeAvailableEvent>(
  PusherEventName.ATTENDEE_AVAILABLE,
  { channels: [PusherChannel.USER] }
);

export type AttendeeFailureEvent = ClassPusherEvent<{
  attendanceTime: UnixTime;
  sender: CourseStudentUser;
  failedAttendee: CourseStudentUser & { failureCode: AttendanceFailureCode };
}>;

export const attendeeFailureEvent = createPusherEvent<AttendeeFailureEvent>(
  PusherEventName.ATTENDEE_FAILURE,
  { channels: [PusherChannel.USER] }
);

export type AttendanceMarkedEvent = ClassPusherEvent<{
  attendanceTime: UnixTime;
  sender: CourseStudentUser;
  count: number;
  studentId: MongoId;
  attendee: MongoId;
}>;

export const attendanceMarkedEvent = createPusherEvent<AttendanceMarkedEvent>(
  PusherEventName.ATTENDANCE_MARKED,
  { channels: [PusherChannel.USER] }
);

export type ClassCheckInEvent = ClassPusherEvent<{
  checkInTime: UnixTime;
  isLate: NumericBoolean;
  scheStartTime: UnixTime;
  sender: CourseStudentUser;
  // team key is not used anywhere
  // team: API.ClassInfo['team'];
}>;

export const classCheckInEvent = createPusherEvent<ClassCheckInEvent>(PusherEventName.CLASS_CHECK_IN, {
  channels: [PusherChannel.USER],
});

export type ClassInChargeSetEvent = ClassPusherEvent<{
  scheStartTime: UnixTime;
  venue: string;
  /**
   * use it only for old records before multiple incharge are allowed
   * @deprecated prefer teamMembers.inCharges key
   */
  inCharge: ClassIncharge;
  /**
   * use it only for old records before multiple incharge are allowed
   * @deprecated prefer teamMembers.assistants key
   */
  assistants: CourseTeamUser[];
  /** will be available on new records after multiple incharge are allowed */
  teamMembers?: {
    /** admin, instructor */
    inCharges: ClassIncharge[];
    /** admin, instructor, ta */
    assistants: CourseTeamUser[];
  };
  sender: ClassIncharge;
  /** 1 when multiple classes are changed, otherwise 0 */
  multiple: NumericBoolean;
}>;

export const classInChargeSetEvent = createPusherEvent<ClassInChargeSetEvent>(
  PusherEventName.CLASS_IN_CHARGE_SET,
  { channels: [PusherChannel.USER] }
);
