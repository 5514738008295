import { Box, Skeleton, Stack } from '@mui/material';

import Typography from '../../../components/Typography';
import { Root } from './styles';

export const PaymentMethodSkeleton = () => {
  return (
    <Root>
      <Stack gap={4}>
        <Typography variant="h5Bold">
          <Skeleton variant="text" width={200} />
        </Typography>
        <Stack gap={6}>
          {new Array(2).fill('').map((_, index) => (
            <Stack key={index} direction="row" alignItems="center" gap={5}>
              <Skeleton variant="circular" height={20} width={20} />
              <Typography variant="body1">
                <Skeleton variant="text" width={200} />
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack gap={4}>
        <Typography variant="h5Bold">
          <Skeleton variant="text" width={200} />
        </Typography>
        <Stack gap={6}>
          {new Array(2).fill('').map((_, index) => (
            <Stack key={index} direction="row" alignItems="center" gap={5}>
              <Box mt={-6}>
                <Skeleton variant="circular" height={20} width={20} />
              </Box>
              <Stack>
                <Typography variant="body1">
                  <Skeleton variant="text" width={200} />
                </Typography>
                <Typography variant="body1">
                  <Skeleton variant="text" width={150} />
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Root>
  );
};

export default PaymentMethodSkeleton;
