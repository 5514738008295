const settings = {
  /** document title */
  acadly_settings: 'Acadly - Settings',

  /** Section titles */
  account_settings: 'Account Settings',
  course_settings: 'Course Settings',

  /** Change password */
  change_password: 'Change password',

  /** Change name */
  change_name: 'Change name',

  /** Change avatar */
  change_avatar: 'Change avatar',

  /** Manage notifications */
  manage_notifications: 'Manage notifications',
  click_to_manage_notifications: 'click to manage notifications',

  /** Manage notifications drawer */
  set_notification_preferences: 'Set Notification Preferences',
  course_schedule_updated: 'Course schedule updated',
  new_query_raised: 'New query raised',
  new_response_to_your_query: 'New response to your query',
  new_class_activity_published: 'New class activity published',
  new_announcement_made: 'New announcement made',
  new_assignment_published: 'New assignment published',
  type_notifications_when_label_are_on_click_to_turn_it_off:
    '{{type}} notifications when {{label}} are on, click to turn it off',
  type_notifications_when_label_are_off_click_to_turn_it_on:
    '{{type}} notifications when {{label}} are off, click to turn it on',
  cloud_notifications: 'Cloud notifications',
  email_notifications: 'Email notifications',
  click_to_save_notification_preferences: 'click to save notification preferences',

  /** Feature updates */
  feature_updates: 'Feature updates',
  click_to_check_new_feature_updates: 'click to check new feature updates',

  /** Feature updates drawer */
  all_feature_updates: 'All feature updates',
  click_to_view_more_label: 'click to {{viewMoreLabel}}',

  /** Help tips */
  help_tips: 'Help Tips',
  click_to_turn_on_help_tips: 'click to turn on help tips',
  click_to_turn_off_help_tips: 'click to turn off help tips',

  /** Accessibility features */
  accessibility_features: 'Accessibility Features',
  click_to_turn_on_accessibility: 'click to turn on accessibility',
  click_to_turn_off_accessibility: 'click to turn on accessibility',

  /** Zoom integration */
  click_to_know_zoom_integration_status_and_to_make_changes_to_it:
    'click to know zoom integration status and to make changes to it',

  /** Remove courses */
  remove_courses: 'Remove courses',
  click_to_remove_courses_where_you_are_admin: 'click to remove courses where you are admin',

  /** Terms of Use */
  terms_of_use: 'Terms of use',
  click_to_read_our_terms_of_use: 'click to read our terms of use',

  /** Privacy Policy */
  click_to_read_our_privacy_policy: 'click to read our privacy policy',

  /** Feedback */
  click_to_give_your_valuable_feedback: 'click to give your valuable feedback',

  /** Share the Goodness */
  click_to_share_the_goodness: 'click to share the goodness',
};

export default settings;
