import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { clearLocalAttendanceData } from '../../../../db/classes/actions';
import { selectClass } from '../../../../db/classes/selectors';
import { AttendanceInProgressData } from '../../../../db/classes/types';
import { ClassParams } from '../../../../pages/routes';
import { getIsInClassTeam, getIsProxyAttendanceInProgress } from '../../helpers';

export interface UseAttendanceSection {
  attendanceInProgress: AttendanceInProgressData;
}

const useAttendanceSection = ({ attendanceInProgress }: UseAttendanceSection) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { courseShortId, classShortId } = useParams<ClassParams>();
  const cls = useSelector(selectClass(classShortId));

  const isInClassTeam = getIsInClassTeam(cls);

  const isProxyAttendanceInProgress = getIsProxyAttendanceInProgress(cls);

  const handleClearLocalAttendanceData = () => {
    dispatch(clearLocalAttendanceData());
  };

  /** remove attendance data on route change as class team will not be interested in this data if they are moving away */
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!courseShortId) return;
      if (!classShortId) return;
      const currentPath = `/acetone/courses/${courseShortId}/timeline/classes/${classShortId}/attendance`;
      if (location.pathname === currentPath) return;
      if (attendanceInProgress.isInProgress) return;
      dispatch(clearLocalAttendanceData());
    });
    return unlisten;
  }, [attendanceInProgress.isInProgress, classShortId, courseShortId, dispatch, history]);

  return {
    courseShortId,
    classShortId,
    isInClassTeam,
    isProxyAttendanceInProgress,
    handleClearLocalAttendanceData,
  };
};

export default useAttendanceSection;
