import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';

import { DownstreamZoomAppEvent, UpstreamZoomAppEvent, ZoomAppEvent } from '@acadly/zoom-app';

import { selectAuthSession } from '../../auth/store/selectors';
import { useCurrentUserWithRole } from '../../courses/hooks';
import { selectClass } from '../../db/classes/selectors';
import {
  fetchZoomMeetingCredentials,
  registerZoomMeetingParticipant,
  unregisterZoomMeetingParticipant,
} from '../../db/online-meeting/actions';
import {
  addressingHandEvent,
  broadcastStartedEvent,
  broadcastStoppedEvent,
  handLoweredEvent,
  handResolvedEvent,
  meetingDestroyedEvent,
  meetingEndedEvent,
  participantJoinedEvent,
  participantLeftEvent,
} from '../../db/online-meeting/pusher-events';
import { selectOnlineMeeting } from '../../db/online-meeting/selectors';
import { ClassRole, ResolveHandAction } from '../../db/shared/types';
import routes, { ClassParams } from '../../pages/routes';
import { usePusherEvent } from '../../pusher/bus';
import Logger from '../../utils/logger';
import { useRequestDispatch } from '../../utils/request-actions';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';
import { convertClassRoleToZoomAppRole } from './helpers';

const logger = Logger.create('zoom-meeting');

export default function useZoomMeetingVM() {
  const requestDispatch = useRequestDispatch();

  const location = useLocation();

  const {
    appRef,
    closeZoomApp,
    state: { classId, courseId },
  } = useZoomMeetingContext();

  const currentUser = useCurrentUserWithRole();

  const [isRemoteAttendanceMarkedDialogOpen, setIsRemoteAttendanceMarkedDialogOpen] = useState(false);

  const session = useSelector(selectAuthSession);

  const cls = useSelector(selectClass(classId));
  const onlineDetails = useSelector(selectOnlineMeeting(classId));

  const origin =
    process.env.NODE_ENV === 'development' ? process.env.REACT_APP_ZOOM_APP_ORIGIN : window.location.origin;

  const leaveMeeting = useCallback(
    function leaveMeeting(forced: boolean = false) {
      if (forced) {
        closeZoomApp();
      } else {
        appRef.current?.sendEvent({
          type: DownstreamZoomAppEvent.LEAVE_MEETING,
        });
      }
      // TODO: unregister notification worker here
    },
    [closeZoomApp, appRef]
  );

  usePusherEvent(
    meetingEndedEvent,
    useCallback(
      function handleMeetingEnded() {
        leaveMeeting(true);
      },
      [leaveMeeting]
    )
  );

  usePusherEvent(
    meetingDestroyedEvent,
    useCallback(
      function handleMeetingDestroyed() {
        leaveMeeting(true);
      },
      [leaveMeeting]
    )
  );

  usePusherEvent(
    broadcastStoppedEvent,
    useCallback(
      function handleBroadcastStopped() {
        leaveMeeting(true);
      },
      [leaveMeeting]
    )
  );

  usePusherEvent(
    broadcastStartedEvent,
    useCallback(
      function handleBroadcastStarted(event) {
        if (event.payload.classId !== classId) return;
        if (
          onlineDetails?.hostUserId === currentUser.userId &&
          !onlineDetails?.isBroadcastedFromCurrentDevice
        ) {
          /**
           * Broadcast has been started from other device so leave meeting
           * from current device
           */
          leaveMeeting();
        }
      },
      [
        classId,
        onlineDetails?.hostUserId,
        currentUser.userId,
        leaveMeeting,
        onlineDetails?.isBroadcastedFromCurrentDevice,
      ]
    )
  );

  usePusherEvent(
    participantJoinedEvent,
    useCallback(
      function handleParticipantJoined(event) {
        if (event.payload.classId !== classId) return;
        const { joinId, mustExpel, name, toLeave, zoomUserId } = event.payload;
        appRef.current?.sendEvent({
          type: DownstreamZoomAppEvent.PARTICIPANT_JOINED,
          joinId,
          mustExpel,
          name,
          toLeave,
          zoomUserId,
        });
      },
      [classId, appRef]
    )
  );

  usePusherEvent(
    participantLeftEvent,
    useCallback(
      function handleParticipantLeft(event) {
        if (event.payload.classId !== classId) return;
        const { name, zoomUserId } = event.payload;
        appRef.current?.sendEvent({
          type: DownstreamZoomAppEvent.PARTICIPANT_LEFT,
          name,
          zoomUserId,
        });
      },
      [classId, appRef]
    )
  );

  const addressHand = useCallback(
    function addressHand(userId: MongoId) {
      if (!session?.userId) return;
      if (session.userId !== userId) return;
      appRef.current?.sendEvent({
        type: DownstreamZoomAppEvent.ENABLE_CAMERA_MIC,
      });
    },
    [session?.userId, appRef]
  );

  usePusherEvent(
    addressingHandEvent,
    useCallback(
      function handleAddressingHand(event) {
        if (event.payload.classId !== classId) return;
        addressHand(event.payload.userId);
      },
      [classId, addressHand]
    )
  );

  const lowerHand = useCallback(
    function lowerHand(userId: MongoId) {
      if (!session?.userId) return;
      if (session.userId !== userId) return;
      appRef.current?.sendEvent({
        type: DownstreamZoomAppEvent.DISABLE_CAMERA_MIC,
      });
    },
    [session?.userId, appRef]
  );

  usePusherEvent(
    handLoweredEvent,
    useCallback(
      function handleHandLowered(event) {
        if (event.payload.classId !== classId) return;
        lowerHand(event.payload.userId);
      },
      [classId, lowerHand]
    )
  );

  usePusherEvent(
    handResolvedEvent,
    useCallback(
      function handleHandResolved(event) {
        if (event.payload.classId !== classId) return;
        const { action, userId } = event.payload;
        lowerHand(userId);
        if (action === ResolveHandAction.NEXT) {
          const { nextUser } = event.payload;
          addressHand(nextUser.userId);
        }
      },
      [addressHand, classId, lowerHand]
    )
  );

  const joinMeeting = useCallback(
    async function joinMeeting() {
      const zoom = appRef.current;
      if (!courseId || !classId || !zoom) return;
      try {
        const { meetingId, meetingPassword, joinId, signature } = await requestDispatch(
          fetchZoomMeetingCredentials,
          { classId }
        );
        zoom.joinMeeting({
          classId,
          courseId,
          disableAudio: onlineDetails?.hostUserId === currentUser.userId,
          joinId,
          meetingId,
          signature,
          meetingPassword,
          role: convertClassRoleToZoomAppRole(onlineDetails?.myClassRole),
          isHost: onlineDetails?.hostUserId === currentUser.userId,
        });
      } catch (error) {
        logger.error(error);
      }
    },
    [
      appRef,
      courseId,
      classId,
      requestDispatch,
      onlineDetails?.myClassRole,
      onlineDetails?.hostUserId,
      currentUser.userId,
    ]
  );

  const handleCloseRemoteAttendanceMarkedDialog = () => {
    setIsRemoteAttendanceMarkedDialogOpen(false);
  };

  const registerParticipant = useCallback(
    async function registerParticipant(event: ZoomAppEvent.Upstream<UpstreamZoomAppEvent.JOINED_MEETING>) {
      if (!classId) return;
      if (!onlineDetails?.joinId) return;

      if (cls?.myRole === ClassRole.STUDENT) {
        const { attendanceRecorded } = await requestDispatch(registerZoomMeetingParticipant, {
          classId,
          joinId: onlineDetails?.joinId,
          meetingId: onlineDetails?.meetingId,
          device: 'web',
          zoomUserId: event.zoomUserId,
        });
        setIsRemoteAttendanceMarkedDialogOpen(Boolean(attendanceRecorded));
      }

      // TODO: start notification worker for zoom-meeting and ask for desktop notification permission
    },
    [classId, cls?.myRole, onlineDetails?.joinId, onlineDetails?.meetingId, requestDispatch]
  );

  const unregisterParticipant = useCallback(
    async function unregisterParticipant(event: ZoomAppEvent.Upstream<UpstreamZoomAppEvent.LEFT_MEETING>) {
      closeZoomApp();

      if (cls?.myRole !== ClassRole.STUDENT) return;
      if (!courseId || !classId || !onlineDetails?.meetingId) return;

      await requestDispatch(unregisterZoomMeetingParticipant, {
        courseId,
        classId,
        meetingId: onlineDetails?.meetingId,
        zoomUserId: event.zoomUserId,
      });
    },
    [classId, closeZoomApp, cls?.myRole, courseId, onlineDetails?.meetingId, requestDispatch]
  );

  const handleNotificationClick = useCallback(function handleNotificationClick(
    event: ZoomAppEvent.Upstream<UpstreamZoomAppEvent.NOTIFICATION_CLICK>
  ) {
    // TODO: implement it
  },
  []);

  const handleEvent = useCallback(
    function handleEvent(e: ZoomAppEvent.Upstream) {
      logger.debug(e);
      switch (e.type) {
        case UpstreamZoomAppEvent.READY:
          joinMeeting();
          break;
        case UpstreamZoomAppEvent.JOINED_MEETING:
          registerParticipant(e);
          break;
        case UpstreamZoomAppEvent.LEFT_MEETING:
          unregisterParticipant(e);
          break;
        case UpstreamZoomAppEvent.NOTIFICATION_CLICK:
          handleNotificationClick(e);
          break;
        case UpstreamZoomAppEvent.UNHANDLED_ERROR:
          logger.error(e);
          break;
        default:
          logger.warn('Unhandled event received!');
          break;
      }
    },
    [handleNotificationClick, joinMeeting, registerParticipant, unregisterParticipant]
  );

  const canPopIn = matchPath<ClassParams>(location.pathname, routes.activities.path)?.isExact;

  return {
    handleCloseRemoteAttendanceMarkedDialog,
    handleEvent,
    isRemoteAttendanceMarkedDialogOpen,
    origin,
    zoomAppRef: appRef,
    canPopIn,
  };
}
