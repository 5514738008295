import { useCallback, useEffect, useState } from 'react';

import { FileAttachment } from '../../../db/shared/types';
import { uploadFormula as _uploadFormula, uploadImage as _uploadImage } from '../helpers';

export interface VMProps {
  initialAttachments?: FileAttachment[];
  onAttachmentsChange?: (attachments: FileAttachment[]) => any;
}

const INITIAL_ATTACHMENTS: FileAttachment[] = [];

export default function useRichTextEditorVM({
  initialAttachments = INITIAL_ATTACHMENTS,
  onAttachmentsChange,
}: VMProps) {
  const [uploadingImageCount, setUploadingImageCount] = useState(0);
  const [attachments, setAttachments] = useState(initialAttachments);

  const uploadFormula = useCallback(_uploadFormula, []);

  const uploadImage = useCallback(async (file: File) => {
    try {
      setUploadingImageCount((count) => count + 1);
      const result = await _uploadImage(file);
      return result;
    } finally {
      setUploadingImageCount((count) => count - 1);
    }
  }, []);

  const handleAttachmentUpload = (attachment: FileAttachment) => {
    setAttachments((attachments) => [...attachments, attachment]);
  };

  const handleAttachmentDelete = (attachment: FileAttachment) => () => {
    setAttachments((attachments) => attachments.filter((a) => a !== attachment));
  };

  useEffect(
    function resetAttachments() {
      setAttachments(initialAttachments);
    },
    [initialAttachments]
  );

  useEffect(
    function notifyAttachmentChange() {
      onAttachmentsChange?.(attachments);
    },
    [attachments, onAttachmentsChange]
  );

  return {
    attachments,
    isUploadingImage: uploadingImageCount > 0,
    onAttachmentUpload: handleAttachmentUpload,
    onAttachmentDelete: handleAttachmentDelete,
    uploadFormula,
    uploadImage,
  };
}
