import { CommentContext } from '../db/shared/types';
import { UserWithRole } from '../types';

export enum PusherEventName {
  INSTRUCTOR_PURCHASE_SUCCESSFUL = 'instructorPurchaseSuccessful',
  ACTIVITY_STOPPED = 'activityStopped',
  ACTIVITY_UPDATED = 'activityUpdated',
  ADDRESSING_HAND = 'addressingHand',
  ANNOUNCEMENT_ADDED = 'announcementAdded',
  ANOTHER_INCHARGE_READY_TO_BROADCAST = 'anotherInChargeReadyToBroadcast',
  APPROVE_QUERY = 'approveQuery',
  ASSIGNMENT_PUBLISHED = 'assignmentPublished',
  ASSIGNMENT_SUBMITTED = 'assignmentSubmitted',
  ATTENDANCE_EDITED = 'attendanceEdited',
  ATTENDANCE_MARKED = 'attendanceMarked',
  ATTENDANCE_SCHEDULED_TO_START = 'attendanceScheduledToStart',
  ATTENDEE_AVAILABLE = 'attendeeAvailable',
  ATTENDEE_FAILURE = 'attendeeFailure',
  BROADCAST_STOPPED = 'broadcastStopped',
  BROADCASTING = 'broadcasting',
  CLASS_ADDED = 'classAdded',
  CLASS_CHECK_IN = 'classCheckIn',
  CLASS_IN_CHARGE_SET = 'classInChargeSet',
  CLASS_TEAM_EDITED = 'classTeamUpdated',
  CLASS_TIMINGS_CHANGED = 'classTimingsChanged',
  CLASS_VENUE_CHANGED = 'classVenueChanged',
  COMMENT_ADDED = 'commentAdded',
  COMMENT_MARKED = 'commentMarked',
  COMMENT_REMOVED = 'commentRemoved',
  DISCUSSION_PUBLISHED = 'discussionPublished',
  HAND_LOWERED = 'handLowered',
  HAND_RAISED = 'handRaised',
  HAND_RESOLVED = 'handResolved',
  MEETING_DESTROYED = 'meetingDestroyed',
  MEETING_ENDED = 'meetingEnded',
  MEETING_RECORDINGS_PUBLISHED = 'meetingRecordingsPublished', // TODO: not handled yet
  MEETING_STARTED = 'meetingStarted',
  PARTICIPANT_JOINED = 'participantJoined',
  PARTICIPANT_LEFT = 'participantLeft',
  POLL_PUBLISHED = 'pollPublished',
  POLL_SUBMITTED = 'pollSubmitted',
  PRO_PURCHASE_RESPONSE = 'proPurchaseResponse', // TODO: not handled yet
  QUERY_ADDED = 'queryAdded',
  QUERY_APPROVED = 'queryApproved',
  YOUR_QUERY_APPROVED = 'yourQueryApproved',
  QUERY_UPVOTED = 'queryUpvoted',
  QUIZ_PUBLISHED = 'quizPublished',
  QUIZ_SUBMITTED = 'quizSubmitted',
  READY_TO_BROADCAST = 'readyToBroadcast',
  REMOTE_ATTENDANCE_RESPONSE = 'remoteAttendanceResponse', // TODO: not handled yet
  REMOTE_ATTENDANCE_STARTED = 'remoteAttendanceStarted',
  REMOTE_ATTENDANCE_STOPPED = 'remoteAttendanceStopped',
  RESOURCE_PUBLISHED = 'resourcePublished',
  SUBMISSION_RETRACTED = 'submissionRetracted',
  WEB_ATTENDANCE_WARNING = 'web-attendanceWarning',
  WORD_CLOUD_AVAILABLE = 'wordCloudAvailable',
  WORD_CLOUD_GENERATED = 'wordCloudGenerated',
  ZOOM_AUTHENTICATED = 'zoomAuthenticated',
}

export enum PusherChannel {
  USER = 'private-user',
  COURSE = 'private-course',
  COURSE_TEAM = 'private-courseTeam',
}

export interface CreatePusherEventOptions {
  /**
   * Used to show in-app or desktop notifications
   * @default true
   */
  notify?: boolean;
  /** List of channels that can receive this event */
  channels: PusherChannel[];
  /**
   * Ignore pusher events if user-id of sender is equal
   * to current user's id
   * @default true
   */
  ignoreForSameUser?: boolean;
}

export interface PusherEventMetaData {
  /** channel which received this event */
  receivedOn: PusherChannel;
  eventName: PusherEventName;
  /** Used to show in-app or desktop notifications */
  notify: boolean;
  /**
   * Ignore pusher events if user-id of sender is equal
   * to current user's id
   * @default true
   */
  ignoreForSameUser: boolean;
}

export enum AcadlyScreen {
  TIMELINE = 'timeline',
  CLASS_PAGE = 'classPage',
  POLL = 'poll',
  QUIZ = 'quiz',
  DISCUSSION = 'discussion',
  ASSIGNMENT = 'assignment',
  RESOURCE = 'resource',
}

export interface BasePusherEventPayload {
  /** could be "NA" in some cases */
  msgId: string;
  goToView?: AcadlyScreen;
  message?: string;
  /** unix-timestamp, added after receiving event at client side */
  timestamp: UnixTime;
  sender: UserWithRole;
}

export type PusherEventPayload<T extends {} = never> = [T] extends [never]
  ? BasePusherEventPayload
  : T extends { sender: any }
  ? Omit<BasePusherEventPayload, 'sender'> & T
  : BasePusherEventPayload & T;

export type CoursePusherEvent<T extends {} = never> = PusherEventPayload<T> & {
  courseId: MongoId;
  universityId?: MongoId;
};

export type AnnouncementPusherEvent<T extends {} = never> = CoursePusherEvent<T> & {
  announcementId: MongoId;
};

export type AssignmentPusherEvent<T extends {} = never> = CoursePusherEvent<T> & {
  assignmentId: MongoId;
};

export type ClassPusherEvent<T extends {} = never> = CoursePusherEvent<T> & {
  classId: MongoId;
};

export type ClassActivityPusherEvent<T extends {} = never> = ClassPusherEvent<T> & {
  activityId: MongoId;
};

export type CommentAddedPusherEvent<T extends {} = never> = CoursePusherEvent<T> & {
  shouldTeamIgnore: NumericBoolean;
  context: CommentContext;
};

export interface PusherEvent<P extends {}, T extends PusherEventName = PusherEventName> {
  type: T;
  payload: P;
  meta: PusherEventMetaData;
}

export interface PusherEventCreator<P extends {}, T extends PusherEventName> {
  eventName: T;
  /** List of channels that can receive this event */
  channels: PusherChannel[];
  (payload: P, channel: PusherChannel): PusherEvent<P, T>;
  match(event: PusherEvent<any>): event is PusherEvent<P, T>;
}

export interface PusherSubscriptionError {
  /** Category of error that occured, e.g. AuthError */
  type: string;
  /** The HTTP Status code of the error response from the auth call. */
  status: number;
  /** Human readable details of error that occurred. */
  error: string;
}
