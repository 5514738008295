import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import {
  AddressHandSuccessPayload,
  CreateZoomMeetingPayload,
  CreateZoomMeetingSuccessPayload,
  FetchClassRecordingsPayload,
  FetchClassRecordingsSuccessPayload,
  FetchZoomMeetingCredentialsPayload,
  FetchZoomMeetingCredentialsSuccessPayload,
  FetchZoomMeetingDetailsPayload,
  FetchZoomMeetingDetailsSuccessPayload,
  LowerHandSuccessPayload,
  MeetingEndedPayload,
  RaiseHandSuccessPayload,
  ReadyToBroadcastPayload,
  ResolveHandPayload,
  ResolveHandSuccessPayload,
  UnregisterZoomMeetingParticipantPayload,
} from './action.type';
import {
  AddressingHandEvent,
  AnotherInchargeReadyToBroadcastEvent,
  BroadcastStartedEvent,
  BroadcastStoppedEvent,
  HandLoweredEvent,
  HandRaisedEvent,
  HandResolvedEvent,
  MeetingDestroyedEvent,
  MeetingStartedEvent,
  ParticipantJoinedEvent,
  ParticipantLeftEvent,
  ZoomAuthenticatedPayload,
} from './pusher-events';

export const authorizeZoomApp = createRequestAction<void, API.AuthorizeZoomAppResponse>(
  'applications/zoom/authorize'
);

export const zoomAuthenticated = createAction<ZoomAuthenticatedPayload>('online-meeting/zoom/authenticated');

export const createZoomMeeting = createRequestAction<
  CreateZoomMeetingPayload,
  CreateZoomMeetingSuccessPayload
>('online-meeting/zoom/create');

export const getZoomMeetingStatus = createRequestAction<API.GetZoomMeetingStatusRequest>(
  'online-meeting/zoom/status/get'
);

export const meetingLaunched = createAction<AnotherInchargeReadyToBroadcastEvent>('online-meting/launched');

export const readyToBroadcast = createAction<ReadyToBroadcastPayload>('online-meeting/zoom/broadcast/ready');

export const startZoomMeetingBroadcast = createRequestAction<
  API.StartZoomMeetingBroadcastRequest,
  API.StartZoomMeetingBroadcastRequest
>('online-meeting/zoom/broadcast/start');

export const broadcastStarted = createAction<BroadcastStartedEvent>('online-meeting/zoom/broadcast/started');
export const meetingStarted = createAction<MeetingStartedEvent>('online-meeting/zoom/started');

export const fetchZoomMeetingDetails = createRequestAction<
  FetchZoomMeetingDetailsPayload,
  FetchZoomMeetingDetailsSuccessPayload
>('online-meeting/zoom/details/fetch');

export const fetchZoomMeetingCredentials = createRequestAction<
  FetchZoomMeetingCredentialsPayload,
  FetchZoomMeetingCredentialsSuccessPayload
>('online-meeting/zoom/credentials/fetch');

export const registerZoomMeetingParticipant = createRequestAction<
  API.RegisterZoomMeetingParticipantRequest,
  API.RegisterZoomMeetingParticipantResponse
>('online-meeting/zoom/participant/register');

export const participantJoined = createAction<ParticipantJoinedEvent>(
  'online-meeting/zoom/participant/joined'
);

export const attendingRemotely = createRequestAction<API.AttendingRemotelyRequest>(
  'online-meeting/zoom/attending-remotely'
);

export const raiseHand = createRequestAction<API.RaiseHandRequest, RaiseHandSuccessPayload>(
  'online-meeting/zoom/raise-hand'
);

export const handRaised = createAction<HandRaisedEvent>('online-meeting/zoom/hand-raised');

export const lowerHand = createRequestAction<API.LowerHandRequest, LowerHandSuccessPayload>(
  'online-meeting/zoom/lower-hand'
);

export const handLowered = createAction<HandLoweredEvent>('online-meeting/zoom/hand-lowered');

export const addressHand = createRequestAction<API.AddressHandRequest, AddressHandSuccessPayload>(
  'online-meeting/zoom/address-hand'
);

export const addressingHand = createAction<AddressingHandEvent>('online-meeting/zoom/addressing-hand');

export const resolveHand = createRequestAction<ResolveHandPayload, ResolveHandSuccessPayload>(
  'online-meeting/zoom/resolve-hand'
);

export const handResolved = createAction<HandResolvedEvent>('online-meeting/zoom/hand-resolved');

export const unregisterZoomMeetingParticipant = createRequestAction<
  UnregisterZoomMeetingParticipantPayload,
  API.UnregisterZoomMeetingParticipantResponse
>('online-meeting/zoom/participant/unregister');

export const participantLeft = createAction<ParticipantLeftEvent>('online-meeting/zoom/participant/left');

export const endZoomMeetingBroadcast = createRequestAction<
  API.EndZoomMeetingBroadcastRequest,
  API.EndZoomMeetingBroadcastRequest
>('online-meeting/zoom/broadcast/end');

export const broadcastStopped = createAction<BroadcastStoppedEvent>('online-meeting/zoom/broadcast/stopped');
export const meetingEnded = createAction<MeetingEndedPayload>('online-meeting/zoom/ended');
export const meetingDestroyed = createAction<MeetingDestroyedEvent>('online-meeting/zoom/destroyed');

export const fetchClassRecordings = createRequestAction<
  FetchClassRecordingsPayload,
  FetchClassRecordingsSuccessPayload
>('online-meeting/recordings/fetch');

export const publishClassRecordings = createRequestAction<
  API.PublishClassRecordingRequest,
  API.PublishClassRecordingRequest
>('online-meeting/recodings/publish');

export const deleteClassRecordings = createRequestAction<
  API.DeleteClassRecordingsRequest,
  API.DeleteClassRecordingsRequest
>('online-meeting/recordings/delete');

export const startRemoteAttendance = createRequestAction<
  API.StartRemoteAttendanceRequest,
  API.StartRemoteAttendanceResponse
>('online-meeting/attendance/remote/start');

export const stopRemoteAttendance = createRequestAction<
  API.StopRemoteAttendanceRequest,
  API.StopRemoteAttendanceResponse
>('online-meeting/attendance/remote/stop');

export const markRemoteAttendance = createRequestAction<
  API.MarkRemoteAttendanceRequest,
  API.MarkRemoteAttendanceResponse
>('online-meeting/attendance/remote/mark');
