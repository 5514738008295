import { Trans, useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import { i18nNS } from '../../../i18n';
import { CourseRole } from '../../../types';
import { format } from '../../../utils/datetime';
import { ANDROID_APP_DOWNLOAD_LINK, IOS_APP_DOWNLOAD_LINK } from '../../constants';
import { AttendanceGif, attendanceInProgressDialogClasses, Instruction, StyledTypography } from './styles';
import useAttendanceInProgressDialogVM from './vm';

const AttendanceInProgressDialog = () => {
  const { t } = useTranslation([i18nNS.CLASS, i18nNS.GLOSSARY]);

  const {
    currentUser,
    attendanceInProgress,
    isAttendanceInProgressDialogOpen,
    onCloseAttendanceInProgressDialog: handleCloseAttendanceInProgressDialog,
    count,
  } = useAttendanceInProgressDialogVM();

  if (currentUser.role !== CourseRole.STUDENT) return null;

  if (!attendanceInProgress?.isInProgress) return null;

  const { taker, startedAt } = attendanceInProgress;

  return (
    <Alert open={isAttendanceInProgressDialogOpen}>
      <Alert.Header>{t('attendance_in_progress', { ns: i18nNS.CLASS })}</Alert.Header>
      <Alert.Body>
        <AttendanceGif />
        <StyledTypography className={attendanceInProgressDialogClasses.highlighted}>
          {t('N_sec', { ns: i18nNS.CLASS, time: count })}
        </StyledTypography>
        <Instruction>
          <Trans
            t={t}
            i18nKey="prof_taker_name_is_recording_automatic_attendance_for_the_lecture_that_began_at_start_time_if_you_re_physically_present_at_the_lecture_venue_please_open_acadly_s_ios_or_android_app_to_be_marked_present"
            values={{ takerName: taker.name, startTime: format(startedAt, 'hh:mm a') }}
          >
            Prof. takerName is recording automatic attendance for the lecture that began at startTime. If
            you\'re physically present at the lecture venue, please open
            <a
              href={IOS_APP_DOWNLOAD_LINK}
              target="_blank"
              rel="noreferrer"
              aria-label={t('click_to_download_android_app', { ns: i18nNS.CLASS })}
            >
              Acadly\'s iOS
            </a>
            or
            <a
              href={ANDROID_APP_DOWNLOAD_LINK}
              target="_blank"
              rel="noreferrer"
              aria-label={t('click_to_download_android_app', { ns: i18nNS.CLASS })}
            >
              Android app
            </a>
            to be marked present.
          </Trans>
        </Instruction>
        <StyledTypography>
          {t('attendance_will_not_be_marked_if_you_are_logged_in_only_through_the_web_browser', {
            ns: i18nNS.CLASS,
          })}
        </StyledTypography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action color="error" onClick={handleCloseAttendanceInProgressDialog}>
          {t('dismiss', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default AttendanceInProgressDialog;
