import { Descendant, Editor, Element as SlateElement, Node as SlateNode, Transforms } from 'slate';

import { ParagraphElement } from './types';

export function insertParagraph(editor: Editor, children?: Descendant[]) {
  const element: ParagraphElement = {
    type: 'paragraph',
    children: children || [{ type: 'text', text: '' }],
  };
  Transforms.insertNodes(editor, element);
}

export function isParagraphElement(node: SlateNode): node is ParagraphElement {
  return SlateElement.isElement(node) && node.type === 'paragraph';
}

export default function withParagraphs(editor: Editor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // If the element is a paragraph, ensure its children are valid.
    if (isParagraphElement(node)) {
      for (const [child, childPath] of Array.from(SlateNode.children(editor, path))) {
        if (SlateElement.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
}
