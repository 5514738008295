import { Trans, useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { PlusIcon, Root } from './styles';
import useNoCoursesCardVM from './vm';

const NoCoursesCard = () => {
  const { t } = useTranslation([i18nNS.HOME, i18nNS.COMMON]);
  const { name } = useNoCoursesCardVM();

  return (
    <Root>
      <Typography variant="h2Bold" color="grey.800">
        {t('hey_name_exclamation', { ns: i18nNS.COMMON, name })}
      </Typography>
      <Typography variant="h5Medium" color="grey.500">
        <Trans
          t={t}
          i18nKey="you_don_t_have_any_live_courses_yet_tap_on_the_plus_button_at_the_bottom_right_to_create_a_course"
        >
          You don't have any live courses yet. Tap on the <PlusIcon />
          <Typography variant="h5Bold" color="grey.800" component="span">
            button
          </Typography>{' '}
          at the bottom right to create a course.
        </Trans>
      </Typography>
    </Root>
  );
};

export default NoCoursesCard;
