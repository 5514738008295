import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';

import { getAcadlyRouteByURL } from '../../pages/helpers';
import routes, { ClassParams } from '../../pages/routes';
import { takeEveryPusher } from '../../pusher/subscribe';
import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { fetchCourseDetails } from '../courses/actions';
import { selectCourseUser, selectCurrentCourse, selectCurrentCourseId } from '../courses/selectors';
import { selectCourseLinks } from '../links/selectors';
import { API } from '../shared/api-responses';
import { refreshTimelineWorker } from '../shared/sagas';
import {
  AttendanceWarningAction,
  ClassRole,
  ClassTeamUser,
  CourseRole,
  CourseStudentUser,
  CourseTeamUser,
} from '../shared/types';
import {
  attendanceMarked,
  attendanceScheduledToStart,
  attendeeAvailable,
  attendeeFailure,
  awardClassParticipationPoints,
  cancelClass,
  checkedInToClass,
  checkInToClass,
  classInchargeSet,
  classTeamEdited,
  clearLocalAttendanceData,
  createClass,
  deleteClass,
  editAttendanceSchedule,
  editClassAgenda,
  editClassAttendance,
  editClassSummary,
  editClassTeam,
  editClassTimings,
  editClassTitle,
  editClassTopics,
  editClassVenue,
  endClass,
  fetchAttendanceResponders,
  fetchClassAttendance,
  fetchClassDetails,
  fetchClassesToCopyActivity,
  fetchClassParticipations,
  fetchClassSummary,
  fetchMyAttendanceStatus,
  moveActivity,
  openClass,
  proxyAttendanceStarted,
  proxyAttendanceStopped,
  rescheduleClass,
  scheduleAttendance,
  startClass,
  startProxyAttendance,
  stopProxyAttendance,
} from './actions';
import {
  acknowledgeAttendanceStarted,
  awardClassParticipationPoints as awardClassParticipationPointsAPI,
  cancelClass as cancelClassAPI,
  checkInToClass as checkInToClassAPI,
  createClass as addClassAPI,
  deleteClass as deleteClassAPI,
  editAttendanceSchedule as editAttendanceScheduleAPI,
  editClassAgenda as editClassAgendaAPI,
  editClassAttendance as editClassAttendanceAPI,
  editClassSummary as editClassSummaryAPI,
  editClassTeam as editClassTeamAPI,
  editClassTimings as editClassTimingsAPI,
  editClassTitle as editClassTitleAPI,
  editClassTopics as editClassTopicsAPI,
  editClassVenue as editClassVenueAPI,
  getAttendanceResponders,
  getClassAttendance,
  getClassDetails,
  getClassesToCopyActivity,
  getClassParticipations,
  getClassSummary,
  getMyAttendanceStatus,
  moveActivity as moveActivityAPI,
  rescheduleClass as rescheduleClassAPI,
  scheduleAttendance as scheduleAttendanceAPI,
  startProxyAttendance as startProxyAttendanceAPI,
  stopProxyAttendance as stopProxyAttendanceAPI,
} from './api';
import { getClassActivities, getClassRole, getClassStatus, getClassTeam, getClassTiming } from './helpers';
import {
  attendanceEditedEvent,
  attendanceMarkedEvent,
  attendanceScheduledToStartEvent,
  attendanceWarningEvent,
  attendeeAvailableEvent,
  attendeeFailureEvent,
  classCheckInEvent,
  classCreatedEvent,
  classInChargeSetEvent,
  classTeamEditedEvent,
  classTimingsChangedEvent,
  classVenueChangedEvent,
} from './pusher-events';
import { selectClass, selectClassUser } from './selectors';
import { Class, FetchClassesToCopyActivitySuccessPayload } from './types';

const log = Logger.create('db/classes');

function* createClassWorker(action: ReturnType<typeof createClass.request>) {
  const { requestId } = action.meta;
  try {
    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    const response: YieldCallType<typeof addClassAPI> = yield call(addClassAPI, action.payload);
    if (response.message === 'success') {
      // refresh course timeline and wait for new timeline data
      yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
      yield put(createClass.success(requestId, { status: 'success' }));
    } else {
      yield put(
        createClass.success(requestId, {
          status: 'failure',
          reason: response.message,
          clashingClasses: response.clashingClasses,
        })
      );
    }
  } catch (error) {
    log.error(error);
    yield put(createClass.failure(requestId));
  }
}

function* classCreatedEventWorker(event: ReturnType<typeof classCreatedEvent>) {
  const { courseId } = event.payload;
  yield call(refreshTimelineWorker, courseId);
}

function* editClassTitleWorker(action: ReturnType<typeof editClassTitle.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editClassTitleAPI, action.payload);
    yield put(editClassTitle.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editClassTitle.failure(requestId));
  }
}

function* editClassTimingsWorker(action: ReturnType<typeof editClassTimings.request>) {
  const { requestId } = action.meta;
  try {
    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    const response: YieldCallType<typeof editClassTimingsAPI> = yield call(
      editClassTimingsAPI,
      action.payload
    );

    if (response.message === 'success') {
      // refresh course timeline and wait for new timeline data
      yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
      // since fetching course details removes class activities, we need to fetch class details again
      yield putResolve(fetchClassDetails.request({ classId: action.payload.classId }));
      yield put(editClassTimings.success(requestId, action.payload));
    } else {
      yield put(
        editClassTimings.failure(requestId, {
          reason: 'clash',
          clashingClasses: response.clashingClasses,
        })
      );
    }
  } catch (error) {
    log.error(error);
    yield put(editClassTimings.failure(requestId, { reason: 'unknown' }));
  }
}

function* classTimingsChangedEventWorker(event: ReturnType<typeof classTimingsChangedEvent>) {
  const { courseId } = event.payload;
  yield call(refreshTimelineWorker, courseId);
}

function* rescheduleClassWorker(action: ReturnType<typeof rescheduleClass.request>) {
  const { requestId } = action.meta;
  try {
    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    yield call(rescheduleClassAPI, action.payload);

    // refresh course timeline and wait for new timeline data
    yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
    // since fetching course details removes class activities, we need to fetch class details again
    yield putResolve(fetchClassDetails.request({ classId: action.payload.classId }));

    yield put(rescheduleClass.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(rescheduleClass.failure(requestId));
  }
}

function* cancelClassWorker(action: ReturnType<typeof cancelClass.request>) {
  const { requestId } = action.meta;
  try {
    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    yield call(cancelClassAPI, action.payload);

    // refresh course timeline and wait for new timeline data
    yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
    yield put(cancelClass.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(cancelClass.failure(requestId));
  }
}

function* editClassVenueWorker(action: ReturnType<typeof editClassVenue.request>) {
  const { requestId } = action.meta;
  try {
    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    yield call(editClassVenueAPI, action.payload);

    // refresh course timeline and wait for new timeline data
    yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
    // since fetching course details removes class activities, we need to fetch class details again
    yield putResolve(fetchClassDetails.request({ classId: action.payload.classId }));

    yield put(editClassVenue.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editClassVenue.failure(requestId));
  }
}

function* classVenueChangedEventWorker(event: ReturnType<typeof classVenueChangedEvent>) {
  const { courseId } = event.payload;
  yield call(refreshTimelineWorker, courseId);
}

function* classInChargeSetEventWorker(event: ReturnType<typeof classInChargeSetEvent>) {
  const { courseId, classId, multiple, teamMembers } = event.payload;

  if (multiple) {
    // fetching course details again for multiple class changes because pusher does not provide changed data for all changed classes
    yield putResolve(fetchCourseDetails.request({ courseId }));
    // since fetching course details removes class activities, we need to fetch class details again
    yield putResolve(fetchClassDetails.request({ classId }));
    return;
  }

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const isIncharge = teamMembers?.inCharges.some((incharge) => incharge.userId === currentUser.userId);

  if (isIncharge) {
    // fetching class details for new incharge
    yield putResolve(fetchClassDetails.request({ classId }));
    return;
  }

  yield put(classInchargeSet({ ...event.payload, currentUser }));
}

function* editClassTeamWorker(action: ReturnType<typeof editClassTeam.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const course: YieldSelectorType<typeof selectCurrentCourse> = yield select(selectCurrentCourse);
    if (!course) return;

    yield call(editClassTeamAPI, action.payload);

    const { editSimilarClasses } = action.payload;

    if (editSimilarClasses.incharges || editSimilarClasses.assistants) {
      // refresh course timeline and wait for new timeline data
      yield putResolve(fetchCourseDetails.request({ courseId: course.id }));
      // since fetching course details removes class activities, we need to fetch class details again
      yield putResolve(fetchClassDetails.request({ classId: action.payload.classId }));
    }

    yield put(editClassTeam.success(requestId, { ...action.payload, currentUser }));
  } catch (error) {
    log.error(error);
    yield put(editClassTeam.failure(requestId));
  }
}

function* classTeamEditedEventWorker(event: ReturnType<typeof classTeamEditedEvent>) {
  const { multiple, courseId, classId, teamMembers } = event.payload;

  if (multiple) {
    // refresh course timeline and wait for new timeline data
    yield putResolve(fetchCourseDetails.request({ courseId }));
    // since fetching course details removes class activities, we need to fetch class details again
    yield putResolve(fetchClassDetails.request({ classId }));
    return;
  }

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  const isIncharge = teamMembers?.inCharges.some((incharge) => incharge.userId === currentUser.userId);

  if (isIncharge) {
    // fetching class details for new incharge
    yield putResolve(fetchClassDetails.request({ classId }));
    return;
  }

  yield put(classTeamEdited({ ...event.payload, currentUser }));
}

function* deleteClassWorker(action: ReturnType<typeof deleteClass.request>) {
  const { requestId } = action.meta;
  try {
    yield call(deleteClassAPI, action.payload);
    yield put(deleteClass.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(deleteClass.failure(requestId));
  }
}

function* fetchClassDetailsWorker(action: ReturnType<typeof fetchClassDetails.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const courseId: YieldSelectorType<typeof selectCurrentCourseId> = yield select(selectCurrentCourseId);
    if (!courseId) return;

    yield put(openClass(action.payload));
    const response: YieldCallType<typeof getClassDetails> = yield call(getClassDetails, action.payload);
    yield put(
      fetchClassDetails.success(requestId, {
        ...response,
        classId,
        courseId,
        currentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchClassDetails.failure(requestId));
  }
}

function* startClassWorker(action: ReturnType<typeof startClass.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const cls: YieldSelectorType<typeof selectClass> = yield select(selectClass);
    if (!cls) return;

    yield put(
      startClass.success(requestId, {
        classId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(startClass.failure(requestId));
  }
}

function* endClassWorker(action: ReturnType<typeof endClass.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const cls: YieldSelectorType<typeof selectClass> = yield select(selectClass);
    if (!cls) return;

    yield put(
      endClass.success(requestId, {
        classId,
        actualEndTime: unix(),
        recordings: undefined,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(endClass.failure(requestId));
  }
}

function* checkInToClassWorker(action: ReturnType<typeof checkInToClass.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof checkInToClassAPI> = yield call(checkInToClassAPI, action.payload);
    yield put(
      checkInToClass.success(requestId, {
        classId: action.payload.classId,
        checkInTime: response.checkInTime,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(checkInToClass.failure(requestId));
  }
}

function* classCheckInEventWorker(event: ReturnType<typeof classCheckInEvent>) {
  yield put(checkedInToClass(event.payload));
}

function* editClassAgendaWorker(action: ReturnType<typeof editClassAgenda.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editClassAgendaAPI, action.payload);
    yield put(editClassAgenda.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editClassAgenda.failure(requestId));
  }
}

function* editClassTopicsWorker(action: ReturnType<typeof editClassTopics.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editClassTopicsAPI, action.payload);
    yield put(editClassTopics.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editClassTopics.failure(requestId));
  }
}

function* fetchClassSummaryWorker(action: ReturnType<typeof fetchClassSummary.request>) {
  const { requestId } = action.meta;
  try {
    const response: YieldCallType<typeof getClassSummary> = yield call(getClassSummary, action.payload);
    yield put(
      fetchClassSummary.success(requestId, {
        ...response,
        ...action.payload,
        fetchedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchClassSummary.failure(requestId));
  }
}

function* editClassSummaryWorker(action: ReturnType<typeof editClassSummary.request>) {
  const { requestId } = action.meta;
  try {
    const { links: linkIds, ...payload } = action.payload;
    const links: YieldSelectorType<typeof selectCourseLinks> = yield select(selectCourseLinks(linkIds));
    const classSummaryLinks = links.flatMap((link) => {
      if (!link) return [];
      const { id, title, url } = link;
      return [{ linkId: id, title, url }];
    });

    yield call(editClassSummaryAPI, { ...payload, links: classSummaryLinks });
    yield put(
      editClassSummary.success(requestId, {
        ...action.payload,
        updatedOn: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(editClassSummary.failure(requestId));
  }
}

function* fetchClassParticipationsWorker(action: ReturnType<typeof fetchClassParticipations.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const response: YieldCallType<typeof getClassParticipations> = yield call(
      getClassParticipations,
      action.payload
    );
    yield put(
      fetchClassParticipations.success(requestId, {
        ...response,
        classId,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchClassParticipations.failure(requestId));
  }
}

function* awardClassParticipationPointsWorker(
  action: ReturnType<typeof awardClassParticipationPoints.request>
) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser || currentUser.role === CourseRole.STUDENT) return;

    const { newLabel }: YieldCallType<typeof awardClassParticipationPointsAPI> = yield call(
      awardClassParticipationPointsAPI,
      action.payload
    );
    yield put(
      awardClassParticipationPoints.success(requestId, {
        ...action.payload,
        isNewLabel: Boolean(newLabel),
        currentUser: currentUser as CourseTeamUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(awardClassParticipationPoints.failure(requestId));
  }
}

function* scheduleAttendanceWorker(action: ReturnType<typeof scheduleAttendance.request>) {
  const { requestId } = action.meta;
  try {
    yield call(scheduleAttendanceAPI, action.payload);
    yield put(scheduleAttendance.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(scheduleAttendance.failure(requestId));
  }
}

function* attendanceScheduledToStartEventWorker(event: ReturnType<typeof attendanceScheduledToStartEvent>) {
  yield put(attendanceScheduledToStart(event.payload));
}

function* editAttendanceScheduleWorker(action: ReturnType<typeof editAttendanceSchedule.request>) {
  const { requestId } = action.meta;
  try {
    yield call(editAttendanceScheduleAPI, action.payload);
    yield put(editAttendanceSchedule.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(editAttendanceSchedule.failure(requestId));
  }
}

function* fetchClassAttendanceWorker(action: ReturnType<typeof fetchClassAttendance.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser) return;

    const response: YieldCallType<typeof getClassAttendance> = yield call(getClassAttendance, action.payload);
    yield put(
      fetchClassAttendance.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: currentUser as CourseTeamUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchClassAttendance.failure(requestId));
  }
}

function* fetchMyAttendanceStatusWorker(action: ReturnType<typeof fetchMyAttendanceStatus.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser || currentUser.role !== CourseRole.STUDENT) return;

    const response: YieldCallType<typeof getMyAttendanceStatus> = yield call(
      getMyAttendanceStatus,
      action.payload
    );

    yield put(
      fetchMyAttendanceStatus.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: currentUser as CourseStudentUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchMyAttendanceStatus.failure(requestId));
  }
}

function* startProxyAttendanceWorker(action: ReturnType<typeof startProxyAttendance.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const classUser: YieldSelectorType<typeof selectClassUser> = yield select(selectClassUser(classId));
    if (!classUser) return;

    const isInClassTeam = [ClassRole.INCHARGE, ClassRole.ASSISTANT].includes(classUser.role);
    if (!isInClassTeam) return;

    yield put(clearLocalAttendanceData());

    const response: YieldCallType<typeof startProxyAttendanceAPI> = yield call(
      startProxyAttendanceAPI,
      action.payload
    );

    yield put(
      startProxyAttendance.success(requestId, {
        ...action.payload,
        ...response,
        currentUser: classUser as ClassTeamUser,
      })
    );
  } catch (error) {
    log.error(error);
    yield put(startProxyAttendance.failure(requestId));
  }
}

function* stopProxyAttendanceWorker(action: ReturnType<typeof stopProxyAttendance.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const classUser: YieldSelectorType<typeof selectClassUser> = yield select(selectClassUser(classId));
    if (!classUser) return;

    const isInClassTeam = [ClassRole.INCHARGE, ClassRole.ASSISTANT].includes(classUser.role);
    if (!isInClassTeam) return;

    yield call(stopProxyAttendanceAPI, action.payload);
    yield put(stopProxyAttendance.success(requestId, action.payload));

    // refresh attendance data
    yield put(fetchClassAttendance.request({ classId }));
  } catch (error) {
    log.error(error);
    yield put(stopProxyAttendance.failure(requestId));
  }
}

function* fetchAttendanceRespondersWorker(action: ReturnType<typeof fetchAttendanceResponders.request>) {
  const { requestId } = action.meta;
  const { classId, courseId } = action.payload;

  try {
    const response: YieldCallType<typeof getAttendanceResponders> = yield call(getAttendanceResponders, {
      classId,
      courseId,
    });

    yield put(
      fetchAttendanceResponders.success(requestId, {
        ...response,
        classId,
        courseId,
        timestamp: unix(),
      })
    );
  } catch (error) {
    log.error(error);
    yield put(fetchAttendanceResponders.failure(requestId));
  }
}

function* attendeeAvailableEventWorker(event: ReturnType<typeof attendeeAvailableEvent>) {
  yield put(attendeeAvailable(event.payload));
}

function* attendeeFailureEventWorker(event: ReturnType<typeof attendeeFailureEvent>) {
  yield put(attendeeFailure(event.payload));
}

function* attendanceMarkedEventWorker(event: ReturnType<typeof attendanceMarkedEvent>) {
  yield put(attendanceMarked(event.payload));
}

function* showAttendanceWarningWorker(event: ReturnType<typeof attendanceWarningEvent>) {
  const { action, classId, courseId, attendanceTime } = event.payload;
  if (action === AttendanceWarningAction.HIDE_WARNING) return;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  if (currentUser.role === CourseRole.STUDENT) {
    yield call(acknowledgeAttendanceStarted, {
      device: 'browser',
      notification: 'pusher',
      event: 'attendanceStarted',
      courseId,
      classId,
      startTime: attendanceTime,
    });
  }
  yield put(proxyAttendanceStarted(event.payload));
}

function* hideAttendanceWarningWorker(event: ReturnType<typeof attendanceWarningEvent>) {
  const { action, classId } = event.payload;
  if (action === AttendanceWarningAction.SHOW_WARNING) return;

  const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
  if (!currentUser) return;

  yield put(proxyAttendanceStopped(event.payload));

  if (currentUser.role === CourseRole.STUDENT) {
    yield put(fetchMyAttendanceStatus.request({ classId }));
    return;
  }
  yield put(fetchClassAttendance.request({ classId }));
}

function* attendanceWarningEventWorker(event: ReturnType<typeof attendanceWarningEvent>) {
  const { action } = event.payload;

  if (action === AttendanceWarningAction.HIDE_WARNING) {
    yield call(hideAttendanceWarningWorker, event);
  } else {
    yield call(showAttendanceWarningWorker, event);
  }
}

function* editClassAttendanceWorker(action: ReturnType<typeof editClassAttendance.request>) {
  const { requestId } = action.meta;
  const { classId } = action.payload;
  try {
    const classUser: YieldSelectorType<typeof selectClassUser> = yield select(selectClassUser(classId));
    if (!classUser) return;

    const isInClassTeam = [ClassRole.INCHARGE, ClassRole.ASSISTANT].includes(classUser.role);
    if (!isInClassTeam) return;

    yield call(editClassAttendanceAPI, action.payload);
    yield put(editClassAttendance.success(requestId, action.payload));

    // refresh attendance data
    yield put(fetchClassAttendance.request({ classId }));
  } catch (error) {
    log.error(error);
    yield put(editClassAttendance.failure(requestId));
  }
}

function* attendanceEditedEventWorker(event: ReturnType<typeof attendanceEditedEvent>) {
  const { classId, sender } = event.payload;
  try {
    const classUser: YieldSelectorType<typeof selectClassUser> = yield select(selectClassUser(classId));
    if (!classUser) return;

    const attendancePage: YieldCallType<typeof routes.attendance.match> = yield call(routes.attendance.match);
    const { classShortId } = attendancePage?.params || {};

    if (!classShortId || !classId.endsWith(classShortId)) return;

    // refresh attendance data
    yield put(fetchClassAttendance.request({ classId }));

    if (sender.userId !== classUser.userId) {
      // TODO: notify user that attendance has been updated
    }
  } catch (error) {
    log.error(error);
  }
}

function* fetchClassesToCopyActivityWorker(action: ReturnType<typeof fetchClassesToCopyActivity.request>) {
  const { requestId } = action.meta;
  try {
    const currentUser: YieldSelectorType<typeof selectCourseUser> = yield select(selectCourseUser());
    if (!currentUser || currentUser.role === CourseRole.STUDENT) return;

    const response: YieldCallType<typeof getClassesToCopyActivity> = yield call(
      getClassesToCopyActivity,
      action.payload
    );

    const classes: FetchClassesToCopyActivitySuccessPayload['classes'] = [];

    for (const cls of response.classes) {
      const team = getClassTeam(cls.details, cls.info);
      const myRole = getClassRole(team, currentUser);

      const comments: Class['comments'] = {
        total: cls.activities?.numCommentsTotal ?? 0,
        seen: 0,
        isSubscribed: false,
      };

      classes.push({
        id: cls._id,
        timing: getClassTiming(cls.details),
        title: cls.details.title || null,
        isOnline: Boolean(cls.details.isOnlineMeeting),
        myRole,
        activities: getClassActivities(myRole, cls.activities),
        sequenceNum: cls.details.trueNum,
        status: getClassStatus({
          status: cls.details.status,
          scheduledStartTime: cls.details.scheStartTime,
          scheduledEndTime: cls.details.scheEndTime,
        }),
        team,
        comments,
      });
    }

    yield put(fetchClassesToCopyActivity.success(requestId, { classes }));
  } catch (error) {
    log.error(error);
    yield put(fetchClassesToCopyActivity.failure(requestId));
  }
}

function* moveActivityWorker(action: ReturnType<typeof moveActivity.request>) {
  const { requestId } = action.meta;
  try {
    const {
      activityId,
      activityType,
      sourceClassId,
      destinationClassId,
      sourceToBeDone,
      destinationToBeDone,
    } = action.payload;
    const payload: API.MoveActivityRequest = {
      activityId,
      type: activityType,
      classId: sourceClassId,
      newClassId: destinationClassId,
      subType: sourceToBeDone,
      newSubType: destinationToBeDone,
    };

    const currentCourseId: YieldSelectorType<typeof selectCurrentCourseId> = yield select(
      selectCurrentCourseId
    );
    if (!currentCourseId) return;

    yield call(moveActivityAPI, payload);

    /** fetch course details for changing activity counts in each class */
    yield putResolve(fetchCourseDetails.request({ courseId: currentCourseId }));
    /** fetching course details removes class activities, so fetching them again */
    yield putResolve(fetchClassDetails.request({ classId: sourceClassId }));

    yield put(moveActivity.success(requestId));

    /** navigate user to class page after successful move */

    const { match }: YieldCallType<typeof getAcadlyRouteByURL> = yield call(
      getAcadlyRouteByURL,
      window.location.pathname
    );
    if (!match?.params) return;
    routes.activities.navigate(match.params as ClassParams);
  } catch (error) {
    log.error(error);
    yield put(moveActivity.failure(requestId));
  }
}

export default function* rootClassesSagas() {
  try {
    yield takeLatest(createClass.request, createClassWorker);
    yield takeEveryPusher(classCreatedEvent, classCreatedEventWorker);

    yield takeLatest(editClassTitle.request, editClassTitleWorker);

    yield takeLatest(editClassTimings.request, editClassTimingsWorker);
    yield takeEveryPusher(classTimingsChangedEvent, classTimingsChangedEventWorker);

    yield takeLatest(rescheduleClass.request, rescheduleClassWorker);
    yield takeLatest(cancelClass.request, cancelClassWorker);

    yield takeLatest(editClassVenue.request, editClassVenueWorker);
    yield takeEveryPusher(classVenueChangedEvent, classVenueChangedEventWorker);

    yield takeEveryPusher(classInChargeSetEvent, classInChargeSetEventWorker);

    yield takeLatest(editClassTeam.request, editClassTeamWorker);
    yield takeEveryPusher(classTeamEditedEvent, classTeamEditedEventWorker);
    yield takeLatest(deleteClass.request, deleteClassWorker);
    yield takeLatest(fetchClassDetails.request, fetchClassDetailsWorker);

    yield takeLatest(startClass.request, startClassWorker);
    yield takeLatest(endClass.request, endClassWorker);

    yield takeLatest(checkInToClass.request, checkInToClassWorker);
    yield takeEveryPusher(classCheckInEvent, classCheckInEventWorker);

    yield takeLatest(editClassAgenda.request, editClassAgendaWorker);
    yield takeLatest(editClassTopics.request, editClassTopicsWorker);
    yield takeLatest(fetchClassSummary.request, fetchClassSummaryWorker);
    yield takeLatest(editClassSummary.request, editClassSummaryWorker);

    yield takeLatest(fetchClassParticipations.request, fetchClassParticipationsWorker);
    yield takeLatest(awardClassParticipationPoints.request, awardClassParticipationPointsWorker);

    yield takeLatest(scheduleAttendance.request, scheduleAttendanceWorker);
    yield takeEveryPusher(attendanceScheduledToStartEvent, attendanceScheduledToStartEventWorker);
    yield takeLatest(editAttendanceSchedule.request, editAttendanceScheduleWorker);
    yield takeLatest(fetchClassAttendance.request, fetchClassAttendanceWorker);
    yield takeLatest(fetchMyAttendanceStatus.request, fetchMyAttendanceStatusWorker);
    yield takeLatest(startProxyAttendance.request, startProxyAttendanceWorker);
    yield takeLatest(stopProxyAttendance.request, stopProxyAttendanceWorker);
    yield takeLatest(fetchAttendanceResponders.request, fetchAttendanceRespondersWorker);
    yield takeEveryPusher(attendeeAvailableEvent, attendeeAvailableEventWorker);
    yield takeEveryPusher(attendeeFailureEvent, attendeeFailureEventWorker);
    yield takeEveryPusher(attendanceMarkedEvent, attendanceMarkedEventWorker);
    yield takeEveryPusher(attendanceWarningEvent, attendanceWarningEventWorker);
    yield takeLatest(editClassAttendance.request, editClassAttendanceWorker);
    yield takeEveryPusher(attendanceEditedEvent, attendanceEditedEventWorker);

    yield takeLatest(fetchClassesToCopyActivity.request, fetchClassesToCopyActivityWorker);
    yield takeLatest(moveActivity.request, moveActivityWorker);
  } catch (error) {
    log.error(error);
  }
}
