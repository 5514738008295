import CircularProgressbar, { Props as CircularProgressbarProps } from './CircularProgressbar';
import LinearProgressbar, { Props as LinearProgressbarProps } from './LinearProgressbar';

export type Props = CircularProgressbarProps | LinearProgressbarProps;

const Progressbar = (props: Props) => {
  if (props.shape === 'circular') {
    return <CircularProgressbar {...props} />;
  }

  return <LinearProgressbar {...props} />;
};

export default Progressbar;
