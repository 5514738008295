import { useSelector } from 'react-redux';

import { selectAttendanceInProgress } from '../../../../../db/classes/selectors';
import { AttendanceProcessFailure } from '../../../../../db/classes/types';
import { getSecondsPassed } from './helper';

export interface VMProps {
  failure: AttendanceProcessFailure;
}

export default function useAttendanceProcessErrorLayoutVM({ failure }: VMProps) {
  const attendanceInProgress = useSelector(selectAttendanceInProgress);

  const secondsPassed = getSecondsPassed({ failure, attendanceInProgress });

  return {
    secondsPassed,
  };
}
