import { KeyboardArrowDown as DropdownArrowIcon } from '@mui/icons-material';
import { Autocomplete, autocompleteClasses, AutocompleteProps, List, listClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Props as TextFieldBaseProps } from '../TextFieldBase';
import { Props as VMProps } from './vm';

export const dropdownClasses = {
  ...autocompleteClasses,
};

export type Props<
  OptionType,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> = VMProps &
  DistributiveOmit<
    AutocompleteProps<OptionType, Multiple, DisableClearable, FreeSolo>,
    'size' | 'renderInput' | 'classes'
  > &
  Pick<
    TextFieldBaseProps,
    'label' | 'required' | 'size' | 'error' | 'helperIcon' | 'helperText' | 'fullWidth' | 'disabled'
  > & {
    classes?: Partial<Record<'root' | 'textFieldRoot' | 'popupIcon', string> & TextFieldBaseProps['classes']>;
  };

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${dropdownClasses.tag}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${dropdownClasses.endAdornment}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${dropdownClasses.clearIndicator}`]: {
    color: theme.palette.grey[600],
  },
})) as typeof Autocomplete;

export const PopupIcon = styled(DropdownArrowIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const Listbox = styled(List)(({ theme }) => ({
  /** used for increasing css specificity */
  [`&.${listClasses.root}`]: {
    [`.${dropdownClasses.option}`]: {
      color: theme.palette.grey[800],
      [`&.${autocompleteClasses.focused}`]: {
        backgroundColor: theme.palette.grey[200],
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.primary[600],
        color: theme.palette.primary.contrastColors[600],
      },
      [`&.${dropdownClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.primary[600],
        color: theme.palette.primary.contrastColors[600],
      },
      '&[aria-disabled="true"]': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.grey[400],
      },
    },
  },
}));
