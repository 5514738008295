import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import AttachmentView from '../../../../../../../components/AttachmentView';
import ImagePreviewDialog from '../../../../../../../components/ImagePreviewDialog';
import RichTextView from '../../../../../../../components/RichText/View';
import { AttachmentType } from '../../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../../i18n';
import CommentCreator from './CommentCreator';
import CommentStatsDialog from './CommentStatsDialog';
import {
  Actions,
  commentClasses,
  ImageButton,
  RemovedCommentContent,
  Root,
  StyledCommentStatsButton,
  StyledImage,
} from './styles';
import useCommentVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  showCreator: boolean;
  className?: string;
}

const Comment = ({ commentId, showCreator, className }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS]);
  const {
    comment,
    attachment,
    handleCommentClick,
    isCommentStatsDialogOpen,
    handleCloseStatsDialog,
    handleStatsClick: _handleStatsClick,
    selectedCommentImagePreviewUrl,
    isImagePreviewDialogOpen,
    handleCloseImagePreviewDialog,
    handleImageClick: _handleImageClick,
  } = useCommentVM({ commentId });

  const handleStatsClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    _handleStatsClick();
  };

  const handleImageClick =
    (previewUrl: string): MouseEventHandler =>
    (event) => {
      event.stopPropagation();
      _handleImageClick(previewUrl);
    };

  if (!comment) return null;

  return (
    <>
      <Root
        role="button"
        tabIndex={0}
        className={clsx({ [commentClasses.clickable]: !comment.isDeleted }, className)}
        onClick={comment.isDeleted ? undefined : handleCommentClick}
      >
        {showCreator && <CommentCreator {...comment.createdBy} createdOn={comment.createdOn} />}
        <Actions
          commentId={comment.id}
          onAwardClick={handleCommentClick}
          className={clsx(commentClasses.actions, { [commentClasses.alignActionsWithCreator]: showCreator })}
        />
        {comment.isDeleted ? (
          <RemovedCommentContent>{comment.message}</RemovedCommentContent>
        ) : (
          <>
            <RichTextView richtext={comment.message} />
            {attachment?.type === AttachmentType.IMAGE && (
              <ImageButton onClick={handleImageClick(attachment.imageURL)}>
                <StyledImage src={attachment.thumbURL} alt="attachment" />
              </ImageButton>
            )}
            {attachment?.type === AttachmentType.FILE && (
              <AttachmentView
                attachment={{
                  originalName: attachment.originalName,
                  name: attachment.name,
                  extension: attachment.extension,
                }}
                requestConfig={attachment.requestConfig}
                color="error"
              />
            )}
            <StyledCommentStatsButton
              commentId={commentId}
              edge="start"
              aria-label={t('this_comment_has_num_likes_num_thanks_and_num_awards_click_to_see_details', {
                ns: i18nNS.COMMENTS,
                numLikes: comment.stats.likes,
                numThanks: comment.stats.thanks,
                numAwards: comment.stats.awards,
              })}
              onClick={handleStatsClick}
            />
          </>
        )}
      </Root>
      <CommentStatsDialog
        open={isCommentStatsDialogOpen}
        onClose={handleCloseStatsDialog}
        commentId={commentId}
      />
      {selectedCommentImagePreviewUrl && (
        <ImagePreviewDialog
          open={isImagePreviewDialogOpen}
          onClose={handleCloseImagePreviewDialog}
          src={selectedCommentImagePreviewUrl}
          alt={t('image_preview', { ns: i18nNS.COMMENTS })}
        />
      )}
    </>
  );
};

export default Comment;
