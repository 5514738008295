import { useTranslation } from 'react-i18next';

import { ReactComponent as ZoomUsIcon } from '../../assets/zoom-us-round.svg';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import ZoomIntegrationDialog from '../../online-meeting/ZoomIntegrationDialog';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import useZoomIntegrationVM from './vm';

const ZoomIntegration = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  const { isZoomIntegrationDialogOpen, handleCloseZoomIntegrationDialog, handleZoomIntegration } =
    useZoomIntegrationVM();

  return (
    <>
      <ListItemButton
        onClick={handleZoomIntegration}
        aria-label={t('click_to_know_zoom_integration_status_and_to_make_changes_to_it', {
          ns: i18nNS.SETTINGS,
        })}
        aria-haspopup
      >
        <ListItemIcon>
          <ZoomUsIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('zoom_integration', { ns: i18nNS.COMMON })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <ZoomIntegrationDialog open={isZoomIntegrationDialogOpen} onClose={handleCloseZoomIntegrationDialog} />
    </>
  );
};

export default ZoomIntegration;
