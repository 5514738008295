import { TransitionGroup } from 'react-transition-group';

import Drawer from '../../../components/Drawer';
import { drawerClasses } from '../../../components/Drawer/styles';
import CoursePaymentProvider from './Context';
import CouponCode from './CouponCode';
import CoursePaymentHeader from './Header';
import Payment from './Payment';
import PaymentFailed from './PaymentFailed';
import PaymentSuccess from './PaymentSuccess';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CoursePaymentDrawer = ({ open, onClose }: Props) => {
  return (
    <CoursePaymentProvider onClose={onClose}>
      <Drawer
        dismissal="none"
        anchor="right"
        open={open}
        onClose={onClose}
        header={(titleId) => <CoursePaymentHeader id={titleId} />}
      >
        {({ bodyId }) => (
          <TransitionGroup
            id={bodyId}
            className={drawerClasses.body}
            component="div"
            style={{ position: 'relative' }}
          >
            <CouponCode />
            <Payment />
            <PaymentFailed />
            <PaymentSuccess />
          </TransitionGroup>
        )}
      </Drawer>
    </CoursePaymentProvider>
  );
};

export default CoursePaymentDrawer;
