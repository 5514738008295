const en = {
  acadly_home: 'Acadly - Home',

  /* No courses screen */
  you_don_t_have_any_live_courses_yet_tap_on_the_plus_button_at_the_bottom_right_to_create_a_course:
    "You don't have any live courses yet. Tap on the <1>+</1><2>button</2> at the bottom right to create a course.",

  /* Fab button */
  click_to_add_courses: 'Click to add courses',
  join_an_existing_course: 'Join an existing course',

  /* home header */
  click_to_learn_more_about_verification: 'click to learn more about verification',
  click_to_learn_about_new_updates: 'click to learn about new updates',
  we_are_in_the_process_of_verifying_you_as_a_faculty_member_at_university:
    'We are in the process of verifying you as a faculty member at {{university}}',
  i_m_a_student: 'I’m a student',
  verification_pending: 'Verification Pending',
  since_acadly_deals_with_student_data_to_meet_with_the_data_security_compliance_requirements_we_ensure_that_every_instructor_account_actually_belongs_to_a_university_faculty_member_or_a_university_staff_or_a_ta:
    'Since Acadly deals with student data, to meet with the data security compliance requirements, we ensure that every instructor account actually belongs to a university faculty member, or a university staff, or a TA.',
  this_verification_process_takes_at_most_two_four_hours_and_till_then_users_are_provided_with_a_trial_account_to_try_acadly_out_with_demo_courses:
    'This verification process takes at most 24 hours and till then, users are provided with a trial account to try Acadly out with demo courses.',
  in_case_you_re_a_student_who_has_accidentally_signed_up_for_an_instructor_account_please_click_on_i_m_a_student_button_we_ll_remove_you_from_the_verification_list_and_you_ll_be_able_to_continue_using_acadly_as_a_student:
    'In case you’re a student who has accidentally signed up for an instructor account, please click on “I’m a student” button. We’ll remove you from the verification list and you’ll be able to continue using Acadly as a student.',

  view_archived_courses: 'View archived courses',
  click_to_view_archived_course: 'click to view archived course',
};

export default en;
