import { Stack, styled } from '@mui/material';

import { forMobile } from '../utils/media-queries';

export const Root = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(5, 0),
  [forMobile(theme)]: {
    padding: theme.spacing(4),
  },
}));

export const Field = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));

export const AddOptionWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 5),
}));
