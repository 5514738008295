import { API } from '../shared/api-responses';
import { NewFeatureSummary } from './types';

interface CreateFeatureUpdatesOption {
  featureName: string;
  summary: API.NewFeatureSummary;
}

export function featureUpdatesFactory({
  featureName,
  summary,
}: CreateFeatureUpdatesOption): NewFeatureSummary {
  return {
    featureName,
    isBlog: Boolean(summary.blog),
    isSeen: Boolean(summary.seen),
    message: summary.message,
    redirectUrl: summary.redirectUrl,
    releasedOn: summary.releasedOn || -1,
    showTo: summary.showTo,
    title: summary.title,
    viewMoreLabel: summary.viewMoreLabel,
  };
}
