import { createPusherEvent } from '../../pusher/helpers';
import { CoursePusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';
import {
  CommentContext,
  CommentSubContext,
  CourseTeamUser,
  MarkCommentAction,
  RateCommentAction,
} from '../shared/types';

export type CommentCreatedEvent = CoursePusherEvent<{
  classId?: MongoId;
  commentId: MongoId;
  context: CommentContext;
  contextId: MongoId;
  subContext: CommentSubContext<CommentContext>;
  details: API.CommentDetails;
  stats: API.Comment['stats'];
  /** ignore this event, if current user is in course-team and shouldTeamIgnore is set to 1 */
  shouldTeamIgnore: NumericBoolean;
}>;

export const commentCreatedEvent = createPusherEvent<CommentCreatedEvent>(PusherEventName.COMMENT_ADDED, {
  channels: [PusherChannel.COURSE, PusherChannel.COURSE_TEAM],
});

/**
 * We receive this event only when user likes or thanks
 * the comment, not when user undo their action i.e. if
 * a user undo a like we will not receive the pusher.
 */
export type CommentMarkedEvent = CoursePusherEvent<{
  commentId: MongoId;
  marked: MarkCommentAction.THANK | MarkCommentAction.LIKE;
}>;

export type CommentRatedEvent = CoursePusherEvent<{
  commentId: MongoId;
  marked: 'awarded';
  action: RateCommentAction;
  points: number;
  sender: CourseTeamUser;
}>;

export type CommentRatedOrMarkedPayload = CoursePusherEvent<CommentMarkedEvent | CommentRatedEvent>;

export const commentMarkedEvent = createPusherEvent<CommentRatedOrMarkedPayload>(
  PusherEventName.COMMENT_MARKED,
  {
    channels: [
      /**
       * Will be received here only when comment context is discussion and
       * discussionPrefs.hideAwards is set to `true`
       */
      PusherChannel.USER,
      PusherChannel.COURSE,
      /**
       * Will be received here only when comment context is discussion and
       * discussionPrefs.hideAwards is set to `true`
       */
      PusherChannel.COURSE_TEAM,
    ],
  }
);

export type CommentDeletedPayload = CoursePusherEvent<{
  commentId: MongoId;
  context: CommentContext;
  contextId: MongoId;
  subContext: CommentSubContext<CommentContext>;
}>;

export const commentDeletedEvent = createPusherEvent<CommentDeletedPayload>(PusherEventName.COMMENT_REMOVED, {
  channels: [PusherChannel.COURSE],
});
