import { useContext, useRef } from 'react';

import { LayoutContext } from '../../Context';

const useLeftActionsVM = () => {
  const { setIsCommentsPanelOpen, isCommentsPanelExpanded, setIsCommentsPanelExpanded } =
    useContext(LayoutContext);

  const expandButtonRef = useRef<HTMLButtonElement>(null);

  const toggleExpand = () => {
    setIsCommentsPanelExpanded((prevVal) => !prevVal);
    // waiting for drawer shrinking animation
    // NOTE 1: do not use async/await as it disables button till settimeout. After timeout, there will be a race between enabling button and setting focus. In almost all cases focus wins the race, so it tries to focus the button before enabling it.
    // NOTE 2: do not return this promise as it will disable the button till settimeout is executed and we will get same race condition as previous note.
    new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
      expandButtonRef.current?.focus();
    });
  };

  const handleClose = () => {
    setIsCommentsPanelOpen(false);
    setIsCommentsPanelExpanded(false);
  };

  return { expandButtonRef, isCommentsPanelExpanded, toggleExpand, handleClose };
};

export default useLeftActionsVM;
