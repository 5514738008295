import { useState } from 'react';

const useManageNotificationsVM = () => {
  const [isManageNotificationsDrawerOpen, setIsManageNotificationsDrawerOpen] = useState(false);

  const handleCloseManageNotificationsDrawer = () => {
    setIsManageNotificationsDrawerOpen(false);
  };

  const handleManageNotifications = () => {
    setIsManageNotificationsDrawerOpen(true);
  };

  return { isManageNotificationsDrawerOpen, handleCloseManageNotificationsDrawer, handleManageNotifications };
};

export default useManageNotificationsVM;
