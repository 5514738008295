import { useTranslation } from 'react-i18next';

import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { i18nNS } from '../../i18n';

const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  const handleClose: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    closeToast();
  };

  return (
    <IconButton
      edge="end"
      size="small"
      color="error"
      sx={{ color: (theme) => theme.palette.common.white, marginTop: 1 }}
      onClick={handleClose}
      aria-label={t('click_to_close_notification', { ns: i18nNS.COMMON })}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseButton;
