import { AriaAttributes, Children, isValidElement, ReactNode, useContext, useRef } from 'react';

import { Popover as MuiPopover, popoverClasses, styled } from '@mui/material';
import { Fade } from '@mui/material';

import clsx from 'clsx';

import PlusIcon from '../../icons/PlusIcon';
import { pick } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';
import ChildFabMenu from './ChildFabMenu';
import FabMenuContext from './Context';
import Fab from './Fab';

export const hamburgerMenuClasses = {
  ...pick(popoverClasses, 'paper', 'root'),
};

export const Popover = styled(MuiPopover)(({ theme }) => ({
  [`& .${hamburgerMenuClasses.paper}`]: {
    boxShadow: theme.acadlyShadows.lg,
  },
}));

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
  overflow: 'hidden',
  borderRadius: 4,
  padding: theme.spacing(1),
}));

const FabButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(7),
  bottom: theme.spacing(7),
  zIndex: theme.zIndex.fab,

  [forMobile(theme)]: {
    right: theme.spacing(1),
    bottom: theme.spacing(1),
  },
}));

const FabIcon = styled(PlusIcon)(({ theme }) => ({
  transform: 'rotate(0deg)',
  transition: theme.transitions.create(['transform'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  '&.opened': {
    transform: 'rotate(135deg)',
  },
}));

interface Props extends AriaAttributes {
  className?: string;
  children: ReactNode;
  icon?: ReactNode;
}

const FabMenuWrapper = ({ className, children, icon, ...props }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { isOpen, open, close } = useContext(FabMenuContext);

  const renderableChildren = Children.map(children, (child) => {
    if (isValidElement(child) && child.type === ChildFabMenu) return child;
    return null;
  });

  return (
    <>
      <Popover
        anchorEl={ref.current}
        onClose={close}
        open={isOpen}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        tabIndex={-1}
      >
        <Fade in={isOpen}>
          <Root id="fab_menu" tabIndex={-1}>
            {renderableChildren}
          </Root>
        </Fade>
      </Popover>
      <FabButton
        {...props}
        ref={ref}
        className={className}
        disableOutline={isOpen}
        onClick={isOpen ? close : open}
        aria-expanded={isOpen ? true : false}
        aria-controls={isOpen ? 'fab_menu' : undefined}
      >
        {icon || <FabIcon className={clsx({ opened: isOpen })} aria-hidden />}
      </FabButton>
    </>
  );
};

export default FabMenuWrapper;
