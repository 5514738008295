import { call, put, takeLatest } from 'redux-saga/effects';

import Logger from '../../utils/logger';
import { addCourseLink, fetchCourseLinks, removeCourseLink } from './actions';
import {
  addCourseLink as addCourseLinkAPI,
  getCourseLinks,
  removeCourseLink as removeCourseLinkAPI,
} from './api';

const log = Logger.create('db/links');

function* fetchCourseLinksWorker(action: ReturnType<typeof fetchCourseLinks.request>) {
  const { requestId } = action.meta;
  try {
    const links: YieldCallType<typeof getCourseLinks> = yield call(getCourseLinks, action.payload);
    yield put(fetchCourseLinks.success(requestId, { links }));
  } catch (error) {
    log.error(error);
    yield put(fetchCourseLinks.failure(requestId));
  }
}

function* addCourseLinkWorker(action: ReturnType<typeof addCourseLink.request>) {
  const { requestId } = action.meta;
  try {
    const link: YieldCallType<typeof addCourseLinkAPI> = yield call(addCourseLinkAPI, action.payload);
    yield put(addCourseLink.success(requestId, { link, parent: action.payload.parent }));
  } catch (error) {
    log.error(error);
    yield put(addCourseLink.failure(requestId));
  }
}

function* removeCourseLinkWorker(action: ReturnType<typeof removeCourseLink.request>) {
  const { requestId } = action.meta;
  try {
    yield call(removeCourseLinkAPI, action.payload);
    yield put(removeCourseLink.success(requestId, action.payload));
  } catch (error) {
    log.error(error);
    yield put(removeCourseLink.failure(requestId));
  }
}

export default function* rootLinksSaga() {
  try {
    yield takeLatest(fetchCourseLinks.request, fetchCourseLinksWorker);
    yield takeLatest(addCourseLink.request, addCourseLinkWorker);
    yield takeLatest(removeCourseLink.request, removeCourseLinkWorker);
  } catch (error) {
    log.error(error);
  }
}
