import Joi from 'joi';

import { EnrollmentBy } from '../../../db/shared/types';
import { requiredOneOption } from '../../../utils/validators';
import { FormValues } from './types';

const validationSchema = Joi.object<FormValues, true>({
  enrollmentBy: requiredOneOption({ options: Object.values(EnrollmentBy) }),
});

export default validationSchema;
