import { ReactNode } from 'react';

import { Divider } from '@mui/material';

import Typography from '../../../../components/Typography';
import FormatMenu from '../FormatMenu';
import { FormulaTranslations, formulaTranslations } from '../i18n';
import { Formatter, FormulaEditor, Preview, Root } from './styles';
import useCreateFormulaOverlay, { VMProps } from './vm';

interface OverlayProps {
  isOpen: boolean;
  children: ReactNode;
  onCancel: () => any;
  onSubmit: () => any;
}

export interface Props extends VMProps {
  isOpen: boolean;
  renderOverlay: (props: OverlayProps) => ReactNode;
  /**
   * Optional language translations key map for internationalization (i18n)
   */
  i18n?: FormulaTranslations;
}

const CreateFormulaOverlay = ({
  isOpen,
  i18n = formulaTranslations.en,
  renderOverlay,
  ...vmProps
}: Props) => {
  const {
    display,
    format,
    inputRef,
    isPreviewPlaceholderVisible,
    onCancel,
    onSubmit,
    previewRef,
    refreshPreview,
    setFormatMenuValue,
  } = useCreateFormulaOverlay(vmProps);

  return (
    <>
      {renderOverlay({
        isOpen,
        onSubmit,
        onCancel,
        children: (
          <Root>
            <Preview ref={previewRef}>
              {isPreviewPlaceholderVisible && (
                <Typography variant="paragraphRegular" color="grey.500">
                  {
                    i18n.as_you_type_the_expression_in_the_input_box_below_the_formatted_output_will_appear_here
                  }
                </Typography>
              )}
            </Preview>
            <Divider />
            <Formatter>
              <Typography variant="h6Regular" color="grey.800">
                {i18n.format_and_display}
              </Typography>
              <FormatMenu i18n={i18n} value={{ display, format }} onChange={setFormatMenuValue} />
            </Formatter>
            <Divider />
            <FormulaEditor
              multiline
              fullWidth
              minRows={4}
              maxRows={Infinity}
              inputRef={inputRef}
              placeholder={i18n.enter_formula_here}
              onInput={refreshPreview}
              autoFocus
            />
          </Root>
        ),
      })}
    </>
  );
};

export default CreateFormulaOverlay;
