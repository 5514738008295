import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const AnalyticsIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M11.2223 10.3259C10.5645 10.7654 9.79113 11 9 11C7.93913 11 6.92172 10.5786 6.17157 9.82843C5.42143 9.07828 5 8.06087 5 7C5 6.20888 5.2346 5.43552 5.67412 4.77772C6.11365 4.11992 6.73836 3.60723 7.46927 3.30448C8.20017 3.00173 9.00444 2.92252 9.78036 3.07686C10.5563 3.2312 11.269 3.61216 11.8284 4.17157C12.3878 4.73098 12.7688 5.44372 12.9231 6.21964C13.0775 6.99556 12.9983 7.79983 12.6955 8.53074C12.3928 9.26164 11.8801 9.88635 11.2223 10.3259Z"
          fill="currentColor"
        />
        <path
          d="M4.05025 15.0503C5.36301 13.7375 7.14348 13 9 13C10.8565 13 12.637 13.7375 13.9497 15.0503C15.2625 16.363 16 18.1435 16 20C16 20.2652 15.8946 20.5196 15.7071 20.7071C15.5196 20.8946 15.2652 21 15 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20C2 18.1435 2.7375 16.363 4.05025 15.0503Z"
          fill="currentColor"
        />
        <path
          d="M15 8C15.5523 8 16 8.44772 16 9V13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13V9C14 8.44772 14.4477 8 15 8Z"
          fill="currentColor"
        />
        <path
          d="M21 6C21.5523 6 22 6.44772 22 7V13C22 13.5523 21.5523 14 21 14C20.4477 14 20 13.5523 20 13V7C20 6.44772 20.4477 6 21 6Z"
          fill="currentColor"
        />
        <path
          d="M18 4C18.5523 4 19 4.44772 19 5V13C19 13.5523 18.5523 14 18 14C17.4477 14 17 13.5523 17 13V5C17 4.44772 17.4477 4 18 4Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2223 10.3259C10.5645 10.7654 9.79113 11 9 11C7.93913 11 6.92172 10.5786 6.17157 9.82843C5.42143 9.07828 5 8.06087 5 7C5 6.20888 5.2346 5.43552 5.67412 4.77772C6.11365 4.11992 6.73836 3.60723 7.46927 3.30448C8.20017 3.00173 9.00444 2.92252 9.78036 3.07686C10.5563 3.2312 11.269 3.61216 11.8284 4.17157C12.3878 4.73098 12.7688 5.44372 12.9231 6.21964C13.0775 6.99556 12.9983 7.79983 12.6955 8.53074C12.3928 9.26164 11.8801 9.88635 11.2223 10.3259ZM10.1111 5.33706C9.78224 5.1173 9.39556 5 9 5C8.46957 5 7.96086 5.21072 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7C7 7.39556 7.1173 7.78224 7.33706 8.11114C7.55682 8.44004 7.86918 8.69639 8.23463 8.84776C8.60009 8.99914 9.00222 9.03874 9.39018 8.96157C9.77814 8.8844 10.1345 8.69392 10.4142 8.41422C10.6939 8.13451 10.8844 7.77814 10.9616 7.39018C11.0387 7.00222 10.9991 6.60009 10.8478 6.23463C10.6964 5.86918 10.44 5.55683 10.1111 5.33706Z"
        fill="currentColor"
      />
      <path
        d="M4.05025 15.0503C5.36301 13.7375 7.14348 13 9 13C10.8565 13 12.637 13.7375 13.9497 15.0503C15.2625 16.363 16 18.1435 16 20C16 20.2652 15.8946 20.5196 15.7071 20.7071C15.5196 20.8946 15.2652 21 15 21C14.7348 21 14.4804 20.8946 14.2929 20.7071C14.1054 20.5196 14 20.2652 14 20C14 18.6739 13.4732 17.4022 12.5355 16.4645C11.5979 15.5268 10.3261 15 9 15C7.67392 15 6.40215 15.5268 5.46447 16.4645C4.52678 17.4022 4 18.6739 4 20C4 20.2652 3.89464 20.5196 3.70711 20.7071C3.51957 20.8946 3.26522 21 3 21C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20C2 18.1435 2.7375 16.363 4.05025 15.0503Z"
        fill="currentColor"
      />
      <path
        d="M16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 8.44772 14 9V13C14 13.5523 14.4477 14 15 14C15.5523 14 16 13.5523 16 13V9Z"
        fill="currentColor"
      />
      <path
        d="M21 6C21.5523 6 22 6.44772 22 7V13C22 13.5523 21.5523 14 21 14C20.4477 14 20 13.5523 20 13V7C20 6.44772 20.4477 6 21 6Z"
        fill="currentColor"
      />
      <path
        d="M19 5C19 4.44772 18.5523 4 18 4C17.4477 4 17 4.44772 17 5V13C17 13.5523 17.4477 14 18 14C18.5523 14 19 13.5523 19 13V5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default AnalyticsIcon;
