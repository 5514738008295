import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import CreateCourseForm from '../CreateCourseForm';
import CopyCourseDescription from './CopyCourseDescription';
import CopyCourseSyllabus from './CopyCourseSyllabus';
import CopyCourseTopics from './CopyCourseTopics';
import CopyReadingList from './CopyReadingList';
import CopySummary from './CopySummary';
import useCopyContentVM from './vm';

const Title = styled((props: TypographyProps) => <Typography {...props} variant="h5Bold" />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

interface Props {
  id?: string;
}

const CopyContent = ({ id }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);

  const {
    blueprintCoursePreview,
    closeDrawer,
    control,
    handleInitializeCourse,
    isSubmitting,
    isVisible,
    moveToPreviousStep,
  } = useCopyContentVM();

  const { description, readingList, topics, syllabus, activities } = blueprintCoursePreview || {};

  return (
    <CreateCourseForm
      id={id}
      isVisible={isVisible}
      onSubmit={handleInitializeCourse}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button type="button" variant="text" startIcon={<ChevronLeftIcon />} onClick={moveToPreviousStep}>
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button type="button" variant="text" onClick={closeDrawer}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button type="submit" variant="contained" isLoading={isSubmitting} endIcon={<ChevronRightIcon />}>
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={5}>
        <Title>
          {t('step_1_dot_2_colon_initialize_course_hyphen_select_content_to_copy', {
            ns: i18nNS.CREATE_COURSE,
          })}
        </Title>
        <CopyCourseDescription control={control} description={description} isSubmitting={isSubmitting} />
        <CopyReadingList control={control} readingList={readingList} isSubmitting={isSubmitting} />
        <CopyCourseTopics control={control} topics={topics} isSubmitting={isSubmitting} />
        <CopyCourseSyllabus control={control} syllabus={syllabus} isSubmitting={isSubmitting} />
        <CopySummary activities={activities} />
      </Stack>
    </CreateCourseForm>
  );
};

export default CopyContent;
