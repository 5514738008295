import { useContext } from 'react';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../components/Breadcrumb/index';

const useReferBreadcrumbVM = () => {
  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems };
};

const ReferBreadcrumb = () => {
  const { breadcrumbItems } = useReferBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.refer}>
      <BreadcrumbItem item={breadcrumbItems.home} />
      <BreadcrumbItem item={breadcrumbItems.settings} />
    </Breadcrumb>
  );
};

export default ReferBreadcrumb;
