import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { CourseLink } from './types';

export async function getCourseLinks(data: API.GetCourseLinksRequest) {
  const { links } = (await request.post<API.GetCourseLinksResponse>(urls.getCourseLinks, data)).data;
  return links.map<CourseLink>((link) => ({
    id: link._id,
    title: link.details.title,
    url: link.details.url,
  }));
}

export async function addCourseLink(data: API.AddCourseLinkRequest) {
  const { linkId: id } = (await request.post<API.AddCourseLinkResponse>(urls.addCourseLink, data)).data;
  const link: CourseLink = { id, title: data.title, url: data.url };
  return link;
}

export async function removeCourseLink(data: API.RemoveCourseLinkRequest) {
  await request.delete(urls.removeCourseLink, { data });
}
