import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import MeetingStatusText from '../MeetingStatusText';
import ZoomAppFooterLayout from '../ZoomAppFooterLayout';
import useStudentBeingAddressedFooterViewVM from './vm';

const StudentBeingAddressedFooterView = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.GLOSSARY]);

  const { handleClickNext, handleClickResolve, hasNext, participantBeingAddressed } =
    useStudentBeingAddressedFooterViewVM();
  return (
    <ZoomAppFooterLayout
      left={
        <>
          <MeetingStatusText variant="paragraphRegular" highlighted>
            {t('addressing', { ns: i18nNS.ONLINE_MEETING })}
          </MeetingStatusText>
          <MeetingStatusText variant="paragraphRegular">
            {t('student_name', { ns: i18nNS.ONLINE_MEETING, studentName: participantBeingAddressed?.name })}
          </MeetingStatusText>
        </>
      }
      right={
        <>
          <Button variant="outlined" size="small" onClick={handleClickResolve}>
            {t('resolve', { ns: i18nNS.GLOSSARY })}
          </Button>
          {hasNext ? (
            <Button variant="outlined" size="small" onClick={handleClickNext}>
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          ) : null}
        </>
      }
    />
  );
};

export default StudentBeingAddressedFooterView;
