import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import Button from '../../../components/Button';
import Drawer from '../../../components/Drawer';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { format } from '../../../utils/datetime';
import { forMobile } from '../../../utils/media-queries';
import Skeleton from './Skeleton';
import { FeatureSummaryCard } from './styles';
import useFeatureUpdatesDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const FeatureUpdatesDrawer = ({ open, onClose: handleClose }: Props) => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.GLOSSARY, i18nNS.COMMON]);
  const isMobile = useMediaQuery(forMobile);

  const {
    isFetchingFeatureUpdates,
    featureUpdates,
    handleFeatureSeen: _handleFeatureSeen,
  } = useFeatureUpdatesDrawerVM({ open, onClose: handleClose });

  const handleFeatureSeen = (featureName: string) => () => {
    return _handleFeatureSeen(featureName);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      header={t('all_feature_updates', { ns: i18nNS.SETTINGS })}
      footer={
        !isMobile && (
          <Button
            variant="text"
            onClick={handleClose}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('close', { ns: i18nNS.GLOSSARY })}
          </Button>
        )
      }
    >
      {isFetchingFeatureUpdates ? (
        <Skeleton />
      ) : (
        <Stack gap={6}>
          {featureUpdates.map(
            ({ featureName, releasedOn, isSeen, title, message, isBlog, viewMoreLabel, redirectUrl }) => (
              <FeatureSummaryCard key={featureName}>
                <Typography variant="paragraphRegular" color="grey.400">
                  {format(releasedOn, 'do MMM, yyyy')}
                </Typography>
                <Typography variant="h6Medium" color={isSeen ? 'grey.400' : 'primary.500'} component="div">
                  {title}
                </Typography>
                <Typography variant="paragraphRegular">
                  {message.split('\n').map((line, index) => (
                    <Fragment key={index}>
                      {line}
                      <br />
                    </Fragment>
                  ))}
                </Typography>
                {isBlog && Boolean(viewMoreLabel) && (
                  <Button
                    component="a"
                    href={redirectUrl}
                    target="_blank"
                    onClick={handleFeatureSeen(featureName)}
                    aria-label={t('click_to_view_more_label', { ns: i18nNS.SETTINGS, viewMoreLabel })}
                    variant="outlined"
                  >
                    {viewMoreLabel}
                  </Button>
                )}
              </FeatureSummaryCard>
            )
          )}
        </Stack>
      )}
    </Drawer>
  );
};

export default FeatureUpdatesDrawer;
