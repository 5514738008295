import Joi from 'joi';

import { ClassModality } from '../../../../../courses/types';
import i18n, { i18nNS } from '../../../../../i18n';
import { formatTimeToUnix } from '../../../../../utils/datetime';
import { optionalString, proVenue, requiredOneOption, requiredString } from '../../../../../utils/validators';
import { FormValues } from './types';

const baseValidationSchema = {
  weekdays: Joi.array()
    .items()
    .min(1)
    .messages({
      'array.min': i18n.t('please_select_a_day', { ns: i18nNS.VALIDATION }),
    }),
  startTime: requiredString
    .custom((startTime, helpers) => {
      const { endTime } = helpers.state.ancestors[0];
      if (!startTime || !endTime) return startTime;
      try {
        const parsedStartTime = formatTimeToUnix(startTime);
        const parsedEndTime = formatTimeToUnix(endTime);
        if (parsedStartTime >= parsedEndTime) return helpers.error('any.custom');
      } catch (error) {
        // swallow parsing error
      }
      return startTime;
    })
    .messages({
      ...requiredString.messages,
      'any.custom': i18n.t('the_start_time_should_be_earlier_than_the_end_time', { ns: i18nNS.VALIDATION }),
    }),
  endTime: requiredString
    .custom((endTime, helpers) => {
      const { startTime } = helpers.state.ancestors[0];
      if (!startTime || !endTime) return endTime;
      try {
        const parsedStartTime = formatTimeToUnix(startTime);
        const parsedEndTime = formatTimeToUnix(endTime);
        if (parsedStartTime >= parsedEndTime) return helpers.error('any.custom');
      } catch (error) {
        // swallow parsing error
      }
      return endTime;
    })
    .messages({
      ...requiredString.messages,
      'any.custom': i18n.t('end_time_should_be_later_than_the_start_time', { ns: i18nNS.VALIDATION }),
    }),
};

export const validationSchema = Joi.object<FormValues, true>({
  ...baseValidationSchema,
  classModality: optionalString,
  venue: requiredString,
});

export const proValidationSchema = Joi.object<FormValues, true>({
  ...baseValidationSchema,
  classModality: requiredOneOption({ options: Object.values(ClassModality) }),
  venue: proVenue({ classModalityFieldName: 'classModality' }),
});
