import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../../../../../../components/Button';
import Tooltip from '../../../../../../../../../components/Tooltip';
import { i18nNS } from '../../../../../../../../../i18n';
import AwardIcon from '../../../../../../../../../icons/AwardIcon';
import useAwardVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  onClick?: MouseEventHandler;
  edge?: ButtonProps['edge'];
  /**
   * shows number of awarded points by current user in badge
   * @default false
   */
  canSeeBadge?: boolean;
  className?: string;
  classes?: Partial<Record<'root' | 'action', string>>;
}

const Award = ({ commentId, onClick, edge, canSeeBadge = false, className, classes }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const {
    canAward,
    hasAwarded,
    awardPoints,
    handleAwardClick: _handleAwardClick,
  } = useAwardVM({ commentId });

  const handleAwardClick: MouseEventHandler = (event) => {
    _handleAwardClick();
    onClick?.(event);
  };

  if (!canAward) return null;

  return (
    <Tooltip
      title={t(hasAwarded ? 'retract_award_points' : 'award_points', { ns: i18nNS.COMMENTS })}
      className={clsx(className, classes?.root)}
    >
      <span>
        <Button
          variant="text"
          size="small"
          edge={edge}
          className={classes?.action}
          aria-label={t(
            hasAwarded
              ? 'click_to_undo_points_awarded_to_this_post'
              : 'click_to_give_award_points_to_this_post',
            { ns: i18nNS.COMMENTS }
          )}
          onClick={handleAwardClick}
        >
          <Badge badgeContent={canSeeBadge ? awardPoints : undefined} color="error">
            <AwardIcon variant={hasAwarded ? 'filled' : 'outlined'} fontSize="small" aria-hidden />
          </Badge>
        </Button>
      </span>
    </Tooltip>
  );
};

export default Award;
