import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { AssignmentRetractedEvent, AssignmentSubmittedEvent } from './pusher-events';
import {
  AddAssignmentQuestionPayload,
  AddAssignmentQuestionSuccessPayload,
  CreateAssignmentPayload,
  CreateAssignmentResponse,
  DeleteAssignmentPayload,
  DeleteAssignmentQuestionPayload,
  DeleteAssignmentSuccessPayload,
  EditAssignmentQuestionPayload,
  EditPublishedAssignmentPayload,
  EditPublishedAssignmentSuccessPayload,
  EditUnpublishedAssignmentPayload,
  FetchAssignmentAnalyticsForCourseTeamPayload,
  FetchAssignmentAnalyticsForCourseTeamSuccessPayload,
  FetchAssignmentDataRequestPayload,
  FetchAssignmentDataSuccessPayload,
  FetchIndividualAssignmentSubmissionPayload,
  FetchIndividualAssignmentSubmissionSuccessPayload,
  PublishAssignmentPayload,
  PublishAssignmentSuccessPayload,
  RetractAssignmentPayload,
  RetractAssignmentSuccessPayload,
  SaveAssignmentGradesPayload,
  SaveFileSubmissionPayload,
  SaveFileSubmissionSuccessPayload,
  SaveURLSubmissionPayload,
  SaveURLSubmissionSuccessPayload,
  SubmitAssignmentGradesSucessPayload,
  SubmitAssignmentPayload,
  SubmitAssignmentSuccessPayload,
} from './types';

export const createAssignment = createRequestAction<CreateAssignmentPayload, CreateAssignmentResponse>(
  'assignments/create'
);

export const editUnpublishedAssignment = createRequestAction<
  EditUnpublishedAssignmentPayload,
  EditUnpublishedAssignmentPayload
>('assignments/unpublished/edit');

export const editPublishedAssignment = createRequestAction<
  EditPublishedAssignmentPayload,
  EditPublishedAssignmentSuccessPayload
>('assignments/published/edit');

export const publishAssignment = createRequestAction<
  PublishAssignmentPayload,
  PublishAssignmentSuccessPayload
>('assignments/publish');

export const fetchAssignmentData = createRequestAction<
  FetchAssignmentDataRequestPayload,
  FetchAssignmentDataSuccessPayload
>('assignments/fetch/data');

export const deleteAssignment = createRequestAction<DeleteAssignmentPayload, DeleteAssignmentSuccessPayload>(
  'assignments/delete'
);

export const addAssignmentQuestion = createRequestAction<
  AddAssignmentQuestionPayload,
  AddAssignmentQuestionSuccessPayload
>('assignments/questions/add');

export const editAssignmentQuestion = createRequestAction<
  EditAssignmentQuestionPayload,
  EditAssignmentQuestionPayload
>('assignments/questions/edit');

export const deleteAssignmentQuestion = createRequestAction<
  DeleteAssignmentQuestionPayload,
  DeleteAssignmentQuestionPayload
>('assignments/questions/delete');

export const saveFileSubmission = createRequestAction<
  SaveFileSubmissionPayload,
  SaveFileSubmissionSuccessPayload
>('assignments/file-submission/save');

export const saveURLSubmission = createRequestAction<
  SaveURLSubmissionPayload,
  SaveURLSubmissionSuccessPayload
>('assignments/url-submission/save');

export const submitAssignment = createRequestAction<SubmitAssignmentPayload, SubmitAssignmentSuccessPayload>(
  'assignments/submit'
);

export const assignmentSubmitted = createAction<AssignmentSubmittedEvent>('assignments/submitted');

export const retractAssignment = createRequestAction<
  RetractAssignmentPayload,
  RetractAssignmentSuccessPayload
>('assignments/retract');

export const assignmentRetracted = createAction<AssignmentRetractedEvent>('assignments/retracted');

export const fetchAssignmentAnalyticsForCourseTeam = createRequestAction<
  FetchAssignmentAnalyticsForCourseTeamPayload,
  FetchAssignmentAnalyticsForCourseTeamSuccessPayload
>('assignments/course-team-analytics/fetch');

export const fetchIndividualAssignmentSubmission = createRequestAction<
  FetchIndividualAssignmentSubmissionPayload,
  FetchIndividualAssignmentSubmissionSuccessPayload
>('assignments/individual-submission/fetch');

export const saveAssignmentGrades =
  createRequestAction<SaveAssignmentGradesPayload>('assignments/grades/save');

export const submitAssignmentGrades = createRequestAction<
  SaveAssignmentGradesPayload,
  SubmitAssignmentGradesSucessPayload
>('assignments/grades/submit');
