import { useTranslation } from 'react-i18next';

import Alert from '../../components/Alert';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import { CourseRole } from '../../types';
import useAttendingInPersonOrRemoteDialogVM from './vm';

const AttendingInPersonOrRemoteDialog = () => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING]);

  const {
    isAttendendingInPersonOrRemoteDialogOpen,
    currentUser,
    handleAttendingRemotely,
    handleAttendingInPerson,
    takerName,
  } = useAttendingInPersonOrRemoteDialogVM();

  if (currentUser.role !== CourseRole.STUDENT) return null;

  if (!isAttendendingInPersonOrRemoteDialogOpen) return null;

  return (
    <Alert open={isAttendendingInPersonOrRemoteDialogOpen}>
      <Alert.Header>
        {t('classroom_attendance_is_being_recorded', { ns: i18nNS.ONLINE_MEETING })}
      </Alert.Header>
      <Alert.Body>
        <Typography variant="paragraphRegular">
          {t('taker_name_is_recording_presence_of_students_attending_the_ongoing_lecture_in_person', {
            ns: i18nNS.ONLINE_MEETING,
            takerName,
          })}
        </Typography>
        <Typography variant="paragraphRegular">
          {t('are_you_attending_the_lecture_in_person_or_remotely', { ns: i18nNS.ONLINE_MEETING })}
        </Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action variant="text" onClick={handleAttendingRemotely}>
          {t('attending_remotely', { ns: i18nNS.ONLINE_MEETING })}
        </Alert.Action>
        <Alert.Action variant="text" onClick={handleAttendingInPerson}>
          {t('attending_in_person', { ns: i18nNS.ONLINE_MEETING })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default AttendingInPersonOrRemoteDialog;
