import { Divider, Skeleton, Stack } from '@mui/material';

import Typography from '../../../components/Typography';
import { NotificationCell } from './styles';

const ManageNotificationsDrawerSkeleton = () => {
  return (
    <Stack gap={5}>
      <Typography variant="h6Medium" color="grey.800" component="div">
        <Skeleton variant="text" width={250} />
      </Typography>
      <Stack divider={<Divider />}>
        {new Array(6).fill(<div />).map((_, index) => (
          <NotificationCell key={index}>
            <Typography variant="paragraphRegular" color="grey.800">
              <Skeleton variant="text" width={200} />
            </Typography>
            <Stack direction="row" alignItems="center" gap={1}>
              <Skeleton variant="rounded" width={40} height={40} />
              <Skeleton variant="rounded" width={40} height={40} />
            </Stack>
          </NotificationCell>
        ))}
      </Stack>
    </Stack>
  );
};

export default ManageNotificationsDrawerSkeleton;
