import { useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import DeleteCoursesDrawer from '../../courses/DeleteCoursesDrawer';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import Trash2Icon from '../../icons/Trash2Icon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import useDeleteCoursesVM from './vm';

const DeleteCourses = () => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  const { isDeleteCoursesDrawerOpen, handleCloseDeleteCoursesDrawer, handleDeleteCourses } =
    useDeleteCoursesVM();

  return (
    <>
      <ListItemButton
        color="error"
        onClick={handleDeleteCourses}
        aria-label={t('click_to_remove_courses_where_you_are_admin', { ns: i18nNS.SETTINGS })}
        aria-haspopup
      >
        <ListItemIcon color="error">
          <Trash2Icon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('remove_courses', { ns: i18nNS.SETTINGS })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <DeleteCoursesDrawer open={isDeleteCoursesDrawerOpen} onClose={handleCloseDeleteCoursesDrawer} />
    </>
  );
};

export default DeleteCourses;
