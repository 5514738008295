import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material';

import Button from '../../../../../components/Button';
import Drawer from '../../../../../components/Drawer';
import Header from '../../../../../components/Header';
import { i18nNS } from '../../../../../i18n';
import ChevronRightIcon from '../../../../../icons/ChevronRightIcon';
import CreateCourseForm from '../../../CreateCourseForm';
import ClassModality from './ClassModality';
import ClassTiming from './ClassTiming';
import useAddWeeklyClassDrawerVM, { Props as VMProps } from './vm';
import Weekdays from './Weekdays';

const Root = styled('div')(() => ({
  position: 'relative',
  height: '100%',
}));

interface Props extends VMProps {}

const AddWeeklyClassDrawer = ({ ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.COMMON]);

  const { open, onClose } = vmOptions;
  const { formMethods, saveWeeklySchedule } = useAddWeeklyClassDrawerVM(vmOptions);

  const { formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      header={() => <Header>{t('adding_weekly_class', { ns: i18nNS.CREATE_COURSE })}</Header>}
    >
      {({ bodyId }) => (
        <Root>
          <FormProvider {...formMethods}>
            <CreateCourseForm
              id={bodyId}
              isVisible={open}
              onSubmit={saveWeeklySchedule}
              footer={
                <>
                  <Button type="button" variant="text" onClick={onClose}>
                    {t('cancel', { ns: i18nNS.GLOSSARY })}
                  </Button>
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    key={`save-${isSubmitting}`}
                    endIcon={<ChevronRightIcon />}
                    aria-label={t('click_to_save_and_create_classes', { ns: i18nNS.CREATE_COURSE })}
                  >
                    {t('save', { ns: i18nNS.GLOSSARY })}
                  </Button>
                </>
              }
            >
              <Weekdays controlName="weekdays" />
              <ClassTiming startTimeControlName="startTime" endTimeControlName="endTime" />
              <ClassModality controlName="classModality" venueControlName="venue" />
            </CreateCourseForm>
          </FormProvider>
        </Root>
      )}
    </Drawer>
  );
};

export default AddWeeklyClassDrawer;
