import { createReducer, Dictionary } from '@reduxjs/toolkit';

import {
  commentCreated,
  createComment,
  fetchComments,
  markCommentsAsSeen,
  subscribeToComments,
} from '../../comments/actions';
import { CommentContext } from '../types';

interface CommentsContainerState {
  byId: Dictionary<{
    comments: {
      total: number;
      seen: number;
      isSubscribed: boolean;
    };
  }>;
}

export default function createCommentReducer<S extends CommentsContainerState>({
  initialState,
  reducerContext,
}: {
  reducerContext: CommentContext;
  initialState: S;
}) {
  return createReducer(initialState, (builder) => {
    builder.addCase(fetchComments.success, (state, action) => {
      const { context, totalCommentsSeen } = action.payload;
      if (totalCommentsSeen === undefined || context.type !== reducerContext) return;

      const entity = state.byId[context.id];
      if (!entity) return;

      if (context.type === CommentContext.ASSIGNMENT) return;

      // mark all comments as seen
      entity.comments.seen = totalCommentsSeen ?? entity.comments.total;
    });

    builder.addCase(createComment.success, (state, action) => {
      const { isAlreadyAdded, context } = action.payload;
      if (isAlreadyAdded || context.type !== reducerContext) return;

      const entity = state.byId[context.id];
      if (!entity) return;

      entity.comments.total++;

      // mark all comments as seen
      entity.comments.seen = entity.comments.total;
    });

    builder.addCase(commentCreated, (state, action) => {
      const { context, contextId, sender, currentUser, canSeeComments } = action.payload;
      if (context !== reducerContext) return;

      const entity = state.byId[contextId];
      if (!entity) return;

      entity.comments.total++;

      if (!canSeeComments) return;

      if (sender.userId !== currentUser.userId) return;

      // mark all comments as seen
      entity.comments.seen = entity.comments.total;
    });

    builder.addCase(markCommentsAsSeen.success, (state, action) => {
      const { context, seen } = action.payload;
      if (context.type !== reducerContext) return;

      const entity = state.byId[context.id];
      if (!entity) return;

      entity.comments.seen = Math.max(entity.comments.total, entity.comments.seen + seen);
    });

    builder.addCase(subscribeToComments.request, (state, action) => {
      const { context, subscribe } = action.payload;

      if (context.type !== reducerContext) return;

      const entity = state.byId[context.id];
      if (!entity) return;

      // updating the state assuming API request will succeed
      entity.comments.isSubscribed = subscribe;
    });

    builder.addCase(subscribeToComments.failure, (state, action) => {
      const { context, subscribe } = action.payload;

      if (context.type !== reducerContext) return;

      const entity = state.byId[context.id];
      if (!entity) return;

      // undo the change on API request failure
      entity.comments.isSubscribed = !subscribe;
    });
  });
}
