export enum PaymentMethod {
  FREE = 'free',
  PURCHASED = 'purchased',
  STUDENT_PAYS = 'student_pays',
  INSTRUCTOR_PAYS = 'instructor_pays',
}

export interface FormValues {
  isCreatingProCourse: 'true' | 'false';
  paymentMethod: PaymentMethod;
}
