import { MAX_QUIZ_QUESTION_MARKS } from '../db/quizzes/helpers';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  MultipleChoiceQuestion,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  QuestionSubmission,
  Quiz,
  QuizQuestion,
  QuizScoreWithMaxScore,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SortingQuestion,
  StudentQuizData,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TrueFalseQuestion,
} from '../db/quizzes/types';
import {
  ClassRole,
  CourseRole,
  QuizQuestionType,
  ScoringScheme,
  SubmissionStatus,
  TipStatusKey,
  UserWithRole,
} from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { QuizOptionColor } from '../styles/colors';
import { getIsActivityPublished, getIsManuallyClosedActivity } from '../utils/activities';
import { unix } from '../utils/datetime';
import { getAlphabetFromIndex } from '../utils/helpers';

export function getQuizHeader(quiz: Pick<Quiz, 'sequenceNum'> | undefined) {
  if (!quiz?.sequenceNum || quiz.sequenceNum <= 0) return i18n.t('quiz', { ns: i18nNS.GLOSSARY });
  return i18n.t('quiz_num', { ns: i18nNS.QUIZ, num: `${quiz.sequenceNum}`.padStart(2, '0') });
}

function getMarks(scoring: ScoringScheme, score: Omit<QuizScoreWithMaxScore, 'maxScore'>) {
  let marks = 0;

  switch (scoring) {
    case ScoringScheme.INCENTIVIZING:
      marks = score.incentivizing;
      break;
    case ScoringScheme.PENALIZING:
      marks = score.penalizing;
      break;
    case ScoringScheme.NEUTRAL:
      marks = score.neutral;
      break;
    default:
      return 0;
  }

  return marks;
}

export function getQuizScore(quiz: Quiz | undefined, studentId: MongoId) {
  if (!quiz) return undefined;

  const submission = quiz.studentDataById[studentId];
  if (!submission?.totalScore) return undefined;

  const score = {
    marks: getMarks(quiz.preferences.scoring, submission.totalScore),
    maxMarks: submission.totalScore.maxScore,
  };

  return score;
}

export function getQuestionScore(quiz: Quiz | undefined, studentId: MongoId, questionId: MongoId) {
  if (!quiz) return undefined;
  if (!quiz.questionsById) return undefined;

  const studentSubmission = quiz.studentDataById[studentId];
  if (!studentSubmission) return undefined;

  if (!studentSubmission.submissionsByQuestionId) return undefined;
  const questionSubmission = studentSubmission.submissionsByQuestionId[questionId];
  if (!questionSubmission?.score) return undefined;

  if (!studentSubmission.totalScore) return undefined;

  const score = {
    marks: getMarks(quiz.preferences.scoring, questionSubmission.score),
    maxMarks: MAX_QUIZ_QUESTION_MARKS,
  };

  return score;
}

export interface TFQOptionData {
  questionType: QuizQuestionType.TF;
  option: 't' | 'f';
  /** Correct answer key, refers to {@link TrueFalseQuestion.answerKey} */
  answerKey?: 't' | 'f';
}

export function getTFQuestionOptionColor(
  optionData: TFQOptionData,
  role = ClassRole.STUDENT,
  studentResponse?: 't' | 'f' | '0000',
  hasAttemptedButNotSubmitted?: boolean
) {
  if (!optionData.answerKey) return QuizOptionColor.GREY;

  const isCorrectOption = optionData.answerKey === optionData.option;

  if (role !== ClassRole.STUDENT) {
    return isCorrectOption ? QuizOptionColor.BLUE : QuizOptionColor.GREY;
  }

  const isUnAttempted = !studentResponse || studentResponse === '0000';

  /**
   * If question not attempted or has attempted but not submitted,
   * then mark correct option as wrong answer
   */
  if (isUnAttempted || hasAttemptedButNotSubmitted) {
    return isCorrectOption ? QuizOptionColor.RED : QuizOptionColor.GREY;
  }

  /**
   * isSelected | isCorrectOption | Color
   * -----------+-----------------+--------
   *      T     |        T        |  BLUE
   *      T     |        F        |  RED
   *      F     |        T        |  GREY
   *      F     |        F        |  GREY
   */

  const isSelected = studentResponse === optionData.option;

  // If not selected mark as neutral
  if (!isSelected) return QuizOptionColor.GREY;

  // if selected correct option then correct answer otherwise wrong answer
  return isCorrectOption ? QuizOptionColor.BLUE : QuizOptionColor.RED;
}

export interface MCQOptionData {
  questionType: QuizQuestionType.MCQ;
  /**
   * Option position at the time of question creation,
   * refers to {@link QuestionOption.num option number}
   */
  option: number;
  /** Correct answer key, refers to {@link MultipleChoiceQuestion.answerKey} */
  answerKey?: string;
}

export function getMCQOptionColor(
  optionData: MCQOptionData,
  role = ClassRole.STUDENT,
  studentResponse?: string,
  hasAttemptedButNotSubmitted?: boolean
) {
  if (!optionData.answerKey) return QuizOptionColor.GREY;

  const isCorrectOption = optionData.answerKey[optionData.option - 1] === '1';

  if (role !== ClassRole.STUDENT) {
    return isCorrectOption ? QuizOptionColor.BLUE : QuizOptionColor.GREY;
  }

  const isUnAttempted = !studentResponse || studentResponse === '0000';

  /**
   * If question not attempted or has attempted but not submitted,
   * then mark correct option as wrong answer
   */
  if (isUnAttempted || hasAttemptedButNotSubmitted) {
    return isCorrectOption ? QuizOptionColor.RED : QuizOptionColor.GREY;
  }

  /**
   * isSelected | isCorrectOption | Color
   * -----------+-----------------+--------
   *      T     |        T        |  BLUE
   *      T     |        F        |  RED
   *      F     |        T        |  RED
   *      F     |        F        |  GREY
   */

  const isSelected = studentResponse[optionData.option - 1] === '1';

  if (isCorrectOption) return isSelected ? QuizOptionColor.BLUE : QuizOptionColor.RED;

  return isSelected ? QuizOptionColor.RED : QuizOptionColor.GREY;
}

export interface SortingOptionData {
  questionType: QuizQuestionType.SORTING;
  orderKey: string;
  /** Correct answer key , refers to {@link SortingQuestion.answerKey} */
  answerKey?: string;
}

export function getSortingQuestionOptionColor(
  optionData: SortingOptionData,
  role = ClassRole.STUDENT,
  studentResponse?: string,
  hasAttemptedButNotSubmitted?: boolean
) {
  if (!optionData.answerKey) return undefined;

  if (role !== ClassRole.STUDENT) return undefined;

  const isUnAttempted = !studentResponse || studentResponse === '0000';

  if (isUnAttempted || hasAttemptedButNotSubmitted) {
    return QuizOptionColor.GREY;
  }

  const correctOptionIndex = optionData.answerKey
    .split('-')
    .findIndex((orderKey) => orderKey === optionData.orderKey);
  if (correctOptionIndex < 0) return QuizOptionColor.GREY;

  const selectedOptionIndex = studentResponse
    .split('-')
    .findIndex((orderKey) => orderKey === optionData.orderKey);

  return selectedOptionIndex === correctOptionIndex ? QuizOptionColor.BLUE : QuizOptionColor.RED;
}

export function getQuizQuestionOptionColor(
  quiz: Quiz | undefined,
  question: QuizQuestion,
  option: 't' | 'f' | number | string,
  studentId: MongoId,
  role = ClassRole.STUDENT
) {
  if (!quiz) return undefined;

  let studentSubmission: StudentQuizData | undefined;
  let questionSubmission: QuestionSubmission | undefined;

  if (role === ClassRole.STUDENT) {
    studentSubmission = quiz.studentDataById[studentId];
    if (!studentSubmission) return QuizOptionColor.GREY;

    questionSubmission = studentSubmission.submissionsByQuestionId?.[question.id];

    const isDeadlineFirst = quiz.preferences.deadlineFirst;

    const isQuizManual = getIsManuallyClosedActivity(quiz);
    const isDueDateTimeManual = isQuizManual && quiz.dueDateTime <= 0;

    const isDeadlineInFuture = quiz.dueDateTime > unix();

    if (isDeadlineFirst && (isDueDateTimeManual || isDeadlineInFuture)) return QuizOptionColor.GREY;
  }

  if (question.type === QuizQuestionType.TF) {
    const optionData = {
      questionType: question.type,
      option: option as 't' | 'f',
      answerKey: question.answerKey,
    };
    return getTFQuestionOptionColor(
      optionData,
      role,
      questionSubmission?.answerString as 't' | 'f' | undefined,
      studentSubmission?.status === SubmissionStatus.IN_PROGRESS
    );
  }

  if (question.type === QuizQuestionType.MCQ) {
    const optionData = {
      questionType: question.type,
      option: option as number,
      answerKey: question.answerKey,
    };
    return getMCQOptionColor(
      optionData,
      role,
      questionSubmission?.answerString,
      studentSubmission?.status === SubmissionStatus.IN_PROGRESS
    );
  }

  const optionData = {
    questionType: question.type,
    orderKey: option as string,
    answerKey: question.answerKey,
  };
  return getSortingQuestionOptionColor(
    optionData,
    role,
    questionSubmission?.answerString,
    studentSubmission?.status === SubmissionStatus.IN_PROGRESS
  );
}

export function computePickedOptionCorrectAlphabet(
  question: SortingQuestion,
  questionSubmission: QuestionSubmission | undefined,
  optionIndex: number
) {
  if (!question.answerKey) return null;
  if (!questionSubmission?.answerString) return null;

  const pickedOptions = questionSubmission.answerString.split('-');
  const pickedOption = pickedOptions[optionIndex];

  const correctOptions = question.answerKey.split('-');
  const pickedOptionCorrectIndex = correctOptions.findIndex(
    (correctOption) => correctOption === pickedOption
  );
  if (pickedOptionCorrectIndex === -1) return null;

  const pickedOptionCorrectAlphabet = getAlphabetFromIndex(pickedOptionCorrectIndex);
  return pickedOptionCorrectAlphabet;
}

export function getSortingQuestionOptions(
  question: SortingQuestion,
  studentData: StudentQuizData | undefined
) {
  const submission = studentData?.submissionsByQuestionId?.[question.id];
  return question.options.map((option, index) => {
    const picked = computePickedOptionCorrectAlphabet(question, submission, index);
    if (!picked) return option;
    return {
      ...option,
      picked,
    };
  });
}

export function getCanAttemptQuiz(quiz: Quiz | undefined, role = ClassRole.STUDENT, studentId?: string) {
  if (!quiz) return false;

  if (role !== ClassRole.STUDENT) return false;

  if (!studentId) return false;

  const studentData = quiz.studentDataById[studentId];
  if (!studentData) return true;

  const isSubmitted = [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(studentData.status);
  if (isSubmitted) return false;

  if (quiz.preferences.allowLate) return true;

  const isQuizManual = getIsManuallyClosedActivity(quiz);
  const isDueDateTimeManual = isQuizManual && quiz.dueDateTime <= 0;

  const isQuizDueDateTimeNowOrInFuture = quiz.dueDateTime >= unix();

  return isDueDateTimeManual || isQuizDueDateTimeNowOrInFuture;
}

export function getIsQuizDeadlinePast(quiz: Quiz | undefined) {
  if (!quiz) return;

  const isQuizManual = getIsManuallyClosedActivity(quiz);
  const isDueDateTimeManual = isQuizManual && quiz.dueDateTime <= 0;

  if (isDueDateTimeManual) return false;

  if (quiz.dueDateTime) return quiz.dueDateTime <= unix();

  return false;
}

export function getCanFetchQuizComments({
  quiz,
  role,
  userId,
}: {
  quiz: Quiz | undefined;
  role: CourseRole | undefined;
  userId: MongoId;
}) {
  if (role !== CourseRole.STUDENT) return true;
  const canAttemptQuiz = getCanAttemptQuiz(quiz, ClassRole.STUDENT, userId);
  return !canAttemptQuiz;
}

export function getCanSendQuizComments(
  quiz: Quiz | undefined,
  currentUser: UserWithRole<CourseRole>,
  isCourseArchived: boolean | undefined
) {
  if (isCourseArchived) return false;

  if (!quiz) return false;

  const isStudent = currentUser.role === CourseRole.STUDENT;
  if (isStudent) {
    const canAttemptQuiz = getCanAttemptQuiz(quiz, ClassRole.STUDENT, currentUser.userId);
    return !canAttemptQuiz;
  }

  const isQuizPublished = getIsActivityPublished(quiz);
  return isQuizPublished;
}

export function getEmptyCommentsMessageForQuiz(
  quiz: Quiz | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  if (!quiz) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  const isQuizPublished = getIsActivityPublished(quiz);
  if (!isQuizPublished) {
    return i18n.t('comments_will_appear_here_once_this_activity_is_published', { ns: i18nNS.CLASS });
  }

  if (currentUser.role !== CourseRole.STUDENT) {
    return i18n.t(
      'students_will_be_able_to_post_their_thoughts_on_this_quiz_using_this_discussion_area_to_prevent_quiz_spoilers_the_students_will_be_able_to_access_it_only_once_they_have_submitted_the_quiz',
      { ns: i18nNS.QUIZ }
    );
  }

  const submissionStatus = quiz.studentDataById[currentUser.userId]?.status;
  const hasSubmitted =
    submissionStatus && [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(submissionStatus);

  if (hasSubmitted) {
    return i18n.t(
      'this_is_the_quiz_discussion_area_any_doubts_or_points_regarding_the_quiz_can_be_posted_here_after_you_ve_submitted_the_quiz_happy_discussing',
      { ns: i18nNS.QUIZ }
    );
  }

  const canAttemptQuiz = getCanAttemptQuiz(quiz, ClassRole.STUDENT, currentUser.userId);
  if (!canAttemptQuiz) {
    return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });
  }

  return i18n.t(
    'you_will_be_able_to_post_doubts_questions_and_random_musings_about_this_quiz_once_you_have_submitted_the_quiz_do_well',
    { ns: i18nNS.QUIZ }
  );
}

export function getQuizPageTipContent(tipKey: TipStatusKey, role: CourseRole) {
  switch (tipKey) {
    case 'quizMainFloatingButton':
      return i18n.t(
        'use_this_button_to_add_a_question_to_the_quiz_you_can_choose_to_add_either_a_multiple_choice_question_or_a_true_false_type_question_or_a_sorting_type_question_there_is_no_limit_to_the_number_of_questions_you_can_add',
        { ns: i18nNS.QUIZ }
      );
    case 'quizMainAttempt':
      return i18n.t(
        'to_attempt_the_quiz_questions_use_this_button_you_can_quit_anytime_and_your_quiz_progress_will_be_saved',
        { ns: i18nNS.QUIZ }
      );
    case 'quizMainChat':
      if (role === CourseRole.STUDENT) {
        return i18n.t(
          'once_you_ve_submitted_the_quiz_you_ll_be_able_to_access_discussions_and_clarify_doubts_about_this_quiz_using_this_section',
          { ns: i18nNS.QUIZ }
        );
      }
      return i18n.t(
        'you_and_the_students_can_conduct_any_discussions_around_a_quiz_using_the_discussion_section_only_those_students_who_ve_submitted_the_quiz_can_participate',
        { ns: i18nNS.QUIZ }
      );
    case 'quizAttemptNavigation':
      return i18n.t(
        'to_move_between_the_questions_you_can_use_the_previous_next_buttons_or_the_question_number_scroll_at_the_top',
        { ns: i18nNS.COMMON }
      );
    case 'quizAttemptSave':
      return i18n.t(
        'you_can_save_and_your_progress_in_the_quiz_by_using_this_button_this_will_not_submit_the_quiz',
        { ns: i18nNS.QUIZ }
      );
    case 'quizAttemptSubmit':
      return i18n.t(
        'whenever_you_re_satisfied_with_your_answers_to_the_questions_you_can_use_this_button_to_submit_the_quiz_please_be_careful_of_not_missing_the_deadline',
        { ns: i18nNS.QUIZ }
      );
    default:
      return '';
  }
}
