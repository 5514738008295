import { ElementType } from 'react';

import { Avatar as MuiAvatar, Stack, StackProps, styled } from '@mui/material';

import { radioGroupClasses } from '../../../components/FormControls/RadioGroup';
import AcadlyRadioOption, {
  radioOptionClasses,
} from '../../../components/FormControls/RadioGroup/RadioOption';
import AcadlyRadioGroup from '../../../components/HookFormControls/RadioGroup';
import { pick } from '../../../utils/helpers';

const chooseAvatarClasses = {
  ...pick(radioGroupClasses, 'radioGroup'),
  ...pick(radioOptionClasses, 'radioRoot'),
};

export const Form = styled(<T extends ElementType>(props: StackProps<T>) => (
  <Stack alignItems="center" gap={3} component="form" noValidate {...props} />
))(({ theme }) => ({
  padding: theme.spacing(5, 0),
}));

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  height: 56,
  width: 56,
}));

export const RadioGroup = styled(AcadlyRadioGroup)(({ theme }) => ({
  [`& .${chooseAvatarClasses.radioGroup}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
})) as typeof AcadlyRadioGroup;

export const RadioOption = styled(AcadlyRadioOption)(({ theme }) => ({
  margin: 'unset',
  [`& .${chooseAvatarClasses.radioRoot}`]: {
    display: 'none',
  },
}));
