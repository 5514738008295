import { Divider, styled } from '@mui/material';

import ListSeparator from '../../../../../../components/ListSeparator';
import { generateClasses } from '../../../../../../utils/helpers';
import AcadlyComment from './Comment';

export const commentGroupClasses = {
  ...generateClasses('CommentGroup', ['newIndicatorOnFirstCommentInGroup']),
};

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
}));

export const NewIndicator = styled(ListSeparator)(({ theme }) => ({
  position: 'relative',
  top: 0,
  /** move new indicator edge to the comments panel's edge */
  left: theme.spacing(-2),
  /**
   * extending width to have new indicator width = comments panel width
   * 100% = commentGroupWidth
   * adding 1rem = padding on comments container on left + right
   */
  width: `calc(100% + ${theme.spacing(4)})`,
  [`&.${commentGroupClasses.newIndicatorOnFirstCommentInGroup}`]: {
    /**
     * when new indicator is visible on a comment which is first child of the group,
     * we move new indicator upwards to show it on the border of the comment group.
     */
    transform: 'translateY(-50%)',
  },
}));

export const CommentSeparator = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 3),
}));

export const Comment = styled(AcadlyComment)(({ theme }) => ({
  padding: theme.spacing(3),
}));
