import { forwardRef, memo, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogActions } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import clsx from 'clsx';

import { i18nNS } from '../../../i18n';
import { formatTimeToDate } from '../../../utils/datetime';
import { omit } from '../../../utils/helpers';
import Button from '../../Button';
import TextFieldBase, { Props as TextFieldBaseProps } from '../TextFieldBase';
import useTimepickerVM, { Props as VMProps } from './vm';

const TimepickerActionBarFactory = (okayId: string, cancelId: string) => {
  const TimepickerActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {
    const { t } = useTranslation([i18nNS.GLOSSARY]);
    return (
      <DialogActions>
        <Button variant="text" id={cancelId} onClick={onCancel}>
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Button>
        <Button id={okayId} onClick={onAccept}>
          {t('okay', { ns: i18nNS.GLOSSARY })}
        </Button>
      </DialogActions>
    );
  };
  return memo(TimepickerActionBar);
};

export interface Props
  extends VMProps,
    Pick<
      TextFieldBaseProps,
      | 'label'
      | 'placeholder'
      | 'required'
      | 'size'
      | 'error'
      | 'helperIcon'
      | 'helperText'
      | 'fullWidth'
      | 'disabled'
      | 'className'
    > {
  classes?: Partial<
    Record<'root' | 'timepickerRoot' | 'textFieldRoot', string> & TextFieldBaseProps['classes']
  >;
}

const Timepicker = (
  {
    label,
    required = false,
    size = 'medium',
    placeholder,
    disabled,
    helperIcon,
    helperText,
    error = false,
    fullWidth,
    className,
    classes,
    ...vmOptions
  }: Props,
  ref: Ref<HTMLInputElement>
) => {
  const { value } = vmOptions;
  const {
    okayId,
    cancelId,
    isTimeDialOpen,
    handleOpenTimeDial,
    handleCloseTimeDial,
    handleChange,
    handleClick,
  } = useTimepickerVM(vmOptions);

  return (
    <div ref={ref} className={clsx(className, classes?.root)} onClick={handleClick}>
      <MobileTimePicker
        open={isTimeDialOpen}
        onClose={handleCloseTimeDial}
        toolbarTitle={label}
        disableOpenPicker={disabled}
        disabled={disabled}
        value={value ? formatTimeToDate(value) : null}
        onChange={handleChange}
        className={classes?.timepickerRoot}
        components={{
          ActionBar: TimepickerActionBarFactory(okayId, cancelId),
        }}
        DialogProps={{
          onClose: (event: MouseEvent) => event.stopPropagation(),
        }}
        renderInput={({ ...params }) => {
          const {
            InputLabelProps,
            InputProps,
            /** ignored props */
            defaultValue,
            value,
            onInvalid,
            onKeyDown,
            onKeyUp,
            margin,
            ...restParams
          } = params;
          const { ref: inputBaseRef, startAdornment, endAdornment, className } = InputProps!;
          return (
            <TextFieldBase
              {...restParams}
              label={label}
              required={required}
              fullWidth={fullWidth}
              /** Props applied to Input tag */
              InputBaseRef={inputBaseRef as Ref<any>}
              inputProps={{ ...restParams.inputProps, onClick: undefined, placeholder }}
              classes={{
                ...(classes ? omit(classes, 'root', 'timepickerRoot', 'textFieldRoot') : {}),
                root: clsx(classes?.textFieldRoot),
                inputBase: clsx(className, classes?.inputBase),
              }}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              size={size}
              error={error}
              autoCapitalize="off"
              autoCorrect="off"
              autoComplete="off"
              disabled={disabled}
              onClick={handleOpenTimeDial}
              /** Props applied to HelperText */
              helperIcon={helperIcon}
              helperText={helperText}
            />
          );
        }}
      />
    </div>
  );
};

export default forwardRef(Timepicker);
