import { useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import FeedbackDialog from '../../feedback/FeedbackDialog';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import MessageSquareIcon from '../../icons/MessageSquareIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';
import useFeedbackVM from './vm';

const Feedback = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.FEEDBACK]);

  const { isFeedbackDialogOpen, handleCloseFeedbackDialog, handleFeedback } = useFeedbackVM();

  return (
    <>
      <ListItemButton
        onClick={handleFeedback}
        aria-label={t('click_to_give_your_valuable_feedback', { ns: i18nNS.SETTINGS })}
        aria-haspopup
      >
        <ListItemIcon>
          <MessageSquareIcon />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h6Regular" component="div">
            {t('feedback', { ns: i18nNS.GLOSSARY })}
          </Typography>
        </ListItemText>
        <ChevronRightIcon color="disabled" />
      </ListItemButton>
      <FeedbackDialog open={isFeedbackDialogOpen} onClose={handleCloseFeedbackDialog} />
    </>
  );
};

export default Feedback;
