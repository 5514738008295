import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { BlueprintCoursePreview } from '../../../db/courses/types';
import { i18nNS } from '../../../i18n';

interface Props {
  activities?: BlueprintCoursePreview['activities'];
}

const CopySummary = ({ activities }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  return (
    <Stack gap={3}>
      <Typography variant="h6Bold" component="div">
        {t('copying_course_activities', { ns: i18nNS.CREATE_COURSE })}
      </Typography>
      <Typography>
        {t(
          'when_a_course_is_copied_acadly_will_use_its_activities_to_intelligently_make_suggestions_for_class_activities_in_the_new_course',
          { ns: i18nNS.CREATE_COURSE }
        )}
      </Typography>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="paragraphMedium" component="span">
            {t('published_quizzes', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          <Typography component="span">{activities?.quizzes || 0}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="paragraphMedium" component="span">
            {t('published_polls', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          <Typography component="span">{activities?.polls || 0}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="paragraphMedium" component="span">
            {t('published_resources', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          <Typography component="span">{activities?.resources || 0}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="paragraphMedium" component="span">
            {t('published_discussions', { ns: i18nNS.CREATE_COURSE })}
          </Typography>
          <Typography component="span">{activities?.discussions || 0}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CopySummary;
