import { styled } from '@mui/material';

import AcadlyDrawer from '../../../../components/Drawer';
import { generateClasses } from '../../../../utils/helpers';

export const commentComposerDrawerClasses = {
  ...generateClasses('CommentComposerDrawer', [
    'drawerContent',
    'richTextEditor',
    'richTextEditorBody',
    'richTextEditorEditable',
    'removeAttachmentButton',
  ]),
};

export const Drawer = styled(AcadlyDrawer)(({ theme }) => ({
  [`& .${commentComposerDrawerClasses.drawerContent}`]: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  [`& .${commentComposerDrawerClasses.richTextEditor}`]: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },

  [`& .${commentComposerDrawerClasses.richTextEditorBody}`]: {
    maxHeight: '100%',
    overflowY: 'auto',
  },

  [`& .${commentComposerDrawerClasses.richTextEditorEditable}`]: {
    minHeight: 200,
  },

  [`& .${commentComposerDrawerClasses.removeAttachmentButton}`]: {
    display: 'none',
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const Image = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: 150,
}));
