import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../../components/ForNonDesktop';
import ChatButton from '../../components/HeaderActions/ChatButton';
import CloseButton from '../../components/HeaderActions/CloseButton';
import { selectPoll } from '../../db/polls/selectors';
import { i18nNS } from '../../i18n';
import routes, { PollParams } from '../../pages/routes';
import { getUnseenComments } from '../../utils/activities';
import { forDesktop } from '../../utils/media-queries';

const usePollAnalyticsHeaderActionsVM = () => {
  const params = useParams<PollParams>();
  const poll = useSelector(selectPoll(params.pollShortId));

  const numUnseenComments = poll ? getUnseenComments(poll) : 0;

  return { params, numUnseenComments };
};

const PollAnalyticsHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.POLL]);

  const { params, numUnseenComments } = usePollAnalyticsHeaderActionsVM();

  return (
    <>
      <CloseButton
        to={routes.poll.url(params)}
        edge={isDesktop ? 'end' : undefined}
        aria-label={t('click_to_close_poll_analytics', { ns: i18nNS.POLL })}
      />
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default PollAnalyticsHeaderActions;
