import { Ref, useCallback, useImperativeHandle, useRef } from 'react';

import { DownstreamZoomAppEvent, ZoomAppEvent } from '@acadly/zoom-app';

import { useEventListener } from '../../utils/hooks';
import { ZoomAppInstance } from '../types';

export interface VmProps {
  /**
   * Iframe origin address.
   * @example
   * 'https://app.acadly.com', 'https://localhost:3005'
   */
  origin: string;
  /**
   * Called when an event is received from zoom-app iframe, it subscribes on
   * window's `message` event and filters messages posted from zoom-app
   */
  onEventReceived?: (event: ZoomAppEvent.Upstream) => void;
}

export function useZoomAppVM({ origin, onEventReceived }: VmProps, ref: Ref<ZoomAppInstance>) {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  /**
   * Send event to iframe from parent window
   */
  const sendEvent = useCallback(
    (event: ZoomAppEvent.Downstream) => {
      if (!iframeRef.current) return;
      const win = iframeRef.current.contentWindow;
      if (!win) return;
      win.postMessage(event, origin);
    },
    [origin]
  );

  /**
   * Receive event from iframe to parent window
   */
  const receiveEvent = useCallback(
    (message: MessageEvent) => {
      if (message.origin !== origin || !message.data) return;
      const event = message.data as ZoomAppEvent.Upstream;
      onEventReceived?.(event);
    },
    [onEventReceived, origin]
  );

  // subscribe to window's "message" event
  useEventListener('message', receiveEvent);

  useImperativeHandle<ZoomAppInstance, ZoomAppInstance>(
    ref,
    () => ({
      joinMeeting: (payload) => {
        sendEvent({
          type: DownstreamZoomAppEvent.JOIN_MEETING,
          ...payload,
        });
      },
      sendEvent,
    }),
    [sendEvent]
  );

  return { iframeRef };
}
