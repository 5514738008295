import { Draft } from '@reduxjs/toolkit';

import { API } from '../../shared/api-responses';
import { Resource } from '../types';
import { updateResourceContent } from './resource-content';

export function updateResource({
  resource,
  title,
  description,
  sequenceNum,
  content,
  editedBy,
  editedOn,
  publishedOn,
}: {
  resource: Draft<Resource>;
  title: Resource['title'];
  description: Resource['description'];
  sequenceNum?: Resource['sequenceNum'];
  content: API.ResourceContent;
  editedBy?: Resource['editedBy'];
  editedOn?: Resource['editedOn'];
  publishedOn?: Resource['publishedOn'];
}): Resource {
  const updatedResource = updateResourceContent({
    resource,
    content,
  });

  updatedResource.title = title;
  updatedResource.description = description;

  if (sequenceNum) {
    updatedResource.sequenceNum = sequenceNum;
  }

  if (editedBy) {
    updatedResource.editedBy = editedBy;
  }

  if (editedOn) {
    updatedResource.editedOn = editedOn;
  }

  if (publishedOn) {
    updatedResource.publishedOn = publishedOn;
  }

  return updatedResource;
}
