import { Trans, useTranslation } from 'react-i18next';

import { Link, Stack } from '@mui/material';

import Button from '../../../components/Button';
import Typography from '../../../components/Typography';
import { ZoomAuthorizationData } from '../../../db/online-meeting/types';
import { i18nNS } from '../../../i18n';
import Skeleton from './Skeleton';
import { AddToZoomButton, AddToZoomImage, List, ZoomLogo } from './styles';

interface Props {
  isAuthorizing: boolean;
  zoomAuthorizationData: ZoomAuthorizationData | undefined;
}

const ZoomIntegrationDialogContent = ({ isAuthorizing, zoomAuthorizationData }: Props) => {
  const { t } = useTranslation([i18nNS.ONLINE_MEETING, i18nNS.COMMON]);

  if (isAuthorizing) return <Skeleton />;

  if (!zoomAuthorizationData) return null;

  const { isAllowed, isAuthorized, hasProCourses, authorizationUrl, revokeUrl } = zoomAuthorizationData;

  if (!isAllowed) {
    return (
      <Stack gap={4}>
        <Typography variant="paragraphRegular" color="grey.800">
          {t(
            'acadly_s_plugin_for_zoom_is_an_acadly_pro_feature_that_is_available_for_verified_instructors_only',
            { ns: i18nNS.ONLINE_MEETING }
          )}
        </Typography>
        <Typography variant="paragraphBold" color="grey.800">
          <Trans
            t={t}
            ns={i18nNS.ONLINE_MEETING}
            i18nKey="to_be_a_verified_acadly_instructor_please_send_a_request_to_support_acadly_com_we_will_get_back_to_you_within_24_hours"
          >
            To be a verified Acadly instructor, please send a request to{' '}
            <Link href="mailto:support@acadly.com">support@acadly.com</Link>. We will get back to you within
            24 hours.
          </Trans>
        </Typography>
      </Stack>
    );
  }

  if (!isAuthorized) {
    if (!hasProCourses) {
      return (
        <Stack gap={4}>
          <Typography variant="paragraphRegular" color="grey.800">
            {t(
              'acadly_s_plugin_for_zoom_is_an_acadly_pro_feature_that_enables_seamless_online_hybrid_and_hy_flex_instruction_using_just_the_acadly_interface_and_your_existing_zoom_account',
              { ns: i18nNS.ONLINE_MEETING }
            )}
          </Typography>
          <Typography variant="paragraphBold" color="grey.800">
            {t(
              'to_integrate_zoom_with_acadly_you_must_create_a_free_trial_course_to_take_acadly_pro_for_a_test_run',
              { ns: i18nNS.ONLINE_MEETING }
            )}
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack gap={4}>
        <ZoomLogo
          src="https://s3.amazonaws.com/static.acad.ly/img/zoom-icon.png"
          alt={t('zoom_icon', { ns: i18nNS.ONLINE_MEETING })}
        />
        <Typography variant="h5Bold" color="grey.800" textAlign="center">
          {t('authorize_acadly_to_use_zoom', { ns: i18nNS.ONLINE_MEETING })}
        </Typography>
        <Stack gap={2}>
          <Typography variant="h6Bold" color="grey.800">
            {t('once_added_acadly_will_be_able_to', { ns: i18nNS.ONLINE_MEETING })}
          </Typography>
          <List>
            <li>
              <Typography variant="paragraphRegular" color="grey.800">
                {t('create_and_manage_zoom_meetings_for_your_classes', { ns: i18nNS.ONLINE_MEETING })}
              </Typography>
            </li>
            <li>
              <Typography variant="paragraphRegular" color="grey.800">
                {t('manage_student_participation_in_the_created_zoom_meetings', {
                  ns: i18nNS.ONLINE_MEETING,
                })}
              </Typography>
            </li>
          </List>
        </Stack>
        <Stack gap={2}>
          <Typography variant="h6Bold" color="grey.800">
            {t('privacy_policy', { ns: i18nNS.COMMON })}
          </Typography>
          <List>
            <li>
              <Typography variant="paragraphRegular" color="grey.800">
                {t(
                  'acadly_uses_and_stores_your_zoom_account_details_to_create_meetings_on_your_behalf_and_capture_user_events_for_these_meetings_to_maintain_the_integrity_of_the_meeting',
                  { ns: i18nNS.ONLINE_MEETING }
                )}
              </Typography>
            </li>
            <li>
              <Typography variant="paragraphRegular" color="grey.800">
                {t('acadly_only_captures_events_for_the_meetings_that_are_created_using_acadly', {
                  ns: i18nNS.ONLINE_MEETING,
                })}
              </Typography>
            </li>
            <li>
              <Typography variant="paragraphRegular" color="grey.800">
                {t(
                  'when_acadly_is_uninstalled_from_your_zoom_account_all_your_zoom_account_details_are_deleted_irrevocably_from_acadly_s_servers',
                  { ns: i18nNS.ONLINE_MEETING }
                )}
              </Typography>
            </li>
          </List>
        </Stack>
        <AddToZoomButton
          variant="text"
          component={Link}
          href={authorizationUrl}
          target="_blank"
          aria-label={t('click_to_install_acadly_at_zoom_marketplace', { ns: i18nNS.ONLINE_MEETING })}
        >
          <AddToZoomImage
            src="https://s3.amazonaws.com/static.acad.ly/img/add_to_zoom.png"
            alt={t('add_to_zoom', { ns: i18nNS.ONLINE_MEETING })}
          />
        </AddToZoomButton>
      </Stack>
    );
  }

  return (
    <Stack gap={4}>
      <ZoomLogo
        src="https://s3.amazonaws.com/static.acad.ly/img/zoom-icon.png"
        alt={t('zoom_icon', { ns: i18nNS.ONLINE_MEETING })}
      />
      <Typography variant="h5Bold" color="grey.800" textAlign="center">
        {t('uninstall_acadly_from_zoom', { ns: i18nNS.ONLINE_MEETING })}
      </Typography>
      <Typography variant="paragraphRegular" color="grey.800">
        {t(
          'you_can_deauthorize_acadly_by_navigating_to_the_zoom_marketplace_and_uninstalling_manually_once_deauthorized_acadly_will_remove_all_the_zoom_data_associated_with_your_user_account',
          { ns: i18nNS.ONLINE_MEETING }
        )}
      </Typography>
      <Button
        variant="text"
        component={Link}
        href={revokeUrl}
        target="_blank"
        aria-label={t('click_to_unistall_acadly_at_zoom_marketplace', { ns: i18nNS.ONLINE_MEETING })}
      >
        {t('uninstall_at_zoom_marketplace')}
      </Button>
    </Stack>
  );
};

export default ZoomIntegrationDialogContent;
