import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import AttachmentView from '../../../../../../../../../components/AttachmentView';
import RichTextView from '../../../../../../../../../components/RichText/View';
import { AttachmentType } from '../../../../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../../../../i18n';
import ChevronDownIcon from '../../../../../../../../../icons/ChevronDownIcon';
import {
  commentDetailsClasses,
  Image,
  RemovedCommentContent,
  StyledCommentStats,
  StyledCommentStatsButton,
} from './styles';
import useCommentDetailsVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const CommentDetails = ({ comment }: Props) => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const { attachment, canSeeCommentStats, handleToggleCommentStats } = useCommentDetailsVM({ comment });

  return (
    <Stack direction="column" alignItems="flex-start" gap={2}>
      {comment.isDeleted ? (
        <RemovedCommentContent>{comment.message}</RemovedCommentContent>
      ) : (
        <>
          <RichTextView richtext={comment.message} />
          {attachment?.type === AttachmentType.IMAGE && (
            <Image src={attachment.thumbURL} alt={t('attachment', { ns: i18nNS.GLOSSARY })} />
          )}
          {attachment?.type === AttachmentType.FILE && (
            <AttachmentView
              color="error"
              attachment={{
                originalName: attachment.originalName,
                name: attachment.name,
                extension: attachment.extension,
              }}
              requestConfig={attachment.requestConfig}
            />
          )}
          <StyledCommentStatsButton
            commentId={comment.id}
            edge="start"
            className={commentDetailsClasses.accordion}
            endIcon={<ChevronDownIcon className={commentDetailsClasses.accordionIcon} />}
            aria-label={t('this_comment_has_num_likes_num_thanks_and_num_awards_click_to_see_details', {
              ns: i18nNS.COMMENTS,
              numLikes: comment.stats.likes,
              numThanks: comment.stats.thanks,
              numAwards: comment.stats.awards,
            })}
            onClick={handleToggleCommentStats}
          />
          {canSeeCommentStats && <StyledCommentStats commentId={comment.id} />}
        </>
      )}
    </Stack>
  );
};

export default CommentDetails;
