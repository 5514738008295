import { useMemo } from 'react';

import {
  CircularProgress as MuiCircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  styled,
} from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../utils/helpers';

interface VMProps {
  /** @default 'medium' */
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge';
}

const useSpinnerVM = ({ size }: VMProps) => {
  const computedSize = useMemo(() => {
    switch (size) {
      case 'xSmall':
        return 16;
      case 'small':
        return 20;
      case 'large':
        return 28;
      case 'xLarge':
        return 32;
      default:
        return 24;
    }
  }, [size]);

  return { computedSize };
};

interface Props extends VMProps, DistributiveOmit<CircularProgressProps, 'color' | 'size'> {
  /** @defalt 'primary' */
  color?: 'primary' | 'success' | 'error' | 'warning';
}

const spinnerClasses = {
  ...circularProgressClasses,
  ...generateClasses('Spinner', ['colorPrimary', 'colorSuccess', 'colorWarning', 'colorError']),
};

const CircularProgress = styled(MuiCircularProgress)(({ theme }) => ({
  [`&.${spinnerClasses.colorPrimary}`]: {
    color: theme.palette.primary[600],
  },
  [`&.${spinnerClasses.colorSuccess}`]: {
    color: theme.palette.success[600],
  },
  [`&.${spinnerClasses.colorWarning}`]: {
    color: theme.palette.warning[600],
  },
  [`&.${spinnerClasses.colorError}`]: {
    color: theme.palette.error[600],
  },
}));

const Spinner = ({ color, size, className, ...props }: Props) => {
  const { computedSize } = useSpinnerVM({ size });

  return (
    <CircularProgress
      {...props}
      className={clsx(
        {
          [spinnerClasses.colorPrimary]: color === 'primary',
          [spinnerClasses.colorSuccess]: color === 'success',
          [spinnerClasses.colorWarning]: color === 'warning',
          [spinnerClasses.colorError]: color === 'primary',
        },
        className
      )}
      size={computedSize}
    />
  );
};

export default Spinner;
