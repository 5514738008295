import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { selectAuthSession } from '../../../auth/store/selectors';
import { selectOnlineMeeting } from '../../../db/online-meeting/selectors';
import routes, { ClassParams } from '../../../pages/routes';
import { toShortID } from '../../../utils/helpers';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useZoomAppFooterLayoutVM() {
  const match = useRouteMatch<ClassParams>({
    path: routes.activities.path,
    exact: true,
  });

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const onlineDetails = useSelector(selectOnlineMeeting(classId));
  const session = useSelector(selectAuthSession);

  const { classShortId } = match?.params || {};

  const isOnClassPage = Boolean(classShortId);

  const handleGoToClassPage = () => {
    if (!session || !onlineDetails) return;
    routes.class.navigate({
      universitySlug: session?.university.slug,
      courseShortId: toShortID(onlineDetails.courseId),
      classShortId: toShortID(onlineDetails.classId),
    });
  };

  return {
    isOnClassPage,
    handleGoToClassPage,
  };
}
