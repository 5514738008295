import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import Button from '../../../components/Button';
import TextField from '../../../components/HookFormControls/TextField';
import AcadlyNote from '../../../components/Note/index';
import { i18nNS } from '../../../i18n';
import AlertCircleIcon from '../../../icons/AlertCircleIcon';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import CreateCourseForm from '../CreateCourseForm';
import useBasicDetailsVM from './vm';

const Title = styled((props: TypographyProps) => <Typography {...props} variant="h5Bold" />)(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const Note = styled(AcadlyNote)(({ theme }) => ({
  marginTop: 'auto',
}));

interface Props {
  id?: string;
}

const BasicDetails = ({ id }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);

  const {
    closeDrawer,
    control,
    handleCreateCourse,
    isVisible,
    isSubmitting,
    canMoveToPreviousStep,
    moveToPreviousStep,
  } = useBasicDetailsVM();

  return (
    <CreateCourseForm
      id={id}
      isVisible={isVisible}
      onSubmit={handleCreateCourse}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          {canMoveToPreviousStep ? (
            <Button
              type="button"
              variant="text"
              startIcon={<ChevronLeftIcon />}
              onClick={moveToPreviousStep}
              aria-label={t('click_to_go_to_previous_step', { ns: i18nNS.COMMON })}
            >
              {t('back', { ns: i18nNS.GLOSSARY })}
            </Button>
          ) : (
            <div />
          )}
          <Stack direction="row" gap={3}>
            <Button
              type="button"
              variant="text"
              onClick={closeDrawer}
              aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              isLoading={isSubmitting}
              endIcon={<ChevronRightIcon />}
              aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('next', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Stack gap={5}>
        <Title>{t('step_1_colon_provide_basic_details', { ns: i18nNS.CREATE_COURSE })}</Title>
        <TextField
          fullWidth
          control={control}
          characterLimit={16}
          name="courseCode"
          label={t('course_code', { ns: i18nNS.CREATE_COURSE })}
          helperText={t('allowed_characters_a_hyphen_z_0_hyphen_9_dot_hyphen_underscore', {
            ns: i18nNS.CREATE_COURSE,
          })}
          autoFocus
        />
        <TextField
          fullWidth
          control={control}
          name="courseTitle"
          characterLimit={140}
          label={t('course_title', { ns: i18nNS.CREATE_COURSE })}
        />
      </Stack>
      <Note icon={<AlertCircleIcon color="inherit" />} color="warning" variant="h6Regular">
        {t('a_course_s_name_and_course_code_cannot_be_changed_later_please_verify_before_you_proceed', {
          ns: i18nNS.CREATE_COURSE,
        })}
      </Note>
    </CreateCourseForm>
  );
};

export default BasicDetails;
