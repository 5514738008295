import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { MAIN_CONTENT_BODY_ID } from '../constants';
import { setPageStatus as setPageStatusAction } from './actions';
import { getAcadlyRouteByName } from './helpers';
import routes from './routes';
import { selectPageStatus } from './selectors';
import { AcadlyPage, PageStatus } from './types';

const usePageScrollPosition = (page: AcadlyPage, canScroll: boolean) => {
  const scrollTopRef = useRef<number | null>(null);

  const { pathname } = useLocation();
  const params = useParams();

  const getScrollContainer = () => {
    const mainContentBody = document.getElementById(MAIN_CONTENT_BODY_ID);
    if (!mainContentBody) {
      console.error(`element with id \`${MAIN_CONTENT_BODY_ID}\` not found`);
      return;
    }
    return mainContentBody;
  };

  const onNext = useCallback(
    (nextPage: AcadlyPage) => {
      if (!canScroll) return;
      if (nextPage !== page) return;

      const scrollContainer = getScrollContainer();
      if (!scrollContainer) return;

      scrollContainer.scrollTo(0, 0);
    },
    [canScroll, page]
  );

  const onPrevious = useCallback(
    (previousPage: AcadlyPage) => {
      if (!canScroll) return;
      if (previousPage !== page) return;

      const scrollContainer = getScrollContainer();
      if (!scrollContainer) return;

      if (!scrollTopRef.current) return;

      scrollContainer.scrollTo(0, scrollTopRef.current);
    },
    [canScroll, page]
  );

  useEffect(
    function updateScrollPosition() {
      const scrollContainer = getScrollContainer();
      if (!scrollContainer) return;

      const handleScroll = () => {
        const route = getAcadlyRouteByName(page);
        /** Only update scrollTop of current page */
        if (route?.url(params) === pathname && canScroll) {
          scrollTopRef.current = scrollContainer.scrollTop;
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    },
    [page, params, pathname, canScroll]
  );

  return { onNext, onPrevious };
};

export const useIsPageReady = (page: AcadlyPage) => {
  const status = useSelector(selectPageStatus(page));
  return status === PageStatus.READY;
};

export const usePageManager = (
  page: AcadlyPage,
  options?: { setPageReadyWhenParentIsReady?: boolean; canScroll?: boolean }
) => {
  const { setPageReadyWhenParentIsReady = false, canScroll = true } = options || {};

  const isUnmounted = useRef(false);

  const dispatch = useDispatch();
  const { dependsOn } = useMemo(() => getAcadlyRouteByName(page) || routes.root, [page]);

  const parentStatus = useSelector(selectPageStatus(dependsOn));
  const selfStatus = useSelector(selectPageStatus(page));

  const isParentReady = useMemo(() => parentStatus === PageStatus.READY, [parentStatus]);
  const isPageReady = useMemo(
    () => isParentReady && selfStatus === PageStatus.READY,
    [isParentReady, selfStatus]
  );

  const { onNext, onPrevious } = usePageScrollPosition(page, canScroll);

  const setPageStatus = useCallback(
    (status: PageStatus) => dispatch(setPageStatusAction({ page: page, status })),
    [dispatch, page]
  );

  const setPageReady = useCallback(() => {
    if (isUnmounted.current) {
      // do nothing if page is unmounted
      return;
    }

    setPageStatus(PageStatus.READY);
  }, [setPageStatus]);

  useEffect(
    function onMount() {
      isUnmounted.current = false;

      return function onUnmount() {
        isUnmounted.current = true;
        // set page to not_ready on unmount
        setPageStatus(PageStatus.NOT_READY);
      };
    },
    [setPageStatus]
  );

  useEffect(
    function autoSetPageStatus() {
      if (isParentReady && setPageReadyWhenParentIsReady) {
        // set page to ready when parent page is ready if auto setting is true
        setPageStatus(PageStatus.READY);
      }
    },
    [isParentReady, setPageReadyWhenParentIsReady, setPageStatus]
  );

  const { pathname } = useLocation();
  const params = useParams();

  useEffect(
    function onVisitNext() {
      if (isPageReady) return;
      onNext(page);
    },
    [isPageReady, page, pathname, onNext]
  );

  useEffect(
    function onVisitPrevious() {
      if (!isPageReady) return;
      const route = getAcadlyRouteByName(page);
      if (!route || route.url(params) !== pathname) return;
      onPrevious(page);
    },
    /**
     * isPageReady is intentionally avoided in dependency array
     * to avoid calling onVisitPrevious when page gets ready
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, page, params, onPrevious]
  );

  return { isParentReady, isPageReady, setPageReady, setPageStatus };
};
