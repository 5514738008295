import { useEffect, useRef } from 'react';

import { useFocused, useSelected, useSlateStatic } from 'slate-react';

import { uploadAndUpdateFormulaNode, wrapWithMathJaxDelimiters } from '../helpers';
import { FormulaElement, FormulaUploadStatus } from '../types';

export interface VMProps {
  element: FormulaElement;
}

export default function useFormulaElement({ element }: VMProps) {
  const editor = useSlateStatic();

  const isFocused = useFocused();
  const isSelected = useSelected();

  const ref = useRef<HTMLDivElement>(null);

  const { code, display, format } = element;

  useEffect(
    function upload() {
      if (element.uploadStatus !== FormulaUploadStatus.NOT_UPLOADED) return;
      uploadAndUpdateFormulaNode(editor, element);
    },
    [editor, element]
  );

  useEffect(
    function initFormula() {
      const parent = ref.current;
      if (!parent) return;

      const element = document.createElement('formula');
      element.setAttribute('contenteditable', 'false');
      element.innerHTML = wrapWithMathJaxDelimiters({ code, display, format });

      parent.append(element);
      window.MathJax.typeset?.([element]);

      return () => {
        window.MathJax.typesetClear?.([element]);
        parent.removeChild(element);
      };
    },
    [code, display, format]
  );

  return {
    isFocused,
    isSelected,
    ref,
  };
}
