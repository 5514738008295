import { Skeleton, Stack } from '@mui/material';

import TextFieldSkeleton from '../../../components/FormControls/TextField/Skeleton';
import Typography from '../../../components/Typography';

const ChangeNameSkeleton = () => {
  return (
    <Stack gap={4}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" gap={4}>
        <Typography variant="paragraphRegular">
          <Skeleton variant="text" width={120} />
        </Typography>
        <Typography variant="paragraphMedium">
          <Skeleton variant="text" width={120} />
        </Typography>
      </Stack>
      <Typography variant="textSmMedium">
        <Skeleton variant="text" width={240} />
      </Typography>
      <TextFieldSkeleton hasLabel size="large" />
      <TextFieldSkeleton hasLabel size="large" />
      <TextFieldSkeleton hasLabel size="large" />
      <Typography variant="paragraphMedium">
        <Skeleton variant="text" width={240} />
      </Typography>
    </Stack>
  );
};

export default ChangeNameSkeleton;
