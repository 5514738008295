import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import AlertCircleIcon from '../../../icons/AlertCircleIcon';
import CourseEndedDrawer from './CourseEndedDrawer';
import useDeadlineOverVM from './vm';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 4),
  backgroundColor: theme.palette.warning[50],
  color: theme.palette.warning[900],
}));

const DeadlineOver = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);

  const {
    isVisible,
    canEditCourseDeadline,
    isOpenCourseEndedDrawer,
    openCourseEndedDrawer,
    closeCourseEndedDrawer,
  } = useDeadlineOverVM();

  if (!isVisible) return null;

  return (
    <>
      <Root>
        <Stack direction="row" alignItems="center" gap={1}>
          <AlertCircleIcon color="inherit" variant="filled" fontSize="medium" />
          <Typography component="span" color="inherit">
            {t('this_course_is_over', { ns: i18nNS.COURSES })}
          </Typography>
        </Stack>
        {canEditCourseDeadline && (
          <Button
            color="warning"
            variant="outlined"
            onClick={openCourseEndedDrawer}
            aria-label={t('click_to_extend_course_deadline_or_archive_the_course', { ns: i18nNS.COURSES })}
          >
            {t('options', { ns: i18nNS.GLOSSARY })}
          </Button>
        )}
      </Root>
      {canEditCourseDeadline && (
        <CourseEndedDrawer open={isOpenCourseEndedDrawer} onClose={closeCourseEndedDrawer} />
      )}
    </>
  );
};

export default DeadlineOver;
