import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { AddCourseBookPayload, CourseBook } from './types';

export async function getCourseBooks(data: API.GetCourseBooksRequest) {
  const { books } = (await request.post<API.GetCourseBooksResponse>(urls.getCourseBooks, data)).data;
  return books.map<CourseBook>((book) => ({
    id: book._id,
    title: book.details.title,
    author: book.details.author,
    isbn: book.details.isbn,
    isRecommended: Boolean(book.recommended),
  }));
}

export async function addCourseBook({ isRecommended, ...data }: AddCourseBookPayload) {
  const payload: API.AddCourseBookRequest = { ...data, recommended: isRecommended ? 1 : 0 };
  const { bookId } = (await request.post<API.AddCourseBookResponse>(urls.addCourseBook, payload)).data;
  const book: CourseBook = { ...data, id: bookId, isRecommended };
  return book;
}

export async function editCourseBook({ id: bookId, isRecommended, ...data }: CourseBook) {
  const payload: API.EditCourseBookRequest = { ...data, bookId, recommended: isRecommended ? 1 : 0 };
  await request.post(urls.editCourseBook, payload);
}

export async function removeCourseBook(data: API.RemoveCourseBookRequest) {
  await request.delete(urls.removeCourseBook, { data });
}
