import CommentList from './CommentList';
import { Root } from './styles';
import useCommentsScrollManagerVM from './vm';

const CommentsScrollManager = () => {
  const { commentListRef } = useCommentsScrollManagerVM();

  return (
    <Root ref={commentListRef}>
      <CommentList />
    </Root>
  );
};

export default CommentsScrollManager;
