import { Stack, StackProps, styled } from '@mui/material';

import Progressbar, { Props as ProgressbarProps } from '../../Progressbar';

export const AttachmentsSection = styled((props: StackProps) => <Stack gap={2} {...props} />)(
  ({ theme }) => ({
    marginTop: theme.spacing(4),
  })
);

export const Loader = styled((props: ProgressbarProps) => (
  <Progressbar shape="linear" variant="indeterminate" {...props} />
))(({ theme }) => ({
  width: 200,
}));
