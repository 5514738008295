import { Draft } from 'immer';

import { Discussion } from '../types';

export function updateDiscussion({
  title,
  description,
  editedOn,
  attachments,
  discussion,
  editedBy,
}: {
  discussion: Draft<Discussion>;
  title: Discussion['title'];
  description: Discussion['description'];
  editedOn?: Discussion['editedOn'];
  attachments?: Discussion['attachments'];
  editedBy?: Discussion['editedBy'];
}): Discussion {
  discussion.title = title;
  discussion.description = description;

  if (editedOn) {
    discussion.editedOn = editedOn;
  }

  if (editedBy) {
    discussion.editedBy = editedBy;
  }

  if (attachments) {
    discussion.attachments = attachments;
  }

  return discussion;
}
