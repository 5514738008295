import { AriaAttributes, ReactNode } from 'react';

import { Button, Chip, chipClasses, Stack, StackProps, styled } from '@mui/material';

import clsx from 'clsx';

import MessageCircleIcon from '../../icons/MessageCircleIcon';
import { ellipsize } from '../../styles/mixins';
import { generateClasses } from '../../utils/helpers';
import Typography, { Props as TypographyProps } from '../Typography';

export enum GridAreas {
  ICON = 'icon',
  HEADER = 'header',
  HEADER_META = 'headerMeta',
  DESCRIPTION = 'description',
}

const activityWidgetVariant = ['default', 'primary', 'success', 'error'] as const;

export type ActivityWidgetVariant = typeof activityWidgetVariant[number];

export const activityWidgetClasses = generateClasses('ActivityWidget', [
  ...activityWidgetVariant,
  'root',
  GridAreas.ICON,
  GridAreas.HEADER,
  'title',
  'tag',
  GridAreas.HEADER_META,
  GridAreas.DESCRIPTION,
]);

interface WrapperProps extends AriaAttributes {
  className?: string;
  onClick?: React.MouseEventHandler;
  children?: ReactNode;
}

const Wrapper = ({ onClick, children, className, ...props }: WrapperProps) => {
  if (onClick) {
    return (
      <Button {...props} className={clsx(className, activityWidgetClasses.root)} onClick={onClick}>
        {children}
      </Button>
    );
  }
  return (
    <div {...props} className={clsx(className, activityWidgetClasses.root)}>
      {children}
    </div>
  );
};

export const Root = styled(Wrapper)(({ theme }) => ({
  [`&.${activityWidgetClasses.root}`]: {
    display: 'grid',
    gridTemplateAreas: `
      "${GridAreas.ICON} ${GridAreas.HEADER} ${GridAreas.HEADER_META}"
      "${GridAreas.ICON} ${GridAreas.DESCRIPTION} ${GridAreas.DESCRIPTION}"
    `,
    gridTemplateColumns: 'auto 1fr auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(0, 3),
    padding: theme.spacing(3),
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    textTransform: 'none',
    textAlign: 'unset',
    width: '100%',
  },
  [`& .${activityWidgetClasses[GridAreas.ICON]}`]: {
    gridArea: GridAreas.ICON,
  },
  [`& .${activityWidgetClasses[GridAreas.HEADER]}`]: {
    gridArea: GridAreas.HEADER,
  },
  [`& .${activityWidgetClasses[GridAreas.HEADER_META]}`]: {
    gridArea: GridAreas.HEADER_META,
  },
  [`& .${activityWidgetClasses[GridAreas.DESCRIPTION]}`]: {
    gridArea: GridAreas.DESCRIPTION,
  },
}));

export const IconWrapper = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="center" alignItems="center" {...props} />
))(({ theme }) => ({
  borderRadius: 4,
  padding: theme.spacing(2),
  '& svg': {
    fontSize: theme.typography.pxToRem(36),
  },
  [`&.${activityWidgetClasses.default}`]: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[50],
  },
  [`&.${activityWidgetClasses.primary}`]: {
    color: theme.palette.primary[900],
    backgroundColor: theme.palette.primary[50],
  },
  [`&.${activityWidgetClasses.success}`]: {
    color: theme.palette.success[700],
    backgroundColor: theme.palette.success[50],
  },
  [`&.${activityWidgetClasses.error}`]: {
    color: theme.palette.error[800],
    backgroundColor: theme.palette.error[50],
  },
}));

export const UnseenCommentsIcon = styled(MessageCircleIcon)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

export const UnseenCommentsBadge = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  borderRadius: 5,
  height: 16,
  backgroundColor: theme.palette.error[500],
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}));

export const Description = styled((props: TypographyProps) => (
  <Typography variant="paragraphMedium" color="grey.400" {...props} />
))(({ theme }) => ({
  ...ellipsize,
}));
