import PusherJS from 'pusher-js';
import { eventChannel } from 'redux-saga';

import { UserSession } from '../auth/types';
import { unix } from '../utils/datetime';
import Logger from '../utils/logger';
import { registerPusher } from './api';
import {
  CreatePusherEventOptions,
  PusherChannel,
  PusherEvent,
  PusherEventCreator,
  PusherEventMetaData,
  PusherEventName,
  PusherEventPayload,
  PusherSubscriptionError,
} from './types';

const logger = Logger.create('pusher');

export async function createPusher(session: UserSession) {
  if (!session.socket) throw new Error('socket information missing');

  await registerPusher();

  const pusher = new PusherJS(session.socket.key, {
    cluster: session.socket.cluster,
    authEndpoint: `${session.cluster}/pusher/auth`,
    auth: {
      headers: {
        Authorization: session.token,
      },
    },
  });

  return pusher;
}

/**
 * Pusher channel map by pusher event name. Used to find out
 * the channel on which the event should be forwarded
 */
const eventCreatorsByEventName: {
  [key in PusherEventName]?: PusherEventCreator<any, PusherEventName>;
} = {};

export const createPusherEvent = <P extends {} = never, EventName extends PusherEventName = PusherEventName>(
  eventName: EventName,
  options: CreatePusherEventOptions
) => {
  const meta: PusherEventMetaData = {
    notify: options.notify ?? true,
    ignoreForSameUser: options.ignoreForSameUser ?? true,
    receivedOn: options.channels[0],
    eventName: eventName,
  };

  const eventCreator: PusherEventCreator<P, EventName> = (payload: P, channel: PusherChannel) => {
    return {
      type: eventName,
      payload,
      meta: {
        ...meta,
        receivedOn: channel,
      },
    };
  };

  eventCreator.eventName = eventName;
  eventCreator.channels = options.channels;

  eventCreator.toString = () => eventName;
  eventCreator.match = (event): event is PusherEvent<P, EventName> => event.type === eventName;

  /**
   * Store it to a map so that after pusher subscription
   * subscriber can create the event
   */
  eventCreatorsByEventName[eventName] = eventCreator;

  return eventCreator;
};

/**
 * Creates a saga-channel and subscribes to all pusher events
 * on provided pusher-channel. This saga-channel emits received
 * events to corresponding pusher event creator, if it exists.
 */
export function createPusherSagaChannel({
  pusher,
  channel,
}: {
  /** pusher instance */
  pusher: PusherJS;
  channel: {
    /** unique channel id used for channel subscription */
    id: string;
    /** name used identify channels by name */
    name: PusherChannel;
  };
}) {
  const unsubscribe = () => {
    logger.info(`un-subscribing from channel: ${channel.name}`);
    pusher.unsubscribe(channel.id);
  };

  const subscribe = (emitter: (input: PusherEvent<{}, PusherEventName>) => void) => {
    logger.info(`subscribing to channel: ${channel.name}`);

    const channelSubscription = pusher.subscribe(channel.id);

    channelSubscription.bind('pusher:subscription_error', ({ status }: PusherSubscriptionError) => {
      if (status === 403) {
        unsubscribe();
        // TODO: handle auth error
      }
    });

    // bind to all events on this channel
    channelSubscription.bind_global((eventName: PusherEventName, data: PusherEventPayload<{}>) => {
      const createEvent = eventCreatorsByEventName[eventName];

      // check if received event is bound to this channel
      if (!createEvent?.channels.includes(channel.name)) return;

      const event = createEvent({ ...data, timestamp: unix() }, channel.name);

      logger.info('Pusher event received:', event);

      emitter(event);
    });

    return unsubscribe;
  };

  return eventChannel(subscribe);
}
