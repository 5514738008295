import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Drawer, DrawerProps, styled, useMediaQuery } from '@mui/material';

import clsx from 'clsx';

import CommentsModule from '../../comments/CommentsModule';
import Progressbar from '../../components/Progressbar';
import Faq from '../../courses/Faq';
import AcadlyHelp from '../../courses/Help';
import { selectCurrentCourse } from '../../db/courses/selectors';
import { useIsPageReady } from '../../pages/hooks';
import routes from '../../pages/routes';
import { AcadlyPage } from '../../pages/types';
import { forDesktop, forMobile } from '../../utils/media-queries';
import { LayoutContext } from './Context';
import DiscussionPanelHeader from './DiscussionPanelHeader';

export const DISCUSSION_PANEL_WIDTH = 350;

const Root = styled('div')(({ theme }) => ({
  width: DISCUSSION_PANEL_WIDTH,
  maxWidth: '100vw',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',

  [forMobile(theme)]: {
    width: '100vw',
  },

  '&.expanded': {
    width: '70vw',
    maxWidth: 750,

    [forMobile(theme)]: {
      width: '100vw',
      maxWidth: 'unset',
    },
  },
}));

const Help = styled(AcadlyHelp)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
}));

const LoadingContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  backgroundColor: theme.palette.background.default,
}));

const usePageDiscussionVM = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const isCoursePageReady = useIsPageReady(AcadlyPage.COURSE);

  const { isCommentsPanelOpen, setIsCommentsPanelOpen, isCommentsPanelExpanded, setIsCommentsPanelExpanded } =
    useContext(LayoutContext);

  const variant: DrawerProps['variant'] = isDesktop ? 'permanent' : 'temporary';
  const isPageDiscussionOpen = isDesktop ? isCommentsPanelExpanded : isCommentsPanelOpen;
  const currentCourse = useSelector(selectCurrentCourse);

  const handleClose = () => {
    setIsCommentsPanelOpen(false);
    setIsCommentsPanelExpanded(false);
  };

  return {
    currentCourse,
    handleClose,
    isCommentsPanelExpanded,
    isCommentsPanelOpen,
    isCoursePageReady,
    isPageDiscussionOpen,
    variant,
  };
};

interface Props {
  className?: string;
}

const PageDiscussion = ({ className }: Props) => {
  const {
    currentCourse,
    handleClose,
    isCommentsPanelExpanded,
    isCoursePageReady,
    isPageDiscussionOpen,
    variant,
  } = usePageDiscussionVM();

  return (
    <Drawer anchor="right" variant={variant} open={isPageDiscussionOpen} onClose={handleClose}>
      <Root className={clsx(className, { expanded: isCommentsPanelExpanded })}>
        <DiscussionPanelHeader />
        <Switch>
          <Route path={[routes.home.path, routes.archives.path, routes.settings.path, routes.refer.path]}>
            <Faq />
          </Route>
          <Route>
            {!isCoursePageReady || !currentCourse ? (
              <LoadingContent>
                <Progressbar shape="circular" size={24} />
              </LoadingContent>
            ) : !currentCourse.status.isLive || !currentCourse.status.hasEnrollments ? (
              <Help />
            ) : (
              <CommentsModule />
            )}
          </Route>
        </Switch>
      </Root>
    </Drawer>
  );
};

export default PageDiscussion;
