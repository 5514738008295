import { notEmpty } from '../../utils/array';
import { API } from '../shared/api-responses';
import {
  ActivityDuration,
  CourseRole,
  CourseUser,
  DueDateType,
  QuizQuestionType,
  SubmissionStatus,
  ToBeDone,
} from '../shared/types';
import {
  MultipleChoiceQuestion,
  Quiz,
  QuizQuestion,
  QuizScoreWithMaxScore,
  SortingQuestion,
  SuggestedQuiz,
  TrueFalseQuestion,
} from './types';

export const MAX_QUIZ_QUESTION_MARKS = 4;

export function createPreferencesFactory(publishDefaults: API.PublishQuizDefaults): Quiz['preferences'] {
  return {
    toBeDone: publishDefaults.activityType,
    scoring: publishDefaults.scoring,
    allowLate: Boolean(publishDefaults.allowLate),
    deadlineFirst: Boolean(publishDefaults.deadlineFirst),
    dueDateType: publishDefaults.dueDateType,
    duration: publishDefaults.duration,
    randomQuestions: Boolean(publishDefaults.randomQues),
    randomOptions: Boolean(publishDefaults.randomOpt),
    subscribeToComments: Boolean(publishDefaults.subscribeToComments),
  };
}

interface QuizIdentifiers {
  courseId: MongoId;
  classId: MongoId;
}

interface CreateQuizOptions<T extends API.BaseQuiz> {
  quiz: T;
  identifiers: QuizIdentifiers;
  publishDefaults: API.PublishQuizDefaults | undefined;
  userData: API.QuizUserData | undefined;
  currentUser: CourseUser | undefined;
}

export function createQuizFactory<T extends API.BaseQuiz>({
  quiz,
  identifiers: { courseId, classId },
  publishDefaults,
  userData,
  currentUser,
}: CreateQuizOptions<T>): Quiz {
  const editedOn = quiz.details.lastEditedOn ?? -1;

  let studentDataById: Quiz['studentDataById'] = {};

  if (currentUser?.role === CourseRole.STUDENT) {
    studentDataById = {
      [currentUser.userId]: {
        userId: currentUser.userId,
        name: currentUser.name,
        avatar: currentUser.avatar,
        totalScore: userData?.score,
        submissionsByQuestionId: null,
        submittedOn: userData?.submittedOn ?? -1,
        status: userData?.status ?? SubmissionStatus.NOT_ATTEMPTED,
      },
    };
  }

  return {
    id: quiz._id,
    classId,
    courseId: courseId,
    title: quiz.details.title || null,
    instructions: quiz.details.instructions,
    attachments: quiz.details.attachments || [],
    toBeDone: quiz.details.toBeDone,
    dueDateTime: quiz.details.dueDateTime,
    sequenceNum: quiz.details.trueNum ?? 0,
    questions: [],
    questionsById: null,
    studentDataById,
    totalSubmissions: quiz.stats?.numSubmitted ?? 0,
    areQuestionsReordered: Boolean(quiz.details.reordered),
    areQuestionsEdited: false,
    preferences: publishDefaults
      ? createPreferencesFactory(publishDefaults)
      : {
          toBeDone: quiz.details.toBeDone,
          scoring: quiz.details.scoring,
          allowLate: Boolean(quiz.details.allowLate),
          deadlineFirst: Boolean(quiz.details.deadlineFirst),
          dueDateType: quiz.details.dueDateType,
          duration:
            quiz.details.toBeDone === ToBeDone.PRE_CLASS
              ? ActivityDuration.NOT_APPLICABLE
              : quiz.details.dueDateType === DueDateType.MANUAL
              ? ActivityDuration.MANUALLY_STOPPED
              : ActivityDuration.AFTER_5_MINUTES, // default is After 5 Minutes
          randomOptions: Boolean(quiz.details.randomOpt),
          randomQuestions: Boolean(quiz.details.randomQues),
          subscribeToComments: Boolean(userData?.subscribed),
        },
    comments: {
      seen: userData?.numCommentsSeen ?? 0,
      total: quiz.activities.numCommentsTotal,
      isSubscribed: Boolean(userData?.subscribed),
    },
    editedOn,
    editedBy: editedOn > 0 ? quiz.details.createdBy : null,
    createdOn: quiz.details.createdOn,
    createdBy: quiz.details.createdBy,
    firstAccessedOn:
      currentUser?.userId === quiz.details.createdBy.userId
        ? quiz.details.createdOn
        : userData?.firstAccessedOn ?? -1,
    lastAccessedOn: userData?.lastAccessedOn ?? -1,
    publishedOn: quiz.details.publishedOn > 0 ? quiz.details.publishedOn : -1,
  };
}

interface SuggestedQuizOptions<T extends API.SuggestedQuiz> {
  suggestedQuiz: T;
}

export function suggestedQuizFactory<T extends API.SuggestedQuiz>({
  suggestedQuiz,
}: SuggestedQuizOptions<T>): SuggestedQuiz {
  return {
    id: suggestedQuiz._id,
    classId: suggestedQuiz.identifiers.classId,
    courseId: suggestedQuiz.identifiers.courseId,
    title: suggestedQuiz.details.title || null,
    instructions: suggestedQuiz.details.instructions,
    attachments: suggestedQuiz.details.attachments || [],
    toBeDone: suggestedQuiz.details.toBeDone,
    questions: [],
    questionsById: null,
    areQuestionsEdited: false,
    preferences: {
      scoring: suggestedQuiz.details.scoring,
    },
    createdOn: suggestedQuiz.details.createdOn,
    createdBy: suggestedQuiz.details.createdBy,
    publishedOn: suggestedQuiz.details.publishedOn > 0 ? suggestedQuiz.details.publishedOn : -1,
    isHidden: Boolean(suggestedQuiz.hidden),
    isUsed: Boolean(suggestedQuiz.used),
    sequenceNum: suggestedQuiz.details.trueNum,
    courseCode: suggestedQuiz.identifiers.courseCode,
    courseTitle: suggestedQuiz.identifiers.courseTitle,
    maxMarks: 0,
    publishedOnDate: suggestedQuiz.details.publishedOnDate,
  };
}

export function createQuizQuestionFactory(question: API.QuizQuestion): QuizQuestion {
  if (question.details.type === QuizQuestionType.TF) {
    const tfq: TrueFalseQuestion = {
      id: question._id,
      description: question.details.description.text,
      num: question.details.order,
      type: question.details.type,
      answerKey: question.details.answerKey as 't' | 'f' | undefined,
    };
    return tfq;
  }

  if (question.details.type === QuizQuestionType.MCQ) {
    const mcq: MultipleChoiceQuestion = {
      id: question._id,
      description: question.details.description.text,
      num: question.details.order,
      type: question.details.type,
      answerKey: question.details.answerKey,
      options: question.details.options,
    };
    return mcq;
  }

  const sq: SortingQuestion = {
    id: question._id,
    description: question.details.description.text,
    num: question.details.order,
    type: question.details.type,
    answerKey: question.details.answerKey,
    options: question.details.options,
  };
  return sq;
}

export function getQuizMaxMarks(quiz: Quiz) {
  return Object.values(quiz.questionsById || {}).filter(notEmpty).length * MAX_QUIZ_QUESTION_MARKS;
}

export function getQuizTotalScore(submission: API.GetQuizDetailsResponse['submission'], maxScore: number) {
  if (!submission) return undefined;

  const hasScore = Object.values(submission).some((response) => {
    return response?.score != null;
  });

  if (!hasScore) return undefined;

  const totalScore: QuizScoreWithMaxScore = {
    maxScore,
    incentivizing: 0,
    neutral: 0,
    penalizing: 0,
  };

  for (const response of Object.values(submission)) {
    const score = response?.score;
    if (!score) continue;

    totalScore.incentivizing += score.incentivizing;
    totalScore.neutral += score.neutral;
    totalScore.penalizing += score.penalizing;
  }

  return totalScore;
}
