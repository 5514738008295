import Joi from 'joi';

import { optionalString, requiredString } from '../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  firstName: requiredString,
  middleName: optionalString,
  lastName: requiredString,
});
