import { useTranslation } from 'react-i18next';

import { MenuItem } from '@mui/material';

import TextFieldSkeleton from '../../../../components/FormControls/TextField/Skeleton';
import Select from '../../../../components/HookFormControls/Select';
import { i18nNS } from '../../../../i18n';
import useSelectTimezoneVM, { VMProps } from './vm';

interface Props extends VMProps {
  className?: string;
}

const SelectTimezone = ({ className, ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  const { control, controlName, setTimezone, timezones } = useSelectTimezoneVM(vmProps);

  if (!timezones) return <TextFieldSkeleton hasLabel />;

  return (
    <Select
      required
      className={className}
      control={control}
      name={controlName}
      disabled={!timezones}
      label={t('timezone', { ns: i18nNS.GLOSSARY })}
      onChange={setTimezone}
      placeholder={t('select_your_course_timezone', { ns: i18nNS.CREATE_COURSE })}
      autoFocus
    >
      {timezones.map((timezone) => (
        <MenuItem key={timezone.timezoneName} value={timezone.timezoneName}>
          {`${timezone.campusName} - ${timezone.readable}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTimezone;
