import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import AcadlyStripeInput, { Props as AcadlyStripeInputProps } from '../FormControls/StripeInput';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useStripeInput = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({
    control,
    name,
    defaultValue,
  });

  const onInputChange = (e: StripeCardElementChangeEvent) => {
    controller.field.onChange(e);
  };

  return { controller, onInputChange };
};

export type StripeInputProps<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<FieldValues, 'name'> &
  DistributiveOmit<AcadlyStripeInputProps, 'error'>;

const StripeInput = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  helperText,
  ...props
}: StripeInputProps<T>) => {
  const { controller, onInputChange } = useStripeInput({ control, name, defaultValue });
  const { fieldState } = controller;
  const { error } = fieldState;

  return (
    <AcadlyStripeInput
      {...props}
      onChange={onInputChange}
      error={Boolean(error)}
      helperText={error?.message || helperText}
    />
  );
};

export default StripeInput;
