import { FileAttachment } from '../../db/shared/types';
import AttachmentIcon from '../../icons.deprecated/AttachmentIcon';
import { ToolbarButton } from '../../rich-text';
import { AttachmentRequestConfig } from '../../types';
import UploadButton, { Props as UploadButtonProps } from '../UploadButton';

export interface Props<T extends Record<string, unknown>> {
  id?: string;
  onUpload: (attachement: FileAttachment) => any;
  onUploadError?: (error: unknown) => any;
  requestConfig: AttachmentRequestConfig<T>;
  isFocused?: boolean;
}

const AttachButton = <T extends Record<string, unknown> = {}>({
  id,
  requestConfig,
  onUpload,
  onUploadError,
  isFocused,
}: Props<T>) => {
  const handleUpload: UploadButtonProps['onUpload'] = ({ name, originalName, type }) => {
    return onUpload({ name, originalName, extension: type });
  };

  return (
    <UploadButton
      id={id}
      accept="*"
      requestConfig={requestConfig}
      onUpload={handleUpload}
      onUploadError={onUploadError}
    >
      <ToolbarButton component="span" tabIndex={-1} aria-selected={isFocused}>
        <AttachmentIcon fontSize="small" />
      </ToolbarButton>
    </UploadButton>
  );
};

export default AttachButton;
