import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const faqClasses = generateClasses('Faq', ['root', 'header', 'search', 'content', 'section']);

export const Root = styled('div')(({ theme }) => ({
  display: 'grid',
  overflow: 'auto',
  flex: 1,
  alignContent: 'flex-start',
  backgroundColor: theme.palette.background.level1,

  [`.${faqClasses.header}`]: {
    flexShrink: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2.5, 5),
  },

  [`.${faqClasses.content}`]: {
    flex: 1,
    alignContent: 'flex-start',
    display: 'grid',
    gap: theme.spacing(2),
    padding: theme.spacing(5),
  },
}));
