import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Divider, Stack } from '@mui/material';

import Alert from '../components/Alert';
import Button from '../components/Button';
import TextField from '../components/HookFormControls/TextField';
import Section from '../components/Section';
import Typography from '../components/Typography';
import { i18nNS } from '../i18n';
import PlusIcon from '../icons/PlusIcon';
import routes from '../pages/routes';
import { useDocumentTitle } from '../utils/hooks';
import { AddOptionWrapper, Field, Root } from './styles';
import useReferVM from './vm';

const Refer = () => {
  const { t } = useTranslation([i18nNS.REFER, i18nNS.GLOSSARY, i18nNS.COMMON]);
  useDocumentTitle(t('acadly_referral', { ns: i18nNS.REFER }));

  const {
    rootRef,
    formMethods,
    fields,
    handleAppendReferral,
    onSubmit,
    isPostSubmitAlertOpen,
    handleClosePostSubmitAlert,
  } = useReferVM();
  const { handleSubmit, control } = formMethods;

  return (
    <Root ref={rootRef}>
      <Stack gap={4}>
        <Typography variant="h6Bold" color="grey.800">
          {t('help_us_spread_the_word', { ns: i18nNS.REFER })}
        </Typography>
        <Stack gap={2}>
          <Typography variant="paragraphRegular" color="grey.800">
            {t(
              'hi_there_thank_you_for_using_acadly_this_semester_if_it_has_been_a_useful_resource_for_you_so_far_you_can_play_a_big_role_in_helping_us_grow_and_improve_by_sparing_a_minute_of_your_time',
              { ns: i18nNS.REFER }
            )}
          </Typography>
          <Typography variant="paragraphRegular" color="grey.800">
            {t(
              'we_re_still_in_our_early_days_and_a_positive_word_or_referral_from_you_could_contribute_significantly_to_our_growth',
              { ns: i18nNS.REFER }
            )}
          </Typography>
          <Typography variant="paragraphRegular" color="grey.800">
            {t(
              'if_you_could_share_the_full_names_and_or_email_addresses_of_any_colleagues_from_universities_who_may_find_acadly_useful_we_will_reach_out_to_them',
              { ns: i18nNS.REFER }
            )}
          </Typography>
        </Stack>
        <Section title={t('send_an_invite_to', { ns: i18nNS.REFER })}>
          <Stack divider={<Divider />}>
            {fields.map((field, index) => (
              <Field key={field.id}>
                <TextField
                  control={control}
                  name={`referrals.${index}.text`}
                  label={t('email_or_full_name', { ns: i18nNS.REFER })}
                  fullWidth
                  autoFocus={index === 0}
                />
              </Field>
            ))}
            <AddOptionWrapper>
              <Button
                variant="outlined"
                onClick={handleAppendReferral}
                aria-label={t('click_to_add_another_option', { ns: i18nNS.COMMON })}
                startIcon={<PlusIcon />}
                fullWidth
              >
                {t('add_another_option', { ns: i18nNS.COMMON })}
              </Button>
            </AddOptionWrapper>
          </Stack>
        </Section>
        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          aria-label={t('click_to_submit_your_referrals', { ns: i18nNS.REFER })}
        >
          {t('submit', { ns: i18nNS.GLOSSARY })}
        </Button>
        <Alert open={isPostSubmitAlertOpen}>
          <Alert.Body>{t('success_do_you_want_to_refer_more_people', { ns: i18nNS.REFER })}</Alert.Body>
          <Alert.Footer>
            <Alert.Action
              variant="text"
              component={Link}
              to={routes.settings.path}
              onClick={handleClosePostSubmitAlert}
              aria-label={t('click_to_go_back_to_settings', { ns: i18nNS.REFER })}
            >
              {t('no', { ns: i18nNS.GLOSSARY })}
            </Alert.Action>
            <Alert.Action
              onClick={handleClosePostSubmitAlert}
              aria-label={t('click_to_refer_more_people', { ns: i18nNS.REFER })}
            >
              {t('yes', { ns: i18nNS.GLOSSARY })}
            </Alert.Action>
          </Alert.Footer>
        </Alert>
      </Stack>
    </Root>
  );
};

export default Refer;
