import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Alert from '../../../components/Alert';
import Drawer from '../../../components/Drawer';
import TextField from '../../../components/HookFormControls/TextField';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { joinName } from '../../../utils/name';
import ChangeNameSkeleton from './Skeleton';
import { CancelButton, SaveButton } from './styles';
import useChangeNameDrawerVM, { VMProps } from './vm';

interface Props extends VMProps {}

const ChangeNameDrawer = (vmOptions: Props) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.COMMON]);

  const { open } = vmOptions;

  const {
    handleCloseDrawer,
    currentUser,
    formMethods,
    onSubmit,
    isFetchingRemainingNameChangeCount,
    remainingNameChangeCount,
  } = useChangeNameDrawerVM(vmOptions);

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    formState: { isValid },
  } = formMethods;
  const [firstName, middleName, lastName] = watch(['firstName', 'middleName', 'lastName']);

  const newName = joinName(firstName, middleName, lastName);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      header={t('changing_name', { ns: i18nNS.AUTH })}
      footer={
        <>
          <CancelButton
            variant="text"
            onClick={handleCloseDrawer}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </CancelButton>
          <SaveButton
            onClick={() => trigger()}
            canOpenAlert={isValid}
            aria-label={t('click_to_change_name', { ns: i18nNS.COMMON })}
          >
            {t('save', { ns: i18nNS.GLOSSARY })}
            <Alert>
              <Alert.Body>
                <Stack gap={2}>
                  <Typography variant="paragraphRegular">
                    {t('are_you_sure_you_want_to_change_your_name_from_old_name_to_new_name', {
                      ns: i18nNS.AUTH,
                      oldName: currentUser.name,
                      newName,
                    })}
                  </Typography>
                  <Typography variant="paragraphRegular">
                    {t(
                      'you_will_be_logged_out_of_acadly_from_all_your_devices_the_change_would_reflect_upon_logging_back_in',
                      { ns: i18nNS.AUTH }
                    )}
                  </Typography>
                </Stack>
              </Alert.Body>
              <Alert.Footer>
                <Alert.Action variant="text">{t('cancel', { ns: i18nNS.GLOSSARY })}</Alert.Action>
                <Alert.Action onClick={isValid ? handleSubmit(onSubmit) : undefined}>
                  {t('yes', { ns: i18nNS.GLOSSARY })}
                </Alert.Action>
              </Alert.Footer>
            </Alert>
          </SaveButton>
        </>
      }
    >
      {isFetchingRemainingNameChangeCount ? (
        <ChangeNameSkeleton />
      ) : (
        <Stack gap={4} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={4}>
            <Typography variant="paragraphRegular" color="grey.600">
              {t('current_name', { ns: i18nNS.AUTH })}
            </Typography>
            <Typography variant="paragraphMedium" color="grey.900">
              {currentUser.name}
            </Typography>
          </Stack>
          <Typography variant="textSmMedium">
            {t('please_enter_your_full_name', { ns: i18nNS.AUTH })}
          </Typography>
          <TextField
            control={control}
            name="firstName"
            label={t('first_name', { ns: i18nNS.AUTH })}
            required
            size="large"
            placeholder={t('type_your_first_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
            autoFocus
          />
          <TextField
            control={control}
            name="middleName"
            label={t('middle_name', { ns: i18nNS.AUTH })}
            size="large"
            placeholder={t('type_your_middle_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
          />
          <TextField
            control={control}
            name="lastName"
            label={t('last_name', { ns: i18nNS.AUTH })}
            required
            size="large"
            placeholder={t('type_your_last_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
          />
          <Typography variant="paragraphMedium">
            {t('you_can_change_your_name_N_times', {
              ns: i18nNS.AUTH,
              count: remainingNameChangeCount,
            })}
          </Typography>
        </Stack>
      )}
    </Drawer>
  );
};

export default ChangeNameDrawer;
