import i18n, { i18nNS } from '../../i18n';
import { SignupTeacherOrganization, SignupTeacherUseCase } from '../types';

interface Option<T extends string> {
  value: T;
  title: string;
}

export const organizations: Option<SignupTeacherOrganization>[] = [
  {
    value: SignupTeacherOrganization.UNIVERSITY,
    title: i18n.t('university_college', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherOrganization.K12,
    title: i18n.t('k_12_school', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherOrganization.VOCATIONAL,
    title: i18n.t('vocational_training_school', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherOrganization.COMPANY,
    title: i18n.t('company_or_govt_organization', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherOrganization.NON_PROFIT,
    title: i18n.t('non_profit', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherOrganization.INDEPENDENT,
    title: i18n.t('independent_instructor_presenter', { ns: i18nNS.AUTH }),
  },
];

export const useCasesType1: Option<SignupTeacherUseCase>[] = [
  {
    value: SignupTeacherUseCase.IN_PERSON_COURSE,
    title: i18n.t('an_in_person_course', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.LIVE_ONLINE_COURSE,
    title: i18n.t('a_synchronous_live_online_course', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.ASYNC_COURSE,
    title: i18n.t('an_asynchronous_self_paced_course', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.STANDALONE_EVENT,
    title: i18n.t('standalone_seminar_event_talk', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.NA,
    title: i18n.t('none_of_the_above', { ns: i18nNS.AUTH }),
  },
];

export const useCasesType2: Option<SignupTeacherUseCase>[] = [
  {
    value: SignupTeacherUseCase.INT_ONLINE_TRAINING,
    title: i18n.t('internal_online_training', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.INT_OFFLINE_TRAINING,
    title: i18n.t('internal_in_person_training', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.CUST_ONLINE_TRAINING,
    title: i18n.t('online_training_for_customers', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.CUST_OFFLINE_TRAINING,
    title: i18n.t('in_person_training_for_customers', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.STANDALONE_EVENT,
    title: i18n.t('standalone_seminar_event_talk', { ns: i18nNS.AUTH }),
  },
  {
    value: SignupTeacherUseCase.NA,
    title: i18n.t('none_of_the_above', { ns: i18nNS.AUTH }),
  },
];
