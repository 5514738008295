import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';
import { requiredString } from '../../utils/validators';
import { MIN_PASSWORD_LENGTH } from '../constants';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  verificationCode: requiredString.min(MIN_PASSWORD_LENGTH).messages({
    ...requiredString.messages,
    'string.min': i18n.t('the_password_should_be_at_least_N_characters_long', {
      ns: i18nNS.VALIDATION,
      min: MIN_PASSWORD_LENGTH,
    }),
  }),
});
