import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Datepicker from '../../../../components/HookFormControls/Datepicker';
import Note from '../../../../components/Note/index';
import { i18nNS } from '../../../../i18n';
import AlertCircleIcon from '../../../../icons/AlertCircleIcon';
import { dateToUnix, unixToDate } from '../../../../utils/datetime';
import { CancelButton, Footer, SaveButton } from './styles';
import useEditCourseDatesVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const EditCourseDates = ({ onClose: handleClose }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);

  const { formMethods, onSubmit, initialEndDate } = useEditCourseDatesVM({ onClose: handleClose });
  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Stack gap={5} height="100%" component="form" onSubmit={handleSubmit(onSubmit)}>
      <Datepicker
        control={control}
        name="startDate"
        disabled
        fullWidth
        label={t('start_date', { ns: i18nNS.COURSES })}
        parser={unixToDate}
        formatter={dateToUnix}
        inputFormat="MMM dd, yyyy"
        aria-label={t('click_to_edit_end_date', { ns: i18nNS.COURSES })}
      />
      <Datepicker
        control={control}
        name="endDate"
        fullWidth
        minDate={initialEndDate}
        parser={unixToDate}
        formatter={dateToUnix}
        inputFormat="MMM dd, yyyy"
        label={t('end_date', { ns: i18nNS.COURSES })}
        aria-label={t('click_to_edit_end_date', { ns: i18nNS.COURSES })}
      />
      <Note icon={<AlertCircleIcon />} color="warning">
        {t(
          'you_wont_be_able_to_add_classes_activities_announcements_etc_after_the_course_end_date_it_is_advised_that_you_keep_a_buffer_of_a_few_days_after_the_last_class_date',
          { ns: i18nNS.COURSES }
        )}
      </Note>
      <Footer>
        <CancelButton
          variant="text"
          onClick={handleClose}
          aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
        >
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </CancelButton>
        <SaveButton
          type="submit"
          isLoading={isSubmitting}
          aria-label={t('click_to_extend_course_deadline', { ns: i18nNS.COURSES })}
        >
          {t('save', { ns: i18nNS.GLOSSARY })}
        </SaveButton>
      </Footer>
    </Stack>
  );
};

export default EditCourseDates;
