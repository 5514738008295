import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const LockIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
          fill="currentColor"
        />
        <path
          d="M17 8H16V6.11C16 5.04913 15.5786 4.03172 14.8284 3.28157C14.0783 2.53143 13.0609 2.11 12 2.11C10.9391 2.11 9.92172 2.53143 9.17157 3.28157C8.42143 4.03172 8 5.04913 8 6.11V8H7C6.20435 8 5.44129 8.31607 4.87868 8.87868C4.31607 9.44129 4 10.2044 4 11V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V11C20 10.2044 19.6839 9.44129 19.1213 8.87868C18.5587 8.31607 17.7956 8 17 8ZM10 6.11C9.98655 5.5653 10.1894 5.03745 10.5643 4.642C10.9391 4.24655 11.4554 4.0157 12 4C12.5446 4.0157 13.0609 4.24655 13.4357 4.642C13.8106 5.03745 14.0135 5.5653 14 6.11V8H10V6.11ZM12 18C11.4067 18 10.8266 17.8241 10.3333 17.4944C9.83994 17.1648 9.45542 16.6962 9.22836 16.1481C9.0013 15.5999 8.94189 14.9967 9.05764 14.4147C9.1734 13.8328 9.45912 13.2982 9.87868 12.8787C10.2982 12.4591 10.8328 12.1734 11.4147 12.0576C11.9967 11.9419 12.5999 12.0013 13.1481 12.2284C13.6962 12.4554 14.1648 12.8399 14.4944 13.3333C14.8241 13.8266 15 14.4067 15 15C15 15.7957 14.6839 16.5587 14.1213 17.1213C13.5587 17.6839 12.7956 18 12 18Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 8H16V6.11C16 5.04913 15.5786 4.03172 14.8284 3.28157C14.0783 2.53143 13.0609 2.11 12 2.11C10.9391 2.11 9.92172 2.53143 9.17157 3.28157C8.42143 4.03172 8 5.04913 8 6.11V8H7C6.20435 8 5.44129 8.31607 4.87868 8.87868C4.31607 9.44129 4 10.2044 4 11V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V11C20 10.2044 19.6839 9.44129 19.1213 8.87868C18.5587 8.31607 17.7956 8 17 8ZM10 6.11C9.98655 5.5653 10.1894 5.03745 10.5643 4.642C10.9391 4.24655 11.4554 4.0157 12 4C12.5446 4.0157 13.0609 4.24655 13.4357 4.642C13.8106 5.03745 14.0135 5.5653 14 6.11V8H10V6.11ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H17C17.2652 10 17.5196 10.1054 17.7071 10.2929C17.8946 10.4804 18 10.7348 18 11V19Z"
        fill="currentColor"
      />
      <path
        d="M12 12C11.4067 12 10.8266 12.1759 10.3333 12.5056C9.83994 12.8352 9.45542 13.3038 9.22836 13.852C9.0013 14.4001 8.94189 15.0033 9.05764 15.5853C9.1734 16.1672 9.45912 16.7018 9.87868 17.1213C10.2982 17.5409 10.8328 17.8266 11.4147 17.9424C11.9967 18.0581 12.5999 17.9987 13.1481 17.7716C13.6962 17.5446 14.1648 17.1601 14.4944 16.6667C14.8241 16.1734 15 15.5933 15 15C15 14.2044 14.6839 13.4413 14.1213 12.8787C13.5587 12.3161 12.7956 12 12 12ZM12 16C11.8022 16 11.6089 15.9414 11.4444 15.8315C11.28 15.7216 11.1518 15.5654 11.0761 15.3827C11.0004 15.2 10.9806 14.9989 11.0192 14.8049C11.0578 14.6109 11.153 14.4327 11.2929 14.2929C11.4327 14.153 11.6109 14.0578 11.8049 14.0192C11.9989 13.9806 12.2 14.0004 12.3827 14.0761C12.5654 14.1518 12.7216 14.28 12.8315 14.4444C12.9414 14.6089 13 14.8022 13 15C13 15.2652 12.8946 15.5196 12.7071 15.7071C12.5196 15.8946 12.2652 16 12 16Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default LockIcon;
