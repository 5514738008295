import { Course as DbCourse } from '../db/courses/types';

export interface GetCourseDetails {
  title: string;
  courseCode: string;
  universityName: string;
  admin: {
    name: string;
  };
}

export type CourseWidgetData = RequiredKeys<
  Partial<DbCourse>,
  'id' | 'code' | 'title' | 'myRole' | 'schedule' | 'status' | 'team'
>;

export enum ClassModality {
  ONLINE = 'online',
  IN_PERSON = 'in_person',
}

export enum CourseExportActivityType {
  CLASSES = 'classes',
  QUIZZES = 'quizzes',
  POLLS = 'polls',
  ASSIGNMENTS = 'assignments',
  PARTICIPATION = 'participation',
  /**
   * Comment stats of all contexts in a course
   */
  ALL_COMMENTS_STATS = 'all_comments_stats',
}
