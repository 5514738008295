import { Stack, Typography } from '@mui/material';

import { Props as ButtonProps } from '../../../../../../../../components/Button';
import AwardIcon from '../../../../../../../../icons/AwardIcon';
import GiftIcon from '../../../../../../../../icons/GiftIcon';
import ThumbsUpIcon from '../../../../../../../../icons/ThumbsUpIcon';
import { Root } from './styles';
import useCommentStatsButtonVM, { Props as VMProps } from './vm';

export interface Props extends VMProps, ButtonProps {}

const CommentStatsButton = ({ commentId, ...props }: Props) => {
  const { numLikes, numThanks, canShowAwards, numAwardPoints } = useCommentStatsButtonVM({ commentId });

  if (!(numLikes > 0 || numThanks > 0 || (canShowAwards && numAwardPoints > 0))) return null;

  return (
    <Root {...props}>
      <Stack direction="row" alignItems="center" gap={2} aria-hidden>
        <ThumbsUpIcon
          variant={numLikes > 0 ? 'filled' : 'outlined'}
          color={numLikes > 0 ? 'primary' : 'inherit'}
          aria-hidden
        />
        <Typography variant="paragraphBold" component="span" color={numLikes > 0 ? 'primary' : 'inherit'}>
          {numLikes}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2} aria-hidden>
        <GiftIcon
          variant={numThanks > 0 ? 'filled' : 'outlined'}
          color={numThanks > 0 ? 'primary' : 'inherit'}
          aria-hidden
        />
        <Typography variant="paragraphBold" component="span" color={numThanks > 0 ? 'primary' : 'inherit'}>
          {numThanks}
        </Typography>
      </Stack>
      {canShowAwards && (
        <Stack direction="row" alignItems="center" gap={2} aria-hidden>
          <AwardIcon
            variant={numAwardPoints > 0 ? 'filled' : 'outlined'}
            color={numAwardPoints > 0 ? 'primary' : 'inherit'}
            aria-hidden
          />
          <Typography
            variant="paragraphBold"
            component="span"
            color={numAwardPoints > 0 ? 'primary' : 'inherit'}
          >
            {numAwardPoints}
          </Typography>
        </Stack>
      )}
    </Root>
  );
};

export default CommentStatsButton;
