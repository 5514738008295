import { API } from '../shared/api-responses';
import {
  Assignment,
  AssignmentGradeData,
  AssignmentQuestion,
  AssignmentStats,
  QuestionSubmission,
  QuestionSubmissionType,
} from './types';

export function toAssignmentQuestion(question: API.AssignmentQuestion): AssignmentQuestion {
  return {
    id: question._id,
    description: question.details.description.text,
    submissionType: question.details.submission,
    maxMarks: question.details.marks,
    attachments: question.details.attachments,
    extensions: question.details.submitExt,
    createdBy: question.details.createdBy,
    createdOn: question.details.createdOn,
  };
}

export function toQuestionSubmission(s: API.AssignmentQuestionSubmission): QuestionSubmission {
  let submission: QuestionSubmission;

  const submissionType = s.file ? QuestionSubmissionType.FILE : QuestionSubmissionType.URL;

  if (submissionType === QuestionSubmissionType.FILE) {
    submission = {
      submissionType: QuestionSubmissionType.FILE,
      file: s.file!,
      marks: s.marks,
      maxMarks: s.maxMarks,
      prevSubmissions: (s.prevSub || []).map((sub) => ({
        submittedOn: sub.submittedOn,
        file: sub.file ?? null,
      })),
      remarks: s.comments.content ? s.comments : null,
    };
  } else {
    submission = {
      submissionType: QuestionSubmissionType.URL,
      url: s.url?.url ?? '',
      marks: s.marks,
      maxMarks: s.maxMarks,
      prevSubmissions: (s.prevSub || []).map((sub) => ({
        submittedOn: sub.submittedOn,
        url: sub.url ?? null,
      })),
      remarks: s.comments.content ? s.comments : null,
      savedOn: s.url?.savedOn ?? -1,
    };
  }

  return submission;
}

export function toAssignmentGradeData(data: API.AssignmentGradeData | undefined): AssignmentGradeData | null {
  if (!data) return null;

  return {
    gradedBy: data.lastGradedBy,
    gradedOn: data.on,
    score: data.score,
  };
}

export function getAssignmentStats(assignment: Assignment | undefined) {
  const stats: AssignmentStats = {
    averageScore: 0,
    graded: assignment?.gradedSubmissions ?? 0,
    submissions: assignment?.totalSubmissions ?? 0,
  };

  if (!assignment?.studentDataById) return stats;

  let gradedScore = 0;

  for (const studentData of Object.values(assignment.studentDataById)) {
    if (!studentData || studentData.submittedOn <= 0) continue;
    if (!studentData.gradeData) continue;

    stats.graded++;
    gradedScore += studentData.gradeData.score;
  }

  const maxScore = stats.graded * assignment.preferences.maxMarks;
  stats.averageScore = (gradedScore * 100) / maxScore || 0;

  return stats;
}
