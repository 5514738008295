import { Stack, StackProps, styled, Typography, TypographyProps } from '@mui/material';

import Section from '../../components/Section/index';
import Placeholder from '../../components/Section/Placeholder';
import { MAIN_CONTENT_WIDTH } from '../../styles/theme';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  maxWidth: MAIN_CONTENT_WIDTH,
  margin: '0 auto',
  padding: theme.spacing(4, 0),
}));

const Instructions = styled((props: TypographyProps) => <Typography variant="paragraphRegular" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey[900],
    padding: theme.spacing(3, 5),
  })
);

const Row = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} {...props} />
))(({ theme }) => ({
  padding: theme.spacing(3, 5),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const StyledTypography = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[800],
}));

const EmphasizedTitle = styled((props: StackProps) => <Stack gap={2} {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.primary[100],
  padding: theme.spacing(5),
}));

const SectionPlayground = () => {
  return (
    <Root>
      <Section title="Instructions" variant="default">
        There are no special instructions. Just attempt the questions. Best of luck!
      </Section>
      <Section title="Instructions" variant="default">
        <Instructions>
          There are no special instructions. Just attempt the questions. Best of luck!
        </Instructions>
      </Section>
      <Section title="Questions" variant="transparent">
        No questions added yet!
      </Section>
      <Section title="Questions" variant="transparent">
        <Placeholder>
          <StyledTypography>No questions added yet!</StyledTypography>
          <StyledTypography>But shortly we will add them!</StyledTypography>
        </Placeholder>
      </Section>
      <Section title="Quiz Analytics" variant="emphasized">
        No Data Yet!
      </Section>
      <Section title="Quiz Analytics" variant="emphasized">
        <Row>
          <StyledTypography>Total Submissions</StyledTypography>
          <StyledTypography variant="paragraphBold">4</StyledTypography>
        </Row>
        <Row>
          <StyledTypography>Scoring scheme</StyledTypography>
          <StyledTypography variant="paragraphBold">Neutral</StyledTypography>
        </Row>
        <Row>
          <StyledTypography>Average score</StyledTypography>
          <StyledTypography variant="paragraphBold">33.33%</StyledTypography>
        </Row>
      </Section>
      <Section
        title={
          <EmphasizedTitle>
            <StyledTypography variant="h5Bold">Submissions</StyledTypography>
            <StyledTypography>As of July 12, 2022</StyledTypography>
          </EmphasizedTitle>
        }
        variant="emphasized"
      >
        <Row>
          <StyledTypography>Total Submissions</StyledTypography>
          <StyledTypography variant="paragraphBold">4</StyledTypography>
        </Row>
        <Row>
          <StyledTypography>Scoring scheme</StyledTypography>
          <StyledTypography variant="paragraphBold">Neutral</StyledTypography>
        </Row>
        <Row>
          <StyledTypography>Average score</StyledTypography>
          <StyledTypography variant="paragraphBold">33.33%</StyledTypography>
        </Row>
      </Section>
    </Root>
  );
};

export default SectionPlayground;
