import { useTranslation } from 'react-i18next';
import { Link, Route, Switch } from 'react-router-dom';

import { Typography } from '@mui/material';

import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import { i18nNS } from '../../i18n';
import routes from '../../pages/routes';
import PublishInfo from '../Info/PublishInfo';
import PublishCourse from './PublishCourse';
import { AppBar } from './styles';
import { CourseTabs } from './types';
import useCourseHeaderVM from './vm';

const CourseHeader = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);
  const { activeTab, canPublishInfo, params } = useCourseHeaderVM();
  return (
    <>
      <AppBar>
        <Tabs value={activeTab} centered>
          <Tab
            id="course-timeline-tab"
            aria-controls="course-timeline-tabpanel"
            component={Link}
            to={routes.timeline.url(params)}
            value={CourseTabs.TIMELINE}
            label={<Typography component="span">{t('timeline', { ns: i18nNS.GLOSSARY })}</Typography>}
          />
          <Tab
            id="course-info-tab"
            aria-controls="course-info-tabpanel"
            component={Link}
            to={routes.info.url(params)}
            value={CourseTabs.INFO}
            label={<Typography component="span">{t('info', { ns: i18nNS.GLOSSARY })}</Typography>}
          />
          <Tab
            id="course-syllabus-tab"
            aria-controls="course-syllabus-tabpanel"
            component={Link}
            to={routes.syllabus.url(params)}
            value={CourseTabs.SYLLABUS}
            label={<Typography component="span">{t('syllabus', { ns: i18nNS.GLOSSARY })}</Typography>}
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={routes.timeline.path}>
          <PublishCourse />
        </Route>
        <Route path={routes.info.path}>{canPublishInfo && <PublishInfo />}</Route>
      </Switch>
    </>
  );
};

export default CourseHeader;
