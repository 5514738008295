import { appActions } from './app/store';
import { logout } from './auth/store/actions';
import store from './store';
import { setAcadlyTimeOffset } from './utils/datetime';
import Logger from './utils/logger';
import { request } from './utils/request';

export default function registerInterceptors() {
  request.interceptors.request.use((config) => {
    const state = store.getState();
    const { session } = state.auth;
    const courseId = state.db.courses.currentCourse;

    const headers = config.headers;

    if (!config.baseURL) {
      if (session?.token) {
        headers.Authorization = `Bearer ${session.token}`;
      }

      if (session?.cluster) {
        config.baseURL = session.cluster;
      }

      if (session) {
        headers.appClient = 'web';
        headers.appVersion = `web-${process.env.REACT_APP_VERSION}`;
        headers.univSlug = headers.univSlug || session?.university.slug;
      }

      if (courseId) {
        headers.courseId = headers.courseId || courseId;
      }
    }

    return config;
  });

  request.interceptors.response.use(
    (response) => {
      const { acadlytime } = response.headers;
      if (!acadlytime) return response;

      setAcadlyTimeOffset(+acadlytime);

      return response;
    },
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const message =
          error.response.data.message ||
          'Some error occured, please try after sometime. If the issue persists, please contact us at support@acadly.com';
        store.dispatch(appActions.setError(message));

        if (error.response.status === 401) {
          store.dispatch(logout({ message: "You've been logged out. Please log in again." }));
        } else {
          Logger.debug({
            message,
            request: {
              status: error.response.status,
              data: error.response.data,
            },
          });
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        const message = `Our servers didn't respond. Please try after some time.`;
        store.dispatch(appActions.setError(message));
      } else {
        // Something happened in setting up the request that triggered an Error
        const message =
          'Some error occured, please try after sometime. If the issue persists, please contact us at support@acadly.com';
        store.dispatch(appActions.setError(message));
        Logger.error(error);
      }

      // forward error
      return Promise.reject(error);
    }
  );
}
