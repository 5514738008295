import { RootState } from '../../store/types';
import { notEmpty } from '../../utils/array';
import { SHORT_ID_LENGTH } from '../../utils/helpers';
import { getFullId } from '../shared/helpers';
import { RecordingFile } from '../shared/types';
import { ZoomParticipant } from './types';

export const selectZoomAuthorizationData = (state: RootState) => {
  return state.db.onlineMeetings.zoomAuthorizationData;
};

export const selectClassRecordingData = (classId: ShortId | MongoId) => (state: RootState) => {
  const { classes, onlineMeetings } = state.db;

  const fullClassId = getFullId(classes, classId);
  if (!fullClassId) return undefined;

  const recordings = onlineMeetings.recordingsByClassId[fullClassId];
  if (!recordings?.data) return undefined;

  return recordings.data;
};

export const selectClassRecordings = (classId: ShortId | MongoId) => (state: RootState) => {
  const { classes, onlineMeetings } = state.db;

  const fullClassId = getFullId(classes, classId);
  if (!fullClassId) return [];

  const recordings = onlineMeetings.recordingsByClassId[fullClassId];
  if (!recordings?.byId) return [];

  const result: {
    recordingId: MongoId;
    meetingName: string;
    recordingFiles: RecordingFile[];
    createdAt: UnixTime;
  }[] = [];

  for (const recording of Object.values(recordings.byId)) {
    if (!recording) continue;
    const { recordingId, meetingName, filesByName, createdAt } = recording;
    result.push({
      recordingId: recordingId,
      meetingName: meetingName,
      recordingFiles: Object.values(filesByName).filter(notEmpty),
      createdAt: createdAt,
    });
  }

  return result.sort((a, b) => a.createdAt - b.createdAt);
};

export const selectClassRecordingConfig = (classId: ShortId | MongoId) => (state: RootState) => {
  const { classes, onlineMeetings } = state.db;

  const fullClassId = getFullId(classes, classId);
  if (!fullClassId) return undefined;

  const recordings = onlineMeetings.recordingsByClassId[fullClassId];
  if (!recordings) return undefined;

  return recordings.config;
};

export const selectOnlineMeeting = (classId: ShortId | MongoId) => (state: RootState) => {
  const { classes, onlineMeetings } = state.db;
  const fullClassId = classId.length === SHORT_ID_LENGTH ? getFullId(classes, classId) : classId;
  if (!fullClassId) return undefined;
  return onlineMeetings.byClassId[fullClassId];
};

export const selectParticipantsWithRaisedHand = (classId: ShortId | MongoId) => (state: RootState) => {
  const onlineDetails = selectOnlineMeeting(classId)(state);
  if (!onlineDetails) return [];
  return Object.values(onlineDetails.participantsById).filter((participant): participant is ZoomParticipant =>
    Boolean(participant?.hasRaisedHand)
  );
};

export const selectCurrentParticipant = (classId: ShortId | MongoId) => (state: RootState) => {
  const session = state.auth.session;
  if (!session) return undefined;
  const onlineDetails = selectOnlineMeeting(classId)(state);
  if (!onlineDetails) return undefined;
  return onlineDetails.participantsById[session.userId];
};

export const selectParticipantBeingAddressed = (classId: ShortId | MongoId) => (state: RootState) => {
  const onlineDetails = selectOnlineMeeting(classId)(state);
  if (!onlineDetails) return undefined;
  return Object.values(onlineDetails.participantsById).find((participant): participant is ZoomParticipant =>
    Boolean(participant?.beingAddressed)
  );
};
