import CommentsPanelModule from './CommentsPanelModule';
import CommentsContextProvider from './Context';

interface Props {
  className?: string;
}

const CommentsModule = ({ className }: Props) => {
  return (
    <CommentsContextProvider>
      <CommentsPanelModule className={className} />
    </CommentsContextProvider>
  );
};

export default CommentsModule;
