import { FormEventHandler, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { sendFeedback } from '../../../db/app/actions';
import i18n, { i18nNS } from '../../../i18n';
import { toDate } from '../../../utils/datetime';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { FormValues } from './types';
import validationSchema from './validators';

export default function usePricingInquiryVM() {
  const requestDispatch = useRequestDispatch();

  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    moveToPreviousStep: _moveToPreviousStep,
    moveToNextStep,
    numCourses,
    numStudents,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.PRICING_INQUIRY;

  const formMethods = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      courseCount: '',
      phone: '',
      studentCount: '',
    },
    mode: 'all',
  });

  const { control, formState, getValues, setValue, handleSubmit } = formMethods;
  const { isSubmitting } = formState;

  const moveToPreviousStep = () => _moveToPreviousStep({ skip: 3 });

  const sendPricingInquiry: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    handleSubmit(async () => {
      await requestDispatch(sendFeedback, {
        subject: '[System generated] Pricing inquiry',
        body: JSON.stringify({
          courseCount: getValues('courseCount'),
          studentCount: getValues('studentCount'),
          courseStartDate: toDate(getValues('courseStartDate')),
          phone: getValues('phone'),
        }),
      });
      moveToNextStep();
    })(e);
  };

  useEffect(() => {
    if (!isVisible) return;
    setValue('courseCount', numCourses);
    setValue('studentCount', numStudents);
  }, [isVisible, numCourses, numStudents, setValue]);

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    closeDrawer,
    control,
    isVisible,
    isSubmitting,
    moveToPreviousStep,
    sendPricingInquiry,
  };
}
