import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { i18nNS } from '../../../i18n';
import AlertCircleIcon from '../../../icons/AlertCircleIcon';
import PayButton from '../../../shared/PaymentWall/PayButton';
import { forNonDesktop } from '../../../utils/media-queries';
import usePaymentWarningVM from './vm';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary[100],
  minHeight: 60,
  padding: theme.spacing(3, 8),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  position: 'relative',
  zIndex: theme.zIndex.fab,

  [forNonDesktop(theme)]: {
    padding: theme.spacing(4),
  },
}));

const MessageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.primary[900],
}));

const PaymentWarning = () => {
  const { t } = useTranslation([i18nNS.COURSES]);

  const { canDismiss, dismiss, isVisible, message } = usePaymentWarningVM();

  if (!isVisible) return null;

  return (
    <Root>
      <MessageWrapper>
        <AlertCircleIcon color="inherit" variant="filled" fontSize="medium" />
        <Typography color="inherit">{message}</Typography>
      </MessageWrapper>
      <Stack alignItems="center" direction="row" gap={4} ml="auto">
        <PayButton>{t('pay_now', { ns: i18nNS.COMMON })}</PayButton>
        {canDismiss && (
          <Button variant="text" onClick={dismiss}>
            {t('later', { ns: i18nNS.GLOSSARY })}
          </Button>
        )}
      </Stack>
    </Root>
  );
};

export default PaymentWarning;
