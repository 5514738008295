import { forwardRef, Ref } from 'react';

import AttendingInPersonOrRemoteDialog from '../AttendingInPersonOrRemoteDialog';
import NotificationPermissionDialog from '../NotificationPermissionDialog';
import RemoteAttendanceInProgressDialog from '../RemoteAttendanceInProgressDialog';
import { ZoomAppInstance } from '../types';
import ZoomAppFooter from '../ZoomAppFooter';
import ZoomAppHeader from '../ZoomAppHeader';
import { Iframe, Root } from './styles';
import { useZoomAppVM, VmProps } from './vm';

interface Props extends VmProps {
  /**
   * Iframe source url
   * @example
   * 'https://app.acadly.com/zoom-app'
   */
  url: string;
  /** @default true */
  canPopIn?: boolean;
}

export const ZoomApp = forwardRef(function ZoomApp(
  { url, canPopIn = true, ...vmProps }: Props,
  ref: Ref<ZoomAppInstance>
) {
  const { iframeRef } = useZoomAppVM(vmProps, ref);
  return (
    <Root>
      <ZoomAppHeader canPopIn={canPopIn} />
      <Iframe
        title="Zoom app"
        ref={iframeRef}
        src={url}
        allow={
          process.env.NODE_ENV === 'development'
            ? "screen-wake-lock; camera; microphone; display-capture; fullscreen 'none'"
            : "screen-wake-lock 'self'; camera 'self'; microphone 'self'; display-capture 'self'; fullscreen 'none'"
        }
      />
      <ZoomAppFooter />
      <NotificationPermissionDialog />
      <AttendingInPersonOrRemoteDialog />
      <RemoteAttendanceInProgressDialog />
    </Root>
  );
});
