import Joi from 'joi';

import i18n, { i18nNS } from '../../../../i18n';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  emailId: Joi.string()
    .email({ tlds: { allow: false } })
    .allow('')
    .messages({
      'string.email': i18n.t('please_enter_a_valid_email_address', { ns: i18nNS.VALIDATION }),
    }),
});
