import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import Tabs from '../../components/Tabs';
import Tab from '../../components/Tabs/Tab';
import { i18nNS } from '../../i18n';
import routes from '../../pages/routes';
import { AppBar } from './styles';
import { ClassTabItems } from './types';
import useClassHeaderVM from './vm';

const ClassHeader = () => {
  const { t } = useTranslation([i18nNS.CLASS, i18nNS.GLOSSARY]);
  const { activeTab, params } = useClassHeaderVM();
  return (
    <AppBar>
      <Tabs value={activeTab} centered>
        <Tab
          id="class-activities-tab"
          aria-controls={t('class_activities_tabpanel', { ns: i18nNS.CLASS })}
          component={Link}
          to={routes.activities.url(params)}
          value={ClassTabItems.ACTIVITIES}
          label={<Typography component="span">{t('activities', { ns: i18nNS.GLOSSARY })}</Typography>}
        />
        <Tab
          id="class-agenda-tab"
          aria-controls={t('class_agenda_tabpanel', { ns: i18nNS.CLASS })}
          component={Link}
          to={routes.agenda.url(params)}
          value={ClassTabItems.AGENDA}
          label={<Typography component="span">{t('agenda', { ns: i18nNS.GLOSSARY })}</Typography>}
        />
        <Tab
          id="class-attendance-tab"
          aria-controls={t('class_attendance_tabpanel', { ns: i18nNS.CLASS })}
          component={Link}
          to={routes.attendance.url(params)}
          value={ClassTabItems.ATTENDANCE}
          label={<Typography component="span">{t('attendance', { ns: i18nNS.GLOSSARY })}</Typography>}
        />
      </Tabs>
    </AppBar>
  );
};

export default ClassHeader;
