import { ElementType } from 'react';
import { useTranslation } from 'react-i18next';

import { Snackbar } from '@mui/material';

import clsx from 'clsx';

import { Props as ButtonProps } from '../../../../../components/Button';
import { i18nNS } from '../../../../../i18n';
import BellIcon from '../../../../../icons/BellIcon';
import BellOffIcon from '../../../../../icons/BellOffIcon';
import { StyledButton, subscribeButtonClasses } from './styles';
import useSubscribeButtonVM from './vm';

type Props<C extends ElementType<any>> = DistributiveOmit<ButtonProps<C>, 'classes'> & {
  classes?: Partial<Record<'button' | 'subscribed' | 'unsubscribed' | 'notification', string>>;
};

const SubscribeButton = <C extends ElementType<any>>({ classes, ...props }: Props<C>) => {
  const { t } = useTranslation([i18nNS.SHARED]);

  const {
    isSubscribed,
    handleToggleSubscription: _handleToggleSubscription,
    isSubscriptionNotificationOpen,
  } = useSubscribeButtonVM();

  const handleToggleSubscription = () => {
    _handleToggleSubscription();
  };

  return (
    <>
      <StyledButton
        {...props}
        className={clsx(
          {
            [subscribeButtonClasses.subscribed]: isSubscribed,
            [subscribeButtonClasses.unsubscribed]: !isSubscribed,
          },
          classes?.subscribed && {
            [classes.subscribed]: isSubscribed,
          },
          classes?.unsubscribed && {
            [classes.unsubscribed]: !isSubscribed,
          },
          props.className,
          classes?.button
        )}
        aria-label={t(
          isSubscribed
            ? 'click_to_subscribe_to_this_discussion_thread'
            : 'click_to_unsubscribe_from_this_discussion_thread',
          { ns: i18nNS.SHARED }
        )}
        onClick={handleToggleSubscription}
      >
        {isSubscribed ? (
          <BellIcon variant="filled" aria-hidden />
        ) : (
          <BellOffIcon variant="filled" aria-hidden />
        )}
      </StyledButton>
      <Snackbar
        className={classes?.notification}
        open={isSubscriptionNotificationOpen}
        message={t(
          isSubscribed
            ? 'subscribed_we_will_send_a_notification_to_you_whenever_someone_posts_a_comment_in_this_thread'
            : 'unsubscribed_you_will_now_not_receive_a_notification_whenever_someone_posts_a_comment_in_this_thread',
          { ns: i18nNS.SHARED }
        )}
      />
    </>
  );
};

export default SubscribeButton;
