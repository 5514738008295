import { useState } from 'react';

const useChangePasswordVM = () => {
  const [isChangePasswordDrawerOpen, setIsChangePasswordDrawerOpen] = useState(false);

  const handleCloseChangePasswordDrawer = () => {
    setIsChangePasswordDrawerOpen(false);
  };

  const handleChangePassword = () => {
    setIsChangePasswordDrawerOpen(true);
  };

  return { isChangePasswordDrawerOpen, handleCloseChangePasswordDrawer, handleChangePassword };
};

export default useChangePasswordVM;
