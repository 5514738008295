import { createAction } from '@reduxjs/toolkit';

import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { WithCurrentUser } from '../shared/types';
import { QueryCreatedEvent, QueryUpvotedEvent, YourQueryApprovedEvent } from './pusher-events';
import {
  ApproveQuerySuccessPayload,
  CloseQuerySuccessPayload,
  CreateQueryPayload,
  CreateQuerySuccessPayload,
  DeleteQuerySuccessPayload,
  FetchAllQueriesSuccessPayload,
  HideQuerySuccessPayload,
  MarkQueryAsReadSuccessPayload,
  QueryId,
} from './types';

export const openQueryPage = createAction<QueryId>('queries/open-page');
export const closeQueryPage = createAction<QueryId>('queries/close-page');

export const fetchAllQueries = createRequestAction<void, FetchAllQueriesSuccessPayload>('queries/fetch/all');

export const createQuery = createRequestAction<CreateQueryPayload, CreateQuerySuccessPayload>(
  'queries/create'
);

export const queryCreated = createAction<WithCurrentUser<QueryCreatedEvent>>('queries/created');

export const approveQuery = createRequestAction<API.ApproveQueryRequest, ApproveQuerySuccessPayload>(
  'queries/approve'
);

export const queryApproved = createAction<WithCurrentUser<QueryCreatedEvent>>('queries/approved');

export const yourQueryApproved = createAction<YourQueryApprovedEvent>('queries/your-approved');

export const deleteQuery = createRequestAction<API.DeleteQueryRequest, DeleteQuerySuccessPayload>(
  'queries/delete'
);

export const markQueryAsViewed = createRequestAction<QueryId, MarkQueryAsReadSuccessPayload>(
  'queries/mark/read'
);

export const upvoteQuery = createRequestAction<QueryId, QueryId>('queries/upvote');

export const queryUpvoted = createAction<WithCurrentUser<QueryUpvotedEvent>>('queries/upvoted');

export const hideQuery = createRequestAction<API.HideQueryRequest, HideQuerySuccessPayload>('queries/hide');

export const closeQuery = createRequestAction<API.CloseQueryRequest, CloseQuerySuccessPayload>(
  'queries/close'
);
