import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ZOOM_MEETING_PIP } from '../../../constants';
import { useCurrentUserWithRole } from '../../../courses/hooks';
import { selectAttendanceInProgress } from '../../../db/classes/selectors';
import { ZoomMeetingContextState } from '../../../online-meeting/ZoomMeetingContext/types';
import { PipContainerType, usePipContainer } from '../../../pip-container';
import { dateTimeDiff, unix } from '../../../utils/datetime';
import { useInterval } from '../../../utils/timer';
import { ATTENDANCE_TIMEOUT } from '../../constants';
import { getIsAttendanceInProgressDialogOpen } from './helper';

const useAttendanceInProgressDialogVM = () => {
  const [count, setCount] = useState(-1);

  const attendanceInProgress = useSelector(selectAttendanceInProgress);
  const currentUser = useCurrentUserWithRole();

  const { isOpen: isZoomMeetingPipOpen } = usePipContainer<ZoomMeetingContextState>({
    id: ZOOM_MEETING_PIP,
    type: PipContainerType.ZOOM_MEETING,
  });

  const { start, stop } = useInterval(() => {
    if (!attendanceInProgress?.isInProgress) {
      onStop();
      return;
    }
    if (isZoomMeetingPipOpen) {
      onStop();
      return;
    }
    const diff = ATTENDANCE_TIMEOUT - dateTimeDiff(unix(), attendanceInProgress.startedAt, 'seconds');
    setCount(diff);
  }, null);

  const onStop = () => {
    stop();
    setCount(-1);
  };

  const isAttendanceInProgressDialogOpen = getIsAttendanceInProgressDialogOpen({
    attendanceInProgress,
    isZoomMeetingPipOpen,
    count,
  });

  useEffect(
    function startCountdown() {
      if (!attendanceInProgress?.isInProgress) return;
      start(1000);
    },
    [start, attendanceInProgress]
  );

  const onCloseAttendanceInProgressDialog = () => {
    onStop();
  };

  return {
    currentUser,
    isAttendanceInProgressDialogOpen,
    onCloseAttendanceInProgressDialog,
    attendanceInProgress,
    count,
  };
};

export default useAttendanceInProgressDialogVM;
