import React, { MouseEventHandler, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu } from '@mui/material';

import clsx from 'clsx';

import { i18nNS } from '../../i18n';
import MoreVerticalIcon from '../../icons/MoreVerticalIcon';
import { Props as ButtonProps } from '../Button';
import { moreActionsMenuClasses, StyledButton } from './styles';
import useMoreActionsMenuVM from './vm';

type Props = DistributiveOmit<ButtonProps, 'onClick'> & {
  className?: string;
  children: (onClose: () => void) => ReactNode;
};

const MoreActionsMenu = ({ className, children, ...props }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const { anchorEl, open, handleClose, handleClick: _handleClick } = useMoreActionsMenuVM();

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    _handleClick(event.currentTarget);
  };

  const stopPropagation: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const menuItems = children(handleClose);

  if (React.Children.count(menuItems) === 0) return null;

  return (
    <>
      <StyledButton
        size="small"
        {...props}
        variant="text"
        className={clsx(className, moreActionsMenuClasses.button)}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label={t('click_to_see_more_options', { ns: i18nNS.COMMON })}
        onClick={handleClick}
      >
        <MoreVerticalIcon />
      </StyledButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={stopPropagation}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default MoreActionsMenu;
