import { configureStore as _configureStore, ReducersMapObject } from '@reduxjs/toolkit';

import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga';

import Logger from '../utils/logger';
import { createRequestActionMiddleware } from '../utils/request-actions';
import createReducer from './reducers';
import { RootState } from './types';

/**
 * Create the store with dynamic reducers
 */
export function configureStore(initialState?: RootState, extraReducers?: ReducersMapObject) {
  const reduxSagaMonitorOptions: SagaMiddlewareOptions = {
    onError: (error, info) => {
      const logger = Logger.create('saga');
      logger.error(error);
      logger.info(info.sagaStack);
    },
  };

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  const middlewares = [sagaMiddleware, createRequestActionMiddleware()];

  const store = _configureStore({
    reducer: createReducer(extraReducers),
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        immutableCheck: { warnAfter: 500 },
        serializableCheck: { warnAfter: 500 },
      }).concat(middlewares);
    },
    preloadedState: initialState,
    devTools: process.env.REACT_APP_ENV !== 'production',
  });

  return { store, runSaga };
}
