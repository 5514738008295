import { useEffect } from 'react';

import { Subject } from 'rxjs';

import { PusherEvent, PusherEventCreator, PusherEventName, PusherEventPayload } from './types';

function createPusherBus() {
  const subject = new Subject<PusherEvent<PusherEventPayload, PusherEventName>>();
  return {
    broadcast: subject.next.bind(subject),
    subscribe: subject.subscribe.bind(subject),
  };
}

const pusherBus = createPusherBus();

export function usePusherEvent<EventName extends PusherEventName, Payload extends {}>(
  eventCreator: PusherEventCreator<Payload, EventName>,
  callback: (event: PusherEvent<Payload, PusherEventName>) => void
) {
  useEffect(
    function subscribeToPusherBus() {
      const subscription = pusherBus.subscribe((e) => {
        if (e.type !== eventCreator.eventName) return;
        callback(e as any);
      });
      return () => {
        subscription.unsubscribe();
      };
    },
    [callback, eventCreator.eventName]
  );
}

export default pusherBus;
