import { styled } from '@mui/material';

import clsx from 'clsx';

import AcadlyAlert, { Props as AcadlyAlertProps } from '../../components/Alert';
import AcadlyUserCell, { Props as AcadlyUserCellProps } from '../../components/UserCell/index';
import { generateClasses } from '../../utils/helpers';

export const studentWithRaisedHandDialogClasses = generateClasses('StudentWithRaisedHandDialog', [
  'alertHeader',
  'alertBody',
  'alertFooter',
  'subtitle',
]);

export const StyledAlert = styled((props: AcadlyAlertProps) => (
  <AcadlyAlert
    {...props}
    classes={{
      body: clsx(props.classes?.body, studentWithRaisedHandDialogClasses.alertBody),
    }}
  />
))(({ theme }) => ({
  [`& .${studentWithRaisedHandDialogClasses.alertHeader}`]: {
    padding: theme.spacing(3, 5),
  },
  [`& .${studentWithRaisedHandDialogClasses.alertBody}`]: {
    padding: 0,
  },
  [`& .${studentWithRaisedHandDialogClasses.alertFooter}`]: {
    padding: theme.spacing(3, 5),
  },
}));

export const UserCell = styled((props: AcadlyUserCellProps) => (
  <AcadlyUserCell
    {...props}
    classes={{
      ...props.classes,
      subtitle: clsx(studentWithRaisedHandDialogClasses.subtitle, props.subtitle),
    }}
  />
))(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3, 5),
  [`& .${studentWithRaisedHandDialogClasses.subtitle}`]: {
    color: theme.palette.primary.main,
  },
  [`&:hover`]: {
    backgroundColor: theme.palette.background.level2,
  },
}));
