import urls from '../../app/urls';
import { format } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import {
  DiscussionId,
  EditPublishedDiscussionPayload,
  PublishDiscussionPayload,
  SaveDiscussionPublishPrefsPayload,
} from './types';

export async function getAllDiscussions() {
  const { data } = await request.get<API.GetAllDiscussionResponse>(urls.getAllDiscussions);
  return data;
}

export async function createDiscussion(payload: API.CreateDiscussionRequest) {
  const { data } = await request.post<API.CreateDiscussionResponse>(urls.createDiscussion, payload);
  return data;
}

export async function editDiscussion(payload: API.EditDiscussionRequest) {
  await request.post(urls.editDiscussion, payload);
}

export async function deleteDiscussion(data: API.DeleteDiscussionRequest) {
  await request.delete(urls.deleteDiscussion, { data });
}

export async function getDiscussionPublishPrefs(params: API.GetDiscussionPublishPrefsRequest) {
  const { data } = await request.get<API.GetDiscussionPublishPrefsResponse>(urls.getDiscussionPrefs, {
    params,
  });
  return data;
}

export async function saveDiscussionPublishPrefs(params: SaveDiscussionPublishPrefsPayload) {
  const payload: API.SaveDiscussionPrefsRequest = {
    classId: params.classId,
    activityType: 'discussion',
    activityId: params.discussionId,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
    discussionPref: {
      submitFirst: params.submitFirst ? 1 : 0,
      hideAwards: params.hideAwards ? 1 : 0,
      anonymity: params.anonymity,
      anonymize: params.anonymize ? 1 : 0,
      subscribeToComments: params.subscribeToComments ? 1 : 0,
    },
  };
  await request.post(urls.savePublishPrefs, payload);
}

export async function publishDiscussion(params: PublishDiscussionPayload) {
  const payload: API.PublishDiscussionRequest = {
    classId: params.classId,
    discussionId: params.discussionId,
    toBeDone: params.preferences.toBeDone,
    submitFirst: params.preferences.submitFirst ? 1 : 0,
    hideAwards: params.preferences.hideAwards ? 1 : 0,
    anonymize: params.preferences.anonymize ? 1 : 0,
    anonymity: params.preferences.anonymity,
    subscribeToComments: params.preferences.subscribeToComments ? 1 : 0,
    saveAsDefault: params.saveAsDefault ? 1 : 0,
  };
  const { data } = await request.post<API.PublishDiscussionResponse>(urls.publishDiscussion, payload);
  return data;
}

export async function markDiscussionAsViewed({
  discussionId,
  currentTime,
}: DiscussionId & { currentTime: UnixTime }) {
  const payload: API.MarkDiscussionAsReadRequest = {
    discussionId,
    firstAccess: 1,
    localTime: format(currentTime, "yyyyMMdd'T'HH:mm"),
  };
  await request.post(urls.markDiscussionAsViewed, payload);
}

export async function editPublishedDiscussion({ notifyStudents, ...params }: EditPublishedDiscussionPayload) {
  const payload: API.EditPublishedDiscussionRequest = {
    ...params,
    toNotify: notifyStudents ? 1 : 0,
  };
  await request.put(urls.editPublishedDiscussion, payload);
}

export async function createWordCloud(payload: API.CreateWordCloudRequest) {
  const response = await request.put<API.CreateWordCloudResponse>(urls.createWordCloud, payload, {
    validateStatus: (status) => status < 500 && status >= 200,
  });

  if (response.status >= 400) {
    return { isCreated: false as const, message: response.data.message };
  }

  return { isCreated: true as const, ...response.data };
}

export async function editWordCloud(payload: API.EditWordCloudRequest) {
  const { data } = await request.put<API.EditWordCloudResponse>(urls.editWordCloud, payload);
  return data;
}

export async function getWordCloudDetails({ discussionId }: DiscussionId) {
  const { data } = await request.get<API.WordCloud>(urls.getWordCloudDetails(discussionId));
  return data;
}
