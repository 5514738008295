import { useContext } from 'react';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';
import Breadcrumb from '../components/Breadcrumb/index';

const useArchivedBreadcrumbVM = () => {
  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems };
};

const ArchivedBreadcrumb = () => {
  const { breadcrumbItems } = useArchivedBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.archived}>
      <BreadcrumbItem item={breadcrumbItems.home} />
    </Breadcrumb>
  );
};

export default ArchivedBreadcrumb;
