import { FormHelperText, styled } from '@mui/material';

export const Body = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  color: theme.palette.grey[500],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
  padding: theme.spacing(4),

  '&:focus-within:has(:focus-visible)': {
    outline: `2px solid ${theme.palette.primary[400]} !important`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const Error = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.error[600],
}));
