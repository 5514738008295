import { forwardRef, Ref } from 'react';
import YouTube from 'react-youtube';

import { Stack, StackProps, styled } from '@mui/material';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';
import Button, { Props as ButtonProps } from '../Button';

export const youtubePlayerClasses = generateClasses('YoutubePlayer', ['playButton', 'playIcon']);

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
}));

export const StyledYoutube = styled(YouTube)(({ theme }) => ({
  display: 'flex',
}));

export const Overlay = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="center" alignItems="center" gap={2} {...props} />
))(({ theme }) => ({
  position: 'absolute',
  inset: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
}));

export const PlayButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button {...props} ref={ref} className={clsx(youtubePlayerClasses.playButton, props.className)} />
  ))
)(({ theme }) => ({
  [`&.${youtubePlayerClasses.playButton}`]: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3, 5),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${youtubePlayerClasses.playIcon}`]: {
      fontSize: theme.typography.pxToRem(28),
      '& > svg': {
        fontSize: theme.typography.pxToRem(28),
      },
    },
  },
}));
