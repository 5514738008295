import { DateWidgetVariant } from '../components/DateWidget';
import { CLASS_END_TIME_OFFSET } from '../constants';
import { ClassModality } from '../courses/types';
import { Class, ClassQueries } from '../db/classes/types';
import { Course } from '../db/courses/types';
import {
  ClassRole,
  ClassStatus,
  CourseRole,
  PluralToActivityType,
  SubmissionStatus,
  TipStatusKey,
} from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { WidgetColor } from '../styles/colors';
import {
  addActivityStats,
  getIsDeadlineNear,
  getIsDeadlinePast,
  getTotalActivities,
  getUnclosedQueries,
  getUncompletedActivities,
  getUnpublishedActivities,
  getUnseenActivities,
  getUnseenComments,
} from '../utils/activities';
import { format, formatTimeFromUnix, unix } from '../utils/datetime';
import { ActivityIcon, ActivityIconBadge, ClassWidgetData } from './types';

export function getClassTitle(cls: Pick<Class, 'title' | 'sequenceNum'>) {
  const title = cls.title?.trim();

  if (title) return title;

  return cls.sequenceNum > 0
    ? i18n.t('class_number', { num: cls.sequenceNum.toString().padStart(2, '0'), ns: i18nNS.COURSES })
    : i18n.t('class', { ns: i18nNS.GLOSSARY });
}

export function getIsInchargeSet(
  cls: Pick<Class, 'team' | 'timing'>,
  course: Pick<Course, 'status' | 'team' | 'myRole'>
) {
  if (!course.status.isLive) return true;

  if (course.status.isArchived) return true;

  const hasInstructors = (course.team.instructors.length ?? 0) > 0;
  if (!hasInstructors) return true;

  if (course.myRole !== CourseRole.ADMIN) return true;

  if (cls.team.isInchargeSet) return true;

  const isDeadlinePast = getIsDeadlinePast(cls.timing.endTime.scheduled);
  return isDeadlinePast;
}

export function getClassTimingText(cls: Pick<Class, 'timing' | 'status'>) {
  const { startTime, endTime } = cls.timing;
  const { isClassOpen, isClassInSession, isClassCancelled, isClassHoliday, isClassClosed, isClassNoRecord } =
    getClassStatus(cls.status);

  if (isClassOpen || isClassInSession || isClassClosed) {
    return `${formatTimeFromUnix(startTime.scheduled)} - ${formatTimeFromUnix(endTime.scheduled)}`;
  }

  if (isClassCancelled) return i18n.t('canceled', { ns: i18nNS.GLOSSARY });

  if (isClassHoliday) return i18n.t('holiday', { ns: i18nNS.GLOSSARY });

  if (isClassNoRecord) return i18n.t('no_record', { ns: i18nNS.COURSES });

  return '';
}

export function getClassWidgetVariant(cls: Pick<Class, 'timing' | 'status'>): DateWidgetVariant {
  const { status, timing } = cls;
  const { isClassInSession, isClassCancelled, isClassHoliday } = getClassStatus(status);

  if (isClassInSession) return 'live';

  if (isClassHoliday || isClassCancelled) return 'unpublished';

  if (getIsDeadlinePast(timing.startTime.scheduled)) {
    return 'past';
  }

  if (getIsDeadlineNear(timing.startTime.scheduled)) {
    return 'near';
  }

  return 'future';
}

interface ActivitiesStatsContainer {
  activities: Class['activities'];
}

function getActivitiesStats<T extends ActivitiesStatsContainer>({ activities }: T) {
  const { inClass, preClass, review } = activities;
  return {
    quizzes: addActivityStats(inClass.quizzes, preClass.quizzes),
    polls: addActivityStats(inClass.polls, preClass.polls),
    discussions: addActivityStats(inClass.discussions, preClass.discussions),
    resources: addActivityStats(inClass.resources, preClass.resources),
    queries: addActivityStats(inClass.queries, preClass.queries, review.queries),
  };
}

export function getTotalClassActivities<T extends ActivitiesStatsContainer>(cls: T) {
  let total = 0;
  const stats = getActivitiesStats(cls);

  for (const activity of Object.values(stats)) {
    total += getTotalActivities(activity);
  }

  return total;
}

export function getHasClassActivities(cls?: Class) {
  if (!cls) return false;
  const totalClassActivities = getTotalClassActivities(cls);
  return totalClassActivities > 0;
}

type ActivitiesStats = ReturnType<typeof getActivitiesStats>;

function getActivityIconColor(
  cls: Pick<Class, 'activities' | 'status' | 'myRole' | 'timing'>,
  type: keyof ActivitiesStats,
  activitiesStats = getActivitiesStats(cls)
): string {
  const stats = activitiesStats[type];

  // TODO: add accessible colors for dark and light mode

  /**
   * if class is in session then show all activities as geen color
   * irrespectives of their course role or activity stats
   */
  const { isClassInSession } = getClassStatus(cls.status);
  if (isClassInSession) return WidgetColor.GREEN;

  const isStudent = cls.myRole === ClassRole.STUDENT;

  const hasUnseenActivities = getUnseenActivities(stats) > 0;

  if (isStudent) {
    switch (type) {
      case 'quizzes':
      case 'polls': {
        const hasUncompletedActivities = getUncompletedActivities(stats) > 0;
        const { scheduled } = cls.timing.endTime;
        if (!hasUncompletedActivities) return WidgetColor.GREY;
        if (getIsDeadlineNear(scheduled)) return WidgetColor.PINK;
        if (getIsDeadlinePast(scheduled)) return WidgetColor.RED;
        return WidgetColor.BLUE;
      }
      case 'discussions':
      case 'resources':
      case 'queries':
      default: {
        return hasUnseenActivities ? WidgetColor.BLUE : WidgetColor.GREY;
      }
    }
  }

  // course team logic further

  switch (type) {
    case 'queries': {
      const s = stats as ClassQueries;
      const hasUnclosedQueries = getUnclosedQueries(s) > 0;
      if (s.pending > 0) return WidgetColor.ORANGE;
      if (hasUnseenActivities || hasUnclosedQueries) return WidgetColor.BLUE;
      return WidgetColor.GREY;
    }
    case 'quizzes':
    case 'polls':
    case 'discussions':
    case 'resources':
    default: {
      const hasUnPublishedActivities = getUnpublishedActivities(stats) > 0;
      return hasUnPublishedActivities ? WidgetColor.RED : WidgetColor.GREY;
    }
  }
}

export function getActivityIcons(cls: Pick<Class, 'activities' | 'status' | 'myRole' | 'timing'>) {
  const icons: ActivityIcon[] = [];
  const activitiesStats = getActivitiesStats(cls);
  const isStudent = cls.myRole === ClassRole.STUDENT;

  for (const [key, stats] of Object.entries(activitiesStats)) {
    const type = key as keyof ActivitiesStats;
    const total = getTotalActivities(stats);
    if (total <= 0) continue;

    const icon: ActivityIcon = {
      badge: ActivityIconBadge.NONE,
      color: getActivityIconColor(cls, type, activitiesStats),
      type: PluralToActivityType[type],
    };

    const isQuery = type === 'queries';
    const hasUnseenComments = getUnseenComments(stats) > 0;
    const hasUnseenActivities = getUnseenActivities(stats) > 0;

    if (hasUnseenActivities && (isStudent || isQuery)) {
      icon.badge = ActivityIconBadge.UNSEEN;
    } else if (hasUnseenComments) {
      icon.badge = ActivityIconBadge.HAS_NEW_COMMENTS;
    }

    icons.push(icon);
  }

  return icons;
}

export function getClassRole(myRole?: ClassRole) {
  return {
    isIncharge: myRole === ClassRole.INCHARGE,
    isAssistant: myRole === ClassRole.ASSISTANT,
    isStudent: myRole === ClassRole.STUDENT,
  };
}

export function getClassTeam(team?: Class['team']) {
  return {
    incharges: team?.incharges,
    assistants: team?.assistants,
  };
}

export function getClassStatus(status: ClassStatus | undefined) {
  return {
    isClassOpen: status === ClassStatus.OPEN,
    isClassInSession: status === ClassStatus.IN_SESSION,
    isClassClosed: status === ClassStatus.CLOSED,
    isClassNoRecord: status === ClassStatus.NO_RECORD,
    isClassCancelled: status === ClassStatus.CANCELED,
    isClassHoliday: status === ClassStatus.HOLIDAY,
  };
}

export function getClassTimings(timing?: Class['timing']) {
  if (!timing) {
    return {
      scheduledDate: undefined,
      actualStartTime: undefined,
      scheduledStartTime: undefined,
      actualEndTime: undefined,
      scheduledEndTime: undefined,
    };
  }

  const { dueDateTime, startTime, endTime } = timing;

  return {
    scheduledDate: dueDateTime,
    actualStartTime: startTime.actual,
    scheduledStartTime: startTime.scheduled,
    actualEndTime: endTime.actual,
    scheduledEndTime: endTime.scheduled,
  };
}

export function getIsScheduledStartTimeInPast(scheduledStartTime?: UnixTime) {
  if (!scheduledStartTime) return false;
  return unix() >= scheduledStartTime;
}

export function getIsScheduledStartTimeInFuture(scheduledStartTime?: UnixTime) {
  if (!scheduledStartTime) return false;
  return unix() < scheduledStartTime;
}

export function getIsScheduledEndTimeInPast(scheduledEndTime?: UnixTime) {
  if (!scheduledEndTime) return false;
  return unix() >= scheduledEndTime;
}

export function getIsScheduledEndTimeInFuture(scheduledEndTime?: UnixTime) {
  if (!scheduledEndTime) return false;
  return unix() < scheduledEndTime;
}

export function getIsClassCancelled(status: Class['status'] | undefined) {
  if (!status) return false;
  const { isClassCancelled } = getClassStatus(status);
  return isClassCancelled;
}

export function getIsClassOnline(isOnline?: boolean) {
  return Boolean(isOnline);
}

export function getClassModality(isOnline?: boolean) {
  if (isOnline === undefined) return '';
  if (isOnline) return ClassModality.ONLINE;
  return ClassModality.IN_PERSON;
}

export function getIsOnline(classModality: ClassModality | '') {
  if (classModality === '') return false;
  return classModality === ClassModality.ONLINE;
}

export function getVenue(venue?: string) {
  return venue ?? '';
}

export function getIsClassScheduledToStart(cls?: Class) {
  const { isIncharge } = getClassRole(cls?.myRole);
  if (!isIncharge) return false;

  const { isClassOpen } = getClassStatus(cls?.status);
  if (!isClassOpen) return false;

  const { scheduledStartTime, scheduledEndTime } = getClassTimings(cls?.timing);
  if (!getIsScheduledEndTimeInFuture(scheduledEndTime)) return false;

  return getIsScheduledStartTimeInPast(scheduledStartTime);
}

export function getScheduleLabelTimerInitialValue(cls?: Class) {
  const { isClassOpen } = getClassStatus(cls?.status);
  if (!isClassOpen) return -1;

  const { scheduledStartTime } = getClassTimings(cls?.timing);
  if (!scheduledStartTime) return -1;

  return scheduledStartTime - unix();
}

export function getCanStudentCheckIn(cls: Class) {
  if (cls?.myRole !== ClassRole.STUDENT) return false;
  const now = unix();
  const isCheckedIn = cls?.attendance.isCheckedIn;

  const scheStartTime = cls?.timing.startTime.scheduled || -1;
  const scheEndTime = cls?.timing.endTime.scheduled || -1;

  return !isCheckedIn && scheStartTime <= now && now < scheEndTime;
}

export function getIsClassEditable(
  isCourseArchived: boolean,
  myRole?: ClassRole,
  status?: Class['status'],
  timing?: Class['timing']
) {
  if (isCourseArchived) return false;

  const { isIncharge } = getClassRole(myRole);
  if (!isIncharge) return false;

  const { isClassOpen } = getClassStatus(status);
  if (!isClassOpen) return false;

  const { scheduledStartTime } = getClassTimings(timing);
  return getIsScheduledStartTimeInFuture(scheduledStartTime);
}

export function getIsClassTeamEditable(isCourseAdmin: boolean, courseTeamMembersCount: number, cls?: Class) {
  /**
   * course team members count is 1 means admin is the only team member
   * in that case there is no need to allow editing course team.
   */
  if (courseTeamMembersCount < 2) return false;

  const { isIncharge } = getClassRole(cls?.myRole);
  if (!isCourseAdmin && !isIncharge) return false;

  const { isClassOpen } = getClassStatus(cls?.status);
  if (!isClassOpen) return false;

  const { scheduledStartTime } = getClassTimings(cls?.timing);
  const isScheduledStartTimeInFuture = getIsScheduledStartTimeInFuture(scheduledStartTime);
  return isScheduledStartTimeInFuture;
}

export function getCanStartClass(cls: Class) {
  const { isClassOpen } = getClassStatus(cls?.status);
  if (!isClassOpen) return false;

  const { scheduledStartTime, scheduledEndTime } = getClassTimings(cls?.timing);

  if (!scheduledEndTime) return;

  const endTime = scheduledEndTime + CLASS_END_TIME_OFFSET;

  const isScheduledStartTimeInFuture = getIsScheduledStartTimeInFuture(scheduledStartTime);
  if (isScheduledStartTimeInFuture) return false;

  const isScheduledEndTimeInFuture = getIsScheduledEndTimeInFuture(endTime);
  return isScheduledEndTimeInFuture;
}

export function getCanEndClass(cls?: Class) {
  const { isIncharge, isAssistant } = getClassRole(cls?.myRole);
  if (!isIncharge && !isAssistant) return false;

  const { isClassInSession } = getClassStatus(cls?.status);
  return isClassInSession;
}

export function getIsInClassTeam(cls: Pick<Class, 'myRole'> | undefined) {
  if (!cls) return false;
  return [ClassRole.INCHARGE, ClassRole.ASSISTANT].includes(cls.myRole);
}

export function getWasAcadlyUsedInClass(
  classStatus: Class['status'] | undefined,
  scheduledStartTime: UnixTime | undefined
) {
  if (!classStatus) return undefined;
  if (!scheduledStartTime) return undefined;

  if (scheduledStartTime > unix()) return undefined;

  const { isClassInSession, isClassClosed } = getClassStatus(classStatus);

  return isClassInSession || isClassClosed;
}

export const getSubmissionStatus = (status: SubmissionStatus) => {
  return status === SubmissionStatus.SUBMITTED
    ? i18n.t('on_time', { ns: i18nNS.CLASS })
    : i18n.t('late', { ns: i18nNS.GLOSSARY });
};

export function getCanSendClassComments(isCourseArchived: boolean | undefined) {
  return !isCourseArchived;
}

export function getEmptyCommentsMessageForClass() {
  return i18n.t(
    'any_discussions_pertaining_to_this_class_can_be_had_in_this_chat_area_the_idea_is_for_the_students_to_have_an_informal_discussion_about_the_class_content_before_the_class_has_begun_or_while_it_is_on_or_after_it_is_over',
    { ns: i18nNS.CLASS }
  );
}

export function getClassPageTipContent(tipKey: TipStatusKey, isMobile: boolean) {
  switch (tipKey) {
    case 'classMainFloatingButton':
      if (isMobile) {
        return i18n.t('tap_here_to_add_pre_class_or_in_class_activities', { ns: i18nNS.CLASS });
      }
      return i18n.t('click_here_to_add_pre_class_or_in_class_activities', { ns: i18nNS.CLASS });
    case 'classMainQueryAdd':
      return i18n.t(
        'if_you_have_questions_about_the_topics_that_will_be_are_being_or_were_discussed_during_this_class_you_can_let_the_course_team_know_by_adding_a_query',
        { ns: i18nNS.CLASS }
      );
    case 'classMainChat':
      return i18n.t(
        'tap_here_to_access_the_class_discussion_thread_students_and_instructors_can_post_comments_and_questions_about_the_class_content_in_an_informal_setting',
        { ns: i18nNS.CLASS }
      );
    default:
      return '';
  }
}

export function getAriaLabelForClassWidget(cls?: ClassWidgetData) {
  if (!cls) return '';
  const discussionCount =
    cls.activities.inClass.discussions.published + cls.activities.preClass.discussions.published;
  const resourceCount =
    cls.activities.inClass.resources.published + cls.activities.preClass.resources.published;
  const pollCount = cls.activities.inClass.polls.published + cls.activities.preClass.polls.published;
  const quizCount = cls.activities.inClass.quizzes.published + cls.activities.preClass.quizzes.published;
  const queriesCount = cls.activities.inClass.queries.total + cls.activities.preClass.queries.total;
  const textDate = format(cls.timing.startTime.scheduled, "EEEE, do MMM 'at' HH:mm aaa");
  const status = cls.status;
  const isNoActivityPresent =
    discussionCount === 0 && pollCount === 0 && resourceCount === 0 && quizCount === 0 && queriesCount === 0;

  if (isNoActivityPresent)
    return i18n.t('class_on_date_class_status_status_class_title_classtitle_no_activities_present', {
      ns: i18nNS.COURSES,
      date: textDate,
      status,
      classtitle: cls.title || 'Untitled',
    });

  return i18n.t(
    'class_on_date_class_status_status_class_title_classtitle_discussioncount_discussion_resourcecount_resource_pollcount_poll_quizcount_quiz_queriescount_query',
    {
      ns: i18nNS.COURSES,
      date: textDate,
      status,
      classtitle: cls.title || 'Untitled',
      discussionCount,
      resourceCount,
      pollCount,
      quizCount,
      queriesCount,
    }
  );
}
