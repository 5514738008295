import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { fetchCourseDetailsByJoinCode, joinCourseByJoinCode } from '../../db/courses/actions';
import { useRequestDispatch } from '../../utils/request-actions';
import { GetCourseDetails } from '../types';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface VMProps {
  open: boolean;
  onClose: () => void;
}

export function useJoinAnExistingCourseDrawerVm({ open, onClose }: VMProps) {
  const requestDispatch = useRequestDispatch();

  const [courseDetails, setCourseDetails] = useState<GetCourseDetails>();

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(
    function scrollToTop() {
      bodyRef.current?.scrollTo(0, 0);
    },
    [courseDetails]
  );

  const formMethods = useForm<FormValues>({
    defaultValues: {
      joinCode: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { reset } = formMethods;

  const onCloseDrawer = () => {
    onClose();
    reset();
    setCourseDetails(undefined);
  };

  const onBack = () => {
    setCourseDetails(undefined);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (!courseDetails) {
      const { title, courseCode, universityName, admin } = await requestDispatch(
        fetchCourseDetailsByJoinCode,
        {
          code: data.joinCode,
        }
      );
      setCourseDetails({ title, courseCode, universityName, admin });
    } else {
      await requestDispatch(joinCourseByJoinCode, {
        joinCode: data.joinCode,
      });
      onCloseDrawer();
    }
  };

  return {
    bodyRef,
    onSubmit,
    formMethods,
    courseDetails,
    onCloseDrawer,
    onBack,
  };
}
