import { Skeleton } from '@mui/material';

interface Props {
  className?: string;
}

const TagSkeleton = ({ className }: Props) => {
  return <Skeleton variant="rounded" height={24} width={70} className={className} />;
};

export default TagSkeleton;
