import { useContext } from 'react';

import { AuthContext } from '../Context';

const useAuthScreenContainerVM = () => {
  const { screen } = useContext(AuthContext);
  return { screen };
};

export default useAuthScreenContainerVM;
