import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';
import { requiredString } from '../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  joinCode: requiredString.length(6).messages({
    ...requiredString.messages,
    'string.length': i18n.t('invalid_join_code', { ns: i18nNS.VALIDATION }),
  }),
});
