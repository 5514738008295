import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import urls from '../../../app/urls';
import AttachmentView from '../../../components/AttachmentView';
import Switch from '../../../components/HookFormControls/Switch';
import { BlueprintCoursePreview } from '../../../db/courses/types';
import { i18nNS } from '../../../i18n';
import { FormValues } from './vm';

interface Props {
  control: Control<FormValues, object>;
  syllabus?: BlueprintCoursePreview['syllabus'];
  isSubmitting: boolean;
}

const CopyCourseSyllabus = ({ control, syllabus, isSubmitting }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);
  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6Bold" component="label">
          {t('copy_course_syllabus', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <Switch
          name="syllabus"
          control={control}
          edge="end"
          inputProps={{
            'aria-label': t('click_to_select_copy_course_syllabus', { ns: i18nNS.CREATE_COURSE }),
            role: 'switch',
          }}
          disabled={!syllabus || isSubmitting}
        />
      </Stack>
      {syllabus && Object.values(syllabus).length > 0 ? (
        <AttachmentView
          attachment={syllabus}
          requestConfig={{
            data: { fileName: syllabus.name },
            method: 'GET',
            url: urls.downloadBlueprintCoursePreviewInfoFile,
          }}
          color="success"
        />
      ) : (
        <Typography>{t('this_course_has_no_topics_to_copy', { ns: i18nNS.CREATE_COURSE })}</Typography>
      )}
    </Stack>
  );
};

export default CopyCourseSyllabus;
