import { createPusherEvent } from '../../pusher/helpers';
import { ClassPusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';
import { ToBeDone, WithCurrentUser } from '../shared/types';

export type PollPublishedEvent = ClassPusherEvent<{
  pollId: MongoId;
  details: API.BasePoll['details'];
  stats: API.BasePoll['stats'];
  activities: API.BasePoll['activities'];
}>;

export type PollPublishedPayload = WithCurrentUser<
  PollPublishedEvent & {
    /** Indicates whether poll data exists in our redux state */
    doesExist: boolean;
  }
>;

export const pollPublishedEvent = createPusherEvent<PollPublishedEvent>(PusherEventName.POLL_PUBLISHED, {
  channels: [PusherChannel.COURSE],
});

export type PollSubmittedEvent = ClassPusherEvent<{
  pollId: MongoId;
  toBeDone: ToBeDone;
  submission: API.SubmitPollResponse['submission'];
}>;

export const pollSubmittedEvent = createPusherEvent<PollSubmittedEvent>(PusherEventName.POLL_SUBMITTED, {
  channels: [PusherChannel.COURSE],
});
