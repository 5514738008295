import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import Button from '../../components/Button';
import TextField from '../../components/HookFormControls/TextField';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import useSetNameVM from './vm';

const SetName = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { formMethods, onSubmit } = useSetNameVM();

  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Layout
      title={t('your_official_full_name_please', { ns: i18nNS.AUTH })}
      subtitle={t(
        'please_enter_your_official_full_name_as_it_appears_in_the_course_roster_watch_out_for_typos_and_auto_correct',
        { ns: i18nNS.AUTH }
      )}
    >
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={4}>
          <TextField
            control={control}
            name="firstName"
            label={t('first_name', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('type_your_first_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
            autoFocus
          />
          <TextField
            control={control}
            name="middleName"
            label={t('middle_name', { ns: i18nNS.AUTH })}
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('type_your_middle_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
          />
          <TextField
            control={control}
            name="lastName"
            label={t('last_name', { ns: i18nNS.AUTH })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('type_your_last_name_here', { ns: i18nNS.AUTH })}
            autoCorrect="off"
            fullWidth
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" component="footer">
          <Button
            type="submit"
            variant="contained"
            size={isMobile ? 'large' : 'xLarge'}
            fullWidth={isMobile}
            endIcon={<ChevronRightIcon />}
            isLoading={isSubmitting}
            aria-label={t('click_to_save_name', { ns: i18nNS.AUTH })}
          >
            {t('next', { ns: i18nNS.GLOSSARY })}
          </Button>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default SetName;
