import { createPusherEvent } from '../../pusher/helpers';
import { ClassActivityPusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';
import { ClassIncharge, CourseStudentUser, ToBeDone, WithCurrentUser } from '../shared/types';
import { QuizScoreByScheme } from './types';

export type QuizPublishedEvent = ClassActivityPusherEvent<{
  quizId: MongoId;
  quizNum: number;
  dueDateTime: UnixTime;
  publishedOn: UnixTime;
  details: API.BaseQuiz['details'];
  stats: API.BaseQuiz['stats'];
  activities: API.BaseQuiz['activities'];
  sender: ClassIncharge;
}>;

export type QuizPublishedPayload = WithCurrentUser<
  QuizPublishedEvent & {
    /** Indicates whether quiz data exists in our redux state */
    doesExist: boolean;
  }
>;

export const quizPublishedEvent = createPusherEvent<QuizPublishedEvent>(PusherEventName.QUIZ_PUBLISHED, {
  channels: [PusherChannel.COURSE],
});

export type QuizSubmittedEvent = ClassActivityPusherEvent<{
  quizId: MongoId;
  toBeDone: ToBeDone;
  score: QuizScoreByScheme;
  submittedOn: UnixTime;
  sender: CourseStudentUser;
}>;

export const quizSubmittedEvent = createPusherEvent<QuizSubmittedEvent>(PusherEventName.QUIZ_SUBMITTED, {
  channels: [PusherChannel.COURSE_TEAM],
});
