import { forwardRef, Ref } from 'react';

import { Stack, StackProps, styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';

export const noteClasses = {
  ...generateClasses('Note', [
    'root',
    'colorSuccess',
    'colorError',
    'colorWarning',
    'colorInfo',
    'icon',
    'text',
    'closeButton',
    'closeIcon',
  ]),
};

export const Root = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => (
    <Stack ref={ref} direction="row" alignItems="flex-start" {...props} />
  ))
)(({ theme }) => ({
  borderRadius: 8,
  [`&.${noteClasses.colorSuccess}`]: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[700],
  },
  [`&.${noteClasses.colorError}`]: {
    backgroundColor: theme.palette.error[50],
    color: theme.palette.error[700],
  },
  [`&.${noteClasses.colorWarning}`]: {
    backgroundColor: theme.palette.warning[50],
    color: theme.palette.warning[700],
  },
  [`&.${noteClasses.colorInfo}`]: {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary[700],
  },
  '&:focus-visible': {
    outline: `2px solid ${theme.palette.primary[400]}`,
    outlineOffset: theme.spacing(0.5),
  },
}));

export const Icon = styled((props: StackProps) => <Stack direction="row" {...props} />)(({ theme }) => ({
  /**
   * parent has align-items flex-start to place the icon on top-left even when we have multiple lines of text
   * When we have single-line of text, it looks a bit off-aligned
   * To fix that, we provide this negative margin on icon
   */
  marginTop: theme.spacing(-0.5),
}));
