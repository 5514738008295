import { createReducer } from '@reduxjs/toolkit';

import { fetchMyCourses } from '../courses/actions';
import { fetchFeatureUpdates, markFeatureUpdateAsRead, setFeatureUpdatesSeenAt } from './actions';
import { featureUpdatesFactory } from './helpers';
import { NewFeatureSummary } from './types';

interface Features {
  byName: Record<string, NewFeatureSummary>;
  seenAt: UnixTime;
  updatedAt: UnixTime;
}

const initialState: Features = {
  byName: {},
  seenAt: -1,
  updatedAt: -1,
};

const featuresReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchMyCourses.success, (state, action) => {
    const { newFeaturesAvailable, newFeaturesSeenAt, newFeaturesLastUpdatedAt } = action.payload;

    state.seenAt = newFeaturesSeenAt || -1;
    state.updatedAt = newFeaturesLastUpdatedAt || -1;

    for (const [featureName, summary] of Object.entries(newFeaturesAvailable)) {
      state.byName[featureName] = featureUpdatesFactory({
        featureName,
        summary,
      });
    }
  });

  builder.addCase(setFeatureUpdatesSeenAt.success, (state, action) => {
    const { newFeaturesSeenAt } = action.payload;
    state.seenAt = newFeaturesSeenAt;
  });

  builder.addCase(fetchFeatureUpdates.success, (state, action) => {
    const { featureList } = action.payload;

    for (const [featureName, summary] of Object.entries(featureList)) {
      state.byName[featureName] = featureUpdatesFactory({
        featureName,
        summary,
      });
    }
  });

  builder.addCase(markFeatureUpdateAsRead.success, (state, action) => {
    const { featureName } = action.payload;

    const featureSummary = state.byName[featureName];
    if (!featureSummary) return;

    featureSummary.isSeen = true;
  });
});

export default featuresReducer;
