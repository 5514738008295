import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import Button, { Props as ButtonProps } from '../../components/Button';
import ChevronRightIcon from '../../icons/ChevronRightIcon';

export const NextButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="contained" endIcon={<ChevronRightIcon />} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 120,
}));
