import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyToggleButtonGroup, {
  Props as AcadlyToggleButtonGroupProps,
} from '../FormControls/ToggleButtonGroup';

type VMProps<T extends FieldValues> = UseControllerProps<T>;

const useToggleButtonGroupVM = <T extends FieldValues>({ control, name, defaultValue }: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  return { controller };
};

export type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyToggleButtonGroupProps, 'ref' | 'value' | 'onChange'>;

const ToggleButtonGroup = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  className,
  ...props
}: Props<T>) => {
  const { controller } = useToggleButtonGroupVM({ name, control, defaultValue });
  const { field } = controller;
  const { value, onChange } = field;
  return <AcadlyToggleButtonGroup className={className} {...props} value={value} onChange={onChange} />;
};

export default ToggleButtonGroup;
