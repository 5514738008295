import { createReducer, Dictionary, isAnyOf } from '@reduxjs/toolkit';

import { getIsActivityPublished } from '../../utils/activities';
import { closeClass, fetchClassDetails } from '../classes/actions';
import { createComment } from '../comments/actions';
import {
  closeCourse,
  fetchAllSuggestedActivities,
  fetchSuggestedActivityData,
  hideSuggestedActivity,
  openCourse,
  useSuggestedActivity,
} from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import {
  ActivityType,
  CommentContext,
  CourseRole,
  QuizQuestionType,
  SubmissionStatus,
  SuggestedActivityType,
} from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  addQuizQuestion,
  createQuiz,
  deleteQuiz,
  deleteQuizQuestion,
  editPublishedQuiz,
  editQuiz,
  editQuizQuestion,
  fetchAllQuizzes,
  fetchIndividualQuizSubmission,
  fetchQuizDetails,
  fetchQuizSubmissions,
  publishQuiz,
  quizEdited,
  quizPublished,
  quizStopped,
  quizSubmitted,
  reorderQuizQuestions,
  saveQuizPublishPrefs,
  saveQuizSubmission,
  stopQuiz,
  submitQuiz,
} from './actions';
import {
  createPreferencesFactory,
  createQuizFactory,
  createQuizQuestionFactory,
  getQuizMaxMarks,
  getQuizTotalScore,
  suggestedQuizFactory,
} from './helpers';
import { QuizQuestion, QuizzesState, StudentQuizData } from './types';

const initialState: QuizzesState = {
  courseId: '',
  byId: {},
  suggestedById: {},
};

const baseQuizzesReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(closeClass, (state) => {
    state.suggestedById = {}; // clear older suggested activities
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { classId, currentUser, quizzes, userData, suggestedActivities } = action.payload;

    state.byId = {}; // clear older quizzes

    for (const quiz of quizzes) {
      state.byId[quiz._id] = createQuizFactory({
        quiz,
        currentUser,
        userData: userData.quizzes[quiz._id],
        publishDefaults: undefined,
        identifiers: { courseId: state.courseId, classId },
      });
    }

    for (const suggestedQuiz of suggestedActivities) {
      if (suggestedQuiz.nodeType !== SuggestedActivityType.QUIZ) continue;

      state.suggestedById[suggestedQuiz._id] = suggestedQuizFactory({
        suggestedQuiz,
      });
    }
  });

  builder.addCase(fetchAllSuggestedActivities.success, (state, action) => {
    const { activities: suggestedActivities } = action.payload;

    state.suggestedById = {}; // clear older suggested quizzes

    for (const suggestedQuiz of suggestedActivities) {
      if (suggestedQuiz.nodeType !== SuggestedActivityType.QUIZ) continue;
      state.suggestedById[suggestedQuiz._id] = suggestedQuizFactory({
        suggestedQuiz,
      });
    }
  });

  builder.addCase(fetchSuggestedActivityData.success, (state, action) => {
    if (action.payload.nodeType !== SuggestedActivityType.QUIZ) return;

    const { _id, identifiers, questions } = action.payload;

    const suggestedQuiz = state.suggestedById[_id];

    if (!suggestedQuiz) return;

    const questionIds: MongoId[] = [];
    const questionsById: Dictionary<QuizQuestion> = {};
    let index = 0;

    for (let question of questions) {
      if (question.nodeType !== 'question') continue;

      questionIds.push(question._id);

      if (question.details.type === QuizQuestionType.TF) {
        questionsById[question._id] = {
          id: question._id,
          num: index + 1,
          description: question.details.description.text,
          type: QuizQuestionType.TF,
          answerKey: question.details.answerKey as 't' | 'f',
        };
      } else if (question.details.type === QuizQuestionType.MCQ) {
        questionsById[question._id] = {
          id: question._id,
          num: index + 1,
          description: question.details.description.text,
          type: QuizQuestionType.MCQ,
          options: question.details.options,
          answerKey: question.details.answerKey,
        };
      } else {
        questionsById[question._id] = {
          id: question._id,
          num: index + 1,
          description: question.details.description.text,
          type: QuizQuestionType.SORTING,
          options: question.details.options,
          answerKey: question.details.answerKey,
        };
      }

      index++;
    }

    state.suggestedById[_id] = {
      ...suggestedQuiz,
      ...identifiers,
      questions: questionIds,
      questionsById,
    };
  });

  builder.addCase(useSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.QUIZ) return;

    const quiz = state.suggestedById[activityId];
    if (!quiz) return;

    quiz.isUsed = true;
    quiz.isHidden = false;
  });

  builder.addCase(hideSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.QUIZ) return;

    const quiz = state.suggestedById[activityId];
    if (!quiz) return;

    quiz.isHidden = true;
  });

  builder.addCase(fetchAllQuizzes.success, (state, action) => {
    const { activityData: quizzes, currentUser, userData } = action.payload;

    state.byId = {}; // clear older quizzes

    for (const quiz of quizzes) {
      state.byId[quiz._id] = createQuizFactory({
        quiz,
        currentUser,
        userData: userData.quizzes[quiz._id],
        publishDefaults: undefined,
        identifiers: { courseId: state.courseId, classId: quiz.identifiers.classId },
      });
    }
  });

  builder.addCase(createQuiz.success, (state, action) => {
    const { classId, toBeDone, currentUser, ...quiz } = action.payload;

    state.byId[quiz._id] = createQuizFactory({
      quiz,
      userData: undefined,
      currentUser,
      publishDefaults: quiz.publishDefaults,
      identifiers: { courseId: state.courseId, classId },
    });
  });

  builder.addCase(deleteQuiz.success, (state, action) => {
    const { quiz } = action.payload;
    state.byId[quiz.id] = undefined;
  });

  builder.addCase(fetchQuizDetails.success, (state, action) => {
    const { quizId, currentUser, isFirstAccess, currentTime, questions, publishDefaults, submission } =
      action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    if (isFirstAccess) {
      quiz.firstAccessedOn = currentTime;
    }

    quiz.lastAccessedOn = currentTime;

    quiz.questions = [];
    quiz.questionsById = {};

    for (const question of questions) {
      quiz.questions.push(question._id);
      quiz.questionsById[question._id] = createQuizQuestionFactory(question);
    }

    quiz.areQuestionsEdited = false;

    const isQuizPublished = getIsActivityPublished(quiz);
    /**
     * Published Poll:
     * publishDefaults are useless
     *
     * Unpublished Poll:
     * publishDefaults = default publish prefs + (overridden by) modified publish prefs
     */
    if (!isQuizPublished && publishDefaults) {
      quiz.preferences = createPreferencesFactory(publishDefaults);
    }

    const { userId, name, avatar, role } = currentUser;

    if (role === CourseRole.STUDENT) {
      const studentData = quiz.studentDataById[userId];

      quiz.studentDataById[userId] = {
        userId,
        name,
        avatar,
        status:
          !studentData || studentData.status === SubmissionStatus.NOT_ATTEMPTED
            ? SubmissionStatus.IN_PROGRESS
            : studentData.status,
        submissionsByQuestionId: submission || {},
        submittedOn: studentData?.submittedOn || -1,
        totalScore: getQuizTotalScore(submission, getQuizMaxMarks(quiz)),
      };
    }
  });

  builder.addCase(quizEdited, (state, action) => {
    const { activityId, editedOn, sender, activityType } = action.payload;

    if (activityType !== ActivityType.QUIZ) return;

    const quiz = state.byId[activityId];
    if (!quiz) return;

    const { quiz: data } = action.payload;

    if (data.title) quiz.title = data.title;
    if (data.instructions) quiz.instructions = data.instructions;
    if (data.attachments) quiz.attachments = data.attachments;

    quiz.editedBy = sender;
    quiz.editedOn = editedOn;

    quiz.areQuestionsEdited = data.areQuestionsEdited === 1;
  });

  builder.addCase(saveQuizPublishPrefs.success, (state, action) => {
    const { quizId, classId, ...prefs } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.preferences = prefs;
  });

  builder.addCase(publishQuiz.success, (state, action) => {
    const { quizId, dueDateTime, dueDateType, preferences, publishedOn, trueNum } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.sequenceNum = trueNum;
    quiz.dueDateTime = dueDateTime;
    quiz.publishedOn = publishedOn;

    quiz.preferences = { ...preferences, dueDateType };
    quiz.comments.isSubscribed = preferences.subscribeToComments;
  });

  builder.addCase(quizPublished, (state, action) => {
    const { classId, courseId, quizId, currentUser, details, activities, stats } = action.payload;

    state.byId[quizId] = createQuizFactory({
      quiz: { _id: quizId, nodeType: 'quiz', activities, details, stats },
      currentUser,
      publishDefaults: undefined,
      identifiers: { classId, courseId },
      userData: undefined,
    });
  });

  builder.addCase(saveQuizSubmission.success, (state, action) => {
    const { quizId, submission, currentUser } = action.payload;

    const studentData = state.byId[quizId]?.studentDataById?.[currentUser.userId];
    if (!studentData) return;

    studentData.submissionsByQuestionId = {};

    for (const { questionId, answerString } of submission) {
      studentData.submissionsByQuestionId[questionId] = {
        answerString: answerString,
      };
    }
  });

  builder.addCase(submitQuiz.success, (state, action) => {
    const { quizId, submission, currentUser, answerKeys = [], submittedOn } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    const { questionsById } = quiz;
    if (!questionsById) return;

    for (const q of answerKeys) {
      const question = questionsById[q._id];
      if (!question) continue;
      if (question.type === q.details.type) {
        question.answerKey = q.details.answerKey;
      }
    }

    const { userId, name, avatar } = currentUser;

    const studentData: StudentQuizData = {
      userId,
      name,
      avatar,
      submissionsByQuestionId: null,
      submittedOn,
      totalScore: getQuizTotalScore(submission, getQuizMaxMarks(quiz)),
      status:
        submittedOn > quiz.dueDateTime && quiz.dueDateTime !== -1
          ? SubmissionStatus.LATE
          : SubmissionStatus.SUBMITTED,
    };

    studentData.submissionsByQuestionId = {};

    for (const [questionId, response] of Object.entries(submission)) {
      studentData.submissionsByQuestionId[questionId] = response;
    }

    if (!quiz.studentDataById) {
      quiz.studentDataById = { [userId]: studentData };
    } else {
      quiz.studentDataById[userId] = studentData;
    }
  });

  builder.addCase(quizSubmitted, (state, action) => {
    const { quizId } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.totalSubmissions++;
  });

  builder.addCase(stopQuiz.success, (state, action) => {
    const { quizId, dueDateTime } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.dueDateTime = dueDateTime;
  });

  builder.addCase(quizStopped, (state, action) => {
    const { activityId: quizId, activityType, dueDateTime } = action.payload;

    if (activityType !== ActivityType.QUIZ) return;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.dueDateTime = dueDateTime;
  });

  builder.addCase(addQuizQuestion.success, (state, action) => {
    const { quizId, ...question } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    if (!quiz.questionsById) quiz.questionsById = {};
    quiz.questions.push(question._id);
    quiz.questionsById[question._id] = createQuizQuestionFactory(question);
  });

  builder.addCase(editQuizQuestion.success, (state, action) => {
    const { quizId, questionId, ...updates } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz?.questionsById) return;

    const question = quiz.questionsById[questionId];
    if (!question) return;

    let updatedQuestion: QuizQuestion;

    if (updates.type === QuizQuestionType.TF) {
      updatedQuestion = {
        id: questionId,
        num: question.num,
        type: QuizQuestionType.TF,
        description: updates.description,
        answerKey: updates.answerKey,
      };
    } else if (updates.type === QuizQuestionType.MCQ) {
      updatedQuestion = {
        id: questionId,
        num: question.num,
        type: QuizQuestionType.MCQ,
        description: updates.description,
        answerKey: updates.answerKey,
        options: updates.options,
      };
    } else {
      updatedQuestion = {
        id: questionId,
        num: question.num,
        type: QuizQuestionType.SORTING,
        description: updates.description,
        answerKey: updates.answerKey,
        options: updates.options,
      };
    }

    quiz.questionsById[questionId] = updatedQuestion;
  });

  builder.addCase(reorderQuizQuestions.success, (state, action) => {
    const { quizId, questionsOrder } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz?.questionsById) return;

    quiz.questions = questionsOrder
      .sort((a, b) => a.order - b.order)
      .map((questionOrder) => questionOrder.questionId);

    for (const { questionId, order } of questionsOrder) {
      const question = quiz.questionsById[questionId];
      if (!question) continue;
      question.num = order;
    }
  });

  builder.addCase(deleteQuizQuestion.success, (state, action) => {
    const { quizId, questionId } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    if (quiz.questionsById) {
      quiz.questions = quiz.questions.filter((qId) => qId !== questionId);
      quiz.questionsById[questionId] = undefined;
    }
  });

  builder.addCase(fetchQuizSubmissions.success, (state, action) => {
    const { quizId, submittedBy } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    for (const submission of submittedBy) {
      const { userId, name, avatar } = submission.identifiers;
      quiz.studentDataById[userId] = {
        userId,
        name,
        avatar,
        submissionsByQuestionId: null,
        status: submission.status.status,
        submittedOn: submission.status.submittedOn,
        totalScore: submission.status.score,
      };
    }
  });

  builder.addCase(fetchIndividualQuizSubmission.success, (state, action) => {
    const { quizId, studentId, submission: data } = action.payload;

    const studentData = state.byId[quizId]?.studentDataById[studentId];
    if (!studentData) return;

    studentData.submissionsByQuestionId = data.submission;
  });

  builder.addCase(createComment.success, (state, action) => {
    const { context } = action.payload;
    if (context.type !== CommentContext.QUIZ) return;

    const quiz = state.byId[context.id];
    if (!quiz) return;

    quiz.comments.total++;
    quiz.comments.seen++;
  });

  builder.addMatcher(isAnyOf(editQuiz.success, editPublishedQuiz.success), (state, action) => {
    const { quizId, currentUser, editedOn, title, attachments, instructions } = action.payload;

    const quiz = state.byId[quizId];
    if (!quiz) return;

    quiz.title = title;
    quiz.attachments = attachments;
    quiz.instructions = instructions || quiz.instructions;

    quiz.editedBy = currentUser;
    quiz.editedOn = editedOn;

    if (editQuiz.success.match(action)) {
      const { scoring } = action.payload;
      quiz.preferences.scoring = scoring;
    }

    if (editPublishedQuiz.success.match(action)) {
      const { questions } = action.payload;
      quiz.questions = [];
      quiz.questionsById = {};

      questions.forEach((question, index) => {
        const { id, ...qData } = question;
        quiz.questions.push(id);
        quiz.questionsById![id] = {
          ...qData,
          id,
          num: index + 1,
        };
      });
    }
  });
});

const quizzesReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.QUIZ }),
  baseQuizzesReducer
);

export default quizzesReducer;
