import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchBlueprintCoursePreview, initializeCourse } from '../../../db/courses/actions';
import { BlueprintCoursePreview } from '../../../db/courses/types';
import i18n, { i18nNS } from '../../../i18n';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

export interface FormValues {
  description: boolean;
  readingList: boolean;
  topics: boolean;
  syllabus: boolean;
}

const useCopyContentVM = () => {
  const requestDispatch = useRequestDispatch();

  const [blueprintCoursePreview, setBlueprintCoursePreview] = useState<BlueprintCoursePreview | null>(null);

  const {
    activeStep,
    closeDrawer,
    isFetchingCourseDetails,
    isCreatingProCourse,
    moveToPreviousStep: _moveToPreviousStep,
    moveToNextStep,
    sourceCourseId,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const isVisible = Boolean(!isFetchingCourseDetails && activeStep === CreateCourseStep.COPY_CONTENT);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      description: Boolean(blueprintCoursePreview?.description),
      readingList: Boolean(
        blueprintCoursePreview?.readingList &&
          (blueprintCoursePreview.readingList.books.length > 0 ||
            blueprintCoursePreview.readingList.links.length > 0 ||
            blueprintCoursePreview.readingList.files.length > 0)
      ),
      topics: Boolean(blueprintCoursePreview?.topics && blueprintCoursePreview.topics.length > 0),
      syllabus: Boolean(blueprintCoursePreview?.syllabus),
    },
    mode: 'all',
  });

  const moveToPreviousStep = () => _moveToPreviousStep();

  const handleInitializeCourse = handleSubmit(async (data) => {
    if (!sourceCourseId) return;

    const { description, readingList, topics, syllabus } = data;

    await requestDispatch(initializeCourse, {
      isCopied: true,
      copyOf: sourceCourseId,
      toCopy: {
        description,
        readingList,
        topics,
        syllabus,
      },
    });

    moveToNextStep();
  });

  useEffect(
    function fetchData() {
      if (!isVisible) return;
      if (!sourceCourseId) {
        throw new Error('Source course-id can not be empty!');
      }
      requestDispatch(fetchBlueprintCoursePreview, { courseId: sourceCourseId }).then((response) => {
        setBlueprintCoursePreview(response);
      });
    },
    [isVisible, requestDispatch, sourceCourseId]
  );

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    blueprintCoursePreview,
    closeDrawer,
    control,
    handleInitializeCourse,
    isSubmitting,
    isVisible,
    moveToPreviousStep,
  };
};

export default useCopyContentVM;
