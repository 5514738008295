import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography, TypographyProps } from '@mui/material';

import { ReactComponent as ZoomUsIcon } from '../../../../../assets/zoom-us-round.svg';
import Alert from '../../../../../components/Alert';
import AlertButton from '../../../../../components/AlertButton';
import { i18nNS } from '../../../../../i18n';
import Trash2Icon from '../../../../../icons/Trash2Icon';
import useWeeklyClassVM, { VMProps } from './vm';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),
  borderRadius: 6,
  border: `1px solid ${theme.palette.grey[300]}`,
  background: `linear-gradient(180deg, ${theme.palette.common.white} 0%, ${theme.palette.grey[50]} 100%)`,
}));

const WeekDayCell = styled((props: TypographyProps<'div'>) => (
  <Typography {...props} component="div" variant="paragraphMedium" />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary[100],
  borderRadius: 6,
  textTransform: 'capitalize',
  border: `1px solid ${theme.palette.primary[600]}`,
  color: theme.palette.primary[600],
}));

interface Props extends VMProps {
  className?: string;
}

const WeeklyClass = ({ className, ...vmProps }: Props) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE, i18nNS.COMMON]);
  const { days, deleteClass, formattedEndTime, formattedStartTime, isOnlineMeeting, venue } =
    useWeeklyClassVM(vmProps);
  return (
    <Root>
      <Stack direction="row" alignItems="flex-start" gap={2}>
        <Stack direction="row" flex={1} gap={2} mt={1} flexWrap="wrap">
          {days.map((day) => (
            <WeekDayCell key={day}>{day}</WeekDayCell>
          ))}
        </Stack>
        <AlertButton
          variant="text"
          color="error"
          edge="end"
          size="xSmall"
          aria-label={t('click_to_delete_this_class', { ns: i18nNS.CREATE_COURSE })}
        >
          <Trash2Icon />
          <Alert maxWidth="xs">
            <Alert.Body>
              <Typography variant="paragraphRegular" color="textPrimary">
                {t('are_you_sure_you_want_to_delete_this_class', { ns: i18nNS.CREATE_COURSE })}
              </Typography>
            </Alert.Body>
            <Alert.Footer>
              <Alert.Action variant="text">{t('no', { ns: i18nNS.GLOSSARY })}</Alert.Action>
              <Alert.Action color="error" variant="text" onClick={deleteClass}>
                {t('yes', { ns: i18nNS.GLOSSARY })}
              </Alert.Action>
            </Alert.Footer>
          </Alert>
        </AlertButton>
      </Stack>
      <Stack gap={1}>
        <Typography>
          {t('start_time_to_end_time', {
            ns: i18nNS.CREATE_COURSE,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          })}
        </Typography>
        {isOnlineMeeting ? (
          <Stack direction="row" gap={2} alignItems="center" component={Typography}>
            <ZoomUsIcon width={20} height={20} />
            {t('this_class_is_online', { ns: i18nNS.COMMON })}
          </Stack>
        ) : (
          <Typography>{t('at_venue', { ns: i18nNS.COMMON, venue })}</Typography>
        )}
      </Stack>
    </Root>
  );
};

export default WeeklyClass;
