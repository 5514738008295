import { useState } from 'react';

const useZoomIntegrationVM = () => {
  const [isZoomIntegrationDialogOpen, setIsZoomIntegrationDialogOpen] = useState(false);

  const handleCloseZoomIntegrationDialog = () => {
    setIsZoomIntegrationDialogOpen(false);
  };

  const handleZoomIntegration = () => {
    setIsZoomIntegrationDialogOpen(true);
  };

  return { isZoomIntegrationDialogOpen, handleCloseZoomIntegrationDialog, handleZoomIntegration };
};

export default useZoomIntegrationVM;
