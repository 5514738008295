import { API } from '../shared/api-responses';
import { Announcement } from './types';

export function createAnnouncementFactory({
  announcementId,
  courseId,
  details,
  userData,
}: {
  announcementId: MongoId;
  courseId: MongoId;
  details: API.AnnouncementDetails;
  userData: API.AnnouncementUserData | undefined;
}): Announcement {
  const { type, title, description, attachments = [], dueDateTime, createdBy, createdOn } = details;
  return {
    id: announcementId,
    type,
    courseId,
    title,
    description,
    attachments,
    dueDateTime,
    comments: { isSubscribed: false, total: 0, seen: 0 },
    createdBy,
    createdOn,
    editedOn: -1, // announcements can not be edited
    editedBy: null,
    views: userData?.timesFetched ?? 0,
    firstAccessedOn: userData?.firstAccessedOn ?? -1,
  };
}
