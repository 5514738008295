import { AriaAttributes, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import clsx from 'clsx';

import { i18nNS } from '../../i18n';
import EditIcon from '../../icons/EditIcon';
import StarIcon from '../../icons/StarIcon';
import Tag, { Props as TagProps } from '../Tag/index';
import Typography from '../Typography';
import {
  activityWidgetClasses,
  ActivityWidgetVariant,
  Description,
  IconWrapper,
  Root,
  UnseenCommentsBadge,
  UnseenCommentsIcon,
} from './styles';

interface ActivityTagProps extends TagProps {
  value: boolean | ReactNode | undefined;
  children: ReactNode;
}

interface Props extends AriaAttributes {
  id?: string;
  className?: string;
  classes?: Partial<typeof activityWidgetClasses>;
  icon: ReactNode;
  title: ReactNode;
  tags: Partial<
    Record<
      | 'unpublished'
      | 'urgent'
      | 'closed'
      | 'pastDue'
      | 'due'
      | 'new'
      | 'edited'
      | 'open'
      | 'queryClosed'
      | 'pending',
      boolean | ReactNode
    >
  >;
  unseenComments: number | ReactNode | undefined;
  headerMeta: ReactNode | undefined;
  description: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: ActivityWidgetVariant;
  tabIndex?: number;
}

const ActivityTag = ({ value, ...props }: ActivityTagProps) => {
  if (!value) return null;
  if (typeof value === 'boolean') return <Tag label={props.children} {...props} />;
  return <>{value}</>;
};

const ActivityWidget = ({
  className,
  classes,
  icon,
  title,
  tags,
  unseenComments,
  headerMeta,
  description,
  onClick,
  variant = 'default',
  ...props
}: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMON]);

  return (
    <Root {...props} className={clsx(activityWidgetClasses.root, className, classes?.root)} onClick={onClick}>
      <IconWrapper
        className={clsx(activityWidgetClasses.icon, activityWidgetClasses[variant], classes?.icon)}
      >
        {icon}
      </IconWrapper>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        className={clsx(activityWidgetClasses.header, classes?.header)}
      >
        <Typography
          variant="h6Bold"
          color="grey.500"
          className={clsx(activityWidgetClasses.title, classes?.title)}
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          className={clsx(activityWidgetClasses.tag, classes?.tag)}
        >
          <ActivityTag value={tags.unpublished} variant="outlined" color="error">
            {t('unpublished', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.urgent} color="error">
            {t('urgent', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.closed} variant="outlined" color="error">
            {t('closed', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.pastDue} variant="outlined" color="error">
            {t('past_due', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.due} variant="outlined" color={variant}>
            {t('due', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag
            value={tags.new}
            color="primary"
            variant="outlined"
            icon={<StarIcon aria-hidden variant="filled" />}
          >
            {t('new', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          {unseenComments && typeof unseenComments === 'number' ? (
            <Stack direction="row" alignItems="center">
              <UnseenCommentsIcon />
              <UnseenCommentsBadge color="error" label={unseenComments} />
            </Stack>
          ) : (
            unseenComments
          )}
          <ActivityTag value={tags.edited} color="default" variant="outlined" icon={<EditIcon aria-hidden />}>
            {t('edited', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.open} color="primary">
            {t('open', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.queryClosed} color="default">
            {t('closed', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
          <ActivityTag value={tags.pending} color="warning">
            {t('pending', { ns: i18nNS.GLOSSARY })}
          </ActivityTag>
        </Stack>
      </Stack>
      {headerMeta &&
        (typeof headerMeta === 'string' ? (
          <Typography
            variant="textSmRegular"
            color="grey.500"
            className={clsx(activityWidgetClasses.headerMeta, classes?.headerMeta)}
          >
            {headerMeta}
          </Typography>
        ) : (
          <div className={clsx(activityWidgetClasses.headerMeta, classes?.headerMeta)}>{headerMeta}</div>
        ))}
      {description && (
        <Description className={clsx(activityWidgetClasses.description, classes?.description)}>
          {description}
        </Description>
      )}
    </Root>
  );
};

export default ActivityWidget;
