import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { createBasicDemoCourse, createProDemoCourse } from '../../../db/courses/actions';
import { useRequestDispatch } from '../../../utils/request-actions';
import { FormValues } from './types';
import validationSchema from './validators';

export interface VMProps {
  isDemoPro: boolean;
  onClose: () => void;
  open: boolean;
}

export default function useDemoCouseDrawerVM({ isDemoPro, onClose, open }: VMProps) {
  const requestDispatch = useRequestDispatch();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      timezone: '',
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setValue, reset } = formMethods;

  const handleChangeTimezone = (timezone: string) => {
    setValue('timezone', timezone);
  };

  const handleCloseDrawer = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormValues> = async (data: FormValues) => {
    if (isDemoPro) {
      await requestDispatch(createProDemoCourse, {
        courseTimezone: data.timezone,
      });
    } else {
      await requestDispatch(createBasicDemoCourse, {
        courseTimezone: data.timezone,
      });
    }
    handleCloseDrawer();
  };

  useEffect(
    function setInitialValue() {
      if (!open) return;
      setValue('timezone', '');
    },
    [open, setValue]
  );

  return {
    isDemoPro,
    handleCloseDrawer,
    onSubmit,
    formMethods,
    handleChangeTimezone,
  };
}
