import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import ForNonDesktop from '../components/ForNonDesktop';
import AnalyticsButton from '../components/HeaderActions/AnalyticsButton';
import ChatButton from '../components/HeaderActions/ChatButton';
import { useCurrentUserWithRole } from '../courses/hooks';
import { selectResource } from '../db/resources/selectors';
import { CourseRole } from '../db/shared/types';
import { i18nNS } from '../i18n';
import routes, { ResourceParams } from '../pages/routes';
import { getIsActivityPublished, getUnseenComments } from '../utils/activities';
import { forDesktop } from '../utils/media-queries';

const ResourceHeaderActionsVM = () => {
  const params = useParams<ResourceParams>();

  const resource = useSelector(selectResource(params.resourceShortId));
  const isResourcePublished = getIsActivityPublished({ publishedOn: resource?.publishedOn });

  const currentUser = useCurrentUserWithRole();

  const numUnseenComments = resource ? getUnseenComments(resource) : 0;

  return { params, isResourcePublished, currentUser, numUnseenComments };
};

const ResourceHeaderActions = () => {
  const isDesktop = useMediaQuery(forDesktop);
  const { t } = useTranslation([i18nNS.RESOURCE]);

  const { params, isResourcePublished, currentUser, numUnseenComments } = ResourceHeaderActionsVM();

  return (
    <>
      {currentUser.role !== CourseRole.STUDENT && isResourcePublished && (
        <AnalyticsButton
          to={routes.resourceAnalytics.url(params)}
          edge={isDesktop ? 'end' : undefined}
          aria-label={t('click_to_open_resource_analytics', { ns: i18nNS.RESOURCE })}
        />
      )}
      <ForNonDesktop>
        <ChatButton edge="end" numUnseenComments={numUnseenComments} />
      </ForNonDesktop>
    </>
  );
};

export default ResourceHeaderActions;
