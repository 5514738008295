import { styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../components/Typography';

export const UnorderedList = styled('ul')(({ theme }) => ({
  paddingInlineStart: theme.spacing(2.5),
}));

export const StyledTypography = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({}));
