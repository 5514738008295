import Joi from 'joi';

import i18n, { i18nNS } from '../../../../i18n';
import { requiredString } from '../../../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  couponCode: requiredString.length(10).messages({
    ...requiredString.messages,
    'string.length': i18n.t('coupon_code_should_be_N_characters', { ns: i18nNS.VALIDATION, count: 10 }),
  }),
});
