import axios from 'axios';

export enum RequestStatus {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  COMPLETE = 'complete',
  FAILED = 'failed',
}

export const request = axios.create();

export default request;
