import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const BellIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M20.52 15.21L18.72 13.4V8.93999C18.744 7.28381 18.1679 5.6749 17.0981 4.41032C16.0284 3.14575 14.5372 2.31092 12.9 2.05999C11.9498 1.93485 10.9839 2.0138 10.0666 2.29156C9.14936 2.56932 8.30188 3.03951 7.58074 3.67075C6.85959 4.30199 6.28136 5.07976 5.88462 5.95218C5.48788 6.8246 5.28176 7.7716 5.28001 8.72999V13.4L3.48001 15.21C3.25392 15.4399 3.1006 15.7313 3.03921 16.0478C2.97783 16.3643 3.01111 16.6919 3.13489 16.9896C3.25867 17.2873 3.46746 17.542 3.73516 17.7217C4.00286 17.9014 4.31759 17.9982 4.64001 18H8.00001V18.34C8.04672 19.3552 8.49396 20.3105 9.24374 20.9965C9.99351 21.6826 10.9847 22.0434 12 22C13.0154 22.0434 14.0065 21.6826 14.7563 20.9965C15.5061 20.3105 15.9533 19.3552 16 18.34V18H19.36C19.6824 17.9982 19.9972 17.9014 20.2649 17.7217C20.5326 17.542 20.7414 17.2873 20.8651 16.9896C20.9889 16.6919 21.0222 16.3643 20.9608 16.0478C20.8994 15.7313 20.7461 15.4399 20.52 15.21V15.21ZM14 18.34C13.9446 18.821 13.7057 19.262 13.3331 19.5713C12.9605 19.8805 12.483 20.0341 12 20C11.517 20.0341 11.0395 19.8805 10.6669 19.5713C10.2944 19.262 10.0555 18.821 10 18.34V18H14V18.34Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.52 15.21L18.72 13.4V8.93999C18.7439 7.28381 18.1679 5.6749 17.0981 4.41032C16.0284 3.14575 14.5372 2.31092 12.9 2.05999C11.9498 1.93485 10.9839 2.0138 10.0666 2.29156C9.14934 2.56932 8.30187 3.03951 7.58072 3.67075C6.85958 4.30199 6.28134 5.07976 5.8846 5.95218C5.48787 6.8246 5.28175 7.7716 5.28 8.72999V13.4L3.48 15.21C3.2539 15.4399 3.10058 15.7313 3.0392 16.0478C2.97781 16.3643 3.01109 16.6919 3.13487 16.9896C3.25866 17.2873 3.46745 17.542 3.73515 17.7217C4.00285 17.9014 4.31758 17.9982 4.64 18H8V18.34C8.04671 19.3552 8.49395 20.3105 9.24372 20.9965C9.9935 21.6826 10.9847 22.0434 12 22C13.0153 22.0434 14.0065 21.6826 14.7563 20.9965C15.506 20.3105 15.9533 19.3552 16 18.34V18H19.36C19.6824 17.9982 19.9971 17.9014 20.2648 17.7217C20.5325 17.542 20.7413 17.2873 20.8651 16.9896C20.9889 16.6919 21.0222 16.3643 20.9608 16.0478C20.8994 15.7313 20.7461 15.4399 20.52 15.21V15.21ZM14 18.34C13.9445 18.821 13.7056 19.262 13.3331 19.5713C12.9605 19.8805 12.483 20.0341 12 20C11.517 20.0341 11.0395 19.8805 10.6669 19.5713C10.2944 19.262 10.0555 18.821 10 18.34V18H14V18.34ZM5.51 16L6.69 14.82C6.87717 14.6339 7.02566 14.4127 7.12692 14.169C7.22818 13.9252 7.28021 13.6639 7.28 13.4V8.72999C7.28054 8.05539 7.42538 7.38871 7.70479 6.7747C7.9842 6.16068 8.39172 5.61354 8.9 5.16999C9.40142 4.71567 9.99557 4.37574 10.6413 4.17372C11.2871 3.9717 11.9691 3.91242 12.64 3.99999C13.7965 4.18776 14.8462 4.787 15.5959 5.68737C16.3456 6.58773 16.7448 7.72862 16.72 8.89999V13.4C16.7185 13.6632 16.7689 13.9241 16.8685 14.1678C16.968 14.4115 17.1146 14.6331 17.3 14.82L18.49 16H5.51Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default BellIcon;
