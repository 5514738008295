import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const EditIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.4 7.34L16.66 4.6C16.3024 4.26409 15.8338 4.07135 15.3433 4.05845C14.8529 4.04554 14.3748 4.21336 14 4.53L4.99999 13.53C4.67676 13.856 4.4755 14.2832 4.42999 14.74L3.99999 18.91C3.98652 19.0565 4.00553 19.2041 4.05565 19.3424C4.10578 19.4807 4.18579 19.6062 4.28999 19.71C4.38343 19.8027 4.49425 19.876 4.61609 19.9258C4.73792 19.9755 4.86838 20.0008 4.99999 20H5.08999L9.25999 19.62C9.71679 19.5745 10.144 19.3732 10.47 19.05L19.47 10.05C19.8193 9.68096 20.0081 9.1885 19.995 8.68053C19.9818 8.17256 19.7679 7.6905 19.4 7.34V7.34ZM16 10.68L13.32 8L15.27 6L18 8.72999L16 10.68Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4 7.34L16.66 4.6C16.3024 4.26409 15.8338 4.07135 15.3433 4.05845C14.8529 4.04554 14.3748 4.21336 14 4.53L4.99999 13.53C4.67676 13.856 4.4755 14.2832 4.42999 14.74L3.99999 18.91C3.98652 19.0565 4.00553 19.2041 4.05565 19.3424C4.10578 19.4807 4.18579 19.6062 4.28999 19.71C4.38343 19.8027 4.49425 19.876 4.61609 19.9258C4.73792 19.9755 4.86838 20.0008 4.99999 20H5.08999L9.25999 19.62C9.71679 19.5745 10.144 19.3732 10.47 19.05L19.47 10.05C19.8193 9.68096 20.0081 9.1885 19.995 8.68053C19.9818 8.17256 19.7679 7.6905 19.4 7.34V7.34ZM9.07999 17.62L6.07999 17.9L6.34999 14.9L12 9.31999L14.7 12.02L9.07999 17.62ZM16 10.68L13.32 8L15.27 6L18 8.72999L16 10.68Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default EditIcon;
