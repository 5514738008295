import { useSelector } from 'react-redux';

import { selectCommentGroupsInReverseOrder } from '../../../../../db/comments/selectors';

const useCommentGroupsVM = () => {
  const commentGroups = useSelector(selectCommentGroupsInReverseOrder);

  return { commentGroups };
};

export default useCommentGroupsVM;
