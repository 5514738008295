import { Poll } from '../db/polls/types';
import { ClassRole, CourseRole, SubmissionStatus, TipStatusKey } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { PollOptionColor } from '../styles/colors';
import { getIsActivityPublished, getIsManuallyClosedActivity } from '../utils/activities';
import { unix } from '../utils/datetime';

export function getPollHeader(poll: Pick<Poll, 'sequenceNum'> | undefined) {
  if (!poll?.sequenceNum || poll.sequenceNum <= 0) return i18n.t('poll', { ns: i18nNS.GLOSSARY });
  return i18n.t('poll_num', { ns: i18nNS.POLL, num: `${poll.sequenceNum}`.padStart(2, '0') });
}

export function getCanAttemptPoll(poll: Poll | undefined, role = ClassRole.STUDENT, studentId?: string) {
  if (!poll) return false;

  if (role !== ClassRole.STUDENT) return false;

  if (!studentId) return false;

  const studentData = poll.studentDataById[studentId];
  if (!studentData) return true;

  const isSubmitted = [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(studentData.status);
  if (isSubmitted) return false;

  if (poll.preferences.allowLate) return true;

  const isPollManual = getIsManuallyClosedActivity(poll);
  const isDueDateTimeManual = isPollManual && poll.dueDateTime <= 0;

  // added +1 for time out dialogs to appear
  const isPollDueDateTimeInFuture = poll.dueDateTime + 1 >= unix();

  return isDueDateTimeManual || isPollDueDateTimeInFuture;
}

export function getPollQuestionOptionColor(
  currentOptionNum: number,
  selectedOptionNum: number | undefined,
  isPollSubmitted: boolean
) {
  if (!isPollSubmitted) return PollOptionColor.BLUE;
  if (selectedOptionNum === currentOptionNum) return PollOptionColor.BLUE;
  return PollOptionColor.GREY;
}

export function getIsPollDeadlinePast(poll: Poll | undefined) {
  if (!poll) return;

  const isManuallyClosedPoll = getIsManuallyClosedActivity(poll);
  const isManualDueDateTime = isManuallyClosedPoll && poll.dueDateTime <= 0;
  if (isManualDueDateTime) return false;

  if (poll.dueDateTime) return poll.dueDateTime <= unix();

  return false;
}

export function getCanFetchPollComments() {
  return true;
}

export function getCanSendPollComments(poll: Poll | undefined, isCourseArchived: boolean | undefined) {
  if (isCourseArchived) return false;

  if (!poll) return false;

  const isPollPublished = getIsActivityPublished(poll);
  return isPollPublished;
}

export function getEmptyCommentsMessageForPoll(poll: Poll | undefined) {
  if (!poll) return i18n.t('there_are_no_comments_yet', { ns: i18nNS.COMMON });

  const isPollPublished = getIsActivityPublished(poll);
  if (!isPollPublished) {
    return i18n.t('comments_will_appear_here_once_this_activity_is_published', { ns: i18nNS.CLASS });
  }

  return i18n.t(
    'every_course_team_member_has_access_to_this_poll_discussion_thread_before_or_after_responding_to_the_poll',
    { ns: i18nNS.POLL }
  );
}

export function getPollPageTipContent(tipKey: TipStatusKey, role: CourseRole) {
  switch (tipKey) {
    case 'pollMainFloatingButton':
      return i18n.t(
        'use_this_button_to_add_the_poll_question_you_can_add_images_too_as_poll_options_as_of_now_each_poll_can_have_only_one_question',
        { ns: i18nNS.POLL }
      );
    case 'pollMainChat':
      if (role === CourseRole.STUDENT) {
        return i18n.t('tap_here_to_discuss_this_poll_with_the_class', { ns: i18nNS.POLL });
      }
      return i18n.t(
        'in_case_the_course_members_need_to_discuss_the_poll_options_and_outcomes_they_can_use_the_poll_discussion_section_that_can_be_accessed_through_here',
        { ns: i18nNS.POLL }
      );
    case 'pollMainSubmit':
      return i18n.t('once_you_have_selected_an_option_use_the_submit_button_to_submit_your_response', {
        ns: i18nNS.POLL,
      });
    default:
      return '';
  }
}
