import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaPlayer } from '../../components/MediaPlayerPip';
import { CourseMember, CourseRole } from '../../db/shared/types';
import { i18nNS } from '../../i18n';
import { format } from '../../utils/datetime';
import { CourseWidgetData } from '../types';
import {
  getCanSeeActivities,
  getCanSeeComments,
  getCanSeeQueries,
  getNumUnseenActivities,
  getNumUnseenComments,
  getQueriesCount,
  getQueriesLabel,
  getQueriesTagColor,
} from './helpers';

interface VMProps {
  course: CourseWidgetData;
}

const COURSE_DATE_FORMAT = 'MMM dd, yyyy';

export default function useCourseWidgetVM({ course }: VMProps) {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { isOpen, getContextData } = useMediaPlayer();
  const playingMedia = getContextData();
  const isPlayingMedia = Boolean(isOpen && course && playingMedia?.courseId === course.id);

  const admin = useMemo<CourseMember>(() => {
    const admin = course.team.admin;

    return {
      ...admin,
      name: course.myRole === CourseRole.ADMIN ? t('you', { ns: i18nNS.GLOSSARY }) : admin.name,
    };
  }, [course, t]);

  const dates = useMemo(() => {
    if (!course) return '';

    const { startDate, endDate } = course.schedule;
    if (startDate === -1 || endDate === -1) return '';

    return `${format(startDate, COURSE_DATE_FORMAT)} - ${format(endDate, COURSE_DATE_FORMAT)}`;
  }, [course]);

  const canSeeQueries = getCanSeeQueries(course);
  const queriesCount = getQueriesCount(course);
  const queriesLabel = getQueriesLabel(course);
  const queriesTagColor = getQueriesTagColor(course);

  const canSeeActivities = getCanSeeActivities(course);
  const numUnseenActivities = getNumUnseenActivities(course);

  const canSeeComments = getCanSeeComments(course);
  const numUnseenComments = getNumUnseenComments(course);

  return {
    admin,
    dates,
    isPlayingMedia,
    canSeeQueries,
    queriesCount,
    queriesLabel,
    queriesTagColor,
    canSeeActivities,
    numUnseenActivities,
    canSeeComments,
    numUnseenComments,
  };
}
