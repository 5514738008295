import { forwardRef, Ref } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';

import SpinnerIcon from '../../icons/LoaderIcon';
import { Chip, Props as StylesProps } from './styles';
import useTagVM, { VMProps } from './vm';

export interface Props extends VMProps, StylesProps {
  id?: string;
  tabIndex?: number;
}

const Tag = (
  { id, variant, color = 'default', icon, label, closeIcon, disabled, className, classes, ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  const { isLoading, handleClose, onClose } = useTagVM(props);

  return (
    <Chip
      id={id}
      ref={ref}
      label={label}
      variant={variant}
      icon={icon}
      color={color}
      deleteIcon={isLoading ? <SpinnerIcon /> : closeIcon ? closeIcon : <CloseIcon />}
      onDelete={onClose ? handleClose : undefined}
      disabled={disabled || isLoading}
      className={className}
      classes={classes}
      size="small"
      {...props}
    />
  );
};

export default forwardRef(Tag);
