const en = {
  /** Zoom integration dialog */
  acadly_s_plugin_for_zoom_is_an_acadly_pro_feature_that_is_available_for_verified_instructors_only:
    "Acadly's Plugin for Zoom is an Acadly Pro feature that is available for verified instructors only",
  to_be_a_verified_acadly_instructor_please_send_a_request_to_support_acadly_com_we_will_get_back_to_you_within_24_hours:
    'To be a verified Acadly instructor, please send a request to <2>support@acadly.com</2>. We will get back to you within 24 hours.',
  acadly_s_plugin_for_zoom_is_an_acadly_pro_feature_that_enables_seamless_online_hybrid_and_hy_flex_instruction_using_just_the_acadly_interface_and_your_existing_zoom_account:
    "Acadly's Plugin for Zoom is an Acadly Pro feature that enables seamless online, hybrid, and HyFlex instruction using just the Acadly interface and your existing Zoom account",
  to_integrate_zoom_with_acadly_you_must_create_a_free_trial_course_to_take_acadly_pro_for_a_test_run:
    'To integrate Zoom with Acadly you must create a Free Trial Course to take Acadly Pro for a test run.',
  zoom_icon: 'Zoom Icon',
  authorize_acadly_to_use_zoom: 'Authorize Acadly to use Zoom',
  once_added_acadly_will_be_able_to: 'Once added, Acadly will be able to',
  create_and_manage_zoom_meetings_for_your_classes: 'Create and manage Zoom meetings for your classes',
  manage_student_participation_in_the_created_zoom_meetings:
    'Manage student participation in the created Zoom meetings',
  acadly_uses_and_stores_your_zoom_account_details_to_create_meetings_on_your_behalf_and_capture_user_events_for_these_meetings_to_maintain_the_integrity_of_the_meeting:
    'Acadly uses and stores your Zoom account details to create meetings on your behalf AND capture user events for these meetings to maintain the integrity of the meeting',
  acadly_only_captures_events_for_the_meetings_that_are_created_using_acadly:
    'Acadly ONLY captures events for the meetings that are created using Acadly',
  when_acadly_is_uninstalled_from_your_zoom_account_all_your_zoom_account_details_are_deleted_irrevocably_from_acadly_s_servers:
    "When Acadly is uninstalled from your Zoom account, all your Zoom account details are deleted irrevocably from Acadly's servers",
  add_to_zoom: 'Add to zoom',
  uninstall_acadly_from_zoom: 'Uninstall Acadly from Zoom',
  you_can_deauthorize_acadly_by_navigating_to_the_zoom_marketplace_and_uninstalling_manually_once_deauthorized_acadly_will_remove_all_the_zoom_data_associated_with_your_user_account:
    'You can deauthorize Acadly by navigating to the Zoom Marketplace and uninstalling manually. Once deauthorized, Acadly will remove all the Zoom data associated with your user account',
  uninstall_at_zoom_marketplace: 'Uninstall at zoom marketplace',
  click_to_install_acadly_at_zoom_marketplace: 'click to install Acadly at zoom marketplace',
  click_to_unistall_acadly_at_zoom_marketplace: 'click to uninstall Acadly at zoom marketplace',

  /* Zoom meeting area */
  to_create_zoom_meetings_on_your_behalf_acadly_needs_limited_access_to_your_zoom_account:
    "To create Zoom meetings on your behalf, Acadly needs limited access to your Zoom account. Please click on the button below to authorize Acadly's access to your Zoom account",
  you_will_be_able_to_create_a_video_meeting_at_least_15_minutes_before_the_scheduled_start_time:
    'You will be able to create a video meeting at least 15 minutes before the scheduled start time',
  this_meeting_is_being_broadcast_from_another_instance:
    'This meeting is being broadcast from another instance',
  create_a_new_zoom_meeting: 'Create a new Zoom meeting',
  host_has_already_created_an_online_meeting_and_may_go_ahead_with_it:
    '{{host}} has already created an online meeting and may go ahead with it',
  to_start_broadcasting_your_video_to_students_enrolled_in_acadly_you_must_first_create_a_new_zoom_meeting:
    'To start broadcasting your video to students enrolled in Acadly, you must first create a new Zoom meeting for this class by clicking on the button below',
  auto_start_meeting_recording: 'Auto start meeting recording',
  meeting_auto_recording_will_begin_as_soon_as_you_launch_zoom_even_if_you_re_not_broadcasting_on_acadly:
    "Meeting Auto Recording will begin as soon as you launch Zoom, even if you're not broadcasting on Acadly",
  for_cloud_auto_recording_to_work_you_need_to_turn_on_automatic_recording_in_zoom_please_follow_the_directions_here:
    'For Cloud Auto Recording to work, you need to turn on Automatic Recording in Zoom. Please follow the directions <1>here</1>',
  would_you_like_save_this_setting: 'Would you like save this setting',
  create_zoom_meeting: 'Create zoom meeting',
  start_zoom_meeting_as_host: 'Start Zoom meeting as host',
  launch_meeting_via_zoom: 'Launch meeting via zoom',
  launching_zoom_dot_dot_dot: 'Launching zoom...',
  to_start_the_meeting_please_click_on_the_button_below_to_launch_the_zoom_app:
    'To start the meeting, please click on the button below to launch the Zoom app and join the meeting as the host. Once the meeting is started, you will be able to broadcast',
  waiting_for_zoom: 'Waiting for Zoom',
  checking_if_meeting_started_on_your_zoom_app_it_should_happen_within_seconds:
    'Checking if meeting started on your Zoom app. It should happen within seconds but may take as many as 2 minutes to detect',
  we_were_unable_to_detect_if_the_zoom_meeting_has_started:
    "We were unable to detect if the Zoom meeting has started. If it hasn't yet, you can click on Retry Launch or look at the <1>troubleshooting guide</1>",
  retry_launch: 'Retry launch',
  join_meeting: 'Join meeting',
  you_are_not_in_the_online_meeting: 'You are not in the online meeting',
  an_online_meeting_is_in_progress_please_click_on_the_button_below_to_join:
    'An online meeting is in progress. Please click on the button below to join',
  host_is_going_to_broadcast_a_meeting_so_you_can_t_launch_the_meeting:
    "{{host}} is going to broadcast a meeting so you can't launch the meeting",
  video_meeting_will_be_available_once_the_broadcast_begins:
    'Video meeting will be available once the broadcast begins',
  meeting_is_in_progress: 'Meeting is in progress...',
  meeting_has_been_ended_by_class_in_charge: 'Meeting has been ended by class in-charge',
  the_video_meeting_for_this_class_is_over: 'The video meeting for this class is over',
  broadcast_stopped: 'Broadcast stopped',
  the_students_now_cannot_access_the_meeting_using_acadly_however_the_meeting_is_still_running_in_the_zoom_app:
    'The students now cannot access the meeting using Acadly. However, the meeting is still running in the Zoom app. You can either restart the broadcast by clicking the button below, or end the meeting using the Zoom app',
  rejoin_created_meeting: 'Rejoin created meeting',
  in_meeting: 'In meeting',
  being_addressed: 'Being addressed',
  raise_hand: 'Raise hand',
  lower_hand: 'Lower hand',
  leave_meeting: 'Leave meeting',
  not_broadcasting: 'Not broadcasting',
  start_broadcasting: 'Start broadcasting',
  broadcasting: 'Broadcasting...',
  stop_recording_attendance: 'Stop Recording Attendance',
  addressing: 'Addressing',
  student_name: '{{studentName}}',
  N_raised_hands_one: '{{count}} Raised hand',
  N_raised_hands_other: '{{count}} Raised hands',
  N_students_are_here_one: '{{count}} student is here',
  N_students_are_here_other: '{{count}} students are here',

  /* Desktop Notification */
  allow_desktop_notifications: 'Allow desktop notifications',
  acadly_will_send_desktop_notifications_only_while_an_online_meeting_is_in_progress:
    'Acadly will send desktop notifications only while an online meeting is in progress',

  /* Attentiveness Check */
  you_turned_up_when_the_the_instructor_was_checking_the_attentiveness_of_the_class_we_have_let_them_know_you_re_here:
    'You turned up when the the instructor was checking the attentiveness of the class. We have let them know you’re here.',
  attentiveness_check: 'Attentiveness check',

  /* Online Attendance Alert Student */
  classroom_attendance_is_being_recorded: 'Classroom attendance is being recorded',
  are_you_attending_the_lecture_in_person_or_remotely: 'Are you attending the lecture in-person or remotely?',
  taker_name_is_recording_presence_of_students_attending_the_ongoing_lecture_in_person:
    '{{takerName}} is recording presence of students attending the ongoing lecture in-person.',
  attending_remotely: 'ATTENDING REMOTELY',
  attending_in_person: 'ATTENDING IN-PERSON',

  /* RemoteAttendanceInProgress */
  attendance_is_in_progress: 'Attendance is in progress',

  /* BroadcastStartedFooterView */
  raised_hands: 'Raised hands',
  click_on_a_student_to_allow_them_to_speak: 'Click on a student to allow them to speak',

  /** ZoomAppHeader */
  pop_out: 'Pop out',
  pop_in: 'Pop In',
  collapse_to_corner: 'Collapse to Corner',
  exit_full_screen: 'Exit full screen',
  full_screen: 'Full screen',
};

export default en;
