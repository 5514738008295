import { forwardRef, Ref } from 'react';

import clsx from 'clsx';

import SwitchCheckedIcon from '../../../icons.deprecated/SwitchCheckedIcon';
import SwitchUncheckedIcon from '../../../icons.deprecated/SwitchUncheckedIcon';
import { Props, StyledFormControlLabel, StyledSwitch, switchClasses } from './styles';

export type { Props };

const Switch = (
  { label, disabled, labelPlacement, className, classes, ...props }: Props,
  ref: Ref<HTMLLabelElement>
) => {
  return (
    <StyledFormControlLabel
      ref={ref}
      label={label}
      disabled={disabled}
      labelPlacement={labelPlacement}
      className={clsx(className, switchClasses.root, classes?.root)}
      classes={{
        root: clsx(switchClasses.root, classes?.root),
        label: clsx(switchClasses.label, classes?.label),
        labelPlacementStart: clsx(switchClasses.labelPlacementStart, classes?.labelPlacementStart),
        labelPlacementTop: clsx(switchClasses.labelPlacementTop, classes?.labelPlacementTop),
        labelPlacementBottom: clsx(switchClasses.labelPlacementBottom, classes?.labelPlacementBottom),
        disabled: clsx(switchClasses.labelDisabled, classes?.labelDisabled),
        error: clsx(switchClasses.labelError, classes?.labelError),
      }}
      control={
        <StyledSwitch
          {...props}
          className={clsx(switchClasses.switchRoot, classes?.switchRoot)}
          focusVisibleClassName={switchClasses.focusVisible}
          classes={{
            root: clsx(switchClasses.switchRoot, classes?.switchRoot),
            switchBase: clsx(switchClasses.switchBase, classes?.switchBase),
            checked: clsx(switchClasses.checked, classes?.checked),
            disabled: clsx(switchClasses.disabled, classes?.disabled),
            track: clsx(switchClasses.track, classes?.track),
          }}
          icon={<SwitchUncheckedIcon className={clsx(switchClasses.thumb, classes?.thumb)} />}
          checkedIcon={<SwitchCheckedIcon className={clsx(switchClasses.thumb, classes?.checkedThumb)} />}
        />
      }
    />
  );
};

export default forwardRef(Switch);
