import { createTheme } from '@mui/material';

import baseTheme from './base';
import { createThemeColor } from './helpers';
import { ColorName, colorPalette, ColorVariant } from './variables';

const {
  blue,
  green,
  orange,
  red,
  grey,
}: Record<ColorName, Record<ColorVariant, string>> = Object.freeze({
  blue: {
    50: '#222F44',
    100: '#273853',
    200: '#324C77',
    300: '#365A96',
    400: '#4978C5',
    500: '#66B2FF',
    600: '#3399FF',
    700: '#0B6ED0',
    800: '#004C99',
    900: '#003A75',
  },
  green: {
    50: 'rgba(45, 103, 88, 0.1)',
    100: 'rgba(39, 104, 87, 0.2)',
    200: 'rgba(45, 134, 111, 0.3)',
    300: 'rgba(47, 147, 121, 0.4)',
    400: 'rgba(45, 169, 137, 0.5)',
    500: 'rgba(77, 219, 182, 0.6)',
    600: 'rgba(107, 239, 205, 0.7)',
    700: 'rgba(23, 217, 162, 0.8)',
    800: 'rgba(9, 185, 135, 0.9)',
    900: 'rgba(0, 122, 91, 1.0)',
  },
  orange: {
    50: 'rgba(103, 69, 45, 0.1)',
    100: 'rgba(104, 66, 39, 0.2)',
    200: 'rgba(134, 82, 45, 0.3)',
    300: 'rgba(147, 89, 47, 0.4)',
    400: 'rgba(169, 97, 45, 0.5)',
    500: 'rgba(234, 158, 102, 0.6)',
    600: 'rgba(241, 136, 59, 0.7)',
    700: 'rgba(217, 104, 23, 0.8)',
    800: 'rgba(185, 82, 9, 0.9)',
    900: 'rgba(122, 51, 0, 1.0)',
  },
  red: {
    50: 'rgba(103, 45, 51, 0.1)',
    100: 'rgba(104, 39, 45, 0.2)',
    200: 'rgba(134, 45, 54, 0.3)',
    300: 'rgba(147, 47, 57, 0.4)',
    400: 'rgba(169, 45, 57, 0.5)',
    500: 'rgba(231, 80, 95, 0.6)',
    600: 'rgba(241, 50, 69, 0.7)',
    700: 'rgba(198, 21, 39, 0.8)',
    800: 'rgba(161, 8, 23, 0.9)',
    900: 'rgba(122, 0, 12, 1.0)',
  },
  grey: {
    50: '#212E42',
    100: '#29374C',
    200: '#2E3A4C',
    300: '#384457',
    400: '#f8fafc',
    500: '#f1f5f9',
    600: '#e2e8f0',
    700: '#cbd5e1',
    800: '#94a3b8',
    900: '#64748b',
  },
});

const theme = createTheme(baseTheme, {
  palette: {
    mode: 'dark',
    primary: createThemeColor({
      colorSet: blue,
      highContrastColor: colorPalette.black,
      lowContrastColor: colorPalette.white,
    }),
    error: createThemeColor({
      colorSet: red,
      highContrastColor: colorPalette.black,
      lowContrastColor: colorPalette.white,
      contrastThreshold: 300,
    }),
    warning: createThemeColor({
      colorSet: orange,
      highContrastColor: colorPalette.black,
      lowContrastColor: colorPalette.white,
      contrastThreshold: 700,
    }),
    success: createThemeColor({
      colorSet: green,
      highContrastColor: colorPalette.black,
      lowContrastColor: colorPalette.white,
      contrastThreshold: 500,
    }),
    grey: createThemeColor({
      colorSet: grey,
      highContrastColor: colorPalette.black,
      lowContrastColor: colorPalette.white,
      contrastThreshold: 500,
    }),
    background: {
      default: '#1e293b',
      paper: colorPalette.black,
      level1: grey[50],
      level2: grey[100],
      contrastColors: {
        default: grey[300],
        paper: colorPalette.white,
        level1: grey[300],
        level2: grey[400],
      },
    },
    text: {
      dark: grey[800],
      primary: grey[600],
      secondary: grey[400],
      placeholder: grey[400],
      disabled: grey[400],
    },
    divider: grey[200],
    action: {
      hover: grey[100],
      disabledOpacity: 1,
      disabledBackground: grey[200],
    },
  },
});

export default theme;
