import { useEffect } from 'react';

import i18n, { i18nNS } from '../../../i18n';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';

const usePaymentSuccess = () => {
  const { activeStep, moveToStep, closeDrawer, setTitle, setMobileTitle, isCreatingProCourse } =
    useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.PAYMENT_SUCCESS;

  const handleCreateCourse = () => {
    moveToStep(CreateCourseStep.BASIC_DETAILS, false);
  };

  useEffect(() => {
    if (!isVisible) return;
    setTitle(i18n.t('payment_successful', { ns: i18nNS.COURSES }));
    setMobileTitle(i18n.t('payment_successful', { ns: i18nNS.COURSES }));
  }, [isVisible, setMobileTitle, setTitle]);

  return { closeDrawer, isVisible, isCreatingProCourse, handleCreateCourse };
};
export default usePaymentSuccess;
