import { ElementType } from 'react';

import { Button as MuiButton, ButtonBaseProps, ButtonProps as MuiButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateClasses } from '../../utils/helpers';
import { Props as VMProps } from './vm';

export const buttonClasses = {
  ...generateClasses('Button', [
    'root',
    'contained',
    'outlined',
    'text',
    'colorPrimary',
    'colorSuccess',
    'colorWarning',
    'colorError',
    'sizeXLarge',
    'sizeLarge',
    'sizeMedium',
    'sizeSmall',
    'sizeXSmall',
    'startIcon',
    'endIcon',
    'edgeStart',
    'edgeEnd',
    'focusVisible',
    'disabled',
    'loading',
    'spinner',
  ]),
};

export interface ButtonProps<C extends ElementType<any>>
  extends VMProps,
    DistributiveOmit<MuiButtonProps, 'color' | 'onClick'> {
  component?: C;
  /** @defalt 'primary' */
  color?: 'primary' | 'success' | 'error' | 'warning';
  edge?: 'start' | 'end';
  /**
   * hides startIcon and children when
   * either isLoading state is true or promise is being resolved on click
   * @default false
   */
  hideContentWhenLoading?: boolean;
  classes?: Partial<typeof buttonClasses>;
}

export type Props<C extends ElementType<any> = 'button'> = ButtonBaseProps<C, ButtonProps<C>>;

export const ButtonRoot = styled(MuiButton, { shouldForwardProp: (prop) => prop !== 'isLoading' })<
  DistributiveOmit<Props, 'color'> & Required<Pick<Props, 'color'>>
>(({ theme, color }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'unset',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    border: '1px solid transparent',

    [`&.${buttonClasses.disabled}`]: {
      cursor: 'not-allowed',
      [`&.${buttonClasses.loading}`]: {
        cursor: 'progress',
      },
    },

    [`&.${buttonClasses.contained}`]: {
      backgroundColor: theme.palette[color][600],
      color: theme.palette[color].contrastColors[600],
      '&:hover': {
        backgroundColor: theme.palette[color][500],
        color: theme.palette[color].contrastColors[500],
      },
      '&:focus': {
        backgroundColor: theme.palette[color][700],
        color: theme.palette[color].contrastColors[700],
      },
      [`&.${buttonClasses.disabled}`]: {
        [`&:not(.${buttonClasses.loading})`]: {
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey.contrastColors[300],
          opacity: 0.5,
        },
      },
    },

    [`&.${buttonClasses.outlined}`]: {
      backgroundColor: 'transparent',
      color: theme.palette[color][600],
      borderColor: theme.palette[color][600],
      '&:hover': {
        backgroundColor: theme.palette[color][50],
      },
      '&:focus': {
        backgroundColor: theme.palette[color][100],
      },
      [`&.${buttonClasses.disabled}`]: {
        [`&:not(.${buttonClasses.loading})`]: {
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.grey.contrastColors[50],
          borderColor: theme.palette.grey.contrastColors[50],
          opacity: 0.4,
        },
      },
    },

    [`&.${buttonClasses.text}`]: {
      backgroundColor: 'transparent',
      color: theme.palette[color][600],
      '&:hover': {
        backgroundColor: theme.palette[color][50],
      },
      '&:focus': {
        backgroundColor: theme.palette[color][100],
      },
      [`&.${buttonClasses.disabled}`]: {
        [`&:not(.${buttonClasses.loading})`]: {
          backgroundColor: 'transparent',
          color: theme.palette.grey.contrastColors[300],
          opacity: 0.4,
        },
      },
    },

    [`&.${buttonClasses.sizeXLarge}`]: {
      gap: theme.spacing(3),
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.33,
      borderRadius: 10,
      padding: `calc(${theme.spacing(4)} - 1px)`,
      minHeight: 56,
      [`&.${buttonClasses.edgeStart}`]: {
        marginLeft: `calc(-1 * (${theme.spacing(4)} - 1px))`,
      },
      [`&.${buttonClasses.edgeEnd}`]: {
        marginRight: `calc(-1 * (${theme.spacing(4)} - 1px))`,
      },
      [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
        '& > *': {
          fontSize: theme.typography.pxToRem(24),
        },
      },
    },

    [`&.${buttonClasses.sizeLarge}`]: {
      gap: theme.spacing(2),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      borderRadius: 8,
      padding: `calc(${theme.spacing(3)} - 1px)`,
      minHeight: 48,
      [`&.${buttonClasses.edgeStart}`]: {
        marginLeft: `calc(-1 * (${theme.spacing(3)} - 1px))`,
      },
      [`&.${buttonClasses.edgeEnd}`]: {
        marginRight: `calc(-1 * (${theme.spacing(3)} - 1px))`,
      },
      [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
        '& > *': {
          fontSize: theme.typography.pxToRem(24),
        },
      },
    },

    [`&.${buttonClasses.sizeMedium}`]: {
      gap: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.14,
      borderRadius: 6,
      padding: `calc(${theme.spacing(3)} - 1px)`,
      minHeight: 40,
      [`&.${buttonClasses.edgeStart}`]: {
        marginLeft: `calc(-1 * (${theme.spacing(3)} - 1px))`,
      },
      [`&.${buttonClasses.edgeEnd}`]: {
        marginRight: `calc(-1 * (${theme.spacing(3)} - 1px))`,
      },
      [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
        '& > *': {
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },

    [`&.${buttonClasses.sizeSmall}`]: {
      gap: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.33,
      borderRadius: 4,
      padding: `calc(${theme.spacing(2)} - 1px)`,
      minHeight: 32,
      [`&.${buttonClasses.edgeStart}`]: {
        marginLeft: `calc(-1 * (${theme.spacing(2)} - 1px))`,
      },
      [`&.${buttonClasses.edgeEnd}`]: {
        marginRight: `calc(-1 * (${theme.spacing(2)} - 1px))`,
      },
      [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
        '& > *': {
          fontSize: theme.typography.pxToRem(16),
        },
      },
    },

    [`&.${buttonClasses.sizeXSmall}`]: {
      gap: theme.spacing(1),
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 1.2,
      borderRadius: 4,
      padding: `calc(${theme.spacing(2)} - 1px)`,
      minHeight: 28,
      [`&.${buttonClasses.edgeStart}`]: {
        marginLeft: `calc(-1 * (${theme.spacing(2)} - 1px))`,
      },
      [`&.${buttonClasses.edgeEnd}`]: {
        marginRight: `calc(-1 * (${theme.spacing(2)} - 1px))`,
      },
      [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
        '& > *': {
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },

    [`& .${buttonClasses.startIcon}, & .${buttonClasses.endIcon}`]: {
      margin: 0,
    },
  };
});
