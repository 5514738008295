import { useTranslation } from 'react-i18next';

import Switch from '../../components/FormControls/Switch';
import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import BulbIcon from '../../icons/BulbIcon';
import SpinnerIcon from '../../icons/LoaderIcon';
import { ListItem, ListItemIcon, ListItemText } from '../styles';
import useHelpTipsVM from './vm';

const HelpTips = () => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  const { isTogglingHelpTips, areHelpTipsOn, handleToggleHelpTips } = useHelpTipsVM();

  return (
    <ListItem>
      <ListItemIcon>{isTogglingHelpTips ? <SpinnerIcon /> : <BulbIcon />}</ListItemIcon>
      <ListItemText>
        <Typography variant="h6Regular" component="div">
          {t('help_tips', { ns: i18nNS.SETTINGS })}
        </Typography>
      </ListItemText>
      <Switch
        edge="end"
        checked={areHelpTipsOn}
        disabled={isTogglingHelpTips}
        onChange={handleToggleHelpTips}
        aria-label={t(areHelpTipsOn ? 'click_to_turn_off_help_tips' : 'click_to_turn_on_help_tips')}
      />
    </ListItem>
  );
};

export default HelpTips;
