import { TransitionGroup } from 'react-transition-group';

import Drawer from '../../components/Drawer';
import { drawerClasses } from '../../components/Drawer/styles';
import { useCreateCourseContext } from '../Context';
import BasicDetails from './BasicDetails';
import CopyContent from './CopyContent';
import CostCalculator from './CostCalculator';
import EnrollmentMethod from './EnrollmentMethod';
import CreateCourseHeader from './Header';
import InitializeCourse from './InitializeCourse';
import Introduction from './Introduction';
import PaymentDetails from './PaymentDetails';
import PaymentFailed from './PaymentFailed';
import PaymentMethod from './PaymentMethod';
import PaymentSuccess from './PaymentSuccess';
import PricingInquiry from './PricingInquiry';
import PricingInquiryThanks from './PricingInquiryThanks';
import Schedule from './Schedule';
import TeamMembers from './TeamMembers';

const useRootVM = () => {
  const { isOpen, closeDrawer } = useCreateCourseContext();
  return { isOpen, closeDrawer };
};

const Root = () => {
  const { isOpen, closeDrawer } = useRootVM();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      header={(titleId) => <CreateCourseHeader id={titleId} />}
    >
      {({ bodyId }) => (
        <TransitionGroup
          id={bodyId}
          className={drawerClasses.body}
          component="div"
          style={{ position: 'relative' }}
        >
          <Introduction />
          <PaymentMethod />
          <CostCalculator />
          <PaymentDetails />
          <PaymentSuccess />
          <PaymentFailed />
          <PricingInquiry />
          <PricingInquiryThanks />
          <BasicDetails />
          <InitializeCourse />
          <CopyContent />
          <Schedule />
          <TeamMembers />
          <EnrollmentMethod />
        </TransitionGroup>
      )}
    </Drawer>
  );
};

export default Root;
