import { useTranslation } from 'react-i18next';

import Alert from '../../../components/Alert';
import Note from '../../../components/Note';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import InfoIcon from '../../../icons/InfoIcon';
import useChoosePaymentMethodDialog from './vm';

export interface ChoosePaymentMethodDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChoosePaymentMethodDialog = ({ open, onClose }: ChoosePaymentMethodDialogProps) => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { alertMessage, handleClickRedeemCoupon, handleClickPayUsingCard } = useChoosePaymentMethodDialog();

  return (
    <Alert open={open} onClose={onClose}>
      <Alert.Header>{t('chooose_payment_method', { ns: i18nNS.COURSES })}</Alert.Header>
      <Alert.Body>
        {alertMessage && (
          <Note icon={<InfoIcon />} color="info">
            {alertMessage}
          </Note>
        )}
        <Typography variant="paragraphRegular">
          {t(
            'if_you_have_a_course_purchase_coupon_you_can_redeem_it_or_you_can_pay_for_the_course_using_your_credit_debit_card',
            { ns: i18nNS.COURSES }
          )}
        </Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action variant="text" aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}>
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action
          variant="outlined"
          onClick={handleClickPayUsingCard}
          aria-label={t('click_to_pay_using_card', { ns: i18nNS.COURSES })}
        >
          {t('pay_using_card', { ns: i18nNS.COURSES })}
        </Alert.Action>
        <Alert.Action
          onClick={handleClickRedeemCoupon}
          aria-label={t('click_to_redeem_coupon', { ns: i18nNS.COURSES })}
        >
          {t('redeem_coupon', { ns: i18nNS.COURSES })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default ChoosePaymentMethodDialog;
