import {
  Tooltip as MuiTooltip,
  tooltipClasses as MuiTooltipClasses,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateClasses } from '../../utils/helpers';

export const tooltipClasses = {
  ...MuiTooltipClasses,
  ...generateClasses('Tooltip', ['arrowWrapper']),
};

export interface Props extends Omit<MuiTooltipProps, 'arrow' | 'disableInteractive' | 'placement'> {
  /** @default true */
  arrow?: MuiTooltipProps['arrow'];
  /** @default true */
  disableInteractive?: MuiTooltipProps['disableInteractive'];
  /** @default 'top' */
  placement?: MuiTooltipProps['placement'];
}

export const StyledTooltip = styled(({ className, ...props }: Props) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(2, 3),
    backgroundColor: theme.palette.grey[900],
    lineHeight: 1.5,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[900],
    position: 'absolute',
    width: 'unset',
    height: 'unset',
  },
  '&[data-popper-placement="top"]': {
    [`& .${tooltipClasses.arrowWrapper}`]: {
      bottom: 0,
      [`& .${tooltipClasses.arrow}`]: {
        margin: 0,
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 100%) rotate(180deg)',
      },
    },
  },
  '&[data-popper-placement="bottom"]': {
    [`& .${tooltipClasses.arrowWrapper}`]: {
      top: 0,
      [`& .${tooltipClasses.arrow}`]: {
        margin: 0,
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -100%)',
      },
    },
  },
  '&[data-popper-placement="left"]': {
    [`& .${tooltipClasses.arrowWrapper}`]: {
      right: 0,
      [`& .${tooltipClasses.arrow}`]: {
        width: 'unset',
        height: 'unset',
        margin: 0,
        right: 0,
        top: '50%',
        transform: 'translate(70%, -50%) rotate(90deg)',
      },
    },
  },
  '&[data-popper-placement="right"]': {
    [`& .${tooltipClasses.arrowWrapper}`]: {
      left: 0,
      [`& .${tooltipClasses.arrow}`]: {
        width: 'unset',
        height: 'unset',
        margin: 0,
        left: 0,
        top: '50%',
        transform: 'translate(-70%, -50%) rotate(270deg)',
      },
    },
  },
}));
