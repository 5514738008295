import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { Clear as ClearIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Stack } from '@mui/material';

import clsx from 'clsx';
import { Editor as SlateEditor } from 'slate';

import { i18nNS } from '../../../i18n';
import { Editor, EditorProps, ToolbarCustomizationProps } from '../../../rich-text';
import { AttachmentRequestConfig } from '../../../types';
import AttachmentView from '../../AttachmentView';
import Button from '../../Button';
import Typography from '../../Typography';
import AttachButton from '../AttachButton';
import DefaultCreateFormulaOverlay from '../DefaultCreateFormulaOverlay';
import { AttachmentsSection, Loader } from './styles';
import useRichTextEditorVM, { VMProps } from './vm';

export type RichTextUploadAttachmentConfig = AttachmentRequestConfig<{
  activityId?: MongoId;
  activityType: 'assignments' | 'announcements' | 'quizzes' | 'discussions' | 'polls' | 'queries';
}>;

export type Props = Omit<
  EditorProps,
  'i18n' | 'toolbar' | 'renderCreateFormulaOverlay' | 'uploadFormula' | 'uploadImage' | 'classes'
> &
  VMProps & {
    /**
     * Can be used to customize toolbar, set to false to hide the toolbar.
     * @default
     * {
     *   canFormatText: true,
     *   canInsertFormula: true,
     *   canUploadImage: true,
     *   uploadAttachmentConfig: undefined
     * }
     */
    toolbar?:
      | false
      | (ToolbarCustomizationProps & {
          /**
           * Upload attachment config, if not provided then it will hide the attach
           * file button in toolbar
           * @default undefined
           */
          uploadAttachmentConfig?: RichTextUploadAttachmentConfig;
        });
    classes?: EditorProps['classes'] &
      Partial<
        Record<
          | 'attachmentsHeading'
          | 'attachmentsList'
          | 'attachment'
          | 'removeAttachmentButton'
          | 'removeAttachmentIcon',
          string
        >
      >;
  };

const RichTextEditor = (
  { children, initialAttachments, onAttachmentsChange, toolbar, classes, ...props }: Props,
  ref: Ref<SlateEditor>
) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  const {
    attachments,
    isUploadingImage,
    onAttachmentDelete: handleAttachmentDelete,
    onAttachmentUpload: handleAttachmentUpload,
    uploadFormula,
    uploadImage,
  } = useRichTextEditorVM({ initialAttachments, onAttachmentsChange });

  return (
    <Editor
      {...props}
      ref={ref}
      i18n={{
        // eslint-disable-next-line camelcase
        as_you_type_the_expression_in_the_input_box_below_the_formatted_output_will_appear_here: t(
          'as_you_type_the_expression_in_the_input_box_below_the_formatted_output_will_appear_here',
          { ns: i18nNS.COMMON }
        ),
        // eslint-disable-next-line camelcase
        enter_formula_here: t('enter_formula_here', { ns: i18nNS.COMMON }),
        // eslint-disable-next-line camelcase
        format_and_display: t('format_and_display', { ns: i18nNS.COMMON }),
        // eslint-disable-next-line camelcase
        input_format: t('input_format', { ns: i18nNS.COMMON }),
        // eslint-disable-next-line camelcase
        output_display_style: t('output_display_style', { ns: i18nNS.COMMON }),
        cancel: t('cancel', { ns: i18nNS.GLOSSARY }),
        done: t('done', { ns: i18nNS.GLOSSARY }),
        input: t('input', { ns: i18nNS.GLOSSARY }),
      }}
      uploadFormula={uploadFormula}
      uploadImage={uploadImage}
      toolbar={
        toolbar !== false && {
          canFormatText: true,
          canInsertFormula: true,
          canUploadImage: true,
          ...toolbar,
          children: toolbar?.uploadAttachmentConfig && (
            <AttachButton requestConfig={toolbar.uploadAttachmentConfig} onUpload={handleAttachmentUpload} />
          ),
        }
      }
      renderCreateFormulaOverlay={DefaultCreateFormulaOverlay}
      classes={classes}
    >
      {attachments.length > 0 && (
        <AttachmentsSection>
          <Typography variant="h6Bold" component="div" className={clsx(classes?.attachmentsHeading)}>
            {t('attachments', { ns: i18nNS.GLOSSARY })}
          </Typography>
          <Stack gap={1} className={clsx(classes?.attachmentsList)}>
            {attachments.map((attachment, index) => (
              <AttachmentView
                key={`${attachment.name}-${index}`}
                color="success"
                attachment={attachment}
                rightAction={
                  <Button
                    variant="text"
                    color="error"
                    edge="end"
                    onClick={handleAttachmentDelete(attachment)}
                    className={classes?.removeAttachmentButton}
                    aria-label={t('click_to_delete_attachment', { ns: i18nNS.COMMON })}
                  >
                    <ClearIcon className={classes?.removeAttachmentIcon} />
                  </Button>
                }
                className={classes?.attachment}
              />
            ))}
          </Stack>
        </AttachmentsSection>
      )}
      <Dialog open={isUploadingImage}>
        <DialogContent>
          <Stack gap={2}>
            <Typography variant="h6Bold" component="div">
              {t('uploading', { ns: i18nNS.GLOSSARY })}
            </Typography>
            <Loader />
          </Stack>
        </DialogContent>
      </Dialog>
      {children}
    </Editor>
  );
};

export default forwardRef(RichTextEditor);
