import { ElementType, FormEventHandler, ReactNode } from 'react';

import { Divider, Fade } from '@mui/material';

import clsx from 'clsx';

import { Footer, Form, FormContent } from './styles';

interface Props {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  footer: (() => ReactNode) | ReactNode;
  id?: string;
  isVisible: boolean;
  onSubmit?: FormEventHandler<HTMLFormElement>;
}

const CoursePaymentLayout = ({ as, children, className, footer, id, isVisible, onSubmit }: Props) => {
  return (
    <Fade in={isVisible} unmountOnExit>
      <Form as={as} id={id} className={clsx(className)} onSubmit={onSubmit}>
        <FormContent>{children}</FormContent>
        <Divider />
        {typeof footer === 'function' ? footer() : <Footer>{footer}</Footer>}
      </Form>
    </Fade>
  );
};

export default CoursePaymentLayout;
