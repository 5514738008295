import { CSSProperties } from 'react';

import { RenderElementProps } from 'slate-react';

import { ParagraphElement as ParagraphElementType } from './types';

interface Props extends RenderElementProps {
  element: ParagraphElementType;
  style?: CSSProperties;
}

const ParagraphElement = ({ attributes, children, style }: Props) => {
  return (
    <div {...attributes} style={style}>
      {children}
    </div>
  );
};

export default ParagraphElement;
