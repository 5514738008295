import Joi from 'joi';

import { attachments, optionalString, requiredString } from '../../../../utils/validators';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  message: Joi.string().when('image', {
    is: null,
    then: Joi.when('attachments', {
      is: Joi.array().min(1),
      then: optionalString,
      otherwise: requiredString,
    }),
    otherwise: optionalString,
  }),
  image: Joi.string().optional().allow(null),
  attachments,
});
