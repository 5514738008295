import { styled } from '@mui/material';

import clsx from 'clsx';

import AcadlyButton, { Props as AcadlyButtonProps } from '../../../components/Button';
import { generateClasses } from '../../../utils/helpers';

const navigationClasses = generateClasses('Navigation', ['button']);

export const Button = styled((props: AcadlyButtonProps) => (
  <AcadlyButton {...props} className={clsx(navigationClasses.button, props.className)} />
))(({ theme }) => ({
  [`&.${navigationClasses.button}`]: {
    color: theme.palette.background.paper,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.background.paper,
      backgroundColor: 'transparent',
    },
    '&:focus': {
      color: theme.palette.background.paper,
      backgroundColor: 'transparent',
    },
  },
}));
