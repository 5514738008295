import { createReducer } from '@reduxjs/toolkit';

import Logger from '../../utils/logger';
import { commentCreated, createComment, fetchComments, markCommentsAsSeen } from '../comments/actions';
import { CommentContextData } from '../comments/types';
import { closeCourse, fetchCourseDetails, fetchSuggestedActivityData, openCourse } from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import {
  AssignmentSubContext,
  CommentContext,
  CourseRole,
  SubmissionStatus,
  SuggestedActivityType,
  TimelineItemType,
} from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  addAssignmentQuestion,
  assignmentRetracted,
  assignmentSubmitted,
  createAssignment,
  deleteAssignment,
  deleteAssignmentQuestion,
  editAssignmentQuestion,
  editPublishedAssignment,
  editUnpublishedAssignment,
  fetchAssignmentAnalyticsForCourseTeam,
  fetchAssignmentData,
  fetchIndividualAssignmentSubmission,
  publishAssignment,
  retractAssignment,
  saveFileSubmission,
  saveURLSubmission,
  submitAssignment,
  submitAssignmentGrades,
} from './actions';
import { incrementCommentsCount } from './case-reducers';
import { toAssignmentGradeData, toAssignmentQuestion, toQuestionSubmission } from './helpers';
import { Assignment, AssignmentsState, FileSubmission, QuestionSubmissionType, URLSubmission } from './types';

const log = Logger.create('db/assignments');

const initialState: AssignmentsState = {
  courseId: '',
  byId: {},
  suggested: null,
};

const baseAssignmentsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, () => initialState);

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const { courseId, courseData = [], currentUser, userData } = action.payload;

    state.courseId = courseId;

    const { userId, name, avatar, role } = currentUser;

    for (const item of courseData) {
      if (item.nodeType !== TimelineItemType.ASSIGNMENT) continue;

      const assignmentUserData = userData?.assignments?.[item._id];

      const comments: Assignment['comments'] = {
        isSubscribed: Boolean(assignmentUserData?.subscribed),
        total: item.activities.numCommentsTotal,
        seen: assignmentUserData?.numCommentsSeen ?? 0,
        preSub: {
          total: item.activities.numCommentsPreSub,
          seen: assignmentUserData?.numCommentsSeenPreSub ?? 0,
        },
        postSub: {
          total: item.activities.numCommentsPostSub,
          seen: assignmentUserData?.numCommentsSeenPostSub ?? 0,
        },
      };

      const preferences: Assignment['preferences'] = {
        allowLate: Boolean(item.details.allowLate),
        dueDateTime: item.details.dueDateTime,
        maxMarks: item.details.maxMarks,
      };

      const studentDataById: Assignment['studentDataById'] = {};

      if (role === CourseRole.STUDENT) {
        studentDataById[userId] = {
          name,
          avatar,
          userId,
          gradeData: toAssignmentGradeData(assignmentUserData?.graded),
          isRetracted: Boolean(assignmentUserData?.retracted),
          status: assignmentUserData?.status ?? SubmissionStatus.NOT_ATTEMPTED,
          submissionsByQuestionId: null,
          submittedOn: assignmentUserData?.submittedOn ?? -1,
        };
      }

      const assignment: Assignment = {
        id: item._id,
        courseId,
        sequenceNum: item.details.num,
        title: item.details.title ?? null,
        instructions: item.details.instructions,
        attachments: item.details.attachments,
        preferences,
        questions: null,
        questionsById: {},
        studentDataById,
        comments,
        gradedSubmissions: item.stats?.numGraded ?? 0,
        totalSubmissions: item.stats?.numSubmitted ?? 0,
        firstAccessedOn: assignmentUserData?.firstAccessedOn || -1,
        lastAccessedOn: assignmentUserData?.lastAccessedOn || -1,
        editedBy: item.details.lastEditedBy ?? null,
        editedOn: item.details.lastEditedOn || -1,
        createdBy: item.details.createdBy,
        createdOn: item.details.createdOn,
        publishedOn: item.details.publishedOn,
      };

      state.byId[assignment.id] = assignment;
    }
  });

  builder.addCase(createAssignment.success, (state, action) => {
    const { assignment, suggested } = action.payload;
    if (assignment) {
      state.byId[assignment.id] = assignment;
      return;
    }

    state.suggested = {
      byId: {},
      order: [],
    };

    for (const item of suggested) {
      const id = item._id;
      state.suggested.order.push(id);
      state.suggested.byId[id] = {
        id,
        courseId: item.identifiers.courseId,
        courseCode: item.identifiers.courseCode,
        courseTitle: item.identifiers.courseTitle,
        title: item.details.title,
        instructions: item.details.instructions,
        maxMarks: item.details.maxMarks,
        attachments: item.details.attachments,
        sequenceNum: item.details.num,
        questions: null,
        questionsById: {},
        isHidden: Boolean(item.hidden),
        isUsed: Boolean(item.used),
        createdBy: item.details.createdBy,
        createdOn: item.details.createdOn,
        publishedOn: item.details.publishedOn,
        publishedOnDate: item.details.publishedOnDate,
      };
    }
  });

  builder.addCase(editUnpublishedAssignment.success, (state, action) => {
    const { assignmentId, attachments, instructions, title } = action.payload;
    const assignment = state.byId[assignmentId];

    if (!assignment) return;

    assignment.title = title;
    assignment.instructions = instructions;
    assignment.attachments = attachments;
  });

  builder.addCase(editPublishedAssignment.success, (state, action) => {
    const { assignmentId, attachments, dueDateTime, instructions, questions, title } = action.payload;
    const assignment = state.byId[assignmentId];

    if (!assignment) return;

    assignment.title = title;
    assignment.instructions = instructions;
    assignment.attachments = attachments;
    assignment.preferences.dueDateTime = dueDateTime;

    if (!questions) return;

    assignment.questions = [];
    assignment.questionsById = {};

    for (const question of questions) {
      assignment.questions.push(question.id);
      assignment.questionsById[question.id] = question;
    }
  });

  builder.addCase(publishAssignment.success, (state, action) => {
    const { assignmentId, allowLate, dueDateTime, num, publishedOn, subscribeToComments } = action.payload;
    const assignment = state.byId[assignmentId];

    if (!assignment) return;

    assignment.preferences.allowLate = allowLate;
    assignment.preferences.dueDateTime = dueDateTime;
    assignment.sequenceNum = num;
    assignment.comments.isSubscribed = subscribeToComments;
    assignment.publishedOn = publishedOn;
  });

  builder.addCase(deleteAssignment.success, (state, action) => {
    const { assignment } = action.payload;
    state.byId[assignment.id] = undefined;
  });

  builder.addCase(fetchAssignmentData.success, (state, action) => {
    const {
      assignmentId,
      fetchedOn,
      isFirstAccess,
      questions,
      courseRole,
      currentUserId: studentId,
      submission = {},
    } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    assignment.lastAccessedOn = fetchedOn;

    if (isFirstAccess) {
      assignment.firstAccessedOn = fetchedOn;
    }

    assignment.questions = [];

    for (const question of questions) {
      assignment.questions.push(question._id);
      assignment.questionsById[question._id] = toAssignmentQuestion(question);
    }

    if (courseRole !== CourseRole.STUDENT) return;

    const studentData = assignment.studentDataById[studentId];

    if (!studentData) {
      log.warn(`student-data for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    if (isFirstAccess) {
      studentData.status = SubmissionStatus.IN_PROGRESS;
    }

    if (!studentData.submissionsByQuestionId) studentData.submissionsByQuestionId = {};

    for (const [questionId, s] of Object.entries(submission)) {
      studentData.submissionsByQuestionId[questionId] = toQuestionSubmission(s);
    }
  });

  builder.addCase(fetchSuggestedActivityData.success, (state, action) => {
    const data = action.payload;

    if (data.nodeType !== SuggestedActivityType.ASSIGNMENT) return;

    const { _id, questions } = data;

    const assignment = state.suggested?.byId[_id];

    if (!assignment) return;

    assignment.questions = [];
    assignment.questionsById = {};

    for (const question of questions) {
      assignment.questions.push(question._id);
      assignment.questionsById[question._id] = {
        id: question._id,
        submissionType: question.details.submission,
        maxMarks: question.details.marks,
        extensions: question.details.submitExt,
        description: question.details.description.text,
        attachments: question.details.attachments,
        createdBy: question.details.createdBy,
        createdOn: question.details.createdOn,
      };
    }
  });

  builder.addCase(addAssignmentQuestion.success, (state, action) => {
    const { assignmentId, ...question } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    if (!assignment.questions) assignment.questions = [];
    assignment.questions.push(question.id);
    assignment.questionsById[question.id] = question;
  });

  builder.addCase(editAssignmentQuestion.success, (state, action) => {
    const { assignmentId, id, ...editedQuestion } = action.payload;

    const question = state.byId[assignmentId]?.questionsById[id];
    if (!question) return;

    question.submissionType = editedQuestion.submissionType;
    question.attachments = editedQuestion.attachments;
    question.description = editedQuestion.description;
    question.extensions = editedQuestion.extensions;
    question.maxMarks = editedQuestion.maxMarks;
  });

  builder.addCase(deleteAssignmentQuestion.success, (state, action) => {
    const { assignmentId, questionId } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment?.questions) return;

    assignment.questions = assignment.questions.filter((id) => id !== questionId);
    assignment.questionsById[questionId] = undefined;
  });

  builder.addCase(saveFileSubmission.success, (state, action) => {
    const { assignmentId, studentId, questionId, uploadedOn, prevSubmission, ...file } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const question = assignment.questionsById[questionId];
    const studentData = assignment.studentDataById[studentId];

    if (!question || !studentData) return;
    if (!studentData.submissionsByQuestionId) studentData.submissionsByQuestionId = {};

    const fileSubmission = studentData.submissionsByQuestionId[questionId] as FileSubmission | undefined;
    const prevSubmissions = fileSubmission?.prevSubmissions || [];

    studentData.submissionsByQuestionId[questionId] = {
      submissionType: QuestionSubmissionType.FILE,
      file: {
        extension: file.fileType,
        name: file.generatedFileName,
        originalName: file.originalFileName,
        uploadedOn: uploadedOn,
      },
      marks: 0,
      maxMarks: question.maxMarks,
      remarks: null,
      prevSubmissions: prevSubmission ? prevSubmissions.concat(prevSubmission) : [],
    };
  });

  builder.addCase(saveURLSubmission.success, (state, action) => {
    const { assignmentId, studentId, questionId, url, uploadedOn, prevSubmission } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const question = assignment.questionsById[questionId];
    const studentData = assignment.studentDataById[studentId];

    if (!question || !studentData) return;
    if (!studentData.submissionsByQuestionId) studentData.submissionsByQuestionId = {};

    const urlSubmission = studentData.submissionsByQuestionId[questionId] as URLSubmission | undefined;
    const prevSubmissions = urlSubmission?.prevSubmissions || [];

    studentData.submissionsByQuestionId[questionId] = {
      submissionType: QuestionSubmissionType.URL,
      url,
      marks: 0,
      maxMarks: question.maxMarks,
      remarks: null,
      savedOn: uploadedOn,
      prevSubmissions: prevSubmission ? prevSubmissions.concat(prevSubmission) : [],
    };
  });

  builder.addCase(submitAssignment.success, (state, action) => {
    const { assignmentId, studentId, status, submittedOn } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const studentData = assignment.studentDataById[studentId];
    if (!studentData) {
      log.warn(`student-data for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    studentData.status = status;
    studentData.submittedOn = submittedOn;
  });

  builder.addCase(assignmentSubmitted, (state, action) => {
    const { assignmentId } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    assignment.totalSubmissions++;
  });

  builder.addCase(retractAssignment.success, (state, action) => {
    const { assignmentId, studentId } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const studentData = assignment.studentDataById[studentId];
    if (!studentData) {
      log.warn(`student-data for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    studentData.isRetracted = true;
    studentData.status = SubmissionStatus.IN_PROGRESS;
  });

  builder.addCase(assignmentRetracted, (state, action) => {
    const { assignmentId } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    assignment.totalSubmissions--;
  });

  builder.addCase(fetchAssignmentAnalyticsForCourseTeam.success, (state, action) => {
    const { assignmentId, submittedBy } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    for (const submission of submittedBy) {
      const { userId, name, avatar } = submission.identifiers;

      assignment.studentDataById[userId] = {
        userId,
        name,
        avatar,
        gradeData: toAssignmentGradeData(submission.status.graded),
        isRetracted: Boolean(submission.status.retracted),
        status: submission.status.status,
        submissionsByQuestionId: null,
        submittedOn: submission.status.submittedOn,
      };
    }
  });

  builder.addCase(fetchIndividualAssignmentSubmission.success, (state, action) => {
    const {
      assignmentId,
      studentId,
      submission: { submission },
    } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const studentData = assignment.studentDataById[studentId];
    if (!studentData) {
      log.warn(`student-data for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    if (studentData.submissionsByQuestionId == null) {
      studentData.submissionsByQuestionId = {};
    }

    for (const [questionId, s] of Object.entries(submission)) {
      studentData.submissionsByQuestionId[questionId] = toQuestionSubmission(s);
    }
  });

  builder.addCase(submitAssignmentGrades.success, (state, action) => {
    const { assignmentId, studentId, grades, gradedBy, gradedOn } = action.payload;

    const assignment = state.byId[assignmentId];
    if (!assignment) return;

    const studentData = assignment.studentDataById[studentId];
    if (!studentData) {
      log.warn(`student-data for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    if (!studentData.submissionsByQuestionId) {
      log.warn(`question submissions for student(${studentId}) in assignment(${assignment.id}) not found!`);
      return;
    }

    let totalScore = 0;

    for (const grade of grades) {
      const submission = studentData.submissionsByQuestionId[grade.questionId];
      if (!submission) continue;

      totalScore += grade.marks;

      submission.marks = grade.marks;
      submission.maxMarks = grade.maxMarks;
      submission.remarks = {
        content: grade.comments.content,
        author: gradedBy,
      };
    }

    studentData.gradeData = {
      gradedBy,
      gradedOn,
      score: totalScore,
    };

    assignment.gradedSubmissions++;
  });

  builder.addCase(fetchComments.success, (state, action) => {
    const { context, totalCommentsSeen } = action.payload;

    const entity = state.byId[context.id];
    if (!entity) return;

    if (context.type !== CommentContext.ASSIGNMENT) return;

    if (context.subType === AssignmentSubContext.PRE_SUBMISSION) {
      const seenPreSubmissionComments = totalCommentsSeen ?? entity.comments.preSub.total;
      entity.comments.preSub.seen = seenPreSubmissionComments;
      entity.comments.seen = seenPreSubmissionComments + entity.comments.postSub.seen;
    } else {
      const seenPostSubmissionComments = totalCommentsSeen ?? entity.comments.postSub.total;
      entity.comments.postSub.seen = seenPostSubmissionComments;
      entity.comments.seen = entity.comments.preSub.seen + seenPostSubmissionComments;
    }
  });

  builder.addCase(createComment.success, (state, action) => {
    const { context } = action.payload;
    incrementCommentsCount({
      assignmentsById: state.byId,
      context,
      isSeen: true,
    });
  });

  builder.addCase(commentCreated, (state, action) => {
    const { context: type, subContext: subType, contextId: id, currentUser, sender } = action.payload;

    incrementCommentsCount({
      assignmentsById: state.byId,
      context: { id, type, subType, classId: null } as CommentContextData,
      isSeen: currentUser.userId === sender.userId,
    });
  });

  builder.addCase(markCommentsAsSeen.success, (state, action) => {
    const { context, seen } = action.payload;
    if (context.type !== CommentContext.ASSIGNMENT) return;

    const assignment = state.byId[context.id];
    if (!assignment) return;

    assignment.comments[context.subType].seen = Math.max(
      assignment.comments[context.subType].total,
      assignment.comments[context.subType].seen + seen
    );
  });
});

const assignmentsReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.ASSIGNMENT }),
  baseAssignmentsReducer
);

export default assignmentsReducer;
