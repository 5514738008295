import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { endZoomMeetingBroadcast, startRemoteAttendance } from '../../../db/online-meeting/actions';
import { remoteAttendanceStopppedEvent } from '../../../db/online-meeting/pusher-events';
import {
  selectOnlineMeeting,
  selectParticipantBeingAddressed,
  selectParticipantsWithRaisedHand,
} from '../../../db/online-meeting/selectors';
import { usePusherEvent } from '../../../pusher/bus';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useZoomMeetingContext } from '../../ZoomMeetingContext/hooks';

export default function useBroadcastStartedFooterViewVM() {
  const requestDispatch = useRequestDispatch();

  const {
    state: { classId },
  } = useZoomMeetingContext();

  const [attendanceId, setAttendanceId] = useState('');

  const [isStudentWithHandRaisedDialogOpen, setIsStudentWithHandRaisedDialogOpen] = useState(false);
  const onlineDetails = useSelector(selectOnlineMeeting(classId));
  const participantsWithHandRaised = useSelector(selectParticipantsWithRaisedHand(classId));

  const isRecordingAttendance = Boolean(attendanceId);
  const numParticipantsWithHandRaised = participantsWithHandRaised.length;
  const participantBeingAddressed = useSelector(selectParticipantBeingAddressed(classId));
  const isParticipantBeingAddressed = Boolean(participantBeingAddressed);

  const clearAttendanceId = useCallback(() => {
    setAttendanceId('');
  }, []);

  usePusherEvent(
    remoteAttendanceStopppedEvent,
    useCallback(
      function handleRemoteAttendanceStopped(event) {
        if (event.payload.attendanceId !== attendanceId) return;
        clearAttendanceId();
      },
      [attendanceId, clearAttendanceId]
    )
  );

  const handleStartRecordingAttendance = async () => {
    if (!classId || !onlineDetails?.meetingId) return;

    const { attendanceId } = await requestDispatch(startRemoteAttendance, {
      classId,
      meetingId: onlineDetails.meetingId,
    });

    setAttendanceId(attendanceId);
  };

  const handleStopBroadcasting = async () => {
    if (!classId || !onlineDetails?.meetingId) return;
    await requestDispatch(endZoomMeetingBroadcast, { classId, meetingId: onlineDetails.meetingId });
  };

  const handleOpenStudentWithHandRaisedDialog = () => {
    setIsStudentWithHandRaisedDialogOpen(true);
  };

  const handleCloseStudentWithHandRaisedDialog = () => {
    setIsStudentWithHandRaisedDialogOpen(false);
  };

  return {
    attendanceId,
    handleCloseStudentWithHandRaisedDialog,
    handleOpenStudentWithHandRaisedDialog,
    handleStartRecordingAttendance,
    handleStopBroadcasting,
    handleStopRecordingAttendance: clearAttendanceId,
    numParticipantsWithHandRaised,
    isParticipantBeingAddressed,
    isRecordingAttendance,
    isStudentWithHandRaisedDialogOpen,
  };
}
