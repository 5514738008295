import './styles/animations.css';

import { FC, StrictMode } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Store } from 'redux';

import { i18nNS } from './i18n';
import appStore from './store';
import { RootState } from './store/types';
import BrowserHistory from './utils/history';

interface Props {
  store?: Store<RootState>;
}

const GlobalProviders: FC<Props> = ({ children, store = appStore }) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  return (
    <StrictMode>
      <Provider store={store}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={{
            okButtonLabel: t('okay', { ns: i18nNS.GLOSSARY }),
            cancelButtonLabel: t('cancel', { ns: i18nNS.GLOSSARY }),
          }}
        >
          <Router history={BrowserHistory}>
            <>{children}</>
          </Router>
        </LocalizationProvider>
      </Provider>
    </StrictMode>
  );
};

export default GlobalProviders;
