import urls from '../../app/urls';
import { format, unix } from '../../utils/datetime';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { AttachmentType, CommentContext, RateCommentAction, ToBeDone } from '../shared/types';
import {
  CommentContextData,
  CommentId,
  CreateCommentPayload,
  ExportAllCommentStatsPayload,
  FetchCommentsPayload,
  MarkCommentPayload,
  MarkCommentsAsSeenSuccessPayload,
  RateCommentPayload,
  SubscribeToCommentsPayload,
  WithCommentContextData,
} from './types';

export async function getComments({ lastCommentId, context }: FetchCommentsPayload) {
  const params: API.GetCommentsRequest<CommentContext> = {
    context: context.type,
    subContext: context.subType,
    contextId: context.id,
    lid: lastCommentId,
  };
  const { data } = await request.get<API.GetCommentsResponse>(urls.getComments, { params });
  return data;
}

export async function createComment({
  context,
  files,
  image,
  message,
}: WithCommentContextData<CreateCommentPayload>) {
  const attachmentType = image
    ? AttachmentType.IMAGE
    : files.length > 0
    ? AttachmentType.FILE
    : AttachmentType.NONE;

  if (attachmentType !== AttachmentType.FILE) {
    files = [{ name: '', originalName: '', extension: '' }];
  }

  const payload: API.CreateCommentRequest<CommentContext> = {
    context: context.type,
    subContext: context.subType,
    contextId: context.id,
    classId: context.classId,
    message,
    attachmentName: '',
    attachments: files,
    attachmentType,
    hasAttachment: attachmentType !== AttachmentType.NONE ? 1 : 0,
    imageUrl: image || '',
    localTime: format(unix(), "yyyyMMdd'T'HH:mm"),
  };

  const { data } = await request.post<API.CreateCommentResponse>(urls.addComment, payload);
  return data;
}

export async function markCommentsAsSeen({ context, seen }: MarkCommentsAsSeenSuccessPayload) {
  let payload: API.MarkCommentsAsSeenRequest;

  if (context.type === CommentContext.ASSIGNMENT) {
    payload = {
      contextId: context.id,
      context: context.type,
      subContext: context.subType,
      numCommentsSeen: seen,
    };
  } else {
    payload = {
      contextId: context.id,
      context: context.type,
      subContext: ToBeDone.PRE_CLASS,
      numCommentsSeen: seen,
    };
  }

  await request.post(urls.seenComment, payload);
}

export async function getCommentReactions({ commentId }: CommentId) {
  const { data } = await request.get<API.GetCommentReactionsResponse>(urls.getCommentLikes(commentId));
  return data;
}

export async function deleteComment(params: CommentId) {
  await request.delete(urls.removeComment, { params });
}

export async function markComment(params: MarkCommentPayload, context: CommentContextData) {
  const payload: API.MarkCommentRequest<CommentContext> = {
    commentId: params.commentId,
    context: context.type,
    subContext: context.subType,
    contextId: context.id,
    marked: params.mark,
  };
  await request.post(urls.markComment, payload);
}

export async function rateComment({ commentId, points }: RateCommentPayload) {
  let payload: API.RateCommentRequest;

  if (!points) {
    payload = {
      commentId,
      action: RateCommentAction.RETRACTING,
      points: 0,
    };
  } else {
    payload = {
      points,
      commentId,
      action: RateCommentAction.AWARDING,
    };
  }

  await request.post(urls.rateComment, payload);
}

export async function exportAllCommentsStats({ shouldEmail }: ExportAllCommentStatsPayload) {
  const params: API.ExportAllCommentStatsRequest = { email: shouldEmail ? 1 : 0 };
  const { data } = await request.get<API.ExportAllCommentStatsResponse>(urls.exportAllCommentsStats, {
    params,
  });
  return data;
}

export async function subscribeToComments({
  context,
  subscribe,
}: WithCommentContextData<SubscribeToCommentsPayload>) {
  const payload: API.SubscribeToCommentsRequest = {
    context: context.type,
    contextId: context.id,
    classId: context.id,
    subscribeToComments: subscribe ? 1 : 0,
  };
  await request.post(urls.commentSubscription, payload);
}
