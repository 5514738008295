import rootAuthSaga from '../auth/store/sagas';
import rootDbSaga from '../db/sagas';
import rootPusherSaga from '../pusher/sagas';

/**
 * statically imported sagas
 */
const rootSagas = [rootDbSaga, rootPusherSaga, rootAuthSaga];

export default rootSagas;
