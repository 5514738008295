import { RefObject, useCallback, useMemo, useRef } from 'react';

import { Editor } from 'slate';

import { ALLOWED_EXTENSIONS, insertPreviewImages } from './helpers';

export function useImagesSelector(editorRef: RefObject<Editor | null>) {
  const selectImageRef = useRef<HTMLInputElement>(null);

  const allowedImages = useMemo(() => {
    return ALLOWED_EXTENSIONS.map((ext) => `image/${ext}`).join();
  }, []);

  const onImageSelect = useCallback(
    async (callback?: () => any) => {
      if (!selectImageRef.current?.files?.length) return;
      if (!editorRef.current) return;
      editorRef.current.restoreSelection();
      await callback?.();
      insertPreviewImages(editorRef.current, selectImageRef.current.files);
      selectImageRef.current.value = ''; // reset it
    },
    [editorRef]
  );

  return { allowedImages, onImageSelect, selectImageRef };
}
