import { Link } from 'react-router-dom';

import { styled } from '@mui/material';

import CloseIcon from '../../icons/CloseIcon';
import { generateClasses } from '../../utils/helpers';
import Button, { Props as ButtonProps } from '../Button';

type Props = ButtonProps & {
  to: string;
};

const closeButtonClasses = {
  ...generateClasses('CloseButton', ['root']),
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${closeButtonClasses.root}`]: {
    color: theme.palette.primary[500],
  },
}));

const CloseButton = ({ to, children, ...props }: Props) => {
  return (
    <StyledButton
      variant="text"
      size="small"
      className={closeButtonClasses.root}
      {...props}
      component={Link}
      to={to}
    >
      {children || <CloseIcon variant="filled" aria-hidden />}
    </StyledButton>
  );
};

export default CloseButton;
