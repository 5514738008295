import { forwardRef, ReactNode, Ref } from 'react';

import { ListProps as MuiListProps } from '@mui/material';

import { Root } from '../styles';
import useAccessibleList, { UseListVmProps } from './vm';

export interface AccessibleListProps extends DistributiveOmit<MuiListProps, 'children'>, UseListVmProps {
  children: (focusIndex: number) => ReactNode;
}

const AccessibleList = forwardRef(
  (
    {
      numItems,
      onEnter,
      getActiveItemId,
      children,
      direction = 'column',
      initialFocusIndex,
      ...props
    }: AccessibleListProps,
    ref: Ref<HTMLUListElement>
  ) => {
    const { listRef, focusIndex, handleFocus, handleBlur, handleKeyDown } = useAccessibleList(
      {
        numItems,
        onEnter,
        getActiveItemId,
        direction,
        initialFocusIndex,
      },
      ref
    );

    return (
      <Root
        ref={listRef}
        role="listbox"
        tabIndex={0}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        aria-activedescendant={getActiveItemId(focusIndex)}
        direction={direction}
        {...props}
      >
        {children(focusIndex)}
      </Root>
    );
  }
);

export default AccessibleList;
