import { Stack, StackProps, styled } from '@mui/material';

export const Root = styled((props: StackProps) => <Stack direction="row" alignItems="center" {...props} />)(
  ({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
    right: theme.spacing(0.5),
    minHeight: theme.spacing(5),
  })
);
