import { Route, Switch } from 'react-router-dom';

import AssignmentAnalyticsHeaderActions from '../../../assignment/AssignmentAnalytics/AssignmentAnalyticsHeaderActions';
import AssignmentHeaderActions from '../../../assignment/AssignmentHeaderActions';
import ClassAnalyticsHeaderActions from '../../../class/ClassAnalytics/ClassAnalyticsHeaderActions';
import ClassHeaderActions from '../../../class/ClassHeaderActions';
import ArchivedHeaderActions from '../../../courses/ArchivedHeaderActions';
import CourseAnalyticsHeaderActions from '../../../courses/CourseAnalytics/CourseAnalyticsHeaderActions';
import CourseHeaderActions from '../../../courses/CourseHeaderActions';
import HomeHeaderActions from '../../../courses/HomeHeaderActions';
import StudentAnalyticsHeaderActions from '../../../courses/StudentAnalytics/StudentAnalyticsHeaderActions';
import DiscussionHeaderActions from '../../../discussion/DiscussionHeaderActions';
import DiscussionWordCloudHeaderActions from '../../../discussion/DiscussionWordCloud/DiscussionWordCloudHeaderActions';
import routes from '../../../pages/routes';
import PollAnalyticsHeaderActions from '../../../poll/PollAnalytics/PollAnalyticsHeaderActions';
import PollHeaderActions from '../../../poll/PollHeaderActions';
import QueryHeaderActions from '../../../query/QueryHeaderActions';
import QuizAnalyticsHeaderActions from '../../../quiz/QuizAnalytics/QuizAnalyticsHeaderActions';
import QuizHeaderActions from '../../../quiz/QuizHeaderActions';
import ReferHeaderActions from '../../../refer/ReferHeaderActions';
import ResourceAnalyticsHeaderActions from '../../../resource/ResourceAnalytics/ResourceAnalyticsHeaderActions';
import ResourceHeaderActions from '../../../resource/ResourceHeaderActions';
import SettingsHeaderActions from '../../../settings/SettingsHeaderActions';
import PaymentWall from '../../PaymentWall';

const Actions = () => {
  return (
    <Switch>
      <Route exact path={routes.home.path}>
        <HomeHeaderActions />
      </Route>
      <Route exact path={routes.archives.path}>
        <ArchivedHeaderActions />
      </Route>
      <Route exact path={routes.settings.path}>
        <SettingsHeaderActions />
      </Route>
      <Route exact path={routes.refer.path}>
        <ReferHeaderActions />
      </Route>
      <Route path={routes.course.path}>
        {/* routes protected by a payment wall */}
        <PaymentWall disableLoader disableFallback>
          <Switch>
            <Route
              exact
              path={[
                routes.timeline.path,
                routes.info.path,
                routes.syllabus.path,
                routes.courseAnnouncements.path,
                routes.courseAssignments.path,
                routes.announcementPage.path,
              ]}
            >
              <CourseHeaderActions />
            </Route>
            <Route exact path={[routes.courseAnalytics.path, routes.courseAverages.path]}>
              <CourseAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.studentAnalytics.path}>
              <StudentAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.assignmentPage.path}>
              <AssignmentHeaderActions />
            </Route>
            <Route exact path={routes.assignmentAnalytics.path}>
              <AssignmentAnalyticsHeaderActions />
            </Route>
            <Route exact path={[routes.activities.path, routes.agenda.path, routes.attendance.path]}>
              <ClassHeaderActions />
            </Route>
            <Route exact path={routes.classAnalytics.path}>
              <ClassAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.quiz.path}>
              <QuizHeaderActions />
            </Route>
            <Route exact path={routes.quizAnalytics.path}>
              <QuizAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.poll.path}>
              <PollHeaderActions />
            </Route>
            <Route exact path={routes.pollAnalytics.path}>
              <PollAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.discussion.path}>
              <DiscussionHeaderActions />
            </Route>
            <Route exact path={routes.discussionWordCloud.path}>
              <DiscussionWordCloudHeaderActions />
            </Route>
            <Route exact path={routes.resource.path}>
              <ResourceHeaderActions />
            </Route>
            <Route exact path={routes.resourceAnalytics.path}>
              <ResourceAnalyticsHeaderActions />
            </Route>
            <Route exact path={routes.query.path}>
              <QueryHeaderActions />
            </Route>
          </Switch>
        </PaymentWall>
      </Route>
    </Switch>
  );
};

export default Actions;
