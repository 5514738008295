import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authorizeZoomApp } from '../../db/online-meeting/actions';
import { selectZoomAuthorizationData } from '../../db/online-meeting/selectors';
import { useRequestDispatch } from '../../utils/request-actions';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useZoomIntegrationDialogVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();

  const zoomAuthorizationData = useSelector(selectZoomAuthorizationData);

  const [isAuthorizing, setIsAuthorizing] = useState(false);

  useEffect(
    function initializeZoomAuthorization() {
      if (!open) return;

      setIsAuthorizing(true);
      requestDispatch(authorizeZoomApp).finally(() => setIsAuthorizing(false));
    },
    [open, requestDispatch]
  );

  return { isAuthorizing, zoomAuthorizationData };
};

export default useZoomIntegrationDialogVM;
