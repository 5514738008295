import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { Comment as CommentType } from '../../../../../../db/comments/types';
import { i18nNS } from '../../../../../../i18n';
import { Comment, commentGroupClasses, CommentSeparator, NewIndicator, Root } from './styles';
import useCommentGroupVM from './vm';

interface Props {
  commentGroup: CommentType[];
}

const CommentGroup = ({ commentGroup }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const { newIndicatorWrapperRef, firstUnseenCommentId, newIndicatorRef } = useCommentGroupVM();

  return (
    <Root>
      {commentGroup.map((comment) => (
        <Fragment key={comment.id}>
          {firstUnseenCommentId === comment.id ? (
            <div ref={newIndicatorWrapperRef}>
              <NewIndicator
                ref={newIndicatorRef}
                color="warning"
                rightLabel={t('new', { ns: i18nNS.GLOSSARY })}
                className={clsx({
                  [commentGroupClasses.newIndicatorOnFirstCommentInGroup]: comment.isFirstCommentInGroup,
                })}
              />
            </div>
          ) : (
            !comment.isFirstCommentInGroup && <CommentSeparator />
          )}
          <Comment commentId={comment.id} showCreator={comment.isFirstCommentInGroup} />
        </Fragment>
      ))}
    </Root>
  );
};

export default CommentGroup;
