import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import { getIsActivityPublished } from '../../utils/activities';
import { closeClass, fetchClassDetails } from '../classes/actions';
import { commentCreated, createComment } from '../comments/actions';
import {
  closeCourse,
  fetchAllSuggestedActivities,
  hideSuggestedActivity,
  openCourse,
  useSuggestedActivity,
} from '../courses/actions';
import createCommentReducer from '../shared/reducers/comments';
import { ActivityType, CommentContext, CourseRole, SuggestedActivityType } from '../shared/types';
import { pipeReducers } from '../shared/utils';
import {
  createDiscussion,
  createWordCloud,
  deleteDiscussion,
  discussionEdited,
  discussionPublished,
  editDiscussion,
  editPublishedDiscussion,
  editWordCloud,
  fetchAllDiscussions,
  fetchDiscussionPublishPrefs,
  fetchWordCloudDetails,
  markDiscussionAsViewed,
  publishDiscussion,
  saveDiscussionPublishPrefs,
  wordCloudAvailable,
} from './actions';
import { updateDiscussion } from './case-reducers/update-discussion';
import {
  createDiscussionFactory,
  createPreferencesFactory,
  suggestedDiscussionFactory,
  wordCloudFactory,
} from './helper';
import { DiscussionsState } from './types';

const initialState: DiscussionsState = {
  courseId: '',
  byId: {},
  suggestedById: {},
};

const baseDiscussionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(closeClass, (state) => {
    state.suggestedById = {}; // clear older suggested activities
  });

  builder.addCase(fetchClassDetails.success, (state, action) => {
    const { classId, currentUser, discussions, userData, suggestedActivities } = action.payload;

    state.byId = {}; // clear older discussions

    for (const discussion of discussions) {
      state.byId[discussion._id] = createDiscussionFactory({
        discussion,
        currentUser,
        publishDefaults: undefined,
        identifiers: { classId, courseId: state.courseId },
        userData: userData.discussions[discussion._id],
      });
    }

    for (const suggestedDiscussion of suggestedActivities) {
      if (suggestedDiscussion.nodeType !== SuggestedActivityType.DISCUSSION) continue;
      state.suggestedById[suggestedDiscussion._id] = suggestedDiscussionFactory({ suggestedDiscussion });
    }
  });

  builder.addCase(fetchAllSuggestedActivities.success, (state, action) => {
    const { activities: suggestedActivities } = action.payload;

    state.suggestedById = {}; // clear older suggested discussions

    for (const suggestedDiscussion of suggestedActivities) {
      if (suggestedDiscussion.nodeType !== SuggestedActivityType.DISCUSSION) continue;
      state.suggestedById[suggestedDiscussion._id] = suggestedDiscussionFactory({ suggestedDiscussion });
    }
  });

  builder.addCase(useSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.DISCUSSION) return;

    const discussion = state.suggestedById[activityId];
    if (!discussion) return;

    discussion.isUsed = true;
    discussion.isHidden = false;
  });

  builder.addCase(hideSuggestedActivity.success, (state, action) => {
    const { activityId, activityType } = action.payload;

    if (activityType !== SuggestedActivityType.DISCUSSION) return;

    const discussion = state.suggestedById[activityId];
    if (!discussion) return;

    discussion.isHidden = true;
  });

  builder.addCase(fetchAllDiscussions.success, (state, action) => {
    const { currentUser, activityData: discussions, userData } = action.payload;

    state.byId = {}; // clear older discussions

    for (const discussion of discussions) {
      state.byId[discussion._id] = createDiscussionFactory({
        currentUser,
        publishDefaults: undefined,
        discussion,
        identifiers: { classId: discussion.identifiers.classId, courseId: state.courseId },
        userData: userData.discussions[discussion._id],
      });
    }
  });

  builder.addCase(createDiscussion.success, (state, action) => {
    const { classId, currentUser, publishDefaults, ...discussion } = action.payload;

    state.byId[discussion._id] = createDiscussionFactory({
      discussion,
      currentUser,
      userData: undefined,
      publishDefaults,
      identifiers: { courseId: state.courseId, classId },
    });
  });

  builder.addCase(deleteDiscussion.success, (state, action) => {
    const { discussion } = action.payload;
    state.byId[discussion.id] = undefined;
  });

  builder.addCase(fetchDiscussionPublishPrefs.success, (state, action) => {
    const { discussionId, publishPref } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    const isDiscussionPublished = getIsActivityPublished(discussion);
    /**
     * Published Poll:
     * publishDefaults are useless
     *
     * Unpublished Poll:
     * publishDefaults = default publish prefs + (overridden by) modified publish prefs
     */
    if (isDiscussionPublished) return;

    discussion.preferences = createPreferencesFactory(publishPref);
  });

  builder.addCase(saveDiscussionPublishPrefs.success, (state, action) => {
    const { discussionId, classId, ...prefs } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    discussion.preferences = prefs;
  });

  builder.addCase(publishDiscussion.success, (state, action) => {
    const { discussionId, preferences, publishedOn, trueNum } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    discussion.sequenceNum = trueNum;
    discussion.publishedOn = publishedOn;

    discussion.preferences = preferences;
    discussion.comments.isSubscribed = preferences.subscribeToComments;
  });

  builder.addCase(discussionPublished, (state, action) => {
    const { classId, discussionId, details, activities, stats, currentUser } = action.payload;

    state.byId[discussionId] = createDiscussionFactory({
      discussion: { _id: discussionId, nodeType: 'discussion', details, activities, stats },
      identifiers: { courseId: state.courseId, classId },
      publishDefaults: undefined,
      currentUser,
      userData: undefined,
    });
  });

  builder.addCase(markDiscussionAsViewed.success, (state, action) => {
    const { discussionId, currentTime, isFirstAccess } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion || !isFirstAccess) return;

    discussion.firstAccessedOn = currentTime;
  });

  builder.addCase(createWordCloud.success, (state, action) => {
    const { discussionId, ...result } = action.payload;

    if (!result.isCreated) return;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    discussion.wordCloud = wordCloudFactory(result);
  });

  builder.addCase(commentCreated, (state, action) => {
    const { context, contextId: discussionId } = action.payload;

    if (context !== CommentContext.DISCUSSION) return;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    discussion.totalSubmissions++;
  });

  builder.addCase(createComment.success, (state, action) => {
    const { context, currentUser } = action.payload;
    if (context.type !== CommentContext.DISCUSSION) return;

    const discussion = state.byId[context.id];
    if (!discussion) return;

    discussion.comments.total++;
    discussion.comments.seen = discussion.comments.total;

    discussion.totalSubmissions++;

    if (currentUser.role === CourseRole.STUDENT) {
      discussion.hasParticipated = true;
    }
  });

  builder.addCase(discussionEdited, (state, action) => {
    const { activityId, activityType, editedOn } = action.payload;

    if (activityType !== ActivityType.DISCUSSION) return;

    const discussion = state.byId[activityId];
    if (!discussion) return;

    const { discussion: data } = action.payload;

    state.byId[activityId] = updateDiscussion({
      discussion,
      title: data.title ?? discussion.title,
      description: data.description,
      editedOn,
      attachments: data.attachments,
    });
  });

  builder.addCase(wordCloudAvailable, (state, action) => {
    const { activityId } = action.payload;

    const discussion = state.byId[activityId];
    if (!discussion) return;

    discussion.wordCloud = {
      isAvailable: true,
      isGenerated: false,
      data: null,
    };
  });

  builder.addMatcher(isAnyOf(editWordCloud.success, fetchWordCloudDetails.success), (state, action) => {
    const { discussionId, ...result } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    discussion.wordCloud = wordCloudFactory(result);
  });

  builder.addMatcher(isAnyOf(editDiscussion.success, editPublishedDiscussion.success), (state, action) => {
    const { discussionId, title, attachments, description, currentUser, editedOn } = action.payload;

    const discussion = state.byId[discussionId];
    if (!discussion) return;

    state.byId[discussionId] = updateDiscussion({
      discussion,
      title,
      description,
      editedOn,
      attachments,
      editedBy: currentUser,
    });
  });
});

const discussionsReducer = pipeReducers(
  createCommentReducer({ initialState, reducerContext: CommentContext.DISCUSSION }),
  baseDiscussionsReducer
);

export default discussionsReducer;
