import {
  Children,
  isValidElement,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useSlate } from 'slate-react';
import { v4 as uuid } from 'uuid';

export interface ToolbarVmProps {
  canFormatText?: boolean;
  canUploadImage?: boolean;
  canInsertFormula?: boolean;
  children?: ReactNode;
}

export default function useToolbarVM({
  canFormatText,
  canInsertFormula,
  canUploadImage,
  children: _children,
}: ToolbarVmProps) {
  const children = Children.toArray(_children);

  const buttonIds = {
    bold: `bold-button-${uuid()}`,
    italic: `italic-button-${uuid()}`,
    underline: `underline-button-${uuid()}`,
    inertFormula: `insert-formula-button-${uuid()}`,
    uploadImage: `upload-image-button-${uuid()}`,
  };

  const buttonGroupIds = {
    formatTextButtonIds: [buttonIds.bold, buttonIds.italic, buttonIds.underline],
    insertFormulaButtonId: [buttonIds.inertFormula],
    uploadImageButtonId: [buttonIds.uploadImage],
    extraToolsButtonId: [] as string[],
  };

  const activedescendantIds = useMemo(() => {
    let ids: string[] = [];
    if (canFormatText) ids = [...ids, ...buttonGroupIds.formatTextButtonIds];
    if (canInsertFormula) ids = [...ids, ...buttonGroupIds.insertFormulaButtonId];
    if (canUploadImage) ids = [...ids, ...buttonGroupIds.uploadImageButtonId];
    if (children.length > 0) {
      children.forEach((child) => {
        if (isValidElement(child)) {
          const id = `button-${uuid()}`;
          ids = [...ids, id];
          buttonGroupIds.extraToolsButtonId.push(id);
        }
      });
    }
    return ids;
  }, [
    buttonGroupIds.extraToolsButtonId,
    buttonGroupIds.formatTextButtonIds,
    buttonGroupIds.insertFormulaButtonId,
    buttonGroupIds.uploadImageButtonId,
    canFormatText,
    canInsertFormula,
    canUploadImage,
    children,
  ]);

  const editor = useSlate();
  const editorRef = useRef(editor);

  const [focusIndex, setFocusIndex] = useState(-1);

  const activeItemId = activedescendantIds[focusIndex];

  const handleFocus = () => {
    setFocusIndex(0);
  };

  const handleBlur = () => {
    setFocusIndex(-1);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
        setFocusIndex((prevIndex) => (prevIndex === 0 ? activedescendantIds.length - 1 : prevIndex - 1));
        break;
      case 'ArrowRight':
        setFocusIndex((prevIndex) => (prevIndex === activedescendantIds.length - 1 ? 0 : prevIndex + 1));
        break;
      case 'Enter':
        const hasMouseDownEvent = [...buttonGroupIds.formatTextButtonIds].includes(activeItemId);

        if (!activeItemId) break;
        const firstChildOfActiveDescendant = document.querySelector(`#${activeItemId}`);

        if (!firstChildOfActiveDescendant) break;

        if (hasMouseDownEvent) {
          firstChildOfActiveDescendant.dispatchEvent(
            new MouseEvent('mousedown', { bubbles: true, cancelable: true })
          );
          return;
        }
        if (!('click' in firstChildOfActiveDescendant)) break;
        if (typeof firstChildOfActiveDescendant.click !== 'function') break;
        firstChildOfActiveDescendant.click();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    editorRef.current = editor;
  }, [editor]);

  return {
    children,
    editorRef,
    buttonIds,
    activeItemId,
    extraToolsButtonId: buttonGroupIds.extraToolsButtonId,
    handleFocus,
    handleBlur,
    handleKeyDown,
  };
}
