import { RootState } from '../../store/types';

export const selectSortByPrefs = (state: RootState) => state.db.app.userPreferences.sortBy;

export const selectTipsPrefs = (state: RootState) => state.db.app.userPreferences.tips;

export const selectAccessibilityPrefs = (state: RootState) => state.db.app.userPreferences.accessibility;

export const selectNotificationPrefs = (state: RootState) => {
  const { notificationPrefs } = state.db.app;
  return notificationPrefs;
};
