import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const WordCloudIcon = ({ variant = 'outlined', ...props }: Props) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 18C3 17.4477 3.44772 17 4 17H20.5C21.0523 17 21.5 17.4477 21.5 18C21.5 18.5523 21.0523 19 20.5 19H4C3.44772 19 3 18.5523 3 18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 15C2 14.4477 2.44772 14 3 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H3C2.44772 16 2 15.5523 2 15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 11.4477 4.44772 11 5 11H8C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13H5C4.44772 13 4 12.5523 4 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 9C7 8.44772 7.44772 8 8 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H8C7.44772 10 7 9.55228 7 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 15C14 14.4477 14.4477 14 15 14H21C21.5523 14 22 14.4477 22 15C22 15.5523 21.5523 16 21 16H15C14.4477 16 14 15.5523 14 15Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12C10 11.4477 10.4477 11 11 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H11C10.4477 13 10 12.5523 10 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9C13 8.44772 13.4477 8 14 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10H14C13.4477 10 13 9.55228 13 9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6C10 5.44772 10.4477 5 11 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H11C10.4477 7 10 6.55228 10 6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 12C15 11.4477 15.4477 11 16 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H16C15.4477 13 15 12.5523 15 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default WordCloudIcon;
