import 'video.js/dist/video-js.css';
import 'videojs-hotkeys';

import { useEffect, useRef } from 'react';

import clsx from 'clsx';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

interface VideoJsPlayerSource {
  src: string;
  type?: string;
}

export enum PlayerType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

const POSTER = 'https://s3.amazonaws.com/static.acad.ly/img/audio-icon.png';

interface VMProps {
  sources: VideoJsPlayerSource[];
  /**
   * ```js
   * autoplay: true
   * ```
   * It will only work when user interaction is there,
   * otherwise it will throw an error
   */
  autoplay?: boolean;
  playerType?: PlayerType;
}

const useVideoJSPlayerVM = ({ sources, autoplay, playerType = PlayerType.VIDEO }: VMProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);

  useEffect(
    function initializeVideoPlayer() {
      // Make sure Video.js player is only initialized once
      if (playerRef.current) return;

      const videoElement = videoRef.current;
      if (!videoElement) return;

      const options: VideoJsPlayerOptions = {
        controls: true,
        responsive: true,
        fluid: true,
        loop: false,
        preload: 'metadata',
        playbackRates: [0.5, 1, 1.5, 2],
        plugins: {
          hotkeys: {
            volumeStep: 0.1,
            seekStep: 5,
            enableModifiersForNumbers: false,
          },
        },
        sources,
        poster: playerType === PlayerType.AUDIO ? POSTER : undefined,
      };

      playerRef.current = videojs(videoElement, options, () => {
        if (!autoplay) return;
        playerRef.current?.play();
      });
    },
    [sources, autoplay, playerType]
  );

  useEffect(
    function updateVideoPlayer() {
      if (!playerRef.current) return;

      // Do not change source if it has not changed
      if (
        JSON.stringify(playerRef.current.currentSources().map((source) => ({ src: source.src }))) ===
        JSON.stringify(sources.map((source) => ({ src: source.src })))
      )
        return;

      playerRef.current.src(sources);

      if (playerType === PlayerType.AUDIO) {
        playerRef.current.poster(POSTER);
      }

      if (autoplay) playerRef.current.play();
    },
    [sources, autoplay, playerType]
  );

  useEffect(function disposeVideoPlayer() {
    return () => {
      if (!playerRef.current) return;
      playerRef.current.dispose();
      playerRef.current = null;
    };
  }, []);

  return {
    videoRef,
  };
};

export interface Props extends VMProps {
  className?: string;
}

const VideoJSPlayer = ({ className, ...vmOptions }: Props) => {
  const { playerType } = vmOptions;
  const { videoRef } = useVideoJSPlayerVM(vmOptions);

  if (playerType === PlayerType.AUDIO) {
    return <audio ref={videoRef} className={clsx('video-js vjs-big-play-centered', className)} />;
  }

  return <video ref={videoRef} className={clsx('video-js vjs-big-play-centered', className)} />;
};

export default VideoJSPlayer;
