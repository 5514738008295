export const CLASS_ABOUT_TO_START_INTERVAL = 15 * 60;

export const ANDROID_APP_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=co.acetone.acadly';
export const ANDROID_APP_DOWNLOAD_IMG_URL =
  'https://s3.amazonaws.com/static.acad.ly/img/en_badge_web_generic-p-500.png';
export const IOS_APP_DOWNLOAD_LINK = 'https://itunes.apple.com/us/app/acadly/id1161073387?mt=8';
export const IOS_APP_DOWNLOAD_IMG_URL = 'https://s3.amazonaws.com/static.acad.ly/img/itunes-link-01.png';

export const ATTENDANCE_TIMEOUT: number = 150; // seconds

export const ATTENDANCE_GIF_0 = 'https://s3.amazonaws.com/static.acad.ly/img/att1.png';
export const ATTENDANCE_GIF_20 = 'https://s3.amazonaws.com/static.acad.ly/img/att2.png';
export const ATTENDANCE_GIF_40 = 'https://s3.amazonaws.com/static.acad.ly/img/att3.png';
export const ATTENDANCE_GIF_80 = 'https://s3.amazonaws.com/static.acad.ly/img/att4.png';

export const ZOOM_ATTENDANCE_HELP_URL = 'https://help.acadly.com/en/articles/4584794-zoom-attendance';
export const ATTENDANCE_NOT_MARKED_URL =
  'https://help.acadly.com/en/articles/1500746-my-attendance-wasn-t-recorded-correctly-how-do-i-correct-this';
