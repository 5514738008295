import { forwardRef, ReactNode, Ref } from 'react';

import { Select as MuiSelect, SelectProps as MuiSelectProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import clsx from 'clsx';

import TextFieldBase from '../TextFieldBase';
import { Placeholder, selectClasses } from './styles';

export type Props = DistributiveOmit<MuiSelectProps, 'classes' | 'required'> & {
  label?: string;
  /**
   * displays Asterisk(*) at the end of the label
   * @default false
   */
  required?: boolean;
  SelectRef?: Ref<HTMLDivElement>;
  /**
   * If helperIcon prop is passed, then it will show up in HelperText section below InputBase regardless of state.
   * Otherwise, in case of error, ErrorIcon will show up. There is no default HelperIcon in any state except error.
   */
  helperIcon?: ReactNode;
  helperText?: string;
  classes?: Partial<Record<'root' | 'placeholder' | 'input', string>>;
};

const Select = (
  { SelectRef, placeholder, label, required, fullWidth, className, classes, ...props }: Props,
  ref: Ref<HTMLDivElement>
) => {
  return (
    <MuiSelect
      {...props}
      ref={SelectRef}
      className={clsx(selectClasses.root, className, classes?.root)}
      displayEmpty
      renderValue={
        props.value === '' || (Array.isArray(props.value) && props.value.length === 0)
          ? () => (
              <Placeholder className={clsx(selectClasses.placeholder, classes?.placeholder)}>
                {placeholder}
              </Placeholder>
            )
          : undefined
      }
      input={
        <TextFieldBase
          label={label}
          required={required}
          fullWidth={fullWidth}
          ref={ref}
          className={clsx(selectClasses.input, classes?.input)}
        />
      }
    />
  );
};

interface SelectHelperIconProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const SelectHelperIcon = ({ Icon }: SelectHelperIconProps) => {
  return <Icon sx={{ fontSize: '1rem' }} aria-hidden />;
};

export default forwardRef(Select);
