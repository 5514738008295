import { Stack } from '@mui/material';

import Awards from './Awards';
import Reactions from './Reactions';

interface Props {
  commentId: MongoId;
  className?: string;
}

const CommentStats = ({ commentId, className }: Props) => {
  return (
    <Stack direction="column" gap={4} width="100%" className={className}>
      <Awards commentId={commentId} />
      <Reactions commentId={commentId} />
    </Stack>
  );
};

export default CommentStats;
