import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../../i18n';
import DownloadIcon from '../../../icons/DownloadIcon';
import Button from '../../Button';
import { FileProps } from '../types';

export interface Props extends FileProps {
  disabled?: boolean;
}

const File = ({ downloadURL, disabled }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON]);

  const handleDownloadClick: MouseEventHandler = (event) => {
    event.stopPropagation();
    if (disabled) event.preventDefault();
  };

  if (!downloadURL) return null;

  return (
    <Button
      variant="text"
      color="success"
      size="small"
      edge="end"
      disabled={disabled}
      component="a"
      href={downloadURL}
      target="_blank"
      onClick={handleDownloadClick}
      aria-label={t('click_to_download_file', { ns: i18nNS.COMMON })}
    >
      <DownloadIcon color="inherit" aria-hidden />
    </Button>
  );
};

export default File;
