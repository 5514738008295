import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import Alert from '../../components/Alert';
import { i18nNS } from '../../i18n';
import useInformationVM from './vm';

interface Props {
  open: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

const InformationAlert = ({ open, onCancel: handleCancel, onContinue: handleContinue }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE]);

  const { universityName, hasEnterpriseAccount, integratedWith } = useInformationVM();

  if (!universityName) return null;
  if (!hasEnterpriseAccount) return null;
  if (!integratedWith) return null;

  return (
    <Alert open={open}>
      <Alert.Header>{t('information', { ns: i18nNS.GLOSSARY })}</Alert.Header>
      <Alert.Body>
        <Typography variant="paragraphRegular">
          {t(
            'university_name_is_an_enterprise_customer_of_acadly_which_means_you_can_import_your_course_from_integrated_with_using_the_acadly_lti_plugin',
            {
              ns: i18nNS.CREATE_COURSE,
              universityName: universityName,
              integratedWith: integratedWith,
            }
          )}
        </Typography>
        <Typography variant="paragraphRegular">
          {t('courses_created_using_the_acadly_lti_plugin_allow', { ns: i18nNS.CREATE_COURSE })}
        </Typography>
        <ul>
          <li>{t('enrollment_syncing', { ns: i18nNS.CREATE_COURSE })}</li>
          <li>
            {t('exporting_grades_from_acadly_to_integrated_with', {
              ns: i18nNS.CREATE_COURSE,
              integratedWith: integratedWith,
            })}
          </li>
          <li>
            {t(
              'access_to_all_the_features_available_with_acadly_pro_including_zoom_integration_for_remote_lectures',
              { ns: i18nNS.CREATE_COURSE }
            )}
          </li>
        </ul>
        <Typography variant="paragraphRegular">
          {t('this_was_for_your_information', { ns: i18nNS.CREATE_COURSE })}{' '}
          {t('if_you_want_to_create_a_course_that_is_not_linked_please_click_on_continue', {
            ns: i18nNS.CREATE_COURSE,
          })}
        </Typography>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action type="button" variant="text" onClick={handleCancel}>
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action type="button" onClick={handleContinue}>
          {t('continue', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default InformationAlert;
