import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import ShareIcon from '../../icons/ShareIcon';
import routes from '../../pages/routes';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';

const ShareTheGoodness = () => {
  const { t } = useTranslation([i18nNS.SETTINGS, i18nNS.COMMON]);

  return (
    <ListItemButton
      component={Link}
      to={routes.refer.url()}
      aria-label={t('click_to_share_the_goodness', { ns: i18nNS.SETTINGS })}
    >
      <ListItemIcon>
        <ShareIcon />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6Regular" component="div">
          {t('share_the_goodness', { ns: i18nNS.COMMON })}
        </Typography>
      </ListItemText>
      <ChevronRightIcon color="disabled" />
    </ListItemButton>
  );
};

export default ShareTheGoodness;
