import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import clsx from 'clsx';

import Button, { Props as ButtonProps } from '../../../../components/Button';
import { generateClasses } from '../../../../utils/helpers';

export const notificationTypeClasses = generateClasses('NotificationType', ['root', 'on', 'off']);

export const NotificationTypeButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button {...props} ref={ref} className={clsx(notificationTypeClasses.root, props.className)} />
  ))
)(({ theme }) => ({
  [`&.${notificationTypeClasses.root}`]: {},
  [`&.${notificationTypeClasses.on}`]: {
    color: theme.palette.primary[600],
  },
  [`&.${notificationTypeClasses.off}`]: {
    color: theme.palette.grey[400],
  },
}));
