import { useState } from 'react';

import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useNotificationPermissionDialogVM() {
  const [isNotificationPermissionDialogOpen, setIsNotificationPermissionDialogOpen] = useState(false);

  const { container } = useZoomMeetingContext();

  const handleCloseNotificationPermissionDialog = () => {
    setIsNotificationPermissionDialogOpen(false);
  };

  const handleSubmitPermission = () => {
    // TODO: add logic for submit notification permission
    setIsNotificationPermissionDialogOpen(false);
  };

  return {
    handleCloseNotificationPermissionDialog,
    handleSubmitPermission,
    isNotificationPermissionDialogOpen,
    container,
  };
}
