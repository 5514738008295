import { useState } from 'react';

import { Stack, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';

import darkTheme from '../../styles/theme/dark';
import lightTheme from '../../styles/theme/light';
import ButtonTabs, { TabItems } from './ButtonTabs';

const useButtonPlaygroundVM = () => {
  const [activeTab, setActiveTab] = useState(TabItems.CONTAINED);

  const handleChangeActiveTab = (newValue: TabItems) => {
    setActiveTab(newValue);
  };

  return { activeTab, handleChangeActiveTab };
};

const ContentRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const ButtonPlayground = () => {
  const { activeTab, handleChangeActiveTab } = useButtonPlaygroundVM();

  return (
    <Stack direction="row">
      <ThemeProvider theme={lightTheme}>
        <ContentRoot>
          <ButtonTabs activeTab={activeTab} onChangeTab={handleChangeActiveTab} />
        </ContentRoot>
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ContentRoot>
          <ButtonTabs activeTab={activeTab} onChangeTab={handleChangeActiveTab} />
        </ContentRoot>
      </ThemeProvider>
    </Stack>
  );
};

export default ButtonPlayground;
