import { styled } from '@mui/material';
import { PickersDay, pickersDayClasses } from '@mui/x-date-pickers';

import { generateClasses } from '../../../utils/helpers';

export const datepickerClasses = {
  ...generateClasses('Datepicker', ['openPickerButton', 'calendarOpen', 'error']),
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${datepickerClasses.openPickerButton}`]: {
    color: theme.palette.grey[400],
  },
  '&:focus-within': {
    [`& .${datepickerClasses.openPickerButton}`]: {
      color: theme.palette.primary[600],
    },
    [`& .${datepickerClasses.error}`]: {
      [`& .${datepickerClasses.openPickerButton}`]: {
        color: theme.palette.error[600],
      },
    },
  },
  [`& .${datepickerClasses.calendarOpen}`]: {
    [`& .${datepickerClasses.openPickerButton}`]: {
      color: theme.palette.primary[600],
    },
    [`&.${datepickerClasses.error}`]: {
      [`& .${datepickerClasses.openPickerButton}`]: {
        color: theme.palette.error[600],
      },
    },
  },
}));

export const StyledPickersDay = styled(PickersDay)(({ theme }) => {
  return {
    borderRadius: 8,
    color: theme.palette.grey[800],
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary[50],
      borderColor: theme.palette.primary[600],
    },
    [`&.${pickersDayClasses.dayOutsideMonth}`]: {
      color: theme.palette.grey[400],
    },
    [`&.${pickersDayClasses.today}`]: {
      color: theme.palette.primary[700],
      backgroundColor: theme.palette.primary[100],
      borderColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.primary[200],
        borderColor: theme.palette.primary[600],
      },
    },
    [`&.${pickersDayClasses.selected}`]: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary[700],
      '&:hover': {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary[700],
      },
    },
    [`&.${pickersDayClasses.disabled}`]: {
      color: theme.palette.grey[400],
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
    },
  };
}) as typeof PickersDay;

/**
 * styling month and year is not done as of now because
 * mui plans to add `renderMonth` and `renderYear` props
 * @see https://github.com/mui/mui-x/issues/4662#issuecomment-1277137895
 */
