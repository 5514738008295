import { useTranslation } from 'react-i18next';

import { DialogActions as MuiDialogActions, DialogContent as MuiDialogContent, styled } from '@mui/material';

import { i18nNS } from '../../i18n';
import AttachmentView from '../AttachmentView';
import Button from '../Button';
import Header from '../Header';

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 5, 5),
}));

export interface Props {
  title: string;
  onClose: () => void;
  fileName: string;
  downloadURL: string;
}

const DownloadStep = ({ title, onClose, fileName, downloadURL }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  return (
    <>
      <Header>{title}</Header>
      <DialogContent>
        <AttachmentView variant="file" fileName={fileName} downloadURL={downloadURL} color="success" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}>
          {t('done', { ns: i18nNS.GLOSSARY })}
        </Button>
      </DialogActions>
    </>
  );
};

export default DownloadStep;
