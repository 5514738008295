import { useTranslation } from 'react-i18next';

import { Link, Stack, useMediaQuery } from '@mui/material';

import PasswordTextField from '../../components/HookFormControls/PasswordTextField';
import { i18nNS } from '../../i18n';
import { forMobile } from '../../utils/media-queries';
import Layout from '../Layout';
import { BackButton, LoginButton } from './styles';
import useLoginPasswordVM from './vm';

const LoginPassword = () => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { formMethods, onSubmit, session, handleBack, handleForgotPassword } = useLoginPasswordVM();

  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <Layout title={t('welcome_back', { ns: i18nNS.AUTH })} canSeeUserCell>
      <Stack gap={6} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack gap={3} alignItems="flex-start">
          <input readOnly type="email" autoComplete="username" value={session.email} hidden />
          <PasswordTextField
            control={control}
            name="password"
            label={t('password', { ns: i18nNS.GLOSSARY })}
            required
            size={isMobile ? 'medium' : 'xLarge'}
            placeholder={t('enter_your_password_here', { ns: i18nNS.AUTH })}
            autoComplete="current-password"
            fullWidth
            autoFocus
          />
          <Link
            variant="paragraphRegular"
            component="button"
            type="button"
            onClick={handleForgotPassword}
            aria-label={t('click_to_go_to_forgot_password_page', { ns: i18nNS.AUTH })}
          >
            {t('forgot_password', { ns: i18nNS.AUTH })}
          </Link>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={isMobile ? 3 : 5}
          component="footer"
        >
          <BackButton
            size={isMobile ? 'large' : 'xLarge'}
            onClick={handleBack}
            aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </BackButton>
          <LoginButton
            type="submit"
            size={isMobile ? 'large' : 'xLarge'}
            isLoading={isSubmitting}
            aria-label={t('click_to_login', { ns: i18nNS.AUTH })}
          >
            {t('login', { ns: i18nNS.GLOSSARY })}
          </LoginButton>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default LoginPassword;
