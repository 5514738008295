import { useEffect, useState } from 'react';

import { distinctUntilKeyChanged, fromEvent, map, merge, mergeWith, tap } from 'rxjs';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

function getHiddenPropertyName(prefix: string | null) {
  return prefix ? prefix + 'Hidden' : 'hidden';
}

function getVisibilityEvent(prefix: string | null) {
  return (prefix || '') + 'visibilitychange';
}

function getBrowserPrefix() {
  // no vendor prefix needed
  if ('hidden' in document) return null;

  for (let i = 0; i < browserPrefixes.length; i++) {
    if (getHiddenPropertyName(browserPrefixes[i]) in document) {
      // return vendor prefix
      return browserPrefixes[i];
    }
  }

  // no vendor prefix found, fallback to default
  return null;
}

const browserPrefix = getBrowserPrefix();
const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
const visibilityEventName = getVisibilityEvent(browserPrefix);

export interface PageVisibilityEvent {
  isHidden: boolean;
}

const focus$ = merge(fromEvent(document, 'focus'), fromEvent(window, 'focus')).pipe(
  map<unknown, PageVisibilityEvent>(() => ({ isHidden: false }))
);

const blur$ = merge(fromEvent(document, 'blur'), fromEvent(window, 'blur')).pipe(
  map<unknown, PageVisibilityEvent>(() => ({ isHidden: true }))
);

function getIsPageHidden() {
  return document[hiddenPropertyName as unknown as 'hidden'] || !document.hasFocus();
}

let isHidden = getIsPageHidden();

const visibility$ = merge(fromEvent(window, 'load'), fromEvent(document, visibilityEventName)).pipe(
  map<unknown, PageVisibilityEvent>(() => ({ isHidden: getIsPageHidden() })),
  mergeWith(focus$, blur$),
  distinctUntilKeyChanged('isHidden'),
  tap((e) => (isHidden = e.isHidden))
);

// Uncomment following line to test page visibility change events
// visibility$.subscribe(e => console.log(e));

export const PageVisibility = {
  visibility$,
  get isHidden() {
    return isHidden;
  },
};

export const usePageVisibility = () => {
  const [isHidden, setIsHidden] = useState(getIsPageHidden());

  useEffect(() => {
    const subscription = visibility$.subscribe((e) => {
      setIsHidden(e.isHidden);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { isHidden };
};
