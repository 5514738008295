import CreatorFooterView from './CreatorFooterView';
import ParticipantFooterView from './ParticipantFooterView';
import useZoomAppFooterVM from './vm';

const ZoomAppFooter = () => {
  const { isHost } = useZoomAppFooterVM();

  if (isHost) return <CreatorFooterView />;

  return <ParticipantFooterView />;
};

export default ZoomAppFooter;
