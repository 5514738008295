import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Drawer from '../../../components/Drawer';
import PasswordTextField from '../../../components/HookFormControls/PasswordTextField';
import { i18nNS } from '../../../i18n';
import { CancelButton, SaveButton } from './styles';
import useChangePasswordDrawerVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const ChangePasswordDrawer = ({ ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY, i18nNS.COMMON]);

  const { open } = vmOptions;
  const { onCloseDrawer: handleCloseDrawer, formMethods, onSubmit } = useChangePasswordDrawerVM(vmOptions);
  const { handleSubmit, control } = formMethods;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleCloseDrawer}
      header={t('changing_password', { ns: i18nNS.AUTH })}
      footer={
        <>
          <CancelButton
            variant="text"
            onClick={handleCloseDrawer}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </CancelButton>
          <SaveButton
            onClick={handleSubmit(onSubmit)}
            aria-label={t('click_to_change_password', { ns: i18nNS.COMMON })}
          >
            {t('save', { ns: i18nNS.GLOSSARY })}
          </SaveButton>
        </>
      }
    >
      <Stack gap={4} component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <PasswordTextField
          control={control}
          name="currentPassword"
          label={t('your_current_password', { ns: i18nNS.AUTH })}
          required
          size="large"
          fullWidth
          autoFocus
        />
        <PasswordTextField
          control={control}
          name="newPassword"
          label={t('new_password', { ns: i18nNS.AUTH })}
          required
          size="large"
          fullWidth
        />
        <PasswordTextField
          control={control}
          name="confirmNewPassword"
          label={t('confirm_new_password', { ns: i18nNS.AUTH })}
          required
          size="large"
          fullWidth
        />
      </Stack>
    </Drawer>
  );
};

export default ChangePasswordDrawer;
