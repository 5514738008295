import { Editor, Element as SlateElement, Node as SlateNode, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import Logger from '../../../utils/logger';
import { ImageEditor, ImageEditorOptions, ImageElement, ImageProps, ImageUploadStatus } from './types';

const logger = Logger.create('richtext-editor');
export const ALLOWED_EXTENSIONS = ['png', 'jpg', 'jpeg', 'heic'];

export function insertImage(editor: Editor, props: ImageProps) {
  const element: ImageElement = {
    ...props,
    type: 'image',
    children: [{ text: '' }],
  };

  Transforms.insertNodes(editor, element);

  // code for adjusting cursor

  if (!editor.selection || !Range.isCollapsed(editor.selection)) return;

  let [, path] = Editor.node(editor, editor.selection);

  // images are added as block level elements. When image is added at the end of the text, there is no element after that, so need to add 1 more element to move focus to new line
  if (path[0] === editor.children.length - 1) {
    Transforms.insertNodes(
      editor,
      { type: 'paragraph', children: [{ type: 'text', text: '' }] },
      { at: [editor.children.length], select: true }
    );
    return;
  }

  // move cursor to next character of image
  Transforms.move(editor, { distance: 1 });
}

export async function uploadAndUpdateImageNode(editor: Editor, element: ImageElement) {
  const imgPath = ReactEditor.findPath(editor, element);
  // storing path in reference, so that it gets updated even when some nodes are deleted while file is uploading
  const imgPathRef = Editor.pathRef(editor, imgPath);

  if (!imgPathRef.current) return;

  try {
    Transforms.setNodes(editor, { uploadStatus: ImageUploadStatus.UPLOADING }, { at: imgPathRef.current });

    const blob = await (await fetch(element.url)).blob();

    const file = new File([blob], element.fileName || 'image.png', {
      type: element.fileMimeType,
    });

    const { url, ach, acw } = await editor.image.upload(file);

    Transforms.setNodes(
      editor,
      { ach, acw, url, uploadStatus: ImageUploadStatus.UPLOADED },
      { at: imgPathRef.current }
    );
  } catch (error) {
    logger.error(error);

    // if image upload failed then remove it from editor
    Transforms.removeNodes(editor, { at: imgPathRef.current });
  }
}

export function isImageElement(node: SlateNode): node is ImageElement {
  return SlateElement.isElement(node) && node.type === 'image';
}

async function readImageAsDataURL(file: File) {
  const reader = new FileReader();
  return new Promise<{ name: string; mimeType: string; url: string }>((resolve, reject) => {
    reader.addEventListener('error', reject);
    reader.addEventListener('load', () => {
      const url = reader.result;
      if (!url) return reject('Empty url');
      resolve({ name: file.name, mimeType: file.type, url: url as string });
    });
    reader.readAsDataURL(file);
  });
}

export function insertPreviewImages(editor: Editor, files: FileList | File[]) {
  const images = Array.from(files).map(readImageAsDataURL);

  if (!images.length) return;

  // wait for promise resolution to insert images in order
  Promise.allSettled(images).then((result) => {
    for (const image of result) {
      if (image.status !== 'fulfilled') continue;
      insertImage(editor, {
        acw: '',
        ach: '',
        url: image.value.url,
        fileName: image.value.name,
        fileMimeType: image.value.mimeType,
        uploadStatus: ImageUploadStatus.NOT_UPLOADED,
      });
    }
  });
}

export default function withImages({ uploadImage }: ImageEditorOptions) {
  return <T extends Editor>(editor: T): T & ImageEditor => {
    (editor as T & ImageEditor).image = { upload: uploadImage };

    const { isVoid, insertData } = editor;

    editor.isVoid = (element) => {
      return element.type === 'image' ? true : isVoid(element);
    };

    editor.insertData = (data) => {
      const { files } = data;

      const images = Array.from(files).filter((file) => {
        const [type, subType = ''] = file.type.split('/');
        return type === 'image' && ALLOWED_EXTENSIONS.includes(subType.toLowerCase());
      });

      if (images.length) {
        insertPreviewImages(editor, images);
        return;
      }

      insertData(data);
    };

    editor.hasImage = () => editor.children.some((child) => isImageElement(child));

    return editor as T & ImageEditor;
  };
}
