import { createReducer, Dictionary } from '@reduxjs/toolkit';

import { closeCourse, fetchCourseDetails, openCourse } from '../courses/actions';
import { addCourseTopic, deleteCourseTopic, editCourseTopic } from './actions';
import { CourseParentTopic, CourseSubTopic, CourseTopic, TopicType } from './types';

interface TopicsState {
  courseId: MongoId;
  byId: Dictionary<CourseTopic>;
}

const initialState: TopicsState = {
  courseId: '',
  byId: {},
};

const topicsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.byId = {};
    state.courseId = '';
  });

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const { topics } = action.payload;

    for (const topic of topics) {
      const { _id, topics: subTopics } = topic;

      let parent: CourseParentTopic | undefined;
      const children: Record<MongoId, CourseSubTopic> = {};

      for (const subTopic of subTopics) {
        if (subTopic.topicId === _id) {
          parent = {
            type: TopicType.PARENT,
            id: _id,
            books: subTopic.details.books,
            links: subTopic.details.links,
            subTopics: [],
            title: subTopic.details.title,
          };
        } else {
          const topic: CourseSubTopic = {
            type: TopicType.SUB_TOPIC,
            id: subTopic.topicId,
            parentId: _id,
            books: subTopic.details.books,
            links: subTopic.details.links,
            title: subTopic.details.title,
          };
          children[subTopic.topicId] = topic;
          state.byId[subTopic.topicId] = topic;
        }
      }

      if (!parent) continue;

      parent.subTopics = Object.keys(children);
      state.byId = { ...state.byId, ...children, [_id]: parent };
    }
  });

  builder.addCase(addCourseTopic.success, (state, action) => {
    const topic = action.payload;

    if (topic.type === TopicType.PARENT) {
      state.byId[topic.topicId] = {
        type: TopicType.PARENT,
        id: topic.topicId,
        books: topic.books,
        links: topic.links,
        title: topic.title,
        subTopics: [],
      };
      return;
    }

    const parent = state.byId[topic.parentId];

    if (parent?.type !== TopicType.PARENT) return;

    parent.subTopics.push(topic.topicId);

    state.byId[topic.topicId] = {
      type: TopicType.SUB_TOPIC,
      id: topic.topicId,
      parentId: parent.id,
      books: topic.books,
      links: topic.links,
      title: topic.title,
    };
  });

  builder.addCase(deleteCourseTopic.success, (state, action) => {
    const { topicId } = action.payload;

    const topic = state.byId[topicId];

    if (!topic) return;

    // delete from dictionary map
    state.byId[topicId] = undefined;

    if (topic.type === TopicType.PARENT) {
      // for parent topic nothing need to be done
      return;
    }

    const prarent = state.byId[topic.parentId];

    if (prarent?.type !== TopicType.PARENT) return;

    // for sub-topics, remove topic-id reference from its parent topic
    prarent.subTopics = prarent.subTopics.filter((id) => id !== topicId);
  });

  builder.addCase(editCourseTopic.success, (state, action) => {
    const { topicId, title, books, links } = action.payload;
    const topic = state.byId[topicId];

    if (!topic) return;

    topic.title = title;
    topic.books = books;
    topic.links = links;
  });
});

export default topicsReducer;
