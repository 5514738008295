import { useContext } from 'react';

import { BreadcrumbContext } from '../app/BreadcrumbProvider';
import Breadcrumb from '../components/Breadcrumb';
import BreadcrumbItem from '../components/Breadcrumb/BreadcrumbItem';

const useSettingsBreadcrumbVM = () => {
  const { breadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems };
};

const SettingsBreadcrumb = () => {
  const { breadcrumbItems } = useSettingsBreadcrumbVM();

  return (
    <Breadcrumb pageTitle={breadcrumbItems.settings}>
      <BreadcrumbItem item={breadcrumbItems.home} />
    </Breadcrumb>
  );
};

export default SettingsBreadcrumb;
