import { AppBar as MuiAppBar, styled, Toolbar as MuiToolbar } from '@mui/material';

import { generateClasses } from '../../../utils/helpers';
import { forNonDesktop } from '../../../utils/media-queries';

export const pageHeaderClasses = {
  ...generateClasses('PageHeader', ['toolbar']),
};

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(5, 5),
  maxHeight: 80,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: theme.zIndex.pageHeader,
  [forNonDesktop(theme)]: {
    padding: theme.spacing(5, 4),
    boxShadow: 'none',
  },
}));

export const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  minHeight: 44,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(5),
  [`&.${pageHeaderClasses.toolbar}`]: {
    minHeight: 'unset',
  },
  [forNonDesktop(theme)]: {
    gap: theme.spacing(4),
  },
}));

export const Actions = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
}));
