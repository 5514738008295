import { CreateCoursePermission, UserSession } from '../auth/types';
import { Course } from '../db/courses/types';
import { CourseRole, CourseType, TipStatusKey } from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import { unix } from '../utils/datetime';

export function canModifyCourseEnrollments(course: Course | undefined) {
  if (!course) return false;
  if (course.status.isArchived || course.type === CourseType.SYNCED) return false;
  return course.myRole === CourseRole.ADMIN;
}

export function getIsDeadlineOverSnackbarVisible(course: Course | undefined) {
  if (!course) return false;
  const now = unix();

  // Course deadline is in future
  if (course.schedule.endDate > now) return false;
  // No need to show as course is already archived
  if (course.status.isArchived) return false;
  // Don't show when course is not published yet
  if (!course.status.isLive) return false;

  return true;
}

export function getCanEditCourseDeadline(course: Course | undefined) {
  if (!course) return false;
  return course.myRole === CourseRole.ADMIN;
}

export function getIsFabMenuVisible(course: Course | undefined) {
  if (!course) return false;
  const now = unix();

  if (course.schedule.endDate < now) return false;
  if (course.status.isArchived) return false;
  return [CourseRole.ADMIN, CourseRole.INSTRUCTOR].includes(course.myRole);
}

export function getCanAddClass(course: Course | undefined) {
  if (!course) return false;

  return course.myRole === CourseRole.ADMIN;
}

export function getCanAddAssignment(course: Course | undefined) {
  if (!course) return false;

  return course.status.isLive;
}

export function getCanAddAnnouncement(course: Course | undefined) {
  if (!course) return false;

  return course.status.isLive;
}

export function getCanViewFeatureUpdatesButton(updatedAt: number | undefined, seenAt: number | undefined) {
  if (!updatedAt || !seenAt) return false;

  return updatedAt > seenAt;
}

export function getCanSendCourseComments(isCourseArchived: boolean | undefined) {
  return !isCourseArchived;
}

export function getEmptyCommentsMessageForCourse() {
  return i18n.t(
    'this_is_an_informal_course_discussion_area_any_small_queries_or_doubts_if_the_students_have_them_can_show_up_here_for_all_to_see_in_case_you_have_an_informal_announcement_you_can_make_it_here_so_far_no_one_has_had_anything_to_say',
    { ns: i18nNS.COURSES }
  );
}

export function getIsCreateDemoCoursePermissionProvisional(session: UserSession) {
  return session.canCreateCourses === CreateCoursePermission.PROVISIONAL;
}

export function getDemoCoursePermission(activeCourses: Course[]) {
  const canCreateBasicCourse = !activeCourses.some(
    (course) => !course.permissions.isPro && course.permissions.isDemo
  );
  const canCreateProCourse = !activeCourses.some(
    (course) => course.permissions.isPro && course.permissions.isDemo
  );

  return {
    canCreateBasicCourse,
    canCreateProCourse,
  };
}

export function getCanSeeInfoText(activeCourses: Course[], session: UserSession) {
  const isCreateDemoCoursePermissionProvisional = getIsCreateDemoCoursePermissionProvisional(session);
  if (isCreateDemoCoursePermissionProvisional) return false;
  return activeCourses.length === 0;
}

export function getCoursePageTipContent(
  tipKey: TipStatusKey,
  role: CourseRole,
  isMobile: boolean,
  isDemoCourse: boolean
) {
  switch (tipKey) {
    case 'courseMainFloatingButton':
      return [
        i18n.t('click_here_to_add_extra_classes_to_the_timeline_and_then_select_publish', {
          ns: i18nNS.COURSES,
        }),
        i18n.t(
          'after_selecting_publish_this_button_can_be_used_to_add_assignments_and_announcements_to_the_timeline_as_well',
          { ns: i18nNS.COURSES }
        ),
        i18n.t(
          isDemoCourse
            ? 'click_here_to_add_more_classes_lectures_to_your_course_timeline'
            : 'before_selecting_publish_it_is_recommended_that_you_add_and_delete_classes_as_per_your_current_schedule_to_avoid_notifying_students_of_changes_later',
          { ns: i18nNS.COURSES }
        ),
      ];
    case 'courseMainAnalytics':
      if (role === CourseRole.STUDENT) {
        if (isMobile) {
          return i18n.t('tap_here_to_access_your_performance_analytics_for_this_course', {
            ns: i18nNS.COURSES,
          });
        }
        return i18n.t('click_here_to_access_your_performance_analytics_for_this_course', {
          ns: i18nNS.COURSES,
        });
      }

      if (isMobile) {
        return i18n.t(
          'tap_here_to_add_students_to_the_course_or_access_the_consolidated_course_analytics_including_overall_student_scores_and_an_individual_student_s_engagement_with_the_course',
          { ns: i18nNS.COURSES }
        );
      }
      return i18n.t(
        'click_here_to_add_students_to_the_course_or_access_the_consolidated_course_analytics_including_overall_student_scores_and_an_individual_student_s_engagement_with_the_course',
        { ns: i18nNS.COURSES }
      );
    case 'courseMainChat':
      return i18n.t(
        'tap_here_to_access_the_course_discussion_thread_which_will_be_active_throughout_the_duration_of_the_course_any_comments_about_the_course_in_general_can_be_posted_here',
        { ns: i18nNS.COURSES }
      );
    default:
      return '';
  }
}
