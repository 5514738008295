import en from './en';

export type FormulaTranslations = {
  [key in keyof typeof en]: string;
};

type Translations = {
  [lang: string]: FormulaTranslations;
};

export const formulaTranslations: Translations = {
  en,
};
