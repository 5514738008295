import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material';

import Typography, { Props as TypographyProps } from '../../../../../components/Typography';
import { RecordInPersonAttendanceColor } from '../../../../../styles/colors';

export const Cell = styled((props: StackProps) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" {...props} />
))(({ theme }) => ({
  backgroundColor: RecordInPersonAttendanceColor.SAFFRON,
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2, 4),
}));

export const CellLabel = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="grey.800" {...props} />
))(({ theme }) => ({}));

export const CellValue = styled((props: TypographyProps) => (
  <Typography variant="paragraphBold" color="grey.800" {...props} />
))(({ theme }) => ({}));

export const StyledTypography = styled((props: TypographyProps) => (
  <Typography variant="paragraphRegular" color="warning.800" {...props} />
))(({ theme }) => ({ margin: theme.spacing(0.5, 2) }));
