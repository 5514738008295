import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { i18nNS } from '../../../../../i18n';
import { ErrorCellWrapper, Time } from './styles';
import useAttendanceProcessErrorLayoutVM, { VMProps } from './vm';

interface Props extends VMProps {
  children: ReactNode;
}

const AttendanceProcessErrorLayout = ({ failure, children }: Props) => {
  const { t } = useTranslation([i18nNS.CLASS]);
  const { secondsPassed } = useAttendanceProcessErrorLayoutVM({ failure });

  return (
    <ErrorCellWrapper>
      <Time>{t('N_seconds', { ns: i18nNS.CLASS, time: secondsPassed })}</Time>
      {children}
    </ErrorCellWrapper>
  );
};

export default AttendanceProcessErrorLayout;
