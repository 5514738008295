import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const ChevronRightIcon = ({ variant = 'outlined', ...props }: Props) => {
  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 17C10.3684 17.0008 10.2379 16.9755 10.1161 16.9258C9.99426 16.876 9.88344 16.8027 9.79 16.71C9.69627 16.617 9.62188 16.5064 9.57111 16.3846C9.52034 16.2627 9.4942 16.132 9.4942 16C9.4942 15.868 9.52034 15.7373 9.57111 15.6154C9.62188 15.4936 9.69627 15.383 9.79 15.29L13.1 12L9.92 8.68999C9.73375 8.50263 9.62921 8.24918 9.62921 7.98499C9.62921 7.7208 9.73375 7.46735 9.92 7.27999C10.013 7.18626 10.1236 7.11187 10.2454 7.0611C10.3673 7.01033 10.498 6.98419 10.63 6.98419C10.762 6.98419 10.8927 7.01033 11.0146 7.0611C11.1364 7.11187 11.247 7.18626 11.34 7.27999L15.2 11.28C15.3832 11.4669 15.4859 11.7182 15.4859 11.98C15.4859 12.2417 15.3832 12.4931 15.2 12.68L11.2 16.68C11.1102 16.7769 11.0022 16.8551 10.882 16.91C10.7619 16.965 10.632 16.9955 10.5 17V17Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ChevronRightIcon;
