import { ToastContainerProps } from 'react-toastify';

import { styled } from '@mui/material';

import { generateClasses } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';

export interface Props extends ToastContainerProps {}

export const toastClasses = {
  ...generateClasses('Toast', ['root', 'hasAvatar', 'toast', 'body', 'progress']),
};

export const StyledContainer = styled('div')(({ theme }) => ({
  [`& .${toastClasses.root}`]: {
    width: '100%',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'flex-start',
    [forMobile(theme)]: {
      padding: theme.spacing(2),
    },
  },
  [`& .${toastClasses.toast}`]: {
    marginBottom: 0,
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    boxShadow: theme.acadlyShadows.lg,
    borderRadius: 8,
    alignItems: 'flex-start',
    padding: theme.spacing(4),
    borderLeftWidth: 6,
    borderLeftStyle: 'solid',
    borderColor: 'transparent',
    [forMobile(theme)]: {
      borderRadius: 4,
      padding: theme.spacing(2),
      [`& + .${toastClasses.toast}`]: {
        marginTop: theme.spacing(2),
      },
      '& .Toastify__toast-icon': {
        display: 'none',
      },
    },
    [`&.${toastClasses.hasAvatar}`]: {
      '& .Toastify__toast-icon': {
        display: 'none',
      },
    },
    '&.Toastify__toast--success': {
      borderColor: theme.palette.success[400],
      backgroundImage: `linear-gradient(270deg, ${theme.palette.success[800]} 0%, ${theme.palette.grey[900]} 100%)`,
      '& .Toastify__toast-icon > svg': {
        fill: theme.palette.success[400],
      },
    },
    '&.Toastify__toast--warning': {
      borderColor: theme.palette.warning[600],
      backgroundImage: `linear-gradient(270deg, ${theme.palette.warning[800]} 0%, ${theme.palette.grey[900]} 100%)`,
      '& .Toastify__toast-icon > svg': {
        fill: theme.palette.warning[400],
      },
    },
    '&.Toastify__toast--error': {
      borderColor: theme.palette.error[600],
      backgroundImage: `linear-gradient(270deg, ${theme.palette.error[800]} 0%, ${theme.palette.grey[900]} 100%)`,
      '& .Toastify__toast-icon > svg': {
        fill: theme.palette.error[400],
      },
    },
    '&.Toastify__toast--info': {
      borderColor: theme.palette.primary[600],
      backgroundImage: `linear-gradient(270deg, ${theme.palette.primary[800]} 0%, ${theme.palette.grey[900]} 100%)`,
      '& .Toastify__toast-icon > svg': {
        fill: theme.palette.primary[400],
      },
    },
  },
  [`& .${toastClasses.body}`]: {
    alignItems: 'flex-start',
    '& .Toastify__toast-icon': {
      marginTop: theme.spacing(0.5),
    },
  },
}));
