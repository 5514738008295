import BroadcastStartedFooterView from '../BroadcastStartedFooterView';
import StartBroadcastFooterView from '../StartBroadcastFooterView';
import useCreatorFooterViewVM from './vm';

const CreatorFooterView = () => {
  const { isBroadcasting } = useCreatorFooterViewVM();
  if (!isBroadcasting) return <StartBroadcastFooterView />;
  return <BroadcastStartedFooterView />;
};

export default CreatorFooterView;
