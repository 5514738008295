import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchFeatureUpdates, markFeatureUpdateAsRead } from '../../../db/features/actions';
import { selectFeatureUpdates } from '../../../db/features/selectors';
import { useRequestDispatch } from '../../../utils/request-actions';

export interface Props {
  open: boolean;
  onClose: () => void;
}

const useFeatureUpdatesDrawerVM = ({ open, onClose }: Props) => {
  const requestDispatch = useRequestDispatch();
  const featureUpdates = useSelector(selectFeatureUpdates);

  const [isFetchingFeatureUpdates, setIsFetchingFeatureUpdates] = useState(false);

  const handleFeatureSeen = (featureName: string) => {
    return requestDispatch(markFeatureUpdateAsRead, { featureName });
  };

  useEffect(
    function getFeatureUpdates() {
      if (!open) return;
      setIsFetchingFeatureUpdates(true);
      requestDispatch(fetchFeatureUpdates).finally(() => setIsFetchingFeatureUpdates(false));
    },
    [open, requestDispatch]
  );

  return { isFetchingFeatureUpdates, featureUpdates, handleFeatureSeen };
};

export default useFeatureUpdatesDrawerVM;
