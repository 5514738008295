import React, { ElementType, ReactNode, Ref } from 'react';

import clsx from 'clsx';

import { DrawerHeader } from './DrawerHeader';
import { Body, drawerClasses, DrawerRoot, Footer } from './styles';
import useDrawerVM, { Props as VMProps } from './vm';

export interface Props<L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>
  extends VMProps<L, R> {
  open: boolean;
  anchor?: 'top' | 'right' | 'bottom' | 'left';
  footer?: ReactNode;
  bodyRef?: Ref<HTMLDivElement>;
  children: ReactNode | (({ titleId, bodyId }: { titleId: string; bodyId: string }) => ReactNode);
  className?: string;
  classes?: Partial<typeof drawerClasses>;
}

const Drawer = React.forwardRef(
  <L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>(
    { classes, children, open, footer, bodyRef, className, anchor, ...vmProps }: Props<L, R>,
    ref: Ref<HTMLDivElement>
  ) => {
    const { bodyId, handleDismiss, handleKeyDown, headerProps, titleId } = useDrawerVM(vmProps);

    return (
      <DrawerRoot
        open={open}
        onClose={handleDismiss}
        onKeyDown={handleKeyDown}
        anchor={anchor}
        aria-labelledby={titleId}
        aria-describedby={bodyId}
        className={className}
        classes={{
          ...classes,
          root: clsx(drawerClasses.root, classes?.root),
          paper: clsx(drawerClasses.paper, classes?.paper),
        }}
        ref={ref}
        tabIndex={-1}
      >
        {typeof vmProps.header === 'function'
          ? vmProps.header(titleId)
          : headerProps && (
              <DrawerHeader {...headerProps} className={clsx(drawerClasses.header, classes?.header)} />
            )}
        {typeof children === 'function' ? (
          children({ titleId, bodyId })
        ) : (
          <Body ref={bodyRef} id={bodyId} className={clsx(drawerClasses.body, classes?.body)}>
            {children}
          </Body>
        )}
        {typeof footer === 'function'
          ? footer()
          : footer && <Footer className={clsx(drawerClasses.footer, classes?.footer)}>{footer}</Footer>}
      </DrawerRoot>
    );
  }
);

export default Drawer;
