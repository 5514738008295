import { useState } from 'react';

const useWeeklyScheduleVM = () => {
  const [isAddWeeklyClassDrawerOpen, setIsAddWeeklyClassDrawerOpen] = useState(false);

  const onAddWeeklyClassButtonClick = () => {
    setIsAddWeeklyClassDrawerOpen(true);
  };

  const onCloseAddWeeklyClassDrawer = () => {
    setIsAddWeeklyClassDrawerOpen(false);
  };

  return {
    isAddWeeklyClassDrawerOpen,
    onAddWeeklyClassButtonClick,
    onCloseAddWeeklyClassDrawer,
  };
};

export default useWeeklyScheduleVM;
