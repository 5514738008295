import { ListItemProps as MuiListItemProps } from '@mui/material';

import { Root } from './styles';
import useListItem, { UseListItemProps } from './vm';

export interface ListItemProps extends MuiListItemProps, UseListItemProps {}

const ListItem = ({ children, isFocused, ...props }: ListItemProps) => {
  const { rootRef } = useListItem({ isFocused });

  return (
    <Root ref={rootRef} aria-selected={isFocused} tabIndex={-1} {...props}>
      {children}
    </Root>
  );
};

export default ListItem;
