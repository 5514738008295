import { Avatar as MuiAvatar, avatarClasses as MuiAvatarClasses, styled } from '@mui/material';

import defaultAvatarImageURL from '../../assets/default-avatar.svg';
import { generateClasses } from '../../utils/helpers';

export const avatarClasses = {
  ...MuiAvatarClasses,
  ...generateClasses('Avatar', ['defaultBackground']),
};

export const Avatar = styled(MuiAvatar, {
  shouldForwardProp: (props) => !['avatarId', 'bucketUrl'].includes(props as string),
})(({ theme }) => ({
  backgroundColor: theme.palette.primary[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
  color: theme.palette.primary[600],
  [`&.${avatarClasses.defaultBackground}`]: {
    backgroundImage: `url(${defaultAvatarImageURL})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
}));
