import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { NotificationType } from '../shared/types';
import { createNotificationPrefsFactory } from './helpers';
import { Accessibility, NotificationPrefs, Tips } from './types';

export async function getUserPreferences() {
  const { data } = await request.get<API.FetchUserPreferencesResponse>(urls.getUserPreferences);
  return data;
}

export async function setSortPreference(payload: API.SetSortPreferenceRequest) {
  await request.put(urls.setSortPreference, payload);
}

export async function updateTipsPreference({ status: tipStatus, turnOff }: Tips) {
  const status = {} as API.UpdateTipsPreferenceRequest['status'];

  for (let _tipStatusKey in tipStatus) {
    const tipStatusKey = _tipStatusKey as keyof API.UpdateTipsPreferenceRequest['status'];
    status[tipStatusKey] = {
      priority: tipStatus[tipStatusKey].priority,
      seen: tipStatus[tipStatusKey].seen ? 1 : 0,
    };
  }

  const payload: API.UpdateTipsPreferenceRequest = {
    agent: 'web',
    status,
    turnOff: turnOff ? 1 : 0,
  };
  await request.post(urls.updateTipsPreference, payload);
}

export async function updateAccessibilityPreference({ turnOff }: Accessibility) {
  const payload: API.UpdateAccessibilityPreferenceRequest = {
    agent: 'web',
    turnOff: turnOff ? 1 : 0,
  };
  await request.post(urls.updateAccessibilityPreference, payload);
}

export async function getNotificationPrefs() {
  const { data } = await request.get<API.GetNotificationPrefsResponse>(urls.getNotificationPrefs);
  return createNotificationPrefsFactory(data.notifPref);
}

export async function saveNotificationPrefs(params: NotificationPrefs) {
  const payload = {} as API.NotificationPrefs;

  for (const [name, prefs] of Object.entries(params)) {
    payload[name as NotificationType] = {
      cloud: prefs.cloud ? 1 : 0,
      mail: prefs.mail ? 1 : 0,
    };
  }

  await request.put(urls.saveNotificationPrefs, payload);
}

export async function sendReferrals(payload: API.SendReferralRequest) {
  await request.post(urls.sendReferral, payload);
}

export function sendFeedback(payload: API.SendFeedbackRequestBody) {
  return request.post<unknown>(urls.feedback, payload);
}
