import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const EyeOffIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M15.29 18.12L14 16.78L12.66 15.44C12.4585 15.4753 12.2546 15.4954 12.05 15.5C11.5862 15.5066 11.1257 15.421 10.6952 15.2481C10.2648 15.0751 9.87306 14.8184 9.54274 14.4927C9.21242 14.1671 8.95013 13.779 8.77112 13.351C8.59211 12.9231 8.49995 12.4639 8.5 12C8.50464 11.7954 8.5247 11.5915 8.56 11.39L6.56 9.39L5 7.87C3.87133 8.93347 2.90441 10.1564 2.13 11.5C2.04223 11.652 1.99603 11.8245 1.99603 12C1.99603 12.1755 2.04223 12.348 2.13 12.5C2.76 13.59 6.13 19 12.02 19H12.27C13.3775 18.9671 14.4708 18.7404 15.5 18.33L15.29 18.12Z"
          fill="currentColor"
        />
        <path
          d="M11.39 8.56C11.5915 8.5247 11.7954 8.50464 12 8.5C12.9283 8.5 13.8185 8.86875 14.4749 9.52513C15.1313 10.1815 15.5 11.0717 15.5 12C15.4954 12.2046 15.4753 12.4085 15.44 12.61L18.96 16.13C20.1028 15.0693 21.0834 13.8462 21.87 12.5C21.9578 12.348 22.004 12.1755 22.004 12C22.004 11.8245 21.9578 11.652 21.87 11.5C21.23 10.39 17.71 4.82 11.73 5C10.6225 5.03285 9.52924 5.25962 8.5 5.67L11.39 8.56Z"
          fill="currentColor"
        />
        <path
          d="M19.41 18L20.71 19.29C20.8037 19.383 20.8781 19.4936 20.9289 19.6154C20.9797 19.7373 21.0058 19.868 21.0058 20C21.0058 20.132 20.9797 20.2627 20.9289 20.3846C20.8781 20.5064 20.8037 20.617 20.71 20.71C20.617 20.8037 20.5064 20.8781 20.3846 20.9289C20.2627 20.9797 20.132 21.0058 20 21.0058C19.868 21.0058 19.7373 20.9797 19.6154 20.9289C19.4936 20.8781 19.383 20.8037 19.29 20.71L16.59 18L12.0842 13.4977C12.0564 13.4992 12.0283 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.9724 10.5007 11.945 10.5022 11.9178L7.28 8.7L5.53 7L3.29 4.71C3.10169 4.5217 2.99591 4.2663 2.99591 4C2.99591 3.7337 3.10169 3.47831 3.29 3.29C3.38324 3.19676 3.49393 3.1228 3.61575 3.07234C3.73757 3.02188 3.86814 2.99591 4 2.99591C4.13186 2.99591 4.26243 3.02188 4.38425 3.07234C4.50607 3.1228 4.61676 3.19676 4.71 3.29L11.918 10.5022C11.9451 10.5007 11.9725 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12C13.5 12.0281 13.4992 12.0559 13.4977 12.0836L19.41 18Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.71001 3.29C4.61677 3.19676 4.50608 3.1228 4.38426 3.07234C4.26243 3.02188 4.13187 2.99591 4.00001 2.99591C3.86815 2.99591 3.73758 3.02188 3.61576 3.07234C3.49394 3.1228 3.38325 3.19676 3.29001 3.29C3.1017 3.47831 2.99592 3.7337 2.99592 4C2.99592 4.2663 3.1017 4.5217 3.29001 4.71L8.92001 10.34C8.56381 11.0026 8.43059 11.7624 8.5401 12.5067C8.6496 13.2509 8.996 13.9401 9.52795 14.4721C10.0599 15.004 10.7491 15.3504 11.4934 15.4599C12.2376 15.5694 12.9974 15.4362 13.66 15.08L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29L4.71001 3.29ZM12 13.5C11.6022 13.5 11.2207 13.342 10.9393 13.0607C10.658 12.7794 10.5 12.3978 10.5 12V11.93L12.06 13.49L12 13.5Z"
        fill="currentColor"
      />
      <path
        d="M12.22 17C7.92001 17.1 5.10001 13.41 4.22001 12C4.84648 11.0007 5.59937 10.0865 6.46001 9.28L5.00001 7.87C3.87133 8.93347 2.90442 10.1564 2.13001 11.5C2.04224 11.652 1.99603 11.8245 1.99603 12C1.99603 12.1755 2.04224 12.348 2.13001 12.5C2.76001 13.59 6.13001 19 12.02 19H12.27C13.3776 18.9671 14.4708 18.7404 15.5 18.33L13.92 16.75C13.3644 16.8962 12.7942 16.98 12.22 17Z"
        fill="currentColor"
      />
      <path
        d="M21.87 11.5C21.23 10.39 17.7 4.82 11.73 5C10.6225 5.03286 9.52924 5.25962 8.50001 5.67L10.08 7.25C10.6356 7.10383 11.2058 7.01998 11.78 7C16.07 6.89 18.89 10.59 19.78 12C19.1381 13.0023 18.3683 13.9167 17.49 14.72L19 16.13C20.1429 15.0693 21.1234 13.8462 21.91 12.5C21.9918 12.3445 22.0311 12.1702 22.0241 11.9946C22.0171 11.8191 21.9639 11.6485 21.87 11.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default EyeOffIcon;
