import { ReactNode } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface StripeProvidersProps {
  children?: ReactNode;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || 'missing');

const StripeProvider = ({ children }: StripeProvidersProps) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
