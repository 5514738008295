import { useSelector } from 'react-redux';

import { useCurrentUserWithRole } from '../../courses/hooks';
import { selectOnlineMeeting } from '../../db/online-meeting/selectors';
import { useZoomMeetingContext } from '../ZoomMeetingContext/hooks';

export default function useZoomAppFooterVM() {
  const {
    state: { classId },
  } = useZoomMeetingContext();

  const currentUser = useCurrentUserWithRole();

  const onlineMeeting = useSelector(selectOnlineMeeting(classId));

  const isHost = onlineMeeting?.hostUserId === currentUser.userId;

  return {
    isHost,
  };
}
