import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';

export const validationSchema = Joi.object({
  joinCode: Joi.string()
    .length(6)
    .messages({
      'string.empty': i18n.t('invalid_join_code', { ns: i18nNS.VALIDATION }),
      'string.length': i18n.t('invalid_join_code', { ns: i18nNS.VALIDATION }),
    })
    .required(),
});
