import { useContext, useEffect, useMemo } from 'react';

import { randomStr } from '../../../utils/helpers';
import { RadioGroupContext } from './Context';

export interface Props {
  /**
   * canUncheck only has an impact when user clicks on already checked radio option.
   *
   * when `canUncheck === true` then clicking on checked radio option will uncheck it.
   * `else` clicking on checked radio option will not have any impact
   *
   * @default false
   */
  canUncheck?: boolean;
  value?: string | null;
  onChange?: (value: string | null) => void;
}

const useRadioGroupVM = ({ canUncheck = false, value = null, onChange }: Props) => {
  const labelId = useMemo(() => `radio-group-label-${randomStr(4)}`, []);

  const { setCanUncheck, value: contextValue, setValue: setContextValue } = useContext(RadioGroupContext);

  const handleClick = async () => {
    // wait for contextValue to be updated by RadioOption
    await new Promise((resolve) => setTimeout(resolve, 0));
    if (value === contextValue) return;
    onChange?.(contextValue);
  };

  useEffect(
    function setInitialCanUncheck() {
      setCanUncheck(canUncheck);
    },
    [canUncheck, setCanUncheck]
  );

  useEffect(
    function setInitialValue() {
      setContextValue(value);
    },
    [value, setContextValue]
  );

  return { labelId, contextValue, handleClick };
};

export default useRadioGroupVM;
