import { AriaAttributes, ElementType, ReactNode } from 'react';

import { GraphicEq as NowPlayingIcon } from '@mui/icons-material';
import {
  ButtonBase,
  ButtonBaseProps,
  Stack as MuiStack,
  StackProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';

import { ReactComponent as ZoomUsIcon } from '../../assets/zoom-us-round.svg';
import Tag from '../../components/Tag/index';
import UserAvatar from '../../components/UserAvatar/index';
import { generateClasses } from '../../utils/helpers';
import { CourseWidgetData } from '../types';

export const courseWidgetClasses = {
  ...generateClasses('CourseWidget', [
    'header',
    'playingNowIcon',
    'zoomUsIcon',
    'courseCode',
    'content',
    'courseTitle',
    'footer',
    'calendarIcon',
    'dates',
    'role',
  ]),
};

interface CourseWidgetProps<C extends ElementType<any>> extends AriaAttributes {
  className?: string;
  component?: C;
  course: CourseWidgetData;
  renderDates?: (dates: string, course: CourseWidgetData) => ReactNode;
  forceRenderDates?: boolean;
}

export type Props<C extends ElementType<any>> = ButtonBaseProps<C, CourseWidgetProps<C>>;

export const Root = styled(ButtonBase)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateAreas: '"header" "content" "footer"',
  gridTemplateColumns: '1fr',
  gridTemplateRows: `${theme.spacing(13)} 1fr ${theme.spacing(12)}`,
  justifyItems: 'stretch',
  gap: theme.spacing(1),
  borderRadius: 8,
  backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`,
  border: `1px solid ${theme.palette.grey[300]}`,
  textAlign: 'left',
}));

export const Header = styled('div')(({ theme }) => ({
  gridArea: 'header',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 5),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

export const StyledNowPlayingIcon = styled(NowPlayingIcon)(({ theme }) => ({
  width: 28,
  height: 28,
  marginRight: theme.spacing(1),
}));

export const StyledZoomUsIcon = styled(ZoomUsIcon)(({ theme }) => ({
  width: 28,
  height: 28,
  marginRight: theme.spacing(1),
}));

export const CourseCode = styled((props: TypographyProps) => <Typography variant="h6Medium" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.primary[900],
  })
);

export const Content = styled('div')(({ theme }) => ({
  gridArea: 'content',
  padding: theme.spacing(3, 5),
}));

export const CourseTitle = styled((props: TypographyProps) => <Typography variant="h5Bold" {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[900],
    marginBottom: theme.spacing(3),
  })
);

export const AdminAvatar = styled(UserAvatar)(({ theme }) => ({
  width: theme.spacing(6),
  height: theme.spacing(6),
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 8,
}));

export const AdminName = styled((props: TypographyProps) => <Typography variant="h6Medium" {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.grey[700],
  })
);

export const Footer = styled('div')(({ theme }) => ({
  gridArea: 'footer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3, 5),
  borderTop: `1px solid ${theme.palette.grey[200]}`,
}));

export const Dates = styled((props: TypographyProps) => <Typography variant="paragraphRegular" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey[800],
  })
);

export const Role = styled(Tag)(({ theme }) => ({
  marginLeft: 'auto',
}));

export const Stack = styled((props: StackProps) => (
  <MuiStack direction="row" alignItems="center" {...props} />
))(({ theme }) => ({}));
