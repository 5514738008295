import { List, listItemClasses, listItemIconClasses, styled, Typography } from '@mui/material';

import demoCourseBg from '../../../assets/demo-course-bg.png';
import Button from '../../../components/Button';

const BOOK_ICON_TOP_OFFSET = 33;

export const Root = styled('div')(({ theme }) => ({
  marginTop: BOOK_ICON_TOP_OFFSET,
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 8,
  backgroundImage: `url(${demoCourseBg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top right',
  position: 'relative',
}));

export const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.spacing(16),
}));

export const BookIcon = styled('img')(() => ({
  position: 'absolute',
  top: -BOOK_ICON_TOP_OFFSET,
  right: 21,
}));

export const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  width: 334,
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  marginBottom: theme.spacing(2),
}));

export const Description = styled(Typography)(({ theme }) => ({}));

export const FeatureSection = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 5, 8),
}));

export const FeatureTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const FeatureList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: 0,

  [`.${listItemClasses.root}`]: {
    padding: 0,
    gap: theme.spacing(3),
  },

  [`.${listItemIconClasses.root}`]: {
    minWidth: 'unset',
    color: theme.palette.primary[400],
  },
}));

export const CreateButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  width: `calc(100% - 2*${theme.spacing(5)})`,
}));
