import { ElementType, forwardRef, ReactNode, Ref } from 'react';

import { Breakpoint, Typography } from '@mui/material';

import clsx from 'clsx';

import Header from '../Header';
import SlideUpTransition from './SlideUpTransition';
import { DialogActions, dialogClasses, DialogContent, StyledMuiDialog } from './styles';
import useDialogVM, { Props as VMProps } from './vm';

export { dialogClasses };

export enum CloseReason {
  CLOSE_BUTTON_CLICK,
  BACKDROP_CLICKED,
  ESC_PRESSED,
  OTHER,
}

export interface Props<L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>
  extends VMProps<L, R> {
  open: boolean;
  container?: Element | (() => Element | null) | null;
  footer?: ReactNode | (() => ReactNode);
  children: ReactNode | (({ titleId, bodyId }: { titleId: string; bodyId: string }) => ReactNode);
  className?: string;
  classes?: Partial<typeof dialogClasses>;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
  /** @default false */
  disablePortal?: boolean;
  /** @default false */
  disableFullscreen?: boolean;
}

const Dialog = forwardRef(
  <L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>(
    {
      open,
      onClose,
      dismissal,
      header,
      children,
      footer,
      classes,
      className,
      container,
      fullWidth,
      maxWidth,
      disablePortal = false,
      disableFullscreen = false,
    }: Props<L, R>,
    ref: Ref<HTMLDivElement>
  ) => {
    const { bodyId, handleDismiss, handleKeyDown, headerProps, isMobile, titleId } = useDialogVM({
      onClose,
      dismissal,
      header,
    });
    const hasContainer = Boolean(container);

    return (
      <StyledMuiDialog
        ref={ref}
        aria-describedby={bodyId}
        aria-labelledby={titleId}
        fullScreen={disableFullscreen ? false : isMobile}
        open={open}
        onClose={handleDismiss}
        onKeyDown={handleKeyDown}
        container={container}
        scroll="paper"
        TransitionComponent={SlideUpTransition}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className={clsx(
          { [dialogClasses.hasContainer]: hasContainer },
          className,
          dialogClasses.root,
          classes?.root
        )}
        classes={{ paper: clsx(dialogClasses.paper, classes?.paper) }}
        disablePortal={disablePortal || hasContainer}
      >
        {typeof header === 'function'
          ? header(titleId)
          : headerProps && (
              <Header {...headerProps} className={clsx(dialogClasses.header, classes?.header)} />
            )}
        {typeof children === 'function' ? (
          children({ titleId, bodyId })
        ) : (
          <DialogContent id={bodyId} className={clsx(dialogClasses.body, classes?.body)}>
            {typeof children === 'string' ? (
              <Typography variant="paragraphRegular">{children}</Typography>
            ) : (
              children
            )}
          </DialogContent>
        )}
        {typeof footer === 'function'
          ? footer()
          : footer && (
              <DialogActions className={clsx(dialogClasses.footer, classes?.footer)}>{footer}</DialogActions>
            )}
      </StyledMuiDialog>
    );
  }
);

export default Dialog;
