import { ToBeDone } from '../db/shared/types';
import { AppContext } from '../types';
import { createAcadlyRoute } from './helpers';
import { AcadlyPage, GetParams } from './types';

const login = createAcadlyRoute({
  name: AcadlyPage.LOGIN,
  path: '/login',
  context: AppContext.GET_IN,
  backPath: '/',
});

const signup = createAcadlyRoute({
  name: AcadlyPage.SIGNUP,
  path: '/signup',
  context: AppContext.GET_IN,
  backPath: '/',
});

const signupWithRole = createAcadlyRoute({
  name: AcadlyPage.SIGNUP_BY_ROLE,
  path: `/signup/:role(teacher|student)`,
  context: AppContext.GET_IN,
  backPath: '/',
});

export type SignupParams = GetParams<typeof signupWithRole>;

const root = createAcadlyRoute({
  name: AcadlyPage.ROOT,
  path: '/',
  context: AppContext.GET_IN,
  backPath: '/',
});

const home = createAcadlyRoute({
  name: AcadlyPage.HOME,
  dependsOn: AcadlyPage.ROOT,
  path: '/home',
  context: AppContext.HOME,
  backPath: '/',
});

const settings = createAcadlyRoute({
  name: AcadlyPage.SETTINGS,
  dependsOn: AcadlyPage.ROOT,
  path: '/settings',
  context: AppContext.HOME,
  backPath: '/',
});

const archives = createAcadlyRoute({
  name: AcadlyPage.ARCHIVES,
  dependsOn: AcadlyPage.ROOT,
  path: `/:universitySlug/archives`,
  context: AppContext.HOME,
  backPath: '/',
});

export type ArchivesParams = GetParams<typeof archives>;

const refer = createAcadlyRoute({
  name: AcadlyPage.REFER,
  dependsOn: AcadlyPage.SETTINGS,
  path: '/refer',
  context: AppContext.HOME,
  backPath: settings.path,
});

const course = createAcadlyRoute({
  name: AcadlyPage.COURSE,
  dependsOn: AcadlyPage.ROOT,
  path: `/:universitySlug/courses/:courseShortId`,
  context: AppContext.COURSE,
  backPath: home.path,
});

export type CourseParams = GetParams<typeof course>;

const timeline = createAcadlyRoute({
  name: AcadlyPage.TIMELINE,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/timeline`,
  context: AppContext.COURSE,
  backPath: home.path,
});

const info = createAcadlyRoute({
  name: AcadlyPage.INFO,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/info`,
  context: AppContext.COURSE,
  backPath: home.path,
});

const syllabus = createAcadlyRoute({
  name: AcadlyPage.SYLLABUS,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/syllabus`,
  context: AppContext.COURSE,
  backPath: home.path,
});

const courseAnalytics = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANALYTICS,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/analytics`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const courseAverages = createAcadlyRoute({
  name: AcadlyPage.COURSE_AVERAGES,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/analytics/averages`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const studentAnalytics = createAcadlyRoute({
  name: AcadlyPage.STUDENT_ANALYTICS,
  dependsOn: AcadlyPage.COURSE,
  path: `${course.path}/analytics/student`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const courseAnnouncements = createAcadlyRoute({
  name: AcadlyPage.COURSE_ANNOUNCEMENTS,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${timeline.path}/announcements`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const newAnnouncement = createAcadlyRoute({
  name: AcadlyPage.NEW_ANNOUNCEMENT,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${courseAnnouncements.path}/new`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const announcementPage = createAcadlyRoute({
  name: AcadlyPage.ANNOUNCEMENT_PAGE,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${courseAnnouncements.path}/:announcementShortId`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

export type AnnouncementParams = GetParams<typeof announcementPage>;

const courseAssignments = createAcadlyRoute({
  name: AcadlyPage.COURSE_ASSIGNMENTS,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${timeline.path}/assignments`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const newAssignment = createAcadlyRoute({
  name: AcadlyPage.NEW_ASSIGNMENT,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${courseAssignments.path}/new`,
  context: AppContext.COURSE,
  backPath: timeline.path,
});

const assignmentPage = createAcadlyRoute({
  name: AcadlyPage.ASSIGNMENT_PAGE,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${courseAssignments.path}/:assignmentShortId`,
  context: AppContext.ASSIGNMENT,
  backPath: timeline.path,
});

export type AssignmentParams = GetParams<typeof assignmentPage>;

const assignmentAnalytics = createAcadlyRoute({
  name: AcadlyPage.ASSIGNMENT_ANALYTICS,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${assignmentPage.path}/analytics`,
  context: AppContext.ASSIGNMENT,
  backPath: assignmentPage.path,
});

export type AssignmentAnalyticsParams = GetParams<typeof assignmentAnalytics>;

const cls = createAcadlyRoute({
  name: AcadlyPage.CLASS,
  dependsOn: AcadlyPage.TIMELINE,
  path: `${timeline.path}/classes/:classShortId`,
  context: AppContext.CLASS,
  backPath: timeline.path,
});

export type ClassParams = GetParams<typeof cls>;

const activities = createAcadlyRoute({
  name: AcadlyPage.ACTIVITIES,
  dependsOn: AcadlyPage.CLASS,
  path: `${cls.path}/activities`,
  context: AppContext.CLASS,
  backPath: timeline.path,
});

const agenda = createAcadlyRoute({
  name: AcadlyPage.AGENDA,
  dependsOn: AcadlyPage.CLASS,
  path: `${cls.path}/agenda`,
  context: AppContext.CLASS,
  backPath: timeline.path,
});

const attendance = createAcadlyRoute({
  name: AcadlyPage.ATTENDANCE,
  dependsOn: AcadlyPage.CLASS,
  path: `${cls.path}/attendance`,
  context: AppContext.CLASS,
  backPath: timeline.path,
});

const classAnalytics = createAcadlyRoute({
  name: AcadlyPage.CLASS_ANALYTICS,
  dependsOn: AcadlyPage.CLASS,
  path: `${cls.path}/analytics`,
  context: AppContext.CLASS,
  backPath: cls.path,
});

export type ClassAnalyticsParams = GetParams<typeof classAnalytics>;

const classQuizzes = createAcadlyRoute({
  name: AcadlyPage.CLASS_QUIZZES,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${activities.path}/quizzes`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

const newQuiz = createAcadlyRoute({
  name: AcadlyPage.NEW_QUIZ,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classQuizzes.path}/new/:toBeDone(${ToBeDone.IN_CLASS}|${ToBeDone.PRE_CLASS})`,
  context: AppContext.QUIZ,
  backPath: activities.path,
});

export type NewQuizParams = GetParams<typeof newQuiz>;

const quiz = createAcadlyRoute({
  name: AcadlyPage.QUIZ,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classQuizzes.path}/:quizShortId`,
  context: AppContext.QUIZ,
  backPath: activities.path,
});

export type QuizParams = GetParams<typeof quiz>;

const quizAnalytics = createAcadlyRoute({
  name: AcadlyPage.QUIZ_ANALYTICS,
  dependsOn: AcadlyPage.QUIZ,
  path: `${quiz.path}/analytics`,
  context: AppContext.QUIZ,
  backPath: quiz.path,
});

export type QuizAnalyticsParams = GetParams<typeof quizAnalytics>;

const classPolls = createAcadlyRoute({
  name: AcadlyPage.CLASS_POLLS,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${activities.path}/polls`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

const newPoll = createAcadlyRoute({
  name: AcadlyPage.NEW_POLL,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classPolls.path}/new/:toBeDone(${ToBeDone.IN_CLASS}|${ToBeDone.PRE_CLASS})`,
  context: AppContext.POLL,
  backPath: activities.path,
});

export type NewPollParams = GetParams<typeof newPoll>;

const poll = createAcadlyRoute({
  name: AcadlyPage.POLL,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classPolls.path}/:pollShortId`,
  context: AppContext.POLL,
  backPath: activities.path,
});

export type PollParams = GetParams<typeof poll>;

const pollAnalytics = createAcadlyRoute({
  name: AcadlyPage.POLL_ANALYTICS,
  dependsOn: AcadlyPage.POLL,
  path: `${poll.path}/analytics`,
  context: AppContext.POLL,
  backPath: poll.path,
});

export type PollAnalyticsParams = GetParams<typeof pollAnalytics>;

const classDiscussions = createAcadlyRoute({
  name: AcadlyPage.CLASS_DISCUSSIONS,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${activities.path}/discussions`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

const newDiscussion = createAcadlyRoute({
  name: AcadlyPage.NEW_DISCUSSION,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classDiscussions.path}/new/:toBeDone(${ToBeDone.IN_CLASS}|${ToBeDone.PRE_CLASS})`,
  context: AppContext.DISCUSSION,
  backPath: activities.path,
});

export type NewDiscussionParams = GetParams<typeof newDiscussion>;

const discussion = createAcadlyRoute({
  name: AcadlyPage.DISCUSSION,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classDiscussions.path}/:discussionShortId`,
  context: AppContext.DISCUSSION,
  backPath: activities.path,
});

export type DiscussionParams = GetParams<typeof discussion>;

const discussionWordCloud = createAcadlyRoute({
  name: AcadlyPage.DISCUSSION_WORD_CLOUD,
  dependsOn: AcadlyPage.DISCUSSION,
  path: `${discussion.path}/word-cloud`,
  context: AppContext.DISCUSSION,
  backPath: discussion.path,
});

export type DiscussionWordCloudParams = GetParams<typeof discussionWordCloud>;

const classQueries = createAcadlyRoute({
  name: AcadlyPage.CLASS_QUERIES,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${activities.path}/queries`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

const newQuery = createAcadlyRoute({
  name: AcadlyPage.NEW_QUERY,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classQueries.path}/new/:anonymity(as-anonymous|as-self)`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

export type NewQueryParams = GetParams<typeof newQuery>;

const query = createAcadlyRoute({
  name: AcadlyPage.QUERY,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classQueries.path}/:queryShortId`,
  context: AppContext.QUERY,
  backPath: activities.path,
});

export type QueryParams = GetParams<typeof query>;

const classResources = createAcadlyRoute({
  name: AcadlyPage.CLASS_RESOURCES,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${activities.path}/resources`,
  context: AppContext.CLASS,
  backPath: activities.path,
});

const newResource = createAcadlyRoute({
  name: AcadlyPage.NEW_RESOURCE,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classResources.path}/new/:toBeDone(${ToBeDone.IN_CLASS}|${ToBeDone.PRE_CLASS})`,
  context: AppContext.RESOURCE,
  backPath: activities.path,
});

export type NewResourceParams = GetParams<typeof newResource>;

const resource = createAcadlyRoute({
  name: AcadlyPage.RESOURCE,
  dependsOn: AcadlyPage.ACTIVITIES,
  path: `${classResources.path}/:resourceShortId`,
  context: AppContext.RESOURCE,
  backPath: activities.path,
});

export type ResourceParams = GetParams<typeof resource>;

const resourceAnalytics = createAcadlyRoute({
  name: AcadlyPage.RESOURCE_ANALYTICS,
  dependsOn: AcadlyPage.RESOURCE,
  path: `${resource.path}/analytics`,
  context: AppContext.RESOURCE,
  backPath: resource.path,
});

export type ResourceAnalyticsParams = GetParams<typeof resourceAnalytics>;

const playground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: '/playground',
  context: AppContext.HOME,
  backPath: '/',
});

const richTextPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/richtext`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const timerPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/timers`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const pipContainerPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/pip`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const formControlsPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/form-controls`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const tooltipPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/tooltip`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const progressbarPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/progressbar`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const buttonPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/buttons`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const tagPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/tag`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const userAvatarPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/userAvatar`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const sectionPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/section`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const alertPlayground = createAcadlyRoute({
  name: AcadlyPage.PLAYGROUND,
  path: `${playground.path}/alert`,
  context: AppContext.HOME,
  backPath: playground.path,
});

const routes = {
  login,
  signup,
  signupWithRole,

  root,
  home,
  settings,
  archives,
  refer,

  course,
  timeline,
  info,
  syllabus,
  courseAnalytics,
  courseAverages,
  studentAnalytics,

  courseAnnouncements,
  newAnnouncement,
  announcementPage,

  courseAssignments,
  newAssignment,
  assignmentPage,
  assignmentAnalytics,

  class: cls,
  activities,
  agenda,
  attendance,
  classAnalytics,

  classQuizzes,
  newQuiz,
  quiz,
  quizAnalytics,

  classPolls,
  newPoll,
  poll,
  pollAnalytics,

  classDiscussions,
  newDiscussion,
  discussion,
  discussionWordCloud,

  classQueries,
  newQuery,
  query,

  classResources,
  newResource,
  resource,
  resourceAnalytics,

  playground,
  richTextPlayground,
  timerPlayground,
  pipContainerPlayground,
  formControlsPlayground,
  tooltipPlayground,
  progressbarPlayground,
  buttonPlayground,
  tagPlayground,
  userAvatarPlayground,
  sectionPlayground,
  alertPlayground,
};

export default routes;
