import { Draft } from '@reduxjs/toolkit';

import { CourseUser } from '../../shared/types';
import { Course, CourseActivities } from '../types';

export function activityPublished({
  course,
  activityType,
  currentUser,
  isActivityExists,
  sender,
}: {
  course: Draft<Course> | undefined;
  activityType: keyof Omit<CourseActivities, 'total' | 'seen'>;
  currentUser: CourseUser;
  sender: CourseUser;
  isActivityExists: boolean;
}) {
  if (!course) return;

  const activity = course.activities[activityType];

  course.activities.total++;
  activity.published++;

  if (!isActivityExists) {
    activity.total++;
  }

  if (currentUser.userId === sender.userId) {
    course.activities.seen++;
  }
}
