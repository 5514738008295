import urls from '../../app/urls';
import request from '../../utils/request';
import { API } from '../shared/api-responses';
import { ResolveHandAction } from '../shared/types';
import {
  CreateZoomMeetingPayload,
  FetchClassRecordingsPayload,
  FetchZoomMeetingDetailsPayload,
  ResolveHandPayload,
  UnregisterZoomMeetingParticipantPayload,
} from './action.type';

export async function authorizeZoomApp() {
  const { data } = await request.get<API.AuthorizeZoomAppResponse>(urls.authorizeZoomApp);
  return data;
}

export async function createZoomMeeting({ classId, autoRecord, saveAsDefault }: CreateZoomMeetingPayload) {
  const payload: API.CreateZoomMeetingRequest = {
    classId,
    autoCloudRecord: autoRecord ? 1 : 0,
    updateAutoCloudRecordDefault: saveAsDefault ? 1 : 0,
  };
  const { data } = await request.post<API.CreateZoomMeetingResponse>(urls.createZoomMeeting, payload);
  return data;
}

export async function getZoomMeetingStatus(params: API.GetZoomMeetingStatusRequest) {
  await request.get(urls.getZoomMeetingStatus, {
    params,
    validateStatus: () => true, // ignore request errors
  });
}

export async function startZoomMeetingBroadcast(payload: API.StartZoomMeetingBroadcastRequest) {
  await request.put(urls.startZoomMeetingBroadcast, payload);
}

export async function getZoomMeetingDetails({ classId, courseId }: FetchZoomMeetingDetailsPayload) {
  const params: API.GetZoomMeetingDetailsRequest = { classId };
  const { data } = await request.get<API.GetZoomMeetingDetailsResponse>(urls.getZoomMeetingDetails, {
    params,
    headers: { courseId },
  });
  return data;
}

export async function getZoomMeetingCredentials(params: API.GetZoomMeetingCredentialsRequest) {
  const { data } = await request.get<API.GetZoomMeetingCredentialsResponse>(urls.getZoomMeetingCredentials, {
    params,
  });
  return data;
}

export async function registerZoomMeetingParticipant(payload: API.RegisterZoomMeetingParticipantRequest) {
  const { data } = await request.put<API.RegisterZoomMeetingParticipantResponse>(
    urls.registerZoomMeetingParticipant,
    payload
  );
  return data;
}

export async function attendingRemotely({ classId, courseId }: API.AttendingRemotelyRequest) {
  await request.post(urls.attendingRemotely, { classId }, { headers: { courseId } });
}

export async function raiseHand(payload: API.RaiseHandRequest) {
  const { data } = await request.post<API.RaiseHandResponse>(urls.raiseHand, payload);
  return data;
}

export async function lowerHand(payload: API.LowerHandRequest) {
  const { data } = await request.post<API.LowerHandResponse>(urls.lowerHand, payload);
  return data;
}

export async function addressHand(payload: API.AddressHandRequest) {
  const { data } = await request.post<API.AddressHandResponse>(urls.addressHand, payload);
  return data;
}

export async function resolveHand({ classId, userId, addressNext }: ResolveHandPayload) {
  const payload: API.ResolveHandRequest = {
    classId,
    userId,
    action: addressNext ? ResolveHandAction.NEXT : ResolveHandAction.RESOLVE,
  };
  const { data } = await request.post<API.ResolveHandResponse>(urls.resolveHand, payload);
  return data;
}

export async function unregisterZoomMeetingParticipant({
  courseId,
  classId,
  meetingId,
  zoomUserId,
}: UnregisterZoomMeetingParticipantPayload) {
  const payload: API.UnregisterZoomMeetingParticipantRequest = { classId, meetingId, zoomUserId };
  const { data } = await request.put<API.UnregisterZoomMeetingParticipantResponse>(
    urls.unregisterZoomMeetingParticipant,
    payload,
    { headers: { courseId } }
  );
  return data;
}

export async function endZoomMeetingBroadcast(payload: API.EndZoomMeetingBroadcastRequest) {
  await request.put(urls.endZoomMeetingBroadcast, payload);
}

export async function getClassRecordings({ classId }: FetchClassRecordingsPayload) {
  const { data } = await request.get<API.GetClassRecordingsResponse>(urls.getClassRecordings(classId));
  return data;
}

export async function publishClassRecordings(payload: API.PublishClassRecordingRequest) {
  const { data } = await request.put<API.PublishClassRecordingResponse>(urls.publishClassRecordings, payload);
  return data;
}

export async function deleteClassRecordings(payload: API.DeleteClassRecordingsRequest) {
  const { data } = await request.put<API.DeleteClassRecordingsResponse>(urls.deleteClassRecordings, payload);
  return data;
}

export async function startRemoteAttendance(payload: API.StartRemoteAttendanceRequest) {
  const { data } = await request.post<API.StartRemoteAttendanceResponse>(urls.startRemoteAttendance, payload);
  return data;
}

export async function stopRemoteAttendance(payload: API.StopRemoteAttendanceRequest) {
  const { data } = await request.post<API.StopRemoteAttendanceResponse>(urls.stopRemoteAttendance, payload);
  return data;
}

export async function markRemoteAttendance(payload: API.MarkRemoteAttendanceRequest) {
  const { data } = await request.post<API.MarkRemoteAttendanceResponse>(urls.markRemoteAttendance, payload);
  return data;
}
