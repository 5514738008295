import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { styled, Typography, TypographyProps } from '@mui/material';

import { HelperText as AcadlyHelperText } from '../../../../../../components/FormControls/TextFieldBase/styles';
import ToggleButton from '../../../../../../components/FormControls/ToggleButtonGroup/ToggleButton';
import { SelectionMode } from '../../../../../../components/FormControls/ToggleButtonGroup/types';
import ToggleButtonGroup from '../../../../../../components/HookFormControls/ToggleButtonGroup';
import { WeekDay } from '../../../../../../db/shared/types';
import { i18nNS } from '../../../../../../i18n';
import useWeekdaysVM, { Props as VMProps } from './vm';

const Label = styled((props: TypographyProps) => <Typography {...props} variant="paragraphBold" />)(
  ({ theme }) => ({
    marginBottom: theme.spacing(3),
  })
);

const HelperText = styled(AcadlyHelperText)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const RequiredIndicator = styled(({ className }: { className?: string }) => (
  <span className={className}>*</span>
))(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.error[600],
}));

interface Props<T extends FieldValues> extends VMProps<T> {
  className?: string;
}

const Weekdays = <T extends FieldValues>({ className, ...vmOptions }: Props<T>) => {
  const { t } = useTranslation([i18nNS.CREATE_COURSE]);

  const { control, controlName, error } = useWeekdaysVM(vmOptions);

  return (
    <>
      <Label>
        {t('class_will_happen_every', { ns: i18nNS.CREATE_COURSE })}
        <RequiredIndicator />
      </Label>
      <ToggleButtonGroup control={control} name={controlName} selectionMode={SelectionMode.MULTI}>
        <ToggleButton value={WeekDay.MON}>{WeekDay.MON}</ToggleButton>
        <ToggleButton value={WeekDay.TUE}>{WeekDay.TUE}</ToggleButton>
        <ToggleButton value={WeekDay.WED}>{WeekDay.WED}</ToggleButton>
        <ToggleButton value={WeekDay.THU}>{WeekDay.THU}</ToggleButton>
        <ToggleButton value={WeekDay.FRI}>{WeekDay.FRI}</ToggleButton>
        <ToggleButton value={WeekDay.SAT}>{WeekDay.SAT}</ToggleButton>
        <ToggleButton value={WeekDay.SUN}>{WeekDay.SUN}</ToggleButton>
      </ToggleButtonGroup>
      <HelperText error={Boolean(error)}>
        {error?.message || t('you_can_select_multiple_days', { ns: i18nNS.CREATE_COURSE })}
      </HelperText>
    </>
  );
};

export default Weekdays;
