import { Control, FieldValues, Path, PathValue, UnpackNestedValue, useController } from 'react-hook-form';

export interface Props<T extends FieldValues> {
  name: Path<T>;
  control: Control<T, object>;
  defaultValue?: UnpackNestedValue<PathValue<T, Path<T>>>;
}

const useNotificationTypeVM = <T extends FieldValues>({ control, name, defaultValue }: Props<T>) => {
  const controllerMethods = useController({ control, name, defaultValue });
  const {
    field: { onChange: onChangeShouldNotify, value: shouldNotify },
  } = controllerMethods;

  const handleToggleNotify = () => {
    onChangeShouldNotify(!shouldNotify);
  };

  return { handleToggleNotify, shouldNotify };
};

export default useNotificationTypeVM;
