import { useContext } from 'react';

import { rateComment } from '../../../../../../../../../../db/comments/actions';
import { useRequestDispatch } from '../../../../../../../../../../utils/request-actions';
import { CommentsContext } from '../../../../../../../../../Context';

export interface Props {
  commentId: MongoId;
}

const useRetractPointsVM = ({ commentId }: Props) => {
  const requestDispatch = useRequestDispatch();

  const { setAwardingCommentId } = useContext(CommentsContext);

  const handleRetractAwardPoints = async () => {
    await requestDispatch(rateComment, { commentId, points: 0 });
    setAwardingCommentId(null);
  };

  const handleCancel = () => {
    setAwardingCommentId(null);
  };

  return { handleRetractAwardPoints, handleCancel };
};

export default useRetractPointsVM;
