import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import Button from '../../../../../../../../../../components/Button';
import Slider from '../../../../../../../../../../components/HookFormControls/Slider';
import Tooltip from '../../../../../../../../../../components/Tooltip';
import { i18nNS } from '../../../../../../../../../../i18n';
import AwardIcon from '../../../../../../../../../../icons/AwardIcon';
import CheckmarkIcon from '../../../../../../../../../../icons/CheckmarkIcon';
import CloseIcon from '../../../../../../../../../../icons/CloseIcon';
import useAwardPointsVM, { Props as VMProps } from './vm';

interface Props extends VMProps {}

const MAX_AWARD_POINTS = 10;

const AwardPoints = ({ commentId, points }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const { formMethods, onSubmit, handleCancel } = useAwardPointsVM({ commentId, points });
  const { handleSubmit, control, formState, watch } = formMethods;
  const { isValid } = formState;
  const awardPoints = watch('awardPoints');

  return (
    <Stack direction="row" alignItems="center" gap={4}>
      <AwardIcon color="primary" fontSize="small" aria-hidden />
      <Stack
        flex={1}
        direction="row"
        alignItems="center"
        gap={4}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Slider
          control={control}
          name="awardPoints"
          step={1}
          min={0}
          max={MAX_AWARD_POINTS}
          valueLabelDisplay="auto"
          color="primary"
          aria-label="slide_to_give_award_points_to_this_post"
        />
        <Typography variant="paragraphRegular">{`${awardPoints}/${MAX_AWARD_POINTS}`}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={2}>
        <Tooltip title={!isValid ? '' : t('save', { ns: i18nNS.GLOSSARY })}>
          <Button
            variant="text"
            size="small"
            edge="end"
            disabled={!isValid}
            hideContentWhenLoading
            aria-label={t('click_to_award_count_points_to_this_students_post', {
              ns: i18nNS.COMMENTS,
              count: awardPoints,
            })}
            onClick={handleSubmit(onSubmit)}
          >
            <CheckmarkIcon color="inherit" fontSize="small" aria-hidden />
          </Button>
        </Tooltip>
        <Tooltip title={t('cancel', { ns: i18nNS.GLOSSARY })}>
          <Button
            variant="text"
            size="small"
            edge="end"
            aria-label={t('click_to_cancel_awarding_points_to_this_students_post', {
              ns: i18nNS.COMMENTS,
            })}
            onClick={handleCancel}
          >
            <CloseIcon color="inherit" fontSize="small" aria-hidden />
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default AwardPoints;
