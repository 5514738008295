import { forwardRef, Ref } from 'react';

import { styled } from '@mui/material';

import Button, { Props as ButtonProps } from '../../components/Button';

export const BackButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="outlined" {...props} ref={ref} />
  ))
)(({ theme }) => ({
  minWidth: 92,
}));

export const LoginButton = styled(
  forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => (
    <Button variant="contained" {...props} ref={ref} />
  ))
)(({ theme }) => ({
  flex: 1,
}));
