import { API } from '../shared/api-responses';
import { CourseUser } from '../shared/types';
import { Discussion, GeneratedWordCloud, SuggestedDiscussion } from './types';

export function createPreferencesFactory(
  publishDefaults: API.PublishDiscussionDefaults
): Discussion['preferences'] {
  return {
    toBeDone: publishDefaults.activityType,
    submitFirst: Boolean(publishDefaults.submitFirst),
    hideAwards: Boolean(publishDefaults.hideAwards),
    anonymize: Boolean(publishDefaults.anonymize),
    anonymity: publishDefaults.anonymity,
    subscribeToComments: Boolean(publishDefaults.subscribeToComments),
  };
}

interface CreateDiscussionOptions<T extends API.BaseDiscussion> {
  discussion: T;
  identifiers: { courseId: MongoId; classId: MongoId };
  publishDefaults: API.PublishDiscussionDefaults | undefined;
  userData: API.DiscussionUserData | undefined;
  currentUser: CourseUser | undefined;
}

export function createDiscussionFactory<T extends API.BaseDiscussion>({
  discussion,
  identifiers: { courseId, classId },
  publishDefaults,
  userData,
  currentUser,
}: CreateDiscussionOptions<T>): Discussion {
  const editedOn = discussion.details.lastEditedOn ?? -1;
  const isWordCloudGenerated = Boolean(discussion.details.wordCloudGenerated);

  return {
    id: discussion._id,
    classId,
    courseId,
    attachments: discussion.details.attachments || [],
    title: discussion.details.title || '',
    description: discussion.details.description,
    toBeDone: discussion.details.toBeDone,
    dueDateTime: -1,
    sequenceNum: discussion.details.trueNum ?? 0,
    totalSubmissions: discussion.activities?.numCommentsTotal ?? 0,
    hasParticipated: Boolean(userData?.submitted),
    wordCloud: {
      isAvailable: Boolean(discussion.details.wordCloudAvailable),
      isGenerated: isWordCloudGenerated,
      data: null,
    },
    preferences: publishDefaults
      ? createPreferencesFactory(publishDefaults)
      : {
          toBeDone: discussion.details.toBeDone,
          submitFirst: Boolean(discussion.details.submitFirst),
          hideAwards: Boolean(discussion.details.hideAwards),
          anonymize: Boolean(discussion.details.anonymize),
          anonymity: discussion.details.anonymity,
          subscribeToComments: Boolean(userData?.subscribed),
        },
    comments: {
      total: discussion.activities.numCommentsTotal,
      seen: userData?.numCommentsSeen ?? 0,
      isSubscribed: Boolean(userData?.subscribed),
    },
    editedOn,
    editedBy: editedOn > 0 ? discussion.details.createdBy : null,
    createdOn: discussion.details.createdOn,
    createdBy: discussion.details.createdBy,
    firstAccessedOn:
      currentUser?.userId === discussion.details.createdBy.userId
        ? discussion.details.createdOn
        : userData?.firstAccessedOn ?? -1,
    lastAccessedOn: userData?.lastAccessedOn ?? -1,
    publishedOn: discussion.details.publishedOn > 0 ? discussion.details.publishedOn : -1,
  };
}

interface SuggestedDiscussionOptions<T extends API.SuggestedDiscussion> {
  suggestedDiscussion: T;
}

export function suggestedDiscussionFactory<T extends API.SuggestedDiscussion>({
  suggestedDiscussion,
}: SuggestedDiscussionOptions<T>): SuggestedDiscussion {
  return {
    id: suggestedDiscussion._id,
    courseId: suggestedDiscussion.identifiers.courseId,
    courseCode: suggestedDiscussion.identifiers.courseCode,
    courseTitle: suggestedDiscussion.identifiers.courseTitle,
    title: suggestedDiscussion.details.title,
    sequenceNum: suggestedDiscussion.details.trueNum,
    isUsed: Boolean(suggestedDiscussion.used),
    isHidden: Boolean(suggestedDiscussion.hidden),
    createdOn: suggestedDiscussion.details.createdOn,
    createdBy: suggestedDiscussion.details.createdBy,
    publishedOn: suggestedDiscussion.details.publishedOn,
    description: suggestedDiscussion.details.description,
    attachments: suggestedDiscussion.details.attachments,
    toBeDone: suggestedDiscussion.details.toBeDone,
  };
}

export function wordCloudFactory({ wordList, ...data }: API.WordCloud): GeneratedWordCloud {
  return {
    isGenerated: true,
    data: {
      ...data,
      words: wordList.map(({ word, freq }) => ({ word, frequency: freq })),
    },
  };
}
