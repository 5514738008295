import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  ActionCreatorWithPreparedPayload as ActionCreator,
  AnyAction,
  createAction,
  Dispatch,
  Middleware,
} from '@reduxjs/toolkit';

import { randomStr } from './helpers';
import Logger from './logger';

interface RequestMeta {
  requestId: string;
}

type RequestActionCreator<P, M extends RequestMeta = RequestMeta> = ActionCreator<[P], P, string, never, M>;

type ResultActionCreator<P, M extends RequestMeta = RequestMeta> = ActionCreator<
  [requestId: string, payload: P],
  P,
  string,
  never,
  M
>;

interface RequestActionCreators<R = void, S = void, F = void> {
  request: RequestActionCreator<R>;
  success: ResultActionCreator<S>;
  failure: ResultActionCreator<F>;
}

const log = Logger.create('request-actions');

/**
 * Creates a set of redux actions for API request lifecycles
 * @param name action type namespace
 * @returns a map of three redux actions: `request`, `success` and `failure`
 */
export function createRequestAction<R = void, S = void, F = void>(
  name: string
): RequestActionCreators<R, S, F> {
  const prepareRequestAction = <T>(payload: T) => ({
    meta: { requestId: randomStr() },
    payload,
  });

  const prepareResultAction = <T>(requestId: string, payload: T) => ({
    meta: { requestId },
    payload,
  });

  return {
    /** Dispatched when API request is initiated */
    request: createAction(`${name}/request`, (payload: R) => prepareRequestAction(payload)),
    /** Dispatched when API request is succeded */
    success: createAction(`${name}/success`, (requestId: string, payload: S) =>
      prepareResultAction(requestId, payload)
    ),
    /** Dispatched when API request is failed */
    failure: createAction(`${name}/failure`, (requestId: string, payload: F) =>
      prepareResultAction(requestId, payload)
    ),
  };
}

interface RequestDispatch {
  <T extends RequestActionCreators<any, any, any>>(
    actions: T,
    payload: ReturnType<T['request']>['payload']
  ): Promise<ReturnType<T['success']>['payload']>;
  <T extends RequestActionCreators<void, any, any>>(actions: T): Promise<ReturnType<T['success']>['payload']>;
}

export function useRequestDispatch() {
  const _dispatch = useDispatch();

  const dispatch = useCallback<RequestDispatch>(
    (actions: RequestActionCreators<any, any, any>, ...args: any[]) =>
      Promise.resolve(_dispatch(actions.request(args[0]))),
    [_dispatch]
  );

  return dispatch;
}

type SuccessCallback = (payload?: any) => void;
type FailureCallback = (payload?: any) => void;

/**
 * Creates redux middleware which intercepts dispatched actions and
 * creates a promise if action type ends with `/request` and resolves
 * it later by the action type ending with `/success` or `/failure`
 * All three actions are mapped using `action.meta.requestId` key
 */
export function createRequestActionMiddleware<
  DispatchExt = {},
  S = any,
  D extends Dispatch = Dispatch
>(): Middleware<DispatchExt, S, D> {
  const pendingActions = new Map<string, { success: SuccessCallback; failure: FailureCallback }>();
  return () => (next) => (action: AnyAction) => {
    const actionType = action.type;
    if (typeof actionType !== 'string' || !action.meta?.requestId) return next(action);

    const requestId = action.meta.requestId;

    if (actionType.endsWith('/request')) {
      return new Promise((success, failure) => {
        pendingActions.set(action.meta.requestId, { success, failure });
        next(action);
      });
    }

    if (!pendingActions.has(requestId)) {
      log.warn(`success or failure callback not found for action(${action.type})`);
      return next(action);
    }

    const { success, failure } = pendingActions.get(requestId)!;

    if (actionType.endsWith('/success')) {
      next(action);
      success(action.payload);
      pendingActions.delete(requestId);
      return;
    }

    if (actionType.endsWith('/failure')) {
      next(action);
      failure(action.payload);
      pendingActions.delete(requestId);
      return;
    }

    return next(action);
  };
}
