const en = {
  click_to_view_poll_title_poll_description_poll: 'click to view {{pollTitle}} {{pollDescription}} poll',
  do_you_want_to_discard_changes_made_to_poll: 'Do you want to discard changes made to poll?',

  poll_num: 'Poll {{num}}',

  showing_all_polls: 'Showing all polls',
  no_polls_have_been_published_in_this_course: 'No polls have been published in the course',
  no_unpublished_polls_have_been_created_in_this_course:
    'No unpublished polls have been created in this course',
  no_polls_have_been_created_in_this_course: 'No polls have been created in this course',

  export_poll_data_as_csv: 'Export poll data as CSV',
  export_poll_data: 'Export Poll Data',
  this_data_is_only_available_for_polls_that_dont_allow_anonymous_responses:
    "This data is only available for polls that don't allow anonymous responses",
  once_the_students_respond_to_the_poll_the_identity_of_responders_and_their_choices_will_show_up_here:
    'Once the students respond to the poll, the identity of responders and their choices will show up here.',
  first_accessed_on_date: 'First accessed on {{date}}',
  please_add_a_poll_question_and_try_again: 'Please add a poll question and try again.',

  poll_type: 'Poll type',
  poll_analytics: 'Poll Analytics',
  click_to_open_poll_analytics: 'click to open poll analytics',
  click_to_close_poll_analytics: 'click to close poll analytics',
  click_to_export_poll_data_as_csv: 'click to export poll data as csv',

  poll_closes: 'Poll closes',
  poll_closed: 'Poll closed',
  poll_preferences: 'Poll preferences',
  publishing_poll: 'Publishing poll',
  poll_question: 'Poll question',
  poll_closes_in_time: 'Poll closes in {{time}}',
  student_response: 'Student response',
  click_to_edit_student_responses: 'click to edit student response',
  attempt_poll: 'Attempt poll',
  resume_poll: 'Resume poll',
  click_to_publish_poll: 'click to publish poll',
  click_to_save_poll_publish_preferences: 'click to save poll publish preferences',
  click_to_save_student_responses: 'Click to save student responses',
  click_to_not_save_student_responses: 'Click to not save student responses',
  click_to_select_student_response_as_not_anonymous: 'click to select student response as not anonymous',
  click_to_select_student_response_as_anonymous: 'click to select student response as anonymous',
  click_to_close_poll_pollendtime: 'click to close poll {{pollEndTime}}',

  poll_is_currently_open: 'Poll is currently open',
  students_wont_be_able_to_respond_to_the_poll_after_the_deadline:
    "Students won't be able to respond to the poll after the deadline.",
  students_will_see_the_poll_results_not_upon_submitting_a_response_but_after_the_poll_deadline_is_over:
    'Students will see the poll results not upon submitting a response but after the poll deadline is over.',
  students_will_see_their_scores_and_answers_as_soon_as_they_submit_their_responses:
    'Students will see their scores and answers as soon as they submit their responses.',
  use_same_publish_preferences_as_default_for_all_pre_class_polls:
    'Use same publish preferences as default for all pre-class polls',
  this_poll_doesn_t_have_a_question_yet_tap_on_the_edit_icon_to_edit_the_question_details_and_options:
    "This poll doesn't have a question yet. Tap on the <1></1> icon to edit the question details and options.",
  you_cannot_edit_the_poll_question_and_options_after_students_have_responded_to_the_poll:
    'You cannot edit the poll question and options after students have responded to the poll',
  poll_question_results_will_appear_after_deadline: 'Poll question (Results will appear after deadline)',
  are_you_sure_you_want_to_submit_this_poll: 'Are you sure you want to submit this poll?',
  this_poll_does_not_allow_late_submissions: 'This poll DOES NOT allow late submissions.',
  this_poll_has_been_updated_click_okay_to_reload_the_changes:
    'This poll has been updated. Click Okay to reload the changes.',

  click_to_save_poll_question: 'Click to save poll question',
  click_to_edit_poll_question: 'Click to edit poll question',
  click_submit_poll_option: 'Click submit poll option',
  click_to_edit_student_response_type: 'Click to edit student response type',
  click_to_edit_poll_closes_duration: 'Click to edit poll closes duration',
  click_to_stop_poll: 'Click to stop poll',
  click_to_cancel_publish_preference: 'Click to cancel publish preference',
  click_to_submit_poll: 'click to submit poll',
  click_to_not_submit_poll: 'click to not submit poll',

  /** comments panel */
  poll_discussion: 'Poll Discussion',
  export_poll_discussion_data_as: 'Export poll discussion data as',
  every_course_team_member_has_access_to_this_poll_discussion_thread_before_or_after_responding_to_the_poll:
    'Every course team member has access to this poll discussion thread, before or after responding to the poll.',

  /** onboarding tips */
  use_this_button_to_add_the_poll_question_you_can_add_images_too_as_poll_options_as_of_now_each_poll_can_have_only_one_question:
    'Use this button to add the poll question. You can add images too as poll options. As of now, each poll can have only one question.',
  tap_here_to_discuss_this_poll_with_the_class: 'Tap here to discuss this poll with the class.',
  in_case_the_course_members_need_to_discuss_the_poll_options_and_outcomes_they_can_use_the_poll_discussion_section_that_can_be_accessed_through_here:
    'In case the course members need to discuss the poll options and outcomes, they can use the poll discussion section that can be accessed through here',
  once_you_have_selected_an_option_use_the_submit_button_to_submit_your_response:
    'Once you have selected an option, use the Submit button to submit your response',
};

export default en;
