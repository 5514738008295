import { useRef, useState } from 'react';

import { dateTimeDiff, unix } from '../../../utils/datetime';
import { useInterval } from '../../../utils/timer';

const RESEND_VERIFICATION_CODE_TIMEOUT = 30; // seconds

export interface Props {
  onResendVerificationCode: () => any;
}

const useResendVerificationCodeVM = ({ onResendVerificationCode }: Props) => {
  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState(false);
  const verificationCodeSentAtRef = useRef<UnixTime | null>(null);
  const [timeLeftToAllowResendingVerificationCode, setTimeLeftToAllowResendingVerificationCode] =
    useState(-1);

  const { start, stop } = useInterval(() => {
    if (!verificationCodeSentAtRef.current) return;

    const diff =
      RESEND_VERIFICATION_CODE_TIMEOUT - dateTimeDiff(unix(), verificationCodeSentAtRef.current, 'seconds');
    if (diff >= 0) {
      setTimeLeftToAllowResendingVerificationCode(diff);
      return;
    }

    stop();
  }, null);

  const handleResendVerificationCode = async () => {
    try {
      setIsResendingVerificationCode(true);
      await onResendVerificationCode();
      verificationCodeSentAtRef.current = unix();
      setTimeLeftToAllowResendingVerificationCode(RESEND_VERIFICATION_CODE_TIMEOUT);
      start(1000);
    } finally {
      setIsResendingVerificationCode(false);
    }
  };

  return {
    isResendingVerificationCode,
    timeLeftToAllowResendingVerificationCode,
    handleResendVerificationCode,
  };
};

export default useResendVerificationCodeVM;
