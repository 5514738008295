import { styled } from '@mui/material';

import AcadlyTabs, { Props as AcadlyTabsProps } from '../../../components/Tabs';
import AcadlyTab from '../../../components/Tabs/Tab';

export const Tabs = styled((props: AcadlyTabsProps) => <AcadlyTabs variant="fullWidth" {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  })
);

export const Tab = styled(AcadlyTab)(({ theme }) => ({
  textTransform: 'unset',
}));
