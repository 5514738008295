import { useMediaQuery } from '@mui/material';

import { forMobile } from '../../../utils/media-queries';
import { useCreateCourseContext } from '../../Context';
import { getCreateCourseProgress } from './helpers';

export default function useCreateCourseHeaderVM() {
  const isMobile = useMediaQuery(forMobile);
  const { activeStep, title: nonMobileTitle, mobileTitle } = useCreateCourseContext();

  const title = isMobile ? mobileTitle : nonMobileTitle;

  const progress = getCreateCourseProgress({ activeStep });

  return { progress, title };
}
