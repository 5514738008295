import { styled } from '@mui/material';

import AlertButton from '../../../components/AlertButton';
import Button from '../../../components/Button';
import { forMobile } from '../../../utils/media-queries';

export const CancelButton = styled(Button)(({ theme }) => ({
  [forMobile(theme)]: {
    minWidth: 92,
  },
}));

export const SaveButton = styled(AlertButton)(({ theme }) => ({
  minWidth: 100,
  [forMobile(theme)]: {
    flex: 1,
  },
}));
