import { KeyboardEvent, KeyboardEventHandler } from 'react';

export interface Props {
  onEnterKeyPress?: () => any;
  onKeyPress?: KeyboardEventHandler;
}

const useTextFieldVM = ({ onEnterKeyPress, onKeyPress }: Props) => {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') onEnterKeyPress?.();
    onKeyPress?.(event);
  };

  return { handleKeyPress };
};

export default useTextFieldVM;
