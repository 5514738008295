import { ChangeEventHandler, MouseEventHandler, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Upload as UploadIcon } from '@mui/icons-material';
import { Button, Dialog, DialogContent, Stack, styled } from '@mui/material';

import clsx from 'clsx';

import { GetPreSignedURLRequestConfig, PreSignedURLResponse } from '../db/shared/types';
import { i18nNS } from '../i18n';
import { generateClasses, randomStr } from '../utils/helpers';
import { useFileUpload } from '../utils/hooks';
import Progressbar from './Progressbar';
import Typography from './Typography';

type FileData = Omit<PreSignedURLResponse, 'contentType'> & { originalName: string };

interface VMProps {
  /**
   * Acceptable file formats
   * @example
   * ['image/jpg', 'image/jpeg', '.doc', '.docx']
   */
  accept: string | string[];
  /**
   * Request payload to fetch S3's pre-signed upload url and
   * file's meta-data
   */
  requestConfig: GetPreSignedURLRequestConfig;
  /**
   * Called after file has been successfully uploaded to S3, if returned
   * promise, then progress bar will hide when the promise is resolved
   */
  onUpload?: (fileData: FileData) => any;
  /** Called when file upload is failed */
  onUploadError?: (error: unknown) => any;
}

export const uploadButtonClasses = generateClasses('UploadButton', ['root', 'input']);

const Root = styled('label')(({ theme }) => ({
  display: 'inline-block',

  [`& .${uploadButtonClasses.input}`]: {
    display: 'none',
  },
}));

const StyledProgressBar = styled(Progressbar)(({ theme }) => ({
  width: 200,
}));

const useUploadButtonVM = ({ accept: _accept, requestConfig, onUpload, onUploadError }: VMProps) => {
  const inputId = useMemo(() => randomStr(), []);
  const accept = useMemo(() => (typeof _accept === 'string' ? _accept : _accept.join()), [_accept]);

  const { isUploading, onUpload: _onUpload, progress } = useFileUpload({ requestConfig });

  const progressPercentage = useMemo(() => {
    if (progress.total <= 0 || progress.uploaded <= 0) return 0;
    return Math.round((progress.uploaded * 100) / progress.total);
  }, [progress.uploaded, progress.total]);

  const handleUpload = async (file: File) => {
    const response = await _onUpload(file);
    if ('error' in response) onUploadError?.(response.error);
    else onUpload?.(response);
  };

  return {
    accept,
    inputId,
    isUploading,
    onUpload: handleUpload,
    isIndeterminate: progress.isIndeterminate,
    progressPercentage,
  };
};

export interface Props extends VMProps {
  id?: string;
  className?: string;
  children?: ReactNode;
  classes?: Partial<typeof uploadButtonClasses>;
}

const UploadButton = ({ id, className, children, ...vmProps }: Props) => {
  const { t } = useTranslation(i18nNS.GLOSSARY);

  const { accept, inputId, isUploading, onUpload, isIndeterminate, progressPercentage } =
    useUploadButtonVM(vmProps);

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { files } = event.target;
    if (!files) return;

    const file = files?.[0];
    if (!file) return;

    onUpload(file);
  };

  const onFileInputClick: MouseEventHandler<HTMLInputElement> = (e) => {
    (e.target as any).value = '';
  };

  return (
    <>
      <Root htmlFor={id || inputId} className={clsx(className, uploadButtonClasses.root)}>
        <input
          type="file"
          id={id || inputId}
          accept={accept}
          className={uploadButtonClasses.input}
          onClick={onFileInputClick}
          onChange={handleFileSelect}
        />
        {children || (
          <Button component="span" startIcon={<UploadIcon />}>
            {t('upload', { ns: i18nNS.GLOSSARY })}
          </Button>
        )}
      </Root>
      <Dialog open={isUploading}>
        <DialogContent>
          <Stack direction="row" alignItems="center" gap={2}>
            <StyledProgressBar
              shape="linear"
              variant={isIndeterminate ? 'indeterminate' : 'determinate'}
              value={progressPercentage}
            />
            <Typography variant="paragraphRegular" color="grey.800">
              {`${progressPercentage}%`}
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadButton;
