const en = {
  create_a_new_course: 'Create a new course',
  click_to_create_a_new_course: 'click to create a new course',
  click_to_join_an_existing_course: 'click to join an existing course',

  course_preview: 'Course preview',

  university_name_is_an_enterprise_customer_of_acadly_which_means_you_can_import_your_course_from_integrated_with_using_the_acadly_lti_plugin:
    '{{universityName}} is an Enterprise customer of Acadly, which means you can import your course from {{integratedWith}} using the Acadly LTI Plugin.',
  courses_created_using_the_acadly_lti_plugin_allow: 'Courses created using the Acadly LTI Plugin allow',
  enrollment_syncing: 'Enrollment syncing',
  exporting_grades_from_acadly_to_integrated_with: 'Exporting grades from Acadly to {{integratedWith}}',
  access_to_all_the_features_available_with_acadly_pro_including_zoom_integration_for_remote_lectures:
    'Access to all the features available with Acadly Pro, including Zoom integration for remote lectures',
  this_was_for_your_information: 'This was for your information.',
  if_you_want_to_create_a_course_that_is_not_linked_please_click_on_continue:
    'If you want to create a course that is not linked, please click on "Continue"',

  /** introduction step */
  you_can_create_two_kinds_of_courses_in_acadly: 'You can create two kinds of courses in Acadly',
  acadly_pro_course: 'Acadly Pro Course',
  get_access_to_all_acadly_features_except_lms_integration_only_available_for_acadly_enterprise_customers:
    'Get access to all Acadly features except LMS integration (only available for <2>Acadly Enterprise</2> customers)',
  download_excel_reports_with_students_email_addresses_for_easy_data_imports_to_the_lms_gradebook:
    "Download Excel reports with students' email addresses for easy data imports to the LMS gradebook",
  schedule_auto_attendance_for_click_free_attendance_tracking:
    'Schedule auto-attendance for click-free attendance tracking',
  anonymise_students_discussion_responses_to_encourage_participation_in_discussions: `Anonymise students' discussion responses to encourage participation in discussions`,
  create_quizzes_with_true_false_multiple_choice_and_sorting_ordering_questions:
    'Create quizzes with True-False, Multiple-Choice, and Sorting-Ordering questions',
  get_zoom_integration_to_teach_online_and_hybrid_classes_learn_more_about_our_zoom_enabled_features_here:
    'Get Zoom integration to teach online and hybrid classes. Learn more about our Zoom-enabled features <2>here.</2>',
  acadly_basic_course: 'Acadly Basic Course',
  all_acadly_basic_features_are_available_in_pro_courses_as_well:
    'All Acadly Basic features are available in Pro courses as well',
  record_in_person_attendance_automatically_at_the_tap_of_a_button:
    'Record in-person attendance automatically, at the tap of a button',
  share_in_class_activities_like_polls_quizzes_discussions_and_word_clouds_to_engage_students:
    'Share in-class activities like polls, quizzes, discussions, and word clouds to engage students',
  share_pre_class_activities_to_keep_students_engaged_round_the_clock: `Share pre-class activities to keep students engaged 'round the clock`,
  download_excel_reports_of_participation_and_attendance_reports_do_not_include_students_email_addresses: `Download Excel reports of participation and attendance (reports do not include students' email addresses)`,
  share_quizzes_with_true_false_and_multiple_choice_questions_sorting_ordering_questions_are_not_included:
    'Share quizzes with True-False and Multiple-Choice questions. Sorting-Ordering questions are not included.',

  /** payment-method step */
  choose_course_type: 'Choose course type',
  create_an_acadly_pro_course: 'Create an Acadly Pro course',
  click_to_create_an_acadly_pro_course: 'click to create an Acadly Pro Course',
  create_an_acadly_basic_course: 'Create an Acadly Basic course',
  click_to_create_an_acadly_basic_course: 'click to create an Acadly Basic Course',
  choose_payment_method: 'Choose payment method',
  use_the_free_course: 'Use the free course',
  click_to_select_option_use_the_free_course: 'click to select option use the free course',
  free_no_credit_card_required: 'FREE. No credit card required.',
  use_a_purchased_course: 'Use a purchased course',
  click_to_select_option_use_a_purchased_course: 'click to select option use a purchased course',
  from_an_earlier_purchase: 'From an earlier purchase',
  purchase_a_new_course_students_pay: 'Purchase a new course - Students pay',
  click_to_select_option_purchase_a_new_course_students_pay:
    'click to select option purchase a new course students pay',
  price_per_student_per_course_free_for_instructors: '{{price}} per student per course, free for instructors',
  purchase_a_new_course_instructor_pays: 'Purchase a new course - Instructor pays',
  click_to_select_option_purchase_a_new_course_instructor_pays:
    'click to select option purchase a new course instructor pays',
  free_for_students: 'Free for students',
  after_a_N_day_free_trial_every_student_enrolled_in_the_course_will_have_to_pay_M_to_continue_accessing_the_course_material_and_activities_on_acadly:
    'After a {{numFreeTrialDays}} day free trial, every student enrolled in the course will have to pay {{amountWithCurrency}} to continue accessing the course material and activities on Acadly',
  costs_will_be_calculated_in_the_next_step_based_on_the_number_of_courses_and_students_enrolled_starts_at_N_per_student:
    'Costs will be calculated in the next step, based on the number of courses and students enrolled. Starts at {{amountWithCurrency}} per student',

  /** cost calculator step */
  purchase_new_courses: 'Purchase new courses',
  cost_calculator: 'Cost calculator',
  course_type: 'Course type',
  number_of_courses_required: 'Number of courses required',
  expected_number_of_enrolled_students_across_all_courses:
    'Expected number of enrolled students across all courses',
  example_if_you_are_purchasing_N_courses_and_expect_around_M_students_per_course_then_the_total_number_of_students_will_be_R:
    'Example - If you are purchasing {{numCourses}} courses and expect around {{numStudents}} students per course, then the total number of students will be {{range}}',
  custom_pricing: 'Custom pricing',
  send_pricing_request: 'Send pricing request',

  /** payment details */
  enter_payment_details: 'Enter payment details',

  /** payment success */
  payment_success: 'Payment success',
  your_purchase_of_an_acadly_basic_course_is_successful_you_can_either_create_the_course_now_or_any_time_later_using_the_create_a_new_course_button_the_purchased_course_will_be_available_to_use_and_you_will_not_have_to_purchase_again:
    '<0>Your purchase of an Acadly Basic Course</0> is successful. You can either create the course now or any time later using the "Create a new course" button. The purchased course will be available to use and you will not have to purchase again.',
  your_purchase_of_an_acadly_pro_course_is_successful_you_can_either_create_the_course_now_or_any_time_later_using_the_create_a_new_course_button_the_purchased_course_will_be_available_to_use_and_you_will_not_have_to_purchase_again:
    '<0>Your purchase of an Acadly Pro Course</0> is successful. You can either create the course now or any time later using the "Create a new course" button. The purchased course will be available to use and you will not have to purchase again.',
  create_acadly_basic_course: 'Create Acadly Basic Course',
  create_acadly_pro_course: 'Create Acadly Pro Course',
  create_later: 'Create later',

  /** payment failed */
  payment_failed: 'Payment failed',

  /** pricing-inquiry step */
  send_pricing_inquiry: 'Send pricing inquiry',
  how_many_courses_are_you_teaching_in_spring_2023: 'How many courses are you teaching in Spring 2023?',
  how_many_students_will_be_enrolled_in_your_courses_in_spring_2023_total:
    'How many students will be enrolled in your courses in Spring 2023 (total)?',
  when_do_you_teach_your_first_class: 'When do you teach your first class?',
  what_is_your_phone_number: 'What is your phone number?',

  /** pricing-inquiry-thanks step */
  we_will_reach_out_to_you_with_our_instructor_pays_pricing_and_payment_information_via_email_in_the_next_24_hours:
    'We will reach out to you with our instructor-pays pricing and payment information via email in the next 24 hours.',
  please_send_us_an_email_at_support_email_if_you_need_to_expedite_this_process:
    'Please send us an email at <1>support@acadly.com</1> if you need to expedite this process.',

  new_course: 'New course',
  adding_a_new_acadly_course: 'Adding a new Acadly course',
  new_pro_course: 'New pro course',
  adding_a_new_acadly_pro_course: 'Adding a new Acadly Pro Course',
  new_basic_course: 'New basic course',
  adding_a_new_acadly_basic_course: 'Adding a new Acadly Basic Course',
  available_in_pro_courses_only: 'Available in pro courses only',

  step_1_colon_provide_basic_details: 'Step 1: Provide basic details',
  course_code: 'Course code',
  course_title: 'Course title',
  allowed_characters_a_hyphen_z_0_hyphen_9_dot_hyphen_underscore: 'Allowed characters A-Z 0-9.-_',
  a_course_s_name_and_course_code_cannot_be_changed_later_please_verify_before_you_proceed:
    'A course’s name and course code cannot be changed later. Please verify before you proceed.',

  step_1_dot_1_colon_initialize_course: 'Step 1.1: Initialize course',
  create_a_course_from_scratch_or_select_a_course_to_copy_from:
    'Create a course from scratch or select a course to copy from',
  you_have_N_pro_course_slot_available: 'You have {{count}} pro course slot available',
  create_a_course_from_scratch: 'Create a course from scratch',
  click_to_create_a_course_from_scratch: 'click to create a course from scratch',
  select_a_past_course_as_blueprint: 'Select a past course as blueprint',
  a_pro_slot_is_considered_utilized_once_you_publish_the_course:
    'A pro slot is considered utilized once you publish the course',

  step_1_dot_2_colon_initialize_course_hyphen_select_content_to_copy:
    'Step 1.2: Initialize course - Select content to copy',
  copy_course_description: 'Copy course description',
  click_to_select_copy_course_description: 'click to select copy course description',
  this_course_has_no_description_to_copy: 'This course has no description to copy',
  copy_reading_list: 'Copy reading list',
  click_to_select_copy_reading_list: 'click to select copy reading list',
  this_course_has_no_books_to_copy: 'This course has no books to copy',
  this_course_has_no_links_to_copy: 'This course has no links to copy',
  this_course_has_no_files_to_copy: 'This course has no files to copy',
  copy_course_topics: 'Copy course topics',
  click_to_select_copy_course_topics: 'click to select copy course topics',
  this_course_has_no_topics_to_copy: 'This course has no topics to copy',
  copy_course_syllabus: 'Copy course syllabus',
  click_to_select_copy_course_syllabus: 'click to select copy course syllabus',
  this_course_has_no_syllabus_file_to_copy: 'This course has no syllabus file to copy',
  copying_course_activities: 'Copying course activities',
  when_a_course_is_copied_acadly_will_use_its_activities_to_intelligently_make_suggestions_for_class_activities_in_the_new_course:
    'When a course is copied, Acadly will use its activities to intelligently make suggestions for class activities in the new course.',
  published_quizzes: 'Published quizzes',
  published_polls: 'Published polls',
  published_resources: 'Published resources',
  published_discussions: 'Published discussions',

  step_2_colon_define_the_course_schedule: 'Step 2: Define the course schedule',
  select_a_course_timezone: 'Select a course timezone',
  click_to_select_a_course_timezone: 'click to select a course timezone',
  selected_timezone_click_to_change_selection: 'selected {{timezone}} click to change selection',
  select_course_timezone: 'Select course timezone',
  timezone_campus: '{{timezone}} Campus',
  click_to_select_timezone_campus: 'click to select {{timezone}} Campus',
  select_your_course_timezone: 'Select your course timezone',
  select_a_your_course_timezone_first: 'Select a your course timezone first',
  selected_start_date_date_click_to_change_selected_start_date:
    'selected start date {{date}} click to change selected start date',
  click_to_select_start_date: 'click to select start date',
  selected_end_date_date_click_to_change_selected_end_date:
    'selected end date {{date}} click to change selected end date',
  click_to_select_end_date: 'click to select end date',
  start_time_to_end_time: '{{startTime}} to {{endTime}}',
  click_to_delete_class_scheduled_on_day_start_time_end_time:
    'click to delete class scheduled on {{day}}, {{startTime}} to {{endTime}}',
  weekly_schedule: 'Weekly Schedule',
  adding_weekly_class: 'Adding weekly class',
  add_weekly_meeting_schedule: 'Add weekly meeting schedule',
  a_weekly_schedule_automatically_creates_your_course_timeline_with_the_scheduled_classes:
    'A weekly schedule automatically creates your course timeline with the scheduled classes.',
  if_you_do_not_specify_a_weekly_session_schedule_you_will_have_to_create_each_class_manually:
    'If you do not specify a weekly session schedule, you will have to create each class manually.',
  class_will_happen_every: 'Class will happen every',
  no_day_selected: 'No day selected',
  you_can_select_multiple_days: 'You can select multiple days',
  click_to_select_day: 'click to select {{day}}',
  click_to_deselect_day: 'click to deselect {{day}}',
  click_to_save_and_create_classes: 'click_to_save_and_create_classes',
  are_you_sure_you_want_to_delete_this_class: 'Are you sure you want to delete this class?',
  click_to_delete_this_class: 'click to delete this class',

  step_3_colon_add_course_team_members: 'Step 3: Add course team members',
  enter_instructor_s_valid_institute_email_address: 'Enter instructor’s valid institute email address',
  add_course_teaching_assistants: 'Add course teaching assistants',
  enter_ta_s_valid_institute_email_address: 'Enter TA’s valid institute email address',
  learn_what_course_teaching_assistant_can_do: 'Learn what course teaching assistant can do',
  course_instructor: 'Course Instructor',
  course_teaching_assistant: 'Course Teaching Assistant',
  click_to_add_course_instructor: 'click to add course instructor',
  click_to_add_teaching_assistant: 'click to add teaching assistant',
  click_to_add_role_label: 'click to add {{roleLabel}}',
  learn_what_course_instructor_can_do: 'Learn what course instructor can do',
  this_is_an_optional_step_you_can_add_the_team_members_later_as_well:
    'This is an optional step. You can add the team members later as well.',
  be_made_a_class_in_charge_or_class_assistant_for_class_meetings:
    'Be made a "Class In-charge" or "Class Assistant" for class meetings',
  create_activities_announcements_assignments: 'Create activities, announcements, assignments',
  access_unpublished_activities: 'Access unpublished activities',
  remove_comments_by_students: 'Remove comments by students',
  take_attendance_if_they_are_part_of_the_team_for_a_class_meeting:
    'Take attendance if they are part of the team for a class meeting',
  reward_offline_class_participation_points_if_they_are_part_of_the_team_for_a_class_meeting:
    'Reward offline class participation points if they are part of the team for a class meeting',
  add_or_remove_students: 'Add or remove students',
  invite_course_collaborators: 'Invite course collaborators',
  edit_course_schedule_or_syllabus_topics: 'Edit course schedule or syllabus topics',
  select_a_class_in_charge: 'Select a Class In-charge',
  grade_assignments: 'Grade assignments',
  be_made_class_assistant_for_class_meetings: 'Be made "Class Assistant" for class meetings',
  be_made_class_in_charge_for_class_meetings: 'Be made "Class In-Charge" for class meetings',
  create_activities_announcement_assignments: 'Create activities, announcement, assignments',
  edit_the_course_schedule_or_syllabus_topics: 'Edit the course schedule or syllabus topics',
  click_to_delete_role_label_team_member_name: 'click to delete {{roleLabel}} {{teamMemberName}}',

  step_4_colon_select_student_enrollment_method: 'Step 4: Select student enrollment method',
  enrollment_by_invitation: 'Enrollment by invitation',
  click_to_enroll_by_invitation: 'click to enroll by invitation',
  once_the_course_is_published_you_will_be_able_to_enroll_students_by_providing_their_email_addresses_allows_for_stricter_control_over_who_can_access_the_course_content:
    'Once the course is published, you will be able to enroll students by providing their email addresses. Allows for stricter control over who can access the course content.',
  enrollment_by_code: 'Enrollment by code',
  click_to_enroll_by_code: 'click to enroll by code',
  after_the_course_is_published_a_join_code_is_generated_that_can_be_used_by_students_to_enroll_in_the_course_however_anyone_with_the_join_code_will_be_able_to_access_the_course_content_as_a_student:
    'After the course is published, a Join Code is generated that can be used by students to enroll in the course. However, anyone with the Join Code will be able to access the course content as a student',
  the_enrollment_method_you_select_cannot_be_changed_later_please_check_before_proceeding:
    'The enrollment method you select cannot be changed later. Please check before proceeding',
  course_successfully_created: 'Course Successfully Created',

  click_to_go_to_next_step: 'click to go to next step',
  click_to_close_create_course_drawer: 'click to close create course drawer',
};

export default en;
