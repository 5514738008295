import { RootState } from '../../store/types';
import {
  ActivityStatsContainer,
  addActivityStats,
  getIsActivityPublished,
  getUnseenComments,
} from '../../utils/activities';
import { getActivityById, getFullId } from '../shared/helpers';
import { ClassRole, ClassUser, TimelineItemType } from '../shared/types';
import { classActivityComparator } from './helpers';
import { Class } from './types';

export const selectFullClassId = (shortId: ShortId | MongoId) => (state: RootState) => {
  const { classes } = state.db;
  return getFullId(classes, shortId);
};

export const selectClass = (id: ShortId | MongoId) => (state: RootState) => {
  const { classes } = state.db;
  return getActivityById(classes, id);
};

export const selectClasses = (courseId: ShortId | MongoId) => (state: RootState) => {
  const course = getActivityById(state.db.courses, courseId);
  const classes: Class[] = [];

  if (!course?.timeline) return classes;

  for (const item of course.timeline) {
    if (item.type !== TimelineItemType.CLASS) continue;
    const cls = getActivityById(state.db.classes, item.id);
    if (!cls) continue;
    classes.push(cls);
  }

  return classes;
};

/**
 * Selects class user, if classId is not specified
 * then current class will be used
 * @param classId optional short or full class-id
 */
export const selectClassUser = (classId: ShortId | MongoId) => (state: RootState) => {
  const session = state.auth.session;
  if (!session) return null;

  const cls = selectClass(classId)(state);
  if (!cls) return null;

  const user: ClassUser = {
    userId: session.userId,
    avatar: session.avatar,
    name: session.name,
    role: cls.myRole,
  };

  return user;
};

export const selectAllParticipationLabels = (state: RootState) => {
  const { classes } = state.db;
  return classes.allParticipationLabels;
};

export const selectUnseenComments = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return 0;

  const clsUnseenComments = getUnseenComments(cls);

  const { inClass, preClass, review } = cls.activities;

  const stats = addActivityStats<ActivityStatsContainer>(
    ...Object.values(inClass),
    ...Object.values(preClass),
    ...Object.values(review)
  );

  const clsActivitiesUnseenComments = getUnseenComments(stats);

  return clsUnseenComments + clsActivitiesUnseenComments;
};

export const selectUnseenQueries = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return 0;

  const { inClass, preClass, review } = cls.activities;
  const stats = addActivityStats(inClass.queries, preClass.queries, review.queries);

  return Math.max(0, stats.total - stats.seen);
};

export const selectDueActivities = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return 0;

  const { inClass, preClass } = cls.activities;

  if (cls.myRole === ClassRole.STUDENT) {
    const stats = addActivityStats(
      inClass.quizzes,
      inClass.polls,

      preClass.quizzes,
      preClass.polls
    );

    return Math.max(0, stats.total - stats.completed);
  }

  const stats = addActivityStats(
    inClass.quizzes,
    inClass.polls,
    inClass.discussions,
    inClass.resources,

    preClass.quizzes,
    preClass.polls,
    preClass.discussions,
    preClass.resources
  );

  return Math.max(0, stats.total - stats.published);
};

export const selectSuggestedActivities = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);
  return cls?.suggestedActivities || [];
};

export const selectPreClassActivities = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return [];

  const { quizzes, polls, discussions, queries, resources } = cls.activities.preClass;

  if (cls.myRole === ClassRole.STUDENT) {
    return [
      ...quizzes.items.filter(getIsActivityPublished),
      ...polls.items.filter(getIsActivityPublished),
      ...discussions.items.filter(getIsActivityPublished),
      ...queries.items.filter(getIsActivityPublished),
      ...resources.items.filter(getIsActivityPublished),
    ].sort(classActivityComparator);
  }

  return [...quizzes.items, ...polls.items, ...discussions.items, ...queries.items, ...resources.items].sort(
    classActivityComparator
  );
};

export const selectInClassActivities = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return [];

  const { quizzes, polls, discussions, queries, resources } = cls.activities.inClass;

  if (cls.myRole === ClassRole.STUDENT) {
    return [
      ...quizzes.items.filter(getIsActivityPublished),
      ...polls.items.filter(getIsActivityPublished),
      ...discussions.items.filter(getIsActivityPublished),
      ...queries.items.filter(getIsActivityPublished),
      ...resources.items.filter(getIsActivityPublished),
    ].sort(classActivityComparator);
  }

  return [...quizzes.items, ...polls.items, ...discussions.items, ...queries.items, ...resources.items].sort(
    classActivityComparator
  );
};

export const selectReviewActivities = (id: ShortId | MongoId) => (state: RootState) => {
  const cls = selectClass(id)(state);

  if (!cls) return [];

  const { queries } = cls.activities.review;

  if (cls.myRole === ClassRole.STUDENT) {
    return queries.items.filter(getIsActivityPublished).sort(classActivityComparator);
  }

  return [...queries.items].sort(classActivityComparator);
};

export const selectAttendanceInProgress = (state: RootState) => {
  const { classes } = state.db;
  return classes.attendanceInProgress;
};

export const selectAttendanceScheduleToStart = (state: RootState) => {
  const { classes } = state.db;
  return classes.attendanceScheduleToStartFor;
};
