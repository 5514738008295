import { RootState } from '../../store/types';
import { CreateCoursePermission } from '../types';

export const selectAuthSession = (state: RootState) => state.auth.session;

export const selectIsProvisionalAccount = (state: RootState) => {
  const { session } = state.auth;
  if (!session) return false;
  return session.canCreateCourses === CreateCoursePermission.PROVISIONAL;
};
