import { createPusherEvent } from '../../pusher/helpers';
import { ClassPusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';
import { WithCurrentUser } from '../shared/types';

export type ResourcePublishedEvent = ClassPusherEvent<{
  classId: MongoId;
  resourceId: MongoId;
  details: API.BaseResource['details'];
  activities: API.BaseResource['activities'];
  stats: API.BaseResource['stats'];
}>;

export type ResourcePublishedPayload = WithCurrentUser<
  ResourcePublishedEvent & {
    /** Indicates whether resource data exists in our redux state */
    doesExist: boolean;
  }
>;

export const resourcePublishedEvent = createPusherEvent<ResourcePublishedEvent>(
  PusherEventName.RESOURCE_PUBLISHED,
  { channels: [PusherChannel.COURSE] }
);
