import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { createCourse } from '../../../db/courses/actions';
import i18n, { i18nNS } from '../../../i18n';
import { useRequestDispatch } from '../../../utils/request-actions';
import { useCreateCourseContext } from '../../Context';
import { CreateCourseStep } from '../../types';
import { FormValues } from './types';
import validationSchema from './validators';

const useBasicDetailsVM = () => {
  const requestDispatch = useRequestDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    activeStep,
    closeDrawer,
    isCreatingProCourse,
    paymentMethodSlotId,
    canMoveToPreviousStep,
    moveToPreviousStep: _moveToPreviousStep,
    setCourseId,
    setTitle,
    setMobileTitle,
  } = useCreateCourseContext();

  const isVisible = activeStep === CreateCourseStep.BASIC_DETAILS;

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      courseCode: '',
      courseTitle: '',
    },
    mode: 'all',
  });

  const moveToPreviousStep = () => _moveToPreviousStep({ skip: 6 });

  const handleCreateCourse = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true);
      const { courseId } = await requestDispatch(createCourse, {
        isPro: Boolean(isCreatingProCourse),
        code: data.courseCode,
        title: data.courseTitle,
        slotId: paymentMethodSlotId,
      });
      setCourseId(courseId);
    } catch (error) {
      setIsSubmitting(false);
    }
  });

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      isCreatingProCourse
        ? i18n.t('adding_a_new_acadly_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('adding_a_new_acadly_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
    setMobileTitle(
      isCreatingProCourse
        ? i18n.t('new_pro_course', { ns: i18nNS.CREATE_COURSE })
        : i18n.t('new_basic_course', { ns: i18nNS.CREATE_COURSE })
    );
  }, [isCreatingProCourse, isVisible, setMobileTitle, setTitle]);

  return {
    closeDrawer,
    control,
    handleCreateCourse,
    isVisible,
    isSubmitting,
    canMoveToPreviousStep,
    moveToPreviousStep,
  };
};

export default useBasicDetailsVM;
