import i18next from 'i18next';

import { DateWidgetVariant } from '../components/DateWidget';
import { CourseWidgetData } from '../courses/types';
import { Assignment, AssignmentQuestion, StudentAssignmentData } from '../db/assignments/types';
import { Course } from '../db/courses/types';
import {
  AssignmentSubContext,
  CourseRole,
  SubmissionStatus,
  TipStatusKey,
  UserWithRole,
} from '../db/shared/types';
import i18n, { i18nNS } from '../i18n';
import {
  getIsActivityPublished,
  getIsDeadlineNear,
  getIsDeadlinePast,
  getUnseenComments,
} from '../utils/activities';
import { format, unix } from '../utils/datetime';
import { AssignmentWidgetData } from './types';

export function getAssignmentNewComments(
  assignment: Pick<Assignment, 'studentDataById' | 'comments'>,
  courseRole: CourseRole,
  studentId: MongoId
) {
  const isStudent = courseRole === CourseRole.STUDENT;
  const studentData = assignment.studentDataById[studentId];
  const isAttempting = studentData?.status === SubmissionStatus.IN_PROGRESS;
  return isStudent && isAttempting
    ? assignment.comments.preSub.total - assignment.comments.preSub.seen
    : getUnseenComments(assignment);
}

export function getIsUnattemptedAssignment(
  assignment: Pick<Assignment, 'studentDataById'>,
  courseRole: CourseRole,
  studentId: MongoId
) {
  if (courseRole !== CourseRole.STUDENT) return false;
  const studentData = assignment.studentDataById[studentId];
  if (!studentData) return true;
  return studentData.status === SubmissionStatus.NOT_ATTEMPTED;
}

interface StudentDataByIdContainer {
  studentDataById: Assignment['studentDataById'];
}

export function getIsAssignmentSubmitted<T extends StudentDataByIdContainer>(
  assignment: T,
  studentId: MongoId
) {
  const studentData = assignment.studentDataById[studentId];
  if (!studentData) return false;
  return [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(studentData.status);
}

interface PreferencesContainer {
  preferences: Assignment['preferences'];
}

export function getCanSubmitAssignment<T extends PreferencesContainer & StudentDataByIdContainer>(
  assignment: T,
  studentId: MongoId
) {
  const isAssignmentSubmitted = getIsAssignmentSubmitted(assignment, studentId);
  if (isAssignmentSubmitted) return false;
  return assignment.preferences.allowLate || !getIsDeadlinePast(assignment.preferences.dueDateTime);
}

export function getIsAssignmentEdited(
  assignment: Pick<Assignment, 'studentDataById' | 'editedOn' | 'lastAccessedOn'>,
  studentId: MongoId
) {
  const isAssignmentSubmitted = getIsAssignmentSubmitted(assignment, studentId);
  if (isAssignmentSubmitted) return false;

  const studentData = assignment.studentDataById[studentId];
  if (!studentData) return false;

  const { editedOn, lastAccessedOn } = assignment;
  return lastAccessedOn < editedOn;
}

export function hasUngradedSubmissions(
  assignment: Pick<Assignment, 'gradedSubmissions' | 'totalSubmissions'>,
  courseRole: CourseRole
) {
  const { gradedSubmissions, totalSubmissions } = assignment;
  const isStudent = courseRole === CourseRole.STUDENT;
  return !isStudent && gradedSubmissions < totalSubmissions;
}

export function getAssignmentStatusText(
  assignment: Pick<
    Assignment,
    'publishedOn' | 'gradedSubmissions' | 'totalSubmissions' | 'preferences' | 'studentDataById'
  >,
  courseRole: CourseRole,
  studentId: MongoId
) {
  const isStudent = courseRole === CourseRole.STUDENT;

  if (!isStudent) {
    const isPublished = assignment.publishedOn > 0;
    if (!isPublished) {
      return i18next.t('unpublished', {
        ns: i18nNS.GLOSSARY,
      });
    }

    const { gradedSubmissions, totalSubmissions } = assignment;
    const ungraded = Math.max(0, totalSubmissions - gradedSubmissions);

    if (ungraded) {
      return i18next.t('num_ungraded_submission', {
        ns: i18nNS.COURSES,
        count: ungraded,
      });
    }

    return i18next.t('num_submission', {
      ns: i18nNS.COURSES,
      count: totalSubmissions,
    });
  }

  // student only logic further

  const canSubmit = getCanSubmitAssignment(assignment, studentId);
  const isSubmitted = getIsAssignmentSubmitted(assignment, studentId);
  const studentData = assignment.studentDataById[studentId];

  if (!canSubmit && (!isSubmitted || !studentData)) {
    return i18next.t('unsubmitted_you_missed_the_deadline', {
      ns: i18nNS.COURSES,
    });
  }

  const isUnattempted = getIsUnattemptedAssignment(assignment, courseRole, studentId);
  if (isUnattempted) {
    return i18next.t('unattempted', { ns: i18nNS.GLOSSARY });
  }

  if (!isSubmitted) {
    return i18next.t('unsubmitted', { ns: i18nNS.GLOSSARY });
  }

  const isGraded = getIsAssignmentGraded(studentData);
  if (!isGraded) {
    return i18next.t('ungraded', { ns: i18nNS.GLOSSARY });
  }

  return i18next.t('submitted_pipe_score_colon_score_slash_max_marks', {
    ns: i18nNS.COURSES,
    score: studentData?.gradeData?.score ?? 0,
    maxMarks: assignment.preferences.maxMarks,
  });
}

export enum ASSIGNMENT_TAGS {
  UNPUBLISHED = 'unpublished',
  UNGRADED = 'ungraded',
  CLOSED = 'closed',
  NEW = 'new',
  PAST_DUE = 'past_due',
  GRADED = 'graded',
}

export function getAssignmentTags(
  assignment: Pick<
    Assignment,
    'publishedOn' | 'gradedSubmissions' | 'totalSubmissions' | 'preferences' | 'studentDataById'
  >,
  courseRole: CourseRole,
  studentId: MongoId
): ASSIGNMENT_TAGS | null {
  const isStudent = courseRole === CourseRole.STUDENT;

  if (!isStudent) {
    const isPublished = assignment.publishedOn > 0;
    if (!isPublished) return ASSIGNMENT_TAGS.UNPUBLISHED;

    return null;
  }

  // student only logic further

  const canSubmit = getCanSubmitAssignment(assignment, studentId);
  const isSubmitted = getIsAssignmentSubmitted(assignment, studentId);
  const studentData = assignment.studentDataById[studentId];

  if (!canSubmit && (!isSubmitted || !studentData)) return ASSIGNMENT_TAGS.CLOSED;

  const isUnattempted = getIsUnattemptedAssignment(assignment, courseRole, studentId);
  if (isUnattempted) return ASSIGNMENT_TAGS.NEW;

  if (!isSubmitted) {
    const isDeadlinePast = getIsDeadlinePast(assignment.preferences.dueDateTime);
    if (isDeadlinePast) return ASSIGNMENT_TAGS.PAST_DUE;
    return null;
  }

  const isGraded = getIsAssignmentGraded(studentData);
  if (!isGraded) return ASSIGNMENT_TAGS.UNGRADED;

  return ASSIGNMENT_TAGS.GRADED;
}

export function getAssignmentTagColor(
  tag: ASSIGNMENT_TAGS | null
): 'default' | 'primary' | 'success' | 'error' {
  if (tag === ASSIGNMENT_TAGS.CLOSED) return 'error';
  if (tag === ASSIGNMENT_TAGS.GRADED) return 'success';
  if (tag === ASSIGNMENT_TAGS.UNGRADED) return 'default';
  if (tag === ASSIGNMENT_TAGS.PAST_DUE) return 'error';
  if (tag === ASSIGNMENT_TAGS.UNPUBLISHED) return 'error';
  if (tag === ASSIGNMENT_TAGS.NEW) return 'primary';
  return 'default';
}

export function getAssignmentHeading(assignment: Pick<Assignment, 'sequenceNum'> | undefined) {
  return (
    i18next.t('assignment', { ns: i18nNS.GLOSSARY }) +
    (assignment && assignment.sequenceNum > 0 ? ` ${assignment.sequenceNum.toString().padStart(2, '0')}` : '')
  );
}

export function getAssignmentTitle(assignment: Pick<Assignment, 'title'>) {
  const title = assignment.title?.trim();
  return title || i18next.t('untitled', { ns: i18nNS.GLOSSARY }) || '';
}

export function getIsCreator(assignment: Assignment | undefined, currentUserId: MongoId) {
  if (!assignment) return false;
  return currentUserId === assignment.createdBy.userId;
}

export function getIsAssignmentPublished(assignment: Assignment | undefined) {
  if (!assignment) return false;
  return assignment.publishedOn > 0;
}

export function getCanDeleteAssignment(
  assignment: Assignment | undefined,
  course: Course | undefined,
  currentUserId: MongoId
) {
  if (!course) return false;
  if (!assignment) return false;
  return (
    !course.status.isArchived &&
    !getIsAssignmentPublished(assignment) &&
    getIsCreator(assignment, currentUserId)
  );
}

export function getCanCopyAssignment(currentUserRole: CourseRole) {
  return currentUserRole === CourseRole.ADMIN || currentUserRole === CourseRole.INSTRUCTOR;
}

export function getCanPublishAssignment(assignment: Assignment | undefined, currentUserId: MongoId) {
  if (!assignment) return false;
  return !getIsAssignmentPublished(assignment) && getIsCreator(assignment, currentUserId);
}

export function getCanEditAssignment(
  assignment: Assignment | undefined,
  course: Course | undefined,
  currentUserId: MongoId
) {
  if (!course) return false;
  if (!assignment) return false;
  return (
    !course.status.isArchived &&
    getIsAssignmentPublished(assignment) &&
    getIsCreator(assignment, currentUserId)
  );
}

export function getCanViewAssignmentQuestions(
  assignment: Assignment | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  if (currentUser.role !== CourseRole.STUDENT) return true;

  if (!assignment) return false;

  const studentData = assignment.studentDataById[currentUser.userId];
  if (!studentData) return false;

  if ([SubmissionStatus.NOT_ATTEMPTED, SubmissionStatus.IN_PROGRESS].includes(studentData.status)) {
    return !assignment.preferences.allowLate && assignment.preferences.dueDateTime < unix();
  }

  return [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(studentData.status);
}

export function getAssignmentStudentData(
  assignment: Assignment | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  if (!assignment) return undefined;
  if (currentUser.role !== CourseRole.STUDENT) return undefined;
  return assignment.studentDataById[currentUser.userId];
}

export function getAssignmentStudentPermissions(
  assignment: Assignment | undefined,
  currentUser: UserWithRole<CourseRole>
) {
  const defaultValues = {
    canAttemptAssignment: false,
    canResumeAssignment: false,
    canRetractSubmission: false,
  };

  if (currentUser.role !== CourseRole.STUDENT) return defaultValues;

  if (!assignment) return defaultValues;

  if (!assignment.preferences.allowLate && assignment.preferences.dueDateTime < unix()) return defaultValues;

  const studentData = assignment.studentDataById[currentUser.userId];
  if (!studentData) {
    return {
      canAttemptAssignment: true,
      canResumeAssignment: false,
      canRetractSubmission: false,
    };
  }

  if (getIsAssignmentGraded(studentData)) return defaultValues;

  return {
    canAttemptAssignment: studentData.status === SubmissionStatus.NOT_ATTEMPTED,
    canResumeAssignment: studentData.status === SubmissionStatus.IN_PROGRESS,
    canRetractSubmission: [SubmissionStatus.SUBMITTED, SubmissionStatus.LATE].includes(studentData.status),
  };
}

export function getIsAssignmentGraded(studentData: StudentAssignmentData | undefined) {
  if (!studentData) return false;
  return studentData.gradeData !== null;
}

export function getQuestionSubmission(
  question: AssignmentQuestion,
  studentData: StudentAssignmentData | undefined
) {
  if (!studentData) return undefined;

  if (!studentData.submissionsByQuestionId) return undefined;
  const submission = studentData.submissionsByQuestionId[question.id];

  return submission;
}

export function getAssignmentWidgetVariant(
  assignment: Pick<Assignment, 'publishedOn' | 'preferences' | 'studentDataById'>,
  role: CourseRole,
  studentId: MongoId
): DateWidgetVariant {
  const isStudent = role === CourseRole.STUDENT;
  const isPublished = assignment.publishedOn > 0;

  if (!isStudent && !isPublished) return 'unpublished';

  if (!isStudent) return 'past';

  // student only logic further
  if (!getCanSubmitAssignment(assignment, studentId)) return 'past';
  if (getIsDeadlineNear(assignment.preferences.dueDateTime)) return 'near';
  if (getIsAssignmentSubmitted(assignment, studentId)) return 'live';

  return 'future';
}

export function splitSubmissionExtensions(submissionExtensions: string): string[] {
  const acceptableFormats = submissionExtensions
    .split(/\s+|,|;/gi)
    .filter((submissionExtension) => submissionExtension);
  return Array.from(new Set(acceptableFormats));
}

export function getCanCreateOrModifyAssignmentQuestion(
  assignment: Assignment | undefined,
  userId: MongoId,
  isEditing: boolean
) {
  if (!assignment) return false;

  if (userId !== assignment.createdBy.userId) return false;

  const isAssignmentPublished = getIsAssignmentPublished(assignment);
  if (!isAssignmentPublished) return true;

  if (!isEditing) return false;

  return assignment.totalSubmissions === 0;
}

export function getCanEditAssignmentTitleAndInstructions(
  assignment: Assignment | undefined,
  userId: MongoId,
  isEditing: boolean
) {
  if (!assignment) return false;

  if (userId !== assignment.createdBy.userId) return false;

  const isAssignmentPublished = getIsAssignmentPublished(assignment);
  if (!isAssignmentPublished) return true;

  return isEditing;
}

export function getCanEditAssignmentPublishPreferences(
  assignment: Assignment | undefined,
  userId: MongoId,
  isEditing: boolean
) {
  if (!assignment) return false;

  if (userId !== assignment.createdBy.userId) return false;

  return isEditing;
}

export function getCanSeeAssignmentQuestionsWarning(
  assignment: Assignment | undefined,
  userId: MongoId,
  isEditing: boolean
) {
  if (!assignment?.totalSubmissions) return false;

  if (userId !== assignment.createdBy.userId) return false;

  if (!isEditing) return false;

  return assignment.totalSubmissions > 0;
}

export function getCanFetchAssignmentPreSubmissionComments() {
  return true;
}

export function getCanFetchAssignmentPostSubmissionComments<
  T extends PreferencesContainer & StudentDataByIdContainer
>({ assignment, role, userId }: { assignment: T; role: CourseRole; userId: MongoId }) {
  if (role !== CourseRole.STUDENT) return true;
  const canAttemptAssignment = getCanSubmitAssignment(assignment, userId);
  return !canAttemptAssignment;
}

export function getCanSendAssignmentComments(
  assignment: Assignment | undefined,
  isCourseArchived: boolean | undefined
) {
  if (isCourseArchived) return false;

  if (!assignment) return false;

  const isAssignmentPublished = getIsActivityPublished(assignment);
  return isAssignmentPublished;
}

export function getEmptyCommentsMessageForAssignment(
  subContext: AssignmentSubContext,
  currentUser: UserWithRole<CourseRole>
) {
  switch (subContext) {
    case AssignmentSubContext.PRE_SUBMISSION:
      if (currentUser.role === CourseRole.STUDENT) {
        return i18n.t(
          'in_case_you_face_any_difficulties_while_attempting_the_assignment_please_feel_free_to_use_this_discussion_thread_you_will_be_able_to_access_the_post_submission_discussion_thread_after_you_have_submitted_the_assignment',
          { ns: i18nNS.ASSIGNMENT }
        );
      }
      return i18n.t(
        'every_student_has_access_to_this_discussion_area_irrespective_of_whether_they_have_submitted_the_assignment_or_not_it_was_designed_to_encourage_discussions_about_the_assignment_before_submitting_it',
        { ns: i18nNS.ASSIGNMENT }
      );
    case AssignmentSubContext.POST_SUBMISSION:
      return i18n.t(
        'only_the_students_who_have_submitted_the_assignment_will_have_access_to_this_discussion_thread',
        { ns: i18nNS.ASSIGNMENT }
      );
  }
}

export function getAssignmentPageTipContent(tipKey: TipStatusKey) {
  switch (tipKey) {
    case 'assignmentMainFloatingButton':
      return i18n.t(
        'using_this_button_you_can_attach_multiple_questions_to_each_assignment_each_question_can_have_a_different_acceptable_submission_format',
        { ns: i18nNS.ASSIGNMENT }
      );
    case 'assignmentAttemptNavigation':
      return i18n.t(
        'to_move_between_the_questions_you_can_use_the_previous_next_buttons_or_the_question_number_scroll_at_the_top',
        { ns: i18nNS.COMMON }
      );
    case 'assignmentAttemptSubmit':
      return i18n.t(
        'whenever_you_re_satisfied_with_your_submissions_you_can_use_this_button_to_submit_the_assignment_for_grading_please_be_careful_of_not_missing_the_deadline',
        { ns: i18nNS.ASSIGNMENT }
      );
    default:
      return '';
  }
}

export function getAriaLabelForAssignmentWidget(
  assignment?: AssignmentWidgetData,
  course?: CourseWidgetData
) {
  if (!course || !assignment) return '';
  const textDate = format(assignment.preferences.dueDateTime, "EEEE, do MMM 'on' HH:mm aaa");

  const isStudent = course?.myRole === CourseRole.STUDENT;

  if (isStudent) {
    return i18n.t('click_to_view_assignment_assignment_title_with_due_date_on_date', {
      ns: i18nNS.ASSIGNMENT,
      assignmentTitle: assignment.title,
      date: textDate,
    });
  }

  return i18n.t('click_to_view_assignment_assignment_title_having_total_submission_submissioncount', {
    ns: i18nNS.ASSIGNMENT,
    assignmentTitle: assignment.title,
    submissioncount: assignment.totalSubmissions,
  });
}

export function getCanExportAssignmentData(assignment: Assignment | undefined) {
  if (!assignment) return false;

  const isAssignmentPublished = getIsAssignmentPublished(assignment);
  return isAssignmentPublished;
}
