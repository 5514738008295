import { forwardRef, Ref } from 'react';

import {
  CircularProgress as MuiCircularProgress,
  circularProgressClasses as MuiCircularProgressClasses,
  CircularProgressProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { generateClasses } from '../../../utils/helpers';
import { CommonProps } from '../types';

export type Props = CommonProps & {
  shape: 'circular';
  /** @default 45 */
  size?: number;
};

export const circularProgressClasses = {
  ...MuiCircularProgressClasses,
  ...generateClasses('Progressbar', ['colorSuccess', 'colorError', 'colorWarning']),
};

const StyledCircularProgress = forwardRef(
  (
    { ...props }: DistributiveOmit<CircularProgressProps, 'size'> & { size: number },
    ref: Ref<HTMLElement>
  ) => {
    return <MuiCircularProgress {...props} ref={ref} />;
  }
);

export const CircularProgress = styled(StyledCircularProgress)(({ theme, size = 45 }) => ({
  color: theme.palette.primary[500],
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    border: `${size / 10}px solid ${theme.palette.grey[200]}`,
    borderRadius: '50%',
    zIndex: -1,
  },

  [`&.${circularProgressClasses.colorPrimary}`]: {
    color: theme.palette.primary[500],
  },
  [`&.${circularProgressClasses.colorSuccess}`]: {
    color: theme.palette.success[500],
  },
  [`&.${circularProgressClasses.colorError}`]: {
    color: theme.palette.error[500],
  },
  [`&.${circularProgressClasses.colorWarning}`]: {
    color: theme.palette.warning[500],
  },
}));
