import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCanFetchAssignmentPostSubmissionComments,
  getCanFetchAssignmentPreSubmissionComments,
} from '../../../assignment/helpers';
import { useCurrentUserWithRole } from '../../../courses/hooks';
import { setCommentContext } from '../../../db/comments/actions';
import { selectContextData } from '../../../db/comments/selectors';
import { CommentContextData } from '../../../db/comments/types';
import { AssignmentSubContext, CommentContext } from '../../../db/shared/types';
import { i18nNS } from '../../../i18n';

interface CommentTab {
  title: string;
  isActive: boolean;
  isDisabled: boolean;
  context: CommentContextData;
}

const useCommentsTabsVM = () => {
  const { t } = useTranslation([i18nNS.COMMENTS]);

  const dispatch = useDispatch();

  const contextData = useSelector(selectContextData());

  const currentUser = useCurrentUserWithRole();

  const tabs: CommentTab[] | null = useMemo(() => {
    if (!contextData) return null;

    if (contextData?.type === CommentContext.ASSIGNMENT) {
      return [
        {
          title: t('pre_submission', { ns: i18nNS.COMMENTS }),
          isActive: contextData.subType === AssignmentSubContext.PRE_SUBMISSION,
          isDisabled: !getCanFetchAssignmentPreSubmissionComments(),
          context: {
            ...contextData,
            subType: AssignmentSubContext.PRE_SUBMISSION,
          },
        },
        {
          title: t('post_submission', { ns: i18nNS.COMMENTS }),
          isActive: contextData.subType === AssignmentSubContext.POST_SUBMISSION,
          isDisabled: contextData.activity
            ? !getCanFetchAssignmentPostSubmissionComments({
                assignment: contextData.activity,
                role: currentUser.role,
                userId: currentUser.userId,
              })
            : true,
          context: {
            ...contextData,
            subType: AssignmentSubContext.POST_SUBMISSION,
          },
        },
      ];
    }

    return null;
  }, [contextData, currentUser.role, currentUser.userId, t]);

  const activeTab = useMemo(() => tabs?.find((t) => t.isActive), [tabs]);

  const handleTabChange = (contextData: CommentContextData) => dispatch(setCommentContext(contextData));

  return {
    tabs,
    activeTab,
    handleTabChange,
  };
};

export default useCommentsTabsVM;
