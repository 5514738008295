import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../../../components/Button';
import RadioOption from '../../../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../../../components/HookFormControls/RadioGroup';
import { UniversityTimezone } from '../../../../db/shared/types';
import { i18nNS } from '../../../../i18n';
import { Dialog, StyledTypography } from './styles';
import useSelectTimezoneAlert, { Props as VMProps } from './vm';

interface Props extends VMProps {
  timezones: UniversityTimezone[];
}

const SelectTimezoneAlert = ({ timezones, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE]);

  const { open, onClose } = vmOptions;
  const { formMethods, onSubmit } = useSelectTimezoneAlert(vmOptions);
  const { handleSubmit, control } = formMethods;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      header={t('select_course_timezone', { ns: i18nNS.CREATE_COURSE })}
      footer={
        <Button type="submit" onClick={handleSubmit(onSubmit)}>
          {t('continue', { ns: i18nNS.GLOSSARY })}
        </Button>
      }
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup control={control} name="timezone">
          <Stack gap={3}>
            {timezones.map((timezone) => (
              <RadioOption
                value={timezone.timezoneName}
                aria-label={t('click_to_select_timezone_campus', {
                  ns: i18nNS.CREATE_COURSE,
                  timezone: timezone.campusName,
                })}
              >
                <Stack>
                  <StyledTypography variant="h6Regular" color="grey.800">
                    {t('timezone_campus', { ns: i18nNS.CREATE_COURSE, timezone: timezone.campusName })}
                  </StyledTypography>
                  <StyledTypography variant="textSmRegular" color="grey.500">
                    {timezone.readable}
                  </StyledTypography>
                </Stack>
              </RadioOption>
            ))}
          </Stack>
        </RadioGroup>
      </form>
    </Dialog>
  );
};

export default SelectTimezoneAlert;
