import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Fade, styled } from '@mui/material';

import { radioGroupClasses } from '../../../../../../components/FormControls/RadioGroup';
import RadioOption from '../../../../../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../../../../../components/HookFormControls/RadioGroup';
import TextField from '../../../../../../components/HookFormControls/TextField';
import { ClassModality as ClassModalityType } from '../../../../../../courses/types';
import { i18nNS } from '../../../../../../i18n';
import useClassModalityVM, { VMProps } from './vm';

const Root = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),

  [`.${radioGroupClasses.radioGroup}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
}));

interface Props<T extends FieldValues> extends VMProps<T> {
  className?: string;
}

const ClassModality = <T extends FieldValues>({ className, ...vmProps }: Props<T>) => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.COMMON, i18nNS.CREATE_COURSE]);
  const { control, controlName, isVenueVisible, isProCourse, toggleVenueFieldVisibility, venueControlName } =
    useClassModalityVM(vmProps);
  return (
    <Root className={className}>
      <RadioGroup control={control} name={controlName} onChange={toggleVenueFieldVisibility}>
        <RadioOption
          value={ClassModalityType.IN_PERSON}
          aria-label={t('click_to_schedule_inperson_class_mode', { ns: i18nNS.COMMON })}
        >
          {t('offline', { ns: i18nNS.GLOSSARY })}
        </RadioOption>
        <RadioOption
          disabled={!isProCourse}
          value={ClassModalityType.ONLINE}
          aria-label={t('click_to_schedule_online_class_mode', { ns: i18nNS.COMMON })}
        >
          {t('online', { ns: i18nNS.GLOSSARY })} (
          {t('available_in_pro_courses_only', { ns: i18nNS.CREATE_COURSE })})
        </RadioOption>
      </RadioGroup>
      <Fade in={isVenueVisible}>
        <div>
          <TextField
            fullWidth
            control={control}
            name={venueControlName}
            required={isVenueVisible}
            label={t('at_venue_link', { ns: i18nNS.COMMON })}
          />
        </div>
      </Fade>
    </Root>
  );
};

export default ClassModality;
