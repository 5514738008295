import { Check as CheckIcon, Star as StarIcon } from '@mui/icons-material';
import { styled } from '@mui/material';

import Tag from '../components/Tag/index';

const ContentRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(8),
}));

const TagPlayground = () => {
  const handleClick = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ContentRoot>
      <Tag color="default" variant="outlined" label="Option" />
      <Tag color="default" label="Option" />
      <Tag color="primary" label="Option" variant="outlined" />
      <Tag color="primary" label="Option" />
      <Tag color="success" label="Option" />
      <Tag color="success" label="Option" variant="outlined" />
      <Tag color="error" label="Option" />
      <Tag color="error" label="Option" variant="outlined" />
      <Tag color="warning" label="Option" />
      <Tag color="warning" label="Option" variant="outlined" />
      <hr />
      <Tag color="default" variant="outlined" label="Option" icon={<StarIcon />} />
      <Tag color="default" label="Option" icon={<StarIcon />} />
      <Tag color="primary" label="Option" variant="outlined" icon={<StarIcon />} />
      <Tag color="primary" label="Option" icon={<StarIcon />} />
      <Tag color="success" label="Option" icon={<StarIcon />} />
      <Tag color="success" label="Option" variant="outlined" icon={<StarIcon />} />
      <Tag color="error" label="Option" icon={<StarIcon />} />
      <Tag color="error" label="Option" variant="outlined" icon={<StarIcon />} />
      <Tag color="warning" label="Option" icon={<StarIcon />} />
      <Tag color="warning" label="Option" variant="outlined" icon={<StarIcon />} />
      <hr />
      <Tag
        color="default"
        variant="outlined"
        label="Option"
        closeIcon={<CheckIcon />}
        onClose={handleClick}
      />
      <Tag color="default" label="Option" onClose={handleClick} />
      <Tag color="primary" label="Option" variant="outlined" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="primary" label="Option" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="success" label="Option" icon={<StarIcon />} onClose={handleClick} disabled />
      <Tag color="success" label="Option" variant="outlined" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="error" label="Option" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="error" label="Option" variant="outlined" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="warning" label="Option" icon={<StarIcon />} onClose={handleClick} />
      <Tag color="warning" label="Option" variant="outlined" icon={<StarIcon />} onClose={handleClick} />
      <hr />
    </ContentRoot>
  );
};
export default TagPlayground;
