import { useTranslation } from 'react-i18next';

import Typography from '../../components/Typography';
import { i18nNS } from '../../i18n';
import ChevronRightIcon from '../../icons/ChevronRightIcon';
import FileTextIcon from '../../icons/FileTextIcon';
import { ListItemButton, ListItemIcon, ListItemText } from '../styles';

const TermsOfUse = () => {
  const { t } = useTranslation([i18nNS.SETTINGS]);

  return (
    <ListItemButton
      component="a"
      href="https://www.acadly.com/terms"
      target="_blank"
      rel="noopener noreferrer"
      aria-label={t('click_to_read_our_terms_of_use', { ns: i18nNS.SETTINGS })}
    >
      <ListItemIcon>
        <FileTextIcon />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="h6Regular" component="div">
          {t('terms_of_use', { ns: i18nNS.SETTINGS })}
        </Typography>
      </ListItemText>
      <ChevronRightIcon color="disabled" />
    </ListItemButton>
  );
};

export default TermsOfUse;
