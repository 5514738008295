import { useMemo } from 'react';

import { useImage } from '../../utils/hooks';
import { CloseReason } from '../Dialog';

export interface Props {
  src: string;
  alt?: string;
  open: boolean;
  onClose: (reason?: CloseReason) => void;
}

const useImagePreviewDialog = ({ src, alt, open, onClose }: Props) => {
  const { loaded, failed, fileSize } = useImage(src);
  const fileSizeInMB = useMemo(() => fileSize / (1024 * 1024), [fileSize]);

  const handleCloseDialog = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      onClose(CloseReason.BACKDROP_CLICKED);
      return;
    }

    if (reason === 'escapeKeyDown') {
      onClose(CloseReason.ESC_PRESSED);
    }
  };

  const handleCloseButtonClick = () => {
    onClose(CloseReason.CLOSE_BUTTON_CLICK);
  };

  return {
    loaded,
    failed,
    fileSizeInMB,
    handleCloseDialog,
    handleCloseButtonClick,
  };
};

export default useImagePreviewDialog;
