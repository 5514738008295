import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, ListItem, ListItemIcon } from '@mui/material';

import BasicBookImage from '../../../assets/basic-book.png';
import Tag from '../../../components/Tag/index';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import BarChartIcon from '../../../icons/BarChartIcon';
import FileIcon from '../../../icons/FileIcon';
import PersonAddIcon from '../../../icons/PersonAddIcon';
import PersonDoneIcon from '../../../icons/PersonDoneIcon';
import {
  BookIcon,
  Content,
  CreateButton,
  Description,
  FeatureList,
  FeatureSection,
  FeatureTitle,
  Header,
  Root,
  Title,
} from './styles';

interface Props {
  onCreateClick: MouseEventHandler;
}

const BasicCourseWidget = ({ onCreateClick }: Props) => {
  const { t } = useTranslation([i18nNS.COURSES]);
  return (
    <Root>
      <Content tabIndex={0}>
        <BookIcon src={BasicBookImage} aria-hidden />
        <Header>
          <Title variant="h4Bold">{t('basic_demo_course', { ns: i18nNS.COURSES })}</Title>
          <Description>
            {t('create_a_basic_demo_course_take_acadly_for_a_spin', { ns: i18nNS.COURSES })}
          </Description>
          <Tag
            color="warning"
            variant="outlined"
            label={t('best_for_in_person_classrooms', { ns: i18nNS.COURSES })}
          />
        </Header>
        <Divider />
        <FeatureSection>
          <FeatureTitle variant="titleBold">{t('features', { ns: i18nNS.COURSES })}</FeatureTitle>
          <FeatureList>
            <ListItem>
              <ListItemIcon>
                <PersonDoneIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('automate_classroom_attendance', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <BarChartIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('share_live_polls_quizzes_discussions', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <PersonAddIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('collaborate_with_co_instructors_and_t_as', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FileIcon fontSize="small" />
              </ListItemIcon>
              <Typography component="span" variant="h6Regular">
                {t('export_data_in_csv_files', { ns: i18nNS.COURSES })}
              </Typography>
            </ListItem>
          </FeatureList>
        </FeatureSection>
      </Content>
      <CreateButton size="xLarge" variant="outlined" onClick={onCreateClick}>
        {t('create_a_basic_demo_course', { ns: i18nNS.COURSES })}
      </CreateButton>
    </Root>
  );
};

export default BasicCourseWidget;
