import { List, styled } from '@mui/material';

export const Root = styled(List)<{ direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse' }>(
  ({ theme, direction }) => {
    return {
      flexDirection: direction,
      padding: 0,
      outlineOffset: theme.spacing(2),

      '&:focus-visible': {
        outline: `2px solid ${theme.palette.primary[400]}`,

        '& > li[aria-selected="true"]': {
          outline: 'inherit',
          outlineOffset: theme.spacing(0.5),
        },
      },
    };
  }
);
