import { useEffect, useRef, useState } from 'react';

import { base64ToFile, fileToBase64 } from '../../../utils/file';

export interface Props {
  open: boolean;
  onResult: (file: File, dataURI: string) => any;
}

const useCropDialogVM = ({ open, onResult }: Props) => {
  const [fileName, setFileName] = useState('');
  const [fileURI, setFileURI] = useState<string>('#');
  const [fileType, setFileType] = useState('image/png');

  const fileInput = useRef<HTMLInputElement | null>(null);
  const cropperRef = useRef<HTMLImageElement>(null);

  const handleUpload = () => {
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const dataURI = (cropper.getCroppedCanvas() as HTMLCanvasElement).toDataURL(fileType);
    const file = base64ToFile(fileName, dataURI);
    onResult(file, dataURI);
  };

  const handleFileSelected = async () => {
    const files = fileInput.current?.files;
    if (files && files.length) {
      const file = files[0];

      setFileName(file.name);
      setFileType(file.type);

      // convert image file to base64 string
      const fileURI = await fileToBase64(file);
      setFileURI(fileURI);
    }
  };

  useEffect(
    function initialize() {
      if (!open) return;

      setFileName('');
      setFileURI('#');
      setFileType('image/png');
    },
    [open]
  );

  return {
    fileName,
    fileURI,
    fileInput,
    cropperRef,
    handleUpload,
    handleFileSelected,
  };
};

export default useCropDialogVM;
