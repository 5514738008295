import { useState } from 'react';

const useFeedbackVM = () => {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false);
  };

  const handleFeedback = () => {
    setIsFeedbackDialogOpen(true);
  };

  return { isFeedbackDialogOpen, handleCloseFeedbackDialog, handleFeedback };
};

export default useFeedbackVM;
