import Joi from 'joi';

import i18n, { i18nNS } from '../../i18n';
import { optionalString, requiredOneOption, requiredString } from '../../utils/validators';
import { SignupTeacherOrganization, SignupTeacherUseCase } from '../types';
import { FormValues } from './types';

export const validationSchema = Joi.object<FormValues, true>({
  fullName: requiredString,
  website: optionalString,
  organization: requiredOneOption({ options: Object.values(SignupTeacherOrganization) }).messages({
    'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.only': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  }),
  useCase: requiredOneOption({ options: Object.values(SignupTeacherUseCase) }).messages({
    'string.base': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'string.empty': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
    'any.only': i18n.t('this_field_is_required', { ns: i18nNS.VALIDATION }),
  }),
});
