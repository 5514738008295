import Award from '../../Actions/Award';
import Delete from '../../Actions/Delete';
import Like from '../../Actions/Like';
import Thank from '../../Actions/Thank';
import { commentActionsClasses, Root } from './styles';

export interface Props {
  commentId: MongoId;
  className?: string;
}

const CommentActions = ({ commentId, className }: Props) => {
  return (
    <Root className={className}>
      <Award
        edge="start"
        commentId={commentId}
        canSeeBadge
        classes={{ action: commentActionsClasses.hasDivider }}
      />
      <Like edge="start" commentId={commentId} classes={{ action: commentActionsClasses.hasDivider }} />
      <Thank edge="start" commentId={commentId} classes={{ action: commentActionsClasses.hasDivider }} />
      <Delete edge="start" commentId={commentId} />
    </Root>
  );
};

export default CommentActions;
