import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';

import { i18nNS } from '../../i18n';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import { forMobile } from '../../utils/media-queries';
import Button from '../Button';
import Drawer from '../Drawer';
import Header from '../Header';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onCancel: () => any;
  onSubmit: () => any;
}

const DefaultCreateFormulaOverlay = ({ isOpen, children, onCancel, onSubmit }: Props) => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      dismissal="none"
      onClose={onCancel}
      header={
        !isMobile
          ? t('inserting_math_expression', { ns: i18nNS.COMMON })
          : () => (
              <Header
                left={{
                  children: <ArrowBackIcon />,
                  onClick: onCancel,
                  'aria-label': t('click_to_go_back', { ns: i18nNS.COMMON }),
                }}
                right={{
                  children: <CheckmarkIcon />,
                  onClick: onSubmit,
                  'aria-label': t('click_to_use_this_formula', { ns: i18nNS.COMMON }),
                }}
              >
                {t('inserting_math_expression', { ns: i18nNS.COMMON })}
              </Header>
            )
      }
      footer={
        isMobile ? null : (
          <>
            <Button variant="text" color="warning" onClick={onCancel}>
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button onClick={onSubmit} aria-label={t('click_to_use_this_formula', { ns: i18nNS.COMMON })}>
              {t('use', { ns: i18nNS.GLOSSARY })}
            </Button>
          </>
        )
      }
    >
      {children}
    </Drawer>
  );
};

export default DefaultCreateFormulaOverlay;
