import { createPusherEvent } from '../../pusher/helpers';
import { ClassActivityPusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { Attachment } from '../../types';
import { API } from '../shared/api-responses';
import { ActivityType, ClassIncharge } from '../shared/types';

export const instructorPurchaseSuccessfulEvent = createPusherEvent(
  PusherEventName.INSTRUCTOR_PURCHASE_SUCCESSFUL,
  { channels: [PusherChannel.USER] }
);

export type ActivityStoppedPayload = ClassActivityPusherEvent<{
  activityType: ActivityType.POLL | ActivityType.QUIZ;
  dueDateTime: UnixTime;
}>;

export const activityStoppedEvent = createPusherEvent<ActivityStoppedPayload>(
  PusherEventName.ACTIVITY_STOPPED,
  { channels: [PusherChannel.COURSE] }
);

interface QuizUpdated {
  activityType: ActivityType.QUIZ;
  quiz: {
    areQuestionsEdited: NumericBoolean;
    title: API.BaseQuiz['details']['title'];
    instructions: API.BaseQuiz['details']['instructions'];
    attachments: API.BaseQuiz['details']['attachments'];
  };
}

interface PollUpdated {
  activityType: ActivityType.POLL;
  poll: {
    areQuestionsEdited: NumericBoolean;
    title: API.BasePoll['details']['title'];
    description: {
      attachments: Attachment[];
      text: string;
    };
  };
}

interface DiscussionUpdated {
  activityType: ActivityType.DISCUSSION;
  discussion: API.BaseDiscussion['details'];
}

interface ResourceUpdated {
  activityType: ActivityType.RESOURCE;
  resource: API.ResourceContent & {
    title: API.BaseResource['details']['title'];
    description: API.BaseResource['details']['description'];
  };
}

export type ActivityUpdatedEvent = ClassActivityPusherEvent<
  {
    editedOn: UnixTime;
    sender: ClassIncharge;
  } & (QuizUpdated | PollUpdated | DiscussionUpdated | ResourceUpdated)
>;

export const activityUpdatedEvent = createPusherEvent<ActivityUpdatedEvent>(
  PusherEventName.ACTIVITY_UPDATED,
  { channels: [PusherChannel.USER] }
);
