import { Dictionary } from '@reduxjs/toolkit';

export enum SortBy {
  ASC_FIRST_NAME = 'sortAscFirstName',
  DESC_FIRST_NAME = 'sortDescFirstName',
  ASC_LAST_NAME = 'sortAscLastName',
  DESC_LAST_NAME = 'sortDescLastName',
}

export type TipStatusKey =
  | 'courseHomeFloatingButton'
  | 'courseHomeSetting'
  | 'courseHomeArchive'
  | 'courseMainFloatingButton'
  | 'courseMainAnalytics'
  | 'courseMainChat'
  | 'courseAnalyticsExport'
  | 'courseAnalyticsAddStudents'
  | 'assignmentMainFloatingButton'
  | 'assignmentMainAttempt'
  | 'assignmentAttemptNavigation'
  | 'assignmentAttemptSave'
  | 'assignmentAttemptSubmit'
  | 'classMainFloatingButton'
  | 'classMainQueryAdd'
  | 'classMainChat'
  | 'activityAnalyticsExport'
  | 'quizMainFloatingButton'
  | 'quizMainAttempt'
  | 'quizMainChat'
  | 'quizAttemptNavigation'
  | 'quizAttemptSave'
  | 'quizAttemptSubmit'
  | 'pollMainFloatingButton'
  | 'pollMainChat'
  | 'pollMainSubmit'
  | 'discussionMainComment'
  | 'queryMainApprove'
  | 'queryMainClose'
  | 'queryMainDelete'
  | 'queryMainHide'
  | 'queryMainUpvote';

export enum NotificationType {
  NEW_ACTIVITY = 'newActivity',
  NEW_ANNOUNCEMENT = 'newAnnouncement',
  NEW_ASSIGNMENT = 'newAssignment',
  NEW_QUERY = 'newQuery',
  QUERY_RESPONSE = 'queryResponse',
  SCHEDULE_UPDATE = 'scheduleUpdate',
}

export enum AppContext {
  GET_IN = 'getin',
  HOME = 'home',
  COURSE = 'course',
  CLASS = 'classes',
  ASSIGNMENT = 'assignments',
  QUIZ = 'quizzes',
  POLL = 'polls',
  RESOURCE = 'resources',
  QUERY = 'queries',
  DISCUSSION = 'discussions',
}

export enum CommentContext {
  COURSE = 'course',
  CLASS = 'classes',
  QUERY = 'queries',
  ASSIGNMENT = 'assignments',
  QUIZ = 'quizzes',
  POLL = 'polls',
  RESOURCE = 'resources',
  DISCUSSION = 'discussions',
}

export enum ToBeDone {
  IN_CLASS = 'inClass',
  PRE_CLASS = 'preClass',
}

export enum QueryToBeDone {
  IN_CLASS = 'inClass',
  PRE_CLASS = 'preClass',
  REVIEW = 'review',
}

export enum AssignmentSubContext {
  PRE_SUBMISSION = 'preSub',
  POST_SUBMISSION = 'postSub',
}

export type CommentSubContext<Context extends CommentContext> = Context extends CommentContext.ASSIGNMENT
  ? AssignmentSubContext
  : Context extends CommentContext.QUERY
  ? QueryToBeDone
  : Context extends CommentContext.COURSE
  ? ToBeDone.PRE_CLASS
  : ToBeDone;

export enum CourseRole {
  ADMIN = 'admin',
  INSTRUCTOR = 'instructor',
  STUDENT = 'student',
  TA = 'ta',
}

export enum ClassRole {
  /** Other course team members */
  NONE = 'none',
  INCHARGE = 'incharge',
  ASSISTANT = 'assistant',
  STUDENT = 'student',
}

export enum QueryRole {
  /** The user is neither creator of the query nor has the same query */
  NONE = '',
  /** The user is the query creator */
  ASKER = 'asker',
  /** The user too has same query */
  UPVOTER = 'supporter',
}

export enum CourseMemberStatus {
  /** User has signed up on Acadly */
  ACTIVE = 'active',
  /**
   * An invitation email has been sent but
   * the user has not signed up on Acadly
   */
  INVITED = 'invited',
}

export interface User {
  name: string;
  userId: MongoId;
  avatar: MongoId;
}

export type UserWithRole<T extends CourseRole> = User & { role: T };

export type CourseUser = UserWithRole<CourseRole>;

export interface CourseMember extends CourseUser {
  emailId: string;
  status: CourseMemberStatus;
}

/**
 * Every course user except student comes into
 * the category of course-team-member
 */
export interface CourseTeamUser extends CourseUser {
  role: CourseRole.ADMIN | CourseRole.INSTRUCTOR | CourseRole.TA;
}

/**
 * A course user who can create activities except class-queries
 * which can only be created by student.
 */
export interface ClassIncharge extends CourseUser {
  role: CourseRole.ADMIN | CourseRole.INSTRUCTOR;
}

export interface ClassUser extends User {
  role: ClassRole;
}

export interface ClassTeamUser extends User {
  role: ClassRole.INCHARGE | ClassRole.ASSISTANT;
}

export interface CourseStudentUser extends CourseUser {
  role: CourseRole.STUDENT;
}

export type WithCurrentUser<T extends object, User = CourseUser> = T & {
  currentUser: User;
};

export enum AttachmentType {
  NONE = '',
  FILE = 'file',
  IMAGE = 'image',
}

export interface FileAttachment {
  /**
   * filename as stored on s3 along with extension
   * @example 'some-random-id.pdf'
   */
  name: string;
  /**
   * file extension as uploaded by the creator
   * @example 'pdf'
   */
  extension: string;
  /**
   * filename as uploaded by the creator without the extension
   * @example 'OB Case Study 3'
   */
  originalName: string;
}

export enum EnrollmentBy {
  /** In these type of courses, a join-code is generated which can be used to enroll in a course  */
  CODE = 'enrolmentCode',
  /** Every student is invited using their emails, an invitation link is sent to their emails */
  INVITATION = 'enrolmentInvite',
}

export enum CourseType {
  /** The course is integrated with an LMS like BlackBoard, Canvas etc */
  SYNCED = 'synced',
  /** Default course type */
  SYSTEM = 'system',
}

export enum TimelineItemType {
  ANNOUNCEMENT = 'announcement',
  ASSIGNMENT = 'assignment',
  CLASS = 'class',
}

export enum AnnouncementType {
  AUTO = 'automatic',
  MANUAL = 'manual',
}

export enum ClassType {
  /** Default class type */
  LECTURE = 'lecture',
  /** @deprecated Not used anymore, here for legacy support only */
  TUTORIAL = 'tutorial',
  /** @deprecated Not used anymore, here for legacy support only */
  LAB = 'lab',
  /** @deprecated Not used anymore, here for legacy support only */
  OFFICEHOUR = 'officeHour',
  /** @deprecated Not used anymore, here for legacy support only */
  SEMINAR = 'seminar',
}

export enum ClassStatus {
  /** Indicates that class was never started */
  NO_RECORD = 'noRecord',
  /** Indicates that class is yet to start */
  OPEN = 'open',
  /** @deprecated Not used anymore, here for legacy support only */
  HOLIDAY = 'holiday',
  /** Indicates that class has been cancelled */
  CANCELED = 'canceled',
  /** Indicates that class have been actually ended */
  CLOSED = 'closed',
  /** Indicates that class has started but not yet ended */
  IN_SESSION = 'inSession',
}

export enum QueryStatus {
  /**
   * Query is visible to students,
   * default for non-anonymous queries
   */
  OPEN = 'open',
  /** Query is closed by asker and still visible to users */
  CLOSED = 'closed',
  /**
   * Query needs to be approved by course team members,
   * default for anonymous queries
   */
  PENDING = 'pending',
}

export enum DueDateType {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum WeekDay {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

/** Duration for automatically closed activities */
export enum ActivityDuration {
  /** Activity will be closed by end of class */
  BY_END_OF_THE_CLASS = -2,
  /** Activity should be stopped manually */
  MANUALLY_STOPPED = -1,
  /** Useful for pre-class activities */
  NOT_APPLICABLE = 0,
  AFTER_30_SECONDS = 0.5,
  AFTER_1_MINUTE = 1,
  AFTER_2_MINUTES = 2,
  AFTER_5_MINUTES = 5,
  AFTER_10_MINUTES = 10,
  AFTER_15_MINUTES = 15,
  AFTER_20_MINUTES = 20,
  AFTER_30_MINUTES = 30,
  AFTER_45_MINUTES = 45,
  AFTER_1_HOUR = 60,
}

export enum SubmissionStatus {
  NOT_ATTEMPTED = 'not_attempted',
  SUBMITTED = 'submitted',
  LATE = 'late',
  IN_PROGRESS = 'inProgress',
}

export enum AssignmentQuestionSubmissionType {
  FILE = 'file',
  URL = 'url',
}

export enum QuizQuestionType {
  MCQ = 'mcq',
  TF = 'tf',
  SORTING = 'reorder',
}

export enum ScoringScheme {
  INCENTIVIZING = 'incentivizing',
  PENALIZING = 'penalizing',
  NEUTRAL = 'neutral',
}

export enum SubmissionType {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum DiscussionAnonymity {
  /** Comments are visible to course team member */
  STUDENTS = 'students',
  /** Comments are not visible to course team member */
  ALL = 'all',
}

export enum ResourceType {
  VIDEO = 'video',
  LINK = 'link',
  FILE = 'file',
  TEXT = 'text',
}

/** Collection of activities */
export interface Activities<T> {
  courseId: MongoId;
  /** Activity-id to activity-data map */
  byId: Dictionary<T>;
}

export interface CommentsData {
  /**
   * If set, an email will be sent to current user
   * when comments are posted
   */
  isSubscribed: boolean;
  /** Number of comments seen by current user */
  seen: number;
  /** Number of comments posted */
  total: number;
}

/** Common activity details */
export interface BaseActivity<T extends CommentsData = CommentsData> {
  id: MongoId;
  courseId: MongoId;
  /** `null` means title is not set */
  title: string | null;
  comments: T;
  /** `-1`, when not edited */
  editedOn: UnixTime;
  /** `null`, when not edited */
  editedBy: CourseUser | null;
  createdBy: CourseUser;
  createdOn: UnixTime;
}

/** Activities which are published in a class */
export interface ClassActivity<T = ToBeDone> extends BaseActivity {
  classId: MongoId;
  toBeDone: T;
  /**
   * Activity due date and time, also used for sorting purpose
   * `-1` means this activity does not have `dueDateTime`.
   * For manually-closed-activities it will be `-1` until the
   * activity is closed.
   */
  dueDateTime: UnixTime;
  /**
   * Sequence number of the activity of it's type in a class.
   * `0` means this activity does not have a sequence number.
   */
  sequenceNum: number;
  /** `-1` when not published */
  publishedOn: UnixTime;
}

export enum ActivityType {
  QUIZ = 'quiz',
  POLL = 'poll',
  QUERY = 'query',
  RESOURCE = 'resource',
  DISCUSSION = 'discussion',
}

export const PluralToActivityType = {
  quizzes: ActivityType.QUIZ,
  polls: ActivityType.POLL,
  queries: ActivityType.QUERY,
  resources: ActivityType.RESOURCE,
  discussions: ActivityType.DISCUSSION,
} as const;

export const ActivityTypeToPlural = {
  [ActivityType.QUIZ]: 'quizzes',
  [ActivityType.POLL]: 'polls',
  [ActivityType.QUERY]: 'queries',
  [ActivityType.RESOURCE]: 'resources',
  [ActivityType.DISCUSSION]: 'discussions',
} as const;

export enum SuggestedActivityType {
  ASSIGNMENT = 'assignment',
  QUIZ = 'quiz',
  POLL = 'poll',
  DISCUSSION = 'discussion',
  RESOURCE = 'resource',
}

export const SuggestedActivityToPlural = {
  [SuggestedActivityType.ASSIGNMENT]: 'assignments',
  [SuggestedActivityType.QUIZ]: 'quizzes',
  [SuggestedActivityType.POLL]: 'polls',
  [SuggestedActivityType.RESOURCE]: 'resources',
  [SuggestedActivityType.DISCUSSION]: 'discussions',
} as const;

export type SuggestedClassActivityType =
  | SuggestedActivityType.QUIZ
  | SuggestedActivityType.POLL
  | SuggestedActivityType.DISCUSSION
  | SuggestedActivityType.RESOURCE;

export interface SuggestedActivity {
  id: MongoId;
  courseId: MongoId;
  courseCode: string;
  courseTitle: string;
  /** `null` meand title is not set */
  title: string | null;
  sequenceNum: number;
  /**
   * set to `true` once this activity is used as source
   * activity to create new activity
   */
  isUsed: boolean;
  /**
   * if `true`, it should be shown in suggested activities
   */
  isHidden: boolean;
  createdOn: UnixTime;
  createdBy: CourseTeamUser;
  publishedOn: UnixTime;
}

export enum RecordingFileType {
  AUDIO = 'audio',
  VIDEO = 'video',
  TRANSCRIPT = 'transcript',
}

export interface RecordingFile {
  displayName: string;
  /** name will be used as unique identifier */
  name: string;
  type: RecordingFileType.AUDIO | RecordingFileType.VIDEO | RecordingFileType.TRANSCRIPT;
}

export interface GetPreSignedURLRequestConfig {
  url: string;
  /**
   * Request body payload except `originalFileName` and
   * `fileType`
   */
  data?: Record<string, unknown>;
  headers?: Record<string, string>;
}

export interface PreSignedURLResponse {
  /** Pre-signed url of S3 */
  url: string;
  /** Name of file in S3 bucket */
  name: string;
  /** Extension of uploaded file */
  type: string;
  /** MIME type of the uploaded file */
  contentType: string;
}

export enum MeetingStatus {
  NOT_STARTED,
  IN_PROGESS,
  ENDED,
}

export enum RecordingStatus {
  IN_PROGESS = 'inProgress',
  DONE = 'done',
}

export enum MarkCommentAction {
  THANK = 'helpful',
  UNTHANK = 'unhelpful',
  LIKE = 'starred',
  UNLIKE = 'unstarred',
}

export enum RateCommentAction {
  AWARDING = 'awarding',
  RETRACTING = 'retracting',
}
export interface UniversityTimezone {
  campusName: string;
  readable: string;
  timezoneName: string;
}

export type CountryTimezone = UniversityTimezone;

export interface ClassParticipation {
  points: number;
  label?: string;
  awardedBy: CourseTeamUser;
}

export enum AttendanceStatus {
  ABSENT = 'absent',
  CHECKED_IN = 'checkedIn',
  EXCUSED = 'excused',
  LATE = 'late',
  NO_RECORD = 'noRecord',
  PRESENT = 'present',
  /** Similar to {@link AttendanceStatus.NO_RECORD}, sent to students only */
  NOT_PRESENT = 'notPresent',
  /** Remotely present */
  REMOTE_PRESENT = 'remote',
}

export enum ScheduleAttendanceRule {
  RANDOM = 'random',
  FIRST_10_MINUTES = 'first10',
  LAST_10_MINUTES = 'last10',
  TIME_SET = 'timeSet',
  UNSET = 'unset',
}

export enum AttendanceProcessFailureType {
  ATTENDEE_FAILURE = 'attendee_failure',
  UNRESPONSIVE_ATTENDEES_FAILURE = 'unresponsive_attendees_failure',
}

/**
 * failureCode types can be
 * `noPermissions` - Has not provided adequate permissions
 * `bleNotSupported` - Is using a device that doesn’t support Bluetooth
 * `noGPS` - Has not switched on the GPS
 */
export enum AttendanceFailureCode {
  NO_PERMISSIONS = 'noPermissions',
  BLE_NOT_SUPPORTED = 'bleNotSupported',
  NO_GPS = 'noGPS',
}

export enum AttendanceWarningAction {
  SHOW_WARNING = 'showWarning',
  HIDE_WARNING = 'hideWarning',
}

export enum CopyToFutureClasses {
  NO = '',
  ALL = 'all',
  SIMILAR = 'similar',
}

export enum ResolveHandAction {
  RESOLVE = 'resolve',
  NEXT = 'next',
}
