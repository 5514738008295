import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Stack } from '@mui/material';

import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import CropDialog from '../CropDialog';
import SelectableAvatar from '../SelectableAvatar';
import SkinTone from '../SkinTone';
import { Avatar, RadioGroup, RadioOption } from './styles';
import useChooseAvatarVM, { ChooseAvatarFormInstance, Props as VMProps } from './vm';

interface Props extends VMProps {}

const ChooseAvatar = ({ ...vmOptions }: Props, ref: Ref<ChooseAvatarFormInstance>) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const {
    onChooseFile: handleChooseFile,
    formMethods,
    onSelectSkinTone: handleSelectSkinTone,
    presetAvatars,
    onSelectPresetAvatar,
    onSubmit,
    isCropDialogOpen,
    onCloseCropDialog: handleCloseCropDialog,
    onFileCropped: handleFileCropped,
  } = useChooseAvatarVM(vmOptions, ref);
  const { handleSubmit, control, watch } = formMethods;
  const [skinTone, avatar] = watch(['skinTone', 'avatar']);

  const handleSelectPresetAvatar = (index: number) => () => {
    return onSelectPresetAvatar(index);
  };

  return (
    <>
      <Stack alignItems="center" gap={3} mt={5} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="paragraphMedium">{t('your_current_avatar', { ns: i18nNS.AUTH })}</Typography>
        <Avatar variant="rounded" src={avatar.src} alt={avatar.alt} />
        <Button
          variant="contained"
          color="primary"
          onClick={handleChooseFile}
          aria-label={t('click_to_choose_file_from_system', { ns: i18nNS.AUTH })}
        >
          {t('choose_from_system', { ns: i18nNS.AUTH })}
        </Button>
        <Typography variant="titleBold" component="p">
          {t('or', { ns: i18nNS.GLOSSARY })}
        </Typography>
        <Typography variant="paragraphMedium" component="h2">
          {t('choose_from_default_avatars', { ns: i18nNS.AUTH })}
        </Typography>
        <Typography variant="textSmBold">{t('select_skin_tone', { ns: i18nNS.AUTH })}</Typography>
        <RadioGroup control={control} name="skinTone">
          <RadioOption value="">
            <SkinTone tone="" isSelected={skinTone === ''} onSelect={handleSelectSkinTone} />
          </RadioOption>
          <RadioOption value="p">
            <SkinTone tone="p" isSelected={skinTone === 'p'} onSelect={handleSelectSkinTone} />
          </RadioOption>
          <RadioOption value="t">
            <SkinTone tone="t" isSelected={skinTone === 't'} onSelect={handleSelectSkinTone} />
          </RadioOption>
          <RadioOption value="d">
            <SkinTone tone="d" isSelected={skinTone === 'd'} onSelect={handleSelectSkinTone} />
          </RadioOption>
        </RadioGroup>
        <Typography variant="textSmBold">{t('select_avatar', { ns: i18nNS.AUTH })}</Typography>
        <RadioGroup control={control} name="presetAvatarIndex">
          {presetAvatars.map((presetAvatar, index) => (
            <RadioOption value={`${index}`} key={index}>
              <SelectableAvatar
                avatarProps={presetAvatar}
                isSelected={presetAvatars[index].src === avatar.src}
                onSelect={handleSelectPresetAvatar(index)}
              />
            </RadioOption>
          ))}
        </RadioGroup>
      </Stack>
      <CropDialog
        open={isCropDialogOpen}
        onCancel={handleCloseCropDialog}
        title={t('crop_avatar', { ns: i18nNS.AUTH })}
        onResult={handleFileCropped}
      />
    </>
  );
};

export default forwardRef(ChooseAvatar);
