import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { fetchComments } from '../../../../../db/comments/actions';
import {
  selectCommentContext,
  selectComments,
  selectTotalComments,
} from '../../../../../db/comments/selectors';
import { useRequestDispatch } from '../../../../../utils/request-actions';
import { CommentsContext } from '../../../../Context';

const useLoadOlderCommentsVM = () => {
  const requestDispatch = useRequestDispatch();

  const context = useSelector(selectCommentContext);
  const comments = useSelector(selectComments);
  const totalComments = useSelector(selectTotalComments());

  const hasMoreComments = useMemo(() => comments.length < totalComments, [comments.length, totalComments]);

  const { pageNumber, incrementPageNumber } = useContext(CommentsContext);

  const handleLoadOlderComments = async () => {
    if (!context || !comments.length) return;

    await requestDispatch(fetchComments, {
      context,
      lastCommentId: comments[0]?.id,
    });

    incrementPageNumber();
  };

  return { hasMoreComments, pageNumber, handleLoadOlderComments };
};

export default useLoadOlderCommentsVM;
