import { publishCourseInfo } from '../../../db/courses/actions';
import { useRequestDispatch } from '../../../utils/request-actions';

const usePublishInfoVM = () => {
  const requestDispatch = useRequestDispatch();

  const onOkayClick = () => {
    return requestDispatch(publishCourseInfo);
  };

  return {
    onOkayClick,
  };
};

export default usePublishInfoVM;
