import { ElementType, KeyboardEvent, MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '@mui/material';

import { i18nNS } from '../../i18n';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import CloseIcon from '../../icons/CloseIcon';
import { randomStr } from '../../utils/helpers';
import { forMobile } from '../../utils/media-queries';
import { HeaderActionProps, Props as HeaderProps } from '../Header';

type Dismissal = 'esc' | 'backdrop';

export interface Props<L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'> {
  /**
   * Dialog dismissal behaviour
   * @default ['esc', 'backdrop']
   */
  dismissal?: 'none' | Dismissal[];
  header?:
    | string
    | ((id: string) => ReactNode)
    | {
        leftButtonProps?: HeaderActionProps<L>;
        rightButtonProps?: HeaderActionProps<R>;
        title: string;
      };
  onClose?: (e: MouseEvent | KeyboardEvent, reason: 'backdropClick' | 'escapeKeyDown' | 'custom') => void;
}

const useDialogVM = <L extends ElementType<any> = 'button', R extends ElementType<any> = 'button'>({
  dismissal = ['esc', 'backdrop'],
  header,
  onClose,
}: Props<L, R>) => {
  const isMobile = useMediaQuery(forMobile);
  const { t } = useTranslation([i18nNS.COMMON]);

  const { titleId, bodyId } = useMemo(() => ({ titleId: randomStr(6), bodyId: randomStr(6) }), []);

  const handleDismiss = useCallback(
    (event: MouseEvent | KeyboardEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (dismissal === 'none') return;

      if (reason === 'backdropClick' && dismissal.includes('backdrop')) {
        return onClose?.(event, reason);
      }

      if (reason === 'escapeKeyDown' && dismissal.includes('esc')) {
        return onClose?.(event, reason);
      }
    },
    [dismissal, onClose]
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const headerProps = useMemo<HeaderProps | undefined>(() => {
    if (!header || typeof header === 'function') return undefined;

    const dismissButtonProps: HeaderActionProps = {
      edge: !isMobile ? 'end' : undefined,
      'aria-label': t('click_to_close_dialog', { ns: i18nNS.COMMON }),
      children: isMobile ? (
        <ArrowBackIcon color="inherit" aria-hidden />
      ) : (
        <CloseIcon color="inherit" aria-hidden />
      ),
      onClick: (event) => onClose?.(event, 'custom'),
    };

    if (typeof header === 'string') {
      return {
        id: titleId,
        children: header,
        left: isMobile ? dismissButtonProps : undefined,
        right: isMobile ? undefined : dismissButtonProps,
      };
    }

    return {
      id: titleId,
      children: header.title,
      left: header.leftButtonProps,
      right: header.rightButtonProps,
    };
  }, [header, isMobile, onClose, t, titleId]);

  return { bodyId, handleDismiss, handleKeyDown, headerProps, isMobile, titleId };
};

export default useDialogVM;
