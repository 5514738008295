import { createAction } from '@reduxjs/toolkit';

import Pusher from 'pusher-js';

import { UserSession } from '../auth/types';

export const startPusher = createAction<UserSession>('pusher/start');
export const startPusherSuccess = createAction<Pusher>('pusher/start/success');

interface WithCourseId {
  courseId: MongoId;
}

export const startCourseChannel = createAction<WithCourseId>('pusher/course/start');
export const stopCourseChannel = createAction('pusher/course/stop');

export const startCourseTeamChannel = createAction<WithCourseId>('pusher/course-team/start');
export const stopCourseTeamChannel = createAction('pusher/course-team/stop');
