const urls = Object.freeze({
  /** Uses base api gateway */
  server: '/server',
  getUserPreferences: '/initialize',
  setSortPreference: '/sortpref/update',
  updateTipsPreference: '/tips/update',
  updateAccessibilityPreference: '/accessibility/update',

  ssoStatus: (sessionId: string) => `/sso-status?sessionId=${sessionId}`,
  login: '/login',
  ssoLogin: '/login-sso',
  email: '/email',
  logout: '/logout',

  signupStudent: '/signup/student',
  signupTeacher: '/signup/v2/teacher',

  verifyTempPass: '/verifytemppass',
  resendTempPass: '/resendtemppass',
  setPassword: '/setpassword',
  forgotPassword: '/forgotpassword',
  changePassword: '/users/profile/password/change',

  changeName: '/users/profile/name/change',
  changeNameStatusResponse: '/users/profile/name/change/status',
  sendReferral: '/refer',

  createProfile: '/users/profile/create',
  getAvatar: '/users/profile/avatar/get',
  changeAvatar: '/users/profile/avatar/create',

  cancelFacultyAccountVerification: '/users/am_student',

  initNotifications: '/notifications/register',
  getNotificationPrefs: '/notifications/get/preferences',
  saveNotificationPrefs: '/notifications/manage/preferences',

  newFeatures: '/newfeatures',
  newFeaturesLastAccesed: '/newfeatures/lastAccessed',

  courseByCode: (code: string) => `/courses/${code}`,
  myCourses: '/courses/v2/mycourses',
  archivedCourses: '/courses/archived',
  archiveCourse: '/courses/archive',
  courseDetails: '/courses/v2/details',
  joinCourse: '/courses/v2/join/code',

  createProDemoCourse: '/pro/create_trial',
  createBasicDemoCourse: '/courses/create_trial',

  getCoursesToCopyActivity: '/archives/get/validtargetcourses',
  getClassesToCopyActivity: '/archives/get/validtargetclasses',

  courseInfo: '/courses/info',
  publishCourseInfo: '/courses/info/publish',
  editCourseDescription: '/courses/info/edit/description',
  saveOfficeHours: '/courses/info/officehours/save',

  getCourseInfoFileUploadURL: '/geturl/courses/info/upload',
  getCourseInfoFileDownloadURL: '/courses/info/files/download',
  saveCourseInfoFile: '/confirm/courses/info/upload',
  removeCourseInfoFile: '/courses/info/files/remove',

  getCourseSyllabusFileUploadURL: '/geturl/courses/syllabus/upload',
  getCourseSyllabusFileDownloadURL: '/courses/syllabus/files/download',
  saveCourseSyllabusFile: '/confirm/courses/syllabus/upload',

  courseTopics: '/topics/course',
  addCourseTopic: '/topics/add',
  deleteCourseTopic: '/topics/remove',
  editCourseTopic: '/topics/edit',

  getCourseLinks: '/links/id',
  addCourseLink: '/links/add',
  removeCourseLink: '/links/remove',

  getCourseBooks: '/books/id',
  addCourseBook: '/books/add',
  editCourseBook: '/books/edit',
  removeCourseBook: '/books/remove',

  addCourseTeamMember: '/members/courses/add/team',
  addCourseStudents: '/members/v2/courses/add/students',
  removeCourseMember: `/courses/members/remove`,
  reinstateRemovedStudent: '/courses/students/reinstate',
  resendCourseInvites: '/members/courses/reinvite/students',

  uploadImage: '/images/upload',
  uploadFormula: '/math/insert',

  getComments: '/comments/v2/get',
  addComment: '/comments/add',
  seenComment: '/comments/seen',
  markComment: '/comments/mark',
  rateComment: '/comments/award',
  getCommentLikes: (commentId: MongoId) => `/comments/get/likes/${commentId}`,
  removeComment: '/comments/remove',
  commentSubscription: `/comments/subscription`,
  downloadCommentFiles: '/comments/files/download',
  uploadCommentFile: '/geturl/comments/files/upload',

  getCourseEnrollments: '/courses/analytics/students',
  getMyCourseAnalytics: '/courses/analytics/v2/self',
  getCourseAveragesForCourseTeam: '/courses/analytics/averages',
  getStudentAveragesForCourseTeam: (studentId: MongoId) => `/courses/analytics/v2/individual/${studentId}`,

  exportActivityGrades: '/export/grades',
  exportAllGrades: '/export/grades/all',
  exportAllCommentsStats: '/export/comments/all',

  getAvailableSlots: '/pro/v2/status/instructor/available_slots',
  getCoursePurchaseCost: (type: string, numCourses: number, numStudents: number) =>
    `/pro/purchase/instructor/cost?type=${type}&numCourses=${numCourses}&numStudents=${numStudents}`,
  getInstructorPurchaseIntent: '/pro/purchase/instructor',
  confirmInstructorPaymentStatus: '/pro/purchase/instructor/status',
  createBasicCourse: '/courses/v2/create',
  createProCourse: '/courses/v2/create_pro',
  getCoursePurchaseCouponStatus: '/pro/purchase/student/course_coupons/status',
  redeemCoupon: '/pro/purchase/student/course_coupons',
  getPurchaseCourseIntent: '/pro/purchase/student',
  updateCoursePurchaseStatus: '/pro/purchase/student/status',

  getAdminCourses: '/courses/amadmin',
  deleteCourse: '/courses/remove',

  fetchBlueprintCourses: '/courses/blueprint',
  initializeCourse: '/courses/initialize',
  blueprintCoursePreview: (courseId: MongoId) => `/courses/bppreview/${courseId}`,
  downloadBlueprintCoursePreviewInfoFile: '/courses/info/files/download',

  getTimezones: '/courses/timezone/get',
  setTimezone: '/courses/timezone/set',
  setCourseStartDate: '/courses/schedule/edit/startdate',
  setCourseEndDate: '/courses/schedule/edit/enddate',
  addWeeklySchedule: '/courses/v3/schedule/add/weeklyclass',
  removeWeeklySchedule: '/courses/schedule/remove/weeklyclass',
  checkNumScheduledClassesLimit: '/courses/v2/numsessions',

  suggestedActivities: '/courses/activities/suggested',
  suggestedActivityDetails: '/courses/activities/suggested/details',
  useSuggestedActivity: '/archives/use/suggestedactivity',
  hideSuggestedActivity: '/archives/hide/suggestedactivity',

  copyActivity: '/archives/copy/activity',
  moveActivity: '/classes/activity/move',

  addTeamMember: '/members/courses/add/team',
  removeTeamMember: '/courses/members/remove',

  publishCourseSchedule: '/courses/v3/schedule/publish',
  publishCourse: '/courses/v2/publish',

  createClass: '/classes/v4/add',
  editClassTitle: '/classes/title/edit',
  editClassTimings: '/classes/v2/timings/edit',
  cancelClass: '/classes/v3/schedule/cancel',
  editClassVenue: '/classes/v3/venue/edit',
  editClassTeam: '/classes/v2/team/edit',
  deleteClass: (classId: MongoId) => `/classes/remove/${classId}`,
  getClassDetails: (classId: MongoId) => `/classes/${classId}`,

  startClass: '/classes/v2/start',
  startOnlineClass: '/classes/v2/start-online',
  endClass: (classId: MongoId) => `/classes/end/${classId}`,

  checkInToClass: '/classes/v2/checkin',

  authorizeZoomApp: '/applications/authorize/zoom',
  createZoomMeeting: '/classes/zoom/create-meeting',
  getZoomMeetingStatus: '/classes/zoom/meeting/status',
  startZoomMeetingBroadcast: '/classes/zoom/broadcast',
  getZoomMeetingDetails: '/classes/zoom/get-meeting-details',
  getZoomMeetingCredentials: '/classes/zoom/generate_signature',
  registerZoomMeetingParticipant: '/classes/zoom/meeting/joined',
  attendingRemotely: '/classes/attendance/attending-remotely',
  raiseHand: '/queries/raise_hand',
  lowerHand: '/queries/lower_hand',
  addressHand: '/queries/address_hand',
  resolveHand: '/queries/resolve_hand',
  unregisterZoomMeetingParticipant: '/classes/zoom/meeting/left',
  endZoomMeetingBroadcast: '/classes/zoom/end_broadcast',
  getClassRecordings: (classId: MongoId) => `/classes/zoom/recordings/${classId}`,
  deleteClassRecordings: '/classes/v2/zoom/recordings/delete',
  publishClassRecordings: '/classes/zoom/recordings/publish',
  downloadClassRecordings: '/classes/v3/zoom/recordings/download',
  startRemoteAttendance: '/classes/zoom/attendance/start',
  stopRemoteAttendance: '/classes/zoom/attendance/stop',
  markRemoteAttendance: '/classes/zoom/attendance/mark',

  editClassAgenda: '/classes/agenda/edit',
  editClassTopics: '/classes/topics/edit',
  getClassSummary: '/classes/summary/get',
  editClassSummary: '/classes/summary/save',
  uploadClassSummaryAttachment: '/geturl/classes/summary/files/upload',
  downloadClassSummaryAttachment: '/classes/summary/files/download',

  getClassParticipation: (classId: MongoId) => `/classes/participation/${classId}`,
  awardClassParticipationPoints: '/classes/v2/participation',

  scheduleAttendance: '/classes/attendance/schedule',
  editAttendanceSchedule: '/classes/attendance/schedule/edit',
  getClassAttendance: (classId: MongoId) => `/classes/v2/attendance/get/${classId}`,
  getMyAttendanceStatus: (classId: MongoId) => `/classes/attendance/mine/${classId}`,
  startProxyAttendance: '/classes/attendance/proxy/start',
  stopProxyAttendance: '/classes/attendance/proxy/stop',
  acknowledgeAttendanceStarted: '/internals/record/notified',
  editClassAttendance: '/classes/v2/attendance/set',
  getAttendanceResponders: '/classes/attendance/responders',

  createAnnouncement: '/announcements/create',
  markAnnouncementAsRead: '/announcements/analytics/set',
  uploadAnnouncementFile: '/geturl/announcements/files/upload',
  downloadAnnouncementFile: '/announcements/files/download',

  createAssignment: '/assignments/v2/create',
  editUnpublishedAssignment: '/assignments/edit',
  editPublishedAssignment: '/assignments/edit/published',
  publishAssignment: '/assignments/v2/publish',
  deleteAssignment: '/assignments/remove',
  getAssignmentData: '/assignments/get',
  addAssignmentQuestion: '/assignments/v2/questions/add',
  editAssignmentQuestion: '/assignments/v2/questions/edit',
  deleteAssignmentQuestion: '/assignments/questions/remove',
  uploadAssignmentFileSubmission: '/geturl/assignments/submissions/upload',
  saveAssignmentFileSubmission: '/confirm/assignments/submissions/upload',
  saveAssignmentURLSubmission: '/confirm/assignments/submissions/url',
  submitAssignment: '/assignments/submit',
  retractAssignment: '/assignments/retract',
  getAssignmentAnalyticsForCourseTeam: (assignmentId: MongoId) =>
    `/assignments/analytics/submissions/all/${assignmentId}`,
  getIndividualAssignmentSubmission: (assignmentId: MongoId, studentId: MongoId) =>
    `/assignments/analytics/submissions/individual/${assignmentId}/${studentId}`,
  saveAssignmentGrades: '/assignments/analytics/grades/save',
  submitAssignmentGrades: '/assignments/analytics/grades/submit',
  uploadAssignmentFile: 'geturl/assignments/files/upload',
  downloadAssignmentFile: '/assignments/files/download',
  // For student
  downloadOwnAssignmentSubmission: '/assignments/submissions/own/download',
  // For course team
  downloadStudentAssignmentSubmission: 'assignments/submissions/student/download',

  feedback: '/feedback',

  savePublishPrefs: '/activities/save/publish-pref',

  getAllQuizzes: '/archives/get/quizzes',
  createQuiz: '/quizzes/create',
  editQuiz: '/quizzes/edit',
  editPublishedQuiz: '/quizzes/v3/edit/published',
  getQuizDetails: '/quizzes/get',
  deleteQuiz: '/quizzes/remove',
  publishQuiz: '/quizzes/v5/publish',
  uploadQuizFile: '/geturl/quizzes/files/upload',
  downloadQuizFile: '/quizzes/files/download',
  saveQuizQuestionResponse: '/quizzes/v2/question/response/save',
  saveQuizSubmission: '/quizzes/v2/save',
  submitQuiz: '/quizzes/v3/submit',
  stopQuiz: '/quizzes/stop',
  addQuizQuestion: '/quizzes/v2/questions/add',
  editQuizQuestion: '/quizzes/v2/questions/edit',
  reorderQuizQuestions: '/quizzes/questions/reorder',
  deleteQuizQuestion: '/quizzes/questions/remove',
  getQuizQuestionStats: (quizId: MongoId, questionId: MongoId) =>
    `quizzes/analytics/questions/${quizId}/${questionId}`,
  getQuizSubmissions: (quizId: MongoId) => `/quizzes/analytics/submissions/all/${quizId}`,
  getIndividualQuizSubmission: (quizId: MongoId, studentId: MongoId) =>
    `quizzes/analytics/submissions/individual/${quizId}/${studentId}`,

  getAllPolls: '/archives/get/polls',
  createPoll: '/polls/create',
  editPoll: '/polls/edit',
  deletePoll: '/polls/remove',
  addPollQuestion: '/polls/questions/add',
  editPollQuestion: '/polls/questions/edit',
  publishPoll: '/polls/v4/publish',
  editPublishedPoll: '/polls/edit/published',
  uploadPollFile: '/geturl/polls/files/upload',
  downloadPollFile: '/polls/files/download',
  getPollDetails: '/polls/get',
  submitPoll: '/polls/v2/submit',
  stopPoll: '/polls/stop',
  getPollSubmissions: (pollId: MongoId) => `polls/analytics/get/${pollId}`,

  getAllResources: '/archives/get/resources',
  createResource: '/resources/create',
  editResource: '/resources/edit',
  deleteResource: '/resources/remove',
  publishResource: '/resources/v2/publish',
  editPublishedResource: '/resources/edit/published',
  uploadResourceFile: '/geturl/resources/files/upload',
  downloadResourceFile: '/resources/files/download',
  incrementResourceViews: '/resources/analytics/set',
  getResourceAnalytics: (resourceId: MongoId) => `/resources/analytics/get/${resourceId}`,

  getAllDiscussions: '/archives/get/discussions',
  createDiscussion: '/discussions/create',
  editDiscussion: '/discussions/edit',
  deleteDiscussion: '/discussions/remove',
  getDiscussionPrefs: '/discussions/preference',
  publishDiscussion: '/discussions/v3/publish',
  editPublishedDiscussion: '/discussions/edit/published',
  uploadDiscussionFile: 'geturl/discussions/files/upload',
  downloadDiscussionFile: '/discussions/files/download',
  markDiscussionAsViewed: '/discussions/analytics/set',
  createWordCloud: '/discussions/wordcloud',
  editWordCloud: '/discussions/wordcloud/edit',
  getWordCloudDetails: (discussionId: MongoId) => `/discussions/wordcloud/${discussionId}`,

  getAllQueries: '/archives/get/queries',
  createQuery: '/queries/create',
  approveQuery: '/queries/approve',
  deleteQuery: '/queries/remove',
  markQueryAsViewed: '/queries/analytics/set',
  uploadQueryFile: 'geturl/queries/files/upload',
  downloadQueryFile: '/queries/files/download',
  upvoteQuery: (queryId: MongoId) => `/queries/upvote/${queryId}`,
  hideQuery: '/queries/hide',
  closeQuery: '/queries/close',
} as const);

export default urls;
