import { MouseEventHandler } from 'react';

import Award from '../Actions/Award';
import Delete from '../Actions/Delete';
import Like from '../Actions/Like';
import Thank from '../Actions/Thank';
import { commentActionsClasses, Root } from './styles';

interface Props {
  commentId: MongoId;
  onAwardClick?: MouseEventHandler;
  className?: string;
}

const CommentActions = ({ commentId, onAwardClick, className }: Props) => {
  const stopPropagation: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  return (
    <Root variant="text" size="small" className={className} onClick={stopPropagation}>
      <Award
        commentId={commentId}
        onClick={onAwardClick}
        classes={{ action: commentActionsClasses.action }}
      />
      <Like commentId={commentId} classes={{ action: commentActionsClasses.action }} />
      <Thank commentId={commentId} classes={{ action: commentActionsClasses.action }} />
      <Delete commentId={commentId} classes={{ action: commentActionsClasses.action }} />
    </Root>
  );
};

export default CommentActions;
