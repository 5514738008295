export enum CreateCoursePermission {
  NOT_ALLOWED,
  ALLOWED,
  PROVISIONAL,
}

export enum NextScreen {
  SSO = 'sso',
  CHANGE_SERVER = 'changeServer',
  GET_ROLE_UE = 'getRoleUE',
  GET_ROLE_UNE = 'getRoleUNE',
  VERIFICATION_AWAITED = 'verificationAwaited',
  GOTO_LOGIN = 'goToLogin',
  PROFILE_NOT_COMPLETED = 'profileNotCompleted',
  LANDING_PAGE = 'landingPage',
  THANK_YOU = 'ThankYou',
  GET_PASSWORD = 'getPassword',
  PASSWORD_NOT_SET = 'passwordNotSet',
  FORGOT_PASSWORD = 'forgotPassword',
  SET_NEW_PASSWORD = 'SetNewPassword',
  RESET_PASSWORD = 'ResetPassword',
  WINDOW_EXPIRED = 'setPasswordWindowExpired',
}

export enum AuthScreen {
  DEFAULT = 'default', // show route based screen
  SSO = 'sso',
  PASSWORD = 'password',
  FORGOT_PASSWORD = 'forgot_password',
  RESET_PASSWORD = 'reset_password',
  VERIFY_EMAIL = 'verify_email',
  SET_PASSWORD = 'set_password',
  SET_NAME = 'set_name',
  JOIN_CODE = 'join_code',
  CREATE_TRIAL_ACCOUNT = 'create_trial_account',
  INSTRUCTOR_VERIFICATION = 'instructor_verification', // when university does not exist
  VERIFICATION_AWAITED = 'verification_awaited',
  SET_PROFILE_AVATAR = 'set_profile_avatar',
  THANK_YOU = 'thank_you',
}

export interface SsoCredentials {
  authUrl: string;
  sessionId: string;
}

export interface UserRegistration {
  userId: MongoId;
  name: string;
  email: string;
  avatar: MongoId;
  /** No trailing slashes */
  cluster: string;
  s3BucketURL: BucketURL;
  userExists: boolean;
  canCreateCourses: CreateCoursePermission;
  profileCompleted: boolean;
  university: {
    name: string;
    slug: string;
  };
  verified: boolean;
}

export interface AuthCredentials {
  token?: string;
  socket?: {
    key: string;
    cluster: string;
  };
}

export interface UserSession extends UserRegistration, AuthCredentials {}

export enum SignupTeacherRole {
  FACULTY = 'faculty',
  TA = 'ta',
  STAFF = 'staff',
  STUDENT = 'student',
}

export enum SignupTeacherOrganization {
  UNIVERSITY = 'university',
  K12 = 'k12',
  COMPANY = 'company',
  VOCATIONAL = 'vocational',
  NON_PROFIT = 'nonProfit',
  INDEPENDENT = 'independent',
}

export enum SignupTeacherUseCase {
  IN_PERSON_COURSE = 'inPersonCourse',
  LIVE_ONLINE_COURSE = 'liveOnlineCourse',
  ASYNC_COURSE = 'asyncCourse',
  STANDALONE_EVENT = 'standaloneEvent',
  INT_ONLINE_TRAINING = 'intOnlineTraining',
  INT_OFFLINE_TRAINING = 'intOfflineTraining',
  CUST_ONLINE_TRAINING = 'custOnlineTraining',
  CUST_OFFLINE_TRAINING = 'custOfflineTraining',
  NA = 'na',
}
