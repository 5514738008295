import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import urls from '../../../../app/urls';
import { uploadImage } from '../../../../components/RichText/helpers';
import { createComment } from '../../../../db/comments/actions';
import { FileAttachment } from '../../../../db/shared/types';
import { fileToBase64, getFileNameComponents } from '../../../../utils/file';
import { useFileUpload } from '../../../../utils/hooks';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { FormValues } from './types';
import { validationSchema } from './validators';

export interface Props {
  open: boolean;
  onClose: (message: string, hasSubmitted?: boolean) => void;
  initialMessage: string;
  imageFile: File | null;
  attachmentFile: File | null;
}

const useCommentComposerDrawerVM = ({ open, onClose, initialMessage, imageFile, attachmentFile }: Props) => {
  const requestDispatch = useRequestDispatch();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      message: '',
      image: null,
      attachments: [],
    },
    resolver: joiResolver(validationSchema),
    mode: 'all',
  });

  const { setValue, getValues, reset } = formMethods;

  const { onUpload } = useFileUpload({
    requestConfig: {
      url: urls.uploadCommentFile,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    let uploadedImage = '';

    if (imageFile) {
      const response = await uploadImage(imageFile);
      uploadedImage = response.url;
    }

    let uploadedFiles: FileAttachment[] = [];

    if (attachmentFile) {
      const response = await onUpload(attachmentFile);
      if ('error' in response) return;
      const { name, type, originalName } = response;
      uploadedFiles.push({
        name,
        extension: type,
        originalName,
      });
    }

    await requestDispatch(createComment, {
      message: data.message,
      image: uploadedImage,
      files: uploadedFiles,
    });

    reset();
    handleCloseDrawer(true);
  };

  const handleCloseDrawer = (hasSubmitted?: boolean) => {
    setValue('image', null);
    setValue('attachments', []);

    const { message } = getValues();
    onClose(message, hasSubmitted);
  };

  useEffect(
    function setInitialMessage() {
      if (!open) return;
      setValue('message', initialMessage, { shouldValidate: true });
    },
    [open, initialMessage, setValue]
  );

  useEffect(
    function setInitialImage() {
      if (!open) return;

      if (!imageFile) {
        setValue('image', null, { shouldValidate: true });
        return;
      }

      fileToBase64(imageFile).then((base64) => {
        setValue('image', base64, { shouldValidate: true });
      });
    },
    [open, imageFile, setValue]
  );

  useEffect(
    function setInitialAttachment() {
      if (!open) return;

      if (!attachmentFile) {
        setValue('attachments', [], { shouldValidate: true });
        return;
      }

      const { name, extension } = getFileNameComponents(attachmentFile.name);
      setValue('attachments', [{ name, extension, originalName: name }], { shouldValidate: true });
    },
    [open, attachmentFile, setValue]
  );

  return {
    formMethods,
    onSubmit,
    handleCloseDrawer,
  };
};

export default useCommentComposerDrawerVM;
