import { forwardRef, Ref } from 'react';

import { Stack, StackProps, styled } from '@mui/material';

export const Root = styled(
  forwardRef((props: StackProps, ref: Ref<HTMLDivElement>) => (
    <Stack direction="column-reverse" gap={2} {...props} ref={ref} />
  ))
)(({ theme }) => ({
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: theme.spacing(2),
  '& > :first-of-type': {
    /**
     * When content is smaller than container's height,the content should be pushed to the top of the container (to satisfy design requirements)
     * And when content is bigger than container's height, a vertical scrollbar should appear.
     *
     * To achieve the above requirement, we can have these properties on Root:
     * display: 'flex',
     * flexDirection: 'column-reverse',
     * justifyContent: 'flex-end',
     * overflow: 'auto' | 'scroll',
     * height: any valid value
     *
     * But justifyContent: 'flex-end' along with flexDirection: 'column-reverse',
     * prevents overflow: 'auto' to have any impact on atleast google chrome.
     *
     * So, to avoid usage of justifyContent: 'flex-end' along with flexDirection: 'column-reverse',
     * we introduce marginBottom: 'auto' on last child (technically first child due to column-reverse) of the Root.
     * @see https://stackoverflow.com/a/37515194/2284240
     */
    marginBottom: 'auto',
  },
}));
