import { useState } from 'react';

const useDeleteCoursesVM = () => {
  const [isDeleteCoursesDrawerOpen, setIsDeleteCoursesDrawerOpen] = useState(false);

  const handleCloseDeleteCoursesDrawer = () => {
    setIsDeleteCoursesDrawerOpen(false);
  };

  const handleDeleteCourses = () => {
    setIsDeleteCoursesDrawerOpen(true);
  };

  return { isDeleteCoursesDrawerOpen, handleCloseDeleteCoursesDrawer, handleDeleteCourses };
};

export default useDeleteCoursesVM;
