import { useState } from 'react';

export interface Props {
  onClose: () => void;
}

const useCourseEndedDrawerVM = ({ onClose }: Props) => {
  const [isEditingCourseDates, setEditingCourseDates] = useState(false);

  const editCourseDates = () => {
    setEditingCourseDates(true);
  };

  const onBack = () => {
    setEditingCourseDates(false);
  };

  const closeDrawer = () => {
    setEditingCourseDates(false);
    onClose();
  };

  return {
    closeDrawer,
    editCourseDates,
    goBack: onBack,
    isEditingCourseDates,
  };
};

export default useCourseEndedDrawerVM;
