import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { i18nNS } from '../../../../i18n';
import routes from '../../../../pages/routes';
import { Title as StyledTitle } from './styles';
import useTitleVM from './vm';

const Title = () => {
  const { t } = useTranslation([
    i18nNS.COURSES,
    i18nNS.ASSIGNMENT,
    i18nNS.CLASS,
    i18nNS.QUIZ,
    i18nNS.POLL,
    i18nNS.DISCUSSION,
    i18nNS.RESOURCE,
    i18nNS.QUERY,
    i18nNS.GLOSSARY,
  ]);

  const { currentCourse } = useTitleVM();

  return (
    <StyledTitle>
      <Switch>
        <Route exact path={[routes.home.path, routes.archives.path, routes.settings.path]}>
          <>{t('faq', { ns: i18nNS.GLOSSARY })}</>
        </Route>
        <Route
          exact
          path={[routes.timeline.path, routes.info.path, routes.syllabus.path, routes.courseAnalytics.path]}
        >
          {currentCourse && !currentCourse.status.hasEnrollments ? (
            <>{t('help', { ns: i18nNS.GLOSSARY })}</>
          ) : (
            <>{t('course_discussion', { ns: i18nNS.COURSES })}</>
          )}
        </Route>
        <Route
          exact
          path={[routes.assignmentPage.path, routes.newAssignment.path, routes.assignmentAnalytics.path]}
        >
          <>{t('assignment_discussion', { ns: i18nNS.ASSIGNMENT })}</>
        </Route>
        <Route
          exact
          path={[
            routes.activities.path,
            routes.agenda.path,
            routes.attendance.path,
            routes.classAnalytics.path,
            routes.newQuery.path,
          ]}
        >
          <>{t('class_discussion', { ns: i18nNS.CLASS })}</>
        </Route>
        <Route exact path={[routes.quiz.path, routes.newQuiz.path, routes.quizAnalytics.path]}>
          <>{t('quiz_discussion', { ns: i18nNS.QUIZ })}</>
        </Route>
        <Route exact path={[routes.poll.path, routes.newPoll.path, routes.pollAnalytics.path]}>
          <>{t('poll_discussion', { ns: i18nNS.POLL })}</>
        </Route>
        <Route
          exact
          path={[routes.discussion.path, routes.newDiscussion.path, routes.discussionWordCloud.path]}
        >
          <>{t('discussion_comments', { ns: i18nNS.DISCUSSION })}</>
        </Route>
        <Route exact path={[routes.resource.path, routes.newResource.path, routes.resourceAnalytics.path]}>
          <>{t('resource_discussion', { ns: i18nNS.RESOURCE })}</>
        </Route>
        <Route exact path={[routes.query.path]}>
          <>{t('query_discussion', { ns: i18nNS.QUERY })}</>
        </Route>
      </Switch>
    </StyledTitle>
  );
};

export default Title;
