import { Skeleton, Stack } from '@mui/material';

import { Props as TextFieldBaseProps } from './index';
import { StyledInputLabel } from './styles';

type Size = TextFieldBaseProps['size'];

const calculateInputHeight = (size: Size) => {
  switch (size) {
    case 'xLarge':
      return 57;
    case 'large':
      return 49;
    case 'medium':
      return 41;
    case 'small':
      return 33;
    default:
      return 33;
  }
};

export interface Props {
  /** @default false */
  hasLabel?: boolean;
  /** @default 'medium' */
  size?: Size;
  className?: string;
}

const TextFieldBaseSkeleton = ({ hasLabel = false, size = 'medium', className }: Props) => {
  return (
    <Stack gap={0.5} className={className}>
      {hasLabel && (
        <StyledInputLabel>
          <Skeleton variant="text" width={80} />
        </StyledInputLabel>
      )}
      <Skeleton variant="rounded" width="100%" height={calculateInputHeight(size)} />
    </Stack>
  );
};

export default TextFieldBaseSkeleton;
