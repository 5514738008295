import { Stack, StackProps, styled } from '@mui/material';

export const Root = styled((props: StackProps) => <Stack {...props} />)(() => ({
  height: '100%',
}));

export const Iframe = styled('iframe')(() => ({
  border: 'none',
  width: '100%',
  flex: 1,
}));
