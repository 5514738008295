export type ColorName = 'blue' | 'green' | 'orange' | 'red' | 'grey';
export type ColorVariant = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
export type ConstantColors = 'white' | 'black';

export const colorPalette: Record<ColorName, Record<ColorVariant, string>> & Record<ConstantColors, string> =
  Object.freeze({
    blue: {
      50: '#f2f8fe',
      100: '#e6f1fd',
      200: '#bfdcfa',
      300: '#99c7f6',
      400: '#4d9cf0',
      500: '#0072e9',
      600: '#0067d2',
      700: '#0056af',
      800: '#00448c',
      900: '#003872',
    },
    green: {
      50: '#f2f9f7',
      100: '#e6f3ef',
      200: '#bfe1d8',
      300: '#99cec1',
      400: '#4daa92',
      500: '#008563',
      600: '#007859',
      700: '#00644a',
      800: '#00503b',
      900: '#004131',
    },
    orange: {
      50: '#fcf6f4',
      100: '#f9eee9',
      200: '#f0d4c9',
      300: '#e7baa9',
      400: '#d68668',
      500: '#c45227',
      600: '#b04a23',
      700: '#933e1d',
      800: '#763117',
      900: '#602813',
    },
    red: {
      50: '#fef2f6',
      100: '#fde6ed',
      200: '#fabfd2',
      300: '#f699b7',
      400: '#f04d82',
      500: '#e9004c',
      600: '#d20044',
      700: '#af0039',
      800: '#8c002e',
      900: '#720025',
    },
    grey: {
      50: '#f8fafc',
      100: '#f1f5f9',
      200: '#e2e8f0',
      300: '#cbd5e1',
      400: '#94a3b8',
      500: '#64748b',
      600: '#475569',
      700: '#334155',
      800: '#1e293b',
      900: '#0f172a',
    },
    white: '#ffffff',
    black: '#000000',
  });

const fontFamily = 'Inter';

export const fontWeight = Object.freeze({
  regular: 400,
  medium: 500,
  bold: 700,
});

export const baseTypographyStyles: Record<string, React.CSSProperties> = {
  display: {
    fontFamily,
    fontSize: '2.75rem',
    lineHeight: 1.45,
  },
  h1: {
    fontFamily,
    fontSize: '2.25rem',
    lineHeight: 1.33,
  },
  h2: {
    fontFamily,
    fontSize: '2rem',
    lineHeight: 1.5,
  },
  h3: {
    fontFamily,
    fontSize: '1.75rem',
    lineHeight: 1.29,
  },
  h4: {
    fontFamily,
    fontSize: '1.5rem',
    lineHeight: 1.33,
  },
  h5: {
    fontFamily,
    fontSize: '1.25rem',
    lineHeight: 1.4,
  },
  h6: {
    fontFamily,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  paragraph: {
    fontFamily,
    fontSize: '0.875rem',
    lineHeight: 1.43,
  },
  link: {
    fontFamily,
    fontSize: '0.875rem',
    lineHeight: 1.42,
    textDecoration: 'underline',
  },
  title: {
    fontFamily,
    fontSize: '0.75rem',
    lineHeight: 1.5,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  textSm: {
    fontFamily,
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  textXs: {
    fontFamily,
    fontSize: '0.625rem',
    lineHeight: 1.6,
  },
};
