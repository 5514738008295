import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from '@mui/material';

import Joi from 'joi';

import PasswordTextField from '../../components/HookFormControls/PasswordTextField';
import TextField from '../../components/HookFormControls/TextField';
import { optionalString, requiredEmail, requiredInteger, requiredString } from '../../utils/validators';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  age: number;
  disabledAge: string;
}

const schema = Joi.object<FormValues, true>({
  firstName: requiredString,
  lastName: optionalString,
  email: requiredEmail,
  password: requiredString,
  age: requiredInteger.min(18).max(60),
  disabledAge: optionalString,
});

const FormTextField = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: {
      // firstName: '',
      lastName: 'Sherathiya',
      email: '',
      password: '',
      age: 0,
      disabledAge: '',
    },
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        control={control}
        name="firstName"
        defaultValue="Vishal"
        required
        label="First Name"
        size="large"
        placeholder="Enter your first name"
        helperText="Use this field to enter your first name"
        characterLimit={80}
        fullWidth
        // disabled
        // error
        // helperIcon={<TextFieldHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <TextField
        control={control}
        name="lastName"
        label="Last Name"
        size="large"
        placeholder="Enter your last name"
        fullWidth
      />
      <br />
      <br />
      <TextField
        control={control}
        name="email"
        type="email"
        required
        label="Email"
        size="large"
        placeholder="Enter your email address"
        fullWidth
      />
      <br />
      <br />
      <PasswordTextField
        control={control}
        name="password"
        required
        label="Password"
        size="large"
        placeholder="Enter your password"
        fullWidth
      />
      <br />
      <br />
      <TextField
        control={control}
        name="age"
        type="number"
        required
        label="Age"
        size="large"
        placeholder="Enter your age"
        fullWidth
      />
      <br />
      <br />
      <TextField
        control={control}
        name="disabledAge"
        type="number"
        required
        label="Disabled field example"
        size="large"
        placeholder="Enter your age"
        disabled
        fullWidth
      />
      <br />
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormTextField;
