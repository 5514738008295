import { ReactNode } from 'react';

import { styled } from '@mui/material';

import UserAvatar from '../../components/UserAvatar/index';
import { generateClasses } from '../../utils/helpers';

const classes = {
  ...generateClasses('NotificationContent', ['avatar']),
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  [`& .${classes.avatar}`]: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 8,
  },
}));

interface NotificationContentProps {
  avatar?: string;
  content: ReactNode;
}

const NotificationContent = ({ avatar, content }: NotificationContentProps) => {
  return (
    <Root>
      {avatar && <UserAvatar avatarId={avatar} variant="rounded" className={classes.avatar} />}
      <div>{content}</div>
    </Root>
  );
};

export default NotificationContent;
