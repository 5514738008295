import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppBar as MuiAppBar, AppBarProps, styled, Typography } from '@mui/material';

import Tabs from '../../../components/Tabs';
import Tab from '../../../components/Tabs/Tab';
import { i18nNS } from '../../../i18n';
import routes from '../../../pages/routes';
import { CourseAnalyticsTab } from './types';
import useCourseAnalyticsHeaderVM from './vm';

const AppBar = styled((props: AppBarProps) => (
  <MuiAppBar {...props} component="div" elevation={0} position="relative" />
))(({ theme }) => ({
  minHeight: 42,
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.paper,
}));

const CourseAnalyticsHeader = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.GLOSSARY]);
  const { activeTab, params } = useCourseAnalyticsHeaderVM();
  return (
    <AppBar>
      <Tabs value={activeTab} aria-label={t('course_analytics_tabs', { ns: i18nNS.COURSES })} centered>
        <Tab
          component={Link}
          value={CourseAnalyticsTab.STUDENTS}
          to={routes.courseAnalytics.url(params)}
          label={<Typography component="span">{t('students', { ns: i18nNS.GLOSSARY })}</Typography>}
        />
        <Tab
          component={Link}
          value={CourseAnalyticsTab.AVERAGES}
          to={routes.courseAverages.url(params)}
          label={<Typography component="span">{t('averages', { ns: i18nNS.GLOSSARY })}</Typography>}
        />
      </Tabs>
    </AppBar>
  );
};

export default CourseAnalyticsHeader;
