import { styled, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import clsx from 'clsx';

import { generateClasses } from '../../utils/helpers';

interface Props {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  label: string;
  count: number;
  /** @default 'primary' */
  color?: 'primary' | 'success' | 'error' | 'warning';
}

const statusClasses = {
  ...generateClasses('Status', ['colorPrimary', 'colorSuccess', 'colorError', 'colorWarning', 'badge']),
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2, 1, 1),
  border: '1px solid transparent',
  borderRadius: 4,
  [`&.${statusClasses.colorPrimary}`]: {
    color: theme.palette.primary[900],
    backgroundColor: theme.palette.primary[100],
    borderColor: theme.palette.primary[200],
    [`& .${statusClasses.badge}`]: {
      backgroundColor: theme.palette.primary[500],
    },
  },
  [`&.${statusClasses.colorSuccess}`]: {
    color: theme.palette.success[900],
    backgroundColor: theme.palette.success[100],
    borderColor: theme.palette.success[200],
    [`& .${statusClasses.badge}`]: {
      backgroundColor: theme.palette.success[500],
    },
  },
  [`&.${statusClasses.colorError}`]: {
    color: theme.palette.error[900],
    backgroundColor: theme.palette.error[100],
    borderColor: theme.palette.error[200],
    [`& .${statusClasses.badge}`]: {
      backgroundColor: theme.palette.error[500],
    },
  },
  [`&.${statusClasses.colorWarning}`]: {
    color: theme.palette.warning[900],
    backgroundColor: theme.palette.warning[100],
    borderColor: theme.palette.warning[200],
    [`& .${statusClasses.badge}`]: {
      backgroundColor: theme.palette.warning[500],
    },
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const Badge = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  padding: theme.spacing(0, 1),
  borderRadius: 5,
}));

const Status = ({ Icon, label, count, color = 'primary' }: Props) => {
  if (!count) return null;

  return (
    <Root
      className={clsx({
        [statusClasses.colorPrimary]: color === 'primary',
        [statusClasses.colorSuccess]: color === 'success',
        [statusClasses.colorError]: color === 'error',
        [statusClasses.colorWarning]: color === 'warning',
      })}
    >
      <Icon color="inherit" fontSize="medium" />
      <Label variant="h6Medium" color="inherit">
        {label}
      </Label>
      <Badge className={statusClasses.badge} variant="textXsBold">
        {count}
      </Badge>
    </Root>
  );
};

export default Status;
