import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const BulbIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12 7C10.9506 7 9.92778 7.33019 9.07645 7.94379C8.22512 8.55739 7.58844 9.4233 7.25658 10.4189C6.92473 11.4144 6.91453 12.4892 7.22743 13.4908C7.54033 14.4925 8.16047 15.3704 9 16V20C9 20.5304 9.21071 21.0391 9.58579 21.4142C9.96086 21.7893 10.4696 22 11 22H13C13.5304 22 14.0391 21.7893 14.4142 21.4142C14.7893 21.0391 15 20.5304 15 20V16C15.8395 15.3704 16.4597 14.4925 16.7726 13.4908C17.0855 12.4892 17.0753 11.4144 16.7434 10.4189C16.4116 9.4233 15.7749 8.55739 14.9236 7.94379C14.0722 7.33019 13.0494 7 12 7Z"
          fill="currentColor"
        />
        <path
          d="M12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6Z"
          fill="currentColor"
        />
        <path
          d="M21 11H19C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12C18 12.2652 18.1054 12.5196 18.2929 12.7071C18.4804 12.8946 18.7348 13 19 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
          fill="currentColor"
        />
        <path
          d="M5 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H5C5.26522 13 5.51957 12.8946 5.70711 12.7071C5.89464 12.5196 6 12.2652 6 12C6 11.7348 5.89464 11.4804 5.70711 11.2929C5.51957 11.1054 5.26522 11 5 11Z"
          fill="currentColor"
        />
        <path
          d="M7.66 6.42L6.22 5C6.02507 4.81567 5.76489 4.71634 5.49672 4.72384C5.22854 4.73134 4.97433 4.84507 4.79 5.04C4.60567 5.23493 4.50634 5.49511 4.51384 5.76328C4.52134 6.03146 4.63507 6.28567 4.83 6.47L6.27 7.86C6.36664 7.95331 6.48108 8.02619 6.6065 8.0743C6.73192 8.12241 6.86575 8.14475 7 8.14C7.1347 8.13949 7.26792 8.11176 7.39164 8.05849C7.51537 8.00522 7.62705 7.9275 7.72 7.83C7.8985 7.63482 7.99241 7.37687 7.98116 7.11261C7.96992 6.84835 7.85444 6.59931 7.66 6.42Z"
          fill="currentColor"
        />
        <path
          d="M19.19 5.05C19.0026 4.86375 18.7492 4.75921 18.485 4.75921C18.2208 4.75921 17.9674 4.86375 17.78 5.05L16.34 6.42C16.1537 6.60736 16.0492 6.86081 16.0492 7.125C16.0492 7.38919 16.1537 7.64264 16.34 7.83C16.4329 7.9275 16.5446 8.00522 16.6684 8.05849C16.7921 8.11176 16.9253 8.13949 17.06 8.14C17.3175 8.13897 17.5646 8.03868 17.75 7.86L19.19 6.47C19.2837 6.37704 19.3581 6.26644 19.4089 6.14458C19.4597 6.02272 19.4858 5.89201 19.4858 5.76C19.4858 5.62799 19.4597 5.49728 19.4089 5.37542C19.3581 5.25356 19.2837 5.14296 19.19 5.05Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7C10.9506 7 9.92778 7.33019 9.07645 7.94379C8.22512 8.55739 7.58844 9.4233 7.25658 10.4189C6.92473 11.4144 6.91453 12.4892 7.22743 13.4908C7.54033 14.4925 8.16047 15.3704 9 16V20C9 20.5304 9.21071 21.0391 9.58579 21.4142C9.96086 21.7893 10.4696 22 11 22H13C13.5304 22 14.0391 21.7893 14.4142 21.4142C14.7893 21.0391 15 20.5304 15 20V16C15.8395 15.3704 16.4597 14.4925 16.7726 13.4908C17.0855 12.4892 17.0753 11.4144 16.7434 10.4189C16.4116 9.4233 15.7749 8.55739 14.9236 7.94379C14.0722 7.33019 13.0494 7 12 7ZM13.5 14.59C13.3474 14.6781 13.2208 14.805 13.133 14.9577C13.0452 15.1105 12.9993 15.2838 13 15.46V20H11V15.46C11.0007 15.2838 10.9548 15.1105 10.867 14.9577C10.7792 14.805 10.6526 14.6781 10.5 14.59C10.0451 14.3274 9.66717 13.9499 9.40394 13.4954C9.1407 13.0409 9.00142 12.5252 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C14.9986 12.5252 14.8593 13.0409 14.5961 13.4954C14.3328 13.9499 13.9549 14.3274 13.5 14.59Z"
        fill="currentColor"
      />
      <path
        d="M12 6C12.2652 6 12.5196 5.89464 12.7071 5.70711C12.8946 5.51957 13 5.26522 13 5V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V5C11 5.26522 11.1054 5.51957 11.2929 5.70711C11.4804 5.89464 11.7348 6 12 6Z"
        fill="currentColor"
      />
      <path
        d="M21 11H19C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12C18 12.2652 18.1054 12.5196 18.2929 12.7071C18.4804 12.8946 18.7348 13 19 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z"
        fill="currentColor"
      />
      <path
        d="M5 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H5C5.26522 13 5.51957 12.8946 5.70711 12.7071C5.89464 12.5196 6 12.2652 6 12C6 11.7348 5.89464 11.4804 5.70711 11.2929C5.51957 11.1054 5.26522 11 5 11Z"
        fill="currentColor"
      />
      <path
        d="M7.66 6.42L6.22 5C6.02507 4.81567 5.76489 4.71634 5.49672 4.72384C5.22854 4.73134 4.97433 4.84507 4.79 5.04C4.60567 5.23493 4.50634 5.49511 4.51384 5.76328C4.52134 6.03146 4.63507 6.28567 4.83 6.47L6.27 7.86C6.36664 7.95331 6.48108 8.02619 6.6065 8.0743C6.73192 8.12241 6.86575 8.14475 7 8.14C7.1347 8.13949 7.26792 8.11176 7.39164 8.05849C7.51537 8.00522 7.62705 7.9275 7.72 7.83C7.8985 7.63482 7.99241 7.37687 7.98116 7.11261C7.96992 6.84835 7.85444 6.59931 7.66 6.42Z"
        fill="currentColor"
      />
      <path
        d="M19.19 5.05C19.0026 4.86375 18.7492 4.75921 18.485 4.75921C18.2208 4.75921 17.9674 4.86375 17.78 5.05L16.34 6.42C16.1537 6.60736 16.0492 6.86081 16.0492 7.125C16.0492 7.38919 16.1537 7.64264 16.34 7.83C16.4329 7.9275 16.5446 8.00522 16.6684 8.05849C16.7921 8.11176 16.9253 8.13949 17.06 8.14C17.3175 8.13897 17.5646 8.03868 17.75 7.86L19.19 6.47C19.2837 6.37704 19.3581 6.26644 19.4089 6.14458C19.4597 6.02272 19.4858 5.89201 19.4858 5.76C19.4858 5.62799 19.4597 5.49728 19.4089 5.37542C19.3581 5.25356 19.2837 5.14296 19.19 5.05Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default BulbIcon;
