import { createReducer } from '@reduxjs/toolkit';

import { UserSession } from '../types';
import { setUserSession } from './actions';
import { getSession } from './session';

export interface AuthState {
  session: UserSession | null;
}

const getInitialSession = () => {
  const sessionWithIsAuthenticated = getSession();
  if (!sessionWithIsAuthenticated) return null;

  const { isAuthenticated, ...session } = sessionWithIsAuthenticated;
  if (isAuthenticated) return session;

  const { socket, token, ...userSession } = session;
  return userSession;
};

const initialState: AuthState = {
  session: getInitialSession(),
};

const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserSession, (state, action) => {
    const { session } = action.payload;
    state.session = session;
  });
});

export default authReducer;
