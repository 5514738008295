const validation = {
  this_field_is_required: 'This field is required',
  please_enter_a_valid_email_address: 'Please enter a valid email address',
  please_enter_a_valid_phone_number: 'Please enter a valid phone number',
  this_email_address_cannot_be_found_sign_up_instead:
    'This email address cannot be found. <2>Sign up instead?</2>',
  this_email_address_already_exists_log_in_instead:
    'This email address already exists. <2>Log in instead?</2>',
  this_is_an_invalid_password: 'This is an invalid password',
  the_password_should_be_at_least_N_characters_long:
    'The password should be at least {{min}} characters long',
  invalid_join_code: 'Invalid join code',
  please_enter_your_first_name: 'Please enter your first name',
  please_select_a_day: 'Please select a day',
  please_select_one_option: 'Please select one option',
  start_time_is_greater_than_end_time: 'Start time is greater than end time',
  start_time_should_be_in_the_future: 'Start time should be in the future',
  the_start_time_should_be_earlier_than_the_end_time: 'The start time should be earlier than the end time',
  end_time_should_be_later_than_the_start_time: 'End time should be later than the start time',
  date_should_be_in_future_or_today: 'Date should be in future or today',
  this_is_an_invalid_isbn: 'This is an invalid ISBN',
  invalid_url: 'Invalid url',
  atleast_four_unique_items_required: 'Atleast 4 unique items required',
  please_fill_this_field: 'Please fill this field',
  please_enter_a_valid_you_tube_video_url: 'Please enter a valid YouTube video URL',
  passwords_don_t_match: `Passwords don't match`,
  please_selet_a_due_date: 'Please select a due date',
  start_date_should_be_ealier_than_end_date: 'Start date should be ealier than end date',
  end_date_should_be_later_than_start_date: 'End date should be later than start date',
  query_needs_to_have_a_title_please_add_a_title_and_try_again:
    'Query needs to have a title. Please add a title and try again.',
  query_needs_to_have_a_description_please_add_a_description_and_try_again:
    'Query needs to have a description. Please add a description and try again.',
  invaid_card: 'Invalid card',
  incomplete_card: 'Incomplete card',
  coupon_code_should_be_N_characters: 'Coupon code should be {{count}} characters',
};

export default validation;
