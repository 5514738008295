import Joi from 'joi';

import i18n, { i18nNS } from '../../../i18n';
import { requiredString } from '../../../utils/validators';
import { MIN_PASSWORD_LENGTH } from '../../constants';

export const validationSchema = Joi.object({
  currentPassword: requiredString,
  newPassword: requiredString.min(MIN_PASSWORD_LENGTH).messages({
    ...requiredString.messages,
    'string.min': i18n.t('the_password_should_be_at_least_N_characters_long', {
      ns: i18nNS.VALIDATION,
      min: MIN_PASSWORD_LENGTH,
    }),
  }),
  confirmNewPassword: requiredString.valid(Joi.ref('newPassword')).messages({
    ...requiredString.messages,
    'any.only': i18n.t('passwords_don_t_match', { ns: i18nNS.VALIDATION }),
  }),
});
