import { SvgIcon, SvgIconProps } from '@mui/material';

interface Props extends SvgIconProps {
  /** @default 'outlined' */
  variant?: 'outlined' | 'filled';
}

const CloseCircleIcon = ({ variant = 'outlined', ...props }: Props) => {
  if (variant === 'filled') {
    return (
      <SvgIcon
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM14.71 13.29C14.8037 13.383 14.8781 13.4936 14.9289 13.6154C14.9797 13.7373 15.0058 13.868 15.0058 14C15.0058 14.132 14.9797 14.2627 14.9289 14.3846C14.8781 14.5064 14.8037 14.617 14.71 14.71C14.617 14.8037 14.5064 14.8781 14.3846 14.9289C14.2627 14.9797 14.132 15.0058 14 15.0058C13.868 15.0058 13.7373 14.9797 13.6154 14.9289C13.4936 14.8781 13.383 14.8037 13.29 14.71L12 13.41L10.71 14.71C10.617 14.8037 10.5064 14.8781 10.3846 14.9289C10.2627 14.9797 10.132 15.0058 10 15.0058C9.86799 15.0058 9.73729 14.9797 9.61543 14.9289C9.49357 14.8781 9.38297 14.8037 9.29 14.71C9.19628 14.617 9.12188 14.5064 9.07111 14.3846C9.02034 14.2627 8.99421 14.132 8.99421 14C8.99421 13.868 9.02034 13.7373 9.07111 13.6154C9.12188 13.4936 9.19628 13.383 9.29 13.29L10.59 12L9.29 10.71C9.1017 10.5217 8.99591 10.2663 8.99591 10C8.99591 9.7337 9.1017 9.4783 9.29 9.29C9.47831 9.1017 9.7337 8.99591 10 8.99591C10.2663 8.99591 10.5217 9.1017 10.71 9.29L12 10.59L13.29 9.29C13.4783 9.1017 13.7337 8.99591 14 8.99591C14.2663 8.99591 14.5217 9.1017 14.71 9.29C14.8983 9.4783 15.0041 9.7337 15.0041 10C15.0041 10.2663 14.8983 10.5217 14.71 10.71L13.41 12L14.71 13.29Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  }

  return (
    <SvgIcon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.44429 3.6853C4.7998 4.78412 3.51808 6.3459 2.7612 8.17317C2.00433 10.0004 1.80629 12.0111 2.19214 13.9509C2.578 15.8907 3.5304 17.6725 4.92893 19.0711C6.32745 20.4696 8.10928 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9996 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4177 20 8.87103 19.5308 7.55543 18.6518C6.23984 17.7727 5.21446 16.5233 4.60896 15.0615C4.00346 13.5997 3.84503 11.9911 4.15371 10.4393C4.4624 8.88743 5.22432 7.46197 6.34314 6.34315C7.46196 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
        fill="currentColor"
      />
      <path
        d="M14.71 9.29C14.617 9.19627 14.5064 9.12188 14.3846 9.07111C14.2627 9.02034 14.132 8.9942 14 8.9942C13.868 8.9942 13.7373 9.02034 13.6154 9.07111C13.4936 9.12188 13.383 9.19627 13.29 9.29L12 10.59L10.71 9.29C10.5217 9.1017 10.2663 8.99591 10 8.99591C9.7337 8.99591 9.4783 9.1017 9.29 9.29C9.10169 9.4783 8.99591 9.7337 8.99591 10C8.99591 10.2663 9.10169 10.5217 9.29 10.71L10.59 12L9.29 13.29C9.19627 13.383 9.12187 13.4936 9.07111 13.6154C9.02034 13.7373 8.9942 13.868 8.9942 14C8.9942 14.132 9.02034 14.2627 9.07111 14.3846C9.12187 14.5064 9.19627 14.617 9.29 14.71C9.38296 14.8037 9.49356 14.8781 9.61542 14.9289C9.73728 14.9797 9.86799 15.0058 10 15.0058C10.132 15.0058 10.2627 14.9797 10.3846 14.9289C10.5064 14.8781 10.617 14.8037 10.71 14.71L12 13.41L13.29 14.71C13.383 14.8037 13.4936 14.8781 13.6154 14.9289C13.7373 14.9797 13.868 15.0058 14 15.0058C14.132 15.0058 14.2627 14.9797 14.3846 14.9289C14.5064 14.8781 14.617 14.8037 14.71 14.71C14.8037 14.617 14.8781 14.5064 14.9289 14.3846C14.9797 14.2627 15.0058 14.132 15.0058 14C15.0058 13.868 14.9797 13.7373 14.9289 13.6154C14.8781 13.4936 14.8037 13.383 14.71 13.29L13.41 12L14.71 10.71C14.8037 10.617 14.8781 10.5064 14.9289 10.3846C14.9797 10.2627 15.0058 10.132 15.0058 10C15.0058 9.86799 14.9797 9.73728 14.9289 9.61542C14.8781 9.49356 14.8037 9.38296 14.71 9.29Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default CloseCircleIcon;
