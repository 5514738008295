import { generatePath, match, matchPath } from 'react-router-dom';

import history from '../utils/history';
import Logger from '../utils/logger';
import { AcadlyPage, AcadlyRoute, NavigateOptions, RouteOptions } from './types';

const log = Logger.create('acadly-routes');

const sitemap = new Map<AcadlyPage, AcadlyRoute<any, any>>();

function goToRoute(pathname: string, options?: NavigateOptions) {
  if (!options) {
    history.push(pathname);
    return;
  }

  const search = new URLSearchParams(options.search || {});

  history[options.replace ? 'replace' : 'push']({
    pathname,
    search: options.search ? `?${search.toString()}` : undefined,
    state: options.state,
  });
}

export function createAcadlyRoute<P extends string, BP extends string>(
  options: RouteOptions<P, BP>
): AcadlyRoute<P, BP> {
  const route: AcadlyRoute<P, BP> = {
    ...options,
    goBack(params, navigateOptions) {
      const pathname = generatePath(options.backPath, params as any);
      goToRoute(pathname, navigateOptions);
    },
    match(exact = false) {
      return matchPath(window.location.pathname, {
        path: options.path,
        exact,
      }) as any;
    },
    navigate(params, navigateOptions) {
      const pathname = route.url(params);
      goToRoute(pathname, navigateOptions);
    },
    url(params) {
      return generatePath<P>(options.path, params as any);
    },
  };

  sitemap.set(route.name, route);
  return route;
}

interface MatchAcadlyRouteResult {
  name: AcadlyPage | null;
  match: match<{}> | null;
  route: AcadlyRoute<any, any> | null;
}

export const getAcadlyRouteByURL = (url: string): MatchAcadlyRouteResult => {
  const routes = Array.from(sitemap.entries());
  for (const [name, route] of routes) {
    const match = matchPath(url, { path: route.path, exact: true });
    if (match) return { name, match, route };
  }
  log.warn(`This route does not exist\n${url}`);
  return { name: null, match: null, route: null };
};

export const getAcadlyRouteByName = <T extends AcadlyRoute<any, any> = AcadlyRoute<any, any>>(
  name: AcadlyPage
): T | undefined => {
  return sitemap.get(name) as T;
};
