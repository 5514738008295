import { Stack } from '@mui/material';

import Spinner from '../../../../components/Spinner';
import CommentGroups from './CommentGroups';
import EmptyCommentMessage from './EmptyCommentMessage';
import LoadOlderComments from './LoadOlderComments';
import useCommentListVM from './vm';

const CommentList = () => {
  const { isFetchingComments, comments } = useCommentListVM();

  if (isFetchingComments) {
    // TODO: replace spinner with Skeleton
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" height="100%" width="100%">
        <Spinner />
      </Stack>
    );
  }

  if (comments.length === 0) {
    return <EmptyCommentMessage />;
  }

  return (
    /**
     * Parent of CommentList is CommentsScrollManager
     * CommentsScrollManager renders its content using:
     * display: 'flex'
     * flexDirection: 'column'
     *
     * Due to that, content will render in reverse order
     * So, any components appearing in return of CommentList should be provided in reverse order
     */
    <>
      <CommentGroups />
      <LoadOlderComments />
    </>
  );
};

export default CommentList;
