import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import AcadlyRadioGroup, { Props as AcadlyRadioGroupProps } from '../FormControls/RadioGroup';

type VMProps<T extends FieldValues> = UseControllerProps<T> & {
  onChange?: (value: string | null) => void;
};

const useRadioGroupVM = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  onChange: _onChange,
}: VMProps<T>) => {
  const controller = useController({ control, name, defaultValue });
  const { field, fieldState } = controller;
  const { ref: groupRef, value, onChange, onBlur } = field;
  const { error } = fieldState;

  const handleChange = (value: string | null) => {
    onChange(value);
    _onChange?.(value);
  };

  return { error, groupRef, handleChange, onBlur, value };
};

type Props<T extends FieldValues> = VMProps<T> &
  DistributiveOmit<AcadlyRadioGroupProps, 'ref' | 'groupRef' | 'name' | 'value' | 'onChange' | 'onBlur'>;

const RadioGroup = <T extends FieldValues>({ control, name, defaultValue, onChange, ...props }: Props<T>) => {
  const { error, groupRef, handleChange, onBlur, value } = useRadioGroupVM({
    control,
    name,
    defaultValue,
    onChange,
  });

  return (
    <AcadlyRadioGroup
      {...props}
      groupRef={groupRef}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      aria-invalid={Boolean(error)}
    />
  );
};

export default RadioGroup;
