import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Button from '../../../../components/Button';
import TextField from '../../../../components/HookFormControls/TextField';
import Typography from '../../../../components/Typography';
import { i18nNS } from '../../../../i18n';
import CoursePaymentLayout from '../Layout';
import useCouponCode from './vm';

const CouponCode = () => {
  const { t } = useTranslation([i18nNS.COURSES, i18nNS.COMMON, i18nNS.GLOSSARY]);

  const { isVisible, handleCloseDrawer, formMethods, onSubmit } = useCouponCode();
  const { handleSubmit, control, formState } = formMethods;
  const { isSubmitting } = formState;

  return (
    <CoursePaymentLayout
      isVisible={isVisible}
      onSubmit={handleSubmit(onSubmit)}
      footer={
        <>
          <Button
            variant="text"
            onClick={handleCloseDrawer}
            aria-label={t('click_to_close_drawer', { ns: i18nNS.COMMON })}
          >
            {t('cancel', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            aria-label={t('click_to_submit_coupon', { ns: i18nNS.COURSES })}
          >
            {t('submit', { ns: i18nNS.GLOSSARY })}
          </Button>
        </>
      }
    >
      <Stack gap={4}>
        <Typography variant="paragraphRegular">
          {t('please_enter_the_coupon_you_have_purchased_from_the_book_store', { ns: i18nNS.COURSES })}
        </Typography>
        <TextField control={control} name="couponCode" characterLimit={10} />
      </Stack>
    </CoursePaymentLayout>
  );
};

export default CouponCode;
