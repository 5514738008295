import en from './en';

type Translation = {
  [key in keyof typeof en]: string;
};

type PollTranslations = {
  [lang: string]: Translation;
};

export const pollTranslations: PollTranslations = {
  en,
};
