import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import {
  DialogActions as MuiDialogActions,
  List as MuiList,
  ListItem,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
  Slide,
  styled,
  Typography,
} from '@mui/material';

import { i18nNS } from '../../i18n';
import DownloadIcon from '../../icons/DownloadIcon';
import EmailIcon from '../../icons/EmailIcon';
import { generateClasses } from '../../utils/helpers';
import Button from '../Button';
import Header from '../Header';
import Progressbar from '../Progressbar';
import { ExportType } from './types';

const exportStepClasses = {
  ...generateClasses('ExportStep', ['backButton']),
};

const BackButton = styled(Button)(({ theme }) => ({
  [`&.${exportStepClasses.backButton}`]: {
    color: theme.palette.grey[500],
  },
}));

const List = styled(MuiList)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 0,
  color: theme.palette.grey[500],
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  color: theme.palette.grey[800],
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  padding: theme.spacing(4, 5, 5),
}));

export interface Props {
  title: string;
  onBack?: () => void;
  onClose: () => void;
  onSelect: (selectedExportType: ExportType) => Promise<void>;
}

const ExportStep = ({ title, onBack, onClose, onSelect }: Props) => {
  const { t } = useTranslation([i18nNS.COMMON, i18nNS.GLOSSARY]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState<ExportType | null>(null);

  const handleSelectOption = (selectedExportType: ExportType) => async () => {
    try {
      setIsLoading(true);
      setSelectedExportType(selectedExportType);
      await onSelect(selectedExportType);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header
        left={
          onBack
            ? () => (
                <BackButton
                  variant="text"
                  size="small"
                  edge="start"
                  className={exportStepClasses.backButton}
                  onClick={onBack}
                  aria-label={t('click_to_go_back', { ns: i18nNS.COMMON })}
                >
                  <ArrowBackIcon color="inherit" aria-hidden />
                </BackButton>
              )
            : undefined
        }
      >
        {title}
      </Header>
      <Slide in direction="left" timeout={onBack ? 300 : 0}>
        <List aria-hidden disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleSelectOption(ExportType.CSV_DOWNLOAD)}
              disabled={isLoading}
              aria-label={t('click_to_select_csv_download', { ns: i18nNS.COMMON })}
            >
              <ListItemIcon aria-hidden>
                {isLoading && selectedExportType === ExportType.CSV_DOWNLOAD ? (
                  <Progressbar shape="circular" variant="indeterminate" color="primary" size={24} />
                ) : (
                  <DownloadIcon />
                )}
              </ListItemIcon>
              <ListItemText aria-hidden>
                <Typography variant="paragraphRegular" color="inherit">
                  {t('csv_download', { ns: i18nNS.COMMON })}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleSelectOption(ExportType.EMAIL_ATTACHMENT)}
              disabled={isLoading}
              aria-label={t('click_to_select_email_attachment', { ns: i18nNS.COMMON })}
            >
              <ListItemIcon aria-hidden>
                {isLoading && selectedExportType === ExportType.EMAIL_ATTACHMENT ? (
                  <Progressbar shape="circular" variant="indeterminate" color="primary" size={24} />
                ) : (
                  <EmailIcon />
                )}
              </ListItemIcon>
              <ListItemText aria-hidden>
                <Typography variant="paragraphRegular" color="inherit">
                  {t('email_attachment', { ns: i18nNS.COMMON })}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Slide>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
          aria-label={t('click_to_close_dialog', { ns: i18nNS.COMMON })}
        >
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Button>
      </DialogActions>
    </>
  );
};

export default ExportStep;
