import { Trans, useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import Alert from '../../../components/Alert';
import Typography from '../../../components/Typography';
import { i18nNS } from '../../../i18n';
import { AuthAPI } from '../../store/api.types';
import { CourseAdminName, UniversityName } from './styles';
import useCourseInfoAlertVM, { Props as VMProps } from './vm';

interface Props extends VMProps {
  open: boolean;
  courseInfo: AuthAPI.GetCourseByCodeResponse;
}

const CourseInfoAlert = ({ open, courseInfo, ...vmOptions }: Props) => {
  const { t } = useTranslation([i18nNS.AUTH, i18nNS.GLOSSARY]);

  const { onClose: handleClose, joinCode } = vmOptions;
  const { handleJoinThisCourse, email } = useCourseInfoAlertVM(vmOptions);

  return (
    <Alert open={open}>
      <Alert.Header>{t('join_code_colon_code', { ns: i18nNS.AUTH, code: joinCode })}</Alert.Header>
      <Alert.Body>
        <Stack gap={3}>
          <Typography variant="paragraphBold" color="grey.700">
            {courseInfo?.courseCode}: {courseInfo?.title}
          </Typography>
          <Typography variant="paragraphMedium" color="grey.900" component="div">
            <UniversityName>{courseInfo?.universityName}</UniversityName>
            <CourseAdminName>{courseInfo?.admin.name}</CourseAdminName>
          </Typography>
          <Typography variant="paragraphRegular" color="grey.500">
            <Trans
              t={t}
              i18nKey="please_press_continue_to_join_this_course_with_following_email_colon_email_address"
              tOptions={{ ns: i18nNS.AUTH, email }}
            >
              Please press continue to join this course with following email:{' '}
              <Typography variant="paragraphBold" color="grey.900">
                {email}
              </Typography>
            </Trans>
          </Typography>
        </Stack>
      </Alert.Body>
      <Alert.Footer>
        <Alert.Action
          variant="text"
          onClick={handleClose}
          aria-label={t('click_to_cancel_joining_this_course', { ns: i18nNS.AUTH })}
        >
          {t('cancel', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
        <Alert.Action
          onClick={handleJoinThisCourse}
          aria-label={t('click_to_join_this_course', { ns: i18nNS.AUTH })}
        >
          {t('continue', { ns: i18nNS.GLOSSARY })}
        </Alert.Action>
      </Alert.Footer>
    </Alert>
  );
};

export default CourseInfoAlert;
