import { createPusherEvent } from '../../pusher/helpers';
import { ClassPusherEvent, PusherChannel, PusherEventName } from '../../pusher/types';
import { API } from '../shared/api-responses';

export type QueryCreatedEvent = ClassPusherEvent<{
  queryId: MongoId;
  details: API.BaseQuery['details'];
  stats: API.BaseQuery['stats'];
  activities: API.BaseQuery['activities'];
}>;

export const queryCreatedEvent = createPusherEvent<QueryCreatedEvent>(PusherEventName.QUERY_ADDED, {
  channels: [PusherChannel.COURSE],
});

export const anonymousQueryCreatedEvent = createPusherEvent<QueryCreatedEvent>(
  PusherEventName.APPROVE_QUERY,
  { channels: [PusherChannel.COURSE_TEAM] }
);

export const queryApprovedEvent = createPusherEvent<QueryCreatedEvent>(PusherEventName.QUERY_APPROVED, {
  channels: [PusherChannel.COURSE],
});

export type YourQueryApprovedEvent = QueryCreatedEvent;

/** received by query creator to differentiate him from other students */
export const yourQueryApprovedEvent = createPusherEvent<YourQueryApprovedEvent>(
  PusherEventName.YOUR_QUERY_APPROVED,
  { channels: [PusherChannel.USER] }
);

export type QueryUpvotedEvent = ClassPusherEvent<{
  queryId: MongoId;
}>;

export const queryUpvotedEvent = createPusherEvent<QueryUpvotedEvent>(PusherEventName.QUERY_UPVOTED, {
  channels: [PusherChannel.COURSE],
});
