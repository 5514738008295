import { useSelector } from 'react-redux';

import { selectAuthSession } from '../../auth/store/selectors';
import { selectUniversityData } from '../../db/courses/selectors';

const useInformationAlertVM = () => {
  const session = useSelector(selectAuthSession);
  const universityData = useSelector(selectUniversityData);

  return {
    universityName: session?.university.name,
    hasEnterpriseAccount: universityData?.hasEnterpriseAccount,
    integratedWith: universityData?.integratedWith,
  };
};

export default useInformationAlertVM;
