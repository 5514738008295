import { forwardRef, ReactNode, Ref } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Stack, Typography, TypographyProps } from '@mui/material';

import clsx from 'clsx';

import Button from '../Button';
import { Icon, noteClasses, Root } from './styles';

export interface Props {
  icon?: JSX.Element;
  color?: 'success' | 'error' | 'warning' | 'info';
  /** @default 'paragraphRegular' */
  variant?: TypographyProps['variant'];
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  classes?: Partial<typeof noteClasses>;
  tabIndex?: number;
}

const Note = forwardRef(
  (
    { icon, color, variant = 'paragraphRegular', children, onClose, className, classes, ...props }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <Root
        ref={ref}
        className={clsx(
          noteClasses.root,
          className,
          classes?.root,
          {
            [noteClasses.colorSuccess]: color === 'success',
            [noteClasses.colorError]: color === 'error',
            [noteClasses.colorWarning]: color === 'warning',
            [noteClasses.colorInfo]: color === 'info',
          },
          classes?.colorSuccess && { [classes.colorSuccess]: color === 'success' },
          classes?.colorError && { [classes.colorError]: color === 'error' },
          classes?.colorWarning && { [classes.colorWarning]: color === 'warning' },
          classes?.colorInfo && { [classes.colorInfo]: color === 'info' }
        )}
        {...props}
      >
        <Stack direction="row" alignItems="flex-start" gap={3} p={4}>
          <Icon className={clsx(noteClasses.icon, classes?.icon)}>{icon}</Icon>
          {typeof children === 'string' ? (
            <Typography variant={variant} className={clsx(noteClasses.text, classes?.text)}>
              {children}
            </Typography>
          ) : (
            children
          )}
        </Stack>
        {Boolean(onClose) && (
          <Button
            variant="text"
            size="small"
            color={color === 'info' ? 'primary' : color}
            className={clsx(noteClasses.closeButton, classes?.closeButton)}
            onClick={onClose}
          >
            <CloseIcon fontSize="xSmall" className={clsx(noteClasses.closeIcon, classes?.closeIcon)} />
          </Button>
        )}
      </Root>
    );
  }
);

export default Note;
