import { createRequestAction } from '../../utils/request-actions';
import { API } from '../shared/api-responses';
import { Accessibility, MarkTipAsSeenPayload, NotificationPrefs, SendFeedbackPayload, Tips } from './types';

export const fetchUserPreferences = createRequestAction<void, API.FetchUserPreferencesResponse>(
  'app/initialize'
);

export const setSortPreference = createRequestAction<
  API.SetSortPreferenceRequest,
  API.SetSortPreferenceRequest
>('app/preferences/set-sort');

export const markTipsAsSeen = createRequestAction<MarkTipAsSeenPayload, Tips>(
  'app/preferences/mark-tip-seen'
);

export const toggleTips = createRequestAction<boolean, Tips>('app/preferences/toggle-tips');

export const toggleAccessibility = createRequestAction<boolean, Accessibility>(
  'app/preferences/toggle-accessibility'
);

export const fetchNotificationPrefs = createRequestAction<void, NotificationPrefs>(
  'app/notification-prefs/fetch'
);

export const saveNotificationPrefs = createRequestAction<NotificationPrefs, NotificationPrefs>(
  'app/notification-prefs/save'
);

export const sendReferral = createRequestAction<API.SendReferralRequest>('send-referral');

export const sendFeedback = createRequestAction<SendFeedbackPayload>('feedback/send');
