import { Skeleton, Stack } from '@mui/material';

import ButtonSkeleton from '../../../components/Button/skeleton';
import Typography from '../../../components/Typography';
import { FeatureSummaryCard } from './styles';

const FeatureUpdatesDrawerSkeleton = () => {
  return (
    <Stack gap={6}>
      {new Array(3).fill(<div />).map((_, index) => (
        <FeatureSummaryCard key={index}>
          <Typography variant="paragraphRegular">
            <Skeleton variant="text" width={120} />
          </Typography>
          <Typography variant="h6Medium" component="div">
            <Skeleton variant="text" width="70%" />
          </Typography>
          <Typography variant="paragraphRegular">
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="20%" />
            <br />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
            <br />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="30%" />
          </Typography>
          <ButtonSkeleton fullWidth />
        </FeatureSummaryCard>
      ))}
    </Stack>
  );
};

export default FeatureUpdatesDrawerSkeleton;
