import { createContext, ReactNode, useContext, useMemo } from 'react';

export interface CoursePaymentContextType {
  closeDrawer: () => void;
}

const CoursePaymentContext = createContext<CoursePaymentContextType | undefined>(undefined);

export const useCoursePaymentContext = () => {
  const contextValue = useContext(CoursePaymentContext);
  if (!contextValue) {
    throw new Error('Accesing useCoursePaymentContext() outside CoursePaymentProvider is not allowed');
  }
  return contextValue;
};

interface VMProps {
  onClose: () => void;
}

const useCoursePaymentProviderVM = ({ onClose }: VMProps) => {
  return useMemo<CoursePaymentContextType>(
    () => ({
      closeDrawer: onClose,
    }),
    [onClose]
  );
};

interface Props extends VMProps {
  children: ReactNode;
}

const CoursePaymentProvider = ({ children, ...vmProps }: Props) => {
  const contextValue = useCoursePaymentProviderVM(vmProps);
  return <CoursePaymentContext.Provider value={contextValue}>{children}</CoursePaymentContext.Provider>;
};

export default CoursePaymentProvider;
