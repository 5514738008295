import { useTranslation } from 'react-i18next';

import { Stack, styled, Typography } from '@mui/material';

import Button from '../../../components/Button';
import { radioGroupClasses } from '../../../components/FormControls/RadioGroup';
import RadioOption, { radioOptionClasses } from '../../../components/FormControls/RadioGroup/RadioOption';
import RadioGroup from '../../../components/HookFormControls/RadioGroup';
import AcadlyNote from '../../../components/Note/index';
import { EnrollmentBy } from '../../../db/shared/types';
import { i18nNS } from '../../../i18n';
import AlertCircleIcon from '../../../icons/AlertCircleIcon';
import ChevronLeftIcon from '../../../icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import { generateClasses } from '../../../utils/helpers';
import CreateCourseForm from '../CreateCourseForm';
import CreateCoursePreview from '../Preview';
import useEnrollmentMethodVM from './vm';

const enrollmentMethodClasses = generateClasses('EnrollmentMethod', ['heading', 'radioLabel']);

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),

  [`.${enrollmentMethodClasses.heading}`]: {
    color: theme.palette.grey[900],
  },

  [`.${radioGroupClasses.radioGroup}`]: {
    gap: theme.spacing(4),
  },

  [`.${radioOptionClasses.root}`]: {
    alignItems: 'flex-start',
  },

  [`.${enrollmentMethodClasses.radioLabel}`]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 7,
    marginLeft: 3,
  },
}));

const Note = styled(AcadlyNote)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const EnrollmentMethod = () => {
  const { t } = useTranslation([i18nNS.GLOSSARY, i18nNS.CREATE_COURSE, i18nNS.COMMON]);
  const {
    closeDrawer,
    control,
    controlName,
    error,
    isSubmitting,
    isVisible,
    moveToPreviousStep,
    publishCourseSchedule,
  } = useEnrollmentMethodVM();

  return (
    <CreateCourseForm
      isVisible={isVisible}
      onSubmit={publishCourseSchedule}
      footer={() => (
        <Stack direction="row" justifyContent="space-between" gap={3} p={5}>
          <Button
            type="button"
            variant="text"
            startIcon={<ChevronLeftIcon />}
            onClick={moveToPreviousStep}
            aria-label={t('click_to_go_to_previous_step', { ns: i18nNS.COMMON })}
          >
            {t('back', { ns: i18nNS.GLOSSARY })}
          </Button>
          <Stack direction="row" gap={3}>
            <Button
              type="button"
              variant="text"
              onClick={closeDrawer}
              aria-label={t('click_to_close_create_course_drawer', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('cancel', { ns: i18nNS.GLOSSARY })}
            </Button>
            <Button
              type="submit"
              variant="contained"
              isLoading={isSubmitting}
              endIcon={<ChevronRightIcon />}
              aria-label={t('click_to_go_to_next_step', { ns: i18nNS.CREATE_COURSE })}
            >
              {t('done', { ns: i18nNS.GLOSSARY })}
            </Button>
          </Stack>
        </Stack>
      )}
    >
      <Root>
        <Typography variant="h5Bold">
          {t('step_4_colon_select_student_enrollment_method', {
            ns: i18nNS.CREATE_COURSE,
          })}
        </Typography>
        <RadioGroup control={control} name={controlName}>
          <RadioOption
            value={EnrollmentBy.INVITATION}
            aria-label={t('click_to_enroll_by_invitation', { ns: i18nNS.CREATE_COURSE })}
          >
            <div className={enrollmentMethodClasses.radioLabel}>
              <Typography variant="h6Bold" component="div">
                {t('enrollment_by_invitation', { ns: i18nNS.CREATE_COURSE })}
              </Typography>
              <Typography component="div">
                {t(
                  'once_the_course_is_published_you_will_be_able_to_enroll_students_by_providing_their_email_addresses_allows_for_stricter_control_over_who_can_access_the_course_content',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </Typography>
            </div>
          </RadioOption>
          <RadioOption
            value={EnrollmentBy.CODE}
            aria-label={t('click_to_enroll_by_code', { ns: i18nNS.CREATE_COURSE })}
          >
            <div className={enrollmentMethodClasses.radioLabel}>
              <Typography variant="h6Bold" component="div">
                {t('enrollment_by_code', { ns: i18nNS.CREATE_COURSE })}
              </Typography>
              <Typography component="div">
                {t(
                  'after_the_course_is_published_a_join_code_is_generated_that_can_be_used_by_students_to_enroll_in_the_course_however_anyone_with_the_join_code_will_be_able_to_access_the_course_content_as_a_student',
                  { ns: i18nNS.CREATE_COURSE }
                )}
              </Typography>
            </div>
          </RadioOption>
        </RadioGroup>
        <Note icon={<AlertCircleIcon color="inherit" />} color="warning" variant="h6Regular">
          {error
            ? error.message
            : t('the_enrollment_method_you_select_cannot_be_changed_later_please_check_before_proceeding', {
                ns: i18nNS.CREATE_COURSE,
              })}
        </Note>
        <CreateCoursePreview showTimezone showWeeklySchedule />
      </Root>
    </CreateCourseForm>
  );
};

export default EnrollmentMethod;
