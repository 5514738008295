import { createReducer } from '@reduxjs/toolkit';

import { unix } from '../../utils/datetime';
import Logger from '../../utils/logger';
import { closeCourse, fetchCourseDetails, openCourse } from '../courses/actions';
import { AnnouncementType, CourseRole, CourseTeamUser, TimelineItemType } from '../shared/types';
import { announcementCreated, createAnnouncement, markAnnouncementAsRead } from './actions';
import { addAnnouncement } from './case-reducers';
import { Announcement, AnnouncementsState } from './types';

const log = Logger.create('db/announcements');

const initialState: AnnouncementsState = {
  courseId: '',
  byId: {},
};

const announcementsReducer = createReducer(initialState, (builder) => {
  builder.addCase(openCourse, (state, action) => {
    state.courseId = action.payload.courseId;
  });

  builder.addCase(closeCourse, (state) => {
    state.courseId = '';
    state.byId = {};
  });

  builder.addCase(createAnnouncement.success, (state, action) => {
    const { _id: announcementId, ...details } = action.payload;
    addAnnouncement({
      announcementId,
      announcementsById: state.byId,
      courseId: state.courseId,
      details: { ...details, type: AnnouncementType.MANUAL },
      userData: { firstAccessedOn: details.createdOn, timesFetched: 1 },
    });
  });

  builder.addCase(announcementCreated, (state, action) => {
    const { courseId, announcementId, details } = action.payload;
    addAnnouncement({
      announcementId,
      announcementsById: state.byId,
      courseId,
      details,
      userData: undefined,
    });
  });

  builder.addCase(markAnnouncementAsRead.success, (state, action) => {
    const { announcementId: id } = action.payload;
    const announcement = state.byId[id];
    if (!announcement) {
      log.warn('Attempt to update a non existent announcement');
      return;
    }
    announcement.views++;
    announcement.firstAccessedOn = unix();
  });

  builder.addCase(fetchCourseDetails.success, (state, action) => {
    const { courseId, courseData = [], userData } = action.payload;

    state.byId = {};
    state.courseId = courseId;

    for (const item of courseData) {
      if (item.nodeType !== TimelineItemType.ANNOUNCEMENT) continue;

      const announcementUserData = userData?.announcements?.[item._id];

      const createdBy: CourseTeamUser =
        item.details.type === AnnouncementType.MANUAL
          ? item.details.createdBy
          : {
              userId: '',
              name: item.details.createdBy.name,
              avatar: 'default',
              role: CourseRole.ADMIN,
            };

      const comments: Announcement['comments'] = {
        isSubscribed: false,
        total: 0,
        seen: 0,
      };

      const announcement: Announcement = {
        id: item._id,
        courseId,
        comments,
        title: item.details.title,
        description: item.details.description,
        type: item.details.type,
        attachments: item.details.attachments || [],
        dueDateTime: item.details.dueDateTime,
        createdBy,
        createdOn: item.details.createdOn,
        editedOn: -1,
        editedBy: null,
        views: announcementUserData?.timesFetched ?? 0,
        firstAccessedOn: announcementUserData?.firstAccessedOn || -1,
      };

      state.byId[item._id] = announcement;
    }
  });
});

export default announcementsReducer;
