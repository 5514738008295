import { useState } from 'react';

import { Comment } from '../../../../../../../../../db/comments/types';
import { getCommentAttachment } from '../../../../../../../../helpers';

export interface Props {
  comment: Comment;
}

const useCommentDetailsVM = ({ comment }: Props) => {
  const attachment = getCommentAttachment(comment);

  const [canSeeCommentStats, setCanSeeCommentStats] = useState(false);

  const handleToggleCommentStats = () => {
    setCanSeeCommentStats((prevVal) => !prevVal);
  };

  return { attachment, canSeeCommentStats, handleToggleCommentStats };
};

export default useCommentDetailsVM;
