import { SubmitHandler, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';
import { Star } from '@mui/icons-material';
import { Button } from '@mui/material';

import Joi from 'joi';

import { TextFieldBaseHelperIcon } from '../../components/FormControls/TextFieldBase';
import DatePicker from '../../components/HookFormControls/Datepicker';
import { dateToUnix, unixToDate } from '../../utils/datetime';
import { requiredInteger } from '../../utils/validators';

interface FormValues {
  dob: Date | null;
  dobUnix: UnixTime | null;
}

const schema = Joi.object<FormValues, true>({
  dob: Joi.date().required(),
  dobUnix: requiredInteger,
});

const FormDatepicker = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: joiResolver(schema),
    defaultValues: {
      dob: null,
      dobUnix: null,
    },
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data, 'data >>>>>');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <DatePicker
        control={control}
        name="dob"
        required
        label="Date of Birth"
        size="large"
        placeholder="Enter your date of birth"
        helperText="Use this field to enter your date of birth"
        fullWidth
        // disabled
        // error
        // helperIcon={<TextFieldBaseHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <DatePicker
        control={control}
        name="dobUnix"
        required
        label="Date of Birth Unix"
        size="large"
        placeholder="Enter your date of birth"
        helperText="Use this field to enter your date of birth"
        fullWidth
        parser={unixToDate}
        formatter={dateToUnix}
        // disabled
        // error
        // helperIcon={<TextFieldBaseHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <DatePicker
        control={control}
        name="dobUnix"
        required
        label="Date of Birth Unix"
        size="large"
        placeholder="Enter your date of birth"
        helperText="Use this field to enter your date of birth"
        fullWidth
        parser={unixToDate}
        formatter={dateToUnix}
        inputFormat="dd/MM/yyyy"
        disabled
        // error
        helperIcon={<TextFieldBaseHelperIcon Icon={Star} />}
      />
      <br />
      <br />
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default FormDatepicker;
