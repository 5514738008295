import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { selectCourse } from '../../db/courses/selectors';
import routes, { CourseParams } from '../../pages/routes';
import { CourseRole } from '../../types';
import { CourseTabs } from './types';

export default function useCourseHeaderVM() {
  const params = useParams<CourseParams>();
  const { pathname } = useLocation();

  const course = useSelector(selectCourse(params.courseShortId));

  const canPublishInfo = useMemo(() => {
    const myRole = course?.myRole;
    const isInfoPublished = course?.status.isInfoPublished;
    return myRole === CourseRole.ADMIN && !isInfoPublished;
  }, [course?.myRole, course?.status.isInfoPublished]);

  const activeTab = useMemo(() => {
    const routeMap = {
      [routes.timeline.path]: CourseTabs.TIMELINE,
      [routes.info.path]: CourseTabs.INFO,
      [routes.syllabus.path]: CourseTabs.SYLLABUS,
    };

    for (const [path, tab] of Object.entries(routeMap)) {
      if (matchPath(pathname, { path, exact: true })) return tab;
    }

    return CourseTabs.TIMELINE;
  }, [pathname]);
  return { activeTab, canPublishInfo, params };
}
