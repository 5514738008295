import { useTranslation } from 'react-i18next';

import { useCurrentUserWithRole } from '../../../../courses/hooks';
import { removeCourseMember } from '../../../../db/courses/actions';
import { CourseMemberStatus } from '../../../../db/shared/types';
import { i18nNS } from '../../../../i18n';
import { CourseRole } from '../../../../types';
import { useRequestDispatch } from '../../../../utils/request-actions';
import { toSentenceCase } from '../../../../utils/string';

export interface VMProps {
  avatar: MongoId;
  emailId: string;
  name: string;
  role: CourseRole.INSTRUCTOR | CourseRole.TA;
  status: CourseMemberStatus;
  userId: MongoId;
}

export default function useCourseTeamUserVM({
  avatar,
  emailId,
  name: _name,
  role: _role,
  status: _status,
  userId,
}: VMProps) {
  const requestDispatch = useRequestDispatch();
  const currentUser = useCurrentUserWithRole();

  const { t } = useTranslation([i18nNS.GLOSSARY]);

  const name = userId === currentUser.userId ? t('you', { ns: i18nNS.GLOSSARY }) : _name;
  const role = _role === CourseRole.TA ? 'TA' : _role;
  const status = _status === CourseMemberStatus.ACTIVE ? emailId : toSentenceCase(_status);

  const deleteMember = () => {
    return requestDispatch(removeCourseMember, {
      memberId: userId,
      role: _role,
    });
  };

  return { avatar, deleteMember, name, role, status, userId };
}
